import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import StarRatings from 'react-star-ratings';
import { Icon, Label } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper from '../../wrappers/Wrapper';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';

const StarRating = (props) => {

  const { allowRating, itemData, key_viewItem } = props ?? {}
  const { _itemKey } = itemData ?? {}

  const parentContext = useContext(ParentContext)
  const { states, handlers, fns } = parentContext ?? {}
  const { appUser_state, rating_state, page_state } = states ?? {}
  const { rating_handlers } = handlers ?? {}
  const { appUser_fns } = fns ?? {}
  const { appUsers } = appUser_state ?? {}
  const { ratings } = rating_state ?? {}

  const appUserAccess = getAppUserAccess(appUsers)
  const { appUserSessionKey, uid } = appUserAccess ?? {}
  const _appUserKey = appUserSessionKey ? appUserSessionKey : uid

  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { appRating } = aps_global ?? {}

  const [currentRating, setCurrentRating] = useState()
  const [currentUserRating, setCurrentUserRating] = useState(0)
  const [currentCount, setCurrentCount] = useState({ totalValue: 0, totalRatings: 0, average: 0 })
  const [showRating, setShowRating] = useState()

  const rating_access = appUser_fns.validateAccess_rating(currentRating, appRating, key_viewItem)
  const { allowAppUserView, allowAppUserRate, appUserRating } = rating_access ?? {}

  const { appUserRatings } = currentRating ?? {}
  const { totalRatings, average } = currentCount ?? {}

  useEffect(() => {
    if (ratings && ratings[_itemKey]) {
      setCurrentRating(ratings[_itemKey])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ratings, _itemKey]);

  useEffect(() => {
    if (appUserRatings) {
      const _totalRatings = Object.keys(appUserRatings).length
      const ratingsArray = _.map(appUserRatings);
      const _average = _.mean(ratingsArray);
      setCurrentCount({ totalRatings: _totalRatings, average: _average })
      if (appUserRatings[_appUserKey]) {
        setCurrentUserRating(appUserRatings[_appUserKey])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserRatings]);

  const handleShowRating = () => setShowRating(!showRating)

  const handleRate = (r) => {
    rating_handlers.handleUpdate_rating(_appUserKey, currentRating, r, _itemKey)
    setShowRating()
  }

  const ss = handleRate ? { sd: '50px', ss: '4px' } : { sd: '12px', ss: '1px' }

  const starRating = () => <StarRatings
    rating={currentUserRating}
    starRatedColor="black"
    starSelectingHoverColor='blue'
    starHoverColor='blue'
    changeRating={handleRate}
    numberOfStars={5}
    starDimension={ss.sd}
    starSpacing={ss.ss}
    isSelectable={handleRate ? true : false}
    name='rating'
  />

  const ratingLabel = () => {
    let color = totalRatings > 0 ? 'green' : 'grey'
    return <Label size='small' color={color} onClick={(e) => _appUserKey && allowAppUserRate && handleShowRating()} >
      <Icon name='users' />
      <Icon name='star outline' />
      {average && average > 0 ? average.toFixed(2) + '  (' + totalRatings + ')' : '-'}
    </Label>
  }

  const ratingLabel_non = () => {
    let color = 'blue'
    return <Label size='small' color={color} onClick={(e) => _appUserKey && allowAppUserRate && handleShowRating()} >
      <Icon name='user' />
      <Icon name='star outline' />{appUserRating ? appUserRating : '-'}
    </Label>
  }

  const wrapper = () => <Wrapper
    content={starRating()}
    css={{ content: 'rating-container select' }}
  ></Wrapper>

  if (showRating) {
    return <FullPageWrapper
      content={wrapper()}
      topperCaption={'Rating'}
      handleCancel={handleShowRating}
    ></FullPageWrapper>
  } else {
    return <div className={'rating-container'}>
      {allowAppUserView ? ratingLabel() : ratingLabel_non()}
    </div>
  }

}

export default StarRating