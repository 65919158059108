import { createRefPath } from "../../appData/appRefPaths";
import { fs_dbu } from "../../appData/fsAppDataUpdate";

export const fsUpdate_addListDoc = (props) => {
  const { baseRef, ufProps, dispatch } = props
  const { refPath } = baseRef
  const { createListProps } = ufProps ?? {}

  const { createListAs, createListData } = createListProps ? createListProps : {}
  const _addRef = createRefPath([createListAs], refPath)

  fs_dbu.add_doc(_addRef, createListData, dispatch)
}