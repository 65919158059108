import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Sidebar } from 'semantic-ui-react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { convertHelpers, getFirstObject, getGoogleSheetDataValues } from '../../../../global/common/convert';
import { creatingHelpers } from '../../../../global/common/creating';
import { filteredDates } from '../../../../global/common/filtering';
import { sortDateArray } from '../../../../global/common/sorting';
import { copyObj } from '../../../../global/common_web/copy';
import NoData from '../../../../global/components/alerts/NoData';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import AppForm from '../../../../global/components/forms/appForms/AppForm';
import DropdownOptionsMenu from '../../../../global/components/forms/elements/DropdownOptionsMenu';
import DataSidebar from '../../../../global/components/swipers/DataSidebar';
import JsonViewer from '../../../../global/components/viewers/JsonViewer';
import { appIconTypes } from '../../../../global/enums/appIconTypes';
import { gEnums } from '../../../../global/enums/globalEnums';
import { fsfn_notifications } from '../../../../global/functions/fbNotifications';
import { fsfn_sheets } from '../../../../global/functions/fbSheetsFuntions';
import UIDictaphone, { dictaphoneListenTypes } from '../../../../global/pageItem/modification/dataModifications/UIDictaphone';
import { newPropEditItem } from '../../../../global/viewSettings/helpers/settingsHelpers';
import { openExternal } from '../../../../global/viewSettings/helpers/settingsLinks';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsDistrictContext } from '../../cnr/contexts/SportsDistrictContext';
import { SportsEditMatchContext } from '../../cnr/contexts/SportsEditMatchContext';
import { SportsSeasonContext, _sportsFormProps } from '../../cnr/contexts/SportsSeasonContext';
import { matchStatusTypes, sportsDataHandlers, sportsDataInitialState, sportsDataReducer } from '../../cnr/reducers/SportsDataReducer';
import { _sportCollections } from '../../cnr/reducers/SportsSidebarReducer';
import { sportsHelpers } from '../../cnr/reducers/reducerHelpers/sportsHelpers';
import { _sdm } from '../dashboard/SportsDashboard';
import SportsRankings from '../rankings/SportsRankings';
import MatchList from './MatchList';
import ScheduleFilter, { _scheduleFilterTypes } from './ScheduleFilter';
import { fsfn_sports } from '../../functions/fbSportsFuntions';
import { seasonalHelpers, seasonalUpdateTypes } from '../../cnr/reducers/reducerHelpers/seasonalSportsData';
import { realtimeDocumentTypes } from '../../cnr/reducers/SportsEditMatchReducer';
import { realTimeScoring } from '../../dbActions/realtimeScoring';

const _csv_match = ['id', '_itemKey', 'startDate', 'section', 'level', 'combined', 'away', 'awayScore', 'home', 'homeScore', 'extra', 'oldStartate', 'postponed', 'cancelled', 'exhibition']
const _csv_matchPlayoff = ['id', 'startDate', 'startTime', 'level', 'poolKey', 'matchNumber', 'location', 'awaySeed', 'away', 'awayScore', 'homeSeed', 'home', 'homeScore', 'away1', 'home1', 'away2', 'home2', 'away3', 'home3', 'away4', 'home4', 'away5', 'home5']

const _rightModes = {
  backupMatches: 'backupMatches',
  backupScores: 'backupScores',
  confirmRankings: 'confirmRankings',
  confirmResults: 'confirmResults',
  confirmScores: 'confirmScores',
  exportPlayoffResults: 'exportPlayoffResults',
  exportPlayoffResultsMedia: 'exportPlayoffResultsMedia',
  exportResults: 'exportResults',
  exportResultsMedia: 'exportResultsMedia',
  updateMatchDayResults: 'updateMatchDayResults',
  updatePlayoffMatchDayResults: 'updatePlayoffMatchDayResults',
}

const _fetchModes = {
  results: 'results',
  playoffResults: 'playoffResults',
  resultsMedia: 'resultsMedia',
  playoffResultsMedia: 'playoffResultsMedia',
}

const _ignoreNoMid = true

const MatchDataUpdate = (props) => {

  const { sportsDataMode, forSportsManagement, fromApp, handleCancel, currentMenuKey } = props

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { pathViews } = paps_state ?? {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state } = sportsDistrictContext ?? {}
  const { gls_scores, sportPermissions, } = sportsDistrict_state ?? {}

  console.log('sportsDistrict_state', sportsDistrict_state)

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}

  const {
    details_info,
    gameScoringType,
    latestSeason,
    links_score,
    matches_info,
    parentDataKeys,
    rankings_info,
    results_matchDay,
    results_matchDay_updates,
    sportsKey,
    sportsShowOptions,
    sportData,
    teams_info,
    useScoringCollection,
    useScoringCollectionOnly,
  } = sportsSeason_state ?? {}

  const { playoffMatches, matches } = matches_info ?? {}
  const { teams } = teams_info ?? {}
  const { matches_seasonal: pks_matches_seasonal } = parentDataKeys ?? {}
  const { details } = details_info ?? {}
  const { firstPlayDate, seasonStartDate } = details ?? {}
  const { matchDate } = sportsShowOptions ?? {}

  // sportsEditMatchContext
  const sportsEditMatchContext = useContext(SportsEditMatchContext)
  const { sportsEditMatch_state } = sportsEditMatchContext ?? {}
  const { matchPendingUpdate } = sportsEditMatch_state ?? {}
  const { matchUpdateType } = matchPendingUpdate ?? {}

  // sportsData
  const init_state = { sportPermissions, teams: teams_info && teams_info.teams, sportsKey, links_score, pathViews, latestSeason, gameScoringType, matches_info, useScoringCollection, useScoringCollectionOnly }
  const [sportsData_state, sportsData_dispatch] = useReducer(sportsDataReducer, init_state, sportsDataInitialState)
  const sportsData_handlers = sportsDataHandlers(sportsData_dispatch, sportsData_state)
  const _glsScore = gls_scores && sportsKey ? gls_scores[sportsKey] : null
  const { scoreEmails } = _glsScore ?? {}

  const {
    _matchDataUpdates,
    _matchStatusGroups,
    confirmation,
    filterType,
    forPlayoffs,
    gsMatchedRefreshed,
    matches_flat,
    rankings_info: rankings_info_data,
    updateAllowed,
    updating,
    mediaMatches,
    matches_gs,
  } = sportsData_state ?? {}

  const { values, filtered, counts } = matches_flat ?? {}
  const { season: matches_flat_season, playoffs: matches_flat_playoff } = values ?? {}
  const { all: matches_flat_filtered } = filtered ?? {}
  const { filtered: filtered_count } = counts ?? {}

  const _updateCaption_results = forPlayoffs ? 'Update Playoff Results' : 'Update Results'
  const _updateCaption_scores = forPlayoffs ? 'Update Playoff Scores' : 'Update Scores'
  const _updateCaption_backupScores = forPlayoffs ? 'Backup Playoff Scores' : 'Backup Scores'
  const _updateCaption_backupMatches = forPlayoffs ? 'Backup Playoff Matches' : 'Backup Matches'

  const _matches_flat = forPlayoffs ? matches_flat_playoff : matches_flat_season

  const [appFormItems, setAppFormItems] = useState()
  const [formProps, setFormProps] = useState()
  const [linkValues, setLinkValues] = useState({})
  const [showConfirmation_rankings, setShowConfirmation_rankings] = useState()
  const [dateGroupKeyz, setDateGroupKeyz] = useState()
  const [mode_right, setMode_right] = useState()
  const [updatingGs, setUpdatingGs] = useState()
  const [mode_gsTab, setMode_gsTab] = useState()

  const { mediaUrl } = linkValues ? linkValues : {}

  const _updateStatus_results = sportPermissions.any && sportPermissions.matchesList ? true : false

  const addDates = (startDateString) => {

    const startDate = new Date(startDateString);

    // Number of days to add
    const daysToAdd = 14;

    // Initialize an array to store the resulting dates
    const resultDates = [];

    for (let i = 1; i <= daysToAdd; i++) {
      // Create a new date by adding 'i' days to the start date
      const newDate = new Date(startDate);
      newDate.setDate(startDate.getDate() + i);

      const newMonth = newDate.getMonth() + 1; // Adjust month to be 1-based
      const newDay = newDate.getDate();
      const newYear = newDate.getFullYear();

      // Format the new date as 'mm/dd/yyyy'
      const formattedDate = `${newMonth.toString().padStart(2, '0')}/${newDay.toString().padStart(2, '0')}/${newYear}`;

      resultDates.push(formattedDate);
    }

    return resultDates
  }

  useEffect(() => {
    switch (sportsDataMode) {
      case _sdm.sports.seasonResults:
        sportsData_handlers && sportsData_handlers.handleGet_matchesData(matchDate)
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (_matches_flat) {
      convertHelpers.createItemKeys(_matches_flat)
      const arrayDate = firstPlayDate ? firstPlayDate : seasonStartDate
      const dateGroupKeys = creatingHelpers.createDatesArray(arrayDate, 4)// Object.keys(_dateGroups) 
      const _dateGroupKeys = sortDateArray(dateGroupKeys)
      const fd = filteredDates(_dateGroupKeys, true)
      const addedDates = addDates(fd[fd.length - 1])
      const _fd = [...fd, ...addedDates]
      setDateGroupKeyz(_fd)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_matches_flat]);

  useEffect(() => {
    if (mediaMatches) {
      sportsSeason_handlers.handleAmmend_mediaMatches(mediaMatches)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaMatches]);

  useEffect(() => {
    const form_props = _sportsFormProps[sportsDataMode]
    const _appFormItems = {}
    if (form_props) {
      Object.keys(form_props).forEach(fp => {
        _appFormItems[fp] = newPropEditItem(fp, gEnums.formItemTypes.text, null, null, true)
      })
    }
    setFormProps(form_props)
    setAppFormItems(_appFormItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentMenuKey]);

  useEffect(() => {
    const _linkValues = {}
    switch (sportsDataMode) {
      case _sdm.district.updateDistrictSeasonScores:
        const xxx = gls_scores && currentMenuKey && gls_scores[currentMenuKey] ? gls_scores[currentMenuKey] : {}
        if (formProps) {
          Object.keys(formProps).forEach(fp => {
            _linkValues[fp] = xxx ? xxx[fp] : null
          })
        }
        break;
      default:
        if (formProps) {
          Object.keys(formProps).forEach(fp => {
            _linkValues[fp] = links_score ? links_score[fp] : null
          })
        }
    }
    setLinkValues(_linkValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sportsDataMode, formProps, links_score, currentMenuKey]);

  useEffect(() => {
    if (_matchDataUpdates && Object.keys(_matchDataUpdates).length > 0) {
      sportsHelpers.getPendingMatchesInfo(_matchDataUpdates, playoffMatches, teams)
      sportsData_handlers.handleSet_matchDataUpdates(_matchDataUpdates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_matchDataUpdates]);

  const sortMatches = (items) => {
    const _matches = _.orderBy(items, [
      item => new Date(item.startDate),
      'level',
      'section',
      'home',
    ]);
    return _matches
  }

  const convertArrayToCSV = (dataArray, headers) => {

    if (dataArray.length === 0) {
      return '';
    }

    const sortedArray = _.orderBy(dataArray, [
      item => new Date(item.startDate),
      'level',
      'section'
    ]);

    const startCaseHeaders = headers.map(header => _.startCase(header));
    const csvHeader = startCaseHeaders.join(',');

    const csvRows = sortedArray.map((data, index) => {
      const csvValues = headers.map((header) => {
        switch (header) {
          case 'id':
            return index + 1
          default:
            let value;
            switch (header) {
              case '_itemKey':
                value = data[header];
                break;
              default:
                value = data[_.camelCase(header)];
                value = typeof value === 'string' && value.includes(',')
                  ? `"${value.replace(/"/g, '""')}"`
                  : value;
            }

            return typeof value === 'object' ? JSON.stringify(value) : value;
        }
      });
      return csvValues.join(',');
    });

    return `${csvHeader}\n${csvRows.join('\n')}`;
  };

  const copyToClipboard = (text) => {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);

    // Select and copy the text
    textarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea
    document.body.removeChild(textarea);
  };

  const handleMode_right = (mr, e) => {
    e && e.stopPropagation()
    let _tabName;
    let _forMedia;
    switch (mr) {
      case _rightModes.exportResults:
        _tabName = linkValues.scoresSheet
        break;
      case _rightModes.exportPlayoffResults:
        _tabName = linkValues.playoffScoresSheet
        break;
      case _rightModes.exportResultsMedia:
        _tabName = linkValues.scoresSheet
        _forMedia = true
        break;
      case _rightModes.exportPlayoffResultsMedia:
        _tabName = linkValues.playoffScoresSheet
        _forMedia = true
        break;
      default:
      // nothing
    }
    _tabName = _forMedia ? _tabName + ' ' + latestSeason + ' Media' : _tabName + ' ' + latestSeason + ' Latest'
    setMode_gsTab(_tabName)
    setMode_right(mr)
  }

  const handleCopy = (opts, e) => {
    e && e.stopPropagation()
    switch (mode_right) {
      case _rightModes.exportResults:
        const csv = convertArrayToCSV(matches_flat_season, _csv_match)
        copyToClipboard(csv)
        break;
      case _rightModes.exportResultsMedia:
        const csvm = convertArrayToCSV(matches_flat_season, _csv_match)
        copyToClipboard(csvm)
        break;
      default:
      // nothing
    }
  }

  const handleExport = async (opts, e) => {

    e && e.stopPropagation()

    switch (mode_right) {

      case _rightModes.exportPlayoffResults:
      case _rightModes.exportPlayoffResultsMedia:
      case _rightModes.exportResults:
      case _rightModes.exportResultsMedia:

        let items;
        let _tabName;
        let _forMedia;
        let _headers;

        switch (mode_right) {
          case _rightModes.exportResults:
            _tabName = 'Results'
            items = matches_flat_season && Object.keys(matches_flat_season).length > 0 ? matches_flat_season : null
            _headers = _csv_match
            break;
          case _rightModes.exportPlayoffResults:
            _tabName = 'Playoff Results'
            items = matches_flat_playoff && Object.keys(matches_flat_playoff).length > 0 ? matches_flat_playoff : null
            _headers = _csv_matchPlayoff
            break;
          case _rightModes.exportResultsMedia:
            _tabName = 'Media Results'
            items = matches_gs && Object.keys(matches_gs).length > 0 ? matches_gs : null
            _headers = _csv_match
            _forMedia = true
            break;
          case _rightModes.exportPlayoffResultsMedia:
            _tabName = 'Media Playoff Results'
            items = matches_gs && Object.keys(matches_gs).length > 0 ? matches_gs : null
            _headers = _csv_matchPlayoff
            _forMedia = true
            break;
          default:
          // nothing
        }

        if (items) {
          const _matches = sortMatches(items)
          setUpdatingGs(true)
          const { values } = getGoogleSheetDataValues(_matches, _headers, _tabName, latestSeason, _forMedia)
          await fsfn_sheets.updateGoogleSheetValues(linkValues.googleSheetsId, mode_gsTab, values)
          setUpdatingGs(false)
        }
        break;
      default:
      // nothing
    }
  }

  const handleModeRight = (opts, e) => {
    e && e.stopPropagation()
    handleMode_right(opts.rightMode)
  }

  const handleConfirmation_results = (opts, e) => {
    e && e.stopPropagation()
    handleMode_right(_rightModes.confirmResults)
  }
  const handleConfirmation_rankings = (opts, e) => {
    e && e.stopPropagation()
    handleMode_right(_rightModes.confirmRankings)
  }
  const handleTestFunction = (opts, e) => {
    const cb = (res) => {
      console.log('res', res)
    }
    const data = {
      pojoOnly: false,
      testingOnly: true,
      glsOnly: false,
    }
    fsfn_sports.getSportsDataGlsScores(data, cb)
  }

  const handleAmmend = (opts, e) => {
    e && e.stopPropagation()
    const updatedMatches = _.chain(matches_flat_filtered)
      .filter(match => _.has(match, 'old.startDate'))
      .map(match => _.set(_.clone(match), 'startDate', _.get(match, 'old.startDate')))
      .value();
    const updatedMatches_gs = _.chain(matches_gs)
      .filter(match => _.has(match, 'old.startDate'))
      .map(match => _.set(_.clone(match), 'startDate', _.get(match, 'old.startDate')))
      .value();
  }

  const handleFilter = (ft, e) => {
    e && e.stopPropagation()
    sportsData_handlers.handleSet_currentFlatFiltered(ft)
  }
  const handleOpenGoogleSheets = (opts, e) => {
    e && e.stopPropagation()
    openExternal.googleSheets(linkValues.googleSheetsId)
  }
  const handleOpenDb = (opts, e) => {
    e && e.stopPropagation()
    openExternal.firebaseDbDirect(pathViews, [_sportCollections._matches_seasonal, pks_matches_seasonal])
  }

  const handleUpdate_gsMatchesSport = (opts, e) => {
    e && e.stopPropagation()
    sportsData_handlers.handleStartUpdate()
    sportsData_handlers.handleUpdate_gsMatchesSport(filterType, forPlayoffs)
  }

  const handleUpdate_matchScores = (e) => {
    e && e.stopPropagation()
    const _forceScoresUpdate = true
    const { matches, playoffMatches } = matches_info
    if (matches) { sportsHelpers.updateSeasonalScoresFromMatches(sportPermissions, matches, pathViews, pks_matches_seasonal, false, _forceScoresUpdate) }
    if (playoffMatches) { sportsHelpers.updateSeasonalScoresFromMatches(sportPermissions, playoffMatches, pathViews, pks_matches_seasonal, true, _forceScoresUpdate) }
  }

  const handleUpdate_matchDayScores = (opts, e) => {
    const cb = () => {
      const _matchKeys = _.keys(results_matchDay)
      realTimeScoring.deleteRealtimeDatabaseScores(realtimeDocumentTypes.matchDay, sportPermissions, sportData, seasonalUpdateTypes.rtMatches, pathViews, _matchKeys, null, latestSeason)
    }
    e && e.stopPropagation()
    sportsData_handlers.handleUpdate_matchDayResults(results_matchDay_updates, forPlayoffs, cb)
  }

  const handleBackup_matches = (e) => {
    e && e.stopPropagation()
    const _forceScoresUpdate = true
    const { matches, playoffMatches } = matches_info
    if (matches) { sportsHelpers.updateSeasonalMatchesFromMatches(sportPermissions, matches, pathViews, pks_matches_seasonal, false, _forceScoresUpdate, true) }
    if (playoffMatches) { sportsHelpers.updateSeasonalMatchesFromMatches(sportPermissions, playoffMatches, pathViews, pks_matches_seasonal, true, _forceScoresUpdate, true) }
  }

  const handleBackup_matchScores = (e) => {
    e && e.stopPropagation()
    const _forceScoresUpdate = true
    const { matches, playoffMatches } = matches_info
    if (matches) { sportsHelpers.updateSeasonalScoresFromMatches(sportPermissions, matches, pathViews, pks_matches_seasonal, false, _forceScoresUpdate, true) }
    if (playoffMatches) { sportsHelpers.updateSeasonalScoresFromMatches(sportPermissions, playoffMatches, pathViews, pks_matches_seasonal, true, _forceScoresUpdate, true) }
  }

  const handleSend_email = (e) => {
    e && e.stopPropagation()
    const x = _matchDataUpdates[filterType]
    const allMatches = []
    _.forEach(x, (section) => {
      _.forEach(section, (match) => {
        allMatches.push(match)
      })
    })

    const matchesWithDate = allMatches.map(match => ({
      ...match,
      startDateFull: new Date(match.startDate)
    }));


    const sortedMatches = _.sortBy(matchesWithDate, 'startDateFull');
    let _currentStartDate;

    const _count = sortedMatches.length

    let emailBody = "Match Results:<br>";
    emailBody += `${'Match Count: '} ${_count}<br>`;

    _.forEach(sortedMatches, (match) => {
      const { away, home, startDate } = match
      const results = match ? match._results : undefined;
      const { name: name_away } = away
      const { name: name_home } = home
      const { score } = results ?? {}
      if (score) {
        if (_currentStartDate !== startDate) {
          emailBody += `<br>${startDate}<br>`;
          _currentStartDate = startDate
        }
        const { home: home_score, away: away_score } = score
        emailBody += `${name_away} ${away_score} @ ${name_home} ${home_score}<br>`;
        // emailBody += `${startDate}<br>`;
        // emailBody += `Score: ${away_score} - ${home_score}<br><br>`;
      }
    })

    const _recips = []

    if (scoreEmails) {
      _.forEach(scoreEmails, (value) => {
        _recips.push(value.name)
      })
    }

    const notificationData = {
      email: {
        recipient: _recips, //'thumbstatsports@gmail.com',
        subject: 'Scores',
        body: emailBody
      },
      _sender: 'thumbstatsports@gmail.com'
    }
    fsfn_notifications.sendEmail(notificationData)
  }

  const handleUpdate_rankings = () => {
    setShowConfirmation_rankings()
    sportsData_handlers.handleStartUpdate()
    sportsData_handlers.handleUpdate_gsRankings(rankings_info_data)
  }

  const handleGoogleSearch_results = (opts) => {
    if (sportsData_handlers) {
      let _forMedia;
      let _forPlayoffs
      const { fetchMode } = opts
      switch (fetchMode) {
        case _fetchModes.results:
          linkValues.sheetName = linkValues.scoresSheet
          break;
        case _fetchModes.playoffResults:
          linkValues.sheetName = linkValues.playoffScoresSheet
          _forPlayoffs = true
          break;
        case _fetchModes.resultsMedia:
          linkValues.sheetName = linkValues.scoresSheet
          _forMedia = true
          break;
        case _fetchModes.playoffResultsMedia:
          _forPlayoffs = true
          _forMedia = true
          linkValues.sheetName = linkValues.playoffScoresSheet
          break;
        default:
        // nothing
      }
      sportsData_handlers.handleStartUpdate()
      sportsData_handlers.handleGet_gsMatches(linkValues, _forPlayoffs, _forMedia)
    }

  }

  const handleGoogleSearch_rankings = () => {
    linkValues.googleSheetsId = linkValues.rankingsGoogleSheetsId
    linkValues.sheetName = linkValues.rankingsSheet
    if (sportsData_handlers) {
      sportsData_handlers.handleStartUpdate()
      sportsData_handlers.handleGet_gsRankings(linkValues, latestSeason, sportsKey)
    }
  }

  const handleMediaSearch_dates = async (date) => {
    sportsData_handlers.handleStartUpdate()
    sportsData_handlers.handleGet_mediaMatches(date, dateGroupKeyz)
  }

  const handleMediaSearch_playoffs = async (date) => {

    // const data = await callFunction('apiGeneric', callData)
    // console.log('data', data)

    sportsData_handlers.handleStartUpdate()
    sportsData_handlers.handleGet_mediaMatches(date, dateGroupKeyz, true)

  }

  const handleToggle = (m) => {
    const { _itemKey, home, homeScore, away, awayScore, level } = m
    const mdu = _matchDataUpdates[filterType]
    const mdui = mdu[level]
    if (mdui) {
      const _m = copyObj(m)
      _m.away = home
      _m.home = away
      _m.awayScore = homeScore
      _m.homeScore = awayScore
      mdui['matches.' + _itemKey] = _m
      sportsData_handlers.handleSet_matchDataUpdates(_matchDataUpdates)
    }
  }

  const handleOpenMedia = () => window.open(mediaUrl, '_blank');

  const matchCount = (jsonData) => {
    let _count = 0
    if (jsonData) {
      Object.keys(jsonData).forEach(key => {
        _count += Object.keys(jsonData[key]).length
      })
    }
    return _count
  }

  const handleSelect = (e, item) => {
    if (item) {
    }
  }

  const btns_ammend = () => {
    const btns = []
    btns.push({ header: 'Adjust', db: true, da: true })
    btns.push({ caption: 'Revert to Old Date', oc: handleAmmend, color: 'teal', fl: true, icon: 'check', opts: { rightMode: _rightModes.exportResults } })
    return btns
  }

  const btns_exportTo = () => {
    const btns = []
    btns.push({ header: 'Google Export', db: true, da: true })
    btns.push({ caption: 'Export Results ' + latestSeason, oc: handleModeRight, color: 'teal', fl: true, icon: 'cloud upload', opts: { rightMode: _rightModes.exportResults } })
    btns.push({ caption: 'Export Playoff Results ' + latestSeason, oc: handleModeRight, color: 'teal', fl: true, icon: 'cloud upload', opts: { rightMode: _rightModes.exportPlayoffResults } })
    btns.push({ caption: 'Export Results ' + latestSeason + ' Media', oc: handleModeRight, color: 'teal', fl: true, icon: 'cloud upload', opts: { rightMode: _rightModes.exportResultsMedia } })
    btns.push({ caption: 'Export Playoff Results ' + latestSeason + ' Media', oc: handleModeRight, color: 'teal', fl: true, icon: 'cloud upload', opts: { rightMode: _rightModes.exportPlayoffResultsMedia } })
    return btns
  }

  const btns_scores = () => {
    const btns = []
    btns.push({ header: 'Scores', db: true, da: true })
    btns.push({ caption: 'Update Scores', oc: handleModeRight, color: 'teal', fl: true, icon: 'cloud upload', opts: { rightMode: _rightModes.confirmScores } })
    btns.push({ caption: 'Update Match Day Results', oc: handleModeRight, color: 'teal', fl: true, icon: 'cloud upload', opts: { rightMode: _rightModes.updateMatchDayResults } })
    btns.push({ caption: 'Test Functions', oc: handleTestFunction, color: 'teal', fl: true, icon: 'check circle' })
    return btns
  }

  const btns_backup = () => {
    const btns = []
    btns.push({ header: 'Backup', db: true, da: true })
    btns.push({ caption: 'Backup Matches', oc: handleModeRight, color: 'teal', fl: true, icon: 'cloud upload', opts: { rightMode: _rightModes.backupMatches } })
    btns.push({ caption: 'Backup Scores', oc: handleModeRight, color: 'teal', fl: true, icon: 'cloud upload', opts: { rightMode: _rightModes.backupScores } })
    return btns
  }

  const btns_search = () => {
    const btns = []
    switch (sportsDataMode) {
      case _sdm.sports.seasonResults:
        btns.push({ header: 'Open External', db: false, da: true })
        btns.push({ caption: 'Database', oc: handleOpenDb, color: 'orange', fl: true, icon: 'external alternate' })
        btns.push({ caption: 'Google Sheets', oc: handleOpenGoogleSheets, color: 'orange', fl: true, icon: 'external alternate' })
        btns.push({ caption: 'Media', oc: handleOpenMedia, color: 'orange', fl: true, icon: 'external alternate' })

        if (linkValues.scoresSheet) {
          btns.push({ header: 'Google Results', db: true, da: true })
          btns.push({ caption: 'Search Google Sheet Results', oc: handleGoogleSearch_results, color: 'blue', fl: true, icon: 'search', opts: { fetchMode: _fetchModes.results } })
          btns.push({ caption: 'Search Google Sheet Playoff Results', oc: handleGoogleSearch_results, color: 'blue', fl: true, icon: 'search', opts: { fetchMode: _fetchModes.playoffResults } })
          btns.push({ caption: 'Search Google Sheet Media Results', oc: handleGoogleSearch_results, color: 'blue', fl: true, icon: 'search', opts: { fetchMode: _fetchModes.resultsMedia } })
          btns.push({ caption: 'Search Google Sheet Media Playoff Results ', oc: handleGoogleSearch_results, color: 'blue', fl: true, icon: 'search', opts: { fetchMode: _fetchModes.playoffResultsMedia } })
        }

        if (mediaUrl) {
          btns.push({ header: 'Media', db: true, da: true })
          btns.push({ caption: 'Search Media Results', oc: handleMediaSearch_dates, color: 'blue', fl: true, icon: 'search' })
          btns.push({ caption: 'Search Media Playoff Results', oc: handleMediaSearch_playoffs, color: 'blue', fl: true, icon: 'search' })
        }
        break;

      case _sdm.sports.seasonRankings:
        btns.push({ caption: 'Search Google Sheets Rankings', oc: handleGoogleSearch_rankings, color: 'blue', icon: 'search', fl: true })
        btns.push({ caption: 'Open Google Sheets', oc: handleOpenGoogleSheets, color: 'orange', icon: appIconTypes.externalAlternate, fl: true })

      default:
        break;

      // nothing
    }
    return btns
  }

  // this will popup if there are results
  const scheduleFilter = (showMatchFilter) => {
    const enabled = showMatchFilter && _matchStatusGroups
    return <ScheduleFilter
      scheduleFilterType={scheduleFilterType}
      handleFilter={handleFilter}
      filterButtons={matchStatusTypes}
      matchStatusGroups={_matchStatusGroups}
      enabled={enabled}
    />
  }

  const footer = () => {

    const btns = []
    const btns_s = btns_search()

    let _allBtns;
    let _showMatchFilter = false;

    switch (sportsDataMode) {
      case _sdm.sports.seasonResults:
        const _updateDisabled_scores = !gsMatchedRefreshed || !updateAllowed
        btns.push({ caption: _updateCaption_results, oc: handleConfirmation_results, color: _updateStatus_results ? 'blue' : 'olive', icon: 'arrow right', disabled: _updateDisabled_scores })
        const btns_x = btns_exportTo()
        const btns_a = btns_ammend()
        const btns_sc = btns_scores()
        const btns_bk = btns_backup()
        _allBtns = [...btns_s, ...btns_sc, ...btns_x, ...btns_bk, ...btns_a]
        _showMatchFilter = true
        break;

      default:
        _allBtns = [...btns_s]
        const _updateDisabled_rankings = !rankings_info
        btns.push({ caption: 'Update Rankings', oc: handleConfirmation_rankings, color: _updateStatus_results ? 'blue' : 'olive', icon: 'arrow right', disabled: _updateDisabled_rankings })
      // nothing
    }

    return <div className={'header-flex'}>
      <div className='flx2'>
        <div>
          <DropdownOptionsMenu menuButtons={_allBtns} caption={'Options'} icon={'list ul'} color={'blue'} />
        </div>
        <div>
          {scheduleFilter(_showMatchFilter)}
        </div>
      </div>
      <div>
        <UiSaveButtons
          others={btns}
          color={'blue'}
        />
      </div>
    </div>
  }

  const scheduleFilterType = _scheduleFilterTypes.dropdown

  let cn = 'match-data-results'
  if (appFormItems && !fromApp) { cn += ' frapp' }
  let headerCount;

  if (filtered_count) {
    headerCount = filterType ? _.startCase(filterType) + ' (' + filtered_count + ')' : 'All (' + filtered_count + ')'
  }

  const content_results = () => <Wrapper
    header={headerCount}
    content={matches_flat_filtered && <MatchList
      matches={matches_flat_filtered}
      filterType={filterType}
      handleDateSearch={handleMediaSearch_dates}
      handleToggle={handleToggle}
    />}
    wrapperType={wrapperTypes.paddedHeader}
  ></Wrapper>

  const content_rankings = () => <SportsRankings rankings_info={rankings_info} rankings_info_data={rankings_info_data} />

  const content_right = () => {

    const { matches, playoffMatches } = matches_info

    const _matches = {
      matches: matches,
      playoffMatches: playoffMatches,
    }

    const _scores = {
      matches: sportsHelpers.getMatchesScores(matches, 'matches'),
      playoffMatches: sportsHelpers.getMatchesScores(playoffMatches, 'playoffMatches'),
      matchDay: 'TEST'
    }

    let wrapperContent;
    let headerCaption;
    let forMedia;

    const btns = []

    switch (mode_right) {
      case _rightModes.confirmResults:
        const jsonData = filterType ? _matchDataUpdates[filterType] : _matchDataUpdates
        const _matchCount = matchCount(jsonData)
        headerCaption = 'Update ' + _matchCount + ' Matches?'
        wrapperContent = <JsonViewer json={jsonData} name={filterType ? filterType : 'matchDataUpdates'} ignoreNoMid={_ignoreNoMid} />
        btns.push({ caption: _updateStatus_results ? _updateCaption_results : _updateCaption_results + ' (preview)', oc: handleUpdate_gsMatchesSport, icon: 'save', color: _updateStatus_results ? 'green' : 'olive', })
        btns.push({ caption: _updateStatus_results ? 'Email Scores' : 'Email Scores (preview)', oc: handleSend_email, icon: 'send', fl: true })
        break;

      case _rightModes.backupMatches:
        headerCaption = 'Backup Scores?'
        wrapperContent = <JsonViewer json={_matches} name={'seasonMatches'} ignoreNoMid={_ignoreNoMid} />
        btns.push({ caption: _updateStatus_results ? _updateCaption_backupMatches : _updateCaption_backupMatches + ' (preview)', oc: handleBackup_matches, icon: 'save', color: _updateStatus_results ? 'green' : 'olive', })
        break;

      case _rightModes.backupScores:
        headerCaption = 'Backup Scores?'
        wrapperContent = <JsonViewer json={_scores} name={'seasonScores'} ignoreNoMid={_ignoreNoMid} />
        btns.push({ caption: _updateStatus_results ? _updateCaption_backupScores : _updateCaption_backupScores + ' (preview)', oc: handleBackup_matchScores, icon: 'save', color: _updateStatus_results ? 'green' : 'olive', })
        break;

      case _rightModes.confirmScores:
        headerCaption = 'Update Scores?'
        wrapperContent = <JsonViewer json={_scores} name={'seasonScores'} ignoreNoMid={_ignoreNoMid} />
        btns.push({ caption: _updateStatus_results ? _updateCaption_scores : _updateCaption_scores + ' (preview)', oc: handleUpdate_matchScores, icon: 'save', color: _updateStatus_results ? 'green' : 'olive', })
        break;

      case _rightModes.updateMatchDayResults:
        headerCaption = 'Update Match Day Scores?'
        wrapperContent = <JsonViewer json={results_matchDay_updates} name={'seasonScores'} ignoreNoMid={_ignoreNoMid} />
        btns.push({ caption: 'Update Match Day Scores', oc: handleUpdate_matchDayScores, icon: 'save', color: _updateStatus_results ? 'green' : 'olive', })
        break;

      case _rightModes.confirmRankings:
        const { rankings: jsonDataa } = rankings_info_data ?? {}
        headerCaption = 'Update Rankings?'
        wrapperContent = <JsonViewer json={jsonDataa} name={'rankingUpdates'} ignoreNoMid={_ignoreNoMid} />
        btns.push({ caption: 'Update Rankings', oc: handleUpdate_rankings, icon: 'save', color: 'green' })
        break;

      case _rightModes.exportResults:
        headerCaption = 'Export to (' + mode_gsTab + ')'
        btns.push({ caption: "Copy", oc: handleCopy, icon: 'copy' })
        btns.push({ caption: "Export", oc: handleExport, icon: 'cloud upload', fl: true })
        wrapperContent = <JsonViewer json={matches_flat_season} name={'Matches'} ignoreNoMid={_ignoreNoMid} />
        break;

      case _rightModes.exportPlayoffResults:
        headerCaption = 'Export to (' + mode_gsTab + ')'
        btns.push({ caption: "Copy", oc: handleCopy, icon: 'copy' })
        btns.push({ caption: "Export", oc: handleExport, icon: 'cloud upload', fl: true })
        wrapperContent = <JsonViewer json={matches_flat_playoff} name={'Matches'} ignoreNoMid={_ignoreNoMid} />
        break;

      case _rightModes.exportResultsMedia:
        headerCaption = 'Export to (' + mode_gsTab + ')'
        btns.push({ caption: "Copy", oc: handleCopy, icon: 'copy' })
        btns.push({ caption: "Export", oc: handleExport, icon: 'cloud upload', fl: true })
        wrapperContent = <JsonViewer json={matches_flat_season} name={'Playoff Matches'} ignoreNoMid={_ignoreNoMid} />
        break;

      case _rightModes.exportPlayoffResultsMedia:
        const _jsonDataw = sortMatches(matches_gs)
        headerCaption = 'Export to (' + mode_gsTab + ')'
        forMedia = true
        btns.push({ caption: "Copy", oc: handleCopy, icon: 'copy' })
        btns.push({ caption: "Export", oc: handleExport, icon: 'cloud upload', fl: true })
        wrapperContent = <JsonViewer json={_jsonDataw} name={'Matches'} ignoreNoMid={_ignoreNoMid} />
        break;
      default:
        wrapperContent = <div>???</div>
    }

    return <Wrapper
      header={headerCaption}
      content={wrapperContent}
      footer={btns && btns.length > 0 && <UiSaveButtons others={btns} />}
      wrapperType={wrapperTypes.paddedHeaderAndFooter}
      updating={updating}
    />
  }

  const sidebar_right = (visible) => <DataSidebar width={fromApp ? 'wide' : null} direction={'right'} visible={visible} content={visible && content_right()} onHide={handleMode_right} />

  const content = () => {
    switch (sportsDataMode) {
      case _sdm.sports.seasonResults:
        return updateAllowed ? content_results() : <NoData fullCaption={gsMatchedRefreshed ? 'Select Filter' : 'Select Option'} labelColor={'grey'} />
      case _sdm.sports.seasonRankings:
        return rankings_info ? content_rankings() : <div></div>
      default:
      // nothing
    }
  }

  const wrapper = () => <Wrapper
    content={content()}
    footer={footer()}
    confirmation={confirmation}
    wrapperType={wrapperTypes.padded}
    updating={updating}
  />

  const sidebar_full = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_right(mode_right ? true : false)}
    <Sidebar.Pusher dimmed={mode_right ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar_full()}
    topperCaption={_.startCase(sportsDataMode)}
    topperCaption2={_.startCase(filterType)}
    handleCancel={handleCancel}
    css={{ container: 'matchUpdates app-dark-mode' }}
  />

  return forSportsManagement ? sidebar_full() : fullPageWrapper()
}

export default MatchDataUpdate 