import React from 'react'
import { uniqueKey } from '../../../../global/common/keys'
import SportsMatchProvider from '../../cnr/contexts/SportsMatchContext'
import SportsRtMatchProvider from '../../cnr/contexts/SportsRtMatchContext'
import MatchSingle from './MatchSingle'
import MatchSummary from './MatchSummary'
import PoolMatch from './PoolMatch'

export const sportsMatchViewTypes = {
  summary: 'summary',
  matchResult: 'matchResult',
  single: 'single',
  singleLevel: 'singleLevel',
  singlePool: 'singlePool',
}

const MatchWrapper = (props) => {

  const { sportsMatchViewType, match } = props
  const { key } = match ?? {}

  const content = () => {
    switch (sportsMatchViewType) {
      case sportsMatchViewTypes.summary:
        return <MatchSummary {...props} />
      case sportsMatchViewTypes.single:
      case sportsMatchViewTypes.singleLevel:
        return match ? <MatchSingle key={uniqueKey('mw', 'ms', key)} {...props} /> : <div></div>
      case sportsMatchViewTypes.singlePool:
        return <PoolMatch match={match} forGrid={true} />
      default:
        return <div>--------</div>
    }
  }

  const wrapper = () => <SportsMatchProvider {...props}>
    <SportsRtMatchProvider {...props}>
      {content()}
    </SportsRtMatchProvider>
  </SportsMatchProvider>

  return wrapper()

}

export default MatchWrapper
