import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import AppUserList from '../../appUsers/AppUserList';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { uniqueKey } from '../../common/keys';
import { copyCodeToClipboard } from '../../common_web/copy';
import { fsfn_auth } from '../../functions/fbAuth';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import UiSaveButtons from '../buttons/UiSaveButtons';
import { gEnums } from '../../enums/globalEnums';
import { getRecentAuths } from '../../common/filtering';

const limit = 100
const _defaultDays = 7
const _dayBackMax = 60

/**
 * 
 * @returns wrapper 
 */
const UserAuths = () => {


  console.log('UserAuths')

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, paps_state, eventInfo_state } = states
  const { pathViews } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings } = pageSettings ?? {}
  const { appUserCollection } = aps_appUserSettings ?? {}

  // eventInfoContext  
  const { appProfileData } = eventInfo_state ?? {}

  const [authsFound, setAuthsFound] = useState()
  const [authsList, setAuthsList] = useState()
  const [authsListEm, setAuthsListEm] = useState()
  const [dayCount, setDayCount] = useState(_defaultDays)
  const [refList, setRefList] = useState()
  const [selectedListType, setSelectedListType] = useState(gEnums.authListTypes.recent)
  const [staticAppUsers, setStaticAppUsers] = useState()

  console.log('dayCount', dayCount)

  const handleDayChange = (e, d) => setDayCount(d.value)
  const handleSelectedListType = (sl) => setSelectedListType(sl)

  const textAreaRef = useRef()

  useEffect(() => {
    let _staticAppUsers;
    if (pathViews.events) {
      if (appUserCollection && appProfileData && appProfileData[appUserCollection]) {
        _staticAppUsers = appProfileData[appUserCollection]
      }
    } else if (pathViews.clients) {
      if (appUserCollection && appProfileData && appProfileData.clientProfiles) {
        _staticAppUsers = appProfileData.clientProfiles
      }
    } else {
      _staticAppUsers = appProfileData.adminProfiles
    }
    setStaticAppUsers(_staticAppUsers)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (staticAppUsers) {
      const _sau = _.sortBy(staticAppUsers, 'lastName')
      const chunks = _.chunk(_sau, limit);
      fsfn_auth.getAuthsByChunkEmail(chunks, pathViews, true, setAuthsFound)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [staticAppUsers]);

  useEffect(() => {
    if (authsFound && staticAppUsers) {
      const { found } = authsFound
      const recent = getRecentAuths(found, 1)
      Object.keys(staticAppUsers).forEach((key) => {
        const _staticAppUser = staticAppUsers[key]
        const { email } = _staticAppUser
        const _af = email ? _.find(found, { email: email.toLowerCase() }) : null
        const _ar = email ? _.find(recent, { email: email.toLowerCase() }) : null
        _staticAppUser.foundType = gEnums.authListTypes.notFound
        if (_af) { _staticAppUser.foundType = gEnums.authListTypes.found }
        if (_ar) { _staticAppUser.foundType = gEnums.authListTypes.recent }
        if (_af || _ar) {
          _staticAppUser.metadata = _af.metadata
        }
      })
      const _staticAppUsersEm = Object.keys(staticAppUsers).map(key => (
        {
          email: staticAppUsers[key].email,
          foundType: staticAppUsers[key].foundType
        }
      ))
      setAuthsList(_.groupBy(staticAppUsers, 'foundType'))
      setAuthsListEm(_.groupBy(_staticAppUsersEm, 'foundType'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [authsFound, dayCount]);

  useEffect(() => {
    if (authsListEm && selectedListType) {
      const _list = authsListEm[selectedListType]
      if (_list) {
        const _listSelected = Object.keys(_list).map(key => (
          _list[key].email
        ))
        setRefList(_listSelected)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [authsListEm, selectedListType]);

  const handleCopy = () => copyCodeToClipboard(textAreaRef)

  const options = () => {
    const opts = []
    for (let i = 1; i <= _dayBackMax; i++) {
      opts.push({ text: 'Last ' + i + ' Days', value: i, key: i })
    }
    return opts
  }

  const dropdown = () => <Dropdown text={'Last ' + dayCount + ' Days'} direction='left' className={'ua-search'} placeholder='days' size={'mini'} search selection options={options()} onChange={handleDayChange} />

  const button = (key, basic, text, color, oc) => <Button key={uniqueKey('au', 'b', key)} compact basic={basic} color={color} onClick={(e) => { oc(key) }}>{text}</Button>

  const buttons = () => {
    const rc = authsList && authsList[gEnums.authListTypes.recent] ? authsList[gEnums.authListTypes.recent].length : 0
    const fc = authsList && authsList[gEnums.authListTypes.found] ? authsList[gEnums.authListTypes.found].length : 0
    const nfc = authsList && authsList[gEnums.authListTypes.notFound] ? authsList[gEnums.authListTypes.notFound].length : 0
    return <Button.Group size={'mini'}>
      {button(gEnums.authListTypes.recent, (selectedListType === gEnums.authListTypes.recent ? false : true), rc, 'green', handleSelectedListType)}
      {button(gEnums.authListTypes.found, (selectedListType === gEnums.authListTypes.found ? false : true), fc, 'blue', handleSelectedListType)}
      {button(gEnums.authListTypes.notFound, (selectedListType === gEnums.authListTypes.notFound ? false : true), nfc, 'red', handleSelectedListType)}
    </Button.Group>
  }

  const content = () => {
    const authsRecent = authsList && authsList[gEnums.authListTypes.recent] ? authsList[gEnums.authListTypes.recent] : []
    const authsFound = authsList && authsList[gEnums.authListTypes.found] ? authsList[gEnums.authListTypes.found] : []
    const authsNotFound = authsList && authsList[gEnums.authListTypes.notFound] ? authsList[gEnums.authListTypes.notFound] : []
    switch (selectedListType) {
      case gEnums.authListTypes.recent:
        return <AppUserList dataItems={authsRecent} showFound={true} found={true} alphaProp={'displayName'} />
      case gEnums.authListTypes.found:
        return <AppUserList dataItems={authsFound} showFound={true} found={true} alphaProp={'displayName'} />
      case gEnums.authListTypes.notFound:
        return <React.Fragment>
          <AppUserList dataItems={authsNotFound} showFound={true} found={false} alphaProp={'displayName'} />
          {ta()}
        </React.Fragment>
      default:
      // nothing
    }
  }

  const header = () => <div className='flx-lr'>
    {buttons()}
    {dropdown()}
  </div>

  const ta = () => <textarea
    key={uniqueKey('gih', 'hc')}
    ref={textAreaRef}
    value={refList}
    style={{ position: 'absolute', left: '-999em' }}
  />

  const wrapper = () => <Wrapper
    header={header()}
    content={content()}
    footer={<UiSaveButtons save={{ caption: 'Copy ' + _.startCase(selectedListType), oc: handleCopy, disabled: !textAreaRef.current }} />}
    wrapperType={wrapperTypes.paddedHeader}
    updating={!authsList}
    cnAlt={'user-auths'}
  ></Wrapper >

  return wrapper()
}

export default UserAuths

