import { last } from 'lodash';
import React from 'react';
import { HashRouter, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AppSub, { subAppTypes } from './AppSub';
import './cssAlt/AppZ.css';
import './cssMain/AppColor.css';
import './cssMain/AppContainers.css';
import './cssMain/AppFramework.css';
import './cssMain/AppGlobal.css';
import './cssMain/AppPage.css';
import './cssMain/AppSettings.css';
import './cssMain/me.css';
import AnalyticsProvider from './global/cnr/contexts/AnalyticsContext';
import AppSettingsProvider from './global/cnr/contexts/AppSettingsContext';
import AuthProvider from './global/cnr/contexts/AuthContext';
import ComponentsProvider from './global/cnr/contexts/ComponentsContext';
import ServiceWorkerProvider from './global/cnr/contexts/ServiceWorkerContext';
import StartProvider from './global/cnr/contexts/StartContext';
import AppRoutes from './global/page/AppRoutes';
import PageNotFound from './global/page/PageNotFound';
import { currentHelpers } from './global/redirection/current';
import DatabaseProvider from './global/cnr/contexts/DatabaseContext';
import { DragFramer } from './global/dragNDrops/DragFramer';

export const useHash = true
export const useIconFolder = false
export const hashmark = '#'
export const consoleStart = false
export const _checkComponentTimes = false

export const _showStates = {
  any: false,
  appSettings: true,
  auth: true,
  manifest: true,
  pageContext: true,
  pageDimmer: true,
  paps: true,
  settingsData: true,
  start: true,
  validation: true,
  viewSettingsMenu: true,
}

const useRedirect = true

/** Starting point fo the App
 * @includes StartProvider  
 * @includes AuthProvider  
 * @includes AppSettingsProvider  
 * @includes HashRouter
 * @includes HashRouter
 * @includes Pages
 *  */
function App() {

  const hashPath = currentHelpers.getHashPath()

  let { _hash, queryValues, _redirect, _pathNameOriginal } = hashPath ?? {}
  if (_hash && _hash.startsWith('#')) { _hash = _hash.substring(1) }
  const pvs = _hash ? currentHelpers.getPathViews(_hash) : currentHelpers.getPathViews(_hash)
  const lastItem = last((_hash).split('/'))

  const getSubA = () => {
    if (lastItem === subAppTypes.emailSignInConfirmation || queryValues.emailSignInConfirmation) {
      return lastItem
    } else if (pvs.willCall || lastItem === subAppTypes.willCall) {
      return subAppTypes.willCall
    } else if (pvs.willCallPhone || lastItem === subAppTypes.willCallPhone) {
      return subAppTypes.willCallPhone
    } else if (pvs.willCallEmail || lastItem === subAppTypes.willCallEmail) {
      return subAppTypes.willCallEmail
    } else if (lastItem === subAppTypes.willCallHome) {
      return lastItem
    } else if (lastItem === subAppTypes.settings) {
      return subAppTypes.settings
    }
  }

  // handleOnMessage().then(payload => {
  //   // setShow(true);
  //   // setNotification({title: payload.notification.title, body: payload.notification.body})
  //   console.log('handleOnMessage: payload', payload);
  // }).catch(err => console.log('failed: ', err));

  const subA = getSubA()

  if (subA) {
    return <AppSub lastItem={subA} queryValues={queryValues} />
  } else {
    if (useRedirect && _redirect) {
      return <div className='App' >
        <Router>
          <Routes>
            <Route path={_pathNameOriginal} element={<PageNotFound />} />p
            <Route path={'/404'} element={<PageNotFound />} />
          </Routes>
        </Router>
      </div>
    } else {
      return <StartProvider>
        <DatabaseProvider>
          <AuthProvider>
            <AppSettingsProvider hashPath={hashPath}>
              <AnalyticsProvider>
                <ComponentsProvider>
                  <div className='App' >
                    {/* <DragFramer /> */}
                    {/* <EmailSignInConfirmation /> */}
                    {!useHash && <Router><AppRoutes /></Router>}
                    {useHash && <HashRouter><AppRoutes /></HashRouter>}
                  </div >
                </ComponentsProvider>
              </AnalyticsProvider>
            </AppSettingsProvider>
          </AuthProvider>
        </DatabaseProvider>
      </StartProvider>
    }
  }
}



export const AppWithProvider = () => {
  return <ServiceWorkerProvider>
    <App />
  </ServiceWorkerProvider>
}

export default App;