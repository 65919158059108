import _ from 'lodash';
import React from 'react';
import { Icon, Image, Label } from 'semantic-ui-react';
import { Video } from '../../../globalAlt/components/video/Video';
import { createScorecard } from '../../../projectSpecific/golf/elements/createScorecard';
import { createTeeboxes } from '../../../projectSpecific/golf/elements/createTeeboxes';
import { formatItem, formatTypes, timeConvertor } from '../../common/dateFormatting';
import { uniqueKey } from '../../common/keys';
import { propHelpers } from '../../common/tester';
import { gEnums } from '../../enums/globalEnums';
import { renderHtml } from '../html/renderHtml';
import { ArrayLabels } from '../labels/ArrayLabels';
import QRCode from 'qrcode.react';
import parsePhoneNumberFromString, { parsePhoneNumber } from 'libphonenumber-js/core';

/**
 * Adjusts the value of the propItem depending on the certain settings.
 * @param {object} parentContext 
 * @param {object} viewItem 
 * @param {object} propItem 
 * @param {*} propValue 
 * @param {object} itemData 
 * @param {object} componentContexts 
 * @returns 
 */
export const getPropElementValue = (parentContext, viewItem, propItem, propValue, itemData, componentContexts) => {

  const { uiItemContext } = componentContexts ?? {}
  const { item_handlers } = uiItemContext ?? {}

  const { states, fns, settings } = parentContext
  const { page_state, paps_state, eventInfo_state, storage_state } = states ?? {}
  const { page_fns } = fns ?? {}

  const { homeSettings } = settings ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}
  const { logging } = homeSettings_global ?? {}

  const { viewKey } = paps_state
  const { globalFiles } = storage_state

  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems } = pageSettings
  const { pushUrl } = page_fns

  const { staticViews } = eventInfo_state ?? {}

  const { key: viKey } = viewItem ?? {}

  const { clientOptions, eventOptions, display: display_global, formatting: formatting_global, imageMapping } = aps_global ?? {}
  const _appOptions = { ...clientOptions, ...eventOptions }
  const { allowPhoneLinks, allowEmailLinks, allowUrlLinks } = _appOptions ?? {}
  const { hideArrays, urlText } = display_global ?? {}

  const { links, display: display_propItem } = propItem
  const { urlLinkProp, linkCaption } = links ?? {}
  const { elemPropType, countOf, valueIfUrl } = display_propItem ?? {}

  const { allowCollectionMapping, locationProps } = imageMapping ?? {}

  let prop_app = propItem && aps_viewItems && viKey && aps_viewItems[viKey] && aps_viewItems[viKey].props && aps_viewItems[viKey].props[propItem.key] ? aps_viewItems[viKey].props[propItem.key] : null
  if (!prop_app) { prop_app = propItem && aps_viewItems && viKey && aps_viewItems[viKey] && aps_viewItems[viKey].props && aps_viewItems[viKey].props[propItem.key.toLowerCase()] ? aps_viewItems[viKey].props[propItem.key.toLowerCase()] : null }

  const {
    key: propKey,
    drilled,
    labeled,
  } = propItem

  let showPropMap = false

  if (allowCollectionMapping && locationProps && locationProps.includes(propKey)) {
    showPropMap = true
  }

  let prop_value = propValue
  let isEmailOrPhone = false
  let isOther = false
  let is_url = false

  if (prop_value) {

    if (propItem) {

      const { data: prop_app_data } = prop_app ?? {}
      const { dataListType, staticList } = prop_app_data ?? {}

      try {

        // date
        if (propHelpers.isDateProp(propKey)) {
          if (formatting_global && formatting_global.dateFormat) { prop_value = formatItem(formatting_global.dateFormat, prop_value) }
        }

        // time
        if (propHelpers.isTimeProp(propKey)) {
          try {
            const { hour, min } = timeConvertor(prop_value)
            if (hour && min) {
              const dt = new Date()
              dt.setHours(hour)
              dt.setMinutes(min)
              prop_value = formatItem(formatting_global ? formatting_global.timeFormat : formatTypes.time, dt)
            }
          } catch (error) {
            console.error(error);
          }
        }

        if (labeled) { prop_value = <Label key={uniqueKey('cs', 'l', propKey)} size='small' circular color={labeled}>{prop_value}</Label> }

        if (countOf) {
          prop_value = itemData[propKey] ? itemData[propKey].length : 0
          if (prop_value === 0) { prop_value = '0' }
        }

        if (propHelpers.isHtml(prop_value) || propHelpers.isPTagged(prop_value)) {
          prop_value = checkForPhone(prop_value)
          let hm = renderHtml(prop_value)
          return <div className={'is-html'}>{hm}</div>
        }

        switch (elemPropType) {
          case gEnums.elemPropTypes.miniSort:
            break;

          case gEnums.elemPropTypes.qrCode:
            return <QRCode size={'64'} value={prop_value} />

          case gEnums.elemPropTypes.time:
            return <Label key={uniqueKey('tl', propKey)} ><Icon name='clock outline' />{prop_value}</Label>

          case gEnums.elemPropTypes.date:
            return <Label key={uniqueKey('dtl', propKey)}><Icon name='calendar alternate outline' />{prop_value}</Label>

          case gEnums.elemPropTypes.image:
            prop_value = globalFileImage(globalFiles, prop_value)
            is_url = true
            break;
          case gEnums.elemPropTypes.golfScorecard:
            isOther = true
            prop_value = createScorecard(prop_value)
            break;
          case gEnums.elemPropTypes.golfTeeboxes:
            isOther = true
            prop_value = createTeeboxes(prop_value)
            break;
          case gEnums.elemPropTypes.video:
            prop_value = <Video />
            isOther = true
            break;
          default:
          // nothingF
        }

        if (allowUrlLinks) {
          if (propHelpers.isUrl(prop_value)) {
            let urlCaption = linkCaption ? linkCaption : prop_value
            if (valueIfUrl) { urlCaption = valueIfUrl }
            if (urlText) { urlCaption = urlText }
            if (urlText) {
              prop_value = <Label size={'small'} color={'black'} onClick={e => pushUrl(e, propValue)}>{urlCaption}</Label>
            } else {
              prop_value = <div className={elemPropType + ' ip-url'} onClick={e => pushUrl(e, propValue)} >{urlCaption}</div >
            }
            is_url = true
          }
          if (!itemData.noClick && urlLinkProp && itemData[urlLinkProp]) {
            prop_value = <div className={elemPropType} onClick={e => pushUrl(e, itemData[urlLinkProp])}>{prop_value}</div >
          }
        }

        // phone
        if (allowPhoneLinks && (propHelpers.isPhoneProp(propKey))) {
          try {
            // try {
            //   const xx = parsePhoneNumberFromString(propValue, 'US')
            //   console.log('xx', xx)
            // } catch (error) {
            //   console.log('ee', error)
            // }

            let hr = prop_value.replace(' ', '')
            hr = hr.replace('(', '-')
            hr = hr.replace(')', '-')
            hr = 'tel:+1' + hr
            prop_value = <a href={hr}>{prop_value}</a>
            isEmailOrPhone = true
          } catch (error) {
            if (logging && logging.allowLogging && logging.logErrors) { console.log('logging >', error) }
          }
        }

        // email
        if (propHelpers.isEmailProp(propKey) && allowEmailLinks) {
          try {
            const mt = 'mailto:' + prop_value
            prop_value = <a href={mt}>{prop_value}</a>
            isEmailOrPhone = true
          } catch (error) {
            if (logging && logging.allowLogging && logging.logErrors) { console.log('logging >', error) }
          }
        }

        if (dataListType) {
          switch (dataListType) {
            case gEnums.dataListTypes.staticViews:
              if (staticViews && staticViews[staticList] && staticViews[staticList][prop_value]) {
                const staticValue = staticViews[staticList][prop_value]
                if (staticValue && staticValue.name) {
                  prop_value = staticValue.name
                }
              }
              break;

            default:
            // nothing
          }
        }

        if (!isEmailOrPhone && !isOther && !countOf && _.isObject(prop_value) && !is_url) {

          let allowArray = true
          let allowObject = true

          if (_.isArray(prop_value) && hideArrays) { allowArray = false }
          if (_.isObject(prop_value) && hideArrays) { allowObject = false }

          if (allowArray && _.isArray(prop_value)) {
            if (drilled) {
              prop_value = <div>This is a drill down</div>
            } else {
              const objectProps = { componentContexts, logging, viewItem, prop_value, propItem, viewKey, itemData }
              prop_value = <ArrayLabels arrayProps={objectProps} />
            }
          } else if (allowObject) {
            const a = []
            Object.keys(prop_value).forEach(pvKey => {
              a.push(<Label color={'blue'} fluid={true}>{pvKey}</Label>)
            })
            prop_value = a
          } else {
            prop_value = null
          }
        }

        if (showPropMap) {
          prop_value = <Label color={'blue'} icon onClick={e => item_handlers.handleShow_modifyMapping(propKey)}><Icon name={'map'} />{prop_value}</Label>
        }

      } catch (error) {
        console.error(error);
        // nothing 
      }
    }
  }

  return prop_value

}

const globalFileImage = (globalFiles, prop_value) => {
  if (globalFiles) {
    if (prop_value) {
      const pvi = prop_value[0]
      if (globalFiles[pvi]) {
        const fileUrl = globalFiles[pvi].fileUrl
        return <Image centered src={fileUrl} style={{ display: 'block' }} alt={'image'} />
      }
    }
  }
  return prop_value
}

const checkForPhone = (html) => {
  var phoneExp = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/img;
  const pns = html.match(phoneExp)
  if (pns && pns.length > 0) {
    pns.forEach(pn => {
      const pl = phoneLink(pn)
      html = html.replace(pn, pl)
    })
  }
  return html
}

const phoneLink = (pn) => {
  let hr = pn.replace(' ', '')
  hr = hr.replace('(', '-')
  hr = hr.replace(')', '-')
  hr = 'tel:+1' + hr
  return "<a href='" + hr + "'>" + pn + "</a>"
  // return <a href={hr}>{pn}</a>
}

