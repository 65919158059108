import { formatDistance } from 'date-fns';
import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { List, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { appNotificationsViewerHandlers, appNotificationsViewerInitialState, appNotificationsViewerReducer, viewModes } from '../cnr/reducers/AppNotificationViewerReducer';
import { formatItem, formatTypes } from '../common/dateFormatting';
import { uniqueKey } from '../common/keys';
import NoData from '../components/alerts/NoData';
import UiSaveButtons from '../components/buttons/UiSaveButtons';
import DropdownOptionsMenu from '../components/forms/elements/DropdownOptionsMenu';
import { gEnums } from '../enums/globalEnums';
import { fsfn_notifications } from '../functions/fbNotifications';
import UiHeader from '../pageItem/UiHeader';
import FullPageWrapper from '../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import CreateNotificationWizard from './CreateNotificationWizard';
import CreateTopicWizard from './CreateTopicWizard';
import NotificationItemStatusWithProvider from './NotificationItemStatus';
import NotificationList from './NotificationList';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';

const _noteBodyMax = 32

export const AppNotificationsViewerContext = createContext();

export const appNotificationTypes = {
  settings: 'settings',
  notifications: 'notifications',
  userNotifications: 'userNotifications',
  notificationsManager: 'notificationsManager',
}

const AppNotificationsManager = (props) => {

  const { forSettings, appNotificationType, useDarkMode } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, fns } = parentContext ?? {}
  const { appUser_state, appNotifications_state, googleSheets_state, page_state, paps_state, eventInfo_state } = states

  const { appUsers } = appUser_state ?? {}
  const { appNotifications_handlers, navigate } = handlers

  const appUserAccess = getAppUserAccess(appUsers)
  const { isAdminOrSuper } = appUserAccess ?? {}

  const { pathViews } = paps_state
  const { page_fns } = fns

  const { pageSettings, otherView } = page_state ?? {}
  const { aps_styles, aps_appUserSettings } = pageSettings ?? {}

  const { staticViews } = eventInfo_state ?? {}

  // notifications
  const { notifications_app, notifications_appUser } = appNotifications_state

  // googleSheetsContext 
  const { googleSheetsData } = googleSheets_state ?? {}
  const { notifications: notifications_google } = googleSheetsData ?? {}
  const _notifications = notifications_google ? notifications_google : notifications_app

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { frameworkRightType, frameworkLeftType } = framework_state ?? {}
  const inFramework = frameworkRightType || frameworkLeftType

  // appNotificationsViewer
  const initState = appNotificationsViewerInitialState({ pathViews, page_fns, staticViews, aps_appUserSettings })
  const [appNotificationsViewer_state, dispatch] = useReducer(appNotificationsViewerReducer, initState);
  const appNotificationsViewer_handlers = appNotificationsViewerHandlers(dispatch)

  const { viewMode, activeNotificationKey, timedNotificationGroupsKeys, timedNotificationGroups } = appNotificationsViewer_state ?? {}
  const { handleInit_notifications, handleSetViewMode } = appNotificationsViewer_handlers ?? {}

  const [currentNotification, setCurrentNotification] = useState()

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.actionButton] : {}
  styleAndClass.className += ' single'

  const handleUserNotificationClick = (sn) => {
    appNotifications_handlers.handleMarkAsSeen(sn)
    if (currentNotification && currentNotification._itemKey === sn._itemKey) {
      setCurrentNotification()
    } else {
      setCurrentNotification(sn)
    }
  }

  const handleCancel = () => props.handleCancel ? props.handleCancel() : navigate(-1)

  useEffect(() => {
    handleInit_notifications(_notifications)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_notifications]);

  useEffect(() => {
    switch (appNotificationType) {
      case appNotificationTypes.userNotifications:
        handleSetViewMode(viewModes.user)
        break;
      case appNotificationTypes.notifications:
        handleSetViewMode(viewModes.list)
        break;
      case appNotificationTypes.notificationsManager:
        handleSetViewMode(viewModes.normal)
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowNormal = () => handleSetViewMode(viewModes.normal)
  const handleShowEdit = () => handleSetViewMode(viewModes.edit)
  const handleTest = () => fsfn_notifications.checkNotification_direct(true)
  const handleTriggerSchedule = () => fsfn_notifications.checkNotification_direct()
  const handleUpdateActive = () => appNotificationsViewer_handlers.handleNotificationActive()
  const handleUpdateSend = () => appNotificationsViewer_handlers.handleNotificationSend()
  const handleRemove = () => appNotifications_handlers.handleRemoveNotification(activeNotificationKey, handleShowNormal)

  const handleAddNormalClick = () => {
    if (otherView === 'add') {
      navigate(-1)
    } else {
      handleSetViewMode(viewMode === viewModes.addNormal ? viewModes.normal : viewModes.addNormal)
    }
  }

  const handleAddNormalDirect = () => {
    if (otherView === 'add') {
      navigate(-1)
    } else {
      handleSetViewMode(viewMode === viewModes.addDirect ? viewModes.normal : viewModes.addDirect)
    }
  }

  const header = () => {
    if (inFramework) {
      return <UiHeader caption={'Notifications Manager'} />
    } else {
      const icon1 = { handleOnClick: handleAddNormalClick }
      const icon2 = { handleOnClick: handleAddNormalDirect, icon: 'calendar outline' }
      return <UiHeader caption={'Notifications List'} icons={[icon1, icon2]} />
    }
  }

  const buttons = (note) => {
    const { active, sent } = note ?? {}
    const btns = [
      { caption: active ? 'Active' : 'Activate', icon: active ? 'check' : 'arrow right', oc: handleUpdateActive, color: active ? 'green' : 'grey' },
      { caption: sent ? 'Sent' : 'Send', icon: sent ? 'check' : 'arrow right', oc: handleUpdateSend, color: sent ? 'green' : 'grey' },
      { caption: 'Edit', icon: 'edit', oc: handleShowEdit, color: 'blue', fl: true },
    ]
    return <UiSaveButtons
      clickOptions={note}
      others={btns}
    />
  }

  const wrapper_confirmation = (note) => <Wrapper
    key={uniqueKey('apn', 'cw')}
    content={<NotificationItemStatusWithProvider notification={note} />}
    footer={buttons(note)}
    wrapperType={wrapperTypes.padded}
  />

  const fpw_preview = (note) => <FullPageWrapper
    topperCaption={'Notification ' + _.startCase(viewMode)}
    topperCaption2={activeNotificationKey}
    content={wrapper_confirmation(note)}
    handleCancel={handleShowNormal}
    isBack={true}
  />

  const deleteWrapper = () => <Wrapper
    key={uniqueKey('apn', 'dw')}
    header={'Comfirm'}
    content={'Are you sure you want to delete this item?'}
    footer={
      <UiSaveButtons
        delete={{ oc: handleShowNormal, caption: 'Close' }}
        save={{ oc: handleRemove, caption: 'Confirm' }}
      />
    }
    wrapperType={wrapperTypes.padded}
  />

  /**
   * 
   * @param {string} type 
   * @param {object} notifications_date 
   * @returns the NotificationList
   */
  const notificationList = (type, notifications_date) => <NotificationList
    type={type}
    notifications_date={notifications_date}
  />

  const dateGroup = (ngk) => {
    if (timedNotificationGroups && timedNotificationGroups[ngk]) {
      const ng = timedNotificationGroups[ngk]
      return notificationList(gEnums.notificationScheduleTypes.scheduled, ng)
    }
  }

  const notificationDateGroups = (type) => {
    const groups = []
    timedNotificationGroupsKeys.forEach((ngk, index) => {
      groups.push(<div key={uniqueKey('apn', 'dg', index)} className={'app-notification-date-group'}>
        <div key={uniqueKey('apn', 'f', index)} >{formatItem(formatTypes.shortDate, ngk)}</div>
        <div key={uniqueKey('apn', 'd', index)} >{dateGroup(ngk)}</div>
      </div>)
    })
    return groups
  }

  const noteItem = (aun, index, full) => {
    const { title, body, read, sendDateUTC } = aun ?? {}
    const yyy = sendDateUTC ? formatDistance(new Date(sendDateUTC), new Date(), { addSuffix: true }) : null
    const _body = full ? body : body.substring(0, _noteBodyMax) + '...'
    return <List.Item key={uniqueKey('apn', 'sg', index)} onClick={(e) => handleUserNotificationClick(aun)} >
      {/* {yyy && <List.Content key={uniqueKey('apn', 'sg', 'lc1', index)} floated='right'>{yyy}</List.Content>} */}
      <List.Icon
        name={read ? 'check circle' : 'circle outline'}
        color={read ? 'green' : 'grey'}
        size='large'
        verticalAlign='middle'
      />
      <List.Content key={uniqueKey('apn', 'sg', 'lc2', index)}>
        <List.Header>{title}<div style={{ float: 'right', fontWeight: 'normal' }}>{yyy}</div></List.Header>
        <List.Description>{_body}</List.Description>
      </List.Content>
    </List.Item>
  }

  const noteItems_appUser = () => {
    const ntis = []
    const _notes = notifications_appUser.all
    if (_notes && _notes.length > 0) {
      const _notes_s = _.orderBy(_notes, 'sendDateUTC', 'desc')
      _notes_s.forEach((aun, index) => {
        if (currentNotification) {
          if (currentNotification._itemKey === aun._itemKey) {
            ntis.push(noteItem(aun, index, true))
          }
        } else {
          ntis.push(noteItem(aun, index))
        }
      })
    }
    return ntis
  }

  const notificationsList_appUser = () => <Segment basic>
    <List divided relaxed>
      {noteItems_appUser()}
    </List>
  </Segment>

  const notificationSeg = (type, content) => <div className={'app-notification-group'}>
    <div>{content}</div>
  </div>

  const notification_list = () => {
    if (timedNotificationGroupsKeys && timedNotificationGroupsKeys.length > 0) {
      return notificationSeg(gEnums.notificationScheduleTypes.scheduled, notificationDateGroups(gEnums.notificationScheduleTypes.scheduled))
    } else {
      return <NoData altCaption={_.startCase(gEnums.notificationScheduleTypes.scheduled)} />
    }
  }

  const noteButtons = () => {
    const btns = [{ oc: handleAddNormalClick, icon: 'add', caption: 'Add' }]
    const btns_dd = []
    if (isAdminOrSuper) {
      btns_dd.push({ oc: handleTest, icon: 'wizard', caption: 'Test', fl: true })
      if (timedNotificationGroupsKeys && timedNotificationGroupsKeys.length > 0) {
        btns_dd.push({ oc: handleTriggerSchedule, icon: 'arrow right', caption: 'Trigger Scheduled', fl: true })
      }
    }

    return <div className={'header-flex'}>
      <div>
        <DropdownOptionsMenu menuButtons={btns_dd} caption={'Options'} icon={'unordered list'} color={'blue'} direction={'right'} upward={true} />
      </div>
      <div>
        <UiSaveButtons
          others={btns}
          color={'blue'}
        />
      </div>
    </div>
  }

  const wrapper = () => <Wrapper
    key={uniqueKey('apn', 'wrap')}
    header={forSettings || inFramework && header()}
    content={notification_list()}
    footer={noteButtons()}
    wrapperType={wrapperTypes.paddedHeader}
  />

  const fpw_notifications = (content) =>
    forSettings
      ?
      <Segment key={uniqueKey('apn', 'wiz')} className={'app-notification-container'} inverted={useDarkMode}>{content}</Segment>
      :
      <FullPageWrapper
        topperCaption={'Notifications'}
        topperCaption2={_.startCase(appNotificationType)}
        content={content}
        handleCancel={handleCancel}
      />

  const notificationsContent = () => {
    switch (viewMode) {
      case viewModes.normal:
        return fpw_notifications(wrapper())
      case viewModes.list:
        return fpw_notifications(notifications_appUser.all && notifications_appUser.all.length > 0 ? notificationsList_appUser() : <NoData fullCaption={'No Notifications'} />)
      case viewModes.user:
        return notifications_appUser.all && notifications_appUser.all.length > 0 ? notificationsList_appUser() : <NoData fullCaption={'No Notifications'} />
      case viewModes.preview:
        return fpw_preview(_notifications[activeNotificationKey])
      case viewModes.edit:
        return <CreateNotificationWizard isSub={true} notificationOriginType={gEnums.notificationOriginTypes.appNotification} handleClose={handleShowNormal} activeNote={_notifications[activeNotificationKey]} />
      case viewModes.remove:
        return fpw_notifications(deleteWrapper())
      case viewModes.addDirect:
      case viewModes.addNormal:
        return <CreateNotificationWizard isSub={true} notificationOriginType={gEnums.notificationOriginTypes.appNotification} activeNote={_notifications[activeNotificationKey]} />
      case viewModes.addTopic:
        return <CreateTopicWizard handleCancel={handleShowNormal} topicOriginType={gEnums.topicOriginTypes.global} />
      default:
        return <FullPageWrapper
          topperCaption={'Notifications'}
          topperCaption2={_.startCase(appNotificationType)}
          content={<NoData altCaption={'Notifications'}></NoData>}
          handleCancel={handleCancel}
        />
    }
  }

  return <AppNotificationsViewerContext.Provider value={{ appNotificationsViewer_state, appNotificationsViewer_handlers }}>
    {notificationsContent()}
  </AppNotificationsViewerContext.Provider>

}

export default AppNotificationsManager