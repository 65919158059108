import { getISOWeek, getYear } from 'date-fns';
import _ from 'lodash';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from '../../../../global/cnr/reducers/reducerHelpers/dispatchProps';
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { fs_db } from '../../../../global/firestoreData/appData/fsAppData';
import { fs_dbu } from '../../../../global/firestoreData/appData/fsAppDataUpdate';

const rts = {
  handleInit_lab: 'handleInit_lab',
  handleSet_lab: 'handleSet_lab',
  handleSet_weeklyLabSamples: 'handleSet_weeklyLabSamples',
  handleCreate_weeklyLabRun: 'handleCreate_weeklyLabRun',
  handleUpdate_weeklyLabRun: 'handleUpdate_weeklyLabRun',
  handleResponse_update: 'handleResponse_update',
  ...grts
}

const _labColletions = ['labCustomers', 'runGroups', 'runs', 'labMasters', 'labRuns']
const _weeklyLabSamplesCollection = '_weeklyLabSamples'

export const labReducer = (state, action) => {

  const { pathViews } = state
  const { dispatch, type } = action

  const { handleSet_lab, handleSet_weeklyLabSamples, handleResponse_update } = labHandlers(dispatch)

  switch (type) {
    case rts.handleInit_lab:
      fetchData(pathViews, _labColletions, handleSet_lab)
      fetchWeeklyLabSamples(pathViews, handleSet_weeklyLabSamples)
      return { ...state }

    case rts.handleSet_lab:
      return { ...state, ...action.combinedData, currentYear: getCurrentYear(), currentWeek: getCurrentWeek() }

    case rts.handleSet_weeklyLabSamples:
      if (state.runs) {
        const labSamples_week = action.labSamples[state.currentWeek]
        const _runs = {}
        Object.keys(state.runs).forEach(runKey => {
          const run = state.runs[runKey]
          _runs[runKey] = {
            name: run.name,
            ws: {}
          }
          if (labSamples_week) {
            Object.keys(labSamples_week).forEach(weekKey => {
              const weeklySamples = labSamples_week[weekKey]
              const groupedData = _.filter(weeklySamples, (ws) => ws.runs && ws.runs.includes(runKey));
              _runs[runKey].ws = {
                [weekKey]: groupedData
              }
            })
          }
        })
      }

      return { ...state, weeklyLabSamples: action.labSamples, currentLabSamples: action.labSamples ? action.labSamples[state.currentWeek] : null }

    case rts.handleCreate_weeklyLabRun:
      const { weeklyLabRuns, weeklyLabRuns_grouped } = createWeeklyLabRun(state.labMasters)
      return { ...state, weeklyLabRuns, weeklyLabRuns_grouped }

    case rts.handleUpdate_weeklyLabRun:
      updateData(pathViews, state.weeklyLabRuns, handleResponse_update)
      return { ...state }

    case rts.handleResponse_update:
      return { ...state, updating: false }

    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const labHandlers = (dispatch) => {
  return {
    handleInit_lab: (pathViews) => { dispatch({ type: rts.handleInit_lab, dispatch, pathViews }) },
    handleSet_lab: (combinedData) => { dispatch({ type: rts.handleSet_lab, dispatch, combinedData }) },
    handleSet_weeklyLabSamples: (labSamples) => { dispatch({ type: rts.handleSet_weeklyLabSamples, dispatch, labSamples }) },
    handleCreate_weeklyLabRun: () => { dispatch({ type: rts.handleCreate_weeklyLabRun, dispatch }) },
    handleUpdate_weeklyLabRun: () => { dispatch({ type: rts.handleUpdate_weeklyLabRun }) },
    handleResponse_update: () => { dispatch({ type: rts.handleResponse_update }) },
    ...responseHandlers(dispatch)
  }
}
export const labInitialState = (init_state) => {
  return { ...init_state }
};

const updateData = async (pathViews, weeklyLabRuns, callback) => {

  const currentYear = getCurrentYear()
  const currentWeek = getCurrentWeek()

  const dataToUpdate = {
    [currentWeek]: {
      weeklyLabRuns,
    }
  }

  const _refPath = createRefPath_event(pathViews, [_weeklyLabSamplesCollection, currentYear, 'weeks', currentWeek]);
  console.log('updateData', _refPath, dataToUpdate)
  fs_dbu.update_doc(_refPath, weeklyLabRuns, callback)

}

const fetchData = async (pathViews, collectionList, callback) => {
  const promises = collectionList.map(async (collectionName) => {
    const refPath = createRefPath_event(pathViews, [collectionName]);
    const data = await fs_db.get_collectionet_collection(refPath)
    return { [collectionName]: data };
  });

  try {
    // Wait for all promises to resolve using Promise.all()
    const res = await Promise.all(promises);
    // Combine results into a single object or array as needed
    const combinedData = res.reduce((acc, result) => ({ ...acc, ...result }), {});
    callback(combinedData)
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

const fetchWeeklyLabSamples = async (pathViews, callback) => {

  const currentYear = getCurrentYear()
  const currentWeek = getCurrentWeek()

  const refPath = createRefPath_event(pathViews, [_weeklyLabSamplesCollection, currentYear, 'weeks', currentWeek]);
  fs_db.get_data({ refPath, callback, opts: { ignoreId: true } })

}

const getCurrentYear = () => {
  const currentDate = new Date();
  const year = getYear(currentDate);
  return year;
};

const getCurrentWeek = () => {
  const currentDate = new Date();
  const weekNumber = getISOWeek(currentDate);
  return 'week_' + weekNumber;
};

const createWeeklyLabRun = (labMasters) => {

  const weeklyLabRuns = {}

  Object.keys(labMasters).forEach(lmKey => {
    const labMaster = labMasters[lmKey]
    weeklyLabRuns[lmKey] = {
      name: labMaster.name,
      runs: labMaster.runs,
    }
  })

  const weeklyLabRuns_grouped = _.groupBy(weeklyLabRuns, (obj) => obj.runs[0]);

  return { weeklyLabRuns, weeklyLabRuns_grouped }

}