import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { topicHandlers, topicInitialState, topicReducer } from '../reducers/TopicReducer';
import { ParentContext } from './ParentContext';

/** AppContext */
export const TopicContext = createContext();

/** Global OtherDatas for the Event */
const TopicProvider = (props) => {

  const { profileType, selectedItems } = props ?? {}

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { paps_state } = states
  const { database_fns } = fns
  const { pathViews } = paps_state ?? {}

  const initState = topicInitialState({ pathViews, profileType })
  const [topic_state, topic_dispatch] = useReducer(topicReducer, initState);
  const topic_handlers = topicHandlers(topic_dispatch, topic_state)

  useEffect(() => {
    const cb = (data) => topic_handlers.handleSet_dbTopics(data)
    const _refPath = createRefPath_event(pathViews, ['_topics'])
    database_fns.get_data({ refPath: _refPath, callback: cb })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedItems) {
      const _emails = []
      Object.keys(selectedItems).forEach(key => {
        if (selectedItems[key].email) {
          _emails.push(selectedItems[key].email)
        }
      })
      topic_handlers.handleEmails(_emails)

      if (selectedItems) {
        const _selectedItems = []
        Object.keys(selectedItems).forEach(key => {
          if (selectedItems[key].fcmTokens) {
            _selectedItems.push(selectedItems[key])
          }
        })
        topic_handlers.handleProfiles(_selectedItems)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const providerContext = () => <TopicContext.Provider
    value={{ topic_state, topic_handlers }}>
    {props.children}
  </TopicContext.Provider>

  return providerContext()

}

export default TopicProvider