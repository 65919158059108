import _ from 'lodash';
import { createRefPath_client, createRefPath } from '../appData/appRefPaths';
import { fs_dbu } from '../appData/fsAppDataUpdate';

export const updateFavorites = (dispatch, appUser, paps_state, swipedItem) => {

  const { pathViews } = paps_state ?? {}
  const { profileData, email } = appUser ?? {}
  const favs = appUser.settingsFavs ? appUser.settingsFavs : []
  const fav = swipedItem.key

  if (!_.includes(favs, fav)) {
    favs.push(fav)
  } else {
    _.remove(favs, function (n) {
      return n === fav;
    });
  }

  let _refPath;

  if (profileData) {
    _refPath = createRefPath(['profiles', profileData.id])
  } else if (email) {
    _refPath = createRefPath_client(pathViews, ['profiles', email])
  }

  const d = { settingsFavs: favs }
  fs_dbu.update_doc(_refPath, d, dispatch)
}