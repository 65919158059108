import React, { useContext } from 'react';
import { Card } from 'semantic-ui-react';
import { getAppUserLink } from '../../../auth/accessPermissions';
import { getAppUserAccess } from '../../../auth/appUserAccessPermissions';
import { ElementContext } from '../../../cnr/contexts/ElementContext';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { uniqueKey } from '../../../common/keys';
import { gEnums } from '../../../enums/globalEnums';
import { _animateTypes } from '../../../motions/AnimateComponent';
import MotionComponent from '../../../motions/MotionComponent';
import { groupTaggedPropSectionElements } from '../../../viewSettings/helpers/settingsHelpers';
import UiImage from '../../imaging/UiImage';
import StarRating from '../../ratings/StarRating';
import BusScheduleLabels from './BusScheduleLabels';
import CardLink from './CardLink';
import DateTimeLabels from './DateTimeLabels';
import StatusLabels from './StatusLabels';
import TimeLabels from './TimeLabels';
import CardExtra from '../CardExtra';

const CardSchedule = () => {

  const _cds = gEnums.cardDisplayTypes

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { appUser_state, eventInfo_state, page_state, rating_state, transition_state } = states
  const { appUser_fns } = fns ?? {}
  const { appUsers } = appUser_state ?? {}
  const { staticViews, staticViews_gs } = eventInfo_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems, aps_appUserSettings } = pageSettings ?? {}
  const { appUserCollection } = aps_appUserSettings ?? {}
  const { appUserPermissions, appRating } = aps_global ?? {}
  const { links } = appUserPermissions ?? {}
  const { ratings } = rating_state ?? {}

  // appUserAccess
  const appUserAccess = getAppUserAccess(appUsers)
  const { appUserSession, appUserSessionKey } = appUserAccess ?? {}

  // transitionContext  
  const { transitions } = transition_state ?? {}
  const transition = transitions ? transitions[_animateTypes.pageItemElement] : null
  const { showTransition } = transition ?? {}

  // uiItemContext 
  const uiItemContext = useContext(UiItemContext)
  const { item_state, item_handlers } = uiItemContext ?? {}
  const { viewItem, _display, propSections_allowed, propGroups, _imageOpts, _viewItemOpts } = item_state ?? {}
  const { handleShow_modifyMapping } = item_handlers ?? {}
  const { cardDisplayType } = _display ?? {}
  const { showExtra, extraDisplayType, showExtraInternal } = _viewItemOpts ?? {}
  const { key: key_viewItem } = viewItem
  const { showItemImage, imageLocation: profileImageLocation, imageSize: profileImageSize } = _imageOpts ?? {}

  // elementContext
  const elementContext = useContext(ElementContext)
  const { element_state } = elementContext ?? {}
  const { itemData, elemProps, clickTo } = element_state ?? {}
  const { handleClick, cardProps } = elemProps ?? {}
  const { scheduleType } = cardProps ?? {}
  const { _itemKey } = itemData ?? {}

  const appUserLink = viewItem && links && getAppUserLink(appUserCollection, links, itemData, viewItem, appUserSession)

  const _staticViews = staticViews_gs ? staticViews_gs : staticViews
  const currentRating = ratings && ratings[_itemKey]

  const rating_access = appUser_fns.validateAccess_rating(currentRating, appRating, key_viewItem)
  const { allowAppUserView } = rating_access ?? {}

  let imageInContent = true

  switch (profileImageLocation) {
    case gEnums.imageLocations.top:
      imageInContent = false
      break;
    default:
    // nothing
  }

  const handleItemClick = () => handleClick(viewItem, itemData._itemKey, null, { itemData: itemData, clickTo })

  const getCn = () => {
    let _cn = 'card-schedule'
    let _showFullImage = false

    switch (cardDisplayType) {
      case _cds.profilePlus:
      case _cds.schedulePlus:
        _cn += ' plus'
        _showFullImage = true
        break;
      case _cds.event:
        if (itemData && itemData.eventStatusType) {
          _cn += ' ' + itemData.eventStatusType
        }
        break;
      default:
      // nothing
    }

    return { cn: _cn, showFullImage: _showFullImage }
  }

  const { cn, showFullImage } = getCn()

  const cardLink = () => <CardLink
    appUserLink={appUserLink}
    itemData={itemData}
  />

  const cardImage_top = () => <UiImage
    viewItem={viewItem}
    itemData={itemData}
    showFullImage={showFullImage}
  />

  const cardImage_inContent = () => <UiImage
    floated={profileImageLocation ? profileImageLocation : 'left'}
    size={profileImageSize ? profileImageSize : 'small'}
    viewItem={viewItem}
    itemData={itemData}
    fullImage={showFullImage}
  />

  const labels = (_keyy) => {
    switch (cardDisplayType) {
      case _cds.busSchedule:
        return <BusScheduleLabels itemData={itemData} />
      case _cds.event:
        return <StatusLabels itemData={itemData} />
      default:
        switch (scheduleType) {
          case 'list':
            return <TimeLabels _keyy={_keyy} itemData={itemData} propGroups={propGroups} />
          default:
            return <DateTimeLabels _keyy={_keyy} itemData={itemData} propGroups={propGroups} />
        }
    }
  }

  const card_extra = (_itemKey) => <CardExtra extraDisplayType={extraDisplayType} _itemKey={_itemKey} />

  const card_schedule = (contentOnly) => {

    const _keyy = uniqueKey('crds', 'sc', key_viewItem, itemData.id)

    const ignoreProps = ['startTime', 'startDate', 'endDate', 'endTime', 'locations']

    const { nameElements, metaElements } = groupTaggedPropSectionElements({
      _keyy,
      appUserSession,
      aps_global,
      aps_viewItem: aps_viewItems && aps_viewItems[key_viewItem],
      display: _display,
      ignoreProps,
      itemData,
      propGroups,
      propsSections: propSections_allowed,
      staticViews: _staticViews,
      viewItemKey: key_viewItem,
      handleShow_modifyMapping
    })

    const cardContent = () => <React.Fragment>
      {showItemImage && !imageInContent && cardImage_top()}
      {scheduleType === 'list' && labels(_keyy)}
      <Card.Content>
        {showItemImage && imageInContent && cardImage_inContent()}
        <Card.Header key={uniqueKey('cs', 'h', _keyy)} as={'h5'}>{nameElements}</Card.Header>
        <Card.Meta key={uniqueKey('cs', 'm', _keyy)}>{metaElements}</Card.Meta>

        {allowAppUserView &&
          <Card.Meta key={uniqueKey('cs', 'sm', _keyy)}>
            <StarRating key={uniqueKey('ss', 'sr', _keyy)} allowRating={true} itemData={itemData} />
          </Card.Meta>
        }
      </Card.Content>
      {scheduleType !== 'list' && labels(_keyy)}
      {appUserLink && appUserSessionKey && cardLink()}
      {showExtra && extraDisplayType && showExtraInternal && card_extra(_itemKey)}
    </React.Fragment>

    return contentOnly ?
      cardContent()
      :
      <Card fluid key={_keyy} className={cn} as='div' onClick={() => { handleItemClick() }}>
        {cardContent()}
      </Card>
  }

  const _showTransition = showTransition && cardDisplayType.indexOf('Plus') < 0

  if (_showTransition) {
    return <MotionComponent
      key={uniqueKey('mv', 'sep', key_viewItem)}
      transition={transition}
      oc={handleItemClick}
    >
      {card_schedule()}
    </MotionComponent>
  } else {
    return card_schedule()
  }

}

export default CardSchedule