import { allPageTypes } from '../../../enums/allPageTypes';
import { gEnums } from '../../../enums/globalEnums';
import { getThemeColorDirect } from '../../../styles/formatting';
import { gridItemIconGroup } from './SegIconGroup';

export const allowedItemsAppUser = (props) => {

  const { parentContext, pageIcons, gcProps, allows, _appUserSessionKey } = props ?? {}

  const { states } = parentContext ?? {}
  const { appNotifications_state, page_state, survey_state, questionsAndAnswers_state } = states ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings } = pageSettings ?? {}
  const { appMeetings, appNotifications, appSurvey, appVoting, appQuestionsAndAnswers } = aps_global ?? {}
  const { allowNotifications, showNotificationsOnHomeScreen, notificationsHomeScreenCaption, showNotificationsCount, notificationLabelColor } = appNotifications ?? {}
  const { allowChat, showChatOnHomeScreen, allowNotes, showNotesOnHomeScreen, showFavoritesOnHomeScreen } = aps_appUserSettings ?? {}
  const { allowVoting, showVotingOnHomeScreen, votingHomeScreenCaption } = appVoting ?? {}
  const { allowSurvey, surveyIsActive: surveyIsActive_global, surveyHomeScreenCaption, surveyUrl } = appSurvey ? appSurvey : {}
  const { allowMeetings, showMeetingsManagerOnHomeScreen, appAdminOnly } = appMeetings ?? {}
  const { allowQuestionsAndAnswers, showOnLandingPage, landingPageLocation, qAndACaption } = appQuestionsAndAnswers ?? {}

  const { notifications_appUser } = appNotifications_state ?? {}
  const { unread: notificatiions_unread, hasNew } = notifications_appUser ?? {}

  const { questionsAndAnswersActive } = questionsAndAnswers_state ?? {}

  // surveyContext  
  const { activeSurvey } = survey_state ?? {}
  const { surveyIsActive } = activeSurvey ?? {}

  const _surveyIsActive = surveyIsActive_global || surveyIsActive

  let _allowSurvey;

  let allow = !pageIcons && allowSurvey && _surveyIsActive && surveyUrl
  if (allow && allows.asr) { _allowSurvey = true }

  let _allowMeetings;
  let allowMtg = !pageIcons && allowMeetings && showMeetingsManagerOnHomeScreen
  if (allowMtg && allows.asr && !appAdminOnly) { _allowMeetings = true }

  let _allowQandA;
  let allowQandA = !pageIcons && questionsAndAnswersActive && questionsAndAnswersActive.length > 0
  _allowQandA = allowQuestionsAndAnswers && showOnLandingPage && allowQandA && landingPageLocation === gEnums.landingPageLocations.appUser

  const notificationProps = {
    text: showNotificationsCount && hasNew && notificatiions_unread && notificatiions_unread.length > 0 ? notificatiions_unread.length : null,
    color: notificationLabelColor ? getThemeColorDirect(aps_global, notificationLabelColor) : null
  }

  const allowedItem = (viewItem, segProps, gcProps, hidden) => {
    const { key } = viewItem ?? {}
    return { viewItem, itemKey: key, segProps, gcProps, hidden }
  }

  const favoritesGridItem = (items) => {
    const giProps = {
      caption: 'Favorites',
      icon: 'star outline',
    }
    items.push(allowedItem({ key: 'favorites', itemKey: _appUserSessionKey }, giProps, gcProps))
  }

  const items = []

  if (!pageIcons) {
    if (allowNotifications && showNotificationsOnHomeScreen) { items.push(allowedItem({ key: allPageTypes.notifications }, { caption: notificationsHomeScreenCaption ? notificationsHomeScreenCaption : 'Your Notifications', icon: 'alarm', notification: notificationProps.text ? notificationProps : null }, gcProps)) }
    if (allowChat && showChatOnHomeScreen) { items.push(allowedItem({ key: allPageTypes.chat }, { caption: 'Chat', icon: 'conversation', }, gcProps)) }
    if (showFavoritesOnHomeScreen) { favoritesGridItem(items) }
    if (_allowMeetings) { items.push(allowedItem({ key: allPageTypes.meetingsManager }, { caption: 'Meetings', icon: 'clipboard check', cn: 'ao' }, gcProps)) }
    if (allowNotes && showNotesOnHomeScreen) { items.push(allowedItem({ key: allPageTypes.notes }, { caption: 'Notes', icon: 'edit outline' }, gcProps)) }
    if (_allowQandA) { items.push(allowedItem({ key: allPageTypes.qAndA }, { caption: qAndACaption ? qAndACaption : 'Q&A', icon: 'question circle' }, gcProps)) }
    if (_allowSurvey) { items.push(allowedItem({ key: allPageTypes.survey }, { caption: surveyHomeScreenCaption ? surveyHomeScreenCaption : 'Survey', icon: 'list ol' }, gcProps)) }
    if (allowVoting && showVotingOnHomeScreen) { items.push(allowedItem({ key: allPageTypes.voting }, { caption: votingHomeScreenCaption ? votingHomeScreenCaption : 'Voting', icon: 'check circle outline' }, gcProps)) }
  }

  return items

}