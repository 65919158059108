import { createRefPath } from "../appData/appRefPaths"
import { fs_dbu } from "../appData/fsAppDataUpdate"

/** Returns an array of promises from the selectedDataItems */
export const deletePromise = async (refPath, vit, data) => {

  const promises = []

  Object.keys(data).forEach(itemKey => {
    const _refPath = createRefPath([vit, itemKey], refPath)
    const r = fs_dbu.doc_delete(_refPath)
    promises.push(r)
  })

  return Promise.all(promises)

}