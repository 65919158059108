import { _developerAdminEmail } from '../global/common/keys';
import { fbAppConfigs, googleMapConfigs } from './appConfigs';
import { appKeys } from './appKeys';
import _ from 'lodash'
// azure
// 2A2A3DC54E98EFFB2C7955B8611BDA175EE5B4D37F6F98DE1BE52F621CA5402B
// 40.117.154.240
// 13.82.175.96


const _hostNames = {
  pojo: ['pojo-1fdf3.web.app', 'thumbstat.com'],
  fcasd_ft: ['fcasd-field-trip.web.app'],
  meetingevolution: ['mobile.events.meetingevolution.net', 'mobiledev.events.meetingevolution.net', 'me-mobile-4410b.web.app', 'localhost:3000'],
}

const _envNames = {
  meetingevolution: ['mobile.events.meetingevolution.net'],
}

export const _projectAppNames = {
  meetingevolution: 'meetingevolution',
  pojo: 'pojo',
  thumbstat: 'thumbstat',
  thumbstat2: 'thumbstat2',
  cardnudge: 'cardnudge',
  fcasd: 'fcasd',
  fcasd_ft: 'fcasd_ft',
}

export const _projectAppNamesNonDev = {
  meetingevolution: 'meetingevolution',
}

export const _appUrlPaths = {
  dev: 'https://mobiledev.events.meetingevolution.net/#/',
  local: 'http://localhost:3000/#/',
  prod: 'https://mobile.events.meetingevolution.net/#/',
}
// https://thumbstat.com/#/clients/thumbstat/events/thumbstatSports/organizations/BjY3VqQ3lUBQ9PXDyXCl/districts/qJOYke2iCxxT8RppMcsc/sports/mensVolleyball
// clients/thumbstat/events/thumbstatSports/organizations/BjY3VqQ3lUBQ9PXDyXCl/districts/qJOYke2iCxxT8RppMcsc
// Camp 1 - Day (grades 9-12)
// Camp 1 - Day (grades 9-12), Camp 2 - Day (grades 9-12) (Possible)

// THIS IS THE CURRENT APP IN LOCALHOST!!!
// export const _currentLocalHostAppName = _projectAppNames.pojo
export const _useMeDb = true
export const _useMeRtDb = false

// export const _currentLocalHostAppName = _projectAppNames.fcasd 
// export const _currentLocalHostAppName = _projectAppNames.fcasd_ft
export const _currentLocalHostAppName = _projectAppNames.meetingevolution
export const _useProdDb = true

const forceProd = false

export const envTypes = {
  development: 'development',
  production: 'production'
}
// =IMPORTRANGE("https://docs.google.com/spreadsheets/d/1l8E-hgFW4xCN-TAWSeQvH2g-o7k3auSiyhd3kaTFvj8/edit", "_schools")

export const getAppConfigSettings = () => {
  return {
    allowAppSportsDataExport: allowAppSportsDataExport(),
    allowAppStorageAndFunctions: allowAppStorageAndFunctions(),
    appEnvironment: getEnvironment(),
    appIcon: getAppIcon(),
    appKeys: allAppKeys(),
    appNames: getAppName(),
    appTitle: getAppTitle(),
    googleMapsConfig: getGoogleMapsConfig(),
    isDev_me: isDev_me(),
    isLocalhost: isLocalhost(),
    isMeProd: isMeProd(),
  }
}

const isDev_me = () => {
  if (document.location.host.indexOf('mobiledev') >= 0 ||
    document.location.host.indexOf('me-mobile-4410b') >= 0) {
    return true
  } else {
    return false
  }
}
/**
 * 
 * @returns true if process.env.NODE_ENV = envTypes.production
 */
export const isMeProd = () => {
  // return true
  switch (process.env.NODE_ENV) {
    case envTypes.production:
      if (isDev_me()) {
        return false
      } else if (document.location.host.indexOf('mobile') >= 0) {
        return true
      } else {
        return false
      }
    default:
      return false
  }
}

export const isLocalhost = () => {
  if (document.location.host.indexOf('localhost') >= 0) {
    return true
  }
}

export const allAppKeys = () => {
  switch (process.env.NODE_ENV) {
    case envTypes.production:
      if (isDev_me()) {
        return appKeys.meetingevolution.dev
      } else if (document.location.host.indexOf('mobile') >= 0) {
        return _useProdDb ? appKeys.meetingevolution.prod : appKeys.meetingevolution.dev
      } else {
        return appKeys.meetingevolution.dev
      }
    default:
      return appKeys.meetingevolution.dev
  }
}

/**
 * 
 * @returns the firebase configuration based on the current project
 */
export const getFbConfig = (useMe) => {
  const { appName } = getAppName(useMe)
  const appConfigs = fbAppConfigs[appName] ? fbAppConfigs[appName] : {}
  const env = getEnvironment()
  const _ac = appConfigs[env]
  return _ac
}

export const getGoogleMapsConfig = () => {
  const { appName } = getAppName()
  const env = getEnvironment()
  const _googleConfigs = googleMapConfigs[appName] ? googleMapConfigs[appName] : {}
  const _gc = _googleConfigs[env]
  return _gc

}

export const getAppName = (useMe) => {

  const { location } = document
  const { host } = location ? location : ''

  const isLocal = isLocalhost()

  let appName;
  let appNameSc;
  let appIcon;
  let appEnv = envTypes.development;

  if (isLocal) {
    appName = _currentLocalHostAppName ?? {}
  } else {
    _.forEach(_hostNames, (hostNames, key) => {
      if (hostNames.includes(host)) {
        appName = _projectAppNames[key]
      }
    })
    _.forEach(_envNames, (envNames, key) => {
      if (envNames.includes(host)) {
        appEnv = envTypes.production
      }
    })
  }

  if (useMe) {
    appName = _projectAppNames.meetingevolution
  }

  switch (appName) {
    case _projectAppNames.meetingevolution:
      appNameSc = 'Meeting Evolution'
      appIcon = 'info'
      break;
    case _projectAppNames.pojo:
      appNameSc = 'Pojo'
      appIcon = 'thumbs up outline'
      break;
    case _projectAppNames.thumbstat:
    case _projectAppNames.thumbstat2:
      appNameSc = 'Thumbstat'
      appIcon = 'thumbs up outline'
      break;
    case _projectAppNames.fcasd_ft:
      appNameSc = 'FSASD'
      appIcon = 'thumbs up outline'
      break;
    default:
    // nothing
  }

  return { appName, appNameSc, appIcon, appEnv, isLocal }

}

export const allowAppStorageAndFunctions = () => {
  const c = getFbConfig()
  const { projectId } = c ?? {}
  switch (projectId) {
    case 'me-mobile-4410b':
    case 'me-production-57ad6':
      return true
    default:
      return false
  }
}

export const allowAppSportsDataExport = () => {
  const c = getFbConfig()
  const { projectId } = c ?? {}
  switch (projectId) {
    case 'me-mobile-4410b':
    case 'me-production-57ad6':
      return true
    default:
      return false
  }
}

export const getAppTitle = () => {
  const { appName } = getAppName()
  switch (appName) {
    case _projectAppNames.meetingevolution:
      return { appTitle: 'Meeting Evolution' }
    case _projectAppNames.thumbstat:
      return { appTitle: 'Thumbstat', fixed: true }
    case _projectAppNames.pojo:
      return { appTitle: 'Pojo', fixed: true }
    case _projectAppNames.fcasd_ft:
      return { appTitle: 'FCASD', fixed: true }
    default:
    // nothing
  }
}

export const getAppIcon = () => {
  const { appName } = getAppName()
  switch (appName) {
    case _projectAppNames.meetingevolution:
      return '/icons/me-96.png'
    case _projectAppNames.thumbstat:
      return '/firebase logo.png'
    case _projectAppNames.pojo:
      return '/icons/ts-96.png'
    default:
    // nothing
  }
}

const getEnvironment = () => {
  switch (process.env.NODE_ENV) {
    case envTypes.production:
      if (isDev_me()) {
        return 'dev'
      } else if (document.location.host.indexOf('mobile') >= 0) {
        return _useProdDb ? 'prod' : 'dev'
      } else {
        return 'dev'
      }

    default:
      if (forceProd) {
        return 'prod'
      } else {
        return 'dev'
      }
  }
}

export const isAppDeveloper = (email) => {
  switch (email) {
    case _developerAdminEmail:
      return true
    default:
      return false
  }
}