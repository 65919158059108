import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { PageContext } from '../../../../global/cnr/contexts/PageContext';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { pasteHandlers, pasteInitialState, pasteReducer } from '../../../../global/cnr/reducers/PasteReducer';
import { creatingHelpers } from '../../../../global/common/creating';
import { uniqueKey } from '../../../../global/common/keys';
import { copyObj } from '../../../../global/common_web/copy';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import TableViewer from '../../../../global/components/viewers/TableViewer';
import StepWizard from '../../../../global/components/wizard/StepWizard';
import { createFsDocKey } from '../../../../global/firestoreData/appData/fsAppData';
import { getNonExistingItems } from '../../../../global/firestoreData/helpers/compareData';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { allowSportsUpdates } from '../../cnr/reducers/reducerHelpers/seasonalSportsData';
import { CreateSportsContext } from '../CreateSports';

const stepTypes = {
  paste: 'paste',
  selectedItems: 'selectedItems',
}

const _columnHeaders_athletes = [
  { name: 'firstName', names: ['Name'], required: true },
  { name: 'lastName', names: ['Name'], required: true },
  { name: 'graduationYear', names: ['Grade'], required: true },
]

// No. Name Grade Ht. Pos
const _columnHeaders_roster = [
  { name: 'firstName', names: ['Name'], required: true },
  { name: 'lastName', names: ['Name'], required: true },
  { name: 'graduationYear', names: ['Grade'], required: true },
  { name: 'playerPosition', names: ['Pos'], required: true },
  { name: 'uniformNumber', names: ['No.'], required: false },
]

const _columnHeaders_pre = [{ name: 'isAthlete' }, { name: 'isRostered' }]
const _columnHeaders_post = [{ name: '_itemKey' }]

const CreateRoster = (props) => {

  const { tableRows, subActionType, fullMode } = props ?? {}
  let { caption } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state } = states
  const { pathViews } = paps_state ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { _sportsModeKey, athletes, roster_gs, teams_info, latestSeason, googleLinks_info } = sportsSeason_state ?? {}
  const { rosters } = teams_info ?? {}
  const { gls_teams } = googleLinks_info ?? {}
  const _glsTeam = gls_teams && gls_teams[pathViews.teams] ? gls_teams[pathViews.teams] : null

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext)
  const { createSports_handlers } = createSportsContext ?? {}

  const _steps = Object.keys(stepTypes)

  const pageContext = useContext(PageContext);

  // pasteReducer  
  const [paste_state, paste_dispatch] = useReducer(pasteReducer, pasteInitialState(paps_state, props.viewItem, props.viewListData, pageContext));
  const paste_handlers = pasteHandlers(paste_dispatch)
  const { pasteValue, itemData: pastedItems, tableItems } = paste_state ?? {}
  const { propsCc } = tableItems ?? {}

  const [step, setStep] = useState({ index: 0, name: stepTypes.paste })
  const [alertCaption, setAlertCaption] = useState()
  const [currentRoster, setCurrentRoster] = useState({})
  const [teamAthletes, setTeamAthletes] = useState({})
  const [propsOk, setPropsOk] = useState()
  const [rosterInfo, setRosterInfo] = useState()

  const { selectedItems, rosterType, combinedHeaders, mainHeaders } = rosterInfo ?? {}

  const stepCount = _steps.length

  const updateCaption = rosterType === 'athletes' ? 'Update Athletes' : 'Update Roster'

  const taRows = tableRows ? tableRows : 20

  const _chs = combinedHeaders ? Object.keys(_.groupBy(combinedHeaders, 'name')) : []

  if (!caption) { caption = 'Paste' }
  caption = _.startCase(caption)

  useEffect(() => {
    if (_glsTeam) {
      sportsSeason_handlers.handleGet_gsRoster(_glsTeam)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_glsTeam]);

  // caption
  useEffect(() => {
    if (combinedHeaders) {
      let _alertCaption = 'Column headers must include: ';
      combinedHeaders.forEach((ch, index) => {
        _alertCaption += ' ' + _.startCase(ch.name)
        if (index < (combinedHeaders.length - 1)) { _alertCaption += ',' }
      })
      setAlertCaption(_alertCaption)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedHeaders]);

  // get roster and trigger get athletes for the team
  useEffect(() => {
    const _roster = rosters ? rosters[_sportsModeKey] : {}
    setCurrentRoster(_roster ? _roster : {})
    sportsSeason_handlers.handleGet_parentKeyCollectionItems('athletes')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    setTeamAthletes(athletes ? copyObj(athletes) : {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [athletes]);

  const getAthleteKey = (teamAthletes, fr) => {
    if (teamAthletes) {
      const tak = _.findKey(teamAthletes, { firstName: fr.firstName, lastName: fr.lastName })
      if (tak) {
        fr.athleteKey = tak
      }
    }
  }

  useEffect(() => {

    const _rosterInfo = {
      selectedItems: {},
      rosterType: 'roster',
      mainHeaders: _columnHeaders_roster,
      combinedHeaders: []
    }

    let _items;

    if (pastedItems) {
      if (teamAthletes) {
        _items = pastedItems
        ammendListData(pastedItems, _rosterInfo)
      }
    }

    if (roster_gs) {
      if (teamAthletes) {
        const r = creatingHelpers.createObject(roster_gs, '_gsid')
        console.log('r', r)
        _items = r
        ammendListData(r, _rosterInfo)
      }
    }

    if (currentRoster) {
      const { foundItems: found_roster, nonExistingItems: _nonExistingItems_roster } = getNonExistingItems(currentRoster, _items, ['firstName', 'lastName'])
      if (found_roster) {
        Object.keys(found_roster).forEach(k => {
          const fr = found_roster[k]
          delete fr.year
          _rosterInfo.selectedItems[k] = found_roster[k]
          _rosterInfo.selectedItems[k].isRostered = true
          getAthleteKey(teamAthletes, fr)
        })
      }
      if (_nonExistingItems_roster) {
        Object.keys(_nonExistingItems_roster).forEach(k => {
          const fr = _nonExistingItems_roster[k]
          delete fr.year
          _rosterInfo.selectedItems[k] = fr
          _rosterInfo.selectedItems[k].isRostered = false
          getAthleteKey(teamAthletes, fr)
        })
      }
    }

    _rosterInfo.combinedHeaders = [..._columnHeaders_pre, ..._rosterInfo.mainHeaders, ..._columnHeaders_post]

    setRosterInfo(_rosterInfo)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roster_gs, pastedItems, teamAthletes, currentRoster]);

  useEffect(() => {
    if (mainHeaders) {
      let _propsOk = true
      console.log('propsCc', propsCc)
      mainHeaders.forEach(ch => {
        if (propsCc && !propsCc.includes(ch.name) && ch.required) {
          _propsOk = false
        }
      })
      setPropsOk(_propsOk)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableItems, mainHeaders, propsCc]);

  const ammendListData = (items, rosterInfo) => {

    const { foundItems: existing_athletes, nonExistingItems: _nonExistingItems_athletes } = getNonExistingItems(teamAthletes, items, ['firstName', 'lastName'])

    if (existing_athletes) {
      Object.keys(existing_athletes).forEach(k => {
        rosterInfo.selectedItems[k] = existing_athletes[k]
        rosterInfo.selectedItems[k].isAthlete = true
      })
    }

    if (_nonExistingItems_athletes) {
      Object.keys(_nonExistingItems_athletes).forEach(k => {
        rosterInfo.selectedItems[k] = _nonExistingItems_athletes[k]
        rosterInfo.selectedItems[k].isAthlete = false
        rosterInfo.rosterType = 'athletes'
        rosterInfo.mainHeaders = _columnHeaders_athletes
      })
    }
  }

  const handlePaste = (e, { value }) => { paste_handlers.handlePaste(value) }

  const handleViewItemPasteCommit = () => {
    const newAthletes = {}
    const newRosters = {}
    if (selectedItems) {
      Object.keys(selectedItems).forEach(k => {
        const selectedItem = selectedItems[k]
        const { isAthlete, isRostered, _itemKey } = selectedItem ?? {}
        delete selectedItem.id
        delete selectedItem.parentKeys
        delete selectedItem._itemKey
        delete selectedItem.isAthlete
        delete selectedItem.isRostered
        const _key = _itemKey ? _itemKey : createFsDocKey('roster')
        if (!isAthlete) {
          newAthletes[_key] = selectedItem
        }
        if (!isRostered) {
          newRosters[_key] = selectedItem
        }
      })
    }
    if (createSports_handlers.handleCreate_teamRoster) { createSports_handlers.handleCreate_teamRoster(latestSeason, newAthletes, newRosters, rosterType) }
  }

  const validateComponent = () => {
    switch (step.name) {
      case stepTypes.paste:
        return selectedItems ? true : false
      case stepTypes.selectedItems:
        return true
      default:
        break;
    }
  }

  const allowBack = step.index > 0
  const allowForward = (step.index < (stepCount - 1) && validateComponent())

  const pasteForm = () => <Wrapper
    header={alertCaption}
    content={<Form>
      <Form.TextArea value={pasteValue} className={'text-area-paste'} key={uniqueKey('vip', 'ta')} onChange={handlePaste} rows={taRows} placeholder='paste here...' />
    </Form>}
    wrapperType={wrapperTypes.padded}
  >
  </Wrapper>

  const tv_selected = () => <TableViewer
    tableKey={'pasted'}
    jsonData={selectedItems}
    ignoreItemKeys={true}
    columnHeaders={_chs}
    propIcons={{ isAthlete: 'user', isRostered: 'user' }}
  />

  const wrapper_selected = () => <Wrapper
    content={tv_selected()}
    footer={<UiSaveButtons save={{ caption: updateCaption, oc: handleViewItemPasteCommit, disabled: !propsOk, color: allowSportsUpdates.athletes ? 'green' : 'blue' }} />}
    wrapperType={wrapperTypes.padded}
  />

  const content = () => {
    switch (step.name) {
      case stepTypes.paste:
        return pasteForm()
      case stepTypes.selectedItems:
        return wrapper_selected()
      default:
        break;
    }
  }

  const stepWizard = () => <StepWizard
    allowBack={allowBack}
    allowForward={allowForward}
    content={content()}
    fullMode={fullMode}
    noModal={true}
    setStep={setStep}
    step={step}
    stepCaption={_.startCase(step.name)}
    steps={_steps}
    topperCaption={_.startCase(subActionType)}
  ></StepWizard>

  const fpw_wizard = () => <FullPageWrapper
    content={stepWizard()}
    topperCaption={_.startCase(subActionType)}
    topperCaption2={latestSeason}
  ></FullPageWrapper>

  const fpw = () => <FullPageWrapper
    content={wrapper_selected()}
    topperCaption={_.startCase(subActionType)}
    topperCaption2={latestSeason}
  ></FullPageWrapper>

  if (subActionType) {
    return _glsTeam && _glsTeam.rosterSheet ? fpw() : fpw_wizard()
  } else {
    return stepWizard()
  }


}

export default CreateRoster  