import _ from 'lodash';
import { array_move } from '../../common/convert';

export const handleAnyPaste = (pasteValue) => {
  try {
    const parsed = JSON.parse(pasteValue)
    const all = getAllItems(parsed)
    return {
      pasteValue,
      jsonData: parsed,
      itemData: all,
      tableItems: null,
    }
  } catch (error) {
    try {
      const items = handleDataTablePaste(pasteValue)
      const all = getAllItems(items.items)
      return {
        tableItems: items,
        jsonData: null,
        itemData: all,
      }
    } catch (error2) {
      console.log('handleAnyPaste - error', error)
      return { error }
    }
  }
}

const getAllItems = (data) => {
  const all = {}
  Object.keys(data).forEach(key => {
    const item = data[key]
    const realKey = item.id ? item.id : key
    all[realKey] = {}
    Object.keys(item).forEach(itemKey => {
      if (item[itemKey] && itemKey !== 'id') {
        all[realKey][_.camelCase(itemKey)] = item[itemKey]
      }
    })
  })
  return all
}

export const handleGridPaste = (value) => {

  const topLines = {}
  const leftValues = {}
  const matchDates = []
  const rows = value.split('\n')
  const matches = []

  let leftTeam;

  rows.forEach((row, rowIndex) => {
    // if (rowIndex === 0) {
    const _rowS = row.split('\t')
    _rowS.forEach((value, columnIndex) => {
      switch (columnIndex) {
        case 0:
          break;
        default:
          matchDates.push(value)
      }
    })
    // }
  })

  rows.forEach((row, rowIndex) => {
    // if (rowIndex > 0) {
    const _rowS = row.split('\t')
    _rowS.forEach((value, columnIndex) => {
      switch (columnIndex) {
        case 0:
          leftTeam = value
          leftValues[value] = { matches: [] }
          break;
        default:
          const match = {
            home: leftTeam,
            away: value,
            matchDateIndex: columnIndex - 1
            // matchDate: matchDates[columnIndex - 1]
          }
          leftValues[leftTeam].matches.push(match)
          matches.push(match)
      }
    })
    // }
  })

  console.log('leftValues', leftValues)

  let topLine = rows[0]

  const topProps = topLine.split('\t')
  const topPropCc = [];

  topProps.forEach(topProp => {
    let tp = (topProp === '#') ? 'number' : topProp
    topLines[tp] = {}
    topPropCc.push(_.camelCase(tp))
  })

  const items = []

  rows.forEach((key, index) => {
    if (index > 0 && rows[index] !== '') {
      const line = rows[index]
      const lineSplit = line.split('\t')
      const item = {}

      let descriptions = []

      topProps.forEach((topProp, index) => {
        const tpcc = _.camelCase(topProp)
        if (lineSplit[index]) {
          let value = lineSplit[index].toString()
          // value = adjustDate(topProp, value)
          // value = adjustTime(topProp, value)
          if (!value) { value = '' }
          item[tpcc] = value
        }
      })

      if (descriptions && descriptions.length > 0) {
        item.descriptions = descriptions
      }

      items.push(item)
    }
  })

  return {
    rows,
    props: topProps,
    items: items,
    propsCc: topPropCc,
  }
}

export const handleDataTablePaste = (value) => {

  const topLines = {}
  const rows = value.split('\n')
  let topLine = rows[0]

  const topProps = topLine.split('\t')
  const topPropCc = [];

  topProps.forEach(topProp => {
    let tp = (topProp === '#') ? 'number' : topProp
    topLines[tp] = {}
    topPropCc.push(_.camelCase(tp))
  })

  const items = []

  rows.forEach((key, index) => {
    if (index > 0 && rows[index] !== '') {
      const line = rows[index]
      const lineSplit = line.split('\t')
      const item = {}

      let descriptions = []

      topProps.forEach((topProp, index) => {
        const tpcc = _.camelCase(topProp)
        if (lineSplit[index]) {
          let value = lineSplit[index].toString()
          // value = adjustDate(topProp, value)
          // value = adjustTime(topProp, value)
          if (!value) { value = '' }
          item[tpcc] = value
        }
      })

      if (descriptions && descriptions.length > 0) {
        item.descriptions = descriptions
      }

      items.push(item)
    }
  })

  return {
    rows,
    props: topProps,
    items: items,
    propsCc: topPropCc,
  }
}

export const createPropHeadersFromData = (items, viProps) => {
  let prhs = []
  if (_.isArray(items)) {
    items.forEach(item => {
      prhs = [...prhs, ...Object.keys(item)]
    })
  } else {
    Object.keys(items).forEach(key => {
      const item = items[key]
      prhs = [...prhs, ...Object.keys(item)]
    })
  }

  let unique = _.uniq(prhs)
  let ni = 0
  let fni = 0
  let lni = 0
  const newProps = {}

  delete unique['id']
  delete unique['uid']

  unique.forEach((item, index) => {
    if (allowItem(item)) {
      if (item === 'firstName') { fni = index }
      if (item === 'lastName') { lni = index }
      if (item === 'name') { ni = index }
      if (viProps) {
        if (viProps && !viProps[item] && !newProps[item] && item.toLowerCase() !== 'id' && item.toLowerCase() !== 'uid' && item.toLowerCase() !== 'id') {
        }
      }
    }
  })

  unique = array_move(unique, fni, 0)
  unique = array_move(unique, lni, 0)
  unique = array_move(unique, ni, 0)

  return {
    propHeaders: unique,
    newProps: newProps
  }
}

const allowItem = (item) => {
  switch (item.toLowerCase()) {
    case 'id':
    case 'uid':
      return false
    default:
    // nothing
  }
  return true
}