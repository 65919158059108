
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Icon, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { ManifestContext } from '../../cnr/contexts/ManifestContext';
import { PageDataContext } from '../../cnr/contexts/PageDataContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { ViewSettingsContext } from '../../cnr/contexts/ViewSettingsContext';
import { g_cns } from '../../common/cns';
import { uniqueKey } from "../../common/keys";
import { copyObj } from '../../common_web/copy';
import { appIconTypes } from '../../enums/appIconTypes';
import { gEnums } from "../../enums/globalEnums";
import { PopupIcon } from '../../genericControls/GenericIcon';
import UiHeader from '../../pageItem/UiHeader';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import NoData from '../alerts/NoData';
import PendingWait from '../alerts/pendings/PendingWait';
import GroupActionButton from "../buttons/GroupActionButton";
import UiSaveButtons from '../buttons/UiSaveButtons';
import ManifestViewer from '../sem/ManifestViewer';
import Uploader from '../uploading/Uploader';
import FullImage from './FullImage';
import ImagesGrid from './ImagesGrid';
import ProfileImage from './ProfileImage';

const fileActionTypes = {
  approveFiles: 'approveFiles',
  makeProfile: 'makeProfile',
  removeFiles: 'removeFiles',
}

/**
 * Displays the files within storage. 
 * @param {object} props (allowMultiItemSelect, fileViewer_handlers, storageLocationType, storageType, viewerProps)
 * @description Clicking on the image will get and display the full image
 * @description Allows upload using Uploader
 * @note If the list is for manifest icons, displays ManifestViewer
 *  */

const ImageGallery = (props) => {

  const {
    allowMultiItemSelect,
    email,
    fromFileManagement,
    fileViewer_handlers,
    refreshing,
    storageLocationType, // pageDirect, pageGallery, etc
    storageType, // image, icon,etc
    viewerProps,
    viewOnly,
  } = props ?? {}

  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { appUser_state, paps_state, page_state, storage_state } = states ?? {}
  const { storage_handlers } = handlers ?? {}

  // authContext 
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { appUserSession } = appUserAccess ?? {}
  const { _itemKey: appUserItemKey } = appUserSession ?? {}

  // papsContext 
  const { view, viewKey, pathViews } = paps_state
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_styles, aps_appUserSettings } = pageSettings ?? {}
  const { appGallery } = aps_global ?? {}
  const { galleryNeedApproval, galleryApprovalAccessLevel, allowAppUserUpload: allowUserUpload_gallery, allowMultiUpload, uploadAccessLevel, gridColumns } = appGallery ?? {}
  const { appUserCollection, allowImageUpload } = aps_appUserSettings ?? {}

  // pageDataContext
  const pageDataContext = useContext(PageDataContext);
  const { pageData_state } = pageDataContext ?? {}
  const { currentPageData } = pageData_state ?? {}
  const { email: email_current } = currentPageData ?? {}

  const _email = email_current ? email_current : email

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { dataName, _dataModificationOpts } = item_state ?? {}
  const { images: images_dm } = _dataModificationOpts ?? {}
  const { allowAppUserUpload: allowUserUpload_pageItem } = images_dm ?? {}

  const pageIsAppUsers = viewKey === appUserItemKey
  const _allowUserUpload = pageIsAppUsers && (allowUserUpload_pageItem || allowUserUpload_gallery) ? true : false

  // storageContext 
  const {
    caption,
    confirmation,
    currentGalleryItems: currentGallery_page,
    currentStorageFiles,
    fullImageProps,
    galleries,
    galleryFetched,
    galleryFolders,
    galleryType,
    storagePermissions,
    profileStorageFile,
    selectedItems,
    showApprovalWizard,
  } = storage_state ?? {}

  console.log('galleryFetched', galleryFetched)

  const {
    page: gallery_page,
    profile: gallery_proflie,
    profileGallery: gallery_proflies,
    pdf: gallery_pdf
  } = galleries ?? {}

  // manifestContext
  const manifestContext = useContext(ManifestContext);
  const { manifest_state } = manifestContext ?? {}
  const { manifestIcons } = manifest_state ?? {}

  // viewSettingsContext
  const viewSettingsContext = useContext(ViewSettingsContext);
  const { viewSettings_handlers } = viewSettingsContext ?? {}

  const styleAndClass = aps_styles && aps_styles[gEnums.projectStyles.actionButton] ? copyObj(aps_styles[gEnums.projectStyles.actionButton]) : {}
  styleAndClass.className += ' solo'

  const { fileMode } = viewerProps ?? {}

  let inverted;
  let approvedOnly;
  // let addIcon = 'add'

  // local state   
  const [showUpload, setShowUpload] = useState()
  const [allowApproval, setAllowApproval] = useState()
  const [profileGallery, setProfileGallery] = useState({})

  const hasGallery = currentGallery_page && Object.keys(currentGallery_page).length > 0
  const hasProfileGallery = profileGallery && Object.keys(profileGallery).length > 0

  const _useFull = storageLocationType === gEnums.storageLocationTypes.event ? false : true

  useEffect(
    () => {

      storage_handlers.handleStart_fetch()
      const _storageProps = {
        allowUserUpload_gallery,
        appUserAccess,
        appUserCollection,
        email: _email,
        galleryApprovalAccessLevel,
        galleryNeedApproval,
        manifestIcons,
        pathViews,
        storageLocationType,
        storageType,
        uploadAccessLevel,
        view,
        viewKey,
      }
      // gets the gallery for the page if does not aleady exist 
      storage_handlers.handleGet_galleryAny(_storageProps)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gallery_page, gallery_pdf, gallery_proflie, gallery_proflies, pathViews, confirmation, appUserAccess]
  )

  useEffect(
    () => {
      switch (storageLocationType) {
        case gEnums.storageLocationTypes.profileGallery:
          if (gallery_proflies) {
            setProfileGallery(gallery_proflies[_email])
          }
          break;
        default:
        // nothing
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gallery_proflies && gallery_proflies[_email]]
  )

  useEffect(
    () => {
      let allow_userUpload;
      switch (storageLocationType) {
        case gEnums.storageLocationTypes.pageGallery:
        case gEnums.storageLocationTypes.pageDirect:
          allow_userUpload = (storagePermissions.allowUpload && !storagePermissions.allowHeader) || _allowUserUpload
          break;
        case gEnums.storageLocationTypes.profileGallery:
          allow_userUpload = (storagePermissions.allowUpload && !storagePermissions.allowHeader)
          // setShowUpload(true)
          break;
        default:
          allow_userUpload = (storagePermissions.allowUpload && !storagePermissions.allowHeader)
        // nothing
      }
      const allowAp = (storagePermissions.allowApprove && !storagePermissions.allowHeader)
      setAllowApproval(allowAp)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storagePermissions]
  )

  useEffect(
    () => {
      if (confirmation && viewSettings_handlers) {
        viewSettings_handlers.handleStopUpdate()
        handleRefresh()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmation]
  )

  useEffect(
    () => {
      if (refreshing) {
        storage_handlers.handleClear_gallery(galleryType)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshing]
  )

  useEffect(
    () => {
      if (fromFileManagement) {
        storage_handlers.handleGet_globalStorageFiles(storageLocationType, storageType)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromFileManagement, storageType]
  )

  const handleImageClick = (e, imageProps) => storage_handlers.handleClick_image(imageProps)
  const handlePdfClick = (e, src) => storage_handlers.handlePdfClick(e, src)
  const handleRefresh = () => storage_handlers.handleGet_globalStorageFiles(storageLocationType, storageType)
  const handleShowApprovalWizard = () => storage_handlers.handleShow_approvalWizard()
  const handleShowUpload = () => setShowUpload(!showUpload)

  const handleCancel = () => {
    switch (storageLocationType) {
      case gEnums.storageLocationTypes.profileGallery:
        setShowUpload(!showUpload)
        break;
      case gEnums.storageLocationTypes.pageDirect:
        if (showUpload) {
          setShowUpload(false)
        } else {
          props.handleCancel && props.handleCancel()
        }
        break;
      default:
        props.handleCancel && props.handleCancel()
    }
  }

  const handleConfirm = (fileActionType) => {
    viewSettings_handlers.handleStartUpdate()
    switch (fileActionType) {
      case fileActionTypes.approveFiles:
        return storage_handlers.handleApprove_selected()
      case fileActionTypes.removeFiles:
        return storage_handlers.handleDelete_selected()
      case fileActionTypes.makeProfile:
        return storage_handlers.handleSet_profile()
      default:
        break;
    }
  }

  /** Ammends the storage's selectedItems  */
  const handleImageSelect = (e, imageProps) => {
    const { documentKey, imageKey: selectedKey } = imageProps ?? {}
    e.stopPropagation()
    if ((storagePermissions.allowApprove && showApprovalWizard)) {
      storage_handlers.handleApprove_image(selectedKey, documentKey)
    } else if (storagePermissions.allowDelete) {
      storage_handlers.handleSelect_image(selectedKey, documentKey, allowMultiItemSelect)
    } else if (storagePermissions.allowSelect) {
      storage_handlers.handleSelect_image(selectedKey, documentKey, allowMultiItemSelect)
    }
  }

  const imageGridProps = {
    approvedOnly,
    handleImageClick,
    handleImageSelect,
    handlePdfClick,
    storagePermissions,
    selectedItems,
    showApprovalWizard,
    documentKey: null,
    isFullGalleryItem: false,
    gallery: null,
  }

  /** Ammends the fieldProp's value */
  const handleAmmendFieldProp = () => fileViewer_handlers && fileViewer_handlers.handleSelectSave(selectedItems)

  /** Returns a Grid with images of a single document */
  const pageGalleryGrid = (cg, documentKey, isFullGalleryItem) => {
    const _currentGallery = cg ? cg : currentGallery_page
    let _current_gallery;
    switch (storageLocationType) {
      case gEnums.storageLocationTypes.pageDirect:
        _current_gallery = [_currentGallery]
        break;
      default:
        _current_gallery = _currentGallery
    }
    imageGridProps.documentKey = documentKey
    imageGridProps.isFullGalleryItem = isFullGalleryItem
    imageGridProps.gallery = _current_gallery
    imageGridProps.gridColumns = gridColumns
    imageGridProps.dataName = dataName
    imageGridProps.origin = 'imageGallery'
    imageGridProps.storageLocationType = storageLocationType
    imageGridProps.imageOwner = _email
    return <ImagesGrid igProps={imageGridProps} viewOnly={viewOnly} />
  }

  /** Returns a Grid with images of a single document */
  const pdfGalleryGrid = (cg, documentKey, isFullGalleryItem) => {
    const _currentGallery = cg ? cg : currentGallery_page
    imageGridProps.documentKey = documentKey
    imageGridProps.isFullGalleryItem = isFullGalleryItem
    imageGridProps.gallery = _currentGallery
    imageGridProps.isPdf = true
    imageGridProps.gridColumns = gridColumns
    imageGridProps.origin = 'imageGallery'
    imageGridProps.storageLocationType = storageLocationType
    return <ImagesGrid igProps={imageGridProps} />
  }

  /** Returns a list of images from `currentStorageFiles` */
  const globalImagesGrid = () => {
    imageGridProps.gallery = currentStorageFiles
    imageGridProps.storageType = storageType
    imageGridProps.origin = 'imageGallery'
    imageGridProps.storageLocationType = storageLocationType
    return <ImagesGrid igProps={imageGridProps} />
  }

  /** Returns all the images for all the items in the `gallery_page` */
  const fullGalleryGrid = () => {
    const columns = []
    Object.keys(galleryFolders).forEach(key => {
      columns.push(<Grid.Column key={key} textAlign="center">
        <Icon name='folder' size={'huge'} color="blue" />
        {key}
      </Grid.Column>)
    })
    return <Grid columns={gridColumns ? gridColumns : 3}> {columns}</Grid>
  }

  /** Retuns one `Segment.Group` of images for each document */
  const documentsGalleryGrid = () => {
    const segs = []
    Object.keys(gallery_page).forEach(documentKey => {
      const fg = gallery_page[documentKey]
      const sg = <Segment.Group key={uniqueKey('sim', 'dgg', documentKey)}>
        <Segment key={uniqueKey('sim', 'dgg', documentKey, 'a')}>{_.startCase(documentKey)}</Segment>
        <Segment key={uniqueKey('sim', 'dgg', documentKey, 'b')}>{pageGalleryGrid(fg, documentKey, true)}</Segment>
      </Segment.Group>
      segs.push(sg)
    })
    return segs
  }

  const uploader = () => <Uploader
    allowMultiUpload={allowMultiUpload}
    handleCancel={handleCancel}
    origin={'imageGallery'}
    storageLocationType={storageLocationType}
    storageType={storageType}
    useFull={_useFull}
  />

  const noData = () => viewOnly ? null : <NoData altCaption={'Images'} />

  const wrapperContent = () => {

    if (!galleryFetched) {
      return !viewOnly && <PendingWait />
    } else {
      if (showUpload) {
        // IMPORTANT: Uploader 
        return uploader()
      } else {
        if (fullImageProps) {
          return <FullImage fullImageProps={fullImageProps} email={_email} handleClose={storage_handlers.handleClose_bigImage} storageLocationType={storageLocationType} viewOnly={viewOnly} />
        } else {

          console.log('storageLocationType', storageLocationType, storageType)

          switch (storageLocationType) {

            case gEnums.storageLocationTypes.clientProfiles:
              return hasGallery ? pageGalleryGrid(null, storageLocationType) : noData()

            case gEnums.storageLocationTypes.profileGallery:
              return hasProfileGallery ? pageGalleryGrid(profileGallery, storageLocationType) : noData()

            case gEnums.storageLocationTypes.fullGallery:
              if (showApprovalWizard) {
                return gallery_page ? documentsGalleryGrid() : noData()
              } else {
                return galleryFolders ? fullGalleryGrid() : noData()
              }

            case gEnums.storageLocationTypes.profiles:
              return <ProfileImage profileStorageFile={profileStorageFile} />

            case gEnums.storageLocationTypes.eventGallery:
            case gEnums.storageLocationTypes.pageDirect:
            case gEnums.storageLocationTypes.pageGallery:
              // case gEnums.storageLocationTypes.profileGallery:
              return hasGallery ? pageGalleryGrid(null, storageLocationType) : noData()

            case gEnums.storageLocationTypes.pagePdf:
              return hasGallery ? pdfGalleryGrid(null, storageLocationType) : <NoData altCaption={'PDfs'} />

            default:
              switch (storageType) {
                case gEnums.storageTypes.manifest:
                  return <ManifestViewer />
                default:
                  return currentStorageFiles ? globalImagesGrid() : noData()
              }
          }
        }
      }
    }
  }

  const gabApprove = () => <GroupActionButton key={uniqueKey('sim', 'a')} handleConfirm={handleConfirm} actionName={fileActionTypes.approveFiles} defaultIcon={'check'} confirmation={confirmation} saveIcon={'check'} itemCount={Object.keys(selectedItems).length} />
  const gabDelete = () => <GroupActionButton key={uniqueKey('sim', 'd')} handleConfirm={handleConfirm} actionName={fileActionTypes.removeFiles} defaultIcon={'trash alternate outline'} confirmation={confirmation} saveIcon={'check'} itemCount={Object.keys(selectedItems).length} />
  const gabProfile = () => <GroupActionButton key={uniqueKey('sim', 'p')} handleConfirm={handleConfirm} actionName={fileActionTypes.makeProfile} defaultIcon={'user circle'} saveIcon={'check'} />
  const gabFieldProp = () => <GroupActionButton key={uniqueKey('sim', 'f')} actionName={'select'} handleConfirm={handleAmmendFieldProp} defaultIcon={'check'} confirmation={confirmation} saveIcon={'check'} />

  const footerSingleButton = () => {
    switch (storageLocationType) {
      case gEnums.storageLocationTypes.profiles:
        return gabProfile()
      default:
      // nothing
    }
  }

  const footerSelectedButton = () => {
    switch (fileMode) {
      case gEnums.fileModes.viewManifest:
      case gEnums.fileModes.viewWithSelect:
      case gEnums.fileModes.viewWithModify:
      case gEnums.fileModes.viewWithPick:
        return gabFieldProp()
      case gEnums.fileModes.view:
        if (storagePermissions.allowDelete) {
          return gabDelete()
        } else if (storagePermissions.allowApprove && showApprovalWizard) {
          return gabApprove()
        }
        break;
      default:
        if (storagePermissions.allowDelete) {
          return gabDelete()
        } else if (storagePermissions.allowApprove && showApprovalWizard) {
          return gabApprove()
        }
    }
  }

  const header = () => {
    if (fullImageProps) {
      const icon1 = { handleOnClick: handleImageClick, icon: appIconTypes.delete, inverted }
      return <UiHeader caption={_.startCase(storageType)} icons={[icon1]} storageType={storageType} isSingle={true} />
    } else {
      const icon1 = { handleOnClick: storagePermissions.allowUpload ? handleShowUpload : null, showAdd: showUpload, inverted }
      const icon2 = { handleOnClick: storagePermissions.allowRefresh ? handleRefresh : null, showAdd: showUpload, inverted, icon: 'refresh', title: 'Refresh' }
      return <UiHeader caption={caption} icons={[icon1, icon2]} />
    }
  }

  const footer_upload = () => {
    const btns = [{ caption: 'Add', oc: handleShowUpload, icon: 'add', color: 'blue' }]
    if (selectedItems && Object.keys(selectedItems).length > 0) {
      btns.push({ caption: 'Remove', oc: handleShowUpload, icon: 'arrow right', color: 'blue' },)
    }
    return <UiSaveButtons others={btns} />
  }


  const footer = () => {
    switch (storageLocationType) {
      case gEnums.storageLocationTypes.profileGallery:
      case gEnums.storageLocationTypes.pageDirect:
        return allowImageUpload && !showUpload && footer_upload()
      default:
        if (selectedItems && Object.keys(selectedItems).length > 0) {
          if (fullImageProps) {
            return footerSingleButton()
          } else {
            return footerSelectedButton()
          }
        }
    }
  }

  const wrapper = () => <Wrapper
    header={storagePermissions.allowHeader && !props.fip ? header() : null}
    content={wrapperContent()}
    footer={!viewOnly && footer()}
    wrapperType={wrapperTypes.padded}
    // css={{ content: 'igw' }}
    noMid={true}
    updating={false}
  />

  const approvalWrapper = () => <Wrapper
    header={storagePermissions.allowHeader && !props.fip ? header() : null}
    content={wrapperContent()}
    footer={!showUpload && footer()}
    noMid={true}
    wrapperType={wrapperTypes.paddedHeader}
  />

  const approvalWizard = () => <FullPageWrapper
    content={approvalWrapper()}
    handleCancel={handleShowApprovalWizard}
    topperCaption={caption ? 'Approve ' + caption : 'Approve Items'}
  />

  const pi = (onClick, iconName, size, styleAndClass, altCn, color) => <PopupIcon piProps={{ onClick, iconName, size, styleAndClass, altCn, color }} />

  // const uploadPopup = () => pi(handleShowUpload, addIcon, 'large', styleAndClass, g_cns.item_popup_solo_right, storagePermissions.iconColor)
  const approvalPopup = () => pi(handleShowApprovalWizard, 'check', 'large', styleAndClass, g_cns.item_popup_solo_right)

  const content = () => <React.Fragment>
    {wrapper()}
    {/* {(allowAdminUpload || allowAppUserUpload) && uploadPopup()} */}
    {allowApproval && approvalPopup()}
  </React.Fragment>

  const fullPageWrapper = () => <FullPageWrapper
    content={showApprovalWizard ? approvalWizard() : content()}
    footer={footer()}
    handleCancel={handleCancel}
    topperCaption={'Image Gallery'}
    topperCaption2={_.startCase(storageLocationType)}
  />

  if (props.handleCancel) {
    return fullPageWrapper()
  } else {
    return showApprovalWizard ? approvalWizard() : wrapper()
  }
}

export default ImageGallery