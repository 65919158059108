const getMdd = (device, spd) => {
  return { key: device, value: device, text: device, msettings: { w: spd.viewportWidth, h: spd.viewportHeight } }
}

export const parentStyleOptions = () => {
  const psos = []
  psos.push({ key: 'home', value: 'home', text: 'home' })
  psos.push({ key: 'client', value: 'client', text: 'client' })
  psos.push({ key: 'event', value: 'event', text: 'event' })
  return psos
}

export const mobileDeviceTypes = () => {
  const devices = []
  Object.keys(smartphoneDimensions).forEach(key => {
    const spd = smartphoneDimensions[key]
    devices.push(getMdd(key, spd))
  })
  return devices
}

export const smartphoneDimensions = {
  iphone: {
    manufacturer: "Apple",
    viewportWidth: 390,
    viewportHeight: 844
  },
  samsung: {
    manufacturer: "Samsung",
    viewportWidth: 400,
    viewportHeight: 760
  },
  pixel: {
    manufacturer: "Google",
    viewportWidth: 412,
    viewportHeight: 732
  },
  desktopMobile: {
    manufacturer: "None",
    viewportWidth: 900,
    viewportHeight: 860
  },
  tabletMobile: {
    manufacturer: "None",
    viewportWidth: 900,
    viewportHeight: 860
  },
  laptop: {
    manufacturer: "Google",
    viewportWidth: 412,
    viewportHeight: 660
  },
};