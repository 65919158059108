import { getAuth, signOut } from "firebase/auth";
import { grts, responseReducers } from "./reducerHelpers/dispatchProps";

export const rts = {
  handleSetAuth: 'handleSetAuth',
  handleSignedOut: 'handleSignedOut',
  handleSignOut: 'handleSignOut',
  handleRefresh: 'handleRefresh',
  ...grts
}

export const authPendingTypes = {
  emailSent: 'emailSent',
  phoneCodeSent: 'phoneCodeSent',
  authConfirmed: 'authConfirmed'
}

export const authReducerInitialState = (initState) => {
  return { ...initState, authIndex: 0 }
};

export const authReducer = (state, action) => {

  const { type, dispatch, currentAuthUser } = action

  const { handleSignedOut } = authHandlers(dispatch)

  switch (type) {
    case rts.handleSetAuth:
      return {
        ...state,
        authValidated: true,
        loggedIn: currentAuthUser ? true : false,
        currentAuthUser: currentAuthUser,
        isAppDeveloper: currentAuthUser && currentAuthUser.isAppDeveloper,
        noAuth: !currentAuthUser,
        authPendingType: currentAuthUser ? authPendingTypes.authConfirmed : state.authPendingType,
        authIndex: state.authIndex === 0 ? 1 : 0
      }

    case rts.handleRefresh:
      const _auth = getAuth()
      _auth && _auth.currentUser && _auth.currentUser.getIdToken(true);
      return {
        ...state,
        authValidated: false
      }

    case rts.handleSignOut:
      const auth = getAuth()
      signOut(auth).then(() => {
        console.log('OUT')
        handleSignedOut()
      })
      return {
        ...state,
        appUser: null,
        loggedIn: false,
        currentAuthUser: null,
        authValidated: null,
      }

    case rts.handleSignedOut:
      return {
        ...state,
        authValidated: true,
        appUser: null,
        loggedIn: false,
        currentAuthUser: null,
      }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { questionProps: null })

    default:
      return { ...state }
  }
}

export const authHandlers = (dispatch) => {
  return {
    handleSetAuth: (currentAuthUser) => { dispatch({ type: rts.handleSetAuth, dispatch, currentAuthUser }) },
    handleSignedOut: () => { dispatch({ type: rts.handleSignedOut, dispatch }) },
    handleSignOut: () => { dispatch({ type: rts.handleSignOut, dispatch }) },
    handleRefresh: () => { dispatch({ type: rts.handleRefresh, dispatch }) },
  }
}
