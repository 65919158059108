import { sortObject } from "../../common/sorting"
import { gEnums } from "../../enums/globalEnums"
import { getTempSettingsState } from "../../firestoreData/settings/ammendSettings"
import { getActiveViewSettingsMenuItems } from "../../viewSettings/components/menus/getActiveMenuItems"
import { getMenuItemMessage, menuItemAllowances } from "../../viewSettings/components/menus/menuItemHelpers"
import { vsComponentTypes } from "../../viewSettings/enums/vsComponentTypes"
import { getSwipeProps } from "./ViewSettingsComponentReducer"

const _allowedSortProps = ['viewItems', 'props']

export const rts = {
  handleSetViewItems: 'handleSetViewItems',
  handleUpdateMenu: 'handleUpdateMenu',
  handlePageData: 'handlePageData,'
}

export const viewSettingsMenuReducer = (state, action) => {

  const {
    appUserAccess,
    baseSettings_state,
    desktopMode,
    menuTypeKey,
    parentDefaults,
    pathViews,
    settingsArea_fns,
    settingsArea_state,
    themeColors,
    viewSettings_handlers,
    vsc_state,
  } = state

  const { type } = action

  const { isGlobal, appArea, data_current, swipedItem, swipedItems, updateProps } = vsc_state ?? {}
  const isGlobalPageItem = true // swipedItems && swipedItems[0] && swipedItems[0].key === vsComponentTypes.globalPageItems ? true : false

  switch (type) {

    case rts.handleSetViewItems:

      const { _viewItems } = getTempSettingsState(action.settings_temp, isGlobal)
      const _vscProps = getSwipeProps(data_current, viewSettings_handlers, swipedItem, updateProps, _viewItems, settingsArea_fns, isGlobal, appArea)
      const { viewProps } = _vscProps ?? {}

      const { menuItems, menuType, otherData, settingsMessage } = getInitMenuData({
        isGlobalPageItem,
        menuTypeKey,
        updateProps,
        parentDefaults,
        currentViewItems: _viewItems,
        viewProps,
        viewSettings_handlers,
      })

      const allowSort = _allowedSortProps.includes(menuTypeKey)
      let propsOnly = true
      if (otherData) { propsOnly = false }

      const miProps = {
        activeData: data_current,
        settingsArea_state,
        baseSettings_state,
        data_current,
        desktopMode,
        getColors: false,
        isGlobal,
        menuItems,
        menuType,
        menuTypeKey,
        pathViews,
        propsOnly,
        themeColors,
      }

      // IMPORTANT: Settings - getActiveViewSettingsMenuItems    
      const activeMenuItems = getActiveViewSettingsMenuItems(miProps, appUserAccess)
      const menuItemAllows = menuItemAllowances(menuType, isGlobal)

      return { ...state, menuItemAllows, propsOnly, activeMenuItems, allowSort, menuTypeKey, viewItems: _viewItems, data_current, swipedItem, updateProps, menuItems, menuType, settingsMessage, otherData }

    case rts.handleUpdateMenu:
      return { ...state }

    default:
      return { ...state }
  }
}

export const viewSettingsMenuInitialState = (initState) => {
  return { ...initState }
};

export const viewSettingsMenuHandlers = (dispatch, state) => {
  return {
    handleSetViewItems: (settings_temp) => { dispatch({ type: rts.handleSetViewItems, settings_temp }) },
    handleUpdateMenu: (settings_temp) => { dispatch({ type: rts.handleUpdateMenu, settings_temp }) },
  }
}

const getInitMenuData = (props) => {

  const {
    currentViewItems,
    isGlobalPageItem,
    menuTypeKey,
    updateProps,
    parentDefaults,
    viewProps,
    viewSettings_handlers,
  } = props

  const { useClientThemes, useClientSignIn, useHomeTransitions, groupingOptions } = parentDefaults ?? {}
  const { useGlobalGroupSettings, useGlobalAlphabetSettings } = groupingOptions ?? {}

  let menuItems;
  let menuType;
  let otherData;
  let settingsMessage;

  switch (menuTypeKey) {
    case gEnums.viewSettingTypes.propSections:
      const upsP = { ...updateProps }
      upsP.formKey = 'props'
      let propSectionData = viewSettings_handlers.getSettingsValue(updateProps, isGlobalPageItem, currentViewItems)
      menuItems = sortObject(propSectionData, 'position')
      menuType = gEnums.menuTypes.propSections
      break;

    case gEnums.viewSettingTypes.globalPageItems: // stay here
    case gEnums.viewSettingTypes.views: // stay here
    case gEnums.viewSettingTypes.viewItems: // stay here 
      let vis = viewProps
      if (vis) { menuItems = sortObject(vis, 'position') }
      menuType = gEnums.menuTypes.viewItem
      break;

    case gEnums.viewSettingTypes.props:
      let propData = viewSettings_handlers.getSettingsValue(updateProps, isGlobalPageItem, currentViewItems)
      menuItems = sortObject(propData, 'position')
      const upsSections = { ...updateProps }
      upsSections.formKey = 'propSections'
      otherData = viewSettings_handlers.getSettingsValue(upsSections, isGlobalPageItem, currentViewItems)
      menuType = gEnums.menuTypes.propItem
      break;

    case gEnums.viewSettingTypes.propSection:
      menuItems = viewProps
      menuType = gEnums.menuTypes.propSections // menuTypeKey
      break;

    case gEnums.viewSettingTypes.appData:
      menuItems = currentViewItems
      menuType = gEnums.menuTypes.viewItemData
      break;

    case gEnums.viewSettingTypes.profiles:
      menuItems = viewProps
      menuType = gEnums.menuTypes.block
      break;

    default:
      menuItems = viewProps
      menuType = gEnums.menuTypes.block
    // nothing
  }

  settingsMessage = getMenuItemMessage(menuTypeKey, { useClientSignIn, useClientThemes, useHomeTransitions, useGlobalGroupSettings, useGlobalAlphabetSettings })

  switch (menuTypeKey) {
    case 'style':
    case 'styles':
    case 'themedColors':
    case 'themedItems':
      menuType = gEnums.menuTypes.applyColor
      break;
    default:
    // nothing
  }

  return { menuItems, menuType, settingsMessage, otherData }
}
