import _ from 'lodash'
import { formatItem, formatTypes } from "../../../global/common/dateFormatting"
import { _matchLsTypes } from "../cnr/reducers/SportsDataReducer"
import { gameScoringTypes } from "../cnr/reducers/SportsSeasonReducer"
import { copyObj } from '../../../global/common_web/copy'
import { realtimeDocumentTypes } from '../cnr/reducers/SportsEditMatchReducer'
import { _matchResultTypes } from '../cnr/reducers/SportsMatchReducer'

const maxPoints = 25
const maxPoints5 = 15

export const matchCalcs = {
  calcAllVballMatches: (matches, gameScoringType) => calcAllVballMatches(matches, gameScoringType),
  calcAllVballMatches_displays: (matches) => calcAllVballMatches_displays(matches),
  calcVballMatch: (match, gameScoringType, realtimeDocumentType, match_alt, allowScoreAdvance) => calcVballMatch(match, gameScoringType, realtimeDocumentType, match_alt, allowScoreAdvance),
  calcVballMatch_display: (match) => calcVballMatch_display(match),
}

/**
 * 
 * @param {object} matches 
 * @param {object} updatedMatch 
 */
const calcAllVballMatches = (matches, gameScoringType) => {
  _.forEach(matches, (match) => {
    match && calcVballMatch(match, gameScoringType)
  })
}

const calcAllVballMatches_displays = (matches) => {
  _.forEach(matches, (match) => {
    match && calcVballMatch_display(match)
  })
}

const calcVballMatch_display = (match) => {

  const { _results, _results_realtime, _results_matchDay, startTime } = match

  let _scores;
  let _resultResults;
  let _scoreType = _matchResultTypes.normal;

  const _scoreDisplay = {
    scoreCaption: startTime,
    scoreType: _matchResultTypes.normal,
  }

  if (_results) {
    _resultResults = _results
    const { score, scores } = _results ?? {}
    _scores = !_.isEmpty(scores) && scores
    if (!_.isEmpty(score)) {
      const { home, away } = score ?? {}
      _scoreDisplay.scoreCaption = away + ' - ' + home
      _scoreType = _matchResultTypes.normal
    }
  }

  if (_results_realtime) {
    _resultResults = _results_realtime
    const { score, scores } = _results_realtime ?? {}
    _scores = !_.isEmpty(scores) && scores
    if (!_.isEmpty(score)) {
      const { home, away } = score ?? {}
      _scoreDisplay.scoreCaption = away + ' - ' + home
      _scoreType = _matchResultTypes.realtime
    }
  }

  if (_results_matchDay) {
    _resultResults = _results_matchDay
    const { score, scores } = _results_matchDay ?? {}
    _scores = !_.isEmpty(scores) && scores
    if (!_.isEmpty(score)) {
      const { home, away } = score ?? {}
      _scoreDisplay.scoreCaption = away + ' - ' + home
      _scoreType = _matchResultTypes.matchDay
    }
  }

  match._resultsStatus = {
    scoreDisplay: _scoreDisplay,
    resultResults: _resultResults,
    scoreType: _scoreType,
  }

  if (_scores) {
    match._resultsStatus.scoreScores = _scores
  }

}


/**
 * 
 * @param {object} match 
 * @param {object} updatedMatch 
 */
const calcVballMatch = (match, gameScoringType, realtimeDocumentType, match_alt, allowScoreAdvance) => {

  setResultsBase(match)

  const { bestOf, setCount, isPlayoff, away, home, results } = match ?? {}
  const { setNumber } = results ?? {}

  const thisMatch = match

  if (!match._status) { match._status = {} }

  if (isPlayoff && away && home && away.seed && home.seed && (home.seed > away.seed)) { match._flipHa = true }

  if (!match.roundInfo && thisMatch.roundInfo) { match.roundInfo = thisMatch.roundInfo }

  const _scores_flat = ammendResults_matchFromFlat(match)

  ammendLevelSectionOnly(match)

  ammendResults_match(match, gameScoringType, _scores_flat, allowScoreAdvance)

  if (match && match.startDate) { match.startDate = formatItem(formatTypes.shortestDate, match.startDate) }
  ammendMatchDate(match)

  ammendScoreDisplays(match, setNumber)

  const { complete } = results ?? {}

  // match.setsToWin = bestOf ? Math.round(setCount / 2) : setCount
  match._status.setsToWin = bestOf ? Math.round(setCount / 2) : setCount

  switch (realtimeDocumentType) {
    case realtimeDocumentTypes.matchDay:
      match._status.isMatchDayResult = true
      break;
    case realtimeDocumentTypes.realtime:
      match._status.isRealtimeResult = true
      break;
  }

  if (match?._status.isPast && !complete) {
    match._status.isMissing = true
  }

}

/**
 * ammends only the ls (level and section)
 * @param {*} match 
 */
const ammendLevelSectionOnly = (match) => {

  const { sections, levels, isPlayoff, poolKey, _matchCollectionKey } = match

  if (_matchCollectionKey === _matchLsTypes.exhibition) {
    match.ls = _.startCase(_matchLsTypes.exhibition)
  } else if (_matchCollectionKey === _matchLsTypes.nonDistrict) {
    match.ls = _.startCase(_matchCollectionKey)
  } else if (_matchCollectionKey === _matchLsTypes.tournament) {
    match.ls = _.startCase(_matchLsTypes.tournament)
  } else {
    if (sections === _matchLsTypes.non) {
      match.ls = sections + ' Section'
    } else if (sections && levels) {
      match.ls = levels + ' - ' + _.startCase(sections)
    } else if (isPlayoff) {
      match.ls = levels + ' - ' + _.startCase(poolKey)
    }
  }
}

const ammendResults_matchFromFlat = (match) => {
  let scores;
  for (var i = 1; i <= 5; i++) {
    if (match['away' + i] && match['home' + i]) {
      if (!scores) { scores = {} }
      scores[i] = { away: 0, home: 0 }
      if (match['away' + i]) { scores[i].away = match['away' + i] }
      if (match['home' + i]) { scores[i].home = match['home' + i] }
    }
  }
  return scores
}

const ammendResults_match = (match, gameScoringType, _scores_flat, allowScoreAdvance) => {

  const { results, _results, } = match

  // if the _results (ammended from data) does not exist, set it to results_pure
  if (!_results && results) { match._results = results }

  switch (gameScoringType) {
    case gameScoringTypes.match:
    case gameScoringTypes.matchPoints:
      ammendScores_match(match, _scores_flat, allowScoreAdvance)
      ammendScore_match(match)
      break;
    default:
      ammendScore_game(match)
  }

}

/**
 * returns a results with no data
 * @param {*} results 
 * @param {*} tempScores 
 */
const setResultsBase = (match, tempScores) => {

  const { home, away, results } = match
  const { score, scores } = results ?? {}

  const _results = {
    home,
    away,
    setsCompleted: 0,
    complete: false,
    // winner: null,
    // loser: null,
    // tied: null, 
    setWinners: {},
    tempScoreCount: (tempScores) ? Object.keys(tempScores).length : 0,
    scoreDisplays: {
      normal: '-',
      home: '',
      full: '',
      scores: '',
      set: ''
    },
    scoreDisplays_flip: {
      normal: '-',
      home: '',
      full: '',
      scores: '',
      set: ''
    },
    score: score ? score : {},
    scores: scores ? scores : {},
  }

  match._results = _results
}

const ammendScoreDisplays = (match, set_number) => {

  const { results, _results = {} } = match;
  const { scores = {}, score = {}, scoreDisplays = {} } = _results;

  const showResults = !!results;

  // Normal and Home displays
  scoreDisplays.normal = showResults ? `${score.away} - ${score.home}` : '-';
  scoreDisplays.home = showResults ? `${score.home} - ${score.away}` : '-';

  // Full display
  const awayName = _.isObject(match.away) ? match.away.name : match.away;
  const homeName = _.isObject(match.home) ? match.home.name : match.home;

  scoreDisplays.full = awayName && homeName && !_.isEmpty(score)
    ? `${awayName} ${score.away} - ${score.home} ${homeName}`
    : `${awayName || ''} vs ${homeName || ''}`;

  // Set displays
  if (scores && set_number) {
    const setScore = scores[set_number] || { home: 0, away: 0 };
    scoreDisplays.setNumber = set_number;
    scoreDisplays.set = `${setScore.away} - ${setScore.home}`;
    scoreDisplays.set_current = `${setScore.away} - ${setScore.home}`;
    _results.setScore = setScore;
  }

  // Flip displays
  if (match._flipHa) {
    _results.scoreDisplays_flip = {
      normal: `${score.home} - ${score.away}`,
      full: `${homeName} ${score.home} - ${score.away} ${awayName}`,
      set: `${_results.setScore?.home || 0} - ${_results.setScore?.away || 0}`
    };
  }

  const scoreString = Object.keys(scores)
    .map(key => `${scores[key].away}-${scores[key].home}`)
    .join(', ');

  _results.scoreDisplays.scores = scoreString

  const totalAwayPoints = Object.values(scores).reduce((total, setScore) => total + (setScore.away || 0), 0);
  const totalHomePoints = Object.values(scores).reduce((total, setScore) => total + (setScore.home || 0), 0);
  const pointDifference = Math.abs(totalAwayPoints - totalHomePoints);
  const teamWithMostPoints = totalAwayPoints > totalHomePoints ? awayName : homeName;

  // Include point difference and team with most points in the score displays
  scoreDisplays.pointDifference = { team: teamWithMostPoints, points: pointDifference };
}

/**
 * 
 * @param {object} match 
 */
const ammendScore_match = (match) => {

  const { bestOf, setCount, results, _status } = match
  const { setNumber } = results ?? {}

  const { setsToWin } = _status
  const stw = setsToWin ? setsToWin : 3

  ammendScoreCalc(match, bestOf, setCount, setNumber, stw)

}

const ammendScoreCalc = (match, bestOf, setCount, setNumber, stw) => {

  const { _results } = match

  if (_results.score) {

    let winnerDeclared = false

    // did a team win?
    if (bestOf) {
      if ((_results.score.home === stw || _results.score.away === stw) && (_results.score.home >= 0 && _results.score.away >= 0)) {
        winnerDeclared = true
      }
    } else {
      if ((_results.score.home + _results.score.away === setCount)) {
        winnerDeclared = true
      }
    }

    if (winnerDeclared) {
      _results.complete = true
      _results.inProgress = false
      // which team won
      if (_results.score.home === stw) {
        _results.winner = match.home
        _results.loser = match.away
        _results.winningTeam = 'home'
      } else {
        _results.winner = match.away
        _results.loser = match.home
        _results.winningTeam = 'away'
      }
    }

    if (match.away && match.home && _results.score) {
      if ((_results.score.home === stw || _results.score.away === stw) && (_results.score.home >= 0 && _results.score.away >= 0)) {
        _results.complete = true
        delete _results.inProgress
        if (_results.score.home === stw) {
          _results.winner = match.home
          _results.loser = match.away
          _results.winner = match.home
          _results.winningTeam = 'home'
        } else if (_results.score.away === stw) {
          _results.winner = match.away
          _results.loser = match.home
          _results.winningTeam = 'away'
        }
      }

      _results.setsCompleted = _results.score.home + _results.score.away
      _results.officialResult = true
      _results.match_complete_possible = (_results.score.home >= stw) || (_results.score.away >= stw) ? true : false

    }

  }
}

/**
 * 
 * @param {object} match 
 */
const ammendScore_game = (match) => {

  let { _results, results_edit } = match
  let _res = results_edit ? copyObj(results_edit) : copyObj(_results)

  let { score } = _res ?? {}

  // do we have a score?
  if (score) {

    let winnerDeclared = true

    if (winnerDeclared) {
      _res.complete = true
      _res.inProgress = false
      // which team won
      if (score.home > score.away) {
        _res.winner = match.home
        _res.loser = match.away
      } else if (score.away > score.home) {
        _res.winner = match.away
        _res.loser = match.home
      } else {
        if (match.away && match.home) {
          _res.tied = [match.away.id, match.home.id]
        }
      }
    }

    if (match.away && match.home && score) {
      _res.setsCompleted = score.home + score.away
      _res.officialResult = true
    }
  }

  // match._results = _res
}

/**
 * ammends the scores to an integer
 * @param {*} scores 
 */
const ammendScoresFix = (scores) => {
  if (scores) {
    Object.keys(scores).forEach(setNumber => {
      if (scores[setNumber]) {
        if (scores[setNumber].away) { scores[setNumber].away = parseInt(scores[setNumber].away) }
        if (scores[setNumber].home) { scores[setNumber].home = parseInt(scores[setNumber].home) }
      }
    })
  }
}

const ammendTwoPointScores = (scores) => {
  // loop the scores to get the score
  _.forEach(scores, (score, setIndex) => {

    let set_index = parseInt(setIndex) + 1

    if (set_index <= 4) {
      if (score.home > maxPoints && score.away < score.home && score.away !== 0) { score.away = score.home - 2 }
      if (score.away > maxPoints && score.home < score.away && score.home !== 0) { score.home = score.away - 2 }
    } else {
      if (score.home > maxPoints5 && score.away < score.home && score.away !== 0) { score.away = score.home - 2 }
      if (score.away > maxPoints && score.home < score.away && score.home !== 0) { score.home = score.away - 2 }
    }
  })
}

const ammendScoresAndMatchWinners = (_results, allowScoreAdvance) => {

  let set_number = 0

  const score = {
    home: 0,
    away: 0
  }

  const { scores } = _results

  // loop the scores
  _.forEach(scores, (scores_set, setIndex) => {

    set_number++

    const h = parseInt(scores_set.home, 10)
    const a = parseInt(scores_set.away, 10)

    if ((h > 0) || (a > 0)) { set_number = parseInt(setIndex) }

    if (!_results.setWinners) { _results.setWinners = {} }

    // a team has more than 25 points and the diff > 2
    if (setIndex <= 4) {
      if (((h >= maxPoints) || (a >= maxPoints)) && Math.abs(h - a)) {
        if (allowScoreAdvance && (h > a)) { score.home++ }
        if (allowScoreAdvance && (h < a)) { score.away++ }
        _results.setWinners[setIndex] = (h > a) ? 'home' : 'away'
      }
    } else {
      if (((h >= maxPoints5) || (a >= maxPoints5)) && Math.abs(h - a)) {
        if (allowScoreAdvance && (h > a)) { score.home++ }
        if (allowScoreAdvance && (h < a)) { score.away++ }
        _results.setWinners[setIndex] = (h > a) ? 'home' : 'away'
      }
    }
  })

  if (allowScoreAdvance && scores) {
    _results.score = score
  }

  return set_number

}

/**
 * ammends the scores
 * @param {object} match 
 */
const ammendScores_match = (match, _scores_flat, allowScoreAdvance) => {

  const _allowScoreAdvance = true

  const { results, _results, _status } = match
  const { score, scores } = results ?? {}
  let { setsToWin } = _status ?? {}
  const stw = setsToWin ? setsToWin : 3

  if (_scores_flat) {
    console.log('_scores_flat', _scores_flat)
  }

  if (!_results.scores && _scores_flat) {
    _results.scores = _scores_flat
  }

  // SCORES
  if (scores) {

    // set score base if needed
    if (!_results.score) { _results.score = { home: 0, away: 0 } }
    if (!_results.score.home) { _results.score.home = 0 }
    if (!_results.score.away) { _results.score.away = 0 }

    ammendScoresFix(_results.scores)
    ammendTwoPointScores(_results.scores)

    const set_number = ammendScoresAndMatchWinners(_results, _allowScoreAdvance)

    _results.setsCompleted = _results.score.home + _results.score.away

    _results.hasScores = true
    _results.setNumber = set_number
    const pointsToWinSet = set_number === 5 ? maxPoints5 : maxPoints

    try {
      const currentSet = _results.scores?.[set_number];
      const homeScore = currentSet?.home ?? 0;
      const awayScore = currentSet?.away ?? 0;
      _results.set_complete_possible = (homeScore >= pointsToWinSet || awayScore >= pointsToWinSet) &&
        Math.abs(homeScore - awayScore) >= 2;
    } catch (error) {
      console.log('e', error)
    }

    _results.match_complete_possible = (_results.score.home >= stw) || (_results.score.away >= stw) ? true : false

  }

}


/**
 * Ammends the match
 * @param {object} match 
 */
const ammendMatchDate = (match) => {

  // update match date and time
  if (match.startDate && match.startTime) {

    const matchDate = new Date(match.startDate);
    const todaysDate = new Date()
    const matchTime = parseInt(match.startTime.substring(0, 1), 10) + 12

    const realMatchDate = new Date(matchDate.getFullYear(), matchDate.getMonth(), matchDate.getDate())
    const realTodaysDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate())

    const realMatchDateAndTime = new Date(matchDate.getFullYear(), matchDate.getMonth(), matchDate.getDate(), matchTime, 0, 0)

    match.startDate = formatItem(formatTypes.shortestDate, matchDate)

    match.startTimeUTC = realMatchDateAndTime
    match.startDateUTC = realMatchDate

    const today = new Date()
    const yesterday = today.setDate(today.getDate() - 1)

    match._status.isPast = new Date(matchDate) <= yesterday
    match._status.isFuture = new Date(matchDate) > today

    if (realMatchDate <= realTodaysDate) {
      match._status.isToday = true
    }

    if (match.home && match.away) {
      match._status.matchName = match.away.name + ' @ ' + match.home.name
    }

    if (match.away && match.away.name === 'BYE') {
      match._status.isBye = true
    }
  } else if (match.startDateUTC && match.startTimeUTC) {
    match.startDate = formatItem(formatTypes.shortestDate, match.startDateUTC)
  }
}

// export const matchCountdown = (match) => {

//   const matchTime = match.realDateAndTime
//   const todaysDate = new Date()
//   const realTodaysDateAndTime = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate(), todaysDate.getHours(), todaysDate.getMinutes(), todaysDate.getSeconds())

//   let diff = differenceInSeconds(matchTime, realTodaysDateAndTime)
//   diff = Math.floor(diff);

//   let days = Math.floor(diff / (24 * 60 * 60))
//   let leftSec = diff - days * 24 * 60 * 60

//   let hrs = Math.floor(leftSec / (60 * 60));
//   leftSec = leftSec - hrs * 60 * 60;

//   let min = Math.floor(leftSec / (60));
//   leftSec = leftSec - min * 60;

//   if (hrs < 10) { hrs = '0' + hrs }
//   if (min < 10) { min = '0' + min }
//   if (leftSec < 10) { leftSec = '0' + leftSec }

//   return hrs + ':' + min + ':' + leftSec

// }

