import _ from 'lodash';
import { createRefPath, createRefPath_event } from "./appRefPaths";
import { fs_dbu } from "./fsAppDataUpdate";
import { fsfn_deleteEventCollection } from "../../functions/fbDelete";
import { updateSettings } from "../settings/updateSettings";
import { removeFromObject } from '../../common/sorting';
import { dataFix } from '../../common/dataFix';

const _allowUpdates = false

/**
 * Deletes either a single collection (if `colletionName` is passed in) or each dataCollection in the 'dataCollections` object.
 * @param {*} state 
 * @param {*} collectionName 
 * @param {*} updateTheSettings 
 * @param {*} updateOnly 
 * @param {*} allowMerge 
 * @param {*} showNewOnly 
 * @param {*} callback 
 */
export const recreateAllDataCollectionsOneByOne = async (state, collectionName, updateTheSettings, updateOnly, allowMerge, showNewOnly, callback) => {

  const updateStaticViews = true

  const { dashboardInfo_preview, previewStatic, pathViews, dashboardInfo_recreate, appDataSource } = state ?? {}
  const { projectData } = dashboardInfo_preview ?? {}
  const { dataCollections } = projectData ?? {}
  const dataCollectionKeys = Object.keys(dataCollections)

  const dcUpdates = initDcUpdates(dataCollectionKeys, collectionName)

  const callback_settings = () => callback(dcUpdates, true)

  const checkDone = (updates) => {
    let done = true
    Object.keys(updates).forEach(key => {
      const update = updates[key]
      if (update.applied && !update.updated) {
        done = false
      }
    })
    if (done) {
      ammendStaticViewsPromise(updateStaticViews, pathViews, previewStatic, collectionName).then(res => {
        if (updateTheSettings && dashboardInfo_recreate) {
          const { projectSettings } = dashboardInfo_recreate ?? {}
          updateSettings.createNewViewsAndViewItemsSettingsToDb(pathViews, projectSettings, callback_settings)
          return false
        } else {
          return done
        }
      })
    }

  }


  // loop the dataCollections
  dataCollectionKeys.forEach((collectionKey, index) => {

    let allowUpdate = false

    if (collectionName) {
      if (collectionName === collectionKey) {
        allowUpdate = true
      }
    } else {
      allowUpdate = true
    }

    if (allowUpdate) {

      const dataCollectionData = dataCollections[collectionKey]
      let _dataCollectionData;

      if (showNewOnly) {
        _dataCollectionData = _.filter(dataCollectionData, { _new: true })
      } else {
        _dataCollectionData = dataCollectionData
      }

      dcUpdates[collectionKey].applied = true
      dcUpdates[collectionKey].updating = true

      callback(dcUpdates)

      recreateDataCollection(pathViews, collectionKey, _dataCollectionData, updateOnly, allowMerge, appDataSource).then(res => {
        dcUpdates[collectionKey].updating = false
        dcUpdates[collectionKey].updated = true
        callback(dcUpdates, checkDone(dcUpdates))
      })
    }
  })
}

/**
 * inits the dcUpdates
 * @param {*} dataCollectionKeys 
 * @returns dcUpdates
 */
const initDcUpdates = (dataCollectionKeys, collectionName) => {
  const dcUpdates = {}
  dataCollectionKeys.forEach(k => {
    if (collectionName) {
      if (k === collectionName) {
        dcUpdates[k] = { updating: false, updated: false, applied: false }
      }
    } else {
      dcUpdates[k] = { updating: false, updated: false, applied: false }
    }
  })
  return dcUpdates
}

const ammendStaticViewsPromise = async (updateStaticViews, pathViews, previewStatic, collectionName) => {
  const promises = []
  if (updateStaticViews) {
    Object.keys(previewStatic).forEach(psKey => {
      let allow = false
      if (collectionName) {
        allow = collectionName === psKey
      }
      const previewStaticItem = previewStatic[psKey]
      const _refPath = createRefPath_event(pathViews, ['staticViews', psKey])
      promises.push(fs_dbu.set_doc(_refPath, previewStaticItem, null, null, true))
    })
  }
  return Promise.all(promises)
}

export const recreateAllDataCollections = async (state, collectionName, updateOnly) => {
  const { dashboardInfo_preview } = state ?? {}
  const { projectData } = dashboardInfo_preview ?? {}
  const { dataCollections } = projectData ?? {}
  const dataCollectionKeys = collectionName ? [collectionName] : Object.keys(dataCollections)
  const result = await recreateDataCollections(state, collectionName, updateOnly)
  const items = {}
  if (result) {
    dataCollectionKeys.forEach((k, index) => {
      items[k] = result[index] ? 'Updated ' + result[index].length + ' Document(s)' : "No Documents Updated"
    })
  }
  return items
}

const recreateDataCollections = async (state, collectionName, updateOnly) => {
  const promises = []
  const { pathViews, dashboardInfo_preview } = state ?? {}
  const { projectData } = dashboardInfo_preview ?? {}
  const { dataCollections } = projectData ?? {}
  if (dataCollections) {
    Object.keys(dataCollections).forEach(collectionKey => {
      if (collectionName) {
        if (collectionKey === collectionName) {
          promises.push(recreateDataCollection(pathViews, collectionKey, dataCollections[collectionKey], updateOnly))
        }
      } else {
        promises.push(recreateDataCollection(pathViews, collectionKey, dataCollections[collectionKey], updateOnly))
      }
    })
  }
  return Promise.all(promises)
}

/**
 * Deletes the data collection and adds the data to a promise
 * @param {object} pathViews 
 * @param {string} collectionKey 
 * @param {object} dataCollectionData 
 * @returns a promise
 */
export const recreateDataCollection = async (pathViews, collectionKey, dataCollectionData, updateOnly, allowMerge, appDataSource) => {
  const promises = []
  // const _refPath = createRefPath_event(pathViews, [collectionKey])
  // const _existingData = await fs_db.get_data({ refPath: _refPath })
  // if (_existingData) {

  if (!updateOnly) {
    await fsfn_deleteEventCollection(pathViews, collectionKey, 100)
    await removeStaticView(pathViews, collectionKey)
  }

  promises.push(addDataCollectionPromise(pathViews, collectionKey, dataCollectionData, updateOnly, allowMerge, appDataSource))

  return Promise.all(promises)
}

const removeStaticView = async (pathViews, collectionKey) => {
  const _refPath = createRefPath_event(pathViews, ['staticViews', collectionKey])
  await fs_dbu.delete_doc(_refPath)
}

/**
 * Add a collection based on the collectionKey with the data in dataToUpdate
 * @param {string} collectionKey 
 * @param {object} dataToUpdate 
 * @returns a promise
 */
const addDataCollectionPromise = async (pathViews, collectionKey, dataCollectionData, updateOnly, allowMerge, appDataSource) => {

  const { useAppDataDocuments, useAppDataDocumentsOnly } = appDataSource ?? {}

  const promises = []
  const _refPath = createRefPath_event(pathViews, [collectionKey])

  console.log('updateOnly, allowMerge', updateOnly, allowMerge)

  const addToPromise = (data, key) => {

    if (data) {

      dataFix.removeDeleteFields(data)
      dataFix.removeAllEmpties(data)
      removeFromObject(data, ['id', '_itemKey'])

      const _updatePath = createRefPath([key], _refPath)

      if (updateOnly) {
        if (allowMerge) {
          if (_allowUpdates) {
            promises.push(fs_dbu.set_doc(_updatePath, data, true, null, true))
          } else {
            // console.log('addDataCollectionPromise - update/merge', _updatePath, data)
          }
        } else {
          if (_allowUpdates) {
            promises.push(fs_dbu.update_doc(_updatePath, data, null, true))
          } else {
            // console.log('addDataCollectionPromise - update only', _updatePath, data)
          }
        }
      } else {
        if (_allowUpdates) {
          promises.push(fs_dbu.set_doc(_updatePath, data, null, null, true))
        } else {
          // console.log('addDataCollectionPromise - set only', _updatePath, data)
        }
      }
    }
  }

  if (dataCollectionData) {

    if (useAppDataDocuments) {
      updateDocument(dataCollectionData, pathViews)
    }

    if (!useAppDataDocumentsOnly) {
      if (_.isArray(dataCollectionData)) {
        dataCollectionData.forEach(data => {
          const { _itemKey } = data
          addToPromise(data, _itemKey)
        })
      } else {
        Object.keys(dataCollectionData).forEach(key => {
          const data = dataCollectionData[key]
          addToPromise(data, key)
        })
      }
    }

    // Object.keys(dataCollectionData).forEach(key => {
    //   if (key) {
    //     const data = dataCollectionData[key]
    //     removeDeleteFields(data)
    //     removeAllEmpties(data)
    //     if (data) {
    //       const _updatePath = createRefPath([key], _refPath)
    //       if (updateOnly) {
    //         if (allowMerge) {
    //           if (_allowUpdates) {
    //             promises.push(fs_dbu.set_doc(_updatePath, data, true, null, true))
    //           } else {
    //             console.log('addDataCollectionPromise - update/merge', _updatePath, data)
    //           }
    //         } else {
    //           if (_allowUpdates) {
    //             promises.push(fs_dbu.update_doc(_updatePath, data, null, true))
    //           } else {
    //             console.log('addDataCollectionPromise - update only', _updatePath, data)
    //           }
    //         }
    //       } else {
    //         if (_allowUpdates) {
    //           promises.push(fs_dbu.set_doc(_updatePath, data, null, null, true))
    //         } else {
    //           console.log('addDataCollectionPromise - set only', _updatePath, data)
    //         }
    //       }
    //     }
    //   }
    // })
  }
  return Promise.all(promises)
}

const updateDocument = async (dataCollectionData, pathViews) => {
  const documentsData = {}
  _.forEach(dataCollectionData, (dataCollectionItem, itemKey) => {
    documentsData[itemKey] = dataCollectionItem
  })
  const results = await updateDocuments(pathViews, documentsData)
  return results
}

const updateDocuments = async (pathViews, documentsData) => {
  const xxx = await updateDocumentsPromise(pathViews, documentsData)
  return xxx
}

const updateDocumentsPromise = (pathViews, documentsData) => {
  const promises = []
  _.forEach(documentsData, (itemData, viewKey) => {
    const _refPathDoc = createRefPath_event(pathViews, ['_documents', viewKey])
    const docToUpdate = {}
    _.forEach(itemData, (data, itemKey) => {
      docToUpdate[itemKey] = dataFix.createDeleteFields(data)
    })
    promises.push(fs_dbu.set_doc(_refPathDoc, docToUpdate))
    // promises.push(fs_dbu.update_doc(_refPathDoc, docToUpdate))
  })
  return Promise.all(promises)
}

/**
 * Deletes a collection based on the collectionKey
 * @param {string} collectionKey 
 * @param {object} existingData 
 * @returns a promise
 */
const deleteDataCollectionPromise = async (pathViews, collectionKey, existingData) => {
  const promises = []
  const _refPath = createRefPath_event(pathViews, [collectionKey])
  if (existingData) {
    Object.keys(existingData).forEach(key => {
      const _deletePath = createRefPath([key], _refPath)
      promises.push(fs_dbu.delete_doc(_deletePath, null, true))
    })
  }
  return Promise.all(promises)
}