import _ from 'lodash';
import React, { useContext } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { daysSinceTimestamp } from '../common/dateFormatting';
import { uniqueKey } from '../common/keys';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import UiHeader from '../pageItem/UiHeader';

const AppUsersPresence = () => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { eventInfo_state } = states
  const { appUsersPresense } = eventInfo_state ?? {}
  const { online, offline } = appUsersPresense ?? {}

  const menuItems = (items, online) => {
    const sortedItems = items && items.length > 0 ? _.sortBy(items, "lastName") : [];
    const mis = _.map(sortedItems, (item, key) => {
      const { displayName, email, timestamp } = item
      const _ts = timestamp ? new Date(timestamp.toDate()).toLocaleString() : ''
      const _tsd = timestamp ? daysSinceTimestamp(timestamp) : ''
      return <Menu.Item key={uniqueKey('aup', key)}>
        <Menu.Header>{displayName} </Menu.Header>
        <Menu.Menu>
          <Menu.Item key={uniqueKey('aup', key, 1)}>
            <Icon name='check circle' color={online ? 'green' : 'grey'} />{_ts}{' ('}{_tsd + ' days ago'}{')'}
          </Menu.Item>
          <Menu.Item key={uniqueKey('aup', key, 2)}>
            {email}
          </Menu.Item>
        </Menu.Menu>
        {/* {icon(item)}
        {item.count && <Label attached='bottom right' size='mini' color='black'>{item.count}</Label>} */}
      </Menu.Item>
    })
    return mis
  }

  const menu = () => {
    const menus = []
    menus.push(<Menu inverted vertical fluid size='tiny'>
      {menuItems(online, true)}
    </Menu>)
    menus.push(<Menu inverted vertical fluid size='tiny'>
      {menuItems(offline)}
    </Menu>)
    return menus
  }

  return <Wrapper
    header={<UiHeader caption={'App Users'} />}
    content={menu()}
    wrapperType={wrapperTypes.padded}
    css={{ container: 'app-dark-mode' }}
  ></Wrapper >

}

export default AppUsersPresence
