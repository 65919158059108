import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { AppSettingsContext } from '../../cnr/contexts/AppSettingsContext';
import { thumbSwipeHandlers, thumbSwipeInitialState, thumbSwipeReducer } from '../../cnr/reducers/ThumbSwipeReducer';
import { gEnums } from '../../enums/globalEnums';
import { svgElements, svgTypes } from '../svg/svgElements';
import { ImageMapContext } from './ImageMapping';
import ThumbCourt from './ThumbCourt';
import svgCanvas from '../svg/svgCanvas';
import { svgCategoryProps } from '../svg/svgCategoryProps';
import { courtSectors } from '../svg/sectors';

// const dims = { w: 360, h: 540 }

// const usePerc = false

const _mins = {
  h: 20,
  w: 20
}

const ThumbSwipe = (props) => {

  const { url, handleZoneClick } = props

  const imageMapContext = useContext(ImageMapContext)
  const { imageMap_state, imageMap_handlers } = imageMapContext ?? {}
  const { currentElements, currentZone, imageMapType, currentLocation_mapped } = imageMap_state ?? {}
  const { zone } = currentLocation_mapped ?? {}

  const allowNew = imageMapType === gEnums.imageMapTypes.zoneMapping

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_state } = appSettingsContext ?? {}
  const { currentDevice } = appSettings_state ?? {}
  const { msettings } = currentDevice ?? {}
  const { h, w } = msettings ?? {}

  let imageStyle = msettings ? { height: h, width: w } : { height: 360, width: 540 }

  // let svgStyle = { verticalAlign: 'top', touchAction: 'none', position: 'relative' }
  // if (msettings) {
  //   svgStyle = { ...svgStyle, ...{ height: h, width: w } }
  // } else {
  //   svgStyle = { ...svgStyle, ...{ height: 360, width: 540 } }
  // }

  // imageStyle = { height: 540, width: 360 }
  // svgStyle = { height: 540, width: 360, verticalAlign: 'top', touchAction: 'none', position: 'relative' }

  // action
  const [thumbSwipe_state, thumbSwipe_dispatch] = useReducer(thumbSwipeReducer, thumbSwipeInitialState({ imageStyle }));
  const thumbSwipe_handlers = thumbSwipeHandlers(thumbSwipe_dispatch, thumbSwipe_state)
  const { currentSvgElements, currentElem, lastActionElement, done } = thumbSwipe_state
  const { handleTouchStart, handleTouchMove, handleTouchEnd } = thumbSwipe_handlers

  // local state
  const [imageDims, setImageDims] = useState()
  const [_svgElements, set_svgElements] = useState([])
  const [_currentElement, setCurrentElement] = useState([])

  const _swipeDiv = useRef(null);
  const [thisCourt, setThisCourt] = useState({ maxHeight: 300 })
  const [sectors, setSectors] = useState(courtSectors(w, thisCourt))
  const [categorySVGProps, setCategorySVGProps] = useState()
  const [zoneStatElements, setZoneStatElements] = useState({ pass: [], oppAttack: [] })
  const [actionLineElements, setActionLineElements] = useState([])
  const [totalDuration, setTotalDuration] = useState(0)
  const [settings, setSettings] = useState({ allowHolder: true })
  const [timerTotal, setTimerTotal] = useState()

  useEffect(() => {
    thumbSwipe_handlers.handleDiv(_swipeDiv)
    thumbSwipe_handlers.handleSvgType(svgTypes.rectangle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    thumbSwipe_handlers.handleSet_currentElements(currentElements)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentElements]);

  useEffect(() => {
    if (currentSvgElements) {
      const se = svgElements(getEps(currentSvgElements), null, null, null, handleZoneClick, imageDims)
      set_svgElements(se)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSvgElements, imageDims]);

  useEffect(() => {
    const se = svgElements(null, currentElem, null, null, handleZoneClick, imageDims)
    setCurrentElement(se)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentElem, imageDims]);

  useEffect(() => {
    const se = svgElements(null, null, currentZone, null, handleZoneClick, imageDims)
    setCurrentElement(se)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentZone, imageDims]);

  useEffect(() => {
    if (sectors) {
      const courtSwipe = {
        maxHeight: 450,
        margin: 0,
        border: 4,
        props: {
          courtColor: 'blue',
          lineColor: 'black',
        },
        style: function () {
          return {
            margin: 2,
            border: '1 px solid',
          }
        }
      }
      const x = svgCategoryProps('volleyball', sectors, courtSwipe)
      setCategorySVGProps(x)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectors]);

  useEffect(() => {
    if (lastActionElement && done === true) {
      if ((lastActionElement.w >= _mins.w) && (lastActionElement.h >= _mins.h)) {
        const fixed = 5
        const { width: w_i, height: h_i } = imageDims ? imageDims : { width: 100, height: 100 }
        lastActionElement.parent = {
          width: w_i,
          height: h_i
        }
        const r_w = lastActionElement.w / w_i
        const r_h = lastActionElement.h / h_i
        const r_x = lastActionElement.sx / w_i
        const r_y = lastActionElement.sy / h_i

        lastActionElement.percs = {
          w: (r_w).toFixed(fixed) * 100,
          h: (r_h).toFixed(fixed) * 100,
          sx: (r_x).toFixed(fixed) * 100,
          sy: (r_y).toFixed(fixed) * 100,
        }

        imageMap_handlers.handleAdd_newZone(lastActionElement)
      }
      imageMap_handlers.handleRemove_currentZone()
      thumbSwipe_handlers.handleReset()

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done]);

  const getEps = (elems) => {
    const eps = {
      elems: [...elems],
    }
    return eps
  }

  const handleLoad = ({ target: img }) => setImageDims({ height: img.offsetHeight, width: img.offsetWidth })

  const svg_touch = (currentOnly) => <svg
    // viewBox="0 0 200 200"
    // viewBox="0 0 100 100"
    ref={_swipeDiv}
    onTouchStart={allowNew ? handleTouchStart : null}
    onTouchMove={allowNew ? handleTouchMove : null}
    onTouchEnd={allowNew ? handleTouchEnd : null}
    onMouseDown={allowNew ? handleTouchStart : null}
    onMouseMove={allowNew ? handleTouchMove : null}
    onMouseUp={allowNew ? handleTouchEnd : null}
  // onLoad={handleLoad}
  >
    {/* {image()} */}
    {!currentOnly && _svgElements}
    {_currentElement}
  </svg>

  // eslint-disable-next-line
  const img = () => <img src={url} onLoad={handleLoad} />

  const svgWrapper = (currentOnly) => <div className="svg-wrapper full">
    {img()}
    {svg_touch(currentOnly)}
  </div>

  const svg_court = (currentOnly) => <div style={{
    textAlign: 'center',
    height: '50vh',
    backgroundColor: 'green'
  }} >
    {svgCanvas('statView-swipe', 400, 200, categorySVGProps, zoneStatElements, actionLineElements)}
    {svg_touch(currentOnly)}
  </div>

  const canvas_pinch = () => {
    const inits = { scale: 1, x: 0, y: 0 }
    return <TransformWrapper
      initialScale={inits.scale}
      initialPositionX={inits.x}
      initialPositionY={inits.y}>
      <TransformComponent>
        {svgWrapper(true)}
      </TransformComponent>
    </TransformWrapper>
  }

  // return <ThumbCourt />

  // return categorySVGProps ? svg_court() : <div></div>
  switch (imageMapType) {

    case gEnums.imageMapTypes.zoneMapping:
    case gEnums.imageMapTypes.locationMapping:
    case gEnums.imageMapTypes.itemMapping:
      return svgWrapper()

    case gEnums.imageMapTypes.itemLocation:
      return canvas_pinch()

    case gEnums.imageMapTypes.courtMapping:
      return svg_court()

    default:
      return canvas_pinch()
  }

}

export default ThumbSwipe