import { allPageTypes } from '../../../enums/allPageTypes';

export const allowedItemsTickets = (props) => {

  const { parentContext, pageIcons, allows, gcProps } = props
  const { states } = parentContext ?? {}

  const { page_state } = states

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { appTicketing } = aps_global ?? {}
  const { ticketingCaption, allowTicketing, allowTicketingScan } = appTicketing ?? {}

  const _ticketCaption = ticketingCaption ? ticketingCaption : 'Ticketing Manager'
  const _ticketScanCaption = ticketingCaption ? 'Scan ' + ticketingCaption : 'Scan Ticketing'

  let _allowTm;
  let _allowTs;
  let allowTm = !pageIcons && allowTicketing
  let allowTs = !pageIcons && allowTicketingScan
  if (allowTm && allows.atm) { _allowTm = true }
  if (allowTs && allows.ats) { _allowTs = true }

  const allowedItem = (viewItem, segProps, gcProps, hidden) => {
    const { key } = viewItem ?? {}
    return { viewItem, itemKey: key, segProps, gcProps, hidden }
  }

  const items = []

  if (_allowTm) { items.push(allowedItem({ key: allPageTypes.ticketingManager }, { caption: _ticketCaption, icon: 'ticket', cn: 'ao' }, gcProps)) }
  if (_allowTs) { items.push(allowedItem({ key: allPageTypes.ticketingScan }, { caption: _ticketScanCaption, icon: 'qrcode', cn: 'ao' }, gcProps)) }

  return items

}