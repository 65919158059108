import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { frameworkHandlers, frameworkReducer, frameworkReducerInitialState } from '../reducers/FrameworkReducer';
import { ParentContext } from './ParentContext';

export const FrameworkContext = createContext();

/**
 * 
 * @param {object} props 
 * @returns a simple FrameworkContext providing framework_state and framework_handlers
 */
const FrameworkProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state, page_state, appSettings_state, appUser_state } = states ?? {}
  const { appUser } = appUser_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_page } = pageSettings ?? {}
  const { viewItems } = aps_page ?? {}

  const { view, pathViews, appViewMode } = paps_state ?? {}
  const { desktopOn, desktopMobileOn } = appSettings_state ?? {}

  const init_state = { ...props, view, pathViews, appViewMode, appUser }
  const [framework_state, functions_dispatch] = useReducer(frameworkReducer, frameworkReducerInitialState(init_state));
  const framework_handlers = frameworkHandlers(functions_dispatch)

  useEffect(() => {
    framework_handlers.handleInit_framework(parentContext)
    framework_handlers.handleClear_navigationItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [viewItems, pathViews.events, pathViews.clients]);

  return <FrameworkContext.Provider value={{ framework_state: { ...framework_state, desktopMode: desktopOn, desktopMobileOn: desktopMobileOn }, framework_handlers }}>
    {props.children}
  </FrameworkContext.Provider>
};

export default FrameworkProvider