import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { setStyleAndClass } from '../../styles/formatting';
import { getPageSettingsProps } from '../../viewSettings/helpers/pageSettings';
import { pageFunctions, pageHandlers, pageInitialState, pageReducer } from '../reducers/PageReducer';
import { AppSettingsContext } from './AppSettingsContext';
import { AuthContext } from './AuthContext';
import { PapsContext } from './PapsContext';
import { PreviewContext } from './PreviewContext';
import { ClientSettingsContext, EventSettingsContext, HomeSettingsContext } from './SettingsDataContext';

export const PageContext = createContext();

const PageProvider = (props) => {

  // ParentContext - not available     

  const authContext = useContext(AuthContext)
  const { auth_state } = authContext ?? {}
  const { currentAuthUser } = auth_state ?? {}
  const { isAppDeveloper } = currentAuthUser ?? {}

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext);
  const { appSettings_state } = appSettingsContext ?? {}
  const { settingsPreviewOn, googleSettings } = appSettings_state ?? {}

  // previewContext
  const previewContext = useContext(PreviewContext)
  const { preview_state } = previewContext ?? {}
  const { settingsPreviewHasChanged, settingsPreviewStatus, settings_googleSheets } = preview_state ?? {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state, navigate } = papsContext ?? {}
  const { fullSettingsKey, pathName, view, otherView, pathViews, lastView, landingView } = paps_state ?? {}
  let { viewKey, getTrueView, pageKey } = paps_state ?? {}

  // settingsContexts 
  const homeSettingsContext = useContext(HomeSettingsContext);
  const clientSettingsContext = useContext(ClientSettingsContext);
  const eventSettingsContext = useContext(EventSettingsContext);

  // homeSettings
  const { homeSettings_state } = homeSettingsContext ?? {}
  const { homeSettings } = homeSettings_state ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}
  const { logging: logging_home, appUrls: homeAppUrls } = homeSettings_global ?? {}

  const { clientSettings_state } = clientSettingsContext ?? {}
  const { eventSettings_state } = eventSettingsContext ?? {}

  const hceStates = { homeSettings_state, clientSettings_state, eventSettings_state }
  const homeClientEventProps = { pathViews, lastView, hceStates }
  const pageSettingsProps = getPageSettingsProps(homeClientEventProps)

  // get the actual view for the page
  let trueView = (viewKey || otherView) ? view : view + '_list'
  trueView = getTrueView(pathViews, view, trueView, landingView, viewKey)

  //pageReducer
  const page_init = { homeAppUrls }
  const [page_state, page_dispatch] = useReducer(pageReducer, page_init, pageInitialState)
  const page_handlers = pageHandlers(page_dispatch, page_state)
  const page_functions = pageFunctions(page_state, isAppDeveloper)

  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_page } = pageSettings ?? {}
  const { logging: logging_app } = aps_global ?? {}

  let { aps_viewItems } = pageSettings ?? {}

  // props
  const logging = { ...logging_home, ...logging_app }
  const { showAppSettings, showHomeSettings } = logging ?? {}

  // IMPORTANT: Page Settings- get the settings for the page
  useEffect(() => {
    const aspProps = {
      // appUser,
      pageKey,
      paps_state,
      pss: pageSettingsProps,
      preview_state,
      settingsPreviewOn,
      trueView,
      view,
      viewKey,
    }
    page_handlers.handleGetPageSettings(aspProps)
    // googlePreviewType, 
    // eslint-disable-next-line
  }, [fullSettingsKey, googleSettings, pathName, pageSettingsProps.time_stamp, settingsPreviewHasChanged, settingsPreviewOn, settings_googleSheets, settingsPreviewStatus, trueView, viewKey]);
  // settingsPreviewOn, settingsPreviewHasChanged

  if (showHomeSettings) console.log('homeSettings_global', homeSettings_global)
  if (showAppSettings) console.log('aps_global', aps_global)

  // IMPORTANT: Push - pushSimple
  const pushSimple = (clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex) => {
    const psProps = {
      aps_page,
      aps_viewItems,
      clickedItem,
      ddGroupIndex,
      ddn,
      ddProps,
      homeSettingsContext,
      isAppDeveloper,
      itemKey,
      modifyMode,
      opts,
      papsContext,
    }
    return page_functions.pushSimple(psProps)
  }

  const getVi = (uivi, isGlobal) => { return page_functions.getVi(uivi, isGlobal) }
  const getViRef = (uivi) => { return page_functions.getViRef(aps_viewItems, uivi, viewKey, pathViews) }
  const getGviDeps = (uivi) => { return page_functions.getGviDeps(aps_viewItems, uivi, viewKey, aps_page) }
  const goBackTo = (count) => page_functions.goBackTo(navigate, count)
  const pushUrl = (e, url) => page_functions.pushUrl(e, url)
  const syncViewItems = (gvs, vis, vs) => page_functions.syncViewItems(gvs, vis, vs)
  const pushDrillDown = (e, viewItem, itemData, pvKey, propItem, ddProps) => page_functions.pushDrillDown(e, homeSettingsContext, papsContext, viewItem, itemData, pvKey, propItem, ddProps)
  const getLink = (appUrlz, name, isMobileDevice) => {
    const x = _.find(appUrlz, { appName: name })
    if (x) {
      if (!isMobileDevice) {
        if (x.appDesktopUrl) {
          return x.appDesktopUrl
        } else {
          return x.appUrl
        }
      } else {
        return x.appUrl
      }
    }
  }

  const getGVIProps = (vit, viewItem, storage_fns, desktopMode) => {

    const gvi = aps_viewItems[vit]
    const { display: display_g } = gvi ?? {}
    const { display, desktopDisplay } = viewItem ?? {}
    const _display = desktopMode && desktopDisplay ? desktopDisplay : display

    let { caption, defaultIcon, iconImage, useIconImage } = display_g ?? {}

    caption = _display && _display.caption ? _display.caption : caption
    defaultIcon = _display && _display.defaultIcon ? _display.defaultIcon : defaultIcon

    iconImage = useIconImage && iconImage ? iconImage : null

    const segProps = {
      caption: caption ? caption : _.startCase(vit) + '*',
      icon: defaultIcon ? defaultIcon : 'file alternate',
      iconImage: iconImage ? iconImage : null,
      iconImageUrls: storage_fns.getIconUrl(useIconImage, iconImage)
    }

    if (caption) { segProps.caption = caption }
    if (defaultIcon) { segProps.icon = defaultIcon }

    return segProps

  }

  // functions 
  const page_fns = {
    getGviDeps,
    getVi,
    getViRef,
    goBackTo,
    pushDrillDown,
    pushSimple,
    pushUrl,
    syncViewItems,
    getLink,
    getGVIProps,
    setStyleAndClass: (aps_global, item, opts, ignoreColors, getColors) => setStyleAndClass(aps_global, item, opts, ignoreColors, getColors)
  }

  const contextProvider = () => {

    const cp = {
      homeSettings_global,
      logging,
      page_fns,
      page_handlers,
      page_state,
    }

    return <PageContext.Provider value={cp}>
      {props.children}
    </PageContext.Provider>
  }

  return pageSettings ? contextProvider() : <div></div>
}

export default PageProvider