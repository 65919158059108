export const defaultHelpers = {
  iconDefaults: () => iconDefaults,
}

export const withDefaultProps = (props, defaults) => {
  return { ...defaults, ...props };
};

const iconDefaults = {
  icon: 'default-icon',
  color: null,
  iconCorner: 'default-corner-icon',
  iconCornerColor: 'blue',
  showIconCorner: false
};