import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFbConfig } from "../../project/appConfiguration";
import { createResult, getFnResponseData } from "../altFunctions/common";

const _showCallLogs = false

/**
 * 
 * @param {enum} genericType 
 * @param {object} callData 
 * @returns - The  `data` {success,result, error} object from the sp
 */
export const callFunction = async (genericType, callData, useMe) => {

  const _useMe = !useMe ? true : useMe

  let ft;
  const _keys = Object.keys(callData)

  if (_keys) {
    _keys.forEach(key => {
      if (key.endsWith('FunctionType')) {
        ft = callData[key];
      }
    })
  }

  _showCallLogs && console.log('>>> callFunction: callData:', genericType, ft, callData)

  try {
    const config = getFbConfig(_useMe)
    const firebaseApp = _useMe ? initializeApp(config, 'alt') : initializeApp(config)
    const functions = getFunctions(firebaseApp);
    const _rsp = (await httpsCallable(functions, genericType)(callData))
    _showCallLogs && console.log('<<< callFunction: response:', _rsp)
    return getFnResponseData(_rsp)
  } catch (error) {
    console.log('callFunction - error', genericType, error)
    return createResult(false, {}, error)
  }
}

export const callFunctionDirect = async (functionType, callData) => {
  const functions = getFunctions();
  try {
    const _rsp = (await httpsCallable(functions, functionType)(callData))
    _showCallLogs && console.log('callFunctionDirect', functionType, callData, _rsp)
    return getFnResponseData(_rsp)
  } catch (error) {
    console.log('callFunction - error', error)
    return createResult(false, {}, error)
  }
}

export const fsfn_genericCall = async (type) => {
  try {
    const functions = getFunctions();
    const callData = { type }
    const _rsp = (await httpsCallable(functions, 'genericCall')(callData))
    _showCallLogs && console.log('fsfn_genericCall', getFnResponseData(_rsp))
  } catch (error) {
    return { data: { success: false } }
  }
}

