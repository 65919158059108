const pvits = {
  projects: 'projects',
  views: 'views',
  viewItems: 'viewItems',
  permissions: 'permissions',
  menuItemPermissions: 'menuItemPermissions',
  loginPermissions: 'loginPermissions',
}

export const key_pages = ['add', 'appSettings', 'create', 'edit', 'help', 'signIn', 'signOut', 'signUp', 'viewSettings', 'qrCode', 'videoConference']

const global_pages = {
  about: 'about',
  appSettings: 'appSettings',
  appProfile: 'appProfile',
  appUserInfo: 'appUserInfo',
  chat: 'chat',
  conversations: 'conversations',
  createDiscussion: 'createDiscussion',
  createMessage: 'createMessage',
  dataEdit: 'dataEdit',
  dataEditOther: 'dataEditOther',
  dataImport: 'dataImport',
  discussions: 'discussions',
  documents: 'documents',
  favorites: 'favorites',
  files: 'files',
  gallery: 'gallery',
  help: 'help',
  home: 'home',
  images: 'images',
  maps: 'maps',
  media: 'media',
  messages: 'messages',
  meetings: 'meetings',
  myInfo: 'myInfo',
  myProfile: 'myProfile',
  notifications: 'notifications',
  page: 'page',
  navOptions: 'navOptions',
  photos: 'photos',
  profiles: 'profiles',
  projectImport: 'projectImport',
  qrCode: 'qrCode',
  search: 'search',
  signIn: 'signIn',
  signInFormEvent: 'signInFormEvent',
  signOut: 'signOut',
  signUp: 'signUp',
  tickets: 'tickets',
  unclone: 'unclone',
  uploadDocument: 'uploadDocument',
  uploadImage: 'uploadImage',
  videoConference: 'videoConference',
  viewProfile: 'viewProfile',
  viewSettings: 'viewSettings',
  ...pvits
}

const project_pages = {
  activityFeeds: 'activityFeeds',
  airportTravel: 'airportTravel',
  appProfilesManager: 'appProfilesManager',
  appUsersManager: 'appUsersManager',
  appUserRequests: 'appUserRequests',
  attendees: 'attendees',
  bios: 'bios',
  busPasses: 'busPasses',
  busScheduleDates: 'busScheduleDates',
  busSchedules: 'busSchedules',
  busScheduleTimes: 'busScheduleTimes',
  carServices: 'carServices',
  cityGuides: 'cityGuides',
  cityInfos: 'cityInfos',
  clients: 'clients',
  credentialScan: 'credentialScan',
  courses: 'courses',
  documents: 'documents',
  events: 'events',
  exhibitors: 'exhibitors',
  facilitys: 'facilitys',
  golf: 'golf',
  golfers: 'golfers',
  hotels: 'hotels',
  imageMapping: 'imageMapping',
  infoBooth: 'infoBooth',
  instructors: 'instructors',
  locations: 'locations',
  maps: 'maps',
  meals: 'meals',
  meetingsManager: 'meetingsManager',
  merchandise: 'merchandise',
  myNotes: 'myNotes',
  myProfile: 'myProfile',
  notes: 'notes',
  notifications: 'notifications',
  notificationsManager: 'notificationsManager',
  officeLocations: 'officeLocations',
  organizations: 'organizations',
  parking: 'parking',
  pdfs: 'pdfs',
  qAndA: 'qAndA',
  qAndAManager: 'qAndAManager',
  reservations: 'reservations',
  restaurants: 'restaurants',
  rideShares: 'rideShares',
  rounds: 'rounds',
  securitys: 'securitys',
  sessionMaps: 'sessionMaps',
  sessions: 'sessions',
  shuttleServices: 'shuttleServices',
  speakers: 'speakers',
  sponsors: 'sponsors',
  survey: 'survey',
  registrationManager: 'registrationManager',
  registrationScan: 'registrationScan',
  surveyManager: 'surveyManager',
  test: 'test',
  ticketingManager: 'ticketingManager',
  ticketingScan: 'ticketingScan',
  todo: 'todo',
  topicsManager: 'topicsManager',
  transportationItems: 'transportationItems',
  transportations: 'transportations',
  transportationSubItems: 'transportationSubItems',
  transportationSubItems2: 'transportationSubItems2',
  transportationTypes: 'transportationTypes',
  vendors: 'vendors',
  venues: 'venues',
  videos: 'videos',
  volunteers: 'volunteers',
  voting: 'voting',
  votingManager: 'votingManager',
  wardrobes: 'wardrobes',
  weather: 'weather',
}

export const allPageTypes = {
  ...global_pages,
  ...project_pages,
}