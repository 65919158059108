import _ from 'lodash';
import React, { useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { Icon, Label, Segment } from "semantic-ui-react";
import { uniqueKey } from '../../../common/keys';

export const dictaphoneListenTypes = {
  name: 'name',
  lastName: 'lastName',
  match: 'match',
}

const UIDictaphone = (props) => {

  const { keyy, list, handleSelect, opts, labelOnly, dictaphoneListenType } = props

  const [isListening, setIsListening] = useState();
  const [names, setNames] = useState([])
  const [listenProp, setListenProp] = useState()

  const { finalTranscript, resetTranscript } = useSpeechRecognition()

  const [currentName, setCurrentName] = useState()
  const [currentAway, setCurrentAway] = useState()
  const [currentHome, setCurrentHome] = useState()
  const [currentMatch, setCurrentMatch] = useState({})

  useEffect(() => {
    switch (dictaphoneListenType) {
      case dictaphoneListenTypes.name:
        setListenProp('name')
        break;
      case dictaphoneListenTypes.lastName:
        setListenProp('lastName')
        break;
      case dictaphoneListenTypes.match:
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dictaphoneListenType]);

  useEffect(() => {
    if (finalTranscript && handleSelect && isListening && opts) {
      switch (dictaphoneListenType) {
        case dictaphoneListenTypes.name:
        case dictaphoneListenTypes.lastName:
          setCurrentName(finalTranscript)
          break;
        case dictaphoneListenTypes.match:
          if (currentAway && currentHome) {
            setCurrentAway(finalTranscript)
          } else {
            if (currentAway) {
              setCurrentHome(finalTranscript)
            } else {
              setCurrentAway(finalTranscript)
            }
          }
          break;
        default:
        // nothing
      }
      resetTranscript()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [finalTranscript]);

  useEffect(() => {
    switch (dictaphoneListenType) {
      case dictaphoneListenTypes.name:
      case dictaphoneListenTypes.lastName:
        if (currentName && handleSelect && isListening && opts) {
          console.log('currentName', currentName)
          let allow = true
          Object.keys(isListening).forEach(key => {
            if (opts[key] !== isListening[key]) {
              allow = false
            }
          })
          if (allow) {
            const _names = [...names]
            _names.push(currentName)
            setNames(_names)

            if (list) {
              let _name = currentName.replace(' ', '')
              _name = _.startCase(_name)
              let item = _.find(list, { [listenProp]: _name })
              let itemKey = _.findKey(list, { [listenProp]: _name })

              if (!item) {
                item = _.find(list, function (o) { return o[listenProp].indexOf(_name) >= 0; })
                itemKey = _.findKey(list, function (o) { return o[listenProp].indexOf(_name) >= 0; })
              }
              if (item && itemKey) {
                handleSelect(null, item, itemKey, opts)
              }
            }
          }
        }
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentName]);

  const findTeam = (itemType, name) => {
    let _name = name.replace(' ', '')
    _name = _.startCase(_name)
    let item = _.find(list, { [itemType]: _name })
    let itemKey = _.findKey(list, { [itemType]: _name })

    if (!item) {
      item = _.find(list, function (o) { return o[itemType].indexOf(_name) >= 0; })
      itemKey = _.findKey(list, function (o) { return o[itemType].indexOf(_name) >= 0; })
    }

    if (item && itemKey) {
      const _currentMatch = { ...currentMatch }
      switch (itemType) {
        case 'away':
          _currentMatch.away = item.away
          break;
        case 'home':
          _currentMatch.home = item.home
          break;
        default:
        // nothing
      }
      setCurrentMatch(_currentMatch)
    }
  }

  useEffect(() => {
    switch (dictaphoneListenType) {
      case dictaphoneListenTypes.match:
        if (handleSelect && isListening && opts) {
          let allow = true
          Object.keys(isListening).forEach(key => {
            if (opts[key] !== isListening[key]) {
              allow = false
            }
          })
          if (allow) {
            if (currentAway) { findTeam('away', currentAway) }
            if (currentHome) { findTeam('home', currentHome) }

          }
        }
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentAway, currentHome]);

  useEffect(() => {
    if (currentMatch.away && currentMatch.home) {
      const item = _.find(list, function (o) { return o['away'].indexOf(currentMatch.away) >= 0 && o['home'].indexOf(currentMatch.home) >= 0; })
      handleSelect(item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentMatch]);

  const handleListing = () => {
    if (isListening) {
      setIsListening();
      SpeechRecognition.stopListening();
      resetTranscript()
    } else {
      setIsListening(opts);
      resetTranscript()
      SpeechRecognition.startListening({
        continuous: true,
      });
    }
  };

  return labelOnly ?
    <Label className={isListening ? 'blink_me_p' : null} onClick={() => handleListing()}>
      <Icon name={isListening ? 'microphone' : 'microphone slash'} />
    </Label>
    :
    <Segment key={keyy ? keyy : uniqueKey('uidt')} basic className="microphone-wrapper">
      <Label color={isListening ? 'green' : 'grey'} onClick={() => handleListing()}>
        <Icon name={isListening ? 'thumbs up' : 'thumbs down'} />Listening
      </Label>
    </Segment>
}

export const UIDictaphoneIcon = (props) => {

  const { handleResult, oneTimeOnly } = props

  const [isListening, setIsListening] = useState();

  const { finalTranscript, resetTranscript } = useSpeechRecognition()

  useEffect(() => {
    if (finalTranscript) {
      resetTranscript()
      let _name = finalTranscript.replace(' ', '')
      handleResult(_name)
      oneTimeOnly && handleListing()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [finalTranscript]);

  const handleListing = () => {
    if (isListening) {
      setIsListening();
      SpeechRecognition.stopListening();
      resetTranscript()
    } else {
      setIsListening(true);
      SpeechRecognition.startListening({
        continuous: true,
      });
    }
  };


  return <Label className={isListening ? 'blink_me_p' : null} onClick={() => handleListing()}><Icon name={isListening ? 'microphone' : 'microphone slash'} /></Label>

}

export default UIDictaphone