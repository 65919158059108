import { copyObj } from '../../../global/common_web/copy'
import { playoffBracketTypes } from '../elements/matches/PlayoffBracket'
import _ from 'lodash'

export const _sportLevels = {
  '1A': '1A',
  '2A': '2A',
  '3A': '3A',
  '4A': '4A',
  '5A': '5A',
  '6A': '6A',
}

export const alterLevelName = (level) => {
  if (level === 'six' || level === 6) { return '6A' }
  if (level === 'five' || level === 5) { return '5A' }
  if (level === 'four' || level === 4) { return '4A' }
  if (level === 'three' || level === 3) { return 'AAA' }
  if (level === 'two' || level === 2) { return 'AA' }
  if (level === 'one' || level === 1) { return 'A' }
  return level
}

export const getTrueLevel = (level) => {
  if (level === 'six' || level === '6A') { return 6 }
  if (level === 'five' || level === '5A') { return 5 }
  if (level === 'four' || level === '4A') { return 4 }
  if (level === 'three' || level === 'AAA') { return 3 }
  if (level === 'two' || level === 'AA') { return 2 }
  if (level === 'one' || level === 'A') { return 1 }
  return level
}

export const getPlayoffMatches = (round, matches) => {

  const roundMatches = []
  let _roundMatchCount = 1
  let name = 'Final'
  let poolKey = 'finals'

  switch (round) {
    case 32:
      _roundMatchCount = 16
      name = 'Round of 32'
      poolKey = playoffBracketTypes.roundOf32
      break;
    case 16:
      _roundMatchCount = 8
      name = 'Round of 16'
      poolKey = playoffBracketTypes.roundOf16
      break;
    case 8:
      _roundMatchCount = 4
      name = 'Quarter-Finals'
      poolKey = playoffBracketTypes.quarterFinals
      break;
    case 4:
      _roundMatchCount = 2
      name = 'Semi-Finals'
      poolKey = playoffBracketTypes.semiFinals
      break;
    case 2:
      _roundMatchCount = 1
      name = 'Finals'
      poolKey = playoffBracketTypes.finals
      break;
    default:
    // nothing
  }

  for (var r = 1; r <= _roundMatchCount; r++) {
    const match = matches[name + '-' + r]
    match.poolKey = poolKey
    match.isPlayoff = true
    if (match) { roundMatches.push(match) }
  }

  return {
    matches: roundMatches,
    name: name
  }
}

export const groupMatchesByDate = (matchData) => {

  const matchDates = {}
  const startDate = new Date(2018, 0, 1)
  const endDate = new Date(2018, 10, 1)

  for (var d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {

    const td = d //format(d, 'MM/DD/YYYY')
    // const dd = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear()
    const dKey = (d.getMonth() + 1) + '_' + d.getDate() + '_' + d.getFullYear()

    // group by date 
    Object.keys(matchData).forEach(key => {
      if (td === matchData[key].date) {
        if (!matchDates[dKey]) { matchDates[dKey] = [] }
        matchDates[dKey].push(matchData[key])
      }
    })
  }
  return matchDates
}

export const sortDB = {
  ammendMinMax(teams) {
    const sectionTeamCount = teams.length
    const teamArray = []
    teams.forEach(team => {
      const { record } = team
      if (record) {
        if (record.smp > 0) {
          record.smr = ((sectionTeamCount - 1) * 2) - record.smp
          record.swp_max = ((record.sw + record.smr) / (record.sw + record.sl + record.smr))
          record.swp_min = ((record.sw) / (record.sw + record.sl + record.smr))
          record.sp_max = record.sp + (record.smr * 3)
          record.sp_min = record.sp
        }
        record.display = '(' + record.sw + '-' + record.sl + ') (' + record.w + '-' + record.l + ')'
        record.displayPoints = '(' + record.sw + '-' + record.sl + ') (' + record.w + '-' + record.l + ') (' + record.owp.toFixed(2) + ') (' + record.op + ') (' + (record.op / record.mp).toFixed(2) + ')'
        record.ppm = (record.op / record.tm)
      }
      teamArray.push(team)
    })
    return teamArray
  },
  sortBy(ar, prop) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return _ar.sort((a, b) => (
      b[prop] - a[prop]
    ))
  },
  sortByDesc(ar, prop) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    const x = _ar.sort((a, b) => (
      b[prop] - a[prop]
    ))
    return x.reverse()
  },
  sortSWP(ar) {
    const x = _.orderBy(ar, ['record.swp', 'record.owp', 'name'], ['desc', 'desc', 'asc'])
    return x
  },
  sortSPoints(ar) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    // eslint-disable-next-line
    return _ar.sort((a, b) => {
      if (b.record.sp === a.record.sp && b.record.op === a.record.op) {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0; // Names are equal
        }
      } else {
        return b.record.sp - a.record.sp || b.record.op - a.record.op
      }
    })
  },
  sortBy2(ar, prop1, prop2) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return _ar.sort((a, b) => (
      b[prop1] - a[prop1] || b[prop2] - a[prop2]
    ))
  },
  sortByNumber(ar, prop) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return _ar.sort((a, b) => {
      const propA = parseInt(a[prop], 10)
      const propB = parseInt(b[prop], 10)
      if (propA > propB) { return -1 }
      if (propA < propB) { return 1 }
      return 0
    })
  },
  sortByTeamMWP(ar, prop1, prop2) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return _ar.sort((a, b) => {

      const prop1A = a[prop1]
      const prop1B = b[prop1]

      const prop2A = a[prop2]
      const prop2B = b[prop2]

      return prop1B - prop1A || prop2B - prop2A
    })
  },

  sortByTeamMWPW(ar, prop1, prop2, prop3) {
    // const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return ar.sort((a, b, c) => {
      const prop1A = a[prop1]
      const prop1B = b[prop1]
      const prop1C = c[prop3]

      const prop2A = a[prop2]
      const prop2B = b[prop2]
      const prop2C = c[prop3]

      return prop1C - prop1B - prop1A || prop2C - prop2B - prop2A
    })
  },

  sortByProp(ar, prop1) {
    return ar.sort((a, b) => {

      const prop1A = a[prop1]
      const prop1B = b[prop1]

      if (prop1A > prop1B) { return -1 }
      if (prop1A < prop1B) { return 1 }

      return 0
    })
  },

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
  sort: function () {
    const a = []
    // convert to array
    Object.keys(this.teams).forEach(key => {
      const team = this.teams[key]
      team.owp = (team.matchWins / (team.matchWins + team.matchLosses)).toFixed(3)
      team.swp = (team.setWins / (team.setWins + team.setLosses)).toFixed(3)
      team.name = key
      a.push(team)
    })
  },

  sortSectionStandings: function (teams, playoffTeamsPerSection, sectionPlayoffTeams, sportsModeSport, forPlayoff, sortStandingsByPoints) {

    let _sectionPlayoffTeamCount = playoffTeamsPerSection ? playoffTeamsPerSection : 4
    const _teams = {}

    let finalPlayoffTeam = null

    const teamArray = this.ammendMinMax(teams)
    const teamCount = _.size(teamArray)

    if (sectionPlayoffTeams && sectionPlayoffTeams[teamCount]) {
      _sectionPlayoffTeamCount = sectionPlayoffTeams[teamCount]
    }

    let sortedTeams;

    switch (sportsModeSport) {
      case 'soccer':
        sortedTeams = this.sortSPoints(teamArray)
        break;
      default:
        if (sortStandingsByPoints) {
          sortedTeams = this.sortSPoints(teamArray)
        } else {
          sortedTeams = this.sortSWP(teamArray)
        }
        break;
    }

    sortedTeams.forEach((sortedTeam, index) => {

      _teams[sortedTeam.name] = sortedTeam

      // TC swp: .42857142857142855
      // CAL swp: 0.375
      // if (sortedTeam.name === 'Trinity Christian' || sortedTeam.name === 'California') {
      //   console.log('sortedTeam.name', sortedTeam, teamCount, _sectionPlayoffTeamCount)
      // }

      const { record } = sortedTeam

      if (record) {
        record.sectionRank = index + 1

        switch (record.sectionRank) {
          case 1:
            record.sectionRankCaption = record.sectionRank + 'st'
            break;
          case 2:
            record.sectionRankCaption = record.sectionRank + 'nd'
            break;
          case 3:
            record.sectionRankCaption = record.sectionRank + 'rd'
            break;
          default:
            record.sectionRankCaption = record.sectionRank + 'th'
        }

        if ((index + 1) <= _sectionPlayoffTeamCount) {
          record.playoffTeam = true
          finalPlayoffTeam = copyObj(sortedTeam)
          finalPlayoffTeam.record.maxPossibleLosses = finalPlayoffTeam.record.l + (finalPlayoffTeam.record.tm - finalPlayoffTeam.record.mp)

        } else {
          finalPlayoffTeam.record.owp = (finalPlayoffTeam.record.w / (finalPlayoffTeam.record.w + finalPlayoffTeam.record.maxPossibleLosses)).toFixed(3)

          switch (sportsModeSport) {
            case 'soccer':
              if (record.sp < finalPlayoffTeam.record.sp) {
                record.nonPlayoffTeam = true
              } else if (record.sp === finalPlayoffTeam.record.sp) {
                record.playoffTeam = true
                record.tied = true
              }
              break;
            default:
              // if the finalPlayoffTeam's swp is better that the current team's swp, they are a non playoff team 
              if (record.swp < finalPlayoffTeam.record.swp) {
                record.nonPlayoffTeam = true
              } else if (record.swp === finalPlayoffTeam.record.swp) {
                record.playoffTeam = true
                record.tied = true
              }
              break;
          }

        }
      }
    })

    switch (sportsModeSport) {
      case 'soccer':
        calcTeams_points(sortedTeams)
        break;
      default:
        calcTeams_swp(sortedTeams)
        break;
    }

    return _teams
  },

  sortLevelStandings: function (teams) {

    const teamArray = []

    // convert to array
    teams.forEach(team => {
      const { record } = team
      if (record) {
        record.owp = .500
        record.swp = .500
        if (record.w + record.l > 0) { record.owp = (record.w / (record.w + record.l)) }
        if (record.sw + record.sl > 0) { record.swp = (record.sw / (record.sw + record.sl)) }
        record.display = '(' + record.sw + '-' + record.sl + ') (' + record.w + '-' + record.l + ')'
      }
      teamArray.push(team)
    })

    const sortedTeams = this.sortSWP(teamArray)

    sortedTeams.forEach((st, index) => {
      st.seed = index
    })

    return sortedTeams
  }
}

const calcTeams_swp = (teams) => {

  const _playoffTeams = _.filter(teams, team => team.record.playoffTeam);
  const _playoffTeamLow = _.minBy(_playoffTeams, 'record.swp_min');

  const _nonPlayoffTeams = _.filter(teams, team => team.record.nonPlayoffTeam);
  const _nonPlayoffTeamHigh = _.maxBy(_nonPlayoffTeams, 'record.swp_min');

  teams.forEach(team => {
    if (team.record) {
      if (_playoffTeamLow) {
        if (team.record.swp_max < _playoffTeamLow.record.swp_min) {
          team.record.playoffEliminated = true
        }
      }
      if (_nonPlayoffTeamHigh) {
        if (team.record.swp_min > _nonPlayoffTeamHigh.record.swp_max) {
          team.record.playoffAssured = true
        }
      }
    }
  })
}

const calcTeams_points = (teams) => {

  const _playoffTeams = _.filter(teams, team => team.record.playoffTeam);
  const _playoffTeamLow = _.minBy(_playoffTeams, 'record.sp_min');

  const _nonPlayoffTeams = _.filter(teams, team => team.record.nonPlayoffTeam);
  const _nonPlayoffTeamHigh = _.maxBy(_nonPlayoffTeams, 'record.sp_min');

  teams.forEach(team => {
    if (team.record) {
      if (_playoffTeamLow) {
        if (team.record.sp_max < _playoffTeamLow.record.sp_min) {
          team.record.playoffEliminated = true
        }
      }
      if (_nonPlayoffTeamHigh) {
        if (team.record.sp_min > _nonPlayoffTeamHigh.record.sp_max) {
          team.record.playoffAssured = true
        }
      }
    }
  })
}


