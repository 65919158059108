import React from 'react'

const MatchOveriew = (props) => {

  const { caption, content, cn, handleClick, clickItem } = props ?? {}

  return <div>
    <div onClick={() => { handleClick && handleClick(clickItem) }}>{caption}</div>
    <div className={cn ? cn : null}>{content}</div>
  </div>
}

export default MatchOveriew
