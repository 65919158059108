import { dispatchProps } from "../../../cnr/reducers/reducerHelpers/dispatchProps"
import { mergePromise } from "../../promises/mergePromise"

export const fsUpdate_updateSortedCollection = (props) => {

  const { baseRef, ufProps, dispatch } = props

  const { refPath, directCollection } = baseRef
  let { vit, sortedData, dataRestrictions } = ufProps ?? {}

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.sortData)) {
    dispatch({ type: dispatchProps.successAlt, dispatch })
    return false
  }

  mergePromise(refPath, vit, directCollection, sortedData).then(() => {
    dispatch({ type: dispatchProps.success, dispatch })
  }).catch(error => {
    dispatch({ type: dispatchProps.error, error, dispatch })
  })
}