import _ from "lodash"
import { convertHelpers } from "../../common/convert"

/**
 * Returns an array of headers ordered by id, names, etc.
 * @param {object} dataItems 
 * @returns 
 */
export const getHeaders = (dataItems, columnHeaders) => {

  if (columnHeaders) { return columnHeaders }

  const allH = {
    headers: [],
    headersPrimary: [],
    headersSecondary: [],
    headersId: [],
    headerDescription: [],
  }

  const headerTypes = {}

  if (_.isArray(dataItems)) {
    dataItems.forEach(item => {
      Object.keys(item).forEach(key => {
        updateH(key, allH, headerTypes, item[key])
      })
    })
  } else {
    if (dataItems) {
      Object.keys(dataItems).forEach(k => {
        const item = dataItems[k]
        Object.keys(item).forEach(key => {
          updateH(key, allH, headerTypes, item[key])
        })
      })
    }
  }
  const headers = [...allH.headersId, ...allH.headersPrimary, ...allH.headersSecondary, ...allH.headers, ...allH.headerDescription, allH.headerTypes]
  return { headers, headerTypes }
}

export const getHeadersFromPropSections = (viewItem) => {
  const { propSections, props: props_vi } = viewItem ?? {}
  const items = []
  if (propSections) {
    const props_header = []
    convertHelpers.createItemKeys(propSections)
    const _propSections = _.sortBy(propSections, 'position')
    _propSections.forEach(propSection => {
      const { _itemKey } = propSection ? propSection : {}
      switch (_itemKey) {
        case 'hidden':
          // nothing
          break;
        default:
          const _psProps = _.filter(props_vi, { propSection: _itemKey })
          if (_psProps) {
            const _props = _.sortBy(_psProps, 'position')
            _props.forEach(pr => {
              props_header.push(pr)
            })
          }
      }

    })
    props_header.forEach(ph => {
      items.push(ph.key)
    })
  }
  return items
}

export const getArrayHeaders = (dataItems) => {
  const allH = {
    headers: [],
    headersPrimary: [],
    headersSecondary: [],
    headersId: [],
    headerDescription: [],
  }
  if (_.isArray(dataItems)) {
    dataItems.forEach(item => {
      Object.keys(item).forEach(key => {
        if (_.isArray(item[key])) {
          updateH(key, allH)
        }
      })
    })
  } else {
    if (dataItems) {
      Object.keys(dataItems).forEach(k => {
        const item = dataItems[k]
        Object.keys(item).forEach(key => {
          if (_.isArray(item[key])) {
            updateH(key, allH)
          }
        })
      })
    }
  }
  const headers = [...allH.headersId, ...allH.headersPrimary, ...allH.headersSecondary, ...allH.headers, ...allH.headerDescription]
  return headers
}

const updateH = (key, allH, headerTypes, dataValue) => {

  const { headers, headersPrimary, headersSecondary, headersId, headerDescription } = allH

  if (headerTypes) {
    if (dataValue && !headerTypes[key]) {
      if (_.isArray(dataValue)) {
        headerTypes[key] = 'array'
      } else if (_.isObject(dataValue)) {
        headerTypes[key] = 'object'
      } else if (_.isBoolean(dataValue)) {
        headerTypes[key] = 'boolean'
      } else {
        headerTypes[key] = typeof dataValue
      }
    }
  }

  switch (key) {
    case '_gs':
    case '_new':
    case 'endDateUTC':
    case 'endTimeUTC':
    case 'parentKeys':
    case 'startDateUTC':
    case 'startTimeUTC':
    case 'uid':
      // don't show
      break;
    case 'id':
    case '_gsid':
    case '_itemKey':
      if (!headersId.includes(key)) { headersId.push(key) }
      break;
    case 'name':
    case 'lastName':
    case 'firstName':
      if (!headersPrimary.includes(key)) { headersPrimary.push(key) }
      break;
    case 'email':
      if (!headersSecondary.includes(key)) { headersSecondary.push(key) }
      break;
    case 'description':
      if (!headerDescription.includes(key)) { headerDescription.push(key) }
      break;
    default:
      if (key.toLowerCase().endsWith('name')) {
        if (!headersPrimary.includes(key)) { headersPrimary.push(key) }
      } else {
        if (!headers.includes(key)) { headers.push(key) }
      }
      break;
  }
}