// import { dataUpload } from '../../firestoreData/dataUpload';

export const rts = {
  handleAmmend_startDimmer: 'handleAmmend_startDimmer',
  handleKillDimmer: 'handleKillDimmer',
  handleTimeLeft: 'handleTimeLeft',
}

export const startTexts = {
  app: 'Loading',
  authorizing: 'Authorizing',
  settings: 'Loading',
  createEvent: 'Creating Event',
  pending: 'Pending Authorization',
}

export const startTypes = {
  app: 'app',
  authorizing: 'authorizing',
  event: 'event',
  createEvent: 'createEvent',
  finish: 'finish',
  pageItem: 'pageItem',
  suspense: 'suspense',
}


export const startReducer = (state, action) => {

  const { killInterval } = state
  const { type } = action

  switch (type) {

    case rts.handleAmmend_startDimmer:
      if (action.startType) {
        let caption;
        switch (action.startType) {
          case startTypes.app:
          case startTypes.authorizing:
          case startTypes.createEvent:
          case startTypes.event:
            switch (action.startType) {
              case startTypes.app:
                caption = startTexts.app
                break;
              case startTypes.authorizing:
                caption = startTexts.authorizing
                break;
              case startTypes.event:
                caption = startTexts.settings
                break;
              case startTypes.createEvent:
                caption = startTexts.createEvent
                break;
              default:
              //nothing
            }
            return { ...state, startDimmerOn: true, startType: action.startType, startCaption: caption, startDocName: action.settingsDocName }

          case startTypes.finish:
            return { ...state, startDimmerOn: false, startType: action.startType, killDimmer: true }

          default:
          // nothing
        }
      } else {
        return { ...state, startDimmerOn: false, startType: null, killDimmer: true }
      }
      break;

    case rts.handleTimeLeft:
      return { ...state, timeLeft: killInterval - 1 }

    case rts.handleKillDimmer:
      return { ...state, startDimmerOn: false, startType: null, killDimmer: true }

    default:
      return { ...state }
  }
}

export const startInitialState = (initState) => {
  return { startDimmerOn: true, ...initState }
};

export const startHandlers = (dispatch, state) => {
  return {
    handleKillDimmer: () => { dispatch({ type: rts.handleKillDimmer }) },
    handleTimeLeft: () => { dispatch({ type: rts.handleTimeLeft }) },
    handleAmmend_startDimmer: (startType, settingsDocName) => { dispatch({ type: rts.handleAmmend_startDimmer, startType, settingsDocName }) },
  }
}