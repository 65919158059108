import React, { useContext } from 'react';
import { DataContext } from '../cnr/contexts/DataContext';
import DisplayProvider from '../cnr/contexts/DisplayContext';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import Pending from '../components/alerts/pendings/Pending';
import PendingSimple from '../components/alerts/pendings/PendingSimple';
import SemElems from '../components/sem/SemElems';

// SemUi has the Suspense
const NonAuthPage = React.lazy(() => import('../components/alerts/NonAuthPage'));
const SemUiActions = React.lazy(() => import('./SemUiActions'));

/**
 * 
 * @param {object} props 
 * @returns a component
 * @calledFrom UiItem and swipeHelpers
 */
const SemUI = (props) => {

  const { selectedGroupItem } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state } = states
  const { pageSettings } = page_state
  const { aps_global } = pageSettings ?? {}
  const { dataOptions } = aps_global ?? {}
  const { onDemand } = dataOptions ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { desktopMode } = framework_state ?? {}

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state, data_handlers } = dataContext ?? {}
  const { confirmation } = data_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { viewItem, vld: viewListData, props_viewItem } = item_state

  const { display, desktopDisplay } = viewItem ?? {}
  const _display = desktopMode && desktopDisplay ? desktopDisplay : display

  let { sortProp } = _display ?? {}

  if (!sortProp) { sortProp = 'name' }

  const { dataActionKey } = data_state

  let { simpleData } = props

  /**
   * 
   * @returns the content
   */
  const content = () => <DisplayProvider selectedGroupItem={selectedGroupItem}>
    <SemElems />
  </DisplayProvider>

  const simpleContent = () => {
    const segs = []
    simpleData.forEach(() => {
      segs.push(<SemElems />)
    })
    return <DisplayProvider selectedGroupItem={selectedGroupItem}>
      {content()}
    </DisplayProvider>
  }

  if (dataActionKey) {
    return <SemUiActions dataActionKey={dataActionKey} />
  } else {
    if (props_viewItem) {
      if (viewListData) {
        return content()
      } else if (simpleData) {
        return simpleContent()
      } else {
        if (onDemand && data_handlers.handleSetFetchData) { data_handlers.handleSetFetchData(true) }
        if (confirmation && confirmation.success === false) {
          return <NonAuthPage itemType={'data'} />
        } else {
          return <PendingSimple />
        }
      }
    } else {
      return Pending(viewItem, 'none', viewItem.bodyHeight)
    }
  }
}

export default SemUI