import { ammendUiSvValue } from '../../common/convert';
import { filterHelpers } from '../../common/filtering';
import { sortObject } from '../../common/sorting';
import { gEnums } from '../../enums/globalEnums';
import { getPropElementValue } from './propValue';

// all we want to do is get the values!!!

export const _sectionProps = {
  address: ['city', 'state', 'zip', 'street', 'address'],
  contact: ['phone', 'cellPhone', 'businessPhone', 'email', 'officePhone', 'mobilePhone'],
  description: ['description'],
  hidden: ['sv', 'itemKey', 'gsid', 'svs', '_gsid', '_itemKey', 'key', 'sv', 'svs', 'sv*', 'svs*'],
  image: ['image', 'imageUrl'],
  location: ['location', 'locations'],
  name: ['firstName', 'lastName', 'middleName', 'suffix', 'name', 'prefix', 'salutation'],
  pinned: ['description', 'descriptions', 'bio', 'aboutMe'],
  schedule: ['date', 'startDate', 'endDate', 'startTime', 'endTime', 'startDateUTC', 'endDateUTC', 'startTimeUTC', 'endTimeUTC'],
  social: ['facebook', 'twitter', 'instagram', 'snapchat', 'tiktok'],
  tagged: ['company', 'locations', 'location', 'registrationTypes', 'title', 'company'],
  travel: ['checkInDate', 'checkOutDate', 'checkIn', 'checkOut', 'hotel', 'confirmationNumber'],
  tags: ['tags'],
}

/**
 * returns a list of propItems 
 * @param {*} parentContext 
 * @param {*} componentContexts 
 * @param {*} viewItem 
 * @param {*} itemData 
 * @param {*} sectionProps 
 * @returns 
 */
export const getAllowedPropsElements = (parentContext, componentContexts, viewItem, itemData, sectionProps) => {

  // componentContexts
  const { displayContext } = componentContexts
  const { display_state } = displayContext ?? {}
  const { getParentValue, valueFromParent } = display_state

  const propItems = []
  const propsSorted = sortObject(sectionProps, 'position')

  if (Object.keys(propsSorted).length === 0) { return null }

  // loop props and get value
  Object.keys(propsSorted).forEach((propKey, _index) => {

    const propSorted = propsSorted[propKey]

    let value = ammendUiSvValue(itemData, propKey)

    // ammend the value to propKey to lowerCase if it does not have a value and try again
    if (!value) { value = itemData[propKey.toLowerCase()] }

    // if valueFromParent, get it's value
    if (!value && valueFromParent) { value = getParentValue(propSorted) }

    value = getPropElementValue(parentContext, viewItem, propSorted, value, itemData, componentContexts)

    propItems.push({
      ...propSorted,
      value: value,
    })
  })

  return propItems

}

/**
 * 
 * @param {object} parentContext 
 * @param {object} componentContexts 
 * @param {object} viewItem 
 * @param {object} itemData 
 * @param {object} sectionProps 
 * @param {boolean} taggedIgnored 
 * @param {array} pageViewItemKeys 
 * @returns A list of allowed props for the viewItem
 */
export const getAllowedProps = (parentContext, componentContexts, viewItem, itemData, sectionProps, taggedIgnored, pageViewItemKeys) => {

  // componentContexts
  const { displayContext, frameworkContext } = componentContexts ?? {}

  const { framework_state } = frameworkContext ?? {}
  const { desktopMode } = framework_state ?? {}

  const { getParentValue } = displayContext ?? {}
  const { display, desktopDisplay } = viewItem ?? {}
  const _display = desktopMode && desktopDisplay ? desktopDisplay : display
  const { ignoreTaggedProps } = _display ?? {}
  const showProp = desktopMode ? gEnums.showTypes.showDesktop : gEnums.showTypes.show

  const spp = {}

  if (sectionProps) {
    Object.keys(sectionProps).forEach(key => {
      switch (sectionProps[key].allowProp) {
        case gEnums.viewPermissionTypes.allow:
          if (sectionProps[key].allowProp === gEnums.viewPermissionTypes.allow) {
            spp[key] = sectionProps[key]
          }
          break;
        default:
        // nothing
      }
    })
  }

  const propItems = []

  let propsShown = filterHelpers.filterObject(spp, showProp)

  propsShown = sortObject(propsShown, 'position')

  if (Object.keys(propsShown).length === 0) { return null }

  // loop props and get value
  Object.keys(propsShown).forEach((key, _index) => {

    const pis = propsShown[key]

    const { propType, display: display_pis } = pis
    let { prop, data, show, showDesktop } = pis
    let { eventKey } = data ?? {}
    let { elemPropType, valueFromParent } = display_pis ?? {}

    const _show = desktopMode ? showDesktop : show

    // set defaults if not there
    if (!elemPropType && propType) { elemPropType = propType }
    if (!prop) { prop = pis.key }
    if (eventKey && (prop !== eventKey)) { prop = eventKey }

    let allowProp = _show

    if (prop.indexOf('UTC') >= 0) { allowProp = false }

    if (ignoreTaggedProps && taggedIgnored && taggedIgnored.includes(key)) { allowProp = false }
    if (_sectionProps.hidden.includes(key)) { allowProp = false }
    if (pageViewItemKeys && pageViewItemKeys.includes(key)) { allowProp = false }

    if (allowProp) {
      let value = itemData[prop]
      if (!value) { value = itemData[prop.toLowerCase()] }
      if (!value && valueFromParent) { value = getParentValue(pis) }
      value = getPropElementValue(parentContext, viewItem, pis, value, itemData, componentContexts)
      propItems.push({
        ...pis,
        value: value,
      })
    }
  })

  console.log('propItems', propItems)
  return propItems

}
