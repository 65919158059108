import _ from 'lodash'
import React, { useState } from 'react'
import { Icon, Label, Menu } from 'semantic-ui-react'
import { uniqueKey } from '../../common/keys'
import { iconColorTypes } from '../../enums/settingsIconTypes'
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper'

const icons = {
  left: 'options'
}

const SettingViewerMenu = (props) => {

  const { noMid, menuGroup, eventName, googleUpdateStatus } = props ?? {}
  const { inputGroup, resultGroups, pickInput } = menuGroup ?? {}

  const useDarkMode = true

  const [activeItem, setActiveItem] = useState()

  const handleOpenMenuItem = (groupKey) => setActiveItem(groupKey === activeItem ? null : groupKey)

  const miStatusProps = (groupKey) => {
    let iconColor = iconColorTypes.menuItem;
    let loading = false;
    if (googleUpdateStatus && googleUpdateStatus[groupKey]) {
      const { status } = googleUpdateStatus[groupKey]
      loading = true
      iconColor = iconColorTypes.fetching
      if (status === 'completed') {
        iconColor = iconColorTypes.success
        loading = false
      }
      if (status === 'failed') {
        iconColor = iconColorTypes.deny
        loading = false
      }
    }
    return { iconColor, loading }
  }

  const menuItem = (resultGroup) => {

    const { groupKey, content, count, checked } = resultGroup
    const active = (activeItem === groupKey) ? true : false
    const cn = active ? '' : 'dis-none'
    const caretIcon = active ? 'angle down' : 'angle right'

    const label = count ? <Label color={iconColorTypes.menuItem} size={'tiny'} circular style={{ marginLeft: '1em', float: 'right' }}>{count}</Label> : null
    const icon = checked ? <Icon color={iconColorTypes.success} name={'check circle'} style={{ marginLeft: '1em', float: 'right' }}></Icon> : null

    const { iconColor, loading } = miStatusProps(groupKey)

    return <Menu.Item key={uniqueKey('svm', groupKey)}  >
      <Menu.Header onClick={() => handleOpenMenuItem(groupKey)}>
        <Icon color={iconColor} name={icons.left} loading={loading} />
        <div>{_.startCase(groupKey)}{label}{icon}</div>
        <Icon name={caretIcon} />
      </Menu.Header>
      {/* <Menu.Menu className={'mi-group-item ' + cn}> */}
      <Menu.Menu className={cn}>
        {content}
      </Menu.Menu>
    </Menu.Item>
  }

  const itemGroups = () => Object.keys(resultGroups).map(groupKey => menuItem(resultGroups[groupKey]))
  const mainMenu = () => <Menu borderless inverted={useDarkMode} vertical fluid className={'msmi three mm sem-menuu menu-groups'}>{itemGroups()}</Menu>

  const menuGroupWrap = (prefix, cssType, content) => <div className={prefix + '-' + cssType + ' settings-menu-' + cssType}>
    <div className='ct-vs settings'>
      {content}
    </div>
  </div>

  const resultWrapper = (prefix, cssType, content) => <Wrapper
    header={eventName}
    content={menuGroupWrap(prefix, cssType, content)}
    wrapperType={wrapperTypes.paddedHeader}
    noMid={noMid}
    css={{ header: 'hw' }}
  />

  const content = () => <div className={'sem-menu'}>
    {inputGroup && menuGroupWrap('hc', 'header', inputGroup)}
    {pickInput && pickInput}
    {resultGroups && resultGroups.length > 0 && resultWrapper('hc', 'content', mainMenu())}
  </div>

  return content()

}

export default SettingViewerMenu