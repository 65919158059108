import _ from 'lodash';
import { appIconTypes } from '../../enums/appIconTypes';
import { getFirstObject } from '../../common/convert';

export const genericMenuProps = (vit, caption, opts) => {
  return {
    key: vit,
    value: vit,
    caption: caption ? caption : _.startCase(vit),
    icon: appIconTypes[vit] ? appIconTypes[vit] : 'question',
    ...opts
  }
}

export const getAlphaMenuItems = (dataItems, alphaProp, restrictToExisting) => {
  const { item } = dataItems ? getFirstObject(dataItems) : {}
  let dataAlphaProp;
  if (item) {
    const { lastName } = item
    dataAlphaProp = lastName ? 'lastName' : 'name'
  }
  const _alphaProp = alphaProp ? alphaProp : dataAlphaProp
  const mis = []
  const letterCount = {}
  let firstAlpha;
  let ai = null
  for (var i = 65; i <= 90; i++) {
    if (restrictToExisting) {
      const x = seeIf(dataItems, _alphaProp, i)
      if (x.length > 0) {
        if (!firstAlpha) { firstAlpha = i }
        if (!ai) { ai = i }
        letterCount[i] = x.length
        mis.push(i)
      }
    } else {
      if (!ai) { ai = 64 }
      mis.push(i)
    }
  }
  return { alphaItems: mis, firstAlpha, letterCount, useAlpha: mis.length > 16 }
}

const seeIf = (dataItems, sortProp, alphaValue) => {
  const vld = Object.values(dataItems).filter(function (item) {
    return item[sortProp] && 0 === item[sortProp].indexOf(String.fromCharCode(alphaValue));
  });
  return vld
}