import _ from 'lodash';
import { sendPasswordResetEmail } from '../../auth/signInActions/resetPasswordEmail';
import { grts, responseReducers } from '../../cnr/reducers/reducerHelpers/dispatchProps';
import { updateFavorites } from '../../firestoreData/favorites/updateFavorites';
import { fsfn_auth } from '../../functions/fbAuth';
import { openExternal } from '../../viewSettings/helpers/settingsLinks';

const rts = {
  addTopicToProfile: 'addTopicToProfile',
  addTopicToProfileDirect: 'addTopicToProfileDirect',
  convertToParentKeys: 'convertToParentKeys', // not in use
  gotoPage: 'gotoPage',
  handleActionQuestion: 'handleActionQuestion', // ??? 
  handleClosePreview: 'handleClosePreview',
  handleCommitNo: 'handleCommitNo',
  handleCommitYes: 'handleCommitYes',
  handleFbFnResponse: 'handleFbFnResponse',
  handleOpenFirebaseDatabase: 'handleOpenFirebaseDatabase',
  handleStopFetch: 'handleStopFetch',
  removeTopicFromProfile: 'removeTopicFromProfile',
  sendPasswordResetEmail: 'sendPasswordResetEmail',
  sendTestNotification: 'sendTestNotification',
  sendToTopic: 'sendToTopic',
  setFavorite: 'setFavorite',
  updateAuth: 'updateAuth',
  ...grts
}

export const actionReducer = (state, action) => {

  const { parentContext, questionProps } = state
  const { states } = parentContext

  const { appUser_state, paps_state } = states ?? {}

  // notificationData
  const { actionType, type, navigate, goto, dispatch, componentContexts: parentContexts_action, modifyData, actionProps, headerProps } = action

  // authContext 
  const { appUser } = appUser_state ?? {}

  const { profileData } = appUser ?? {}
  const { settingsFavs: favs } = profileData ?? {}

  switch (type) {

    case rts.handleStopFetch:
      return { ...state, updating: false }

    case rts.handleActionQuestion:

      let caption;

      if (actionType) {
        caption = _.startCase(actionType) + '?'
      } else {
        caption = 'This caption is not set in `ActionReducer'
      }

      if (caption.startsWith('Handle')) { caption = caption.replace('Handle', '') }

      return {
        ...state,
        allContexts: parentContexts_action,
        questionProps: {
          check: true,
          header: 'Please Confirm',
          question: caption,
          type: actionType,
          actionItemData: modifyData,
          headerProps: headerProps,
          actionPreview: type
        }
      }

    case rts.handleCommitNo:
      return {
        ...state,
        questionProps: null
      }

    case rts.handleClosePreview:
      return {
        ...state,
        preview: null
      }

    case rts.handleCommitYes:

      if (questionProps && questionProps.type) {

        let aid = questionProps.actionItemData

        switch (questionProps.type) {
          case rts.updateAuth:
            fsfn_auth.updateAuth(aid, dispatch, rts.updateSuccess, rts.updateError)
            return { ...state }

          case rts.sendPasswordResetEmail:
            sendPasswordResetEmail(aid.email, dispatch, rts.updateSuccess, rts.updateError)(aid.email)
            return { ...state }

          case rts.convertToParentKeys:
            // fsfn_convertToParentKeys(aps_viewItems, paps_state)
            return { ...state }

          default:
            return { ...state }
        }
      }

      return {
        ...state,
        questionProps: null
      }


    case rts.addTopicToProfileDirect:
      fsfn_auth.addProfileTopic({ email: action.email, topic: action.topic }, dispatch, rts.handleSuccess, rts.handleError)
      return { ...state }

    case rts.handleFbFnResponse:
      const { fn_response } = action
      const { data } = fn_response
      const { result } = data
      return { ...state, preview: result, questionProps: null, updating: false }


    case rts.gotoPage:
      const { push: histPush, location } = navigate
      const path = location.pathname + '/' + goto
      histPush(path)
      return { ...state }

    case rts.setFavorite:
      const { swipedItem: swi } = actionProps ?? {}
      if (swi && swi.key) {
        const fav = swi.key
        const sfs = favs ? [...favs] : []
        if (!_.includes(sfs, fav)) {
          sfs.push(fav)
        } else {
          _.remove(sfs, function (n) {
            return n === fav;
          });
        }
        updateFavorites(dispatch, appUser, paps_state, swi)
        return {
          ...state,
          favs: sfs
        }
      }
      return { ...state, }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { questionProps: null })

    default:
      return { ...state }
  }
}

export const actionInitialState = (initState) => {
  const { parentContext } = initState
  return { parentContext, updateStatus: {} }
};

export const actionHandlers = (dispatch) => {
  return {
    addTopicToProfileDirect: (email, topic) => { dispatch({ type: rts.addTopicToProfileDirect, dispatch, email, topic }) },
    gotoPage: (navigate, goto) => { dispatch({ type: rts.gotoPage, dispatch, navigate, goto }) },
    handleActionQuestion: (actionType, componentContexts, modifyData, headerProps) => { dispatch({ type: rts.handleActionQuestion, dispatch, actionType, componentContexts, modifyData, headerProps }) },
    handleClosePreview: () => { dispatch({ type: rts.handleClosePreview }) },
    handleCommitNo: () => { dispatch({ type: rts.handleCommitNo }) },
    handleCommitYes: () => { dispatch({ type: rts.handleCommitYes, dispatch }) },
    handleOpenFirebaseDb: (pathViews, currentVit, currentId, forSettings, forStorage) => { openExternal.firebaseDb(pathViews, currentVit, currentId, forSettings, forStorage) },
    handleStopFetch: () => { dispatch({ type: rts.handleStopFetch }) },
    setFavorite: (actionProps) => { dispatch({ type: rts.setFavorite, dispatch, actionProps }) },
  }
}