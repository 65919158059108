import { deleteField } from "firebase/firestore";
import _ from 'lodash';
import { convertSnapshot } from '../../cnr/contexts/contextHelpers';
import { _secretDataName } from '../../cnr/reducers/DataReducer';
import { ref_get } from "../appData/fsRefs";
import { createRefPath } from "../appData/appRefPaths";
import { fs_dbu } from "../appData/fsAppDataUpdate";
import { getBaseRef } from '../helpers/getBaseRef';

const limitUpdates = true

export const updateHiddenData = (dispatch, pathViews, viewItem, viewListData, page_fns, unhide, dispatchType) => {

  const { getGviDeps } = page_fns ?? {}
  const { key: currentVit } = viewItem ?? {}

  const secretProps = _.filter(viewItem.props, { 'secret': true })
  const secretDatas = getSecretDatas(viewListData, secretProps)

  if (currentVit) {
    const viDeps = getGviDeps(currentVit)
    const baseRef = getBaseRef(viDeps, pathViews, null)
    const { refPath } = baseRef

    if (unhide) {
      getSecretDocumentPromises(refPath, currentVit, viewListData, secretProps).then(res => {
        if (res) {
          unSecretDocumentPromises(refPath, res).then(res => {
            dispatch({ type: dispatchType, ...res })
          })
        }
      })
    } else {
      setSecretPromises(refPath, currentVit, secretDatas, viewListData).then(res => {
        dispatch({ type: dispatchType, ...res })
      })
    }
    return false
  }
}

const getDeleteKeys = (hiddenData) => {
  const hdKeys = {}
  Object.keys(hiddenData).forEach(hdKey => {
    hdKeys[hdKey] = deleteField()
  })
  return hdKeys
}

/** Sets the hidden data */
const setSecretPromises = (baseRef, currentVit, hiddenDatas) => {
  const promises = []
  Object.keys(hiddenDatas).forEach((itemKey, index) => {
    let allow = true
    if (limitUpdates && index > 0) { allow = false }

    if (allow) {
      // update the secretData
      const hiddenData = hiddenDatas[itemKey]
      const secretRef = baseRef.collection(currentVit).doc(itemKey).collection(_secretDataName).doc(_secretDataName)
      promises.push(secretRef.set(hiddenData, { merge: true }))
      const itemRef = baseRef.collection(currentVit).doc(itemKey)
      // remove the secret item from the document
      const hdKeys = getDeleteKeys(hiddenData)
      if (hdKeys && Object.keys(hdKeys).length > 0) {
        promises.push(itemRef.update(hdKeys))
      }
    }

  })
  return Promise.all(promises)
}

/** Deletes the hidden data */
const getSecretDocumentPromises = (refPath, currentVit, viewListData, hiddenProps) => {
  const promises = []
  Object.keys(viewListData).forEach((itemKey, index) => {
    let allow = true
    if (limitUpdates && index > 0) { allow = false }
    if (allow) {
      const _getRef = createRefPath([currentVit, itemKey, _secretDataName, _secretDataName], refPath)
      const r = ref_get(null, _getRef)
      promises.push(r)
    }
  })
  return Promise.all(promises)
}

/** Deletes the hidden data */
const unSecretDocumentPromises = (refPath, res) => {
  const promises = []
  res.forEach(r => {
    const ri = convertSnapshot(r, true, { returnFirstObject: true })
    const { secret } = ri

    const parentName = r.ref.parent.parent.parent.id
    const parentKey = r.ref.parent.parent.id

    const _updateRef = createRefPath([parentName, parentKey], refPath)
    const ru = fs_dbu.doc_update(_updateRef, secret)
    promises.push(ru)

    const _deleteRef = createRefPath([parentName, parentKey, _secretDataName, _secretDataName], refPath)
    const rd = fs_dbu.doc_delete(_deleteRef)
    promises.push(rd)
  })
  return Promise.all(promises)
}

export const getSecretDatas = (viewListData, secretProps) => {

  const secretDatas = {}

  if (viewListData) {
    // loop the data
    Object.keys(viewListData).forEach(itemKey => {
      const vld = viewListData[itemKey]
      const secretData = {}
      // loop the data item
      Object.keys(vld).forEach(propKey => {
        secretProps.forEach(secretProp => {
          const { key: spKey } = secretProp
          if (propKey === spKey) {
            if (vld[propKey]) {
              if (!secretData[spKey]) { secretData[spKey] = {} }
              secretData[spKey] = vld[propKey]
            }
          }
        })
      })
      if (secretData && Object.keys(secretData).length > 0) {
        if (secretDatas) { secretDatas[itemKey] = {} }
        secretDatas[itemKey] = secretData
      }
    })
  }
  return secretDatas
}