import { fsfn_auth } from "../../../functions/fbAuth";
import { dispatchProps } from "../../../cnr/reducers/reducerHelpers/dispatchProps";
import { createRefPath } from "../../appData/appRefPaths";
import { fs_dbu } from "../../appData/fsAppDataUpdate";
import { dataFix } from "../../../common/dataFix";

/**
 * This will update an single item to firebase
 * @param {string} baseRef 
 * @param {object} ufProps 
 * @param {string} vit 
 * @param {function} dispatch 
 * @returns 
 */
export const fsUpdate_updateData = async (props) => {

  const {
    baseRef,
    dispatch,
    ufProps,
  } = props

  const { refPath, directCollection } = baseRef
  let { vit, itemData, swipedItem, viewKey, dataRestrictions } = ufProps ?? {}

  if (!viewKey && itemData && itemData.id) { viewKey = itemData.id }
  if (!viewKey && itemData && itemData._itemKey) { viewKey = itemData._itemKey }
  if (!viewKey && swipedItem && swipedItem.key) { viewKey = swipedItem.key }

  dataFix.createDeleteFields(itemData)

  if (viewKey) {

    const coll = directCollection ? directCollection : vit
    const _updateRef = createRefPath([coll, viewKey], refPath)

    if (dataRestrictions && (dataRestrictions.all || dataRestrictions.updateData)) {
      dispatch({ type: dispatchProps.successAlt, dispatch })
      return false
    }

    switch (vit) {
      default:
        await fs_dbu.update_doc(_updateRef, itemData, dispatch)
        const editPath = coll + '/' + viewKey
        switch (vit) {
          case 'profiles':
            fsfn_auth.addAuthCustomToken(itemData, dispatchProps.success)
            break;
          default:
            dispatch({ type: dispatchProps.success, updatePath: editPath })
        }
    }
  } else {
    alert('Item not updated. No viewItemKey')
  }
}