
import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';

const PendingData = (props) => {
  const { keyy } = props ?? {}
  return <Segment key={uniqueKey('dp', keyy)} basic className={'pending-container'} >
    <Dimmer key={uniqueKey('dpd', keyy)} active inverted >
      <Loader key={uniqueKey('dpl', keyy)} inverted>Loading</Loader>
    </Dimmer>
  </Segment>
}

export default PendingData