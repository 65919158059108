import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { DataManagementContext } from '../../components/viewers/DataManagementViewer';
import { gEnums } from '../../enums/globalEnums';
import { googleSheetsHandlers, googleSheetsInitialState, googleSheetsReducer, googleSheetsTypes } from '../reducers/GoogleSheetsReducer';
import { ParentContext } from './ParentContext';
import { PreviewContext } from './PreviewContext';

/** AppContext */
export const GoogleSheetsContext = createContext();

/** Global OtherDatas for the Event */
const GoogleSheetsProvider = (props) => {

  const { googleSheetsType } = props ?? {}

  const parentContext = useContext(ParentContext)
  const { states, handlers, settings } = parentContext ?? {}
  const { appSettings_state, eventInfo_state, page_state, paps_state } = states ?? {}
  const { appSettings_handlers, eventInfo_handlers } = handlers ?? {}
  const { homeSettings } = settings ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}
  const { globalAppSettings, logging } = homeSettings_global ?? {}
  const { databaseProjectId } = globalAppSettings ?? {}
  const { dataRefreshOn, tempDataSource, tempGoogleSheetsId } = appSettings_state ?? {}

  // papsContext 
  const { pathViews, appViewMode } = paps_state ?? {}

  // pageContext  
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems, aps_views } = pageSettings ?? {}
  const { googleSheets, googleForms, googleSheetsImportOptions, appDataSource, dataOptions } = aps_global ?? {}
  const { appDataSourceType, useAppDataLinks } = appDataSource ?? {}
  const { googleSheetsId } = googleSheets ?? {}
  const { googleFormsResponseId } = googleForms ?? {}
  const { dateFormat } = dataOptions ?? {}

  const _appDataSourceType = tempDataSource ? tempDataSource : appDataSourceType

  // eventInfoContext  
  const { staticViews, appDataLinks } = eventInfo_state ?? {}

  // previewContext
  const previewContext = useContext(PreviewContext)
  const { preview_handlers } = previewContext ?? {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state } = dataManagementContext ?? {}

  const forDataManagement = dataManagement_state ? true : false

  // googleSheets
  const appInfo = { pageSettings, staticViews, aps_viewItems, aps_views }
  const googleSheets_initState = { appInfo, pathViews, databaseProjectId, googleSheetsImportOptions, forDataManagement, useAppDataLinks, appDataLinks, appDataSource, logging }
  const [googleSheets_state, googleSheets_dispatch] = useReducer(googleSheetsReducer, googleSheetsInitialState(googleSheets_initState));
  const googleSheets_handlers = googleSheetsHandlers(googleSheets_dispatch, googleSheets_state)

  const { googleSheetsOn, googleSheetsData, staticViews: staticViews_googleSheets, appSettings_google, dashboardInfo_preview } = googleSheets_state ?? {}
  const { projectSettings } = dashboardInfo_preview ?? {}

  useEffect(() => {
    if (googleSheetsId) {
      switch (appViewMode) {
        case gEnums.appViewModes.googleSheets:
          appSettings_handlers.handleDataSourceType(gEnums.dataSourceTypes.googleSpreadSheet)
          break;
        default:
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appViewMode]);

  useEffect(() => {
    if (googleSheetsId) {
      switch (googleSheetsType) {
        case googleSheetsTypes.normal:
          if (googleSheetsImportOptions) { googleSheetsImportOptions.dateFormat = dateFormat }
          googleSheets_handlers.handleGet_googleSheet(googleSheetsId)
          break;
        case googleSheetsTypes.registration:
          googleSheets_handlers.handleGet_googleSheet(googleFormsResponseId)
          break;
        case googleSheetsTypes.app:
          switch (_appDataSourceType) {
            case gEnums.dataSourceTypes.googleSpreadSheet:
              if (googleSheetsImportOptions) { googleSheetsImportOptions.dateFormat = dateFormat }
              googleSheets_handlers.handleStartUpdate()
              const _googleSheetsId = tempGoogleSheetsId ? tempGoogleSheetsId : googleSheetsId
              googleSheets_handlers.handleGet_googleSheet(_googleSheetsId, null, true)
              break;
            default:
            // nothing
          }
          break;
        default:
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleSheetsId, googleFormsResponseId, dataRefreshOn, _appDataSourceType]);

  // updates the eventInfo.staticViews_gs
  useEffect(() => {
    if (projectSettings) {
      preview_handlers.handleSettings_googleSheets(projectSettings)
      // page_handlers.handleAmmend_pageSettingsFromGoogleSheets(copyObj(appSettings_google))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSettings]);

  // useEffect(() => {
  //   // if settings_preview_changed, update the preview 
  //   if (settings_preview && settingsPreviewOn) {
  //     preview_handlers.handleSettings_preview(settings_preview, settingsPreviewOn, settingsPreviewStatus)
  //   }
  //   // eslint-disable-next-line
  // }, [settings_preview_changed]);

  // updates the eventInfo.staticViews_gs
  useEffect(() => {
    if (eventInfo_handlers) {
      switch (_appDataSourceType) {
        case gEnums.dataSourceTypes.googleSpreadSheet:
          eventInfo_handlers.handleSet_googleSheetsStaticViews(staticViews_googleSheets)
          break;
        default:
          eventInfo_handlers.handleSet_googleSheetsStaticViews(null)
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleSheetsData, _appDataSourceType]);

  const providerContext = () => <GoogleSheetsContext.Provider
    value={{ googleSheets_handlers: googleSheets_handlers, googleSheets_state: googleSheets_state }}>
    {props.children}
  </GoogleSheetsContext.Provider>

  switch (_appDataSourceType) {
    case gEnums.dataSourceTypes.googleSpreadSheet:
    case gEnums.dataSourceTypes.googleSheetsData:
      return googleSheetsData ? providerContext() : providerContext()
    default:
      if (googleSheetsOn) {
        return googleSheetsData ? providerContext() : providerContext()
      } else {
        return providerContext()
      }
  }
}

export default GoogleSheetsProvider