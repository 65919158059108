import _ from 'lodash'
import { isOdd } from '../../../../../global/common/filtering'

export const createSeasonFromGrid = (result, state) => {

  if (result) {

    const { seasonal_current, sports_data } = state
    const { teams: teams_sport } = sports_data ?? {}

    const { details, teams: teams_d } = seasonal_current ?? {}
    const { year } = details ?? {}
    const { teams, levels, section_info, levels_info, currentInfo } = createTeamsFromGrid(result, teams_sport)
    const playDates = findDatesFromGrid(result, year)

    const _teams = {}

    const yTeams = []

    if (teams) {
      teams.forEach(team => {
        const yTeam = _.find(teams_sport, { name: team.name })
        if (!yTeam) {
          yTeams.push(team.name)
        } else {
          _teams[yTeam._itemKey] = { ...team, ...yTeam }
        }
      })
    }

    const matches = []
    const openMatches = []

    let levelName;
    let startSection = false
    let sectionName;
    let isSectionHeader;

    result.forEach((row, rowIndex) => {
      if (_.isArray(row)) {
        if (row[0] && row[0].indexOf('Section') >= 0) {
          isSectionHeader = true
          startSection = true
          sectionName = row[0].replace('Section ', 'section')
        } else {
          isSectionHeader = false
        }

        const _levelName = findLevel(row)
        levelName = _levelName ? _levelName : levelName

        if (startSection) {
          if (row.length === 0) {
            startSection = false
          } else {
            let teamName;
            let matchDateIndex = 0
            let isHome;
            row.forEach((cellValue, cellIndex) => {
              let _cellValue = cellValue.trim()
              let opponentName;
              switch (cellIndex) {
                case 0:
                  teamName = _cellValue
                  break;
                default:
                  if (isOdd(cellIndex)) {
                    isHome = _cellValue === '1' ? true : false
                  } else {
                    opponentName = _cellValue

                    const thisTeam = _.find(_teams, { name: teamName })

                    if (opponentName !== 'OPEN') {

                      const opponent = _.find(_teams, { name: opponentName })
                      const matchExisting = _.find(matches, function (m) { return ((m.home.name === thisTeam.name) && (m.away.name === opponent.name) || (m.away.name === thisTeam.name) && (m.home.name === opponent.name)) })

                      const _home = isHome ? thisTeam : opponent
                      const _away = !isHome ? thisTeam : opponent

                      if (!matchExisting) {
                        if (opponent && opponentName !== 'OPEN') {
                          matches.push(
                            {
                              home: _home,
                              away: _away,
                              startDate: playDates[matchDateIndex],
                              levels: opponent.levels === levelName ? levelName : 'Non',
                              sections: opponent.sections === sectionName ? sectionName : 'Non',
                              teams: [_home.id, _away.id],
                              key: _away.name + '-' + _home.name
                            }
                          )
                        }
                      }
                    } else {
                      // openMatches.push(
                      //   {
                      //     home: _home,
                      //     away: _away,
                      //     startDate: playDates[matchDateIndex],
                      //   }
                      // )
                    }

                    matchDateIndex++
                  }
              }
            })
          }
        }
      }
    })

    // teams.forEach(team => {
    //   const _teamMatches = _.filter(matches, function (m) { return (m.home === team.name) || (m.away === team.name) })
    //   team.matches = _.sortBy(_teamMatches, 'matchDateIndex')
    // })

    return { matches, teams: _teams, openMatches, levels, playDates, section_info, levels_info, currentInfo }

  }
}

const findDatesFromGrid = (result, year) => {

  if (result) {

    const playDates = []
    const _year = '/' + year
    const _yearSub = '/' + (year - 1)
    let dateRowIndex = -1

    result.forEach((row, rowIndex) => {
      if (_.isArray(row) && dateRowIndex < 0) {
        row.forEach((cellValue, cellIndex) => {
          const _cellValue = cellValue.trim()
          if ((_cellValue.indexOf(_year) >= 0) || _cellValue.indexOf(_yearSub) >= 0) {
            dateRowIndex = rowIndex
          }
        })
      }
    })

    if (dateRowIndex >= 0) {
      const dateRow = result[dateRowIndex]
      dateRow.forEach(dateValue => {
        if ((dateValue.indexOf(_year) >= 0) || dateValue.indexOf(_yearSub) >= 0) {
          playDates.push(dateValue)
        }
      })
    }


    return playDates

  }
}

const createTeamsFromGrid = (result, teams_sport) => {

  if (result) {

    const teams = []
    const levels = []
    const levels_info = {
      next: {},
      existing: {}
    }
    const section_info = {
      counts: {}
    }
    const currentInfo = {
      all: {},
      selected: {}
    }

    let levelName;
    let startSection = false
    let sectionName;
    let isSectionHeader;

    let teamPosition = 0;

    result.forEach((row, rowIndex) => {

      if (_.isArray(row)) {

        if (row[0] && row[0].indexOf('Section') >= 0) {
          isSectionHeader = true
          startSection = true
          sectionName = row[0].replace('Section ', 'section')
        } else {
          isSectionHeader = false
        }

        const _levelName = findLevel(row)
        levelName = _levelName ? _levelName : levelName

        const _existingLevel = _.find(levels, { name: levelName })

        if (levelName && !_existingLevel) {
          levels.push({
            name: levelName,
            level: levelName.replace('A', ''),
            checked: true
          })
        }

        if (startSection) {
          if (row.length === 0) {
            startSection = false
            teamPosition = 0
          } else {
            let teamName;
            row.forEach((cellValue, cellIndex) => {
              const _cellValue = cellValue.trim()
              switch (cellIndex) {
                case 0:
                  teamName = _cellValue
                  if (!isSectionHeader && !teams.includes(teamName)) {
                    const _teamS = _.find(teams_sport, { name: teamName })
                    if (!_teamS) {
                      console.log('teamName', teamName)
                    }
                    const _team = {
                      name: teamName,
                      levels: levelName,
                      sections: sectionName,
                      position: teamPosition
                    }
                    const _teamC = { ..._teamS, ..._team }
                    teams.push(_teamC)
                    teamPosition++
                  }
                  break;
                default:
                // nothing
              }
            })
          }
        }
      }
    })

    levels.forEach(level => {
      const _teams = _.filter(teams, { levels: level.name })
      const _sectionTeams = _.groupBy(_teams, 'sections')
      level.sections = _sectionTeams
      section_info.counts[level.name] = Object.keys(level.sections).length
      levels_info.next[level.name] = {
        name: level.name,
        sections: {},
        sectionCount: Object.keys(_sectionTeams).length,
        checked: true,
      }
      Object.keys(_sectionTeams).forEach(sectionKey => {
        const teams = _sectionTeams[sectionKey]
        levels_info.next[level.name].sections[sectionKey] = {
          name: _.startCase(sectionKey),
          checked: true,
          teams: teams
        }
      })
    })

    levels_info.existing = levels_info.next


    currentInfo.selected = teams
    currentInfo.all = teams

    return { teams, levels, section_info, levels_info, currentInfo }

  }
}

const findLevel = (row) => {
  let level;
  row.forEach((cellValue, cellIndex) => {
    if (cellValue && cellValue.indexOf('Class ') >= 0) {
      level = cellValue.replace('Class ', '')
      level = _.camelCase(level)
    }
  })
  return level
}