import _ from 'lodash';
import { convertHelpers } from '../../../../../global/common/convert';
import { creatingHelpers } from '../../../../../global/common/creating';
import { formatItem, formatTypes, getFormattedDate } from '../../../../../global/common/dateFormatting';
import { addIdsToDataItem } from '../../../../../global/firestoreData/appData/ammendData';
import { getParentKeyData_sports } from './parentKeySportsData';
import { _matchSeasonalCollection } from '../SportsSeasonReducer';
import { sportsHelpers } from './sportsHelpers';
import { matchCalcs } from '../../../helpers/match';
import { realTimeScoring } from '../../../dbActions/realtimeScoring';
import { realtimeDocumentTypes } from '../SportsEditMatchReducer';

// const _useNewMethod = true

/**
 * Gets the data for the dataItems (teams, matches, rankings, history, scheduleDates and playoffMatches)
 * @param {object} pathViews 
 * @param {object} dataParents 
 * @param {object} additionalDataParents 
 * @param {string} selectedSeason 
 * @param {function} handleSetDataResults  
 */

// pathViews, dataItems, dataParents, additionalDataParents, sportsKey, selectedSeason, handleSetDataResults, gameScoringType
export const getSportsData_parentKeyCollections = async (sportsOriginType, props) => {

  const {
    additionalDataParents,
    data_cache,
    database_fns,
    dataItems,
    dataParents,
    handleSetDataResults,
    pathViews,
    selectedSeason,
    sportsKey,
  } = props

  const allowCache = true

  const fetches = {
    cache: [],
    database: [],
  }

  const data_pure = {}
  const sports_data = {}
  const sports_refs = {}
  const parentDataKeys = {}

  const callback_datas = () => {

    let valid = true
    let valid_pure = true

    const notValids = []
    const notValids_pure = []

    // loop the dataItems for the component
    _.forEach(dataItems, (_dataItem) => {

      const { collectionName, collectionSubs, collectionDoc } = _dataItem ? _dataItem : {}

      switch (collectionName) {
        case _matchSeasonalCollection:
          if (collectionSubs) {
            _.forEach(collectionSubs, (collectionSub) => {
              if (!sports_data[collectionSub]) {
                valid = false
                notValids.push(collectionSub)
              }
            })
          }
          break;
        default:
          if (collectionDoc) {
            if (!sports_data[collectionDoc]) {
              valid = false
              notValids.push(collectionDoc)
            }
          } else if (!sports_data[collectionName]) {
            valid = false
            notValids.push(collectionName)
          }
      }

      switch (collectionName) {
        case _matchSeasonalCollection:
          if (collectionSubs) {
            _.forEach(collectionSubs, (collectionSub) => {
              if (!data_pure[collectionSub]) {
                valid_pure = false
                notValids_pure.push(collectionSub)
              }
            })
          }
          break;
        default:
          if (!data_pure[collectionName]) {
            valid_pure = false
            notValids_pure.push(collectionName)
          }
      }
    })

    if (valid_pure) {
      const rps = {
        data_pure,
        parentDataKeys,
        sportsKey,
        sports_refs,
        fetches,
      }
      handleSetDataResults(rps)
    }

    if (valid) {
      // if (sports_refs) {
      //   const all = {}
      //   Object.keys(sports_refs).forEach(k => {
      //     all[k] = {
      //       ref: sports_refs[k],
      //       data: sports_data && sports_data[k] ? sports_data[k] : null,
      //       parentDataKey: parentDataKeys && parentDataKeys[k] ? parentDataKeys[k] : null,
      //     }
      //   })
      // }

      const matches_data = getMatchesData(sports_data, selectedSeason)
      console.log('matches_data', matches_data)

      handleSetDataResults(data_pure, sports_data, matches_data, parentDataKeys, sportsKey, sports_refs)
    }

  }

  // data, opts
  const callback_data = (data, opts) => {

    const { dataItem, sub } = opts ?? {}
    const { _dataItemKey, collectionName } = dataItem ?? {}
    const _cName = sub ? sub : collectionName

    data_pure[_cName] = {
      data,
      opts,
      _dataItemKey
    }

    callback_datas()

  }

  const _refPaths = []

  // loop the dataItems, the items that will get retrieved from the database
  _.forEach(dataItems, (dataItem, key) => {

    let existingData;
    const existingSubDatas = {};

    let hasExistingData;
    let hasExistingSubData;

    const { collectionName, collectionSubs, ignoreCache } = dataItem ?? {}

    if (data_cache) {
      if (collectionSubs) {
        _.forEach(collectionSubs, (collectionSub) => {
          if (data_cache[collectionSub]) {
            existingSubDatas[collectionSub] = data_cache[collectionSub]
            hasExistingSubData = allowCache && !ignoreCache
            fetches.cache.push(collectionSub)
          }
        })
      } else {
        if (data_cache[collectionName]) {
          existingData = {
            [collectionName]: data_cache[collectionName]
          }
          hasExistingData = allowCache
          fetches.cache.push(collectionName)
        }
      }
    }

    if (hasExistingData) {
      const opts = { dataItem }
      callback_data(existingData, opts)
    } else if (hasExistingSubData) {
      _.forEach(existingSubDatas, (subData, subDataKey) => {
        const opts = { dataItem, sub: subDataKey }
        const _subData = {
          [subDataKey]: subData
        }
        callback_data(_subData, opts)
      })
    } else {
      fetches.database.push(collectionName)
      dataItem._dataItemKey = key
      const pkdProps = {
        pathViews,
        dataItem,
        dataParents,
        callback: callback_data,
        additionalDataParents,
        sportsKey,
        latestSeason: selectedSeason,
        _refPaths,
        database_fns
      }
      getParentKeyData_sports(pkdProps)
    }

  })

}

export const ammendPureDatas = (dataItems, data_pure, selectedSeason) => {

  const sports_data = {}
  const sports_refs = {}
  const parentDataKeys = {}

  _.forEach(data_pure, (dataItem_pure, key) => {

    const { data, opts } = dataItem_pure
    const dataItem = dataItems[key]

    const { sub, itemKey: itemKey_opts, _refPath, wherez } = opts ?? {}
    const { collectionName, collectionDoc, docField, docFields } = dataItem ?? {}

    const _cName = sub ? sub : collectionName

    let dataResultName = collectionDoc ? collectionDoc : _cName
    let dataCollectionName = collectionDoc ? collectionDoc : _cName
    let ignoreData = false;

    const { _itemKey } = data ?? {}
    let d = data ? data[dataResultName] : {}

    switch (_cName) {
      case 'matches_backup':
      case 'matches':
      case 'playoffs':
      case 'teams':
        const itemKey = itemKey_opts ? itemKey_opts : _itemKey
        addIdsToDataItem(d)
        if (itemKey) {
          parentDataKeys[collectionName] = itemKey
        }
        break;
      default:
      // nothing
    }

    switch (_cName) {
      case 'googleLinks':
        if (docFields) {
          ignoreData = true
          docFields.forEach(df => {
            d = data ? data[df] : {}
            sports_data[collectionName + '_' + df] = d ?? {}
            parentDataKeys[collectionName + '_' + df] = _itemKey ? _itemKey : null
          })
        } else if (docField) {
          d = data ? data[docField] : {}
          dataCollectionName = docField
        }
        break;

      case 'seasonals':
        ignoreData = true
        _.forEach(data, (dataItem, dataItemKey) => {
          switch (dataItemKey) {
            case 'details':
            case 'sportsSettings':
              sports_data[dataItemKey] = dataItem
          }
        })
        // if (collectionDoc) {
        //   d = data ? data[collectionDoc] : {}
        //   dataCollectionName = collectionDoc
        //   parentDataKeys[collectionName] = _itemKey
        // } 
        break;

      case 'staff':
      case 'rosters':
      case 'rankings':
        parentDataKeys[collectionName] = _itemKey
        d = data
        break;

      case 'matches_backup':
      case 'matches':
      case 'matchesNonSection':
      case 'playoffs':
        break;

      case 'schoolsByState':
        d = { schools: data && data.schools ? data.schools : {} }
        break;

      case 'sportsAccess_district':
        d = {
          schools: data && data.schools ? data.schools : {},
          sports: data && data.sports ? data.sports : {},
        }
        break;

      case 'sportsAccess_schools':
        d = { schools: data && data.schools ? data.schools : {} }
        break;

      case 'sportsAccess_sports':
        d = { teams: data && data.teams ? data.teams : {} }
        break;

      default:
      // nothing
    }

    if (!ignoreData) {
      if (d && d._itemKey) { delete d._itemKey }
      sports_data[dataCollectionName] = d ?? {}
      sports_refs[dataCollectionName] = { _refPath, wherez }
      if (_itemKey) {
        parentDataKeys[dataCollectionName] = _itemKey ? _itemKey : null
      }
    }
  })

  const matches_data = getMatchesData(sports_data, selectedSeason)

  return { sports_data, matches_data }

}

export const adjustSeasonState = (asProps) => {

  const {
    _getScoresFromScores,
    _sportsModeSport,
    action,
    dataItems_sportsSeason,
    fetches,
    gameScoringType,
    state,
  } = asProps

  const { sports_data, matches_data, parentDataKeys } = action

  getDataSizes(sports_data, matches_data)

  const {
    levelDateGroups,
    levelLastDates,
    levelLatestDates,
    levelMatches,
    matchDateGroups,
    playoffTournaments,
    selectedSeason: _selectedSeason,
  } = matches_data ?? {}

  const drs = getDRS(dataItems_sportsSeason, sports_data)

  let _teamsFromMatches;

  if (drs.matches && drs.teams && _.isEmpty(drs.teams)) {
    _teamsFromMatches = getTeamsFromMatches(drs.matches)
  }

  const { details } = drs ?? {}
  let sectionPlayoffTeams = {}

  if (details) {
    const _ptPrefix = 'playoffTeamsPerSection'
    for (let ptps = 3; ptps <= 10; ptps++) {
      if (details[_ptPrefix + ptps]) {
        sectionPlayoffTeams[ptps] = details[_ptPrefix + ptps]
      }
    }
  }

  const {
    scheduleDates,
    sectionDates,
    sportsSettings,
  } = sports_data ?? {}

  const _rankings = drs.rankings ? drs.rankings.rankings : null

  if (_getScoresFromScores) {
    sportsHelpers.ammendScoresWithScores(drs.matches, drs.scores)
    sportsHelpers.ammendScoresWithScores(drs.playoffMatches, drs.playoffScores)
  }

  // at this point, drs.matches does not have a _display nor _status 

  if (drs.matches) { matchCalcs.calcAllVballMatches(drs.matches, gameScoringType) }
  if (drs.playoffMatches) { matchCalcs.calcAllVballMatches(drs.playoffMatches, gameScoringType) }

  const _lMatches = drs.playoffMatches ? { ...drs.matches, ...drs.playoffMatches } : drs.matches

  const upcomingMatchDate = new Date()
  const levelUpcomingMatches = getUpcomingMatches(_lMatches, upcomingMatchDate)
  const _levelUpcomingMatches = {}

  if (matchDateGroups) {
    _.forEach(matchDateGroups, (mdg) => {
      const { matchDate } = mdg
      const _lum = getUpcomingMatches(_lMatches, new Date(matchDate))
      _levelUpcomingMatches[matchDate] = _lum
    })
  }

  const ssi = drs.teams && drs.matches ? sportsHelpers.ammendTeams(drs.teams, drs.matches, _rankings, _sportsModeSport, sectionPlayoffTeams) : null
  const { sportLevels, teams } = ssi ?? {}
  const { matchDateKeys, matchDateKeys_count } = sportsHelpers.getMatchDateKeys(matchDateGroups)

  const matchesTeams = {}

  const amm = sportsHelpers.ammendMatchesInfo(drs.matches, drs.playoffMatches)

  if (amm.matches) {

    if (teams) {
      Object.keys(teams).forEach(tk => {
        const tms = _.filter(amm.matches, function (m) { return m.teams && m.teams.includes(tk); })
        const today = new Date();
        const _next = _.takeRight(_.filter(_.orderBy(tms, ['startDate'], ['desc']), item => new Date(item.startDate).setHours(state._startHour, 0, 0, 0) >= today), 1);
        const _latest = _.takeRight(_.filter(_.orderBy(tms, ['startDate'], ['asc']), item => (item.results && item.results.complete === true) && new Date(item.startDate).setHours(state._startHour, 0, 0, 0) < today), 5);
        matchesTeams[tk] = {
          all: tms,
          next: _next,
          latest: _latest
        }
      })
    }
  }

  // if (useRtMatchData) {
  //   realTimeScoring.getRealtimeScores(realtimeDocumentTypes.realtime, sportData, pathViews, latestSeason, handleSet_realtimeResults)
  //   realTimeScoring.getRealtimeScores(realtimeDocumentTypes.matchDay, sportData, pathViews, latestSeason, handleSet_realtimeMatchDateResults)
  // }

  const _sections = teams ? _.groupBy(teams, 'sections') : null
  const levelVisibleDates = {}

  if (levelLatestDates) {
    _.forEach(levelLatestDates, (lld, lk) => {
      levelVisibleDates[lk] = [lld]
    })
  }

  return {
    ...state,
    latestSeason: _selectedSeason,
    latestSeason_full: _selectedSeason ? (_selectedSeason - 1) + '-' + _selectedSeason : _selectedSeason,
    currentSeason: _selectedSeason,
    history_info: {
      history: drs.history,
      teams: sportsHelpers.getTeamHistories(drs.teams, drs.history)
    },
    matches_info: {
      matchDateGroups,
      matchDateKeys_count,
      matchDateKeys,
      levelMatches,
      levelDateGroups,
      levelLastDates,
      levelLatestDates,
      levelVisibleDates,
      levelUpcomingMatches,
      _levelUpcomingMatches,
      matches: amm.matches,
      playoffMatches_backup: drs.playoffMatches_backup ? drs.playoffMatches_backup : {},
      playoffMatches: amm.playoffMatches,
      matchesAll: drs.playoffMatches ? { ...amm.matches, ...drs.playoffMatches } : amm.matches,
      matchesRetrieved: true,
      scores: drs.scores,
      playoffScores: drs.playoffScores,
    },
    playoff_info: {
      playoffTournaments: playoffTournaments ? playoffTournaments : null,
    },
    teams_info: {
      teams: teams,
      rosters: drs.teamRosters,
      staff: drs.teamStaff,
      playoffTeams: teams && sportsHelpers.getPlayoffTeams(teams),
      sectionKeys: _sections ? Object.keys(_sections).sort() : [],
      teams_fromMatches: _teamsFromMatches
    },
    tournaments_info: {
      tournaments: drs.tournaments,
    },
    rankings_info: {
      rankings: _rankings,
      lastestRanking: sportsHelpers.getLastestRankings(_rankings)
    },
    seasonal_info: {
      scheduleDates,
      sectionDates,
      sportLevels,
      totalWeeks: amm.totalWeeks,
      firstMatchDate: amm.firstMatchDate,
      lastMatchDate: amm.lastMatchDate,
      seasonReady: true,
      sportsSettings,
    },
    details_info: {
      details: drs.details,
      sectionPlayoffTeams,
    },
    googleLinks_info: {
      gls_teams: drs.gls_teams
    },
    sportsAccess_sports: {
      teams: drs.sportsAccess_sports ? { ...drs.sportsAccess_sports.teams } : {},
    },
    gls_teams: drs.gls_teams ? { ...drs.gls_teams } : {},
    parentDataKeys,
    sports_data,
    upcomingMatchDate,
    fetches
  }

}

const getDRS = (dataItems_sportsSeason, sports_data) => {

  const drs = {}

  _.forEach(dataItems_sportsSeason, (dataItem, dataItemKey) => {
    const { collectionName, collectionDoc, collectionSubs } = dataItem ?? {}
    switch (collectionName) {
      case _matchSeasonalCollection:
        if (collectionSubs) {
          _.forEach(collectionSubs, (collectionSub) => {
            drs[collectionSub] = sports_data[collectionSub]
          })
        }
        break;
      default:
        if (collectionDoc) {
          drs[collectionDoc] = sports_data[collectionDoc]
        } else if (sports_data[collectionName]) {
          drs[dataItemKey] = sports_data[dataItemKey]
        }
    }
  })

  return drs
}

const getMatchesData = (sports_data, selectedSeason) => {

  const { matches, rosters, teams, staff, details } = sports_data

  const matches_data = {
    playoffTournaments: null,
    matchDateGroups: null,
    levelMatches: null,
    levelDateGroups: null,
    levelLastDates: null,
    levelLatestDates: null,
  }

  if (matches) {
    const { _matches, _playoffMatches, _dateGroups, _levelMatches, _levelDateGroups } = getAllMatches(sports_data)
    matches_data.matches = _matches
    matches_data.playoffMatches = _playoffMatches
    matches_data.matchDateGroups = _dateGroups
    matches_data.levelMatches = _levelMatches
    matches_data.levelDateGroups = _levelDateGroups
    const { _lastLevelDates, _latestLevelDates } = getLastMatchDates(_levelDateGroups)
    matches_data.levelLastDates = _lastLevelDates
    matches_data.levelLatestDates = _latestLevelDates
    matches_data.playoffTournaments = createPlayoffTournaments(_playoffMatches)
    matches_data.selectedSeason = selectedSeason
  }

  if (teams && rosters) { matches_data['rosters'] = getAllTeamItems(matches_data, 'rosters', 'athletes') }
  if (teams && staff) { matches_data['staff'] = getAllTeamItems(matches_data, 'staff', 'staff') }
  if (details) { matches_data['details'] = details }

  return matches_data

}

const getAllMatches = (datas) => {
  // playoffMatches is coming from it's own collection
  const { matches, playoffMatches } = datas
  if (matches) {
    let _matches = matches
    const _playoffMatchesFromMatches = _.pickBy(_matches, { _matchCollectionKey: 'playoffs' })
    const _playoffMatches = !_.isEmpty(playoffMatches) ? playoffMatches : _playoffMatchesFromMatches
    // combine the matches if needed
    if (!_.isEmpty(playoffMatches)) { _matches = { ...matches, ...playoffMatches } }
    const { dateGroups: _dateGroups, missingMatches } = getMatchDateGroups(_matches)
    const { _levelMatches, _levelDateGroups } = getLevelMatches(_matches)
    return { _matches, _playoffMatches, _dateGroups, _levelMatches, _levelDateGroups, missingMatches }
  }
}

const getLevelMatches = (matches) => {

  // group the matches by level.
  const matches_groupedByLevel = _.groupBy(matches, 'levels')

  const _levelMatches = {}
  const _levelDateGroups = {}

  if (matches_groupedByLevel) {

    Object.keys(matches_groupedByLevel).forEach(levelKey => {

      let matches_level = matches_groupedByLevel[levelKey]

      if (matches_groupedByLevel.Non) {
        const matches_home = _.filter(matches_groupedByLevel.Non, m => m.home && m.home.levels === levelKey);
        const matches_away = _.filter(matches_groupedByLevel.Non, m => m.away && m.away.levels === levelKey);
        matches_level = [...matches_level, ...matches_home, ...matches_away]
      }

      if (matches_groupedByLevel.nonDistrict) {
        const matches_home = _.filter(matches_groupedByLevel.nonDistrict, m => m.home && m.home.levels === levelKey);
        const matches_away = _.filter(matches_groupedByLevel.nonDistrict, m => m.away && m.away.levels === levelKey);
        matches_level = [...matches_level, ...matches_home, ...matches_away]
      }

      // create the _itemKeys
      const _groupMatches = creatingHelpers.createKeyedObjectFromKey(matches_level, null, '_itemKey')
      _levelMatches[levelKey] = _groupMatches
      const { dateGroups: _dateGroups } = getMatchDateGroups(_groupMatches)
      _levelDateGroups[levelKey] = _dateGroups
    })
  }

  return { _levelMatches, _levelDateGroups }

}

const getAllTeamItems = (datas, itemType, itemPropType) => {
  const _items = {}
  const { teams } = datas
  const _data = datas[itemType]
  if (teams && _data) {
    Object.keys(teams).forEach(tk => {
      const xxx = _.filter(_data, function (item) {
        return (item && item.parentKeys && item.parentKeys.teams === tk);
      })
      if (xxx && xxx.length === 1) {
        _items[tk] = xxx[0][itemPropType]
      }
    })
  }
  return _items
}



const getMatchDateGroups = (sortedMatches) => {

  convertHelpers.createItemKeys(sortedMatches)

  const today = new Date()
  const todayF = formatItem(formatTypes.fullDate, today)

  let matchDates = _.groupBy(sortedMatches, 'startDate')

  const dateGroups = []
  let missingMatches = []

  // let groupKeys = Object.keys(x)
  let groupKeys = Object.keys(matchDates)
  groupKeys = _.sortBy(groupKeys, function (value) { return new Date(value); })

  groupKeys.forEach(gk => {
    dateGroups.push({
      matchDate: gk,
      matches: creatingHelpers.createKeyedObjectFromKey(matchDates[gk], null, '_itemKey')
    })
  })

  if (groupKeys[todayF]) {
    dateGroups.unshift({
      matchDate: todayF,
      matches: creatingHelpers.createKeyedObjectFromKey(matchDates[todayF], null, '_itemKey')
    })
  }

  const mz = getMissing(sortedMatches)

  if (mz && Object.keys(mz).length > 0) {
    missingMatches = mz
  }

  return { dateGroups, missingMatches }

}

const createPlayoffTournaments = (playoffMatches) => {
  const ts = []
  const levelMatches = _.groupBy(playoffMatches, 'levels')
  if (levelMatches) {
    Object.keys(levelMatches).forEach(levelKey => {
      ts[levelKey] = { rounds: { playoffs: { itemKey: 'playoffs', pools: {} } } }
      const lm = levelMatches[levelKey]
      const poolMatches = _.groupBy(lm, 'poolKey')
      Object.keys(poolMatches).forEach(poolKey => {
        ts[levelKey].rounds.playoffs.pools[poolKey] = {
          poolKey: poolKey,
          matches: mz(poolMatches[poolKey])
        }
      })
    })
  }
  return ts
}

const mz = (matches) => {
  const ms = {}
  matches.forEach(m => {
    ms['match' + m.matchNumber] = m
  })
  return ms
}

const getMissing = (matches) => {
  if (matches) {
    const missing = _.filter(Object.values(matches), function (m) {
      const today = new Date()
      const yesterday = today.setDate(today.getDate() - 1)
      return (
        new Date(m.startDate) < yesterday
        && !m.results
      );
    })
    if (missing) {
      const m_s = creatingHelpers.createKeyedObjectFromKey(missing)
      return m_s
    }
  }
}

export const getUpcomingMatches = (matches, upcomingMatchDate) => {

  const _today = getFormattedDate(upcomingMatchDate)

  const matches_level = _.groupBy(matches, 'levels')

  const filterMatchesByStartDate = (group, formattedMatchDate) => _.filter(group, match => match.startDate === formattedMatchDate && !match?._status?.isBye);

  const levelUpcomingMatches = {}

  _.forEach(matches_level, (matches, level) => {
    if (level !== 'undefined') {
      const dateLimits = getMatchDateLimits(matches)

      let _startDate = new Date(upcomingMatchDate);
      _startDate.setHours(0, 0, 0, 0);

      let _startDateFound;
      let filteredMatches = [];
      let selectedMatches = [];
      let iterations = 0;

      if (dateLimits.latestStartDate >= _startDate) {
        // Continue searching until you find matches with a non-zero count
        while (filteredMatches.length === 0 && iterations < 80) {
          const formattedMatchDate = getFormattedDate(_startDate)
          _startDateFound = formattedMatchDate
          filteredMatches = filterMatchesByStartDate(matches_level[level], formattedMatchDate);
          _startDate.setDate(_startDate.getDate() + 1); // Move to the next day
        }
        selectedMatches = selectedMatches.concat(filteredMatches);
        levelUpcomingMatches[level] = {
          matches: filteredMatches,
          startDate: _startDateFound,
          level
        }
      } else {
        levelUpcomingMatches[level] = {
          matches: [],
          offSeason: true,
          level
        }
      }
    }
  })
  // // Iterate through groups using forEach
  // Object.keys(matches_level).forEach(level => {

  //   if (level !== 'undefined') {
  //     const matches = matches_level[level]

  //     const dateLimits = getMatchDateLimits(matches)

  //     let _startDate = new Date(upcomingMatchDate);
  //     _startDate.setHours(0, 0, 0, 0);

  //     let _startDateFound;
  //     let filteredMatches = [];
  //     let selectedMatches = [];
  //     let iterations = 0;

  //     if (dateLimits.latestStartDate >= _startDate) {
  //       // Continue searching until you find matches with a non-zero count
  //       while (filteredMatches.length === 0 && iterations < 80) {
  //         const formattedMatchDate = getFormattedDate(_startDate)
  //         _startDateFound = formattedMatchDate
  //         filteredMatches = filterMatchesByStartDate(matches_level[level], formattedMatchDate);
  //         _startDate.setDate(_startDate.getDate() + 1); // Move to the next day
  //       }
  //       selectedMatches = selectedMatches.concat(filteredMatches);
  //       levelUpcomingMatches[level] = {
  //         matches: filteredMatches,
  //         startDate: _startDateFound
  //       }
  //     } else {
  //       levelUpcomingMatches[level] = {
  //         matches: [],
  //         offSeason: true
  //       }
  //     }
  //   }

  // });

  Object.keys(levelUpcomingMatches).forEach(lk => {
    const lms = levelUpcomingMatches[lk]
    const { startDate, matches, offSeason } = lms
    levelUpcomingMatches[lk].offSeason = offSeason
    levelUpcomingMatches[lk].hasMatches = startDate === _today ? true : false
    levelUpcomingMatches[lk].matchCount = startDate === _today ? matches.length : 0
    levelUpcomingMatches[lk].caption = startDate === _today ? matches.length + ' matches' : 'No matches'
    levelUpcomingMatches[lk].subCaption = startDate === _today ? '' : 'Next match ' + formatItem(formatTypes.fullDate, startDate) + '.'
    if (offSeason) {
      levelUpcomingMatches[lk].subCaption = 'No Matches Scheduled'
    }
    switch (lk) {
      case 'nonDistrict':
      case 'Non':
      case 'non':
        levelUpcomingMatches[lk].hide = levelUpcomingMatches[lk].matchCount === 0 ? true : false
    }
  })

  return levelUpcomingMatches

}

const getLastMatchDates = (levelDateGroups) => {
  const _lastLevelDates = {}
  const _latestLevelDates = {}
  Object.keys(levelDateGroups).forEach(key => {
    _lastLevelDates[key] = getLastDateKey(levelDateGroups[key])
    _latestLevelDates[key] = getLatestDateKey(levelDateGroups[key])
  })
  return { _lastLevelDates, _latestLevelDates }
}

const getLastDateKey = (ldg) => {

  const ldgKeys = []

  if (ldg) {
    Object.keys(ldg).forEach(ldgKey => {
      ldgKeys.push(ldg[ldgKey].matchDate)
    })
  }

  let dd = false;
  let _dateKey;

  ldgKeys.forEach((dgk, index) => {
    var d1 = new Date(dgk);
    var d2 = new Date();
    var diff = d2.getTime() - d1.getTime();

    _dateKey = ldgKeys[index]

    if (diff < 0 && !dd) {
      const xxx = ldgKeys.indexOf(dgk)
      const yyy = xxx - 1
      const zzz = ldgKeys[yyy]
      _dateKey = zzz
      dd = true
    }
  })

  return _dateKey
}

const getLatestDateKey = (ldg) => {

  const ldgKeys = [] // matchDateKeys

  if (ldg) {
    Object.keys(ldg).forEach(ldgKey => {
      ldgKeys.push(ldg[ldgKey].matchDate)
    })
  }

  let dateIsSet = false;
  let _dateKeyFirst = ldgKeys ? ldgKeys[0] : null;
  let _dateKeyLast = ldgKeys ? ldgKeys[ldgKeys.length - 1] : null;

  // loop the keys to find a match
  ldgKeys.forEach(dgk => {

    var date_matchKey = new Date(dgk);
    var date_today = new Date();

    var diff = date_today.getTime() - date_matchKey.getTime();

    if (diff < 0 && !dateIsSet) {
      const xxx = ldgKeys.indexOf(dgk)
      const yyy = xxx - 1
      const zzz = ldgKeys[yyy]
      _dateKeyLast = zzz ? zzz : _dateKeyFirst
      dateIsSet = true
    }
  })
  return _dateKeyLast
}

const getMatchDateLimits = (matches) => {
  // Initialize variables to hold the earliest and latest startDate
  let earliestStartDate = null;
  let latestStartDate = null;

  // Iterate through the list of matches
  matches.forEach(match => {
    const matchStartDate = new Date(match.startDate);

    // Update earliestStartDate and latestStartDate
    if (earliestStartDate === null || matchStartDate < earliestStartDate) {
      earliestStartDate = matchStartDate;
    }

    if (latestStartDate === null || matchStartDate > latestStartDate) {
      latestStartDate = matchStartDate;
    }
  });

  return { earliestStartDate, latestStartDate }
}

const getDataSizes = (sports_data, matches_data) => {

  const jsonDatas = {
    sports: JSON.stringify(sports_data),
    matches: JSON.stringify(matches_data),
  }

  const sizes = {
    sports: new Blob([jsonDatas.sports]).size,
    matches: new Blob([jsonDatas.matches]).size,
  }


}

const getTeamsFromMatches = (matches) => {

  const groupedMatches = _.groupBy(matches, match => `${match.home.levels}-${match.home.sections}`);

  const allTeams = {}

  Object.keys(groupedMatches).forEach(gt => {
    const groupMatches = groupedMatches[gt]
    const sss = _.groupBy(groupMatches, match => `${match.home.id}`);
    Object.keys(sss).forEach(teamKey => {
      const ms = sss[teamKey] // array
      ms.forEach(m => {
        const { home } = m
        if (home.id && !allTeams[teamKey]) {
          allTeams[teamKey] = {
            _itemKey: home.id,
            name: home.name,
            id: home.id,
            levels: home.levels,
            sections: home.sections,
            record: {}
          }
        }
      })
    })
  })

  return allTeams

}
