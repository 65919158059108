import QRCode from 'qrcode.react';
import React, { useContext } from 'react';
import { Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../../auth/appUserAccessPermissions';
import { FrameworkContext } from '../../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { convertHelpers } from '../../../common/convert';
import { gEnums } from '../../../enums/globalEnums';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import { _appSidebarContentTypes } from '../../../cnr/reducers/FrameworkReducer';

/**
 * 
 * @param {object} props (qrCodeType, itemData, viewItem)
 * @returns 
 */
const QrCode = (props) => {

  const { qrCodeType, itemData, appSidebarContentType } = props ?? {}


  const _name = itemData ? convertHelpers.getItemName(itemData) : null

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers } = frameworkContext ?? {}

  const _qrSize = 254

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state, page_state, paps_state } = states ?? {}

  // authContext 
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { displayName, appUserSessionKey } = appUserAccess ?? {}

  // papsContext 
  const { pathViews, sharePath } = paps_state ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings, aps_global } = pageSettings ?? {}
  const { eventOptions, clientOptions } = aps_global ?? {}
  const _appOptions = { ...clientOptions, ...eventOptions }
  const { shortenedLink } = _appOptions ?? {}
  const { appUserCollection } = aps_appUserSettings ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers } = uiItemContext ?? {}

  let cn = 'qr-container'
  if (qrCodeType === gEnums.qrCodeTypes.itemData) { cn += ' solo' }

  const handleCancel = () => {
    if (item_handlers && item_handlers.handleShow_itemSidebar) {
      item_handlers.handleShow_itemSidebar()
    } else if (props.handleCancel) {
      props.handleCancel()
    } else if (appSidebarContentType) {
      framework_handlers.handleShow_appSidebarContent()
    }
  }

  const qrAppLink = () => appSidebarContentType === _appSidebarContentTypes.qrCodeShort && shortenedLink ? shortenedLink : sharePath

  const qrAppUser = () => {
    const qv = {
      clientKey: pathViews ? pathViews.clients : null,
      eventKey: pathViews ? pathViews.events : null,
      [appUserCollection]: appUserSessionKey,
      displayName,
    }
    return JSON.stringify(qv, null, 3)
  }

  const qrContent = () => {
    let qrv;
    switch (qrCodeType) {
      case gEnums.qrCodeTypes.appLink:
        qrv = qrAppLink()
        break;
      case gEnums.qrCodeTypes.appUser:
        qrv = qrAppUser()
        break;
      case gEnums.qrCodeTypes.itemData:
        qrv = sharePath
        break;
      default:
      // nothing
    }
    return <Segment placeholder className={cn}>
      <QRCode size={_qrSize} value={qrv} />
    </Segment>
  }


  const topper2 = () => {
    switch (qrCodeType) {
      case gEnums.qrCodeTypes.appLink:
        return appSidebarContentType === _appSidebarContentTypes.qrCodeShort && shortenedLink ? shortenedLink : sharePath
      case gEnums.qrCodeTypes.appUser:
        return displayName
      case gEnums.qrCodeTypes.itemData:
        return _name
      default:
      // nothing
    }
  }

  const footer = () => <div>
    <div>{sharePath}</div>
    <div>{shortenedLink}</div>
  </div>

  const wrapper = () => <Wrapper
    content={qrContent(164)}
    footer={footer()}
    wrapperType={wrapperTypes.padded}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleCancel}
    topperCaption={'QR Code'}
    topperCaption2={topper2()}
  />

  switch (qrCodeType) {
    case gEnums.qrCodeTypes.appLink:
    case gEnums.qrCodeTypes.itemData:
      return fullPageWrapper()
    default:
      return qrContent()
  }
}

export default QrCode