import { sendMessageToServiceWorker } from "../../../cnr/reducers/reducerHelpers/serviceWorkerHelpers";
import { getNow } from "../../../common/dateFormatting";
import { createRefPath, createRefPath_client, createRefPath_event } from '../../appData/appRefPaths';
import { fs_dbu } from '../../appData/fsAppDataUpdate';

export const timeStampsHelpers = {
  getEventTimeStamps_db: (database_fns, pathViews, allowListen, callback) => getEventTimeStamps_db(database_fns, pathViews, allowListen, callback),
  updateSettingsTimeStamp_db: (settingsDocName, _timestamp) => updateSettingsTimeStamp_db(settingsDocName, _timestamp),
  updateEventTimeStamp_db: (pathViews, fieldName, _timestamp) => updateEventTimeStamp_db(pathViews, fieldName, _timestamp),
  updateEventTimeStamps_db: (pathViews, documentsData, _timestamp) => updateEventTimeStamps_db(pathViews, documentsData, _timestamp),
  updateTimeStamps_sw: (cacheKey, cacheData) => updateTimeStamps_sw(cacheKey, cacheData),
}

/**
 * updates the _timeStamps/timeStamp document
 * @param {*} pathViews 
 * @param {*} vit 
 * @param {*} _timestamp 
 */
const updateSettingsTimeStamp_db = async (settingsDocName, _timestamp) => {

  const cb = () => {
    switch (fieldName) {
      case 'settings':
        break;
      default:
    }
  }

  let refPath = createRefPath(['_timeStamps', 'timeStamps'])

  // updates the _timeStamps collection
  const dataToUpdate = {
    [settingsDocName]: _timestamp ? _timestamp : getNow()
  }

  console.log('refPath', refPath)
  console.log('dataToUpdate', dataToUpdate)

  // await fs_dbu.set_doc(refPath, dataToUpdate, true, cb)

}

/**
 * updates the _timeStamps/timeStamp document
 * @param {*} pathViews 
 * @param {*} vit 
 * @param {*} _timestamp 
 */
const updateEventTimeStamp_db = async (pathViews, fieldName, _timestamp) => {

  const cb = () => {
    switch (fieldName) {
      case 'settings':
        break;
      default:
    }
  }

  let refPath;

  if (pathViews.events) {
    refPath = createRefPath_event(pathViews, ['_timeStamps', 'timeStamps'])
  } else if (pathViews.clients) {
    refPath = createRefPath_client(pathViews, ['_timeStamps', 'timeStamps'])
  } else {
    refPath = createRefPath(['_timeStamps', 'timeStamps'])
  }

  // updates the _timeStamps collection
  const dataToUpdate = {
    [fieldName]: _timestamp ? _timestamp : getNow()
  }

  console.log('refPath', refPath)

  await fs_dbu.set_doc(refPath, dataToUpdate, true, cb)

}

/**
 * 
 * @param {*} pathViews 
 * @param {*} fieldName 
 * @param {*} _timestamp 
 */
const updateEventTimeStamps_db = async (pathViews, documentsData, _timestamp) => {

  // updates the _timeStamps collection
  const refPath = createRefPath_event(pathViews, ['_timeStamps', 'timeStamps'])
  const dataToUpdate = {}

  _.forEach(documentsData, (documentData, documentKey) => {
    dataToUpdate[documentKey] = _timestamp ? _timestamp : getNow()
  })

  const xxx = await fs_dbu.set_doc(refPath, dataToUpdate, true)

  return xxx

}

const getEventTimeStamps_db = (database_fns, pathViews, allowListen, callback) => {
  const refPath = createRefPath_event(pathViews, ['_timeStamps', 'timeStamps'])
  database_fns.get_data({ refPath, callback, opts: { ignoreId: true, returnFirstObject: true, listen: allowListen } })
}

const updateTimeStamps_sw = (cacheKey, cacheData) => {
  const settings = {
    cacheKey: cacheKey,
    cacheName: '_timeStamps',
    cacheData: cacheData,
    merge: true
  }
  console.log('settings', settings)
  sendMessageToServiceWorker(settings)
}
