export const checkBasePermissions = (baseSettings, settingsConsole_home_global, adjustedPermissions_existing) => {

  const { groupSettings, groupItemSettings } = settingsConsole_home_global ?? {}

  const { global, componentPermissions, adjustedPermissions } = baseSettings ?? {}

  const _adjustedPermissions = {
    global: {},
    group: {},
    page: {},
    pageItem: {},
    prop: {},
    propSection: {}
  }

  if (adjustedPermissions) {
    Object.keys(adjustedPermissions).forEach(cpKey => {
      const cpi = adjustedPermissions[cpKey]
      ammendAdjusted(cpi, global, _adjustedPermissions[cpKey])
    })
  } else if (componentPermissions) {
    Object.keys(componentPermissions).forEach(cpKey => {
      const cpi = componentPermissions[cpKey]
      ammendAdjusted(cpi, global, _adjustedPermissions[cpKey])
    })
  }

  const missing_gi = {}
  const missing_gis = {}

  if (groupSettings) {
    Object.keys(groupSettings).forEach(k => {
      if (!_adjustedPermissions.group[k] && !_adjustedPermissions.global[k] && !_adjustedPermissions.page[k]) {
        missing_gi[k] = groupSettings[k]
      }
    })
  }

  if (groupItemSettings) {
    Object.keys(groupItemSettings).forEach(k => {
      if (!_adjustedPermissions.group[k] && !_adjustedPermissions.global[k] && !_adjustedPermissions.page[k]) {
        missing_gis[k] = groupItemSettings[k]
      }
    })
  }

  if (adjustedPermissions_existing) {
    Object.keys(adjustedPermissions_existing).forEach(k => {
      const ape = adjustedPermissions_existing[k]
      const ap = _adjustedPermissions[k]
      if (ape && ap) {
        Object.keys(ape).forEach(ak => {
          // if (ak === 'settingsActions') {
          //   console.log('settingsActions!')
          // }
          if (ap[ak]) {
            ap[ak] = { ...ap[ak], ...ape[ak] }
          }
        })
      }
    })
  }

  // if (allowUpdate) {
  //   updateAdjustedPermissions(_adjustedPermissions)
  // }

  return _adjustedPermissions

}

const ammendAdjusted = (permissions, global, adjusted) => {

  Object.keys(permissions).forEach(permKey => {

    const _permission = permissions[permKey]
    const _global = global[permKey]

    const { componentAreas } = _permission ?? {}

    const {
      settingsAuthLevel: settingsAuthLevel_gs,
      caption: caption_gs,
      icon: icon_gs,
      dividedText: dividedText_gs,
      _position: _position_gs,
      allowSettingsUpdate: allowSettingsUpdate_gs,
      settingHandler: settingHandler_gs,
      settingHandlerRts: settingHandlerRts_gs,
      settingsUpdateHandler: settingsUpdateHandler_gs,
      settingsUpdateHandlerFunction: settingsUpdateHandlerFunction_gs,
    } = _permission ?? {}

    const {
      settingsAuthLevel: settingsAuthLevel_gsi,
      caption: caption_gsi,
      icon: icon_gsi,
      dividedText: dividedText_gsi,
      _position: _position_gsi,
      settingHandler: settingHandler_gsi,
      settingHandlerRts: settingHandlerRts_gsi,
      settingsUpdateHandler: settingsUpdateHandler_gsi,
      settingsUpdateHandlerFunction: settingsUpdateHandlerFunction_gsi,
    } = _global ?? {}

    const _settingsAuthLevel = settingsAuthLevel_gsi ? settingsAuthLevel_gsi : settingsAuthLevel_gs
    const _caption = caption_gsi ? caption_gsi : caption_gs
    const _icon = icon_gsi ? icon_gsi : icon_gs
    const _dividedText = dividedText_gsi ? dividedText_gsi : dividedText_gs
    const _position = _position_gsi ? _position_gsi : _position_gs

    const _allowSettingsUpdate = settingsUpdateHandler_gsi ? settingsUpdateHandler_gsi : allowSettingsUpdate_gs
    const _settingHandler = settingHandler_gsi ? settingHandler_gsi : settingHandler_gs
    const _settingHandlerRts = settingHandlerRts_gsi ? settingHandlerRts_gsi : settingHandlerRts_gs
    const _settingsUpdateHandler = settingsUpdateHandler_gsi ? settingsUpdateHandler_gsi : settingsUpdateHandler_gs
    const _settingsUpdateHandlerFunction = settingsUpdateHandlerFunction_gsi ? settingsUpdateHandlerFunction_gsi : settingsUpdateHandlerFunction_gs

    const _componentAreas = { ...componentAreas }

    adjusted[permKey] = { ..._permission, componentAreas: _componentAreas }

    // console.log('_settingsAuthLevel', _settingsAuthLevel)

    if (_settingsAuthLevel) { adjusted[permKey].settingsAuthLevel = _settingsAuthLevel }
    if (_caption) { adjusted[permKey].caption = _caption }
    if (_icon) { adjusted[permKey].icon = _icon }
    if (_dividedText) { adjusted[permKey].dividedText = _dividedText }
    if (_position) { adjusted[permKey]._position = _position }

    if (_allowSettingsUpdate) { adjusted[permKey].allowSettingsUpdate = _allowSettingsUpdate }
    if (_settingHandler) { adjusted[permKey].settingHandler = _settingHandler }
    if (_settingHandlerRts) { adjusted[permKey].settingHandlerRts = _settingHandlerRts }
    if (_settingsUpdateHandler) { adjusted[permKey].settingsUpdateHandler = _settingsUpdateHandler }
    if (_settingsUpdateHandlerFunction) { adjusted[permKey].settingsUpdateHandlerFunction = _settingsUpdateHandlerFunction }
  })
} 