import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import { AppSettingsContext } from '../cnr/contexts/AppSettingsContext';
import { GoogleSheetsContext } from '../cnr/contexts/GoogleSheetsContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { gEnums } from '../enums/globalEnums';
import { openExternal } from '../viewSettings/helpers/settingsLinks';

export const DataSourceSelect = () => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state } = states
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { appDataSource, googleSheets } = aps_global ?? {}
  const { appDataSourceType } = appDataSource ?? {}
  const { googleSheetsId } = googleSheets ?? {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_state } = googleSheetsContext ?? {}
  const { updating } = googleSheets_state ?? {}

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_handlers } = appSettingsContext ?? {}

  const _appDataSourceType = appDataSourceType ? appDataSourceType : gEnums.dataSourceTypes.firebase

  const [optsList, setOptsList] = useState()
  const [currentDataSourceType, setCurrentDataSourceType] = useState(_appDataSourceType)

  const handleOpenGs = (e) => {
    e.stopPropagation()
    openExternal.googleSheets(googleSheetsId)
  }

  const handleChange = (e, data) => {
    const { value } = data
    switch (value) {
      case 'clear':
        setCurrentDataSourceType(_appDataSourceType)
        appSettings_handlers.handleDataSourceType(_appDataSourceType)
        break;
      default:
        setCurrentDataSourceType(value)
        appSettings_handlers.handleDataSourceType(value)
    }
  }

  const displayOptions = () => {
    const mds = gEnums.dataSourceTypes
    const opts = []
    opts.push({ className: 'opt-icn', key: 'clear', value: 'clear', text: _.startCase('clear'), icon: { name: getDataSourceItem('clear'), color: 'blue' } })
    Object.keys(mds).forEach(key => {
      switch (key) {
        case gEnums.dataSourceTypes.googleSpreadSheet:
          opts.push({ className: 'opt-icn', key, value: key, text: <div>{_.startCase(key)}<Icon name='external alternate' title='Open Google Sheet' onClick={(e) => { handleOpenGs(e) }} /></div>, icon: { name: getDataSourceItem(key), color: 'blue' } })
          break;
        default:
          opts.push({ key, value: key, text: _.startCase(key), icon: { name: getDataSourceItem(key), color: 'blue' } })
      }
    })
    setOptsList(opts)
  }

  useEffect(() => {
    displayOptions()
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  if (!optsList) {
    return <div></div>
  } else {
    let ddcn = 'icon thin profile-select data-source'
    if (updating) { ddcn += ' blink_me_p' }
    return <Dropdown
      button
      className={ddcn}
      icon={{ name: getDataSourceItem(currentDataSourceType) }}
      labeled
      options={optsList}
      // placeholder={_.startCase(menuPlaceholder)}
      scrolling
      onChange={handleChange}
      text={_.startCase(currentDataSourceType)}
      title={'Current Data Source'}
    />
  }
}

export const getDataSourceItem = (key) => {
  let icon = 'database'
  switch (key) {
    case 'clear':
      icon = 'delete'
      break;
    case gEnums.dataSourceTypes.component:
      icon = 'disk'
      break;
    case gEnums.dataSourceTypes.none:
    case gEnums.dataSourceTypes.firebase:
      icon = 'fire'
      break;
    case gEnums.dataSourceTypes.googleSpreadSheet:
      icon = 'google drive'
      break;
    case gEnums.dataSourceTypes.googleSheetsData:
      icon = 'google plus g'
      break;
    case gEnums.dataSourceTypes.pageDataCollection:
      icon = 'list'
      break;
    case gEnums.dataSourceTypes.static:
      icon = 'cog'
      break;
    case gEnums.dataSourceTypes.urlFeed:
      icon = 'feed'
      break;
    default:
    // nothing
  }
  return icon
}