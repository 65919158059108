import _ from 'lodash';
import React, { Suspense, useContext, useEffect, useReducer, useState } from 'react';
import { Icon, Input, Label, Menu } from 'semantic-ui-react';
import { _appUrlPaths, _projectAppNames, envTypes } from '../../project/appConfiguration';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { getAuthIcon } from '../auth/authPermissions';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { GoogleSheetsContext } from '../cnr/contexts/GoogleSheetsContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { leftInitialState, leftMenuHandlers, leftMenuReducer, leftMenuTriggerTypes, leftMenuTypes } from '../cnr/reducers/LeftMenuReducer';
import { uniqueKey } from "../common/keys";
import SuspenseDimmer from '../components/alerts/SuspenseDimmer';
import { gEnums } from '../enums/globalEnums';
import { openExternal } from '../viewSettings/helpers/settingsLinks';
import { defaultHelpers, withDefaultProps } from '../common/defaultProps';
import { ServiceWorkerContext } from '../cnr/contexts/ServiceWorkerContext';
import { appIconTypes } from '../enums/appIconTypes';
import { DatabaseContext } from '../cnr/contexts/DatabaseContext';
import { frameworkLeftTypes } from '../cnr/reducers/FrameworkReducer';

const showCorner = false

const SettingsAppLeftMenu = (props) => {

  const { allowSideSettings } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, fns, navigate } = parentContext ?? {}
  const { appSettings_state, appUser_state, paps_state, page_state, manifest_state, eventInfo_state } = states
  const { appUser_handlers, appSettings_handlers, eventInfo_handlers, serviceWorker_handlers } = handlers
  const { appUser_fns } = fns
  const { appUsers, isClone } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { accessLevel: appUserAccessLevel, isSuperAdminDeveloper } = appUserAccess ?? {}
  const currentIcon = getAuthIcon(appUserAccessLevel)
  const authSettingsPermissionType = appUser_fns.validate_trueSettingsAuth(appUserAccess)
  const allowAdmin = authSettingsPermissionType === gEnums.viewPermissionTypes.allow

  const { pageSettings } = page_state
  const { aps_global, settingsSourceType, settingsOriginalSourceType } = pageSettings ?? {}
  const { tempDataSource, authOn, helpOn, appConfigSettings } = appSettings_state ?? {}
  const { isHomePage, appArea, pathViews, isLandingPage, pathModes, pathName, view } = paps_state ?? {}
  const { startIcon } = manifest_state ? manifest_state : {}
  const { appDataSource, googleSheets } = aps_global ?? {}
  const { appDataSourceType, allowAppDataDocumentsUpdate } = appDataSource ?? {}
  const { allowGoogleSheets, googleSheetsId } = googleSheets ?? {}
  const { appNames, isMeProd } = appConfigSettings ?? {}
  const { appName, appEnv, isLocal } = appNames ?? {}

  const { appUsersPresense } = eventInfo_state ?? {}
  const { online, offline } = appUsersPresense ?? {}

  const { isSuperAdmin: _isSuperAdmin } = appUserAccess ?? {}
  const { isSuperAdminDeveloper: _isSuperAdminDeveloper } = appUserAccess ?? {}
  const isEvent = pathViews.events

  const databaseContext = useContext(DatabaseContext);
  const { database_handlers } = databaseContext ?? {}

  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { showLeftMenuCaptions, permissionGroups } = framework_state ?? {}

  // serviceWorkerContext
  const serviceWorkerContext = useContext(ServiceWorkerContext)
  const { serviceWorker_state } = serviceWorkerContext ?? {}
  const { deferredPrompt } = serviceWorker_state ?? {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_state } = googleSheetsContext ?? {}
  const { updating } = googleSheets_state ?? {}

  const _appDataSourceType = tempDataSource ? tempDataSource : appDataSourceType

  // appSettingsContext 
  const {
    altDatabaseOn,
    dataOverrideOn,
    sandboxOn,
    settingsPreviewOn,
  } = appSettings_state ?? {}

  const _allowRefreshDb = isLandingPage && ((tempDataSource === gEnums.dataSourceTypes.googleSpreadSheet) || (appDataSourceType === gEnums.dataSourceTypes.googleSpreadSheet))

  const allowSd = pathModes.sport || pathModes.district || pathModes.organization

  let dashboardType;

  if (pathModes.sport) {
    dashboardType = 'Sports'
  } else if (pathModes.district) {
    dashboardType = 'District'
  } else if (pathModes.organization) {
    dashboardType = 'Organization'
  }

  const _settings = {
    _appDataSourceType,
    _isSuperAdmin,
    _isSuperAdminDeveloper,
    allowAdmin,
    allowAppDataDocumentsUpdate,
    allowGoogleSheets,
    allowSd,
    allowSideSettings,
    altDatabaseOn,
    appArea,
    appDataSource,
    appEnv,
    appName,
    appUserAccess,
    appUsersPresense,
    aps_global,
    authOn,
    currentIcon,
    dashboardType,
    dataOverrideOn,
    deferredPrompt,
    settingsSourceType,
    settingsOriginalSourceType,
    googleSheetsId,
    helpOn,
    isClone,
    isEvent,
    isLocal,
    isMeProd,
    offline,
    online,
    pathViews,
    pathViews,
    permissionGroups,
    settingsPreviewOn,
    showLeftMenuCaptions,
    updating,
  }

  const initState = leftInitialState({ _settings })
  const [leftMenu_state, leftMenu_dispatch] = useReducer(leftMenuReducer, initState);
  const leftMenu_handlers = leftMenuHandlers(leftMenu_dispatch)
  const { leftOptions, currentMenuType, triggerActionType, frt, altTrigger } = leftMenu_state ?? {}
  const { handleInit_leftMenu, handleSet_currentMenuType } = leftMenu_handlers ?? {}

  const [gsKey, setGsKey] = useState()

  useEffect(() => {
    handleInit_leftMenu(_settings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    _allowRefreshDb,
    allowSideSettings,
    altDatabaseOn,
    appArea,
    appUserAccessLevel,
    authOn,
    dataOverrideOn,
    deferredPrompt,
    helpOn,
    online,
    pathName,
    pathViews,
    permissionGroups,
    sandboxOn,
    settingsPreviewOn,
    showLeftMenuCaptions,
    startIcon,
    tempDataSource,
    updating,
  ]);

  useEffect(() => {
    if (triggerActionType) {
      switch (triggerActionType) {

        case leftMenuTriggerTypes.confirmAction:
          switch (altTrigger) {

            case leftMenuTriggerTypes.appDataDocuments:
              eventInfo_handlers.handleCreate_appDataDocuments()
              break;

            case leftMenuTriggerTypes.appDataLinks:
              eventInfo_handlers.handleCreate_appDataLinks()
              break;

            case leftMenuTriggerTypes.appProfiles:
              eventInfo_handlers.handleCreate_appProfiles()
              break;
          }
          break;

        case leftMenuTriggerTypes.toggle:
          framework_handlers.handleToggle_leftMenu()
          break;

        case leftMenuTriggerTypes.triggerAction:
          switch (frt) {
            case frameworkLeftTypes.refreshCache:
              serviceWorker_handlers.handleGet_cache()
              break;
            case frameworkLeftTypes.clearCache:
              serviceWorker_handlers.handleClear_cache()
              break;
            case frameworkLeftTypes.clearCacheData:
              serviceWorker_handlers.handleClear_cache(null, true)
              break;
            case frameworkLeftTypes.clearCacheSettings:
              serviceWorker_handlers.handleClear_cache(null, false, true)
              break;
          }
          break;

        case leftMenuTriggerTypes.frameworkLeft:
          framework_handlers.handleShow_frameworkLeft(view, frt)
          break;

        case leftMenuTriggerTypes.appUserAccess:
          switch (frt.value) {
            case 'r':
              appUser_handlers.handleResetAccessLevel()
              break;
            default:
              appUser_handlers.handleSet_tempAccessLevel(frt.value, pathViews)
          }
          break;

        case leftMenuTriggerTypes.dashboard:
          framework_handlers.handleShow_appDashboard(frt)
          break;


        case leftMenuTriggerTypes.dataOrigin:
          switch (frt) {
            case _projectAppNames.pojo:
              database_handlers.handleChange_database(_projectAppNames.pojo)
              break
            default:
            // nothing
          }
          break;

        case leftMenuTriggerTypes.dataSource:
          appSettings_handlers.handleDataSourceType(frt)
          break;

        case leftMenuTriggerTypes.device:
          appSettings_handlers.handleSetDevice(frt)
          break;

        case leftMenuTriggerTypes.settings:
          appSettings_handlers.handleToggle_settingsLeftOn()
          break;

        case leftMenuTriggerTypes.search:
          appSettings_handlers.handleToggle_settingsLeftOn()
          break;

        case leftMenuTriggerTypes.openDb:
          openExternal.db(pathViews)
          break;

        case leftMenuTriggerTypes.settingsDb:
          openExternal.settingsDb(pathViews, appArea)
          break;

        case leftMenuTriggerTypes.openStorage:
          openExternal.db(pathViews, true)
          break;

        case leftMenuTriggerTypes.openGs:
          openExternal.googleSheets(googleSheetsId)
          break;

        case leftMenuTriggerTypes.gotoClient:
          navigate('/clients/' + pathViews.clients)
          break;

        case leftMenuTriggerTypes.gotoHome:
          navigate('/')
          break;

        case leftMenuTriggerTypes.gotoDev:
          handleOpen_otherSite(true)
          break;

        case leftMenuTriggerTypes.gotoSite:
          handleOpen_otherSite()
          break;

        // case leftMenuTriggerTypes.appDataDocuments:
        //   eventInfo_handlers.handleCreate_appDataDocuments()
        //   break;

        // case leftMenuTriggerTypes.appDataLinks:
        //   eventInfo_handlers.handleCreate_appDataLinks()
        //   break;

        // case leftMenuTriggerTypes.appProfiles:
        //   eventInfo_handlers.handleCreate_appProfiles()
        //   break;

        case leftMenuTriggerTypes.createNewEvent:
          console.log('create new event')
          break;

        case leftMenuTriggerTypes.dataOverride:
          appSettings_handlers.handleToggle_dataOverrideOn()
          break;

        case leftMenuTriggerTypes.help:
          appSettings_handlers.handleToggle_helpOn()
          break;

        case leftMenuTriggerTypes.auth:
          appSettings_handlers.handleToggle_authOn()
          break;

        case leftMenuTriggerTypes.settingsPreview:
          appSettings_handlers.handleToggle_previewOn(true)
          break;

        case leftMenuTriggerTypes.altDatabase:
          appSettings_handlers.handleToggle_altDatabaseOn(true)
          break;
        default:
        // nothing
      }
      handleSet_currentMenuType()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    triggerActionType,
  ]);


  const handleGsKey = (e, d) => setGsKey(d.value)

  const getUrl = () => {
    console.log('gsKey', gsKey)
    appSettings_handlers.handleDataSourceType(gEnums.dataSourceTypes.googleSpreadSheet, gsKey)
  }

  const handleOpen_otherSite = (toDev) => {
    const { clients, events } = pathViews ?? {}
    let suffix = '';
    if (events) {
      suffix = 'clients/' + clients + '/events/' + events + '/landing'
    } else if (clients) {
      suffix = 'clients/' + clients
    }
    if (toDev) {
      window.open(_appUrlPaths.dev + suffix, '_blank')
    } else {
      switch (appEnv) {
        case envTypes.development:
          window.open(_appUrlPaths.prod + suffix, '_blank')
          break;
        case envTypes.production:
          window.open(_appUrlPaths.dev + suffix, '_blank')
          break;
      }
    }

  }

  const handleShow_confirm = (props) => handleSet_currentMenuType({ lmt: leftMenuTypes.confirmation }, props)
  const handleShow_input = (props) => handleSet_currentMenuType({ lmt: leftMenuTypes.input }, props)

  const icon = (item) => {
    const _item = withDefaultProps(item, defaultHelpers.iconDefaults);
    const { icon, color, iconCorner, iconCornerColor, showIconCorner } = _item
    if (iconCorner && (showCorner || showIconCorner)) {
      return <Icon.Group as={'i'}>
        <Icon name={icon} color={color ? color : null} />
        <Icon corner={true} name={iconCorner} color={iconCornerColor ? iconCornerColor : 'blue'} />
      </Icon.Group>
    } else {
      return <Icon name={icon} color={color ? color : null} />
    }
  }

  const input = () => <Input
    value={gsKey}
    onChange={handleGsKey}
    action={{ icon: appIconTypes.arrowRight, onClick: () => { getUrl() } }} />

  const menuItems = (opts) => {
    const mis = _.map(opts, (item, key) => {
      const { caption, count, description, dividerBefore, text, oc, showCaption, showInput, updating, useConfirm, useInput, opts } = item
      const { ocn } = opts ?? {}
      const _caption = showLeftMenuCaptions || showCaption ? text : caption
      let cn = dividerBefore ? 'sbm-top' : ''
      if (item.cn) { cn += ' ' + item.cn }
      if (_caption) { cn += ' left-sub' }
      if (updating) { cn += ' blink_me_p' }
      if (ocn) { cn += ' ' + ocn }
      let _oc = useConfirm ? handleShow_confirm : oc
      if (useInput) { _oc = handleShow_input }

      return <Menu.Item
        key={uniqueKey('svs', 'mmi', key)}
        name={item.text}
        onClick={() => _oc && _oc({ frt: item.frt, lmt: item.lmt, lma: item.lma })} title={text}
        className={cn}
      >
        {!showInput && icon(item)}
        {count && <Label attached='bottom right' size='mini' color='black'>{count}</Label>}
        {showInput && input()}
        {!showInput && _caption && _caption}
        {description && description}
      </Menu.Item>
    })
    return mis
  }

  const menu = () => {
    const divs = []
    switch (currentMenuType) {
      case leftMenuTypes.actions:
      case leftMenuTypes.admins:
      case leftMenuTypes.appAdmins:
      case leftMenuTypes.appInfos:
      case leftMenuTypes.appUsers:
      case leftMenuTypes.confirmation:
      case leftMenuTypes.dashboards:
      case leftMenuTypes.dataOrigins:
      case leftMenuTypes.dataSources:
      case leftMenuTypes.devices:
      case leftMenuTypes.externals:
      case leftMenuTypes.input:
        divs.push(<div>{menuItems(leftOptions[currentMenuType])}</div>)
        break;

      default:
        return <Menu inverted icon vertical fixed="left" className='frm-right'>
          <div>{menuItems(leftOptions.dashboard)}</div>
          <div>{menuItems(leftOptions.action)}</div>
          <div>{menuItems(leftOptions.appUser)}</div>
          <div>{menuItems(leftOptions.device)}</div>
          {isHomePage && isSuperAdminDeveloper && <div>{menuItems(leftOptions.dataOrigin)}</div>}
          <div>{menuItems(leftOptions.dataSource)}</div>
          <div>{menuItems(leftOptions.overrides)}</div>
          <div>{menuItems(leftOptions.external)}</div>
          <div>{menuItems(leftOptions.home)}</div>
        </Menu>
    }
    return <Menu inverted icon vertical fixed="left" className='frm-right'>
      {divs}
    </Menu>
  }

  return <Suspense fallback={<SuspenseDimmer caption={'appSub'} origin={'SettingsAppLeftMenu'} />}>
    {leftOptions && menu()}
  </Suspense>

}

export default SettingsAppLeftMenu