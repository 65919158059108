import { getAuth, sendPasswordResetEmail as spre } from "firebase/auth";
import { signInResponseData } from "../../cnr/reducers/reducerHelpers/signInProps";
import { signInResponseTypes } from '../../cnr/reducers/SignInReducer';

export const sendPasswordResetEmail = (email, callback) => {
  const auth = getAuth()
  spre(auth, email).then(res => {
    const _sirProps = { signInResponseType: signInResponseTypes.emailResetSent, email, res }
    callback && callback(signInResponseData(_sirProps))
  }).catch((error) => {
    const _sirProps = { signInResponseType: signInResponseTypes.emailResetNotSent, email, error }
    callback && callback(signInResponseData(_sirProps))
  })
}