import _ from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Sidebar } from 'semantic-ui-react'
import { DataContext } from '../../cnr/contexts/DataContext'
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { uniqueKey } from '../../common/keys'
import UiSaveButtons from '../../components/buttons/UiSaveButtons'
import { updateSettings } from '../../firestoreData/settings/updateSettings'
import PageItem from '../../page/PageItem'
import AppSettingsSidebarWithProvider, { settingsMenuTypes } from '../../viewSettings/AppSettingsSidebar'
import FullPageWrapper from '../../wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper'
import DataSidebar from '../swipers/DataSidebar'
import { appViewerColors } from './AppDashboard'

const viewSettingsModes = {
  none: 'none',
  display: 'display',
  grouping: 'grouping',
  images: 'images',
  props: 'props',
  viewJson: 'viewJson',
}

export const SettingsViewerContext = createContext();

export const FullSettingsViewer = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { pathViews, view } = paps_state ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers, framework_state } = frameworkContext ?? {}
  const { propMode } = framework_state ?? {}

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state } = dataContext ?? {}
  const { uivi: name, viewItem } = data_state ?? {}

  const [viewSettingsMode, setViewSettingsMode] = useState();
  const [viewItem_preview, setViewItemPreview] = useState();
  const [viewItemChanged, setViewItemChanged] = useState();

  useEffect(() => {
    setViewItemPreview({ ...viewItem })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewItem]);

  const handleUpdateViewItem = (propKey) => {
    const _viewItemPreview = { ...viewItem_preview }
    _viewItemPreview.props[propKey].pending = !_viewItemPreview.props[propKey].pending
    setViewItemPreview(_viewItemPreview)
    setViewItemChanged(true)
  }

  const handleViewSettingsMode = (opts) => {
    setViewSettingsMode(opts ? opts.viewSettingsMode : null)
    // if (opts && !opts.viewSettingsMode) { setViewItemPreview() }
  }

  const handlePropMode = () => framework_handlers.handlePropMode()
  const handleSave = () => updateSettings.updateViewViewItemSettingsDB(pathViews, view, viewItem_preview)

  const pageItem = () => {
    let cn = 'item-content'
    if (propMode) { cn += ' prop-mode' }
    return <div className={'app-container mcn top dt-mode'}>
      <div className={'app-menu'}></div>
      <div className={'app-content'}>
        <div className={'item-container hcp'}>
          <div className={cn}>
            <PageItem key={uniqueKey('pcpic', name)} uivi={name} caption={name} viewItemPreview={viewItem_preview} />
          </div>
        </div>
      </div>
    </div>
  }

  const header = () => {
    const btns = [
      { caption: _.startCase(viewSettingsModes.viewJson), oc: handleViewSettingsMode, icon: 'settings', opts: { viewSettingsMode: viewSettingsModes.viewJson } },
      { caption: 'Display', icon: 'cog', oc: handleViewSettingsMode, opts: { viewSettingsMode: viewSettingsModes.display } },
      { caption: 'Group', icon: 'cog', oc: handleViewSettingsMode, opts: { viewSettingsMode: viewSettingsModes.grouping } },
      { caption: 'Images', icon: 'cog', oc: handleViewSettingsMode, opts: { viewSettingsMode: viewSettingsModes.images } },
      { caption: 'Props', icon: 'cog', oc: handlePropMode }
    ]
    return <UiSaveButtons
      others={btns}
      color={appViewerColors.fsv}
      rows={2}
    />
  }

  const footer = () => <UiSaveButtons
    save={{ oc: handleSave, disabled: !viewItemChanged }}
    rows={2}
  />

  const viewerContent = () => {
    let cn = 'wrp-sub sett'
    return <Wrapper
      header={header()}
      content={pageItem()}
      footer={footer()}
      wrapperType={wrapperTypes.paddedHeaderAndFooter}
      css={{ container: cn }}
    />
  }

  // vsl-container dark-mode
  const sidebar_right = (visible) => <DataSidebar direction={'right'} visible={visible} content={visible && viewSettingsSidebar()} onHide={handleViewSettingsMode} />

  // const VerticalSidebar = ({ visible }) => (
  //   <Sidebar
  //     as={Segment}
  //     animation={'overlay'}
  //     direction={'right'}
  //     icon='labeled'
  //     vertical
  //     visible={visible}
  //     width='very wide'
  //     className='vsl-container dark-mode'
  //     onHide={() => { handleViewSettingsMode() }}
  //   >
  //     {viewSettingsSidebar()}
  //   </Sidebar>
  // )

  const viewSettingsSidebar = (isGlobal) => <AppSettingsSidebarWithProvider isGlobal={isGlobal} settingsMenuType={settingsMenuTypes.fullSettingsView} />

  const sidebar = () => {
    return <Sidebar.Pushable style={{ overflow: 'hidden' }}>
      {sidebar_right(viewSettingsMode ? true : false)}
      <Sidebar.Pusher dimmed={viewSettingsMode ? true : false} className={'h100'}>
        {viewerContent()}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  }

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    topperCaption={'Settings Views'}
    topperCaption2={_.startCase(name)}
  />

  return viewItem_preview ? <SettingsViewerContext.Provider value={{ settingsViewer_state: { viewItem_preview }, settingsViewer_handlers: { handleUpdateViewItem } }}>
    {fullPageWrapper()}
  </SettingsViewerContext.Provider> : <div></div>
} 