import React, { useContext, useEffect, useState } from 'react'
import { uniqueKey } from '../../../../global/common/keys'
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext'
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext'
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext'
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'
import { _matchResultTypes, matchDisplayTypes } from '../../cnr/reducers/SportsMatchReducer'
import { sportsShowTypes } from '../../cnr/reducers/SportsSeasonReducer'

const key_match = 'ms-match-score'

/**
 * 
 * @param {object} props 
 * @returns The score display portion of the match UI
 */
const MatchScoreDisplay = (props) => {

  const {
    display,
    fullDisplay,
    keyy,
    mediaResults,
    origin,
    scheduleTypes
  } = props

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { _sportData, sportsShowType, sportsShowOptions } = sportsSeason_state ?? {}
  const { rtMatchUpdates } = _sportData ?? {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state, seasonMatches_handlers } = seasonMatchesContext ?? {}
  const { matchScheduleType } = seasonMatches_state ?? {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ?? {}
  const { match_true, matchAllows, matchDisplayType } = sportsMatch_state ?? {}
  const { _itemKey, _resultsStatus, _status } = match_true ?? {}
  const { scoreType } = _resultsStatus ?? {}
  const { scores: allowScoreEdits } = matchAllows ?? {}

  const { isMissing, isPast, isBye, isMatchDayResult } = _status ?? {}

  // sportsRtMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state, sportsRtMatch_handlers } = sportsRtMatchContext ?? {}
  const { match_rt } = sportsRtMatch_state ?? {}
  const { _results: _results_rt } = match_rt ?? {}
  const { scoreDisplays: scoreDisplays_rt } = _results_rt ?? {}
  const { normal: scoreDisplay_rt } = scoreDisplays_rt ?? {}

  let _allowScoreEdits = allowScoreEdits && origin === 'matchSingle'

  if (scheduleTypes && scheduleTypes.common) {
    _allowScoreEdits = false
  }

  let _showMatchOptions;

  switch (matchDisplayType) {
    case matchDisplayTypes.matchOptions:
    case matchDisplayTypes.realtimeScoring:
      _showMatchOptions = true
      break;
    default:
    // nothing
  }

  const [currentDisplay, setCurrentDisplay] = useState()
  const [flash, setFlash] = useState(false);
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    if (sportsShowType && (sportsShowType === sportsShowTypes.realtimeResults) && sportsShowOptions && sportsShowOptions.matchKey === _itemKey) {
      const xx = { match_rt: match_true, match_rt_scorer: null }
      sportsMatch_handlers.handleSet_realtimeMatchData(xx)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportsShowType]);

  useEffect(() => {
    const { scoreCaption } = fullDisplay ?? {}
    if (hasMounted && scoreCaption !== currentDisplay) {
      setFlash(true);
      setCurrentDisplay(scoreCaption)
      // Remove the flash class after animation ends
      const timeout = setTimeout(() => setFlash(false), 1000);
      // Cleanup timeout
      return () => clearTimeout(timeout);
    } else {
      setHasMounted(true); // Set the flag after the first render
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreDisplay_rt, fullDisplay, display, hasMounted]);

  const handleMatchClick_edit = (e) => {
    e.stopPropagation(e)
    seasonMatches_handlers.handleSelect_currentMatch(match_true, matchDisplayTypes.matchOptions)
  }

  const matchDisplay_normal = (cn) => <div
    key={uniqueKey(key_match, keyy)}
    className={cn}
    onClick={(e) => { _allowScoreEdits && handleMatchClick_edit(e) }}>
    <div>{currentDisplay}</div>
  </div>

  const matchDisplay = () => {
    let cn = 'match-display ' + scoreType
    if (flash) { cn += ' flash' }
    return matchDisplay_normal(cn)
  }

  return matchDisplay()

}

export default MatchScoreDisplay