import React, { useContext, useEffect } from 'react';
import { Header, Icon, List, Message, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { startTypes } from '../../cnr/reducers/StartReducer';
import { g_cns } from '../../common/cns';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import { gEnums } from '../../enums/globalEnums';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import UserEvents from '../user/UserEvents';
import { uniqueKey } from '../../common/keys';

const NonAuthPage = (props) => {

  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { appUser_state, page_state, paps_state } = states
  const { start_handlers, auth_handlers } = handlers

  // authContext 
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { clientAccessKey, displayName } = appUserAccess ?? {}

  // papsContext 
  const { pathViews } = paps_state ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { topMenu } = aps_global ?? {}
  const { mainFixedCaption } = topMenu ?? {}

  // startContext 
  const { handleAmmend_startDimmer } = start_handlers ?? {}

  const hasClient = pathViews && pathViews.clients
  const hasEvent = pathViews && pathViews.events

  const { itemType, allowPageView } = props

  const handleLogOut = () => auth_handlers.handleSignOut()

  // form
  useEffect(() => {
    handleAmmend_startDimmer(startTypes.finish)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  let itemCaption;

  switch (itemType) {
    case 'page':
      itemCaption = 'Page'
      break;
    case 'item':
      itemCaption = 'Item'
      break;
    case 'data':
      itemCaption = 'Data'
      // iconName = 'database'
      break;
    default:
      itemCaption = 'Item'
      break;
  }

  let topperCaption = 'Welcome'
  if (displayName) { topperCaption += ' ' + displayName }

  const reasonsList = (reasonItems) => reasonItems.map((ri, index) => <List.Item key={uniqueKey('nap', index)}>{ri}</List.Item>)

  const nonAuthReasons = () => {

    let header = 'You do not have permission to access this ' + itemCaption + '.'

    const reasonItems = [];

    if (hasEvent) {
      switch (allowPageView) {
        case gEnums.viewPermissionTypes.deny_noClientKey:
          reasonItems.push('No client access.')
          break;
        case gEnums.viewPermissionTypes.allow_clientLimited:
        case gEnums.viewPermissionTypes.deny_noEventKey:
          reasonItems.push('No event access.')
          break;
        default:
        // nothing
      }
    } else if (hasClient) {
      switch (allowPageView) {
        case gEnums.viewPermissionTypes.allow_clientLimited:
        case gEnums.viewPermissionTypes.deny_noClientKey:
          reasonItems.push('No client access.')
          break;
        case gEnums.viewPermissionTypes.deny_noEventKey:
          reasonItems.push('No event access.')
          break;
        default:
        // nothing 
      }
    } else {
      reasonItems.push('No client/event access.')
    }

    return <Segment basic>
      <Header as={'h5'}><Message warning>{header}</Message></Header>
      {reasonItems && reasonItems.length > 0 && <List>
        {reasonsList(reasonItems)}
      </List>}
    </Segment>
  }

  const content = () => {
    if (clientAccessKey) {
      return <UserEvents />
    } else {
      return nonAuthReasons()
    }
  }

  const wizardContent = () => <Wrapper
    content={content()}
    footer={<UiSaveButtons save={{ caption: 'Log out', oc: handleLogOut, icon: 'log out' }} />}
    wrapperType={wrapperTypes.paddedFooter}
  />

  return <FullPageWrapper
    content={wizardContent()}
    topperCaption={topperCaption}
    topperCaption2={mainFixedCaption}
    isBack={true}
  />

}

export const NonAccessPage = (props) => {

  const { vpt: _viewPermissionType, wrapWithAc } = props

  let iconName = 'ban';

  let content = 'No Access'
  let subContent = 'You do not have access to the data in this context.'

  switch (_viewPermissionType) {
    case gEnums.viewPermissionTypes.allowToAppUserAdmin:
      subContent = 'You are only seeing this information because are and admin.'
      break;
    default:
    // nothing
  }

  const pending = () => {
    return <div className={'pending-container'}>
      <div className={'pending-header'}>
        <Header as='h4'>
          <Icon name={iconName} color='blue' />
          <Header.Content>{content}</Header.Content>
        </Header>
      </div>
      <div className={'pending-content'}>
        {subContent}
      </div>
    </div>
  }

  if (wrapWithAc) {
    return <div className={g_cns.app_content}>
      {pending()}
    </div>
  } else {
    return pending()
  }
}

export default NonAuthPage