import React, { createContext } from 'react';

export const CreateContext = createContext();

const CreateProvider = (props) => {
  return <CreateContext.Provider value={{ ...props }}>
    {props.children}
  </CreateContext.Provider>
};

export default CreateProvider 