import React from 'react';
import SingleImage from "../../components/sem/SingleImage"
import { _storageSettings } from "../../storage/storageHelpers"
import { getGalleryImage } from "./StorageReducer"

export const rts = {
  handleGet_galleryImage: 'handleGet_galleryImage',
  handleSet_galleryImage: 'handleSet_galleryImage',
  handleShow_imageUpload: 'handleShow_imageUpload',
  handleImageVisible: 'handleImageVisible',
}

export const imageReducer = (state, action) => {

  const {
    aps_appUserSettings,
    dataCollectionName,
    galleries,
    itemData,
    key_viewItem,
    storageOptions,
    viewItem,
  } = state

  const { useClientProfileImage } = aps_appUserSettings ?? {}

  const { type, dispatch } = action

  const { email, _itemKey } = itemData ?? {}
  const { profile } = galleries ?? {}
  const { clientStorageImageCollections } = storageOptions ?? {}

  const { handleSet_galleryImage } = imageHandlers(dispatch)

  switch (type) {

    case rts.handleGet_galleryImage:

      let _fullImageProps = {};

      if (useClientProfileImage && clientStorageImageCollections && clientStorageImageCollections.includes(key_viewItem)) {
        if (profile && profile[email] && profile[email].urls && profile[email].urls.full) {
          _fullImageProps = { ...profile[email] }
          handleSet_galleryImage(_fullImageProps)
        } else {
          const galleryType = _storageSettings.galleryTypes.direct
          const documentName = dataCollectionName ? dataCollectionName : viewItem.key
          const { id: documentId, name } = itemData ?? {}
          getGalleryImage({ galleries, galleryType, documentName, documentId, itemName: name, callback: handleSet_galleryImage })
        }
      } else {
        if (action.galleryItems && action.galleryItems[_itemKey]) { _fullImageProps = action.galleryItems[_itemKey] }
        if (action.galleryItems && action.galleryItems[email]) { _fullImageProps = action.galleryItems[email] }
        handleSet_galleryImage(_fullImageProps)
      }
      // koleen.roach@securian.com
      // leaders_2023
      return { ...state }

    case rts.handleSet_galleryImage:
      const { fullImageProps } = action
      const _trueImageUrl = fullImageProps ? getTrueUrl(fullImageProps) : null
      return fullImageProps ? { ...state, fullImageProps, _trueImageUrl } : { ...state, fullImageProps: {} }

    case rts.handleImageVisible:
      if (state.fullImageProps) {
        let ve = getCardElement(state, action, true)
        return { ...state, cardImageElement: ve }
      } else {
        return { ...state }
      }

    case rts.handleShow_imageUpload:
      return { ...state, showImageUpload: !state.showImageUpload }

    default:
      return { ...state }
  }
}

export const imageInitialState = (initState) => {
  return { ...initState }
};

export const imageHandlers = (dispatch, state) => {
  return {
    handleImageVisible: (imageOptions) => { dispatch({ type: rts.handleImageVisible, dispatch, imageOptions }) },
    handleShow_imageUpload: () => { dispatch({ type: rts.handleShow_imageUpload, dispatch }) },
    handleGet_galleryImage: (galleryItems) => { dispatch({ type: rts.handleGet_galleryImage, dispatch, galleryItems }) },
    handleSet_galleryImage: (fullImageProps) => { dispatch({ type: rts.handleSet_galleryImage, dispatch, fullImageProps }) },
  }
}

const getTrueUrl = (fullImageProps, showFullImage) => {

  const { urls: urls_current } = fullImageProps ?? {}
  const { thumbnail: current_url_thumbnail, full: current_url_full } = urls_current ?? {}

  let _trueImageUrl;

  if ((showFullImage) && current_url_full) {
    _trueImageUrl = current_url_full
  } else if (current_url_thumbnail) {
    _trueImageUrl = current_url_thumbnail
  }

  return _trueImageUrl

}

const getCardElement = (state, action, isVisible) => {
  const { fullImageProps, _trueImageUrl, transition_image } = state
  const { imageOptions } = action
  if (fullImageProps) {
    if (isVisible) {
      const cip = { ...state, _trueImageUrl, fullImageProps, transition_image, ...imageOptions }
      return { type: 'image', element: <SingleImage {...cip} /> }
    }
  }
}