import React, { useContext, useState } from 'react';
import { Form, Icon, Image } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';
import { FormContext } from '../../../cnr/contexts/FormContext';
import { gEnums } from '../../../enums/globalEnums';
import { appIconTypes } from '../../../enums/appIconTypes';
import { ColorPicker } from './ColorPicker';
import TimePicker from './TimePicker';
import { propHelpers } from '../../../common/tester';
import { FormItemContext } from '../FormItem';

const FormInput = (props) => {

  // formContext
  const formContext = useContext(FormContext)
  const { form_state, form_handlers } = formContext ?? {}
  const { data_localForm } = form_state ?? {}
  const { handlePropPush, handleItemAdd } = form_handlers ?? {}

  // formItemContext
  const formItemContext = useContext(FormItemContext)
  const { formItem_state } = formItemContext ?? {}
  const { fip, fipProps, itemProp, themeColors } = formItem_state ?? {}
  const { key: key_prop, data } = itemProp
  const { regUrl } = fipProps ?? {}

  const { dataType, formItemType, urlLink, urlSheetsLink, hasImageUrl, eventUpdateLink, saveLink, autoFill, hasAddIcon, pushToOthers } = data ?? {}
  const { propname, otype, onChange: _onChange, value } = fip
  const isColor = propname.toLowerCase().indexOf('color') >= 0 ? true : false

  fip.value = data_localForm ? data_localForm[key_prop] : null

  const [showPw, setShowPw] = useState()

  const items = []

  if (propname && propHelpers.isTimeProp(propname)) {
    return <TimePicker fip={fip} itemProp={itemProp} />
  } else {
    if (isColor) {
      return <ColorPicker key={uniqueKey('fi', propname)} fip={fip} themeColors={themeColors} />
    } else {
      switch (formItemType) {
        case gEnums.formItemTypes.textReadOnly:
          items.push(<Form.Input {...fip} fluid readOnly />)
          break;
        default:
          if (urlLink) {
            const icon = fip && fip.value ? fip.value : 'question circle'
            const color = fip && fip.value ? 'green' : 'grey'
            items.push(<Icon key={uniqueKey('fii', propname, 1)} name={appIconTypes.externalAlternate} color={'blue'} style={{ float: 'right' }} onClick={(e) => window.open(urlLink, '_blank')} />)
            items.push(<Icon key={uniqueKey('fii', propname, 2)} name={icon} color={color} style={{ float: 'right' }} />)
            items.push(<Form.Input {...fip} fluid />)
          } else if (urlSheetsLink) {
            items.push(<Icon key={uniqueKey('fii', propname)} bordered name={appIconTypes.externalAlternate} color={'blue'} style={{ float: 'right' }} onClick={(e) => window.open(urlSheetsLink + fip.value, '_blank')} />)
            items.push(<Form.Input {...fip} fluid />)
          } else if (eventUpdateLink) {
            items.push(<Icon key={uniqueKey('fii', propname)} bordered name={appIconTypes.externalAlternate} color={'blue'} style={{ float: 'right' }} onClick={(e) => window.open(regUrl + fip.value, '_blank')} />)
            items.push(<Form.Input {...fip} fluid />)
          } else if (saveLink) {
            items.push(<Icon key={uniqueKey('fii', propname)} bordered name={'save'} color={'blue'} style={{ float: 'right' }} onClick={(e) => alert('Save this')} />)
            items.push(<Form.Input {...fip} fluid />)
          } else if (autoFill) {
            items.push(<Form.Input {...fip} fluid disabled />)
          } else if (pushToOthers) {
            fip.width = 14
            items.push(<Form.Group>
              <Form.Input {...fip} fluid />
              <Form.Button key={uniqueKey('fii', propname)} icon={'copy'} style={{ marginTop: '1.75em' }} onClick={(e) => handlePropPush(e)} />
            </Form.Group>)
          } else if (hasAddIcon) {
            fip.width = 14
            items.push(<Form.Group>
              <Form.Input {...fip} fluid />
              <Form.Button key={uniqueKey('fii', propname)} icon={appIconTypes.add} style={{ marginTop: '1.75em' }} onClick={(e) => handleItemAdd(e)} />
            </Form.Group>)
          } else if (hasImageUrl) {
            const imageUrl = fip && fip.value ? fip.value : null
            items.push(<Form.Input {...fip} fluid />)
            if (imageUrl) items.push(<Image src={imageUrl} />)
          } else if (otype === 'password') {
            fip.type = showPw ? 'text' : 'password'
            const icon = showPw ? 'eye' : 'eye slash'
            items.push(<Icon key={uniqueKey('fii', propname)} name={icon} style={{ float: 'right' }} onClick={(e) => setShowPw(!showPw)} />)
            items.push(<Form.Input autoComplete='new-password' {...fip} fluid />)
          } else {
            switch (dataType) {
              case gEnums.dataTypes.currency:
                fip.icon = 'dollar'
                items.push(<Form.Input iconPosition='left' {...fip} fluid />)
                break;
              case gEnums.dataTypes.number:
                fip.icon = 'hashtag'
                items.push(<Form.Input iconPosition='left' {...fip} fluid />)
                break;
              default:
                items.push(<Form.Input {...fip} fluid />)
            }

          }
      }
    }
  }

  if (data.highlighted) {
    items.unshift(<Icon key={uniqueKey('fii', propname)} color={'green'} name={'user secret'} style={{ float: 'right' }} />)
  }

  // console.log('_propHelp', _propHelp)
  // if (_propHelp) {
  //   items.push(<div>{_propHelp.description}</div>)
  // }

  return items
}

export default FormInput

function onlyNumberKey(e, data) {
  const re = /^[0-9\b]+$/;
  if (e.target.value === '' || re.test(e.target.value)) {
    return e.target.value
  }
}