import React, { useContext, useEffect } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'
import { DragNDropContext } from '../cnr/contexts/DragNDropContext'
import { uniqueKey } from '../common/keys'

const DraggableElement = (props) => {

  const { element, portal, portalCn, index, draggableId } = props

  const dragNDropContext = useContext(DragNDropContext);
  const { dragNDrop_handlers } = dragNDropContext ?? {}

  const uk = draggableId ? uniqueKey(draggableId, 'de') : uniqueKey('dd', 'dr', index)

  useEffect(() => {
    dragNDrop_handlers.handleAmmend_draggableIds(draggableId)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [draggableId])

  const draggableElement = () => {
    return <Draggable
      key={uk}
      draggableId={draggableId}
      index={index}
    >
      {(provided, snapshot) => {
        return <PortalAwareItem
          portal={portal}
          element={element}
          provided={provided}
          snapshot={snapshot}
          portalCn={portalCn}
          keyy={uk}
        />
      }}
    </Draggable>
  }

  return draggableElement()

}

export default DraggableElement

const PortalAwareItem = (props) => {

  const { portal, element, provided, snapshot, portalCn, keyy } = props

  const usePortal = snapshot.isDragging ? true : false;

  const divProps = {
    ref: provided.innerRef,
    ...provided.draggableProps,
    ...provided.dragHandleProps,
    // inPortal: usePortal,
  }

  if (portalCn) { divProps.className = portalCn }

  const child = (
    <div key={uniqueKey('pai', keyy)} {...divProps}>
      {element}
    </div>
  );

  if (!usePortal) { return child; }

  // if dragging - put the item in a portal
  return createPortal(
    child,
    portal
  )
  // return PortalReactDOM(child, portal);

}