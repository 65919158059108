import { appIconTypes } from '../../enums/appIconTypes';
import { allPageTypes } from '../../enums/allPageTypes';
import { genericMenuProps } from '../menus/genericMenuItem';
import { gEnums } from '../../enums/globalEnums';
/** sideMenuItems
 * @returns SignOut, Global Settings, View Settings, All Events, All Clients
 */
export const slideMenuItems = (appUser_fns, appUser, paps_state, sideViewItems, showSignOut) => {

  const { view } = paps_state

  const rt = {
    adminMenuItem: false,
    signUpSlideItems: [],
    appSlideItems: [],
    adminSlideItems: [],
    gotoItems: [],
  }

  if (sideViewItems) {
    Object.keys(sideViewItems).forEach(key => {
      const svi = sideViewItems[key]
      const { _viewPermissionType, display: display_viewItem } = svi ?? {}
      const { caption, iconImage, defaultIcon, useIconImage } = display_viewItem ?? {}
      switch (_viewPermissionType) {
        case gEnums.viewPermissionTypes.allow:
        case gEnums.viewPermissionTypes.allowToAppUserAdmin:
          const adm = _viewPermissionType === gEnums.viewPermissionTypes.allowToAppUserAdmin ? true : false
          const cn = _viewPermissionType === gEnums.viewPermissionTypes.allowToAppUserAdmin ? 'adm' : null
          if (svi) {
            if (svi.general) {
              rt.appSlideItems.push(genericMenuProps(key, caption ? caption : svi.viewItemType, { iconImage, useIconImage, clickAppUrl: svi.general.clickAppUrl }))
            } else {
              rt.appSlideItems.push(genericMenuProps(key, caption ? caption : svi.viewItemType, { icon: defaultIcon, iconImage, useIconImage, adm, className: cn }))
            }
          }
          break;
        default:
        // nothing
      }
    })
  }

  if (appUser) {
    // signUp
    !showSignOut && rt.signUpSlideItems.push(genericMenuProps(allPageTypes.signOut, null, { className: 'menu-signin' }))
    const ic = 'grey'
    if (appUser_fns.validate_settingsMenuAuth()) {
      rt.adminSlideItems.push(genericMenuProps('appSettings', 'App Settings', { icon: 'settings', iconColor: ic, permissionProps: { permissionType: 'appSettings' } }))
      rt.adminSlideItems.push(genericMenuProps('viewSettings', 'View Settings', { icon: 'cog', iconColor: ic, permissionProps: { permissionType: 'viewSettings' } }))

      if (view !== 'home') {
        rt.adminSlideItems.push(genericMenuProps('client', 'Client', { icon: appIconTypes.client, iconColor: ic }))
        if (appUser_fns.validate_settingsMenuAuth()) {
          rt.adminSlideItems.push(genericMenuProps('allClients', 'All Clients', { icon: appIconTypes.clients, iconColor: ic }))
          rt.adminSlideItems.push(genericMenuProps('home', 'Home', { icon: appIconTypes.home, iconColor: ic, }))
        }
      }
    }
  } else {
    rt.signUpSlideItems.unshift(genericMenuProps(allPageTypes.signIn, null, { className: 'menu-signin' }))
  }

  return rt
}
