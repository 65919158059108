import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Divider, Header, Icon, Label, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../../global/auth/appUserAccessPermissions';
import { AppComponentContext } from '../../../global/cnr/contexts/AppComponentContext';
import { ParentContext } from '../../../global/cnr/contexts/ParentContext';
import { formatItem, formatTypes } from '../../../global/common/dateFormatting';
import AppForm from '../../../global/components/forms/appForms/AppForm';
import { gEnums } from '../../../global/enums/globalEnums';
import { newPropEditItem } from '../../../global/viewSettings/helpers/settingsHelpers';
import { FieldTripContext } from '../cnr/contexts/FieldTripContext';
import { approvalRoles, approvalTypes, emailTypes, extraFieldProps, fieldTripListTypes, staffRoleTypes, validationRoles } from '../cnr/reducers/FieldTripReducer';
import { _developerAdminEmail } from '../../../global/common/keys';

const _size = 'small'

const _icons = {
  email: 'mail',
  approved: 'check circle',
  pending: 'circle outline'
}

const _captions = {
  email: 'Date Email Sent',
  approved: 'Date Approved',
  validated: 'Date Validated'
}

const FieldTripApprovals = (props) => {

  const { fieldTripListType } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { appUser_state } = states
  const { page_fns } = fns ?? {}
  const { pushSimple } = page_fns

  const { appUsers } = appUser_state ?? {}

  const appUserAccess = getAppUserAccess(appUsers)
  const { accessLevel, appUserSession } = appUserAccess ?? {}
  const { appUserEvent } = appUserSession ?? {}
  const { staffTypes: staffTypes_appUser } = appUserEvent ?? {}

  // fieldTripsContext
  const fieldTripsContext = useContext(AppComponentContext)
  const { fieldTrips_state } = fieldTripsContext ?? {}
  const { fieldTripSettings, staffTypes, staffFieldTrips } = fieldTrips_state ?? {}

  const { useMe, allowApprovalUpdate, allowEmail, useDefaultEmails, secretaryEmail, principalEmail, districtResourceEmail, showStatusToAll } = fieldTripSettings ?? {}

  // fieldTripContext
  const fieldTripContext = useContext(FieldTripContext)
  const { fieldTrip_state, fieldTrip_handlers } = fieldTripContext ?? {}
  const { fieldTrip, fieldTripStatus, roleToUpdate, extraFieldProps } = fieldTrip_state ?? {}
  const { status } = fieldTrip ?? {}
  const { approvals, validations } = fieldTripStatus ?? {}

  console.log('fieldTripStatus', fieldTripStatus)

  const [listItems, setListItems] = useState()
  const [allowReset, setAllowReset] = useState()
  const [formData, setFormData] = useState({})
  const [updating, setUpdating] = useState()
  const [appUserRolePermissions, setAppUserRolePermissions] = useState()

  useEffect(() => {
    if (fieldTripListType) {
      switch (fieldTripListType) {
        case fieldTripListTypes.approvals:
          setListItems(approvals)
          if (accessLevel >= gEnums.accessLevels.appDataAdmin.value) {
            setAllowReset(true)
          }
          break;
        case fieldTripListTypes.validations:
          setListItems(validations)
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [fieldTripListType, approvals, validations]);

  useEffect(() => {
    if (staffTypes_appUser) {
      const _appUserRolePermissions = {}
      _.forEach(staffRoleTypes, (staffRoleType, key) => {
        const staffKey = _.findKey(staffTypes, { name: _.startCase(key) })
        _appUserRolePermissions[staffRoleType] = staffTypes_appUser.includes(staffKey)
      })
      setAppUserRolePermissions(_appUserRolePermissions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [staffTypes_appUser]);


  const handleItemClick = (viewItem, _itemKey) => pushSimple(viewItem, _itemKey)
  const handleResetData = () => {
    setFormData({})
    fieldTrip_handlers.handleResetData()
  }

  const handleTrigger_email = (e, opts) => {
    e.stopPropagation()
    const { role, emailType } = opts
    setUpdating(true)
    fieldTrip_handlers.handleTrigger_email(role, emailType, setUpdating)
  }

  const handleTrigger_approved = (e, opts) => {
    e.stopPropagation()
    fieldTrip_handlers.handleUpdate_fieldTripApproved(opts, formData)
  }

  const itemDescription_appoved = (roleKey, time_stamp_approved) => {
    const { approvalAllowed, pendingType } = canApprove_approval(roleKey)
    if (approvalAllowed) {
      return time_stamp_approved ? 'Approved on ' + time_stamp_approved : 'Pending Approval'
    } else {
      return 'Awaiting ' + _.startCase(pendingType) + ' Approval'
    }
  }

  const itemDescription_emailSent = (roleKey, time_stamp_emailSent) => {
    if (time_stamp_emailSent) {
      return 'Email sent on ' + time_stamp_emailSent
    } else {
      return null
    }
  }

  const labels_status = (email_ts, approved_ts, forValidation) => {
    const lbls = []
    lbls.push(lbl_detail(_captions.email, _icons.email, email_ts ? 'green' : 'grey', email_ts))
    lbls.push(lbl_detail(forValidation ? _captions.validated : _captions.approved, _icons.approved, approved_ts ? 'green' : 'grey', approved_ts))
    return lbls
  }

  const getItemInfo = (roleKey) => {

    const approvedDateProp = roleKey + 'ApprovedDate'
    const approvedEmailProp = roleKey + 'EmailSentDate'

    const fieldTripData_role = fieldTrip[roleKey]
    const fieldTripsData_role = fieldTrips_state[roleKey]
    const fieldTripApprovalDate = status && status[approvedDateProp]
    const fieldTripSentDate = status && status[approvedEmailProp]

    const time_stamp_approved = fieldTripApprovalDate ? formatItem(formatTypes.fullDate, fieldTripApprovalDate.toDate()) : null
    const time_stamp_emailSent = fieldTripSentDate ? formatItem(formatTypes.fullDate, fieldTripSentDate.toDate()) : null

    const itemInfo = {}

    _.forEach(fieldTripData_role, (itemKey => {

      if (fieldTripsData_role && fieldTripsData_role[itemKey]) {

        const item = fieldTripsData_role[itemKey]
        const { name, _itemKey } = item ?? {}

        let { email } = item ?? {}
        let email2;
        let forValidation;

        switch (roleKey) {
          case validationRoles.specialEdSecretaries:
          case validationRoles.transportation:
            forValidation = true
            break;
          default:
            break;
        }

        if (useDefaultEmails) {
          switch (roleKey) {
            case approvalRoles.secretaries:
              email = secretaryEmail ? secretaryEmail + ' *' : email
              break;
            case approvalRoles.principals:
              email = principalEmail ? principalEmail + ' *' : email
              break;
            case approvalRoles.districtResources:
              email = districtResourceEmail ? districtResourceEmail + ' *' : email
              break;
            default:
              break;
          }
        }

        if (useMe) {
          email2 = _developerAdminEmail
        }

        itemInfo._itemKey = _itemKey
        itemInfo.name = name
        itemInfo.email = email
        itemInfo.email2 = email2
        itemInfo.time_stamp_approved = time_stamp_approved
        itemInfo.approved = time_stamp_approved ? true : false
        itemInfo.emailed = time_stamp_emailSent ? true : false
        itemInfo.description_approved = itemDescription_appoved(roleKey, time_stamp_approved)
        itemInfo.description_emailSent = itemDescription_emailSent(roleKey, time_stamp_emailSent)
        itemInfo.labels = labels_status(time_stamp_emailSent, time_stamp_approved, forValidation)
        itemInfo.role = _.startCase(roleKey)
        itemInfo.roleKey = roleKey
        itemInfo.has = true
      }
    }))

    return itemInfo

  }

  const canApprove_approval = (role, itemInfo) => {

    let approvalAllowed = false
    let pendingType;

    switch (fieldTripListType) {

      case fieldTripListTypes.approvals:
        switch (role) {
          case approvalRoles.secretaries:
            if (itemInfo && itemInfo.emailed) {
              approvalAllowed = true
              _.forEach(formData, (formDataValue, key) => {
                if (!formDataValue) {
                  approvalAllowed = false
                }
              })
            } else {
              approvalAllowed = true
            }
            // secretaries approval

            break;
          case approvalRoles.principals:
            // secretaries approval
            approvalAllowed = approvals[approvalRoles.secretaries].approvalType === approvalTypes.approved;
            pendingType = approvalRoles.secretaries
            break;
          case approvalRoles.districtResources:
            // principals approval
            approvalAllowed = approvals[approvalRoles.principals].approvalType === approvalTypes.approved;
            pendingType = approvalRoles.principals
            break;
          default:
            approvalAllowed = true; // Secretary can always approve
            break;
        }
        break;

      case fieldTripListTypes.validations:
        switch (role) {
          case validationRoles.transportation:
          case validationRoles.specialEdSecretaries:
            // districtResources approval
            approvalAllowed = approvals[approvalRoles.districtResources].approvalType === approvalTypes.approved;
            pendingType = approvalRoles.districtResources
            break;
          default:
            approvalAllowed = true; // Secretary can always approve
            break;
        }
    }
    return { approvalAllowed, pendingType }

  };

  const ccWrapper = (button1, button2) => <Card.Content extra className={'other'}>
    {button1}
    {button2 && button2}
  </Card.Content>

  const ccWrapper_form = (frm) => <Card.Content>
    {frm}
  </Card.Content>

  const lbl = (text, iconName, color) => <Label size={_size} color='black' >
    <Icon name={iconName} color={color} />{text}
  </Label>

  const lbl_detail = (text, iconName, color, detail) => <Label>
    <Icon name={iconName} color={color} /> {text}
    <Label.Detail>{detail}</Label.Detail>
  </Label>

  const btn = (text, iconName, color, oc, opts, disabled) => <Button icon labelPosition={oc ? 'right' : 'left'} inverted={oc ? false : true} size={_size} color={color} disabled={disabled}
    onClick={(e) => oc && oc(e, opts)}>
    <Icon name={iconName} color={oc ? null : color} />{text}</Button>

  const button_send = (role, itemInfo) => {

    const { description_approved } = itemInfo ?? {}

    const _caption_approved = approvalRoles[role] ? 'Approved' : 'Validated'
    const _caption_approve = approvalRoles[role] ? 'Approve' : 'Validate'

    const { approvalAllowed } = canApprove_approval(role, itemInfo)
    const _disabled = !approvalAllowed

    if (roleToUpdate && roleToUpdate.role === role) {
      return ccWrapper(
        btn('Approve', 'check', 'green', handleTrigger_approved, { role, approved: true, ...formData }, _disabled),
        btn('Deny', 'ban', 'red', handleTrigger_approved, { role, approved: false }, _disabled)
      )
    } else {
      if (listItems && listItems[role] && listItems[role].approvalType) {
        const rat = listItems[role].approvalType
        switch (rat) {
          case approvalTypes.approved:
            return ccWrapper(lbl(_caption_approved, 'check circle', 'green'))
          case approvalTypes.denied:
            return ccWrapper(lbl('Denied', 'ban', 'red'))
          case approvalTypes.seen:
            return ccWrapper(lbl('Seen', 'check', 'blue'))
          case approvalTypes.acknowledged:
            return ccWrapper(lbl('Acknowledged', 'check circle', 'green'))
          case approvalTypes.sent:
            const _caption_appropve = allowEmail ? _caption_approve : _caption_approve + ' (test)'
            return allowApprovalUpdate ? ccWrapper(btn(_caption_appropve, 'arrow right', _disabled ? 'grey' : 'blue', handleTrigger_approved, { role, approved: true }, _disabled)) : null
          case approvalTypes.none:
            if (!approvalAllowed) {
              return ccWrapper(lbl(description_approved, 'question', 'green'))
            } else {
              const _caption_email = allowEmail ? 'Send Email' : 'Send Email (test)'
              return ccWrapper(btn(_caption_email, updating ? 'spinner' : 'arrow right', _disabled ? 'grey' : allowEmail ? 'green' : 'blue', handleTrigger_email, { role, emailType: emailTypes.validation }, _disabled))

            }
        }
      }
    }
  }

  const form_extra = (extras) => <AppForm
    handleUpdateParentData={setFormData}
    appFormItems={extras}
    parentData={{ paymentMethod: '' }}
  />

  const content_extra = (role) => {
    const extras = extraFieldProps && extraFieldProps[role]
    if (extras) {
      return ccWrapper_form(form_extra(extras))
    }
  }

  const icon = (icon, color, iconCorner, iconCornerColor) => {
    if (iconCorner) {
      return <Icon.Group as={'i'} style={{ marginRight: '.5em' }}>
        <Icon name={icon} color={color ? color : null} />
        <Icon corner={true} name={iconCorner} color={iconCornerColor ? iconCornerColor : 'blue'} />
      </Icon.Group>
    } else {
      return <Icon name={icon} color={color ? color : null} />
    }
  }

  const cardItem = (approval, roleKey, allows) => {

    const _icon = {
      color: 'grey',
      icon: 'question circle outline'
    }

    switch (approval.approvalType) {
      case approvalTypes.approved:
        _icon.color = 'green'
        _icon.icon = 'check circle'
        break;
      case approvalTypes.denied:
        _icon.color = 'red'
        _icon.icon = 'ban'
        break;
      default:
        break;
    }

    const itemInfo = getItemInfo(roleKey)

    const { approved, role, name, email, email2, labels, _itemKey, emailed } = itemInfo ?? {}

    const viewItem = { key: 'staff' }

    const ccn = allows.isAppUserRole ? 'crd-sub sel' : 'crd-sub'

    return itemInfo.has ? <Card fluid as='div' className={ccn} color={approved ? 'green' : 'grey'}  >
      <Card.Content>
        <Card.Header>{icon(allows.isAppUserRole ? 'user circle' : 'user', approved ? 'green' : 'grey', approved ? 'check' : 'circle outline', approved ? 'green' : 'grey')}{role}</Card.Header>
        <Card.Meta onClick={(e) => { handleItemClick(viewItem, _itemKey) }}>{name}</Card.Meta>
        <Card.Meta className={useMe ? 'txt-line-through' : null}>{email}</Card.Meta>
        {itemInfo.email2 && <Card.Meta>{email2}</Card.Meta>}
      </Card.Content>
      {itemInfo.labels && <Card.Content extra className={'other'}>
        {labels}
      </Card.Content>}
      {emailed && !approved && content_extra(roleKey)}
      {allows.allow && button_send(roleKey, itemInfo)}
    </Card>
      : null
  }

  const getCardItems = () => {

    const cardItems = []
    const cardItems_appUser = []

    // loop the listItems
    _.forEach(listItems, (approval, roleKey) => {

      const allows = {
        roleKey,
        allow: appUserRolePermissions ? appUserRolePermissions[roleKey] : true,
        isAppUserRole: appUserRolePermissions && appUserRolePermissions[roleKey] ? true : false
      }

      if (roleToUpdate && !showStatusToAll) {
        if (roleToUpdate.role === roleKey) {
          if (allows.isAppUserRole) {
            cardItems_appUser.push(cardItem(approval, roleKey, allows))
          } else {
            cardItems.push(cardItem(approval, roleKey, allows))
          }
        }
      } else {
        if (allows.isAppUserRole) {
          cardItems_appUser.push(cardItem(approval, roleKey, allows))
        } else {
          cardItems.push(cardItem(approval, roleKey, allows))
        }
      }
    })

    return { cardItems, cardItems_appUser }

  }

  const approvalList = () => {

    const { cardItems, cardItems_appUser } = getCardItems()

    return cardItems.length > 0 ? <Segment>
      {allowReset && btn('Reset Approvals', 'delete', 'blue', handleResetData)}
      <Divider horizontal>
        <Header as='h5'>
          <Icon name='users' />
          {_.startCase(fieldTripListType)}
        </Header>
      </Divider>
      <Card.Group>
        {cardItems_appUser}
        {cardItems}
      </Card.Group>
    </Segment> : null
  }

  const content = () => <div>
    {listItems && approvalList()}
  </div>

  return content()
}

export default FieldTripApprovals