import _ from 'lodash';
import { currentHelpers } from '../../redirection/current';
import { grts, responseHandlers } from './reducerHelpers/dispatchProps';
import { sendMessageToServiceWorker } from './reducerHelpers/serviceWorkerHelpers';
import { timeStampsHelpers } from '../../firestoreData/updates/subUpdates/fsTimeStamps';
import { copyObj } from '../../common_web/copy';
import { convertHelpers } from '../../common/convert';

const _showConsoleLog = false
const _sw_normal = 'service-worker.js';

export const _cacheNames = {
  cache_client: 'cache_client',
  cache_district: 'cache_district',
  cache_event: 'cache_event',
  cache_home: 'cache_home',
  cache_main: 'cache_main',
  cache_organization: 'cache_organization',
  cache_settings_timestamp: 'cache_settings_timestamp',
  cache_settings: 'cache_settings',
  cache_sport: 'cache_sport',
  other: 'other',
  test: 'test',
}

export const _cacheTypes = {
  _timeStamps: '_timeStamps',
  access_teams: 'access_teams',
  appGlobalData: 'appGlobalData',
  data: 'data',
  district_data: 'district_data',
  globalData: 'globalData',
  organization_data: 'organization_data',
  settings: 'settings',
  sports_data: 'sports_data',
  sports_info: 'sports_info',
}

export const _serviceWorkerMessageTypes = {
  skipWaiting: 'SKIP_WAITING',
  checkForUpdate: 'CHECK_FOR_UPDATE',
  newVersionAvailable: 'NEW_VERSION_AVAILABLE',
  newVersionNumber: 'NEW_VERSION_NUMBER',
  ammendAppSettings: 'ammendAppSettings',
}

const rts = {
  handleAlert_newServiceWorker: 'handleAlert_newServiceWorker',
  handleAmmend_currentAppVersion: 'handleAmmend_currentAppVersion',
  handleAmmend_serviceWorkerMessage: 'handleAmmend_serviceWorkerMessage',
  handleAmmendCache_settingsNeedsUpdate: 'handleAmmendCache_settingsNeedsUpdate',
  handleCheckForUpdate: 'handleCheckForUpdate',
  handleClear_cache: 'handleClear_cache',
  handleClear_refresh: 'handleClear_refresh',
  handleGet_cache: 'handleGet_cache',
  handleInit_serviceWorker: 'handleInit_serviceWorker',
  handleSend_messageToServiceWorker: 'handleSend_messageToServiceWorker',
  handleSet_cache: 'handleSet_cache',
  handleSet_deferredPrompt: 'handleSet_deferredPrompt',
  handleSet_registration: 'handleSet_registration',
  handleSet_registrationSuccess: 'handleSet_registrationSuccess',
  ...grts
}

export const serviceWorkerReducer = (state, action) => {

  const { type, dispatch } = action
  const { registration } = state

  const { handleSet_registration, handleGet_cache, handleSet_cache, handleSet_registrationSuccess, handleAlert_newServiceWorker, handleSet_deferredPrompt, handleAmmend_serviceWorkerMessage } = serviceWorkerHandlers(dispatch)

  switch (type) {

    case rts.handleInit_serviceWorker:
      registerServiceWorker(handleSet_registration, handleSet_registrationSuccess)
      return state

    case rts.handleClear_cache:
      if (action.cacheKey) {
        clearSpecificCache(action.cacheKey)
      } else if (action.cacheDataOnly) {
        clearSpecificCachesByPrefix('cache_event')
      } else if (action.cacheSettingsOnly) {
        clearSpecificCachesByPrefix('cache_settings')
      } else {
        clearAllCaches()
      }
      return state

    case rts.handleClear_refresh:
      refreshPageWithCacheClear()
      return state

    case rts.handleGet_cache:
      fetchCache(handleSet_cache)
      return state

    case rts.handleSet_cache:
      return { ...state, cache_info: action.cache_info };

    case rts.handleAmmend_currentAppVersion:
      return { ...state, currentAppVersion: action.currentAppVersion }

    case rts.handleAmmendCache_settingsNeedsUpdate:
      return { ...state, cacheUpdateSettingsKey: action.cacheUpdateSettingsKey, cacheNeedsSettingsUpdate: true }

    case rts.handleSet_registration:
      _showConsoleLog && console.log('handleSet_registration', action.reg)
      addListener_updateFound(action.reg, handleAlert_newServiceWorker)
      addListner_beforeInstallPrompt(handleSet_deferredPrompt)
      addListener_navMessage(handleAmmend_serviceWorkerMessage)
      return { ...state, registration: action.reg, registrationSuccess: true };

    // triggered from  
    case rts.handleSet_registrationSuccess:
      _showConsoleLog && console.error('Service Worker registration failed:', action.error);
      return { ...state, registrationSuccess: action.success, registrationError: action.error };

    case rts.handleSet_deferredPrompt:
      _showConsoleLog && console.log('handleSet_deferredPrompt', action.deferredPrompt)
      return { ...state, deferredPrompt: action.deferredPrompt };

    case rts.handleAmmend_serviceWorkerMessage:
      const lastSeen = currentHelpers.storageItem_get('hasSeenUpdatePromptRecently')
      const currentTime = new Date().getTime();
      // let allowPrompt;
      // if (!lastSeen || currentTime - parseInt(lastSeen) > 24 * 60 * 60 * 1000) {
      //   allowPrompt = true
      // }
      const { message } = action
      const { currentVersion, newVersion } = message ?? {}
      _showConsoleLog && console.log('currentVersion, newVersion', currentVersion, newVersion)
      if (lastSeen && newVersion && newVersion !== currentVersion) {
        return { ...state, newVersionAvailable: action.newVersionAvailable, newVersionNumber: newVersion }
      } else {
        return { ...state }
      }

    case rts.handleCheckForUpdate:
      if (registration) {
        registration.update();
      }
      return state;

    case rts.handleAlert_newServiceWorker:
      _showConsoleLog && console.log('handleAlert_newServiceWorker', action)
      return state;

    case rts.handleSend_messageToServiceWorker:
      const { settings } = action
      // update the service worker cache 
      console.log('handleSend_messageToServiceWorker', settings)
      const success = sendMessageToServiceWorker(settings)
      if (success) {
        // console.log('------- >>> success', 'sendMessageToServiceWorker', settings)
        handleGet_cache()
        // if (pathViews && vit) {
        //   switch (cacheName) {
        //     case _cacheTypes.data:
        //       console.log('settings', settings)
        //       timeStampsHelpers.updateEventTimeStamp_db(pathViews, vit)
        //   }
        // }
      }
      return state

    default:
      return state;
  }
}

export const serviceWorkerHandlers = (dispatch) => {

  return {
    handleAlert_newServiceWorker: (response) => { dispatch({ type: rts.handleAlert_newServiceWorker, dispatch, response }) },
    handleAmmend_serviceWorkerMessage: (message) => { dispatch({ type: rts.handleAmmend_serviceWorkerMessage, dispatch, message }) },
    handleAmmendCache_settingsNeedsUpdate: (cacheUpdateSettingsKey) => { dispatch({ type: rts.handleAmmendCache_settingsNeedsUpdate, dispatch, cacheUpdateSettingsKey }) },
    handleCheckForUpdate: () => { dispatch({ type: rts.handleCheckForUpdate, dispatch }) },
    handleClear_cache: (cacheKey, cacheDataOnly, cacheSettingsOnly) => { dispatch({ type: rts.handleClear_cache, dispatch, cacheKey, cacheDataOnly, cacheSettingsOnly }) },
    handleClear_refresh: () => { dispatch({ type: rts.handleClear_refresh, dispatch }) },
    handleGet_cache: () => { dispatch({ type: rts.handleGet_cache, dispatch }) },
    handleInit_serviceWorker: (pathViews) => { dispatch({ type: rts.handleInit_serviceWorker, dispatch, pathViews }) },
    handleSend_messageToServiceWorker: (settings, cacheName, pathViews, vit) => { dispatch({ type: rts.handleSend_messageToServiceWorker, dispatch, settings, cacheName, pathViews, vit }) },
    handleSet_cache: (cache_info) => { dispatch({ type: rts.handleSet_cache, dispatch, cache_info }) },
    handleSet_currentAppVersion: (currentAppVersion) => { dispatch({ type: rts.handleAmmend_currentAppVersion, dispatch, currentAppVersion }) },
    handleSet_deferredPrompt: (deferredPrompt) => { dispatch({ type: rts.handleSet_deferredPrompt, dispatch, deferredPrompt }) },
    handleSet_registration: (reg) => { dispatch({ type: rts.handleSet_registration, dispatch, reg }) },
    handleSet_registrationSuccess: (success, error) => { dispatch({ type: rts.handleSet_registrationSuccess, dispatch, success, error }) },
    ...responseHandlers(dispatch)
  }
}

export const serviceWorkerInitialState = (initState) => {
  return { ...initState }
};

const registerServiceWorker = async (handleSet_registration, handleSet_registrationSuccess) => {

  const sw_normal_url = `${process.env.PUBLIC_URL}/${_sw_normal}`;

  try {
    const reg = await navigator.serviceWorker.register(sw_normal_url);
    console.log('!! Registration Set !!')
    handleSet_registration(reg);
  } catch (error) {
    handleSet_registrationSuccess(false, error);
  }
}

const addListener_updateFound = (registration, callback) => {
  registration.addEventListener('updatefound', () => {
    const newWorker = registration.installing;
    newWorker.addEventListener('statechange', () => {
      if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
        callback('New service worker installed, waiting to be activated')
      }
    });
  });
}

const addListner_beforeInstallPrompt = (callback) => {
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    callback(e)
  });
}

/**
 * Sets a `message` listener. If a message is sent from the service-worker.js file, it will be picked up here.
 * @param {object} callback 
 */
const addListener_navMessage = (callback) => {
  navigator.serviceWorker.addEventListener('message', event => {
    if (event.data) {
      switch (event.data.type) {
        case _serviceWorkerMessageTypes.newVersionAvailable:
          callback(event.data)
          break;
        case _serviceWorkerMessageTypes.newVersionNumber:
          console.log('addListener_navMessage', event.data)
          callback(event.data)
          break;
        default:
          callback(true)
          break;
      }
    }
  });
}

/**
 * Gets all the items in Cache storage
 * @param {function} callback 
 */
const fetchCache = async (callback) => {

  try {

    const items = await listCachedItems();

    const _cache_info = {
      cache_groups: {},
      cacheFetched: false
    }

    const _cachedGroups = {};

    _.forEach(items, (item, key) => {

      const cacheMappings = [
        { cacheKey: _cacheNames.cache_client, typeKey: 'cache_client' },
        { cacheKey: _cacheNames.cache_district, typeKey: 'cache_district' },
        { cacheKey: _cacheNames.cache_event, typeKey: 'cache_event' },
        { cacheKey: _cacheNames.cache_home, typeKey: 'cache_home' },
        { cacheKey: _cacheNames.cache_organization, typeKey: 'cache_organization' },
        { cacheKey: _cacheNames.cache_settings, typeKey: null },
        { cacheKey: _cacheNames.cache_sport, typeKey: 'cache_sport' },
        { cacheKey: 'main-cache', typeKey: 'cache_main' }
      ];

      let _typeKey, _key;

      _.some(cacheMappings, ({ cacheKey, typeKey }) => {
        if (key.includes(cacheKey)) {
          _typeKey = typeKey;
          _key = key.replace(`${cacheKey}-`, '');
          return true; // Break loop once matched
        }
        return false;
      });

      if (_typeKey) {
        _.set(_cachedGroups, [_typeKey, _key], item);
      } else {
        _cachedGroups[_key || key] = item; // If no match, fall back to original key
      }
    });

    _cache_info.cacheFetched = true
    _cache_info.cache_groups = _cachedGroups

    try {
      _.forEach(_cachedGroups, (cache_group, cachedGroupKey) => {
        const _cachedGroupKey = cachedGroupKey.split('-')[0]
        switch (_cachedGroupKey) {
          case _cacheNames.cache_settings:
            // nothing
            break;
          default:
            _.forEach(cache_group, (cache_data_item) => {
              _.forEach(cache_data_item, (data, key) => {
                if (cache_data_item) {
                  switch (key) {
                    case _cacheTypes.appGlobalData:
                    case _cacheTypes._timeStamps:
                      break;
                    default:
                      if (!cache_data_item.cache_collections) { cache_data_item.cache_collections = {} }
                      const data_collection = copyObj(data)
                      convertHelpers.createItemKeys(data_collection)
                      // console.log('data_collection', key, data_collection)
                      // _.forEach(data_collection, (dc) => {
                      //   convertHelpers.createItemKeys(dc)
                      // })
                      cache_data_item.cache_collections[key] = copyObj(data_collection)
                      delete cache_data_item[key]
                  }
                }
              })
            })
        }
      })
    } catch (error) {
      console.error('fetchCache error', error)
    }

    callback(_cache_info)

  } catch (error) {
    console.error('Failed to fetch cached items:', error);
  }
};

const listCachedItems = async () => {
  try {
    const cacheKeys = await caches.keys(); // Get all cache names 
    const allCachedItems = {}; // Initialize an empty object

    await Promise.all(_.map(cacheKeys, async (cacheKey) => {
      const cache = await caches.open(cacheKey);
      const cachedRequests = await cache.keys(); // Get all requests in the cache

      await Promise.all(_.map(cachedRequests, async (request) => {
        const response = await cache.match(request); // Get the response for each request
        const _response = response ? await convertResponse(response) : 'No Response';

        // Extract key/name from the request URL
        const key = extractKeyFromUrl(request.url);

        if (!allCachedItems[cacheKey]) { allCachedItems[cacheKey] = {} }
        allCachedItems[cacheKey][key] = _response
      }));
    }));

    return allCachedItems;
  } catch (error) {
    console.error('Failed to list cached items:', error);
    return {};
  }
};

const convertResponse = async (response) => {
  try {
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      return await response.json();
    } else {
      return await response.text();
    }
  } catch (error) {
    console.error('Error converting response:', error);
    return 'Error';
  }
};

const extractKeyFromUrl = (url) => {
  try {
    // Assuming your URL pattern is /somepath/key
    const urlParts = new URL(url).pathname.split('/');
    // Extract the key from the URL
    return urlParts[urlParts.length - 1] || 'default-key';
  } catch (error) {
    console.error('Error extracting key from URL:', error);
    return 'default-key';
  }
};

const clearSpecificCache = async (cacheKey) => {
  if ('caches' in window) {
    const cacheDeleted = await caches.delete(cacheKey);
    if (cacheDeleted) {
      console.log(`Cache "${cacheKey}" has been deleted.`);
    } else {
      console.log(`Cache "${cacheKey}" not found.`);
    }
  } else {
    console.log("Cache API not supported in this browser.");
  }
};

const clearAllCaches = async () => {
  if ('caches' in window) {
    try {
      const cacheKeys = await caches.keys(); // List all cache names
      for (const cacheKey of cacheKeys) {
        await caches.delete(cacheKey); // Delete each cache
        console.log(`Cache "${cacheKey}" has been deleted.`);
      }
      if (cacheKeys.length === 0) {
        console.log("No caches found.");
      }
    } catch (error) {
      console.error("Error deleting caches:", error);
    }
  } else {
    console.log("Cache API not supported in this browser.");
  }
};

// const clearAllCaches = async () => {
//   if ('caches' in window) {
//     const cacheNames = await caches.keys(); // List all cache names
//     cacheNames.forEach(async (cacheKey) => {
//       await caches.delete(cacheKey);
//       console.log(`Cache "${cacheKey}" has been deleted.`);
//     });
//   } else {
//     console.log("Cache API not supported in this browser.");
//   }
// };

const clearSpecificCachesByPrefix = async (prefix) => {
  if ('caches' in window) {
    const cacheKeys = await caches.keys(); // Get all cache names
    const cachesToDelete = cacheKeys.filter((name) => name.startsWith(prefix));

    cachesToDelete.forEach(async (cacheKey) => {
      await caches.delete(cacheKey);
      console.log(`Cache "${cacheKey}" has been deleted.`);
    });

    if (cachesToDelete.length === 0) {
      console.log(`No caches found starting with "${prefix}".`);
    }
  } else {
    console.log("Cache API not supported in this browser.");
  }
};

const refreshPageWithCacheClear = () => {

  // Clearing cache using different techniques based on browser support
  try {
    if (typeof caches !== 'undefined') {
      // Clearing cache using Service Workers (if supported)
      caches.keys().then(function (cacheKeys) {
        cacheKeys.forEach(function (cacheKey) {
          caches.delete(cacheKey);
        });
      });
    }

    if (window.hasOwnProperty('caches')) {
      // Clearing cache using CacheStorage API (if supported)
      window.caches.keys().then(function (cacheKeys) {
        cacheKeys.forEach(function (cacheKey) {
          window.caches.delete(cacheKey);
        });
      });
    }

  } catch (error) {
    console.error('error', error)
  }

  // Reloading the page
  window.location.reload(true); // Passing true as a parameter forces a server reload and cache clear on most browsers
}

