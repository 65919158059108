import React, { useState } from 'react';
import { Checkbox, Dropdown, Grid, Header, Segment } from 'semantic-ui-react';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import UiSaveButtons from '../buttons/UiSaveButtons';

const AppUserSettings = (props) => {

  const { handleCancel } = props ?? {}

  // Sample state for theme selection
  const [selectedTheme, setSelectedTheme] = useState('light');

  // Sample state for language selection
  const [selectedLanguage, setSelectedLanguage] = useState('english');

  // Sample state for font size
  const [fontSize, setFontSize] = useState('medium');

  // Sample state for notification preferences
  const [pushNotification, setPushNotification] = useState(true);
  const [emailNotification, setEmailNotification] = useState(true);
  const [inAppAlerts, setInAppAlerts] = useState(true);

  // Other settings state variables can be added similarly

  const themeOptions = [
    { key: 'light', text: 'Light', value: 'light' },
    { key: 'dark', text: 'Dark', value: 'dark' },
    // Add more theme options as needed
  ];

  const languageOptions = [
    { key: 'english', text: 'English', value: 'english' },
    { key: 'spanish', text: 'Spanish', value: 'spanish' },
    // Add more language options as needed
  ];

  const fontSizeOptions = [
    { key: 'small', text: 'Small', value: 'small' },
    { key: 'medium', text: 'Medium', value: 'medium' },
    { key: 'large', text: 'Large', value: 'large' },
    // Add more font size options as needed
  ];

  // Handle save button click
  const handleSave = () => {
    // Add logic to save settings
    console.log('Settings saved!');
  };

  const content = () => <Grid >
    <Grid.Column>
      <Header as="h2" textAlign="center">
        User Settings
      </Header>
      <Segment>
        <Header as="h3">Theme</Header>
        <Dropdown
          placeholder="Select Theme"
          selection
          options={themeOptions}
          value={selectedTheme}
          onChange={(e, { value }) => setSelectedTheme(value)}
        />
      </Segment>

      <Segment>
        <Header as="h3">Language</Header>
        <Dropdown
          placeholder="Select Language"
          selection
          options={languageOptions}
          value={selectedLanguage}
          onChange={(e, { value }) => setSelectedLanguage(value)}
        />
      </Segment>

      <Segment>
        <Header as="h3">Font Size</Header>
        <Dropdown
          placeholder="Select Font Size"
          selection
          options={fontSizeOptions}
          value={fontSize}
          onChange={(e, { value }) => setFontSize(value)}
        />
      </Segment>

      <Segment>
        <Header as="h3">Notification Preferences</Header>
        <Checkbox
          label="Push Notifications"
          checked={pushNotification}
          onChange={() => setPushNotification(!pushNotification)}
        />
        <Checkbox
          label="Email Notifications"
          checked={emailNotification}
          onChange={() => setEmailNotification(!emailNotification)}
        />
        <Checkbox
          label="In-App Alerts"
          checked={inAppAlerts}
          onChange={() => setInAppAlerts(!inAppAlerts)}
        />
      </Segment>

    </Grid.Column>
  </Grid>

  const footer = () => <UiSaveButtons save={{ oc: handleSave }} />

  const wrapper = () => <Wrapper
    content={content()}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper>

  const fpw_menu = () => <FullPageWrapper
    content={wrapper()}
    topperCaption={'Settings'}
    handleCancel={handleCancel}
  ></FullPageWrapper >

  return fpw_menu()

};

export default AppUserSettings;
