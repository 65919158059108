import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { PageContext } from '../../cnr/contexts/PageContext'

const UiStaticDropdowns = (props) => {

  const { handlePageItem, selectedPageItem, notificationTargets } = props ?? {}

  // pageContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_viewItems } = pageSettings ?? {}

  const [viewsList, setViewsList] = useState()
  const [selectedView, setSelectedView] = useState(selectedPageItem ? selectedPageItem.key : null)

  const handleSelectView = (e, d) => setSelectedView(d.value)

  const getItems = (keys) => {
    const _items = []
    keys.forEach(key => {
      _items.push({ key: key, value: key, text: _.startCase(key) })
    })
    return _.sortBy(_items, 'key')
  }

  useEffect(() => {
    if (aps_viewItems) {
      let items = []
      if (notificationTargets) {
        const _subItems = []
        Object.keys(aps_viewItems).forEach(key => {
          if (notificationTargets) {
            if (notificationTargets.includes(key)) {
              _subItems.push(key)
            }
          }
        })
        items = getItems(_subItems)
      } else {
        items = getItems(Object.keys(aps_viewItems))
      }
      setViewsList(items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (handlePageItem && selectedView && aps_viewItems && aps_viewItems[selectedView]) {
      handlePageItem(selectedView, aps_viewItems[selectedView])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedView]);

  const dd_view = () => <Form.Dropdown placeholder={'Page'} label={'Page'} search scrolling selection value={selectedView} options={viewsList} onChange={handleSelectView} />

  return (
    <Form>
      {viewsList && dd_view()}
      {/* {selectedDataItems && dd_dataItem()} */}
    </Form>
  )
}

export default UiStaticDropdowns