import React from 'react';
import { Segment } from 'semantic-ui-react';
import { googleSheetsTypes } from '../../cnr/reducers/GoogleSheetsReducer';
import { clickOriginTypes, gEnums } from '../../enums/globalEnums';
import { CreateEventWithProvider } from '../../viewSettings/create/addSubs/CreateEvent';
import { allDataModes } from './AppDashboard';

const AppCheck = React.lazy(() => import('./AppCheck'));
const AppDataTransferWithProvider = React.lazy(() => import('../../pageItem/modification/dataModifications/AppDataTransfer'));
const AppDataViewer = React.lazy(() => import('./AppDataViewer'));
const AppDocuments = React.lazy(() => import('./AppDocuments'));
const AppStorageTransferWithProvider = React.lazy(() => import('../../pageItem/modification/dataModifications/AppStorageTransfer'));
const AppUserManagement = React.lazy(() => import('../../appUsers/AppUserManagement'));
const FileManagement = React.lazy(() => import('./FileManagement'));
const GoogleDashboardWithProvider = React.lazy(() => import('./GoogleDashboard'));
const GoogleFormsViewerWithProvider = React.lazy(() => import('./GoogleFormsViewer'));
const ImageMap = React.lazy(() => import('../swipe/ImageMapping'));
const ImageMapping = React.lazy(() => import('../swipe/ImageMapping'));
const QuickView = React.lazy(() => import('./QuickView'));
const RegDataViewerWithProvider = React.lazy(() => import('./RegDataViewer'));
const TransferProvider = React.lazy(() => import('../../cnr/contexts/TransferContext'));
const UiMoveData = React.lazy(() => import('../../pageItem/modification/dataManagement/UiMoveData'));
const UiUpload = React.lazy(() => import('../../pageItem/modification/dataManagement/UiUpload'));

const AppDashboardContent = (props) => {

  const { currentDataMode, handleDataMode } = props ?? {}

  switch (currentDataMode) {

    case 'bulkImageUpload':
      return <UiUpload origin={'AppDashboardContent'} showUpload={true} />

    case allDataModes.appCheck:
      return <AppCheck />

    case allDataModes.appData:
      return <AppDataViewer />

    case allDataModes.appDocuments:
      return <AppDocuments />

    case allDataModes.transferData:
      return <AppDataTransferWithProvider clickOriginType={clickOriginTypes.dataViewer} appTransferMode={currentDataMode} handleCancel={handleDataMode} />

    case allDataModes.transferStorage:
      return <AppStorageTransferWithProvider clickOriginType={clickOriginTypes.dataViewer} handleCancel={handleDataMode} />

    case allDataModes.appUsers:
    case allDataModes.clientAppUsers:
    case allDataModes.clientProfiles:
      return <AppUserManagement clickOriginType={clickOriginTypes.dataViewer} handleCancel={handleDataMode} />

    case allDataModes.fileManagement:
      return <FileManagement />

    case allDataModes.googleDashboard:
      return <TransferProvider>
        <GoogleDashboardWithProvider googleSheetType={googleSheetsTypes.normal} clickOriginType={clickOriginTypes.dataViewer} />
      </TransferProvider>

    case allDataModes.googleRegistration:
      return <TransferProvider>
        <GoogleFormsViewerWithProvider />
      </TransferProvider>

    case allDataModes.zoneMapping:
      return <ImageMap handleCancel={handleDataMode} imageMapType={gEnums.imageMapTypes.zoneMapping} inDashboard={true} />

    case allDataModes.locationMapping:
      return <ImageMapping handleCancel={handleDataMode} imageMapType={gEnums.imageMapTypes.locationMapping} inDashboard={true} />

    case allDataModes.import:
      return <Segment basic>Import</Segment>

    case allDataModes.quickView:
      return <QuickView />

    case allDataModes.recreateEvent:
      return <CreateEventWithProvider fromAppViewer={true} />

    case allDataModes.registrationData:
      return <RegDataViewerWithProvider clickOriginType={clickOriginTypes.dataViewer} handleCancel={handleDataMode} />

    case allDataModes.moveDataTo:
      return <UiMoveData dataMode={currentDataMode} />
    default:
    // nothing
  }
}

export default AppDashboardContent