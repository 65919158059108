import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Message } from 'semantic-ui-react';
import JsonViewer from '../../components/viewers/JsonViewer';
import { DataManagementContext } from '../../components/viewers/DataManagementViewer';
import { appIconTypes } from '../../enums/appIconTypes';
import { backupModes } from '../../cnr/reducers/DataManagmentReducer';
import { dataManagementTypes, dataSidebarActionTypes, settingsActionTypes } from '../../viewSettings/enums/itemActionTypes';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import { DataModificationsContext } from './UiDataModifications';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';

/**
 * 
 * @param {object} props (useFull, jsonConfirm, handleCancel, handleUpdate, caption, text, updateCaption)
 * @params test
 * @returns 
 */
const UiConfirmation = (props) => {

  const { useFull, inSidebar, jsonConfirm, topperCaption2 } = props ?? {}

  // dataModificationsContext
  const dataModificationsContext = useContext(DataModificationsContext)
  const { dataModifications_handlers } = dataModificationsContext ?? {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state, dataManagement_handlers } = dataManagementContext ?? {}
  const { dataManagementType, updating, confirmation, viewItem, commitOpts } = dataManagement_state ?? {}
  const { key: viewItem_key } = viewItem ?? {}
  const inViewer = inSidebar || (dataManagement_state && !useFull) ? true : false

  const _dataManagementType = props && props.dataManagementType ? props.dataManagementType : dataManagementType

  const [caption, setCaption] = useState()
  const [text, setText] = useState()
  const [updateCaption, setUpdateCaption] = useState()
  const [updateIcon, setUpdateIcon] = useState()
  const [updateColor, setUpdateColor] = useState()

  useEffect(() => {

    console.log('_dataManagementType', _dataManagementType)

    switch (_dataManagementType) {

      case settingsActionTypes.transferDataToProduction:
        setCaption('Push Data')
        setText('Are you sure you want to push this data to Production?')
        setUpdateCaption(_.startCase(_dataManagementType))
        break;

      case dataSidebarActionTypes.backupItemData:
        if (commitOpts) {
          switch (commitOpts.backupMode) {
            case backupModes.backup:
              setCaption('Data Backup')
              setText('Are you sure you want to backup the data? This will save a copy of the data to the `_backup` collection')
              setUpdateCaption('Backup Item Data')
              break;
            case backupModes.restore:
              setCaption('Data Restore')
              setText('Are you sure you want to restore the data? This will replace the current app data with the data in the `_backup` collection')
              setUpdateCaption('Restore Item Data')
              break;
            default:
            // nothing
          }
        } else {
          setCaption('Data Backup')
          setText('Are you sure you want to backup the data? This will save a copy of the data to the `_backup` collection')
          setUpdateCaption('Backup Item Data')
        }
        break;

      case settingsActionTypes.lockData:
        setCaption('Lock Data')
        setText('Are you sure you want to lock the data?')
        setUpdateCaption(_.startCase(_dataManagementType))
        break;

      case dataSidebarActionTypes.updateStaticViews:
        setCaption('Static Views')
        setText('Are you sure you want to update the static views?')
        setUpdateCaption(_.startCase(_dataManagementType))
        break;

      case dataSidebarActionTypes.createGlobalData:
        setCaption('Please Confirm')
        setText('Are you sure you want to update the global data?')
        setUpdateCaption(_.startCase(_dataManagementType))
        break;

      case dataManagementTypes.fixPropData:
        setCaption('Please Confirm')
        setText('Are you sure you want to update the ammended data?')
        setUpdateCaption(_.startCase(_dataManagementType))
        break;

      case dataSidebarActionTypes.moveData:
      case dataSidebarActionTypes.moveDataTo:
        setCaption('Please Confirm')
        setText('Are you sure you want to move the data?')
        setUpdateCaption(_.startCase(_dataManagementType))
        break;


      case dataManagementTypes.updateDataRelationships:
        setCaption('Please Confirm')
        setText('Are you sure you want to update the data relationships?')
        setUpdateCaption(_.startCase(_dataManagementType))
        break;

      case dataSidebarActionTypes.createSingleDocument:
        setCaption('Please Confirm')
        setText('Are you sure you want to convert this collection to a document?')
        setUpdateCaption(_.startCase(_dataManagementType))
        break;

      case dataSidebarActionTypes.createAppDataLinks:
        setCaption('Please Confirm')
        setText('Are you sure you want to create app data links for this collection?')
        setUpdateCaption(_.startCase(_dataManagementType))
        break;
      //   caption: 'Confirmation',
      //   text: 'Are you sure you want to ' + saveCaption + '?',
      //   updateCaption: saveCaption

      default:
        setCaption(props ? props.caption : '')
        setText(props ? props.text : '')
        setUpdateCaption(props ? props.updateCaption : '')
        setUpdateIcon(props ? props.updateIcon : '')
        setUpdateColor(props ? props.updateColor : '')
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleCancel = () => {
    if (props.handleCancel) {
      props.handleCancel()
    } else {
      dataManagement_handlers && dataManagement_handlers.handleSelect_dataActionOption()
      dataModifications_handlers && dataModifications_handlers.handleDataModificationSelect()
    }
  }

  const handleUpdate = () => {
    if (props && props.handleUpdate) {
      props.handleUpdate()
    }
  }

  const message = () => <Message warning>
    <Message.Header>{caption}</Message.Header>
    {text}
  </Message>

  const jsonViewer = () => <JsonViewer json={jsonConfirm} name={viewItem_key} />

  const content = () => <React.Fragment>
    {message()}
    {jsonViewer()}
  </React.Fragment>

  const footer = () => <UiSaveButtons
    save={{ oc: handleUpdate, caption: updateCaption, icon: updateIcon ? updateIcon : appIconTypes.save, color: updateColor ? updateColor : iconColorTypes.save }}
  />

  const container = () => <Wrapper
    content={jsonConfirm ? content() : message()}
    footer={props && props.handleUpdate && footer()}
    wrapperType={wrapperTypes.padded}
    updating={updating}
    confirmation={confirmation}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={container()}
    handleCancel={handleCancel}
    topperCaption={'Confirmation'}
    topperCaption2={topperCaption2}
    isBack={true}
  />

  return inViewer ? container() : fullPageWrapper()

}

export default UiConfirmation