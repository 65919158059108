import React, { useState } from 'react';
import { Icon, Image } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import MotionComponent from '../../motions/MotionComponent';
import UiUpload from '../../pageItem/modification/dataManagement/UiUpload';
import FullImage from '../imaging/FullImage';
import DefaultImageIcon from './DefaultImageIcon';

const SingleImage = (props) => {

  const {
    _itemKey,
    _trueImageUrl,
    allowAction,
    asAvatar,
    fullImageProps,
    imageData,
    imageType,
    size,
    transition_image,
    useAvatar,
  } = props

  let { imageSize } = props

  const _asAvatar = asAvatar || useAvatar

  const [showBigImage, setShowBigImage] = useState()
  const [showImageUpload, setShowImageUpload] = useState()

  const handleShowBigImage = () => setShowBigImage(!showBigImage)
  const handleShowImageUpload = () => setShowImageUpload(!showImageUpload)
  const handleCloseUpload = () => setShowImageUpload()

  const image = () => {
    if (_trueImageUrl) {
      let cn = imageType ? 'img-type-' + imageType : null
      return <div className={allowAction ? 'aie' : null} onClick={() => { allowAction && handleShowBigImage(true) }}>
        <Image
          className={cn}
          key={uniqueKey('crdi', _itemKey)}
          avatar={_asAvatar}
          size={imageSize && imageSize !== 'avatar' ? imageSize : 'tiny'}
          src={_trueImageUrl}
        />
        {allowAction && <div>{<Icon name='edit outline' />}</div>}
      </div>
    } else {
      return <DefaultImageIcon {...props} handleShowBigImage={handleShowBigImage} />
    }
  }

  const cardImage = () => {
    if (imageData) { imageSize = 'massive' }
    if (size) { imageSize = size }
    if (showBigImage) {
      return <FullImage src={_trueImageUrl} fullImageProps={fullImageProps} handleClose={handleShowBigImage} handleShowImageUpload={handleShowImageUpload} />
    } else {
      if (transition_image && transition_image.showTransition) {
        return <MotionComponent transition={transition_image} >
          {image(imageSize)}
        </MotionComponent>
      } else {
        return image(imageSize)
      }
    }
  }

  if (showImageUpload) {
    return <UiUpload origin={'SingleImage'} handleCancel={handleCloseUpload} />
  } else {
    return cardImage()
  }

}

export default SingleImage