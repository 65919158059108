import { GoogleAuthProvider, getAuth, sendSignInLinkToEmail, signInWithCustomToken, signInWithEmailAndPassword, signInWithEmailLink, signInWithPhoneNumber, signInWithPopup } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";
import _ from 'lodash';
import { signInResponseTypes, sirts } from "../../cnr/reducers/SignInReducer";
import { _collectionName_appUserValidation, _collectionName_passcodeValidation, _emailSignInStorageItem } from "../../cnr/reducers/ValidationReducer";
import { signInResponseData } from "../../cnr/reducers/reducerHelpers/signInProps";
import { gEnums } from "../../enums/globalEnums";
import { createRefPath } from "../../firestoreData/appData/appRefPaths";
import { fs_dbu } from "../../firestoreData/appData/fsAppDataUpdate";
import { fsfn_notifications } from "../../functions/fbNotifications";
import { currentHelpers } from "../../redirection/current";

const _testing = false
const _useLocalStorage = true

export const firebaseSignIn = {
  customToken: () => signIn_customToken(),
  emailAndPassword: (authCreds) => signIn_emailAndPassword(authCreds),
  withEmailLink: (validatedAppUser, callback) => signIn_withEmailLink(validatedAppUser, callback),
  googleLink: () => signIn_googleLink(),
  passcodeInLink: (res, validationCode, creds, appSignIn, signInEmail, callback, parentCallback) => sendSignIn_passcodeInLink(res, validationCode, creds, appSignIn, signInEmail, callback, parentCallback),
  phoneNumber: (phoneNumber, recaptcha, callback) => signIn_phoneNumber(phoneNumber, recaptcha, callback),
  sendSignInLink: (creds, sharePath, appSignIn, signInEmail, callback, parentCallback) => sendSignIn_link(creds, sharePath, appSignIn, signInEmail, callback, parentCallback),
  sendSignInLinks: (selectedItems, sharePath, appSignIn, signInEmail, callback, parentCallback) => sendSignIn_links(selectedItems, sharePath, appSignIn, signInEmail, callback, parentCallback),
}

/**
 * This will call `signInWithPhoneNumber`, which will send an SMS with a code. The confirmationResult object will be returned.
 * The confirmationResult will have a confirmationResult.confirm object which will accept the confirmation code and finish the auth process.
 * @param {string} phoneNumber 
 * @param {object} recaptcha 
 * @param {function} callback 
 */
const signIn_phoneNumber = (phoneNumber, recaptcha, callback) => {
  const _phoneNumber = _testing ? '+14125552081' : phoneNumber
  const auth = getAuth()
  signInWithPhoneNumber(auth, _phoneNumber, recaptcha)
    .then(function (confirmationResult) {
      const _sirProps = { signInResponseType: signInResponseTypes.phoneCodeSent, confirmationResult }
      callback && callback(signInResponseData(_sirProps))
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
    }).catch(function (error) {
      console.error('sendPhoneCode', error)
      // Error; SMS not sent
      const _sirProps = { signInResponseType: signInResponseTypes.phoneCodeNotSent, phoneNumber, error }
      callback && callback(signInResponseData(_sirProps))
      // ...
    });
}

const signIn_withEmailLink = async (validatedAppUser, callback) => {

  const { _itemKey: email, link: url } = validatedAppUser ?? {}

  if (email) {
    const auth = getAuth()
    try {
      // firebase
      const _emailLink = url ? url : window.location.href
      await signInWithEmailLink(auth, email, _emailLink)
      appUserValidation_update(email, gEnums.emailValidationTypes.activated)
      callback({ success: true, emailValidationStatus: gEnums.emailValidationTypes.activated })
    } catch (error) {
      appUserValidation_update(email, gEnums.emailValidationTypes.activated)
      // window.localStorage.removeItem(_emailSignInStorageItem);
      callback({ success: false, emailValidationStatus: gEnums.emailValidationTypes.denied, error })
    }
  } else {
    callback({ success: false, emailValidationStatus: gEnums.emailValidationTypes.denied, error: { message: 'No email or url' } })
  }
}

const signIn_emailAndPassword = async (authCreds) => {

  const hsir = sirts.handleSignInResponse

  const creds = {
    email: authCreds.email.toLowerCase(),
    password: authCreds.password
  }

  const auth = getAuth()
  return signInWithEmailAndPassword(auth, creds.email, creds.password)
    .then(async (ref) => {
      return ({ type: hsir, signInResponseType: signInResponseTypes.signInSuccess, success: true });
    }).catch((err) => {
      if (err.code) {
        switch (err.code) {
          case 'auth/too-many-requests':
            return ({ type: hsir, signInResponseType: signInResponseTypes.tooManyAttempts, success: false });
          case 'auth/wrong-password':
            return ({ type: hsir, signInResponseType: signInResponseTypes.incorrectPassword, success: false });
          default:
            return ({ type: hsir, signInResponseType: signInResponseTypes.incorrectPassword, success: false });
        }
      } else {
        return ({ type: hsir, signInResponseType: signInResponseTypes.incorrectPassword, success: false });
      }
    })
}

/** Sends a email to the emailAddress.
 * If the recipient clicks on the 'Sign in to ...' link, they will be redirected to the url use below
 * @callback dispatch: type = handleSignInResponse
 */
const signIn_googleLink = async () => {

  const provider = new GoogleAuthProvider();

  const auth = getAuth();

  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      console.log('credential', credential)
      // const token = credential.accessToken;
      // The signed-in user info.
      // const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    }).catch((error) => {
      // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // The email of the user's account used.
      // const email = error.customData.email;
      // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    })
}

const signIn_customToken = async (token, callback) => {

  const hsir = sirts.handleSignInResponse

  const auth = getAuth();

  signInWithCustomToken(auth, token)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      return ({ type: hsir, signInResponseType: signInResponseTypes.signInSuccess, user, success: true });
      // ...
    })
    .catch((error) => {
      // const errorCode = error.code;
      // const errorMessage = error.message;
      return ({ type: hsir, signInResponseType: signInResponseTypes.unknownError, success: false });
      // ...
    });
}

const sendSignIn_passcodeInLink = async (res, validationCode, creds, appSignIn, signInEmail, callback, parentCallback) => {

  // const {  result } = res ?? {}
  // const { customToken } = result ?? {}
  const { email } = creds

  const { logSignInAttemptsToDatabase } = appSignIn ?? {}

  const dispatchSuccessType = signInResponseTypes.emailLinkSent
  const dispatchNonSuccessType = signInResponseTypes.emailLinkNotSent

  const params = {
    isEmailLink: true,
    emailSignInConfirmation: true,
    openInSamePWA: true,
    email,
  }

  if (email) {
    const { signInBody, signInSubject, signInBodyLinkText, pwaAppName } = signInEmail ?? {}
    const notificationData = {
      email: email,
      subject: signInSubject ? signInSubject : 'Sign into the App',
      body: signInBody ? signInBody : 'Click the link to sign into the App. _LINK',
      signInBodyLinkText: signInBodyLinkText ? signInBodyLinkText : null,
      params,
      pwaAppName,
      logSignInAttemptsToDatabase,
      customToken: validationCode
    }

    try {
      const response = await fsfn_notifications.sendCustomTokenEmail(notificationData)
      console.log('response', response)
      const _sirProps = { signInResponseType: dispatchSuccessType, creds }
      callback && callback(signInResponseData(_sirProps), parentCallback)
      return { success: true }
    } catch (error) {
      console.error(error)
      const _sirProps = { signInResponseType: dispatchNonSuccessType, error, creds }
      callback && callback(signInResponseData(_sirProps), parentCallback)
      return { success: false, error }
    }
  } else {
    console.error('No Email')
    const _sirProps = { signInResponseType: dispatchNonSuccessType, creds }
    callback && callback(signInResponseData(_sirProps))
    return { success: false, error: 'No Email' }
  }
}

const sendSignIn_links = (selectedItems, sharePath, appSignIn, signInEmail, callback, parentCallback) => {
  Object.keys(selectedItems).forEach(key => {
    const selectedItem = selectedItems[key]
    const { email } = selectedItem ?? {}
    if (email) {
      sendSignIn_link(email, sharePath, appSignIn, signInEmail, callback, parentCallback)
    }
  })
}

const sendSignIn_link = async (creds, sharePath, appSignIn, signInEmail, callback, parentCallback) => {

  const { email } = creds

  const { useCustomEmailLink, logSignInAttemptsToDatabase } = appSignIn ?? {}

  const dispatchSuccessType = signInResponseTypes.emailLinkSent
  const dispatchNonSuccessType = signInResponseTypes.emailLinkNotSent

  const _sharePath = sharePath // .replace('landing', subAppTypes.emailSignInConfirmation)

  const params = {
    isEmailLink: true,
    emailSignInConfirmation: true,
    openInSamePWA: true,
    email,
  }

  var actionCodeSettings = {
    url: _sharePath,
    handleCodeInApp: true,
    appName: 'TEST'
  };

  if (actionCodeSettings.url) {
    Object.keys(params).forEach(paramName => {
      actionCodeSettings.url += `${actionCodeSettings.url.includes('?') ? '&' : '?'}${paramName}=${params[paramName]}`;
    });
  }

  if (email) {
    if (useCustomEmailLink) {
      const { signInBody, signInSubject, signInBodyLinkText, pwaAppName } = signInEmail ?? {}
      const notificationData = {
        email: email,
        subject: signInSubject ? signInSubject : 'Sign into the App',
        sharePath: _sharePath,
        body: signInBody ? signInBody : 'Click the link to sign into the App. _LINK',
        signInBodyLinkText: signInBodyLinkText ? signInBodyLinkText : null,
        params,
        pwaAppName,
        logSignInAttemptsToDatabase
      }
      try {
        const response = await fsfn_notifications.sendLinkEmail(notificationData)
        if (_useLocalStorage) {
          currentHelpers.storageItem_set(_emailSignInStorageItem, email)
        }
        await appUserValidation_update(email, gEnums.emailValidationTypes.requested, response)
        const _sirProps = { signInResponseType: dispatchSuccessType, creds }
        callback && callback(signInResponseData(_sirProps, parentCallback))
        return { success: true }
      } catch (error) {
        console.error(error)
        const _sirProps = { signInResponseType: dispatchNonSuccessType, error, creds }
        callback && callback(signInResponseData(_sirProps), parentCallback)
        return { success: false, error }
      }
    } else {
      try {
        const auth = getAuth()
        console.log('actionCodeSettings', actionCodeSettings)
        // firebase
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
        if (_useLocalStorage) { currentHelpers.storageItem_set(_emailSignInStorageItem, email) }
        await appUserValidation_update(email, gEnums.emailValidationTypes.requestedNonLink)
        const _sirProps = { signInResponseType: dispatchSuccessType, creds }
        callback && callback(signInResponseData(_sirProps), parentCallback)
        return { success: true }
      } catch (error) {
        console.error(error)
        const _sirProps = { signInResponseType: dispatchNonSuccessType, error, creds }
        callback && callback(signInResponseData(_sirProps), parentCallback)
        return { success: false, error }
      }
    }
  } else {
    console.error('No Email')
    const _sirProps = { signInResponseType: dispatchNonSuccessType, creds }
    callback && callback(signInResponseData(_sirProps))
    return { success: false, error: 'No Email' }
  }
}

export const appUserValidation_update = async (email, status, response) => {

  const _refPath = createRefPath([_collectionName_appUserValidation, email])
  const _ts = serverTimestamp()

  let dataToUpdate;
  let dataToSet;

  switch (status) {
    case gEnums.emailValidationTypes.requested:
      if (response) {
        const { result } = response ?? {}
        const { link } = result ?? {}
        var urlParams = new URLSearchParams(link);
        var oobCode = _.get(Object.fromEntries(urlParams), 'oobCode');
        dataToSet = { email, status, oobCode, link, pendingTimestamp: _ts }
      }
      break;
    case gEnums.emailValidationTypes.requestedNonLink:
      dataToSet = { email, status, pendingTimestamp: _ts }
      break;
    case gEnums.emailValidationTypes.accepted:
      dataToUpdate = { status, acceptedTimestamp: _ts }
      break;
    case gEnums.emailValidationTypes.denied:
      dataToUpdate = { status, deniedTimestamp: _ts }
      break;
    default:
      dataToUpdate = { status }
    // nothing
  }
  if (dataToSet) {
    await fs_dbu.set_doc(_refPath, dataToSet)
  } else if (dataToUpdate) {
    await fs_dbu.update_doc(_refPath, dataToUpdate)
  }
}


/**
 * updates `_appUserPasscodeValidation` with the validationCode and customToken
 * @param {*} creds 
 * @param {*} res 
 * @param {*} validationCode 
 * @param {*} callback 
 */
export const appUserPasscodeValidation_update = async (creds, res, validationCode, callback) => {
  const { email } = creds ?? {}
  const { success, result } = res ?? {}
  const { customToken } = result ?? {}
  const _refPath = createRefPath([_collectionName_passcodeValidation, email])
  const dataToSet = { success: success, validationCode, customToken, acceptedTimestamp: serverTimestamp() }
  await fs_dbu.set_doc(_refPath, dataToSet)
  callback(res)
}