import _ from 'lodash';

const rts = {
  handleAmmendDdGroups: 'handleAmmendDdGroups',
  handleSetElementGroups: 'handleSetElementGroups',
  handleUpdateGroupItemChange: 'handleUpdateGroupItemChange',
}

export const settingsAreaMenuReducer = (state, action) => {

  const {
    dndGroups,
    menuElementGroups,
  } = state

  const { type } = action

  switch (type) {

    case rts.handleSetElementGroups:
      // IMPORTANT: Settings - handleSetElementGroups
      return { ...state, menuItemElements: action.menuItemElements, menuElementGroups: action.menuElementGroups, appArea: action.appArea }

    case rts.handleAmmendDdGroups:
      if (menuElementGroups && dndGroups) {
        Object.keys(dndGroups).forEach(ddgKey => {
          if (menuElementGroups[ddgKey]) {
            dndGroups[ddgKey].elements = menuElementGroups[ddgKey].menuItemElements
          }
        })
      }
      return {
        ...state,
        dndGroups: dndGroups
      }

    case rts.handleUpdateGroupItemChange:
      const { ddg } = action
      const dnd_groups = { ...dndGroups }

      // sort the dataGroup's dataItems into their correct place
      Object.keys(ddg).forEach(key => {
        const { dataItems } = ddg[key]
        if (dnd_groups[key]) {
          dnd_groups[key].dataItems = dataItems
        }
      })

      Object.keys(dnd_groups).forEach(key => {
        const dndGroup = dnd_groups[key]
        const { dataItems, elements } = dndGroup
        const _elements = []
        dataItems.forEach(dataItem => {
          const { key } = dataItem
          const element = _.find(elements, { key: 'mig-gi-' + key })
          _elements.push(element)
        })
        dndGroup.elements = _elements
      })
      return { ...state, dndGroups: dnd_groups }

    default:
      return { ...state }
  }
}

export const settingsAreaMenuInitialState = (init_state) => {
  return { ...init_state }
};

export const settingsAreaMenuHandlers = (dispatch, state) => {
  return {
    handleAmmendDdGroups: () => { dispatch({ type: rts.handleAmmendDdGroups, dispatch }) },
    handleSetElementGroups: (menuItemElements, menuElementGroups, appArea) => { dispatch({ type: rts.handleSetElementGroups, dispatch, menuItemElements, menuElementGroups, appArea }) },
    handleUpdateGroupItemChange: (ddg) => { dispatch({ type: rts.handleUpdateGroupItemChange, dispatch, ddg }) },
  }
}