import _ from 'lodash'
import { getFirstObjectKey } from "../../common/convert"
import { getSwiperContentInit, handleSwipeChange } from './reducerHelpers/swipeHelpers'

const rts = {
  handleSet_swiperContent: 'handleSet_swiperContent',
  handleSet_tabIndex: 'handleSet_tabIndex',
  handleSet_tabIndexByKey: 'handleSet_tabIndexByKey',
  handleSwipe_changeIndex: 'handleSwipe_changeIndex',
  handleInit_swiperContent: 'handleInit_swiperContent',
  handleAmmend_swiperItems: 'handleAmmend_swiperItems',
}

export const swiperReducer = (state, action) => {

  const { swiperContent } = state
  const { swiperTabs } = swiperContent ?? {}
  const { type, dispatch } = action

  const swipe_handlers = swiperHandlers(dispatch)

  const { handleSet_swiperContent } = swipe_handlers

  switch (type) {

    case rts.handleInit_swiperContent:
      getSwiperContentInit(state, action.gscProps, handleSet_swiperContent)
      return { ...state, view: action.view }

    case rts.handleSet_swiperContent:
      return { ...state, swiperContent: action.swiperContent, ...action.gscProps }

    case rts.handleAmmend_swiperItems:
      const _swiperContent = { ...swiperContent }
      _swiperContent.swiperItems = action.swiperItems
      return { ...state, swiperContent: _swiperContent, tabView: action.tvw }

    case rts.handleSwipe_changeIndex:
      handleSwipeChange(state, swipe_handlers, action.index)
      const swiperTab = swiperTabs ? swiperTabs[action.index] : {}
      const { key } = swiperTab ?? {}
      return { ...state, currentSwipeIndex: action.index, currentSwipeKey: key }

    case rts.handleSet_tabIndex:
      return { ...state, tabIndex: action.tabIndex }

    case rts.handleSet_tabIndexByKey:
      if (swiperTabs) {
        const st = _.pickBy(swiperTabs, { key: action.key })
        if (st) {
          const xx = getFirstObjectKey(st)
          return { ...state, tabIndex: xx }
        }
      }
      return { ...state }

    default:
      return { ...state }
  }
}

export const swiperInitialState = (init_state) => {
  return { ...init_state }
};

export const swiperHandlers = (dispatch, state) => {
  return {
    handleAmmend_swiperItems: (swiperItems, tvw, loadSwipeItemData) => { dispatch({ type: rts.handleAmmend_swiperItems, dispatch, swiperItems, tvw, loadSwipeItemData }) },
    handleInit_swiperContent: (gscProps) => { dispatch({ type: rts.handleInit_swiperContent, dispatch, gscProps }) },
    handleSet_swiperContent: (swiperContent, gscProps) => { dispatch({ type: rts.handleSet_swiperContent, dispatch, swiperContent, gscProps }) },
    handleSet_tabIndex: (tabIndex) => { dispatch({ type: rts.handleSet_tabIndex, dispatch, tabIndex }) },
    handleSet_tabIndexByKey: (key) => { dispatch({ type: rts.handleSet_tabIndexByKey, dispatch, key }) },
    handleSwipe_changeIndex: (index) => { dispatch({ type: rts.handleSwipe_changeIndex, dispatch, index }) },
  }
}