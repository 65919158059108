import React, { createContext, useEffect, useState } from 'react';
import { currentHelpers } from '../../redirection/current';
import { gEnums } from '../../enums/globalEnums';

/** 
 * @parentContexts [StartContext, AuthContext, AppSettingContext] 
 * @provides paps_state, paps_handlers, navigate
 * @description Contains information about the current page location (url, path, etc.) 
 * */
export const SearchParamContext = createContext();

/** 
 * @parentContexts [StartContext, AuthContext, AppSettingContext] 
 * @provides paps_state, paps_handlers, navigate
 * @description Contains information about the current page location (url, path, etc.) 
 * */
const SearchParamProvider = (props) => {

  const hp = currentHelpers.getHashPath()
  const { queryValues } = hp ?? {}
  const { appViewMode } = queryValues ?? {}

  const [searchParam_state, set_searchParam_state] = useState({})

  useEffect(() => {
    if (appViewMode) {
      set_searchParam_state({ appViewMode, isGoogleSheetMode: appViewMode === gEnums.appViewModes.googleSheets })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appViewMode]);


  const providerContext = () => <SearchParamContext.Provider value={{ searchParam_state }}>
    {props.children}
  </SearchParamContext.Provider>

  return providerContext()
}

/** 
 * @provides paps_state, paps_handlers, navigate
 */
export default SearchParamProvider