import React from 'react'
import { Icon, Label, Popup, Segment } from 'semantic-ui-react'
import _ from 'lodash'

const _size = 'small'

const ScheduleLegend = (props) => {

  const content = () => <Segment basic className='schedule-legend' inverted>
    <Label size={_size}>{'Away Win'}</Label>
    <Label size={_size}>{'Home Win'}</Label>
    <Label size={_size}>{'Missing Score'}</Label>
    <Label size={_size}>{'Pending Score'}</Label>
    <Label size={_size}>{'Times are subject to change'}</Label>
  </Segment>

  return content()
}

export default ScheduleLegend
