import React from 'react'
import { Icon, Label, Segment } from 'semantic-ui-react'
import { _sectionNames, getSectionColor } from '../../create/sectionColors'
import _ from 'lodash'
import { uniqueKey } from '../../../../global/common/keys'

const SectionsLegend = () => {

  const labels = () => {
    return Object.keys(_sectionNames).map((sn, index) => {
      const sectionColor = getSectionColor(sn)
      return <Label key={uniqueKey('leg-sec', index)} style={{ borderLeft: '2px solid var(--sem-' + sectionColor }} >{_.startCase(sn)}</Label>
    })
  }

  const locationLabel = (value, color, index) => <Label key={uniqueKey('leg-sec', 'loc', index)}>
    <Icon color={color} name='map marker alternate' />{value}
  </Label>

  const updateLabel = (value, color) => <Label key={uniqueKey('leg-sec', 'up')} className='legend-team' >
    {value}
  </Label>

  return (
    <Segment basic className='sections-legend'>
      {locationLabel('location finalized', 'blue', 1)}
      {locationLabel('location not finalized', 'yellow', 2)}
      {updateLabel('Upset')}
      {labels()}
    </Segment>
  )
}

export default SectionsLegend
