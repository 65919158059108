import { deleteField } from 'firebase/firestore';
import _ from 'lodash';
import { createFsDocKey } from '../../../global/firestoreData/appData/fsAppData';
import { matchUpdateTypes } from '../cnr/reducers/SportsSeasonReducer';
import { seasonalHelpers, seasonalUpdateTypes } from '../cnr/reducers/reducerHelpers/seasonalSportsData';
import { sportsHelpers } from '../cnr/reducers/reducerHelpers/sportsHelpers';
import { realTimeScoring } from './realtimeScoring';
import { realtimeDocumentTypes } from '../cnr/reducers/SportsEditMatchReducer';
import { _matchResultTypes } from '../cnr/reducers/SportsMatchReducer';

const _allowPendingMatchUpdates = true

export const updateTheMatch = async (matchProps) => {

  const {
    actionProps,
    appUserInfo,
    callback,
    latestSeason,
    matchResultType,
    matchUpdateType,
    pathViews,
    sportData,
    sportPermissions,
    sportsKey,
  } = matchProps

  const { match_true, match_edit, match_rt, sortedAthletes, playoffMatches } = actionProps ?? {}
  const { useRtGameDayData } = sportData
  let _useRtGameDayData

  let _match_current;

  switch (matchResultType) {
    case _matchResultTypes.realtime:
      _match_current = match_rt
      break;
    case _matchResultTypes.matchDay:
      _match_current = match_edit
      break;
    case _matchResultTypes.normal:
      _match_current = match_edit
      break;
    default:
    // nothing
  }

  const { _itemKey: _matchKey, _resultsStatus, isPlayoff, startDate, startTime, location, home, away, _matchCollectionKey } = _match_current ?? {}
  const { resultResults } = _resultsStatus ?? {}
  const { score, scores, winner, loser } = resultResults ?? {}

  let _collectionName = isPlayoff ? 'playoffMatches' : 'playoffMatches'
  let _dataType = isPlayoff ? 'playoffMatches' : 'matches'
  let _collectionName_scores = isPlayoff ? 'playoffScores' : 'scores'
  let _dataType_scores = isPlayoff ? 'playoffScores' : 'scores'

  let _updateScoreCollection;

  const pks = seasonalHelpers.getPks(pathViews, latestSeason, sportsKey, ['teams'])

  const matchData = {}
  const matchData_scores = {}

  if (_match_current && _matchKey) {

    const _mk = _dataType + '.' + _matchKey
    const _mk_scores = _dataType_scores + '.' + _matchKey

    if (!_match_current.teams && home.id && away.id) {
      matchData[_mk + '.teams'] = [home.id, away.id];
    }

    // depending of the matchUpdateType, modify the corresponding prop...

    switch (matchUpdateType) {
      case matchUpdateTypes.deleteRealtimeMatch:
        // do nothing here
        break;

      case matchUpdateTypes.deleteMatch:
        matchData[_mk] = deleteField();
        break;

      case matchUpdateTypes.deleteScore:
        matchData[_mk + '.results'] = deleteField();
        break;

      case matchUpdateTypes.postponeMatch:
        matchData[_mk + '.postponed'] = true;
        matchData[_mk + '.cancelled'] = deleteField();
        break;

      case matchUpdateTypes.cancelMatch:
        matchData[_mk + '.cancelled'] = true;
        matchData[_mk + '.postponed'] = deleteField();
        break;

      case matchUpdateTypes.resetByeMatch:
        const { place, poolName, matchNumber } = _match_current.pending ?? {}
        const byeName = poolName + ' #' + matchNumber + '/' + place
        console.log('byeName', byeName)
        matchData[_mk + '.away'] = { id: _match_current.seeds.away, name: byeName };
        break;

      case matchUpdateTypes.updateMatchDetails:
        matchData[_mk + '.location'] = location;
        matchData[_mk + '.startDate'] = startDate;
        matchData[_mk + '.startTime'] = startTime;
        matchData[_mk + '.postponed'] = deleteField();
        matchData[_mk + '.cancelled'] = deleteField();
        break;

      case matchUpdateTypes.updateMatchDayMatch:
        if (score || scores) {
          const res = {}

          if (scores) {
            if (_.isArray(scores)) {
              res.scores = _.compact(scores);
            } else {
              res.scores = scores;
            }
          }
          if (score) { res.score = score; }
          matchData[_mk + '.results'] = res;
          matchData_scores[_mk_scores] = { ...res };
        }
        break;

      case matchUpdateTypes.updateScore:

        _useRtGameDayData = useRtGameDayData

        if (score || scores) {

          const res = {}

          if (scores) {
            if (_.isArray(scores)) {
              res.scores = _.compact(scores);
            } else {
              res.scores = scores;
            }
          }
          if (score) { res.score = score; }

          let players;

          if (sortedAthletes) {
            players = {}
            sortedAthletes.forEach((sa, index) => {
              players[sa.key] = {
                firstName: sa.firstName,
                lastName: sa.lastName,
                position: index
              }
            })
            res.players = players
          }

          matchData[_mk + '.results'] = res;
          matchData_scores[_mk_scores] = { ...res };
          _updateScoreCollection = true
        }
        break;

      default:
        break;
    }

    const opts = { subDocumentKey: _matchCollectionKey }

    // if useRtGameDayData (in settings) is true, only the realtime (matchDay) database will be updated

    if (_useRtGameDayData) {
      const matchData_rt = {}
      if (scores) { matchData_rt.scores = scores; }
      if (score) { matchData_rt.score = score; }
      realTimeScoring.updateRealtimeScoreToDatabase(realtimeDocumentTypes.matchDay, sportPermissions, sportData, seasonalUpdateTypes.matches, pathViews, _matchKey, matchData_rt, appUserInfo, latestSeason, callback)
    } else {
      switch (matchUpdateType) {
        case matchUpdateTypes.deleteRealtimeMatch:
          realTimeScoring.deleteRealtimeDatabaseScores(sportPermissions, sportData, seasonalUpdateTypes.matches, pathViews, [_matchKey], appUserInfo, latestSeason)
          break;

        default:
          const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, pks, null, _collectionName, null, matchData, opts)

          await seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.matches, _updateProps)

          if (_updateScoreCollection) {
            const _updateProps2 = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, pks, null, _collectionName_scores, null, matchData_scores, opts)
            await seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.matches, _updateProps2)
          }

          if (match_rt && sportPermissions.any && sportPermissions.matches) {
            // await deleteRealtimeDatabaseScores(pathViews, _matchKey, appUserInfo, latestSeason)
          }

          if (sportPermissions.any && sportPermissions.matches && isPlayoff && playoffMatches && matchUpdateType === matchUpdateTypes.updateScore) {
            updatePendingMatch(sportPermissions, pathViews, latestSeason, sportsKey, null, _match_current, playoffMatches, winner, loser, _collectionName, callback)
          }
      }
    }

    // callback(matchProps)

  }
}

export const updateCurrentRealtimeMatchScoreToRtDb = async (sportPermissions, sportData, pathViews, actionProps, appUserInfo, latestSeason, callback) => {

  const { useRtMatchData } = sportData ?? {}
  const { match_rt, match_true } = actionProps ?? {}
  const { score, scores, complete, winner, loser, setNumber, setScorez } = match_rt ?? {}
  const { _itemKey: match_key } = match_true ?? {}

  if (match_rt && match_key && (score || scores)) {

    const res = {}
    if (complete) { res.complete = true; }
    if (scores) { res.scores = scores; }
    if (score) { res.score = score; }
    if (winner) { res.winner = winner; }
    if (loser) { res.loser = loser; }
    if (setNumber) { res.setNumber = setNumber; }
    if (useRtMatchData) {
      realTimeScoring.updateRealtimeScoreToDatabase(realtimeDocumentTypes.realtime, sportPermissions, sportData, seasonalUpdateTypes.matches, pathViews, match_key, res, appUserInfo, latestSeason, callback)
    } else {
      console.log('updateCurrentRealtimeMatchScore not permitted', sportData, res)
    }
  }
}


/**
 * Updates the next match of the team updated
 * @param {object} pathViews 
 * @param {string} latestSeason 
 * @param {string} dataType 
 * @param {object} match_edit 
 * @param {object} playoffMatches 
 * @param {object} winner 
 * @param {string} altSeasonalName 
 * @param {func} callback 
 */
const updatePendingMatch = async (sportPermissions, pathViews, latestSeason, sportsKey, documentFieldName, match_edit, playoffMatches, winner, loser, altSeasonalName, callback) => {

  const pks = seasonalHelpers.getPks(pathViews, latestSeason, sportsKey)

  const pendings = {
    levelMatchUpdates: {},
    matchWinners: {},
    matchLosers: {}
  }

  const { _matchCollectionKey } = sportsHelpers.getPendingMatchInfo(match_edit, playoffMatches, pendings)

  const opts = { subDocumentKey: _matchCollectionKey }

  if (_allowPendingMatchUpdates) {
    const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, pks, callback, altSeasonalName, null, pendings.levelMatchUpdates, opts)
    seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.matches, _updateProps)
  } else {
    console.log('UPDATE THE PENDING MATCH')
    console.log('pks', pks)
    console.log('match_edit', match_edit)
    console.log('pendings', pendings)
    console.log('*****')
  }
}

/**
 * 
 * @param {object} match 
 * @param {string} teamType 
 * @param {string} oppType 
 * @param {object} playoffMatches 
 * @param {string} levels 
 * @param {string} winner 
 * @param {string} data 
 */
export const getMatchData = (match, teamType, oppType, playoffMatches, levels, winner, data) => {
  const { _itemKey } = match ?? {}
  let _playoffMatch = playoffMatches[_itemKey]
  if (!_playoffMatch) {
    _playoffMatch = _.find(playoffMatches, { _itemKey: _itemKey })
  }
  if (_playoffMatch) {
    const opponent = getOpponent(levels, playoffMatches, _playoffMatch, oppType)
    data['matches.' + _itemKey + '.' + teamType] = winner;
    if (opponent) { data['matches.' + _itemKey + '.' + oppType] = opponent; }
  }
}

/**
 * 
 * @param {object} levels 
 * @param {object} playoffMatches 
 * @param {string} homeOrAway 
 * @param {string} poolKey 
 * @param {number} originalSeed 
 * @returns the pending match
 * @description filters the playoff matches for a match on the `poolKey` and the `originalSeed`
 */
const getPendingMatch = (playoffMatches, levels, homeOrAway, poolKey, originalSeed) => _.filter(playoffMatches, function (pm) {
  const { pending, results } = pm
  return levels === pm.levels && pending &&
    (
      pending[homeOrAway].poolKey === poolKey &&
      (pending[homeOrAway].originalSeed === originalSeed)
    );
})

const getPendingMatches = (playoffMatches, levels, poolKey) => _.filter(playoffMatches, function (pm) {
  const { pending } = pm
  return pm && levels === pm.levels && pending && pending.away && pending.home && (pending.away.poolKey === poolKey || pending.home.poolKey === poolKey);
})

const getOriginalMatch = (levels, playoffMatches, originalSeed) => _.filter(playoffMatches, function (pm) {
  const { home } = pm
  return levels === pm.levels && home && (
    home.seed === originalSeed);
})

const getOpponent = (levels, playoffMatches, playoffMatch, homeOrAway) => {
  const { pending: _pending } = playoffMatch
  const pendingTeam = _pending[homeOrAway]
  const { originalSeed } = pendingTeam
  const originalMatch = getOriginalMatch(levels, playoffMatches, originalSeed)
  if (originalMatch && originalMatch.length === 1) {
    return originalMatch[0][homeOrAway]
  }
}

export const createNewMatch = async (sportPermissions, pathViews, latestSeason, sportsKey, actionProps, callback) => {

  const { match_edit } = actionProps ?? {}
  const { isPlayoff } = match_edit ?? {}

  const pks = seasonalHelpers.getPks(pathViews, latestSeason, sportsKey)

  const _matches = isPlayoff ? 'playoffMatches' : 'matches'
  const altSeasonalName = isPlayoff ? '_playoffs' : null

  if (match_edit) {
    const matchKey = createFsDocKey('newMatch')
    const data = {}
    data[_matches + '.' + matchKey] = match_edit;
    const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, pks, callback, 'matches', null, data)
    seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.matches, _updateProps, 'matches', null, data, callback)
  } else {
    console.log(match_edit)
  }
}

