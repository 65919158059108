import _ from 'lodash'
import { fileStatusTypes } from '../cnr/reducers/UploadReducer'
import { getHeadersFromPropSections } from '../components/headers/getHeaders'
import { Quill, } from 'react-quill'
import { propHelpers } from './tester'
import { dateHelpers } from './dateFormatting'

export const convertHelpers = {
  addNameFromKey: (items, staticView) => addNameFromKey(items, staticView),
  createItemKeys: (items, altKey, useStartCase) => createItemKeys(items, altKey, useStartCase),
  createKeyedObject: (items) => createKeyedObject(items),
  createObjectFromArray: (items, additionalItems) => createObjectFromArray(items, additionalItems),
  getFirstObject: (data, objectIndex) => getFirstObject(data, objectIndex),
  getFirstObjectKey: (data) => getFirstObjectKey(data),
  getItemName: (item, useComma, noNames) => getItemName(item, useComma, noNames),
  getLastObject: (data) => getLastObject(data),
  getMerged: (object1, object2, mergeProp, defaultProps, selectedKey, otherProps) => getMerged(object1, object2, mergeProp, defaultProps, selectedKey, otherProps),
  sortItemsByName: (items) => sortItemsByName(items),
}

const addNameFromKey = (items, staticView) => {
  _.forEach(items, (item, key) => {
    if (staticView[key]) {
      const staticItem = staticView[key]
      const { name, firstName, lastName } = staticItem ?? {}
      const _name = firstName && lastName ? firstName + ' ' + lastName : name
      item.name = _name
    }
  })
}

const getMerged = (object1, object2, mergeProp, defaultProps, selectedKey, otherProps) => {

  const firstPicks = otherProps ? _.map(object1, item => _.pick(item, ['name', 'firstName', 'lastName', '_itemKey', ...otherProps])) : _.map(object1, item => _.pick(item, ['name', 'firstName', 'lastName', '_itemKey']));
  const firstKeyed = _.keyBy(firstPicks, '_itemKey');

  const secondPicks = _.map(object2, item => _.pick(item, [mergeProp, '_itemKey']));
  const secondKeyd = _.keyBy(secondPicks, '_itemKey');

  const merged = _.merge({}, firstKeyed, secondKeyd)

  if (defaultProps) {
    _.forEach(merged, (m) => {
      _.forEach(defaultProps, (dp) => {
        switch (dp) {
          case 'selected':
            if (selectedKey) {
              const sv = m[mergeProp]
              const _isSelected = sv && sv.includes(selectedKey) ? true : false
              m[dp] = _isSelected
            } else {
              m[dp] = false
            }
            break;
          default:
            m[dp] = false
        }
      })
    })
  }

  dateHelpers.ammendDates(merged)

  return merged
}

export function hasValue(value) {
  if (!_.isEmpty(value) || _.isBoolean(value)) {
    return true
  }
}

export const ammendUiSvValue = (itemData, key) => {
  const value = itemData[key]
  if (itemData && itemData._sv && itemData._sv[key]) {
    return itemData._sv[key]
  }
  return value
}

/**
 * 
 * @param {*} value 
 * @param {string} key 
 * @returns value
 */
export const ammendUiValue = (value, key) => {
  return value
}

const getAdditional = (item, stsps) => {
  const _additional = {}
  if (stsps && stsps.length > 0) {
    stsps.forEach(stsp => {
      if (item[stsp]) {
        _additional[stsp] = item[stsp]
      }
    })
  }
  return _additional
}

export const getStaticItems = (items, dataFieldName, stsps) => {
  const _items = {}
  items.forEach(item => {
    const { _itemKey, firstName, lastName, date, startDate } = item ?? {}
    let { name } = item ?? {}
    if (!name && (firstName && lastName)) {
      name = firstName + ' ' + lastName
      item.name = name
    }
    const _name = dataFieldName ? item[dataFieldName] : getItemName(item)
    if (_name && _itemKey) {
      const _additional = getAdditional(item, stsps)
      _items[_itemKey] = { name: _name, ..._additional }
      if (firstName) { _items[_itemKey].firstName = firstName }
      if (lastName) { _items[_itemKey].lastName = lastName }
      if (date) { _items[_itemKey].date = date }
      if (startDate) { _items[_itemKey].startDate = startDate }
    }
  })
  return _items
}

/**
 * returns the name or firstName and lastName
 * @param {object} item 
 * @returns 
 */
const getItemName = (item, useComma, noNames) => {
  const { name, firstName, lastName, displayName } = item ?? {}
  if (name || firstName || lastName) {
    if (firstName && lastName) {
      if (useComma) {
        return lastName + ', ' + firstName
      } else {
        return firstName + ' ' + lastName
      }
    } else {
      return name
    }
  } else if (displayName) {
    return displayName
  } else {
    noNames && noNames.push(item)
    return '---'
  }
}

const sortItemsByName = (items) => {

  const getSortKey = (item) => {
    if (item.firstName && item.lastName) {
      // If item has firstName and lastName, sort by lastName then firstName
      return [item.lastName, item.firstName];
    } else {
      // Otherwise, sort by name
      return item.name;
    }
  };

  const sortedItems = _.sortBy(items, (item) => getSortKey(item));

  return sortedItems

}

/**
 * 
 * @param {object} data 
 * @returns the key of the first object
 */
export const getFirstObjectKey = (data) => data ? Object.keys(data)[0] : null

/**
 * 
 * @param {object} data 
 * @returns the first object and fk
 */
export const getFirstObject = (data, objectIndex) => {
  const fk = Object.keys(data)[objectIndex ? objectIndex : 0]
  const item = data ? data[fk] : {}
  return { item, fk }
}

export const getLastObject = (data) => {
  const fk = Object.keys(data)[Object.keys(data).length - 1]
  const item = data ? data[fk] : {}
  return { item, fk }
}

export const getObjectLength = (data) => {
  if (data) {
    if (_.isArray(data)) {
      return data.length
    } else {
      return Object.keys(data).length
    }
  } else {
    return 0
  }
}

/**
 * 
 * @param {object} data 
 * @returns the first object with data
 */
export const getFirstKeyedObject = (data) => {
  const fk = Object.keys(data)[0]
  const fd = data ? data[fk] : {}
  return { [fk]: fd }
}

/**
 * 
 * @param {object} data 
 * @param {number} index 
 * @returns the indexed object with data
 */
export const getObjectByIndex = (data, index) => {
  const fk = Object.keys(data)[index]
  const fd = data ? data[fk] : {}
  return { [fk]: fd }
}

export const getNthObject = (data, index) => {
  return data ? data[Object.keys(data)[index]] : null
}

/**
 * adds an _itemKey for each item in the items object
 * @param {object} items 
 */
const createItemKeys = (items, altKey, useStartCase) => {
  if (items) {
    _.forEach(items, (item, key) => {
      if (_.isObject(item)) {
        item[altKey ? altKey : '_itemKey'] = useStartCase ? _.startCase(key) : key
      }
    })
  }
}

export const removeItemKeys = (items, altKey, useStartCase) => {
  if (items) {
    Object.keys(items).forEach(key => {
      if (_.isObject(items[key])) {
        delete items[key][altKey ? altKey : '_itemKey']
      }
    })
  }
}

export const createItemPropValues = (items, propName, value) => {
  if (items) {
    Object.keys(items).forEach(key => {
      items[key][propName] = value
    })
  }
}

const createObjectFromArray = (items, additionalItems) => {
  const fullO = {}
  if (_.isArray(items)) {
    items.forEach((item, index) => {
      const { _itemKey } = item
      fullO[_itemKey ? _itemKey : index] = item
      if (additionalItems) {
        _.forEach(additionalItems, (additionalItem, key) => {
          fullO[_itemKey][key] = additionalItem
        })
      }
    })
  }
  return fullO
}

const createKeyedObject = (items) => {
  const fullO = {}
  if (_.isArray(items)) {
    items.forEach((item, index) => {
      fullO[index] = item
      fullO[index].id = index
      fullO[index]._itemKey = index
    })
  } else {
    if (_.isObject(items)) {
      Object.keys(items).forEach((key, index) => {
        const item = items[key]
        fullO[key] = item
        fullO[key].id = key
        fullO[key]._itemKey = key
      })
      // return items
    }
  }
  return fullO
}

/**
 * adds a `_sv` prop to the data items with the value of the corresponding s taticView value
 * @param {*} staticViews 
 * @param {*} dataItems 
 */
export const ammendDataWithStaticViewValues = (staticViews, dataItems) => {
  if (staticViews && dataItems) {
    _.forEach(dataItems, (dataItem, key) => {
      if (dataItem) {
        _.forEach(dataItem, (propValue, propKey) => {
          if (staticViews[propKey] && staticViews[propKey][propValue]) {
            if (!dataItem._sv) { dataItem._sv = {} }
            dataItem._sv[propKey] = getItemName(staticViews[propKey][propValue])
          }
        })
      }
    })
  }
}

export const getSplitStaticName = (staticViews, staticView, staticValue) => {

  const sc = _.startCase(staticView)
  const scs = sc.split(' ')
  let v = staticValue;

  if (_.isArray(staticValue)) {
    let _sv = []
    staticValue.forEach((sv, index) => {
      _sv.push(getStaticName(staticViews, staticView, sv))
    })
    v = _sv
  } else {
    v = getStaticName(staticViews, staticView, staticValue)
    if (v === staticValue) {
      scs.forEach(spl => {
        const _staticView = spl.trim().toLowerCase()
        v = getStaticName(staticViews, _staticView, staticValue)
      })
    }
  }

  return v
}

const findStaticKey = (sks, itemData, staticView) => {
  const matchingItemKey = _.findKey(staticView, sv =>
    _.every(sks, sk => itemData[sk] === undefined || sv[sk] === itemData[sk])
  );
  return matchingItemKey ? matchingItemKey : null;
};


/**
 * 
 * @param {object} staticViews 
 * @param {string} staticView 
 * @param {object} itemData 
 * @param {boolean} allowGuests 
 * @returns a `staticKey`
 */
export const getStaticKey = (staticViews, staticView, itemData, allowGuests, aps_viewItems) => {

  let staticKey;
  let _staticView = staticView

  // if (allowGuests && staticView === 'guests') { _staticView = 'attendees' }
  if (staticViews && staticViews[_staticView]) {

    const sv = staticViews[_staticView]

    const vi = aps_viewItems ? aps_viewItems[_staticView] : {}
    const { props: props_vi, dataConnection } = vi ?? {}

    const sks = dataConnection && dataConnection.uniqueProps ? dataConnection.uniqueProps : ['name']

    let useSks = false

    if (sks && sks.length > 0) {
      useSks = true
    }

    const { startDate: startDate_props } = props_vi ? props_vi : {}
    const { data: data_sd } = startDate_props ? startDate_props : {}
    const { saveToStatic: saveToStatic_sd } = data_sd ? data_sd : {}

    if (sv) {
      if (useSks) {
        const x = findStaticKey(sks, itemData, sv)
        return x
      } else {
        const { name, firstName, lastName, startDate, date } = itemData

        if (startDate && name && saveToStatic_sd) {
          staticKey = _.findKey(sv, function (ei) {
            return (ei.name === name && Date.parse(ei.startDate) === Date.parse(startDate));
          })
        } else if (date) {
          staticKey = _.findKey(sv, function (ei) {
            return (ei.name === name && Date.parse(ei.date) === Date.parse(date));
          })
        } else if (firstName && lastName) {
          staticKey = _.findKey(sv, function (ei) {
            const fullName = firstName + ' ' + lastName
            return ((ei.firstName && ei.firstName.toLowerCase() === firstName.toLowerCase() && ei.lastName && ei.lastName.toLowerCase() === lastName.toLowerCase()) || (ei.name && ei.name.toLowerCase() === fullName.toLowerCase()));
          })
        } else if (name) {
          staticKey = _.findKey(sv, function (ei) {
            return (ei.name === name);
          })
        }
      }
      getItemName(itemData)

      return staticKey
    }
  }
}

/**
 * 
 * @param {object} staticViews 
 * @param {string} staticView 
 * @param {string} staticValue 
 * @param {boolean} returnFound 
 * @param {boolean} allowGuests 
 * @returns a `string`
 */
export const getStaticName = (staticViews, staticView, staticValue, returnFound, allowGuests) => {
  let _staticView = staticView
  if (allowGuests) {
    switch (staticView) {
      case 'guests':
      case 'familyAttendees':
        _staticView = 'attendees'
        break;
      default:
      // nothing
    }
  }
  if (staticViews && staticViews[_staticView] && staticViews[_staticView][staticValue]) {
    const svv = staticViews[_staticView][staticValue]
    if (returnFound) {
      return { sv: getItemName(svv) }
    } else {
      return getItemName(svv)
    }
  } else {
    if (returnFound) {
      return { sv: staticValue, notFound: true }
    } else {
      return staticValue
    }
  }
}

/**
 * 
 * @param {object} dataParents 
 * @param {object} pathViews 
 * @param {obj3dt} parentKeys_pageData 
 * @param {*} view 
 * @param {*} viewKey 
 * @returns parentKey
 * @deprecated
 */
export const updateParentKeys = (dataParents, pathViews, parentKeys_pageData, view, viewKey) => {

  let parentKeys;

  dataParents.forEach(dp => {
    if (pathViews[dp]) {
      if (!parentKeys) { parentKeys = {} }
      parentKeys[dp] = pathViews[dp]
    }
  })

  parentKeys[view] = viewKey
  if (parentKeys_pageData) {
    Object.keys(parentKeys_pageData).forEach(ppk => {
      if (!parentKeys[ppk]) {
        parentKeys[ppk] = parentKeys_pageData[ppk]
      }
    })
  }
  return parentKeys
}


const convertToKeyValueDict = arrayObj => {
  const val = {}
  arrayObj.forEach(ob => {
    val[ob.name] = ob.value
  })
  return val
}

/**
 * 
 * @param {*} a1 
 * @param {*} a2 
 * @returns update or merge array
 * @deprecated
 */
export const updateOrMerge = (a1, a2) => {
  const ob1 = convertToKeyValueDict(a1)
  const ob2 = convertToKeyValueDict(a2)
  // Note: Spread operator with objects used here
  const merged_obj = { ...ob1, ...ob2 }
  const val = Object.entries(merged_obj)
  return val.map(obj => ({ name: obj[0], value: obj[1] }))
}

/**
 * 
 * @param {array} arr 
 * @param {number} old_index 
 * @param {number} new_index 
 * @returns an `array`
 * @description moves and item in an array to the `new_index`
 */
export const array_move = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

/**
 * 
 * @param {object} currentItems 
 * @param {string} dataPropName 
 * @description converts array values to strings for the dataPropName in the array
 */
export const convertArraysToStrings = (currentItems, dataPropName) => {
  if (currentItems) {
    Object.keys(currentItems).forEach(key => {
      const item = currentItems[key]
      if (dataPropName) {
        const propValue = item[dataPropName]
        if (propValue && _.isArray(propValue) && propValue.length === 1) {
          item[dataPropName] = propValue[0]
        }
      } else {
        Object.keys(item).forEach(iKey => {
          const itemValue = item[iKey]
          if (_.isArray(itemValue) && itemValue.length === 1) {
            item[iKey] = itemValue[0]
          }
        })
      }
    })
  }
}

/**
 * 
 * @param {object} dataItems 
 * @param {object} opts 
 * @deprecated
 */
export const getDataItems = (dataItems, opts) => {

  const { allowMultiColumnArrays } = opts ?? {}

  let viewDataItems = {}
  const headerProps = {}
  const dups = []

  // get the HEADERS
  try {
    Object.keys(dataItems).forEach((key, index) => {
      if (index === 0) {
        const _dataItem = dataItems[key]
        let count = 1
        // loop the first row
        Object.keys(_dataItem).forEach((key2, index) => {
          if (headerProps[key2]) {
            if (!dups.includes(key2)) { dups.push(key2) }
            headerProps[key2 + '_' + count] = {}
            count++
          } else {
            headerProps[key2] = {}
          }
          headerProps[key2] = {}
        })
      }
    });

    // array
    Object.keys(dataItems).forEach((key, index) => {
      if (index > 0) {
        const _dataItem = dataItems[key]
        let di;
        let dupValues;
        // loop the headerProps 
        Object.keys(headerProps).forEach((hKey, headerIndex) => {
          if (_dataItem[hKey]) {
            switch (hKey.toLowerCase()) {
              case 'id':
                break;
              default:
                // @ts-ignore
                if (!di) { di = {} }

                const itemKey = _.camelCase(hKey)
                const itemValue = _dataItem[hKey]

                if (allowMultiColumnArrays) {
                  dups.forEach((dup) => {
                    if (hKey.indexOf(dup) >= 0) {
                      if (!dupValues) { dupValues = {} }
                      if (!dupValues[dup]) { dupValues[dup] = [] }
                      dupValues[dup].push(itemValue)
                    }
                  })
                }

                if (hKey.indexOf('_') < 0) {
                  di[itemKey] = itemValue
                }
            }
          }
        })
        if (di) {
          viewDataItems[_dataItem[0]] = di
          if (allowMultiColumnArrays && dupValues) {
            Object.keys(dupValues).forEach(dupKey => {
              viewDataItems[_dataItem[0]][_.camelCase(dupKey)] = dupValues[dupKey]
            })
          }
        }
      }
    })
  } catch (error) {
    if (dataItems) {
      Object.keys(dataItems).forEach(function (key, index) {
        const _dataItem = dataItems[key]
        if (index === 0) {
          Object.keys(_dataItem).forEach(function (keyy) {
            headerProps[keyy] = {}
          })
        }
      });
    }
    viewDataItems = dataItems
  }

  return {
    headerProps,
    viewDataItems,
  }
}

/**
 * 
 * @param {object} dataCollections 
 * @returns a list of `staticViews`
 * @description Creates an itentical list for the `dataColletions` with only name, firstName, lastName values
 */
export const createStaticViewFromDataCollections = (dataCollections, aps_viewItems) => {

  const staticViews = {}

  if (dataCollections && Object.keys(dataCollections).length > 0) {
    Object.keys(dataCollections).forEach(dcKey => {
      if (dcKey) {
        const vi = aps_viewItems ? aps_viewItems[dcKey] : null
        const { dataConnection } = vi ?? {}
        const { uniqueProps } = dataConnection ?? {}

        staticViews[dcKey] = {}
        const staticView = staticViews[dcKey]

        const dataCollection = dataCollections[dcKey]
        if (staticView && dataCollection && Object.keys(dataCollection).length > 0) {
          Object.keys(dataCollection).forEach(dciKey => {
            try {
              const dci = dataCollection[dciKey]
              //@ts-ignore

              if (uniqueProps) {
                uniqueProps.forEach(up => {
                  if (dci[up]) {
                    if (!staticView[dciKey]) { staticView[dciKey] = {} }
                    staticView[dciKey][up] = dci[up]
                  }
                })
              }
            } catch (error) {
              // nothing - it does not exist
            }
          })
        }
      }
    })
  }
  return staticViews
}

export const getAlphaFromList = (dataItems, propName) => {
  const firstLetters = dataItems ? _.map(Object.values(dataItems), item => _.get(item, `${propName}[0]`, null)) : []
  const filteredFirstLetters = _.compact(_.uniq(firstLetters));
  const xx = _.map(filteredFirstLetters, item => item.toUpperCase())
  const alphas = _.sortBy(_.uniq(xx))
  if (alphas.length > 0) {
    return { alphas, firstLetter: alphas[0], firstAsc: alphas[0].charCodeAt() }
  } else {
    return { alphas, firstLetter: '?', firstAsc: 0 }
  }
}

/**
 * 
 * @param {object} dataItems 
 * @param {string} alphaChar 
 * @param {string} sortProp 
 * @returns a filtered list of `dataItems` that match the `alphaChar`
 */
export const getAlphaList = (dataItems, alphaChar, sortProp, currentGallery) => {

  let _sortProp = sortProp
  let firstAlpha;

  const { item: firstItem } = dataItems ? getFirstObject(dataItems) : {}

  // make sure the sort prop is correct
  if (firstItem) {
    const { lastName, displayName } = firstItem
    if (displayName) { _sortProp = 'displayName' }
    if (lastName) { _sortProp = 'lastName' }
  }

  const alphaList = {}

  if (dataItems) {
    Object.keys(dataItems).forEach(key => {
      const item = dataItems[key]
      const { email } = item
      if (item[_sortProp] && 0 === item[_sortProp].indexOf(alphaChar)) {
        alphaList[key] = item
        if (currentGallery && currentGallery[email]) {
          const galleryItem = currentGallery[email]
          const { urls } = galleryItem
          if (urls) {
            alphaList[key].urls = urls
            alphaList[key].fileStatusType = fileStatusTypes.existing
          }
        }
        if (!firstAlpha) { firstAlpha = key }
      }
    })
  }

  return { alphaList, sortProp: _sortProp, firstAlpha }
}

/**
 * 
 * @param {object} dataItems 
 * @param {string} view 
 * @param {string} viewKey 
 * @returns a filtered list of `dataItems` that match the `view` and 'viewItem' that are `checkedIn'
 */
export const getCheckInCount = (dataItems, view, viewKey) => {
  let _count = 0
  if (view) {
    const _checkInViewKey = view ? view + '_checkedIn' : null
    if (dataItems) {
      Object.keys(dataItems).forEach(key => {
        const _dataItem = dataItems[key]
        if (_dataItem && _dataItem[_checkInViewKey] && _dataItem[_checkInViewKey].includes(viewKey)) {
          _count++
        }
      })
    }
  } else {
    const gb = _.groupBy(dataItems, 'checkedIn')
    _count = gb['true'] ? gb['true'].length : 0
  }
  return _count
}

/**
 * The de-facto unbiased shuffle algorithm is the Fisher-Yates (aka Knuth) Shuffle.
 * You can see a great visualization here (and the original post linked to this)
 * @param {*} array 
 * @returns 
 */
function shuffle(array) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

/**
 * 
 * @param {string} text 
 * @param {string} character 
 * @param {number} pos 
 * @returns 
 */
const insertString = (text, character, pos) => {
  if (typeof (pos) === "undefined") {
    pos = 0;
  }
  if (typeof (character) === "undefined") {
    character = '';
  }
  return text.slice(0, pos) + character + text.slice(pos);
}

export const findResultFromArrayOfNumbers = (numbers, findResult, tries) => {

  const numberCount = _.isArray(numbers) ? numbers.length : 0
  const randomCount = numberCount - 1
  const dividers = ['/', '*', '+', '-']

  const results = {}
  const errors = []
  let result;

  for (var i = 0; i < tries; i++) {

    const _shuffledNumbers = shuffle(numbers)

    const randomIndex = Math.floor(Math.random() * randomCount)
    const end = randomIndex + randomIndex + 2
    const prt = { start: 0, end }

    let expression = '';

    // add the numbers and parens (if needed)
    _shuffledNumbers.forEach((n, index) => {
      expression += n
      if (index < (numberCount - 1)) {
        const rd = Math.floor(Math.random() * 4)
        expression += dividers[rd]
      }
    })

    // add the parens
    if (end > 0) {
      expression = insertString(expression, '(', prt.start)
      expression = insertString(expression, ')', prt.end)
    }

    try {
      const value = eval(expression)
      if (value === findResult) {
        result = { expression, value }
      }
      results[expression] = { value }
    } catch (error) {
      errors.push(expression)
    }
  }

  return { result, results, errors }

}

export const groupByKeys = (groups, keyProp, currentEnum, enumKey) => {

  const _groups = {}

  Object.keys(groups).forEach(groupKey => {
    const groupItems = groups[groupKey]
    // get the groupItem
    let _groupKey;

    if (_groupKey === undefined) { _groupKey = 'none' }
    if (currentEnum) {
      if (enumKey) {
        _groupKey = _.isString(groupKey) ? _.findKey(currentEnum, { [enumKey]: parseInt(groupKey) }) : _.findKey(currentEnum, { [enumKey]: groupKey })
      } else {
        _groupKey = currentEnum[groupKey]
      }
    } else {
      _groupKey = groupKey
    }

    _groups[_groupKey] = {}
    if (_.isArray(groupItems)) {
      groupItems.forEach(groupItem => {
        const groupItemKey = groupItem[keyProp]
        _groups[_groupKey][groupItemKey] = groupItem
      })
    }
  })

  delete _groups.undefined

  return _groups
}

export const convertArrayToObject = (items, fieldProp, valueProp) => {
  const fullO = {}
  if (_.isArray(items)) {
    items.forEach(item => {
      fullO[item[fieldProp]] = item[valueProp]
    })
  }
  return fullO
}

export const convertArrayToArray = (items, valueProp) => {
  const fullO = []
  if (_.isArray(items)) {
    items.forEach(item => {
      fullO.push(item[valueProp])
    })
  }
  return fullO
}

export function flattenObject(obj, parentKey = '') {
  let result = {};

  for (const key in obj) {
    const newKey = parentKey ? `${parentKey}_${key}` : key;
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      result = { ...result, ...flattenObject(obj[key], newKey) };
    } else {
      result[newKey] = obj[key];
    }
  }

  return [result];
}


const itemValue = (index, header, value) => {
  switch (header) {
    case 'id':
      return index + 1
    default:
      let hv = value && !_.isObject(value) && !_.isArray(value) ? value : ''
      switch (header) {
        case 'section':
          hv = hv.replace('section', '')
          break;
        default:
      }

      return formatValue(hv, header)
  }
}

const formatValue = (value, header) => {
  if (_.isString(value)) {
    const trimmedValue = _.trim(value);

    // Check if it's purely numeric
    if (!_.isNaN(_.toNumber(trimmedValue))) {
      value = _.toNumber(trimmedValue);
    }
    // Check if the string is a valid date (stricter format)
    else if (!isNaN(Date.parse(trimmedValue)) && /^\d{4}-\d{2}-\d{2}$/.test(trimmedValue)) {
      const dateObj = new Date(trimmedValue);
      const formattedDate = `${_.padStart(dateObj.getMonth() + 1, 2, '0')}/${_.padStart(dateObj.getDate(), 2, '0')}/${dateObj.getFullYear()}`;
      value = formattedDate;
    }
  }

  if (value === 0 && header && header.indexOf('Score') < 0) {
    value = '';
  }

  return value;
};

const formatValueXXX = (value) => {

  if (_.isString(value) && !_.isNaN(_.toNumber(_.trim(value)))) {
    // Convert the value to a number
    value = _.toNumber(value);
  }

  if (_.isString(value) && !isNaN(Date.parse(value))) {
    // Format the date as MM/DD/YYYY
    const dateObj = new Date(value);
    const formattedDate = `${_.padStart(dateObj.getMonth() + 1, 2, '0')}/${_.padStart(dateObj.getDate(), 2, '0')}/${dateObj.getFullYear()}`;
    value = formattedDate;
  }

  if (value === 0) {
    value = ''
  }

  return value

}

export const getGoogleSheetDataValues = (data, headers, name, latestSeason, forMedia) => {
  const _headers = headers.map(h => (_.startCase(h)))
  // Convert the match objects to a 2D array of values
  const values = [_headers, ...data.map((obj, index) => headers.map(
    header => itemValue(index, header, obj[header])))
  ];

  if (latestSeason) {
    return { values, tabName: forMedia ? name + ' ' + latestSeason + ' Media' : name + ' ' + latestSeason + ' Latest' }
  } else {
    return { values, tabName: name + ' Latest' }
  }
}

/**
 * 
 * @param {object} viewListData 
 * @param {object} viewItem 
 * @param {object} staticViews_app 
 * @returns 
 */
export const convertViewListDataToGoogleSheetsData = (viewListData, viewItem, staticViews_app, useSectionProps) => {

  const _ignoreKeys = ['_svs', '_sv', 'sv', 'svs', '_dateInfo', 'sessions', 'attendees']
  const _ignoreValues = ['Invalid Date']

  const sectionProps = getHeadersFromPropSections(viewItem)

  let allValues = []

  // get the headers 
  const headers = {}

  if (useSectionProps && sectionProps) {
    sectionProps.unshift('ID')
    sectionProps.forEach(sp => {
      if (!_ignoreKeys.includes(sp)) {
        headers[sp] = {
          propName: sp,
          arrayCount: 0
        }
      }
    })
    ammendHeaders(viewListData, headers, _ignoreKeys)
  } else {
    Object.keys(viewListData).forEach(key => {
      const itemData = viewListData[key]
      Object.keys(itemData).forEach((propKey, propIndex) => {
        if (!_ignoreKeys.includes(propKey)) {
          if (!headers[propKey]) {
            headers[propKey] = {
              propName: propKey,
              arrayCount: 0
            }
          }
          const propValue = itemData[propKey]
          if (propValue) {
            if (_.isArray(propValue)) {
              propValue.forEach((pv, index) => {
                const _count = index + 1
                if (_count > headers[propKey].arrayCount) {
                  headers[propKey].arrayCount = _count
                }
              })
            }
          }
        }
      })
    })
  }

  const firstRow = []

  Object.keys(headers).forEach(headerKey => {
    const h = headers[headerKey]
    const { arrayCount } = h
    if (arrayCount > 0) {
      for (var i = 1; i <= arrayCount; i++) {
        firstRow.push(_.startCase(headerKey))
      }
    } else {
      firstRow.push(_.startCase(headerKey))
    }
  })

  Object.keys(viewListData).forEach((key, index) => {
    const _dataItemValues = []
    const itemData = viewListData[key]
    Object.keys(headers).forEach(headerKey => {
      const h = headers[headerKey]
      const { propName, arrayCount } = h
      switch (propName) {
        case 'ID':
          // _dataItemValues.push(index + 1)
          _dataItemValues.push(key)
          break;
        // case 'itemKey':
        //   _dataItemValues.push(key)
        //   break;
        default:
          const itemPropData = itemData[propName]
          if (itemPropData) {
            if (arrayCount > 0) {
              const staticView = staticViews_app[propName]
              for (var i = 0; i < arrayCount; i++) {
                if (_.isArray(itemPropData)) {
                  if (itemPropData[i]) {
                    const av = itemPropData[i]
                    const svv = staticView[av]
                    if (svv) {
                      const name = getItemName(svv)
                      _dataItemValues.push(name)
                    } else {
                      _dataItemValues.push('')
                    }
                  } else {
                    _dataItemValues.push('')
                  }
                } else {
                  _dataItemValues.push('')
                }
              }
            } else {
              if (!_ignoreValues.includes(itemPropData)) {
                _dataItemValues.push(itemPropData)
              } else {
                _dataItemValues.push('')
              }
            }
          } else {
            _dataItemValues.push('')
          }
      }
    })
    allValues.push(_dataItemValues)
  })

  allValues.unshift(firstRow)

  return allValues
}

const ammendHeaders = (viewListData, headers, _ignoreKeys) => {
  Object.keys(viewListData).forEach(key => {
    const itemData = viewListData[key]
    Object.keys(itemData).forEach((propKey, propIndex) => {
      if (headers[propKey]) {
        const propValue = itemData[propKey]
        if (propValue) {
          if (_.isArray(propValue)) {
            propValue.forEach((pv, index) => {
              const _count = index + 1
              if (_count > headers[propKey].arrayCount) {
                headers[propKey].arrayCount = _count
              }
            })
          }
        }
      }
    })
  })
}

export function getDistance(lat1, lng1, lat2, lng2, unit) {
  if ((lat1 === lat2) && (lng1 === lng2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lng1 - lng2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") { dist = dist * 1.609344 }
    if (unit === "N") { dist = dist * 0.8684 }
    return dist;
  }
}

export function getCrList(items, propKey) {
  const _items = _.sortBy(items, propKey)
  let dataNames = ''
  _items.forEach(item => {
    dataNames += item[propKey] + '\n'
  })
  return dataNames
}

export function getNameList(items, propKey) {
  const _items = _.sortBy(items, propKey)
  let dataNames = []
  _items.forEach(item => {
    dataNames.push(item.name)
  })
  return dataNames
}

export function convertToWYSIWYG(text) {
  const container = document.createElement('div');
  const editor = document.createElement('div');
  container.appendChild(editor);

  const quill = new Quill(editor, {
    theme: 'snow', // Specify the theme, 'snow' for a clean and minimalistic editor
    readOnly: true // Set readOnly to true to prevent editing
  });

  // quill.setContents(quill.clipboard.dangerouslyPasteHTML(text))
  quill.setContents(quill.clipboard.convert(text));

  // Get the HTML content of the editor
  let wysiwygHTML = editor.querySelector('.ql-editor').innerHTML;
  wysiwygHTML = insertLineBreaks(wysiwygHTML)

  return wysiwygHTML;
}

export function insertLineBreaks(text) {
  const modifiedText = text.replace(/•/g, '</p><p>•');
  return modifiedText;
}

export function calculateByteSize(obj) {
  // This function calculates the byte size of a string
  const str = JSON.stringify(obj)
  let byteSize = 0;
  const limit = 1048576
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    if (charCode < 0x007f) {
      byteSize += 1;
    } else if ((charCode >= 0x0080) && (charCode <= 0x07ff)) {
      byteSize += 2;
    } else if ((charCode >= 0x0800) && (charCode <= 0xffff)) {
      byteSize += 3;
    } else {
      byteSize += 4;
    }
  }
  return {
    byteSize,
    valid: byteSize <= limit ? true : false,
    remaining: limit - byteSize,
    perc: byteSize / limit
  }
}

export function fixAddress(address) {
  const addressParts = address.split(', ');
  addressParts[0] = _.startCase(_.camelCase(addressParts[0]))
  addressParts[1] = _.startCase(_.camelCase(addressParts[1]))
  return addressParts.join(', ')

}

export function fixLastName(name) {
  const apostropheIndex = name.indexOf("'");
  // If apostrophe exists and the next character is a lowercase letter
  if (apostropheIndex !== -1 && /[a-z]/.test(name[apostropheIndex + 1])) {
    // Capitalize the letter after the apostrophe
    const capitalizedLetter = name[apostropheIndex + 1].toUpperCase();
    // Replace the original letter with the capitalized one
    name = name.substring(0, apostropheIndex + 1) + capitalizedLetter + name.substring(apostropheIndex + 2);
  }
  return name;
}

function customizer_min(objValue, srcValue) {
  // If both values are numbers, return the greater one
  if (_.isNumber(objValue) && _.isNumber(srcValue)) {
    return Math.min(objValue, srcValue);
  }
  // For non-numeric values or non-selected properties, use the source value
  return srcValue;
}

function customizer_max(objValue, srcValue) {
  // If both values are numbers, return the greater one
  if (_.isNumber(objValue) && _.isNumber(srcValue)) {
    return Math.max(objValue, srcValue);
  }
  // For non-numeric values or non-selected properties, use the source value
  return srcValue;
}

export function combineObjectsWithSelectedProps(obj1, obj2, selectedProps, min) {
  const combinedObject = min ? _.mergeWith({}, obj1, obj2, customizer_min) : _.mergeWith({}, obj1, obj2, customizer_max);
  // Pick only the selected properties
  return _.pick(combinedObject, selectedProps);
}

/**
 * 
 * @param {object} obj 
 * @returns the same object after remving the empties
 */
export function removeEmptyObjectArrays(obj) {
  return _.pickBy(obj, (value) => {
    if (_.isArray(value)) {
      return !_.isEmpty(value);
    } else if (_.isPlainObject(value)) {
      return !_.isEmpty(value);
    }
    return true; // Keep other types of values
  });
}

export function convertGsToHtml(obj) {
  // Check if the input is an object
  if (_.isObject(obj)) {
    // Iterate over each property of the object
    _.forEach(obj, (value, key) => {
      // If the property is a string, convert it to HTML-like format
      if (_.isString(value) && propHelpers.isHtml(value)) {
        obj[key] = convertTextToHtml(value);
      } else if (_.isObject(value)) {
        // If the property is an object, recursively call convertTextProperties
        convertGsToHtml(value);
      }
    });
  }
}

export function convertTextToHtml(text) {

  const modifiedText = text.replace(/\n\n+/g, '\n');

  // Split the text into paragraphs based on line breaks
  const paragraphs = modifiedText.split('\n').filter(para => para.trim() !== '');

  // Map each paragraph to a string with line breaks converted to <br> tags
  const htmlContent = paragraphs.map(para => {
    const lines = para.split('\n').map(line => `${line}<br>`);
    return lines.join('');
  });

  return htmlContent.join('<br><br>'); // Join paragraphs with double line breaks
}

