import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import DateInput from '../../../../global/components/forms/elements/DateInput';
import TimeInput from '../../../../global/components/forms/elements/TimeInput';
import { SportsEditMatchContext } from '../../cnr/contexts/SportsEditMatchContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';

const MatchForm = (props) => {

  const { shows, handleActionPreview, solo, setMatchReady, historyMatch, handleUpdateParent, match_edit: newCurrentMatch } = props
  const { showLocation, showDate, showTeams, showLevel } = shows ?? {}

  const teamCaptions = {
    away: historyMatch ? 'champion' : 'away',
    home: historyMatch ? 'runner-up' : 'home'
  }

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { teams_info, matches_info } = sportsSeason_state
  const { teams } = teams_info ?? {}
  const { matches } = matches_info ?? {}

  const sportsEditMatchContext = useContext(SportsEditMatchContext)
  const { sportsEditMatch_state, sportsEditMatch_handlers } = sportsEditMatchContext ?? {}
  const { match_edit } = sportsEditMatch_state ?? {}

  const [_currentMatch, set_currentMatch] = useState()
  const [teamOptsList, setTeamOptsList] = useState()
  const [levelOptsList, setLevelOptsList] = useState()

  useEffect(() => {
    const _cm = historyMatch ? historyMatch : match_edit
    set_currentMatch(_cm ? _cm : newCurrentMatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [match_edit]);

  useEffect(() => {
    const list = []
    if (teams) {
      const teamsS = _.sortBy(teams, 'name')
      teamsS.forEach(team => {
        const { _itemKey, name, id } = team ?? {}
        const _key = _itemKey ? _itemKey : id
        list.push({ key: _key, value: _key, text: name })
      })
      setTeamOptsList(list)
    }
    const levelList = []
    const levels = ['8A', '7A', '6A', '5A', '4A', '3A', '2A', '1A', 'non', 'nonDistrict', 'playoff']
    levels.forEach(level => {
      levelList.push({ key: level, value: level, text: level })
    })
    setLevelOptsList(levelList)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    setMatchReady && setMatchReady(newMatchReady())
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_currentMatch]);

  const newMatchReady = () => {
    if (_currentMatch) {
      const { home, away, startDate, startTime } = _currentMatch
      let ready = away !== null && home !== null
      if (showDate && !startDate) { ready = false }
      if (showDate && !startTime) { ready = false }
      if (ready && matches) {
        const existing = _.find(matches, function (m) {
          const { away: away_m, home: home_m } = m
          const { id: id_home } = home_m ?? {}
          const { id: id_away } = away_m ?? {}
          return (
            (id_away === away) && (id_home === home)
          );
        })
        if (existing) {
          ready = false
        }
      }
      return ready
    }
  }

  const handleSelectTeamA = (e, data) => {
    const cm = { ..._currentMatch }
    const _team = teams[data.value]
    cm.away = {
      id: _team.id,
      name: _team.name,
      levels: _team.levels,
      sections: _team.sections
    }
    sportsEditMatch_handlers && sportsEditMatch_handlers.handleAmmend_editMatch(cm)
    handleActionPreview && handleActionPreview({ match_edit: cm, ready: newMatchReady() })
    handleUpdateParent && handleUpdateParent({ match_edit: cm })
    set_currentMatch(cm)
  }

  const handleSelectTeamH = (e, data) => {
    const cm = { ..._currentMatch }
    cm.home = data.value
    sportsEditMatch_handlers && sportsEditMatch_handlers.handleAmmend_editMatch(cm)
    handleActionPreview && handleActionPreview({ match_edit: cm, ready: newMatchReady() })
    handleUpdateParent && handleUpdateParent({ match_edit: cm })
    set_currentMatch(cm)
  }

  const handleUpdateDate = (e, data) => {
    const cm = { ..._currentMatch }
    cm.startDate = data.value
    sportsEditMatch_handlers && sportsEditMatch_handlers.handleAmmend_editMatch(cm)
    handleActionPreview && handleActionPreview({ match_edit: cm, ready: newMatchReady() })
    set_currentMatch(cm)
  }

  const handleUpdateTime = (e, data) => {
    const cm = { ..._currentMatch }
    cm.startTime = data.value
    sportsEditMatch_handlers && sportsEditMatch_handlers.handleAmmend_editMatch(cm)
    handleActionPreview && handleActionPreview({ match_edit: cm, ready: newMatchReady() })
    set_currentMatch(cm)
  }

  const handleSelectedLocation = (e, d) => {
    const l = teams[d.value]
    const cm = { ..._currentMatch }
    cm.location = {
      id: l.id,
      name: l.name
    }
    sportsEditMatch_handlers && sportsEditMatch_handlers.handleAmmend_editMatch(cm)
    handleActionPreview && handleActionPreview({ match_edit: cm, ready: newMatchReady() })
    set_currentMatch(cm)
  }

  const handleSelectLevel = (e, data) => {
    const cm = { ..._currentMatch }
    cm._levelKey_old = cm._levelKey
    cm._levelKey = data.value
    sportsEditMatch_handlers && sportsEditMatch_handlers.handleAmmend_editMatch(cm)
    handleActionPreview && handleActionPreview({ match_edit: cm, ready: newMatchReady() })
    set_currentMatch(cm)
  }

  const fdd = (caption, team, oc) => {
    let _key;
    if (_.isObject(team)) {
      const { _itemKey, name, id } = team ?? {}
      _key = _itemKey
    } else {
      _key = team
    }
    return <Form.Dropdown label={_.startCase(caption)} placeholder={caption} search scrolling selection value={_key} options={teamOptsList} onChange={oc} />
  }

  const teamASelect = () => fdd(teamCaptions.away, _currentMatch.away, handleSelectTeamA)
  const teamHSelect = () => fdd(teamCaptions.home, _currentMatch.home, handleSelectTeamH)

  const dateSelect = () => <DateInput fip={{ propname: 'matchDate', onChange: handleUpdateDate, label: 'Start Date', value: _currentMatch.startDate }} />
  const timeSelect = () => <TimeInput fip={{ propname: 'startTime', onChange: handleUpdateTime, label: 'Start Time', value: _currentMatch.startTime }} />
  const locationSelect = () => <Form.Dropdown label={'Location'} placeholder={'Location'} search scrolling selection value={_currentMatch.location && _currentMatch.location.id} options={teamOptsList} onChange={handleSelectedLocation} />
  const levelSelect = () => <Form.Dropdown label={'Level'} placeholder={'Level'} search scrolling selection value={_currentMatch._levelKey} options={levelOptsList} onChange={handleSelectLevel} />

  const matchTeamsDiv = () => {
    let cn = 'match-teams'
    if (solo) { cn += ' fh' }
    return <Form basic className={cn}>
      {showTeams && <React.Fragment>
        <div className={'match-teams-teams'}>
          {teamASelect()}
          {teamHSelect()}
        </div>
      </React.Fragment>}
      {showDate && <div className={'match-team-date'}>{dateSelect()} </div>}
      {showDate && <div className={'match-team-time'}> {timeSelect()}</div>}
      {showLocation && <div className={'match-match-location'}> {locationSelect()}</div>}
      {showLevel && <div className={'match-match-location'}> {levelSelect()}</div>}
    </Form>
  }

  return _currentMatch ? matchTeamsDiv() : <div></div>
}

export default MatchForm