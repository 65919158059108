import React from 'react'
import { svgElements } from "./svgElements"

const svgCanvas = (kkey, maxHeight, maxWidth, categorySvgProps, elemsAndPercs, elemsOnly, elemsAndPercs2) => {

  console.log('categorySvgProps', categorySvgProps)

  const canvas = () => (
    <svg key={kkey}
      height={maxHeight + 'px'}
      width={'100%'}
      style={{ verticalAlign: 'top', touchAction: 'none', position: 'relative' }}
    >
      {/* adding elements to he canvas */}
      {/* court lines */}
      {svgElements(categorySvgProps)}
      {elemsAndPercs && svgElements(null, elemsAndPercs)}
      {elemsAndPercs2 && svgElements(null, elemsAndPercs2)}
      {elemsOnly && svgElements(null, null, elemsOnly)}
    </svg>
  )

  return canvas()

}

export default svgCanvas