import _ from 'lodash';
import { getAppUserLinks } from '../../auth/accessPermissions';
import { g_cns } from '../../common/cns';
import { _sectionProps } from '../../components/props/propsShown';
import { gEnums } from '../../enums/globalEnums';
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { arrayUpdateToDatabase } from '../../firestoreData/updates/arrays/arrayUpdates';
import { addToClass, getSectionThemeColors, setItemStyleAndClass } from '../../styles/formatting';
import { sectionMerge } from '../../viewSettings/helpers/sectionMerge';
import { getPropSectionsGrouped } from '../../viewSettings/helpers/settingsHelpers';
import { postFilterData } from '../contexts/contextHelpers/postFilterData';
import { firestoreHandlers, firestoreReducer, fsrts } from './FirestoreReducer';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from './reducerHelpers/dispatchProps';

const rts = {
  handle_fsSaveDataItemRating: 'handle_fsSaveDataItemRating',
  handle_fsUpdateDbFromGoogleSheet: 'handle_fsUpdateDbFromGoogleSheet', // not sure 
  handle_fsUpdateRequestAccess: 'handle_fsUpdateRequestAccess',
  handleClose_openedItem: 'handleClose_openedItem',
  handleDrill: 'handleDrill',
  handleGet_validationAccess: 'handleGet_validationAccess',
  handleInit_item: 'handleInit_item',
  handleRateDataItem: 'handleRateDataItem',
  handleSet_currentGroupKey: 'handleSet_currentGroupKey',
  handleSet_modifyAction: 'handleSet_modifyAction',
  handleSet_updateFilterOpts: 'handleSet_updateFilterOpts',
  handleShow_itemEdit: 'handleShow_itemEdit',
  handleShow_itemSidebar: 'handleShow_itemSidebar',
  handleShow_modifyMapping: 'handleShow_modifyMapping',
  handleShow_settingsSidebar: 'handleShow_settingsSidebar',
  handleShow_sort: 'handleShow_sort',
  handleToggle_propSetting: 'handleToggle_propSetting',
  handleToggle_sectionSetting: 'handleToggle_sectionSetting',
  handleUpdate_linkItem: 'handleUpdate_linkItem',
  // handleUpdate_staticView: 'handleUpdate_staticView',
  ...fsrts,
  ...grts,
}

export const itemReducer = (state, action) => {

  const { type, init_state, dispatch, popupAction } = action

  const {
    dataFieldName,
    linkIndex,
    linkType,
    mapFieldName,
    modifyActionType,
    subActionType,
  } = popupAction ?? {}

  const {
    modifyActionType: _modifyActionType,
    modifySidebarOpen,
    modifyMappingOpen,
    paps_state,
    page_fns,
    settingsSidebarOpen,
    showSort,
  } = state

  switch (type) {

    case rts.handleGet_validationAccess:
      return { ...state }

    case rts.handleSet_currentGroupKey:
      return { ...state, currentGroupKey: action.currentGroupKey }

    case rts.handleInit_item:
      const { appUserAccess, _filterOpts, _groupingOpts, _viewItemOpts, viewItem: viewItemInit, viewListData: vldInit, logging: loggingInit } = init_state
      const x = ammendVld({ _filterOpts, _groupingOpts, _viewItemOpts, appUserAccess, viewItem: viewItemInit, viewListData: vldInit, logging: loggingInit })
      const { navigationOptions, allowItemPopup } = getNavigationOptions(init_state, action.dataManagementSettings, action.aps_appUserSettings, appUserAccess, action.pageSettings)
      const _cnc = getClassName(action.init_state)
      return { ...state, _filterOpts, appUserAccess, ...action.init_state, viewItem: viewItemInit, vld: x, navigationOptions, allowItemPopup, _cnc }

    case rts.handleSet_updateFilterOpts:
      const { alphaValue, filterValues } = action.filterOpts
      const _filter_opts = { ...state._filterOpts }
      const _grouping_opts = { ...state._groupingOpts }
      if (alphaValue) { _filter_opts.alphaValue = alphaValue }
      if (filterValues) { _filter_opts.filterValues = filterValues }
      if (alphaValue === 'Favs') {
      }
      const y = ammendVld({
        filterOpts: _filter_opts,
        groupingOpts: _grouping_opts,
        appUserAccess: state.appUserAccess,
        viewItem: state.viewItem,
        viewListData: state.viewListData,
        logging: state.logging,
      })
      return { ...state, _filterOpts: _filter_opts, _groupingOpts: _grouping_opts, vld: y }

    // case rts.handleUpdate_staticView:
    //   updateStaticView(action.actionItem)
    //   return { ...state }

    case rts.handleUpdate_linkItem:
      const uProps = {
        actionItem: action.actionItem,
        dispatch,
        dataActionType: gEnums.itemLinkTypes.linkProfile,
        logging: state.logging,
        page_fns,
        paps_state,
        remove: action.actionItem.remove
      }
      arrayUpdateToDatabase(uProps)
      return { ...state }

    // RESPONSE  
    case rts.handle_fsUpdateDbFromGoogleSheet:
    case rts.handle_fsUpdateRequestAccess: // normal

      const ufProps = getRequestProps(type, state, action)

      if (action.createFunction) {
        action.createFunction(paps_state, ufProps)
      } else {
        // parentDispatch, paps_state, ufProps, parentDispatchProps
        return firestoreReducer(state, action, { type: 'updateFirestoreData', dispatch, paps_state, ufProps })
      }

      switch (type) {
        case rts.handle_fsUpdateDbFromGoogleSheet:
          return { ...state, modifySidebarOpen: false, modifyActionType: null, updating: false, settingsSidebarOpen: false }
        case rts.handle_fsSaveDataItemRating:
          return { ...state, modifyActionType: null, updating: false, rating: true }
        default:
        // nothing
      }
      return { ...state }

    // RATING
    case rts.handleRateDataItem:
      const _refRating = createRefPath_event(paps_state.pathViews, ['_ratings', action.viewItem_key])
      return { ...state, itemRating: action.rating }

    // SHOW SORT
    case rts.handleShow_sort:
      return { ...state, showSort: !showSort }

    case rts.handleToggle_propSetting:
      const { propToggle, propItem } = action
      const { _overrides: _overrides_prop } = propItem ?? {}
      _overrides_prop[propToggle] = !_overrides_prop[propToggle]
      console.log('_overrides_prop', _overrides_prop)
      return { ...state, overridesChanged: true }

    case rts.handleToggle_sectionSetting:
      const { sectionToggle, sectionItem } = action
      const { _overrides: _overrides_section } = sectionItem ?? {}
      _overrides_section[sectionToggle] = !_overrides_section[sectionToggle]
      return { ...state }

    // SHOW SORT
    case rts.handleShow_itemEdit:
      const { editableItem } = action
      if (editableItem) {
        const k = _.camelCase(editableItem.key)
        const ei = { [k]: action.editableItem }
        return { ...state, showSingleItemEdit: true, editableItem: { ...ei } }
      } else {
        return { ...state, showSingleItemEdit: false, editableItem: null }
      }

    case rts.handleClose_openedItem:
    case rts.handleShow_itemSidebar:
      if (modifySidebarOpen) {
        return { ...state, modifySidebarOpen: false, modifyActionType: null, settingsSidebarOpen: false, filterSidebarOpen: false, editableItem: null }
      } else if (_modifyActionType) {
        return { ...state, modifySidebarOpen: false, modifyActionType: null, settingsSidebarOpen: false, filterSidebarOpen: false, editableItem: null }
      } else {
        return { ...state, modifySidebarOpen: true, settingsSidebarOpen: false, filterSidebarOpen: false }
      }

    case rts.handleShow_settingsSidebar:
      if (settingsSidebarOpen) {
        return { ...state, settingsSidebarOpen: false, modifyActionType: null, modifySidebarOpen: false, editableItem: null }
      } else {
        return { ...state, settingsSidebarOpen: true, modifySidebarOpen: false }
      }

    case rts.handleShow_modifyMapping:
      return { ...state, modifyMappingOpen: !modifyMappingOpen, modifyMapPropKey: action.propKey }

    case rts.handleSet_modifyAction:
      const cbn = {
        ...state,
        modifyActionType,
        subActionType,
        linkType,
        linkIndex,
        dataFieldName,
        mapFieldName,
        ...popupAction
      }
      return cbn

    case rts.handleDrill:
      return { ...state, drillProps: action.drillProps }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const itemInitialState = (initState) => {
  return { ...initState }
};

export const itemHandlers = (dispatch) => {
  return {
    handle_fsSaveDataItemRating: () => { dispatch({ type: rts.handle_fsSaveDataItemRating, dispatch }) },
    handle_fsSaveSort: () => { dispatch({ type: rts.handle_fsSaveSort, dispatch }) },
    handle_fsUpdateDbFromGoogleSheet: (selectedItems, parentKeys) => { dispatch({ type: rts.handle_fsUpdateDbFromGoogleSheet, dispatch, selectedItems, parentKeys }) },
    handle_fsUpdateRequestAccess: (appUser, pathName, currentPageDataCaption) => { dispatch({ type: rts.handle_fsUpdateRequestAccess, dispatch, appUser, pathName, currentPageDataCaption }) },
    handleClose_openedItem: () => { dispatch({ type: rts.handleClose_openedItem, dispatch }) },
    handleDrill: (drillProps) => { dispatch({ type: rts.handleDrill, dispatch, drillProps }) },
    handleGet_validationAccess: () => { dispatch({ type: rts.handleGet_validationAccess }) },
    handleInit_item: (init_state, dataManagementSettings, aps_appUserSettings, pageSettings) => { dispatch({ type: rts.handleInit_item, dispatch, init_state, dataManagementSettings, aps_appUserSettings, pageSettings }) },
    handleRateDataItem: (viewItem_key, viewKey, rating) => { dispatch({ type: rts.handleRateDataItem, dispatch, viewItem_key, viewKey, rating }) },
    handleSet_currentGroupKey: (currentGroupKey) => { dispatch({ type: rts.handleSet_currentGroupKey, dispatch, currentGroupKey }) },
    handleSet_modifyAction: (popupAction) => { dispatch({ type: rts.handleSet_modifyAction, dispatch, popupAction }) },
    handleSet_updateFilterOpts: (filterOpts) => { dispatch({ type: rts.handleSet_updateFilterOpts, dispatch, filterOpts }) },
    handleShow_itemEdit: (editableItem) => { dispatch({ type: rts.handleShow_itemEdit, dispatch, editableItem }) },
    handleShow_itemSidebar: () => { dispatch({ type: rts.handleShow_itemSidebar, dispatch }) },
    handleShow_modifyMapping: (propKey) => { dispatch({ type: rts.handleShow_modifyMapping, dispatch, propKey }) },
    handleShow_settingsSidebar: () => { dispatch({ type: rts.handleShow_settingsSidebar, dispatch }) },
    handleShow_sort: () => { dispatch({ type: rts.handleShow_sort, dispatch }) },
    handleToggle_propSetting: (propToggle, propItem) => { dispatch({ type: rts.handleToggle_propSetting, dispatch, propToggle, propItem }) },
    handleToggle_sectionSetting: (sectionToggle, sectionItem) => { dispatch({ type: rts.handleToggle_sectionSetting, dispatch, sectionToggle, sectionItem }) },
    handleUpdate_linkItem: (actionItem) => { dispatch({ type: rts.handleUpdate_linkItem, dispatch, actionItem }) },
    // handleUpdate_staticView: (actionItem) => { dispatch({ type: rts.handleUpdate_staticView, dispatch, actionItem }) },
    ...firestoreHandlers(dispatch),
    ...responseHandlers(dispatch)
  }
}

const getRequestProps = (type, state, action) => {
  const { viewItem, itemRating, vld } = state
  switch (type) {
    case rts.handle_fsSaveDataItemRating:
      const ratingItem = vld[Object.keys(vld)[0]]
      return {
        id: ratingItem.id,
        itemData: ratingItem,
        dataUpdateType: gEnums.dataUpdateTypes.updateRating,
        vit: viewItem.key,
        rating: itemRating
      }
    case rts.handle_fsUpdateDbFromGoogleSheet:
      return {
        id: null,
        selectedDataItems: action.selectedItems,
        dataUpdateType: gEnums.dataUpdateTypes.addData,
        vit: viewItem.key,
      }
    case rts.handle_fsUpdateRequestAccess:
      return {
        modifyType: gEnums.dataUpdateTypes.updateAccessRequest,
        dataUpdateType: gEnums.dataUpdateTypes.updateAccessRequest,
        appUser: action.appUser,
        pathName: action.pathName,
        pathCaption: action.currentPageDataCaption
      }
    default:
      break;
  }
}

export const getAllowPopups = (layoutType, filterStatusType, viewItem_preview) => {

  let allowPopups = true

  switch (layoutType) {
    case gEnums.layoutTypes.horizontalSliders:
      break;
    case gEnums.layoutTypes.singlePage:
      allowPopups = false
      break;
    default:
    // nothing
  }

  if (filterStatusType === gEnums.filterStatusTypes.showing) { allowPopups = false }
  if (viewItem_preview) { allowPopups = false }

  return allowPopups

}

const getClassName = (state) => {

  const {
    _display,
    viewItem,
    cardProps,
    itemData,
    _groupingOpts,
    _imageOpts,
    _pageOpts,
  } = state

  const viewListCount = itemData ? Object.keys(itemData).length : 0

  const { slider, qrCodes } = viewItem ?? {}
  const { layout } = _pageOpts ?? {}
  const { cardDisplayType, cssContainerName, useCssContainer } = _display ?? {}
  const { groupType, groupActive } = _groupingOpts ?? {}
  const { showItemImage, imageLocation, imageType, imageSize } = _imageOpts ?? {}

  const { layoutType, sliderCount: viewSliderCount } = layout ?? {}
  let { horizontalSlider, sliderCount } = slider ?? {}
  let _horizontalSliderCount = sliderCount ? sliderCount : 0
  let useHorizontalSlider = horizontalSlider ? true : false

  if (layoutType === gEnums.layoutTypes.horizontalSliders) {
    useHorizontalSlider = true
    _horizontalSliderCount = viewSliderCount
  }

  const singleDataItem = viewListCount === 1

  let _cn = singleDataItem ? g_cns.item_container : g_cns.cards_container

  if (cardDisplayType) { _cn = addToClass(_cn, cardDisplayType) }
  if (viewListCount === 1) { }

  const { showQrCode, qrLocation } = qrCodes ?? {}

  switch (groupType) {
    // case gEnums.groupTypes.swipeableGroup:
    //   _cn = addToClass(_cn, 'item-horz')
    //   break;
    default:
      _cn = addToClass(_cn, useHorizontalSlider ? 'item-horz' : 'item-vert')
    // nothing
  }

  if (useCssContainer && cssContainerName) { _cn += ' ucss ' + cssContainerName }
  if (singleDataItem) { _cn += ' solo' }

  if (cardProps && cardProps.gap) { _cn = addToClass(_cn, 'gp-' + cardProps.gap) }

  // if (ipr > 1) { _cn = addToClass(_cn, 'ipr-' + ipr) }
  // if (ipr < 3) { _cn = addToClass(_cn, 'nmw') }

  if (imageLocation && showItemImage) {
    _cn = addToClass(_cn, 'card-img')
    _cn = addToClass(_cn, imageLocation, 'img-pos')
    _cn = addToClass(_cn, imageSize, 'img-size')
    _cn = addToClass(_cn, imageType, 'imt')
  }

  if (groupActive) {
    _cn = addToClass(_cn, 'grp')
  }

  if (showQrCode) {
    _cn = addToClass(_cn, 'card-qr')
    _cn = addToClass(_cn, qrLocation, 'qr')
  }

  switch (cardDisplayType) {
    case gEnums.cardDisplayTypes.profilePics:
      _cn += ' pci'
      break;

    case gEnums.cardDisplayTypes.profilePlus:
    case gEnums.cardDisplayTypes.schedulePlus:
      _cn += ' plus'
      break;
    default:
    // nothing
  }

  return _cn

}

const getNavigationOptions = (initState, dataManagementSettings, aps_appUserSettings, appUserAccess, pageSettings) => {

  const { appUser_fns, viewItem, viewItemStatus, itemData, accessRequests, view, viewKey } = initState

  const { appUserSessionKey } = appUserAccess ?? {}
  const { appUserCollection, allowLinks, links, appUserDataModifications } = aps_appUserSettings ?? {}

  const pageNavOptions = appUser_fns.validateAccess_uiAction({
    accessRequests,
    appUserAccess,
    appUserDataModifications,
    dataManagementSettings,
    itemData,
    pageSettings,
    view,
    viewItem,
    viewItemStatus,
    viewKey,
  })

  const appUserLinkItems = []
  if (itemData && allowLinks && links && appUserSessionKey) {
    getAppUserLinks(appUserLinkItems, appUserCollection, links, itemData, viewKey, viewItem, itemData.id)
  }

  const allowItemPopup = getAllowPopup(pageNavOptions)

  const navigationOptions = { pageNavOptions, appUserLinkItems }

  return { navigationOptions, allowItemPopup }
}

const getAllowPopup = (pageNavOptions) => {
  let aip = false
  let totalCount = 0

  if (Object.keys(pageNavOptions.appUserDirect).length > 0) {
    aip = true
    totalCount += Object.keys(pageNavOptions.appUserDirect).length
  }

  if (Object.keys(pageNavOptions.dataManagement).length > 0) {
    aip = true
    totalCount += Object.keys(pageNavOptions.dataManagement).length
  }

  if (Object.keys(pageNavOptions.dataModification).length > 0) {
    aip = true
    totalCount += Object.keys(pageNavOptions.dataModification).length
  }

  if (!aip && pageNavOptions.length > 0) { aip = true }

  const allowPopup = { allow: aip, count: totalCount }

  return allowPopup
}

/**
 * 
 * @param {object} props 
 * @param {object} item_state 
 * @returns propSections_allowed and styleAndClassB
 */
export const ammendViewItemsSettings = (props, item_state, aps_appUserSettings) => {

  const {
    aps_global,
    appUser_fns,
    desktopMode,
    display_global,
    groupDataProps,
    pageViewItemKeys,
    pathViews,
    appAccessType,
    viewItem,
    viewKey,
  } = props ?? {}

  const { propGroups, itemData, _display, propOverrideOn, _propsToggled } = item_state ?? {}
  const { propArrays } = propGroups ?? {}

  const { hideSelectedPropsAndSections } = display_global ?? {}

  let {
    selectedTab
  } = props ?? {}

  const { props: props_viewItem, propSections, display, desktopDisplay, itemColor, drillDown } = viewItem ?? {}
  const _display_vi = _display ? _display : display
  let _display_actual = desktopMode && desktopDisplay ? desktopDisplay : _display_vi

  const { colors } = itemColor ?? {}

  const styleAndClassB = {}
  if (colors) { setItemStyleAndClass(aps_global, styleAndClassB, colors) }
  styleAndClassB.className = g_cns.item_content //+ ' ' + uivi

  if (groupDataProps && groupDataProps.defaultTab && !selectedTab) { selectedTab = groupDataProps.defaultTab }

  // appAccessType, pathViews, props_viewItem, display, viewKey
  appUser_fns.validateAccess_props(appAccessType, pathViews, props_viewItem, _display_actual, viewKey, propOverrideOn, _propsToggled)
  appUser_fns.validateAccess_sections(pathViews, propSections, drillDown, null, _display_actual, appAccessType, viewKey, propOverrideOn)

  if (hideSelectedPropsAndSections && !propOverrideOn) {
    excludePropSections(props_viewItem, propSections)
  }

  const propSections_allowed = getPropSectionsGrouped(props_viewItem, propSections, propOverrideOn ? true : false, null, display_global, propGroups, viewItem, aps_appUserSettings, itemData, propOverrideOn)

  if (propSections_allowed) {
    _.forEach(propSections_allowed, (propSection_allowed, psKey) => {
      const { _allowSection } = propSection_allowed
      if (!_allowSection) {
        delete propSections_allowed[psKey]
      } else {
        switch (_allowSection) {
          case gEnums.viewPermissionTypes.deny:
            delete propSections_allowed[psKey]
            break;
          default:
            const { props: props_section_allowed } = propSection_allowed ?? {}
            ammendSectionStyles(aps_global, propSection_allowed)
            propSection_allowed._props_allowed = getUiAllowedProps(psKey, viewItem, props_section_allowed, desktopMode, null, propArrays, pageViewItemKeys, propOverrideOn)

        }
      }

    })
  }

  return { propSections_allowed, styleAndClassB }
}

const excludePropSections = (props_viewItem, propSections) => {
  const items = {
    tagged: _.filter(props_viewItem, { [gEnums.menuItemOptionTypes.tagged]: true }),
    schedule: _.filter(props_viewItem, { [gEnums.menuItemOptionTypes.schedule]: true }),
  }
  _.forEach(items, (item) => {
    _.forEach(item, (itemItem) => {
      const { propSection } = itemItem
      if (propSections && propSections[propSection]) {
        propSections[propSection]._allowSection = gEnums.viewPermissionTypes.deny
      }
    })
  })
}

const ammendSectionStyles = (aps_global, propSection_allowed) => {

  const { key: key_propSection } = propSection_allowed ?? {}
  const { display: display_global } = aps_global ?? {}
  const { propsToSeparate: propsToSeparate_global } = display_global ?? {}

  const { sectionBody, sectionDisplay, sectionHeader } = sectionMerge(aps_global, propSection_allowed)
  const sectionBodyStyle = getSectionThemeColors(aps_global, sectionBody)

  let { bodyDisplayType } = sectionBody ?? {}
  let { sectionDisplayType } = sectionDisplay ?? {}

  switch (bodyDisplayType) {
    case gEnums.contentTypes.header:
      sectionBody.bodyDisplayType = gEnums.sectionBodyTypes.inline
      sectionHeader.headerDisplayType = gEnums.sectionHeaderTypes.none
      break;
    default:
    // nothing
  }

  let isSeparate;

  if ((sectionDisplayType === gEnums.sectionDisplayTypes.description) || (propsToSeparate_global && propsToSeparate_global.includes(key_propSection))) { isSeparate = true }
  if ((sectionDisplayType === gEnums.sectionDisplayTypes.bio) || (propsToSeparate_global && propsToSeparate_global.includes(key_propSection))) { isSeparate = true }

  if (isSeparate) {
    sectionHeader.headerDisplayType = gEnums.sectionHeaderTypes.none
    sectionBody.bodyDisplayType = gEnums.sectionBodyTypes.noCaptions
  }

  propSection_allowed._styles = {
    sectionBody,
    sectionBodyStyle,
    sectionHeader,
    sectionDisplay,
  }
}

const getUiAllowedProps = (psKey, viewItem, props_viewItem, desktopMode, ddGroupIndex, propArrays, pageViewItemKeys, propOverrideOn) => {

  const { display, desktopDisplay, key: key_viewItem } = viewItem
  const _display = desktopMode && desktopDisplay ? desktopDisplay : display
  const { ignoreTaggedProps, cardDisplayType } = _display ?? {}

  const currentAllowedProps = {}

  // get the props that are allowed
  if (props_viewItem) {

    let index = 0

    _.forEach(props_viewItem, (prop_viewItem, key) => {

      const { _allowProp, propType, display: display_prop, data, show } = prop_viewItem ?? {}
      let { prop: prop_key } = prop_viewItem ?? {}
      let { elemPropType } = display_prop ?? {}

      switch (_allowProp) {
        case gEnums.viewPermissionTypes.allow:
        case gEnums.viewPermissionTypes.allowOverride:
          let _allowUiProp = true

          let { eventKey } = data ?? {}

          // set defaults if not there
          if (!elemPropType && propType) { elemPropType = propType }
          if (!prop_key) { prop_key = prop_viewItem.key }
          if (eventKey && (prop_key !== eventKey)) { prop_key = eventKey }

          const { allowed } = checkAllowed(psKey, key, key_viewItem, prop_key, index, _sectionProps, ignoreTaggedProps, propArrays, pageViewItemKeys, cardDisplayType)

          if (!allowed) {
            _allowUiProp = false
          }

          if (_allowUiProp || propOverrideOn) {
            currentAllowedProps[prop_key] = prop_viewItem
            currentAllowedProps[prop_key]._show = show
          }
          break;
        default:

      }

      // if (_allowProp && show) {

      //   let _allowUiProp = true

      //   let { eventKey } = data ?? {}

      //   // set defaults if not there
      //   if (!elemPropType && propType) { elemPropType = propType }
      //   if (!prop_key) { prop_key = prop_viewItem.key }
      //   if (eventKey && (prop_key !== eventKey)) { prop_key = eventKey }

      //   const { allowed } = checkAllowed(psKey, key, key_viewItem, prop_key, index, _sectionProps, ignoreTaggedProps, propArrays, pageViewItemKeys, cardDisplayType)

      //   if (!allowed) {
      //     _allowUiProp = false
      //   }

      //   if (_allowUiProp || propOverrideOn) {
      //     currentAllowedProps[prop_key] = prop_viewItem
      //     currentAllowedProps[prop_key]._show = show
      //   }
      // }
      index++
    })
  }
  return currentAllowedProps

}

const checkAllowed = (psKey, key, key_viewItem, prop_key, index, _sectionProps, ignoreTaggedProps, propArrays, pageViewItemKeys, cardDisplayType) => {

  let allowed = true
  let reason = ''

  if (prop_key.indexOf('UTC') >= 0) {
    allowed = false
    reason = 'UTC'
  }

  if (_sectionProps.hidden.includes(key)) {
    allowed = false
    reason = 'In Section Props'
  }
  if (psKey === 'schedule') {
    switch (cardDisplayType) {
      case gEnums.cardDisplayTypes.schedule:
      case gEnums.cardDisplayTypes.schedulePlus:
        if (_sectionProps.schedule.includes(key)) {
          allowed = false
          reason = 'schedule'
        }
        break;
      default:
      // nothing
    }
  }

  if (index > 0) {
    if (ignoreTaggedProps && propArrays && propArrays.includes(prop_key)) {
      allowed = false
      reason = 'ignoreTaggedProps'
    }
    if (pageViewItemKeys && pageViewItemKeys.includes(key) && key_viewItem !== key) {
      allowed = false
      reason = 'pageViewItemKeys'
    }
  } else {

  }

  return { allowed, reason }

}

const ammendVld = ({ filterOpts, groupingOpts, appUserAccess, viewItem, viewListData, viewItemOpts, logging }) => {
  const f = []
  return postFilterData({
    ...filterOpts,
    groupingOpts,
    appUserAccess,
    logging,
    viewItem,
    viewListData,
    viewItemOpts,
    f
  })
}

// const updateStaticView = (actionItem) => {

//   const { pathViews, viewListData, staticView, uniqueProps, viewItem } = actionItem
//   const staticItems = getStaticItems(Object.values(viewListData), 'name', uniqueProps)

//   createItemKeys(staticView)
//   createItemKeys(staticItems)

//   const sorted = {
//     staticView: _.sortBy(staticView, 'name'),
//     staticItems: _.sortBy(staticItems, 'name')
//   }

//   const areEqual = _.isEqualWith(sorted.staticItems, sorted.staticView, (obj1, obj2) => {
//     return _.isEqual(_.sortBy(_.toPairs(obj1)), _.sortBy(_.toPairs(obj2)));
//   });

//   const missing = {
//     staticView: _.differenceWith(sorted.staticItems, sorted.staticView, _.isEqual),
//     staticItems: _.differenceWith(sorted.staticView, sorted.staticItems, _.isEqual)
//   }

//   // console.log('staticItems', staticItems)
//   // console.log('viewListData', viewListData)
//   // console.log('areEqual', areEqual)
//   // console.log('sorted', sorted)

//   if (!areEqual && missing.staticView.length > 0) {
//     console.log('Updating the static views', viewItem.key)
//     removeItemKeys(staticItems)
//     saveToStaticViews(pathViews, viewItem, staticItems, viewItem.key)
//   }
// }