import React from 'react'
import { Sidebar } from 'semantic-ui-react'
import { uniqueKey } from '../../../../global/common/keys'

const MatchScheduleSidebar = (props) => {

  const {
    content,
    onHide,
    sidebarContent,
    visible,
  } = props ?? {}

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    <Sidebar
      animation={'overlay'}
      direction={'bottom'}
      onHide={() => onHide(false)}
      visible={visible}
      width='thin'
      className='ms-realtime'
      key={uniqueKey('ms', 'sb')}
    >
      {visible && sidebarContent}
    </Sidebar>
    <Sidebar.Pusher dimmed={visible} style={{ height: '100%' }}>
      {content}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  return sidebar()

}

export default MatchScheduleSidebar