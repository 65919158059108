import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Grid, Icon, Segment } from 'semantic-ui-react';
import { AppFormsContext } from '../../cnr/contexts/AppFormsContext';
import { HeaderContext } from '../../cnr/contexts/HeaderContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { StartContext } from '../../cnr/contexts/StartContext';
import { signInHandlers, signInReducer, signInReducerInitialState } from '../../cnr/reducers/SignInReducer';
import FormForm from '../../components/forms/FormForm';
import { getFormPropz } from '../../components/forms/props/formPropz';
import { gEnums } from '../../enums/globalEnums';
import { iconColorTypes } from '../../enums/settingsIconTypes';

export const SignInTemp = () => {

  const parentContext = useContext(ParentContext);
  const { states, handlers, fns, settings } = parentContext ?? {}
  const { page_state, paps_state } = states
  const { functions_handlers } = handlers
  const { database_fns } = fns ?? {}
  const { clientSettings } = settings ?? {}

  // startContext
  const startContext = useContext(StartContext);
  const { start_handlers } = startContext ?? {}
  const { handleAmmend_startDimmer } = start_handlers ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings } = pageSettings ?? {}
  const { appAccess } = aps_global
  const { tempAuthEmails, tempAuthPasscode } = appAccess ?? {}

  const { global: global_client } = clientSettings ?? {}
  const { appSignIn: appSignIn_client } = global_client ?? {}
  const { forceEventSignInAtClient: forceEventSignInAtClient_c, useClientSignInSettings: useClientSignIn_c } = appSignIn_client ?? {}

  // headerContext
  const headerContext = useContext(HeaderContext)
  const { header_handlers } = headerContext ?? {}
  const { handlePageCaption } = header_handlers ?? {}

  // appFormsContext
  const appFormsContext = useContext(AppFormsContext);
  const { appForms_state, appForms_fns } = appFormsContext ?? {}
  const { appForms, formsUpdated } = appForms_state ?? {}
  const { getForms } = appForms_fns ?? {}

  // pageContext  
  const { appSignIn, topMenu, adminNotifications } = aps_global ?? {}
  const { signInToClient, useClientSignInSettings } = appSignIn ?? {}
  const { mainFixedCaption } = topMenu ?? {}

  const _useClientSignIn = useClientSignIn_c || useClientSignInSettings
  const _appSignIn = _useClientSignIn ? appSignIn_client : appSignIn

  // reducer
  const initState = signInReducerInitialState({ database_fns, functions_handlers, appSignIn: _appSignIn, paps_state, aps_global, aps_appUserSettings, signInMethodType: gEnums.signInFormTypes.signInAuthPasscode, adminNotifications })
  // const initState = signInReducerInitialState({setIsFetching, database_fns, paps_state, aps_global, aps_appUserSettings, gEnums.signInFormTypes.signInAuthPasscode})
  const [signIn_state, dispatch] = useReducer(signInReducer, initState);
  const signIn_handlers = signInHandlers(dispatch)

  const { creds, profileData_signIn, signInMessage, signInFormType, fetching } = signIn_state ?? {}

  const [formProps, setFormProps] = useState()
  const [viewProps, setViewProps] = useState()
  const [tempAllowed, setTempAllowed] = useState()

  // form
  useEffect(() => {
    handleAmmend_startDimmer()
    if (appForms && appForms[signInFormType]) {
      const vprs = appForms[signInFormType]
      const fpp = { form_handlers: signIn_handlers, viewProps: vprs, data_current: profileData_signIn }
      const fprs = getFormPropz(fpp)
      setFormProps(fprs)
      setViewProps(vprs)
    } else {
      getForms()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [signInFormType, formsUpdated]);

  // caption
  useEffect(() => {
    if (handlePageCaption) { handlePageCaption('Temp Sign In') }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidateTemp = () => {
    const { passcode, email } = creds ?? {}
    const temp = _.find(tempAuthEmails, { value: email })
    if (temp && tempAuthPasscode === passcode) {
      setTempAllowed(true)
    }
  }

  const validateForm = () => {

    let infoReady = true

    if (viewProps) {
      Object.keys(viewProps).forEach(key => {
        const itemProp = viewProps[key]
        if (itemProp && itemProp.data && itemProp.data.required) {
          if (creds && !creds[key]) {
            infoReady = false
          }
        }
      })
    }

    return infoReady
  }

  const infoReady = validateForm()

  /** Creates the Button for the initial request */
  const buttonSignIn = (buttonColor, buttonCaption) => {
    return <Button
      color={iconColorTypes.info}
      fluid
      size='large'
      disabled={!infoReady}
      onClick={() => { handleValidateTemp() }}
    >
      {buttonCaption}
    </Button>
  }

  const signInFooter = () => {

    let buttonCaption = 'Sign In'
    let buttonColor = 'blue'

    return buttonSignIn(buttonColor, buttonCaption)
  }

  const signInForm = () => <FormForm
    formAttributes={{ autoComplete: 'off' }}
    formProps={formProps}
  />

  const headerIcon = () => {
    const checkIcon = tempAllowed ? 'check' : 'delete'
    return <Icon.Group size='huge'>
      <Icon name='user' color={'blue'} />
      {fetching && <Icon corner loading name='cog' />}
      {!fetching && signInFormType === gEnums.signInFormTypes.signInPassword && <Icon corner name={checkIcon} />}
    </Icon.Group>
  }

  const headerDiv = () => {
    const { signInMessage } = signIn_state ?? {}
    const { title, content } = signInMessage ?? {}
    return <React.Fragment>
      <div>{title}</div>
      <div>{content}</div>
    </React.Fragment>
  }

  const si = () => {
    return <Segment placeholder className={'seg-sign-in'} >
      <Grid textAlign='center'>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column>
            {headerIcon()}
          </Grid.Column>
        </Grid.Row>
        {signInMessage && signInMessage.success === false && <Grid.Row verticalAlign='middle'>
          <Grid.Column>
            {headerDiv()}
          </Grid.Column>
        </Grid.Row>}
        <Grid.Row verticalAlign='middle'>
          <Grid.Column>
            {signInForm()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column>
            {signInFooter()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment >
  }

  if (formProps) { return si() }

  return <div></div>

}