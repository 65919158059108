export const appKeys = {
  meetingevolution: {
    dev: {
      // webPushCertificate: 'BDTIIDS85ulGTTR59p8z8mI0d26Ciqgdg5kr3H7nNSIRCJpZlgzoSnuVftoWcybRufECXdSTdTTssm_x9pBjSQI',
      // serverKey: 'AAAAEoXfzG0:APA91bGaK5SEwRYSD5JmhC5YuV48VTaRjU87Wbtlhmky2HT9-N85pUEx6ie7ZQSN3FH9ywA0Kl1lURuijczcIvrRW02MVPMg65rxp3a6n34bMC9hRNcRE_ZEgJKPxTKNqHJPGmHzWqs-',
      apiKey: 'AIzaSyDHbf1FjfK5gM89jIv6ZcP9_9QY-cX2ufc',
      publicVapidKey: 'BDTIIDS85ulGTTR59p8z8mI0d26Ciqgdg5kr3H7nNSIRCJpZlgzoSnuVftoWcybRufECXdSTdTTssm_x9pBjSQI',
    },
    prod: {
      // webPushCertificate: 'BFMq02vAp5NSMzl_hfZivK48cQ1JUM0rko8RXh7zSnh28CCH1L9v5gSk-Y-08YaZmck2fjsMXKdZZKaMenB_zq0',
      // serverKey: 'AAAADHB7Cu8:APA91bE3Y2fkqwSs80OtbPu7C4TdxG7YvaXPKKW8tG45UXCUcT7oss6jhIsSnY7Ivp-Bn_Ggj3BmogMpgM4kT50rZeqWe9viXohiUjGRVl7R_cR5oWY75w7g4atMT3anLxLK-cvnbxXf',
      apiKey: 'AIzaSyBAnPqX2PmWY_cxjm48cUyyYDBEMGmxvPQ',
      publicVapidKey: 'BFMq02vAp5NSMzl_hfZivK48cQ1JUM0rko8RXh7zSnh28CCH1L9v5gSk-Y-08YaZmck2fjsMXKdZZKaMenB_zq0',
    }
  }
}