import _ from 'lodash';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import { GoogleSheetsContext } from '../../cnr/contexts/GoogleSheetsContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { googleModeTypes } from '../../cnr/reducers/GoogleSheetsReducer';
import { uniqueKey } from '../../common/keys';
import { appIconTypes } from '../../enums/appIconTypes';
import { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import TransitionWrapper from '../../wrappers/TransitionWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import SuspenseDimmer from '../alerts/SuspenseDimmer';
import UiSaveButtons from '../buttons/UiSaveButtons';
import DataSidebar from '../swipers/DataSidebar';
import AppDashboardContent from './AppDashboardContent';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';

export const allDataModes = {
  allClientAppUsers: 'allClientAppUsers',
  appCheck: 'appCheck',
  appData: 'appData',
  appDocuments: 'appDocuments',
  appUsers: 'appUsers',
  clientAppUsers: 'clientAppUsers',
  clientProfiles: 'clientProfiles',
  fileManagement: 'fileManagement',
  googleDashboard: 'googleDashboard',
  googleRegistration: 'googleRegistration',
  imageMapping: 'imageMapping',
  import: 'import',
  locationMapping: 'locationMapping',
  moveDataTo: 'moveDataTo',
  recreateEvent: 'recreateEvent',
  registrationData: 'registrationData',
  quickView: 'quickView',
  transferData: 'transferData',
  transferSettings: 'transferSettings',
  transferAppSettings: 'transferAppSettings',
  transferManifests: 'transferManifests',
  transferStorage: 'transferStorage',
  zoneMapping: 'zoneMapping',
}

export const appViewerColors = {
  dmv: 'black',
  gsv: 'blue',
  fsv: 'black'
}

const _caption = 'Dashboard'

/**
 * This is the overall dataViewer for the app
 * @param {object} props 
 * @returns AppDataViewer, GoogleSheetsViewer or RegDataViewer
 * @contexts (GoogleSheetsContext, RegDataContext)
 */
const AppDashboard = (props) => {

  const { noModal } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appSettings_state, page_state, paps_state, transition_state } = states ?? {}
  const { selectedDataMode } = appSettings_state ?? {}
  const { pathViews } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { googleSheets } = aps_global ?? {}
  const { googleSheetsId: googleSheetsId_existing } = googleSheets ?? {}

  const isEvent = pathViews.events
  const isClient = pathViews.clients && !isEvent

  const { transitions } = transition_state ?? {}
  const transition = transitions ? transitions[_animateTypes.component] : null
  const { showTransition } = transition ?? {}

  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { selectedDataMode: selectedDataMode_fw } = framework_state ?? {}

  const _selectedDataMode = selectedDataMode_fw ? selectedDataMode_fw : selectedDataMode

  // uiItemContext - will not exist if from landing page
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers, item_state } = uiItemContext ?? {}
  const { handleClose_openedItem } = item_handlers ?? {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_handlers } = googleSheetsContext ?? {}

  const soloItem = item_state ? true : false

  // local state
  const [captions, setCaptions] = useState({ topper: _caption, topper2: 'App Data' })
  const [activeDashboardMenuItems, setActiveDashboardMenuItems] = useState({})
  const [showSideMenu, setShowSideMenu] = useState()
  const [currentDataMode, setCurrentDataMode] = useState()

  // get the menuItems that will appear in the side menu
  useEffect(() => {
    let _menuItems = {}
    if (soloItem) {
      _menuItems[allDataModes.appData] = allDataModes.appData
    } else {
      _menuItems[allDataModes.appCheck] = allDataModes.appCheck
      _menuItems[allDataModes.appData] = allDataModes.appData
      _menuItems[allDataModes.appDocuments] = allDataModes.appDocuments
      _menuItems[allDataModes.transferData] = allDataModes.transferData
      if (isEvent) {
        _menuItems[allDataModes.appUsers] = allDataModes.appUsers
        _menuItems[allDataModes.fileManagement] = allDataModes.fileManagement
        _menuItems[allDataModes.googleDashboard] = allDataModes.googleDashboard
        _menuItems[allDataModes.googleRegistration] = allDataModes.googleRegistration
        _menuItems[allDataModes.locationMapping] = allDataModes.locationMapping
        _menuItems[allDataModes.moveDataTo] = allDataModes.moveDataTo
        _menuItems[allDataModes.quickView] = allDataModes.quickView
        _menuItems[allDataModes.recreateEvent] = allDataModes.recreateEvent
        _menuItems[allDataModes.registrationData] = allDataModes.registrationData
        _menuItems[allDataModes.transferStorage] = allDataModes.transferStorage
        _menuItems[allDataModes.zoneMapping] = allDataModes.zoneMapping
      }
      if (isClient) {
        // _activeDataModes[allDataModes.clientAppUsers] = allDataModes.clientAppUsers
        _menuItems[allDataModes.clientProfiles] = allDataModes.clientProfiles
        _menuItems[allDataModes.transferStorage] = allDataModes.transferStorage
      }
    }
    // the active dataMode will be appData, unless _selectedDataMode has a value
    setCurrentDataMode(_selectedDataMode ? _selectedDataMode : allDataModes.appData)
    setCaptions({ topper: _caption, topper2: _.startCase(_selectedDataMode ? _selectedDataMode : allDataModes.appData) })
    setActiveDashboardMenuItems(_menuItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleSheetsId_existing, _selectedDataMode]);

  const handleCancel = () => {
    props.handleCancel && props.handleCancel()
    googleSheets_handlers && googleSheets_handlers.handleSet_googleMode(googleModeTypes.appData)
    if (handleClose_openedItem) { handleClose_openedItem() }
  }

  const handleShowSideMenu = () => setShowSideMenu(!showSideMenu)

  /**
   * changes the dataMode for hte componnent
   * @param {object} adm 
   */
  const handleDataMode = (adm) => {
    if (_selectedDataMode) {
      handleCancel()
      setShowSideMenu()
    } else {
      const _adm = adm ? adm : allDataModes.appData
      setCurrentDataMode(_adm)
      setCaptions({ topper: _caption, topper2: _.startCase(_adm) })
      setShowSideMenu()
    }
  }

  /**
   * 
   * @returns one button that opens the dashboard side menu
   */
  const buttons_mode = () => {
    const btns = [{ caption: _.startCase(currentDataMode), oc: handleShowSideMenu, icon: 'unordered list' }]
    return <UiSaveButtons
      others={btns}
      color={'black'}
    />
  }

  /**
   * 
   * @returns a wrapper containing a header and content for the dashboard.
   * The header contains a button to apend the menu containing the activeDashboardMenuItems.
   * 
   */
  const wrapper_content = () => <Wrapper
    header={buttons_mode()}
    content={currentDataMode && <AppDashboardContent currentDataMode={currentDataMode} handleDataMode={handleDataMode} />}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    css={{ header: 'header-flex', container: 'wrp' }}
  />

  const menu_left = () => {
    const _activeDateMenuItems = _.sortBy(activeDashboardMenuItems)
    const mis = _activeDateMenuItems.map(key => (
      <Menu.Item
        className={currentDataMode === key ? 'sel' : null}
        key={uniqueKey('dv', 'mi', key)}
        onClick={() => { handleDataMode(key) }}>
        {_.startCase(key)}
        <Icon color={'blue'} name={appIconTypes[key]} />
      </Menu.Item>)
    )
    return <TransitionWrapper>
      <Menu vertical fluid inverted className={'av-menu ad'}>{mis}</Menu>
    </TransitionWrapper>
  }

  const sidebar_left = (visible) => <DataSidebar direction={'left'} visible={visible} content={visible && menu_left()} onHide={setShowSideMenu} />

  /**
   * 
   * @returns a sidebar containing the activeDashboardMenuItems and
   * The content, depending on the currentDataMode
   */
  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_left(showSideMenu ? true : false)}
    <Sidebar.Pusher dimmed={showSideMenu ? true : false} className={'h100'}>
      {wrapper_content()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const fullPageWrapper = () => <FullPageWrapper
    content={_selectedDataMode ? wrapper_content() : sidebar()}
    handleCancel={handleCancel}
    topperCaption={captions.topper}
    topperCaption2={captions.topper2}
    fullMode={true}
    noModal={noModal}
    css={{ container: 'app-dashboard' }}
    ignoreStyle={true}
  />

  if (showTransition) {
    return <Suspense fallback={<SuspenseDimmer origin={'AppDashboard'} />}>
      <MotionComponent transition={transition}>
        {fullPageWrapper()}
      </MotionComponent>
    </Suspense>
  } else {
    return <Suspense fallback={<SuspenseDimmer origin={'AppDashboard'} />}>
      {fullPageWrapper()}
    </Suspense>
  }

}

export default AppDashboard