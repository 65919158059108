import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { gEnums } from '../../enums/globalEnums';
import { headerHandlers, headerInitialState, headerReducer } from '../reducers/HeaderReducer';
import { ParentContext } from './ParentContext';

export const HeaderContext = createContext();

/**  
 * @description Sets the views and global for the current view 
 * from the database or static settings.
 */
const HeaderProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state, paps_state, page_state } = states ?? {}

  // appUser_state
  const { appUser } = appUser_state ?? {}

  // paps_state
  const { nonLandingView, view, viewKey } = paps_state ?? {}

  // page_state
  const { pageSettings } = page_state ?? {}
  const { aps_page, aps_global, aps_appUserSettings } = pageSettings ?? {}
  const { pageDataCaption } = page_state ?? {}
  const { topMenu: topMenu_app, projectOptions } = aps_global ?? {}
  const { allowFavorites, favoriteCollections } = aps_appUserSettings ?? {}
  const { allowSiteSearch } = projectOptions ?? {}

  let {
    mainFixedCaption,
    mainFixedSubCaption,
    captionCharacterMax,
  } = topMenu_app ?? {}

  let { menu: menu_page } = aps_page ?? {}
  let { captionType, caption: caption_page } = menu_page ?? {}

  const _showFavorite = viewKey && allowFavorites && favoriteCollections && favoriteCollections.includes(view)

  let _captionCharacterMax = captionCharacterMax ? captionCharacterMax : 36
  const _appCaptions = { main: mainFixedCaption }

  switch (view) {
    case 'landing':
      break;
    default:
      // if this is NOT a landing page
      _appCaptions.main = _.startCase(nonLandingView)
  }

  const init_state = { appCaptions: _appCaptions, allowSiteSearch, menuTitleMax: _captionCharacterMax }
  const [header_state, header_dispatch] = useReducer(headerReducer, headerInitialState(init_state));
  const header_handlers = headerHandlers(header_dispatch, header_state)

  useEffect(() => {

    switch (view) {
      case 'landing':
        // if this is a landing page
        _appCaptions.main = mainFixedCaption
        _appCaptions.sub = mainFixedSubCaption
        break;

      case 'appProfile':
        _appCaptions.main = appUser ? 'Profile - ' + appUser.displayName : 'Profile'
        break;

      default:
        if (pageDataCaption) {
          _appCaptions.main = pageDataCaption
        } else {
          _appCaptions.main = menu_page ? menu_page.caption : 'caption'
        }
    }

    if (allowSiteSearch) { _captionCharacterMax = _captionCharacterMax - 3 }
    if (_showFavorite) { _captionCharacterMax = _captionCharacterMax - 3 }

    switch (captionType) {
      case gEnums.captionTypes.fixed:
        _appCaptions.main = caption_page
        break;
      default:
      // nothing
    }

    if (_appCaptions.main && _appCaptions.main.length > _captionCharacterMax) { _appCaptions.main = _appCaptions.main.substring(0, _captionCharacterMax) + '...' }

    header_handlers.handleUpdateHeader(_appCaptions)

    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [menu_page, pageDataCaption]);

  return (
    <HeaderContext.Provider value={{ ...props, header_handlers, header_state }}>
      {props.children}
    </HeaderContext.Provider>
  )
}

export default HeaderProvider