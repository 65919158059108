import { dispatchProps } from "../cnr/reducers/reducerHelpers/dispatchProps";
import { callFunction } from "./fbAll";

const transferGenericTypes = {
  transferGeneric: 'transferGeneric',
}

const transferFunctionTypes = {
  getOtherData: 'getOtherData',
  getProdData: 'getProdData',
  transferAppSettings: 'transferAppSettings',
  transferClientToProd: 'transferClientToProd',
  transferCollectionsData: 'transferCollectionsData',
  transferDataBetweenDatabases: 'transferDataBetweenDatabases',
  transferDataToProd: 'transferDataToProd',
  transferDirect: 'transferDirect',
  transferDirectPath: 'transferDirectPath',
  transferDirectCollection: 'transferDirectCollection',
  transferEventToProd: 'transferEventToProd',
  transferManifests: 'transferManifests',
  transferSettings: 'transferSettings',
  transferSettingsToProd: 'transferSettingsToProd',
  transferStorageToProduction: 'transferStorageToProduction',
}

const _showLogs = false

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(transferGenericTypes.transferGeneric, callData, true)
  return data
}

export const fsfn_transfer = {
  getProductionData: (pathViews, collectionName, productionSettings, logging, callback) => fsfn_getProductionData(pathViews, collectionName, productionSettings, logging, callback),
  transferAppSettings: (projectIds, callback) => fsfn_transferAppSettings(projectIds, callback),
  transferClientToProd: (createInfo, pathViews, productionSettings, optz, logging) => fsfn_transferClientToProd(createInfo, pathViews, productionSettings, optz, logging),
  transferCollectionsData: (projectIds, pvKeys, showLogs, transfers, clearCollections, callback) => fsfn_transferCollectionsData(projectIds, pvKeys, showLogs, transfers, clearCollections, callback),
  transferDataBetweenDatabases: (collections, source, destination, merge, showLogs) => transferDataBetweenDatabases(collections, source, destination, merge, showLogs),
  transferDataToProd: (pathViews, collections, itemKey, productionSettings, dataToTransfer, merge, logging) => fsfn_transferDataToProd(pathViews, collections, itemKey, productionSettings, dataToTransfer, merge, logging),
  transferDirect_path: (projectIds, paths, dispatch) => fsfn_transferDirect_path(projectIds, paths, dispatch),
  transferDirect_ts: (projectIds, showLogs, paths, currentItem, subDocument) => fsfn_transferDirect_ts(projectIds, showLogs, paths, currentItem, subDocument),
  transferDirect: (fss, showLogs, paths, transferCollections) => fsfn_transferDirect(fss, showLogs, paths, transferCollections),
  transferEventToProd: (createInfo, pathViews, productionSettings, optz, logging) => fsfn_transferEventToProd(createInfo, pathViews, productionSettings, optz, logging),
  transferManifests: (projectIds, pvKeys, callback) => fsfn_transferManifests(projectIds, pvKeys, callback),
  transferSettings: (projectIds, pvKeys, callback) => fsfn_transferSettings(projectIds, pvKeys, callback),
  transferSettingsToProd: (settingsDocName, logging) => fsfn_transferSettingsToProd(settingsDocName, logging),
}

export const fsfn_transferDirect = async (fss, showLogs, paths, transferCollections) => {

  const projectIds = {
    source: fss && fss.source && fss.source._databaseId && fss.source._databaseId.projectId ? fss.source._databaseId.projectId : null,
    destination: fss && fss.destination && fss.destination._databaseId && fss.destination._databaseId.projectId ? fss.destination._databaseId.projectId : null
  }

  const callData = { projectIds, showLogs, paths, transferCollections }
  callData.transferFunctionType = transferFunctionTypes.transferDirect
  try {
    _showLogs && console.log('fsfn_transferDirect- callData', callData)
    const response_data = await callFsFunction(callData) // OK
    _showLogs && console.log('fsfn_transferDirect - response_data', response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_transferDirect_path = async (projectIds, paths, dispatch) => {
  const callData = { projectIds, paths, showLogs: true }
  callData.transferFunctionType = transferFunctionTypes.transferDirectPath
  try {
    _showLogs && console.log('fsfn_transferDirect_path- callData', callData)
    const response_data = await callFsFunction(callData) // OK
    _showLogs && console.log('fsfn_transferDirect_path - response_data', response_data)
    dispatch && dispatch({ type: dispatchProps.success, dispatch })
    return response_data
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_transferDirect_ts = async (projectIds, showLogs, paths, currentItem, subDocument) => {
  const callData = { projectIds, showLogs, paths, subCollection: currentItem.subCollection, subDocument }
  callData.transferFunctionType = transferFunctionTypes.transferDirectCollection
  try {
    _showLogs && console.log('fsfn_transferDirect- callData', callData)
    const response_data = await callFsFunction(callData) // OK
    _showLogs && console.log('fsfn_transferDirect - response_data', response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_transferCollectionsData = async (projectIds, pvKeys, showLogs, transfers, clearCollections, callback) => {

  const callData = { pvKeys, projectIds, showLogs, transfers, clearCollections }
  callData.transferFunctionType = transferFunctionTypes.transferCollectionsData
  try {
    _showLogs && console.log('fsfn_transferData- callData', callData)
    const response_data = await callFsFunction(callData)
    _showLogs && console.log('fsfn_transferData - response_data', response_data)
    callback && callback(response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

export const transferDataBetweenDatabases = async (collections, source, destination, merge, showLogs) => {
  const callData = { collections, source, destination, merge, showLogs }
  callData.transferFunctionType = transferFunctionTypes.transferDataBetweenDatabases
  try {
    _showLogs && console.log('callData', callData)
    const response_data = await callFsFunction(callData) // OK
    _showLogs && console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_transferClientToProd = async (createInfo, pathViews, productionSettings, optz, logging) => {
  const callData = { createInfo, pathViews, showLogs: logging && logging.allowLogging && logging.logTransferEventToProd }
  const { noClientSettings } = optz ?? {}
  try {
    const { createInfo } = callData ?? {}
    const { projectData } = createInfo ?? {}
    const { eventData } = projectData ?? {}
    let { id: eventKey } = eventData
    callData.settingsDocKey = eventKey
    callData.pathViews = pathViews
    callData.transferFunctionType = transferFunctionTypes.transferClientToProd
    callData.productionSettings = productionSettings
    callData.noClientSettings = noClientSettings
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}


/**
 * Transfers data to production
 * @param {object} createInfo 
 * @param {string} pathViews 
 * @param {object} logging 
 * @returns 
 */
export const fsfn_transferEventToProd = async (createInfo, pathViews, productionSettings, optz, logging) => {
  const callData = { createInfo, pathViews, showLogs: logging && logging.allowLogging && logging.logTransferEventToProd }
  const { noClientSettings } = optz ?? {}
  try {
    const { createInfo } = callData ?? {}
    const { projectData } = createInfo ?? {}
    const { eventData } = projectData ?? {}
    let { id: eventKey } = eventData
    callData.settingsDocKey = eventKey
    callData.pathViews = pathViews
    callData.transferFunctionType = transferFunctionTypes.transferEventToProd
    callData.productionSettings = productionSettings
    callData.noClientSettings = noClientSettings
    callData.merge = false
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * Transfers settings data to production
 * @param {string} settingsDocName 
 * @param {boolean} logging 
 * @returns 
 */
export const fsfn_transferSettings = async (projectIds, pvKeys, callback) => {
  const callData = { transferFunctionType: transferFunctionTypes.transferSettings, projectIds, pvKeys, showLogs: true }
  try {
    _showLogs && console.log('fsfn_transferSettings - callData', callData)
    const response_data = await callFsFunction(callData) // OK
    _showLogs && console.log('fsfn_transferSettings - response_data', response_data)
    callback && callback(response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * Transfers settings data to production
 * @param {string} settingsDocName 
 * @param {boolean} logging 
 * @returns 
 */
export const fsfn_transferManifests = async (projectIds, pvKeys, callback) => {
  const callData = { transferFunctionType: transferFunctionTypes.transferManifests, projectIds, pvKeys, showLogs: true }
  try {
    _showLogs && console.log('fsfn_transferManifests - callData', callData)
    const response_data = await callFsFunction(callData) // OK
    _showLogs && console.log('fsfn_transferManifests - response_data', response_data)
    callback && callback(response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} projectIds 
 * @returns the result
 * @description updates the appSettings and appForms
 */
export const fsfn_transferAppSettings = async (projectIds, callback) => {
  const callData = { transferFunctionType: transferFunctionTypes.transferAppSettings, projectIds, showLogs: true }
  try {
    _showLogs && console.log('fsfn_transferAppSettings - callData', callData)
    const response_data = await callFsFunction(callData) // OK
    _showLogs && console.log('fsfn_transferAppSettings - response_data', response_data)
    callback && callback(response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}


/**
 * Transfers settings data to production
 * @param {string} settingsDocName 
 * @param {boolean} logging 
 * @returns 
 */
export const fsfn_transferSettingsToProd = async (settingsDocName, logging) => {
  // const callData = { transferFunctionType: transferFunctionTypes.transferSettingsToProd, settingsDocKey: settingsDocName, showLogs: logging && logging.allowLogging && logging.logTransferSettingsToProd }
  const callData = { transferFunctionType: transferFunctionTypes.transferSettingsToProd, settingsDocKey: settingsDocName, showLogs: true }
  try {
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * Gets the data in Dev add the data to Production using Set
 * @param {string} pathViews 
 * @param {object} collections 
 * @param {object} logging 
 * @returns response_data {success, result, error}
 */
export const fsfn_transferDataToProd = async (pathViews, collections, itemKey, productionSettings, dataToTransfer, merge, logging) => {
  const callData = {
    createInfo: {
      collections,
      itemKey,
      dataToTransfer
    },
    pathViews,
    productionSettings,
    showLogs: true, //logging && logging.allowLogging && logging.logTransferSettingsToProd,
    transferFunctionType: transferFunctionTypes.transferDataToProd,
    merge
  }
  try {
    const response_data = await callFsFunction(callData) // OK 
    return response_data
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_getProductionData = async (pathViews, collectionName, productionSettings, logging, callback) => {
  const callData = {
    createInfo: {
      collectionName
    },
    pathViews,
    productionSettings,
    showLogs: logging && logging.allowLogging && logging.logTransferSettingsToProd,
    transferFunctionType: transferFunctionTypes.getProdData
  }
  try {
    const response_data = await callFsFunction(callData) // OK
    const { result } = response_data
    callback && callback(result)
    return response_data
  } catch (error) {
    console.error(error)
  }
}