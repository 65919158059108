import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { Dimmer } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { pageDataHandlers, pageDataInitialState, pageDataReducer } from '../reducers/PageDataReducer';
import { ParentContext } from './ParentContext';

export const PageDataContext = createContext();

/**
 * 
 * @param {object} props 
 * @returns 
 */
const PageDataProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states, handlers, fns } = parentContext ?? {}
  const { appUser_state, eventInfo_state, googleData_state, paps_state, page_state } = states ?? {}
  const { page_handlers, startData_handlers } = handlers ?? {}
  const { page_fns, database_fns } = fns ?? {}


  // authContext
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  // papsContext 
  const { pathViews, pathName, view, viewKey } = paps_state ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_page } = pageSettings ?? {}
  const { menu: menu_page, viewItems: viewItems_page } = aps_page ?? {}
  const { captionProps, caption: caption_page, subCaptionProps } = menu_page ?? {}

  let viewItem_page = viewItems_page && viewItems_page[view] ? viewItems_page[view] : {}
  const { dataSource, dataConstraints } = viewItem_page ?? {}

  const _dataConstraints = dataConstraints ? dataConstraints : dataSource

  const { staticViews } = eventInfo_state ?? {}
  const { googleDataOn } = googleData_state ?? {}

  // IMPORTANT: Page Data - Get the data state
  const init_state = { database_fns, pathName, captionProps, subCaptionProps, caption_page, staticViews }
  const [pageData_state, pageData_dispatch] = useReducer(pageDataReducer, pageDataInitialState(init_state));
  const pageData_handlers = pageDataHandlers(pageData_dispatch, pageData_state)

  const { pageDateRetrieved, currentPageDataCaption, currentPageDataSubCaption, pageDataKey, currentPageData } = pageData_state ?? {}

  useEffect(() => {

    const pdProps = {
      appUserAccess,
      pageData_state,
      googleData_state,
      isPageData: true,
      page_state,
      paps_state,
      page_fns,
      staticViews,
      view,
      viewKey,
    }

    pageData_handlers.handleGetPageData(pdProps)

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathName, _dataConstraints, googleDataOn]);

  useEffect(() => {
    if (currentPageDataCaption && page_handlers) {
      page_handlers.handleUpdatePageDataCaption(currentPageDataCaption, currentPageDataSubCaption)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentPageDataCaption, currentPageDataSubCaption]);

  useEffect(() => {
    if (pageDataKey && currentPageData) {
      startData_handlers.handlePageData(pageDataKey, currentPageData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pageDataKey]);

  return pageDateRetrieved ? <PageDataContext.Provider value={{ pageData_handlers, pageData_state }}>
    {props.children}
  </PageDataContext.Provider>
    :
    <Dimmer active={true}  ><div className={'pending-wait'}></div></Dimmer>
}

export default PageDataProvider