import React, { useContext } from 'react';
import { Sidebar } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { gEnums } from '../../enums/globalEnums';

const DataSidebar = (props) => {

  const { visible, content, onHide, direction, width } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state } = states
  const { pageSettings } = page_state ?? {}
  const { aps_styles } = pageSettings ?? {}
  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.actionItems] : {}

  const sidebar = () => <Sidebar
    {...styleAndClass}
    animation={'overlay'}
    direction={direction ? direction : 'left'}
    icon='labeled'
    visible={visible}
    width={width ? width : 'very wide'}
    className='vsr-container'
    onHide={() => onHide()}
  >
    {visible && content}
  </Sidebar>

  return visible ? sidebar() : <div></div>

}

export default DataSidebar