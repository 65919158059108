import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Divider, Header, Icon, Label, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { formatItem, formatTypes, getFormattedDate } from '../../../../global/common/dateFormatting';
import { uniqueKey } from '../../../../global/common/keys';
import { sortObj } from '../../../../global/common/sorting';
import CalMonths from '../../../../global/components/calendar/CalMonths';
import { gEnums } from '../../../../global/enums/globalEnums';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper from '../../../../global/wrappers/Wrapper';
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext';
import { SportsSeasonContext, matchScheduleTypes } from '../../cnr/contexts/SportsSeasonContext';
import { _showTypes } from '../../cnr/reducers/SeasonMatchesReducer';
import { _matchLsTypes } from '../../cnr/reducers/SportsDataReducer';
import { sportsShowTypes } from '../../cnr/reducers/SportsSeasonReducer';
import { sportsHelpers } from '../../cnr/reducers/reducerHelpers/sportsHelpers';
import { getSectionColor } from '../../create/sectionColors';
import { _sdm } from '../dashboard/SportsDashboard';
import SectionStandings from '../standings/SectionStandings';
import MatchCreate from './MatchCreate';
import MatchDataUpdate from './MatchDataUpdate';
import MatchList from './MatchList';
import MatchScheduleHeader from './MatchScheduleHeader';
import MatchWrapper, { sportsMatchViewTypes } from './MatchWrapper';
import MatchCount from './MatchCount';
import ScheduleLegend from '../standings/ScheduleLegend';

const _allowByes = true
const _labelSize = 'tiny'

const matchHeaderIcons = {
  today: 'arrow right',
  todayS: 'arrow down',
  calendar: 'calendar alternate outline',
  list: 'list',
}

const _circulars = {
  matchCount: false,
  playoff: false,
  edit: false,
  today: true,
}

const MatchListSelected = (props) => {

  const { teamKey, dateKey, forOverview, forSummary } = props ?? {}

  const today = getFormattedDate(new Date())

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, appUser_state } = states
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { sportSettings } = aps_global ?? {}
  const { matchResultFullBackground } = sportSettings ?? {}

  // authContext 
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { accessLevel } = appUserAccess ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers, sportsSeason_fns } = sportsSeasonContext ?? {}
  const { latestSeason, mediaMatches, matches_info, matchScheduleDisplayType, useScrollTo, useScoreBlock, _startHour, parentDataKeys, _shows_sports } = sportsSeason_state ?? {}
  const { matchDateKeys_count, matchDateKeys, matchDateGroups } = matches_info ?? {}
  const { _matchStatusGroups } = mediaMatches ?? {}
  const { allUpdates } = _matchStatusGroups ?? {}
  const { matches_seasonal } = parentDataKeys ?? {}

  const getMdt = (teamKey) => {
    const filteredMatches = _.flatMap(matchDateGroups, item => {
      return _.flatMap(item.matches, match => {
        if (match.home && match.away && (match.home.id === teamKey || match.away.id === teamKey)) {
          return [match];
        }
        return [];
      });
    });
    const grouped = _.groupBy(filteredMatches, 'startDate')
    const x = []
    _.forEach(grouped, (value, keyOrIndex) => {
      x.push({
        matchDate: keyOrIndex,
        matches: value
      })
    })
    return x
  }

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state, seasonMatches_handlers } = seasonMatchesContext ?? {}
  const {
    _shows_matches,
    commonMatches,
    dateMatches,
    dateMatchResults,
    isTournament,
    lastDatesSelected,
    levelDateGroup,
    levelDateKey,
    levelKey: levelKey_selected,
    matchScheduleType,
    pathViews,
    poolMatches,
    scrollToDate,
    scrollToDateIndex,
    sepBySection,
    teamMatches,
    visibleLevelDates,
    showMissing,
    tournamentType,
    forPlayoff,
    playoffDates,
  } = seasonMatches_state

  const {
    matchResults: showMatchResults,
  } = _shows_sports ?? {}

  const {
    teamCalendar: showTeamCalendar,
    calendar: showCalendar,
    expand: showExpand,
    newMatch: showNewMatch,
  } = _shows_matches ?? {}

  const { levelDateMatches, sectionDateMatches, favMatches, matchDate } = dateMatchResults ?? {}

  // const pds = _.filter(sectionDateMatches, { isPlayoff: true })
  // const playoffDates = pds && Object.keys(pds).length > 0

  let showDateHeader;
  let headerFormatType;

  switch (matchScheduleDisplayType) {
    case gEnums.matchScheduleDisplayTypes.allDates:
    case gEnums.matchScheduleDisplayTypes.clickedDates:
      showDateHeader = true
      headerFormatType = formatTypes.fullDate
      break;
    default:
      headerFormatType = formatTypes.shorterDate
    // nothing
  }

  const scheduleTypes = {
    district: pathViews && pathViews.districts && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    match: pathViews && pathViews.matches ? true : false,
    sport: pathViews && pathViews.sports ? true : false,
    team: teamKey || (pathViews && pathViews.teams) ? true : false,
    common: commonMatches ? true : false,
    date: dateKey ? true : false,
  }

  const showByes = _allowByes && (scheduleTypes.team)

  const [availableDates, setAvailableDates] = useState()
  const [availableTeams, setAvailableTeams] = useState()
  const [matchesReady] = useState(true)
  const [selectedAvailableDate, setSelectedAvailableDate] = useState()
  const [showMissingOnly, setShowMissingOnly] = useState()
  const [showEditDate] = useState()
  const [matchesLoaded, setMatchesLoaded] = useState()
  const [scrollDiv, setScrollDiv] = useState()
  const [scrollDivId, setScrollDivId] = useState()

  let _selectedDate = lastDatesSelected && lastDatesSelected[levelKey_selected] ? lastDatesSelected[levelKey_selected] : levelDateKey
  if (scrollToDate) {
    _selectedDate = scrollToDate
  }

  const scollToRef = useRef(); // eslint-disable-next-line     
  const parentDivRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => setMatchesLoaded(true), 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_selectedDate, levelKey_selected]);

  useEffect(() => {
    if (matchesLoaded && _selectedDate && useScrollTo && scollToRef && scollToRef.current && !forPlayoff) {
      if (scollToRef.current) {
        const selectedDiv = scollToRef.current
        if (selectedDiv && selectedDiv.getAttribute('las') === levelKey_selected) {
          setScrollDiv(selectedDiv)
          setScrollDivId(selectedDiv.id)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToDateIndex, scrollToDate, _selectedDate, matchesLoaded, levelKey_selected]);

  useEffect(() => {
    if (scrollDiv) {
      setTimeout(function () {
        scrollDiv.scrollIntoView({
          behavior: "instant",
        });
        if (!showExpand) {
          // seasonMatches_handlers.handleShow_matches(_showTypes.expand, null, true)
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToDateIndex, scrollDivId, scrollDiv]);

  /**
 * 
 * @param {date} matchDate 
 * @handles the date click from the calendar
 */
  const handleCalSelectedDate = (d) => {
    if (d === 'Missing') {
      setShowMissingOnly(!showMissingOnly)
    } else {
      seasonMatches_handlers.handle_setVisibleDates(levelKey_selected, d)
    }
    seasonMatches_handlers.handleShow_matches(_showTypes.calendar)
  }

  /**
   * 
   * @param {date} matchDate 
   * @handles the date header click
   */
  const handleDateHeaderClick = (matchDate) => seasonMatches_handlers.handle_setVisibleDates(levelKey_selected, matchDate)

  /**
   * 
   * @handles the scroll of the matches into view
   */

  const handleGetAvailableTeams = () => {
    if (availableTeams) {
      setAvailableTeams()
    } else if (availableDates) {
      setAvailableDates()
    } else {
      const ta = sportsSeason_fns.getTeamsAvailableByAllDates()
      setAvailableDates(ta)
      // setAvailableTeams(_.sortBy(ta, 'name'))
    }
  }

  const handleSelectedAvailableDate = (sd) => setSelectedAvailableDate(sd === selectedAvailableDate ? null : sd)

  const handleEditDate = (e, md) => {
    e.stopPropagation()
    const opts = { sportsShowType: sportsShowTypes.matchResults, matchDate: md }
    sportsSeason_handlers.handleShow_sportsElem(opts)
  }

  // const countLabel = (matchCount, color, title) => <Label size={_labelSize} color={color} circular={_circulars.matchCount} title={title}>{matchCount}</Label>
  // const playoffLabel = () => <Label size={_labelSize} color={'green'} circular={_circulars.playoff} title={'Playoff'}>{'P'}</Label>
  // const editDateLabel = (matchDate) => <Label size={_labelSize} color={'black'} circular={_circulars.edit} title={'Edit Date'} onClick={(e) => { handleEditDate(e, matchDate) }}>
  //   <Icon name={'edit'} style={{ margin: 0 }} />
  // </Label>

  const todayLabel = (md, dateSelected) => <Label key={uniqueKey('mls', 'tl', md)} circular={_circulars.today} size={_labelSize} color={'grey'} title={dateSelected ? 'Collapse' : 'Expand'}>
    <Icon name={dateSelected ? matchHeaderIcons.todayS : matchHeaderIcons.today} style={{ margin: 0 }} />
  </Label>

  const singleMatch = (segs, match, showFavs, keyOrIndex) => {

    const { _status, key, id } = match ?? {}
    const { isBye } = _status ?? {}

    let keyy = key ? key : id
    if (!keyy) { keyy = keyOrIndex }

    if (!isBye || showByes) {
      const m = <MatchWrapper
        key={uniqueKey('mls', 'mw', keyy)}
        sportsMatchViewType={sportsMatchViewTypes.single}
        scheduleTypes={scheduleTypes}
        match={match}
        tournamentType={tournamentType}
        teamKey={teamKey}
        forOverview={forOverview}
        forSummary={forSummary}
      />
      segs.push(m)
    }
  }

  const matchList = (matches, showFavs, isFuture) => {

    let segs = []
    let _matches = matches;

    // if (showMissing) {
    //   _matches = _.filter(matches, function (m) { return m?._status?.isMissing })
    // } else {
    //   _matches = matches
    // }
    // console.log('showMissing', showMissing, _matches)

    if (_.isArray(_matches)) {
      if (_matches && _matches.length > 0) {
        _.forEach(_matches, (match, index) => {
          singleMatch(segs, match, showFavs, index)
        })
      }
    } else {
      if (_matches && Object.keys(_matches).length > 0) {
        _.forEach(_matches, (match, key) => {
          singleMatch(segs, match, showFavs, key)
        })
      }
    }

    return segs.length > 0 ? segs : <Segment key={uniqueKey('mls', 'ml')} className={'seg-ph-full'} placeholder style={{ height: '100%', textAlign: 'center', fontWeight: 'bold' }}>{isFuture ? 'No Upcoming Matches' : 'No Matches'}</Segment>
  }

  const sectionLabel = (section) => <Label circular size={_labelSize} color={getSectionColor(section)}></Label>

  const divider = (value, icon, index, sectionKey) => <Divider key={uniqueKey('mls', 'tm', index, value)} horizontal>
    <Header as='h5'>
      {icon && <Icon name={icon} />}
      {value}
      {sectionKey && sectionLabel(sectionKey)}
    </Header>
  </Divider>

  /**
   * 
   * @param {string} matchDate 
   * @param {number} matchCount 
   * @param {boolean} showCount 
   * @param {number} nrCount 
   * @param {boolean} dateSelected 
   * @returns 
   */
  const dateHeader = (matchDate, showCount, matchCounts, dateSelected, _isToday) =>
    <div key={uniqueKey('mls', 'd', levelKey_selected, matchDate)}
      onClick={() => { handleDateHeaderClick(matchDate) }}
      las={levelKey_selected}
      id={matchDate}
      ref={(matchDate === _selectedDate) ? scollToRef : null}
    >
      <div>{formatItem(headerFormatType, matchDate)}</div>

      <div className={'sdw-lbls'}>
        {showCount && <MatchCount matchCounts={matchCounts} />}
        {todayLabel(matchDate, dateSelected)}
      </div>
    </div>

  const noMatchesDiv = () => ""

  const matchSegs = (sectionSegs, sectionMatchSegs, sectionKey, keyy, index) => {
    sectionSegs.push(<div key={uniqueKey('mls', 'lds', sectionKey)} className={'schedule-section'}>
      {sectionKey && sectionKey !== 'undefined' && divider(_.startCase(sectionKey, index), null, null, sectionKey)}
      <div key={uniqueKey('mls', 'lds', 'st')} className={'schedule-section-teams'}>{sectionMatchSegs}</div>
    </div>)
  }

  const sectionDateSegs = (matches, segsArr, levelKey, matchDate, index) => {

    const _levelKey = levelKey ? levelKey : levelKey_selected

    let showMatches;
    let showCount;
    let showMediaCount;
    let mediaCount;

    if (allUpdates) {
      const newArray = _.intersection(Object.keys(matches), Object.keys(allUpdates));
      mediaCount = newArray.length
    }

    const matchCount = matches ? Object.keys(matches).length : 0

    const matches_normal = _.filter(matches, function (m) { return !m?._status?.isBye })
    const matches_playoffs = _.filter(matches, function (m) { return m.isPlayoff && !m?._status?.isBye })

    const matchCount_normal = matches_normal ? Object.keys(matches_normal).length : 0
    const matchCount_playoffs = matches_playoffs ? Object.keys(matches_playoffs).length : 0

    const pastNonScore = matches ? _.filter(matches, function (m) { return m?._status?.isPast && !m?._status?.isBye && !m._results?.complete; }) : null
    const nrCount = pastNonScore ? pastNonScore.length : 0
    const isPlayoffs = matchCount_normal === matchCount_playoffs

    switch (matchScheduleDisplayType) {
      case gEnums.matchScheduleDisplayTypes.clickedDates:
        showMatches = visibleLevelDates && visibleLevelDates[_levelKey] && visibleLevelDates[_levelKey].includes(matchDate)
        showCount = true
        showMediaCount = true
        break;
      default:
        showMatches = true
    }

    if (matches) {
      Object.keys(matches).forEach(mk => {
        if (matches[mk].sections) {
          matches[mk].sections = matches[mk].sections.trim()
        }
      })
    }

    const matchInfo = {
      matchDate,
      levelKey,
      inSection: _.filter(matches, function (m) { return !m.isPlayoff && (m.away && m.home) && (m.away.sections === m.home.sections && m.away.levels === m.home.levels); }),
      nonSection: _.filter(matches, function (m) { return !m.isPlayoff && m.levels !== 'nonDistrict' && (m.away && m.home) && (m.away.sections !== m.home.sections || m.away.levels !== m.home.levels); }),
      nonDistrict: _.filter(matches, function (m) { return !m.isPlayoff && (m.levels === 'nonDistrict'); }),
      tournaments: _.filter(matches, function (m) { return !m.isPlayoff && (m.away && m.home && !m.isPlayoff) && (m.home.levels === _matchLsTypes.tournament); }),
      playoff: _.filter(matches, function (m) { return (m.isPlayoff); })
    }

    matchInfo.groupedBySection = _.groupBy(matchInfo.inSection, 'sections')
    matchInfo.groupedBySectionSorted = sortObj(matchInfo.groupedBySection)

    // loop Sections  
    const sectionSegs = []
    const nonSectionSegs = []
    const nonDistrictSegs = []
    const tournamentSegs = []

    // section matches
    Object.keys(matchInfo.groupedBySectionSorted).forEach(sectionKey => {
      const keyy = levelKey + '-' + sectionKey
      const sectionMatches = matchInfo.groupedBySection[sectionKey]
      const sectionMatchesSorted = _.sortBy(sectionMatches, ['home.name']);
      const sectionMatchSegs = matchList(sectionMatchesSorted, true)
      matchSegs(sectionSegs, sectionMatchSegs, sectionKey, keyy, index)
    })

    // non section matches
    if (matchInfo.nonSection && matchInfo.nonSection.length > 0) {
      const nonSectionSorted = _.sortBy(matchInfo.nonSection, ['home.name']);
      const xxx = matchList(nonSectionSorted, true)
      matchSegs(nonSectionSegs, xxx, 'nonSection', levelKey + '-non', index)
    }

    // non distcit matches
    if (matchInfo.nonDistrict && matchInfo.nonDistrict.length > 0) {
      const nonDistrictSorted = _.sortBy(matchInfo.nonDistrict, ['home.name']);
      const xxx = matchList(nonDistrictSorted, true)
      matchSegs(nonDistrictSegs, xxx, 'nonDistrict', levelKey + '-non', index)
    }

    if (matchInfo.tournaments && matchInfo.tournaments.length > 0) {
      const xxx = matchList(matchInfo.tournaments, true)
      matchSegs(tournamentSegs, xxx, _matchLsTypes.tournament, levelKey + '-non', index)
    }

    if (matchInfo.playoff && matchInfo.playoff.length > 0) {
      const _playoffMatches = _.sortBy(matchInfo.playoff, 'matchNumber')
      const xxx = matchList(_playoffMatches, true)
      matchSegs(nonSectionSegs, xxx, 'playoff', levelKey + '-non', index)
    }

    const _sectionSegs = [...sectionSegs, ...nonSectionSegs, ...nonDistrictSegs]

    if (_sectionSegs.length > 0) {
      let cn_sections = 'sdw'
      if (isPlayoffs) { cn_sections += ' playoffs' }
      if (matchDate) {
        const _isToday = today === matchDate
        const matchCounts = { matchDate, matchCount, nrCount, _isToday, showMediaCount, mediaCount, isPlayoffs, allowEdit: accessLevel >= gEnums.accessLevels.superAdmin.value }
        segsArr.push(<div key={uniqueKey('mls', matchDate)} className={cn_sections}>
          {dateHeader(matchDate, showCount, matchCounts, showMatches, _isToday)}
          {(showMatches || showExpand) && <div key={uniqueKey('mls', 'lds', 'sl', matchDate)} className={'schedule-level'}>
            <div key={uniqueKey('mls', 'lds', 'sls', matchDate)} className={'schedule-level-section'}>{_sectionSegs.length > 0 ? _sectionSegs : noMatchesDiv()}</div>
          </div>}
        </div>)
      } else {
        segsArr.push(<div key={uniqueKey('mls', 'lds', 'sl')} className={'schedule-level'}>
          <div key={uniqueKey('mls', 'lds', 'sls')} className={'schedule-level-section'}>{_sectionSegs.length > 0 ? _sectionSegs : noMatchesDiv()}</div>
        </div>)
      }
    }

  }

  const favSegs = (fs) => {
    if (favMatches) {
      const favMatchSegs = matchList(favMatches)
      fs.unshift(<div key={uniqueKey('mls', 'lds', 'f')} className={'schedule-section fav'}>
        <Divider horizontal>{'Following'}</Divider>
        <div key={uniqueKey('mls', 'lds', 'fst')} className={'schedule-section-teams'}>{favMatchSegs}</div>
      </div>)
    }
  }

  const todaysMatches = () => {

    const levelSegs = []
    const _favSegs = []

    favSegs(_favSegs)

    if (sepBySection) {
      switch (matchScheduleDisplayType) {
        case gEnums.matchScheduleDisplayTypes.allDates:
        case gEnums.matchScheduleDisplayTypes.clickedDates:
          if (levelDateGroup) {
            Object.keys(levelDateGroup).forEach((dgKey, index) => {
              const levelGroupDate = levelDateGroup[dgKey]
              const { matchDate, matches } = levelGroupDate ?? {}
              sectionDateSegs(matches, levelSegs, null, matchDate, index)
            })
          }
          break;
        case gEnums.matchScheduleDisplayTypes.levelDates:
          levelSegs.push(<div>TEST</div>)
          break;
        default:
          sectionDateSegs(sectionDateMatches, levelSegs)
      }
    } else if (levelDateMatches) {
      Object.keys(levelDateMatches).forEach(levelKey => {
        const sectionSegs = []
        let sections = _.groupBy(levelDateMatches[levelKey], 'sections')
        const ss = sortObj(sections)
        Object.keys(ss).forEach(sectionKey => {
          const sectionMatches = sections[sectionKey]
          sectionDateSegs(sectionMatches, sectionSegs, levelKey)
        })

        levelSegs.push(<div key={uniqueKey('mls', 'tm')} className={'schedule-level'}>
          <div key={uniqueKey('mls', 'tm', 'h')} className={'schedule-level-header'}>{levelKey}</div>
          <div key={uniqueKey('mls', 'tm', 'ls')} className={'schedule-level-section'}>{sectionSegs}</div>
        </div>)
      })
    }

    let cn = 'schedule-dates'
    if (!matchesReady) { cn += ' nr' }

    return <div key={uniqueKey('mls', 'sd')} className={cn}>
      {showDateHeader && <MatchScheduleHeader />}
      <div className={'schedule-dates-wrapper'}>
        <div key={uniqueKey('mls', 'sl')} ref={parentDivRef} className={'schedule-levels'}>{_favSegs}{levelSegs}</div>
        <ScheduleLegend />
      </div>
    </div>
  }

  const todaysPlayoffMatches = () => {
    const sectionMatchesSorted = _.sortBy(sectionDateMatches, 'matchNumber')
    const sectionMatchSegs = matchList(sectionMatchesSorted)
    return <div key={uniqueKey('mls', 'tpm')} className={'schedule-dates'}>
      <div key={uniqueKey('mls', 'tpm', 'sl')} ref={parentDivRef} className={'schedule-levels'}>{sectionMatchSegs}</div>
    </div>
  }

  const wrapper_availableTeams = () => <Wrapper
    content={<SectionStandings sectionOpts={{ teams: availableTeams }} caption={'Idle Teams'} levelKey={levelKey_selected} />}
  ></Wrapper>

  const wrapper_availableDates = () => {
    const dateItems = []
    if (availableDates) {
      Object.keys(availableDates).forEach(adk => {
        const availableTeams = availableDates[adk]
        const _ateams = _.sortBy(availableTeams, 'name')
        const teamCount = _ateams ? _ateams.length : 0
        dateItems.push(<div className={'available-teams'}>
          <div onClick={() => { handleSelectedAvailableDate(adk) }}>{formatItem(formatTypes.fullDate, adk)}<Label size={_labelSize} color='blue'>{teamCount}</Label></div>
          {selectedAvailableDate === adk && <SectionStandings sectionOpts={{ teams: _ateams }} caption={'Idle Teams'} />}
        </div>)
      })
    }
    return <Wrapper
      content={dateItems}
    ></Wrapper>
  }

  const fpw_availableTeams = () => <FullPageWrapper
    content={wrapper_availableTeams()}
    handleCancel={handleGetAvailableTeams}
    topperCaption={'Idle Teams'}
    topperCaption2={matchDate}
  ></FullPageWrapper>

  const fpw_availableDates = () => <FullPageWrapper
    content={wrapper_availableDates()}
    handleCancel={handleGetAvailableTeams}
    topperCaption={'Idle Teams'}
    topperCaption2={matchDate}
  ></FullPageWrapper>

  const wrapper_calendar = () => {
    let _matchDateKeys = matchDateKeys
    let _matchDateKeys_count = matchDateKeys_count
    let _showToday = true
    let x;
    if (showTeamCalendar && pathViews.teams) {
      x = getMdt(pathViews.teams)
      const mdks = sportsHelpers.getMatchDateKeys(x)
      const { matchDateKeys: k, matchDateKeys_count: c } = mdks ?? {}
      _matchDateKeys = k
      _matchDateKeys_count = c
      _showToday = false
    }
    const sidebarItem = {
      items: _matchDateKeys,
      itemz: _matchDateKeys_count,
      itemsData: x,
      keyAs: "matchDates",
      showToday: _showToday,
    }

    return <Wrapper
      content={<CalMonths
        sidebarItem={sidebarItem}
        handleClick={handleCalSelectedDate}
        handleClose={seasonMatches_handlers.handleShow_matches}
        teamKey={pathViews.teams}
      />}
      wrapperCss={{ container: 'app-dark-mode' }}
    >
    </Wrapper>
  }

  const fpw_calendar = () => <FullPageWrapper
    content={wrapper_calendar()}
    handleCancel={seasonMatches_handlers.handleShow_matches}
    cancelOptions={_showTypes.calendar}
    topperCaption={'Match Calendar'}
  ></FullPageWrapper>

  const fpw_results = () => <FullPageWrapper
    content={<MatchDataUpdate fromApp={true} forSportsManagement={true} sportsDataMode={_sdm.sports.seasonResults} />}
    handleCancel={seasonMatches_handlers.handleShow_matches}
    cancelOptions={_showTypes.matchResults}
    topperCaption={'Match Results'}
    topperCaption2={latestSeason + ' : ' + matches_seasonal}
  ></FullPageWrapper>

  const fpw_editDate = () => {
    const mm = _.find(levelDateGroup, { matchDate: showEditDate })
    return <FullPageWrapper
      content={<MatchList matches={mm && mm.matches} allowEdits={true} showEditDate={showEditDate} />}
      handleCancel={handleEditDate}
      cancelOptions={_showTypes.matchResults}
      topperCaption={'Date Matches'}
    ></FullPageWrapper>
  }

  const content = () => {
    if (showEditDate) {
      return fpw_editDate()
    } else if (showMatchResults) {
      return fpw_results()
    } else if (showCalendar || showTeamCalendar) {
      return fpw_calendar()
    } else {
      if (availableTeams) {
        return fpw_availableTeams()
      } else if (availableDates) {
        return fpw_availableDates()
      } else if (isTournament) {
        const pms = _.sortBy(poolMatches, 'matchNumber')
        return <div className={'tournament-match-container'}>{matchList(pms)}</div>
      } else {
        if (scheduleTypes.date) {
          return <div>TEST</div>
        } else if (scheduleTypes.common) {
          return matchList(commonMatches)
        } else if (scheduleTypes.match && !matchScheduleType) {
          // MATCH  
          return matchList(dateMatches)
        } else if (scheduleTypes.team) {
          // TEAM
          switch (matchScheduleType) {
            case matchScheduleTypes.latest:
            case matchScheduleTypes.next:
              if (teamMatches && teamMatches.length > 0) {
                return matchList(teamMatches)
              }
              break
            default:
              if (dateMatches) {
                const xxx = Object.values(teamMatches)
                const today_before = new Date().setHours(_startHour, 0, 0, 0);
                const today_after = new Date().setHours(_startHour, 0, 0, 0);
                const matchesBeforeToday = xxx.filter(match => new Date(match.startDate).setHours(_startHour, 0, 0, 0) < today_before);
                const matchesTodayAndBeyond = xxx.filter(match => new Date(match.startDate).setHours(_startHour, 0, 0, 0) >= today_after);
                return <div>
                  {matchesBeforeToday && matchList(matchesBeforeToday)}
                  {matchesTodayAndBeyond &&
                    <div>
                      {/* <div ref={scollToRef} className='upcoming-matches'>Upcoming Matches</div> */}
                      {matchList(matchesTodayAndBeyond, null, true)}
                    </div>
                  }
                </div>
              } else {
                return matchList(teamMatches)
              }
          }
        } else if (playoffDates) {
          return todaysPlayoffMatches()
        } else {
          // LEVEL 
          return showNewMatch ? <MatchCreate
            handleSetShowNewMatch={seasonMatches_handlers.handleShow_matches}
            shows={{ showTeams: true, showDate: true, showLocation: false }}
            matchDate={matchDate} />
            :
            todaysMatches()
        }
      }
    }
  }

  return content()

}

export default MatchListSelected
