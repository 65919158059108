import { addDoc, collection, deleteDoc, doc, setDoc, updateDoc, where } from "firebase/firestore";
import _ from 'lodash';
import { dispatchProps } from "../../cnr/reducers/reducerHelpers/dispatchProps";
import { uploadSingleFile } from "../../cnr/reducers/UploadReducer";
import { currentHelpers } from "../../redirection/current";
import { _storageSettings } from "../../storage/storageHelpers";
import { createRefPath, createRefPath_event } from "./appRefPaths";
import { arrayString, fs_db } from "./fsAppData";

export const fs_dbu = {
  doc_add: (refPath, data) => doc_add(refPath, data),
  doc_delete: (refPath) => doc_delete(refPath),
  doc_set: (refPath, data, merge) => doc_set(refPath, data, merge),
  doc_update_where: (refPath, data, whereProp, whereValue, allowSet) => doc_update_where(refPath, data, whereProp, whereValue, allowSet),
  doc_update: (refPath, data, allowSet) => doc_update(refPath, data, allowSet),
  add_doc: (refPath, data, dispatch, forPromise, appUserAccess, storagePaths, vit) => fs_add_doc(refPath, data, dispatch, forPromise, appUserAccess, storagePaths, vit),
  delete_doc: (refPath, dispatch, forPromise) => fs_delete_doc(refPath, dispatch, forPromise),
  set_doc: (refPath, data, merge, dispatch, forPromise, fs) => fs_set_doc(refPath, data, merge, dispatch, forPromise, fs),
  update_dataCollection: (pathViews, collectionName, docId, itemData, callback) => fs_update_dataCollection(pathViews, collectionName, docId, itemData, callback),
  update_doc: (refPath, data, dispatch, forPromise, appUserAccess, storagePaths, vit) => fs_update_doc(refPath, data, dispatch, forPromise, appUserAccess, storagePaths, vit),
}

const fs_update_dataCollection = (pathViews, collectionName, docId, itemData, callback) => {
  const _updateRef = createRefPath_event(pathViews, [collectionName, docId])
  delete itemData.id
  fs_dbu.update_doc(_updateRef, itemData, callback)
}

/**
 *  
 * @param {any} refPath - A reference to the document to write.
 * @param {object} data -  map of the fields and values for the document.
 * @returns Writes to the document referred to by this DocumentReference. If the document does not yet exist, it will be created.
 */
const doc_update_where = async (refPath, data, whereProp, whereValue, allowSet) => {
  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const wheres = [where(whereProp, '==', whereValue)]
  fs_db.get_data({ refPath: ps, wheres, opts: { returnFirstObject: true } }).then(item => {
    if (item) {
      const _updateRef = createRefPath([item.id], ps)
      fs_dbu.update_doc(_updateRef, data).then(res => {
        console.log('res', res)
      })
    }
  }).catch(() => {
    // callback(null)
  })

}

/**
 *  
 * @param {any} refPath - A reference to the document to write.
 * @param {object} data -  map of the fields and values for the document.
 * @returns Writes to the document referred to by this DocumentReference. If the document does not yet exist, it will be created.
 */
const doc_update = async (refPath, data, allowSet) => {
  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const fs = fs_db.get_fs()
  const ref_d = doc(fs, ps)
  try {
    return updateDoc(ref_d, data)
  } catch (error) {
    console.error(error);
    if (allowSet) {
      return setDoc(ref_d, data)
    }
  }
}

/**
 * This will call the updateDoc function. If the update fails, it will call the setDoc function
 * @param {string or array} refPath 
 * @param {object} data 
 * @param {function} dispatch 
 * @param {boolean} forPromise 
 * @returns boolean
 */
const fs_update_doc = async (refPath, data, dispatch, forPromise, appUserAccess, storagePaths, vit) => {

  const _data = { ...data }

  let _fileImage;
  if (_data.fileImage) {
    _fileImage = _data.fileImage
    delete _data.fileImage
  }

  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const fs = fs_db.get_fs()

  try {
    const ref_d = doc(fs, ps);
    if (forPromise) {
      try {
        return updateDoc(ref_d, _data)
      } catch (error) {
        return fs_dbu.set_doc(refPath, _data, true, dispatch, forPromise)
      }
    }
    const res = await updateDoc(ref_d, _data)
    if (_fileImage) {
      const lp = currentHelpers.getLastPathView(refPath)
      const { lastKey } = lp ?? {}
      uploadFileImage(appUserAccess, { id: lastKey }, _fileImage, storagePaths, vit, null, dispatch)
    } else {
      dispatch && dispatch({ type: dispatchProps.success, dispatch, res, dataToUpdate: _data })
    }
  } catch (error) {
    try {
      fs_dbu.set_doc(refPath, _data, true, dispatch, forPromise)
    } catch (error) {
      console.error(error);
      dispatch && dispatch({ type: dispatchProps.error, error, dispatch, dataToUpdate: _data })
    }
  }
}

/**
 * Writes to the document referred to by the specified DocumentReference. If the document does not yet exist, it will be created. If you provide merge or mergeFields, the provided data can be merged into an existing document.
 * @param {any} refPath - the refPath (string or array)
 * @param {object} data - the data to be updated
 * @param {boolean} merge - whether setDoc will be `merged`
 * @returns a `setDoc` reference  
 */
const doc_set = async (refPath, data, merge) => {
  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const fs = fs_db.get_fs()
  const ref_d = doc(fs, ps)
  return setDoc(ref_d, data, { merge: merge })
}

/**
 * Updates the database document using 'set'
 * @param {string} refPath 
 * @param {object} data 
 * @param {boolean} merge 
 * @param {function} dispatch 
 * @param {boolean} forPromise 
 * @returns dispatch
 */
const fs_set_doc = async (refPath, data, merge, dispatch, forPromise, fs) => {
  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const _fs = fs ? fs : fs_db.get_fs()
  try {
    const ref_d = doc(_fs, ps)
    if (forPromise) { return setDoc(ref_d, data, { merge: merge }) }
    const res = await setDoc(ref_d, data, { merge: merge })
    dispatch && dispatch({ type: dispatchProps.success, dispatch, res, dataToUpdate: data })
  } catch (error) {
    console.error(error);
    console.error('?', _fs)
    dispatch && dispatch({ type: dispatchProps.error, error, dispatch, dataToUpdate: data })
  }
}

/**
 * Add a new document to specified CollectionReference with the given data, assigning it a document ID automatically.
 * @param {any} refPath - the refPath (string or array)
 * @param {object} data - the data to be added
 * @returns A Promise resolved with a DocumentReference pointing to the newly created document after it has been written to the backend (Note that it won't resolve while you're offline).
 */
const doc_add = async (refPath, data) => {
  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const fs = fs_db.get_fs()
  const ref_d = doc(fs, ps)
  return addDoc(ref_d, data)
}

/**
 * 
 * @param {*} refPath 
 * @param {*} data 
 * @param {*} dispatch 
 * @param {*} forPromise 
 * @description Adds a document
 */
const fs_add_doc = async (refPath, data, dispatch, forPromise, appUserAccess, storagePaths, vit) => {

  const _data = { ...data }

  let _fileImage;
  let _fileImages = [];

  if (_data.fileImage) {
    _fileImage = _data.fileImage
    delete _data.fileImage
  }

  if (_data) {
    Object.keys(_data).forEach(key => {
      if (_data[key].type && _data[key].type.indexOf('image') >= 0) {
        _fileImages.push(_data[key])
        delete _data[key]
      }
    })
  }

  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const fs = fs_db.get_fs()
  const ref_c = collection(fs, ps)

  if (forPromise) { return addDoc(ref_c, _data) }

  try {
    const res = await addDoc(ref_c, _data)
    if (_fileImage) {
      uploadFileImage(appUserAccess, res, _fileImage, storagePaths, vit, null, dispatch)
    } else if (_fileImages && _fileImages.length > 0) {
      _fileImages.forEach(fi => {
        uploadFileImage(appUserAccess, res, fi, storagePaths, vit, null, dispatch)
      })
    } else {
      dispatch && dispatch({ type: dispatchProps.success, dispatch, res })
      return res
    }
  } catch (error) {
    console.error(error);
    dispatch && dispatch({ type: dispatchProps.error, error, dispatch })
    return false
  }
}

/**
 * Deletes the document referred to by the specified DocumentReference.
 * @param {any} refPath - the refPath (string or array) 
 * @returns a `deleteDoc` reference (ref_d)
 */
const doc_delete = async (refPath) => {
  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const fs = fs_db.get_fs()
  const ref_d = doc(fs, ps)
  return deleteDoc(ref_d)
}

/**
 * 
 * @param {*} refPath 
 * @param {*} dispatch 
 * @param {*} forPromise 
 * @description Deletes a document 
 */
const fs_delete_doc = async (refPath, dispatch, forPromise) => {
  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const fs = fs_db.get_fs()
  const ref_d = doc(fs, ps)
  if (forPromise) { return deleteDoc(ref_d) }
  deleteDoc(ref_d).then(ref => {
    dispatch && dispatch({ type: dispatchProps.success, dispatch })
    return true
  }).catch(error => {
    dispatch && dispatch({ type: dispatchProps.error, error, dispatch })
    return false
  })
}

const uploadFileImage = async (appUserAccess, result, fileImage, storagePaths, vit, formData, dispatch) => {
  const keyy = result && result.id ? result.id : 'Test'
  const _storageRef = storagePaths.events + '/' + _storageSettings.galleryPaths.galleryDirect + '/' + vit + '/' + keyy
  const props = { file: fileImage, fileKey: 'aaa', appUserAccess, storageRef: _storageRef, formData }
  await uploadSingleFile(props)
  dispatch && dispatch({ type: dispatchProps.success, dispatch })
}