import { deleteField, where } from 'firebase/firestore';
import _ from 'lodash';
import { convertHelpers, createItemPropValues, getObjectLength } from '../../../../../global/common/convert';
import { copyObj } from '../../../../../global/common_web/copy';
import { createRefPath_event } from '../../../../../global/firestoreData/appData/appRefPaths';
import { fs_db } from '../../../../../global/firestoreData/appData/fsAppData';
import { fs_dbu } from '../../../../../global/firestoreData/appData/fsAppDataUpdate';
import { _sportCollections } from '../SportsSidebarReducer';

export const _districtCollectionItems = {
  _teams: { collectionName: '_teams' },
  _matches: { collectionName: '_matches_seasonal', subCollection: 'matches', noYear: true, pks: ['sports'] },
  schools: { collectionName: 'schools' },
  teams: { collectionName: 'teams' },
}


const useBackupTeams = false

export const getDistrictData = (database_fns, pathViews, callback) => {

  const districtData = {
    groupedData: {
      matches_issues: {}
    }
  }

  const cb_data = (data, opts) => {

    const { cn: key, parentKeys } = opts

    districtData[key] = data

    let done = true

    Object.keys(_districtCollectionItems).forEach(dcKey => {
      if (!districtData[dcKey]) {
        done = false
      }
    })

    if (done) {
      combineAllMatches(districtData, parentKeys)
      combineAllTeams(districtData)
      getGroupdData(districtData)
      divideMatchesBySeason(districtData)

      // if (districtData.sports) {
      //   const _sports = {}
      //   const allowwed = ['name', 'gender', 'latestSeason', 'seasons', 'year']
      //   _.forEach(districtData.sports, (sport, key) => {
      //     _sports[key] = {}
      //     _.forEach(sport, (value, prop) => {
      //       if (allowwed.includes(prop) && value) {
      //         _sports[key][prop] = value
      //       }
      //     })
      //     console.log('sport', sport)
      //   })
      //   const pks = getPks(pathViews)
      //   updateSeasonalOrGlobal(seasonalUpdateTypes.sports, pathViews, pks, 'sports', 'sports', _sports, null, { ignoreSeason: true })
      //   // console.log('dataToUpdate', dataToUpdate)
      // }
      callback(districtData)
    }
  }

  const wheres = [where('parentKeys.districts', '==', pathViews.districts)]

  Object.keys(_districtCollectionItems).forEach(dkKey => {
    const dci = _districtCollectionItems[dkKey]
    const { collectionName, subCollection, pks } = dci ?? {}
    const refPath = createRefPath_event(pathViews, [collectionName])
    database_fns.get_data({ refPath: refPath, callback: cb_data, wheres, opts: { cbProps: { cn: dkKey }, subCollection, pks } })
  })
}

export const updateAllMatchesByLevel = async (pathViews, districtData, callback) => {
  const xx = await updateMatchesByLevel(pathViews, districtData)
  callback(xx)
}

export const createSeasonalMatches = async (database_fns, pathViews, districtData, callback) => {

  const { _matches_backup, sports } = districtData

  if (_matches_backup) {

    Object.keys(_matches_backup).forEach(async msk => {

      const mbs = _matches_backup[msk]
      const { parentKeys } = mbs ?? {}
      const { sports: sports_pks } = parentKeys ?? {}
      const _sport = sports[sports_pks]
      const { name: sportName } = _sport ?? {}

      const _refPath = createRefPath_event(pathViews, ['_matches_backup', msk, 'matches'])

      const data_backup = await database_fns.get_data({ refPath: _refPath })

      if (data_backup) {
        const _refPathU = createRefPath_event(pathViews, [_sportCollections._matches_seasonal, msk])
        const dataToRemove = {
          districts: deleteField(),
          organizations: deleteField(),
          sports: deleteField(),
          sportsYears: deleteField(),
        }
        await fs_dbu.update_doc(_refPathU, dataToRemove)

      }
    })
  }
  console.log('_matches_backup', _matches_backup)
}


export const getTeamsAndMatches = (mergeItems, districtData) => {

  const { groupedData, teams } = districtData ?? {}
  const { teams_seasonal, matches_all } = groupedData ?? {}

  const getMergedItem = (index) => {
    return {
      _itemKey: mergeItems[index] ? mergeItems[index]._itemKey : '---',
      name: mergeItems[index] ? mergeItems[index].name : '---',
    }
  }

  if (mergeItems && getObjectLength(mergeItems) === 2) {

    const _schoolsSelected = {
      source: getMergedItem(1),
      destination: getMergedItem(2),
    }

    const _teams_seasonal = getTeamsFromSeasonal(teams_seasonal, _schoolsSelected)

    const _teams = _.pickBy(teams, function (t) {
      if (t) { return (t.name === _schoolsSelected.source.name) }
    })

    const data = {
      schoolsSelected: _schoolsSelected,
      matches_seasonal: fixTheMatches(matches_all, _schoolsSelected),
      teams_seasonal: fixSeasonalTeams(_teams_seasonal, _schoolsSelected),
      teams: fixTheTeams(_teams, _schoolsSelected)
    }

    return data

  }
}

/**
 * 
 * @param {object} teams_seasonal 
 * @param {object} schoolsSelected 
 * @returns all the teams matching the name of the source.
 * Loops through each of the teams_seasonal
 */
const getTeamsFromSeasonal = (teams_seasonal, schoolsSelected) => {

  const { source } = schoolsSelected
  const _teams = []

  if (teams_seasonal) {
    Object.keys(teams_seasonal).forEach(tsk => {
      const team_seasonal = teams_seasonal[tsk]
      const { teams } = team_seasonal
      if (teams) {
        const tt = _.pickBy(teams, function (t) {
          if (t) { return (t.name === source.name) }
        })
        Object.keys(tt).forEach(ttk => {
          const _team = tt[ttk]
          _team._teamSeasonKey = tsk
          _teams.push(_team)
        })
      }
    })
  }

  return _teams

}

const fixTheTeams = (teams, schoolsSelected) => {

  const { source, destination } = schoolsSelected

  const _teams = {}

  // change the data from the teams from
  Object.keys(teams).forEach(tk => {
    const team = teams[tk]
    const _pks = copyObj(team.parentKeys)
    team.old = {
      name: source.name,
      id: source._itemKey,
      parentKeys: _pks
    }
    team.name = destination.name // change the nae of the team 
    team.parentKeys = { ..._pks, schools: destination._itemKey }
    _teams[team._itemKey] = team
  })

  return _teams
}

const fixSeasonalTeams = (teams, schoolsSelected) => {

  const { source, destination } = schoolsSelected

  // only need to change the team NAME here. 
  teams.forEach(t => {
    t.old = { name: source.name }
    t.name = destination.name
    t._itemKey = destination._itemKey
  })

  return teams

}

const fixTheMatches = (matches_all, schoolsSelected) => {

  const { source, destination } = schoolsSelected

  const _matches_seasonal = _.pickBy(matches_all, function (m) {
    if (m) { return (m.home && m.home.name === source.name) || (m.away && m.away.name === source.name) }
  })

  // only need to change the team NAME here. 
  Object.keys(_matches_seasonal).forEach(msk => {
    const m = _matches_seasonal[msk]
    // look for a HOME team match
    if (m.home && m.home.name === source.name) {
      m.home.old = {
        name: source.name,
      }
      m.home.name = destination.name
    }
    // look for a AWAY team match
    if (m.away && m.away.name === source.name) {
      m.away.old = {
        name: source.name,
      }
      m.away.name = destination.name
    }
  })

  return _matches_seasonal

}


const updateMatchesByLevel = async (pathViews, districtData) => {

  const promises = []
  const cn = useBackupTeams ? _districtCollectionItems._matches.collectionName + '_backup' : _districtCollectionItems._matches.collectionName

  const { groupedData } = districtData
  const { seasonalLevelMatches } = groupedData

  Object.keys(seasonalLevelMatches).forEach(async msk => {

    const _refPath = createRefPath_event(pathViews, [cn, msk])
    // const dataToRemove = { matches: deleteField() }

    const seasonLevel = seasonalLevelMatches[msk]

    const { levels, parentKeys } = seasonLevel

    // fs_dbu.update_doc(_refPath, { parentKeys }).then(res => {

    Object.keys(levels).forEach(async levelKey => {

      const level = levels[levelKey]
      const { matches } = level ?? {}
      const dataToUpdate = { matches: matches }
    })
  })

  return Promise.all(promises)

}

/**
 * Set the matches_all object with all the matches from all the matchesSeasonal objects
 * @param {object} districtData 
 */
const combineAllMatches = (districtData) => {
  const { _matches: matchesSeasonal, sports } = districtData
  let matches_all = {}
  Object.keys(matchesSeasonal).forEach(msk => {
    const ms = matchesSeasonal[msk]
    const { _parentKeys } = ms ?? {}
    const { sports: pks_sports, sportsYears } = _parentKeys ?? {}
    const sportName = sports && pks_sports && sports[pks_sports] ? sports[pks_sports].name : null
    Object.keys(ms).forEach(mlk => {
      const item = ms[mlk]
      const { matches } = item ?? {}
      if (matches) {
        createItemPropValues(matches, '_matchSeasonKey', msk)
        createItemPropValues(matches, '_matchLevelKey', mlk)
        createItemPropValues(matches, '_sportName', _.camelCase(sportName))
        createItemPropValues(matches, '_sportsYears', sportsYears)
        createItemPropValues(matches, '_sportsKey', pks_sports)
        Object.assign(matches_all, matches);
      }
    })
  })
  districtData.groupedData.matches_all = matches_all
}

const combineAllTeams = (districtData) => {
  const { _teams: teams_seasonal, teams: teams_full } = districtData
  let teams_seasonal_all = {}
  Object.keys(teams_seasonal).forEach(msk => {
    const item = teams_seasonal[msk]
    const { teams, parentKeys } = item ?? {}
    const { sports } = parentKeys ?? {}
    if (teams) {
      // add the _teamSeasonKey
      Object.keys(teams).forEach(tk => {
        const team_full = teams_full ? teams_full[tk] : {}
        const { parentKeys: parentKeys_team_full } = team_full ?? {}
        const { schools } = parentKeys_team_full ?? {}
        teams[tk]._teamSchoolKey = schools
      })
      createItemPropValues(teams, '_teamSeasonKey', msk)
      createItemPropValues(teams, '_teamSportsKey', sports)
      Object.assign(teams_seasonal_all, teams);
    }
  })
  districtData.groupedData.teams_seasonal = teams_seasonal
  districtData.groupedData.teams_seasonal_all = teams_seasonal_all
}

const ammendLevels = (matches) => {
  Object.keys(matches).forEach(mk => {
    const match = matches[mk]
    const { levels, home, away } = match ?? {}
    if (!levels && home && away && (home.levels === away.levels)) {
      if (home && away && (home.levels === away.levels)) {
        match.levels = home.levels
      } else {
        match.levels = 'Non'
      }
    }
  })
}

const getTeamMatches = (matches_all, teams_seasonal_all) => {
  const _teamMatches = {}
  const matches_home = _.groupBy(matches_all, (m) => (_.has(m, 'home') && m.home.name !== 'BYE' ? m.home.id : 'NoTeam'));
  const matches_away = _.groupBy(matches_all, (m) => (_.has(m, 'away') && m.away.name !== 'BYE' ? m.away.id : 'NoTeam'));
  const matches_combined = Object.assign({}, matches_home, matches_away)
  Object.keys(matches_combined).forEach(teamId => {
    const teamSeasonal = teams_seasonal_all ? teams_seasonal_all[teamId] : {}
    const { name, _teamSchoolKey } = teamSeasonal ?? {}
    _teamMatches[teamId] = { name, matches: {}, schoolKey: _teamSchoolKey }
    const matches_team = matches_combined[teamId]
    const matches_teamSport = _.groupBy(matches_team, '_sportName');
    if (matches_teamSport) {
      Object.keys(matches_teamSport).forEach(sportName => {
        _teamMatches[teamId].matches[sportName] = {}
        const matches_sport = matches_teamSport[sportName]
        const matches_teamSportYear = _.groupBy(matches_sport, '_sportsYears');
        _teamMatches[teamId].matches[sportName] = matches_teamSportYear
      })
    }
  })
  return _teamMatches
}

const divideMatchesBySeason = (districtData) => {
  const { sports, groupedData } = districtData
  const { matches_all, teams_seasonal_all } = groupedData ?? {}
  if (matches_all && sports) {
    ammendLevels(matches_all)
    convertHelpers.createItemKeys(matches_all)
    districtData.groupedData.matches_season = _.groupBy(matches_all, '_matchSeasonKey');
    districtData.groupedData.matches_teamSportYear = getTeamMatches(matches_all, teams_seasonal_all)
  }
}

const getExistingNonObjectTeams = (nonObjectList, objectList) => {

  function matchObjectsMatch(match1, match2) {
    if (match1.home && match1.away && match2.home && match2.away) {
      return _.isEqual(match1.home, { name: match2.home.name }) && _.isEqual(match1.away, { name: match2.away.name });
    }
  }

  const matchesMatched = nonObjectList.filter(match1 => {
    return objectList.some(match2 => matchObjectsMatch(match1, match2));
  });

}

const getGroupdData = (districtData) => {

  const { sports, schools, teams, groupedData } = districtData
  const { matches_all, teams_seasonal_all } = groupedData ?? {}

  if (schools && teams && teams_seasonal_all && matches_all) {

    districtData.groupedData.matches_issues.notFoundTeams = getTeamsNotFound(teams_seasonal_all, teams)

    const { _modifiedTeams, _missingMatchData, _missingMatchObjects, _badSectionMatches } = getMissingMatchTeams(matches_all, teams)

    console.log('_badSectionMatches', _badSectionMatches)

    districtData.groupedData.matches_issues.modifiedTeams = _modifiedTeams
    districtData.groupedData.matches_issues.missingData = _missingMatchData
    districtData.groupedData.matches_issues.missingTeamObjects = _missingMatchObjects
    districtData.groupedData.matches_issues.badSectionMatches = _badSectionMatches

    getExistingNonObjectTeams(Object.values(_missingMatchObjects), Object.values((matches_all)))

    districtData.groupedData.schools_withTeams = getTeamsSchoolSeasonal(teams_seasonal_all, schools, sports)
    districtData.groupedData.teams_bySchool = getTeamsGrouped(teams, schools, 'schools', sports)
    districtData.groupedData.teams_bySport = getTeamsGrouped(teams, sports, 'sports', sports)

    console.log('districtData', districtData)
  }
}

const getTeamsNotFound = (teams_seasonal_all, teams) => {
  const _teamsMismatch = {}
  const teamsNotFound = []
  Object.keys(teams_seasonal_all).forEach(tk => {
    const team = teams_seasonal_all[tk]
    const _team = teams[tk]
    if (!_team) {
      teamsNotFound.push({ tk, team: team })
    } else {
      if (team.name !== _team.name) {
        _teamsMismatch[tk] = {
          ...team,
          name: _team.name,
          name_season: team.name,
          _teamSeasonKey: team._teamSeasonKey,
          teamKey: tk
        }
      }
    }
  })
  return _teamsMismatch
}

const getTeamsGrouped = (teams, items, parentKey, sports) => {

  const groups = {}

  if (items) {
    const _teamsGroupedByNameX = _.groupBy(teams, 'parentKeys.' + parentKey)
    Object.keys(_teamsGroupedByNameX).forEach(groupKey => {
      const _teams_grouped = _teamsGroupedByNameX[groupKey]
      if (_teams_grouped) {
        _teams_grouped.forEach(t => {
          const { parentKeys } = t
          const { sports: pks_sports } = parentKeys ?? {}
          t.sport = sports[pks_sports].name
        })
      }
      const _item = items[groupKey]
      if (_item) {
        groups[groupKey] = {
          name: _item.name,
          _itemKey: _item._itemKey,
          teams: _teams_grouped
        }
      }
    })
  }

  return groups
}

const getTeamsSchoolSeasonal = (teams_seasonal_all, schools, sports) => {

  const groups = {}

  if (schools) {
    const _teamsGroupedByNameX = _.groupBy(teams_seasonal_all, 'name')
    Object.keys(_teamsGroupedByNameX).forEach(groupKey => {
      const _teams_grouped = _teamsGroupedByNameX[groupKey]
      const _item = _.find(schools, { name: groupKey })
      if (_item) {
        groups[_.camelCase(_item.name)] = {
          name: _item.name,
          _itemKey: _item._itemKey,
          teams: _teams_grouped
        }
      }
    })
  }

  return groups
}

const getMissingMatchTeams = (matches_all, teams_seasonal_all) => {

  const _modifiedTeams = []
  const _missingMatchData = []
  const _missingMatchObjects = []
  const _badSectionMatches = []

  Object.keys(matches_all).forEach(key_match => {

    const seasonalMatch = matches_all[key_match]

    const { away, home, _matchSeasonKey, _matchLevelKey } = seasonalMatch

    const { id: id_away, name: name_away, levels: levels_away, sections: sections_away } = away ?? {}
    const { id: id_home, name: name_home, levels: levels_home, sections: sections_home } = home ?? {}

    if (name_away !== 'BYE' && name_home !== 'BYE') {

      if ((levels_away !== levels_home) || (sections_away !== sections_home) && (_matchLevelKey !== 'Non')) {
        switch (_matchLevelKey) {
          case 'Non':
          case 'nonDistrict':
          case 'playoffs':
            // nothing
            break;
          default:
            _badSectionMatches.push({
              ...seasonalMatch,
              _itemKey: key_match,
              _newMatchLevelKey: 'Non'
            })
        }
      }

      const _teamAway = _.find(teams_seasonal_all, { name: name_away })
      const _teamHome = _.find(teams_seasonal_all, { name: name_home })

      if (!teams_seasonal_all[id_away]) {
        _missingMatchObjects.push(seasonalMatch)
      }

      if (!teams_seasonal_all[id_home]) {
        _missingMatchObjects.push(seasonalMatch)
      }

      // Pju8PiddOmSpMaGdLC6o

      if (!away || !away) {
        _missingMatchData.push(seasonalMatch)
      } else {
        if (!_teamAway) {
          _modifiedTeams.push({
            ...seasonalMatch,
            away: {
              ...away,
              name: teams_seasonal_all[id_away] ? teams_seasonal_all[id_away].name : '???',
              name_season: name_away,
            },
            _matchSeasonKey: _matchSeasonKey,
            _matchKey: key_match
          })
        }
        if (!_teamHome) {
          _modifiedTeams.push({
            ...seasonalMatch,
            home: {
              ...home,
              name: teams_seasonal_all[id_home] ? teams_seasonal_all[id_home].name : '???',
              name_season: name_home,
            },
            _matchSeasonKey: _matchSeasonKey,
            _matchKey: key_match
          })
        }
      }
    }
  })

  return { _modifiedTeams, _missingMatchData, _missingMatchObjects, _badSectionMatches }
}

const ammendMatchData = (matches) => {
  const deleteProps = ['setCount', 'bestOf', 'setsToWin']
  Object.keys(matches).forEach(mk => {
    const match = matches[mk]
    deleteProps.forEach(dp => {
      delete match[dp]
    })
    delete match.parentKeys
    if (match.home && match.home.parentKeys) {
      delete match.home.parentKeys
    }
    if (match.away && match.away.parentKeys) {
      delete match.away.parentKeys
    }
    if (match.startDateUTC && !match.startDate) {
      const dateObject = match.startDateUTC.toDate();
      match.startDate = ammendDate(dateObject)
    }
    if (match.startTimeUTC && !match.startTime) {
      const dateObject = match.startTimeUTC.toDate();
      match.startTime = ammendTime(dateObject)
    }
  })
}

const ammendDate = (dateObject) => {
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
  const day = dateObject.getDate().toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  return `${month}/${day}/${year}`
}

const ammendTime = (dateObject) => {
  const hours = dateObject.getHours().toString().padStart(2, '0');
  const minutes = dateObject.getMinutes().toString().padStart(2, '0');
  const startTime = formatTimeWithAMPM(hours, minutes);
  return startTime
}

function formatTimeWithAMPM(hours, minutes) {
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour clock
  return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
}
