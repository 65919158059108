import React, { useContext, useEffect, useRef } from 'react';
import { Label, Menu } from 'semantic-ui-react';
import AlphaProvider, { AlphaContext } from '../../cnr/contexts/AlphaContext';
import { DataContext } from '../../cnr/contexts/DataContext';
import { FilterContext } from '../../cnr/contexts/FilterContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { getTabView, setHorizontalScroll } from '../../cnr/reducers/reducerHelpers/swipeHelpers';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { currentHelpers } from '../../redirection/current';

const AlphabetMenu = (props) => {

  const { isSolo, useVertical, grouping: grouping_props } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext)
  const { states } = parentContext ?? {}
  const { paps_state } = states

  // papsContext  
  const { view, viewKey } = paps_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { viewItem, _groupingOpts } = item_state ?? {}
  const { grouping } = viewItem ?? {}

  let _grouping = _groupingOpts ? _groupingOpts : grouping

  if (useVertical) {
    const grouping = {
      alphaDisplayType: gEnums.alphaDisplayTypes.verticalFull,
      useVerticalHidden: true,
      verticalOpacity: gEnums.opacityTypes.light,
      restrictToExisting: true,
    }
    _grouping = grouping
  } else if (grouping_props) {
    _grouping = grouping_props
  }

  const {
    fetchByAlphabet,
    restrictToExisting,
    showAlphaCount,
    showAlphaHeader,
    showFavorites,
    useHorizontalScroll,
  } = _grouping ?? {}

  const _restrictToExisting = restrictToExisting || (props && props.restrictToExisting)

  // filterContext 
  const filterContext = useContext(FilterContext);
  const { uiFilter_handlers } = filterContext ?? {}

  // alphaContext
  const alphaContext = useContext(AlphaContext);
  const { alpha_state, alpha_handlers } = alphaContext ?? {}
  const { useAlpha, alphaItem, alphaItems, currentAlphaIndex, isVert, letterShowing, letterCount, snc, handleAlphaChange, groupedInfo } = alpha_state ?? {}

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state, data_handlers } = dataContext ?? {}
  const { viewListData } = data_state ?? {}

  const _viewListData = viewListData // dataItems ? dataItems : viewListData

  let storageItem = 'alpha-item-'
  storageItem += viewKey ? view + '-' + viewKey : view

  const _menuContainerId = 'alpha-menu'

  const _currentLetter = currentAlphaIndex ? String.fromCharCode(currentAlphaIndex) : 65

  const scrollableDivRef = useRef(null);

  useEffect(() => {
    const htm = (e) => {
      // Check if the touch event target is within the scrollable div 
      if (e.target.closest('.menu-alphabet') && e.target.closest('.menu-alphabet').id === 'alpha-menu') {
        e.preventDefault();
      }
    };

    document.addEventListener('touchmove', htm, { passive: false });

    return () => {
      document.removeEventListener('touchmove', htm);
    };
  }, [scrollableDivRef]);

  useEffect(() => {
    if (_viewListData) {
      alpha_handlers.handleInit_alpha(_viewListData, null, _grouping, _restrictToExisting, storageItem, props.alphaValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_viewListData]);

  useEffect(() => {
    if (alphaItem) {
      const _handleAlphaChange = handleAlphaChange ? handleAlphaChange : props.handleAlphaChange
      if (fetchByAlphabet && data_handlers) {
        data_handlers.handleSetAlphabetValue(alphaItem)
      } else if (handleAlphaChange) {
        switch (alphaItem) {
          case 'sel':
            props.handleGotoTop()
            break;
          case 'nonsel':
            props.handleGotoBottom()
            break;
          default:
            _handleAlphaChange && _handleAlphaChange(alphaItem)
        }
      } else {
        !isSolo && uiFilter_handlers && uiFilter_handlers.handleChange_alphaFilter(alphaItem, true)
        _handleAlphaChange && _handleAlphaChange(alphaItem)
      }
      if (useHorizontalScroll) {
        const tvw = getTabView(_menuContainerId, 'am')
        tvw.setTab(alphaItems.length)
        const alphaIndex = alphaItems.indexOf(parseInt(alphaItem));
        setHorizontalScroll(tvw, _menuContainerId, alphaIndex)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [alphaItem]);

  const handleItemClick = (e, { name }) => {
    currentHelpers.storageItem_set(storageItem, name)
    alpha_handlers.handleSet_alphaItem(name)
  }

  const handleUpdateCurrentAlpha = (e, alphaIndex) => {
    if (alphaIndex !== currentAlphaIndex) {
      alpha_handlers.handleSet_currentAlphaIndex(alphaIndex);
      isSolo && props && props.handleMouseMove && props.handleMouseMove(e, alphaIndex)
    }
  }

  const handleTouchMove = (event) => {
    const touchedItem = document.elementFromPoint(
      event.touches[0].clientX,
      event.touches[0].clientY
    );
    if (touchedItem && touchedItem.attributes && touchedItem.attributes['itemval'] && touchedItem.attributes['itemval'].value) {
      const itemValue = parseInt(touchedItem.attributes['itemval'].value)
      if (itemValue !== currentAlphaIndex) {
        alpha_handlers.handleSet_currentAlphaIndex(itemValue)
      }
    }
  }

  const handleSetAlphaItem = () => {
    alpha_handlers.handleSet_alphaItem(currentAlphaIndex)
    alpha_handlers.handleSet_letterShowing(false)
    currentHelpers.storageItem_set(storageItem, currentAlphaIndex)
  }

  const handleShowLetter = (e) => {
    if (e) { handleTouchMove(e) }
    alpha_handlers.handleSet_letterShowing(true)
  }

  const handleKillLetter = () => alpha_handlers.handleSet_letterShowing()

  const menuItem = (val, isChar, index) => <Menu.Item
    className={isVert && letterShowing && _currentLetter && (_currentLetter === String.fromCharCode(val)) ? 'sel' : null}
    id={'am_' + index}
    itemval={val}
    touchtype={'mi'}
    name={val.toString()}
    key={uniqueKey('abm', val)}
    active={val === parseInt(alphaItem)}
    onClick={handleItemClick}

    onMouseMove={(e) => { isVert && letterShowing && handleUpdateCurrentAlpha(e, val) }}
    onMouseUp={(e) => { isVert && handleSetAlphaItem(val) }}

    onTouchStart={(e) => { isVert && letterShowing && handleUpdateCurrentAlpha(e, val) }}
    onTouchMove={(e) => { isVert && handleTouchMove(e) }}
    onTouchEnd={() => { isVert && handleSetAlphaItem(val) }}
  >
    {!isChar && String.fromCharCode(val)}
    {isChar && val}
  </Menu.Item>

  const alphaMis = () => {
    const mis = alphaItems.map((ai, index) => {
      return menuItem(ai, null, index)
    })
    if (showFavorites) {
      mis.unshift(menuItem('Favs', true, alphaItems.length))
    }
    if (props.handleGotoTop) {
      mis.unshift(menuItem('sel', true, 0))
    }
    if (props.handleGotoBottom) {
      mis.push(menuItem('nonsel', true, 0))
    }
    return mis
  }

  let cn = snc ? 'alpha-menu ' + snc.className : 'alpha-menu'

  if (cn) {
    cn += letterShowing ? ' ' : ' dead'
  }

  const menu = () => <Menu
    ref={scrollableDivRef}
    id={_menuContainerId}
    onMouseDown={(e) => { isVert && handleShowLetter() }}
    onMouseLeave={(e) => { isVert && handleKillLetter() }}

    onTouchStart={(e) => { isVert && handleShowLetter(e) }}
    onTouchMove={(e) => { isVert && handleTouchMove(e) }}

    inverted
    borderless
    vertical={isVert ? true : false}
    compact={isVert ? true : null}
    className={cn}
    style={snc.style}
  >
    {alphaItems && alphaMis()}

  </Menu >

  return useAlpha ? <React.Fragment>
    {isVert && letterShowing && <div className="circle-container" style={snc.style}>
      <div className="scrolling-letter">{_currentLetter}</div>
      {showAlphaCount && <div className="scrolling-count">{letterCount && letterCount[currentAlphaIndex] ? letterCount[currentAlphaIndex] : 0}</div>}
    </div>}
    {isVert && showAlphaHeader && <div className="show-letter" style={snc.style}>
      <Label circular color='blue'>{_currentLetter}</Label>
    </div>}
    {menu()}
  </React.Fragment> : <div></div>

}

export default AlphabetMenu


export const AlphabetMenuWithProvider = (props) => {
  return <AlphaProvider  {...props}>
    <AlphabetMenu />
  </AlphaProvider>
}