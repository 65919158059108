import _ from 'lodash'
import React, { useContext, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { uniqueKey } from '../../../global/common/keys'
import { TicketingContext } from '../cnr/contexts/TicketingContext'
import SubTicket from './SubTicket'

const SubTickets = (props) => {

  const { ticket, ticketType, subTicketSelected, handleSubTicketSelect, forSend, isWillCall } = props ?? {}
  const { subTickets } = ticket ?? {}

  // ticketingContext
  const ticketingContext = useContext(TicketingContext)
  const { ticketing_state } = ticketingContext ?? {}
  const { subTicketsSelected, presentedTicketsSelected, showPresent, showLookup, filterType, showPrint, appTicketing } = ticketing_state ?? {}
  const { showAvailableTicketsFirst, showAssignedTicketsLast } = appTicketing ?? {}

  const [ticketIndex, setTicketIndex] = useState(0)

  const subTicks = () => {
    const segs = []
    if (subTickets) {

      subTickets.forEach((subTicket, index) => {
        subTicket._itemKey = subTicket.subTicketKey
        // if (index <= 1) { subTicket.entered = true }
      })

      let _subTickets = _.sortBy(subTickets, 'subTicketKey')
      if (filterType) {
        switch (filterType) {
          case 'Entered':
            _subTickets = _.filter(_subTickets, { entered: true })
            break;
          case 'Remaining':
            _subTickets = _.filter(_subTickets, { entered: false })
            break;
          default:
            break;
        }
      }

      // _subTickets = _.sortBy(_subTickets, 'subTicketIndex')

      if (showAvailableTicketsFirst && showAssignedTicketsLast) {
        _subTickets = _.orderBy(_subTickets, ['entered', 'assignedTo', 'subTicketIndex'], ['asc', 'desc', 'asc'])
      } else if (showAvailableTicketsFirst) {
        _subTickets = _.orderBy(_subTickets, ['entered', 'subTicketIndex'], ['asc', 'asc'])
      } else if (showAssignedTicketsLast) {
        _subTickets = _.orderBy(_subTickets, ['assignedTo', 'subTicketIndex'], ['desc', 'asc'])
        // _subTickets = _.orderBy(_subTickets, ['subTicketIndex', 'assignedTo'], ['asc', 'desc'])
      }

      // if (showAvailableTicketsFirst) { _subTickets = _.sortBy(_subTickets, 'entered') }

      _subTickets.forEach(subTicket => {
        const { _itemKey } = subTicket
        if (showPresent) {
          if (presentedTicketsSelected && presentedTicketsSelected.includes(_itemKey)) {
            segs.push(<SubTicket key={uniqueKey('sbtk', _itemKey)} ticket={ticket} subTicket={subTicket} subTicketKey={_itemKey} subTicketSelected={presentedTicketsSelected} ticketType={ticketType} forSend={forSend} isWillCall={isWillCall} />)
          }
        } else if (forSend) {
          if (subTicketsSelected && subTicketsSelected.includes(_itemKey)) {
            segs.push(<SubTicket key={uniqueKey('sbtk', _itemKey)} ticket={ticket} subTicket={subTicket} subTicketKey={_itemKey} subTicketSelected={subTicketSelected} ticketType={ticketType} forSend={forSend} isWillCall={isWillCall} />)
          }
        } else {
          segs.push(<SubTicket key={uniqueKey('sbtk', _itemKey)} ticket={ticket} subTicket={subTicket} subTicketKey={_itemKey} subTicketSelected={subTicketSelected} ticketType={ticketType} handleSubTicketSelect={handleSubTicketSelect} isWillCall={isWillCall} />)
        }
      })
    }
    return segs
  }

  const swipeableContent = () => {
    return <SwipeableViews
      className={'rsvs ticks'}
      key={uniqueKey('st', 'sv')}
      enableMouseEvents
      index={ticketIndex}
      onChangeIndex={setTicketIndex}
    >
      {subTicks()}
    </SwipeableViews>
  }

  if (showLookup && 1 === 3) {
    return subTicks()
  } else {
    if (showPrint) {
      return subTicks()
    } else {
      return isWillCall || showPresent || showLookup ? swipeableContent() : subTicks()
    }
  }
}

export default SubTickets