import _ from 'lodash';
import { gEnums } from '../../enums/globalEnums';
import { getGallery_clientProfiles, get_documentStorageItems } from '../../storage/storageItems';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from './reducerHelpers/dispatchProps';

const rts = {
  handleGet_itemGalleryItems: 'handleGet_itemGalleryItems',
  handleGet_itemPdfs: 'handleGet_itemPdfs',
  handleSet_itemStorageImages: 'handleSet_itemStorageImages',
  handleSet_existingGalleryItems: 'handleSet_existingGalleryItems',
  handleUpdate_itemStorageImage: 'handleUpdate_itemStorageImage',
  ...grts,
}

export const itemStorageReducer = (state, action) => {

  const { pathViews, useClientProfileImage, appUserCollection, clientStorageImageCollections, getImageFromProfileProp, profileImageProp, staticViews } = state
  const { type, dispatch } = action

  const { handleSet_itemStorageImages } = itemStorageHandlers(dispatch)

  // console.log('itemStorageReducer', action)

  switch (type) {
    case rts.handleGet_itemGalleryItems:
      if (useClientProfileImage && clientStorageImageCollections && clientStorageImageCollections.includes(action.key_viewItem)) {
        let allEmails = []
        _.forEach(clientStorageImageCollections, (imageCollection) => {
          const staticView = staticViews[imageCollection]
          const emails = _.map(staticView, 'email');
          allEmails = _.concat(allEmails, emails)
        })
        getGallery_clientProfiles(pathViews, appUserCollection, null, handleSet_itemStorageImages, gEnums.storageLocationTypes.clientProfiles, allEmails)
        // }
        // if (useClientProfileImage && action.key_viewItem === appUserCollection) {
        //   const staticView = staticViews[appUserCollection]
        //   const emails = _.map(staticView, 'email');
        //   getGallery_clientProfiles(pathViews, appUserCollection, null, handleSet_itemStorageImages, gEnums.storageLocationTypes.clientProfiles, emails)
        // } else if (clientStorageImageCollections && clientStorageImageCollections.includes(action.key_viewItem)) {
        //   getGallery_clientFolder(pathViews, action.key_viewItem, handleSet_itemStorageImages)
      } else {
        get_documentStorageItems(pathViews, action, handleSet_itemStorageImages)
      }
      return { ...state }

    case rts.handleGet_itemPdfs:
      return { ...state }

    case rts.handleSet_itemStorageImages: // internal
      // existing items
      const { galleryItems: _galleryItems } = state
      const { galleryResponse } = action
      const { galleryItems, galleryPath } = galleryResponse
      // send the results to the storage provider   
      const gis = _galleryItems ? { ..._galleryItems, ...galleryItems } : galleryItems
      return { ...state, galleryItems: gis, updateStoragePath: galleryPath, galleryResponse }

    case rts.handleSet_existingGalleryItems:
      const _galleryResponse = {
        galleryItems: action.galleryItems,
        galleryPath: action.key_viewItem
      }
      return { ...state, galleryItems: action.galleryItems, updateStoragePath: action.galleryPath, galleryResponse: _galleryResponse }

    case rts.handleUpdate_itemStorageImage: // internal 
      return { ...state }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterUpdate })

    default:
      return state
  }
}

export const itemStorageInitialState = (initState) => {
  return { ...initState, updateStoragePath: null }
};

export const itemStorageHandlers = (dispatch) => {
  return {
    handleGet_itemGalleryItems: (galleryPath, key_viewItem) => { dispatch({ type: rts.handleGet_itemGalleryItems, dispatch, galleryPath, key_viewItem }) },
    handleSet_existingGalleryItems: (galleryItems, key_viewItem, galleryPath) => { dispatch({ type: rts.handleSet_existingGalleryItems, dispatch, galleryItems, key_viewItem, galleryPath }) },
    handleSet_itemStorageImages: (galleryResponse) => { dispatch({ type: rts.handleSet_itemStorageImages, dispatch, galleryResponse }) },
    handleUpdate_itemStorageImage: (galleryPath, key_viewItem) => { dispatch({ type: rts.handleUpdate_itemStorageImage, dispatch, galleryPath, key_viewItem }) },
    ...responseHandlers(dispatch)
  }
} 