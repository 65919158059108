import _ from 'lodash'
import React, { useContext } from 'react'
import { viewDataModes } from '../../cnr/reducers/DataManagmentReducer'
import UiSaveButtons from '../../components/buttons/UiSaveButtons'
import { DataManagementContext } from './DataManagementViewer'
import { UiItemContext } from '../../cnr/contexts/UiItemContext'
import { ParentContext } from '../../cnr/contexts/ParentContext'

const DataManagementTop = (props) => {

  const { uivi_direct } = props

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { eventInfo_state } = states ?? {}

  const dataManagementContent = useContext(DataManagementContext)
  const { dataManagement_state, dataManagement_handlers } = dataManagementContent ?? {}
  const { _defaultMenuItem, activeTab, appData, dataManagementType, tabData } = dataManagement_state ?? {}

  const { missingSvDatas } = eventInfo_state ?? {}
  const missingSvData = missingSvDatas ? missingSvDatas[uivi_direct] : []
  const _hasMissingSvData = missingSvData && missingSvData.length > 0

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}

  const allowDataSource = item_state ? true : false

  const handleViewDataMode = (opts) => dataManagement_handlers.handleViewDataMode(opts)
  const handleNone = () => { }

  switch (dataManagementType) {
    case _defaultMenuItem:
      switch (activeTab) {
        case 0:
          const btns = []
          if (tabData) {
            btns.push({ caption: _.startCase(viewDataModes.viewJson), oc: handleViewDataMode, icon: 'settings', opts: { viewDataMode: viewDataModes.viewJson } })
          }
          if (allowDataSource) {
            btns.push({ caption: _.startCase(viewDataModes.dataSource), oc: handleViewDataMode, icon: 'filter', opts: { viewDataMode: viewDataModes.dataSource } })
          }
          btns.push({ caption: appData ? Object.keys(appData).length : 0, oc: handleNone, color: 'black', icon: 'hashtag' })
          btns.push({ caption: 'Static Views', oc: handleNone, color: _hasMissingSvData ? 'red' : 'green', icon: _hasMissingSvData ? 'alarm' : 'check' })
          return <UiSaveButtons
            others={btns}
            color={'blue'}
          />
        case 1:
        case 2:
          return <UiSaveButtons
            json={{ caption: _.startCase(viewDataModes.viewJson), oc: handleViewDataMode, icon: 'settings', opts: { viewDataMode: viewDataModes.viewJson } }}
          />
        case 3:
          return <UiSaveButtons
            json={{ caption: _.startCase(viewDataModes.viewJson), oc: handleViewDataMode, icon: 'settings', opts: { viewDataMode: viewDataModes.viewJson } }}
          />
        default:
          break;
      }
      break;
    default:
      return <div></div>
  }
}

export default DataManagementTop
