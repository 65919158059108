import _ from 'lodash'
import { getHelpGroup } from '../../components/help/helpSettings'
import { createRefPath } from "../../firestoreData/appData/appRefPaths"
import { fs_db } from "../../firestoreData/appData/fsAppData"
import { fs_dbu } from '../../firestoreData/appData/fsAppDataUpdate'

const rts = {
  handleGet_currentHelp: 'handleGet_currentHelp',
  handleInit_help: 'handleInit_help',
  handleSet_helpData: 'handleSet_helpData',
  handleUpdate_allHelp: 'handleUpdate_allHelp',
  handleUpdate_help: 'handleUpdate_help',
}

export const helpTypes = {
  actions: 'actions',
  documents: 'documents',
  questions: 'questions',
  singleItem: 'singleItem',
  topics: 'topics',
}

const _linkTypes = {
  vw: 'global',
  vw: 'page',
  vi: 'pageItem',
  pr: 'prop',
  ps: 'propSection',
}

export const helpReducer = (state, action) => {

  const { helpDocs } = state
  const { dispatch, type, helpSettings } = action

  const { handleSet_helpData } = helpHandlers(dispatch)

  switch (type) {

    case rts.handleInit_help:
      fs_db.get_data({ refPath: ['help'], callback: handleSet_helpData, opts: { ignoreId: true } })
      return { ...state }

    case rts.handleSet_helpData:
      const { hd, allHelpItems } = ammendHelpDocuments(action.helpDocs)
      if (allHelpItems) {
        // console.log('allHelpItems', allHelpItems)
        // const _refPath = createRefPath(['help', 'helpDocs'])

        // const _allHelpItems = removeAllEmpties(allHelpItems)

        // console.log('_allHelpItems', _allHelpItems)

        // Object.keys(_allHelpItems).forEach(k => {
        //   const hi = _allHelpItems[k]
        //   const { description, groupKeys } = hi
        //   if (!description || description === 'undefined') { console.log('d', hi) }
        //   if (!groupKeys || groupKeys.length === 0) { console.log('d', hi) }
        // })

        // console.log('allHelpItems', allHelpItems)
        // console.log('_allHelpItems', _allHelpItems)
        // console.log('_allHelpDocs', _allHelpDocs)
        // fs_dbu.set_doc(_refPath, _allHelpItems)
        // fs_db.get_data({ refPath: _refPath, callback: handleDbSettings, opts: { ignoreId: true } })
      }
      return { ...state, helpDocs: hd, allHelpItems }

    case rts.handleUpdate_allHelp:
      updateHelpDoc(action.helpType, helpSettings)
      return { ...state }

    case rts.handleUpdate_help:
      updateHelpDoc(action.helpType, helpSettings)
      return { ...state }

    case rts.handleGet_currentHelp:
      const { helpType, hsProps } = action.helpProps

      let _helpType = helpType;
      if (isTopic()) { _helpType = helpTypes.topics }
      const helpDataObject = getHelpDataObject(helpDocs, _helpType)
      const helpGroup = getHelpGroup(helpDataObject, hsProps)
      return { ...state, helpGroup, helpType_active: _helpType }

    default:
      return { ...state }
  }
}

export const helpInitialState = (init_state) => {
  return { ...init_state }
};

export const helpHandlers = (dispatch, state) => {
  return {
    handleInit_help: () => { dispatch({ type: rts.handleInit_help, dispatch }) },
    handleSet_helpData: (helpDocs) => { dispatch({ type: rts.handleSet_helpData, dispatch, helpDocs }) },
    handleUpdate_allHelp: (helpSettings, helpType) => { dispatch({ type: rts.handleUpdate_allHelp, dispatch, helpSettings, helpType }) },
    handleUpdate_help: (helpSettings, helpType) => { dispatch({ type: rts.handleUpdate_help, dispatch, helpSettings, helpType }) },
    handleGet_currentHelp: (helpProps) => dispatch({ type: rts.handleGet_currentHelp, dispatch, helpProps }),
  }
}

const updateHelpDoc = (helpType, dataItem) => {
  const docKey = 'help' + _.startCase(helpType)
  const _refPath = createRefPath(['help', docKey])
  fs_dbu.set_doc(_refPath, dataItem, true)
}

const getHelpDataObject = (helpDocs, helpType) => {
  const { helpDocuments, helpQuestions, helpActions, helpTopics } = helpDocs ?? {}
  let activeDoc;
  switch (helpType) {
    case helpTypes.documents:
    case helpTypes.singleItem:
      activeDoc = helpDocuments
      break;
    case helpTypes.questions:
      activeDoc = helpQuestions
      break;
    case helpTypes.actions:
      activeDoc = helpActions
      break;
    // case helpTypes.singleItem:
    case helpTypes.topics:
      activeDoc = helpTopics
      break;
    default:
      activeDoc = helpDocuments
  }
  return activeDoc
}

const isTopic = (helpKey) => {
  switch (helpKey) {
    case 'props':
    case 'viewItems':
    case 'views':
      return true
    default:
    // nothing
  }
}

const ammendHelpDocuments = (helpDocs) => {
  const allHelpItems = {}
  const { helpTopics, helpDocuments } = helpDocs
  if (helpTopics && helpDocuments) {
    Object.keys(helpDocuments).forEach(key => {
      const hds = helpDocuments[key]
      Object.keys(hds).forEach(k => {
        const hd = hds[k]
        if (hd && _.isObject(hd)) {
          const { items } = hd
          if (helpTopics[k]) {
            hd.groupDescription = helpTopics[k]
          } else {
            if (!hd.groupDescription) {
              hd.groupDescription = 'Group settings for ' + _.startCase(k)
            }
          }
          if (items) {
            Object.keys(items).forEach(itemKey => {
              if (!allHelpItems[itemKey]) { allHelpItems[itemKey] = { groupKeys: [] } }
              allHelpItems[itemKey].description = items[itemKey].description
              if (!allHelpItems[itemKey].groupKeys.includes(_linkTypes[key])) {
                allHelpItems[itemKey].groupKeys.push(_linkTypes[key])
              }
            })
          }
        }
      })
    })
  }
  return { hd: helpDocs, allHelpItems }
}