import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { _showStates } from '../../../App';
import PageDimmer from '../../components/alerts/PageDimmer';
import { _dimmerTypes } from '../../motions/AnimateComponent';
import { pageDimmerHandlers, pageDimmerInitialState, pageDimmerReducer, pageDimmerTypes } from '../reducers/PageDimmerReducer';
import { ParentContext } from './ParentContext';

const killInterval = 0.5

export const PageDimmerContext = createContext();

const PageDimmerProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state, transition_state } = states
  const { pathViews } = paps_state ?? {}

  const { dimmers } = transition_state ?? {}
  const dimmer_page = dimmers ? dimmers[_dimmerTypes.page] : null
  const { useDimmerTransition } = dimmer_page ?? {}

  const init_state = pageDimmerInitialState({ dimmer_page, pageDimmerType: pageDimmerTypes.app, startTime: Date.now(), killInterval })
  const [pageDimmer_state, pageDimmer_dispatch] = useReducer(pageDimmerReducer, init_state)
  const pageDimmer_handlers = pageDimmerHandlers(pageDimmer_dispatch)
  const { killPageDimmer, timeLeft } = pageDimmer_state

  if (_showStates.any && _showStates.pageDimmer) {
    console.log(Object.keys(pageDimmer_state))
    console.log(Object.keys(pageDimmer_handlers))
  }

  useEffect(() => {
    pageDimmer_handlers.handleAmmend_pageDimmer(pageDimmerTypes.start)
    // eslint-disable-next-line
  }, [pathViews]);

  useEffect(() => {
    if (killPageDimmer) {
      if (!timeLeft) {
        // pageDimmer_handlers.handleKill_pageDimmer()
      } else {
        const intervalId = setInterval(() => {
          pageDimmer_handlers.handleTimeLeft();
        }, 1000);
        return () => clearInterval(intervalId);
      }
    }
    // eslint-disable-next-line
  }, [timeLeft, killPageDimmer]);

  return <PageDimmerContext.Provider value={{ pageDimmer_state, pageDimmer_handlers }}>
    {useDimmerTransition && <PageDimmer />}
    {props.children}
  </PageDimmerContext.Provider>
}

export default PageDimmerProvider 