import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { _projectAppNames } from '../../../project/appConfiguration';
import { gEnums } from '../../enums/globalEnums';
import { storageHandlers, storageInitialState, storageReducer } from '../reducers/StorageReducer';
import { AppSettingsContext } from './AppSettingsContext';
import { AppUsersContext } from './AppUsersContext';
import { EventInfoContext } from './EventInfoContext';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';

/** Global Files (Icon, Image, PDF) for the Event taken from the storage */
export const StorageContext = createContext();

/** Global Files (Icon, Image, PDF) for the Event taken from storage */
const StorageProvider = (props) => {

  // eventInfoContext
  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_state } = appSettingsContext ?? {}
  const { appConfigSettings } = appSettings_state ?? {}
  const { appNames } = appConfigSettings ?? {}
  const { appName } = appNames ?? {}

  // eventInfoContext
  const eventInfoContext = useContext(EventInfoContext)
  const { eventInfo_state } = eventInfoContext ?? {}
  const { staticViews } = eventInfo_state ?? {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext
  const { rootPaths, settingsDocName, pathViews } = paps_state ?? {}

  // pageContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings } = pageSettings ?? {}
  const { projectOptions, storageOptions } = aps_global ?? {}
  const { useUnderscorePrefix } = projectOptions ?? {}
  const { appUserCollection, useClientProfileImage, getImageFromProfileProp, profileImageProp } = aps_appUserSettings ?? {}

  const appUsersContext = useContext(AppUsersContext)
  const { appUsers_state } = appUsersContext ?? {}

  let _storageRootPath;
  let _storageRootPath_client = pathViews.clients ? rootPaths.clients.substring(1) : null

  if (pathViews.events) {
    _storageRootPath = rootPaths.events.substring(1)
  } else if (pathViews.clients) {
    _storageRootPath = rootPaths.clients.substring(1)
  }

  const init_state = { pathViews, storageOptions, useClientProfileImage, getImageFromProfileProp, profileImageProp }
  const [storage_state, storageDispatch] = useReducer(storageReducer, storageInitialState(init_state));
  const storage_handlers = storageHandlers(storageDispatch, storage_state)
  const { globalFiles } = storage_state ?? {}

  // run this if settingsDocName changes
  useEffect(
    () => {
      switch (appName) {
        case _projectAppNames.meetingevolution:
        case _projectAppNames.pojo:
        case _projectAppNames.fcasd_ft:
          const appUsers = appUsers_state && appUserCollection && appUsers_state[appUserCollection]
          storage_handlers.handleGet_globalFiles(pathViews, _storageRootPath, _storageRootPath_client, useUnderscorePrefix, appUserCollection, appUsers, staticViews)
          break;
        case _projectAppNames.thumbstat:
        case _projectAppNames.thumbstat2:
          storage_handlers.handleSet_globalFiles({})
          break;
        default:
        // nothing
      }
    },
    // eslint-disable-next-line
    [settingsDocName, appUsers_state]
  )

  const storage_fns = {
    getIconUrl: (allow, fileName) => {
      if (allow && globalFiles && fileName && globalFiles.icon && globalFiles.icon[fileName]) {
        return globalFiles.icon[fileName]
      }
    },
    // LOOK
    getImageUrl: (fileName, fullImage, storageType, callback) => {
      if (globalFiles && globalFiles[storageType] && globalFiles[storageType][fileName]) {
        const { urls } = globalFiles[storageType][fileName]
        const { full, thumbnail } = urls ?? {}
        callback({ url: fullImage ? full : thumbnail })
      } else {
        callback()
      }
    },
    getImageUrls: (app_banners, bannerUrlSize, storageType, callback) => {
      let { banners } = app_banners ?? {}
      if (globalFiles && globalFiles[storageType]) {
        const _bannerUrls = []
        const _banners = _.isArray(banners) ? banners : [banners]
        if (_banners) {
          _banners.forEach(banner => {
            if (globalFiles[storageType][banner]) {
              const { urls } = globalFiles[storageType][banner]
              const { full, thumbnail } = urls ?? {}
              _bannerUrls.push(bannerUrlSize === gEnums.banner ? full : thumbnail)
            }
          })
          callback({ urls: _bannerUrls })
        }
      } else {
        callback()
      }
    },
  }


  const providerContext = () => <StorageContext.Provider value={{ storage_state, storage_handlers, storage_fns }}>
    {props.children}
  </StorageContext.Provider>

  return globalFiles ? providerContext() : <div></div>

}

export default StorageProvider