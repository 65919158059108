import React, { useContext, useEffect, useState } from 'react';
import { Sidebar } from 'semantic-ui-react';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import GoogleSheetsProvider, { GoogleSheetsContext } from '../../cnr/contexts/GoogleSheetsContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { viewDataModes } from '../../cnr/reducers/DataManagmentReducer';
import { googleModeTypes, googleSheetsTypes } from '../../cnr/reducers/GoogleSheetsReducer';
import { updateSettings } from '../../firestoreData/settings/updateSettings';
import SettingsViewer from '../../viewSettings/actions/SettingsViewer';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import ConfirmationQuestion from '../alerts/ConfirmationQuestion';
import PendingWait from '../alerts/pendings/PendingWait';
import DataSidebar from '../swipers/DataSidebar';
import DcUpdatesList from './DcUpdatesList';
import GoogleSheetsTabs from './GoogleSheetsTabs';
import SearchInputForm from './SearchInputForm';
import { clickOriginTypes } from '../../enums/globalEnums';

export const GoogleDashboard = (props) => {

  const { noModal, noWizard, fromCreate, clickOriginType, googleSheetType } = props ?? {}

  let _fromDataViewer;

  switch (clickOriginType) {
    case clickOriginTypes.dataViewer:
      _fromDataViewer = true
    default:
    //nothing
  }

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, settings } = parentContext ?? {}
  const { paps_state, page_state, googleData_state } = states ?? {}
  const { pathViews, storageRootPath } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}

  const { homeSettings } = settings ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}
  const { logging } = homeSettings_global ?? {}

  const { aps_global } = pageSettings ?? {}
  const { googleSheets, googleSheetsImportOptions, dataOptions, googleForms, appDataSource } = aps_global ?? {}
  const { googleSheetsId: googleSheetsId_existing } = googleSheets ?? {}
  const { dateFormat } = dataOptions ?? {}
  const { confirmation: confirmation_gd, updating: updating_gd, } = googleData_state ?? {}
  const { googleFormsResponseId } = googleForms ?? {}

  const _googleSheetsId = googleSheetType === googleSheetsTypes.registration ? googleFormsResponseId : googleSheetsId_existing

  // googleSheetsContext
  const frameworkContext = useContext(FrameworkContext)
  const { framework_state } = frameworkContext ?? {}
  const { frameworkRightType } = framework_state ?? {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_state, googleSheets_handlers } = googleSheetsContext ?? {}

  const {
    activeMenuItem,
    confirmation: confirmation_gs,
    confirmationQuestion: _confirmationQuestion,
    dashboardInfo_merged,
    dashboardInfo_preview,
    dcUpdates,
    googleConfirmationType,
    googleModeType,
    googleUpdateStatus,
    showConfirmationQuestion,
    showNewOnly,
    updating: updating_gs,
    settingsViewerProps,
  } = googleSheets_state ?? {}

  console.log('dashboardInfo_preview', dashboardInfo_preview)

  const [googleSheetsId, setGoogleSheetsId] = useState(_googleSheetsId && (_fromDataViewer || (googleModeType === googleModeTypes.previewDirect)) ? _googleSheetsId : null)
  const [viewDataMode, setViewDataMode] = useState()

  const _updating = !fromCreate && (updating_gd || updating_gs)
  const _confirmation = confirmation_gd || confirmation_gs

  const caption = 'Data Viewer'

  const callback_settings = async () => googleSheets_handlers.handleStopUpdate()

  useEffect(() => {
    if (googleSheetsImportOptions) { googleSheetsImportOptions.dateFormat = dateFormat }
    googleSheets_handlers.handleSet_updateOptions(googleSheetsImportOptions, storageRootPath, logging)
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);

  useEffect(() => {
    if (!fromCreate && _googleSheetsId) {
      if (googleSheetsImportOptions) { googleSheetsImportOptions.dateFormat = dateFormat }
      // gsid, useItemKey, fromCreate
      googleSheets_handlers.handleGet_googleSheet(_googleSheetsId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_googleSheetsId]);

  useEffect(() => {
    if (dashboardInfo_preview) {
      googleSheets_handlers.handleMerge_settings(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardInfo_preview]);

  useEffect(() => {
    if (viewDataMode || fromCreate) {
      googleSheets_handlers.handleGet_settingsViewerProps(viewDataMode, fromCreate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDataMode, dashboardInfo_preview]);

  /**
   * handles the save/update from SettingsViewer
   */
  const handleUpdate_dbSettings = () => {

    googleSheets_handlers.handleStartUpdate()

    switch (viewDataMode) {
      case viewDataModes.updateDataCollections:
        googleSheets_handlers.handleRecreate_dataCollectionsOneByOne()
        break;

      case viewDataModes.updateDataCollection:
        googleSheets_handlers.handleUpdate_dataCollectionsOneByOne(activeMenuItem)
        break;

      case viewDataModes.recreateDataCollection:
        googleSheets_handlers.handleRecreate_dataCollectionsOneByOne(activeMenuItem)
        break;

      case viewDataModes.recreateDataCollections:
        googleSheets_handlers.handleRecreate_dataCollectionsOneByOne()
        break;

      case viewDataModes.recreateDataDocuments:
        googleSheets_handlers.handleRecreateDataDocuments()
        break;

      case viewDataModes.recreateDataAndSettings:
        googleSheets_handlers.handleRecreate_dataCollectionsOneByOne(null, true, true)
        break;

      case viewDataModes.mergeSettings:
      case viewDataModes.recreateSettings:
        let ps;

        switch (viewDataMode) {
          case viewDataModes.mergeSettings:
            ps = dashboardInfo_merged
            break;

          case viewDataModes.recreateSettings:
            ps = dashboardInfo_preview
            break;

          default:
          // nothing
        }

        if (ps) {
          const { projectSettings } = ps ?? {}
          updateSettings.createNewViewsAndViewItemsSettingsToDb(pathViews, projectSettings, callback_settings)
        }

        break;

      default:
      // nothing
    }
  }

  const handleCancel = () => googleSheets_handlers.handleSet_googleMode(googleModeTypes.none)
  const handleGetGoogleSheet = () => googleSheets_handlers.handleGet_googleSheet(googleSheetsId, null, fromCreate)
  const handleGoogleChange = (e, data) => setGoogleSheetsId(data.value)
  const handleViewDataMode = (opts) => setViewDataMode(opts ? opts.viewDataMode : null)
  const handleHideSidebar = () => !dcUpdates && handleViewDataMode()

  const confirmationQuestion = () => <ConfirmationQuestion
    {..._confirmationQuestion}
    noModal={true}
  />

  /**
   * 
   * @returns a small from with the input for the google sheets id
   */
  const inputForm = () => <SearchInputForm
    disabled={!googleSheetsId}
    label='Google Sheets Key'
    noSeg={true}
    onChange={handleGoogleChange}
    onClick={handleGetGoogleSheet}
    value={googleSheetsId ? googleSheetsId : ''}
  />

  const googleTabSheets = () => <GoogleSheetsTabs
    fromCreate={fromCreate}
    googleSheetType={googleSheetType}
    previewInfo={dashboardInfo_preview}
    setViewDataMode={setViewDataMode}
  />

  const tabContent = () => dashboardInfo_preview ? googleTabSheets() : <PendingWait />

  const settingsViewer = () => {

    const { _createType, createType, ci } = settingsViewerProps ?? {}
    let _updateDescription;

    switch (createType) {
      case viewDataModes.updateDataCollections:
        _updateDescription = 'Updates all of the data collections.'
        ci.projectCollection = null
        break;

      case viewDataModes.updateDataCollection:
        _updateDescription = 'Updates the selected (' + activeMenuItem + ') data collection.'
        break;

      case viewDataModes.recreateDataCollection:
        _updateDescription = 'Recreates the selected (' + activeMenuItem + ') data collection. This will remove the existing data.'
        break;

      case viewDataModes.recreateDataCollections:
        _updateDescription = 'Recreates all of the data collections. This will remove the existing data.'
        break;

      case viewDataModes.recreateDataDocuments:
        _updateDescription = 'Recreates all of the data documents. This will remove the existing data.'
        break;

      case viewDataModes.recreateDataAndSettings:
        _updateDescription = 'Recreates all of the data collections and recreate the settings based on the google sheet. This will remove the existing data.'
        break;

      case viewDataModes.mergeSettings:
      case viewDataModes.recreateSettings:

        switch (viewDataMode) {
          case viewDataModes.mergeSettings:
            _updateDescription = 'Updates the settings with the merged values of the existing settings and the settings based on the google sheet.'
            break;

          case viewDataModes.recreateSettings:
            _updateDescription = 'Recreates the settings based on the google sheet.'
            break;

          default:
          // nothing
        }

        break;

      default:
      // nothing
    }

    return <SettingsViewer
      actionName={createType}
      createInfo={{ ...ci }}
      createType={_createType}
      googleUpdateStatus={googleUpdateStatus}
      handleUpdateClick={!fromCreate && handleUpdate_dbSettings}
      ignoreSingleUpdates={true}
      showNewOnly={showNewOnly}
      updateDescription={_updateDescription}
      updating={_updating}
      noSave={fromCreate}
    />
  }

  const content = () => {
    if (showConfirmationQuestion && !_updating) {
      return confirmationQuestion(googleConfirmationType)
    } else {
      if (fromCreate) {
        const showInput = (googleModeType === googleModeTypes.preview) || (googleModeType === googleModeTypes.previewDirect) || noModal || noWizard
        return <Wrapper
          header={showInput && inputForm()}
          content={settingsViewer()}
          wrapperType={wrapperTypes.paddedHeaderAndFooter}
        >
        </Wrapper>
      } else {
        return <React.Fragment>
          {dashboardInfo_preview && settingsViewer()}
        </React.Fragment>
      }
    }
  }

  let cn = 'wrp-sub'

  const sidebar_right = (visible) => <DataSidebar direction={'right'} visible={visible} content={visible && settingsViewer()} onHide={handleHideSidebar} />

  const dcUpdateList = () => <DcUpdatesList dcUpdates={dcUpdates} />

  /**
   * 
   * @returns a wrapper containing a sidebar (with google sheet names) and the selected table
   */
  const wrapper = () => <Wrapper
    content={tabContent()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    confirmation={_confirmation}
    confirmationNoModal={fromCreate}
    updating={_updating}
    noMid={true}
    css={{ container: cn, content: 'google-data-container' }}
  />

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_right(viewDataMode ? true : false)}
    <Sidebar.Pusher dimmed={viewDataMode ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    handleCancel={handleCancel}
    topperCaption={caption}
    topperCaption2={_googleSheetsId}
    noModal={noModal}
  />

  if (frameworkRightType) {
    return sidebar()
  } else {
    if (dcUpdates) {
      return dcUpdateList()
    } else {
      return noWizard || fromCreate ? content() : _fromDataViewer ? sidebar() : fullPageWrapper()
    }
  }

}

const GoogleDashboardWithProvider = (props) => {
  return <GoogleSheetsProvider googleSheetsType={props.googleSheetType}>
    <GoogleDashboard {...props} />
  </GoogleSheetsProvider>
}

export default GoogleDashboardWithProvider