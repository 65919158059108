import React from 'react';
import { Icon } from 'semantic-ui-react';

/** Create a Pdf Icon */
const SemPdfSimple = (props) => {

  const { fileUrl, inGrid, handlePdfIconClick } = props

  const handleOpenPdf = (e) => handlePdfIconClick(e, fileUrl)

  return inGrid ?
    <Icon size={'huge'} name='file pdf outline' onClick={(e) => handleOpenPdf(e)} />
    :
    <div>
      <Icon size={'huge'} name='file pdf outline' onClick={(e) => handleOpenPdf(e)} />
    </div>
}

export default SemPdfSimple