import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { addAdditionalProps } from '../../optionLists/additionalProps';
import { DataManagementContext } from '../../components/viewers/DataManagementViewer';
import { SettingsViewerContext } from '../../components/viewers/FullSettingsViewer';
import { separateContexts } from '../../common/filtering';
import { getFormLists } from '../../optionLists/getFormLists';
import { formHandlers, formInitialState, formReducer } from '../reducers/FormReducer';
import { BaseSettingsContext } from './BaseSettingsContext';
import { CreateContext } from './CreateContext';
import { DataContext } from './DataContext';
import { DataModifyContext } from './DataModifyContext';
import { FrameworkContext } from './FrameworkContent';
import { ParentContext } from './ParentContext';
import { ClientInfoContext } from './ClientInfoContext';
import { UiItemContext } from './UiItemContext';
import { ViewSettingsComponentContext } from './ViewSettingsComponentContext';
import { ViewSettingsContext } from './ViewSettingsContext';
import { SettingParentContext } from './SettingParentContext';
import { HelpContext } from './HelpContext';

const WAIT_INTERVAL = 500;

/**
 *  
 * @returns The `FormContext` Context/Provider with FormItems or SettingsForm passed in
 */
export const FormContext = createContext();

/**
 * 
 * @param {object} props (formProps, vpsa, swipedItem)
 * @returns The `FormContext` Context/Provider with FormItems or SettingsForm passed in
 */
const FormProvider = (props) => {

  const { formProps, vpsa, swipedItem, ignoreItemStatus: ignoreItemStatus_p } = props
  const { viewProps, updateProps, data_current, form_handlers: fh } = formProps ?? {}
  const { vit } = updateProps ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, fns, settings } = parentContext ?? {}
  const { appUser_state, page_state, eventInfo_state } = states ?? {}
  const { isDeveloper } = appUser_state ?? {}
  const { staticViews } = eventInfo_state ?? {}

  const { homeSettings } = settings ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}

  // pageContext  
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems } = pageSettings ?? {}
  const { appUrls: appUrls_home, rssFeeds: rssFeeds_home } = homeSettings_global ?? {}
  const { themeColors, themeItems, dataOptions } = aps_global ?? {}
  const { useItemStatus, useItemTimezone } = dataOptions ?? {}

  // contexts
  const baseSettingsContext = useContext(BaseSettingsContext)
  const createContext = useContext(CreateContext);
  const dataContext = useContext(DataContext);
  const dataManagementContext = useContext(DataManagementContext);
  const dataModifyContext = useContext(DataModifyContext)
  const frameworkContext = useContext(FrameworkContext);
  const helpContext = useContext(HelpContext);
  const settingsParentContext = useContext(SettingParentContext)
  const settingsViewerContext = useContext(SettingsViewerContext);
  const clientInfoContext = useContext(ClientInfoContext)
  const uiItemContext = useContext(UiItemContext);
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext)

  const { vsc_state } = viewSettingsComponentContext ?? {}
  const { swipedItems, updateProps: updateProps_vscc } = vsc_state ?? {}

  let vit_si;
  if (swipedItems) {
    swipedItems.forEach(si => {
      if (si.vit) {
        vit_si = si.vit
      }
    })
  }

  // frameworkContext
  const { framework_state } = frameworkContext ?? {}
  const { desktopMode } = framework_state ?? {}

  // dataManagementContext
  const { dataManagement_state } = dataManagementContext ?? {}
  const { viewItem_preview: viewItemPreview_data } = dataManagement_state ?? {}

  // dataManagementContext
  const { dataModify_state, dataModify_handlers } = dataModifyContext ?? {}
  const { allowImage } = dataModify_state ?? {}

  // settingsViewerContext
  const { settingsViewer_state } = settingsViewerContext ?? {}
  const { viewItem_preview: viewItemPreview_settings } = settingsViewer_state ?? {}

  const { item_state } = uiItemContext ?? {}
  const { modifyActionType } = item_state ?? {}

  // viewSettingsContext
  const viewSettingsContext = useContext(ViewSettingsContext)
  const { viewSettings_state } = viewSettingsContext ?? {}
  const { settings_temp_global } = viewSettings_state ?? {}
  const { menu } = settings_temp_global ?? {}

  const isDataItem = item_state ? true : false

  const { create_handlers } = createContext ?? {}
  const { viewSettings_handlers } = viewSettingsContext ?? {}

  const { key: vit_dv } = viewItemPreview_data ?? {}
  const { key: vit_sv } = viewItemPreview_settings ?? {}

  const isDataContext = dataContext ? true : false
  const isViewSettingsContext = viewSettingsContext ? true : false

  const contexts = {
    baseSettingsContext: baseSettingsContext ?? {},
    dataContext: dataContext ?? {},
    dataManagementContext: dataManagementContext ?? {},
    dataModifyContext: dataModifyContext ?? {},
    frameworkContext: frameworkContext ?? {},
    helpContext: helpContext ?? {},
    settingsParentContext: settingsParentContext ?? {},
    settingsViewerContext: settingsViewerContext ?? {},
    staticViewsClientContext: clientInfoContext ?? {},
    uiItemContext: uiItemContext ?? {},
    viewSettingsComponentContext: viewSettingsComponentContext ?? {},
    viewSettingsContext: viewSettingsContext ?? {},
  }

  const { states: _states, handlers: _handlers, fns: _fns } = separateContexts(contexts)

  const formParent_contexts = {
    states: { ..._states, ...states },
    handlers: { ..._handlers, ...handlers },
    fns: { ..._fns, ...fns },
    settings
  }

  const init_state = {
    ...props,
    allowImage,
    dataOptions,
    desktopMode,
    isDataContext,
    isViewSettingsContext,
    modifyType: dataModify_state ? dataModify_state.modifyType : null,
    modifyActionType,
    swipedItem,
    themeColors,
    themeItems,
    vpsa,
    showAll: true
  }

  const [form_state, form_dispatch] = useReducer(formReducer, formInitialState(init_state));
  const form_handlers = formHandlers(form_dispatch)

  const { data_localForm, optionsList } = form_state ?? {}
  const { dataHasChanged, propItemData } = form_state ?? {}

  const { handleOptionsList } = form_handlers ?? {}

  const getSettingsUpdateProps = () => {

    const { updateProps: updateProps_form } = formProps ?? {}

    const { data_state } = dataContext ?? {}
    const { updateProps: updateProps_data } = data_state ?? {}

    // set the update props depending on origin
    let ups = updateProps_vscc ? updateProps_vscc : updateProps_data
    if (!ups && updateProps_form) { ups = updateProps_form }
    if (updateProps_data) { ups = updateProps_data }
    if (ups) { ups.swipedItems = swipedItems }

    return ups
  }

  useEffect(() => {
    form_handlers.handleSet_formProps(formProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [formProps]);

  useEffect(() => {
    if (data_localForm) {
      const timer = setTimeout(() => triggerChange(data_localForm), WAIT_INTERVAL);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_localForm]);

  /**
   * Handles the form change AFTER the WAIT_INTERVAL
   * @param {object} fd 
   */
  const triggerChange = (fd) => {

    if (dataHasChanged) {

      // create_handlers
      if (create_handlers && create_handlers.handleFormDataChange_create) {
        create_handlers.handleFormDataChange_create({ data_form: data_localForm })
      }

      // dataModify_handlers
      if (dataModify_handlers && dataModify_handlers.handleFormDataChange_dataModify) {
        const dmcp = { data_form: data_localForm, propItemData }
        dataModify_handlers.handleFormDataChange_dataModify(dmcp)
      }

      // form_handlers
      if (form_handlers && form_handlers.handleFormDataChange_local) {
        form_handlers.handleFormDataChange_local(data_localForm)
      }

      // form_handlers
      if (fh && fh.handleFormDataChange_local) {
        fh.handleFormDataChange_local(data_localForm)
      }

      if (viewSettings_handlers && viewSettings_handlers.handleFormDataChange_settings) {
        const ups = getSettingsUpdateProps()
        viewSettings_handlers.handleFormDataChange_settings(data_localForm, ups)
      }
    }
  }

  const getCurrentViPropList = (data_combined) => {

    let _currentViPropList = null
    let _currentCollectionPropList = null

    const { captionPage, dataCollectionName } = data_combined ?? {}

    let _vit = vit

    if (vit_dv) { _vit = vit_dv }
    if (vit_sv) { _vit = vit_sv }
    if (vit_si) { _vit = vit_si }
    if (dataCollectionName) { _vit = dataCollectionName }

    if (aps_viewItems && _vit && aps_viewItems[_vit]) {
      let currentVi = aps_viewItems[_vit]
      const { altCollectionName } = currentVi ?? {}
      if (altCollectionName && aps_viewItems[altCollectionName]) {
        currentVi = aps_viewItems[altCollectionName]
      }
      const { props: props_vi, propsAdditional } = currentVi ?? {}
      const { ignoreItemStatus, showPdf } = propsAdditional ?? {}
      if (useItemStatus && !ignoreItemStatus && isDataItem && !ignoreItemStatus_p) { props_vi['itemStatusType'] = {} }
      if (useItemTimezone && !ignoreItemStatus && isDataItem && !ignoreItemStatus_p) { props_vi['itemStatusType'] = {} }
      if (showPdf) { props_vi['itemPdf'] = {} }
      _currentViPropList = props_vi
    }

    if (captionPage && aps_viewItems && aps_viewItems[captionPage]) { _currentCollectionPropList = aps_viewItems[captionPage].props }
    if (menu && menu.captionPage && aps_viewItems && aps_viewItems[menu.captionPage]) { _currentCollectionPropList = aps_viewItems[menu.captionPage].props }

    return { _currentViPropList, _currentCollectionPropList }

  }

  // IMPORTANT: Form - Options - getOptions
  useEffect(() => {
    const data_combined = { ...data_current, ...data_localForm }
    const { _currentViPropList, _currentCollectionPropList } = getCurrentViPropList(data_combined)
    const _formListProps = {
      appUrls_home,
      aps_global,
      aps_viewItems,
      currentCollectionPropList: _currentCollectionPropList,
      currentViPropList: _currentViPropList,
      data_combined,
      data_current,
      data_localForm,
      formParent_contexts,
      formProps,
      handleOptionsList,
      isDeveloper,
      rssFeeds_home,
      staticViews,
      swipedItem,
      updateProps,
      viewProps,
    }
    let { viewItem } = formProps ?? {}
    addAdditionalProps(swipedItem, viewProps, viewItem, dataOptions, allowImage, isDataItem, ignoreItemStatus_p)
    getFormLists(_formListProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [viewProps, data_localForm]); // LOOOK data_localForm

  const formContext = () => <FormContext.Provider value={{ form_state, form_handlers, formParent_contexts }}>
    {props.children}
  </FormContext.Provider>

  if (optionsList) {
    return formContext()
  } else {
    return <div></div>
  }
};

export default FormProvider