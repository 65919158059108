import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { appUserAuthHandlers, appUserAuthReducer, appUserAuthReducerInitialState } from "../reducers/AppUserAuthReducer";
import { AppSettingsContext } from './AppSettingsContext';
import { AppUserContext } from './AppUserContext';
import { EventInfoContext } from './EventInfoContext';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';

export const AppUserAuthContext = createContext();

const AppUserAuthProvider = (props) => {

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ?? {}
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  // eventInfoContext
  const eventInfoContext = useContext(EventInfoContext);
  const { eventInfo_state } = eventInfoContext ?? {}
  const { appProfileData } = eventInfo_state ?? {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext ?? {}
  const { pathViews } = paps_state ?? {}

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_state } = appSettingsContext ?? {}
  const { authOn } = appSettings_state ?? {}

  // appUserContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings } = pageSettings ?? {}
  const { appUserCollection } = aps_appUserSettings ?? {}

  // AuthReducer
  const initState = appUserAuthReducerInitialState({ pathViews, appUserCollection, appUserAccess })
  const [appUserAuth_state, appUserAuth_dispatch] = useReducer(appUserAuthReducer, initState);
  const appUserAuth_handlers = appUserAuthHandlers(appUserAuth_dispatch, appUserAuth_state)

  useEffect(() => {
    if (authOn && appUserAccess && appUserAccess.isAdminOrSuper && appProfileData) {
      appUserAuth_handlers.handleGetAppUserAuths(appProfileData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [authOn, appProfileData])

  const provider = () => <AppUserAuthContext.Provider
    value={{
      appUserAuth_handlers,
      appUserAuth_state,
    }} >
    {props.children}
  </AppUserAuthContext.Provider >

  return appUserAuth_state ? provider() : <div></div>
}

export default AppUserAuthProvider
