import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Form, List, Segment, Sidebar } from 'semantic-ui-react'
import { updateGlobal } from '../../../../projectSpecific/sports/dbActions/globals'
import { ParentContext } from '../../../cnr/contexts/ParentContext'
import { convertHelpers, getStaticItems } from '../../../common/convert'
import UiSaveButtons from '../../../components/buttons/UiSaveButtons'
import { getHeaders } from '../../../components/headers/getHeaders'
import SidebarConfirm from '../../../components/swipers/SidebarConfirm'
import { DataManagementContext } from '../../../components/viewers/DataManagementViewer'
import JsonViewer from '../../../components/viewers/JsonViewer'
import { appIconTypes } from '../../../enums/appIconTypes'
import { listList } from '../../../lists/lists'
import { dataDataActionTypes, dataSidebarActionTypes } from '../../../viewSettings/enums/itemActionTypes'
import FullPageWrapper from '../../../wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper'

/** Returns a list of data items that will be added */
const UiCreateGlobalData = (props) => {

  const { selectedOption, viewKey, viewDataMode: viewDataMode_props, appData: appData_props, callback } = props ?? {}
  const { propKey } = selectedOption ? selectedOption : {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { eventInfo_state, paps_state, page_state } = states
  const { staticViews } = eventInfo_state ?? {}
  const { pathViews } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_viewItems } = pageSettings ?? {}
  const _viewItem_aps = aps_viewItems && viewKey ? aps_viewItems[viewKey] : null

  // dataManagementContent
  const dataManagementContent = useContext(DataManagementContext)
  const { dataManagement_state, dataManagement_handlers } = dataManagementContent ? dataManagementContent : {}
  const { viewItem, viewDataMode, appData, updating, confirmation, showCommitDataManagment } = dataManagement_state ?? {}

  const _viewItem = _viewItem_aps ? _viewItem_aps : viewItem
  const _viewDataMode = viewDataMode_props ? viewDataMode_props : viewDataMode
  const _appData = appData_props ? appData_props : appData

  const { key: key_viewItem, dataConnection } = _viewItem ?? {}
  const { uniqueProps } = dataConnection ?? {}
  const inViewer = dataManagement_state ? true : false

  const _inViewer = props.inViewer || inViewer

  const appDataSorted = _appData ? _.sortBy(_appData) : []

  const [selectionOptions, setSelectionOptions] = useState()
  const [mainCaption, setMainCaption] = useState('Create  Data')
  const [commitCaption, setCommitCaption] = useState()
  const [dataFieldName, setDataFieldName] = useState(propKey ? propKey : null)
  const [dataList, setDataList] = useState([])
  const [staticView, setStaticView] = useState()
  const [staticList, setStaticList] = useState()
  const [forStaticViews, setForStaticViews] = useState()

  const allowSave = dataList && dataList.length > 0 && dataFieldName ? true : false

  let usePropField = true

  switch (_viewDataMode) {
    case dataSidebarActionTypes.updateStaticViews:
    case dataSidebarActionTypes.createGlobalData:
      usePropField = false;
      break;
    default:
    // nothing
  }

  let buttonCaption = _.startCase(_viewDataMode)

  if (dataFieldName) {
    buttonCaption = buttonCaption += ' (' + _.startCase(key_viewItem) + '/' + _.startCase(dataFieldName) + ')'
  } else {
    buttonCaption = buttonCaption += ' (' + _.startCase(key_viewItem) + ')'
  }

  const getItems = (items) => {
    const _items = []
    items.forEach(d => {
      const n = dataFieldName ? d[dataFieldName] : convertHelpers.getItemName(d)
      if (n) {
        if (_.isArray(n)) {
          n.forEach(d => {
            if (!_items.includes(d)) {
              _items.push(d)
            }
          })
        } else {
          if (!_items.includes(n)) {
            _items.push(n)
          }
        }
      }
    })
    return _items
  }

  useEffect(() => {
    switch (_viewDataMode) {
      case dataDataActionTypes.createCollectionData:
        setMainCaption('Create Collection Data')
        setCommitCaption('Are you sure you want to update/create a data collection for the item')
        setDataFieldName(_viewDataMode === dataDataActionTypes.createCollectionData ? 'name' : null)
        break;

      case dataSidebarActionTypes.createGlobalData:
        setMainCaption('Create Global Data')
        setCommitCaption('Are you sure you want to update/create global data for the item')
        setDataFieldName(_viewDataMode === dataSidebarActionTypes.createGlobalData ? 'name' : null)
        const _itemz = appDataSorted ? getItems(appDataSorted) : []
        setStaticList(_itemz)
        break;

      case dataSidebarActionTypes.updateStaticViews:
        setMainCaption('Save to Static Views')
        setCommitCaption('Are you sure you want to update/create the static views')
        setDataFieldName(_viewDataMode === dataSidebarActionTypes.updateStaticViews ? 'name' : null)
        setForStaticViews(true)
        const _sv = staticViews ? staticViews[key_viewItem] : {}
        const _items = _sv ? getItems(appDataSorted) : []
        const _staticList = _sv ? getStaticItems(appDataSorted, dataFieldName, uniqueProps) : []
        setStaticView(_items)
        setStaticList(_staticList)
        console.log('_staticList', _staticList)

        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_viewDataMode]);

  const handleConvertProp = () => {
    const _items = getItems(appDataSorted)
    setDataList(_items)
  }

  useEffect(() => {
    if (appDataSorted) {
      const { headers: h } = getHeaders(appDataSorted)
      if (!h.includes('name')) { h.push('name') }
      setSelectionOptions(listList(h))
      const _items = getItems(appDataSorted)
      setDataList(_items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleFieldChange = (e, data) => setDataFieldName(data.value)


  /**
   * saves the data to the database
   */
  const handleSave = () => {

    let staticName = dataFieldName === 'name' ? key_viewItem : dataFieldName

    dataManagement_handlers.handleStartUpdate()

    const dataItems = {}
    dataList.forEach(l => { dataItems[l] = { name: l } })

    switch (_viewDataMode) {
      case dataDataActionTypes.createCollectionData:
        if (!staticName.endsWith('s')) { staticName = staticName + 's' }
        const collectionItems = {}
        dataList.forEach((l, index) => { collectionItems[index + 1] = { name: l } })
        // collectionName, dataItems, clearData, updateOnly
        dataManagement_handlers.handleUpdate_collection(staticName, collectionItems, true)
        break;

      case dataSidebarActionTypes.createGlobalData:
        dataManagement_handlers.handleUpdate_global(key_viewItem, dataItems)
        break;

      case dataSidebarActionTypes.updateStaticViews:
        dataManagement_handlers.handleSaveToStaticViews(staticList, key_viewItem)
        break;

      default:
      // nothing
    }
  }

  const handleCommit = () => {
    dataManagement_handlers.handleStartUpdate()
    handleSave()
  }

  const handleShowCommit = () => {
    if (dataManagement_handlers) {
      dataManagement_handlers.handleShowCommit_dataManagment(_viewDataMode)
    } else {
      const cb = () => {
        callback && callback()
      }
      const dataItems = {}
      dataList.forEach(l => { dataItems[l] = { name: l } })
      updateGlobal(pathViews, viewKey, dataItems, cb)
    }
  }
  const handleKillCommit = () => dataManagement_handlers.handleShowCommit_dataManagment()

  /** This is the dropdown for the field */
  const header = () => <Form>
    <Form.Dropdown
      placeholder='Select Field'
      fluid
      selection
      options={selectionOptions ? selectionOptions : []}
      onChange={handleFieldChange}
    />
  </Form>

  const itemList = (listItem) => _.map(listItem, (item, key) => {
    return <List.Item>
      <List.Header>{_.startCase(key)}</List.Header>{item}
    </List.Item>
  })

  const documentsContent = (list) => {
    let segs = []
    if (list) {
      segs = _.map(list, (listItem, key) => {
        return <Segment.Group>
          <Segment>{key}</Segment>
          <Segment><List>
            {itemList(listItem)}
          </List></Segment>
        </Segment.Group>
      })
    }
    return <div className={'document-container'}>{segs}</div>
  }

  const content = () => {
    switch (_viewDataMode) {
      case dataSidebarActionTypes.updateStaticViews:
        return documentsContent(staticList)
      default:
        return <JsonViewer json={staticList} />
    }
  }

  const sidebar_confirm = (visible) => <SidebarConfirm
    visible={visible}
    onHide={handleKillCommit}
    oc={handleCommit}
    header={_.startCase('confirmation')}
    content={'Are you sure you want update the static views for the data?'}
    saveCaption={_.startCase(_viewDataMode)}
    useCancel={true}
    updating={updating}
  />

  const container = () => <Wrapper
    header={usePropField && header()}
    content={dataList && content()}
    footer={<UiSaveButtons
      preview={forStaticViews ? null : { oc: handleConvertProp, disabled: !dataFieldName }}
      save={{ oc: handleShowCommit, color: 'blue', caption: buttonCaption, disabled: !allowSave, icon: appIconTypes.arrowRight }} />
    }
    wrapperType={forStaticViews ? wrapperTypes.paddedFooter : wrapperTypes.padded}
    css={{ container: 'global-data' }}
    updating={updating}
    confirmation={confirmation}
  />

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_confirm(showCommitDataManagment)}
    <Sidebar.Pusher dimmed={showCommitDataManagment} className={'h100'}>
      {container()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    topperCaption={mainCaption + ' (' + _.startCase(dataFieldName) + ')'}
  />

  return _inViewer ? sidebar() : fullPageWrapper()

}

export default UiCreateGlobalData