import React, { useContext } from 'react'
import { Icon, Image } from 'semantic-ui-react'
import { uniqueKey } from '../../common/keys'
import { ParentContext } from '../../cnr/contexts/ParentContext'

const DefaultImageIcon = (props) => {

  const {
    allowAction,
    cn,
    containerRef,
    defaultImageIcon,
    handleShowBigImage,
    iconImage,
    iconName,
    iconSize,
    isInit,
    isVisible,
    itemData,
    showDefaultImage,
    useIconImage,
    useLazyImageLoading,
  } = props ?? {}

  const { id } = itemData ?? {}

  const parentContext = useContext(ParentContext);
  const { fns } = parentContext ?? {}
  const { storage_fns } = fns

  let _iconName = iconName ? iconName : 'user'
  let iconColor = 'blue'

  if (useLazyImageLoading && !isVisible) { iconColor = 'grey' }
  if (defaultImageIcon) { _iconName = defaultImageIcon }

  if (showDefaultImage) {

    const iconImageUrls = storage_fns.getIconUrl(useIconImage, iconImage)
    const { urls } = iconImageUrls ?? {}
    const { full, thumbnail } = urls ?? {}
    const src = thumbnail ? thumbnail : full

    if (allowAction && !isInit && handleShowBigImage) {
      return <Icon.Group
        size={iconSize ? iconSize : 'huge'}
        key={uniqueKey('crdi', 'i', id)}
        onClick={() => { allowAction && handleShowBigImage && handleShowBigImage() }}
      >
        <Icon name={_iconName} color={iconColor} />
        <Icon corner name='add' />
      </Icon.Group>
    } else {
      if (src) {
        return <div className={cn} key={uniqueKey('crdi', id)} ref={containerRef} >
          <Image src={src} alt={iconImage} className={'icon-image'} />
        </div>
      } else {
        return <div className={cn} key={uniqueKey('crdi', id)} ref={containerRef} >
          <Icon
            key={uniqueKey('crdi', 'i', id)}
            name={_iconName} size={iconSize ? iconSize : 'huge'}
            color={iconColor}
            onClick={() => { handleShowBigImage && handleShowBigImage() }}
          />
        </div>
      }

    }
  } else {
    return null
  }
}

export default DefaultImageIcon