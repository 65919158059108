import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { convertHelpers } from '../../common/convert';
import { gEnums } from '../../enums/globalEnums';
import { getAllAppUserUrls } from '../../storage/storageHelpers';
import { eventInfoHandlers, eventInfoInitialState, eventInfoReducer } from '../reducers/EventInfoReducer';
import { AppSettingsContext } from './AppSettingsContext';
import { AppUsersContext } from './AppUsersContext';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';
import { ServiceWorkerContext } from './ServiceWorkerContext';
import { DatabaseContext } from './DatabaseContext';
import { _cacheNames, _cacheTypes } from '../reducers/ServiceWorkerReducer';
import { timeStampsHelpers } from '../../firestoreData/updates/subUpdates/fsTimeStamps';

const _allowTsCacheUpdate = true

/** gets the data for the uiItem.
 * @returns viewListData, confirmation, globalFiles */
export const EventInfoContext = createContext();

/** gets the data for the uiItem.
 * @returns viewListData,  
 * @returns confirmation, 
 * @returns globalFiles */
const EventInfoProvider = (props) => {

  // ParentContext not available

  const aat = gEnums.appAreaTypes
  const svc = [aat.clients, aat.clubs, aat.districts, aat.event, aat.leagues, aat.schools, aat.organizations, aat.client]

  // databaseContext
  const databaseContext = useContext(DatabaseContext)
  const { database_fns } = databaseContext ?? {}

  // appUsersContext
  const appUsersContext = useContext(AppUsersContext);
  const { appUsers_handlers } = appUsersContext
  const { handleSet_eventAppUsers } = appUsers_handlers ?? {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext
  const { appArea, pathViews, cacheKey } = paps_state ?? {}

  // serviceWorkerContext
  const serviceWorkerContext = useContext(ServiceWorkerContext)
  const { serviceWorker_state, serviceWorker_handlers } = serviceWorkerContext ?? {}
  const { cache_info } = serviceWorker_state ?? {}
  const { cache_groups } = cache_info ?? {}
  const { cache_event } = cache_groups ?? {}
  const cache_currentEvent = cache_event && pathViews && pathViews.events ? cache_event[pathViews.events] : {}
  const { cache_collections: _cache_collections, _timeStamps: _timeStamps_cache } = cache_currentEvent ?? {}

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext);
  const { appSettings_state } = appSettingsContext ?? {}
  const { timeStamps_event_db } = appSettings_state ?? {}

  const isOrganization = pathViews.organizations ? true : false

  // pageContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings, aps_viewItems } = pageSettings ?? {}
  const { appDataSource, topMenu } = aps_global ?? {}
  const { appUserCollection, appUserCollections, appUserViewTypeProp, allowPresenceTracking } = aps_appUserSettings ?? {}
  const { useStartDataCollections, getAllDataCollections, startDataCollections } = appDataSource ?? {}
  const { mainFixedCaption } = topMenu ?? {}

  const _appUserCollections = appUserCollections ? appUserCollections : []
  if (_appUserCollections.length === 0 && appUserCollection) { _appUserCollections.push(appUserCollection) }

  // eventInfoInitialState
  const initState = eventInfoInitialState({ pathViews, database_fns })
  const [eventInfo_state, dispatch] = useReducer(eventInfoReducer, initState);
  const eventInfo_handlers = eventInfoHandlers(dispatch)

  const { staticViews, staticEventKey, appDataDocuments, appGlobalData, appGlobalDataFromCache } = eventInfo_state ?? {}
  const { attendees } = staticViews ?? {}

  const getStaticName = (staticView, staticValue, returnFound) => {
    const _staticView = staticView === 'guests' ? 'attendees' : staticView
    if (staticViews && staticViews[_staticView] && staticViews[_staticView][staticValue]) {
      const svv = staticViews[_staticView][staticValue]
      if (returnFound) {
        return { sv: convertHelpers.getItemName(svv) }
      } else {
        return convertHelpers.getItemName(svv)
      }
    } else {
      if (returnFound) {
        return { sv: staticValue, notFound: true }
      } else {
        return staticValue
      }
    }
  }

  useEffect(
    () => {

      const cache_eventInfo = {
        cache_collection_timeStampsInfo: {},
        ...cache_currentEvent
      }

      // get the cache_collection_timeStampsInfo from cache
      const { cache_collections, _timeStamps } = cache_eventInfo

      // console.log('timeStamps_event_db', timeStamps_event_db)
      // console.log('cache_event', cache_event)
      // console.log('_timeStamps', _timeStamps)

      _.forEach(cache_collections, (cache_collection, collectionKey) => {

        const eventTimeStamp_db = timeStamps_event_db && timeStamps_event_db[collectionKey]
        const eventTimeStamp_cache = _timeStamps && _timeStamps[collectionKey]

        if (eventTimeStamp_db && eventTimeStamp_cache) {
          cache_eventInfo.cache_collection_timeStampsInfo[collectionKey] = {
            cacheUpdateNeeded: eventTimeStamp_db !== eventTimeStamp_cache,
          }
        } else {
          cache_eventInfo.cache_collection_timeStampsInfo[collectionKey] = {
            cacheUpdateNeeded: true,
            timeStampUpdateNeeded: true,
          }
        }

      });

      // console.log('cache_eventInfo', cache_eventInfo)
      eventInfo_handlers.handleSet_eventCacheData(pathViews, cache_eventInfo)


      // eventInfo_handlers.handleGet_appGlobalData(pathViews, cache_appGlobalData)
    },
    // eslint-disable-next-line
    []
  )

  useEffect(
    () => {
      if (svc.includes(appArea)) {
        if (staticEventKey !== pathViews.events && !isOrganization) {
          eventInfo_handlers.handleGet_eventStaticViews(pathViews)
        }
      }

      if (useStartDataCollections && (startDataCollections || getAllDataCollections)) {
        const _collectionItems = aps_viewItems ? Object.keys(aps_viewItems) : []
        eventInfo_handlers.handleGet_startDataCollections(startDataCollections, getAllDataCollections, _collectionItems, pathViews)
      }

      if (allowPresenceTracking) {
        eventInfo_handlers.handleGet_appUserPresence(pathViews)
      }

      eventInfo_handlers.handleInit_event({ pathViews, aps_viewItems, appDataSource })

    },
    // eslint-disable-next-line
    [pathViews.events]
  )

  useEffect(
    () => {
      if (!appGlobalDataFromCache && appGlobalData) {
        const settings = {
          cacheKey: cacheKey,
          cacheName: _cacheTypes.appGlobalData,
          cacheData: appGlobalData,
        }
        serviceWorker_handlers.handleSend_messageToServiceWorker(settings);
      }
    },
    // eslint-disable-next-line
    [appGlobalData]
  )

  useEffect(
    () => {
      if (attendees && (appUserViewTypeProp === gEnums.appUserProfileImageTypes.profile)) {
        getAllAppUserUrls(attendees, eventInfo_handlers.handleSet_appUserUrls)
      }
    },
    // eslint-disable-next-line
    [attendees]
  )

  useEffect(
    () => {
      if (appDataDocuments) {
        if (appUserCollection && appDataDocuments[appUserCollection]) {
          handleSet_eventAppUsers(appDataDocuments[appUserCollection])
        }
      }
    },
    // eslint-disable-next-line
    [appDataDocuments]
  )

  // update the cache with the data from the appDataDocuments
  useEffect(
    () => {
      if (appDataDocuments) {

        if (_allowTsCacheUpdate && !_timeStamps_cache && timeStamps_event_db) {
          timeStampsHelpers.updateTimeStamps_sw(cacheKey, timeStamps_event_db)
        }

        _.forEach(appDataDocuments, (appDataDocument, collectionKey) => {
          let allowSend = false
          if (!_cache_collections) {
            allowSend = true
          } else if (!_cache_collections[collectionKey]) {
            allowSend = true
          }

          if (allowSend) {
            const settings_data = { cacheKey: cacheKey, cacheName: collectionKey, cacheData: appDataDocument }
            serviceWorker_handlers.handleSend_messageToServiceWorker(settings_data);
          }
        })
      }
    },
    // eslint-disable-next-line
    [appDataDocuments]
  )

  const eventInfo_fns = {
    getStaticName: (staticView, staticValue, returnFound) => getStaticName(staticView, staticValue, returnFound)
  }

  if (svc.includes(appArea)) {
    if (staticEventKey) {
      return <EventInfoContext.Provider value={{ eventInfo_state, eventInfo_handlers, eventInfo_fns }}>
        {props.children}
      </EventInfoContext.Provider>
    } else {
      return <EventInfoContext.Provider value={{ eventInfo_state, eventInfo_handlers, eventInfo_fns }}>
        {props.children}
      </EventInfoContext.Provider>
    }
  } else {
    return <EventInfoContext.Provider value={{ eventInfo_state, eventInfo_handlers, eventInfo_fns }}>
      {props.children}
    </EventInfoContext.Provider>
  }
}

export default EventInfoProvider


// Understood, if that's the case all you have to do is to reset the Play: 1 by following the steps below and proceed with the downgrade process in which you can do by following the steps on this link, https://support.sonos.com/article/downgrade-a-sonos-product-to-s1

// To perform a factory reset on your Sonos device. Just to set your expectations this will completely wipe-out everything that has been saved and configured on the Sonos unit.

// Kindly follow the steps:
// 1. Unplug the power cord.
// 2. Press and hold the Play/Pause button while you reconnect the power cord.
// 3. Continue holding the button until the light flashes orange and white.
// 4. The light will flash green when the process is complete and the product is ready to be set up.
// You can refer to this article https://support.sonos.com/article/reset-your-sonos-product