import _ from 'lodash';
import { gEnums } from '../../../enums/globalEnums';
import { pageItemActionTypes } from '../../enums/itemActionTypes';
import { allowApply, assignColors } from './menuItemHelpers';

const _allowAll = false

/** Creates simple menu from the settings */

/**
 * called from ViewSettingsMenuReducer
 * @param {*} props (many)
 * @param {*} appUserAccess 
 * @param {*} settingsOptions 
 * @returns Creates simple menu from the settings
 */
export const getActiveViewSettingsMenuItems = (props, appUserAccess) => {

  // IMPORTANT: Settings - getActiveViewSettingsMenuItems    

  const {
    activeData,
    baseSettings_state,
    data_current,
    desktopMode,
    getColors,
    isGlobal,
    menuItems,
    menuType,
    menuTypeKey,
    propsOnly,
    settingsArea_state,
    themeColors,
  } = props

  const { baseSettings } = baseSettings_state ?? {}
  const { adjustedPermissions } = baseSettings ?? {}
  const { global: global_prm, page: page_prm, pageItem: pageItem_prm } = adjustedPermissions ?? {}
  const { projectModules } = settingsArea_state ?? {}

  let activeMenuItems = []

  if (menuItems) {

    if (_.isArray(menuItems)) {

      const grpSettings = global_prm[menuTypeKey] ? global_prm[menuTypeKey] : {}

      // most likely sub or child items 
      menuItems.forEach(item => {
        if (item) {

          let allowItem = false

          if (pageItemActionTypes[item]) {
            const { allowQuestionsAndAnswers, allowVideoConferencing } = projectModules ?? {}
            switch (item) {
              case pageItemActionTypes.questionsAndAnswers:
                allowItem = allowQuestionsAndAnswers
                break;
              case pageItemActionTypes.videoConferencing:
                allowItem = allowVideoConferencing
                break;
              default:
                allowItem = true
              // nothing
            }
          } else {
            allowItem = true
          }

          if (allowItem && !grpSettings.restrict || (appUserAccess && (appUserAccess.settingsAuthLevel === gEnums.accessLevels.superAdmin.value))) {
            const { restrict } = grpSettings
            let caption = _.startCase(item)
            const i = {
              caption,
              key: menuTypeKey,
              settingKey: item,
              parentSettingKey: item,
              subKey: item,
              show: true,
              showDesktop: true,
              restrict,
              propSection: item.propSection,
              ...assignColors(data_current, themeColors, menuType, getColors, item, true)
            }
            if (data_current[item]) { i.applied = data_current[item].apply }
            if (activeData && activeData[item] && activeData[item]) {
              if (activeData[item].allowAction) { i.isActive = true }
              if (activeData[item].apply) { i.isActive = true }
              if (activeData[item].useTransitions) { i.isActive = true }
            }
            activeMenuItems.push(i)
          }
        }
      })
    } else {


      // the menuItems here are the pageItems/viewItems 
      Object.keys(menuItems).forEach(key => {

        let grpSettings;

        if (page_prm[key]) {
          grpSettings = page_prm[key]
        } else if (pageItem_prm[key]) {
          grpSettings = pageItem_prm[key]
        } else {
          grpSettings = {}
        }

        const { componentAreas } = grpSettings ?? {}
        const { pageAreaTypes } = componentAreas ?? {}

        if (!grpSettings.caption) { grpSettings.caption = _.startCase(key) }

        let allow = true

        if (grpSettings) {

          if (pageAreaTypes && !pageAreaTypes.includes('all')) {
            if (isGlobal) {
              if (pageAreaTypes && !pageAreaTypes.includes('global') && !pageAreaTypes.includes('global')) {
                allow = false
              }
            } else {
              if (pageAreaTypes && !pageAreaTypes.includes('page')) {
                allow = false
              }
            }
          }

          if (grpSettings.restrict) {
            if (!appUserAccess) {
              allow = false
            } else {
              if (appUserAccess.settingsAuthLevel < gEnums.accessLevels.admin.value) {
                allow = false
              }
            }
          }

          if (grpSettings.settingsAuthLevel) {
            if (grpSettings.settingsAuthLevel > appUserAccess.settingsAuthLevel) {
              allow = false
            }
          }

        }

        if (allow || _allowAll) {
          activeMenuItems.push(getMenuItem(desktopMode, props, key, null, grpSettings, activeData))
        }
      })
    }
  }

  switch (menuType) {
    case gEnums.menuTypes.viewItem:
    case gEnums.menuTypes.viewItemData:
      if (isGlobal) { activeMenuItems = _.sortBy(activeMenuItems, 'key') }
      break;

    case gEnums.menuTypes.propItem:
      if (propsOnly) { activeMenuItems = _.sortBy(activeMenuItems, 'key') }
      break;
    default:
    // nothing
  }

  return activeMenuItems

}

/**
 * 
 * @param {boolean} desktopMode 
 * @param {object} props 
 * @param {string} key 
 * @param {string} otherKey 
 * @param {object} grpSettings 
 * @param {object} activeData 
 * @returns an object for the menuItem set up for the element to read
 */
const getMenuItem = (desktopMode, props, key, otherKey, grpSettings, activeData) => {

  const { menuItems, menuTypeKey, data_current, menuType, themeColors, getColors } = props

  const menuItem = menuItems[key]

  const { restrictAdd, adminOnly, remove, show, showDesktop, tagged, schedule, header, cssed, drilled, secret, pin, propSection, applied, display: display_item } = menuItem ?? {}

  const iconProps = { adminOnly, cssed, drilled, header, pin, schedule, secret, show, tagged, remove, restrictAdd }

  // remove the undefined
  _.forEach(iconProps, (iconProp, key) => {
    if (iconProp === undefined) {
      delete iconProps[key]
    }
  })

  let { caption, defaultIcon } = display_item ?? {}

  const _show = desktopMode ? showDesktop : show
  let isActive;

  if (activeData && activeData[key] && Object.keys(activeData[key]).length > 0) {
    switch (key) {
      case 'dataManagement':
      case 'dataModifications':
      case 'pageItemActions':
      case 'permissions':
        const activeAllows = _.find(activeData[key], { 'allowAction': true })
        if (activeAllows) isActive = true
        break;
      default:
        isActive = true
    }
  }

  const { restrict, settingsAuthLevel, isAlt } = grpSettings

  if (!caption) { caption = grpSettings.caption }
  if (menuItem.displayCaption) { caption = _.startCase(menuItem.displayCaption) }

  switch (menuTypeKey) {
    case 'viewItems':
      if (caption !== _.startCase(key)) { caption += ' (' + _.startCase(key) + ')' }
      break;
    case 'views':
      break;
    default:
    // nothing
  }

  const showItem = allowApply(menuType) ? applied : _show
  const otherKeyValue = otherKey ? menuItem[otherKey] : null

  const _menuItem = {
    isAlt,
    adminOnly,
    caption,
    icon: defaultIcon ? defaultIcon : null,
    isActive,
    key: otherKeyValue ? otherKeyValue : key,
    propSection,
    restrict,
    restrictAdd,
    secret,
    settingsAuthLevel,
    show: showItem,
    showDesktop: showItem,
    iconProps,
    subProps: { ...iconProps },
    ...assignColors(data_current, themeColors, menuType, getColors, menuItem)
  }

  return _menuItem

}

// const getGroupItemSettings = (aagpSetting, key) => {
//   let grpSettings = {}
//   Object.keys(aagpSetting).forEach(k => {
//     if (aagpSetting[k] && aagpSetting[k][key]) {
//       grpSettings = aagpSetting[k][key]
//     }
//   })
//   return grpSettings
// }