import { deleteField, serverTimestamp } from "firebase/firestore";
import { createRefPath_event } from "../../../../global/firestoreData/appData/appRefPaths";
import { fs_dbu } from "../../../../global/firestoreData/appData/fsAppDataUpdate";
import { handleUpdateEmailSent, handle_triggerEmail } from "./FieldTripHelpers";
import { updateDocumentHelpers } from "../../../../global/firestoreData/updates/subUpdates/fsUpdate_document";
import _ from 'lodash'
import { newPropEditItem } from "../../../../global/viewSettings/helpers/settingsHelpers";
import { gEnums } from "../../../../global/enums/globalEnums";
import { currentHelpers } from "../../../../global/redirection/current";
import { formatTypes } from "../../../../global/common/dateFormatting";

export const fieldTripListTypes = {
  approvals: 'approvals',
  validations: 'validations',
}

export const staffRoleTypes = {
  admin: 'admins',
  secretary: 'secretaries',
  principal: 'principals',
  districtResource: 'districtResources',
  specialEdSecretary: 'specialEdSecretaries',
  transportation: 'transportation',
  teacher: 'teachers',
};

export const approvalRoles = {
  secretaries: 'secretaries',
  principals: 'principals',
  districtResources: 'districtResources',
};

export const validationRoles = {
  specialEdSecretaries: 'specialEdSecretaries',
  transportation: 'transportation',
};

export const approvalTypes = {
  approved: 'approved',
  denied: 'denied',
  seen: 'seen',
  sent: 'sent',
  acknowledged: 'acknowledged',
  none: 'none',
};

export const emailTypes = {
  approval: 'approval',
  validation: 'validations'
}

export const rts = {
  handleCallback_fieldTripApproved: 'handleCallback_fieldTripApproved',
  handleInit_fieldTrip: 'handleInit_fieldTrip',
  handleResetData: 'handleResetData',
  handleStausChange: 'handleStausChange',
  handleTrigger_email: 'handleTrigger_email',
  handleUpdate_emailSent: 'handleUpdate_emailSent',
  handleUpdate_fieldTripApproved: 'handleUpdate_fieldTripApproved',
}

export const fieldTripReducer = (state, action) => {

  const { firestore_handlers, fieldTrip, fieldTrips_state, pathViews, sharePathFull, fieldTripStatus, appDataSource } = state
  const { fieldTripSettings } = fieldTrips_state ?? {}
  const { type, dispatch } = action
  const { useAppDataDocuments } = appDataSource ?? {}

  const { handleUpdate_emailSent, handleTrigger_email } = fieldTripHandlers(dispatch)

  const _fieldTripStatus = { ...fieldTripStatus }

  switch (type) {

    case rts.handleInit_fieldTrip:
      const _approvals = {
        [approvalRoles.secretaries]: { approvalType: approvalTypes.none },
        [approvalRoles.principals]: { approvalType: approvalTypes.none },
        [approvalRoles.districtResources]: { approvalType: approvalTypes.none },
      }
      const _validations = {
        [validationRoles.transportation]: { approvalType: approvalTypes.none },
        [validationRoles.specialEdSecretaries]: { approvalType: approvalTypes.none },
      }
      const _fts = {
        approvals: _approvals,
        validations: _validations,
      }

      // const _fieldTrip = fieldTrip ? fieldTrip : action.fieldTrip
      const _fieldTrip = action.fieldTrip

      if (_fieldTrip && !_fieldTrip.status) { _fieldTrip.status = {} }

      return {
        ...state,
        fieldTrip: _fieldTrip,
        fieldTripStatus: _fieldTripStatus ? _fieldTripStatus : _fts,
        approvals: _approvals,
        validations: _validations,
        extraFieldProps: getExtraFieldProps()
      }

    case rts.handleStausChange:
      const _appr = ammendApprovals(fieldTripStatus.approvals, fieldTrip.status)
      const _valds = ammendValidations(fieldTripStatus.validations, fieldTrip.status)
      _fieldTripStatus.validations = _valds
      _fieldTripStatus.approvals = _appr
      const roleToUpdate = getRoleToUpdate(fieldTrip)
      if (roleToUpdate) {
        return { ...state, fieldTripStatus: _fieldTripStatus, roleToUpdate }
      } else {
        return { ...state, fieldTripStatus: _fieldTripStatus }
      }


    case rts.handleUpdate_fieldTripApproved:
      const cb = () => {
        const { autoSendApprovalEmails } = fieldTripSettings ?? {}
        if (autoSendApprovalEmails) {
          const { role } = action.roleToUpdate ?? {}
          const emailRoles = sendTo_approval(role)
          if (emailRoles) {
            _.forEach(emailRoles, (emailRole) => {
              console.log('emailRole', emailRole)
              handle_triggerEmail(fieldTrips_state, fieldTrip, emailRole, pathViews, sharePathFull, handleUpdate_emailSent)
            })
          }
        }
      }
      handleUpdateApproved(fieldTrip, action.roleToUpdate, action.formData, pathViews, useAppDataDocuments, firestore_handlers, cb)
      return { ...state }

    case rts.handleCallback_fieldTripApproved:
      return { ...state }

    case rts.handleTrigger_email:
      handle_triggerEmail(fieldTrips_state, fieldTrip, action.role, pathViews, sharePathFull, handleUpdate_emailSent, action.cb)
      return { ...state }

    case rts.handleUpdate_emailSent:
      handleUpdateEmailSent(action.fieldTrip, action.role, pathViews, useAppDataDocuments)
      if (action.cb) { action.cb() }
      return { ...state }

    case rts.handleResetData:
      handle_resetData(fieldTrip, pathViews, useAppDataDocuments, firestore_handlers)
      return { ...state }

    default:
      return { ...state }
  }
}

export const fieldTripHandlers = (dispatch) => {
  return {
    handleInit_fieldTrip: (fieldTrip) => { dispatch({ type: rts.handleInit_fieldTrip, dispatch, fieldTrip }) },
    handleResetData: () => { dispatch({ type: rts.handleResetData, dispatch }) },
    handleStausChange: () => { dispatch({ type: rts.handleStausChange, dispatch }) },
    handleTrigger_email: (role, emailType, cb) => { dispatch({ type: rts.handleTrigger_email, dispatch, role, emailType, cb }) },
    handleUpdate_emailSent: (fieldTrip, role, cb) => { dispatch({ type: rts.handleUpdate_emailSent, dispatch, fieldTrip, role, cb }) },
    handleUpdate_fieldTripApproved: (roleToUpdate, formData, sendToTypes) => { dispatch({ type: rts.handleUpdate_fieldTripApproved, dispatch, roleToUpdate, formData, sendToTypes }) },
  }
}

export const fieldTripInitialState = (init_state) => {
  return { ...init_state, fieldTripStatus: { approvals: {}, validations: {} } }
};

const handleUpdateApproved = (fieldTrip, roleToUpdate, formData, pathViews, useAppDataDocuments, firestore_handlers, callback) => {
  if (roleToUpdate) {
    const { status, _itemKey } = fieldTrip ?? {}
    const { role, approved } = roleToUpdate ?? {}
    const dataToUpdate = {
      status: {
        ...status,
        [role + 'Approved']: approved,
        [role + 'ApprovedDate']: serverTimestamp(),
      }
    }
    if (formData) {
      _.forEach(formData, (formDataValue, key) => {
        dataToUpdate[key] = formDataValue
      })
    }
    if (useAppDataDocuments) {
      firestore_handlers.handleUpdate_itemInDocuments('fieldTrips', _itemKey, dataToUpdate, callback)
    } else {
      const refPath = createRefPath_event(pathViews, ['fieldTrips', _itemKey])
      fs_dbu.update_doc(refPath, dataToUpdate)
    }
  }
};

const handle_resetData = (fieldTrip, pathViews, useAppDataDocuments, firestore_handlers) => {
  const dataToUpdate = { status: deleteField(), paymentMethod: deleteField(), estimatedAmount: deleteField(), fundsAvailable: deleteField() }
  if (useAppDataDocuments) {
    firestore_handlers.handleUpdate_itemInDocuments('fieldTrips', fieldTrip._itemKey, dataToUpdate)
  } else {
    const refPath = createRefPath_event(pathViews, ['fieldTrips', fieldTrip._itemKey])
    fs_dbu.update_doc(refPath, dataToUpdate)
  }
};


const sendTo_approval = (role) => {
  const sendToTypes = [];
  switch (role) {
    case approvalRoles.secretaries:
      sendToTypes.push(approvalRoles.principals)
      break;
    case approvalRoles.principals:
      sendToTypes.push(approvalRoles.districtResources)
      break;
    case approvalRoles.districtResources:
      // principals approval 
      sendToTypes.push(validationRoles.transportation)
      sendToTypes.push(validationRoles.specialEdSecretaries)
      break;
    default:
      break;
  }
  return sendToTypes
};

const getExtraFieldProps = () => {
  const extraFieldProps = {
    secretaries: {
      paymentMethod: newPropEditItem('paymentMethod', gEnums.formItemTypes.text),
      estimatedAmount: newPropEditItem('estimatedAmount', gEnums.formItemTypes.text),
      fundsAvailable: newPropEditItem('fundsAvailable', gEnums.formItemTypes.checkbox)
    }
  }
  return extraFieldProps
}

const ammendApprovals = (approvals, status) => {

  const _approvals = { ...approvals }

  _.forEach(approvalRoles, (approvalRole) => {

    const isApproved = status[approvalRole + 'Approved']
    const approvedDate = status[approvalRole + 'ApprovedDate']
    const emailSentDate = status[approvalRole + 'EmailSentDate']

    switch (isApproved) {
      case true:
        _approvals[approvalRole] = {
          approvalType: approvalTypes.approved,
          approvalDate: approvedDate
        }
        break;
      case false:
        _approvals[approvalRole] = {
          approvalType: approvalTypes.denied,
          approvalDate: approvedDate
        }
        break;
      default:
        if (emailSentDate) {
          _approvals[approvalRole] = {
            approvalType: approvalTypes.sent,
          }
        } else {
          _approvals[approvalRole] = {
            approvalType: approvalTypes.none,
          }
        }
        break;
    }
  })

  return _approvals

}

const ammendValidations = (validations, status) => {

  const _validations = { ...validations }

  _.forEach(validationRoles, (validationRole) => {

    const isApproved = status[validationRole + 'Approved']
    const approvedDate = status[validationRole + 'ApprovedDate']
    const emailSentDate = status[validationRole + 'EmailSentDate']

    switch (isApproved) {
      case true:
        _validations[validationRole] = {
          approvalType: approvalTypes.approved,
          approvalDate: approvedDate
        }
        break;
      case false:
        _validations[validationRole] = {
          approvalType: approvalTypes.denied,
          approvalDate: approvedDate
        }
        break;
      default:
        if (emailSentDate) {
          _validations[validationRole] = {
            approvalType: approvalTypes.sent,
          }
        } else {
          _validations[validationRole] = {
            approvalType: approvalTypes.none,
          }
        }
        break;
    }
  })

  return _validations

}

const getRoleToUpdate = (fieldTrip) => {
  const { href } = window.location
  const qvs = href ? currentHelpers.getQueryValues(href) : {}
  const { role, approved, itemKey } = qvs ?? {}
  if (role && itemKey) {
    const _fieldTripRole = fieldTrip[role]
    if (_.isArray(_fieldTripRole) && _fieldTripRole.includes(itemKey)) {
      const _roleToUpdate = {
        role,
        approved
      }
      return _roleToUpdate
    }
  }
}
