import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { FrameworkContext } from '../../../cnr/contexts/FrameworkContent';
import { ViewSettingsContext } from '../../../cnr/contexts/ViewSettingsContext';
import { uniqueKey } from '../../../common/keys';
import { appIconTypes } from '../../../enums/appIconTypes';
import { gEnums } from '../../../enums/globalEnums';
import { iconColorTypes, settingsIconTypes } from '../../../enums/settingsIconTypes';
import HeaderDropdown from '../../headers/HeaderDropdown';
import MenuItemIcons from './MenuItemIcons';

/** Menu item for a single  */

/**
 * 
 * @param {*} props (menuItem, grProps, restrict, menuItemAllows, useDarkMode, showHelpLine)
 * @menuItem (caption, isActive, show, subKey, showDesktop, key: itemKey, settingKey, settingsAuthLevel, title)
 * @returns 
 */
const MenuItemSingle = (props) => {

  const { menuItem, grProps, restrict, menuItemAllows, useDarkMode, showHelpLine } = props

  const {
    caption,
    isActive,
    key: itemKey,
    settingKey,
    settingsAuthLevel,
    show,
    showDesktop,
    subKey,
    title,
  } = menuItem

  const { clicks, themeSelectClick, themeSelected } = grProps
  const { clickTo } = clicks ?? {}

  // viewSettingsSwipeContext - THIS has all the info
  const viewSettingsContext = useContext(ViewSettingsContext);
  const { viewSettings_state } = viewSettingsContext ?? {}
  const { settingsStatus } = viewSettings_state ?? {}

  let _caption = caption
  if (itemKey.startsWith('_')) { _caption += ' *' }
  if (itemKey.indexOf(' ') >= 0) { _caption += ' **' }

  let { icon } = menuItem

  const keyy = 'mis-' + menuItem.key

  // determine the icon color
  let isMarked = isActive

  if (show && !subKey) { isMarked = true }
  // if (item.hasContents) { isMarked = true }

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { desktopMode } = framework_state ?? {}

  const _show = desktopMode ? showDesktop : show

  if (!icon && itemKey && settingsIconTypes[itemKey]) { icon = settingsIconTypes[itemKey] }
  if (settingKey && settingsIconTypes[settingKey]) { icon = settingsIconTypes[settingKey] }
  if (!icon) { icon = 'list' }

  const themeIsOn = themeSelected === settingKey

  let useAllow = false
  let allowClick = false

  let cn = 'mis'

  if (menuItemAllows.useAllow) {
    useAllow = true
    allowClick = true
  } else {
    allowClick = true
  }

  if (restrict) {
    allowClick = false
    cn += ' restrict'
  }

  const arrowProps = { inverted: useDarkMode }

  const [hdd, setHdd] = useState()

  useEffect(() => {
    if (menuItemAllows.useAllow) {
      setHdd(<HeaderDropdown key={uniqueKey('hdd', keyy)} dataHeaderType={gEnums.dataHeaderTypes.menuHeader} clicks={clicks} menuItem={menuItem} menuItemAllows={menuItemAllows} />)
    }
    // eslint-disable-next-line
  }, [menuItem, settingsStatus]);

  const handleClick = () => clickTo(menuItem, true)

  /**
   * Handles the click of a menu icon (show, tag, etc.)
   * @param {object} e 
   * @param {string} iconType 
   */
  const handleIconClick = (e, iconType) => {
    const { clickOption } = clicks
    e.preventDefault()
    e.stopPropagation()
    if (clickOption) {
      clickOption({ menuItem, allowClick: true, clickOptionType: iconType }, e)
    }
  }

  const arrowIcon = () => <Icon key={uniqueKey('ai', keyy)} color={isMarked ? iconColorTypes.active : iconColorTypes.default} size={'small'} name={appIconTypes.arrowRight} {...arrowProps} />

  const highlightIcon = () => {
    const highlightSnc = {
      style: {
        backgroundColor: themeIsOn ? iconColorTypes.highlight : iconColorTypes.default,
      },
      className: themeIsOn ? 'applied' : null
    }
    return <Icon key={uniqueKey('mii', 'hi')} bordered size={'small'} circular name={'lightbulb'} {...highlightSnc} onClick={(e) => {
      themeSelectClick && themeSelectClick(e, menuItem, true, gEnums.menuItemOptionTypes.highlight)
    }}
    />
  }

  const colorStyle = (item) => {
    const { bcl, cl, cla } = item
    return {
      style: {
        backgroundColor: bcl ? bcl : iconColorTypes.default,
        color: cl ? cl : 'black',
      },
      className: cla ? 'clr applied' : 'clr'
    }
  }

  const colorDiv = () => {
    if (menuItemAllows.color) {
      return <div key={uniqueKey('mis', 'hi', keyy)} className={'smii'}>
        {highlightIcon()}
      </div>
    }
  }

  /**
   * 
   * @returns an Icon. This is the icon at the left side of the menu item.
   */
  const menuItemIcon = () => {
    const defaultColor = restrict ? iconColorTypes.default : iconColorTypes.menuItem
    let iconColor = defaultColor
    if (isMarked) { iconColor = iconColorTypes.active }
    const cn = !isMarked ? 'n-mrk' : 'mrk'
    const plc = _.find(gEnums.authLevels, { value: settingsAuthLevel })
    if (plc && plc.color) { iconColor = plc.color }
    if (menuItemAllows.color) {
      return <Icon key={uniqueKey('mii', keyy)} {...colorStyle(menuItem)} name={icon} bordered />
    } else {
      return <Icon key={uniqueKey('mii', keyy)} className={cn} color={iconColor} name={icon} circular inverted={useDarkMode} />
    }
  }

  const itemCaption = () => <div key={uniqueKey('mii', 'ic', keyy)} title={title}>{_caption}</div>

  const menuHelp = () => <div key={uniqueKey('mii', 'hlp', keyy)} className='menu-help'>Help</div>

  return <Menu.Item key={uniqueKey('mis', 'mim', keyy)} className={cn} onClick={() => { allowClick && handleClick() }}>
    <Menu.Header>
      {menuItemIcon()}
      {itemCaption()}
      {useAllow && <MenuItemIcons menuItem={menuItem} show={_show} handleIconClick={handleIconClick} menuItemAllows={menuItemAllows} useAllow={useAllow} desktopMode={desktopMode} itemKey={itemKey} useDarkMode={useDarkMode} />}
      {/* {tagDiv()} */}
      {colorDiv()}
      {!useAllow && arrowIcon()}
      {hdd && hdd}
      {/* {showHelpLine && menuHelp()} */}
    </Menu.Header>
  </Menu.Item>
}

export default MenuItemSingle