export const appFormTypes = {
  accessCode: 'accessCode',
  appAuth: 'appAuth',
  appEnvironment: 'appEnvironment',
  appLogin: 'appLogin',
  appUser: 'appUser',
  appUserAboutMe: 'appUserAboutMe',
  appUserBio: 'appUserBio',
  appUserFamily: 'appUserFamily',
  appUserGallery: 'appUserGallery',
  appUserAccess: 'appUserAccess',
  appUserDataQuery: 'appUserDataQuery',
  appUserInfo: 'appUserInfo',
  appUserPrivacy: 'appUserPrivacy',
  appUserProfile: 'appUserProfile',
  appUserProfileAboutMe: 'appUserProfileAboutMe',
  appUserProfileBio: 'appUserProfileBio',
  appUserProfileImage: 'appUserProfileImage',
  appUserPermissions: 'appUserPermissions',
  appUserQuery: 'appUserQuery',
  auth: 'auth',
  createSeason: 'createSeason',
  createEvent: 'createEvent',
  clientAuth: 'clientAuth',
  copyViewItem: 'copyViewItem',
  dataQuery: 'dataQuery',
  editSeason: 'editSeason',
  emailAuth: 'emailAuth',
  emailDirect: 'emailDirect',
  eventStatus: 'eventStatus',
  family: 'family',
  giftCard: 'giftCard',
  giftCardWithImage: 'giftCardWithImage',
  globalSettings: 'globalSettings',
  golfTournamentOptions: 'golfTournamentOptions',
  golfTournamentSettings: 'golfTournamentSettings',
  googleLinks: 'googleLinks',
  groupSettings: 'groupSettings',
  imagePropList: 'imageProps',
  imageMapping: 'imageMapping',
  jitsi: 'jitsi',
  mapInput: 'mapInput',
  meeting: 'meeting',
  meetingRoom: 'meetingRoom',
  message: 'message',
  messageDirect: 'messageDirect',
  moveFiles: 'moveFiles',
  note: 'note',
  notification: 'notification',
  notificationOptions: 'notificationOptions',
  notificationsDelivery: 'notificationsDelivery',
  notificationsDirect: 'notificationsDirect',
  notificationSettings: 'notificationSettings',
  notificationsSchedule: 'notificationsSchedule',
  pageFieldList: 'pageFieldList',
  pageItemSettings: 'pageItemSettings',
  propSettings: 'propSettings',
  propSectionSettings: 'propSectionSettings',
  pageSettings: 'pageSettings',
  password: 'password',
  permissionSettings: 'permissionSettings',
  // profile: 'profile',
  propInputs: 'propInputs',
  query: 'query',
  questionsAndAnswers: 'questionsAndAnswers',
  rating: 'rating',
  resetPassword: 'resetPassword',
  settings: 'settings',
  signIn: 'signIn',
  signInConfirmation: 'signInConfirmation',
  signInEmail: 'signInEmail',
  signInEmailPassword: 'signInEmailPassword',
  signInEvent: 'signInEvent',
  signInGlobal: 'signInGlobal',
  signInPassword: 'signInPassword',
  signInPhoneNumber: 'signInPhoneNumber',
  signInUserName: 'signInUserName',
  signUpEmail: 'signUpEmail',
  signUpEMail: 'signUpEMail',
  signUpEmailLink: 'signUpEmailLink',
  signUpPhoneNumber: 'signUpPhoneNumber',
  simpleInput: 'simpleInput',
  simpleSelect: 'simpleSelect',
  simpleSelectInput: 'simpleSelectInput',
  smsDirect: 'smsDirect',
  smsAllow: 'smsAllow',
  topic: 'topic',
  transferData: 'transferData',
  transferDataDirect: 'transferDataDirect',
  transferOther: 'transferOther',
  transferStorage: 'transferStorage',
  url: 'url',
  voting: 'voting',

  clientProfile: 'clientProfile',
  eventAdmin: 'eventAdmin',

  createGolfMatch: 'createGolfMatch',
  createGolfSmallEvent: 'createGolfSmallEvent',
  createGolfTournament: 'createGolfTournament',
  createGolfEvent: 'createGolfEvent',
}

export const appUserFormTypes = {
  appUserInfo: appFormTypes.appUserInfo,
  appUserPrivacy: appFormTypes.appUserPrivacy,
  appUserProfileImage: appFormTypes.appUserProfileImage,
  profileImage: 'profileImage'
}