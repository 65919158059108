import { thumbTouch } from "../../components/swipe/ThumbTouch"

const tickMargin = 10
const allowUpdate = true

// const usePerc = false

export const rts = {
  handleFinish: 'handleFinish',
  handleDisplayItem: 'handleDisplayItem',
  handleDiv: 'handleDiv',
  handleSvgElement: 'handleSvgElement',
  handleSvgType: 'handleSvgType',
  handleTouchEnd: 'handleTouchEnd',
  handleTouchMove: 'handleTouchMove',
  handleTouchStart: 'handleTouchStart',
}

export const swipeReducer = (state, action) => {

  const { svgType, isTouching, thumbTouch, actionLineColor, thisCourt, settings } = state
  const { type, touchEvent, dispatch, swipeHolder } = action

  let _touch = thumbTouch.touch

  const { handleFinish } = swipeHandlers(dispatch)

  switch (type) {

    case rts.handleDiv:
      return { ...state, swipeDiv: action.swipeDiv.current }

    case rts.handleTouchStart:

      let os = state.swipeDiv.getBoundingClientRect();

      _touch.offsetLeft = os.left
      _touch.offsetTop = os.top

      const { clientX, clientY, isTouch, isMouse } = getCoords(touchEvent)

      _touch.touchStartX = clientX;
      _touch.touchStartY = clientY;
      _touch.isTouch = isTouch
      _touch.isMouse = isMouse

      if (settings.allowHolder) {
        return { ...state, isTouching: true, done: false, totalDuration: 0 }
      } else {
        return { ...state, isTouching: true, done: false, totalDuration: 0, timerTotal: setInterval(state.tock, 50) }
      }

    case rts.handleTouchMove:

      if (isTouching) {
        if (_touch.isTouch || _touch.isMouse) {

          const { clientX, clientY } = getCoords(touchEvent)

          if (clientX && clientY) {

            let tempDiffX = Math.abs(_touch.touchNowX - clientX)
            let tempDiffY = Math.abs(_touch.touchNowY - clientY)

            // allow some movement to the touch hold
            if (settings.allowHolder) {
              if (tempDiffX < tickMargin && tempDiffY < tickMargin) {
                clearInterval(state.timer);
              }
            }

            _touch.touchNowX = clientX
            _touch.touchNowY = clientY

            // reset the timer if moved 
            _touch.moveDiffX = Math.abs(_touch.touchStartX - _touch.touchNowX)
            _touch.moveDiffY = Math.abs(_touch.touchStartY - _touch.touchNowY)

            if (_touch.direction === 'n') {
              if (_touch.moveDiffX > _touch.moveDiffY) {
                _touch.direction = 'h'
              } else {
                _touch.direction = 'v'
              }
            }

            if (_touch.touchNowY <= _touch.touchStartY) {
              _touch.up = true
            } else {
              _touch.up = false
            }


          }
        } else if (_touch.isMouse) {

        }
      }

      return { ...state, done: false }

    case rts.handleTouchEnd:
      if (isTouching) {
        clearInterval(state.timer);
        clearInterval(state.timerTotal)
        handleFinish()
        return { ...state, timerOn: false, done: true }
      } else {
        return { ...state }
      }

    case rts.handleFinish:

      let touchD = thumbTouch.touchData

      let newResult = touchD.csr.swipeResult
      let timerOn;

      if (swipeHolder) {
        newResult = swipeHolder
        touchD.actionLineColor = touchD.csr.color
        timerOn = false
        clearInterval(state.timer);
        clearInterval(state.timerTotal)
        touchD.csr.swipeResult = swipeHolder
      }

      if (thumbTouch.swipeData && thumbTouch.swipeData.resultItems.slowers && thumbTouch.swipeData.resultItems.slowers[thumbTouch.touchData.csr.resultItems]) {
        if (state.totalDuration > 25) {
          newResult = thumbTouch.swipeData.resultItems.slowers[thumbTouch.touchData.csr.swipeResult]
        }
      }

      if (thumbTouch.swipeData) { updateSwipeProps(state, newResult) }

      return {
        ...state,
        isTouching: false,
        done: true,
        swipedRight: _touch.swipedRight(),
        timerOn,
      }

    default:
      return { ...state }
  }
}

export const swipeInitialState = (initState) => {

  const { imageStyle, statCategory, tss, props } = initState ?? {}

  const touchData = {}

  return {
    isTouching: false,
    swipeType: initState.swipeType,
    tss: initState.tss,

    settings: {
      allowSwipe: tss ? tss.allowSwipeHold : false,
      colors: statCategory ? statCategory.colors : null,
      icons: statCategory ? statCategory.icons : null,
      handlers: tss ? tss.handlers : null,
      styleHeights: props ? props.styleHeights : {}
    },

    currentElem: null,
    touchData: touchData,
    next: '--',
    elapsed: 0,
    duration: 0,
    totalDuration: 0,

    thumbTouch: thumbTouch,
    imageStyle,
  }
};

export const swipeHandlers = (dispatch, state) => {
  return {
    handleDisplayItem: (displayItem) => { dispatch({ type: rts.handleDisplayItem, displayItem }) },
    handleDiv: (swipeDiv) => { dispatch({ type: rts.handleDiv, swipeDiv }) },
    handleFinish: (isEOP, swipeHolder) => { dispatch({ type: rts.handleFinish, isEOP, swipeHolder }) },
    handleReset: () => { dispatch({ type: rts.handleReset }) },
    handleTouchEnd: (touchEvent) => { dispatch({ type: rts.handleTouchEnd, touchEvent, dispatch }) },
    handleTouchMove: (touchEvent) => { dispatch({ type: rts.handleTouchMove, touchEvent, dispatch }) },
    handleTouchStart: (touchEvent) => { dispatch({ type: rts.handleTouchStart, touchEvent, dispatch }) },
  }
}

const updateSwipeProps = (state, newResult) => {

  let updateIsGood = true

  const { thumbTouch, settings, thisCourt } = state
  let touchD = thumbTouch.touchData

  let swipeProps = { details: {} }

  // touchD.current
  swipeProps.details['movement'] = touchD.movement(state.sectors.maxCourtWidth, thisCourt.maxHeight, touchD)
  if (touchD.dipped) { swipeProps['dipped'] = touchD.dipped }

  if (thumbTouch.swipeData) {
    Object.keys(thumbTouch.swipeData.requestItems).forEach(key => {
      if (key !== 'xtra') {
        if (!thumbTouch.swipeData.requestItems[key] || thumbTouch.swipeData.requestItems[key] === '---') {
          console.log('NOT GOOD' + key)
          updateIsGood = false
        }
      }
    })
  }

  if (allowUpdate && updateIsGood && !touchD.areasSame()) {
    settings.handlers.updateSwipeAction(swipeProps)
  }
}

const getCoords = (touchEvent) => {

  let clientX;
  let clientY;
  let isTouch;
  let isMouse;

  if (touchEvent.touches && touchEvent.touches[0]) {
    clientX = touchEvent.touches[0].clientX;
    clientY = touchEvent.touches[0].clientY;
    isTouch = (touchEvent.touches);
  } else {
    clientX = window.event.clientX;
    clientY = window.event.clientY;
    isMouse = true
  }

  return { clientX, clientY, isTouch, isMouse }
}