import _ from 'lodash';
import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';
import { appIconTypes } from '../../../enums/appIconTypes';
import { gEnums } from '../../../enums/globalEnums';
import MenuSettingsClose from './MenuSettingsClose';
import SettingsAreaGroupMenu from './SettingsAreaGroupMenu';

const SettingsAreaMenuBottom = (props) => {

  const { mbProps } = props ?? {}

  const {
    activeAll,
    isGlobal,
    isSolo,
    pathViews,
    pushSimple,
    updateAll,
    useCollapsibleMenus,
    useDarkMode,
    view,
    viewKey,
    _viewPermissionType,
  } = mbProps ?? {}

  const miExpand = () => <Menu.Item key={uniqueKey('smsmi', 'x')} onClick={() => updateAll()} >
    <Icon color={'blue'} name={activeAll ? 'expand' : 'window maximize outline'} />
  </Menu.Item>

  const menuItem_close = (text, iconName, menuIconColor, gotoKey, gotoId, gotoMode) => <Menu.Item key={iconName} onClick={() => pushSimple({ key: gotoKey }, gotoId, gotoMode)}>
    <Icon color={menuIconColor} name={iconName} />
    {text}
  </Menu.Item>

  const addGroupItem = (grp, key, goto) => {
    grp.groupItems[key] = { ui: { caption: _.startCase(key) }, gotoKey: goto }
  }

  const content = () => {
    if (isSolo) {
      const bmis = []
      bmis.push(menuItem_close('Close', appIconTypes.delete, 'blue', 'close'))
      if (useCollapsibleMenus) { bmis.push(miExpand()) }
      if (bmis.length > 0) { return <MenuSettingsClose useDarkMode={useDarkMode} /> }
    } else {
      const group = { key: 'admin', iconColor: 'grey', allowed: true, groupItems: {} }
      const _grpSettings = { caption: 'Admin', icon: 'settings', settingsAuthLevel: gEnums.authLevels.superAdmin.value, notInverted: true }

      if (_viewPermissionType === gEnums.viewPermissionTypes.allow && isGlobal) {
        if (view !== 'home') {
          addGroupItem(group, 'home', 'home')
          if (pathViews.clients) { addGroupItem(group, 'clients', 'allClients') }
          if (pathViews.clients && pathViews.clients !== viewKey) { addGroupItem(group, 'client', 'client') }
        }
      }

      const _menuItemsGroup = <SettingsAreaGroupMenu group={group} grpSettings={_grpSettings} inverse={true} />

      if (Object.keys(group.groupItems).length > 0) {
        return <Menu borderless inverted={useDarkMode} vertical fluid className={'msmi menu-groups three adm'}>{_menuItemsGroup}</Menu>
      } else {
        return <div></div>
      }
    }

  }

  return content()
}

export default SettingsAreaMenuBottom