import React, { createContext, useEffect, useReducer } from 'react';
import { googleScriptsHandlers, googleScriptsInitialState, googleScriptsReducer } from '../reducers/GoogleScriptsReducer';

/** AppContext */
export const GoogleScriptsContext = createContext();

/** Global OtherDatas for the Event */
const GoogleScriptsProvider = (props) => {

  // googleScripts 
  const googleScripts_initState = {}
  const [googleScripts_state, googleScripts_dispatch] = useReducer(googleScriptsReducer, googleScriptsInitialState(googleScripts_initState));
  const googleScripts_handlers = googleScriptsHandlers(googleScripts_dispatch, googleScripts_state)

  // useEffect(() => {
  //   // Trigger the custom function in the HTML file
  //   console.log('window.parent', window.parent)
  //   try {
  //     window.parent.postMessage({ type: 'invokeCustomFunctionInHTML' }, '*');
  //   } catch (error) {
  //     console.log('error', error)
  //   }
  // }, []);

  useEffect(() => {
    try {
      window.top.postMessage({ type: 'invokeCustomFunctionInHTML' }, '*');
    } catch (error) {
      console.log('error', error)
    }
  }, []);

  const providerContext = () => <GoogleScriptsContext.Provider
    value={{ googleScripts_handlers, googleScripts_state }}>
    {props.children}
  </GoogleScriptsContext.Provider>

  return providerContext()
}

export default GoogleScriptsProvider