import React, { useContext } from 'react';
import { Button, Header } from 'semantic-ui-react';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { sportsShowTypes } from '../../cnr/reducers/SportsSeasonReducer';
import MatchScoreButtons from './MatchScoreButtons';
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext';
import { _matchResultTypes, matchDisplayTypes } from '../../cnr/reducers/SportsMatchReducer';

const MatchOptions = () => {

  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { _sportData, sportsShowType, sportsShowOptions } = sportsSeason_state ?? {}
  const { useRtMatchDataView, rtMatchUpdates } = _sportData ?? {}

  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state, seasonMatches_handlers } = seasonMatchesContext ?? {}
  const { matchScheduleType, matchDisplayType } = seasonMatches_state ?? {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ?? {}
  const { match_true, matchData_rt } = sportsMatch_state ?? {}
  const { _resultsStatus } = match_true ?? {}
  const { scoreType } = _resultsStatus ?? {}

  let allowRtScoring = true;

  switch (scoreType) {
    case _matchResultTypes.matchDay:
      allowRtScoring = false
      break;
    default:
    // nothing
  }

  const handleShow_matchOptions = (e) => {
    e && e.stopPropagation()
    seasonMatches_handlers && seasonMatches_handlers.handleSelect_currentMatch(null, null)
  }

  const handleShow_matchScoreTable = (e) => {
    e && e.stopPropagation()
    const opts = { sportsShowType: sportsShowTypes.updateResults, matchKey: match_true._itemKey }
    sportsSeason_handlers.handleShow_sportsElem(opts)
  }

  const handleShow_matchRtScoring = (e) => {
    e && e.stopPropagation()
    seasonMatches_handlers && seasonMatches_handlers.handleSelect_currentMatch(match_true, matchDisplayTypes.realtimeScoring)
    // sportsMatch_handlers && sportsMatch_handlers.handleAmmend_matchDisplayType(matchDisplayTypes.realtimeScoring, match_true)
  }

  const match_options = () => <div className='match-score-btns opts' onClick={(e) => { handleShow_matchOptions(e) }}>
    <Header as='h4'>Result Options</Header>
    <div >
      <Button size='mini' color={'blue'} disabled={!allowRtScoring} onClick={(e) => { handleShow_matchRtScoring(e) }} >{'Realtime Scoring'}</Button>
      <Button size='mini' color={'blue'} onClick={(e) => { handleShow_matchScoreTable(e) }} >{'Update Results'}</Button>
    </div>
  </div>

  switch (matchDisplayType) {
    case matchDisplayTypes.matchOptions:
      return match_options()
    case matchDisplayTypes.realtimeScoring:
      return <MatchScoreButtons matchScheduleType={matchScheduleType} />
    default:
      return match_options()
  }

}

export default MatchOptions