import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { HelpContext } from '../../cnr/contexts/HelpContext';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import { getHelps, getHelpGroup } from './helpSettings';

const HelpEdit = (props) => {

  const { helpProps } = props
  const { hsProps, helpType, handleCloseHelpEdit, helpKey } = helpProps
  const { helpDocField, helpDocSubField, helpProp } = hsProps

  // helpContext
  const helpContext = useContext(HelpContext)
  const { help_state, help_handlers } = helpContext ? helpContext : {}
  const { helpDocs } = help_state ?? {}
  const helpDocument_active = helpDocs['help' + _.startCase(helpType)]

  // eslint-disable-next-line 
  const [helpGroup, setHelpGroup] = useState()
  const [helpEditDescriptionValue, setHelpEditDescriptionValue] = useState()
  const [helpEditLineValue, setHelpEditLineValue] = useState()

  console.log('helpKey', helpKey)
  console.log('helpDocField', helpDocField)
  console.log('helpGroup', helpGroup)

  useEffect(() => {
    const help_group = getHelpGroup(helpDocument_active, hsProps)
    const { description: helpEditDescription, line: helpEditLine } = getHelps(help_group, hsProps)
    setHelpGroup(help_group)
    setHelpEditDescriptionValue(helpEditDescription ? helpEditDescription.replace(/'/g, "`") : '')
    setHelpEditLineValue(helpEditLine)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [helpDocument_active]);

  const handleDescriptionChange = (e, data) => setHelpEditDescriptionValue(data.value)
  const handleEditLineChange = (e, data) => setHelpEditLineValue(data.value)

  const handleHelpUpdate = () => {

    let help_group = helpDocument_active[helpDocField] && helpDocument_active[helpDocField][helpDocSubField] ? helpDocument_active[helpDocField][helpDocSubField] : null

    if (help_group) {
      if (helpProp) {
        if (!help_group.items) { help_group.items = {} }
        if (!help_group.items[helpProp]) { help_group.items[helpProp] = {} }
        help_group.items[helpProp].description = helpEditDescriptionValue
        if (helpEditLineValue) { help_group.items[helpProp].line = helpEditLineValue }
      } else {
        help_group.groupDescription = helpEditDescriptionValue
        if (helpEditLineValue) { help_group.groupLine = helpEditLineValue }
      }
    } else {
      switch (helpKey) {
        case 'ticketing':
          const aaa = 'ticketing'
          help_group = helpDocument_active[helpDocField]
          if (!helpDocument_active.others) { helpDocument_active.others = {} }
          if (!helpDocument_active.others[aaa]) { helpDocument_active.others[aaa] = {} }
          if (helpProp) {
            if (!helpDocument_active.others[aaa].items) { helpDocument_active.others[aaa].items = {} }
            if (!helpDocument_active.others[aaa].items[helpProp]) { helpDocument_active.others[aaa].items[helpProp] = {} }
            helpDocument_active.others[aaa].items[helpProp].description = helpEditDescriptionValue
          } else {
            helpDocument_active.others[aaa].groupDescription = helpEditDescriptionValue
          }
          break;
        default:
          help_group = helpDocument_active[helpDocField]
          if (!help_group[helpDocSubField]) { help_group[helpDocSubField] = {} }
          if (helpProp) {
            if (!help_group[helpDocSubField].items) { help_group[helpDocSubField].items = {} }
            if (!help_group[helpDocSubField].items[helpProp]) { help_group[helpDocSubField].items[helpProp] = {} }
            help_group[helpDocSubField].items[helpProp].description = helpEditDescriptionValue
            if (helpEditLineValue) { help_group[helpDocSubField].items[helpProp].line = helpEditLineValue }
          } else if (helpGroup) {
            help_group[helpDocSubField].groupDescription = helpGroup && helpGroup.groupDescription ? helpGroup.groupDescription : ''
            if (helpEditLineValue) { help_group[helpDocSubField].items.items[helpProp].groupLine = helpEditLineValue }
          }
      }
    }
    help_handlers.handleUpdate_help(helpDocument_active, helpType)
    handleCloseHelpEdit()
  }

  const content = () => <div className={'help-edit-edit'}>
    <div><label>Description</label></div>
    <Form.TextArea value={helpEditDescriptionValue} onChange={handleDescriptionChange}></Form.TextArea>
    <Form.Input label={'Help Line'} placeholder={''} value={helpEditLineValue} onChange={handleEditLineChange} fluid></Form.Input>
  </div>

  return <Wrapper
    content={content()}
    footer={<UiSaveButtons
      save={{ oc: handleHelpUpdate, caption: 'Save Help' }}
      cancel={{ oc: handleCloseHelpEdit }}
    />}
    wrapperType={wrapperTypes.padded}
    cnAlt={'help-edit'}
  ></ Wrapper>

}

export default HelpEdit