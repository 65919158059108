import _ from 'lodash';
import React, { useContext } from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { keysEqual } from '../../../global/validation/validation';
import { uniqueKey } from '../../../global/common/keys';
import NoData from '../../../global/components/alerts/NoData';
import { TicketingContext } from '../cnr/contexts/TicketingContext';
import TicketInfo from './TicketInfo';

/** Returns a group of Tickets */
const Tickets = (props) => {

  const { tickets, ticketType } = props ?? {}

  const ticketingContext = useContext(TicketingContext)
  const { ticketing_state } = ticketingContext ?? {}
  const { appTicketing, showTicket, isWillCall, willCallTicketPath, currentAppUserHasWillCallEmail, currentAppUserHasWillCallPhoneNumber } = ticketing_state ?? {}
  const { showAppUsersWillCallTickets } = appTicketing ?? {}

  const isSingleTicket = tickets && Object.keys(tickets).length === 1 ? true : false

  const handleGoToWillCall = (type) => window.open(willCallTicketPath + type, '_blank')

  const ticketInfo = (ticket, key) => {
    if (ticket.name && ticket.subTickets && ticket.subTickets.length > 0) {
      return <TicketInfo key={uniqueKey('tck', 'ti', key)} ticket={ticket} ticketType={ticketType} isSingleTicket={isSingleTicket} />
    }
  }

  const ticketItems = () => {
    let _ticketItems = [];
    if (_.isArray(tickets)) {
      // eslint-disable-next-line
      _ticketItems = tickets.map(ticket => {
        if (isWillCall && showTicket) {
          if (keysEqual(ticket.subTicketKey, showTicket)) {
            return ticketInfo(ticket, ticket.subTicketKey)
          }
        } else {
          return ticketInfo(ticket, ticket.subTicketKey)
        }
      })
    } else {
      // eslint-disable-next-line
      _ticketItems = Object.keys(tickets).map(key => {
        if (isWillCall && showTicket) {
          if (keysEqual(key, showTicket)) {
            return ticketInfo(tickets[key], key)
          }
        } else {
          return ticketInfo(tickets[key], key)
        }
      })
    }
    return _ticketItems && _ticketItems.length > 0 ? _ticketItems : <NoData altCaption='Sub Tickets' />
  }

  const willCallContent = () => {
    const items = []
    if (currentAppUserHasWillCallEmail) { items.push(<div onClick={() => { handleGoToWillCall('Email') }}>{'Check Email Will Call'}</div>) }
    if (currentAppUserHasWillCallPhoneNumber) { items.push(<div onClick={() => { handleGoToWillCall('Phone') }}>{'Check Phone Number Will Call'}</div>) }
    return <Message positive icon size='tiny'>
      <Icon name='alarm' />
      <Message.Content>
        <Message.Header>You have will call passes</Message.Header>
        <Message.List items={items} />
      </Message.Content>
    </Message>
  }

  const content = () => <div className={'tickets-container'}>
    {showAppUsersWillCallTickets && !isWillCall && (currentAppUserHasWillCallEmail || currentAppUserHasWillCallPhoneNumber) && willCallContent()}
    {ticketItems()}
  </div>

  return tickets ? content() : <div>None</div>

}

export default Tickets