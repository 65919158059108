import Resizer from 'react-image-file-resizer';

export const manifestSizes = [72, 96, 128, 144, 152, 192, 384, 512]
export const fileSizes = [600, 200]
export const thumbnailSizes = [200]

export const manifestSizesT = {
  72: 72,
  96: 96,
  128: 128,
  144: 144,
  152: 152,
  192: 192,
  384: 384,
  512: 512
}

const reduceImage = (file, size) => {
  return new Promise(function (resolve, reject) {
    Resizer.imageFileResizer(
      file,
      size,
      size,
      'PNG',
      100,
      0,
      uri => {
        uri.name = 'n_' + size
        resolve(uri)
      },
      'blob',
    );
  });
}

/** Creates an icon for each of the `manifestSizes` */
export const createManifestIcons = async (file) => {
  const promises = []
  manifestSizes.forEach(size => {
    promises.push(reduceImage(file, size))
  })
  return Promise.all(promises)
}

/** Creates an icon for each of the `thumbnailSizes` */
export const createThumbnailIcons = async (file, autoReduceImage) => {
  const promises = []
  if (autoReduceImage) {
    fileSizes.forEach(size => {
      console.log('size', size)
      promises.push(reduceImage(file, size))
    })
  } else {
    thumbnailSizes.forEach(size => {
      console.log('size', size)
      promises.push(reduceImage(file, size))
    })
  }
  return Promise.all(promises)
}

export const createThumbnail = async (file, size) => {
  const promises = []
  promises.push(reduceImage(file, size))
  return Promise.all(promises)
}