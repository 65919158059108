import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Icon, Image } from 'semantic-ui-react';
import { ParentContext } from './global/cnr/contexts/ParentContext';
import { ServiceWorkerContext } from './global/cnr/contexts/ServiceWorkerContext';
import { propHelpers } from './global/common/tester';
import UiSaveButtons from './global/components/buttons/UiSaveButtons';
import { renderHtml } from './global/components/html/renderHtml';
import { currentHelpers } from './global/redirection/current';
import FullPageWrapper from './global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from './global/wrappers/Wrapper';

const WAIT_INTERVAL = 15000

export const installAppTypes = {
  auto: 'auto',
  help: 'help',
  manual: 'manual',
}

const AddToHomeScreen = (props) => {

  const { installAppType } = props ?? {}

  const parentContext = useContext(ParentContext);
  const { states, handlers, settings } = parentContext ?? {}
  const { appSettings_state, paps_state, page_state, manifest_state, storage_state } = states ?? {}
  const { appUser_handlers, appSettings_handlers } = handlers ?? {}
  const { pathViews, platform } = paps_state ?? {}
  const { events: eventKey } = pathViews ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { homeSettings } = settings ?? {}
  const { appInstallation, topMenu } = aps_global ?? {}
  const { mainFixedCaption } = topMenu ?? {}
  const { addToHomeScreenText, waitInterval, showInstallationPdf, installationPdf } = appInstallation ?? {}
  const { startIcon } = manifest_state ?? {}
  const { fileUrl } = startIcon ?? {}
  const { global: global_home } = homeSettings ?? {}
  const { pwaHelp } = global_home ?? {}
  const { pwaSubTitle, pwaSafariDescription, pwaAndroidDescription } = pwaHelp ?? {}
  const { showHomeScreenPrompt } = appSettings_state ?? {}
  const { isSafari } = platform ?? {}

  let _pwaDescription = isSafari ? pwaSafariDescription : pwaAndroidDescription

  const addToHomeScreenItem = eventKey ? 'appInstallation-' + eventKey : 'appInstallation'
  const _waitInterval = waitInterval ? waitInterval * 1000 : WAIT_INTERVAL

  // serviceWorkerContext
  const serviceWorkerContext = useContext(ServiceWorkerContext)
  const { serviceWorker_state } = serviceWorkerContext ?? {}
  const { deferredPrompt } = serviceWorker_state ?? {}

  // local state
  const [allow, setAllow] = useState()
  const [homeScreenText, setHomeScreenText] = useState()
  const [showHelp, setShowHelp] = useState()
  const [activeSnC, setActiveSnC] = useState()
  const [helpUrl, setHelpUrl] = useState()

  useEffect(() => {
    switch (installAppType) {
      case installAppTypes.auto:
        const _wi = showHomeScreenPrompt ? 1000 : _waitInterval
        const timer = setTimeout(() => setAllow(true), _wi);
        return () => clearTimeout(timer);
      case installAppTypes.help:
      case installAppTypes.manual:
        setAllow(true)
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHomeScreenPrompt]);

  useEffect(() => {
    const { globalFiles } = storage_state ?? {}
    const { pdf } = globalFiles ?? {}
    const _helpPdf = pdf && pdf[installationPdf]
    const { urls } = _helpPdf ?? {}
    const { full } = urls ?? {}
    setHelpUrl(full)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    let _addToHomeScreenText = addToHomeScreenText ? addToHomeScreenText : 'Add `' + mainFixedCaption + '` to homescreen?'
    if (_addToHomeScreenText && propHelpers.isHtml(_addToHomeScreenText)) { _addToHomeScreenText = renderHtml(_addToHomeScreenText) }
    if (!deferredPrompt) {
      _addToHomeScreenText = 'This app is not installable in this environment.'
    }
    setHomeScreenText(_addToHomeScreenText)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleAddToHomeScreen = async () => {
    if (deferredPrompt) {
      try {
        currentHelpers.storageItem_set(addToHomeScreenItem, true)
        appUser_handlers.handleResponse_addToHomePage(addToHomeScreenItem)
        appSettings_handlers.handleShow_homeScreenPrompt(false)
        deferredPrompt.prompt();
        const choiceResult = await deferredPrompt.userChoice;
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
      } catch (error) {
        console.error('Error prompting A2HS:', error);
      }
    }
  };

  const handleClickNo = (e) => {
    e.preventDefault()
    e.stopPropagation()
    switch (installAppType) {
      case installAppTypes.help:
        appSettings_handlers.handleShowHomeScreenHelp()
        break;
      default:
        currentHelpers.storageItem_set(addToHomeScreenItem, true)
        appUser_handlers.handleResponse_addToHomePage(addToHomeScreenItem)
        appSettings_handlers.handleShow_homeScreenPrompt(false)
    }
  };

  const handleShowHelp = () => setShowHelp(!showHelp)
  const handleShowHelpPdf = () => window.open(helpUrl, '_blank')

  const manIcon = () => <Image src={fileUrl} size={'tiny'} />

  const wrapperNoSupport = () => {
    const _pwaTitle = pwaSubTitle ? pwaSubTitle : 'This device does not allow this app to be loaded automatically.'
    _pwaDescription = _pwaDescription ? _pwaDescription : 'For apple devices, click the share button on the Safari menu and scroll to find `Add To Home Screen` – select that and follow the prompts.'
    return <div {...activeSnC}>
      <div>{fileUrl && manIcon()}</div>
      <div>{renderHtml(_pwaTitle)}</div>
      <div>{renderHtml(_pwaDescription)}</div>
    </div>
  }

  const wrapper = () => <Wrapper
    content={wrapperNoSupport()}
    footer={(deferredPrompt || showInstallationPdf) && <UiSaveButtons
      preview={showInstallationPdf ? { oc: handleShowHelpPdf, caption: 'Help', cn: 'btn-help', icon: 'help' } : null}
    // save={installAppType !== installAppTypes.help && supportsPWA ? { oc: handleClickYes, caption: 'Yes', cn: 'btn-homepage', icon: 'check' } : null}
    />}
    wrapperType={wrapperTypes.padded}
  >
  </Wrapper>

  const fpw = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleShowHelp}
    topperCaption={'Add To Home Screen Help'}
  // halfModal={installAppType === installAppTypes.auto}
  />

  let cn = 'app-homepage'
  if (deferredPrompt) { cn += ' nh' }

  const buttons_normal = () => {
    if (deferredPrompt) {
      return <div className='ui two buttons'>
        <Button basic color='green' onClick={(e) => { handleAddToHomeScreen(e) }}>
          OK
        </Button>
        <Button basic color='red' onClick={(e) => { handleClickNo(e) }}>
          Cancel
        </Button>
      </div>
    } else {
      return <div className='ui two buttons'>
        <Button fluid basic color='green' onClick={(e) => { handleClickNo(e) }}>
          OK
        </Button>
      </div>
    }
  }

  const content = () => {
    if (isSafari) {
      return <div className={cn}>
        <Card fluid>
          <Card.Content>
            <Icon style={{ float: 'right' }} float='right' name='plus' />
            <Card.Header>Add to Homescreen</Card.Header>
            <Card.Meta>{pwaSubTitle}</Card.Meta>
            <Card.Description>{renderHtml(_pwaDescription)}</Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button fluid basic color='green' onClick={(e) => { handleClickNo(e) }}>
              OK
            </Button>
          </Card.Content>
        </Card>
      </div>
    } else {
      return <div className={cn}>
        <Card fluid>
          <Card.Content>
            <Icon style={{ float: 'right' }} float='right' name='plus' />
            <Card.Header>Add to Homescreen</Card.Header>
            <Card.Description>{homeScreenText}</Card.Description>
          </Card.Content>
          <Card.Content extra>
            {buttons_normal()}
          </Card.Content>
        </Card>
      </div>
    }
  }

  if (showHelp) {
    return fpw()
  } else {
    return allow ? content() : <div></div>
  }
};

export default AddToHomeScreen;
