import { where } from "firebase/firestore";
import _ from 'lodash';
import { appUserValidation_update, firebaseSignIn } from "../../auth/signInActions/signInMethods";
import { createRefPath } from "../../firestoreData/appData/appRefPaths";
import { fs_db } from "../../firestoreData/appData/fsAppData";
import { fs_dbu } from "../../firestoreData/appData/fsAppDataUpdate";
import { grts, responseReducers } from "./reducerHelpers/dispatchProps";

export const rts = {
  handleDelete_validation: 'handleDelete_validation',
  handleGet_validationByEmail: 'handleGet_validationByEmail',
  handleGet_validationByUrl: 'handleGet_validationByUrl',
  handleResponse_signIn: 'handleResponse_signIn',
  handleResponse_signInLink: 'handleResponse_signInLink',
  handleResponse_validationByEmail: 'handleResponse_validationByEmail',
  handleResponse_validationByUrl: 'handleResponse_validationByUrl',
  handleSend_signInLink: 'handleSend_signInLink',
  handleSend_signInLinks: 'handleSend_signInLinks',
  handleSet_isSignInLink: 'handleSet_isSignInLink',
  handleSet_isValidated: 'handleSet_isValidated',
  handleSignIn_withValidatedEmailLink: 'handleSignIn_withValidatedEmailLink',
  handleUpdate_appUserValidation: 'handleUpdate_appUserValidation',
  ...grts
}

export const _emailSignInStorageItem = 'emailForSignUp'
export const _linkSignInStorageItem = 'linkForSignUp'

export const _collectionName_appUserValidation = '_appUserValidation'
export const _collectionName_passcodeValidation = '_appUserPasscodeValidation'


export const validationInitialState = (initState) => {
  return { ...initState, authIndex: 0 }
};

export const validationReducer = (state, action) => {

  const { sharePath, appSignIn, signInEmail, validatedAppUser } = state

  const { type, dispatch, result } = action

  const { handleResponse_validationByEmail, handleResponse_validationByUrl, handleResponse_signInLink, handleResponse_signIn } = validationHandlers(dispatch)

  switch (type) {

    case rts.handleSet_isSignInLink:
      return { ...state, isSignInLink: action.isSignInLink }

    case rts.handleSet_isValidated:
      return { ...state, validationStatus: action.isValidated ? 'success' : 'failed' }

    case rts.handleSend_signInLink:
      firebaseSignIn.sendSignInLink(action.creds, sharePath, appSignIn, signInEmail, handleResponse_signInLink, action.callback)
      return { ...state }

    case rts.handleResponse_signInLink:
      return { ...state, sendSignInLinkResponse: action.result }

    case rts.handleSend_signInLinks:
      firebaseSignIn.sendSignInLinks(action.selectedItems, sharePath, appSignIn, signInEmail, action.callback)
      return { ...state }

    case rts.handleUpdate_appUserValidation:
      appUserValidation_update(action.email, action.status)
      validatedAppUser.status = action.status
      return { ...state, validatedAppUser }

    case rts.handleGet_validationByEmail:
      appUserValidation_getEmail(action.email, handleResponse_validationByEmail)
      return { ...state }

    case rts.handleGet_validationByUrl:
      appUserValidation_getByUrl(action.url, action.appUserEmail, handleResponse_validationByUrl)
      return { ...state }

    case rts.handleResponse_validationByEmail:
      return { ...state, validatedAppUser: result }

    case rts.handleResponse_validationByUrl:
      if (result) {
        const isValidated = result && (result._itemKey === action.appUserEmail)
        return { ...state, validatedAppUser: result, validationStatus: isValidated ? 'success' : 'failed' }
      } else {
        return { ...state, validatedAppUser: result, validationStatus: 'failed' }
      }

    case rts.handleSignIn_withValidatedEmailLink:
      if (validatedAppUser) {
        firebaseSignIn.withEmailLink(validatedAppUser, handleResponse_signIn)
      }
      return { ...state }

    case rts.handleDelete_validation:
      appUserValidation_deleteEmail(validatedAppUser)
      return { ...state }

    case rts.handleResponse_signIn:
      // const adminNotifications = {
      //   alertAdminOnSignIn: true,
      //   alertAdminOnAuthError: true,
      //   alertAdminOnUpdates: true,
      // }
      // console.log('sendPushToAdmin')
      // sendPushToAdmin(validatedAppUser, { email: validatedAppUser._itemKey }, adminNotifications, null, action.validationStatus)
      return { ...state, validationStatus: action.validationStatus }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { questionProps: null })

    default:
      return { ...state }
  }
}

export const validationHandlers = (dispatch) => {
  return {
    handleDelete_validation: () => { dispatch({ type: rts.handleDelete_validation, dispatch }) },
    handleGet_validationByEmail: (email) => { dispatch({ type: rts.handleGet_validationByEmail, dispatch, email }) },
    handleGet_validationByUrl: (url, appUserEmail) => { dispatch({ type: rts.handleGet_validationByUrl, dispatch, url, appUserEmail }) },
    handleResponse_signIn: (validationStatus) => { dispatch({ type: rts.handleResponse_signIn, dispatch, validationStatus }) },
    handleResponse_signInLink: (result, callback) => { dispatch({ type: rts.handleResponse_signInLink, dispatch, result, callback }) },
    handleResponse_validationByEmail: (result) => { dispatch({ type: rts.handleResponse_validationByEmail, dispatch, result }) },
    handleResponse_validationByUrl: (result, appUserEmail) => { dispatch({ type: rts.handleResponse_validationByUrl, dispatch, result, appUserEmail }) },
    handleSend_signInLink: (creds, callback) => { dispatch({ type: rts.handleSend_signInLink, dispatch, creds, callback }) },
    handleSend_signInLinks: (selectedItems, callback) => { dispatch({ type: rts.handleSend_signInLinks, dispatch, selectedItems, callback }) },
    handleSet_isSignInLink: (isSignInLink) => { dispatch({ type: rts.handleSet_isSignInLink, dispatch, isSignInLink }) },
    handleSet_isValidated: (isValidated) => { dispatch({ type: rts.handleSet_isValidated, dispatch, isValidated }) },
    handleSignIn_withValidatedEmailLink: () => { dispatch({ type: rts.handleSignIn_withValidatedEmailLink, dispatch }) },
    handleUpdate_appUserValidation: (email, status, response) => { dispatch({ type: rts.handleUpdate_appUserValidation, dispatch, email, status, response }) },
  }
}

const appUserValidation_getByUrl = async (url, appUserEmail, callback) => {
  var urlParams = new URLSearchParams(url);
  var oobCode = _.get(Object.fromEntries(urlParams), 'oobCode');
  const _refPath = createRefPath([_collectionName_appUserValidation])
  const wheres = [where('oobCode', '==', oobCode)]
  const result = await fs_db.get_data({ refPath: _refPath, wheres, opts: { returnFirstObject: true } })
  callback(result, appUserEmail)
}

const appUserValidation_getEmail = (email, callback) => {
  const _refPath = createRefPath([_collectionName_appUserValidation, email])
  console.log('refPath', _refPath)
  fs_db.get_data({ refPath: _refPath, callback, opts: { returnFirstObject: true, listen: true } })
}

const appUserValidation_deleteEmail = (validatedAppUser, callback) => {
  const { _itemKey: email } = validatedAppUser ?? {}
  if (email) {
    const _refPath = createRefPath([_collectionName_appUserValidation, email])
    fs_dbu.delete_doc(_refPath)
  }
}



