import { getCourtSpace } from "./svgCategoryProps"
import { svgElementProps } from "./svgElements"

export const courtSectors = (maxCourtWidth, court) => {

  const settings = {
    maxCourtHeight: null,
    courtSpace: null,
    maxCourtWidth: null,
    dimension: {
      width: 90,
      height: 30
    }
  }

  settings.maxCourtHeight = court.maxHeight
  settings.courtSpace = getCourtSpace(court.maxHeight, maxCourtWidth)
  settings.maxCourtWidth = maxCourtWidth

  const sectors = {
    maxCourtHeight: settings.maxCourtHeight,
    courtSpace: settings.courtSpace,
    maxCourtWidth: settings.maxCourtWidth,

    addZoneStats: function (elems, rotationStats, action, color, offsetY) {
      const sector = this.zones.top
      Object.keys(sector).forEach(key => {
        const sectorItem = sectors.zones.top[key]
        const center = sectorItem.getCenterCoords()
        const actionCalc = rotationStats['zone' + key].calcs[action]
        const prefix = (action === 'pass') ? 'P: ' : 'A: '
        let value = '---'
        if (actionCalc.perc) {
          value = prefix + (actionCalc.perc + ' (' + actionCalc.ta + ')')
        }
        this.addZone(elems, center, sectorItem, null, value, color, offsetY)
      })
    },

    clearSectorMarks: function (sector) {
      // CLEAR existing mark's lines and stats for the sectorItems
      Object.keys(sector).forEach((key, i) => {
        const sectorItem = sector[key]
        if (sectorItem.mark && sectorItem.mark.lines) {
          sectorItem.mark.lines = []
        }
        if (sectorItem.mark && sectorItem.mark.stats) {
          sectorItem.mark.stats = null
        }
      })
    },

    addSelectedPlays: function (selectedPlays, statDisplay, elems, statCategory, noStats) {

      // get the correct sector based on the action (attack, serve, pass) 
      const sector = this[statDisplay.action]

      // Object.keys(sector).forEach(key => {
      //   sector[key].drawRect(elems)
      // })

      if (sector) {
        const groupEnd = (statDisplay.action === 'serve' ? false : false)
        const colors = statCategory.colors

        sectors.clearSectorMarks(sector)

        // create a line for each of the selectedPlays
        Object.keys(selectedPlays).forEach((key, i) => {

          const playerStat = selectedPlays[key]
          const movement = playerStat.details.movement
          const rw = (this.maxCourtWidth / movement.cw)
          const rh = (settings.maxCourtHeight / movement.ch)

          const line = {
            sx: movement.startX * rw,
            ex: movement.endX * rw,
            sy: movement.startY * rh,
            ey: movement.endY * rh,
            result: playerStat.result,
            color: colors[playerStat.result],
            position: playerStat.position,
            action: playerStat.action,
            withArrow: true
            // RESULT // LOOK
          }

          // this will only add a mark if the ACTION is matching
          this.addMarkLine(playerStat, line, statDisplay.action, statCategory)

        })

        // now we have all the marks, get the zoneStats from statCategory
        if (!noStats) {
          Object.keys(sector).forEach((key, i) => {
            const sectorItem = sector[key]
            if (sectorItem.mark.stats && sectorItem.mark.stats[statDisplay.action]) {
              const st = sectorItem.mark.stats[statDisplay.action]
              const statz = statCategory.resultCalcs.stat(sectorItem.mark.stats)
              const z = {
                percentage: statz[statDisplay.action].perc,
                attempts: statz[statDisplay.action].ta,
                color: statz[statDisplay.action].statColor
              }
              st.calcs = z
            }
          })
        }

        // create the element props (lines and zones) 
        Object.keys(sector).forEach((key, i) => {
          const sectorItem = sector[key]
          const center = sectorItem.getCenterCoords()
          // adjust the y value to better display where the action started from
          if (statDisplay.action === 'pass') { center.y = (settings.dimension.height / 2) + 5 }
          if (statDisplay.action === 'serve') { center.y = settings.maxCourtHeight - ((settings.dimension.height / 2) + 5) }
          if (sectorItem.mark) {
            if (sectorItem.mark.lines) {
              addLines(elems, sectorItem.mark.lines, center, groupEnd)
            }
            if (!noStats && sectorItem.mark.stats && sectorItem.mark.stats[statDisplay.action]) {
              const stat = sectorItem.mark.stats[statDisplay.action]
              const color = (stat.calcs && stat.calcs.color) ? stat.calcs.color : 'grey'
              this.addZone(elems, center, sectorItem, statDisplay, null, color, 0)
            }
          }
        })
      }

    },

    addZone: function (elems, center, sectorItem, statDisplay, value, color, offsetY) {

      const coords = {
        x: center.x - (settings.dimension.width / 2),
        y: center.y - (settings.dimension.height / 2) + offsetY
      }

      elems.rects.push(svgElementProps.rect(settings.dimension.width, settings.dimension.height, coords.x, coords.y + offsetY, color, 4, 4, false))

      if (statDisplay) {
        const st = sectorItem.mark.stats[statDisplay.action]
        const stat = {
          perc: st.calcs.percentage,
          ta: st.calcs.attempts
        }
        elems.rects.push(svgElementProps.textStat(coords.x + (settings.dimension.width / 2), coords.y + (settings.dimension.height / 2) + offsetY, stat, 'white', 14))
      }

      if (value) {
        elems.texts.push(svgElementProps.text(coords.x + (settings.dimension.width / 2), coords.y + (settings.dimension.height / 2) + offsetY, value, 'white', 14))
      }
    },

    addMarkLine: function (playerStat, line, playerAction, statCategory) {

      // sector base on action 
      const actionSectors = this[playerStat.action]

      if (actionSectors) {
        const sect = this.findSector(actionSectors, line.sx, line.sy)
        if (sect) {
          sect.mark.lines.push(line)
          if (!sect.mark.stats) { sect.mark.stats = statCategory.resultDefault(statCategory.resultItems).action(playerAction) }
          if (sect.mark.stats[playerAction]) { sect.mark.stats[playerAction][playerStat.result]++ }
        }
      }
    },

    findSector: function (items, x, y) {
      let zz = null
      Object.keys(items).forEach(item => {
        const z = items[item]
        if (x >= z.tl.x && x < z.tr.x && y >= z.tl.y && y <= z.bl.y) {
          zz = z
        }
      })
      return zz
    },

    zones: {
      top: {
        1: setBounderies(settings, 0, 0),
        6: setBounderies(settings, 1, 0),
        5: setBounderies(settings, 2, 0),
        2: setBounderies(settings, 0, 1),
        3: setBounderies(settings, 1, 1),
        4: setBounderies(settings, 2, 1)
      },
      bottom: {
        4: setBounderies(settings, 0, 2),
        3: setBounderies(settings, 1, 2),
        2: setBounderies(settings, 2, 2),
        5: setBounderies(settings, 0, 3),
        6: setBounderies(settings, 1, 3),
        1: setBounderies(settings, 2, 3),
      },

      find: function (x, y, touchD) {

        let asn = null

        Object.keys(this.top).forEach(zone => {
          const z = this.top[zone]
          if (x >= z.tl.x && x < z.tr.x && y >= z.tl.y && y <= z.bl.y) {
            asn = setAsn('top', z, zone)
          }
        })
        Object.keys(this.bottom).forEach(zone => {
          const z = this.bottom[zone]
          if (x >= z.tl.x && x < z.tr.x && y >= z.tl.y && y <= z.bl.y) {
            asn = setAsn('bottom', z, zone)
          }
        })

        return asn
      }
    },

    attack: {
      left: setBounderies(settings, 0, 2),
      middle: setBounderies(settings, 1, 2),
      right: setBounderies(settings, 2, 2),
      A: setBounderies(settings, 0, 3),
      pipe: setBounderies(settings, 1, 3),
      D: setBounderies(settings, 2, 3),
    },

    oppAttack: {
      D: setBounderies(settings, 0, 0),
      pipe: setBounderies(settings, 1, 0),
      A: setBounderies(settings, 2, 0),
      right: setBounderies(settings, 0, 1),
      middle: setBounderies(settings, 1, 1),
      left: setBounderies(settings, 2, 1),
    },

    // start bottom
    serve: {
      left: setBounderies(settings, 0, 3),
      middle: setBounderies(settings, 1, 3),
      right: setBounderies(settings, 2, 3),
    },

    // start top
    pass: {
      left: setBounderies(settings, 0, 0),
      middle: setBounderies(settings, 1, 0),
      right: setBounderies(settings, 2, 0),
    },
  }

  return sectors

}

const setBounderies = (settings, startX, startY, cs) => {

  const setBounds = (xs, xe, ys, ye) => {

    const setXYCoords = (x, y) => {
      const xx = settings.courtSpace.thirdW * x
      const yy = settings.courtSpace.quarterH * y
      return setXY(xx, yy)
    }

    const setXY = (x, y) => {
      return {
        x: x,
        y: y
      }
    }

    return {
      tl: setXYCoords(xs, ys),
      tr: setXYCoords(xe, ys),
      bl: setXYCoords(xs, ye),
      br: setXYCoords(xe, ye),
      getCenterCoords: function () {
        const diffX = (this.tr.x - this.tl.x) / 2
        const diffY = (this.bl.y - this.tl.y) / 2
        return setXY((this.tl.x + diffX), (this.tl.y + diffY))
      },
      drawRect: function (elems) {
        const w = this.tr.x - this.tl.x
        const h = this.bl.y - this.tl.y
        elems.rects.push(svgElementProps.rect(w, h, this.tl.x, this.tl.y, 'none', 4, 4, false))
      },
      mark: mark(),
    }
  }

  return setBounds(startX, startX + 1, startY, startY + 1)
}

const addLines = (elems, lines, coords, groupEnd) => {
  Object.keys(lines).forEach((key, i) => {
    const line = lines[key]
    if (groupEnd) {
      elems.lines.push(
        svgElementProps.line(
          coords.x,
          line.sx,
          coords.y,
          line.sy,
          3,
          (line.color) ? line.color : 'grey',
          true
        )
      )
    } else {
      elems.lines.push(
        svgElementProps.line(
          coords.x,
          line.ex,
          coords.y,
          line.ey,
          3,
          (line.color) ? line.color : 'grey',
          true
        )
      )
    }
  })
}

const setAsn = (area, sector, name) => {
  return {
    area: area,
    sector: sector,
    name: name
  }
}


const mark = () => {
  return {
    lines: [],
    stats: null
  }
}
