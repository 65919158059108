import React from 'react';
import { motion } from 'framer-motion'

const variantsContainer = {
  initial: {
    transition: {
      staggerChildren: 0.2
    }
  },
  animate: {
    transition: {
      staggerChildren: 0.2
    }
  }
};

const variantsDot = {
  initial: {
    y: "0%"
  },
  animate: {
    y: "100%"
  }
};

const DotTransition = {
  duration: 0.5,
  yoyo: Infinity,
  ease: "easeInOut"
};

const StartDots = (props) => {

  const { startType } = props ?? {}

  let dotCount = 3
  let bc;

  switch (startType) {
    default:
      bc = '#484848'
  }

  const _dots = []

  for (var i = 0; i < dotCount; i++) {
    _dots.push(<motion.span
      key={i}
      className={'start-dot'}
      style={{ backgroundColor: bc }}
      variants={variantsDot}
      transition={DotTransition}
    />)
  }

  return (
    <div
      className={'start-dots-container'}
    >
      <motion.div
        className={'start-dot-container'}
        variants={variantsContainer}
        initial="initial"
        animate="animate"
      >
        {_dots}
      </motion.div>
    </div>
  );
}

export default StartDots