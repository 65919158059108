import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { Icon, Input, Label } from 'semantic-ui-react';
import { FilterContext } from '../cnr/contexts/FilterContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import { UIDictaphoneIcon } from './modification/dataModifications/UIDictaphone';

const WAIT_INTERVAL = 1500;

const UiSearchFilter = (props) => {

  const { handleSearchChange } = props

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { viewItem } = item_state ?? {}
  const { search } = viewItem ?? {}
  const { showVoiceSearch } = search ?? {}

  // filterContext
  const filterContext = useContext(FilterContext);
  const { uiFilter_handlers } = filterContext ?? {}

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line
  const [result, setResults] = useState([])
  // eslint-disable-next-line
  const [searchValue, setSearchValue] = useState('')

  const handleCancel = () => {
    resetComponent(false)
    setSearchValue(' ')
    if (uiFilter_handlers) {
      uiFilter_handlers.handleSearchChange(null)
    } else if (handleSearchChange) {
      handleSearchChange(' ')
    }
  }

  const handleChange = (e, { value }) => {
    setIsLoading(true)
    setSearchValue(value)
    setTimeout(() => {
      if (value.length < 1) return resetComponent()
      if (handleSearchChange) {
        handleSearchChange(value)
      } else {
        if (uiFilter_handlers) {
          uiFilter_handlers.handleSearchChange(value)
        } else if (handleSearchChange) {
          handleSearchChange(value)
        }
      }
    }, WAIT_INTERVAL)
  }

  const handleResult = (value) => {
    setIsLoading(true)
    setSearchValue(value)
    setTimeout(() => {
      if (value.length < 1) return resetComponent()
      if (handleSearchChange) {
        handleSearchChange(value)
      } else {
        if (uiFilter_handlers) {
          uiFilter_handlers.handleSearchChange(value)
        } else if (handleSearchChange) {
          handleSearchChange(value)
        }
      }
    }, WAIT_INTERVAL)
  }

  const resetComponent = (init) => {
    setIsLoading(false)
    setResults([])
    setSearchValue(' ')
    if (!init) {
      if (handleSearchChange) {
        handleSearchChange(' ')
      } else {
        if (uiFilter_handlers) {
          uiFilter_handlers.handleSearchChange(null)
        } else if (handleSearchChange) {
          handleSearchChange(' ')
        }
      }
    }
  }

  const searchLabel = () => {
    if (searchValue.length > 1) {
      return <Label onClick={handleCancel}><Icon name={'delete'} color={'red'} /></Label>
    } else {
      if (showVoiceSearch) {
        return <UIDictaphoneIcon handleResult={handleResult} oneTimeOnly={true} />
      } else {
        return <Label onClick={handleCancel}><Icon name={'delete'} color={'red'} /></Label>
      }
    }
  }

  return <Input
    label={searchLabel()}
    labelPosition='left'
    icon={'search'}
    value={searchValue}
    onChange={_.debounce(handleChange, 500, { leading: true })}
    fluid
  />
}

export default UiSearchFilter