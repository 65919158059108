import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Divider, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';
import { sortObject } from '../../../common/sorting';
import { useAllSettingsFunctions } from '../../../cnr/contexts/BaseSettingsContext';
import { FormItemProp } from './FormItemProp';
import { FormContext } from '../../../cnr/contexts/FormContext';

const FormElements = () => {

  const formContext = useContext(FormContext)
  const { form_state } = formContext ?? {}
  const { componentContexts, data_localForm, viewProps_normal, viewProps_sectioned, ignorePropSections, noSort } = form_state ?? {}
  const { frameworkContext, viewSettingsComponentContext } = componentContexts ?? {}
  const { framework_state } = frameworkContext ?? {}
  const { fullMode } = framework_state ?? {}
  const settingsSort = viewSettingsComponentContext || noSort || fullMode ? true : true

  const [propElems, setPropElems] = useState()

  const form_elements = (_propElements, _viewProps) => {
    if (_viewProps) {
      if (!_.isArray(_viewProps)) {
        if (settingsSort) {
          const sortedPropsS = useAllSettingsFunctions ? sortObject(_viewProps, 'position') : sortObject(_viewProps, '_position')
          Object.keys(sortedPropsS).forEach((key, index) => {
            const sp = sortedPropsS[key]
            _propElements.push(<FormItemProp itemProp={sp} index={index} />)
          })
        } else {
          const sortedPropsP = _.orderBy(_viewProps, ['position'])
          sortedPropsP.forEach((itemProp, index) => {
            _propElements.push(<FormItemProp itemProp={itemProp} index={index} />)
          })
        }
      } else {
        _viewProps.forEach((itemProp, index) => {
          _propElements.push(<FormItemProp itemProp={itemProp} index={index} />)
        })
        const newJson = {}
        if (data_localForm) {
          _viewProps.forEach(vpKey => {
            newJson[vpKey] = data_localForm[vpKey]
          })
        }
      }
    } else {
      _propElements.push(<Segment basic key={uniqueKey('afip')}>{'No Item Props'}</Segment>)
    }
  }

  const getFormElems = () => {
    let propElements = []

    if (viewProps_sectioned && !ignorePropSections) {
      Object.keys(viewProps_sectioned).forEach(sectionKey => {
        const _section = viewProps_sectioned[sectionKey]
        const { props: props_section } = _section
        switch (sectionKey) {
          case 'hidden':
            // nothing
            break
          default:
            propElements.push(<Divider key={uniqueKey('fi', 'd', sectionKey)} horizontal>{_.startCase(sectionKey)}</Divider>)
            form_elements(propElements, props_section)
        }
      })
    } else {
      form_elements(propElements, viewProps_normal)
    }

    setPropElems(propElements)
  }

  useEffect(() => {
    getFormElems()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [viewProps_normal, viewProps_sectioned]);


  const content = () => {
    return propElems
  }

  return content()

}

export default FormElements