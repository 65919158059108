import { fsfn_getFeed, fsfn_getFeeds } from "../../functions/fbFetchFunctions";

const _useFunctions = true

const rts = {
  handleGet_rssFeeds: 'handleGet_rssFeeds',
  handleSet_rssFeeds: 'handleSet_rssFeeds',
  handleSet_rssFeedsNone: 'handleSet_rssFeedsNone',
}

export const rssReducer = (state, action) => {

  const { type, dispatch, rssFeeds_home, rssFeeds, rssFeed, titleSearch, contentSearch } = action

  const { handleSet_rssFeeds, handleSet_rssFeedsNone } = rssHandlers(dispatch)

  switch (type) {

    case rts.handleGet_rssFeeds:
      getFeeds(rssFeeds_home, rssFeeds, rssFeed, titleSearch, contentSearch, handleSet_rssFeeds, handleSet_rssFeedsNone)
      return { ...state }

    case rts.handleSet_rssFeeds:
      return { ...state, feeds: action.feeds }

    case rts.handleSet_rssFeedsNone:
      return { ...state, feedMessage: action.message }

    default:
      return { ...state }
  }
}

export const rssInitialState = (initState) => {
  return { ...initState }
};

export const rssHandlers = (dispatch) => {
  return {
    handleGet_rssFeeds: (rssFeeds_home, rssFeeds, rssFeed, titleSearch, contentSearch) => { dispatch({ type: rts.handleGet_rssFeeds, dispatch, rssFeeds_home, rssFeeds, rssFeed, titleSearch, contentSearch }) },
    handleSet_rssFeeds: (feeds) => { dispatch({ type: rts.handleSet_rssFeeds, dispatch, feeds }) },
    handleSet_rssFeedsNone: (message) => { dispatch({ type: rts.handleSet_rssFeedsNone, dispatch, message }) },
  }
}

const getFeeds = (rssFeeds_home, rssFeeds, rssFeed, titleSearch, contentSearch, callback, callbackNone) => {
  if (rssFeeds_home && rssFeeds && !rssFeed) {
    if (_useFunctions) {
      fsfn_getFeeds(rssFeeds_home, rssFeeds, titleSearch, contentSearch).then(srt => {
        if (srt && srt.length > 0) {
          callback(srt)
        } else {
          callbackNone('No current news items')
        }
      })
    } else {
      getFeeds(rssFeeds_home, rssFeeds, callback)
    }

  } else if (rssFeed) {
    fsfn_getFeed(rssFeed, titleSearch, contentSearch).then(response_data => {
      const { result } = response_data ?? {}
      const { items } = result ?? {}
      if (items && items.length > 0) {
        callback(items)
      } else {
        callbackNone('No current news items')
      }
    })
  }
}