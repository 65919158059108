import React, { useContext } from 'react'
import { Card, Icon, Label } from 'semantic-ui-react'
import { CredentialingContext } from '../../../cnr/contexts/CredentialingContext'
import { uniqueKey } from '../../../common/keys'

const UiCredential = (props) => {

  const { itemData, _cardKeyy } = props
  const { _itemKey } = itemData ?? {}

  // credentialingContext
  const credentialingContext = useContext(CredentialingContext)
  const { credentialing_state } = credentialingContext ?? {}
  const { checkIns } = credentialing_state ?? {}

  const _checkIn = checkIns && _itemKey && checkIns[_itemKey] ? checkIns[_itemKey] : null
  const checkedIn = _checkIn && _checkIn.checkedIn ? true : false

  const indicatorItem = () => <div key={uniqueKey('cp', 'i', _cardKeyy)} >
    <Label>
      <Icon name='user' color={checkedIn ? 'green' : 'red'} /> Checked In
    </Label>
  </div>

  return _checkIn ? <div className='card-indics' key={uniqueKey('cp', 'ci', _cardKeyy)}>
    <Card.Content
      key={uniqueKey('cp', 'crd', _cardKeyy, 4)}
      extra
    >
      {indicatorItem()}
    </Card.Content>
  </div> : null

}

export default UiCredential