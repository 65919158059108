
import _ from 'lodash';

export const compareData = (viewItem, existingData, newData) => {

  const existingItems = { ...existingData }

  const addedItems = []
  const updatedChangedItems = {}
  const updatedItems = {}
  const newProps = {}

  const { props, dataConnection } = viewItem
  const { uniqueProps } = dataConnection ?? {}

  if (props) {

    const existingProps = Object.keys(props)

    if (newData) {
      Object.keys(newData).forEach(newItemKey => {
        const newItem = newData[newItemKey]
        const fd = {}
        if (uniqueProps) {
          uniqueProps.forEach(up => {
            fd[up] = newItem[up]
          })
        } else {
          // fd.name = newItem['name']
        }

        const existingItem = _.find(existingItems, fd)

        if (existingItem) {

          const updateItem = {}
          const updateChangedItem = {}

          Object.keys(newItem).forEach(propKey => {
            if (existingProps.includes(propKey)) {
              if (existingItem[propKey] && (existingItem[propKey] !== newItem[propKey])) {
                updateChangedItem[propKey] = existingItem[propKey] + 'XPROP/' + newItem[propKey]
                updateItem[propKey] = newItem[propKey]
              } else {
                updateChangedItem[propKey] = newItem[propKey]``
                updateItem[propKey] = newItem[propKey]
              }
            } else {
              updateChangedItem[propKey] = newItem[propKey]
              updateItem[propKey] = newItem[propKey]
              newProps[propKey] = {}
            }
          })
          updatedChangedItems[existingItem.id] = updateChangedItem
          updatedItems[existingItem.id] = updateItem
        } else {
          // add the item, no modification
          addedItems.push(newItem)
        }
      })
    }
    const combinedItems = { ...updatedChangedItems, ...addedItems }
    return { addedItems, updatedItems, updatedChangedItems, newProps, combinedItems }

  }
}

export const getNonExistingItems = (existingItems, newItems, compareProps) => {

  const foundItems = {}
  const nonExistingItems = {}

  let existingCount = 0

  if (newItems) {
    // loop the newItems
    Object.keys(newItems).forEach(k => {
      const newItem = newItems[k]
      const { isUnique, item } = checkExisting(newItem, existingItems, compareProps);
      if (isUnique) {
        nonExistingItems[k] = newItem
      } else {
        if (item && newItem) {
          if (newItem.playerPosition) { item.playerPosition = newItem.playerPosition }
          if (newItem.uniformNumber) { item.uniformNumber = newItem.uniformNumber }
        }
        foundItems[k] = item ? item : newItem
        existingCount++
      }
    })
  }
  return { nonExistingItems, foundItems, existingCount }

}

const checkExisting = (newItem, existingItems, compareProps) => {
  let newItemFound = false
  let item;
  if (existingItems) {
    // loop the existingItems
    Object.keys(existingItems).forEach(ak => {
      const existingItem = existingItems[ak]
      const uniqueItems = {}
      let trueCount = 0
      // compare the props from the existingItem
      compareProps.forEach(cp => {
        // add the prop to the uniqueItems object
        uniqueItems[cp] = newItem[cp] === existingItem[cp]
      })

      Object.keys(uniqueItems).forEach(ui => {
        if (uniqueItems[ui] === true) {
          trueCount++
        }
      })

      if (trueCount === compareProps.length) {
        newItemFound = true
      }
    })
  }
  return { isUnique: newItemFound ? false : true, item: item }
}