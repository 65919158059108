import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import { AppNotificationsContext } from '../cnr/contexts/AppNotificationsContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import { updateSimpleArray } from '../common/sorting';
import UiSaveButtons from '../components/buttons/UiSaveButtons';
import FormForm from '../components/forms/FormForm';
import { getFormPropz } from '../components/forms/props/formPropz';
import MenuSelect from '../components/menus/MenuSelect';
import StepWizard from '../components/wizard/StepWizard';
import { appFormTypes } from '../enums/appFormTypes';
import { gEnums } from '../enums/globalEnums';
import UiDataFilters, { fullFilterTypes } from '../pageItem/UiDataFilters';
import { DataActionsContext } from '../pageItem/modification/UiDataManagement';
import UiStaticDropdowns from '../pageItem/modification/UiStaticDropdowns';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import TargetConfirmation from './TargetConfirmation';
import TopicGroupSelect from './TopicGroupSelect';

const stepTypes = {
  confirmation: 'confirmation',
  confirmationTopic: 'confirmationTopic',
  topics: 'topics',
  filters: 'filters',
  links: 'links',
  name: 'name',
  notification: 'notification',
  notificationTarget: 'notificationTarget',
}

const CreateTopicWizard = (props) => {

  const { topicOriginType, activeTopic } = props ?? {}

  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { page_state, paps_state, eventInfo_state, appForms_state } = states
  const { navigate } = handlers
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems, aps_appUserSettings } = pageSettings ?? {}

  // papsContext 
  const { view, viewKey } = paps_state ?? {}

  // pageContext 
  const { appNotifications } = aps_global ?? {}
  const { notificationTargets } = appNotifications ?? {}
  const { appUserCollection } = aps_appUserSettings ?? {}

  // eventInfoContext  
  const { staticViews, appProfileData } = eventInfo_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state, item_handlers } = uiItemContext ?? {}
  const { viewItem } = item_state ?? {}
  const { key: viewItemKey } = viewItem ?? {}

  // dataActionsContext
  const dataActionsContext = useContext(DataActionsContext)
  const { dataActions_handlers } = dataActionsContext ?? {}

  const _viewItem = viewItemKey && aps_viewItems && aps_viewItems[viewItemKey] ? aps_viewItems[viewItemKey] : null

  // appFormsContext 
  const { appForms } = appForms_state ?? {}

  // appNotificationsContext
  const appNotificationsContext = useContext(AppNotificationsContext);
  const { appNotifications_handlers } = appNotificationsContext

  let spi = activeTopic ? activeTopic.pageItem : null
  if (!spi) { spi = viewItem ? viewItem.key : null }
  if (!spi && viewItemKey) { spi = viewItemKey }

  // local state  
  const [topicData, setTopicData] = useState({ targetPage: 'attendees', filter: null, filters: null, selectedAppUsers: null, selectedTargets: null, topicOrigin: null, notificationType: null })
  const [selectedPageItem, setSelectedPageItem] = useState(spi ? { key: spi, pageItem: _viewItem } : null)
  const [selectedFilter, setSelectedFilter] = useState(activeTopic ? activeTopic.filter : null)
  const [selectedFilters, setSelectedFilters] = useState(activeTopic ? activeTopic.filters : null)

  const [topicName, setTopicName] = useState()
  const [topicProps, setTopicProps] = useState()
  const [selectedTargets] = useState()
  const [selectedAppUsers, setSelectedAppUsers] = useState([])
  const [topicGroup, setTopicGroup] = useState()
  const [topicFormData, setTopicFormData] = useState(activeTopic ? { name: activeTopic.name } : {})

  let steps;

  switch (topicOriginType) {
    case gEnums.topicOriginTypes.manager:
      steps = [stepTypes.name, stepTypes.links, stepTypes.confirmationTopic]
      break;
    case gEnums.topicOriginTypes.page:
      steps = [stepTypes.name, stepTypes.links, stepTypes.confirmation]
      break;
    default:
      steps = [stepTypes.topics]
  }

  const [step, setStep] = useState({ index: 0, name: steps[0] })
  const stepCount = steps.length

  if (topicData) {
    if (selectedFilter) { topicData.filter = selectedFilter }
    if (selectedFilters) { topicData.filters = selectedFilters }
    if (selectedTargets) { topicData.selectedTargets = selectedTargets }
    if (topicOriginType === gEnums.topicOriginTypes.page) {
      topicData.topicOrigin = {
        viewItemKey,
        view,
        viewKey,
      }
    }
  }

  const handlePageItem = (pageItemKey, pageItem) => setSelectedPageItem({ key: pageItemKey, pageItem })
  const handleSelectFilter = (sf) => setSelectedFilter(sf)
  const handleSelectedFilters = (sf) => setSelectedFilters(sf)
  const handleLocalPropChange = (data_form) => {
    setTopicFormData(data_form)
  }
  const handleNameChange = (e, data) => setTopicName(data.value)
  const handleSelect = (e, itemKey) => {
    const _selectedAppUsers = updateSimpleArray(topicGroup, selectedAppUsers, itemKey)
    setSelectedAppUsers(_selectedAppUsers)
  }

  const handleCancelTopic = () => {
    switch (topicOriginType) {
      case gEnums.topicOriginTypes.page:
        dataActions_handlers && dataActions_handlers.handleDataActionSelect()
        item_handlers && item_handlers.handleShow_itemSidebar()
        break;
      default:
        props && props.handleCancel ? props.handleCancel() : navigate(-1)
        break;
    }
  }

  const handleSubscribe = () => {
    // switch (topicOriginType) {
    //   case gEnums.topicOriginTypes.manager:
    //     appNotifications_handlers.handleSave_topics(appProfileData)
    //     break;
    //   case gEnums.topicOriginTypes.page:
    //     appNotifications_handlers.handleSave_topics(appProfileData)
    //     break;
    //   default:
    //     appNotifications_handlers.handleSave_topics(appProfileData)
    // } 
  }

  const handleUnSubscribe = () => {
    switch (topicOriginType) {
      case gEnums.topicOriginTypes.manager:
        appNotifications_handlers.handleSave_topics(appProfileData, true)
        break;
      case gEnums.topicOriginTypes.page:
        appNotifications_handlers.handleSave_topics(appProfileData, true)
        break;
      default:
        appNotifications_handlers.handleSave_topics(appProfileData, true)
    }
  }

  const validateComponent = () => {
    switch (step.name) {
      case stepTypes.name:
        return topicName ? true : false
      case stepTypes.links:
        return selectedAppUsers && selectedAppUsers.length > 0 ? true : false
      case stepTypes.notificationTarget:
        return selectedPageItem ? true : false
      case stepTypes.filters:
        return selectedFilters ? true : true
      case stepTypes.notification:
        return topicFormData && topicFormData.name ? true : false
      default:
        break;
    }
  }

  const allowBack = step.index > 0
  const allowForward = (step.index < (stepCount - 1) && validateComponent())

  useEffect(() => {
    const appFormType = appFormTypes.topic
    if (appForms && appForms[appFormType]) {
      const vprs = appForms[appFormType]
      const form_handlers = { handleFormDataChange_local: handleLocalPropChange }
      const fpp = { form_handlers, viewProps: vprs, data_current: topicFormData }
      const fprs = getFormPropz(fpp)
      setTopicProps(fprs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [topicFormData]);

  useEffect(() => {
    const _td = { targetPage: selectedPageItem ? selectedPageItem.key : null, ...topicFormData }
    setTopicData(_td)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [topicFormData, selectedPageItem]);

  useEffect(() => {
    if (appProfileData && appProfileData[appUserCollection]) {
      const t = appProfileData[appUserCollection]
      setTopicGroup(t)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appProfileData]);

  const originSeg = () => {
    const { topicOrigin } = topicData ?? {}
    const { view, viewKey } = topicOrigin ?? {}
    if (staticViews && view && viewKey && staticViews[view] && staticViews[view][viewKey]) {
      return <Segment inverted basic>{'From ' + _.startCase(view) + ': ' + staticViews[view][viewKey].name}</Segment>
    }
    return <Segment inverted basic>{'From ' + topicData.topicOrigin.view}</Segment>
  }

  const create_topic = () => <FormForm formProps={topicProps} />
  const create_filter = () => <UiDataFilters fullFilterType={fullFilterTypes.settingsView} notificationData={topicData} selectedPageItem={selectedPageItem} handleSelectedFilters={handleSelectedFilters} handleSelectFilter={handleSelectFilter} selectedFilters={selectedFilters} />
  const create_page = () => <React.Fragment>
    {topicData && topicData.notificationType === gEnums.notificationScheduleTypes.direct && originSeg()}
    <UiStaticDropdowns handlePageItem={handlePageItem} selectedPageItem={selectedPageItem} notificationTargets={notificationTargets} notificationData={topicData} />
  </React.Fragment>

  const saveButton = () => <UiSaveButtons
    save={{ caption: 'Subsribe', oc: handleSubscribe }}
    delete={{ caption: 'Un-Subsribe', oc: handleUnSubscribe }}
  />

  const save_info = () => {
    return <Wrapper
      header={'Topic Targets'}
      content={<TargetConfirmation
        selectedAppUsers={selectedAppUsers}
        appProfileData={appProfileData}
        topicGroup={topicGroup}
        fromCreate={true}
        topicName={topicName}
      />}
      footer={saveButton()}
      wrapperType={wrapperTypes.padded}
    />
  }

  const topics_info = () => <Wrapper
    header={'Topic Targets'}
    content={<TopicGroupSelect appProfileData={appProfileData} />}
    footer={saveButton()}
    wrapperType={wrapperTypes.padded}
  />

  const topicForm = () => <Form>
    <Form.Input value={topicName} label='Topic Name' fluid onChange={handleNameChange} />
  </Form>

  const stepCaption = () => {
    const name = _.startCase(step.name)
    switch (step.name) {
      default:
        return name
    }
  }

  const content = () => {
    switch (step.name) {
      case stepTypes.name:
        return topicForm()
      case stepTypes.links:
        return <MenuSelect menuList={topicGroup} handleSelect={handleSelect} selectedItems={selectedAppUsers} />
      case stepTypes.notification:
        return create_topic()
      case stepTypes.filters:
        return create_filter()
      case stepTypes.notificationTarget:
        return create_page()
      case stepTypes.confirmation:
      case stepTypes.confirmationTopic:
        return save_info()
      case stepTypes.topics:
        return topics_info()
      default:
        break;
    }
  }

  const stepWizard = () => <StepWizard
    allowBack={allowBack}
    allowForward={allowForward}
    content={content()}
    handleCancel={handleCancelTopic}
    setStep={setStep}
    step={step}
    stepCaption={stepCaption()}
    steps={steps}
    topperCaption={'Create Topic'}
    isBack={true}
  // noModal={topicOriginType === gEnums.topicOriginTypes.global}
  ></StepWizard>

  return step ? stepWizard() : <div></div>

}

export default CreateTopicWizard