import { where } from 'firebase/firestore'
import _ from 'lodash'
import React, { useContext, useEffect, useReducer } from 'react'
import { Button, Card, Icon, Label, List, Segment } from 'semantic-ui-react'
import { getAppUserAccess } from '../auth/appUserAccessPermissions'
import { ParentContext } from '../cnr/contexts/ParentContext'
import { _meetingDisplayTypes, _meetingSubTypes, meetingsHandlers, meetingsInitialState, meetingsReducer } from '../cnr/reducers/MeetingsReducer'
import { convertHelpers } from '../common/convert'
import { formatItem, formatTypes, timeConvertor } from '../common/dateFormatting'
import { uniqueKey } from '../common/keys'
import { updateArrayInclusion } from '../common/sorting'
import UiSaveButtons from '../components/buttons/UiSaveButtons'
import AppForm from '../components/forms/appForms/AppForm'
import MenuSelect from '../components/menus/MenuSelect'
import StepWizard from '../components/wizard/StepWizard'
import { appFormTypes } from '../enums/appFormTypes'
import { dynListTypes, gEnums } from '../enums/globalEnums'
import { createRefPath_event } from '../firestoreData/appData/appRefPaths'
import { newPropEditItem, newPropObject } from '../viewSettings/helpers/settingsHelpers'
import { propDataOpts } from '../viewSettings/settingsProps/settingsAll'
import FullPageWrapper from '../wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper'

const cn_cg = 'crdc item-vert'

const _appForms_meetings = {
  meeting: {
    name: newPropEditItem('name', gEnums.formItemTypes.text),
    description: newPropEditItem('description', gEnums.formItemTypes.textArea),
  },
  meetingRoom: {
    location: newPropObject('location', propDataOpts(true, gEnums.formItemTypes.select, dynListTypes.activeStaticView, { staticView: 'locations' })),
    startDate: newPropEditItem('startDate', gEnums.formItemTypes.date),
    startTime: newPropEditItem('startTime', gEnums.formItemTypes.text),
    endTime: newPropEditItem('endTime', gEnums.formItemTypes.text),
    timeInterval: newPropEditItem('timeInterval', gEnums.formItemTypes.slider)
  },
  meetingRoomDate: {
    startDate: newPropEditItem('startDate', gEnums.formItemTypes.date),
    startTime: newPropEditItem('startTime', gEnums.formItemTypes.text),
    endTime: newPropEditItem('endTime', gEnums.formItemTypes.text),
    timeInterval: newPropEditItem('timeInterval', gEnums.formItemTypes.slider)
  }
}

const stepTypes_meeting = {
  enterInfo: 'enterInfo',
  selectAttendees: 'selectAttendees',
}

const AppMeetingsManagement = (props) => {

  const { handleCancel, forProfileItem } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { appUser_state, page_state, paps_state, eventInfo_state } = states
  const { database_fns } = fns
  const { appUsers } = appUser_state ?? {}
  const { pathViews } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings, aps_global } = pageSettings ?? {}
  const { appUserCollection } = aps_appUserSettings ?? {}
  const { appMeetings } = aps_global ?? {}
  const { timeInterval: timeInterval_settings } = appMeetings ?? {}
  const { staticViews } = eventInfo_state ?? {}

  const _steps = Object.keys(stepTypes_meeting)

  const _appUserAccess = getAppUserAccess(appUsers)
  const { appUserSession } = _appUserAccess ?? {}
  const { appUserSessionKey } = appUserSession ?? {}

  // reducer
  const initState = meetingsInitialState({ steps: _steps, step: { index: 0, name: 'enterInfo' }, staticView: staticViews ? staticViews['locations'] : {}, pathViews, timeInterval_settings: timeInterval_settings, meetingDisplayType: _meetingDisplayTypes.roomBlocks, forProfileItem, appUserSessionKey })
  const [meetings_state, meetings_dispatch] = useReducer(meetingsReducer, initState);
  const meetings_handlers = meetingsHandlers(meetings_dispatch)
  const {
    attendeeMeetings,
    attendees,
    currentMeetingData,
    currentMeetingRoomData,
    currentMeetingRoomDateData,
    defaultOptions,
    meetingDisplayType,
    meetingRooms,
    meetingRoomBlocks,
    scheduledMeetings,
    meetingSubType,
    selectedAttendees,
    selectedMeeting,
    selectedMeetingRoom,
    selectedMeetingRoomBlock,
    selectedTimeSlot,
    step,
    steps,
  } = meetings_state ?? {}

  let _timeInterval = timeInterval_settings ? timeInterval_settings : 30

  if (selectedMeetingRoom && selectedMeetingRoom.timeInterval) {
    _timeInterval = selectedMeetingRoom.timeInterval
  }

  const handleEditMeeting = () => meetings_handlers.handleSet_subMeetingType(_meetingSubTypes.editMeetingRoom)
  const handleEditMeetingRoom = () => meetings_handlers.handleSet_subMeetingType(_meetingSubTypes.editMeetingRoom)
  const handleMeetingDisplayType = (b) => meetings_handlers.handleSet_meetingDisplayType(b)
  const handleShowAddMeeting = () => meetings_handlers.handleSet_subMeetingType(_meetingSubTypes.addMeeting)
  const handleShowAddMeetingRoom = () => handleMeetingSubType(_meetingSubTypes.addMeetingRoom)
  const handleUpdateMeetingData = (d) => meetings_handlers.handleAmmend_meetingData(d)
  const handleUpdateMeetingRoomData = (d) => meetings_handlers.handleAmmend_meetingRoomData(d)

  const stepCount = steps.length

  const handleStep = (step) => {
    if (step) {
      switch (step.name) {
        case stepTypes_meeting.enterInfo:
          meetings_handlers.handle_setStep({ index: 0, name: steps[0] })
          break;
        case stepTypes_meeting.selectAttendees:
          meetings_handlers.handle_setStep({ index: 1, name: steps[1] })
          break;
        default:
        // nothing
      }
    }
  }

  const handleMeetingSubType = (mdt, item) => meetings_handlers.handleSet_subMeetingType(mdt, item)
  const handleCancelMeetingSubType = () => handleMeetingSubType()
  const handleAmmendFormData = (d) => meetings_handlers.handleAmmend_data(d)
  const handleUpdate = () => meetings_handlers.handle_update()

  useEffect(() => {
    meetings_handlers.handle_init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cb = (_attendees) => {
      convertHelpers.createItemKeys(_attendees)
      meetings_handlers.handleSet_attendees(_attendees)
    }
    const refPath_attendees = createRefPath_event(pathViews, ['attendees'])
    const wheres = [where('registrationTypes', '==', 'MLS')]
    database_fns.get_data({ refPath: refPath_attendees, wheres, callback: cb })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUserCollection]);

  const buttons = () => {
    return Object.keys(_meetingDisplayTypes).map((key, index) => {
      return <Button key={uniqueKey('ms', 'b', index)} className={meetingDisplayType === key ? 'btn-sel' : null} color={meetingDisplayType === key ? 'blue' : null} onClick={() => { handleMeetingDisplayType(key, index) }}>{_.startCase(key)}</Button>
    })
  }

  const button_group = () => <Button.Group size={'mini'} >
    {buttons()}
  </Button.Group>

  const icon = (iconName, color) => <Icon color={color} name={iconName} />

  const nameLabel = (value, color, cn) => <Label key={uniqueKey('dtl', color)} className={cn ? cn : null}>
    <div>{value}</div>
  </Label>

  const dateLabel = (value, color, cn) => <Label key={uniqueKey('dtl', color)} className={cn ? cn : null} >
    <div>{icon('calendar', color)} </div>
    <div>{value}</div>
  </Label>

  const timeLabel_simple = (value, dtProp, key, color, cn) => <Label key={uniqueKey('dtl', color)} className={'pi-' + dtProp + cn}>
    <div>{icon('time', color)} </div>
    <div>{value}</div>
  </Label>

  const timeLabel = (time, color, cn) => {
    if (time) {
      const { hour, min } = timeConvertor(time)
      let value_time = time
      if (hour && min) {
        const dt = new Date()
        dt.setHours(hour)
        dt.setMinutes(min)
        value_time = formatItem(formatTypes.time, dt)
      }
      return timeLabel_simple(value_time, 'sss', null, color, cn)
    }
  }

  const timeIntervalLabel = (value, color) => <Label key={uniqueKey('ditl', color)} >
    <div>{icon('stopwatch', color)} </div>
    <div>{value}</div>
  </Label>

  const takenLabel = (value, color) => <Label key={uniqueKey('ditl', color)} >
    <div>{icon('delete calendar', color)} </div>
    <div>{value}</div>
  </Label>

  const freeLabel = (value, color) => <Label key={uniqueKey('ditl', color)} >
    <div>{icon('checked calendar', color)} </div>
    <div>{value}</div>
  </Label>

  const countLabel = (value, color) => <Label key={uniqueKey('ditl', color)} >
    <div>{icon('users', color)} </div>
    <div>{value}</div>
  </Label>

  const timeSlots = () => {

    const { endTime, startTime, timeSlots } = selectedMeetingRoom ?? {}

    // Create an array to store the time slots
    const _timeSlots = [];

    if (startTime && endTime) {

      const _start = {
        hour: startTime.split(':')[0],
        minute: startTime.split(':')[1],
      }

      const _end = {
        hour: endTime.split(':')[0],
        minute: endTime.split(':')[1],
      }

      // Start time and end time
      const _startTime = new Date();
      _startTime.setHours(_start.hour, _start.minute, 0); // 8:00 AM

      const _endTime = new Date();
      _endTime.setHours(_end.hour, _end.minute, 0); // 8:00 PM

      // Generate time slots every 30 minutes
      const currentTime = new Date(_startTime);
      let timeSlotIndex = 0

      while (currentTime <= _endTime) {

        const count = timeSlots && timeSlots[timeSlotIndex] && timeSlots[timeSlotIndex].attendees ? Object.keys(timeSlots[timeSlotIndex].attendees).length : 0

        // Format time as "hh:mm AM/PM"
        const formattedTime = currentTime.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        });

        const ts = {
          index: timeSlotIndex,
          formattedTime,
          currentTime
        }

        if (timeSlots && timeSlots[timeSlotIndex]) {
          ts.name = timeSlots[timeSlotIndex].name
        }

        // Push the time slot into the array
        _timeSlots.push(
          <List.Item key={formattedTime} className={'meet-times'} onClick={(e) => { handleMeetingSubType(_meetingSubTypes.timeSlot, ts) }}>
            {timeSlotDetails(ts.name, formattedTime, count)}
          </List.Item>
        );

        timeSlotIndex++

        // Increment time by 30 minutes
        currentTime.setMinutes(currentTime.getMinutes() + _timeInterval);
      }

    }

    return <Segment basic><List divided relaxed small>
      {_timeSlots}
    </List></Segment>
  };

  const card_meeting = (scheduledMeeting, index, selected) => {
    const { name, startDate, startTime, endTime, meetingRoom, attendees: attendees_meeting } = scheduledMeeting ?? {}
    const _meetingRoom = meetingRooms && meetingRooms[meetingRoom] ? meetingRooms[meetingRoom].name : meetingRoom
    return <Card key={uniqueKey('amm', 'cm', index)} fluid className={'card-content'} as='div' onClick={(e) => { !selected && handleMeetingSubType(_meetingSubTypes.scheduledMeeting, scheduledMeeting) }}>
      <Card.Content>
        <Card.Header>{name}</Card.Header>
        <Card.Description>
          {_meetingRoom}
        </Card.Description>
      </Card.Content>
      <Card.Content extra className={'meet-times'}>
        {dateLabel(formatItem(formatTypes.fullDate, startDate), 'blue', ' tsl')}
        {timeLabel(startTime, 'green')}
        {timeLabel(endTime, 'red')}
      </Card.Content>
      {selected && attendeeList_meeting && <Card.Content extra>
        {attendeeList_meeting(attendees_meeting)}
      </Card.Content>}
    </Card>
  }

  const timeSlotDateDetails = (name, startDate, formattedTime, count) => {
    const taken = name ? true : false
    return <React.Fragment>
      {nameLabel(taken ? name : 'OPEN', taken ? 'blue' : 'green', ' tsl')}
      <div className='mrts'>
        {dateLabel(startDate, taken ? 'blue' : 'green')}
        {timeLabel(formattedTime, taken ? 'blue' : 'green')}
        {countLabel(count, taken ? 'blue' : 'green')}
      </div>
    </React.Fragment>
  }

  const timeSlotDetails = (name, formattedTime, count) => {
    const taken = name ? true : false
    return <React.Fragment>
      {timeLabel(formattedTime, taken ? 'blue' : 'green', ' tsl')}
      <div className='mrts'>
        {nameLabel(taken ? name : 'OPEN', taken ? 'blue' : 'green')}
        {countLabel(count, taken ? 'blue' : 'green')}
      </div>
    </React.Fragment>
  }

  const meetingRoomDetails = (meetingRoom) => {
    const { availablity, startDate, startTime, endTime, timeInterval } = meetingRoom ?? {}
    const { taken, free } = availablity ?? {}
    return <React.Fragment>
      {dateLabel(formatItem(formatTypes.fullDate, startDate), 'blue', ' tsl')}
      <div className='mrts'>
        {timeLabel(startTime, 'green')}
        {timeIntervalLabel(timeInterval, 'black')}
        {timeLabel(endTime, 'red')}
      </div>
      <div className='mrts'>
        {freeLabel(free + ' free', 'green')}
        {takenLabel(taken + ' taken', 'blue')}
      </div>
    </React.Fragment>
  }

  const card_meetingRoom = (meetingRoom, index, selected) => {

    const { name, timeSlots: _timeSlots, availablity } = meetingRoom ?? {}
    const { taken } = availablity ?? {}
    const count = _timeSlots ? Object.keys(_timeSlots).length : 0

    return <Card key={uniqueKey('amm', 'mr', index)} fluid className={'card-content'} as='div' onClick={(e) => { !selected && handleMeetingSubType(_meetingSubTypes.meetingRoom, meetingRoom) }}>
      <Card.Content>
        <Card.Header>
          <Label circular color={(count > 0 ? 'blue' : 'grey')} style={{ 'marginRight': '1em' }}><Icon name='time' />{taken}</Label>
          {name}
        </Card.Header>
      </Card.Content>
      <Card.Content extra className={'meet-times'}>
        {meetingRoomDetails(meetingRoom)}
      </Card.Content>
      {selected && timeSlots()}
    </Card>
  }

  const card_meetingRoomBlock = (mrbKey, meetingRoomBlock, index, selected) => {
    if (meetingRoomBlock) {
      const lis = meetingRoomBlock.map(mrb => {
        return <List.Item onClick={(e) => { !selected && handleMeetingSubType(_meetingSubTypes.meetingRoom, mrb) }}>
          <List.Icon name='users' size='large' verticalAlign='middle' color={'blue'} />
          <List.Content>
            <List.Description className='meet-times'>
              {meetingRoomDetails(mrb)}
            </List.Description>
          </List.Content>
        </List.Item>
      })
      return <Card key={uniqueKey('amm', 'mr', index)} fluid className={'card-content'} as='div'  >
        <Card.Content>
          <Card.Header>
            {/* <Label circular color={(count > 0 ? 'blue' : 'grey')} style={{ 'marginRight': '1em' }}><Icon name='time' />{taken}</Label> */}
            {mrbKey}
          </Card.Header>
          <Card.Description>
            <List divided relaxed small>
              {lis}
            </List>
          </Card.Description>
        </Card.Content>
        {selected && timeSlots()}
      </Card>
    }
  }

  const card_timeSlot = (timeSlot) => {
    const { startDate } = selectedMeetingRoom
    const { name, description, formattedTime, index } = timeSlot
    const count = getCount(timeSlot)
    if (name) {
      return <Card key={uniqueKey('amm', 'cts', index)} fluid className={'card-content'} as='div' >
        <Card.Content>
          <Card.Content className={'meet-times'} >
            {timeSlotDateDetails(name, startDate, formattedTime, count)}</Card.Content>
          <Card.Content extra>
            {description}
          </Card.Content>
        </Card.Content>
        <Card.Content  >
          {attendeeList_selected(timeSlot)}
        </Card.Content>
      </Card>
    } else {
      return <Card key={uniqueKey('amm', 'cts', index)} fluid className={'card-content'} as='div' >
        <Card.Content className={'meet-times'} >
          {timeSlotDateDetails(name, startDate, formattedTime, count)}
          <Card.Description>
            <div>No attendees selected</div>
          </Card.Description>
        </Card.Content>

      </Card >
    }
  }

  const card_selected = (selected) => {
    switch (meetingSubType) {
      case _meetingSubTypes.timeSlot:
        return card_timeSlot(selectedTimeSlot)
      case _meetingSubTypes.scheduledMeeting:
        return card_meeting(selectedMeeting, 0, selected)
      case _meetingSubTypes.meetingRoom:
        return card_meetingRoom(selectedMeetingRoom, 0, selected)
      case _meetingSubTypes.meetingRoomBlock:
        return card_meetingRoomBlock('TEST', selectedMeetingRoomBlock, 0, selected)
      default:
      // nothing
    }
  }

  const footer_main = () => {
    const btns = []
    switch (meetingDisplayType) {
      case _meetingDisplayTypes.rooms:
        btns.push({ oc: handleShowAddMeetingRoom, caption: 'Add Meeting Room', icon: 'add' })
        break;
      default:
      // nothing
    }
    return btns
  }

  const footer_selected = () => {
    const btns = []
    if (selectedTimeSlot) {
      btns.push({ oc: handleShowAddMeeting, caption: 'Add Meeting', icon: 'add' })
    } else if (selectedMeeting) {
      btns.push({ oc: handleEditMeeting, caption: 'Edit Meeting', icon: 'edit' })
    } else if (selectedMeetingRoom) {
      btns.push({ oc: handleEditMeetingRoom, caption: 'Edit Meeting Room', icon: 'edit' })
    }
    return btns
  }

  const footer_selectedAdd = () => {
    const allow = currentMeetingData && currentMeetingData.name && selectedAttendees && selectedAttendees.length > 0 ? true : false
    const btns = []
    if (selectedTimeSlot) {
      btns.push({ oc: meetings_handlers.handleSave_newMeeting, caption: 'Create Meeting', icon: 'save', disabled: !allow })
    }
    return btns
  }

  const wrapper_selected = () => {
    const btns = footer_selected()
    return <Wrapper
      content={<Card.Group className={cn_cg}>{card_selected(true)}</Card.Group>}
      footer={btns && btns.length > 0 && <UiSaveButtons others={btns} />}
      wrapperType={wrapperTypes.paddedFooter}
    />
  }

  const form = (appFormType) => <AppForm
    appFormType={appFormType}
    parentData={selectedMeetingRoom}
    handleUpdateParentData={appFormType === appFormTypes.meeting ? handleUpdateMeetingData : handleUpdateMeetingRoomData}
    handleSave={handleUpdate}
    defaultOptions={defaultOptions}
  />

  const formWithItems = () => {

    let _appFormItems;
    let _parentData;
    let _saveCaption;
    let _handleSave;

    switch (meetingSubType) {
      case _meetingSubTypes.addMeetingRoom:
        _appFormItems = _appForms_meetings.meetingRoom
        _parentData = currentMeetingRoomData
        _saveCaption = 'Save Meeting Room'
        _handleSave = handleUpdate
        break;

      case _meetingSubTypes.addMeeting:
        // _appForms.meeting, currentMeetingData, handleUpdateMeetingData
        _appFormItems = _appForms_meetings.meeting
        _parentData = currentMeetingData
        _saveCaption = 'Save Meeting'
        // _handleSave = handleUpdate
        break;

      default:
      // nothing

    }

    return <AppForm
      appFormItems={_appFormItems}
      parentData={_parentData}
      handleUpdateParentData={handleAmmendFormData}
      handleSave={_handleSave}
      handleSaveCaption={_saveCaption}
      defaultOptions={defaultOptions}
    />
  }

  const validateComponent = () => {
    switch (step.name) {
      case stepTypes_meeting.enterInfo:
        return currentMeetingData && currentMeetingData.name
      case stepTypes_meeting.selectAttendees:
        return selectedAttendees && selectedAttendees.length > 0
      default:
      // nothing
    }
  }
  const allowBack = step.index > 0
  const allowForward = (step.index < (stepCount - 1) && validateComponent())

  const wizardContent = () => {
    switch (step.name) {
      case stepTypes_meeting.enterInfo:
        return formWithItems(_appForms_meetings.meeting, currentMeetingData, handleUpdateMeetingData)
      case stepTypes_meeting.selectAttendees:
        return attendeeList_full()
      default:
      // nothing
    }
  }

  const stepWizard = () => <StepWizard
    allowBack={allowBack}
    allowForward={allowForward}
    content={wizardContent()}
    noModal={true}
    setStep={handleStep}
    step={step}
    stepCaption={_.startCase(step.name)}
    steps={steps}
  ></StepWizard>


  const www = () => <Wrapper
    content={stepWizard()}
    footer={<UiSaveButtons others={footer_selectedAdd()} />}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper>


  const fpw_add = () => {
    const { name, startDate } = selectedMeetingRoom ?? {}
    const { formattedTime } = selectedTimeSlot ?? {}
    const _topperCaption = 'Add Meeting - ' + name
    const _topperCaption2 = startDate + ' - ' + formattedTime
    return <FullPageWrapper
      content={meetingSubType === _meetingSubTypes.addMeeting ? www() : formWithItems(_appForms_meetings.meetingRoom, currentMeetingRoomData, handleUpdateMeetingRoomData)}
      topperCaption={meetingSubType === _meetingSubTypes.addMeeting ? _topperCaption : 'Add Meeting Room'}
      topperCaption2={_topperCaption2}
      handleCancel={handleCancelMeetingSubType}
    />
  }

  const fpw_edit = () => <FullPageWrapper
    content={form(meetingSubType === _meetingSubTypes.editMeetingMeeting ? appFormTypes.meeting : appFormTypes.meetingRoom)}
    topperCaption={'Edit Meeting Room'}
    topperCaption2={selectedMeetingRoom.name}
    handleCancel={handleCancelMeetingSubType}
  />

  const fpw_addAttendee = () => <FullPageWrapper
    content={attendeeList_full()}
    topperCaption={'Add Attendees'}
    handleCancel={handleCancelMeetingSubType}
  />

  const fpw_addDate = () => <FullPageWrapper
    content={formWithItems(_appForms_meetings.meetingRoomDate, currentMeetingRoomDateData, handleUpdate)}
    topperCaption={'Add Date'}
    handleCancel={handleCancelMeetingSubType}
  />

  const fpw_selected = () => {

    const tc = _.startCase(meetingSubType)
    let tc2;

    const { name, startDate } = selectedMeetingRoom ?? {}

    switch (meetingSubType) {
      case _meetingSubTypes.meetingRoom:
      case _meetingSubTypes.editMeetingRoom:
        tc2 = name
        break;

      case _meetingSubTypes.timeSlot:
        tc2 = name
        break;

      case _meetingSubTypes.addMeeting:
        tc2 = name + ' - ' + startDate + ' - ' + selectedTimeSlot.formattedTime
        break;

      case _meetingSubTypes.editMeeting:
        tc2 = name + ' - ' + selectedTimeSlot.formattedTime
        break;

      case _meetingSubTypes.addMeetingRoom:
        break;

      case _meetingSubTypes.attendees:
        break;

      default:
        break;
    }

    return <FullPageWrapper
      content={wrapper_selected()}
      topperCaption={tc}
      topperCaption2={tc2}
      handleCancel={handleCancelMeetingSubType}
    />
  }

  const handleSelectAttendee = (e, d) => {
    e.stopPropagation()
    const _selectedAttendees = selectedAttendees ? [...selectedAttendees] : []
    updateArrayInclusion(_selectedAttendees, d._itemKey)
    meetings_handlers.handleSet_selectedAttendees(_selectedAttendees)
  }

  const attendeeList_full = () => <Wrapper
    content={<MenuSelect menuList={_.sortBy(attendees, 'lastName')} handleSelect={handleSelectAttendee} selectedItems={[]} />}
    wrapperType={wrapperTypes.padded}
  />

  const getCount = (timeSlot) => {
    if (selectedMeetingRoom && selectedMeetingRoom.timeSlots && selectedMeetingRoom.timeSlots[timeSlot.index] && selectedMeetingRoom.timeSlots[timeSlot.index].attendees) {
      return selectedMeetingRoom.timeSlots[timeSlot.index].attendees.length
    }
    return 0
  }

  const attendeeList_selected = (timeSlot) => {
    if (selectedMeetingRoom && selectedMeetingRoom.timeSlots && selectedMeetingRoom.timeSlots[timeSlot.index] && selectedMeetingRoom.timeSlots[timeSlot.index].attendees) {
      const _attendeeKeys = selectedMeetingRoom.timeSlots[timeSlot.index].attendees
      const _filteredAttendees = _.filter(attendees, (attendee) => _attendeeKeys.includes(attendee.id));
      return <MenuSelect menuList={_filteredAttendees} handleSelect={handleSelectAttendee} selectedItems={_attendeeKeys} />
    }
    return <div>No Attendees</div>
  }

  const attendeeList_meeting = (attendees_meeting) => {
    const _filteredAttendees = _.filter(attendees, (attendee) => attendees_meeting.includes(attendee.id));
    return <MenuSelect menuList={_filteredAttendees} handleSelect={handleSelectAttendee} selectedItems={attendees_meeting} readOnly={true} />
  }

  const content = () => {
    let c;
    const btns = footer_main()
    switch (meetingDisplayType) {
      case _meetingDisplayTypes.scheduledMeetings:
        c = scheduledMeetings ? scheduledMeetings.map((scheduledMeeting, index) => (card_meeting(scheduledMeeting, index))) : null
        break;
      case _meetingDisplayTypes.rooms:
        const _meetingsRooms = _.sortBy(meetingRooms, 'name')
        c = _meetingsRooms ? _meetingsRooms.map((meetingRoom, index) => (card_meetingRoom(meetingRoom, index))) : null
        break;
      case _meetingDisplayTypes.roomBlocks:
        c = meetingRoomBlocks ? Object.keys(meetingRoomBlocks).map((mrbKey, index) => {
          const meetingRoomBlock = meetingRoomBlocks[mrbKey]
          return card_meetingRoomBlock(mrbKey, meetingRoomBlock, index)
        }) : null
        break;
      default:
      // nothing
    }
    return <Wrapper
      header={button_group()}
      content={c ? <Card.Group className={cn_cg}>{c}</Card.Group> : <div></div>}
      footer={btns && btns.length > 0 && <UiSaveButtons others={btns} />}
      wrapperType={wrapperTypes.padded}
      css={{ container: 'amm' }
      }
    />
  }

  const content_attendees = () => {
    const c = attendeeMeetings ? attendeeMeetings.map((meeting, index) => (card_meeting(meeting, index))) : null
    return <Wrapper
      content={c ? <Card.Group className={cn_cg}>{c}</Card.Group> : <div></div>}
      wrapperType={wrapperTypes.padded}
      css={{ container: 'amm' }
      }
    />
  }

  const fpw_meetings = () => <FullPageWrapper
    content={content()}
    topperCaption={'Meetings'}
    handleCancel={handleCancel}
  />

  switch (meetingSubType) {
    case _meetingSubTypes.scheduledMeeting:
    case _meetingSubTypes.meetingRoom:
    case _meetingSubTypes.meetingRoomBlock:
    case _meetingSubTypes.timeSlot:
      return fpw_selected()

    case _meetingSubTypes.editMeeting:
    case _meetingSubTypes.editMeetingRoom:
      return fpw_edit()

    case _meetingSubTypes.addMeeting:
    case _meetingSubTypes.addMeetingRoom:
      return fpw_add()

    case _meetingSubTypes.attendees:
      return fpw_addAttendee()

    case _meetingSubTypes.addMeetingRoomDate:
      return fpw_addDate()

    default:
      return forProfileItem ? content_attendees() : fpw_meetings()
  }

}

export default AppMeetingsManagement