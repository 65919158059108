import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { clientInfoHandlers, clientInfoInitialState, clientInfoReducer } from '../reducers/ClientInfoReducer';
import { PapsContext } from './PapsContext';
import { DatabaseContext } from './DatabaseContext';
import { _useCache, ServiceWorkerContext } from './ServiceWorkerContext';
import { _cacheNames, _cacheTypes } from '../reducers/ServiceWorkerReducer';
import { getNow } from '../../common/dateFormatting';


/** gets the data for the uiItem.
 * @returns viewListData, confirmation, globalFiles */
export const ClientInfoContext = createContext();

/** gets the data for the uiItem.
 * @returns viewListData,  
 * @returns confirmation, 
 * @returns globalFiles */
const ClientInfoProvider = (props) => {

  // databaseContext
  const databaseContext = useContext(DatabaseContext)
  const { database_fns } = databaseContext ?? {}

  // serviceWorkerContext
  const serviceWorkerContext = useContext(ServiceWorkerContext)
  const { serviceWorker_state, serviceWorker_handlers } = serviceWorkerContext ?? {}
  const cache_data = serviceWorker_state?.cache_info?.cache_groups?.[_cacheNames.cache_client] ?? {};

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext
  const { pathViews, cacheKey } = paps_state ?? {}
  const { clients: client_key } = pathViews ?? {}

  // clientInfoInitialState
  const initState = clientInfoInitialState({ database_fns, pathViews })
  const [clientInfo_state, dispatch] = useReducer(clientInfoReducer, initState);
  const clientInfo_handlers = clientInfoHandlers(dispatch)
  const { dataFromCache, clientsFetched, clientEvents } = clientInfo_state ?? {}

  useEffect(() => {
    if (client_key) {
      const cache_data_client = cache_data && cache_data[client_key]
      const { _timeStamps, cache_collections } = cache_data_client ?? {}
      const { events: events_cache } = cache_collections ?? {}
      const { events: events_timeStamp } = _timeStamps ?? {}
      if (events_cache && _useCache.clients) {
        clientInfo_handlers.handleSet_clientEvents(events_cache, null, true)
      } else {
        clientInfo_handlers.handleGet_clientEvents(pathViews)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [client_key]);

  useEffect(() => {
    if (!dataFromCache) {
      // if clientEvents exist, send the data AND timestamps to the service worker.
      if (clientEvents) {
        const uivi = 'events'
        const _currentTimeStamp = getNow()
        const settings_data = {
          cacheKey: cacheKey,
          cacheName: uivi,
          cacheData: clientEvents
        }
        serviceWorker_handlers.handleSend_messageToServiceWorker(settings_data);

        // cache the TIME STAMP
        const settings_timeStamp = {
          cacheKey: cacheKey,
          cacheName: '_timeStamps',
          cacheData: { [uivi]: _currentTimeStamp, merge: true }
        }
        serviceWorker_handlers.handleSend_messageToServiceWorker(settings_timeStamp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [clientsFetched, clientEvents]);

  const context = () => <ClientInfoContext.Provider value={{ clientInfo_state, clientInfo_handlers }}>
    {props.children}
  </ClientInfoContext.Provider>

  return context()
}

export default ClientInfoProvider