import React, { useContext, useEffect, useState } from 'react';
import { getFormPropz } from '../../../components/forms/props/formPropz';
import FormForm from '../../../components/forms/FormForm';
import { AppFormsContext } from '../../../cnr/contexts/AppFormsContext';
import { appFormTypes } from '../../../enums/appFormTypes';
import { SettingParentContext } from '../../../cnr/contexts/SettingParentContext';

export const InputContent = (props) => {

  const { inputProps } = props
  const { hds, formData, camelCase } = inputProps

  const settingsParentContext = useContext(SettingParentContext)
  const { states } = settingsParentContext ?? {}
  const { appForms_state } = states ?? {}
  const { appForms } = appForms_state ?? {}

  const [formProps, setFormProps] = useState()

  useEffect(() => {
    if (appForms && appForms[appFormTypes.simpleInput]) {
      const vprs = appForms[appFormTypes.simpleInput]
      const fpp = { form_handlers: hds, viewProps: vprs, data_current: formData, camelCase }
      const fprs = getFormPropz(fpp)
      setFormProps(fprs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  if (formProps) {
    return <FormForm formAttributes={{ inverted: true }
    } formProps={formProps} />
  }

  return <div></div>

}

export const SelectContent = (props) => {

  const { selectProps } = props ?? {}
  const { formData: _formData, hds, formType } = selectProps ?? {}

  // appFormsContext
  const appFormsContext = useContext(AppFormsContext);
  const { appForms_state } = appFormsContext ?? {}
  const { appForms } = appForms_state ?? {}

  const [formData] = useState(_formData ? _formData : {})

  // local state
  const [formProps, setFormProps] = useState()

  useEffect(() => {
    if (appForms && appForms[appFormTypes.simpleSelect]) {
      const vprs = appForms[appFormTypes.simpleSelect]
      const fpp = { form_handlers: hds, viewProps: vprs, data_current: formData }
      const fprs = getFormPropz(fpp)
      if (fprs && fprs.viewProps && fprs.viewProps.name && fprs.viewProps.name.data) {
        fprs.viewProps.name.data.listType = selectProps.listType
      }
      setFormProps(fprs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [formType]);

  return formProps ? <FormForm
    formAttributes={{ inverted: true }}
    formProps={formProps}
  /> : <div></div>
}

export const NewPageContent = (props) => {

  const { selectProps } = props ?? {}
  const { view_dup, formData: _formData, hds } = selectProps ?? {}

  // appFormsContext
  const appFormsContext = useContext(AppFormsContext);
  const { appForms_state } = appFormsContext ?? {}
  const { appForms } = appForms_state ?? {}

  const [formData] = useState(_formData ? _formData : { page: view_dup })

  // local state
  const [formProps, setFormProps] = useState()

  useEffect(() => {
    if (appForms && appForms[appFormTypes.simpleSelectInput]) {
      const vprs = appForms[appFormTypes.simpleSelectInput]
      const fpp = { form_handlers: hds, viewProps: vprs, data_current: formData }
      const fprs = getFormPropz(fpp)
      if (fprs && fprs.viewProps && fprs.viewProps.page && fprs.viewProps.page.data) {
        fprs.viewProps.page.data.listType = selectProps.listType
      }
      setFormProps(fprs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  return formProps ? <FormForm
    formAttributes={{ inverted: true }}
    formProps={formProps}
  /> : <div></div>
}