import React from 'react';
import { Icon, Message, Segment } from 'semantic-ui-react';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper from '../../wrappers/Wrapper';

const FullPageAlert = (props) => {

  const { title, message, navigate } = props

  const handleCancel = () => {
    navigate && navigate(-1)
    props.handleCancel && props.handleCancel()
  }

  return <FullPageWrapper
    content={<Wrapper
      content={<Segment placeholder>
        <Message
          size='tiny'
          icon={<Icon color='blue' name="bell" />}
          header={title}
          content={message}
        />

      </Segment>}
      css={{ container: 'full-page-alert' }}
    />}
    topperCaption={title ? title : 'Alert'}
    handleCancel={handleCancel}
  />
};

export default FullPageAlert