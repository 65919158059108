import { isSupported } from 'firebase/messaging';
import React, { createContext, useEffect, useReducer } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { papsHandlers, papsInitialState, papsReducer } from '../reducers/PapsReducer';
import { _showStates } from '../../../App';


const showStatus = false

/** 
 * @parentContexts [StartContext, AuthContext, AppSettingContext] 
 * @provides paps_state, paps_handlers, navigate
 * @description Contains information about the current page location (url, path, etc.) 
 * */
export const PapsContext = createContext();

/** 
 * @parentContexts [StartContext, AuthContext, AppSettingContext] 
 * @provides paps_state, paps_handlers, navigate
 * @description Contains information about the current page location (url, path, etc.) 
 * */
const PapsProvider = (props) => {

  const { pathname: newPathName } = useLocation()

  const navigate = useNavigate()

  const [paps_state, paps_dispatch] = useReducer(papsReducer, papsInitialState());
  const paps_handlers = papsHandlers(paps_dispatch)
  const { pathname: paps_pathName } = paps_state

  if (_showStates.any && _showStates.paps) {
    console.log(Object.keys(paps_state).sort())
    console.log(Object.keys(paps_handlers).sort())
  }

  useEffect(() => {
    isSupported().then(messagingSupported => {
      paps_handlers.handleUpdate_messagingSupported(messagingSupported)
    })
    // eslint-disable-next-line
  }, [newPathName]);

  useEffect(() => {
    paps_handlers.handleAmmend_currentPath(newPathName, paps_pathName)
    // eslint-disable-next-line
  }, [newPathName]);

  const linker = (content, item, cns) => {
    const { id, key } = item ?? {}
    const too = id ? id : key
    let to = newPathName + '/' + too
    if (to.indexOf('//') >= 0) { to = to.replace('//', '/') }
    if (cns) {
      return <Link to={to} {...cns}>{content}</Link>
    } else {
      return <Link to={to}>{content}</Link>
    }
  }

  const providerContext = () => <PapsContext.Provider value={{ paps_state: { ...paps_state, showStatus }, paps_handlers: { ...paps_handlers, linker }, navigate, pathName: newPathName }}>
    {props.children}
  </PapsContext.Provider>

  return paps_pathName ? providerContext() : <div></div>
}

/** 
 * @provides paps_state, paps_handlers, navigate
 */
export default PapsProvider