import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { questionsAndAnswersHandlers, questionsAndAnswersInitialState, questionsAndAnswersReducer } from '../reducers/QuestionsAndAnswersReducer';
import { surverReducer, surveyHandlers, surveyInitialState } from '../reducers/SurverReducer';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';
import { ratingHandlers, ratingInitialState, ratingReducer } from '../reducers/RatingReducer';
import { DatabaseContext } from './DatabaseContext';

/** Global VideoConferences (Icon, Image, PDF) for the Event taken from the database */
export const QuestionsAndAnswersContext = createContext();
export const SurveyContext = createContext();
export const RatingContext = createContext();

/** This will get the qAndA, ratings and survey */
const AppAdminProvider = (props) => {

  // databaseContext
  const databaseContext = useContext(DatabaseContext)
  const { database_fns } = databaseContext ?? {}

  // papsContext
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext ?? {}

  // pageContext
  const pageContext = useContext(PageContext)
  const { page_state } = pageContext ?? {}

  const { settingsDocName, pathViews } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { appQuestionsAndAnswers, appRating, appSurvey } = aps_global ?? {}
  const { allowQuestionsAndAnswers } = appQuestionsAndAnswers ?? {}
  const { allowRating } = appRating ?? {}
  const { allowSurvey } = appSurvey ?? {}

  // questionsAndAnswers_state
  const init_state_qna = { database_fns, pathViews, allowQuestionsAndAnswers }
  const [questionsAndAnswers_state, questionsAndAnswersDispatch] = useReducer(questionsAndAnswersReducer, questionsAndAnswersInitialState(init_state_qna));
  const questionsAndAnswers_handlers = questionsAndAnswersHandlers(questionsAndAnswersDispatch, questionsAndAnswers_state)
  const { questionsAndAnswers } = questionsAndAnswers_state ?? {}

  const init_state_rating = { database_fns, pathViews, allowRating }
  const [rating_state, ratingDispatch] = useReducer(ratingReducer, ratingInitialState(init_state_rating));
  const rating_handlers = ratingHandlers(ratingDispatch, rating_state)

  // survey_state
  const init_state_survey = { database_fns, pathViews, allowSurvey }
  const [survey_state, surveyDispatch] = useReducer(surverReducer, surveyInitialState(init_state_survey));
  const survey_handlers = surveyHandlers(surveyDispatch, survey_state)
  const { activeSurvey } = survey_state ?? {}

  useEffect(
    () => {
      // only get videoConferences in the events collection  
      questionsAndAnswers_handlers.handleInit_qAndA()
      rating_handlers.handleInit_rating()
      survey_handlers.handleInit_survey()
    },
    // eslint-disable-next-line
    [settingsDocName] // does not re-rednder unless uivi has changed
  )

  const providerContext = () => {
    return <QuestionsAndAnswersContext.Provider value={{ questionsAndAnswers_state, questionsAndAnswers_handlers }}>
      <RatingContext.Provider value={{ rating_state, rating_handlers }}>
        <SurveyContext.Provider value={{ survey_state, survey_handlers }}>
          {props.children}
        </SurveyContext.Provider>
      </RatingContext.Provider>
    </QuestionsAndAnswersContext.Provider>
  }

  if (questionsAndAnswers && activeSurvey) { return providerContext() }

  return <div></div>

}

export default AppAdminProvider