import _ from 'lodash';
import { _linkedDataCollectionName } from '../../../../projectSpecific/sports/dbActions/globals';
import { dispatchProps } from '../../../cnr/reducers/reducerHelpers/dispatchProps';
import { createRefPath_event } from '../../appData/appRefPaths';
import { fs_dbu } from '../../appData/fsAppDataUpdate';

const _allowLinkUpdates = false

export const updateLinkHelpers = {
  update_appLinkedData: (pathViews, _linkedData, dispatch) => update_appLinkedData(pathViews, _linkedData, dispatch),
  get_appLinkedData: (pathViews, viewItemKey, viewListData, staticViews, allowUpdate) => get_appLinkedData(pathViews, viewItemKey, viewListData, staticViews, allowUpdate),
}

const update_appLinkedData = (pathViews, _linkedData, dispatch) => {
  _.forEach(_linkedData, (data, key) => {
    const _refPathL = createRefPath_event(pathViews, [_linkedDataCollectionName, key])
    if (_allowLinkUpdates) {
      fs_dbu.set_doc(_refPathL, data, true).then(res => {
        dispatch && dispatch({ type: dispatchProps.successAlt, dispatch })
      }).catch(error => {
        console.log('error', error)
      })
    } else {
      // console.log('update_itemInDocuments', _refPathL, data)
    }
  })
}

const get_appLinkedData = (pathViews, viewItemKey, viewListData, staticViews, allowUpdate) => {
  const _linkedData = {}
  if (viewListData) {
    const _sdks = _.keys(staticViews)
    _.forEach(viewListData, (dataItem, docKey) => { // docKey = attendees, sessions, etc. 
      _.forEach(dataItem, (docPropValue, docProp) => { // docProp = name, attendees, sessions, etc.
        // if the docPropValue is an array AND the docProp is a key in the appDataDocuments
        if (_sdks.includes(docProp) && _.isArray(docPropValue) && _.size(docPropValue) > 0) {
          // create the OBEJCT if needed
          if (!_linkedData[viewItemKey]) { _linkedData[viewItemKey] = {} }
          if (!_linkedData[viewItemKey][docKey]) { _linkedData[viewItemKey][docKey] = {} }
          if (!_linkedData[viewItemKey][docKey][docProp]) { _linkedData[viewItemKey][docKey][docProp] = {} }
          _linkedData[viewItemKey][docKey][docProp] = docPropValue
        }
      })
    })
  }

  if (allowUpdate && !_.isEmpty(_linkedData)) {
    update_appLinkedData(pathViews, _linkedData)
  }

  return _linkedData

}