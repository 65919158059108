import { useEffect } from 'react';

const useTimeMeasurement = (typeOf, valid, callback) => {
  useEffect(() => {
    let startTime;
    const delay = 100

    const handleMeasurement = () => {
      const endTime = performance.now();
      const duration = (endTime - startTime) / 1000;
      // console.log(typeOf + ` valid in ${duration} milliseconds`);
      callback(duration)
    };

    if (valid) {
      startTime = performance.now();
      const timeoutId = setTimeout(handleMeasurement, delay);

      return () => {
        clearTimeout(timeoutId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid]);
};

export default useTimeMeasurement;