import React, { useContext, useEffect, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { uniqueKey } from '../../../../global/common/keys';
import { SportsEditMatchContext } from '../../cnr/contexts/SportsEditMatchContext';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { gameScoringTypes } from '../../cnr/reducers/SportsSeasonReducer';
import { _matchResultTypes } from '../../cnr/reducers/SportsMatchReducer';

/** Return as the score in a table-like format */
const MatchScoreTable = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state } = states
  const { appUsers } = appUser_state ?? {}

  const appUserAccess = getAppUserAccess(appUsers)
  const { displayName } = appUserAccess ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext
  const { gameScoringType } = sportsSeason_state ?? {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state } = sportsMatchContext ?? {}
  const { match_true } = sportsMatch_state ?? {}
  const { _resultsStatus } = match_true ?? {}
  const { resultResults, scoreType } = _resultsStatus ?? {}

  // sportsMatchContext
  const sportsEditMatchContext = useContext(SportsEditMatchContext)
  const { sportsEditMatch_state } = sportsEditMatchContext ?? {}
  const { match_edit } = sportsEditMatch_state ?? {}

  const [currentMatch, setCurrentMatch] = useState()
  const [matchResults, setMatchResults] = useState()

  const { home, away } = currentMatch ?? {}
  const { complete, score, scores, setNumber, setWinners, setsCompleted, inProgress } = matchResults ?? {}

  useEffect(() => {
    const _match = match_edit ? match_edit : match_true
    setCurrentMatch(_match)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match_edit, match_true]);

  useEffect(() => {
    if (resultResults) {
      setMatchResults(resultResults)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultResults]);


  let color;
  if (complete) { color = 'blue' }
  if (inProgress) { color = 'green' }

  const fullScoreDiv = (team, teamType) => {

    const { name, id: id_team } = team ?? {}

    const c = []
    let colz = 5
    let caption = 'S'
    let _score;
    let _scores;

    switch (gameScoringType) {
      case gameScoringTypes.match:
      case gameScoringTypes.matchPoints:
        colz = 5
        caption = 'S'
        _score = score
        _scores = scores
        break;
      case gameScoringTypes.half:
        colz = 2
        caption = 'F'
        _score = score
        _scores = score
        break;
      case gameScoringTypes.quarter:
        colz = 4
        caption = 'F'
        _score = score
        _scores = score
        break;
      default:
        colz = 5
        caption = 'S'
        _score = score
        _scores = scores
    }

    for (var col = -1; col <= colz; col++) {
      const x = col
      switch (col) {
        case -1: // team names
          c.push(<div key={uniqueKey('mst', 'tn', id_team)}>{name ? name : 'Team'}</div>)
          break;
        case 0: // set number
          if (team) {
            const teamScore = _score ? _score[teamType] : '-'
            c.push(<div key={uniqueKey('mst', 'ts', id_team, teamType)}>{teamScore}</div>)
          } else {
            c.push(<div key={uniqueKey('mst', 'ts', id_team, teamType)}>{caption}</div>)
          }
          break;
        default: //
          if (team) {
            if (_scores) {
              const setScore = _scores[col]
              if (setScore) {
                // score
                const sw = setWinners && setWinners[col] ? setWinners[col] : null
                let cnn;
                if (sw === teamType) { cnn = 'winner' }
                c.push(<div key={uniqueKey('mst', 'ts', id_team, teamType, col)} className={cnn}>{setScore[teamType]}</div>)
              } else {
                // no score
                c.push(<div ckey={uniqueKey('mst', 'ts', id_team, teamType, col)} className={(setNumber === col) ? 'selected' : ''}>{'-'}</div>)
              }
            } else {
              // no score
              c.push(<div key={uniqueKey('mst', 'ts', id_team, teamType, col)} className={(setNumber === col) ? 'selected' : ''}>{'-'}</div>)
            }
            break;
          } else {
            //  set number
            let scn = (setNumber === col) ? 'selected' : null
            if (scn && col <= setsCompleted) { scn += ' complete' }
            if (x <= setsCompleted + 1) { scn += ' avail' }
            c.push(<div key={uniqueKey('mst', 'sn', col)} className={scn} >{col}</div>)
          }
      }
    }
    return c
  }

  let cn = 'match-score-table ' + scoreType

  const fullMatchSeg = () => <Segment className={cn} color={color}  >
    <div key={uniqueKey('mst', 'scoring-owner')} className={'scoring-owner'}>{'Scorer: '}{displayName ? displayName : ''}</div>
    <div key={uniqueKey('mst', 'scoring-header')} className={'scoring-header'}>{fullScoreDiv()}</div>
    <div key={uniqueKey('mst', 'scoring-row-2')} className={'scoring-row-2'}>{fullScoreDiv(away, 'away')}</div>
    <div key={uniqueKey('mst', 'scoring-row-1')} className={'scoring-row-1'}>{fullScoreDiv(home, 'home')}</div>
  </Segment>

  return fullMatchSeg()

}

export default MatchScoreTable