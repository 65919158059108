import React, { useContext } from 'react';
import NonAuthPage from '../components/alerts/NonAuthPage';
import PendingAuth from '../components/alerts/pendings/PendingAuth';
import SignIn from '../components/auth/SignIn';
import { SignInTemp } from '../components/auth/SignInTemp';
import { gEnums } from '../enums/globalEnums';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import JsonViewer from '../components/viewers/JsonViewer';

/**
 * 
 * @param {*} props (view, appUser, loggedIn, pageAccess)
 * @returns SignIn, SignInTemp, NonAuthPage or PendingAuth
 */
const AppContentAlt = (props) => {

  const { view, appUser, loggedIn, pageAccess, fromDirect } = props
  const { allowPageView } = pageAccess ? pageAccess : {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state } = states
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  const notAllowedContent = () => {
    if (!loggedIn) {
      return <SignIn origin={'AppContentAlt'} fromDirect={fromDirect} />
    } else {
      switch (allowPageView) {
        case gEnums.viewPermissionTypes.allowTemp:
          return <SignInTemp />

        case gEnums.viewPermissionTypes.deny:
        case gEnums.viewPermissionTypes.deny_noClientKey:
        case gEnums.viewPermissionTypes.deny_noEventKey:
        case gEnums.viewPermissionTypes.allow_clientLimited:
          if (loggedIn) {
            switch (view) {
              // case 'home':
              //   return <AltPage type={altPageTypes.welcome} />
              default:
                if (appUser.profileValidated) {
                  return <NonAuthPage itemType={'page'} allowPageView={allowPageView} wrapWithAc={true} />
                } else {
                  return <PendingAuth />
                }
            }
          } else {
            return <SignIn origin={'AppContentAlt'} fromDirect={fromDirect} />
          }
        default:
          return <JsonViewer json={appUserAccess} />
        // nothing
      }
    }
  }

  return notAllowedContent()

}

export default AppContentAlt