export const iconColorTypes = {
  active: 'blue',
  admin: 'red',
  adminOnly: 'red',
  cancel: 'grey',
  confirmation: 'blue',
  css: 'green',
  schedule: 'green',
  default: 'grey',
  delete: 'red',
  deny: 'red',
  drill: 'green',
  external: 'orange',
  fix: 'yellow',
  google: 'green',
  googleData: 'blue',
  header: 'green',
  help: 'pink',
  highlight: 'green',
  info: 'blue',
  kill: 'black',
  menuItem: 'blue',
  normal: 'blue',
  notification: 'yellow',
  on: 'blue',
  passcode: 'code',
  pin: 'green',
  preview: 'purple',
  previewData: 'blue',
  propOff: 'grey',
  propOn: 'black',
  remove: 'red',
  restrict: 'red',
  restrictAdd: 'red',
  save: 'green',
  secret: 'orange',
  show: 'green',
  showCommit: 'blue',
  signOut: 'blue',
  success: 'green',
  tag: 'green',
  tagged: 'green',
  update: 'blue',
  updating: 'yellow',
  semBrown: '#a5673f',
  semBlack: '#1b1c1d',
  semBlue: '#2185d0',
  semGreen: '#21ba45',
  semPurple: '#a333c8',
  semOrange: '#f2711c',
  settingsLive: 'blue',
  settingsUser: 'blue',
  settingsCache: 'orange',
}

export const settingsIconTypes = {
  accessManagement: 'lock',
  actionItem: 'ellipsis vertical',
  actionItems: 'ellipsis vertical',
  actionPermissions: 'bolt',
  activate: 'bolt',
  activeTabLine: 'window minimize outline',
  add: 'add',
  adminOnly: 'lock',
  app: 'tv',
  appAccess: 'handshake outline',
  appAccessRequests: 'thumbs up outline',
  appActions: 'bolt',
  appBanner: 'flag outline',
  appBanners: 'flag outline',
  appConsole: 'exclamation triangle',
  appConversations: 'chat',
  appCredentialing: 'id badge outline',
  appData: 'database',
  appGallery: 'image outline',
  appManagement: 'cog',
  appManagers: 'user secret',
  appNotes: 'edit outline',
  appNotifications: 'alarm',
  appOptions: 'unordered list',
  appPermissions: 'thumbs up outline',
  appProfiles: 'user circle',
  appQuestionsAndAnswers: 'question circle',
  appSettings: 'globe',
  appSignIn: 'user secret',
  appStatus: 'tasks',
  appSurvey: 'list ol',
  appTicketing: 'ticket',
  transferData: 'cloud download',
  appUrls: 'list',
  appUser: 'user',
  appUserAboutMe: 'users',
  appUserBio: 'users',
  appUserFamily: 'users',
  appUserGallery: 'images outline',
  appUserConnections: 'linkify',
  appUserLinks: 'linkify',
  appUserManagement: 'users',
  appUserPagePermissions: 'key',
  appUserPrivacy: 'user secret',
  appUserProfileSettings: 'user',
  appUsers: 'users',
  appUserSettings: 'cog',
  appUserUiOptions: 'options',
  appVideoConferencing: 'video',
  appVoting: 'check circle outline',
  auth: 'lock',
  authentication: 'user doctor',
  background: 'square',
  banner: 'flag',
  banners: 'flag outline',
  baseSettings: 'eye',
  body: 'mobile alternate',
  bottomMenu: 'arrow circle down',
  bulkImageUpload: 'images outline',
  calendar: 'calendar alternate outline',
  client: 'handshake',
  clientData: 'database',
  clientManagement: 'home',
  clientProfiles: 'user circle',
  clients: 'building outline',
  clientsData: 'database',
  clientsManagement: 'home',
  combine: 'handshake',
  connections: 'linkify',
  console: 'grid layout',
  content: 'list ul',
  conversations: 'talk',
  conversationsAndNotifications: 'alarm',
  createClient: 'wizard',
  createEvent: 'wizard',
  createList: 'list ul',
  css: 'code',
  dashboard: 'dashboard ',
  data: 'database',
  dataCollections: 'list',
  dataConnection: 'code branch',
  dataConstraints: 'shield',
  dataFiltering: 'filter',
  dataFilters: 'filter',
  dataLinking: 'linkify',
  dataLinks: 'linkify',
  dataLists: 'clipboard list',
  dataManagement: 'database',
  dataModifications: 'bolt',
  dataNotifications: 'alarm',
  dataOptions: 'options',
  dataPermissions: 'privacy',
  dataPrivacy: 'hide',
  dataRestrictions: 'ban',
  dataSource: 'database',
  defaultTheme: 'setting',
  delete: 'delete',
  deleteClient: 'remove circle',
  deleteEvent: 'remove circle',
  dependencies: 'key',
  desktop: 'desktop',
  developerOptions: 'code',
  developerProjectOptions: 'options',
  display: 'tv',
  drill: 'arrow alternate circle down outline',
  drillDown: 'level down',
  edit: 'edit',
  event: 'flag',
  eventAuth: 'user secret',
  eventData: 'database',
  eventManagement: 'home',
  eventManagers: 'user secret',
  events: 'flag',
  external: 'external alternate',
  family: 'users',
  favorites: 'star outline',
  feeds: 'feed',
  fileManagement: 'file alternate outline',
  files: 'file outline',
  filter: 'filter',
  filters: 'filter',
  fix: 'wizard',
  formatting: 'font',
  gallery: 'images outline',
  general: 'cog',
  geoList: 'globe',
  geoLocation: 'globe',
  globalAppManagement: 'globe',
  globalNotifications: 'bell',
  globalPageItems: 'globe',
  globalAppSettings: 'cog',
  golfManagement: 'golf ball',
  googleApps: 'google',
  googleDashboard: 'google',
  googleOptions: 'google',
  googleRegistration: 'google',
  googleSheets: 'google',
  googleSheetsDataOptions: 'google',
  googleSheetsImportOptions: 'google',
  gridIcons: 'grid layout',
  gridSettingsIcons: 'grid layout',
  gridViewItemIcons: 'grid layout',
  group: 'object group',
  groupCaptions: 'language',
  grouping: 'object ungroup outline',
  groupItemCaptions: 'language',
  header: 'window maximize outline',
  home: 'home',
  homeData: 'database',
  homeManagement: 'home',
  homePage: 'home',
  imageLinks: 'image outline',
  imageMapping: 'map',
  imageOptions: 'image outline',
  images: 'image outline',
  itemActions: 'bolt',
  itemClick: 'mouse pointer',
  itemColor: 'adjust',
  itemLinking: 'add',
  items: 'file outline',
  json: 'js',
  layout: 'block layout',
  linking: 'paperclip',
  links: 'paperclip',
  listItems: 'list ul',
  locationSettings: 'map',
  locationMapping: 'map',
  logging: 'bug',
  management: 'home',
  manifest: 'unordered list',
  mappedCollections: 'list',
  maps: 'map',
  menu: 'sitemap',
  menus: 'sitemap',
  modifyList: 'list',
  navigation: 'arrow circle down',
  notes: 'write square',
  notifications: 'alarm',
  notificationSettings: 'alarm',
  notificationsManager: 'alarm',
  openAppDev: 'external alternate',
  openAppProduction: 'external alternate',
  openAuth: 'external alternate',
  openCloudConsole: 'external alternate',
  openCloudDataConsole: 'external alternate',
  openCloudStorageConsole: 'external alternate',
  openCredentials: 'external alternate',
  openDatabase: 'external alternate',
  openDatabaseSettings: 'external alternate',
  openFunctions: 'external alternate',
  openRealtimeDatabase: 'external alternate',
  openStorage: 'external alternate',
  openServiceAccount: 'external alternate',
  openServiceApiAccount: 'external alternate',
  page: 'folder open outline',
  pageDisplay: 'file outline',
  pageItemActions: 'bolt',
  pageItems: 'tags',
  pageManagement: 'folder open outline',
  pageNavigation: '',
  pageOptions: 'cog',
  pages: 'folder open outline',
  pageSettings: 'cog',
  pageSetup: 'cog',
  parentManagement: 'sitemap',
  pdf: 'file pdf outline',
  pdfs: 'file pdf outline',
  permissions: 'thumbs up outline',
  permissionSettings: 'thumbs up outline',
  pin: 'pin',
  productionSettings: 'settings',
  appUserProfileImage: 'picture',
  profileImage: 'picture',
  profiles: 'user circle',
  projectActions: 'bolt',
  projectManagement: 'globe',
  propItems: 'list',
  props: 'list ul',
  propSections: 'object group',
  qrCode: 'qrcode',
  qrCodes: 'qrcode',
  questionsAndAnswers: 'question',
  quickLinks: 'bookmark',
  rating: 'star outline',
  recreateEvent: 'wizard',
  registrationData: 'registered',
  registrationInfo: 'cog',
  remove: 'delete',
  reservations: 'hotel',
  restrictAdd: 'add',
  schedule: 'calendar alternate outline',
  search: 'search',
  secret: 'user secret',
  sectionBody: 'mobile alternate',
  sectionDisplay: 'tv',
  sectionHeader: 'text width',
  sectionPermissions: 'thumbs up outline',
  sections: 'boxes',
  settings: 'setting',
  settingsActions: 'bolt',
  settingsAndPermissions: 'users',
  settingsConsole: 'setting',
  settingsLayout: 'television',
  settingsManagement: 'certificate',
  settingsOptions: 'options',
  settingsPermissions: 'thumbs up outline',
  setup: 'hourglass start',
  show: 'check circle',
  sideMenu: 'sidebar',
  slider: 'sliders',
  sorting: 'sort amount down',
  splashScreen: 'mobile alternate',
  split: 'cut',
  star: 'star',
  status: 'toggle on',
  style: 'paint brush',
  styles: 'paint brush',
  styling: 'paint brush',
  stylingAndThemes: 'paint brush',
  subAction: 'bolt',
  subMenu: 'minus',
  survey: 'list ol',
  tabs: 'ellipsis horizontal',
  tagged: 'tag',
  themeColors: 'pencil square',
  themedItems: 'object ungroup',
  ticketing: 'ticket',
  tools: 'bell',
  topMenu: 'arrow circle up',
  tv: 'tv',
  twitter: 'twitter',
  ui: 'mobile alternate',
  uiElements: 'grid layout',
  uiElementStyle: 'tag',
  updateEvent: 'wizard',
  videoConferencing: 'video',
  videoLinks: 'video',
  view: 'file outline',
  viewActions: 'bolt',
  viewContent: 'list ul',
  viewItems: 'copy outline',
  viewLinks: 'linkify',
  views: 'folder open outline',
  voting: 'check circle outline',
  waiver: 'signup',
  zoomInfo: 'zoom',
  zoneMapping: 'map',
}