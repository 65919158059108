export const defaultSettings = {
  "global": {
    "manifest": {
      "colors": {
        "color": "color2",
        "backgroundColor": "color1",
        "size": "normal",
        "apply": true
      }
    },
    "uiSections": {
      "header": {
        "apply": true,
        "fontWeight": "bold",
        "size": "large"
      },
      "sectionBody": {
        "captionWidth": 5,
        "bodyDisplayType": "table",
        "apply": true
      },
      "sectionHeader": {
        "apply": true,
        "headerDisplayType": "blockAttached"
      }
    },
    "themedItems": {
      "appBanner": {
        "colors": {
          "color": "color2",
          "size": "normal",
          "backgroundColor": "color1",
          "apply": true
        }
      },
      "tabs": {
        "colors": {
          "apply": true,
          "color": "color2",
          "size": "normal",
          "backgroundColor": "color1"
        }
      },
      "background": {
        "colors": {
          "size": "normal",
          "color": "color2",
          "backgroundColor": "color1"
        }
      },
      "activeTabLine": {
        "colors": {
          "apply": true,
          "backgroundColor": "color3",
          "color": "color2",
          "size": "normal"
        }
      },
      "subMenus": {
        "colors": {
          "apply": true,
          "size": "normal",
          "backgroundColor": "color1",
          "color": "color2"
        }
      },
      "defaultTheme": {
        "colors": {
          "color": "color2",
          "backgroundColor": "color1",
          "size": "normal",
          "apply": true
        }
      },
      "topMenu": {
        "colors": {
          "apply": true,
          "backgroundColor": "color1",
          "size": "normal",
          "color": "color2"
        }
      },
      "gridIcons": {
        "colors": {
          "backgroundColor": "color1",
          "size": "normal",
          "apply": true,
          "color": "color2"
        }
      },
      "splashScreen": {
        "colors": {
          "size": "normal",
          "apply": true,
          "backgroundColor": "color1",
          "color": "color2"
        }
      },
      "sideMenu": {
        "colors": {
          "backgroundColor": "color1",
          "apply": true,
          "color": "color2",
          "size": "normal"
        }
      },
      "filters": {
        "colors": {
          "backgroundColor": "color1",
          "apply": true,
          "size": "normal",
          "color": "color2"
        }
      },
      "navigation": {
        "colors": {
          "size": "normal",
          "backgroundColor": "color1",
          "color": "color2",
          "apply": true
        }
      }
    },
    "topMenu": {
      "titleCharacterMax": 28,
      "menuLocation": "top",
      "mainFixedCaption": "Clients",
      "mainCaptionSource": "fixed"
    },
    "settingsOptions": {
      "useDarkMode": true,
      "allowCopySettings": true,
      "allowResetSettings": true,
      "sortGroupsByPermission": true
    },
    "formatting": {
      "dateMenuFormat": "shorterDate",
      "timeFormat": "ampm",
      "dateFormat": "shortDate"
    },
    "navigation": {
      "includeSlideout": true,
      "includeHome": true,
      "showBottomMenus": true
    },
    "themeColors": {
      "color2": "#ffffff",
      "color3": "#bbff6b",
      "color1": "#5299d2"
    },
    "appUser": {
      "appUserCollection": "attendees"
    },
    "googleSheetsImportOptions": {
      "allowSingleColumnArrays": true,
      "singleColumnArraySeparator": ";",
      "allowMultiColumnArrays": true,
      "convertIdsToFirebaseKeys": true
    },
    "sideMenu": {
      "sideMenuItems": [
        "clients"
      ]
    }
  },
  "viewItems": {
    "clients": {
      "propSections": {
        "details": {
          "position": 1,
          "show": true,
          "sectionBody": {
            "bodyDisplayType": "table"
          },
          "sectionHeader": {
            "headerDisplayType": "blockAttached",
            "caption": "Details"
          },
          "showDesktop": true
        },
        "name": {
          "sectionHeader": {
            "caption": "Name",
            "headerDisplayType": "blockAttached"
          },
          "show": true,
          "showDesktop": true,
          "position": 0
        }
      },
      "props": {
        "createDate": {
          "propSection": "details",
          "key": "createDate",
          "display": {
            "elemPropType": "normal",
            "caption": "Create Date"
          },
          "show": true,
          "showDesktop": true,
          "position": 0,
          "data": {
            "formItemType": "text"
          },
          "allowProp": "allow"
        },
        "published": {
          "allowProp": "allow",
          "display": {
            "elemPropType": "normal",
            "caption": "Published"
          },
          "key": "published",
          "propSection": "details",
          "showDesktop": true,
          "position": 0,
          "data": {
            "formItemType": "text"
          },
          "show": true
        },
        "startTimeUTC": {
          "propSection": "details",
          "show": false,
          "key": "startTimeUTC",
          "display": {
            "caption": "Start Time UTC",
            "elemPropType": "normal"
          },
          "data": {
            "formItemType": "text"
          },
          "position": 0,
          "allowProp": "allow",
          "showDesktop": true
        },
        "description": {
          "show": true,
          "display": {
            "elemPropType": "normal"
          },
          "position": 3,
          "data": {
            "formItemType": "text"
          },
          "propSection": "details",
          "prop": "description",
          "showDesktop": true
        },
        "clientId": {
          "allowProp": "allow",
          "show": true,
          "display": {
            "elemPropType": "normal",
            "caption": "Client Id"
          },
          "data": {
            "formItemType": "text"
          },
          "propSection": "details",
          "showDesktop": true,
          "position": 0,
          "key": "clientId"
        },
        "eventStatusType": {
          "showDesktop": true,
          "key": "eventStatusType",
          "display": {
            "caption": "Event Status Type",
            "elemPropType": "normal"
          },
          "data": {
            "formItemType": "text"
          },
          "position": 0,
          "show": true,
          "allowProp": "allow",
          "propSection": "details"
        },
        "key": {
          "display": {
            "elemPropType": "normal",
            "caption": "Key"
          },
          "show": true,
          "data": {
            "formItemType": "text"
          },
          "allowProp": "allow",
          "showDesktop": true,
          "propSection": "details",
          "position": 0,
          "key": "key"
        },
        "googleSheetsId": {
          "showDesktop": true,
          "display": {
            "elemPropType": "normal",
            "caption": "Google Sheets Id"
          },
          "show": true,
          "position": 0,
          "key": "googleSheetsId",
          "allowProp": "allow",
          "propSection": "details",
          "data": {
            "formItemType": "text"
          }
        },
        "clientMobileKey": {
          "key": "clientMobileKey",
          "showDesktop": true,
          "propSection": "details",
          "display": {
            "caption": "Client Mobile Key",
            "elemPropType": "normal"
          },
          "position": 0,
          "data": {
            "formItemType": "text"
          },
          "show": true
        },
        "startDate": {
          "data": {
            "formItemType": "text"
          },
          "key": "startDate",
          "display": {
            "caption": "Start Date",
            "elemPropType": "normal"
          },
          "allowProp": "allow",
          "show": true,
          "position": 0,
          "propSection": "details",
          "showDesktop": true
        },
        "endDate": {
          "propSection": "details",
          "display": {
            "elemPropType": "normal",
            "caption": "End Date"
          },
          "position": 0,
          "showDesktop": true,
          "allowProp": "allow",
          "data": {
            "formItemType": "text"
          },
          "show": true,
          "key": "endDate"
        },
        "_itemKey": {
          "position": 0,
          "key": "_itemKey",
          "propSection": "details",
          "display": {
            "caption": "Item Key",
            "elemPropType": "normal"
          },
          "show": true,
          "data": {
            "formItemType": "text"
          },
          "showDesktop": true,
          "allowProp": "allow"
        },
        "shortDescription": {
          "propSection": "details",
          "position": 0,
          "display": {
            "elemPropType": "normal",
            "caption": "Short Description"
          },
          "key": "shortDescription",
          "data": {
            "formItemType": "text"
          },
          "showDesktop": true,
          "show": true,
          "allowProp": "allow"
        },
        "fontColor": {
          "data": {
            "formItemType": "text"
          },
          "key": "fontColor",
          "showDesktop": true,
          "display": {
            "caption": "Font Color",
            "elemPropType": "normal"
          },
          "position": 0,
          "propSection": "details",
          "allowProp": "allow",
          "show": true
        },
        "name": {
          "show": true,
          "showDesktop": true,
          "display": {
            "elemPropType": "normal"
          },
          "data": {
            "formItemType": "text"
          },
          "prop": "name",
          "propSection": "name",
          "position": 2
        },
        "location": {
          "allowProp": "allow",
          "data": {
            "formItemType": "text"
          },
          "display": {
            "elemPropType": "normal",
            "caption": "Location"
          },
          "propSection": "details",
          "position": 0,
          "key": "location",
          "show": true,
          "showDesktop": true
        },
        "clientType": {
          "position": 0,
          "data": {
            "formItemType": "text"
          },
          "allowProp": "allow",
          "display": {
            "elemPropType": "normal",
            "caption": "Client Type"
          },
          "show": true,
          "showDesktop": true,
          "propSection": "details",
          "key": "clientType"
        }
      },
      "display": {
        "caption": "Clients",
        "displayType": "card",
        "defaultIcon": "building outline",
        "cardDisplayType": "normal",
        "iconsPerRow": 3,
        "layoutType": "swipeable"
      },
      "key": "clients",
      "showDesktop": true,
      "show": true,
      "desktopDisplay": {
        "displayType": "card",
        "defaultIcon": "building outline",
        "caption": "Clients"
      }
    }
  },
  "views": {
    "clients": {
      "show": true,
      "layout": {
        "layoutType": "swipeable"
      },
      "showDesktop": true,
      "desktopLayout": {
        "layoutType": "singlePage"
      },
      "menu": {
        "caption": "Clients",
        "captionPage": "clients",
        "captionType": "fromData",
        "captionProps": [
          "name"
        ]
      },
      "viewItems": {
        "clients": {
          "pageItemData": {},
          "showDesktop": true,
          "desktopDisplay": {
            "displayType": "card",
            "caption": "About"
          },
          "propSections": {
            "details": {
              "position": 1,
              "sectionBody": {
                "bodyDisplayType": "table"
              },
              "sectionHeader": {
                "headerDisplayType": "blockAttached",
                "caption": "Details"
              },
              "show": true,
              "showDesktop": true
            },
            "name": {
              "showDesktop": true,
              "sectionHeader": {
                "headerDisplayType": "blockAttached",
                "caption": "Name"
              },
              "position": 0,
              "show": true
            }
          },
          "show": true,
          "display": {
            "caption": "About",
            "displayType": "card",
            "cardDisplayType": "normal"
          },
          "props": {
            "description": {
              "show": true,
              "propSection": "details",
              "prop": "description",
              "key": "description",
              "data": {
                "regKey": "description",
                "formItemType": "text"
              },
              "display": {
                "caption": "Description",
                "elemPropType": "normal"
              },
              "position": 3,
              "showDesktop": true
            },
            "clientMobileKey": {
              "key": "clientMobileKey",
              "data": {
                "formItemType": "text"
              },
              "propSection": "details",
              "position": 0,
              "show": true,
              "display": {
                "caption": "Client Mobile Key",
                "elemPropType": "normal"
              },
              "showDesktop": true
            },
            "eventStatusType": {
              "showDesktop": true,
              "data": {
                "formItemType": "text"
              },
              "propSection": "details",
              "key": "eventStatusType",
              "position": 0,
              "display": {
                "caption": "Event Status Type",
                "elemPropType": "normal"
              },
              "allowProp": "allow",
              "show": true
            },
            "clientId": {
              "allowProp": "allow",
              "show": true,
              "data": {
                "formItemType": "text"
              },
              "display": {
                "caption": "Client Id",
                "elemPropType": "normal"
              },
              "propSection": "details",
              "position": 0,
              "showDesktop": true,
              "key": "clientId"
            },
            "phone": {
              "display": {
                "caption": "Phone",
                "elemPropType": "normal"
              },
              "show": true,
              "key": "phone",
              "position": 1,
              "propSection": "details",
              "prop": "phone",
              "data": {
                "regKey": "phone",
                "formItemType": "text"
              },
              "showDesktop": true
            },
            "location": {
              "position": 0,
              "propSection": "details",
              "allowProp": "allow",
              "show": true,
              "display": {
                "elemPropType": "normal",
                "caption": "Location"
              },
              "showDesktop": true,
              "data": {
                "formItemType": "text"
              },
              "key": "location"
            },
            "startTimeUTC": {
              "propSection": "details",
              "key": "startTimeUTC",
              "position": 0,
              "display": {
                "elemPropType": "normal",
                "caption": "Start Time UTC"
              },
              "show": false,
              "allowProp": "allow",
              "showDesktop": true,
              "data": {
                "formItemType": "text"
              }
            },
            "address": {
              "show": true,
              "position": 5,
              "propSection": "details",
              "display": {
                "caption": "Address",
                "elemPropType": "normal"
              },
              "data": {
                "regKey": "address",
                "formItemType": "text"
              },
              "prop": "address",
              "key": "address",
              "showDesktop": true
            },
            "shortDescription": {
              "allowProp": "allow",
              "key": "shortDescription",
              "display": {
                "elemPropType": "normal",
                "caption": "Short Description"
              },
              "position": 0,
              "propSection": "details",
              "show": true,
              "showDesktop": true,
              "data": {
                "formItemType": "text"
              }
            },
            "createDate": {
              "display": {
                "caption": "Create Date",
                "elemPropType": "normal"
              },
              "key": "createDate",
              "showDesktop": true,
              "position": 0,
              "propSection": "details",
              "data": {
                "formItemType": "text"
              },
              "show": true,
              "allowProp": "allow"
            },
            "name": {
              "data": {
                "regKey": "name",
                "formItemType": "text"
              },
              "display": {
                "caption": "Name",
                "elemPropType": "normal"
              },
              "tagged": true,
              "position": 2,
              "propSection": "name",
              "showDesktop": true,
              "key": "name",
              "show": true,
              "prop": "name"
            },
            "published": {
              "propSection": "details",
              "key": "published",
              "showDesktop": true,
              "data": {
                "formItemType": "text"
              },
              "show": true,
              "display": {
                "elemPropType": "normal",
                "caption": "Published"
              },
              "allowProp": "allow",
              "position": 0
            },
            "_itemKey": {
              "showDesktop": true,
              "key": "_itemKey",
              "show": true,
              "position": 0,
              "data": {
                "formItemType": "text"
              },
              "display": {
                "caption": "Item Key",
                "elemPropType": "normal"
              },
              "propSection": "details",
              "allowProp": "allow"
            },
            "startDate": {
              "data": {
                "formItemType": "text"
              },
              "allowProp": "allow",
              "showDesktop": true,
              "position": 0,
              "show": true,
              "propSection": "details",
              "display": {
                "caption": "Start Date",
                "elemPropType": "normal"
              },
              "key": "startDate"
            },
            "clientType": {
              "show": true,
              "showDesktop": true,
              "data": {
                "formItemType": "text"
              },
              "position": 0,
              "propSection": "details",
              "display": {
                "elemPropType": "normal",
                "caption": "Client Type"
              },
              "allowProp": "allow",
              "key": "clientType"
            },
            "googleSheetsId": {
              "key": "googleSheetsId",
              "position": 0,
              "display": {
                "elemPropType": "normal",
                "caption": "Google Sheets Id"
              },
              "show": true,
              "showDesktop": true,
              "propSection": "details",
              "allowProp": "allow",
              "data": {
                "formItemType": "text"
              }
            },
            "website": {
              "display": {
                "elemPropType": "normal",
                "caption": "Website"
              },
              "data": {
                "formItemType": "text",
                "regKey": "website"
              },
              "propSection": "details",
              "showDesktop": true,
              "prop": "website",
              "position": 4,
              "key": "website",
              "show": true
            },
            "fontColor": {
              "data": {
                "formItemType": "text"
              },
              "propSection": "details",
              "key": "fontColor",
              "show": true,
              "showDesktop": true,
              "allowProp": "allow",
              "position": 0,
              "display": {
                "caption": "Font Color",
                "elemPropType": "normal"
              }
            },
            "endDate": {
              "allowProp": "allow",
              "showDesktop": true,
              "key": "endDate",
              "data": {
                "formItemType": "text"
              },
              "propSection": "details",
              "show": true,
              "display": {
                "elemPropType": "normal",
                "caption": "End Date"
              },
              "position": 0
            },
            "key": {
              "show": true,
              "showDesktop": true,
              "key": "key",
              "allowProp": "allow",
              "data": {
                "formItemType": "text"
              },
              "display": {
                "caption": "Key",
                "elemPropType": "normal"
              },
              "position": 0,
              "propSection": "details"
            }
          },
          "position": 0,
          "dataSource": {
            "dataSourceType": "firebase",
            "dataCollectionName": "clients"
          }
        }
      }
    },
    "clients_list": {
      "desktopLayout": {
        "layoutType": "singlePage"
      },
      "viewItems": {
        "clients": {
          "key": "clients",
          "_viewPermissionType": "allow",
          "props": {
            "clientType": {
              "key": "clientType",
              "display": {
                "caption": "Client Type",
                "elemPropType": "normal"
              },
              "allowProp": "allow",
              "propSection": "details",
              "position": 0,
              "show": true,
              "data": {
                "formItemType": "text"
              },
              "showDesktop": true
            },
            "shortDescription": {
              "data": {
                "formItemType": "text"
              },
              "propSection": "details",
              "position": 0,
              "showDesktop": true,
              "key": "shortDescription",
              "show": true,
              "allowProp": "allow",
              "display": {
                "elemPropType": "normal",
                "caption": "Short Description"
              }
            },
            "location": {
              "allowProp": "allow",
              "showDesktop": true,
              "display": {
                "elemPropType": "normal",
                "caption": "Location"
              },
              "data": {
                "formItemType": "text"
              },
              "show": true,
              "key": "location",
              "propSection": "details",
              "position": 0
            },
            "startTimeUTC": {
              "key": "startTimeUTC",
              "allowProp": "allow",
              "show": false,
              "propSection": "details",
              "position": 0,
              "display": {
                "caption": "Start Time UTC",
                "elemPropType": "normal"
              },
              "data": {
                "formItemType": "text"
              },
              "showDesktop": true
            },
            "address": {
              "data": {
                "formItemType": "text",
                "regKey": "address"
              },
              "propSection": "details",
              "allowProp": "allow",
              "prop": "address",
              "_allowProp": "allow",
              "show": true,
              "position": 5,
              "display": {
                "caption": "Address",
                "elemPropType": "normal"
              },
              "key": "address",
              "showDesktop": true
            },
            "_itemKey": {
              "data": {
                "formItemType": "text"
              },
              "allowProp": "allow",
              "key": "_itemKey",
              "display": {
                "caption": "Item Key",
                "elemPropType": "normal"
              },
              "showDesktop": true,
              "show": true,
              "position": 0,
              "propSection": "details"
            },
            "endDate": {
              "data": {
                "formItemType": "text"
              },
              "allowProp": "allow",
              "showDesktop": true,
              "propSection": "details",
              "position": 0,
              "display": {
                "elemPropType": "normal",
                "caption": "End Date"
              },
              "show": true,
              "key": "endDate"
            },
            "clientMobileKey": {
              "display": {
                "caption": "Client Mobile Key",
                "elemPropType": "normal"
              },
              "data": {
                "formItemType": "text"
              },
              "key": "clientMobileKey",
              "position": 0,
              "showDesktop": true,
              "propSection": "details",
              "show": true
            },
            "published": {
              "propSection": "details",
              "showDesktop": true,
              "display": {
                "elemPropType": "normal",
                "caption": "Published"
              },
              "data": {
                "formItemType": "text"
              },
              "allowProp": "allow",
              "key": "published",
              "show": true,
              "position": 0
            },
            "website": {
              "position": 4,
              "show": true,
              "propSection": "details",
              "data": {
                "formItemType": "text",
                "regKey": "website"
              },
              "allowProp": "allow",
              "prop": "website",
              "showDesktop": true,
              "display": {
                "elemPropType": "normal",
                "caption": "Website"
              },
              "_allowProp": "allow",
              "key": "website"
            },
            "name": {
              "propSection": "name",
              "display": {
                "elemPropType": "normal",
                "caption": "Name"
              },
              "position": 2,
              "tagged": true,
              "prop": "name",
              "key": "name",
              "allowProp": "allow",
              "show": true,
              "_allowProp": "allow",
              "showDesktop": true,
              "data": {
                "formItemType": "text",
                "regKey": "name"
              }
            },
            "clientId": {
              "data": {
                "formItemType": "text"
              },
              "key": "clientId",
              "display": {
                "elemPropType": "normal",
                "caption": "Client Id"
              },
              "allowProp": "allow",
              "propSection": "details",
              "showDesktop": true,
              "show": true,
              "position": 0
            },
            "key": {
              "key": "key",
              "data": {
                "formItemType": "text"
              },
              "display": {
                "caption": "Key",
                "elemPropType": "normal"
              },
              "showDesktop": true,
              "position": 0,
              "show": true,
              "allowProp": "allow",
              "propSection": "details"
            },
            "startDate": {
              "show": true,
              "allowProp": "allow",
              "data": {
                "formItemType": "text"
              },
              "key": "startDate",
              "showDesktop": true,
              "position": 0,
              "propSection": "details",
              "display": {
                "caption": "Start Date",
                "elemPropType": "normal"
              }
            },
            "description": {
              "position": 3,
              "display": {
                "caption": "Description",
                "elemPropType": "normal"
              },
              "key": "description",
              "propSection": "details",
              "show": true,
              "prop": "description",
              "allowProp": "allow",
              "data": {
                "formItemType": "text",
                "regKey": "description"
              },
              "_allowProp": "allow",
              "showDesktop": true
            },
            "fontColor": {
              "position": 0,
              "show": true,
              "data": {
                "formItemType": "text"
              },
              "propSection": "details",
              "key": "fontColor",
              "showDesktop": true,
              "display": {
                "caption": "Font Color",
                "elemPropType": "normal"
              },
              "allowProp": "allow"
            },
            "googleSheetsId": {
              "display": {
                "elemPropType": "normal",
                "caption": "Google Sheets Id"
              },
              "key": "googleSheetsId",
              "position": 0,
              "data": {
                "formItemType": "text"
              },
              "propSection": "details",
              "show": true,
              "allowProp": "allow",
              "showDesktop": true
            },
            "phone": {
              "display": {
                "caption": "Phone",
                "elemPropType": "normal"
              },
              "prop": "phone",
              "showDesktop": true,
              "propSection": "details",
              "data": {
                "regKey": "phone",
                "formItemType": "text"
              },
              "position": 1,
              "show": true,
              "allowProp": "allow",
              "_allowProp": "allow",
              "key": "phone"
            },
            "eventStatusType": {
              "propSection": "details",
              "key": "eventStatusType",
              "show": true,
              "position": 0,
              "showDesktop": true,
              "display": {
                "elemPropType": "normal",
                "caption": "Event Status Type"
              },
              "allowProp": "allow",
              "data": {
                "formItemType": "text"
              }
            },
            "createDate": {
              "data": {
                "formItemType": "text"
              },
              "showDesktop": true,
              "propSection": "details",
              "display": {
                "caption": "Create Date",
                "elemPropType": "normal"
              },
              "position": 0,
              "allowProp": "allow",
              "show": true,
              "key": "createDate"
            }
          },
          "propSections": {
            "name": {
              "show": true,
              "sectionHeader": {
                "caption": "Name",
                "headerDisplayType": "blockAttached"
              },
              "position": 0,
              "showDesktop": true,
              "key": "name"
            },
            "details": {
              "sectionHeader": {
                "caption": "Details",
                "headerDisplayType": "blockAttached"
              },
              "showDesktop": true,
              "show": true,
              "key": "details",
              "position": 1,
              "sectionBody": {
                "bodyDisplayType": "table"
              }
            }
          },
          "dataSource": {
            "dataSourceType": "firebase",
            "dataCollectionName": "clients"
          },
          "showDesktop": true,
          "position": 0,
          "desktopDisplay": {
            "displayType": "card",
            "caption": "Clients"
          },
          "show": true,
          "pageItemData": {},
          "display": {
            "cardsPerRow": 2,
            "displayType": "card",
            "caption": "Clients",
            "cardDisplayType": "nameSectionOnly"
          }
        }
      },
      "showDesktop": true,
      "layout": {
        "layoutType": "swipeable"
      },
      "show": true,
      "menu": {
        "caption": "Clients",
        "captionType": "fixed"
      }
    },
    "home_list": {
      "menu": {
        "captionType": "fixed",
        "caption": "Home"
      },
      "viewItems": {
        "clients": {
          "dataSource": {
            "dataSourceType": "firebase",
            "dataCollectionName": "clients"
          },
          "showDesktop": true,
          "key": "clients",
          "propSections": {
            "details": {
              "position": 1,
              "showDesktop": true,
              "show": true,
              "sectionHeader": {
                "headerDisplayType": "blockAttached",
                "caption": "Details"
              }
            },
            "name": {
              "sectionHeader": {
                "headerDisplayType": "blockAttached",
                "caption": "Name"
              },
              "position": 0,
              "showDesktop": true,
              "show": true
            }
          },
          "_viewPermissionType": "allow",
          "show": true,
          "props": {
            "name": {
              "data": {
                "formItemType": "text"
              },
              "prop": "name",
              "show": true,
              "display": {
                "elemPropType": "normal"
              },
              "showDesktop": true,
              "position": 2
            },
            "address": {
              "showDesktop": true,
              "display": {
                "elemPropType": "normal"
              },
              "show": true,
              "position": 5,
              "data": {
                "formItemType": "text"
              },
              "prop": "address"
            },
            "phone": {
              "showDesktop": true,
              "data": {
                "formItemType": "text"
              },
              "prop": "phone",
              "display": {
                "elemPropType": "normal"
              },
              "position": 1,
              "show": true
            },
            "description": {
              "display": {
                "elemPropType": "normal"
              },
              "position": 3,
              "data": {
                "formItemType": "text"
              },
              "showDesktop": true,
              "prop": "description",
              "show": true
            },
            "website": {
              "show": true,
              "data": {
                "formItemType": "text"
              },
              "prop": "website",
              "position": 4,
              "display": {
                "elemPropType": "normal"
              },
              "showDesktop": true
            }
          },
          "desktopDisplay": {
            "caption": "Clients",
            "displayType": "card",
            "defaultIcon": "building outline"
          },
          "display": {
            "layoutType": "swipeable",
            "defaultIcon": "building outline",
            "displayType": "card",
            "caption": "Clients",
            "iconsPerRow": 3,
            "cardDisplayType": "nameSectionOnly"
          }
        }
      },
      "desktopLayout": {
        "layoutType": "icons"
      },
      "layout": {
        "layoutType": "icons"
      }
    }
  }
}
