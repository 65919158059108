import React from 'react'
import { gridIconElements } from './gridItems/SegIconGroup'
import { uniqueKey } from '../../common/keys'
import { Grid } from 'semantic-ui-react'
import DragDropper, { dragDropperTypes } from '../../dragNDrops/DragDropper'

const GridItemsSort = (props) => {

  const { gridItemGroup, gridItems, defaultSnC, backgroundSaC, iconsPerRow } = props ?? {}

  const handleSorted = (sortedItems) => {
    console.log('sortedItems', sortedItems)
  }

  const gridContainer = (contents) => {
    return <div key={uniqueKey('iggc')} {...backgroundSaC}>
      <div className={'grid-icons-container'} >
        <div className={defaultSnC.className}>
          {contents}
        </div>
      </div>
    </div>
  }


  const getRowElements = (gridRow) => gridRow.map(gridItem => <Grid.Column>{gridItem.element}</Grid.Column>)

  const dndGroups = {}

  const elements = getRowElements(gridItems)
  dndGroups['gridElements'] = { elements, dataItems: gridItemGroup, horizontal: true }

  const dd = <DragDropper
    allowSort={true}
    dndGroups={dndGroups}
    dndType={dragDropperTypes.simple}
    horizontal={true}
    handleSorted={handleSorted}
    portalCn={'column fx'}
    wrapperCns={'ui grid three column'}
  />

  const content = gridContainer(dd)

  return content
}

export default GridItemsSort
