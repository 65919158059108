// import { createAppUserData } from '../../cnr/reducers/AppUserReducer';
import { ammendAppUserData, getAppUserProfile } from './getProfile';

export const getClonedAppUser = (database_fns, clonedUser, paps_state, callback, eventAppUsers, aps_appUserSettings) => {

  if (clonedUser) {

    const creds = { userName: clonedUser.email }
    const { pathViews } = paps_state ?? {}

    const _callback = (appUserInfo) => {
      // const appUser2 = createAppUserData(clonedUser, appUserInfo, null, null, pathViews)
      const { appUser, appUserAccess } = ammendAppUserData(clonedUser, appUserInfo, null, null, aps_appUserSettings, pathViews)
      callback(appUser, appUserAccess)
    }

    getAppUserProfile(database_fns, creds, pathViews, aps_appUserSettings, null, null, _callback, eventAppUsers)

  } else {

    callback({ noAuth: true })
    return {}
  }
}