import _ from 'lodash';
import { gEnums } from '../enums/globalEnums';
import { countries } from '../genericData/countries';
import { eventOptionsList } from './eventOptions';
import { usaStates } from '../genericData/states';

/** Creates a list of (key, value, text) items */
export const createMenuList = (list) => {

  const items = []

  if (_.isArray(list)) {
    list.forEach(key => {
      items.push({
        name: _.startCase(key),
        key: key,
      })
    })
  } else {
    Object.keys(list).forEach(key => {
      if (list[key].caption) {
        items.push({
          name: list[key].value,
          key: list[key].key,
        })
      } else {
        items.push({
          name: _.startCase(key),
          key: key,
        })
      }
    })
  }
  return items
}

export const createNameItemList = (listItems) => {
  const items = []
  if (_.isArray(listItems)) {
    listItems.forEach(item => {
      items.push({
        text: item.name,
        value: item.id,
        key: item.id,
      })
    })
  } else {
    Object.keys(listItems).forEach(key => {
      if (listItems[key].name) {
        items.push({
          text: listItems[key].name,
          value: listItems[key].id,
          key: listItems[key].id,
        })
      }
    })
  }
  return items
}

// viewProps are the formProps

export const getAllOptionsList = (listType, opts) => {
  return AllOptions[listType](opts)
}

const AllOptions = {

  toOptions: (options, prop) => {
    const opts = []
    if (options) {
      Object.keys(options).forEach(key => {
        opts.push({ key: key, value: key, text: options[key][prop] })
      })
    }
    return opts
  },

  listOptions: (options) => {
    const opts = []
    let lopts = options
    if (lopts) {
      opts.push({ key: 'none', value: null, text: 'none' })
      Object.keys(lopts).forEach(key => {
        const option = options[key]
        const caption = option && option.caption ? option.caption : _.startCase(key)
        const value = option && option.value ? option.value : key
        const optKey = option && option.key ? option.key : key
        if (value && optKey) {
          opts.push({ key: optKey, value: value, text: caption })
        } else {
          opts.push({ key: key, value: caption, text: option.caption })
        }
      })
    }
    return opts
  },

  stateOptions: (options) => {
    const opts = []
    Object.keys(options).forEach(key => {
      opts.push({ key: key, value: key, text: options[key] })
    })
    return opts
  },

  countryOptions: (options) => {
    const opts = []
    options.forEach(country => {
      opts.push({ key: country.code, value: country.code, text: country.name })
    })
    return opts
  },

  getHeight: (h) => {
    const feet = Math.floor(h / 12)
    const inches = h - (feet * 12)
    return feet + "'" + inches + '"'
  },

  numberOptions: (options) => {
    let { start, end, optionType, step, noNone } = options
    const opts = []
    if (optionType === 'rankings') {
      opts.push({ key: 'preseason', value: 'preSeason', text: 'preSeason' })
    }
    if (!start) { start = 1 }
    if (!end) { end = 10 }
    for (var i = start; i <= end; i++) {
      switch (optionType) {
        case 'height':
          const f = AllOptions.getHeight(i)
          opts.push({ key: f, value: f, text: f })
          break;
        default:
          if (step) {
            const v = i * parseInt(step)
            opts.push({ key: v, value: v, text: v })
          } else {
            opts.push({ key: i, value: i, text: i })
          }
      }
    }
    if (!noNone) { opts.unshift({ key: 'none', value: null, text: 'none' }) }
    return opts
  },

  decimalOptions: (options) => {
    let { start, end } = options
    const opts = []
    if (!start) { start = 0 }
    if (!end) { end = 4 }
    for (var i = start; i <= end; i += 0.1) {
      let v = i.toFixed(1)
      opts.push({ key: v, value: v, text: v })
    }
    opts.push({ key: end.toFixed(1), value: end.toFixed(1), text: end.toFixed(1) })
    opts.unshift({ key: 'none', value: null, text: 'none' })
    return opts
  },

  fontOptions: (options) => {
    let { start, end } = options
    const opts = []
    if (!start) { start = 0 }
    if (!end) { end = 4 }
    for (var i = start; i <= end; i += 100) {
      opts.push({ key: i, value: i, text: i })
    }
    opts.unshift({ key: 'none', value: null, text: 'none' })
    return opts
  },

  timeOptions: () => {
    const opts = []
    var hours, minutes, ampm;
    const start = 0
    const end = 1425
    for (var i = start; i <= end; i += 15) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = '0' + minutes; // adding leading zero
      }
      ampm = hours % 24 < 12 ? 'AM' : 'PM';
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      const time = hours + ':' + minutes + ' ' + ampm
      opts.push({ key: time, value: time, text: time })
    }
    return opts
  },

  timeOptions24: () => {
    const opts = []
    var hours, minutes;
    const start = 0
    const end = 1425
    for (var i = start; i <= end; i += 15) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (hours < 10) {
        hours = '0' + hours; // adding leading zero
      }
      if (minutes < 10) {
        minutes = '0' + minutes; // adding leading zero
      }
      const time = hours + ':' + minutes + ':00'
      opts.push({ key: time, value: time, text: time })
    }
    return opts
  },

  selectedOptions: (prop) => {
    const opts = []
    prop.selectedOptions.forEach(key => {
      opts.push({ key: key, value: key, text: key })
    })
    return opts
  },

  options: (prop, list) => {
    if (prop.options) {
      switch (prop.options) {
        case gEnums.listOptions.time:
          return AllOptions.timeOptions()
        case gEnums.listOptions.time24:
          return AllOptions.timeOptions24()
        case gEnums.listOptions.team:
          return AllOptions.teamOptions(list)
        case gEnums.listOptions.number:
          return AllOptions.numberOptions(prop)
        case gEnums.listOptions.decimal:
          return AllOptions.decimalOptions(prop)
        case gEnums.listOptions.font:
          return AllOptions.fontOptions(prop)
        case gEnums.listOptions.usaStates:
          return AllOptions.stateOptions(usaStates)
        case gEnums.listOptions.countries:
          return AllOptions.countryOptions(countries)
        case gEnums.listOptions.list:
          if (prop.list) {
            return AllOptions.listOptions(prop.list)
          } else if (prop.listType) {
            return eventOptionsList(prop.listType)
          }
          break;
        default:
        // nothing
      }
    } else if (prop.selectedOptions) {
      return AllOptions.selectedOptions(prop)
    }
  },

  getAll: (itemProps, lists) => {
    Object.keys(itemProps).forEach(key => {
      const itemProp = itemProps[key]
      if (itemProp && itemProp.data) {
        if (itemProp.data.formItemType) {
          if (itemProp.data.list) {
            lists[key] = AllOptions.options(itemProp)
          }
        } else {
          AllOptions.getAll(itemProp, lists)
        }
      }
    })
  }
}

export default AllOptions