import _ from 'lodash'
import { formatItem, formatTypes, getFormattedDate } from "../../../../global/common/dateFormatting"
import { updateArrayInclusion } from "../../../../global/common/sorting"
import { matchScheduleTypes } from "../contexts/SportsSeasonContext"
import { matchDisplayTypes } from './SportsMatchReducer'

const rts = {
  handle_setVisibleDates: 'handle_setVisibleDates',
  handleShow_matches: 'handleShow_matches',
  handle_gotoToday: 'handle_gotoToday',
  handleGoTo_match: 'handleGoTo_match',
  handleMatch_editClick: 'handleMatch_editClick',
  handleFilter_matches: 'handleFilter_matches',
  handleMatches_init: 'handleMatches_init',
  handleMatches_initPlayoff: 'handleMatches_initPlayoff',
  handleMatches_teamMatches: 'handleMatches_teamMatches',
  handleNoData: 'handleNoData',
  handleResults_matchDate: 'handleResults_matchDate',
  handleSet_levelDateGroup: 'handleSet_levelDateGroup',
  handleSet_levelGroupMatchElements: 'handleSet_levelGroupMatchElements',
  handleSet_swiper: 'handleSet_swiper',
  handleSelect_currentMatch: 'handleSelect_currentMatch',
}

export const _showTypes = {
  calendar: 'calendar',
  expand: 'expand',
  matchResults: 'matchResults',
  missing: 'missing',
  newMatch: 'newMatch',
  search: 'search',
  updateScores: 'updateScores',
}

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @returns state
 * @description a sportsSeasonMatches season consists of multiple collections (teams, matches, rankings, history, scheduleDates, playoffs)
 * 
 */
export const seasonMatchesReducer = (state, action) => {

  const {
    _teamKey,
    matchScheduleType,
    matchesAll,
    previewMatches,
    scheduleTypes,
    sportsSeason_fns,
    sortedMatches,
    pushSimple,
  } = state

  let _visibleLevelDates = state.visibleLevelDates ?? {}
  let _lastDatesSelected = state.lastDatesSelected ?? {}

  const { type, matches_info, commonMatches } = action

  switch (type) {

    case rts.handleSelect_currentMatch:
      const { matchDisplayType, selectedMatch: match_rt } = action
      const isSame = state.matchDisplayType && (matchDisplayType === state.matchDisplayType)
      if (match_rt) {
        if (isSame) {
          return { ...state, matchDisplayType: null, matchData_rt: null, rt_matchKey: null }
        } else {
          const matchData_rt = { match_rt }
          switch (matchDisplayType) {
            case matchDisplayTypes.realtimeScoring:
              return { ...state, matchDisplayType, matchData_rt, rt_matchKey: match_rt._itemKey }
            default:
              return { ...state, matchDisplayType, matchData_rt, rt_matchKey: match_rt._itemKey }
          }
        }
      } else {
        return { ...state, matchDisplayType: null, matchData_rt: null, rt_matchKey: null }
      }

    case rts.handleMatches_init:
      const { matchesAll: matchesAll_init, levelVisibleDates, matches_matchDay } = matches_info ?? {}
      let _matches = matchesAll_init
      if (previewMatches) { _matches = previewMatches }
      if (commonMatches) { _matches = commonMatches }
      const { sortedMatches: sms, noData } = getSortedMatches(state, _matches, commonMatches)
      const ldms = getLevelDateMatches(_matches)
      const pastNonScore = _matches ? _.filter(_matches, function (m) { return m?._status?.isPast && !m?._status?.isBye && !m._results?.complete; }) : null
      const _pns = _.groupBy(pastNonScore, 'levels')
      return {
        ...state,
        pastNonScoreMatches: _pns,
        scheduledMatches: _matches,
        levelDateMatches: commonMatches ? commonMatches : ldms,
        matchesAll: matchesAll_init,
        sortedMatches: sms,
        noData: noData,
        visibleLevelDates: levelVisibleDates
      }

    case rts.handleMatches_initPlayoff:
      return { ...state, pools: action.pools, poolMatches: action.poolMatches }

    case rts.handleResults_matchDate:
      const dateMatchResults = action.dmr
      const { sectionDateMatches } = dateMatchResults ?? {}
      const pds = _.filter(sectionDateMatches, { isPlayoff: true })
      const playoffDates = pds && Object.keys(pds).length > 0
      return { ...state, dateMatchResults: action.dmr, playoffDates }

    case rts.handleSet_levelDateGroup:
      return { ...state, levelDateGroup: action.dlg, levelDateKey: action.dlk, levelKey: action.lk }

    case rts.handleSet_levelGroupMatchElements:
      return { ...state, levelGroupMatchElements: action.lgms }

    case rts.handleFilter_matches:
      return { ...state, matchFilter: action.mf }

    case rts.handleNoData:
      return { ...state, noData: action.nd }

    case rts.handleMatches_teamMatches:
      let _teamMatches;
      if (sortedMatches) {
        if (scheduleTypes.team || scheduleTypes.match) {
          if (matchScheduleType === matchScheduleTypes.next) {
            _teamMatches = sortedMatches
          } else {
            _teamMatches = sportsSeason_fns.getFilteredMatches(sortedMatches, _teamKey, action.selectedLocation, action.selectedResult, action.selectedSection, state.selectedOpponent === action.selectedOpponent ? null : action.selectedOpponent)
          }
        }
      }
      return { ...state, teamMatches: _teamMatches, selectedOpponent: action.selectedOpponent }

    case rts.handleSet_swiper:
      return { ...state, swiper: action.swiper }

    case rts.handleMatch_editClick:
      pushSimple(action.clickedItem, action.itemKey, action.modifyMode, action.opts, action.ddn, action.ddProps, action.ddGroupIndex)
      return { ...state, match_edit: matchesAll ? matchesAll[action.itemKey] : null }

    case rts.handleGoTo_match:
      pushSimple(action.clickedItem, action.itemKey, action.modifyMode, action.opts, action.ddn, action.ddProps, action.ddGroupIndex)
      return { ...state, match_edit: matchesAll ? matchesAll[action.itemKey] : null }

    case rts.handle_gotoToday:
      const today = new Date();
      const xxxx = formatItem(formatTypes.shortestDate, today)
      return { ...state, scrollToDate: xxxx, scrollToDateIndex: state.scrollToDateIndex === 0 ? 1 : 0 }

    case rts.handleShow_matches:
      let _shows_matches = { ...state._shows_matches }
      const { showType, forceShow } = action
      let _showExpand = state.showExpand;
      let _showMissing = state.showMissing;
      if (!showType) {
        _shows_matches = {}
      } else {
        _shows_matches[showType] = forceShow ? true : !_shows_matches[showType]
        switch (showType) {
          case _showTypes.expand:
            _showExpand = !_showExpand
            if (state.showExpand) {
              _visibleLevelDates = {}
            }
            break;
          case _showTypes.missing:
            _showMissing = !_showMissing
            // if (_shows_matches[showType]) {
            //   const missingMatches = matchesAll ? _.filter(matchesAll, function (m) { return m?._status?.isMissing; }) : null
            //   console.log('missingMatches', missingMatches)
            // }
            break;
          default:
          // nothing
        }
      }
      return { ...state, _shows_matches, showExpfand: _showExpand, showMissing: _showMissing, visibleLevelDates: _visibleLevelDates }

    case rts.handle_setVisibleDates:
      if (!_visibleLevelDates[action.levelKey]) { _visibleLevelDates[action.levelKey] = [] }
      updateArrayInclusion(_visibleLevelDates[action.levelKey], action.matchDate)
      _lastDatesSelected[action.levelKey] = action.matchDate
      return { ...state, visibleLevelDates: _visibleLevelDates, lastDatesSelected: _lastDatesSelected }

    default:
      return { ...state }
  }
}

export const seasonMatchesHandlers = (dispatch) => {
  return {
    handleSelect_currentMatch: (selectedMatch, matchDisplayType) => { dispatch({ type: rts.handleSelect_currentMatch, dispatch, selectedMatch, matchDisplayType }) },

    handle_gotoToday: () => { dispatch({ type: rts.handle_gotoToday, dispatch }) },
    handle_setVisibleDates: (levelKey, matchDate) => { dispatch({ type: rts.handle_setVisibleDates, dispatch, levelKey, matchDate }) },
    handleFilter_matches: (mf) => { dispatch({ type: rts.handleFilter_matches, dispatch, mf }) },
    handleGoTo_match: (pushSimple, clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex) => { dispatch({ type: rts.handleGoTo_match, dispatch, pushSimple, clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex }) },
    handleMatch_editClick: (clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex) => { dispatch({ type: rts.handleMatch_editClick, dispatch, clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex }) },
    handleMatches_init: (matches_info, commonMatches) => { dispatch({ type: rts.handleMatches_init, dispatch, matches_info, commonMatches }) },
    handleMatches_initPlayoff: (pools, poolMatches) => { dispatch({ type: rts.handleMatches_initPlayoff, dispatch, pools, poolMatches }) },
    handleMatches_teamMatches: (selectedLocation, selectedResult, selectedSection, selectedOpponent) => { dispatch({ type: rts.handleMatches_teamMatches, dispatch, selectedLocation, selectedResult, selectedSection, selectedOpponent }) },
    handleNoData: (nd) => { dispatch({ type: rts.handleNoData, dispatch, nd }) },
    handleResults_matchDate: (dmr) => { dispatch({ type: rts.handleResults_matchDate, dispatch, dmr }) },
    handleSet_levelDateGroup: (dlg, dlk, lk) => { dispatch({ type: rts.handleSet_levelDateGroup, dispatch, dlg, dlk, lk }) },
    handleSet_levelGroupMatchElements: (lgms) => { dispatch({ type: rts.handleSet_levelGroupMatchElements, dispatch, lgms }) },
    handleSet_swiper: (swiper) => { dispatch({ type: rts.handleSet_swiper, dispatch, swiper }) },
    handleShow_matches: (showType, teamOnly, forceShow) => { dispatch({ type: rts.handleShow_matches, dispatch, showType, teamOnly, forceShow }) },
  }
}

export const seasonMatchesInitialState = (init_state) => {
  return { ...init_state, matchFilter: {}, teamMatches: {}, lastDatesSelected: {}, _shows_matches: { expand: false } }
};


const getLevelDateMatches = (matches) => {

  const today = getFormattedDate(new Date())

  const dateMatches = _.filter(matches, (match) => {
    // Parse the match's startDate into a Date object
    const matchDate = new Date(match.startDate);

    // Format the matchDate in the same format as today
    const formattedMatchDate = getFormattedDate(matchDate)

    // Compare the formattedMatchDate with today's date
    return formattedMatchDate === today;
  })

  const groupedMatches = _.groupBy(dateMatches, 'levels');
  return groupedMatches

}


const getSortedMatches = (state, scheduledMatches, commonMatches) => {

  const {
    _teamKey,
    latestMatchesCount,
    matchScheduleType,
    matchesTeams,
    matchesRetrieved,
    pathViews,
    scheduleTypes,
    sportsSeason_fns,
  } = state

  let sortedMatches;
  let noData;

  if (scheduledMatches && Object.keys(scheduledMatches).length > 0) {
    if (scheduleTypes.common) {
      sortedMatches = commonMatches
    } else {
      if (scheduleTypes.team) {
        if (matchesTeams) {
          switch (matchScheduleType) {
            case matchScheduleTypes.next:
              sortedMatches = matchesTeams && matchesTeams[_teamKey].next ? matchesTeams[_teamKey].next : 'No Matches'
              break;
            case matchScheduleTypes.latest:
              sortedMatches = matchesTeams && matchesTeams[_teamKey].latest ? matchesTeams[_teamKey].latest : 'No Matches'
              break;
            default:
              sortedMatches = matchesTeams && matchesTeams[_teamKey].all
          }

        } else {
          const tm = sportsSeason_fns.getMatchesByTeam(scheduledMatches, _teamKey, false, matchScheduleType, latestMatchesCount)
          sortedMatches = tm
        }
      } else if (scheduleTypes.match) {
        const mm = sportsSeason_fns.getMatch(scheduledMatches, pathViews.matches)
        sortedMatches = mm
      }
    }
    noData = false
  } else {
    noData = matchesRetrieved && scheduledMatches ? true : false
  }

  return { sortedMatches, noData }
}