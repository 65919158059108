import { createRefPath } from "../../firestoreData/appData/appRefPaths"
import { grts } from "./reducerHelpers/dispatchProps"

const rts = {
  handleGet_clients: 'handleGet_clients',
  handleSet_clients: 'handleSet_clients',
  ...grts
}

export const homeInfoReducer = (state, action) => {

  const { database_fns, pathViews } = state
  const { type, dispatch } = action

  const homeInfo_handlers = homeInfoHandlers(dispatch)
  const { handleSet_clients } = homeInfo_handlers

  switch (type) {

    case rts.handleGet_clients:
      getClients(database_fns, handleSet_clients)
      return { ...state }

    case rts.handleSet_clients:
      return { ...state, clients: action.clients }

    default:
      return { ...state }
  }
}

export const homeInfoInitialState = (initState) => {
  return { ...initState }
};

export const homeInfoHandlers = (dispatch) => {
  return {
    handleGet_clients: () => { dispatch({ type: rts.handleGet_clients, dispatch }) },
    handleSet_clients: (clients) => { dispatch({ type: rts.handleSet_clients, dispatch, clients }) },
  }
}

const getClients = async (database_fns, callback) => {
  const _refPath = createRefPath(['clients'])
  const clients = await database_fns.get_data({ refPath: _refPath })
  callback(clients)
} 