export const vsComponentTypes = {
  actions: 'actions',
  add: 'add',
  appAccessRequests: 'appAccessRequests',
  appConversations: 'appConversations', // XXX
  appNotifications: 'appNotifications', // XXX
  appOptions: 'appOptions', // XXX
  appPhotos: 'appPhotos',
  appStatus: 'appStatus',
  appVideoConferencing: 'appVideoConferencing',
  card: 'card',
  constraintBuilder: 'constraintBuilder',
  copyEvent: 'copyEvent',
  createClient: 'createClient',
  createEvent: 'createEvent',
  createGlobalEventAuth: 'createGlobalEventAuth',
  createSettings: 'createSettings',
  dataCollections: 'dataCollections',
  dataLists: 'dataLists',
  dataListsPage: 'dataListsPage',
  dataManagementSettings: 'dataManagementSettings',
  dataPage: 'dataPage',
  deleteClient: 'deleteClient',
  deleteEvent: 'deleteEvent',
  deletePage: 'deletePage',
  eventUpdates: 'eventUpdates',
  filePicker: 'filePicker',
  filePickerPage: 'filePickerPage',
  files: 'files',
  filesPage: 'filesPage',
  forms: 'forms',
  globalPageItems: 'globalPageItems',
  globalSettings: 'globalSettings',
  groupItemSettings: 'groupItemSettings',
  groupSettings: 'groupSettings',
  imageMapping: 'imageMapping',
  imageMappingBuilder: 'imageMappingBuilder',
  manifestPicker: 'manifestPicker',
  manifestPickerPage: 'manifestPickerPage',
  notificationsManager: 'notificationsManager',
  pageSettings: 'pageSettings',
  pageItemSettings: 'pageItemSettings',
  propSettings: 'propSettings',
  propSectionSettings: 'propSectionSettings',
  props: 'props',
  propSections: 'propSections',
  pushEventToProduction: 'pushEventToProduction',
  rssFeeds: 'rssFeeds',
  scheduledNotifications: 'scheduledNotifications',
  subPage: 'subPage',
  transitionItems: 'transitionItems',
  updateEvent: 'updateEvent',
  uploadImage: 'uploadImage',
  uploadPage: 'uploadPage',
  viewActions: 'viewActions',
  viewItems: 'viewItems',
  views: 'views',
}