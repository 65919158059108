import { useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions';
import { AppUserContext } from '../../../../global/cnr/contexts/AppUserContext';
import { PapsContext } from '../../../../global/cnr/contexts/PapsContext';
import { labHandlers, labInitialState, labReducer } from '../reducers/LabReducer';

const LabProvider = () => {

  // papsContext
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext
  const { pathViews } = paps_state ?? {}

  const appUserContext = useContext(AppUserContext)
  const { appUser_state } = appUserContext
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { appUserSession } = appUserAccess ?? {}
  const { _itemKey: appUserSessionKey } = appUserSession ?? {}

  const init_state = { pathViews }
  const [lab_state, lab_dispatch] = useReducer(labReducer, init_state, labInitialState)
  const lab_handlers = labHandlers(lab_dispatch, lab_state)

  useEffect(() => {
    lab_handlers.handleInit_lab(pathViews)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserSessionKey]);

  return { lab_handlers, lab_state }
};

export default LabProvider
