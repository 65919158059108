import React, { createContext } from 'react';

export const DataModifyContext = createContext();

const DataModifyProvider = (props) => {
  return <DataModifyContext.Provider value={{ ...props }}>
    {props.children}
  </DataModifyContext.Provider>
};

export default DataModifyProvider 