import _ from 'lodash';
import React from 'react';
import { convertHelpers } from '../../../common/convert';
import { uniqueKey } from '../../../common/keys';
import { gEnums } from '../../../enums/globalEnums';
import { settingsIconTypes } from '../../../enums/settingsIconTypes';
import SettingsAreaGroupMenuItemElement from './SettingsAreaGroupMenuItemElement';

/**
 * 
 * @param {object} groupItems 
 * @param {object} settingsMenuContext 
 * @returns an ARRAY of menu.item ELEMENTS
 */
export const getSettingsAreaGroupMenuItemElements = (groupKey, groupItems, settingsMenuContext, settingsAreaContext) => {

  const _apits = gEnums.additionalPageItemTypes

  const { settingsArea_state } = settingsAreaContext ?? {}
  const { projectOptions } = settingsArea_state ?? {}

  const _menuItemElements = []
  const _menuItemElementGroups = []

  // get the subMenus
  if (groupItems) {

    const groupItems_ammended = {}

    convertHelpers.createItemKeys(groupItems, 'key')

    Object.keys(groupItems).forEach(key => {
      const groupItem = groupItems[key]
      const { gotoKey } = groupItem ?? {}
      groupItems_ammended[key] = {
        key,
        caption: _.startCase(key),
        icon: settingsIconTypes[key] ? settingsIconTypes[key] : 'cog',
        gotoKey
      }
      // }
    })

    const groupItems_sorted = _.sortBy(groupItems_ammended, ['key', '_position'])

    // loop the groupItems and create an element for each groupItem
    groupItems_sorted.forEach((groupItem, index) => {
      const { key } = groupItem

      if (key) {
        let allow = true

        if (projectOptions && _apits[key]) {
          allow = false // allowGroupProjectOptions ? allowGroupProjectOptions(key) : true
        }

        if (allow) {
          const element = <SettingsAreaGroupMenuItemElement key={uniqueKey('mig', 'gi', key)} groupItemKey={key} groupItem={groupItem} index={index} groupItemSettingz={groupItems_ammended} />
          if (element) {
            _menuItemElementGroups[key] = element
            _menuItemElements.push(element)
          }
        }
      }
    })
  }

  return { menuItemElements: _menuItemElements, menuItemElementGroups: _menuItemElementGroups }

}