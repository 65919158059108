import React, { createContext, useContext, useEffect, useReducer } from "react";
import { ParentContext } from "../../../../global/cnr/contexts/ParentContext";
import { seasonMatchesHandlers, seasonMatchesInitialState, seasonMatchesReducer } from "../reducers/SeasonMatchesReducer";
import { SportsSeasonContext, matchScheduleTypes } from "./SportsSeasonContext";
import { getAppUserAccess } from "../../../../global/auth/appUserAccessPermissions";

export const SeasonMatchesContext = createContext()

export default function SeasonMatchesProvider(props) {

  const {
    commonMatches,
    latestMatchesCount,
    forBackup,
    matchScheduleType,
    scheduleDisplayType,
    matchScheduleDisplayType,
    previewMatches,
    teamKey,
    tournamentType,
    forPlayoff,
    forOverview,
    forSummary,
  } = props

  const {
    poolMatches,
    pools,
  } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { appUser_state, paps_state } = states
  const { appUsers } = appUser_state ?? {}
  const { page_fns } = fns
  const { pushSimple } = page_fns ?? {}
  const { pathViews } = paps_state ?? {}

  const appUserAccess = getAppUserAccess(appUsers)
  const { itemAccess } = appUserAccess ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_fns } = sportsSeasonContext ?? {}
  const { matches_info, seasonMatches_info, sportPermissions } = sportsSeason_state ?? {}

  const _matches_info = forBackup && seasonMatches_info ? seasonMatches_info : matches_info

  const _teamKey = teamKey ? teamKey : pathViews.teams

  const scheduleTypes = {
    district: pathViews && pathViews.districts && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    match: pathViews && pathViews.matches ? true : false,
    sport: pathViews && pathViews.sports ? true : false,
    team: teamKey || (pathViews && pathViews.teams) ? true : false,
    common: commonMatches ? true : false
  }

  let _sepBySection = true
  if (scheduleTypes.team || scheduleTypes.match || scheduleTypes.common) {
    _sepBySection = false
  }

  const seasonMatches_fns = {

    getMatchCn: (match, useScoreBlock, tournamentType, playoffDates, allUpdates, matchResultFullBackground) => {

      let _sportsMatch_cn = 'schedule-match'

      if (!match) { return _sportsMatch_cn }

      const {
        _flipHa,
        _results,
        _status,
        away,
        complete,
        home,
        id,
        isPlayoff,
        key,
        location,
        matchAllows,
        pending,
      } = match ?? {}

      const { edit: _allowEdit, scores: _allowScores, matchAccessLevel } = matchAllows ?? {}

      const { home: home_pending, away: away_pending } = pending ?? {}

      let loc = location ? location.name : null

      let _home = home
      let _away = away

      if (home_pending) { _home = home_pending }
      if (away_pending) { _away = away_pending }

      const { isMissing, isPast, isBye, isMatchDayResult } = _status ?? {}
      const isPending = (!home || !away) ? true : false
      const teamIsHome = _home.id === _teamKey

      const _isPlayoff = false
      const keyy = key ? key : id


      if (matchScheduleType === matchScheduleTypes.latest) { _sportsMatch_cn += ' lmo' }
      if (useScoreBlock) { _sportsMatch_cn += ' blocked' }
      if (complete) { _sportsMatch_cn += ' complete' }

      if (tournamentType) {
        _sportsMatch_cn += ' pool'
      } else if (scheduleTypes.match) {
        _sportsMatch_cn += ' match'
      } else if (scheduleTypes.team && !_isPlayoff) {
        _sportsMatch_cn += ' teams'
      } else {
        _sportsMatch_cn += ' section'
      }

      if (matchResultFullBackground) { _sportsMatch_cn += ' fmrb' }

      if (playoffDates || isPlayoff) {
        _sportsMatch_cn += ' playoff'
        if (_flipHa) {
          _sportsMatch_cn += ' flip'
        }
      }

      if (allUpdates && allUpdates[keyy]) { _sportsMatch_cn += ' mmr' }

      const allowMatchEdits = { match: false, sport: false, any: false }


      allowMatchEdits.match = (itemAccess && ((_home && itemAccess.includes(_home.id)) || (_away && itemAccess.includes(_away.id)))) ? true : false
      allowMatchEdits.sport = (itemAccess && itemAccess.includes(sportsKey)) ? true : false

      // NEW STUFF
      const { winner, hasScores, score, scoreDisplays, scoreDisplays_flip, tied } = _results ?? {}
      const _scoreDisplays = _flipHa ? scoreDisplays_flip : scoreDisplays
      let { scores: scoreDisplay_scores } = _scoreDisplays ?? {}

      if (winner) {
        _sportsMatch_cn += winner.id === _teamKey ? ' win' : ' loss'
        _sportsMatch_cn += winner.id === home.id ? ' wh' : ' wa'
      }

      if (tied && tied.length === 2) {
        _sportsMatch_cn += ' tie'
      }

      if (!complete) { _sportsMatch_cn += ' future' }
      if (complete) { _sportsMatch_cn += ' complete' }
      if (allowMatchEdits.any) { _sportsMatch_cn += ' ame' }
      if (allowMatchEdits.sport) { _sportsMatch_cn += ' ase' }
      if (_allowScores && !forOverview) { _sportsMatch_cn += ' ae' }
      if (matchAccessLevel) { _sportsMatch_cn += ' mal-' + matchAccessLevel }
      if (isBye) { _sportsMatch_cn += ' bye' }
      if (isPast && !winner) { _sportsMatch_cn += ' no-res' }
      if (isPending) { _sportsMatch_cn += ' pending' }
      if (isMissing) { _sportsMatch_cn += ' missing' }
      if (isMatchDayResult) { _sportsMatch_cn += ' mdr' }
      if (loc === _home.name) { _sportsMatch_cn += ' lch' }


      if (teamIsHome) {
        _sportsMatch_cn += ' tih'
      } else {
        _sportsMatch_cn += ' tia'
      }

      if (scoreDisplay_scores && !forOverview) { _sportsMatch_cn += ' scrs' }


      return _sportsMatch_cn
    }
  }

  // sidebar
  const init = {
    _teamKey,
    commonMatches,
    latestMatchesCount,
    matchScheduleType,
    scheduleDisplayType,
    matchScheduleDisplayType,
    matchesInfo: _matches_info,
    pathViews,
    previewMatches,
    pushSimple,
    scheduleTypes,
    sepBySection: _sepBySection,
    sportsSeason_fns,
    sportPermissions,
    tournamentType,
    forPlayoff,
    forSummary
  }

  const [seasonMatches_state, seasonMatches_dispatch] = useReducer(seasonMatchesReducer, seasonMatchesInitialState(init));
  const seasonMatches_handlers = seasonMatchesHandlers(seasonMatches_dispatch)

  // update the scheduledMatches
  useEffect(() => {
    seasonMatches_handlers.handleMatches_init(_matches_info, commonMatches)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews, _matches_info, matchScheduleType, commonMatches]);

  useEffect(() => {
    seasonMatches_handlers.handleMatches_initPlayoff(pools, poolMatches)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews, poolMatches, pools]);

  return <SeasonMatchesContext.Provider
    value={{ seasonMatches_state, seasonMatches_handlers, seasonMatches_fns }}>
    {props.children}
  </SeasonMatchesContext.Provider>
}