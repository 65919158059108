import { allPageTypes } from '../../../enums/allPageTypes';

/**
 * 
 * @param {object} props 
 * @returns credentialScan, notificationsManager, meetingsManager, qAndAManager, surveyManager, votingManager
 */
export const allowedItemsAppAdmin = (props) => {

  const { parentContext, pageIcons, gcProps, allows, accessLevel } = props

  const { states } = parentContext ?? {}
  const { page_state } = states

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { appCredentialing, appNotifications, appMeetings, appQuestionsAndAnswers, appSurvey, appVoting } = aps_global ?? {}
  const { showNotificationsManagerOnHomeScreen, notificationsAuthLevel, allowNotifications } = appNotifications ?? {} // allowNotifications
  const { credentialingHomeScreenCaption, showCredentialingManagerOnHomeScreen } = appCredentialing ?? {}
  const { allowMeetings, showMeetingsManagerOnHomeScreen, appAdminOnly } = appMeetings ?? {}
  const { allowSurvey, showSurveyManagerOnHomeScreen, surveyManagerAuthLevel } = appSurvey ?? {}
  const { allowQuestionsAndAnswers, showQandAManagerOnHomeScreen, qAndAHomeScreenCaption, questionsAndAnswersCollections } = appQuestionsAndAnswers ?? {}
  const { allowVoting, showVotingManagerOnHomeScreen, votingManagerAuthLevel, votingCollections } = appVoting ?? {}

  let _allowVoting;
  let allowVtm = !pageIcons && allowVoting && showVotingManagerOnHomeScreen && votingCollections
  if (allowVtm && allows.avt && (accessLevel >= votingManagerAuthLevel)) { _allowVoting = true }

  let _allowCredentialing;
  let allowCredentialing = !pageIcons && showCredentialingManagerOnHomeScreen
  if (allowCredentialing && allows.avt) { _allowCredentialing = true }

  let _allowMeetings;
  let allowMtg = !pageIcons && allowMeetings && showMeetingsManagerOnHomeScreen
  if (allowMtg && allows.avt && appAdminOnly) { _allowMeetings = true }

  let _allowSurvey;
  let allowSrv = !pageIcons && allowSurvey && showSurveyManagerOnHomeScreen
  if (allowSrv && allows.avt && (accessLevel >= surveyManagerAuthLevel)) { _allowSurvey = true }

  let _allowQandA;
  let allowQandA = !pageIcons && allowQuestionsAndAnswers && showQandAManagerOnHomeScreen && questionsAndAnswersCollections
  if (allowQandA && allows.aqa) { _allowQandA = true }

  let _allowNotifications;
  let allowNotes = !pageIcons && allowNotifications && showNotificationsManagerOnHomeScreen
  if (allowNotes && allows.aao && (accessLevel >= notificationsAuthLevel)) { _allowNotifications = true }

  const allowedItem = (viewItem, segProps, gcProps, hidden) => {
    const { key } = viewItem ?? {}
    return { viewItem, itemKey: key, segProps, gcProps, hidden }
  }

  const items = []

  if (!pageIcons) {
    if (_allowCredentialing) { items.push(allowedItem({ key: allPageTypes.credentialScan }, { caption: credentialingHomeScreenCaption ? credentialingHomeScreenCaption : 'Credential Manager', icon: 'clipboard check', cn: 'ao' }, gcProps)) }
    if (_allowNotifications) { items.push(allowedItem({ key: allPageTypes.notificationsManager }, { caption: 'Notifications Manager', icon: 'calendar check outline', cn: 'ao' }, gcProps)) }
    if (_allowMeetings) { items.push(allowedItem({ key: allPageTypes.meetingsManager }, { caption: 'Meetings Manager', icon: 'clipboard check', cn: 'ao' }, gcProps)) }
    if (_allowQandA) { items.push(allowedItem({ key: allPageTypes.qAndAManager }, { caption: qAndAHomeScreenCaption ? qAndAHomeScreenCaption : 'Q and A Manager', icon: 'clipboard check', cn: 'ao' }, gcProps)) }
    if (_allowSurvey) { items.push(allowedItem({ key: allPageTypes.surveyManager }, { caption: 'Survey Manager', icon: 'clipboard check', cn: 'ao' }, gcProps)) }
    if (_allowVoting) { items.push(allowedItem({ key: allPageTypes.votingManager }, { caption: 'Voting Manager', icon: 'clipboard check', cn: 'ao' }, gcProps)) }
  }

  return items

}

