import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";

export const rts = {
  handleInit_appUsers: 'handleInit_appUsers',
  handleSet_profiles: 'handhandleSet_profilesleSetProfiles',
  handleSet_eventAppUsers: 'handleSet_eventAppUsers',
  ...grts
}

export const appUsersReducerInitialState = (initState) => {
  return { ...initState }
};

export const appUsersReducer = (state, action) => {

  const { pathViews } = state
  const { type, dispatch } = action

  switch (type) {

    case rts.handleInit_appUsers:
      return { ...state }

    case rts.handleSet_eventAppUsers:
      return { ...state, eventAppUsers: action.eventAppUsers }

    case rts.handleSet_profiles:
      const { profileData } = action
      const _stateData = {}
      if (profileData) {
        Object.keys(profileData).forEach(k => {
          _stateData[k] = profileData[k]
        })
      }
      console.log('_stateData', _stateData)
      return { ...state, ..._stateData, profilesRetrieved: true }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const appUsersHandlers = (dispatch) => {
  return {
    handleInit_appUsers: (appUserCollections) => { dispatch({ type: rts.handleInit_appUsers, dispatch, appUserCollections }) },
    handleSet_eventAppUsers: (eventAppUsers) => { dispatch({ type: rts.handleSet_eventAppUsers, dispatch, eventAppUsers }) },
    handleSetProfiles: (profileData) => { dispatch({ type: rts.handleSet_profiles, dispatch, profileData }) },
    ...responseHandlers(dispatch)
  }
}
