import _ from 'lodash';
import { gEnums } from '../../enums/globalEnums';

const rts = {
  handleInit_appMonitor: 'handleInit_appMonitor',
  handleUpdate_appMonitor: 'handleUpdate_appMonitor',
}

export const appMonitorReducer = (state, action) => {

  const { dispatch, type, appUserAccess } = action

  switch (type) {

    case rts.handleInit_appMonitor:
      const allowMonitor = appUserAccess && appUserAccess.accessLevel >= gEnums.accessLevels.superAdmin.value
      return { ...state, appMonitor: {}, appUserAccess, allowMonitor }

    case rts.handleUpdate_appMonitor:
      return { ...state }

    default:
      return { ...state }
  }
}

export const appMonitorInitialState = (initState) => {
  return { ...initState }
};

export const appMonitorHandlers = (dispatch) => {
  return {
    handleInit_appMonitor: (appUserAccess) => { dispatch({ type: rts.handleInit_appMonitor, dispatch, appUserAccess }) },
    handleUpdate_appMonitor: (update) => { dispatch({ type: rts.handleInit_appMonitor, dispatch, update }) },
  }
}
