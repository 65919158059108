import _ from 'lodash'
import React, { useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import { appIconTypes } from '../../enums/appIconTypes';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import GenericIcon from '../../genericControls/GenericIcon';

const GroupActionButton = (props) => {

  const {
    actionName,
    asIcon,
    cancelButton,
    confirmation,
    dataCollectionKey,
    defaultIcon,
    directAction,
    handleConfirm,
    handleItemHelp,
    handlePreview,
    iconColor,
    infoButton,
    itemCount,
    noCommit,
    previewClicked,
    previewReady,
    saveIcon,
    setConfirmation,
    setPreviewClicked,
    showItemHelp,
    styleAndClass,
    uploadButton,
  } = props

  let { caption } = props
  const { style } = styleAndClass ?? {}

  caption = caption ? caption : actionName

  // local state
  const [commitIcon, setCommitIcon] = useState();
  const [alertClicked, setAlertClicked] = useState();
  const [confirmStarted, setConfirmStarted] = useState();

  const reset = () => {
    setConfirmStarted()
    setAlertClicked()
    setPreviewClicked && setPreviewClicked()
    setConfirmation && setConfirmation()
    handlePreview && handlePreview()
  }

  useEffect(() => {
    setCommitIcon(saveIcon)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveIcon]);

  useEffect(() => {
    if (confirmation) {
      const timer = setTimeout(() => {
        reset()
      }, confirmation.success === true ? 2000 : 3000);
      return () => {
        clearTimeout(timer)
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation]);

  // handlers
  const handleAlertClick = (actionName) => {
    if (actionName) {
      setAlertClicked(actionName)
      handlePreview && handlePreview(actionName)
    } else {
      reset()
    }
  }

  const handleConfirmClick = (action_name) => {
    if (action_name) {
      handleConfirm(action_name, dataCollectionKey)
      setConfirmStarted(true)
    } else {
      reset()
    }
  }

  const isActiveAction = (previewClicked === actionName) || (alertClicked === actionName) ? true : false

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const icon = (btnLeft, oc, actionName) => {
    const { color, icon, disabled, loading } = btnLeft
    return gi('gab-ico-key', icon, oc, actionName, { bordered: true, inverted: true, color, disabled, loading })
  }

  const btn = (btnProps, oc, actionName, btnAllProps, useStyle) => {
    const { color, icon, caption, disabled, loading } = btnProps
    const { color: color_all } = btnAllProps ?? {}
    return <Button style={useStyle && style} disabled={disabled} color={color ? color : color_all} onClick={e => oc && oc(actionName)}><Icon name={icon} loading={loading} />{caption && caption}</Button>
  }

  const btnInfo = () => {
    const btnProps = { icon: appIconTypes.info, color: iconColorTypes.success }
    return btn(btnProps, infoButton.oc)
  }

  const btnCancel = () => {
    const btnProps = { icon: appIconTypes.remove, color: iconColorTypes.default }
    return btn(btnProps, cancelButton.oc)
  }

  /** initial button. All button click the same */
  const bgInit = (btnProps) => {
    const { btnAll, btnLeft, btnMiddle, btnRight, cn } = btnProps
    if (asIcon) {
      return icon(btnLeft, handleAlertClick, actionName)
    } else {
      return <Button.Group icon fluid key={uniqueKey('gab', 'b')} className={cn} >
        {cancelButton && btnCancel()}
        {infoButton && btnInfo()}
        {btn(btnLeft, handleAlertClick, actionName, btnAll, true)}
        {btn(btnMiddle, handleAlertClick, actionName, btnAll, true)}
        {btn(btnRight, handleAlertClick, actionName, btnAll, true)}
      </Button.Group>
    }
  }

  const bgConfirmation = (btnProps) => {

    btnProps.cn += ' confirmed'
    btnProps.btnAll.color = confirmation.success ? iconColorTypes.success : iconColorTypes.admin
    btnProps.btnRight.icon = confirmation.success ? appIconTypes.check : appIconTypes.delete
    btnProps.btnRight.loading = false

    const { btnMiddle, btnRight, cn } = btnProps
    return <Button.Group icon fluid key={uniqueKey('gab', 'c')} className={cn}>
      {btn(btnMiddle, handleAlertClick)}
      {btn(btnRight, handleConfirmClick, null, null, true)}
    </Button.Group>
  }

  const bgNoConfirm = (btnProps) => {

    btnProps.cn += ' no-commit'
    btnProps.btnLeft.icon = appIconTypes.delete
    btnProps.btnLeft.color = iconColorTypes.cancel
    btnProps.btnMiddle.caption = _.startCase(caption) + '?'
    btnProps.btnMiddle.color = iconColorTypes.default

    const { btnLeft, btnMiddle, btnHelp, cn } = btnProps
    return <Button.Group icon fluid key={uniqueKey('gab', 'nc')} className={cn} disabled={true}>
      {btn(btnLeft)}
      {btn(btnMiddle, handleConfirmClick)}
      {handleItemHelp && btn(btnHelp, handleItemHelp, actionName)}
    </Button.Group>
  }

  const bgConfirm = (btnProps) => {

    btnProps.btnLeft.icon = appIconTypes.delete // cancel the action
    btnProps.btnLeft.color = iconColorTypes.cancel
    btnProps.btnMiddle.caption = _.startCase(caption) + '?' // show the action
    btnProps.btnMiddle.color = iconColorTypes.cancel
    if (!btnProps.btnRight.loading) {
      btnProps.btnRight.icon = commitIcon // confirm the action
      btnProps.btnRight.color = iconColorTypes.update
    }

    const { btnLeft, btnMiddle, btnRight, btnHelp, cn } = btnProps

    return <Button.Group icon fluid key={uniqueKey('gab', 'cf')} className={cn} disabled={true}>
      {btn(btnLeft, handleAlertClick)}
      {btn(btnMiddle, handleAlertClick)}
      {handleItemHelp && btn(btnHelp, handleItemHelp, actionName)}
      {btn(btnRight, handleConfirmClick, actionName, null, true)}
    </Button.Group>
  }

  const bgPending = (btnProps) => {

    btnProps.btnLeft.icon = appIconTypes.delete
    btnProps.btnLeft.color = iconColorTypes.cancel
    btnProps.btnMiddle.caption = _.startCase(caption) + '?'
    btnProps.btnMiddle.color = iconColorTypes.cancel
    btnProps.btnRight.loading = true
    btnProps.btnRight.icon = appIconTypes.cog
    btnProps.btnRight.color = iconColorTypes.fetching

    btnProps.cn += ' pending'
    const { btnMiddle, btnRight, cn } = btnProps

    return <Button.Group icon fluid key={uniqueKey('gab', 'p')} className={cn} disabled={true}>
      {btn(btnMiddle, handleAlertClick)}
      {btn(btnRight, handleConfirmClick, actionName)}
    </Button.Group>
  }

  const bgDirect = () => {

    btnProps.btnRight.icon = previewClicked ? appIconTypes.cog : commitIcon
    btnProps.btnRight.color = previewClicked ? iconColorTypes.fetching : iconColorTypes.update

    const { btnAll, btnLeft, btnMiddle, btnRight, cn } = btnProps

    return <Button.Group icon fluid key={uniqueKey('gab', 'd')} className={cn}>
      {cancelButton && btnCancel()}
      {infoButton && btnInfo()}
      {btn(btnLeft, handleConfirmClick, actionName, btnAll)}
      {btn(btnMiddle, handleConfirmClick, actionName, btnAll)}
      {btn(btnRight, handleConfirmClick, actionName, btnAll, true)}
    </Button.Group>
  }

  const bgUpload = (btnProps) => {

    btnProps.cn += ' upload'
    btnProps.btnMiddle.color = iconColorTypes.update
    btnProps.btnRight.color = iconColorTypes.update

    const { btnMiddle, cn } = btnProps
    return <Button.Group icon fluid key={uniqueKey('gab', 'u')} className={cn} size={'tiny'}>
      {btn(btnMiddle)}
      {uploadButton}
    </Button.Group>
  }

  let cn = 'btn-confirm'

  if (infoButton) { cn += ' alt-ib' }
  if (cancelButton) { cn += ' alt-cb' }

  const btnProps = {
    confirmReady: handlePreview ? previewReady : true,
    btnAll: {
      color: iconColor ? iconColor : iconColorTypes.update,
    },
    btnLeft: {
      icon: defaultIcon ? defaultIcon : appIconTypes.cog,
      color: iconColorTypes.info
    },
    btnMiddle: {
      caption: confirmation ? confirmation.title : _.startCase(caption)
    },
    btnRight: (previewClicked ?
      {
        icon: appIconTypes.cog,
        color: iconColorTypes.fetching,
        loading: previewReady ? false : true,
      }
      :
      {
        icon: appIconTypes.arrowRight,
        color: previewClicked ? iconColorTypes.fetching : iconColorTypes.update,
        loading: previewClicked && !previewReady ? true : false,
      }
    ),
    btnHelp: {
      icon: showItemHelp ? appIconTypes.closeWindow : appIconTypes.help,
      color: showItemHelp ? iconColorTypes.default : iconColorTypes.help,
    },
    cn,
  }

  if (previewClicked) {
    // console.log(btnProps)
  }

  if (itemCount) { btnProps.btnMiddle.caption += ' (' + itemCount + ')' }

  if (confirmStarted) {
    return bgPending(btnProps)
  } else {
    if (directAction) {
      return bgDirect(btnProps)
    } else if (uploadButton) {
      return bgUpload(btnProps)
    } else {
      if (confirmation && isActiveAction) {
        return bgConfirmation(btnProps)
      } else {
        if (isActiveAction) {
          if (handleItemHelp) { btnProps.cn += ' help' }
          if (noCommit) {
            return bgNoConfirm(btnProps)
          } else {
            return bgConfirm(btnProps)
          }
        } else {
          return bgInit(btnProps)
        }
      }
    }
  }
}

export default GroupActionButton