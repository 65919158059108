import _ from 'lodash';
import { _timeZones, formatItem } from '../common/dateFormatting';
import { manifestSizes } from '../components/imaging/createManifestIcons';
import { gEnums, listTypes } from '../enums/globalEnums';
import { appFormTypes, appUserFormTypes } from '../enums/appFormTypes';
import { listHelpers } from '../lists/lists';
import { dataListOptionsList } from './dataListOptionsList';
import { _projectAppNames, _projectAppNamesNonDev } from '../../project/appConfiguration';

export const formOptionsList = (props) => {

  const { currentListProps, aps_global, aps_viewItems, staticViews, data_combined, updateProps, isDeveloper } = props
  const { dataCollectionName } = data_combined ?? {}

  const ol = {}

  currentListProps.forEach(viewProp => {
    const { data } = viewProp ?? {}
    const { listType, dataListType, labelProps } = data ?? {}
    if (labelProps) {
      labelProps.forEach(lp => {
        const { data: data_lp } = lp
        const { listType: listType_lp } = data_lp
        if (listTypes[listType_lp] && gEnums[listType_lp]) {
          ol[listType_lp] = createItemList(listType_lp, gEnums[listType_lp])
        }
      })
    } else {

      if (dataListType) {
        const optionProps = {
          itemProp: viewProp,
          aps_global,
          staticViews,
          data_combined,
          updateProps,
        }
        ol[listType] = listHelpers.listList(dataListOptionsList(optionProps))
      } else {
        switch (listType) {

          case listTypes.dataList:
            ol[listType] = listHelpers.listList(listTypes)
            break;
          case listTypes.manifestSizes:
            ol[listType] = listHelpers.listList(manifestSizes)
            break;
          case listTypes.timeZones:
            ol[listType] = listHelpers.listList(_timeZones)
            break;
          case listTypes.appFormTypes:
            ol[listType] = listHelpers.listList(appFormTypes)
            break;
          case listTypes.appUserFormTypes:
            ol[listType] = listHelpers.listList(appUserFormTypes)
            break;
          case listTypes.appNames:
            ol[listType] = listHelpers.listList(isDeveloper ? _projectAppNames : _projectAppNamesNonDev)
            break;
          case listTypes.dataFilters:
            const cvi = aps_viewItems && dataCollectionName ? aps_viewItems[dataCollectionName] : null
            if (cvi) {
              const { dataFilters } = cvi ?? {}
              if (dataFilters) {
                ol[listType] = listHelpers.listListO(dataFilters)
              }
            }
            break;
          case listTypes.notificationDeliveryTypes:
            const { appNotifications } = aps_global ?? {}
            const { allowPushNotifications, allowSmsNotifications, allowEmailNotifications } = appNotifications ?? {}
            if (listTypes[listType] && gEnums[listType]) {
              const ndtList = gEnums[listType]
              if (!allowPushNotifications) { delete ndtList.push }
              if (!allowSmsNotifications) { delete ndtList.sms }
              if (!allowEmailNotifications) { delete ndtList.email }
              ol[listType] = createItemList(listType, ndtList)
            }
            break;
          default:
            if (listTypes[listType] && gEnums[listType]) {
              ol[listType] = createItemList(listType, gEnums[listType])
            }
            break;
        }
      }
    }
  })

  return ol
}

const getText = (listType, key) => {
  switch (listType) {
    case listTypes.dateTypes:
      const d = Date.now()
      return _.startCase(key) + ' (' + formatItem(key, d) + ')'
    default:
      return _.startCase(key)
  }
}

const createItemList = (listType, list) => {

  const items = []

  if (_.isArray(list)) {
    list.forEach(key => {
      items.push({
        text: getText(listType, key),
        value: key,
        key: key,
      })
    })
  } else {
    Object.keys(list).forEach(key => {
      if (list[key].caption) {
        items.push({
          text: list[key].caption,
          value: list[key].value,
          key: list[key].key,
        })
      } else {
        items.push({
          text: getText(listType, key),
          value: key,
          key: key,
        })
      }
    })
  }
  return items
}
