import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'
import { SportsStandingsContext } from '../standings/LeagueStandings'

export const TeamRecord = (props) => {

  const {
    team,
    handleCheckClick,
    selected_teams } = props ?? {}

  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_fns } = sportsSeasonContext ?? {}
  const { matches_info } = sportsSeason_state ?? {}

  const sportsStandingsContext = useContext(SportsStandingsContext)
  const { sportsStandings_state } = sportsStandingsContext ?? {}
  const { commonOpponents, showSelected, showCommon, selectedLevelKey } = sportsStandings_state ?? {}

  const [teamRecord, setTeamRecord] = useState(props.teamRecord)

  const { name, results, nonResults } = teamRecord ?? {}
  const { section, nonSection } = results ?? {}
  const { section: section_nr, nonSection: nonSection_nr } = nonResults ?? {}

  useEffect(() => {
    const { matchesAll } = matches_info ?? {}
    if (team && matchesAll) {
      const _teamRecord = sportsSeason_fns ? sportsSeason_fns.getTeamRecord(team, matchesAll, selectedLevelKey) : {}
      setTeamRecord(_teamRecord)
      // const _trs = _.sortBy(_teamRecords, 'name')
      // setTeamRecords(_trs)
      // setTeamRecords_selected(_trs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [team]);

  const content_results = (rs) => {
    const _rstl = []
    if (rs) {
      const _rs = _.sortBy(rs, 'opponent.name')
      _rs.forEach(r => {
        const { opponent, score } = r
        const cn = showSelected && commonOpponents.includes(opponent.name) ? 'team-sel' : null
        if (showCommon) {
          if (commonOpponents.includes(opponent.name)) {
            _rstl.push(<div className={cn}>
              <div>{opponent.name}</div>
              <div>{score.away}{'-'}{score.home}</div>
            </div>)
          }
        } else {
          _rstl.push(<div className={cn}>
            <div>{opponent.name}</div>
            <div>{score.away}{'-'}{score.home}</div>
          </div>)
        }

      })
    }
    return _rstl
  }

  const content_nonResults = (rs) => {
    const _rstl = []
    if (rs) {
      rs.forEach(r => {
        const { opponent, startDate } = r
        _rstl.push(
          <div>{opponent.name}{': '}{startDate} </div>
        )
      })
    }
    return _rstl
  }

  const content_teamRecords_sn = (groupType, sectionGroup, sectionGroupNr) => {
    const divs = []
    const { wins, losses } = sectionGroup ?? {}
    divs.push(<div className='team-record-sec'>
      <div>{groupType}</div>
      <div className='team-record-results'>
        <div className='team-record-wl'>
          <div>{'Wins'}</div>
          <div className='team-record-opps win'>
            {content_results(wins)}
          </div>
        </div>
        <div>
          <div>{'Losses'}</div>
          <div className='team-record-opps loss'>
            {content_results(losses)}
          </div>
        </div>
      </div>
      {!showSelected && <div className='team-record-nonResults'>
        <div>{'Remaining'}</div>
        <div className='team-record-opps'>
          {content_nonResults(sectionGroupNr)}
        </div>
      </div>}
    </div>)
    return divs
  }

  const content_team = (nonResults) => {
    if (team) {
      const teamDivs = [
        ...content_teamRecords_sn('Section', section, section_nr),
        ...content_teamRecords_sn('Non-Section', nonSection, nonSection_nr)
      ]
      let cn = 'team-record'
      return <div className='team-records'>
        <div className={cn} onClick={(e) => !showSelected && handleCheckClick && handleCheckClick(e, teamRecord, true)}  >
          <div>
            {teamDivs}
          </div>
        </div>
      </div>
    } else {
      const checked = selected_teams && selected_teams.includes(teamRecord._itemKey)
      const teamDivs = [
        ...content_teamRecords_sn('Section', section, section_nr),
        ...content_teamRecords_sn('Non-Section', nonSection, nonSection_nr)
      ]
      let cn = 'team-record'
      if (checked) { cn += ' selected' }
      return <div className={cn} onClick={(e) => !showSelected && handleCheckClick && handleCheckClick(e, teamRecord, checked)}  >
        <div className='team-record-name'>{name}<Icon name={checked ? 'caret down' : 'caret right'} /></div>
        {checked && <div>
          {teamDivs}
        </div>}
      </div>
    }

  }

  return teamRecord ? content_team() : <div></div>

}
