import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Dropdown, Icon, Label, Menu, Segment } from 'semantic-ui-react'
import { uniqueKey } from '../../../../global/common/keys'
import { updateArrayInclusion } from '../../../../global/common/sorting'
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper'
import { SportsSeasonContext, matchScheduleTypes } from '../../cnr/contexts/SportsSeasonContext'
import { sportsShowTypes } from '../../cnr/reducers/SportsSeasonReducer'
import MatchScheduleWithProvider from '../../elements/matches/MatchSchedule'
import { CreatePlayoffContext } from '../CreatePlayoff'
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext'

const TeamMatchCompare = (props) => {

  const { inViewer, sportsShowType: sportsShowType_props, matchTeams, contentOnly } = props ?? {}

  const parentContext = useContext(ParentContext);
  const { fns } = parentContext ?? {}
  const { page_fns } = fns
  const { pushSimple } = page_fns ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { teams_info, sportsShowType, sportCompares } = sportsSeason_state ?? {}
  const { handleShow_sportsElem } = sportsSeason_handlers ?? {}
  const { teams } = teams_info ?? {}
  const { levelRecords, teamMatches, directMatches, commonMatches, opponentMatches, teamResults } = sportCompares ?? {}

  // createPlayoffContext
  const createPlayoffContext = useContext(CreatePlayoffContext)
  const { createPlayoff_state } = createPlayoffContext ?? {}
  const { playoff_info } = createPlayoff_state ?? {}
  const { playoffLevel, playoffLevelTeams, playoffTeamCompares } = playoff_info ?? {}
  const _playoffLevelTeams = playoffLevelTeams && playoffLevel && playoffLevelTeams[playoffLevel] ? playoffLevelTeams[playoffLevel] : null

  let _sportsShowType = sportsShowType_props ? sportsShowType_props : sportsShowType
  if (matchTeams) { _sportsShowType = sportsShowTypes.compareCommon }

  const [teamOptions, setTeamOptions] = useState()
  const [selectedTeams, setSelectedTeams] = useState([])
  const [topperCaption2, setTopperCaption2] = useState([])
  const [selectedTeamSchedules, setSelectedTeamSchedules] = useState([])

  useEffect(() => {

    const _teamOptions = []
    if (teams) {
      const _teams = _.sortBy(teams, 'name')
      _teams.forEach(team => {
        const { name, _itemKey } = team ?? {}
        _teamOptions.push({ key: _itemKey, text: name, value: _itemKey })
      })
    }

    switch (_sportsShowType) {
      case sportsShowTypes.compareCommon:
        break;

      case sportsShowTypes.compareTeams:
        setTopperCaption2('Common Opponents')
        setTeamOptions(_teamOptions)
        break;

      case sportsShowTypes.matchesRemaining:
        setTeamOptions(_teamOptions)
        break;

      case sportsShowTypes.viewSchedules:
        setTopperCaption2('Team Schedules')
        !_playoffLevelTeams && setTeamOptions(_teamOptions)
        break;

      case sportsShowTypes.compareSections:
        setTopperCaption2('Non Section Match Records')
        break;

      case sportsShowTypes.fullStandings:
        setTopperCaption2('Full Standings')
        break;
      default:
      // nothing
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [teams]);

  useEffect(() => {
    if (playoffTeamCompares) {
      const keys = _.map(playoffTeamCompares, item => item._itemKey)
      setSelectedTeams(keys)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [playoffTeamCompares]);


  useEffect(() => {
    if (matchTeams) {
      setSelectedTeams(matchTeams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (selectedTeams) {
      switch (_sportsShowType) {
        case sportsShowTypes.compareSections:
          sportsSeason_handlers.handleCalc_sectionCompares()
          break;
        case sportsShowTypes.compareTeams:
        case sportsShowTypes.compareCommon:
          if (selectedTeams && selectedTeams.length === 2) {
            sportsSeason_handlers.handleCalc_teamCompares(selectedTeams)
          } else {
            sportsSeason_handlers.handleCalc_teamCompares()
          }
          break;
        case sportsShowTypes.matchesRemaining:
          sportsSeason_handlers.handleCalc_matchesRemaining(selectedTeams)
          break;
        default:
        // nothing 
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedTeams]);

  const handleSelectTeam = (teamKey) => {
    const _selectedTeamSchedules = [...selectedTeamSchedules]
    updateArrayInclusion(_selectedTeamSchedules, teamKey)
    setSelectedTeamSchedules(_selectedTeamSchedules)
  }

  const handleTeamChange = (e, data) => {
    switch (_sportsShowType) {
      case sportsShowTypes.viewSchedules:
        setSelectedTeams(data.value)
        break;
      case sportsShowTypes.compareTeams:
        if (data.value && data.value.length > 2) {
          setSelectedTeams(data.value.slice(0, 2))
        } else {
          setSelectedTeams(data.value)
        }
        break;
      case sportsShowTypes.matchesRemaining:
        if (data.value && data.value.length > 5) {
          setSelectedTeams(data.value.slice(0, 5))
        } else {
          setSelectedTeams(data.value)
        }
        break;
      default:
      // nothing 
    }
  }

  const teamSchedules = () => {
    const mis = []
    if (teams) {
      selectedTeams.forEach(st => {
        const team = teams[st]
        const { name, _itemKey } = team ?? {}
        const _selected = selectedTeamSchedules.includes(_itemKey)
        mis.push(<Menu.Item key={uniqueKey('tsi', _itemKey)}>
          <Menu.Header onClick={(e) => { handleSelectTeam(_itemKey) }}>
            <div>{name}</div>
            <Icon name={_selected ? 'caret down' : 'arrow right'} style={{ float: 'right' }}></Icon>
          </Menu.Header>
          {_selected && <Menu.Menu>
            <Menu.Item key={uniqueKey('tsi', _itemKey, 's')} >
              <MatchScheduleWithProvider matchScheduleType={matchScheduleTypes.overview} teamKey={_itemKey} noMid={true} />
            </Menu.Item>
          </Menu.Menu>}
        </Menu.Item>)
      })
    }
    return <Menu vertical inverted fluid className='match-team-schedules'>
      {mis}
    </Menu>
  }

  const opponentTeamResults = (commonMatches, oppTeamKey) => {
    const teamMatches = {}
    _.forEach(selectedTeams, (selectedTeam, stk) => {
      const team = teams[selectedTeam]
      const oppTeam = teams[oppTeamKey]
      const { name: teamName } = team
      const { name: oppName } = oppTeam
      const matches = _.filter(commonMatches, (match) => _.includes(match.teams, selectedTeam) && _.includes(match.teams, oppTeamKey));
      teamMatches[selectedTeam] = {
        teamName,
        oppName,
        matches
      }
    })
    return teamMatches
  }

  const oppLabels = (_xxx) => {

    const teamDivs = []

    const awayTeam = _.find(teams, { _itemKey: selectedTeams[0] })
    const homeTeam = _.find(teams, { _itemKey: selectedTeams[1] })

    let _oppName;
    _.forEach(_xxx, (teamMatches, oppName) => {
      _oppName = oppName

      const _team = _.find(teams, { name: oppName })
      const { _itemKey: _oppKey } = _team ?? {}

      const divsLeft = []
      const divsRight = []

      _.forEach(teamMatches, (teamLabels, index) => {
        _.forEach(teamLabels, (opp) => {
          switch (index) {
            case selectedTeams[0]:
              divsLeft.push(opp.label)
              break
            case selectedTeams[1]:
              divsRight.push(opp.label)
              break;
            default:
            // nothing
          }
        })
      })
      teamDivs.push(
        <div className='tmc-opp'>
          <div>{divsLeft}</div>
          <div onClick={(e) => { pushSimple && sportsSeason_handlers.handleGoTo_team(pushSimple, { id: _oppKey }) }}>{_oppName}</div>
          <div>{divsRight}</div>
        </div>
      )
    })

    return <div className='tmc-opps'>
      <div className='tmc-opp-teams'>
        <div>{awayTeam && awayTeam.name}</div>
        <div>{homeTeam && homeTeam.name}</div>
      </div>
      {teamDivs}
    </div>
  }


  const opponentSchedule_short = () => {

    const _opponentMatches = {}
    const _opponentLables = []

    if (opponentMatches) {
      Object.keys(opponentMatches).forEach(oppTeamKey => {
        const team = teams[oppTeamKey]
        const { name: opponentName } = team ?? {}
        _opponentMatches[opponentName] = {}
        const _commonMatches = opponentMatches[oppTeamKey]
        if (opponentName && _commonMatches) {

          const teamOpponentMatches = opponentTeamResults(commonMatches, oppTeamKey)

          const oppLs = {}
          // teams
          _.forEach(teamOpponentMatches, (oppMatches, teamKey) => {

            oppLs[teamKey] = []
            const { teamName, oppName, matches } = oppMatches

            _.forEach(matches, (oppMatch) => {
              const { _results } = oppMatch
              const { winner, scoreDisplays } = _results ?? {}
              const { normal } = scoreDisplays ?? {}
              const isWinner = winner && (teamKey === winner.id)
              let cn = 'tmcd'
              cn += isWinner ? ' w' : ' l'
              oppLs[teamKey].push({
                teamName,
                opponentName,
                label: <Label className={cn} >{normal}</Label>
              })
            })
          })

          _opponentMatches[opponentName] = oppLs

        }
      })
      _opponentLables.push(oppLabels(_opponentMatches))
    }
    return _opponentLables.length > 0 ? _opponentLables : 'No Common Opponents'
  }

  const opponentSchedule = () => {
    const opps = []
    if (opponentMatches) {
      Object.keys(opponentMatches).forEach(oppTeamKey => {
        const team = teams[oppTeamKey]
        const { name: opponentName } = team ?? {}
        const _commonMatches = opponentMatches[oppTeamKey]
        if (opponentName && _commonMatches) {
          opps.push(<Segment.Group>
            <Segment>{opponentName}</Segment>
            <Segment><MatchScheduleWithProvider componentContexts={{}} commonMatches={_commonMatches} /></Segment>
          </Segment.Group>)
        }
      })
    }
    return opps
  }

  const teamsSchedule = () => {
    const opps = []
    if (teamMatches) {
      _.forEach(teamMatches, (_teamMatches, key) => {
        const team = teams[key]
        const { name } = team ?? {}
        if (name && _teamMatches) {
          opps.push(<Segment.Group>
            <Segment>{name}</Segment>
            <Segment><MatchScheduleWithProvider componentContexts={{}} commonMatches={_teamMatches} /></Segment>
          </Segment.Group>)
        }
      })
    }
    return opps
  }

  const teamsResults = () => {
    const segs = []
    if (teamResults) {
      _.forEach(teamResults, (tr, key) => {
        const { wp } = tr
        const _team = teams[key]
        const { name } = _team
        segs.push(<Segment>{name}{' ('}{wp ? wp.toFixed(3) : 'none'}{')'}</Segment>)
      })
    }
    return <Segment.Group>
      <Segment>{'Winning Percentages (common)'}</Segment>
      {segs}
    </Segment.Group>
  }

  const schedules = () => <div className='match-team-container'>
    {teamsResults()}
    <Segment.Group>
      <Segment>{'Direct'}</Segment>
      <Segment><MatchScheduleWithProvider componentContexts={{}} commonMatches={directMatches} /></Segment>
    </Segment.Group>
    {opponentSchedule_short()}
    {opponentSchedule()}
  </div>

  const sortByKeys = (items, reverse) => {
    if (reverse) {
      return _.fromPairs(
        _.reverse(_.sortBy(_.toPairs(items), 0))
      )
    } else {
      return _.fromPairs(
        _.sortBy(_.toPairs(items), 0)
      )
    }
  };

  const records_level = () => {
    const groups = []
    const _levelRecords = sortByKeys(levelRecords, true)
    _.forEach(_levelRecords, (lr, lrKey) => {
      groups.push(<Segment>{lrKey}{' (sorted by winning %)'}</Segment>)
      delete lr.Non
      const _lr = _.orderBy(lr, ['wp'], ['desc'])
      _.forEach(_lr, (sr, srKey) => {
        switch (srKey) {
          case 'Non':
            break;
          default:

            const { l, w, tm, wp, sectionKey } = sr ?? {}
            const _lr = lrKey + ' - ' + _.startCase(sectionKey)
            groups.push(
              <Segment.Group>
                <Segment>{_lr}</Segment>
                <Segment className='tmcl'>
                  <Label color={'black'}><span>{'Matches'}</span><span>{tm}</span></Label>
                  <Label color={'green'}><span>{'Wins'}</span><span>{w}</span></Label>
                  <Label color={'red'}><span>{'Losses'}</span><span>{l}</span></Label>
                  <Label color={'grey'}><span>{'Winning %'}</span><span>{wp}</span></Label>
                </Segment>
              </Segment.Group>
            )
        }
      })
    })
    return groups
  }

  const content = () => {
    switch (_sportsShowType) {
      case sportsShowTypes.compareCommon:
        return (commonMatches && _.size(commonMatches) > 0) ? opponentSchedule_short() : <div>d</div>
      case sportsShowTypes.viewSchedules:
        return teamSchedules()
      case sportsShowTypes.compareSections:
        return levelRecords ? records_level() : <div></div>
      case sportsShowTypes.compareTeams:
        return (commonMatches || directMatches) ? schedules() : <div></div>
      case sportsShowTypes.matchesRemaining:
        return teamMatches ? teamsSchedule() : <div></div>
      default:
      // nothing 
    }
  }

  const ddTeams = () => <Dropdown
    placeholder='Teams'
    fluid
    multiple
    selection
    options={teamOptions}
    value={selectedTeams}
    onChange={handleTeamChange}
  />

  const header = () => {
    if (teamOptions) {
      return ddTeams()
    }
    switch (_sportsShowType) {
      case sportsShowTypes.compareCommon:
        return 'Common Opponents'
      default:
      // nothing
    }
  }

  const wrapper = () => <Wrapper
    header={header()}
    content={content()}
    wrapperType={wrapperTypes.padded}
    wrapperCss={{ container: 'app-dark-mode' }}
  ></Wrapper>

  const fpw = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleShow_sportsElem}
    topperCaption={_.startCase(_sportsShowType)}
    topperCaption2={topperCaption2}
    wrapperCss={{ container: 'app-dark-mode' }}
  ></FullPageWrapper>

  if (contentOnly) {
    return content()
  } else {
    return inViewer ? wrapper() : fpw()
  }
}

export default TeamMatchCompare