import _ from 'lodash';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from '../../../../global/cnr/reducers/reducerHelpers/dispatchProps';
import { convertHelpers, getGoogleSheetDataValues } from '../../../../global/common/convert';
import { fsfn_sheets } from '../../../../global/functions/fbSheetsFuntions';
import { _sdm } from '../../elements/dashboard/SportsDashboard';
import { _sportsFormProps } from '../contexts/SportsSeasonContext';
import { _districtCollections } from './SportsDistrictReducer';
import { seasonalHelpers, seasonalUpdateTypes } from './reducerHelpers/seasonalSportsData';

export const _sportCollections_pks = {
  districtContacts: 'districtContacts',
  gls_scores: 'gls_scores',
  gls_teams: 'gls_teams',
  news: 'news',
  schoolContacts: 'schoolContacts',
  sportsAccess_district: 'sportsAccess_district',
  sportsAccess_schools: 'sportsAccess_schools',
  sportsAccess_sports: 'sportsAccess_sports',
  sportsSettings_rosters: 'sportsSettings_rosters',
  teamRosters: 'teamRosters',
  tournaments: 'tournaments',
}

export const _sportCollections = {
  _history: '_history',
  _matches_seasonal: '_matches_seasonal',
  _rankings: '_rankings',
  _rosters: '_rosters',
  _schools: '_schools',
  _scores_seasonal: '_scores_seasonal',
  _seasonals: '_seasonals',
  _sportsAccess_sports: '_sportsAccess_sports',
  _teams: '_teams',
  _tournaments: '_tournaments',
}

export const sportAccessTypes = {
  none: 'none',
  team: 'team',
  sport: 'sport',
  school: 'school',
  tournament: 'tournament',
  accessLink: 'accessLink',
  scoreLink: 'scoreLink',
  googleLink: 'googleLink',
  tournamentResults: 'tournamentResults',
  teamRoster: 'teamRoster',
}

const _accessOptions = {
  schools: {
    edit: 'edit',
    full: 'full',
    scores: 'scores',
  },
  sport: {
    edit: 'edit',
    full: 'full',
    scores: 'scores',
  },
  team: {
    edit: 'edit',
    full: 'full',
    scores: 'scores',
  }
}

const rts = {
  handleConfirm_save: 'handleConfirm_save',
  handleConfirm_teamSettings: 'handleConfirm_teamSettings',
  handleCreate_gsTeamSheetsWithProtection: 'handleCreate_gsTeamSheetsWithProtection',
  handleGet_gsSheetData: 'handleGet_gsSheetData',
  handleGet_gsTabValues: 'handleGet_gsTabValues',
  handleInit_sportsSidebar: 'handleInit_sportsSidebar',
  handleSave_accessInfoToDatabase: 'handleSave_accessInfoToDatabase',
  handleSave_glsToDatabase: 'handleSave_glsToDatabase',
  handleSave_teamSettings: 'handleSave_teamSettings',
  handleSet_currentMenuKey: 'handleSet_currentMenuKey',
  handleSet_formData: 'handleSet_formData',
  handleSet_gsSheetData: 'handleSet_gsSheetData',
  handleSet_gsTabValues: 'handleSet_gsTabValues',
  handleShow_createNew: 'handleShow_createNew',
  handleUpdate_gsDistrictAccess: 'handleUpdate_gsDistrictAccess',
  ...grts
}

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @returns state
 * @description a sports season consists of multiple collections (teams, matches, rankings, history, scheduleDates, playoffs)
 * 
 */
export const sportsSidebarReducer = (state, action) => {

  const { menuItems, sportsDataMode, current_accessOrGls, currentMenuKey, tournamentResults, sportPermissions } = state
  const { type, dispatch } = action

  const { handleConfirm_save, handleConfirm_teamSettings, handleSet_gsSheetData, handleSet_gsTabValues } = sportsSidebarHandlers(dispatch)

  switch (type) {

    case rts.handleInit_sportsSidebar:
      const _initState = getInitState(action, state)
      const _formProps = getSportsFormProps(_initState)
      return {
        ...state,
        ..._initState,
        access_teams: action.access_teams,
        access_sports: action.access_sports,
        access_schools: action.access_schools,
        sportAccessType: action.sportAccessType,
        sportsDataMode: action.sportsDataMode,
        formProps: _formProps,
        currentItem: null,
        currentMenuKey: null,
        showCreateNew: false,
        formData: null
      }

    case rts.handleSet_currentMenuKey:
      const _formData = getFormData(action.currentMenuKey, state)
      const _currentItem = menuItems[action.currentMenuKey]
      return {
        ...state,
        formData: _formData,
        currentItem: _currentItem,
        currentMenuKey: action.currentMenuKey
      }

    case rts.handleSet_formData:

      const _fd = action.formData
      const { teamAccess } = _fd
      const _current_accessOrGls = current_accessOrGls ? { ...current_accessOrGls } : {}

      switch (sportsDataMode) {

        case _sdm.district.accessSchools:
        case _sdm.district.accessSport:
        case _sdm.sports.accessTeams:
          _current_accessOrGls[currentMenuKey] = teamAccess
          return { ...state, formData: teamAccess, current_accessOrGls: _current_accessOrGls, menuObjects: _current_accessOrGls }

        case _sdm.district.glsScores:
        case _sdm.sports.news:
          _current_accessOrGls[currentMenuKey] = _fd
          return { ...state, formData: _fd, current_accessOrGls: _current_accessOrGls, menuObjects: _current_accessOrGls }

        case _sdm.sports.tournamentResults:
          const _tournamentResults = { ...tournamentResults }
          _current_accessOrGls[currentMenuKey] = _fd
          _tournamentResults[currentMenuKey] = _fd
          return { ...state, tournamentResults: _tournamentResults, formData: _fd, current_accessOrGls: _current_accessOrGls, menuObjects: _current_accessOrGls }

        default:
        // nothing
        // case _sdm.district.glsScores:
        //   const _glsScores = { ...glsScores }
        //   if (!_glsScores[currentMenuKey]) { _glsScores[currentMenuKey] = {} }
        //   _glsScores[currentMenuKey] = _fd
        //   return { ...state, formData: _fd, glsScores: _glsScores, menuObjects: _glsScores }

        // case _sdm.sports.glsTeam:
        //   const _glsTeams = { ...glsTeams }
        //   if (!_glsTeams[currentMenuKey]) { _glsTeams[currentMenuKey] = {} }
        //   _glsTeams[currentMenuKey] = _fd
        //   return { ...state, formData: _fd, glsTeams: _glsTeams, menuObjects: _glsTeams }

      }
      return { ...state, formData: _fd }

    case rts.handleSave_glsToDatabase:
      saveGlsDataToDatabase(state, action, handleConfirm_teamSettings)
      return { ...state }

    case rts.handleSave_teamSettings:
      saveSettingsData(state, action, handleConfirm_teamSettings)
      return { ...state }

    case rts.handleConfirm_teamSettings:
      return { ...state, updating: null }

    case rts.handleSave_accessInfoToDatabase:
      saveAccessInfoToDatabase(state, handleConfirm_save)
      return { ...state }

    case rts.handleConfirm_save:
      return { ...state, updating: null }

    case rts.handleShow_createNew:
      console.log('action.currentMenuKey', action.currentMenuKey)
      const _formPropz = getSportsFormProps(state)
      const _formDataa = getFormData(action.currentMenuKey, state)
      const _menuItems = { ...menuItems }
      _menuItems['newItem'] = { name: 'newItem', _itemKey: 'newItem' }
      return { ...state, showCreateNew: !state.showCreateNew, formProps: _formPropz, formData: _formDataa, menuItems: _menuItems }

    case rts.handleGet_gsTabValues:
      getGsTabValues(action.settings, action.sheetType, sportsDataMode, handleSet_gsTabValues)
      return { ...state }

    case rts.handleSet_gsTabValues:
      return { ...state, [action.sheetName]: action.results, sheetType: action.sheetType, updating: null }

    case rts.handleGet_gsSheetData:
      getGsSheetData(action, handleSet_gsSheetData)
      return { ...state }

    case rts.handleSet_gsSheetData:
      const _sheetsData = state.sheetsData ? { ...state.sheetsData } : {}
      _sheetsData[action.sheetName] = action.results
      return { ...state, sheetsData: _sheetsData, updating: null, current_accessOrGls: action.results }

    case rts.handleCreate_gsTeamSheetsWithProtection:
      createGsTeamSheetsWithProtections(action)
      return { ...state }

    case rts.handleUpdate_gsDistrictAccess:
      updateGsDistrictAccess(action, state)
      return { ...state }

    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation }, { gsMatchedRefreshed: false })

    default:
      return { ...state }
  }
}

export const sportsSidebarHandlers = (dispatch) => {
  return {
    handleConfirm_save: () => { dispatch({ type: rts.handleConfirm_save, dispatch }) },
    handleConfirm_teamSettings: () => { dispatch({ type: rts.handleConfirm_teamSettings, dispatch }) },
    handleCreate_gsTeamSheetsWithProtection: (access_teams, teams) => { dispatch({ type: rts.handleCreate_gsTeamSheetsWithProtection, dispatch, access_teams, teams }) },
    handleGet_gsSheetData: (googleSheetsId, sheetName, sheetKey) => { dispatch({ type: rts.handleGet_gsSheetData, dispatch, googleSheetsId, sheetName, sheetKey }) },
    handleGet_gsTabValues: (settings, sheetType) => { dispatch({ type: rts.handleGet_gsTabValues, dispatch, settings, sheetType }) },
    handleInit_sportsSidebar: (sportsDataMode, sports_current, access_teams, access_sports, access_schools) => { dispatch({ type: rts.handleInit_sportsSidebar, dispatch, sportsDataMode, sports_current, access_teams, access_sports, access_schools }) },
    handleSave_accessInfoToDatabase: () => { dispatch({ type: rts.handleSave_accessInfoToDatabase, dispatch }) },
    handleSave_glsToDatabase: (data) => { dispatch({ type: rts.handleSave_glsToDatabase, dispatch, data }) },
    handleSave_teamSettings: (sheetType, data, currentMenuKey) => { dispatch({ type: rts.handleSave_teamSettings, dispatch, sheetType, data, currentMenuKey }) },
    handleSet_currentMenuKey: (currentMenuKey) => { dispatch({ type: rts.handleSet_currentMenuKey, dispatch, currentMenuKey }) },
    handleSet_formData: (formData) => { dispatch({ type: rts.handleSet_formData, dispatch, formData }) },
    handleSet_gsSheetData: (sheetName, results, sheetKey) => { dispatch({ type: rts.handleSet_gsSheetData, dispatch, sheetName, results, sheetKey }) },
    handleSet_gsTabValues: (sheetName, sheetType, results) => { dispatch({ type: rts.handleSet_gsTabValues, dispatch, sheetName, sheetType, results }) },
    handleShow_createNew: () => { dispatch({ type: rts.handleShow_createNew, dispatch }) },
    handleUpdate_gsDistrictAccess: (current_accessOrGls, globalItems, globalItem, sport) => { dispatch({ type: rts.handleUpdate_gsDistrictAccess, dispatch, current_accessOrGls, globalItems, globalItem, sport }) },
    ...responseHandlers(dispatch)
  }
}

export const sportsSidebarInitialState = (init_state) => {
  return { ...init_state }
};

const getInitState = (action, state) => {

  const { sportsDataMode, sports_current, access_teams, access_sports, access_schools } = action

  const {
    database_fns,
    parent: {
      access: {
        access_schools_schools,
      },
      gls: {
        gls_scores,
        gls_teams,
      },
    },
    news,
    pathViews,
    schools_district,
    sportsKey,
    rosters,
    teams,
    tournaments,
  } = state

  let sportAccessType;

  switch (sportsDataMode) {
    case _sdm.sports.teamRosters:
      sportAccessType = sportAccessTypes.team
      break;

    // case _sdm.sports.teamRosters:
    //   sportAccessType = sportAccessTypes.teamRoster
    //   break;

    case _sdm.district.glsScores:
      sportAccessType = sportAccessTypes.scoreLink
      break;

    case _sdm.district.districtSchools:
    case _sdm.district.accessSchools:
    case _sdm.district.contactsSchools:
      sportAccessType = sportAccessTypes.school
      break;

    case _sdm.district.accessSport:
      sportAccessType = sportAccessTypes.sport
      break;

    case _sdm.sports.accessTeams:
      sportAccessType = sportAccessTypes.team
      break;

    case _sdm.district.updateDistrictSeasonScores:
      sportAccessType = sportAccessTypes.none
      break;

    case _sdm.sports.tournamentResults:
      sportAccessType = sportAccessTypes.tournamentResults
      break;

    case _sdm.sports.news:
      sportAccessType = sportAccessTypes.news
      break;

    default:
    // nothing
  }

  const _isSport = pathViews && pathViews.sports ? true : false

  const sportsMode = {
    organization: pathViews && pathViews.organizations && !pathViews.districts && !pathViews.schools && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    district: pathViews && pathViews.districts && !pathViews.schools && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    match: pathViews && pathViews.matches ? true : false,
    school: pathViews && pathViews.schools ? true : false,
    sport: pathViews && pathViews.sports ? true : false,
    team: false,
  }

  const _initState = { ...state, sportsDataMode };

  switch (sportsDataMode) {

    case _sdm.district.glsScores:
      _initState.glsScores = gls_scores
      ammendInitState(_initState, sports_current, gls_scores, gls_scores, null, _accessOptions.team, 'Update Score Settings', _sportCollections_pks.gls_scores)
      break;

    case _sdm.sports.glsTeam:
      _initState.glsTeams = gls_teams
      ammendInitState(_initState, teams, gls_teams, gls_teams, null, _accessOptions.team, 'Update Team Settings', _sportCollections_pks.gls_teams)
      break;

    case _sdm.sports.teamRosters:
      const { teams: teamRosters } = gls_teams ?? {}
      _initState.teamRosters = teamRosters
      ammendInitState(_initState, teams, rosters, rosters, null, null, 'Update Team Rosters', _sportCollections_pks.teamRosters, true)
      break;

    case _sdm.sports.tournamentResults:
      convertHelpers.createItemKeys(tournaments)
      _initState.tournamentResults = tournaments
      ammendInitState(_initState, tournaments, tournaments, tournaments, null, null, 'Update Tournament Results', _sportCollections_pks.tournaments)
      break;

    case _sdm.sports.news:
      convertHelpers.createItemKeys(news)
      _initState.news = news
      ammendInitState(_initState, news, news, news, null, null, 'Update News', _sportCollections_pks.news)
      break;

    case _sdm.district.updateDistrictSeasonScores:
      ammendInitState(_initState, sports_current, null, null, null, _accessOptions, 'Update Team Settings', _sportCollections_pks.gls_teams, null, true)
      break;

    case _sdm.district.contactsSchools:
      ammendInitState(_initState, schools_district, null, null, 'contactsSchools', _accessOptions, 'Update School Contact Settings', _sportCollections_pks.schoolContacts)
      break;

    case _sdm.district.districtSchools:
      ammendInitState(_initState, schools_district, null, null, 'contactsDistrict', _accessOptions, 'Update District Contact Settings', _sportCollections_pks.districtContacts)
      break;

    case _sdm.district.sportsData:
      const tps = {}
      const _menuItems = _isSport ? _sportCollections : _districtCollections
      _.forEach(_menuItems, function (k, v) {
        tps[k] = { name: k, _itemKey: k }
        switch (k) {
          case _sportCollections._matches_seasonal:
            tps[k].subCollection = 'matches'
            break;
          case _sportCollections._scores_seasonal:
            tps[k].subCollection = 'scores'
            break;
          default:
          // nothing
        }
      })
      ammendInitState(_initState, tps, null, null, null, _accessOptions, 'Export Data', _sportCollections_pks.gls_teams, true, true)
      break;

    default:
      let items = []

      switch (sportsDataMode) {

        case _sdm.district.glsDistrict:
          items = { selectSport: { name: 'District', _itemKey: 'selectDistrict' } }
          break;

        default:
          switch (sportAccessType) {

            case sportAccessTypes.school:
              ammendInitState(_initState, schools_district, access_schools, access_schools, 'accessSchools', _accessOptions.schools, 'Update School Access', _sportCollections_pks.sportsAccess_district)
              break;

            case sportAccessTypes.sport:
              if (sportsMode.district) {
                ammendInitState(_initState, sports_current, access_sports, access_sports, 'accessSport', _accessOptions.sport, 'Update Sport Access', _sportCollections_pks.sportsAccess_district)
              } else if (sportsMode.school) {
                const _sportAccess = access_schools_schools ? access_schools_schools[pathViews.schools] : {}
                ammendInitState(_initState, sports_current, _sportAccess, _sportAccess, 'accessSport', _accessOptions.sport, 'Update Sport Access', _sportCollections_pks.sportsAccess_schools)
              }
              break;

            case sportAccessTypes.team:
              ammendInitState(_initState, teams, access_teams, access_teams, 'teamAccess', _accessOptions.team, 'Update Team Access', _sportCollections_pks.sportsAccess_sports)
              break;

            case sportAccessTypes.scoreLink:
              const cb_scoreLink = (data) => {
                const { sportsSeasonalData } = data
                const { sports: sports_access } = sportsSeasonalData ?? {}
                ammendInitState(_initState, sports_current, sports_access, sports_access, 'accessSport', _accessOptions.team, 'Update Sport Links', _sportCollections_pks.gls_scores)
              }

              const _pks_sl = seasonalHelpers.getPks(pathViews, null, sportsKey, ['teams'])
              seasonalHelpers.getSports_collectionItem(database_fns, pathViews, _pks_sl, '_' + _sportCollections_pks.gls_scores, true, null, cb_scoreLink)
              break;


            default:
            // nothing
          }
      }
  }

  return _initState
}

const ammendInitState = (uds, menuItems, menuObjects, current_accessOrGls, propName, accessOptions, saveCaption, collectionName, isContent, showItemKey) => {
  uds.current_accessOrGls = current_accessOrGls
  uds.accessOptions = accessOptions
  uds.collectionName = collectionName
  uds.isContent = isContent
  uds.menuItems = menuItems
  uds.menuObjects = menuObjects
  uds.propName = propName
  uds.saveCaption = saveCaption
  uds.showItemKey = showItemKey
}

const getSportsFormProps = (state) => {

  const { sportsDataMode, sportAccessType, propName } = state

  let form_props;

  switch (sportsDataMode) {
    case _sdm.district.glsScores:
      form_props = _sportsFormProps.glsScores
      break;

    case _sdm.sports.glsTeam:
      form_props = _sportsFormProps.glsTeam
      break;

    case _sdm.sports.tournamentResults:
      form_props = _sportsFormProps.tournament
      break;

    case _sdm.sports.sportsSettings:
      form_props = _sportsFormProps.sportsSettings
      break;

    case _sdm.sports.news:
      form_props = _sportsFormProps.news
      break;

    case _sdm.district.updateDistrictSeasonScores:
      form_props = _sportsFormProps.scoreLink
      break;

    default:
      switch (sportAccessType) {
        case sportAccessTypes.accessLink:
          form_props = _sportsFormProps.glsDistrict
          break;
        case sportAccessTypes.scoreLink:
          form_props = _sportsFormProps.glsScores
          break;

        default:
          form_props = propName ? _sportsFormProps[propName] : _sportsFormProps.access
      }
  }

  return form_props

}

/**
 * 
 * @param {string} currentMenuKey 
 * @param {object} state 
 * @returns the formData object
 */
const getFormData = (currentMenuKey, state) => {

  const {
    formProps,
    propName,
    current_accessOrGls,
    sportsDataMode,
  } = state

  let _formData = {};
  let _currentData = {}
  let _ammendData = true

  if (current_accessOrGls && currentMenuKey && current_accessOrGls[currentMenuKey]) {
    _currentData = current_accessOrGls[currentMenuKey]
  }

  switch (sportsDataMode) {
    case _sdm.sports.accessTeams:
    case _sdm.district.accessSchools:
    case _sdm.district.accessSport:
      _ammendData = false
      break;
    default:
    // nothing
  }

  if (_ammendData) {
    ammendFormData(_formData, formProps, _currentData, propName)
  } else {
    _formData = _currentData
  }

  return _formData

}

const ammendFormData = (formData, form_props, data, propName) => {
  Object.keys(form_props).forEach(fp => {
    switch (fp) {
      case [propName]:
        formData[fp] = data && data[fp] ? [...data[fp]] : []
        break;
      default:
        formData[fp] = data && data[fp] ? data[fp] : ''
    }
  })
}

const saveGlsDataToDatabase = (state, action, callback) => {

  const { data } = action
  const { pathViews, collectionName, sportPermissions } = state
  const options = {
    ignoreSeason: true
  }

  const cb = () => callback()

  let _pks = seasonalHelpers.getPks(pathViews)

  const _updateData = {
    [collectionName]: data
  }

  if (collectionName && _updateData) {
    const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, _pks, cb, collectionName, null, _updateData, options)
    seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.teamRosters, _updateProps)
  }

}

const saveSettingsData = (state, action, callback) => {

  const { sheetType, data, currentMenuKey } = action
  const { pathViews, selectedSeason, sportsKey, collectionName, sportPermissions } = state

  const cb = () => callback()

  let _collectionName;
  let _updateData;
  let _pks = seasonalHelpers.getPks(pathViews, selectedSeason, sportsKey, ['teams'])
  const options = {}

  switch (sheetType) {
    case _sportsFormProps.glsTeam.rosterSheet:
      _collectionName = 'teamRosters'
      _updateData = {
        [_collectionName]: {
          [currentMenuKey]: data
        }
      }
      break;
    case _sportsFormProps.glsTeam.staffSheet:
      _collectionName = 'teamStaff'
      _updateData = {
        [_collectionName]: {
          [currentMenuKey]: data
        }
      }
      break;
    default:
      _collectionName = collectionName
      _updateData = {
        [_collectionName]: data
      }
      options.ignoreSeason = true
    // nothing 
  }

  if (collectionName && _updateData) {
    const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, _pks, cb, collectionName, null, _updateData, options)
    seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.teamRosters, _updateProps)
  }

}

const saveAccessInfoToDatabase = (state, callback) => {

  const {
    glsScores,
    collectionName,
    currentMenuKey,
    formData,
    pathViews,
    selectedSeason,
    sheetsData,
    sportAccessType,
    current_accessOrGls,
    sportsDataMode,
    sportsKey,
    glsTeams,
    tournamentResults,
    sportPermissions,
  } = state

  const { schoolsContacts, districtContacts } = sheetsData ?? {}

  const sportsMode = {
    organization: pathViews && pathViews.organizations && !pathViews.districts && !pathViews.schools && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    district: pathViews && pathViews.districts && !pathViews.schools && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    match: pathViews && pathViews.matches ? true : false,
    school: pathViews && pathViews.schools ? true : false,
    sport: pathViews && pathViews.sports ? true : false,
    team: false,
  }

  const cb = () => callback()

  let _updateData;
  let _pks;

  const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, null, cb, null, null, null, { ignoreSeason: true })

  switch (sportsDataMode) {

    case _sdm.district.glsScores:
      _updateData = { glsScores: glsScores }
      _pks = seasonalHelpers.getPks(pathViews)
      seasonalHelpers.ammendUpdateProps(_updateProps, _pks)
      seasonalHelpers.ammendUpdateNameProps(_updateProps, collectionName, null, _updateData)
      seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.sportsAccess, _updateProps)
      break;

    case _sdm.district.contactsDistrict:
      _updateData = { districts: districtContacts }
      _pks = seasonalHelpers.getPks(pathViews)
      seasonalHelpers.ammendUpdateProps(_updateProps, _pks)
      seasonalHelpers.ammendUpdateNameProps(_updateProps, collectionName, null, _updateData)
      seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.sportsAccess, _updateProps)
      break;

    case _sdm.district.contactsSchools:
      _updateData = { schools: schoolsContacts }
      _pks = seasonalHelpers.getPks(pathViews, null, null, ['schools'])
      seasonalHelpers.ammendUpdateProps(_updateProps, _pks)
      seasonalHelpers.ammendUpdateNameProps(_updateProps, collectionName, null, _updateData)
      seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.sportsAccess, _updateProps)
      break;

    case 'teamSettings':
      _updateData = { teams: glsTeams }
      _pks = seasonalHelpers.getPks(pathViews, null, sportsKey, ['teams'])
      seasonalHelpers.ammendUpdateProps(_updateProps, _pks)
      seasonalHelpers.ammendUpdateNameProps(_updateProps, collectionName, null, _updateData)
      seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.sportsAccess, _updateProps)
      break;

    case 'tournamentResults':
      if (tournamentResults) {
        Object.keys(tournamentResults).forEach(trk => {
          const tr = tournamentResults[trk]
          const { name } = tr
          switch (trk) {
            case 'newItem':
              tournamentResults[name] = tr
              delete tournamentResults[trk]
              break;
            default:
            // nothing
          }
        })
      }
      _updateData = { tournaments: tournamentResults }
      _pks = seasonalHelpers.getPks(pathViews, selectedSeason, sportsKey)
      seasonalHelpers.ammendUpdateProps(_updateProps, _pks)
      seasonalHelpers.ammendUpdateNameProps(_updateProps, collectionName, null, _updateData)
      seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.tournamentResults, _updateProps)
      break;

    case _sdm.sports.teamRosters:
      // _updateData = { ['teamRosters']: current_accessOrGls } 
      _updateData = { ['teamRosters.' + currentMenuKey]: current_accessOrGls }
      _pks = seasonalHelpers.getPks(pathViews, selectedSeason, sportsKey, ['schools', 'teams'])
      seasonalHelpers.ammendUpdateProps(_updateProps, _pks)
      seasonalHelpers.ammendUpdateNameProps(_updateProps, collectionName, null, _updateData)
      seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.teamRosters, _updateProps)
      break;

    default:
      switch (sportAccessType) {

        case sportAccessTypes.school:
          _updateData = { schools: current_accessOrGls }
          _pks = seasonalHelpers.getPks(pathViews, null, null, ['schools'])
          break;

        case sportAccessTypes.sport:
          if (sportsMode.school) {
            _updateData = { ['schools.' + pathViews.schools]: current_accessOrGls }
          } else if (sportsMode.district) {
            _updateData = { sports: current_accessOrGls }
          }
          _pks = seasonalHelpers.getPks(pathViews, null, null, ['schools'])
          break;

        case sportAccessTypes.team:
          _updateData = { teams: current_accessOrGls }
          _pks = seasonalHelpers.getPks(pathViews, null, sportsKey, ['teams'])
          break;

        case sportAccessTypes.googleLink:
        case sportAccessTypes.accessLink:
          _updateData = { sports: current_accessOrGls }
          _pks = seasonalHelpers.getPks(pathViews, null, sportsKey, ['teams'])
          break;

        case sportAccessTypes.scoreLink:
          _updateData = { ['sports.' + currentMenuKey]: formData }
          _pks = seasonalHelpers.getPks(pathViews, null, sportsKey, ['teams'])
          break;

        default:
        // notthing

      }

      seasonalHelpers.ammendUpdateProps(_updateProps, _pks)
      seasonalHelpers.ammendUpdateNameProps(_updateProps, collectionName, null, _updateData)
      seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.sportsAccess, _updateProps)
  }
}

const getGsSheetData = (action, callback) => {

  const { googleSheetsId, sheetName, sheetKey } = action

  const cb = (results) => {
    callback(_.camelCase(sheetName), results, sheetKey)
  }
  const formData = {
    sheetName: sheetName,
    googleSheetsId: googleSheetsId
  }
  fsfn_sheets.getGoogleSheetRange(formData, cb, null, true)
}

const getGsTabValues = (settings, sheetType, sportsDataMode, callback) => {

  const { googleSheetsId, rosterSheet, staffSheet } = settings

  let sheetName;

  switch (sheetType) {
    case _sportsFormProps.glsTeam.rosterSheet:
      sheetName = rosterSheet
      break;
    case _sportsFormProps.glsTeam.staffSheet:
      sheetName = staffSheet
      break;
    default:
    // nothing
  }

  const cb = (results) => {
    if (results) {
      switch (sportsDataMode) {
        case _sdm.sports.glsTeam:
          callback(_.camelCase(sheetName), sheetType, results)
          break;
        default:
        // nothing
      }
    }
  }
  const formData = {
    sheetName: sheetName,
    googleSheetsId: googleSheetsId
  }
  fsfn_sheets.getGoogleSheetRange(formData, cb, null, true)
}

const createGsTeamSheetsWithProtections = async (action) => {
  const { access_teams, teams } = action
  if (access_teams && teams) {
    const _sheetTeams = {}
    _.forEach(access_teams, (value, key) => {
      // if (key === 'VlN5Ya0oY045OMFBxfTA') {
      const { name: email } = value[0]
      const _team = teams[key]
      const { name } = _team ?? {}
      _sheetTeams[key] = {
        name,
        email
      }
      // }
    })
    const _teams = _.sortBy(_sheetTeams, 'name')
    await fsfn_sheets.createTeamSheets('1p42B-oO8Ih9_JJ8SBpy2_uHWo6JrlCVUm9eZOUruBYk', _teams, true)

  }
}

const updateGsDistrictAccess = async (action, state) => {
  const { sportsModeKey } = state
  const { current_accessOrGls, globalItems, globalItem, sport } = action
  const _items = []
  Object.keys(current_accessOrGls).forEach(sak => {
    const sportAccess = current_accessOrGls[sak]
    if (sportAccess) {
      sportAccess.forEach(sa => {
        const { name, accessLevel } = sa
        if (globalItems[sak]) {
          const _item = {
            name,
            [globalItem]: globalItems[sak].name,
            accessLevel
          }
          if (sport) {
            _item.sport = _.startCase(sportsModeKey)
          }
          _items.push(_item)
        }
      })
    }
  })
  const headers = sport ? ['id', 'name', globalItem, 'sport', 'accessLevel'] : ['id', 'name', globalItem, 'accessLevel']
  const gsv = getGoogleSheetDataValues(_items, headers, 'School Access')
  const { values } = gsv
  await fsfn_sheets.updateGoogleSheetValues('1wM1S8OuHK7omYIGsu5snNLEVt4zR72zOYkNpoEH4Wd4', _.startCase(globalItem) + ' Access', values)
}