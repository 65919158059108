import React from 'react'

import { useMotionValue, Reorder } from "framer-motion";
import { useRaisedShadow } from './use-raised-shadow';

const DragFramerItem = (props) => {

  const { item } = props
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);

  return (
    <Reorder.Item className='fm-ri' value={item} id={item._itemKey} style={{ boxShadow, y }}>
      <div>{item.name}</div><div>{item.other}</div>
    </Reorder.Item>
  );
}

export default DragFramerItem
