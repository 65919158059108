export const sgs = {
  crossover: {
    5: {
      1: {
        1: { o: 1 },
        2: { o: 2 },
        3: { o: 3 },
        4: { o: 4 },
        5: { o: 5 },
      },
      2: {
        1: { o: 2 },
        2: { o: 3 },
        3: { o: 4 },
        4: { o: 5 },
        5: { o: 1 },
      },
      3: {
        1: { o: 3 },
        2: { o: 4 },
        3: { o: 5 },
        4: { o: 1 },
        5: { o: 2 },
      },
      4: {
        1: { o: 4 },
        2: { o: 5 },
        3: { o: 1 },
        4: { o: 2 },
        5: { o: 3 },
      },
      5: {
        1: { o: 5 },
        2: { o: 1 },
        3: { o: 2 },
        4: { o: 3 },
        5: { o: 4 },
      },
    },
    6: {
      1: {
        1: { o: 1 },
        2: { o: 2 },
        3: { o: 3 },
        4: { o: 4 },
        5: { o: 5 },
        6: { o: 6 },
      },
      2: {
        1: { o: 2 },
        2: { o: 3 },
        3: { o: 4 },
        4: { o: 5 },
        5: { o: 6 },
        6: { o: 1 },
      },
      3: {
        1: { o: 3 },
        2: { o: 4 },
        3: { o: 5 },
        4: { o: 6 },
        5: { o: 1 },
        6: { o: 2 },
      },
      4: {
        1: { o: 4 },
        2: { o: 5 },
        3: { o: 6 },
        4: { o: 1 },
        5: { o: 2 },
        6: { o: 3 },
      },
      5: {
        1: { o: 5 },
        2: { o: 6 },
        3: { o: 1 },
        4: { o: 2 },
        5: { o: 3 },
        6: { o: 4 },
      },
      6: {
        1: { o: 6 },
        2: { o: 1 },
        3: { o: 2 },
        4: { o: 3 },
        5: { o: 4 },
        6: { o: 5 },
      },
    }
  },
  wpial_home_away: {
    5: {
      1: {
        1: { h: 5, a: 1 },
        2: { h: 4, a: 2 },
      },
      2: {
        1: { h: 1, a: 4 },
        2: { h: 2, a: 3 },
      },
      3: {
        1: { h: 3, a: 1 },
        2: { h: 4, a: 5 },
      },
      4: {
        1: { h: 1, a: 2 },
        2: { h: 5, a: 3 },
      },
      5: {
        1: { h: 2, a: 5 },
        2: { h: 3, a: 4 },
      },
    },
    6: {
      1: {
        1: { h: 6, a: 1 },
        2: { h: 5, a: 2 },
        3: { h: 4, a: 3 }
      },
      2: {
        1: { h: 1, a: 4 },
        2: { h: 3, a: 5 },
        3: { h: 2, a: 6 }
      },
      3: {
        1: { h: 3, a: 1 },
        2: { h: 4, a: 2 },
        3: { h: 6, a: 5 }
      },
      4: {
        1: { h: 2, a: 1 },
        2: { h: 6, a: 3 },
        3: { h: 5, a: 4 }
      },
      5: {
        1: { h: 3, a: 2 },
        2: { h: 1, a: 5 },
        3: { h: 4, a: 6 }
      },
    },
    7: {
      1: {
        1: { h: 7, a: 1 },
        2: { h: 6, a: 2 },
        3: { h: 5, a: 3 }
      },
      2: {
        1: { h: 3, a: 4 },
        2: { h: 2, a: 5 },
        3: { h: 1, a: 6 }
      },
      3: {
        1: { h: 5, a: 1 },
        2: { h: 4, a: 2 },
        3: { h: 6, a: 7 }
      },
      4: {
        1: { h: 2, a: 3 },
        2: { h: 1, a: 4 },
        3: { h: 7, a: 5 }
      },
      5: {
        1: { h: 3, a: 1 },
        2: { h: 5, a: 6 },
        3: { h: 4, a: 7 }
      },
      6: {
        1: { h: 1, a: 2 },
        2: { h: 7, a: 3 },
        3: { h: 6, a: 4 }
      },
      7: {
        1: { h: 4, a: 5 },
        2: { h: 3, a: 6 },
        3: { h: 2, a: 7 }
      },
    },
    8: {
      1: {
        1: { h: 5, a: 1 },
        2: { h: 6, a: 2 },
        3: { h: 7, a: 3 },
        4: { h: 8, a: 4 }
      },
      2: {
        1: { h: 3, a: 5 },
        2: { h: 4, a: 6 },
        3: { h: 2, a: 7 },
        4: { h: 1, a: 8 }
      },
      3: {
        1: { h: 7, a: 1 },
        2: { h: 4, a: 2 },
        3: { h: 6, a: 3 },
        4: { h: 8, a: 5 }
      },
      4: {
        1: { h: 2, a: 1 },
        2: { h: 5, a: 4 },
        3: { h: 6, a: 7 },
        4: { h: 3, a: 8 }
      },
      5: {
        1: { h: 4, a: 3 },
        2: { h: 7, a: 5 },
        3: { h: 1, a: 6 },
        4: { h: 2, a: 8 }
      },
      6: {
        1: { h: 4, a: 1 },
        2: { h: 3, a: 2 },
        3: { h: 5, a: 6 },
        4: { h: 8, a: 7 }
      },
      7: {
        1: { h: 1, a: 3 },
        2: { h: 7, a: 4 },
        3: { h: 2, a: 5 },
        4: { h: 6, a: 8 }
      },
    },
    9: {
      1: {
        1: { h: 6, a: 4 },
        2: { h: 7, a: 3 },
        3: { h: 8, a: 2 },
        4: { h: 9, a: 1 }
      },
      2: {
        1: { h: 1, a: 8 },
        2: { h: 2, a: 7 },
        3: { h: 3, a: 6 },
        4: { h: 4, a: 5 }
      },
      3: {
        1: { h: 5, a: 3 },
        2: { h: 6, a: 2 },
        3: { h: 7, a: 1 },
        4: { h: 8, a: 9 }
      },
      4: {
        1: { h: 1, a: 6 },
        2: { h: 2, a: 5 },
        3: { h: 4, a: 3 },
        4: { h: 9, a: 7 }
      },
      5: {
        1: { h: 2, a: 4 },
        2: { h: 5, a: 1 },
        3: { h: 7, a: 8 },
        4: { h: 9, a: 6 }
      },
      6: {
        1: { h: 1, a: 4 },
        2: { h: 3, a: 2 },
        3: { h: 5, a: 9 },
        4: { h: 6, a: 8 }
      },
      7: {
        1: { h: 3, a: 1 },
        2: { h: 4, a: 9 },
        3: { h: 6, a: 7 },
        4: { h: 8, a: 5 }
      },
      8: {
        1: { h: 1, a: 2 },
        2: { h: 7, a: 5 },
        3: { h: 8, a: 4 },
        4: { h: 9, a: 3 }
      },
      9: {
        1: { h: 2, a: 9 },
        2: { h: 3, a: 8 },
        3: { h: 4, a: 7 },
        4: { h: 5, a: 6 }
      },
    }
  },
  wpial_single: {
    5: {
      1: {
        1: { h: 5, a: 1 },
        2: { h: 4, a: 2 },
      },
      2: {
        1: { h: 1, a: 4 },
        2: { h: 2, a: 3 },
      },
      3: {
        1: { h: 3, a: 1 },
        2: { h: 4, a: 5 },
      },
      4: {
        1: { h: 1, a: 2 },
        2: { h: 5, a: 3 },
      },
      5: {
        1: { h: 2, a: 5 },
        2: { h: 3, a: 4 },
      },
    },
    6: {
      1: {
        1: { h: 6, a: 1 },
        2: { h: 5, a: 2 },
        3: { h: 4, a: 3 }
      },
      2: {
        1: { h: 1, a: 4 },
        2: { h: 3, a: 5 },
        3: { h: 2, a: 6 }
      },
      3: {
        1: { h: 3, a: 1 },
        2: { h: 4, a: 2 },
        3: { h: 6, a: 5 }
      },
      4: {
        1: { h: 2, a: 1 },
        2: { h: 6, a: 3 },
        3: { h: 5, a: 4 }
      },
      5: {
        1: { h: 3, a: 2 },
        2: { h: 1, a: 5 },
        3: { h: 4, a: 6 }
      },
    },
    7: {
      1: {
        1: { h: 7, a: 1 },
        2: { h: 6, a: 2 },
        3: { h: 5, a: 3 }
      },
      2: {
        1: { h: 3, a: 4 },
        2: { h: 2, a: 5 },
        3: { h: 1, a: 6 }
      },
      3: {
        1: { h: 5, a: 1 },
        2: { h: 4, a: 2 },
        3: { h: 6, a: 7 }
      },
      4: {
        1: { h: 2, a: 3 },
        2: { h: 1, a: 4 },
        3: { h: 7, a: 5 }
      },
      5: {
        1: { h: 3, a: 1 },
        2: { h: 5, a: 6 },
        3: { h: 4, a: 7 }
      },
      6: {
        1: { h: 1, a: 2 },
        2: { h: 7, a: 3 },
        3: { h: 6, a: 4 }
      },
      7: {
        1: { h: 4, a: 5 },
        2: { h: 3, a: 6 },
        3: { h: 2, a: 7 }
      },
    },
    8: {
      1: {
        1: { h: 5, a: 1 },
        2: { h: 6, a: 2 },
        3: { h: 7, a: 3 },
        4: { h: 8, a: 4 }
      },
      2: {
        1: { h: 3, a: 5 },
        2: { h: 4, a: 6 },
        3: { h: 2, a: 7 },
        4: { h: 1, a: 8 }
      },
      3: {
        1: { h: 7, a: 1 },
        2: { h: 4, a: 2 },
        3: { h: 6, a: 3 },
        4: { h: 8, a: 5 }
      },
      4: {
        1: { h: 2, a: 1 },
        2: { h: 5, a: 4 },
        3: { h: 6, a: 7 },
        4: { h: 3, a: 8 }
      },
      5: {
        1: { h: 4, a: 3 },
        2: { h: 7, a: 5 },
        3: { h: 1, a: 6 },
        4: { h: 2, a: 8 }
      },
      6: {
        1: { h: 4, a: 1 },
        2: { h: 3, a: 2 },
        3: { h: 5, a: 6 },
        4: { h: 8, a: 7 }
      },
      7: {
        1: { h: 1, a: 3 },
        2: { h: 7, a: 4 },
        3: { h: 2, a: 5 },
        4: { h: 6, a: 8 }
      },
    },
    9: {
      1: {
        1: { h: 6, a: 4 },
        2: { h: 7, a: 3 },
        3: { h: 8, a: 2 },
        4: { h: 9, a: 1 }
      },
      2: {
        1: { h: 1, a: 8 },
        2: { h: 2, a: 7 },
        3: { h: 3, a: 6 },
        4: { h: 4, a: 5 }
      },
      3: {
        1: { h: 5, a: 3 },
        2: { h: 6, a: 2 },
        3: { h: 7, a: 1 },
        4: { h: 8, a: 9 }
      },
      4: {
        1: { h: 1, a: 6 },
        2: { h: 2, a: 5 },
        3: { h: 4, a: 3 },
        4: { h: 9, a: 7 }
      },
      5: {
        1: { h: 2, a: 4 },
        2: { h: 5, a: 1 },
        3: { h: 7, a: 8 },
        4: { h: 9, a: 6 }
      },
      6: {
        1: { h: 1, a: 4 },
        2: { h: 3, a: 2 },
        3: { h: 5, a: 9 },
        4: { h: 6, a: 8 }
      },
      7: {
        1: { h: 3, a: 1 },
        2: { h: 4, a: 9 },
        3: { h: 6, a: 7 },
        4: { h: 8, a: 5 }
      },
      8: {
        1: { h: 1, a: 2 },
        2: { h: 7, a: 5 },
        3: { h: 8, a: 4 },
        4: { h: 9, a: 3 }
      },
      9: {
        1: { h: 2, a: 9 },
        2: { h: 3, a: 8 },
        3: { h: 4, a: 7 },
        4: { h: 5, a: 6 }
      },
    }
  },
  scheduleGrid2: {
    6: {
      1: {},
      2: {
        1: { h: 6, a: 1 },
        2: { h: 5, a: 2 },
        3: { h: 4, a: 3 }
      },
      3: {
        1: { h: 1, a: 4 },
        2: { h: 3, a: 5 },
        3: { h: 2, a: 6 }
      },
      4: {
        1: { h: 3, a: 1 },
        2: { h: 4, a: 2 },
        3: { h: 6, a: 5 }
      },
      5: {
        1: { h: 2, a: 1 },
        2: { h: 6, a: 3 },
        3: { h: 5, a: 4 }
      },
      6: {
        1: { h: 3, a: 2 },
        2: { h: 1, a: 5 },
        3: { h: 4, a: 6 }
      },
      7: {},
    },
    7: {
      1: {
        1: { h: 7, a: 1 },
        2: { h: 6, a: 2 },
        3: { h: 5, a: 3 }
      },
      2: {
        1: { h: 3, a: 4 },
        2: { h: 2, a: 5 },
        3: { h: 1, a: 6 }
      },
      3: {
        1: { h: 5, a: 1 },
        2: { h: 4, a: 2 },
        3: { h: 6, a: 7 }
      },
      4: {
        1: { h: 2, a: 3 },
        2: { h: 1, a: 4 },
        3: { h: 7, a: 5 }
      },
      5: {
        1: { h: 3, a: 1 },
        2: { h: 5, a: 6 },
        3: { h: 4, a: 7 }
      },
      6: {
        1: { h: 1, a: 2 },
        2: { h: 7, a: 3 },
        3: { h: 6, a: 4 }
      },
      7: {
        1: { h: 4, a: 5 },
        2: { h: 3, a: 6 },
        3: { h: 2, a: 7 }
      },
    },
    8: {
      1: {
        1: { h: 5, a: 1 },
        2: { h: 6, a: 2 },
        3: { h: 7, a: 3 },
        4: { h: 8, a: 4 }
      },
      2: {
        1: { h: 3, a: 5 },
        2: { h: 4, a: 6 },
        3: { h: 2, a: 7 },
        4: { h: 1, a: 8 }
      },
      3: {
        1: { h: 7, a: 1 },
        2: { h: 4, a: 2 },
        3: { h: 6, a: 3 },
        4: { h: 8, a: 5 }
      },
      4: {
        1: { h: 2, a: 1 },
        2: { h: 5, a: 4 },
        3: { h: 6, a: 7 },
        4: { h: 3, a: 8 }
      },
      5: {
        1: { h: 4, a: 3 },
        2: { h: 7, a: 5 },
        3: { h: 1, a: 6 },
        4: { h: 2, a: 8 }
      },
      6: {
        1: { h: 4, a: 1 },
        2: { h: 3, a: 2 },
        3: { h: 5, a: 6 },
        4: { h: 8, a: 7 }
      },
      7: {
        1: { h: 1, a: 3 },
        2: { h: 7, a: 4 },
        3: { h: 2, a: 5 },
        4: { h: 6, a: 8 }
      },
    }
  },
  scheduleGrid3: {
    6: {
      1: {},
      2: {
        1: { h: 6, a: 1 },
        2: { h: 5, a: 2 },
        3: { h: 4, a: 3 }
      },
      3: {
        1: { h: 1, a: 4 },
        2: { h: 3, a: 5 },
        3: { h: 2, a: 6 }
      },
      4: {
        1: { h: 3, a: 1 },
        2: { h: 4, a: 2 },
        3: { h: 6, a: 5 }
      },
      5: {
        1: { h: 2, a: 1 },
        2: { h: 6, a: 3 },
        3: { h: 5, a: 4 }
      },
      6: {
        1: { h: 3, a: 2 },
        2: { h: 1, a: 5 },
        3: { h: 4, a: 6 }
      },
      7: {},
    },
    7: {
      1: {
        1: { h: 7, a: 1 },
        2: { h: 6, a: 2 },
        3: { h: 5, a: 3 }
      },
      2: {
        1: { h: 3, a: 4 },
        2: { h: 2, a: 5 },
        3: { h: 1, a: 6 }
      },
      3: {
        1: { h: 5, a: 1 },
        2: { h: 4, a: 2 },
        3: { h: 6, a: 7 }
      },
      4: {
        1: { h: 2, a: 3 },
        2: { h: 1, a: 4 },
        3: { h: 7, a: 5 }
      },
      5: {
        1: { h: 3, a: 1 },
        2: { h: 5, a: 6 },
        3: { h: 4, a: 7 }
      },
      6: {
        1: { h: 1, a: 2 },
        2: { h: 7, a: 3 },
        3: { h: 6, a: 4 }
      },
      7: {
        1: { h: 4, a: 5 },
        2: { h: 3, a: 6 },
        3: { h: 2, a: 7 }
      },
    },
    8: {
      1: {
        1: { h: 5, a: 1 },
        2: { h: 6, a: 2 },
        3: { h: 7, a: 3 },
        4: { h: 8, a: 4 }
      },
      2: {
        1: { h: 3, a: 5 },
        2: { h: 4, a: 6 },
        3: { h: 2, a: 7 },
        4: { h: 1, a: 8 }
      },
      3: {
        1: { h: 7, a: 1 },
        2: { h: 4, a: 2 },
        3: { h: 6, a: 3 },
        4: { h: 8, a: 5 }
      },
      4: {
        1: { h: 2, a: 1 },
        2: { h: 5, a: 4 },
        3: { h: 6, a: 7 },
        4: { h: 3, a: 8 }
      },
      5: {
        1: { h: 4, a: 3 },
        2: { h: 7, a: 5 },
        3: { h: 1, a: 6 },
        4: { h: 2, a: 8 }
      },
      6: {
        1: { h: 4, a: 1 },
        2: { h: 3, a: 2 },
        3: { h: 5, a: 6 },
        4: { h: 8, a: 7 }
      },
      7: {
        1: { h: 1, a: 3 },
        2: { h: 7, a: 4 },
        3: { h: 2, a: 5 },
        4: { h: 6, a: 8 }
      },
    }
  },
}