import React from 'react'
import DataSidebar from './DataSidebar'
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper'
import UiSaveButtons from '../buttons/UiSaveButtons'

const SidebarConfirm = (props) => {

  const {
    visible,
    onHide,
    header,
    content,
    oc,
    saveCaption,
    useCancel,
    updating,
  } = props ?? {}

  const wrapper_sidebarRight = () => <Wrapper
    header={header}
    content={content}
    footer={<UiSaveButtons save={{ oc, caption: saveCaption }} cancel={useCancel ? { oc: onHide, caption: 'Cancel' } : null} />}
    wrapperType={wrapperTypes.padded}
    css={{ container: 'data-sidebar app-dark-mode' }}
    updating={updating}
  ></Wrapper>

  const sidebar_confirm = () => <DataSidebar direction={'right'}
    visible={visible}
    content={visible && wrapper_sidebarRight()}
    onHide={onHide}
  />

  return sidebar_confirm()

}

export default SidebarConfirm