import _ from 'lodash'
import React, { useContext, useEffect, useReducer } from 'react'
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions'
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext'
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer, sidebarSliderTypes } from '../../../../global/cnr/reducers/SidebarReducer'
import { uniqueKey } from '../../../../global/common/keys'
import { sortObj } from '../../../../global/common/sorting'
import NoData from '../../../../global/components/alerts/NoData'
import SwiperWrapper from '../../../../global/components/swipers/SwiperWrapper'
import { currentHelpers } from '../../../../global/redirection/current'
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'
import { sportsStandingsHandlers, sportsStandingsInitialState, sportsStandingsReducer } from '../../cnr/reducers/SportsStandingsReducer'
import { CreatePlayoffContext } from '../../create/CreatePlayoff'
import StandingsWrapper from '../teams/StandingsWrapper'
import SectionStandings from './SectionStandings'
import StandingsLegend from './StandingsLegend'
import { sportsShowTypes } from '../../cnr/reducers/SportsSeasonReducer'

const sidebarType = 'leagueStandings'

export const SportsStandingsContext = React.createContext()

const LeagueStandings = (props) => {

  // componentContexts
  const { componentContexts, forOverview, forPlayoff } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state, paps_state } = states ?? {}
  const { pathViews, viewKey, storageItemPage } = paps_state ?? {}
  const { appUsers } = appUser_state ?? {}

  // componentContexts
  const { displayContext } = componentContexts ?? {}
  const { display_state } = displayContext ?? {}
  const { displayProps } = display_state ?? {}
  const { selectedGroupItem } = displayProps ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_fns, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { sportsMode, districts_info, sportDistricts, teams_info, details_info, matches_info, currents, sportsShowType } = sportsSeason_state ?? {}
  const { teams } = teams_info ?? {}
  const { matches, playoffMatches } = matches_info ?? {}
  const { organization: districts_org } = districts_info ?? {}
  const { details, sectionPlayoffTeams } = details_info ?? {}
  const { playoffTeamsPerSection } = details ?? {}

  // createPlayoffContext
  const createPlayoffContext = useContext(CreatePlayoffContext);
  const { createPlayoff_state } = createPlayoffContext ?? {}
  const { playoff_info } = createPlayoff_state ?? {}
  const { playoffLevel, playoffLevelTeams } = playoff_info ?? {}

  const appUserAccess = getAppUserAccess(appUsers)
  const { pageFavs } = appUserAccess ?? {}
  const _teamsFavs = pageFavs && pageFavs['teams']

  const init_state = {}
  const [sportsStandings_state, sportsStandings_dispatch] = useReducer(sportsStandingsReducer, init_state, sportsStandingsInitialState)
  const sportsStandings_handlers = sportsStandingsHandlers(sportsStandings_dispatch, sportsStandings_state)
  const { showPlayoffPicture, selected_teams, swiper, levels, selectedTeams, selectedTeamLevel, selectedLevelKey, noData } = sportsStandings_state ?? {}

  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType, handlers: sportsSeason_handlers }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items } = sidebar_state ?? {}
  const { setInit } = sidebar_handlers ?? {}

  const _showPlayoffPicture = sportsShowType ? sportsShowType === sportsShowTypes.fullStandings : showPlayoffPicture

  const { ms_levels } = sidebar_items ?? {}

  const _initProps = { sidebarSliderType: sidebarSliderTypes.labeled }

  const handleSwiperIndexChange = (_lk, li) => sportsStandings_handlers.handleSet_selectedLevel(li.key)
  const plts = (lk) => (lk + ' (' + playoffLevelTeams[lk].length + ')')

  // ms_districts
  useEffect(() => {
    if (sportsMode.districtSports && !sportsMode.matches) {
      const _districts = []
      if (sportDistricts && districts_org && !sportsMode.sports) {
        sportDistricts.forEach(sd => {
          if (districts_org[sd]) {
            _districts.push(districts_org[sd].name)
          }
        })
        currents.districts = currentHelpers.getStorageItem('ms_districts', _districts, storageItemPage)
        setInit({ smt: sidebarMenuTypes.one, items: _districts, currents, as: 'districts' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sportDistricts, districts_org]);

  // ms_levels
  useEffect(() => {
    if (selectedTeams) {
      if (Object.keys(selectedTeams).length > 0) {
        const _levels = _.groupBy(selectedTeams, 'levels')
        if (_levels.Non) { delete _levels.Non }
        sportsStandings_handlers.handleSet_levels(_levels)
        const levelKeys = selectedTeamLevel ? [selectedTeamLevel] : Object.keys(_levels).sort().reverse()
        currents.levels = currentHelpers.getStorageItem('ms_levels', levelKeys, storageItemPage)
        setInit({ smt: sidebarMenuTypes.two, items: levelKeys, currents, as: 'levels', ..._initProps, includeSearch: false })
      } else {
        sportsStandings_handlers.handleNoData(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedTeams]);

  useEffect(() => {
    if (ms_levels) {
      const _swiperItems = []
      ms_levels.items.forEach(lk => {
        const _standings = standings(lk)
        _swiperItems.push({
          key: lk,
          content: _standings,
          caption: forPlayoff && playoffLevelTeams ? plts(lk) : lk
        })
      })
      sportsStandings_handlers.handleSet_swiper({ swipeItems: _swiperItems, sidebarItems: ms_levels, handleSwiperIndexChange })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ms_levels, selected_teams, _showPlayoffPicture]);

  useEffect(() => {
    if (forPlayoff && ms_levels) {
      const _captions = {}
      ms_levels.items.forEach(lk => {
        _captions[lk] = plts(lk)
      })
      sportsStandings_handlers.handleAmmend_swiperCaptions(_captions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [playoffLevelTeams]);

  useEffect(() => {
    if (teams) {
      if (selectedGroupItem) {
        const st = sportsSeason_fns.getTeamsByLevel(selectedGroupItem)
        sportsStandings_handlers.handleSet_selectedTeams(st)
      } else {
        if (pathViews.teams) {
          if (teams && teams[pathViews.teams]) {
            const { sections, levels } = teams[pathViews.teams]
            const lst = sportsSeason_fns.getTeamsBySection(levels, sections)
            sportsStandings_handlers.handleSet_selectedTeamLevel(levels)
            sportsStandings_handlers.handleSet_selectedTeams(lst)
          }
        } else if (pathViews.matches) {
          let _thisMatch = matches && matches[pathViews.matches] ? matches[pathViews.matches] : null
          if (!_thisMatch) { _thisMatch = playoffMatches && playoffMatches[pathViews.matches] ? playoffMatches[pathViews.matches] : {} }
          if (_thisMatch) {
            const { home, away } = _thisMatch
            const mlst = sportsSeason_fns.getTeamsByMatch(home, away)
            sportsStandings_handlers.handleSet_selectedTeams(mlst)
          }
        } else {
          sportsStandings_handlers.handleSet_selectedTeams(teams)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams, selectedGroupItem]);


  const isFavInSection = (sectionTeams) => {
    let isFav;
    Object.keys(_teamsFavs).forEach(tf => {
      const stf = _.find(sectionTeams, { _itemKey: tf })
      if (stf) { isFav = true }
    })
    return isFav
  }

  const standings_section = (sectionTeams, _levelKey, caption, isFavSection) => {

    const sectionOpts = {
      _levelKey,
      caption,
      forOverview,
      forPlayoff,
      isFavSection,
      selectedTeam: null,
      selectedTeams: null,
      teamOnly: false,
      teams: sectionTeams,
    }

    if (pathViews.teams) {
      const teamSection = _.find(sectionTeams, { id: pathViews.teams })
      if (teamSection) {
        sectionOpts.selectedTeam = pathViews.teams
        sectionOpts.isFavSection = false
      }
    } else if (pathViews.matches) {

      let _thisMatch = matches && viewKey && matches[viewKey] ? matches[viewKey] : null
      if (!_thisMatch) { _thisMatch = playoffMatches && viewKey && playoffMatches[viewKey] ? playoffMatches[viewKey] : {} }

      if (_thisMatch && _thisMatch.home && _thisMatch.away) {

        const team1Section = _.find(sectionTeams, { id: _thisMatch.home.id })
        const team2Section = _.find(sectionTeams, { id: _thisMatch.away.id })

        // if teams from different sections
        if (team1Section && team2Section) {
          if ((team2Section.levels === team1Section.levels) && (team2Section.sections === team1Section.sections)) {
            sectionOpts.selectedTeams = [_thisMatch.home.id, _thisMatch.away.id]
          } else {
            if (team1Section) {
              sectionOpts.selectedTeam = _thisMatch.home.id
            }
            if (team2Section) {
              sectionOpts.selectedTeam = _thisMatch.away.id
            }
          }
        } else {
          if (team1Section) {
            sectionOpts.selectedTeam = _thisMatch.home.id
          }
          if (team2Section) {
            sectionOpts.selectedTeam = _thisMatch.away.id
          }
        }
      }
    } else {
      sectionOpts.selectedTeams = sectionTeams
    }

    return <SectionStandings sectionOpts={sectionOpts} />
  }

  // const handleChange = (e, data) => {
  //   if (e.stopPropagation) e.stopPropagation();
  //   createPlayoff_handlers.handleSet_playoffLevelCount(data.lk, data.value)
  // }

  // const optionsList = () => {
  //   const opts = []
  //   for (var i = 0; i < 24; i++) {
  //     opts.push({ key: i, value: i, text: i })
  //   }
  //   return opts
  // }

  // const dropdown = (lk) => <Dropdown
  //   fluid
  //   button
  //   size={'mini'}
  //   className='icon thin profile-select access'
  //   options={optionsList()}
  //   scrolling
  //   onChange={handleChange}
  //   lk={lk}
  //   value={playoffLevelCounts && playoffLevelCounts[lk]}
  // />

  const standings = (lk) => {

    const levelSegs = []

    const _levelKey_selected = ms_levels && ms_levels.selected ? ms_levels.selected.item : null
    const _levelKey = lk ? lk : _levelKey_selected
    const selectedLevel = _levelKey && levels ? levels[_levelKey] : null

    switch (sportsShowType) {
      case sportsShowTypes.fullStandings:
        const _sectionStandings = standings_section(selectedLevel, selectedLevelKey, selectedLevelKey)
        return <StandingsWrapper sectionStandings={_sectionStandings} />
      default:
        if (forPlayoff && 1 === 3) {
          const _sectionStandings = standings_section(selectedLevel, selectedLevelKey, selectedLevelKey)
          return <div key={uniqueKey('ls', lk)}>
            {_sectionStandings}
          </div>
        } else {
          const section = _.groupBy(selectedLevel, 'sections')

          if (Object.keys(section).length > 0) {

            const s = sortObj(section)
            const stss = []

            Object.keys(s).forEach(sectionKey => {
              const caption = _levelKey + ' - ' + _.startCase(sectionKey)
              const sectionTeams = section[sectionKey]

              const isFavSection = _teamsFavs && isFavInSection(sectionTeams)

              // let _sectionStandings; 
              const _sectionStandings = standings_section(sectionTeams, _levelKey, caption, isFavSection)

              if (isFavSection && !forPlayoff) {
                stss.unshift(_sectionStandings)
              } else {
                stss.push(_sectionStandings)
              }
            })

            levelSegs.push(stss)

            let showLegend;

            if (lk || (pathViews.teams && !forOverview)) { showLegend = true }

            if (showLegend) {
              return <div key={uniqueKey('ls', lk)}>
                {levelSegs}
                <StandingsLegend playoffTeamsPerSection={playoffTeamsPerSection} sectionPlayoffTeams={sectionPlayoffTeams} />
              </div>
            } else {
              return levelSegs
            }
          } else {
            return <NoData fullCaption={'No Standings'} />
          }
        }
    }
    // if (showPlayoffPicture) {
    //   const _sectionStandings = standings_section(selectedLevel, selectedLevelKey, selectedLevelKey)
    //   return <StandingsWrapper sectionStandings={_sectionStandings} />
    // } else {

    // }
  }

  const leagueStandings = () => {

    if (_showPlayoffPicture) {
      return standings(selectedLevelKey)
    } else if (playoffLevel) {
      return standings(playoffLevel)
    } else {
      if (noData) {
        return <NoData fullCaption={'No Standings'} />
      } else {
        if (forOverview || pathViews.teams) {
          return standings()
        } else {
          const extraButtons = []
          return swiper ? <SwiperWrapper
            swType={'ls'}
            swiper={swiper}
            noData={noData}
            key={uniqueKey('sw', 'ls')}
            extraButtons={extraButtons}
          />
            :
            <div></div>
        }
      }
    }


  }

  return <SportsStandingsContext.Provider
    value={{ sportsStandings_state, sportsStandings_handlers }}>
    {leagueStandings()}
  </SportsStandingsContext.Provider>

}

export default LeagueStandings