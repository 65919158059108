import { last } from 'lodash';
import { createRefPath_event } from '../firestoreData/appData/appRefPaths';
import { fs_dbu } from "../firestoreData/appData/fsAppDataUpdate";
import { _storageSettings } from './storageHelpers';

export const approveStorageFilesToDatabase = (galleries, pathViews) => {

  const { galleryPaths } = _storageSettings

  const _gl = galleries ? { ...galleries } : {}
  const { page: gallery_page } = _gl ? _gl : {}

  const promises = []

  const eventPath = createRefPath_event(pathViews, [galleryPaths.galleryPage])


  Object.keys(gallery_page).forEach(key => {
    const gp = gallery_page[key]
    fs_dbu.set_doc(gp, eventPath)
    promises.push(fs_dbu.set_doc(gp, eventPath))
  })
  return Promise.all(promises)
}

export const updateStorageFilesToDatabase = (galleries, pathViews, selectedImage, viewItem_key) => {

  const { galleryPaths } = _storageSettings

  const { image } = selectedImage
  const { pathz } = image ? image : {}
  const { full } = pathz ? pathz : {}
  const lastItem = last((full).split('/'))

  const refPaths = createRefPath_event(pathViews, [galleryPaths.galleryDirect, viewItem_key])

  const dataToUpdate = {
    [lastItem]: {
      isDefault: true
    }
  }
  const promises = []
  promises.push(fs_dbu.doc_set(refPaths, dataToUpdate, true))
  return Promise.all(promises)
}