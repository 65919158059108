import { isPossiblePhoneNumber } from "react-phone-number-input";

export const propHelpers = {
  containsLineBreaks: (value) => containsLineBreaks(value),
  isDateProp: (propKey) => propKey.toLowerCase().endsWith('date') || propKey.toLowerCase().endsWith('checkin') || propKey.toLowerCase().endsWith('checkout') || propKey.toLowerCase().endsWith('startdate') || propKey.toLowerCase().endsWith('enddate'),
  isDescriptionProp: (propKey) => propKey === 'description',
  isEmailProp: (propKey) => propKey.toLowerCase().endsWith('email') || propKey.toLowerCase().indexOf('email') >= 0,
  isHtml: (value) => isHtml(value),
  isPhoneProp: (propKey) => propKey.toLowerCase().endsWith('phone') || propKey.toLowerCase().indexOf('phone') >= 0,
  isPhoneValue: (propKey) => isPossiblePhoneNumber(propKey),
  isTimeProp: (propKey) => propKey.toLowerCase().endsWith('time'),
  isUrl: (value) => isUrl(value),
  isPTagged: (value) => isPTagged(value),
  isYesNoProp: (value) => value.toLowerCase() === 'yes' || value.toLowerCase() === 'no',
}

const isHtml = (value) => {
  return /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(value)
}

const isPTagged = (value) => {
  return /<p>(.*?)<\/p>/g.test(value)
}

// export const containsHTML = (text) => {
//   const htmlPattern = /<[^>]*>/; // Regular expression to match HTML tags
//   return htmlPattern.test(text);
// }

const containsLineBreaks = (text) => {
  const lineBreakPattern = /[\r\n]/; // Regular expression to match line breaks (\r or \n)
  return lineBreakPattern.test(text);
}



// export const isHtml = (value) => {
//   return /<[a-z][\s\S]*?>/i.test(value);
// }

export const isHTML2 = (str) => {
  var doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

const isUrl = (value) => {
  const re = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  var pattern = new RegExp(re);
  // var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
  //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
  //   '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
  //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + //port
  //   '(\\?[;&amp;a-z\\d%_.~+=-]*)?' + // query string
  //   '(\\#[-a-z\\d_]*)?$', 'i');
  return pattern.test(value);
}



// export const isDateProp = (propKey) => propKey.toLowerCase().endsWith('date') || propKey.toLowerCase().endsWith('checkin') || propKey.toLowerCase().endsWith('checkout') || propKey.toLowerCase().endsWith('startdate') || propKey.toLowerCase().endsWith('enddate')
// export const isTimeProp = (propKey) => propKey.toLowerCase().endsWith('time')
// export const isEmailProp = (propKey) => propKey.toLowerCase().endsWith('email') || propKey.toLowerCase().indexOf('email') >= 0
// export const isDescriptionProp = (propKey) => propKey === 'description'
// export const isYesNoProp = (value) => value.toLowerCase() === 'yes' || value.toLowerCase() === 'no'
// export const isPhoneProp = (propKey) => propKey.toLowerCase().endsWith('phone') || propKey.toLowerCase().indexOf('phone') >= 0