import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Icon, Label, Menu, Checkbox } from 'semantic-ui-react'
import { GoogleSheetsContext } from '../../cnr/contexts/GoogleSheetsContext'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { viewDataModes } from '../../cnr/reducers/DataManagmentReducer'
import { googleSheetsTypes } from '../../cnr/reducers/GoogleSheetsReducer'
import { filterHelpers } from '../../common/filtering'
import { appIconTypes } from '../../enums/appIconTypes'
import { iconColorTypes } from '../../enums/settingsIconTypes'
import { createGoogleSheetKeys, validateCollectionArrays } from '../../validation/validation'
import { getViewItemHeaders } from '../../viewSettings/helpers/settingsHelpers'
import { openExternal } from '../../viewSettings/helpers/settingsLinks'
import TransitionWrapper, { transitionWrapperTypes } from '../../wrappers/TransitionWrapper'
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper'
import UiSaveButtons from '../buttons/UiSaveButtons'
import DropdownOptionsMenu from '../forms/elements/DropdownOptionsMenu'
import DataListTable from './DataListTable'
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent'

const ddIcons = {
  action: 'external alternate',
  dataOptions: 'unordered list',
  updateOptions: 'unordered list',
}

/**
 * 
 * @param {object} props (previewInfo, handleActiveTab)
 * @previewInfo (projectData)
 * @projectData (dataCollections)
 * @returns a `Tab` with a `Tab` for each `dataCollection` in `dataCollections`
 */
const GoogleSheetsTabs = (props) => {

  const { previewInfo, fromCreate, _updating, setViewDataMode, googleSheetType } = props
  const { projectData } = previewInfo ?? {}
  const { dataCollections } = projectData ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, paps_state, eventInfo_state } = states ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_viewItems } = pageSettings ?? {}
  const { pathViews } = paps_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { googleForms } = aps_global ?? {}
  const { googleFormsId } = googleForms ?? {}

  // googleSheetsContext
  const frameworkContext = useContext(FrameworkContext)
  const { framework_state } = frameworkContext ?? {}
  const { frInfo } = framework_state ?? {}
  const { uivi } = frInfo ?? {}

  const _useSingleItem = uivi ? false : false

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_state, googleSheets_handlers } = googleSheetsContext ?? {}
  const { activeMenuItem, dashboardInfo_preview, googleSheetsData, googleSheetsId, showConfirmationQuestion, showNewOnly, newCounts } = googleSheets_state ?? {}

  // local state  
  // const [googleSheetKeys, setGoogleSheetKeys] = useState([])
  const [googleSheetItems, setGoogleSheetItems] = useState({})
  const [currentDataTables, setCurrentDataTables] = useState({})
  const [currentDataTable, setCurrentDataTable] = useState()
  const [currentCount, setCurrentCount] = useState({})
  const [dataCounts, setDataCounts] = useState({})
  const [updating, setUpdating] = useState()
  const [useItemKey, setUseItemKey] = useState()
  const [currentDataLink, setCurrentDataLink] = useState()

  const handleUseItemKey = () => setUseItemKey(!useItemKey)

  useEffect(() => {
    if (dataCollections) {
      const _googleSheetKeys = _.sortBy(Object.keys(dataCollections))
      const _googleSheetItems = {}
      _googleSheetKeys.forEach(gsk => {
        _googleSheetItems[gsk] = {
          name: gsk,
          included: true,
          key: gsk
        }
      })
      // setGoogleSheetKeys(_googleSheetKeys)
      setGoogleSheetItems(_googleSheetItems)
      const _counts = {}
      Object.keys(dataCollections).forEach(dcKey => {
        const dataCollection = dataCollections[dcKey]
        const dcl = Object.keys(dataCollection).length
        const newCount = newCounts ? newCounts[dcKey] : 0
        _counts[dcKey] = {
          dcl,
          newCount
        }
      })
      setDataCounts(_counts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuItem, dataCollections, newCounts]);

  useEffect(() => {
    if (activeMenuItem) {
      const _currentDataTables = { ...currentDataTables }
      let _dataTable = {}
      const dataCollection = dataCollections[activeMenuItem]
      const viewItem = aps_viewItems[activeMenuItem]
      const _news = filterHelpers.filter(dataCollection, '_new', true, null, '_itemKey')
      const _dataCollection = showNewOnly ? _news : dataCollection

      if (viewItem) {
        const vih = getViewItemHeaders(viewItem)
        _dataTable = dataListTable(_dataCollection, viewItem, vih)
      } else {
        try {
          _dataTable = dataListTable(_dataCollection, {}, activeMenuItem)
        } catch (error) {

        }
      }
      _currentDataTables[activeMenuItem] = _dataTable
      const _currentCount = dataCounts[activeMenuItem]
      setCurrentDataTable(_dataTable)
      setCurrentDataTables(_currentDataTables)
      setCurrentCount(_currentCount)
      setUpdating(false)

    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuItem, showNewOnly]);

  // if the googleSheetItems change, update the google sheets data
  useEffect(() => {
    if (googleSheetItems) {
      googleSheets_handlers.handle_gsAmmendments(googleSheetItems)
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleSheetItems]);

  useEffect(() => {
    if (uivi && dataCollections) {
      googleSheets_handlers.handleSet_activeMenuItem(uivi)
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCollections, uivi]);

  const handleDataCollectionChange = (dmi) => {
    setUpdating(true)
    googleSheets_handlers.handleSet_activeMenuItem(dmi)
  }

  const handleGsiClick = (e, gsi) => {
    e.stopPropagation()
    const _googleSheetItems = { ...googleSheetItems }
    _googleSheetItems[gsi].included = !_googleSheetItems[gsi].included
    setGoogleSheetItems(_googleSheetItems)
  }

  const handleDatabaseOpen = () => openExternal.db(pathViews, null, activeMenuItem)
  const handleGetGoogleSheet = () => googleSheets_handlers.handleGet_googleSheet(googleSheetsId, useItemKey)
  const handleOpenGoogleSheets = () => openExternal.googleSheets(googleSheetsId)
  const handleOpenGoogleForms = () => openExternal.googleForm(googleFormsId)
  const handleViewDataMode = (opts) => setViewDataMode(opts ? opts.viewDataMode : null)
  const handleShowNewOnly = () => googleSheets_handlers.handleShow_newOnly()
  const handleValidateDataCollections = () => validateCollectionArrays(dataCollections)
  const handleUpdateGoogleSheets = () => createGoogleSheetKeys(googleSheetsData, dataCollections)

  const dataListTable = (fd, viewItem, vih) => <DataListTable
    fixedData={fd}
    viewItem={viewItem}
  />

  const topper = () => {
    const _text = _.startCase(activeMenuItem)
    const _dataCount = dataCounts ? dataCounts[activeMenuItem] : {}
    const { dcl, newCount } = _dataCount ?? {}
    const hasNew = newCount > 0
    let countColor = hasNew ? 'green' : 'grey'
    if (showNewOnly) { countColor = 'black' }
    return <div className={'topper-flex-2'}>
      <div> {_text}</div>
      <div>
        <Label color={'blue'}><Icon name='hashtag' />{dcl}</Label>
        <Label color={countColor} onClick={() => { hasNew && handleShowNewOnly(activeMenuItem) }}><Icon name='plus circle' />{newCount}</Label>
      </div>
    </div>
  }

  const buttons_bottomRight = () => {
    let _tabName = _.startCase(activeMenuItem)
    if (showNewOnly) { _tabName += ' - New Items Only' }
    const btns = [
      { caption: _.startCase(viewDataModes.updateDataCollection) + ' (' + _tabName + ')', oc: handleViewDataMode, icon: appIconTypes.arrowRight, disabled: !activeMenuItem, opts: { viewDataMode: viewDataModes.updateDataCollection, commitType: viewDataModes.updateDataCollection } },
    ]
    !showNewOnly && btns.push({ caption: _.startCase(viewDataModes.recreateDataCollection) + ' (' + _tabName + ')', oc: handleViewDataMode, icon: appIconTypes.arrowRight, disabled: !activeMenuItem, opts: { viewDataMode: viewDataModes.recreateDataCollection, commitType: viewDataModes.recreateDataCollection } })
    return <DropdownOptionsMenu menuButtons={btns} upward={true} caption={'Data Update Options'} color={'blue'} icon={ddIcons.updateOptions} direction={'left'} disabled={!activeMenuItem} />
  }

  const buttons_bottomLeft = () => {
    const { newCount } = currentCount ?? {}
    const btns = []
    const btns_dd = [{ caption: 'Open Database', oc: handleDatabaseOpen, icon: 'database', color: iconColorTypes.external }]
    switch (googleSheetType) {
      case googleSheetsTypes.normal:
        btns_dd.push({ caption: 'Open Google Sheets', oc: handleOpenGoogleSheets, icon: appIconTypes.externalAlternate, color: iconColorTypes.external, disabled: !googleSheetsId })
        btns_dd.push({ caption: 'Refresh Google Sheets', oc: handleGetGoogleSheet, icon: appIconTypes.refresh, color: iconColorTypes.normal, disabled: !googleSheetsId })
        btns_dd.push({ caption: 'Validate Google Sheets', oc: handleValidateDataCollections, icon: appIconTypes.refresh, color: iconColorTypes.normal, disabled: !googleSheetsId })
        btns_dd.push({ caption: 'Update Google Sheets Keys', oc: handleUpdateGoogleSheets, icon: appIconTypes.refresh, color: iconColorTypes.normal, disabled: !googleSheetsId })
        break;
      case googleSheetsTypes.registration:
        btns.push({ caption: 'Open Google Forms (' + googleFormsId + ')', oc: handleOpenGoogleForms, icon: appIconTypes.externalAlternate, color: iconColorTypes.external, disabled: !googleFormsId })
        break;
      default:
      // nothing
    }

    return <div>
      <UiSaveButtons
        others={btns}
        ddo={btns_dd.length > 0 && <DropdownOptionsMenu menuButtons={btns_dd} caption={'Actions'} icon={ddIcons.action} color={'blue'} />}
        color={'blue'}
        rows={2}
      />
    </div>
  }

  const buttons_top = () => {
    const btns = [
      { disabled: !dashboardInfo_preview, caption: _.startCase(viewDataModes.mergeSettings), oc: handleViewDataMode, icon: appIconTypes.arrowRight, opts: { viewDataMode: viewDataModes.mergeSettings } },
      { disabled: !dashboardInfo_preview, caption: _.startCase(viewDataModes.recreateDataAndSettings), oc: handleViewDataMode, icon: appIconTypes.arrowRight, opts: { viewDataMode: viewDataModes.recreateDataAndSettings } },
      { disabled: !dashboardInfo_preview, caption: _.startCase(viewDataModes.recreateDataCollections), oc: handleViewDataMode, icon: appIconTypes.arrowRight, opts: { viewDataMode: viewDataModes.recreateDataCollections, commitType: viewDataModes.recreateDataCollections } },
      { disabled: !dashboardInfo_preview, caption: _.startCase(viewDataModes.recreateDataDocuments), oc: handleViewDataMode, icon: appIconTypes.arrowRight, opts: { viewDataMode: viewDataModes.recreateDataDocuments, commitType: viewDataModes.recreateDataDocuments } },
      { disabled: !dashboardInfo_preview, caption: _.startCase(viewDataModes.recreateSettings), oc: handleViewDataMode, icon: appIconTypes.arrowRight, opts: { viewDataMode: viewDataModes.recreateSettings } },
      { disabled: !dashboardInfo_preview, caption: _.startCase(viewDataModes.updateDataCollections), oc: handleViewDataMode, icon: appIconTypes.arrowRight, opts: { viewDataMode: viewDataModes.updateDataCollections, commitType: viewDataModes.updateDataCollections } },
    ]
    return <div>
      <DropdownOptionsMenu menuButtons={btns} caption={'Data and Settings Options'} color={'blue'} icon={ddIcons.dataOptions} direction={'left'} />
    </div>
  }

  const footer = () => <div className={_useSingleItem ? 'header-non-flex' : 'header-flex'}>
    {!_useSingleItem && buttons_bottomLeft()}
    {!_useSingleItem && <Checkbox label='Use Item Key' onClick={(e) => { handleUseItemKey(e) }} />}
    {buttons_bottomRight()}
  </div>

  const content_data = () => <Wrapper
    header={topper()}
    content={<TransitionWrapper key={activeMenuItem} transitionWrapperType={transitionWrapperTypes.fade}>
      {currentDataTable}
    </TransitionWrapper>}
    footer={!showConfirmationQuestion && !_updating && !fromCreate && footer()}
    wrapperType={wrapperTypes.paddedHeader}
    updating={updating}
  />

  const menu_data = () => activeMenuItem && currentDataTable ? content_data(activeMenuItem) : <div className='cntr fw-b'>Select Menu Item</div>

  const menu_text = (key) => {
    const _text = _.startCase(key)
    const _dataCount = dataCounts ? dataCounts[key] : {}
    const { newCount } = _dataCount ?? {}
    return <div className={newCount > 0 ? 'gsin' : null}>{_text}</div>
  }

  const menu_vert = () => {
    const _googleSheetItems = _.sortBy(googleSheetItems, 'name')
    const _mis = _googleSheetItems ? _googleSheetItems.map(gsi => (
      <Menu.Item
        onClick={() => { handleDataCollectionChange(gsi.key) }}
        className={activeMenuItem === gsi.key ? 'sel' : null}
      >
        {menu_text(gsi.name)}
        <Icon name={'check circle'} style={{ float: 'right' }} color={gsi.included ? 'green' : 'grey'} onClick={(e) => { handleGsiClick(e, gsi.key) }} />
      </Menu.Item>)) : []
    return <Menu vertical>{_mis}</Menu>
  }

  const menuContent = () => <div className={_useSingleItem ? 'adv sgs' : 'adv gs'}>
    {!_useSingleItem && menu_vert()}
    {menu_data()}
  </div>

  const wrapper = () => <Wrapper
    header={!_useSingleItem && buttons_top()}
    content={menuContent()}
    wrapperType={wrapperTypes.paddedHeader}
    noMid={true}
  ></Wrapper>

  return wrapper()

}

export default GoogleSheetsTabs

