import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { alphaHandlers, alphaInitialState, alphaReducer } from '../reducers/AlphaReducer';
import { ParentContext } from './ParentContext';
import { gEnums } from '../../enums/globalEnums';
import { DataManagementContext } from '../../components/viewers/DataManagementViewer';

export const AlphaContext = createContext();

const AlphaProvider = (props) => {

  const { dataItems, grouping, handleAlphaChange, handleMouseMove } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext)
  const { states } = parentContext ?? {}
  const { page_state } = states
  const { pageSettings } = page_state ?? {}
  const { aps_styles } = pageSettings ?? {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const ignoreStyle = dataManagementContext ? true : false

  const alphabetSnC = aps_styles ? aps_styles[gEnums.projectStyles.alphabet] : {}
  if (ignoreStyle) { alphabetSnC.style = {} }

  const init_state = { alphabetSnC, grouping, handleAlphaChange, handleMouseMove }
  const [alpha_state, alpha_dispatch] = useReducer(alphaReducer, init_state, alphaInitialState)
  const alpha_handlers = alphaHandlers(alpha_dispatch, alpha_state)

  useEffect(() => {
    if (dataItems) {
      alpha_handlers.handleInit_alpha(null, dataItems, grouping, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItems]);

  const providerContext = () => <AlphaContext.Provider value={{ alpha_state, alpha_handlers }}>
    {props.children}
  </AlphaContext.Provider>

  return providerContext()
}

export default AlphaProvider