import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { homeInfoHandlers, homeInfoInitialState, homeInfoReducer } from '../reducers/HomeInfoReducer';
import { DatabaseContext } from './DatabaseContext';


/** gets the data for the uiItem.
 * @returns viewListData, confirmation, globalFiles */
export const HomeInfoContext = createContext();

/** gets the data for the uiItem.
 * @returns viewListData,  
 * @returns confirmation, 
 * @returns globalFiles */
const HomeInfoProvider = (props) => {

  // databaseContext
  const databaseContext = useContext(DatabaseContext)
  const { database_fns } = databaseContext ?? {}

  // homeInfoInitialState
  const initState = homeInfoInitialState({ database_fns })
  const [homeInfo_state, dispatch] = useReducer(homeInfoReducer, initState);
  const homeInfo_handlers = homeInfoHandlers(dispatch)

  useEffect(
    () => {
      homeInfo_handlers.handleGet_clients()
    },
    // eslint-disable-next-line
    []
  )

  const context = () => <HomeInfoContext.Provider value={{ homeInfo_state, homeInfo_handlers }}>
    {props.children}
  </HomeInfoContext.Provider>

  return context()
}

export default HomeInfoProvider