import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { dispatchProps } from '../../cnr/reducers/reducerHelpers/dispatchProps';
import { signInResponseTypes, sirts } from '../../cnr/reducers/SignInReducer';
import { createAppUser } from './createAppUser';

export const signUpEmail = (dispatch, state) => {

  const hsir = sirts.handleSignInResponse

  const auth = getAuth()
  const { creds, signInData } = state
  const { firstName, lastName } = signInData ? signInData : creds

  createUserWithEmailAndPassword(
    auth,
    creds.email.toLowerCase(),
    creds.password
  ).then((resp) => {
    // add a new profile with the id IF it doesn't exist  
    var user = auth.currentUser;
    // update the AUTH profile with the displayName
    user.updateProfile({
      displayName: firstName + ' ' + lastName
    }).then(function () {
      dispatch({ type: hsir, signInResponseType: signInResponseTypes.signInWithEmailSuccess, state })
    }).catch(function (error) {
      dispatch({ type: hsir, signInResponseType: signInResponseTypes.signUpError, state })
    });
  }).catch((err) => {
    dispatch({ type: hsir, signInResponseType: signInResponseTypes.signUpError, state, err })
  })
}

export const signUpEmailDirect = (dispatch, email, pw, userData, papsState) => {

  const auth = getAuth()
  const { firstName, lastName } = userData

  createUserWithEmailAndPassword(
    auth,
    email.toLowerCase(),
    pw.toLowerCase()
  ).then((resp) => {
    // add a new profile with the id IF it doesn't exist  
    var user = auth.currentUser;
    // update the AUTH profile with the displayName
    user.updateProfile({
      displayName: firstName + ' ' + lastName
    }).then(function () {
      createAppUser(dispatch, resp.user.uid, email, userData, papsState)
    }).catch(function (error) {
      dispatch({ type: dispatchProps.success })
    });
  }).catch((err) => {
    dispatch({ type: dispatchProps.error, err })
  })
}