import _ from 'lodash';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Accordion, Message, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { HelpContext } from '../../cnr/contexts/HelpContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { ViewSettingsContext } from '../../cnr/contexts/ViewSettingsContext';
import { helpTypes } from '../../cnr/reducers/HelpReducer';
import { uniqueKey } from '../../common/keys';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import HelpEdit from './HelpEdit';
import { formatDescription, getEnumLabels, getHelps, groupItemIgnore } from './helpSettings';

/**
 * 
 * @param {object} props (helpType, hsProps, groupItems, topOnly)
 * groupItems are the list of props (banner, bannerHeight, bannerSize, etc.) within the group
 * @returns 
 */
const Help = (props) => {

  const { helpType, helpKey: helpKey_props, hsProps, groupItems, topOnly } = props

  const parentContext = useContext(ParentContext);
  const { states, settings } = parentContext ?? {}
  const { appUser_state } = states ?? {}
  const { homeSettings } = settings ?? {}
  const { global: global_home } = homeSettings ?? {}
  const { settingsOptions: settingsOptions_home } = global_home ?? {}

  // authContext 
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { isSuperAdmin } = appUserAccess ?? {}

  const { useDarkMode } = settingsOptions_home ?? {}

  // viewSettingsContext 
  const viewSettingsContext = useContext(ViewSettingsContext);
  const { viewSettings_state, viewSettings_handlers } = viewSettingsContext ?? {}
  const { selectedGroupItem, helpKey, isGlobal } = viewSettings_state ?? {}
  const { handleShowHelp } = viewSettings_handlers ? viewSettings_handlers : {}

  const _helpKey = helpKey ? helpKey : helpKey_props

  // helpContext
  const helpContext = useContext(HelpContext)
  const { help_state, help_handlers } = helpContext ? helpContext : {}
  const { helpType_active, helpGroup } = help_state ?? {}

  const _helpDocField = isGlobal ? 'vw' : 'vi'
  const _hsProps = hsProps ? hsProps : { helpDocField: _helpDocField, helpDocSubField: selectedGroupItem.key }

  // local state   
  const [activeIndex, setActiveIndex] = useState();
  const [helpEditItem, setHelpEditItem] = useState();
  const [sortedHelpProps] = useState(_.sortBy(groupItems, 'prop'))

  const handleCancel = () => props.handleCancel ? props.handleCancel() : handleShowHelp()

  const helpProps = { inverted: useDarkMode }

  switch (_helpKey) {
    case 'ticketing':
      helpProps.inverted = false
      break;
    default:
    // nothing
  }

  useEffect(() => {
    help_handlers.handleGet_currentHelp({ helpType, hsProps: _hsProps })
    // eslint-disable-next-line
  }, []);

  const handleItemClick = (_e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
    setHelpEditItem()
  }

  const handleCloseHelpEdit = () => setHelpEditItem()
  const handleHelpEdit = (hsProps) => { if (isSuperAdmin && !helpEditItem) { setHelpEditItem(hsProps) } }

  const helpEdit = (hsProps, helpType) => {
    const helpProps = {
      hsProps,
      helpType: helpType ? helpType : helpType_active,
      helpKey: _helpKey,
      handleCloseHelpEdit: handleCloseHelpEdit
    }
    return <HelpEdit helpProps={helpProps} useDarkMode={useDarkMode} />
  }

  const allowHelpEdit = (hssProps) => {
    let _showHelp = false
    if (helpEditItem) {
      if (helpEditItem.helpProp) {
        if (helpEditItem.helpProp === hssProps.helpProp) { _showHelp = true }
      } else {
        if (!hssProps.helpProp) {
          if (helpEditItem.helpDocSubField === hssProps.helpDocSubField) { _showHelp = true }
        }
      }
    }
    return _showHelp
  }

  // this can be the title r
  const helpItemContent = (prop) => {

    const hssProps = { ...hsProps }

    if (prop) { hssProps.helpProp = prop }

    const { description } = getHelps(helpGroup, hssProps)
    const enumLabels = getEnumLabels(prop)

    const _description = description ? description : 'Help has not been set for ' + _.startCase(helpKey_props) + '. Click to edit'

    return <Message attached onClick={_e => handleHelpEdit(hssProps)}>
      <Message.Content>{formatDescription(_description)}</Message.Content>
      {enumLabels && <Message.Content className={'help-list'}>{enumLabels}</Message.Content>}
      {isSuperAdmin && allowHelpEdit(hssProps) && <Message.Content>{helpEdit(helpEditItem)}</Message.Content>}
    </Message>
  }

  const singleMessage = () => <Segment basic className={'help-container h'}>
    {helpItemContent()}
  </Segment>

  const accordionItem = (prop, index, sty) => <Fragment key={uniqueKey('hlp', 'ai', index)}>
    <Accordion.Title style={sty}
      active={activeIndex === index}
      content={_.startCase(prop)}
      index={index}
      onClick={handleItemClick}
    />
    <Accordion.Content active={activeIndex === index} content={helpItemContent(prop)} />
  </Fragment>

  const accordionItems = () => {
    const panels = []
    const combinedHelp = []
    let total = 0
    sortedHelpProps.forEach((helpProp, index) => {
      const { prop } = helpProp
      if (!groupItemIgnore(prop, combinedHelp)) {
        if (prop) {
          const sty = helpGroup && helpGroup.items && helpGroup.items[prop] ? {} : { color: 'grey' }
          panels.push(accordionItem(prop, index, sty))
        } else {
          panels.push(accordionItem(prop, index))
        }
      }
      total++
    })
    if (combinedHelp.length > 0) {
      combinedHelp.forEach(chi => {
        panels.unshift(accordionItem(chi, total))
        total++
      })
    }
    return panels
  }

  const helpAccordion = () => <Segment basic className={'help-seg'} {...helpProps}>
    {helpItemContent()}
    <Accordion className={'help-accordion'}  {...helpProps}>
      {accordionItems()}
    </Accordion>
  </Segment>


  console.log('helpType_active', helpType_active)

  const helpContent = () => {
    if (helpType_active) {
      switch (helpType_active) {
        case helpTypes.documents:
          return helpAccordion()
        case helpTypes.singleItem:
          return singleMessage()
        default:
          return singleMessage()
      }
    }
    return <div>Help Item</div>
  }

  return topOnly ? helpContent() : <FullPageWrapper
    content={helpContent()}
    handleCancel={handleCancel}
    topperCaption={'Help'}
    topperCaption2={_.startCase(_helpKey)}
    noModal={true}
    isSub={true}
  />

}

export default Help