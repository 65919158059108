import _ from 'lodash';
import { convertGsToHtml, createStaticViewFromDataCollections } from "../../common/convert";
import { dataFix } from '../../common/dataFix';
import { ammendAllDates } from '../../common/dateFormatting';
import { copyObj } from "../../common_web/copy";
import { recreateAllDataCollections, recreateAllDataCollectionsOneByOne } from "../../firestoreData/appData/fsRecreateData";
import { fsfn_create } from "../../functions/fbCreate";
import { fsfn_sheets } from "../../functions/fbSheetsFuntions";
import { settingsViewerTypes } from '../../viewSettings/actions/SettingsViewer';
import { createResponseTypes, createTypes } from "./CreateReducer";
import { viewDataModes } from './DataManagmentReducer';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";
import { responsePromiseHandler } from "./reducerHelpers/reponsePromiseHandler";
import { updateDocumentHelpers } from '../../firestoreData/updates/subUpdates/fsUpdate_document';

// =UNIQUE(Attendees!B:B)
// =ARRAYFORMULA(TRANSPOSE(UNIQUE(Sessions!B2:B)))

const rts = {
  handle_gsAmmendments: 'handle_gsAmmendments',
  handleAmmend_settingsViewer: 'handleAmmend_settingsViewer',
  handleCallback_updateOneByOne: 'handleCallback_updateOneByOne',
  handleCreate_eventDataFromGs: 'handleCreate_eventDataFromGs',
  handleCreate_eventFromGs: 'handleCreate_eventFromGs',
  handleCreate_googleSpreadsheetTab: 'handleCreate_googleSpreadsheetTab', // pending
  handleGet_googleSheet: 'handleGet_googleSheet',
  handleGet_previousPreviewInfo: 'handleGet_previousPreviewInfo',
  handleGet_settingsViewerProps: 'handleGet_settingsViewerProps',
  handleMerge_settings: 'handleMerge_settings',
  handlePreview: 'handlePreview',
  handleRecreate_dataCollectionsOneByOne: 'handleRecreate_dataCollectionsOneByOne',
  handleRecreateDataCollections: 'handleRecreateDataCollections',
  handleRecreateDataDocuments: 'handleRecreateDataDocuments',
  handleResponse_googleSheet: 'handleResponse_googleSheet',
  handleSet_activeMenuItem: 'handleSet_activeMenuItem',
  handleSet_googleMode: 'handleSet_googleMode',
  handleSet_previewInfo: 'handleSet_previewInfo',
  handleSet_projectOptions: 'handleSet_projectOptions',
  handleSet_themeColors: 'handleSet_themeColors',
  handleSet_updateOptions: 'handleSet_updateOptions',
  handleShow_newOnly: 'handleShow_newOnly',
  handleUpdate_dataCollectionsOneByOne: 'handleUpdate_dataCollectionsOneByOne',
  handleUpdate_googleSpreadsheetTab: 'handleUpdate_googleSpreadsheetTab',
  ...grts,
  ...createResponseTypes
}

export const googleSheetsTypes = {
  create: 'create',
  normal: 'normal',
  registration: 'registration',
  app: 'app',
}

export const googleModeTypes = {
  none: 'none',
  app: 'app',
  commit: 'commit',
  data: 'data',
  preview: 'preview',
  previewDirect: 'previewDirect',
  previewing: 'previewing',
  sheets: 'sheets',
}

export const googlePreviewTypes = {
  none: 'none',
  newFull: 'newFull',
  newDataOnly: 'newDataOnly',
  dataOnly: 'dataOnly'
}

export const googleConfirmationTypes = {
  create: 'create',
  createWithDataSheet: 'createWithDataSheet',
  data: 'data',
  sheetsData: 'sheetsData',
  settings: 'settings',
  recreate: 'recreate',
}

const _overrideProps = false
const _inverseMerge = false

export const googleSheetsInitialState = (initState) => {
  return { ...initState, googleUpdateStatus: {} }
};

export const googleSheetsReducer = (state, action) => {

  const { type, dispatch } = action

  const { appInfo, googleSheetsId, dashboardInfo_preview, pathViews, logging, googleSheetsImportOptions, databaseProjectId, useAppDataLinks, appDataLinks, appDataSource } = state ?? {}
  const { pageSettings: pageSettings_app, staticViews: staticViews_app, aps_viewItems, aps_views } = appInfo ?? {}

  let _googleSheetsImportOptions = action.googleSheetsImportOptions ? action.googleSheetsImportOptions : googleSheetsImportOptions
  if (!_googleSheetsImportOptions) { _googleSheetsImportOptions = {} }

  const _gsh = googleSheetsHandlers(action.dispatch)
  const { handleResponse_googleSheet, handleFunctionResponse, handleCallback_updateOneByOne } = _gsh

  switch (type) {

    // GETS the google sheets data
    case rts.handleGet_googleSheet:
      getDataFromGoogleSheets(action, { pathViews, _googleSheetsImportOptions, staticViews_app, aps_viewItems, aps_views, pageSettings_app }, handleResponse_googleSheet)
      return { ...state, updating: true }

    case rts.handleResponse_googleSheet:
      const { gsResponse } = action
      const { _preview, svs, dcs } = gsResponse ?? {}
      const { newCounts } = _preview ?? {}

      if (useAppDataLinks && appDataLinks) {
        ammendGsWithDataLinks(_preview, appDataLinks)
      }

      console.log('dcs', dcs)
      console.log('_preview', _preview)

      return {
        ...state,
        dashboardInfo_preview: _preview,
        googleModeType: googleModeTypes.app,
        googleSheetsData: dcs,
        newCounts,
        previewStatic: svs,
        staticViews: svs,
        updating: false,
      }

    case rts.handle_gsAmmendments:
      ammendDataFromGoogleSheets(state.dashboardInfo_preview, { pathViews, _googleSheetsImportOptions, staticViews_app, aps_viewItems, aps_views, pageSettings_app }, handleResponse_googleSheet, action.googleSheetItems)
      return { ...state }

    case rts.handleGet_settingsViewerProps:
      return { ...state, settingsViewerProps: settingsViewerProps(action.viewDataMode, action.fromCreate, state.activeMenuItem, state) }

    case rts.handleMerge_settings:
      const _dashboardInfo_merged = getMergedSettings(dashboardInfo_preview, pageSettings_app, staticViews_app, action.globalOnly, true, true)
      const _dashboardInfo_recreate = getMergedSettings(dashboardInfo_preview, pageSettings_app, staticViews_app, action.globalOnly, false, true)
      return { ...state, dashboardInfo_merged: _dashboardInfo_merged, dashboardInfo_recreate: _dashboardInfo_recreate }

    case rts.handleAmmend_settingsViewer:
      return { ...state, newSettings: action.newSettings }

    // create the event in  the database
    case rts.handleCreate_eventFromGs:
    case rts.handleCreate_eventDataFromGs:
      const { destination } = action
      const _createInfo = action.createInfo ? action.createInfo : { ...dashboardInfo_preview }
      if (destination && destination.clientKey && _createInfo.projectData && _createInfo.projectData.clientData) {
        _createInfo.projectData.clientData.clientMobileKey = destination.clientKey
      }
      const response_promise = fsfn_create.createEventInDatabase(_createInfo, action.recreate, logging, pathViews, databaseProjectId, action.addModeType, action.config_alt, destination)
      responsePromiseHandler(response_promise, handleFunctionResponse, dispatch)
      return { ...state }

    case rts.handleRecreateDataCollections:
      recreateAllDataCollections(state, action.collectionName, action.updateOnly).then(res => {
        dispatch({ type: grts.updateSuccess, dispatch, opts: { contentItems: res } })
      }).catch(error => {
        console.log('error', error)
      })
      return { ...state }

    case rts.handleRecreate_dataCollectionsOneByOne:
      recreateAllDataCollectionsOneByOne(state, action.collectionName, action.updateTheSettings, action.updateOnly, action.allowMerge, action.newOnly, handleCallback_updateOneByOne)
      return { ...state }

    case rts.handleUpdate_dataCollectionsOneByOne:
      recreateAllDataCollectionsOneByOne(state, action.collectionName, action.updateTheSettings, true, true, state.showNewOnly, handleCallback_updateOneByOne)
      return { ...state }

    case rts.handleRecreateDataDocuments:
      const { dashboardInfo_recreate } = state
      const { projectData } = dashboardInfo_recreate ?? {}
      const { dataCollections } = projectData ?? {}
      updateDocumentHelpers.update_dataToDocuments(pathViews, dataCollections, dispatch)
      return { ...state }

    case rts.handleCallback_updateOneByOne:
      return { ...state, dcUpdates: action.done ? null : action.dcUpdates, updating: action.done ? null : state.updating }

    case rts.handleCreateSpreadsheet:
      fsfn_sheets.createGoogleSpreadsheet(action.title).then(response_data => {
        const { success } = response_data ?? {}
        console.log('SUCCESS', success)
      })
      return { ...state }

    case rts.handleUpdate_googleSpreadsheetTab:
      fsfn_sheets.updateGoogleSheetValues(googleSheetsId, action.tabName, action.values)
      return { ...state }

    case rts.handleCreate_googleSpreadsheetTab:
      fsfn_sheets.createGoogleSpreadsheetTab(googleSheetsId, action.tabName, action.fetchFirestoreData, action.values).then(response_data => {
        dispatch({ type: rts.updateSuccess, dispatch })
      })
      return { ...state }

    case rts.handleSet_activeMenuItem:
      return { ...state, activeMenuItem: action.activeMenuItem }

    case rts.handleSet_googleMode:
      switch (action.googleModeType) {
        case googleModeTypes.none:
          return {
            ...state,
            googleModeType: googleModeTypes.none,
            googlePreviewType: googlePreviewTypes.none,
            googleSheetsData: null,
            dashboardInfo_preview: null,
            googleUpdateStatus: null,
            showConfirmationQuestion: null
          }
        default:
          return {
            ...state,
            googleModeType: state.googleModeType === action.googleModeType ? googleModeTypes.none : action.googleModeType
          }
      }

    case rts.handleShow_newOnly:
      return { ...state, showNewOnly: !state.showNewOnly }

    case rts.handleSet_previewInfo:
      return { ...state, dashboardInfo_preview: action.pi }

    case rts.handlePreview:
      const { dataOnly } = action.opts
      return {
        ...state,
        googlePreviewType: dataOnly ? googlePreviewTypes.newDataOnly : googlePreviewTypes.newFull,
        googleModeType: googleModeTypes.previewing
      }

    // case rts.handleCancelConfirmation:
    //   return { ...state, showConfirmationQuestion: false }

    // case rts.handleUpdates:
    //   return { ...state, showConfirmationQuestion: false, googleUpdateStatus: getGoogleUpdateStatus(state, action) }

    // case rts.handleUpdated:
    //   return { ...state, showConfirmationQuestion: false, googleUpdateStatus: getGoogleUpdatedStatus(state, action) }

    case rts.handleSet_updateOptions:
      return { ...state, googleSheetsImportOptions: action.googleSheetsImportOptions, storageRootPath: action.storageRootPath, logging: action.logging }

    case rts.handleSet_projectOptions:
      return { ...state, projectOptions: action.projectOptions }

    case rts.handleSet_themeColors:
      return { ...state, themeColors: action.themeColors }

    case rts.handleGet_previousPreviewInfo:
      const xxxx = getPreviousPreviewInfo(dashboardInfo_preview, action.previousEventSettings)
      return { ...state, ...xxxx }

    case rts.handleStopUpdate:
      return { ...state, updating: null }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action,
        {
          dispatch,
          dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation,
          questionProps: null,
          updatingCaption: 'Creating',
        })

    default:
      return state;
  }
}

export const googleSheetsHandlers = (dispatch) => {
  return {
    handle_gsAmmendments: (googleSheetItems) => { dispatch({ type: rts.handle_gsAmmendments, dispatch, googleSheetItems }) },
    handleAmmend_settingsViewer: (newSettings) => { dispatch({ type: rts.handleAmmend_settingsViewer, dispatch, newSettings, }) },
    handleCallback_updateOneByOne: (dcUpdates, done) => { dispatch({ type: rts.handleCallback_updateOneByOne, dispatch, dcUpdates, done }) },
    handleCreate_eventDataFromGs: (eventOptions) => { dispatch({ type: rts.handleCreate_eventDataFromGs, dispatch, eventOptions }) },
    handleCreate_eventFromGs: (createInfo, recreate, addModeType, config_alt, destination) => { dispatch({ type: rts.handleCreate_eventFromGs, dispatch, createInfo, recreate, addModeType, config_alt, destination }) },
    handleCreate_googleSpreadsheetTab: (tabName, fetchFirestoreData, values) => { dispatch({ type: rts.handleCreate_googleSpreadsheetTab, dispatch, tabName, fetchFirestoreData, values }) },
    handleCreateSpreadsheet: (title) => { dispatch({ type: rts.handleCreateSpreadsheet, dispatch, title }) },
    handleGet_googleSheet: (gsid, useItemKey, fromCreate) => { dispatch({ type: rts.handleGet_googleSheet, dispatch, gsid, useItemKey, fromCreate }) },
    handleGet_previousPreviewInfo: (previousEventSettings) => { dispatch({ type: rts.handleGet_previousPreviewInfo, dispatch, previousEventSettings }) },
    handleGet_settingsViewerProps: (viewDataMode, fromCreate) => { dispatch({ type: rts.handleGet_settingsViewerProps, dispatch, viewDataMode, fromCreate }) },
    handleMerge_settings: (globalOnly) => { dispatch({ type: rts.handleMerge_settings, dispatch, globalOnly }) },
    handlePreview: (opts) => { dispatch({ type: rts.handlePreview, dispatch, opts }) },
    handleRecreate_dataCollectionsOneByOne: (collectionName, updateTheSettings, updateStaticViews, updateOnly, allowMerge, newOnly) => { dispatch({ type: rts.handleRecreate_dataCollectionsOneByOne, dispatch, collectionName, updateTheSettings, updateStaticViews, updateOnly, allowMerge, newOnly }) },
    handleRecreateDataCollections: (collectionName, updateOnly) => { dispatch({ type: rts.handleRecreateDataCollections, dispatch, collectionName, updateOnly }) },
    handleRecreateDataDocuments: () => { dispatch({ type: rts.handleRecreateDataDocuments, dispatch }) },
    handleResponse_googleSheet: (gsResponse) => { dispatch({ type: rts.handleResponse_googleSheet, dispatch, gsResponse }) },
    handleSet_activeMenuItem: (activeMenuItem) => { dispatch({ type: rts.handleSet_activeMenuItem, dispatch, activeMenuItem }) },
    handleSet_googleMode: (googleModeType) => { dispatch({ type: rts.handleSet_googleMode, dispatch, googleModeType }) },
    handleSet_previewInfo: (pi) => { dispatch({ type: rts.handleSet_previewInfo, dispatch, pi }) },
    handleSet_projectOptions: (projectOptions) => { dispatch({ type: rts.handleSet_projectOptions, dispatch, projectOptions }) },
    handleSet_themeColors: (themeColors) => { dispatch({ type: rts.handleSet_themeColors, dispatch, themeColors }) },
    handleSet_updateOptions: (googleSheetsImportOptions, storageRootPath, logging) => { dispatch({ type: rts.handleSet_updateOptions, dispatch, googleSheetsImportOptions, storageRootPath, logging }) },
    handleShow_newOnly: () => { dispatch({ type: rts.handleShow_newOnly, dispatch }) },
    handleUpdate_dataCollectionsOneByOne: (collectionName, updateTheSettings, updateStaticViews) => { dispatch({ type: rts.handleUpdate_dataCollectionsOneByOne, dispatch, collectionName, updateTheSettings, updateStaticViews }) },
    handleUpdate_googleSpreadsheetTab: (tabName, values) => { dispatch({ type: rts.handleUpdate_googleSpreadsheetTab, dispatch, tabName, values }) },
    ...responseHandlers(dispatch)
  }
}

const getDataFromGoogleSheets = async (action, opts, callback) => {

  const { gsid, fromCreate } = action ?? {}
  const { pathViews, _googleSheetsImportOptions, staticViews_app, aps_viewItems } = opts
  const formData = { eventName: 'Settings', googleSheetsId: gsid }
  const sheetProps = { formData, pathViews }
  const response = await fsfn_sheets.createProjectFromSheets(sheetProps, 'event', _googleSheetsImportOptions, null, staticViews_app, true, aps_viewItems)
  const { result } = response ?? {}

  const _opts = { ...opts, fromCreate }

  ammendDataFromGoogleSheets(result, _opts, callback)

}

const ammendDataFromGoogleSheets = (result, opts, callback, googleSheetItems) => {

  const { projectData, projectSettings, newCounts } = result ?? {}
  const { viewItems, views } = projectSettings ?? {}
  const { aps_viewItems, aps_views, pageSettings_app, fromCreate } = opts ?? {}

  const _excluded = googleSheetItems ? _.filter(googleSheetItems, { included: false }) : []
  const excludedItemKeys = _excluded.map(item => item.key);

  if (excludedItemKeys.length > 0) {
    removeExcludedItems(viewItems, excludedItemKeys)
    removeExcludedItems(views, excludedItemKeys, true)
    removeExcludedItems(aps_viewItems, excludedItemKeys)
    removeExcludedItems(aps_views, excludedItemKeys)
    removeExcludedItems(aps_views, excludedItemKeys, true)
  }

  const { dataCollections } = projectData ?? {}

  const svs = createStaticViewFromDataCollections(dataCollections, aps_viewItems)
  if (dataCollections) { ammendAllDates(dataCollections) }
  convertGsToHtml(dataCollections)

  const _projectSettings = getPreviewSettings(projectSettings, pageSettings_app, fromCreate)

  const _preview = {
    projectData: projectData,
    projectSettings: _projectSettings,
    newCounts: newCounts,
  }

  callback({ _preview, svs, dcs: dataCollections })
}

const removeExcludedItems = (items, excludedItemKeys, includeList) => {
  if (items && excludedItemKeys) {
    Object.keys(items).forEach(vik => {
      const _vik = includeList ? vik.replace('_list', '') : vik
      if (excludedItemKeys.includes(_vik)) {
        delete items[vik]
      }
    })
  }
}

const getAppSettings = (dashboardInfo_preview) => {
  const { projectSettings } = dashboardInfo_preview
  const { global, viewItems, views } = projectSettings ?? {}
  if (global && viewItems && views) {
    const appSettings = {
      global,
      viewItems,
      views,
    }
    return appSettings
  }
}

const getGoogleUpdateStatus = (state, action) => {
  const { googleUpdateStatus } = state
  const { statusKey, status } = action
  const _updateStatus = { ...googleUpdateStatus }
  if (!_updateStatus[statusKey]) { _updateStatus[statusKey] = {} }
  _updateStatus[statusKey].status = status
  return _updateStatus
}

const getGoogleUpdatedStatus = (state, action) => {
  const { googleUpdateStatus } = state
  const { response } = action
  const { success, collectionName } = response ?? {}
  const _updateStatus = { ...googleUpdateStatus }
  if (!_updateStatus[collectionName]) { _updateStatus[collectionName] = {} }
  _updateStatus[collectionName].status = success ? 'completed' : 'failed'
  return _updateStatus
}

const getPreviewSettings = (projectSettings, pageSettings_app, fromCreate) => {

  const { aps_global, aps_viewItems, aps_views } = pageSettings_app ?? {}
  const { global, viewItems, views } = projectSettings ?? {}

  const _deletedItems = {
    views: [],
    props: [],
    propSections: []
  }

  let _projectSettings = {}

  const settings_fromGs = {
    global: global,
    viewItems: viewItems,
    views: views,
  }
  // at this point, any added connections are not there
  const settings_adjusted = {
    global: mergeObjs(global, aps_global),
    viewItems: viewItems ? fromCreate ? viewItems : mergeObjs(viewItems, aps_viewItems) : aps_viewItems,
    views: views ? fromCreate ? views : mergeObjs(views, aps_views) : aps_views,
  }

  // console.log('settings_fromGs', fromCreate, settings_fromGs.views.events_list)
  // console.log('settings_adjusted', fromCreate, settings_adjusted.views.events_list)
  // const glb = global ? fromCreate ? global : mergeObjs(global, aps_global) : aps_global
  // const vis = viewItems ? fromCreate ? viewItems : mergeObjs(viewItems, aps_viewItems) : aps_viewItems
  // const vws = views ? fromCreate ? views : mergeObjs(views, aps_views) : aps_views

  if (_overrideProps) {
    ammendPropsAndSections(settings_adjusted.viewItems, viewItems)
    ammendPropsAndSections(settings_adjusted.views, views)
  }

  removeNonExistingPropsAndPropSections(settings_adjusted.viewItems, viewItems, _deletedItems)

  // loop the views
  Object.keys(settings_adjusted.views).forEach(vwKey => {
    const vw_m = settings_adjusted.views ? settings_adjusted.views[vwKey] : {}
    const vw_o = views ? views[vwKey] : {}
    const { viewItems: viewItems_m } = vw_m ?? {}
    const { viewItems: viewItems_o } = vw_o ?? {}
    removeNonExistingPropsAndPropSections(viewItems_m, viewItems_o, _deletedItems)
  })

  // const _vis = dataFix.ammendOmits(vis, _deleteViProps)

  _projectSettings = {
    global: settings_adjusted.global,
    viewItems: settings_adjusted.viewItems,
    views: settings_adjusted.views,
  }

  return _projectSettings

}

const ammendPropsAndSections = (merged, original) => {
  Object.keys(merged).forEach(k => {
    const _vim = merged[k]
    const _vio = original[k]
    const { viewItems: viewItems_m } = _vim
    const { props, propSections, viewItems: viewItems_o } = _vio

    if (props) { _vim.props = props }
    if (propSections) { _vim.propSections = propSections }

    if (viewItems_m) {
      Object.keys(viewItems_m).forEach(vik => {
        const viewItem_m = viewItems_m[vik]
        const viewItem_o = viewItems_o ? viewItems_o[vik] : null
        if (viewItem_m && viewItem_o) {
          const { props: props_o, propSections: propSections_o } = viewItem_o
          if (props_o && Object.keys(props_o).length > 0) { viewItem_m.props = props_o }
          if (propSections_o && Object.keys(propSections_o).length > 0) { viewItem_m.propSections = propSections_o }
        }
      })
    }
  })

}

// const customizer = (originalItem, newItem, key) => {
//     switch (key) {
//     case 'propSection':
//       return originalItem
//     default:
//       return newItem
//   }
//   switch (key) {
//     case 'propSection':
//       return originalItem
//     default:
//       return newItem
//   }
// };

const removeNonExistingPropsAndPropSections = (viewItems_merged, viewItems, deletedItems) => {
  if (viewItems_merged && viewItems) {
    Object.keys(viewItems_merged).forEach(viKey => {
      const vi_m = viewItems_merged[viKey]
      const vi = viewItems[viKey]
      const { props: props_m, propSections: propSections_m } = vi_m ?? {}
      const { props, propSections } = vi ?? {}
      const propKeys = props ? Object.keys(props) : []
      const propSectionKeys = propSections ? Object.keys(propSections) : []
      if (props_m) {
        Object.keys(props_m).forEach(prmKey => {
          if (!propKeys.includes(prmKey)) {
            deletedItems.props.push({ viKey, prmKey })
            delete props_m[prmKey]
          }
        })
      }
      if (propSections_m) {
        Object.keys(propSections_m).forEach(psKey => {
          if (!propSectionKeys.includes(psKey)) {
            deletedItems.propSections.push({ viKey, psKey })
            delete propSections_m[psKey]
          }
        })
      }
    })
  }
}

const getMergedSettings = (dashboardInfo_preview, pageSettings_app, staticViews_app, globalOnly, forUpdate, newIsPriority) => {

  const { aps_global, aps_viewItems, aps_views } = pageSettings_app
  const { projectSettings: projectSettings_preview, projectData } = dashboardInfo_preview ?? {}
  const { dataCollections } = projectData ?? {}
  const _dataCollections = dataCollections ? copyObj(dataCollections) : {}
  const { global: global_preview, viewItems: viewItems_preview, views: views_preview } = projectSettings_preview ?? {}

  let _projectSettings = {}

  if (globalOnly) {
    _projectSettings = {
      viewItems: viewItems_preview,
      views: views_preview,
    }
  } else {
    const vis = mergeObjs(viewItems_preview, aps_viewItems, _inverseMerge)
    const vws = mergeObjs(views_preview, aps_views, _inverseMerge)

    if (_overrideProps) {
      ammendPropsAndSections(vis, viewItems_preview)
      ammendPropsAndSections(vws, views_preview)
    }

    _projectSettings = {
      global: global_preview,
      viewItems: vis,
      views: vws,
    }
  }
  if (forUpdate && _dataCollections && viewItems_preview && staticViews_app) {
    Object.keys(viewItems_preview).forEach(key => {
      if (staticViews_app[key]) {
        delete _dataCollections[key]
      }
    })
  }
  const _projectData = {
    dataCollections: _dataCollections
  }

  return { projectSettings: _projectSettings, projectData: _projectData }
}

const getPreviousPreviewInfo = (dashboardInfo_preview, previousEventSettings) => {

  const { global: global_previous, viewItems: viewItems_previous, views: views_previous } = previousEventSettings ?? {}
  const { projectOptions: projectOptions_previous, themeColors: themeColors_previous } = global_previous ?? {}

  const _previewInfo = { ...dashboardInfo_preview }
  const { projectSettings } = _previewInfo ?? {}

  const { global: global_current, viewItems: viewItems_current, views: views_current } = projectSettings ?? {}

  _previewInfo.projectSettings.global = global_previous

  // merge the objects
  _previewInfo.projectSettings.viewItems = mergeObjs(viewItems_current, viewItems_previous, _inverseMerge)
  _previewInfo.projectSettings.views = mergeObjs(views_current, views_previous, _inverseMerge)
  _previewInfo.projectSettings.global.topMenu = _.merge({}, global_current.topMenu, global_previous.topMenu);

  if (_overrideProps) {
    ammendPropsAndSections(_previewInfo.projectSettings.viewItems, viewItems_previous)
    ammendPropsAndSections(_previewInfo.projectSettings.views, views_previous)
  }

  _previewInfo.projectSettings.global.googleSheets = global_current.googleSheets

  if (global_current.banner) { _previewInfo.projectSettings.global.banner = global_current.banner }
  if (global_current.homePage) { _previewInfo.projectSettings.global.homePage = global_current.homePage }

  delete _previewInfo.projectSettings.global._itemKey
  delete _previewInfo.projectSettings.global.id
  delete _previewInfo.projectSettings.views._itemKey
  delete _previewInfo.projectSettings.views.id
  delete _previewInfo.projectSettings.viewItems._itemKey
  delete _previewInfo.projectSettings.viewItems.id

  _previewInfo.projectSettings.global = dataFix.removeAllEmpties(_previewInfo.projectSettings.global)

  return {
    dashboardInfo_preview: _previewInfo,
    themeColors: themeColors_previous,
    projectOptions: projectOptions_previous
  }
}

const settingsViewerProps = (viewDataMode, fromCreate, activeMenuItem, state) => {

  const { dashboardInfo_preview, dashboardInfo_recreate, dashboardInfo_merged } = state

  let ci;
  let createType = createTypes.updateSettings
  let _createType = fromCreate ? null : settingsViewerTypes.settingsOnly

  switch (viewDataMode) {

    case viewDataModes.updateDataCollections:
      const projDCUs = {
        projectData: dashboardInfo_recreate.projectData,
        projectCollection: activeMenuItem,
      }
      ci = projDCUs
      _createType = settingsViewerTypes.updateEvent
      createType = createTypes.updateDataCollections
      break;

    case viewDataModes.updateDataCollection:
      const projDCU = {
        projectData: dashboardInfo_recreate.projectData,
        projectCollection: activeMenuItem,
      }
      ci = projDCU
      _createType = settingsViewerTypes.updateEvent
      createType = createTypes.updateDataCollection
      break;

    case viewDataModes.recreateDataCollection:
      const projDC = {
        projectData: dashboardInfo_recreate.projectData,
        projectCollection: activeMenuItem,
      }
      ci = projDC
      _createType = settingsViewerTypes.updateEvent
      createType = createTypes.recreateDataCollection
      break;

    case viewDataModes.recreateDataCollections:
      const projA = {
        projectData: dashboardInfo_recreate.projectData
      }
      ci = projA
      _createType = settingsViewerTypes.updateEvent
      createType = createTypes.recreateDataCollections
      break;

    case viewDataModes.recreateDataDocuments:
      const projD = {
        projectData: dashboardInfo_recreate.projectData
      }
      ci = projD
      _createType = settingsViewerTypes.updateEvent
      createType = createTypes.recreateDataDocuments
      break;

    case viewDataModes.recreateDataAndSettings:
      const projR = {
        projectSettings: dashboardInfo_recreate.projectSettings,
        projectData: dashboardInfo_recreate.projectData
      }
      ci = projR
      _createType = settingsViewerTypes.updateEvent
      createType = createTypes.recreateDataAndSettings
      break;

    case viewDataModes.mergeSettings:
      const projUM = {
        projectSettings: dashboardInfo_merged.projectSettings,
      }
      ci = projUM
      _createType = settingsViewerTypes.updateEvent
      createType = createTypes.mergeSettings
      break;

    case viewDataModes.recreateSettings:
      const projU = {
        projectSettings: dashboardInfo_preview.projectSettings,
      }
      ci = projU
      _createType = settingsViewerTypes.updateEvent
      createType = createTypes.recreateSettings
      break;

    default:
      ci = dashboardInfo_preview
  }

  return { _createType, createType, ci }
}

const mergeObjs = (newItem, existingItem, inverse) => {
  if (inverse) {
    return _.merge({}, existingItem, newItem)
  } else {
    return _.merge({}, newItem, existingItem)
  }
}

const ammendGsWithDataLinks = (_preview, appDataLinks) => {
  if (_preview && _preview.projectData && _preview.projectData.dataCollections) {
    _.forEach(appDataLinks, (appDataLink, key) => {
      if (_preview.projectData.dataCollections[key]) {
        const _dataCollection = _preview.projectData.dataCollections[key]
        _.forEach(appDataLink, (item, key) => {
          if (_dataCollection[key]) {
            const di = _dataCollection[key]
            _.forEach(item, (propValue, propKey) => {
              if (!di[propKey]) {
                di[propKey] = propValue
              }
            })
          }
        })
      }
    })
  }
}