import _ from 'lodash';
import { hashmark, useHash } from "../../../App";
import { getStartTimeUtc } from '../../common/dateFormatting';
import { gEnums } from '../../enums/globalEnums';
import { createRefPath } from '../../firestoreData/appData/appRefPaths';
import { fs_dbu } from '../../firestoreData/appData/fsAppDataUpdate';
import { removeNotification, updateMarkAsRead } from "../../firestoreData/notifications/notificationsData";
import { fsfn_notifications } from '../../functions/fbNotifications';
import { checkScheduledNotifications } from '../../functions/fbScheduledNotifications';
import { grts, responseReducers } from './reducerHelpers/dispatchProps';
import { currentHelpers } from '../../redirection/current';

// https://web-push-book.gauntface.com/demos/payload-examples/

const rts = {
  handleShow_payload: 'handleShow_payload',
  handleClose_payload: 'handleClose_payload',
  handleAppUserNotifications: 'handleAppUserNotifications',
  handleLoadDataError: 'handleLoadDataError',
  handleLoadNotifications: 'handleLoadNotifications',
  handleMarkAsRead: 'handleMarkAsRead',
  handleMarkAsSeen: 'handleMarkAsSeen',
  handleNewForegroundNotification: 'handleNewForegroundNotification',
  handleNotificationClick: 'handleNotificationClick',
  handleNotificationClose: 'handleNotificationClose',
  handleRemoveNotification: 'handleRemoveNotification',
  handleSave_topics: 'handleSave_topics',
  handleScheduleNotifications: 'handleScheduleNotifications',
  handleSend_email: 'handleSend_email',
  handleSendScheduledNotifications: 'handleSendScheduledNotifications',
  handleUpdateScheduledNotifications: 'handleUpdateScheduledNotifications',
  ...grts
}

const _lsName = 'notificationsRead'

export const appNotificationsReducer = (state, action) => {

  const { appNotifications, appUserAccess, aps_appUserSettings, pathViews, rootPaths, page_fns, logging, notifications_app, newPayload, navigate, aps_viewItems, allowNotificationTopics } = state
  const { payload, type, dispatch, notifications, appProfileData } = action
  const { markAsReadInDatabase } = appNotifications ?? {}

  switch (type) {

    case rts.handleShow_payload:
      return { ...state, showPayload: true }

    case rts.handleClose_payload:
      return { ...state, showPayload: false, pushPayload: null }

    case rts.handleAppUserNotifications:

      checkNotifications(notifications_app, pathViews, page_fns)

      const sn = notifications_app ? _.filter(notifications_app, { sent: true, active: true }) : null
      const _appUserAccess = action.appUserAccess ? action.appUserAccess : appUserAccess
      const notifications_appUser = getNotifications_appUser(_appUserAccess, aps_appUserSettings, sn);

      return {
        ...state,
        appUserAccess: action.appUserAccess,
        notifications_appUser,
      }

    case rts.handleLoadNotifications:
    case rts.handleLoadDataError:

      // get the `sent` notifications_app 
      const notifications_sent = notifications ? _.filter(notifications, { sent: true, active: true }) : null

      // xxx(notifications, pathViews)

      return {
        ...state,
        notifications_sent,
        sentNotificationsCount: notifications_sent ? notifications_sent.length : 0,
        notifications_app: notifications,
      }

    // SAVE
    case rts.handleSave_topics:
      if (appProfileData) {
        const tpg = getTopicFcmGroups(appProfileData, aps_appUserSettings, rootPaths)
        if (tpg) { fsfn_notifications.addTopicGroups(tpg, action.unsubscribe) }
      }
      return { ...state }

    // NOTIFICATION - handleNewForegroundNotification
    case rts.handleNewForegroundNotification:
      // if newPayload, newPayload will light up in PageTopHeader      
      return {
        ...state,
        ...getPayloadUrl(payload)
      }

    // NOTIFICATION - handleNotificationClick
    case rts.handleNotificationClick:

      // the app is already open

      const { notification, data } = newPayload ?? {}
      const alert = notification ? notification : data
      const { clickPath } = data ?? {}
      const { clickSuffix, clients: clientKey, events: eventKey, page, pageKey } = alert ?? {}

      let url;
      if (clickPath) {
        if (useHash) { url = '/' + hashmark }
        url += '/' + clickPath
        navigate.push(clickPath)
      } else {
        if (useHash) { url = '/' + hashmark }
        if (clientKey) { url = '/clients/' + clientKey }
        if (eventKey) { url += '/events/' + eventKey }
        if (page) { url += '/' + page }
        if (pageKey) { url += '/' + pageKey }
        if (clickSuffix) { url += '/' + clickSuffix }
        navigate.push(url)
      }

      return {
        ...state,
        newPayload: null,
      }

    case rts.handleNotificationClose:
      if (action.inApp && action.noteKey) {
        if (markAsReadInDatabase) {
          updateMarkAsRead(appUserAccess, pathViews, action.noteKey, page_fns, markAsReadInDatabase)
        } else {
          updateLocalStorage(action.noteKey)
          const _notifications_appUser = getNotifications_appUser(appUserAccess, aps_appUserSettings, state.notifications_sent);
          return {
            ...state,
            notifications_appUser: _notifications_appUser,
          }
        }
      }
      return { ...state, newPayload: null }

    case rts.handleRemoveNotification:
      removeNotification(pathViews, page_fns, action.noteId, dispatch, action.callback)
      return { ...state }

    case rts.handleSend_email:
      return { ...state }

    // SCHEDULED
    case rts.handleScheduleNotifications:
      const _props = {
        action,
        notifications_app,
        appNotifications,
        appUserAccess,
        aps_appUserSettings,
        aps_viewItems,
        logging,
        page_fns,
        pathViews,
        allowNotificationTopics,
      }
      checkScheduledNotifications(_props)
      return { ...state, timerOn: action.startTimer }

    case rts.handleMarkAsRead:
      const { note } = action
      const _id = note.id ? note.id : note.key
      updateMarkAsRead(appUserAccess, pathViews, _id, page_fns)
      return { ...state }

    case rts.handleMarkAsSeen:
      const { note: noteS } = action
      const { _itemKey } = noteS ?? {}
      updateLocalStorage(_itemKey)
      const _notificationz_appUser = getNotifications_appUser(appUserAccess, aps_appUserSettings, state.notifications_sent);
      return {
        ...state,
        notifications_appUser: _notificationz_appUser,
      }

    case rts.handleSendScheduledNotifications:
      fsfn_notifications.sendScheduledNotifications_gcs_direct()
      return { ...state }

    case rts.handleUpdateScheduledNotifications:
      updateScheduledNotifications(action.notificationData, pathViews)
      return { ...state }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { questionProps: null })

    default:
      return { ...state }
  }
}

export const appNotificationsInitialState = (initState) => {
  return {
    notificationData: {},
    messageReady: false,
    allNotifications: null,
    payloads: [],
    ...initState
  }
};

export const appNotificationsHandlers = (dispatch) => {
  return {
    handleShow_payload: () => { dispatch({ type: rts.handleShow_payload, dispatch }) },
    handleClose_payload: () => { dispatch({ type: rts.handleClose_payload, dispatch }) },
    handleAppUserNotifications: (appUserAccess) => { dispatch({ type: rts.handleAppUserNotifications, dispatch, appUserAccess }) },
    handleLoadNotifications: (notifications) => { dispatch({ type: rts.handleLoadNotifications, notifications }) },
    handleMarkAsRead: (note) => { dispatch({ type: rts.handleMarkAsRead, note }) },
    handleMarkAsSeen: (note) => { dispatch({ type: rts.handleMarkAsSeen, note }) },
    handleNewForegroundNotification: (payload) => { dispatch({ type: rts.handleNewForegroundNotification, payload }) },
    handleNotificationClick: () => { dispatch({ type: rts.handleNotificationClick }) },
    handleNotificationClose: (inApp, noteKey) => { dispatch({ type: rts.handleNotificationClose, inApp, noteKey }) },
    handleRemoveNotification: (noteId, callback) => { dispatch({ type: rts.handleRemoveNotification, dispatch, noteId, callback }) },
    handleSave_topics: (appProfileData, unsubscribe) => { dispatch({ type: rts.handleSave_topics, dispatch, appProfileData, unsubscribe }) },
    handleScheduleNotifications: (startTimer) => { dispatch({ type: rts.handleScheduleNotifications, dispatch, startTimer }) },
    handleSend_email: (notificationData) => { dispatch({ type: rts.handleSend_email, dispatch, notificationData }) },
    handleSendScheduledNotifications: (notificationData) => { dispatch({ type: rts.handleSendScheduledNotifications, dispatch }) },
    handleUpdateScheduledNotifications: (notificationData) => { dispatch({ type: rts.handleUpdateScheduledNotifications, dispatch, notificationData }) },
  }
}
const getPayloadUrl = (payload) => {

  const { notification, data } = payload ?? {}
  const _notification = notification ? notification : data
  const { onclick, clickSuffix, clientKey, eventKey, page, pageKey } = data ?? {}

  let url;
  if (onclick) {
    url = '/#' + onclick
  } else if (clientKey && eventKey) {
    // if (useHash) { url = '/#' + hashmark }
    url = '/#'
    if (clientKey) { url += '/clients/' + clientKey }
    if (eventKey) { url += '/events/' + eventKey }
    if (page) { url += '/' + page }
    if (pageKey) { url += '/' + pageKey }
    if (clickSuffix) { url += '/' + clickSuffix }
  }

  return { newPayload: payload, pushPayload: _notification, url }
}

const getTopicFcmGroups = (appProfileData, aps_appUserSettings, rootPaths) => {

  const { appUserCollection } = aps_appUserSettings ?? {}

  const fcmGroups = {}
  const fcmUserGroups = {}
  const fcmTopicGroups = {}
  const fcms = []

  Object.keys(appProfileData).forEach(groupKey => {

    const topicGroup = appProfileData[groupKey]

    if (groupKey === appUserCollection) {

      fcmUserGroups[groupKey] = []

      Object.keys(topicGroup).forEach(key => {
        const target = topicGroup[key]
        const { fcmTokens } = target
        if (fcmTokens) {
          fcmTokens.forEach(fcmToken => {
            if (!fcmUserGroups[groupKey].includes(fcmToken)) {
              fcmUserGroups[groupKey].push(fcmToken)
            }
          })
        }
      })
    } else {
      fcmTopicGroups[groupKey] = {}

      Object.keys(topicGroup).forEach(topicGroupKey => {

        fcmTopicGroups[groupKey][topicGroupKey] = []

        const topicItem = topicGroup[topicGroupKey]
        const { targets } = topicItem
        if (targets) {
          Object.keys(targets).forEach(targetKey => {
            const target = targets[targetKey]
            const { fcmTokens } = target
            if (fcmTokens) {
              fcmTokens.forEach(fcmToken => {
                if (!fcmTopicGroups[groupKey][topicGroupKey].includes(fcmToken)) {
                  fcmTopicGroups[groupKey][topicGroupKey].push(fcmToken)
                }
              })
            }
          })
        }
      })
    }
    if (!fcmGroups[groupKey]) { fcmGroups[groupKey] = [] }
    fcmGroups[groupKey].push(fcms)
  })

  const tpg = {}

  const { events } = rootPaths ?? {}
  const topicPath = events ? events.replace(/\//g, '_') : null

  if (topicPath) {
    Object.keys(fcmUserGroups).forEach(key => {
      tpg[topicPath + key] = fcmUserGroups[key]
    })

    Object.keys(fcmTopicGroups).forEach(key => {
      Object.keys(fcmTopicGroups[key]).forEach(key2 => {
        tpg[topicPath + key + '_' + key2] = fcmTopicGroups[key][key2]
      })
    })

    return tpg
  }

  return null
}

/**
 * 
 * @param {object} appUserAccess 
 * @param {object} aps_appUserSettings 
 * @param {object} notifications_sent 
 * @returns The notifications for the appUser based on the appUserViewTypes
 */
const getNotifications_appUser = (appUserAccess, aps_appUserSettings, notifications_sent) => {

  const { appUserSession, appUserAccessType } = appUserAccess ?? {}
  const { appUserViewTypeProp } = aps_appUserSettings ? aps_appUserSettings : { appUserViewTypeProp: 'registrationTypes' }

  const aun = []
  let auKey = { groupKey: null, itemKey: null };

  const _readNotes = getLocalStorage()
  const _isAdmin = appUserAccess && (appUserAccess.accessLevel >= gEnums.accessLevels.admin.value)

  if (appUserAccess && notifications_sent) {

    if (appUserSession || _isAdmin) {

      const _appUser_viewType = appUserSession ? appUserSession[appUserViewTypeProp] : null

      auKey.groupKey = appUserViewTypeProp
      auKey.itemKey = _appUser_viewType


      // loop the sent notifications
      notifications_sent.forEach(notification => {

        let allow = true
        if (notification.sendDate && notification.sendTime) {
          notification.sendDateUTC = getStartTimeUtc(notification.sendDate, notification.sendTime)
          notification.sendTimeUTC = notification.sendDateUTC.getTime()
        }

        // check to see if the note has been read by the appUser
        notification.read = _readNotes && _readNotes.includes(notification._itemKey) ? true : false

        if (allow) {

          const { readBy, targetOptions } = notification ?? {}

          const { notificationRecipientKeys, pageItem, pageItemKey, subKey, subItem, subKeys, recipientItem } = targetOptions ?? {}

          let _allowNotification = false
          const { _itemKey, appUserSessionKey } = appUserSession ?? {}

          if (notificationRecipientKeys && _.isArray(notificationRecipientKeys) && notificationRecipientKeys.length > 0) {
            _allowNotification = notificationRecipientKeys.includes(_itemKey) || notificationRecipientKeys.includes(appUserSessionKey) || _isAdmin
          } else if (pageItem && pageItemKey) {
            // see if the appUser has the `pageItemKey` in its `pageItem` prop
            const appUserItemValue = appUserSession && appUserSession[pageItem] ? appUserSession[pageItem] : null
            if (appUserItemValue && _.isArray(appUserItemValue) && appUserItemValue.includes(pageItemKey)) {
              _allowNotification = true
            }
          } else if (subItem) {
            // see if the appUser's subItem is = to the subKey
            if (subKey && appUserSession && appUserSession[subItem] && appUserSession[subItem] === subKey) {
              _allowNotification = true
            }
            if (subKeys && appUserSession && appUserSession[subItem] && subKeys.includes(appUserSession[subItem])) {
              _allowNotification = true
            }
          } else {
            _allowNotification = true
          }

          if (recipientItem && appUserSessionKey) {
            _allowNotification = true
          }
          // clients_pzlP0uVW5Ctz29Lb76Ll_events_d7lxLeGXdA7QAZL5Nk5v
          // clients_pzlP0uVW5Ctz29Lb76Ll_events_mPCSFBFMlCGvyQiV5yFH

          if (_allowNotification) {
            addNoteItem(aun, notification, readBy, appUserAccess.uid)
          }
        }
      })
    } else {
      notifications_sent.forEach(sn => {
        addAdminNoteItems(appUserAccessType, aun, appUserAccess, sn, _readNotes)
      })
    }
  }

  const aunts = {
    all: aun,
    read: _.filter(aun, { read: true }),
    unread: _.filter(aun, { read: false }),
  }

  aunts.hasNew = aunts.unread && aunts.unread.length > 0 ? true : false

  return aunts

}

const addAdminNoteItems = (appUserAccessType, aun, appUserAccess, sn, _readNotes) => {
  let allow = true
  if (_readNotes && _readNotes.includes(sn._itemKey)) { allow = false }
  if (allow) {
    const { selectedTopicGroups, readBy } = sn ?? {}
    if (selectedTopicGroups) {
      if (selectedTopicGroups.adminProfiles) {
        switch (appUserAccessType) {
          case gEnums.appUserAccessTypes.admin:
          case gEnums.appUserAccessTypes.superAdmin:
            addNoteItem(aun, sn, readBy, appUserAccess.uid)
            break;
          default:
            break;
        }
      }
    }
  }
}

const addNoteItem = (aun, sn, readBy, uid) => {
  if (!readBy) {
    aun.push(sn)
  } else {
    if (!readBy.includes(uid)) {
      aun.push(sn)
    }
  }
}

const getLocalStorage = () => currentHelpers.storageItem_get(_lsName)

const updateLocalStorage = (noteKey) => {
  const lsExistingValue = currentHelpers.storageItem_get(_lsName)
  const lsValue = lsExistingValue ? JSON.parse(lsExistingValue) : []
  if (!lsValue.includes(noteKey)) { lsValue.push(noteKey) }
  currentHelpers.storageItem_set(_lsName, JSON.stringify(lsValue))
}

const checkNotifications = (notifications) => {
  if (notifications) {
    Object.keys(notifications).forEach(k => {
      const n = notifications[k]
      const { notificationDeliveryType } = n ?? {}
      switch (notificationDeliveryType) {
        case gEnums.notificationDeliveryTypes.inApp:
          break;
        default:
        // nothing
      }
    })
  }
}

const updateScheduledNotifications = (dataUpdate, pathViews, callback) => {
  if (dataUpdate && pathViews) {
    const pathKey = 'clients_' + pathViews.clients + '_events_' + pathViews.events
    const _refPath = createRefPath(['notificationSchedules', pathKey])
    fs_dbu.set_doc(_refPath, dataUpdate, false, callback)
  }
}
