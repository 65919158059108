import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { Button, Dropdown, Label, List, Sidebar } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { imageMapHandlers, imageMapInitialState, imageMapReducer } from '../../cnr/reducers/ImageMapReducer';
import { getFirstObject } from '../../common/convert';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import UiSaveButtons from '../buttons/UiSaveButtons';
import ThumbSwipe from './ThumbSwipe';
import Uploader from '../uploading/Uploader';
import UiCreateGlobalData from '../../pageItem/modification/dataManagement/UiCreateGlobalData';
import { dataSidebarActionTypes } from '../../viewSettings/enums/itemActionTypes';
import DataSidebar from '../swipers/DataSidebar';
import DropdownOptionsMenu from '../forms/elements/DropdownOptionsMenu';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { DataModificationsContext } from '../../pageItem/modification/UiDataModifications';
import { listList } from '../../lists/lists';

// const url = 'https://i.pinimg.com/736x/7f/92/62/7f9262868a0a7aa99684aa04239cde49--home-floor-plans-large-house-floor-plans.jpg'

const _zoneCollectionName = '_zones'
const useStatic = true
const _defaultLocationKey = 'locations'

export const ImageMapContext = createContext();

/**
 * 
 * @param {object} props 
 * @returns A sidebar (imageMap, locations, zones )
 */
const ImageMapping = (props) => {

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { paps_state, page_state, eventInfo_state, storage_state } = states
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { imageMapping: imageMapping_global } = aps_global ?? {}
  const { locationProps } = imageMapping_global ?? {}

  const { storage_fns } = fns

  // papsContext  
  const { pathViews } = paps_state

  // storageContext 
  const { globalFiles } = storage_state ?? {}
  const { floormap: floorMapFiles } = globalFiles ?? {}
  const floorMapFileKeys = floorMapFiles ? Object.keys(floorMapFiles) : []

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { frInfo, frameworkRightType } = framework_state ?? {}
  const { viewListData: vld_fr, viewItem: viewItem_fr } = frInfo ?? {}

  const dataModificationsContext = useContext(DataModificationsContext)
  const { dataModifications_state, dataModifications_handlers } = dataModificationsContext ?? {}
  const { appData, viewItem: viewItem_dm } = dataModifications_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers, item_state } = uiItemContext ?? {}
  const { vld, viewItem, modifyMapPropKey } = item_state ?? {}
  const { handleShow_modifyMapping } = item_handlers ?? {}
  const { key: uivi } = viewItem ?? {}

  // pageContext 
  const imageMapping_vi = imageMapping_global ? _.find(imageMapping_global, { collectionName: uivi }) : {}
  const { mapFieldName: mapFieldName_vi } = imageMapping_vi ?? {}
  const _mapFieldName = mapFieldName_vi ? mapFieldName_vi : modifyMapPropKey

  const { staticViews, appGlobalData } = eventInfo_state ?? {}
  const _globalLocationKeys = appGlobalData ? Object.keys(appGlobalData) : []

  const { item: itemData } = vld ? getFirstObject(vld) : {}
  const { name: name_item } = itemData ?? {}
  const itemLocation = itemData ? itemData[_mapFieldName] : null


  // imageMap_state
  const [imageMap_state, imageMap_dispatch] = useReducer(imageMapReducer, imageMapInitialState({
    collectionName: _zoneCollectionName,
    imageMapType: props.imageMapType ? props.imageMapType : gEnums.imageMapTypes.zoneMapping,
    showZoneHeader: props.imageMapType === gEnums.imageMapTypes.itemLocation,
    isItemMapping: props.imageMapType === gEnums.imageMapTypes.itemMapping,
    itemLocation,
    locationProps: locationProps,
    mapFieldName: _mapFieldName,
    globalLocationKeys: _globalLocationKeys,
    pathViews,
    storage_fns,
    appGlobalData,
  }));

  const imageMap_handlers = imageMapHandlers(imageMap_dispatch, imageMap_state)
  const {
    _zonedFloormaps,
    currentFloormap,
    currentLocation,
    currentZone,
    currentZones,
    floormapUrl,
    imageMapType,
    locationName,
    locationsData,
    isItemMapping,
    showZoneHeader,
    selectedLocationKey,
    selectedLocationData,
    selectedGlobalData,
    updating,
  } = imageMap_state ?? {}

  const { name: name_location } = currentLocation ?? {}

  const _hasZones = currentZones && Object.keys(currentZones).length > 0
  const _url = floormapUrl ? floormapUrl : null

  const [showMessage, setShowMessage] = useState()
  const [showUpload, setShowUpload] = useState()
  const [showUpdateLocations, setShowUpdateLocations] = useState()

  const _locationsOk = selectedLocationData && selectedGlobalData && (Object.keys(selectedLocationData).length === Object.keys(selectedLocationData).length)

  const handleShowUpload = () => setShowUpload(!showUpload)
  const handleShowUpdateLocations = () => setShowUpdateLocations(!showUpdateLocations)

  const handleSelectedLocationData = (opts) => {
    const { mfn } = opts ?? {}
    imageMap_handlers.handleSet_selectedLocationKey(mfn)
    imageMap_handlers.handleSet_selectedLocationData(locationsData && locationsData[mfn] ? locationsData[mfn] : {})
  }

  useEffect(() => {
    if (!_.isEmpty(locationsData)) {
      switch (imageMapType) {
        case gEnums.imageMapTypes.itemMapping:
          const mfn = _defaultLocationKey
          const _locationData = locationsData && locationsData[mfn] ? locationsData[mfn] : {}

          imageMap_handlers.handleSet_selectedLocationKey(mfn)
          imageMap_handlers.handleSet_selectedLocationData(_locationData)

          const { item } = getFirstObject(appData)
          const _itemLocation = item && item[_defaultLocationKey]
          const _itemLocationKey = _itemLocation && _itemLocation[0]

          if (_locationData && _itemLocationKey && _locationData[_itemLocationKey]) {
            const _locationDataItem = _locationData[_itemLocationKey]
            imageMap_handlers.handleSet_currentLocation(_locationDataItem)
          }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [locationsData, imageMapType]);


  useEffect(() => {
    const staticView = useStatic && staticViews && staticViews[_mapFieldName]
    imageMap_handlers.handleInit_imageMap(staticView)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (_zonedFloormaps && floormapUrl) {
      imageMap_handlers.handleChange_floormapUrl()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_zonedFloormaps, floormapUrl]);

  // sets up the menus (mapping and zones only)
  useEffect(() => {
    if (selectedGlobalData && Object.keys(selectedGlobalData).length > 0) {
      const _staticLocations = staticViews ? staticViews[_mapFieldName] : {}
      let _locationName;
      if (_.isArray(itemLocation)) {
        const _locationKey = itemLocation[0]
        const staticLocation = _staticLocations ? _staticLocations[_locationKey] : null
        if (staticLocation) {
          _locationName = staticLocation.name
        }
      } else {
        if (_staticLocations && itemLocation && _staticLocations[itemLocation]) {
          _locationName = _staticLocations[itemLocation].name
        }
      }
      imageMap_handlers.handleAmmend_locationName(_locationName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedGlobalData, floorMapFiles]);

  useEffect(() => {
    switch (imageMapType) {
      case gEnums.imageMapTypes.locationMapping:
      case gEnums.imageMapTypes.zoneMapping:
        imageMap_handlers.handleGet_floormapImageFromStorage(currentFloormap)
        break;
      default:
      // nothing
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedGlobalData, _zonedFloormaps, currentFloormap]);

  useEffect(() => {
    switch (imageMapType) {
      case gEnums.imageMapTypes.itemLocation:
      case gEnums.imageMapTypes.itemMapping:
        if (_zonedFloormaps && selectedGlobalData && itemLocation) {
          imageMap_handlers.handleAmmend_location()
        }
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedGlobalData, _zonedFloormaps]);

  // simple message
  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setShowMessage()
      }, 2000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [showMessage]);

  const handleOption_floormap = (a, b) => {
    const { value } = b
    imageMap_handlers.handleSet_currentFloormap(value)
  }

  const handleClick_floormap = (floormap) => imageMap_handlers.handleSet_currentFloormap(floormap)
  const handleClick_location = (location) => imageMap_handlers.handleSet_currentLocation(location)
  const handleClick_removeZone = () => imageMap_handlers.handleRemove_zone()
  const handleClick_zone = (ce) => imageMap_handlers.handleSet_currentZone(ce)
  const updateFieldDataToDb = () => imageMap_handlers.handleUpdate_fieldDataToDb()
  const updateZonesToDb = () => imageMap_handlers.handleUpdate_zonesToDb()

  const thumbSwipe = () => currentFloormap ? <ImageMapContext.Provider value={{ imageMap_state, imageMap_handlers }}>
    {isItemMapping && dropdown_floormap()}
    <ThumbSwipe
      url={_url}
      handleZoneClick={handleClick_zone}
    />
  </ImageMapContext.Provider> : <div>Select Floormap</div>

  const saveButtons_mapping = () => {
    const btns = []
    currentZone && btns.push({ oc: handleClick_removeZone, caption: 'Remove Zone (' + currentZone.text + ')', icon: 'delete', color: 'red', fl: true, disabled: !currentZone })
    btns.push({ oc: updateZonesToDb, caption: 'Update Zones (' + Object.keys(currentZones).length + ')', icon: 'save', disabled: !_hasZones })
    return <UiSaveButtons others={btns} />
  }

  const saveButtons_locations = () => {
    let caption = 'Update ' + _.startCase(selectedLocationKey)
    caption += ' (' + name_location + ' to ' + currentZone.zone + ')'
    const btns = []
    btns.push({ oc: updateFieldDataToDb, caption: caption, icon: 'save', disabled: !_hasZones })
    return <UiSaveButtons others={btns} />
  }

  const buttons_locations = () => {
    const btns = []
    const _caption = 'Update ' + _.startCase(selectedLocationKey)
    btns.push({ oc: handleShowUpdateLocations, caption: _caption, icon: 'arrow right', color: _locationsOk ? 'blue' : 'green' })
    return <UiSaveButtons others={btns} />
  }

  const buttons_locationsData = () => {
    const btns_dd = []
    if (locationProps) {
      locationProps.forEach(lp => {
        btns_dd.push({ oc: handleSelectedLocationData, caption: _.startCase(lp), fl: true, opts: { mfn: lp } })
      })
    }
    return <DropdownOptionsMenu menuButtons={btns_dd} caption={'Location Types'} icon={'unordered list'} color={'blue'} direction={'right'} upward={true} float={'left'} />
  }

  const buttons_floormaps = () => {
    const btns = []
    btns.push({ oc: handleShowUpload, caption: 'Upload Image', icon: 'arrow right' })
    return <UiSaveButtons others={btns} />
  }

  const saveButtons = () => {
    switch (imageMapType) {
      case gEnums.imageMapTypes.zoneMapping:
        return saveButtons_mapping()
      case gEnums.imageMapTypes.itemMapping:
      case gEnums.imageMapTypes.locationMapping:
        return currentLocation && currentZone && saveButtons_locations()
      default:
      // nothing 
    }
  }

  const topButtons_floorMaps = () => <Button.Group size='mini' fluid >
    <Button primary={imageMapType === gEnums.imageMapTypes.zoneMapping} onClick={() => { imageMap_handlers.handleSet_imageMapType(gEnums.imageMapTypes.zoneMapping) }}>{_.startCase(gEnums.imageMapTypes.zoneMapping)}</Button>
    <Button.Or />
    <Button primary={imageMapType === gEnums.imageMapTypes.locationMapping} onClick={() => { imageMap_handlers.handleSet_imageMapType(gEnums.imageMapTypes.locationMapping) }}>{_.startCase(gEnums.imageMapTypes.locationMapping)}</Button>
  </Button.Group>

  const list_floormap = () => {
    const listItems = floorMapFileKeys ? floorMapFileKeys.map((key, index) => (<List.Item key={(uniqueKey('fm', index))} className={currentFloormap && currentFloormap === key ? 'sel' : null} onClick={() => { handleClick_floormap(key) }}>{key}</List.Item>)) : []
    return <List relaxed divided>
      {listItems}
    </List>
  }

  const dropdown_floormap = () => {
    const listItems = floorMapFileKeys ? listList(floorMapFileKeys) : []
    return <Dropdown
      key={uniqueKey('dl', 's', 'fm')}
      value={currentFloormap}
      selection
      fluid
      options={listItems}
      onChange={handleOption_floormap}
    // onClick={handleSelectClick}
    // item={item}
    >
    </Dropdown>
  }

  const list_locations = () => {
    const _locations = selectedLocationData ? _.sortBy(selectedLocationData, 'name') : []
    const listItems = selectedGlobalData ? _locations.map((location, index) => {
      const { name, _itemKey } = location ?? {}
      const _location_mapped = _.find(selectedGlobalData, { name: name })
      const { zone } = _location_mapped ?? {}
      const { imageKey, zoneIndex } = zone ?? {}
      const _currentZone = currentZones ? currentZones['zone ' + zoneIndex] : {}
      const { text } = _currentZone ?? {}
      return <List.Item
        key={(uniqueKey('loc', index))}
        className={currentLocation && currentLocation._itemKey === _itemKey ? 'sel' : null}
        onClick={() => { handleClick_location(location) }}>
        <div>{name}</div>
        <div>
          <div>{imageKey ? imageKey : '-'}</div>
          <Label size={'tiny'} color={zoneIndex ? 'green' : 'grey'}>{text ? text : '-'}</Label>
        </div>
      </List.Item>
    }) : []
    return <List relaxed divided>
      {listItems}
    </List>
  }

  const wrapper_floormap = () => <Wrapper
    header={'Floor Maps'}
    content={list_floormap()}
    footer={buttons_floormaps()}
    wrapperType={wrapperTypes.padded}
    css={{ footer: 'split' }}
  ></Wrapper>

  const wrapper_zones = () => <Wrapper
    header={showZoneHeader && 'Zones - ' + currentFloormap}
    content={thumbSwipe()}
    footer={_hasZones && saveButtons()}
    wrapperType={wrapperTypes.padded}
    css={{ header: 'imt-' + imageMapType, footer: 'split' }}
  ></Wrapper>

  const wrapper_locations = () => <Wrapper
    header={_.startCase(selectedLocationKey)}
    content={list_locations()}
    footer={buttons_locations()}
    wrapperType={wrapperTypes.padded}
  ></Wrapper>

  const zone_mapping = () => <div className='image-mapping zone'>
    {wrapper_floormap()}
    {currentFloormap && wrapper_zones()}
  </div>

  const location_mapping = () => <div className='image-mapping location'>
    {wrapper_locations()}
    {wrapper_floormap()}
    {currentFloormap && wrapper_zones()}
  </div>

  const fpw = () => <FullPageWrapper
    topperCaption={name_item}
    topperCaption2={locationName}
    content={wrapper_zones()}
    handleCancel={handleShow_modifyMapping}
  ></FullPageWrapper>

  const wrapper_sidebarRight = () => <Wrapper
    content={<UiCreateGlobalData viewKey={selectedLocationKey} inViewer={true} callback={handleShowUpdateLocations} viewDataMode={dataSidebarActionTypes.createGlobalData} appData={selectedLocationData} />}
    wrapperType={wrapperTypes.paddedHeader}
    css={{ container: 'data-sidebar' }}
  ></Wrapper>

  const sidebar_right = (visible) => <DataSidebar direction={'right'} visible={visible} content={visible && wrapper_sidebarRight()} onHide={handleShowUpdateLocations} />

  const content = () => {
    switch (imageMapType) {
      case gEnums.imageMapTypes.zoneMapping:
        return zone_mapping()
      case gEnums.imageMapTypes.locationMapping:
        return location_mapping()
      case gEnums.imageMapTypes.itemLocation:
      case gEnums.imageMapTypes.itemMapping:
        return fpw()
      default:
        return zone_mapping()
    }
  }

  const wrapper_content = () => <Wrapper
    header={isItemMapping ? name_location : topButtons_floorMaps()}
    content={content()}
    footer={!isItemMapping && buttons_locationsData()}
    wrapperType={wrapperTypes.padded}
  ></Wrapper>

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_right(showUpdateLocations)}
    <Sidebar.Pusher dimmed={showUpdateLocations} className='h100' >
      {wrapper_content()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  if (showUpload) {
    return <Uploader
      storageType={gEnums.storageTypes.floormap}
      storageLocationType={gEnums.storageLocationTypes.event}
      origin={'SingleImage'}
      useFull={true}
      handleCancel={handleShowUpload}
      showUpload={true} i
      mageMap_handlers={imageMap_handlers} />
  } else {
    return sidebar()
  }

}

export default ImageMapping