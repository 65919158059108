import { grts } from '../../cnr/reducers/reducerHelpers/dispatchProps';
import { createRefPath } from '../../firestoreData/appData/appRefPaths';
import { fs_db } from '../../firestoreData/appData/fsAppData';
import { fs_dbu } from "../../firestoreData/appData/fsAppDataUpdate";
import { doc_get_promise } from '../../firestoreData/appData/fsRefs';
import { _settingsFs } from './getSettings';

export const updateDataLists = async (dispatch, paps_state) => {

  const dispatchSuccessType = grts.updateSuccess
  const dispatchErrorType = grts.updateError

  const { pathViews } = paps_state ?? {}
  const { events } = pathViews ?? {}

  const settingsName = _settingsFs.root

  const _getRef = events ? createRefPath([settingsName, events]) : createRefPath([settingsName])
  fs_db.get_data({ refPath: _getRef }).then(_allSettings => {
    createPromises(_allSettings, settingsName).then(res => {
      dispatch({ type: dispatchSuccessType });
    }).catch(err => {
      dispatch({ type: dispatchErrorType }, err);
    });
  })
}

const createPromises = async (_allSettings, settingsName) => {
  const promises = []
  Object.keys(_allSettings).forEach(key => {
    switch (key) {
      case 'home':
      case 'clients':
        break;
      default:
        const settings = _allSettings[key]
        // change the data lists if needed. 
        const _setRef = createRefPath([settingsName, key])
        promises.push(doc_get_promise(fs_dbu.doc_set(_setRef, settings)))
    }
  })
  return Promise.all(promises)
}