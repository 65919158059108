import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Menu } from 'semantic-ui-react'
import { uniqueKey } from '../common/keys'
import FullPageWrapper from './FullPageWrapper'
import Wrapper, { wrapperTypes } from './Wrapper'
import SortableList from '../dragNDrops/SortableList'
import DragContext from '../dragNDrops/DragContext'

const WrapperSidebar = (props) => {

  const {
    allowKeyboard,
    containerCn,
    groupKey,
    handleCancel,
    headerCn,
    menuItemCaption,
    stepCaption,
    topperCaption,
    topperCaption2,
    useDroppable,
    useFull,
    useSortable,
  } = props ?? {}

  const [menuItems, setMenuItems] = useState()
  const [currentItem, setCurrentItem] = useState()
  const [currentItemKey, setCurrentItemKey] = useState()

  useEffect(() => {
    const isArr = _.isArray(props.menuItems)
    const _menuItems = isArr ? [...props.menuItems] : { ...props.menuItems }
    const _sortedItems = !isArr ? _.sortBy(_menuItems, 'name') : _menuItems
    if (allowKeyboard && !isArr) {
      _sortedItems.forEach((si, index) => {
        si._position = index
      })
    }
    setMenuItems(_sortedItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [props && props.menuItems]);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowUp':
        moveSelection(-1);
        break;
      case 'ArrowDown':
        moveSelection(1);
        break;
      default:
        break;
    }
  };

  const moveSelection = (step) => {
    const { _position } = currentItem ?? {}
    const newPosition = _position + step
    const _newItem = _.find(menuItems, { _position: newPosition })
    handleItemSelect(_newItem)
  };

  const handleItemSelect = (menuItem) => {
    if (_.isObject(menuItem)) {
      const { _itemKey } = menuItem ?? {}
      setCurrentItem(menuItem)
      setCurrentItemKey(_itemKey)
      props.handleMenuClick(_itemKey)
    } else {
      setCurrentItem(menuItem)
      setCurrentItemKey(menuItem)
      props.handleMenuClick(menuItem)
    }
  }

  const getMenuItems = (_menuItems, isArray) => {
    const mis = []
    let menuItemKey;
    let caption;
    _menuItems.forEach(menuItem => {
      if (_.isObject(menuItem)) {
        const { name, _itemKey } = menuItem ?? {}
        menuItemKey = _itemKey
        caption = name
      } else {
        menuItemKey = menuItem
        caption = _.startCase(menuItem)
      }
      mis.push(<Menu.Item
        className={currentItemKey && menuItemKey === currentItemKey ? 'sel' : null}
        key={uniqueKey('dv', 'mi', menuItemKey)}
        onClick={() => { handleItemSelect(menuItem) }}>
        {caption}
      </Menu.Item>)
    })
    return mis
  }

  const menu = () => {
    let mis = getMenuItems(menuItems)
    if (useSortable) {
      return <SortableList menuItems={mis} css={{ menu: 'av-menu smw' }} />
    }
    return <Wrapper
      header={menuItemCaption}
      content={<Menu
        vertical
        inverted
        className={'av-menu smw'}
        onKeyDown={allowKeyboard && handleKeyDown} tabIndex="0" style={{ outline: 'none' }}
      >{mis}</Menu>}
      wrapperType={wrapperTypes.paddedHeader}
    ></Wrapper>
  }

  const formWithHeader = () => {
    return props.content
  }

  const content = () => <div className={'dashboard-menu'}>
    {menuItems && menu()}
    {formWithHeader()}
  </div>

  const wrapper = () => <Wrapper
    header={stepCaption}
    content={content()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    css={{ content: 'no-mid', header: headerCn }}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    topperCaption={topperCaption}
    topperCaption2={topperCaption2}
    fullMode={true}
    handleCancel={handleCancel}
    css={{ container: containerCn, header: headerCn }}
  />

  const dragDropContent = () => <DragContext
    content={useFull ? fullPageWrapper() : wrapper()}
    groupKey={groupKey}
  >
  </DragContext>

  if (useDroppable) {
    return dragDropContent()
  } else {
    return useFull ? fullPageWrapper() : wrapper()
  }



}

export default WrapperSidebar