import { last } from 'lodash';
import React, { Suspense } from 'react';
import { HashRouter } from 'react-router-dom';
import ValidationProvider from './global/cnr/contexts/ValidationContext';
import SuspenseDimmer from './global/components/alerts/SuspenseDimmer';
import { currentHelpers } from './global/redirection/current';
import EmailSignInConfirmation from './global/validation/EmailSignInConfirmation';
import { willCallTypes } from './globalAlt/components/willCall/WillCall';
import DirectTicket from './projectSpecific/ticketing/components/DirectTicket';
import FullPageWrapper from './global/wrappers/FullPageWrapper';

export const subAppTypes = {
  emailConfirmation: 'emailConfirmation',
  emailSignInConfirmation: 'emailSignInConfirmation',
  settings: 'settings',
  ticketing: 'ticketing',
  willCall: 'willCall',
  willCallPhone: 'willCallPhone',
  willCallEmail: 'willCallEmail',
  willCallHome: 'willCallHome',
}

const AppFormsProvider = React.lazy(() => import("./global/cnr/contexts/AppFormsContext"));
const AppSettingsProvider = React.lazy(() => import('./global/cnr/contexts/AppSettingsContext'));
const AuthProvider = React.lazy(() => import('./global/cnr/contexts/AuthContext'));
const GoogleSheets = React.lazy(() => import('./global/components/googleSheets/GoogleSheets'));
const HelpProvider = React.lazy(() => import('./global/cnr/contexts/HelpContext'));
const PageProvider = React.lazy(() => import('./global/cnr/contexts/PageContext'));
const PapsProvider = React.lazy(() => import('./global/cnr/contexts/PapsContext'));
const SettingsDataProvider = React.lazy(() => import('./global/cnr/contexts/SettingsDataContext'));
const StorageProvider = React.lazy(() => import('./global/cnr/contexts/StorageContext'));
const WillCall = React.lazy(() => import('./globalAlt/components/willCall/WillCall'));
const WillCallHome = React.lazy(() => import('./globalAlt/components/willCall/WillCallHome'));

/** Starting point fo the App
 * @includes AuthProvider  
 * @includes HashRouter
 * @includes Pages
 *  */
function AppSub(props) {

  const { lastItem: lastItem_app, queryValues } = props ?? {}

  let { _hash } = currentHelpers.getHashPath()
  if (_hash && _hash.startsWith('#')) {
    _hash = _hash.substring(1)
  }
  const pvs = _hash ? currentHelpers.getPathViews(_hash) : currentHelpers.getPathViews(window.location.href)
  const lastItem = lastItem_app ? lastItem_app : last((_hash).split('/'))

  let subA;
  let subConfirm;

  if ((lastItem === subAppTypes.emailSignInConfirmation) || (queryValues && queryValues.emailSignInConfirmation)) {
    subConfirm = lastItem
  } else if (pvs.willCall || lastItem === subAppTypes.willCall) {
    subA = subAppTypes.willCall
  } else if (pvs.willCallPhone || lastItem === subAppTypes.willCallPhone) {
    subA = subAppTypes.willCallPhone
  } else if (pvs.willCallEmail || lastItem === subAppTypes.willCallEmail) {
    subA = subAppTypes.willCallEmail
  } else if (lastItem === subAppTypes.willCallHome) {
    subA = lastItem
  } else if (subAppTypes.settings) {
    subA = subAppTypes.settings
  }

  if (subConfirm) {
    return (
      <Suspense fallback={<SuspenseDimmer caption={'appSub'} origin={'AppSub'} />}>
        <HashRouter>
          <AuthProvider>
            <ValidationProvider>
              <EmailSignInConfirmation />
            </ValidationProvider>
          </AuthProvider>
        </HashRouter>
      </Suspense>)
  } else if (subA === subAppTypes.settings) {
    return <SubApp subA={subA} />
  } else {
    return (
      <Suspense fallback={<SuspenseDimmer caption={'appSub'} origin={'AppSub'} />}>
        <HashRouter>
          <AuthProvider>
            <AppSettingsProvider>
              <ValidationProvider>
                <PapsProvider>
                  <PageProvider>
                    <HelpProvider>
                      <SettingsDataProvider eventOnly={true}>
                        <StorageProvider>
                          <AppFormsProvider>
                            <SubApp subA={subA} />
                          </AppFormsProvider>
                        </StorageProvider>
                      </SettingsDataProvider>
                    </HelpProvider>
                  </PageProvider>
                </PapsProvider>
              </ValidationProvider>
            </AppSettingsProvider>
          </AuthProvider>
        </HashRouter>
      </Suspense>)
  }
}

export default AppSub;

const SubApp = (props) => {
  const { subA } = props
  switch (subA) {
    case subAppTypes.settings:
      return <Settings />
    case subAppTypes.emailConfirmation:
    case subAppTypes.emailSignInConfirmation:
      return <EmailSignInConfirmation />
    case subAppTypes.googleSheets:
      return <GoogleSheets />
    case subAppTypes.ticketing:
      return <DirectTicket />
    case subAppTypes.willCall:
      return <WillCall />
    case subAppTypes.willCallEmail:
      return <WillCall willCallType={willCallTypes.email} />
    case subAppTypes.willCallPhone:
      return <WillCall willCallType={willCallTypes.phone} />
    case subAppTypes.willCallHome:
      return <WillCallHome />
    default:
      return <div>Invalid Page</div>
  }
}

const Settings = () => {
  console.log('first')
  return <div style={{ backgroundColor: 'white', color: 'black', height: '100vh', width: '100vw' }}>
    {'TEST'}
  </div>
}