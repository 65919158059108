import React, { createContext, useReducer } from 'react';
import { startDataHandlers, startDataInitialState, startDataReducer } from '../reducers/StartDataReducer';

export const StartDataContext = createContext();

/** Global OtherDatas for the Event */
const StartDataProvider = (props) => {

  const init_state = {}
  const [startData_state, startData_dispatch] = useReducer(startDataReducer, init_state, startDataInitialState)
  const startData_handlers = startDataHandlers(startData_dispatch, startData_state)

  const providerContext = () => <StartDataContext.Provider value={{ startData_state, startData_handlers }}>
    {props.children}
  </StartDataContext.Provider>

  return providerContext()


}

export default StartDataProvider
