
import React, { useContext, useEffect, useState } from "react";
import { Input, Message } from 'semantic-ui-react';
import { ParentContext } from "../../cnr/contexts/ParentContext";
import { UploadContext } from "../../cnr/contexts/UploadContext";
import { appFormTypes } from '../../enums/appFormTypes';
import { appIconTypes } from "../../enums/appIconTypes";
import { gEnums } from '../../enums/globalEnums';
import AppForm from "../forms/appForms/AppForm";
import ManifestViewer from "../sem/ManifestViewer";
import UploadSegButton from "./UploadSegButton";

/**
 * 
 * @param {object} props (useAvatar, storageRef, showPath, storageType, viewItemKey)
 * @returns 
 */
const SingleImageUploader = (props) => {

  const { inverted, storageRef, storageLocationType, showPath, storageType, viewItemKey } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, settings } = parentContext ?? {}
  const { homeSettings } = settings ?? {}
  const { global: global_home } = homeSettings ?? {}
  const { settingsPermissions } = global_home ?? {}
  const { storage_state } = states ?? {}
  const { galleries } = storage_state ?? {}
  const { direct } = galleries ?? {}

  let { allowImagesFromUrl } = settingsPermissions ?? {}

  // uploadContext
  const uploadContext = useContext(UploadContext)
  const { upload_state, upload_handlers } = uploadContext ?? {}
  const { firstSelectedFile, firstSelectedImage, formData, storageUpdate, showAlertModal, importStarted, manifestFiles } = upload_state ?? {}

  const directGallery = direct && direct[viewItemKey]
  const permissionProps = { permissionType: gEnums.menuPermissionTypes.uploadDocument }

  const [isOriginal] = useState()
  const [imageUrl, setImageUrl] = useState()

  let showFileName;

  switch (storageType) {
    case gEnums.storageTypes.pdf:
      showFileName = true
      break;
    default:
    // nothing
  }

  switch (storageLocationType) {
    case gEnums.storageLocationTypes.pagePdf:
      showFileName = true
      break;
    default:
    // nothing
  }

  useEffect(() => {
    upload_handlers.handleUpdate_singleItemStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [directGallery]);

  const handleUpdateParentData = (d) => upload_handlers.handleFileFormData(d)

  const getUrl = () => {
    if (imageUrl) {
      var filename = imageUrl.substring(imageUrl.lastIndexOf("/") + 1).split("?")[0];
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function () {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
        a.download = filename; // Set the file name.
        upload_handlers.handleSet_imageImage(a.href)
      };
      xhr.open('GET', imageUrl);
      xhr.send();
    }
  }

  const handleUrlChange = (e, data) => setImageUrl(data.value)

  // const imageEditor = (imageFile, key, isAvatar) => {
  //   const imageProps = { maxWidth: 240, maxHeight: 240 }
  //   return <ImageEditor
  //     imageEditFile={imageFile}
  //     imageEditProps={imageProps}
  //     isOriginal={isOriginal}
  //     key={uniqueKey('su', 'ii', key)}
  //     permissionProps={permissionProps}
  //     useAvatar={isAvatar ? isAvatar : useAvatar}
  //   />
  // }

  const segMessage = (storageUpdate) => <Message positive={storageUpdate.success} negative={!storageUpdate.success}>
    <Message.Header>{storageUpdate.header}</Message.Header>
    {storageUpdate.message}
  </Message>

  const urlInput = () => <Input label={'url'} value={imageUrl} onChange={handleUrlChange} action={{ icon: appIconTypes.arrowRight, onClick: () => { getUrl() } }} />
  const divPath = () => <div>{storageRef}</div>

  const uploadSegButton = () => <UploadSegButton
    upload_state={upload_state}
    upload_handlers={upload_handlers}
    storageType={storageType}
    imageFile={firstSelectedFile}
    imageImage={firstSelectedImage}
  />

  const appForm = () => <AppForm
    _formAttributes={{ inverted: inverted }}
    appFormType={appFormTypes.simpleInput}
    parentData={formData}
    handleUpdateParentData={handleUpdateParentData}
  />

  const contents = () => <React.Fragment>
    {!storageUpdate && allowImagesFromUrl && <div className={'uploader-url'}>{urlInput()} </div>}
    <div className={'uploader-path'}>{showPath && storageRef && divPath()} </div>
    <div className={'uploader-progress'}> {showAlertModal && importStarted && storageUpdate && segMessage(storageUpdate)}</div>
    <div className={'uploader-file-name'}>{showFileName && appForm()}</div>
    <div className={'uploader-content'} >
      {!storageUpdate && uploadSegButton()}
    </div>
    <div className={'uploader-manifest'} >
      {manifestFiles && !storageUpdate && <ManifestViewer manifestFiles={manifestFiles} preview={true} />}
    </div>
  </React.Fragment>

  return contents()

}

export default SingleImageUploader