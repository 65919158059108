import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer, sidebarSliderTypes } from '../../../../global/cnr/reducers/SidebarReducer';
import { formatItem, formatTypes } from '../../../../global/common/dateFormatting';
import { uniqueKey } from '../../../../global/common/keys';
import { getArrayIndex } from '../../../../global/common/sorting';
import SwiperWrapper from '../../../../global/components/swipers/SwiperWrapper';
import { gEnums } from '../../../../global/enums/globalEnums';
import { currentHelpers } from '../../../../global/redirection/current';
import MenuSidebars from '../../../../global/sidebars/MenuSidebars';
import Wrapper from '../../../../global/wrappers/Wrapper';
import SeasonMatchesProvider, { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext';
import { SportsSeasonContext, matchScheduleTypes } from '../../cnr/contexts/SportsSeasonContext';
import { CreateSportsContext } from '../../create/CreateSports';
import MatchFilter from './MatchFilter';
import MatchLevelDates from './MatchLevelDates';
import MatchListSelected from './MatchListSelected';
import MatchScheduleSidebar from './MatchScheduleSidebar';

const _useRtSidebarScoring = false
const sidebarType = 'matchList'

const _sidebarItemTypes = {
  matchDates: 'matchDates',
  districts: 'districts',
  levels: 'levels',
}

export const MatchSchedule = (props) => {

  const {
    commonMatches,
    forBackup,
    forPlayoff,
    forOverview,
    forSummary,
    matchScheduleType,
    previewMatches,
    matchScheduleDisplayType: matchScheduleDisplayType_display,
    teamKey,
    dateKey,
    noMid
  } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state, page_state, appUser_state } = states

  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { sportSettings } = aps_global ?? {}
  const { useHorizontalScoreboard } = sportSettings ?? {}

  // authContext 
  const { appUser } = appUser_state ?? {}
  const { profileData } = appUser ?? {}
  const { pageFavs } = profileData ?? {}
  const { teams: team_favs } = pageFavs ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers, sportsSeason_fns } = sportsSeasonContext ?? {}
  const { sportDistricts, seasonal_info, inverted, matches_info, districts_info, seasonMatches_info, matchScheduleDisplayType } = sportsSeason_state ?? {}
  const { organization: districts_org } = districts_info ?? {}
  const { sportLevels } = seasonal_info ?? {}

  const _matches_info = forBackup && seasonMatches_info ? seasonMatches_info : matches_info

  const _matchScheduleDisplayType = matchScheduleDisplayType_display ? matchScheduleDisplayType_display : matchScheduleDisplayType

  const {
    levelDateGroups,
    levelLastDates,
    matchDateGroups,
    matchDateKeys_count,
    matchDateKeys,
  } = _matches_info ?? {}

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_state } = createSportsContext ?? {}
  const { levels_info } = createSports_state ?? {}
  const { next } = levels_info ?? {}

  // papsContext    
  const { pathViews, pathName, storageItemPage } = paps_state ?? {}

  // createSportsContext
  const seasonMatchesContext = useContext(SeasonMatchesContext);
  const { seasonMatches_state, seasonMatches_handlers } = seasonMatchesContext ?? {}

  const {
    levelDateGroup,
    levelGroupMatchElements,
    matchFilter,
    noData,
    sortedMatches,
    swiper,
    showToday,
    rtScoringMatch,
    matchDisplayType,
  } = seasonMatches_state ?? {}

  const {
    handleResults_matchDate,
    handleSet_levelDateGroup,
    handleSet_levelGroupMatchElements,
    handleSet_swiper,
    handleMatches_teamMatches,
  } = seasonMatches_handlers ?? {}

  const scheduleTypes = {
    district: pathViews && pathViews.districts && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    match: pathViews && pathViews.matches ? true : false,
    sport: pathViews && pathViews.sports ? true : false,
    team: teamKey || (pathViews && pathViews.teams) ? true : false,
    common: commonMatches ? true : false,
    date: dateKey ? true : false
  }

  // sidebar
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType, handlers: sportsSeason_handlers }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, dimmed } = sidebar_state ?? {}
  const { setInit } = sidebar_handlers ?? {}
  const { ms_matchDates, ms_districts, ms_levels } = sidebar_items ?? {}

  const { selectedLocation, selectedResult, selectedSection, selectedOpponent } = matchFilter ?? {}

  const item_matchDates = ms_matchDates && ms_matchDates.selected ? ms_matchDates.selected.item : null
  const item_districts = ms_districts && ms_districts.selected ? ms_districts.selected.item : null
  const item_levels = ms_levels && ms_levels.selected ? ms_levels.selected.item : null

  const [currents, setCurrents] = useState({})

  const _initProps = { sidebarSliderType: sidebarSliderTypes.labeled }
  const _initCalendarProps = { sidebarSliderType: sidebarSliderTypes.calendar }

  let _allowMatchFilter;

  switch (matchScheduleType) {
    case matchScheduleTypes.latest:
    case matchScheduleTypes.next:
      break;
    default:
      _allowMatchFilter = !scheduleTypes.common && scheduleTypes.team
  }

  /**
   * 
   * @param {number} index 
   * @param {object} item 
   */
  const handleSwiperIndexChange = (index, item) => {
    const _levelDateGroup = levelDateGroups[item.key]
    let _levelDateKey = getCurrentDateKey(_levelDateGroup)
    if (!_levelDateKey) { _levelDateKey = formatItem(formatTypes.shortestDate, new Date()) }
    handleSet_levelDateGroup && handleSet_levelDateGroup(_levelDateGroup, _levelDateKey, item.key)
  }

  /**
   * 
   * @param {object} ldg(array (matchDate, matches))
   * @returns 
   */
  const getCurrentDateKey = (ldg) => {

    const ldgKeys = [] // matchDateKeys

    if (ldg) {
      Object.keys(ldg).forEach(ldgKey => {
        ldgKeys.push(ldg[ldgKey].matchDate)
      })
    }

    let dateIsSet = false;
    let _dateKeyFirst = ldgKeys ? ldgKeys[0] : null;
    let _dateKeyLast = ldgKeys ? ldgKeys[ldgKeys.length - 1] : null;

    // loop the keys to find a match
    ldgKeys.forEach(dgk => {

      var date_matchKey = new Date(dgk);
      var date_today = new Date();

      var diff = date_today.getTime() - date_matchKey.getTime();

      if (diff < 0 && !dateIsSet) {
        const xxx = ldgKeys.indexOf(dgk)
        const yyy = xxx - 1
        const zzz = ldgKeys[yyy]
        _dateKeyLast = zzz ? zzz : _dateKeyFirst
        dateIsSet = true
      }
    })
    return _dateKeyLast
  }

  useEffect(() => {
    const _currents = {}
    Object.keys(_sidebarItemTypes).forEach((sitk) => {
      const si = currentHelpers.storageItem_get(storageItemPage + '_ms_' + sitk)
      let _index = 0
      switch (sitk) {
        case _sidebarItemTypes.matchDates:
          break;
        case _sidebarItemTypes.districts:
          break;
        case _sidebarItemTypes.levels:
          if (sportLevels) {
            const _lastPath = currentHelpers.storageItem_get('lastPath')
            const isBack = _lastPath === pathName ? true : false
            if (isBack) {
              _index = sportLevels ? getArrayIndex(sportLevels, si) : 0
              _currents[sitk] = { index: _index, item: si }
            } else {
              _currents[sitk] = { index: 0, item: sportLevels[0] }
            }

          }
          break;
        default:
        // nothing
      }
    })
    setCurrents(_currents)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathName, sportLevels]);

  // update ms_matchDates
  useEffect(() => {
    switch (_matchScheduleDisplayType) {
      case gEnums.matchScheduleDisplayTypes.selectedDate:
        if (matchDateKeys) {
          setInit({ smt: sidebarMenuTypes.one, items: matchDateKeys, itemz: matchDateKeys_count, currents, as: _sidebarItemTypes.matchDates, ..._initCalendarProps, showToday: true, showMissing: false })
        }
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [levelLastDates, matchDateKeys, matchDateKeys_count]);

  // update DISTRICTS
  useEffect(() => {
    if (scheduleTypes.district) {
      const _districts = []
      if (sportDistricts && districts_org) {
        sportDistricts.forEach(sd => {
          if (districts_org[sd]) {
            _districts.push(districts_org[sd].name)
          }
        })
      }
      setInit({ smt: sidebarMenuTypes.two, items: _districts, currents, as: _sidebarItemTypes.districts, ..._initProps })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sportDistricts, districts_org]);

  // update LEVELS
  useEffect(() => {
    let _sportLevels = sportLevels
    if (next) {
      let _spl = []
      Object.keys(next).forEach(key => {
        if (next[key].checked) {
          _spl.push(key)
        }
      })
      _sportLevels = _spl
    }
    setInit({ smt: sidebarMenuTypes.three, items: _sportLevels, currents, as: _sidebarItemTypes.levels, ..._initProps, includeSearch: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sportLevels, currents]);

  useEffect(() => {
    switch (_matchScheduleDisplayType) {
      case gEnums.matchScheduleDisplayTypes.selectedDate:
        if (matchDateGroups) {
          const dmr = sportsSeason_fns.getSelectedDateMatches(matchDateGroups, ms_matchDates, ms_districts, ms_levels, team_favs, previewMatches)
          const { sectionDateMatches } = dmr ?? {}
          const pms = sectionDateMatches ? _.filter(sectionDateMatches, { isPlayoff: true }) : {}
          dmr.playoffDates = pms && Object.keys(pms).length > 0
          handleResults_matchDate && handleResults_matchDate(dmr)
        }
        break;

      case gEnums.matchScheduleDisplayTypes.allDates:
      case gEnums.matchScheduleDisplayTypes.clickedDates:
        if (levelDateGroups && item_levels) {
          const _levelDateGroup = levelDateGroups[item_levels]
          if (_levelDateGroup) {
            let _levelDateKey = getCurrentDateKey(_levelDateGroup)
            if (!_levelDateKey) { _levelDateKey = formatItem(formatTypes.shortestDate, new Date()) }
            handleSet_levelDateGroup && handleSet_levelDateGroup(_levelDateGroup, _levelDateKey, item_levels)
          }
        }
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [matchDateGroups, item_matchDates, item_districts, item_levels, currents]);

  useEffect(() => {
    if (sortedMatches) {
      handleMatches_teamMatches(selectedLocation, selectedResult, selectedSection, selectedOpponent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sortedMatches, selectedLocation, selectedResult, selectedSection, selectedOpponent]);

  useEffect(() => {
    if (ms_levels && ms_levels.items && levelDateGroup && !levelGroupMatchElements) {
      const lgms = {}
      ms_levels.items.forEach(key => {
        const _levelDateGroup = levelDateGroups[key]
        lgms[key] = matchSegs(key, _levelDateGroup)
      })
      handleSet_levelGroupMatchElements(lgms)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ms_levels, levelDateGroup]);

  useEffect(() => {
    if (levelGroupMatchElements) {
      const _swiperItems = []
      const _levelGroupMatchElements = _.fromPairs(_.sortBy(_.toPairs(levelGroupMatchElements), ([key]) => key).reverse());
      Object.keys(_levelGroupMatchElements).forEach((k, index) => {
        _swiperItems.push({
          key: k,
          content: _levelGroupMatchElements[k],
          caption: k,
          index
        })
      })
      handleSet_swiper({ swipeItems: _swiperItems, sidebarItems: ms_levels, handleSwiperIndexChange })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [levelGroupMatchElements]);

  const matchSegs = (itemLevel) => {
    const mss = <MatchListSelected
      key={uniqueKey('mss', 'ml', itemLevel)}
      teamKey={teamKey}
      dateKey={dateKey}
      item_levels={itemLevel ? itemLevel : item_levels}
      forOverview={forOverview}
      forSummary={forSummary}
      forPlayoff={forPlayoff} />
    return [mss]
  }

  const matchesWrapper = () => <Wrapper
    header={_allowMatchFilter && <MatchFilter />}
    content={matchSegs()}
    updating={false}
    updatingCaption={'Fetching'}
    noMid={noMid}
  />

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={matchesWrapper()}
    dimmed={dimmed}
    inverted={inverted}
  />

  const content = () => {
    if (scheduleTypes.date) {
      return <div>Date</div>
    } else if (scheduleTypes.common) {
      return commonMatches ? matchesWrapper() : <div key={uniqueKey('mtl', 'd')} ></div>
    } else if (scheduleTypes.team || scheduleTypes.match) {
      return sortedMatches ? matchesWrapper() : <div key={uniqueKey('mtl', 'd')} ></div>
    } else {
      if (showToday) {
        return <MatchLevelDates showHeader={true} useHorizontalScoreboard={useHorizontalScoreboard} />
      } else {
        switch (_matchScheduleDisplayType) {
          case gEnums.matchScheduleDisplayTypes.allDates:
          case gEnums.matchScheduleDisplayTypes.clickedDates:
            return swiper ? <SwiperWrapper swType={'ms'} swiper={swiper} noData={noData} key={uniqueKey('sw', 'sm')} /> : <div></div>
          case gEnums.matchScheduleDisplayTypes.selectedDate:
            return menuSidebars()
          case gEnums.matchScheduleDisplayTypes.levelDates:
            return <MatchLevelDates useHorizontalScoreboard={useHorizontalScoreboard} />
          default:
            return <div></div>
        }
      }
    }
  }

  return content()

}

/**
 * 
 * @param {object} props 
 * @returns the MatchSchedule componnent wrapped with SeasonMatchesProvider
 */
export const MatchScheduleWithProvider = (props) => {
  return <SeasonMatchesProvider  {...props}  >
    <MatchSchedule {...props} />
  </SeasonMatchesProvider>
}

export default MatchScheduleWithProvider
