import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Icon, Image, Label, List } from 'semantic-ui-react';
import Checker from '../forms/elements/Checker';
import { convertHelpers } from '../../common/convert';
import { uniqueKey } from '../../common/keys';
import { ParentContext } from '../../cnr/contexts/ParentContext';

/**
 * 
 * @param {*} props (allowed, checkAllow, handleSelect, ignoreKey, linkedItems, linkedKey, menuList, selectedItems, selectedOnly)
 * @returns a List with List.Items
 */
const MenuSelect = (props) => {

  const { allowed, storageType, listSize, checkAllow, handleSelect, ignoreKey, linkedItems, linkedKey, menuList, selectedItems, selectedOnly, readOnly } = props

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { storage_state } = states
  const { globalFiles } = storage_state ?? {}

  const [files, setFiles] = useState()

  useEffect(() => {
    const _globalFiles = globalFiles && storageType ? globalFiles[storageType] : null
    setFiles(_globalFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageType, globalFiles]);

  const listIcon = (menuListItem) => {
    if (files && menuListItem && menuListItem.display && menuListItem.display.iconImage && files[menuListItem.display.iconImage]) {
      const file = files[menuListItem.display.iconImage]
      const { urls } = file ?? {}
      const { thumbnail } = urls ?? {}
      if (thumbnail) {
        return <Image size='mini' src={thumbnail} />
      }
    } else if (menuListItem && menuListItem.pdf && menuListItem.pdf.checked) {
      return <Icon size='mini' name={'file pdf outline'} />
    }
  }

  /**
   * 
   * @param {object} menuListItem 
   * @param {string} itemKey 
   * @returns 
   */
  const listItem = (menuListItem, itemKey) => {

    const { key, caption, startDate, startTime, _itemKey } = menuListItem ?? {}

    const _key = key ? key : _itemKey

    let label;

    if (linkedItems && linkedItems[_key]) {
      const lis = _.filter(linkedItems[_key], { selected: true })
      const count = lis.length
      label = <Label color={linkedKey === _key ? 'green' : 'blue'} size={'tiny'}>{count}</Label>
    }

    let itemCaption = convertHelpers.getItemName(menuListItem)

    if (!itemCaption) { itemCaption = itemKey }
    if (caption) { itemCaption = caption }

    let checked;

    if (selectedItems) {
      if (_.isArray(selectedItems)) {
        checked = selectedItems && selectedItems.includes(_key) ? true : false
      } else {
        checked = selectedItems && selectedItems[_key] && selectedItems[_key].selected
      }
    }

    let allowListItem = true
    if (selectedOnly) { allowListItem = checked }

    return allowListItem ? <List.Item key={uniqueKey('ms', 'li', _key)}>
      <List.Content floated='right'>
        {!ignoreKey && <span className='ms-key'>{_key}</span>}
        {label}
        {listIcon(menuListItem)}
      </List.Content>
      <Checker item={menuListItem} label={itemCaption} altLabels={[startDate, startTime]} disabled={readOnly} handleClick={handleSelect} checked={checked} checkAllow={checkAllow} allowed={allowed} />
    </List.Item> : null
  }

  const listItems = () => {
    const elements = []
    if (menuList) {
      Object.keys(menuList).forEach(key => {
        const menuListItem = menuList[key]
        if (_.isObject(menuListItem)) {
          try {
            elements.push(listItem(menuListItem, key))
          } catch (error) {
            console.log('error', error)
          }
        }
      })
      return elements
    }
  }

  const list = () => <List divided size={listSize ? listSize : null} verticalAlign='middle'>{listItems()}</List>

  return list()

}

export default MenuSelect