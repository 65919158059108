import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { Image, Segment } from 'semantic-ui-react';
import { AppUserContext } from '../../../global/cnr/contexts/AppUserContext';
import { AuthContext } from "../../../global/cnr/contexts/AuthContext";
import { PapsContext } from "../../../global/cnr/contexts/PapsContext";
import { EventSettingsContext } from "../../../global/cnr/contexts/SettingsDataContext";
import { StorageContext } from "../../../global/cnr/contexts/StorageContext";
import { UiItemContext } from "../../../global/cnr/contexts/UiItemContext";
import { signInMethodTypes } from '../../../global/cnr/reducers/SignInReducer';
import { uniqueKey } from "../../../global/common/keys";
import NoData from "../../../global/components/alerts/NoData";
import StartDimmer from '../../../global/components/alerts/StartDimmer';
import { firebaseAuth } from "../../../global/components/auth/firebase/firebaseAuth";
import UiSaveButtons from "../../../global/components/buttons/UiSaveButtons";
import Popuper from '../../../global/components/popups/Popuper';
import { gEnums } from "../../../global/enums/globalEnums";
import GenericPopupMenuItem from '../../../global/genericControls/GenericMenuItem';
import { formatTelephone, getThemeColorDirect, setThemeColors } from "../../../global/styles/formatting";
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import { PageWrapper } from '../../../global/wrappers/PageWrapper';
import Wrapper, { wrapperTypes } from "../../../global/wrappers/Wrapper";
import { TicketingContext } from "../../../projectSpecific/ticketing/cnr/contexts/TicketingContext";
import { ticketingHandlers, ticketingInitialState, ticketingReducer } from "../../../projectSpecific/ticketing/cnr/reducers/TicketingReducer";
import AppUserTicketingWithProvider from "../../../projectSpecific/ticketing/components/AppUserTicketing";
import AssignTicket, { assignTypes } from "../../../projectSpecific/ticketing/components/AssignTIcket";
import TicketHelpWizard from "../../../projectSpecific/ticketing/components/TicketHelpWizard";

// http://localhost:3000/#/clients/PwbtTs9DLDu9aq8NAfh2/events/2021%20MLS%20CUP/willCall/+14126002081

export const willCallTypes = {
  email: 'email',
  phone: 'phone',
}

const userMenuItems = {
  help: 'help',
  print: 'print',
  refresh: 'refresh',
  signOut: 'signOut',
}

const WillCall = (props) => {

  const { willCallType } = props ?? {}

  const authContext = useContext(AuthContext)
  const { auth_handlers } = authContext ?? {}

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ?? {}
  const { appUser } = appUser_state ?? {}

  // papsContext
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext ?? {}
  const { platform } = paps_state ?? {}
  const { isMobileDevice } = platform ?? {}

  // eventSettingsContext
  const eventSettingsContext = useContext(EventSettingsContext)
  const { eventSettings_state } = eventSettingsContext ?? {}
  const { eventSettings } = eventSettings_state ?? {}
  const { global } = eventSettings ?? {}
  const { banner, appTicketing, appSignIn } = global ?? {}
  const { bannerType, banner: banner_global } = banner ?? {}
  const { ticketImage, ticketColors, ticketBorderColor } = appTicketing ?? {}

  // storageContext
  const storageContext = useContext(StorageContext)
  const { storage_fns } = storageContext ?? {}

  // authContext  
  const { appUserAccess, phoneNumber: phoneNumber_appUser, email: email_appUser } = appUser ?? {}

  let { loggedIn } = appUserAccess ?? {}
  let _loggedIn = loggedIn

  if (willCallType) {
    switch (willCallType) {
      case willCallTypes.email:
        if (!email_appUser) { _loggedIn = false }
        break;
      case willCallTypes.phone:
        if (!phoneNumber_appUser) { _loggedIn = false }
        break;
      default:
        break;
    }
  }

  const { pathViews, rootPaths } = paps_state ?? {}
  let { willCall: _willCallKey } = pathViews ?? {}

  // if the willCallKey is not in the pathView, assign it by the auth email or phoneNumber
  if (!_willCallKey) {
    switch (willCallType) {
      case willCallTypes.email:
        if (email_appUser) { _willCallKey = email_appUser }
        break;
      case willCallTypes.phone:
        if (phoneNumber_appUser) { _willCallKey = phoneNumber_appUser }
        break;
      default:
        break;
    }
  }

  const _isPhoneNumber = _willCallKey ? isPossiblePhoneNumber(_willCallKey) : false

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers } = uiItemContext ?? {}
  const { handleShow_itemSidebar } = item_handlers ?? {}

  const [_banner, set_banner] = useState()
  const [showHelp, setShowHelp] = useState()
  // const [pwe, setPwe] = useState()

  const init_state = { isWillCall: true, pathViews, rootPaths, appTicketing }
  const [ticketing_state, ticketingDispatch] = useReducer(ticketingReducer, ticketingInitialState(init_state));
  const ticketing_handlers = ticketingHandlers(ticketingDispatch, ticketing_state)
  const { willCallTickets, groupedWillCalls, tickets, showReassign, reassignInfo, showPrint, showTicket } = ticketing_state ?? {}
  const ticketCount = tickets ? Object.keys(tickets).length : 0

  const handleShowHelp = () => setShowHelp(!showHelp)

  const handleMenuItemClick = (item) => {
    switch (item) {
      case userMenuItems.help:
        handleShowHelp()
        break;
      case userMenuItems.print:
        ticketing_handlers.handleShowPrint()
        break;
      case userMenuItems.signOut:
        auth_handlers.handleSignOut()
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    ticketing_handlers.initWillCall(pathViews, 'tickets')
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (_willCallKey && tickets && groupedWillCalls) {
      ticketing_handlers.handleGetWillCallByKey(_willCallKey, pathViews.willCall)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_willCallKey, tickets, groupedWillCalls, ticketCount]);

  useEffect(() => {
    if (!_loggedIn) {
      if (willCallType) {
        switch (willCallType) {
          case willCallTypes.email:
            firebaseAuth(appSignIn, [signInMethodTypes.emailAndPassword], null, true)
            break;
          case willCallTypes.phone:
            firebaseAuth(appSignIn, [signInMethodTypes.phone])
            break;
          default:
            firebaseAuth(appSignIn, [signInMethodTypes.phone])
            break;
        }
      } else {
        firebaseAuth(appSignIn, [signInMethodTypes.phone])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    switch (bannerType) {
      case gEnums.bannerTypes.fromFile:
        if (banner) { storage_fns.getImageUrl(banner_global, true, gEnums.storageTypes.image, set_banner) }
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [banner_global, bannerType]);

  useEffect(() => {
    if (ticketImage) { storage_fns.getImageUrl(ticketImage, true, gEnums.storageTypes.image, ticketing_handlers.handleTicketImage) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ticketImage]);

  useEffect(() => {
    const _ticketColors = ticketColors ? setThemeColors(global, ticketColors) : {}
    const tcs = {
      background: _ticketColors.backgroundColor,
      text: _ticketColors.color,
      border: getThemeColorDirect(global, ticketBorderColor),
    }
    ticketing_handlers.handleTicketColors(tcs)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const assignTicket = () => {
    const { ticket, subTicketKey } = reassignInfo ?? {}
    const { _itemKey } = ticket ?? {}
    if (willCallTickets && willCallTickets[_itemKey]) {
      const _ticket = willCallTickets[_itemKey]
      const { subTickets } = _ticket ?? {}
      if (subTickets) {
        const subTicket = _.find(subTickets, { subTicketKey: subTicketKey })
        if (subTicket) {
          return <AssignTicket
            ticket={ticket}
            handleCancel={ticketing_handlers.handleShowReassign}
            subTicket={subTicket}
            isWillCall={true}
            assignType={assignTypes.forward}
          />
        }
      }
    }
    return <div>---</div>
  }

  const willCallContent = () => <Wrapper
    content={<AppUserTicketingWithProvider willCallTickets={willCallTickets} />}
    footer={showPrint && <UiSaveButtons save={{ oc: ticketing_handlers.handleShowPrint, caption: 'Close' }} />}
    wrapperType={wrapperTypes.paddedFooter}
  />

  const bannerImage = () => <Image key={uniqueKey('wci')} size='large' className={'banner-image'} centered src={_banner.url} style={{ display: 'block' }} alt={'image'} />

  const wcKey = () => {
    if (_willCallKey) {
      return _isPhoneNumber ? formatTelephone(_willCallKey) : _willCallKey
    } else {
      return ''
    }
  }

  const willCallHeader = () => <div className={'will-call-header'}>
    <div>{'Will Call'}</div>
    <div>{wcKey()}</div>
  </div>

  const wizard = () => {
    if (showReassign) {
      return <FullPageWrapper
        topperCaption={'Will Call' + wcKey()}
        content={assignTicket()}
        noModal={true}
      ></FullPageWrapper >
    } else {
      if (_loggedIn) {
        return <FullPageWrapper
          topperCaption={_banner ? bannerImage() : null}
          topperHeader={willCallHeader()}
          content={willCallTickets && Object.keys(willCallTickets).length > 0 ? willCallContent() : <NoData altCaption={'Will Call Passes'} inverted={true} />}
          noModal={true}
          handleHelp={handleShowHelp}
        ></FullPageWrapper >
      } else {
        return <FullPageWrapper
          topperCaption={'Will Call Authentication'}
          content={<Segment basic className={'phone-auth'} id="firebaseui-auth-container"></Segment>}
          handleCancel={handleShow_itemSidebar && handleShow_itemSidebar}
          noModal={true}
        />
      }
    }
  }

  const gpmi = (item, oc) => <GenericPopupMenuItem item={item} oc={oc} />

  const sidebarMenuItems = () => {
    const mis = []
    mis.push(gpmi(userMenuItems.help, handleMenuItemClick))
    mis.push(gpmi(userMenuItems.print, handleMenuItemClick))
    mis.push(gpmi(userMenuItems.signOut, handleMenuItemClick))
    return mis
  }

  const popuper = () => <Popuper
    menuItems={sidebarMenuItems()}
    content={wizard()}
    showPopupIcon={true}
  />

  const content = () => {
    if (willCallTickets === undefined) {
      return <StartDimmer caption={''} />
    } else {
      if (showHelp) {
        return <TicketHelpWizard handleShowHelp={handleShowHelp} noModal={true} isWillCall={true} />
      } else if (showPrint) {
        return wizard()
      } else {
        if (showTicket) {
          return wizard()
        } else {
          return popuper()
        }
      }
    }
  }

  return <TicketingContext.Provider value={{ ticketing_state, ticketing_handlers }}>
    <PageWrapper content={content()} showPrint={showPrint} isMobileDevice={isMobileDevice} />
  </TicketingContext.Provider>
}

export default WillCall