import _ from 'lodash';
import React, { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { _checkComponentTimes, _showStates } from '../../../App';
import { _projectAppNames, isLocalhost } from '../../../project/appConfiguration';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import FullPageAlert from '../../components/alerts/FullPageAlert';
import { currentHelpers } from '../../redirection/current';
import { _cacheNames, _cacheTypes } from '../reducers/ServiceWorkerReducer';
import { startTypes } from '../reducers/StartReducer';
import { AppSettingsContext } from './AppSettingsContext';
import { AppUserContext } from './AppUserContext';
import { PapsContext } from './PapsContext';
import { _settingSourceTypes, ServiceWorkerContext } from './ServiceWorkerContext';
import { StartContext } from './StartContext';

const _ignoreSa = false
const _reassigingToPojo = true

/** 
 * @state clientSettings_state (`clientSettings`)
 * @contexts (`AppSettingsContext`, `HomeSettingsContext`, `PapsContext`, `StartContext`)
 */
export const ClientSettingsContext = createContext();
/** 
 * @state eventSettings_state (`eventSettings`)
 * @functions eventSettings_fns (`getGlobalSetting`, `getGlobalViewItem`, `getGlobalViewItemProps`, `getHomeGlobalSetting`, `mergeViewItemProps`)
 * @contexts (`AppSettingsContext`, `ClientSettingsContext`, `HomeSettingsContext`,`PapsContext`, `StartContext`)
 */
export const EventSettingsContext = createContext();
/** 
 * @state homeSettings_state (`homeSettings`)
 * @contexts (`AppSettingsContext`, `PapsContext`, `StartContext`)
 */
export const HomeSettingsContext = createContext();

/**
 * Returns the home, client and event settings contexts from the database 
 * @param {object} props (ignoreStart)
 * @provides ClientSettingsContext (clientSettings)
 * @provides EventSettingsContext (eventSettings)
 * @provides HomeSettingsContext (homeSettings)
 * @contexts [StartContext, AuthContext, AppSettingContext, PapsProvider] 
 * @returns 
 */
const SettingsDataProvider = (props) => {

  const { ignoreStart } = props ?? {}

  const _isLocalhost = isLocalhost()
  const _host_doc = document.location.host

  // authContext 
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ?? {}
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { isSuperAdmin } = appUserAccess ?? {}

  const _isSuperAdmin = _ignoreSa ? false : isSuperAdmin

  // startContext
  const startContext = useContext(StartContext);
  const { start_state, start_handlers, start_fns } = startContext ?? {}
  const { startDocName } = start_state ?? {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext ?? {}
  const { settingsDocName, settingsDocKeys, pathViews, pathname, view } = paps_state ?? {}
  const { clients: client_key, events: event_key } = pathViews ?? {}

  const _isHome = !client_key && !event_key ? true : false
  const _isEvent = event_key ? true : false

  // serviceWorkerContext
  const serviceWorkerContext = useContext(ServiceWorkerContext)
  const { serviceWorker_state, serviceWorker_handlers } = serviceWorkerContext ?? {}
  const { useSwCache, cache_info, cacheUpdateSettingsKey, cacheNeedsSettingsUpdate } = serviceWorker_state ?? {}
  const { cacheFetched, cache_groups } = cache_info ?? {}
  const { cache_settings } = cache_groups ?? {}

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext);
  const { appSettings_state, appSettings_handlers } = appSettingsContext ?? {}
  const { allAppSettings, settingsKeys, appConfigSettings, fbErrorCode, appSettings, appLanding_global, appShortUrls_global, modifiedAppSettings, currentPathName } = appSettings_state ?? {}
  const { useAppLanding, landingUrl, clientKey, eventKey, appLandingUrls } = appLanding_global ?? {}
  const { useAppShortUrls, appLandingShortUrls } = appShortUrls_global ?? {}
  const { appNames } = appConfigSettings ?? {}
  const { appName } = appNames ?? {}
  const { hceSettings } = appSettings ?? {}
  const { settingsReady, settingsError } = appSettings ?? {}

  _checkComponentTimes && start_fns.useTimeMeasurement('SettingsDataProvider', settingsReady)

  const isClient = client_key ? true : false
  let _clientSettings;

  if (hceSettings) {
    _clientSettings = isClient ? hceSettings.client_key : hceSettings.clients
  }

  const newPath = !_.isEqual(currentPathName, pathname)

  const states_all = {
    home: hceSettings ? { homeSettings: hceSettings.home } : {},
    client: _clientSettings ? { clientSettings: _clientSettings } : {},
    event: hceSettings ? { eventSettings: hceSettings.event_key } : {},
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (pathViews && pathViews.events) {
      appSettings_handlers.handleGet_eventTimeStamps(pathViews)
    } else {
      appSettings_handlers.handleSet_eventTimeStamps({})
    }
    // eslint-disable-next-line
  }, [pathViews && pathViews.events]);

  // console.log('allAppSettings', allAppSettings)


  // if the settingsDocName changes, get the settings
  // the callback is handleAmmend_appSettings
  // RERUNS: settingsDocName, pathViews
  useEffect(() => {

    if (_.size(settingsDocKeys) > 0) {

      const appSettings = allAppSettings ? allAppSettings[settingsDocName] : null

      if (appSettings) {
        appSettings_handlers.handleAmmend_appSettings(appSettings, pathViews, settingsDocKeys, pathname, _settingSourceTypes.app)
      } else {
        if (start_handlers.handleAmmend_startDimmer && (startDocName !== settingsDocName) && !ignoreStart) {
          start_handlers.handleAmmend_startDimmer(startTypes.event, settingsDocName)
        }

        // get the settings document
        appSettings_handlers.handleGet_hceSettings_simple(settingsDocKeys)
      }
    }
  },
    // eslint-disable-next-line
    [settingsDocName]
  )

  useEffect(() => {
    if (settingsKeys) {
      appSettings_handlers.handleGet_hceSettings_fromSimple()
    }
  }, [settingsKeys]);

  useEffect(() => {
    if (modifiedAppSettings && newPath) {
      appSettings_handlers.handleAmmend_modifiedAppSettings()
    }
  }, [newPath, modifiedAppSettings]);

  // triggers the redirection of the app to the `landingUrl` if useAppLanding and `landingUrl` are set.
  useEffect(
    () => {

      let _hostUrl;

      if (!isClient) {
        if (useAppLanding && !_isSuperAdmin) {
          if (landingUrl) {
            _hostUrl = '/' + landingUrl
          } else if (clientKey && eventKey) {
            _hostUrl = '/clients/' + clientKey + '/events/' + eventKey
          } else if (appLandingUrls && appLandingUrls) {
            appLandingUrls.forEach(appLandingUrl => {
              const { host, hostUrl } = appLandingUrl
              if (_host_doc === host) {
                if (hostUrl) {
                  _hostUrl = hostUrl
                }
              }
            })
          }
        }

        if (useAppShortUrls && appLandingShortUrls && _isHome) {
          const splits = currentHelpers.getSplits()
          appLandingShortUrls.forEach(appLandingShortUrl => {
            const { host, shortUrl, redirectUrl } = appLandingShortUrl
            if (_host_doc === host || _isLocalhost) {
              const shortMatch = splits.includes(shortUrl)
              if (shortMatch) {
                _hostUrl = redirectUrl
              }
            }
          })
        }

        if (_reassigingToPojo && _isHome && _host_doc === 'thumbstat.com') {
          _hostUrl = '/clients/b4C6wHP0A5AgQPTD78ue/events/pojoSports/organizations/BjY3VqQ3lUBQ9PXDyXCl/districts/qJOYke2iCxxT8RppMcsc/sports/V8h4h1sJDQpc4Mb969ko'
        }
      }

      if (_hostUrl) { navigate(_hostUrl) }

    },
    // eslint-disable-next-line
    [useAppLanding, useAppShortUrls]
  )

  /**
   * 
   * @param {string} cacheName 
   * @param {object} hceData 
   */
  const useServiceWorkerCache = (cacheName, hceData) => {

    const cache_setting = cache_settings ? cache_settings[cacheName] : {};
    const { status: status_cache } = cache_setting ?? {}
    const { status: status_hce } = hceData ?? {};

    useEffect(() => {
      // Check if hceData is not empty and cacheFetched is true before running the effect
      if (cacheFetched && hceData) {

        let updateSettingsSw = false

        if (!status_cache) {
          updateSettingsSw = true
        } else {
          const { lastUpdate: lastUpdate_settings } = status_cache ?? {};
          const { lastUpdate } = status_hce ?? {};
          updateSettingsSw = lastUpdate_settings !== lastUpdate
        }

        if (updateSettingsSw) {
          // console.log('updateSettingsSw', updateSettingsSw)
          // console.log('!! Updating Settings Cache !!', pathViews.events)
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(() => {
              // Send message to service worker to update cache
              const settings = {
                cacheKey: _cacheNames.cache_settings,
                cacheName,
                cacheData: hceData,
              }
              serviceWorker_handlers.handleSend_messageToServiceWorker(settings, _cacheTypes.settings);
            });
          }
        }
      }
      // eslint-disable-next-line
    }, [cacheFetched, hceData]); // Ensure effect triggers when cacheFetched or data changes
  };

  // Always call the hook but it will only run if conditions are met
  useServiceWorkerCache('home', hceSettings?.home);
  useServiceWorkerCache('clients', hceSettings?.clients);
  useServiceWorkerCache(client_key, hceSettings?.client_key);
  useServiceWorkerCache(event_key, hceSettings?.event_key);

  const homeSettings_fns = {
    getSignInHelp: (helpType) => {
      const { homeSettings } = states_all.home ?? {}
      const { global } = homeSettings ?? {}
      const { signInHelp } = global ?? {}
      const help = signInHelp ? signInHelp[helpType] : null
      return help
    }
  }

  const eventSettings_fns = {
    getHomeGlobalSetting: (key, subKey) => {
      const { global } = hceSettings.home ?? {}
      const globalItem = global ? global[key] : {}
      if (globalItem) {
        if (subKey) {
          return globalItem ? globalItem[subKey] : null
        } else {
          return globalItem
        }
      } else {
        return null
      }
    },
    getGlobalSetting: (key, subKey) => {
      const { global } = hceSettings.events ?? {}
      const globalItem = global ? global[key] : {}
      if (globalItem) {
        if (subKey) {
          return globalItem ? globalItem[subKey] : null
        } else {
          return globalItem
        }
      } else {
        return null
      }
    },
    getGlobalViewItem: (key) => {
      const { viewItems } = hceSettings.events ?? {}
      const viewItem = viewItems ? viewItems[key] : {}
      return viewItem
    },
    getGlobalViewItemProps: (key) => {
      const { viewItems } = hceSettings.events ?? {}
      const viewItem = viewItems ? viewItems[key] : {}
      const { props } = viewItem ?? {}
      return props
    },
    mergeViewItemProps: (viewItem) => {
      const { props, key } = viewItem ?? {}
      const { viewItems } = hceSettings.events ?? {}
      const viewItem_g = viewItems ? viewItems[key] : {}
      const { props: props_g } = viewItem_g ?? {}
      const np = {}
      if (props_g) {
        Object.keys(props_g).forEach(pKey => {
          if (props[pKey]) {
            const prop_g = props_g[pKey]
            props[pKey] = _.merge(prop_g, props[pKey])
          }
        })
      }
      return np
    }
  }

  if (_showStates.any && _showStates.settingsData) {
    console.log(Object.keys(eventSettings_fns).sort())
  }

  // , appSettings_handlers: appSettings_handlers // was in EventSettingsContext > NOT needed
  const providerContext = () => <HomeSettingsContext.Provider value={{ homeSettings_state: states_all.home, homeSettings_fns }}>
    <ClientSettingsContext.Provider value={{ clientSettings_state: states_all.client }}>
      <EventSettingsContext.Provider value={{ eventSettings_fns, eventSettings_state: states_all.event }}>
        {props.children}
      </EventSettingsContext.Provider>
    </ClientSettingsContext.Provider>
  </HomeSettingsContext.Provider>

  if (settingsError) {
    console.log('settingsError', settingsError)
    switch (appName) {
      case _projectAppNames.pojo:
        return <FullPageAlert
          title={'Thumbstat'}
          message={"Hello there! Currently working on improving your experience. We are in the process of creating a upgraded version due the site traffic. We apologize for any inconvenience this may cause. Thank you for your patience and understanding! Please try back again at a later time."}
        />
      default:
        return <FullPageAlert
          title={appName}
          message={fbErrorCode ? fbErrorCode : settingsError.toString()}
        />
    }

  } else {
    return settingsReady ? providerContext() : <div></div>
  }

}

export default SettingsDataProvider 