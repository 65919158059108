import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';
import { Grid, Menu } from 'semantic-ui-react';
import { uniqueKey } from '../common/keys';
import { getListStyle } from '../viewSettings/sorting/sortHelpers';
import { dragDropperTypes } from './DragDropper';

export const DropGroupWrapper = (props) => {

  const { dgwProps } = props

  console.log('dgwProps', dgwProps)

  const {
    allowSort,
    dndType,
    header,
    showHeader,
    sortFixedCount,
    uks,
  } = dgwProps


  let cn = 'drop-group-container ' + dndType
  let cnc = 'drop-group-content'

  if (!allowSort) { cnc += ' nd' }
  if (sortFixedCount) { cn += ' fixed' }

  const groupContent = () => {
    switch (dndType) {
      case dragDropperTypes.settingsGroups:
        return <React.Fragment >
          {showHeader && header}
          <div key={uks.uk2} className={cnc}>{<DroppableWrapper dgwProps={dgwProps} />}</div>
        </React.Fragment>

      default:
        return <React.Fragment>
          {showHeader && header}
          <div key={uks.uk2} className={cnc}>{<DroppableWrapper dgwProps={dgwProps} />}</div>
        </React.Fragment>
    }
  }

  return <div key={uks.uki} className={cn}>
    {groupContent()}
  </div>
}

export const DroppableWrapper = (props) => {

  const { dgwProps } = props

  const {
    allowSort,
    dndType,
    group,
    groupKey,
    horizontal,
    wrapperCns,
  } = dgwProps

  const { elements } = group ?? {}

  let cn = 'drop-list ddw active drag'
  if (wrapperCns) { cn += ' ' + wrapperCns }

  const divContents = () => {

    let dragElements;

    if (elements) {
      dragElements = elements.map((element, index) => allowSort ? <DraggableElement key={uniqueKey('dde', index)} dgwProps={dgwProps} index={index} element={element} /> : element)
      switch (dndType) {
        case dragDropperTypes.settingsGroups:
          return <Menu vertical borderless fluid inverted className={'msmi menu-groups three'} >
            <Menu.Item className={'mi-group'}>
              <Menu.Menu  >
                {dragElements}
              </Menu.Menu>
            </Menu.Item>
          </Menu>
        default:
          if (horizontal) {
            return <Grid.Row>
              {elements.map((element, index) => allowSort ? <DraggableElement dgwProps={dgwProps} index={index} element={element} /> : element)}
            </Grid.Row>
          } else {
            return dragElements
          }
      }
    }

    return <div></div>
  }

  return <Droppable
    droppableId={groupKey}
    direction={horizontal ? 'horizontal' : 'vertical'}
    key={uniqueKey('dd', 'dr')}
  >
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        className={cn}
        key={uniqueKey('dd', 'dr', 'd', groupKey)}
        style={getListStyle(snapshot.isDraggingOver)}
      >
        {divContents()}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
}

const DraggableElement = (props) => {

  const { dgwProps, element, index } = props

  const {
    groupKey,
    portal,
    portalCn,
  } = dgwProps

  const drKey = groupKey + '_' + index

  return <Draggable
    key={uniqueKey('de', drKey)}
    draggableId={drKey}
    index={index}
  >
    {(draggableProvided, draggableSnapshot) => (
      <PortalAwareItem
        portal={portal}
        element={element}
        provided={draggableProvided}
        snapshot={draggableSnapshot}
        portalCn={portalCn}
      />
    )}
  </Draggable>
}

const PortalAwareItem = (props) => {

  const { portal, element, provided, snapshot, portalCn } = props

  const usePortal = snapshot.isDragging ? true : false;

  const divProps = {
    ref: provided.innerRef,
    ...provided.draggableProps,
    ...provided.dragHandleProps,
  }

  if (portalCn) { divProps.className = portalCn }

  const child = (
    <div {...divProps}>
      {element}
    </div>
  );

  if (!usePortal) { return child; }

  // if dragging - put the item in a portal 
  return createPortal(
    child,
    portal
  )

}