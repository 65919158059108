import React, { useContext } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { uniqueKey } from '../../../../global/common/keys';
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext';
import { _showTypes } from '../../cnr/reducers/SeasonMatchesReducer';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';

const matchHeaderIcons = {
  today: 'arrow right',
  todayS: 'arrow down',
  todayOnly: 'calendar check outline',
  todayOnlyS: 'calendar check',
  calendar: 'calendar alternate outline',
  list: 'list',
}

const MatchScheduleHeader = (props) => {

  const { todayOnly } = props ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { showExpandIcon, showTodayIcon } = sportsSeason_state ?? {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state, seasonMatches_handlers } = seasonMatchesContext ? seasonMatchesContext : {}
  const {
    dateMatchResults,
    levelKey,
    pastNonScoreMatches,
    showExpand,
    showMissing,
    showToday,
  } = seasonMatches_state

  const levelMissing = pastNonScoreMatches && levelKey ? pastNonScoreMatches[levelKey] : {}
  const levelMissingCount = levelMissing ? levelMissing.length : 0

  const { matchDate } = dateMatchResults ? dateMatchResults : {}

  const handleShowMissing = () => seasonMatches_handlers.handleShow_matches(_showTypes.missing)
  const handleShowCalendar = () => seasonMatches_handlers.handleShow_matches(_showTypes.calendar)
  const handleShowExpand = () => seasonMatches_handlers.handleShow_matches(_showTypes.expand)
  const handleGotoToday = () => seasonMatches_handlers.handle_gotoToday()

  const missingIcon = () => <Label icon color={levelMissingCount === 0 ? 'green' : 'yellow'} size='mini' title={showMissing ? 'Hide Missing' : 'Show Missing'} onClick={() => { handleShowMissing() }} ><Icon name={levelMissingCount === 0 ? 'check' : 'question'} />{levelMissingCount}</Label>

  const expandIcon = () => <Icon color={'blue'} name={showExpand ? 'window maximize' : 'window maximize outline'} bordered title={showExpand ? 'Collapse' : 'Expand'} onClick={() => { handleShowExpand() }} />
  const calendarIcon = () => <Icon color={'blue'} name={matchHeaderIcons.calendar} bordered title={'Show Calendar'} onClick={() => { handleShowCalendar() }} />
  const todayIcon = () => <Icon color={'blue'} name={showToday ? matchHeaderIcons.todayOnlyS : matchHeaderIcons.todayOnly} bordered title={'Show Today'} onClick={() => { handleGotoToday() }} />

  return <div key={uniqueKey('mls', 'ti')} className={'schedule-dates-header'}>
    {matchDate}
    {!todayOnly && missingIcon()}
    {showTodayIcon && todayIcon()}
    {showExpandIcon && !todayOnly && expandIcon()}
    {!todayOnly && calendarIcon()}
  </div>
}

export default MatchScheduleHeader