import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Label } from 'semantic-ui-react'
import PendingWait from '../../../../global/components/alerts/pendings/PendingWait'
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons'
import { fsfn_sheets } from '../../../../global/functions/fbSheetsFuntions'
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper'

export const TryoutList = (props) => {

  const [totals, setTotals] = useState({ greys: 0, reds: 0, blues: 0, greens: 0 })
  const [currentPlayers, setCurrentPlayers] = useState()

  const [gsValues] = useState({ sheetName: 'Roster', googleSheetsId: '1nZttFMd-peCFRjfi4gax_OlG3GxOWzTYewhpPQ64ZN4' })

  const updateTotals = (cps) => {
    const _greys = _.filter(cps, { level: 0 })
    const _reds = _.filter(cps, { level: 1 })
    const _blues = _.filter(cps, { level: 2 })
    const _greens = _.filter(cps, { level: 3 })
    setTotals({ greys: _greys.length, reds: _reds.length, blues: _blues.length, greens: _greens.length })
  }

  const getGoogleData = () => {
    const cb = (results) => {
      const _rstz = _.sortBy(results, function (p) {
        return parseInt(p.number, 10);
      });
      _rstz.forEach(p => {
        p.level = parseInt(p.level)
        p.head = parseInt(p.head)
        p.assistant = parseInt(p.assistant)
      })
      setCurrentPlayers(_rstz)
      updateTotals(_rstz)
    }
    fsfn_sheets.getGoogleSheetRange(gsValues, cb)
  }

  useEffect(() => {
    getGoogleData()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleClick = (index) => {
    const _currentPlayers = [...currentPlayers]
    _currentPlayers[index].level = _currentPlayers[index].level + 1
    if (_currentPlayers[index].level > 3) { _currentPlayers[index].level = 0 }
    setCurrentPlayers(_currentPlayers)
    updateTotals(_currentPlayers)
    const updateData = {
      values: [[_currentPlayers[index].level]], // Update with the desired value
    };
    // fsfn_sheets.updateGoogleSheetValue(gsValues.googleSheetsId, gsValues.sheetName + '!E' + (index + 2), updateData)
  }

  const handleUpdateList = () => {
    const dataList = []
    currentPlayers.forEach(p => {
      dataList.push([p.level])
    })
    const updateData = {
      values: dataList, // Update with the desired value
    };
    fsfn_sheets.updateGoogleSheetValue(gsValues.googleSheetsId, gsValues.sheetName + '!E2:E', updateData)
  }

  const handleRefreshList = () => getGoogleData()

  const playerLabel = (level, index, allowEdit) => {
    let color = 'grey'
    switch (level) {
      case 0:
        color = 'grey'
        break;
      case 1:
        color = 'red'
        break;
      case 2:
        color = 'blue'
        break;
      case 3:
        color = 'green'
        break;
      default:
        color = 'grey'
    }
    return <Label color={color} onClick={e => allowEdit && handleClick(index)}>{level ? level : 0}</Label>
  }

  const headerDiv = () => <div className='tryout_list_header'>
    <div><Label color={'grey'}>{'U: ' + totals.greys}</Label></div>
    <div><Label color={'red'}>{'C: ' + totals.reds}</Label></div>
    <div><Label color={'blue'}>{'M: ' + totals.blues}</Label></div>
    <div><Label color={'green'}>{'T: ' + totals.greens}</Label></div>
  </div>

  const playerHeaderDiv = () => {
    return <div className='tryout_list_h'>
      <div>{'#'}</div>
      <div>{'Last'}</div>
      <div>{'First'}</div>
      <div>{'R'}</div>
      <div>{'H'}</div>
      <div>{'A'}</div>
    </div>
  }

  const playerDiv = (player, index) => {
    const { number, lastName, firstName, level, head, assistant } = player
    return <div className='tryout_list'>
      <div>{number}</div>
      <div>{lastName}</div>
      <div>{firstName}</div>
      <div>{playerLabel(level, index, true)}</div>
      <div>{playerLabel(head, index)}</div>
      <div>{playerLabel(assistant, index)}</div>
    </div>
  }

  const playersDiv = () => currentPlayers.map((player, index) => playerDiv(player, index))

  const content = () => <div>
    {headerDiv()}
    {playerHeaderDiv()}
    {playersDiv()}
  </div>

  const fullContent = () => <Wrapper
    content={content()}
    wrapperType={wrapperTypes.paddedFooter}
    footer={<UiSaveButtons save={{ oc: handleUpdateList }} preview={{ oc: handleRefreshList, caption: 'Refresh' }} />}
  ></Wrapper>

  const fpw = () => <FullPageWrapper
    handleCancel={props.handleCancel}
    topperCaption={'Tryout Roster'}
    content={fullContent()}
  ></FullPageWrapper>

  return !currentPlayers ? <PendingWait /> : fpw()
}
