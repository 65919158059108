import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Label } from 'semantic-ui-react';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer, sidebarSliderTypes } from '../../../../global/cnr/reducers/SidebarReducer';
import { sortObject } from '../../../../global/common/sorting';
import DragDropper, { dragDropperTypes } from '../../../../global/dragNDrops/DragDropper';
import MenuSidebars from '../../../../global/sidebars/MenuSidebars';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { CreateSportsContext } from '../CreateSports';
import { getSectionColor } from '../sectionColors';
import { CreatePlayoffContext } from '../CreatePlayoff';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import DropdownOptionsMenu from '../../../../global/components/forms/elements/DropdownOptionsMenu';
import { _teamNames, _teamNamesReplace } from '../../elements/standings/SectionStandings';

const sidebarType = 'createSeeds'

export const seedTypes = {
  playoff: 'playoff',
  tournament: 'tournament'
}

const CreateSeeds = (props) => {

  const { seedType } = props

  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_handlers } = sportsSeasonContext ?? {}

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_state } = createSportsContext ?? {}
  const { teams_info } = createSports_state ?? {}
  const { selected: teams_selected } = teams_info ?? {}

  // createPlayoffContext
  const createPlayoffContext = useContext(CreatePlayoffContext);
  const { createPlayoff_state, createPlayoff_handlers } = createPlayoffContext ?? {}
  const { seededTeams, playoff_info, playoffGroup } = createPlayoff_state ?? {}
  const { playoffLevelTeams, playoffLevel } = playoff_info ?? {}

  // sidebar
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType, handlers: sportsSeason_handlers }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, currents, dimmed } = sidebar_state ?? {}
  const { setInit } = sidebar_handlers ?? {}

  const { ms_playoffGroups } = sidebar_items ?? {}
  const { selected: selected_pg } = ms_playoffGroups ?? {}
  const { item: item_pg } = selected_pg ?? {}

  const _initProps = { sidebarSliderType: sidebarSliderTypes.labeled }

  const [levels, setLevels] = useState()

  const createSeededTeams = (teams) => {
    const _seededTeams = {}
    _.forEach(teams, (team, index) => {
      const { id, name, levels, sections, record } = team
      const { sectionRank } = record ?? {}
      _seededTeams[id] = {
        id: id,
        name: name,
        levels: levels,
        sections: sections,
        record: {
          ...record,
          sectionRank,
        },
        seed: index,
        position: index,
      }
    })
    return _seededTeams
  }

  useEffect(() => {
    if (playoffLevel) {
      setInit({ smt: sidebarMenuTypes.one, items: [playoffLevel], currents, as: 'playoffGroups', ..._initProps })
    } else if (levels) {
      setInit({ smt: sidebarMenuTypes.one, items: levels, currents, as: 'playoffGroups', ..._initProps })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [levels, playoffLevel]);

  useEffect(() => {
    if (teams_selected) {
      const _seededTeams = createSeededTeams(teams_selected)
      createPlayoff_handlers.handleUpdate_seededTeams(_seededTeams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [teams_selected]);

  // once
  useEffect(() => {
    if (playoffLevelTeams) {
      setLevels(Object.keys(playoffLevelTeams).sort().reverse())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (playoffLevelTeams) {
      const _playoffLevelTeams = {}
      Object.keys(playoffLevelTeams).forEach(pltk => {
        const _playoffGroup = playoffLevelTeams[pltk]
        const _playoffGroup_sorted = _.orderBy(_playoffGroup, ['record.ppm', 'record.owp', 'record.swp'], ['desc', 'desc', 'desc']);
        _playoffLevelTeams[pltk] = _playoffGroup_sorted
      })
      createPlayoff_handlers.handleSet_playoffLevelTeams(_playoffLevelTeams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (playoffLevelTeams && item_pg) {
      const _playoffGroup = playoffLevelTeams[item_pg]
      createPlayoff_handlers.handleSet_playoffGroup(item_pg, _playoffGroup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [item_pg, playoffLevelTeams]);

  const handleSortTeams = (opts) => {
    const { sortBy } = opts
    const _playoffGroup = playoffLevelTeams[item_pg]
    let sorted;
    switch (sortBy) {
      case 'points':
        sorted = _.orderBy(_playoffGroup, ['record.ppm', 'record.owp', 'record.swp'], ['desc', 'desc', 'desc']);
        break;
      case 'owp':
        sorted = _.orderBy(_playoffGroup, ['record.owp', 'record.swp'], ['desc', 'desc']);
        break;
      default:
        sorted = _.orderBy(_playoffGroup, ['record.ppm', 'record.owp', 'record.swp'], ['desc', 'desc', 'desc']);
    }
    createPlayoff_handlers.handleSet_playoffGroup(item_pg, sorted)
  }

  /** handles the sorting of teams in the levels and sections */
  const handleSorted = (sortedGroups) => {
    // convert back to objects 
    if (sortedGroups) {
      const newVld = {}
      if (seedType === seedTypes.playoff && playoffLevelTeams) {
        Object.keys(sortedGroups).forEach(key => {
          const dataItems = sortedGroups[key].dataItems
          Object.keys(dataItems).forEach(k => {
            const dataItem = dataItems[k]
            dataItem.seed = dataItem.position
            newVld[dataItem.id] = dataItem
          })
          const plt = { ...playoffLevelTeams }
          plt[selected_pg.item] = _.sortBy(newVld, 'seed')
          createPlayoff_handlers.handleUpdate_playoffSeededTeams(plt)
        })
      } else if (seedType === seedTypes.tournament) {
        Object.keys(sortedGroups).forEach(key => {
          const dataItems = sortedGroups[key].dataItems
          Object.keys(dataItems).forEach(k => {
            const dataItem = dataItems[k]
            // match seed and position
            dataItem.seed = dataItem.position
            newVld[dataItem.id] = dataItem
          })
        })
        createPlayoff_handlers.handleUpdate_seededTeams(newVld)
      }
    }
  }

  const lblSize = 'mini'

  const numberLabel = (index, text) => <Label color={'grey'}>{indexLabel(index)}{text}</Label>
  const numberLabelWith = (index, text, section, sectionRank, badSeed) => <Label color={'black'}>{indexLabel(index, badSeed)}{text} {sectionRankLabel(section, sectionRank ? sectionRank : '-')} {sectionLabel(section)}</Label>
  const indexLabel = (index, badSeed) => <Label size={lblSize} color={badSeed ? getSectionColor(badSeed) : 'black'}>{index}</Label>
  const sectionLabel = (section) => <Label size={lblSize} color={getSectionColor(section)}>{section}</Label>
  const sectionRankLabel = (section, rank) => <Label size={lblSize} color={getSectionColor(section)}>{rank}</Label>

  const btns_search = () => {
    const btns = []
    btns.push({ caption: 'Sort by Points', oc: handleSortTeams, color: 'blue', fl: true, icon: 'sort', opts: { sortBy: 'points' } })
    btns.push({ caption: 'Sort by OWP', oc: handleSortTeams, color: 'blue', fl: true, icon: 'sort', opts: { sortBy: 'owp' } })
    return btns
  }

  const seedFooter = () => {
    const _allBtns = btns_search()
    return <div className={'header-flex'}>
      <div className='flx2'>
        <div>
          <DropdownOptionsMenu menuButtons={_allBtns} caption={'Sort'} icon={'options'} color={'blue'} upward={true} />
        </div>
      </div>
    </div>
  }

  const teamName = (name) => {
    let _name = name
    for (let i = 0; i < _teamNames.length; i++) {
      if (name.includes(_teamNames[i])) {
        _name = name.replace(_teamNames[i], _teamNamesReplace[i]);
      }
    }
    return _name
  }

  const menuMenuItems = (dataItems) => {
    const ds = []
    Object.keys(dataItems).forEach((key, index) => {
      const di = dataItems[key]
      const { name, seed, record, sections, badSeed } = di
      const _name = teamName(name)
      const { sectionRank } = record ? record : {}
      let cn = 'drag-number'
      if (index !== seed && !playoffLevelTeams && seedType === seedTypes.playoff) { cn += ' issue' }
      if (playoffLevelTeams) {
        const caption = di.record && di.record.displayPoints ? _name + ' ' + di.record.displayPoints : _name
        ds.push(<div className={cn}>{numberLabelWith(index + 1, caption, _.startCase(sections), sectionRank, badSeed)}</div>)
      } else {
        ds.push(<div className={cn}>{numberLabel(index + 1, _name)}</div>)
      }
    })
    return ds
  }

  const playoffGroupContainer = () => {
    const dndGroups = {}
    const dataItems = _.sortBy(playoffGroup, 'seed')
    const elements = menuMenuItems(dataItems)
    dndGroups['seeds'] = { elements, dataItems }
    const dd_groups = sortObject(dndGroups, 'key')
    return <DragDropper
      dndType={dragDropperTypes.simple}
      dndGroups={dd_groups}
      handleSorted={handleSorted}
      groupChangeKey={'seeds'}
      allowSort={true}
      portalCn={'msmi four menu'}
    />
  }

  const playoffGroupWrapper = () => <Wrapper
    content={playoffGroupContainer()}
    footer={seedFooter()}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper>

  const seededGroupContainer = () => {
    const dndGroups = {}
    const dataItems = _.sortBy(seededTeams, 'seed')
    const elements = menuMenuItems(dataItems)
    dndGroups['seeds'] = { elements, dataItems }
    return <DragDropper
      dndType={dragDropperTypes.simple}
      handleSorted={handleSorted}
      groupChangeKey={'seeds'}
      allowSort={true}
      portalCn={'msmi four menu'}
    />
  }

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={playoffGroup ? playoffGroupWrapper() : <div></div>}
    dimmed={dimmed}
  />

  if (playoffLevelTeams && levels && ms_playoffGroups && ms_playoffGroups.items) {
    return menuSidebars()
  } else if (seededTeams && !playoffLevelTeams) {
    return seededGroupContainer()
  }

  return <div></div>

}

export default CreateSeeds