import _ from 'lodash'
import { convertHelpers } from '../../../../global/common/convert'
import { creatingHelpers } from '../../../../global/common/creating'
import { handleUpdateEmailSent, handle_triggerEmail } from './FieldTripHelpers'
import { approvalRoles, staffRoleTypes, validationRoles } from './FieldTripReducer'

const rts = {
  handleInit_fieldTrips: 'handleInit_fieldTrips',
  handleResponse_data: 'handleResponse_data',
  handleTrigger_email: 'handleTrigger_email',
  handleUpdate_emailSent: 'handleUpdate_emailSent',
  handleSet_otherData: 'handleSet_otherData',
}

const fieldTripCollections = [
  'fieldTrips',
  'staff',
  'staffTypes',
]

export const fieldTripsReducer = (state, action) => {

  const { paps_state, appDataSource } = state
  const { pathViews, sharePathFull } = paps_state ?? {}
  const { type, dispatch } = action
  const { useAppDataDocuments } = appDataSource ?? {}

  const { handleResponse_data, handleUpdate_emailSent } = fieldTripsHandlers(dispatch)

  switch (type) {

    case rts.handleInit_fieldTrips:
      if (action.appDataDocuments && action.appDataDocuments.fieldTrips) {
        const { fieldTrips, staff, staffTypes } = action.appDataDocuments
        convertHelpers.createItemKeys(staff)
        const { otherData, otherDataa } = getOtherData(staff, staffTypes)
        const staffFieldTrips = getStaffFieldTrips(fieldTrips, staffTypes, otherData)
        ammendStatus(fieldTrips)
        return { ...state, fieldTrips, staffTypes, ...otherData, staffFieldTrips }
        // } else {
        //   _.forEach(fieldTripCollections, (fieldTripCollection => {
        //     getFtData(pathViews, fieldTripCollection, handleRes`ponse_data)
        //   }))
      }
      return { ...state }

    case rts.handleResponse_data:
      return { ...state, [action.collectionName]: action.data }

    case rts.handleSet_otherData:
      return { ...state, ...action.otherData }

    case rts.handleTrigger_email:
      handle_triggerEmail(state, action.fieldTrip, action.role, pathViews, sharePathFull, handleUpdate_emailSent)
      return { ...state }

    case rts.handleUpdate_emailSent:
      handleUpdateEmailSent(action.fieldTrip, action.role, pathViews, useAppDataDocuments)
      if (action.cb) { action.cb() }
      return { ...state }

    default:
      return { ...state }
  }
}

export const fieldTripsHandlers = (dispatch) => {
  return {
    handleInit_fieldTrips: (appDataDocuments, fieldTrip) => { dispatch({ type: rts.handleInit_fieldTrips, dispatch, appDataDocuments, fieldTrip }) },
    handleResponse_data: (data, collectionName) => { dispatch({ type: rts.handleResponse_data, dispatch, data, collectionName }) },
    handleTrigger_email: (fieldTrip, role) => { dispatch({ type: rts.handleTrigger_email, dispatch, fieldTrip, role }) },
    handleUpdate_emailSent: (fieldTrip, role, cb) => { dispatch({ type: rts.handleUpdate_emailSent, dispatch, fieldTrip, role, cb }) },
    handleSet_otherData: (otherData) => { dispatch({ type: rts.handleSet_otherData, dispatch, otherData }) },
  }
}

export const fieldTripsInitialState = (init_state) => {
  return { ...init_state }
};

const getOtherData = (staff, staffTypes) => {
  const otherData = {}
  const otherDataa = {}
  const xx = _.groupBy(staff, 'staffTypes')
  _.forEach(staffTypes, (st, stkey) => {
    const items = xx[stkey]
    if (items) {
      const _items = creatingHelpers.createObject(items, '_itemKey')
      const _name = _.camelCase(st.name)
      const _fullName = staffRoleTypes[_name];
      if (_fullName) {
        otherData[_.camelCase(_fullName)] = _items
      } else {
        otherDataa[_.camelCase(_name)] = _items
      }
    }
  })
  return { otherData, otherDataa }
}

const getStaffFieldTrips = (fieldTrips, staffTypes_all, otherData) => {

  const staffFieldTrips = {}

  _.forEach(otherData, (staffGroup, staffGroupKey) => {
    _.forEach(staffGroup, (staffGroupItem, staffGroupItemKey) => {
      const { staffTypes, _itemKey, name } = staffGroupItem ?? {}
      if (staffTypes && staffTypes.length === 1) {
        const staffTypeKey = staffTypes[0]
        let staffTypeName = staffTypes_all[staffTypeKey] && staffTypes_all[staffTypeKey].name
        staffTypeName = _.camelCase(staffTypeName)
        staffTypeName = staffRoleTypes[staffTypeName]
        const _filtered = _.filter(fieldTrips, (fieldTrip) => fieldTrip[staffTypeName] && fieldTrip[staffTypeName].includes(staffGroupItemKey));
        if (_filtered.length > 0) {
          staffFieldTrips[_itemKey] = _filtered
        }
      }
    })
  })
  return staffFieldTrips
}

const ammendStatus = (fieldTrips) => {
  _.forEach(fieldTrips, (fieldTrip) => {
    const { status } = fieldTrip ?? {}
    const _statusItems = {}

    _.forEach(approvalRoles, (approvalRole => {
      const _sent = approvalRole + 'EmailSentDate'
      const _approved = approvalRole + 'Approved'
      const _status = {
        sent: status && status[_sent] ? true : false,
        approved: status && status[_approved] ? true : false
      }
      if (fieldTrip[approvalRole]) {
        _statusItems[approvalRole] = _status
      }
    }))

    _.forEach(validationRoles, (validationRole => {
      const _sent = validationRole + 'EmailSentDate'
      const _validated = validationRole + 'Approved'
      const _status = {
        sent: status && status[_sent] ? true : false,
        approved: status && status[_validated] ? true : false
      }
      if (fieldTrip[validationRole]) {
        _statusItems[validationRole] = _status
      }
    }))
    fieldTrip._statusItems = _statusItems
  })
}

// const getItemsStatus = (fieldTrips) => {

//   const approvedDateProp = roleKey + 'ApprovedDate'
//   const approvedEmailProp = roleKey + 'EmailSentDate'

//   _.forEach(fieldTrips, (fieldTrip) => {
//     const { status } = fieldTrip ?? {}
//     const fieldTripData_role = fieldTrip[roleKey]
//     const fieldTripsData_role = fieldTrips_state[roleKey]
//     const fieldTripApprovalDate = status && status[approvedDateProp]
//     const fieldTripSentDate = status && status[approvedEmailProp]

//     const time_stamp_approved = fieldTripApprovalDate ? formatItem(formatTypes.fullDate, fieldTripApprovalDate.toDate()) : null
//     const time_stamp_emailSent = fieldTripSentDate ? formatItem(formatTypes.fullDate, fieldTripSentDate.toDate()) : null

//     const itemInfo = {}

//     _.forEach(fieldTripData_role, (itemKey => {

//       if (fieldTripsData_role && fieldTripsData_role[itemKey]) {

//         const item = fieldTripsData_role[itemKey]
//         const { name, _itemKey } = item ?? {}

//         let { email } = item ?? {}
//         let email2;
//         let forValidation;

//         switch (roleKey) {
//           case validationRoles.specialEdSecretaries:
//           case validationRoles.transportation:
//             forValidation = true
//             break;
//           default:
//             break;
//         }

//         if (useDefaultEmails) {
//           switch (roleKey) {
//             case approvalRoles.secretaries:
//               email = secretaryEmail ? secretaryEmail + ' *' : email
//               break;
//             case approvalRoles.principals:
//               email = principalEmail ? principalEmail + ' *' : email
//               break;
//             case approvalRoles.districtResources:
//               email = districtResourceEmail ? districtResourceEmail + ' *' : email
//               break;
//             default:
//               break;
//           }
//         }

//         if (useMe) {
//           email2 = _developerAdminEmail
//         }

//         itemInfo._itemKey = _itemKey
//         itemInfo.name = name
//         itemInfo.email = email
//         itemInfo.email2 = email2
//         itemInfo.time_stamp_approved = time_stamp_approved
//         itemInfo.approved = time_stamp_approved ? true : false
//         itemInfo.emailed = time_stamp_emailSent ? true : false
//         itemInfo.description_approved = itemDescription_appoved(roleKey, time_stamp_approved)
//         itemInfo.description_emailSent = itemDescription_emailSent(roleKey, time_stamp_emailSent)
//         itemInfo.labels = labels_status(time_stamp_emailSent, time_stamp_approved, forValidation)
//         itemInfo.role = _.startCase(roleKey)
//         itemInfo.roleKey = roleKey
//         itemInfo.has = true
//       }
//     }))
//   })




//   return itemInfo

// }