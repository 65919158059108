import React, { createContext, useContext, useEffect, useState } from 'react';
import Splash from '../../components/alerts/Splash';
import { gEnums } from '../../enums/globalEnums';
import { startTypes } from '../reducers/StartReducer';
import { ParentContext } from './ParentContext';

export const SplashContext = createContext();

const SplashContextProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { appSettings_state, page_state } = states
  const { appSettings_handlers, start_handlers } = handlers

  // appSettingsContext 
  let { showSplash } = appSettings_state

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_styles } = pageSettings ?? {}
  const { splashScreen } = aps_global ?? {}
  let { splashScreenDelay } = splashScreen ?? {}

  // startContext 
  const { handleAmmend_startDimmer } = start_handlers ?? {}

  if (!splashScreenDelay) { splashScreenDelay = 2 }

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.splashScreen] : {}

  if (!styleAndClass.style) { styleAndClass.style = {} }
  if (!styleAndClass.style.backgroundColor) { styleAndClass.style.backgroundColor = 'black' }
  if (!styleAndClass.style.color) { styleAndClass.style.color = 'white' }

  const initSecs = showSplash ? splashScreenDelay : 0
  const [timeLeft, setTimeLeft] = useState(initSecs);

  useEffect(() => {
    if (!timeLeft && showSplash) {
      appSettings_handlers.handleSplash()
      return;
    } else {
      if (showSplash) {
        handleAmmend_startDimmer && handleAmmend_startDimmer(startTypes.finish)
        const intervalId = setInterval(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
      }
    }
    // eslint-disable-next-line
  }, [timeLeft, showSplash]);

  return (
    <React.Fragment>
      {showSplash && <Splash styleAndClass={styleAndClass} />}
      {!showSplash && <SplashContext.Provider value={{ ok: true }}>
        {props.children}
      </SplashContext.Provider>
      }
    </React.Fragment>
  )
}

export default SplashContextProvider