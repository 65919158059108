import _ from 'lodash';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Image, Input, List, Ref, Segment, Select, Visibility } from 'semantic-ui-react';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { creatingHelpers } from '../common/creating';
import { filterHelpers } from '../common/filtering';
import { uniqueKey } from '../common/keys';
import { copyObj } from '../common_web/copy';
import PendingSeg from '../components/alerts/pendings/PendingSeg';
import Checker from '../components/forms/elements/Checker';
import { AlphabetMenuWithProvider } from '../components/menus/AlphabetMenu';
import { gEnums } from '../enums/globalEnums';
import { iconColorTypes } from '../enums/settingsIconTypes';
import { listList, listStatic } from '../lists/lists';
import { dataModificationTypes } from '../viewSettings/enums/itemActionTypes';

const scrollProps = {
  scrollIncrement: 25,
  useScroll: false
}

const _statusTypes = {
  none: { icon: 'circle outline', color: 'grey' },
  active: { icon: 'check', color: 'blue' },
  approved: { icon: 'check circle', color: 'green' },
  beta: { icon: 'bell', color: 'orange' },
  delete: { icon: 'delete', color: 'red' },
  inactive: { icon: 'ban', color: 'grey' },
  hidden: { icon: 'unhide', color: 'red' },
  pending: { icon: 'clock outline', color: 'orange' },
}

// const _changeTypes = {
//   none: { icon: 'circle outline', color: 'grey' },
//   changed: { icon: 'check', color: 'blue' },
// }

const _startLet = 'srt-let'

/** Simple list containing the data items of a View 
 * @onClick viewDataSwiper_handlers.handleLinkData
 * @onClick viewDataSwiper_handlers.handleGroupData
 * @onClick viewDataSwiper_handlers.handleDataListClick
 * 
*/
const DataList = (props) => {

  const showImgs = false

  const {
    appData,
    currentPropKey,
    dataModificationType,
    handleAmmend,
    handleDifferences,
    handleSelected,
    headerTypes,
    ignoreInverted,
    inApp,
    isList,
    key_viewItem,
    listActionType,
    listData,
    selectedItems,
    showSelectedOnly,
    viewItem,
  } = props


  const { scrollIncrement, useScroll } = scrollProps
  const _headerType = headerTypes && currentPropKey && headerTypes[currentPropKey]

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { eventInfo_state, page_state } = states ?? {}
  const { page_fns } = fns ?? {}

  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems } = pageSettings ?? {}
  const { settingsOptions } = aps_global ?? {}
  const { useDarkMode } = settingsOptions ?? {}
  const { staticViews } = eventInfo_state ?? {}

  const viewItem_g = aps_viewItems && viewItem && aps_viewItems[viewItem.key] ? aps_viewItems[viewItem.key] : null

  const { display: display_g } = viewItem_g ?? {}
  const { sortProp: sortProp_g } = display_g ?? {}

  let _useDarkMode = useDarkMode
  if (inApp) { _useDarkMode = false }
  if (ignoreInverted) { _useDarkMode = false }

  switch (listActionType) {
    case gEnums.listActionTypes.delete:
      _useDarkMode = false
      break;
    default:
    // nothing
  }

  const getViewableList = (scrolled) => {
    let ld;
    if (listData) {
      creatingHelpers.createKeys(listData)
      if (scrolled) {
        const l = Object.values(listData)
        const nvld = []
        l.slice(0, dataCount).forEach((item, i) => {
          nvld.push(item);
        });
        ld = nvld
      } else {
        if (isList) {
          ld = Object.keys(listData)
        } else {
          ld = Object.values(listData)
        }
      }
    }
    if (ld) { ld = _.sortBy(ld, sortProp_g ? sortProp_g : 'name') }
    return ld ? ld : []
  }

  // local state
  const [differences, setDifferences] = useState([])
  const [fullList] = useState(getViewableList())
  const [viewableVld, setViewableVld] = useState()
  const [dataCount, setDataCount] = useState(scrollIncrement ? scrollIncrement : 10)
  const [optsList, setOptsList] = useState()
  const [originalList, setOriginalList] = useState()
  const [activeOptions, setActiveOptions] = useState({})

  // ref
  const contextRef = createRef()

  useEffect(() => {
    if (appData) {
      setOriginalList(_.sortBy(copyObj(appData), sortProp_g ? sortProp_g : 'name'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  useEffect(() => {
    setViewableVld(getViewableList(useScroll))
    switch (dataModificationType) {
      case dataModificationTypes.status:
        setOptsList(listList(gEnums.statusTypes))
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData]);

  useEffect(() => {
    const _staticView = staticViews && currentPropKey ? staticViews[currentPropKey] : {}
    switch (dataModificationType) {
      case dataModificationTypes.modifyList:
        const x = listStatic(_staticView)
        setOptsList(x)
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPropKey]);

  useEffect(() => {
    if (fullList && useScroll && dataCount) {
      const nvld = []
      fullList.slice(0, dataCount).forEach((item, i) => {
        nvld.push(item);
      });
      setViewableVld(nvld)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCount]);

  useEffect(() => {
    handleDifferences(differences)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [differences]);

  // handlers
  const handleScroll = (e, value) => {
    if (useScroll) {
      if (value.calculations.bottomVisible) {
        if (dataCount <= fullList.length + 1) {
          let count = dataCount + scrollIncrement
          if (count > fullList.length + 1) { count = fullList.length + 1 }
          setDataCount(count)
        }
      }
    }
  }


  const handlePageClick = (e, item) => {
    e.stopPropagation()
    page_fns.pushSimple({ key: viewItem.key }, item.key)
  }

  const handleClick = (item, checked) => {
    // const key = isList ? item : item.key
    // if (showDirectDataLinking) {
    // viewDataSwiper_handlers.handleLinkData(viewItem.key, key, checked)
    // } else if (showGroupDataLinking) {
    // viewDataSwiper_handlers.handleGroupData(viewItem.key, key, checked)
    // } else {
    // viewDataSwiper_handlers.handleDataListClick(key)
    // }
  }

  const handleCheckClick = (e, item, checked) => {
    e.stopPropagation()
    if (handleSelected) { handleSelected(item, checked) }
  }

  const handleDataUpdate = (data) => {
    let _differences;
    const { item, value } = data ?? {}
    const { _itemKey } = item ?? {}
    const _viewableVld = [...viewableVld]
    const _item = filterHelpers.find(_viewableVld, '_itemKey', _itemKey)
    if (_item) {
      switch (dataModificationType) {
        case dataModificationTypes.status:
          if (value) {
            _item.itemStatusType = value
          } else {
            delete _item.itemStatusType
          }
          _differences = _.differenceWith(_viewableVld, originalList, (a, b) => a.itemStatusType === b.itemStatusType);
          break;
        default:
          _item[currentPropKey] = value
          _differences = _.differenceBy(_viewableVld, originalList, currentPropKey);
      }
      setViewableVld(_viewableVld)
      if (handleAmmend) { handleAmmend(_viewableVld) }
    }
    console.log('_differences', _differences)
    setDifferences(_differences)
  }

  const handlePropChange = (e, data) => {
    e.stopPropagation()
    handleDataUpdate(data)
  }

  const handleStatusChange = (e, data) => {
    e.stopPropagation()
    handleDataUpdate(data)
  }

  const handleSelectClick = (e, data) => {
    const { item, value } = data ?? {}
    const { key } = item ?? {}
    e.stopPropagation()
    const _activeOptions = { ...activeOptions }
    _activeOptions[key] = true
    setActiveOptions(_activeOptions)
  };


  const handleMouseMove = (event, alphaIndex) => {
    const alphaChar = alphaIndex ? String.fromCharCode(alphaIndex).toLowerCase() : 'a'
    const alphaElem = document.querySelector('.' + _startLet + '_' + alphaChar);
    // const mouseY = event.clientY;
    // const index = Math.floor(mouseY / alphaElem[0].offsetHeight);

    // Scroll to the corresponding alpha item
    if (alphaElem) {
      alphaElem.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const listItemItem = (checker, gotoPageIcon) => <div className={'list-item-container'}>
    <div>{checker}</div>
    {gotoPageIcon && <div>{gotoPageIcon}</div>}
  </div>

  const imageListItem = (item, index, headerValue, iconName, checked) => <List.Item key={uniqueKey('dl', index)} onClick={e => handleClick(item, checked)}>
    <List.Content floated='right'>
      <Button icon style={{ backgroundColor: 'inherit' }}>
        <Icon color={iconColorTypes.menuItem} name={iconName} />
      </Button>
    </List.Content>
    <Image
      avatar
      src='https://react.semantic-ui.com/images/avatar/small/lena.png'
    />
    <List.Content>{headerValue}</List.Content>
  </List.Item>

  const listItem_checker = (item, index, gotoPageIcon, checked, checker) => <List.Item key={uniqueKey('dl', index)} onClick={e => handleClick(item, checked)}>
    <List.Content>{listItemItem(checker, gotoPageIcon)}</List.Content>
  </List.Item>

  const listItem_emailLink = (item, index, headerValue, emailValue, checker, checked) => <List.Item key={uniqueKey('dl', index)} onClick={e => handleClick(item, checked)}>
    <List.Content floated='right' >{checker}</List.Content>
    <List.Content>
      <List.Header >{headerValue}</List.Header>
      <List.Description>{emailValue}</List.Description>
    </List.Content>
  </List.Item>

  const listItem_delete = (item, index, headerValue, checker) => <div key={uniqueKey('dl', index)} >
    {listItemItem(checker)}
    <div>{headerValue}</div>
  </div>

  const listItem_status = (index, headerValue, select, icon, alphaLetter, isDiff) => {
    let cn = alphaLetter ? _startLet + '_' + alphaLetter.toLowerCase() : null
    if (isDiff) {
      cn = cn ? `${cn} diff` : 'diff';
    }
    return <div key={uniqueKey('dl', index)} className={cn} >
      <div>
        {icon && <Icon name={icon.icon} color={icon.color} />}
        {headerValue}
      </div>
      {listItemItem(select)}
    </div >
  }

  const select = (item, status) => <Select
    key={uniqueKey('dl', 's', item.key)}
    placeholder={'Status'}
    value={status}
    options={optsList && optsList}
    onChange={handleStatusChange}
    onClick={handleSelectClick}
    item={item}
  />

  const dropdown = (item, value) => <Dropdown
    key={uniqueKey('dl', 's', item.key)}
    value={value}
    multiple
    selection
    options={optsList && optsList}
    onChange={handleStatusChange}
    onClick={handleSelectClick}
    item={item}
  />

  const input = (item, value) => <Input placeholder='Search...' value={value} onChange={handlePropChange} item={item} />

  const listElement = (item, status) => {
    switch (dataModificationType) {
      case dataModificationTypes.modifyList:
        switch (_headerType) {
          case 'string':
            switch (currentPropKey) {
              case 'itemStatusType':
                return select(item, status)
              default:
                return input(item, item[currentPropKey])
            }
          case 'array':
            const v = item[currentPropKey]
            return dropdown(item, v)
          default:
          // nothing 
        }
        break;
      default:
        return select(item, status)
    }
  }

  const listItem = (item, index, alphaLetter) => {

    let headerValue;
    let emailValue
    let iconName; // = dataLinkIcon
    let iconColor = 'blue'
    let status;
    let checked = false

    const { title, lastName, firstName, name, email, eventStatusType, key, _itemKey } = item ?? {}

    if (title) { headerValue = title }
    if (lastName && firstName) { headerValue = lastName + ', ' + firstName }
    if (name && !headerValue) { headerValue = name }
    if (email) { emailValue = email }
    if (eventStatusType) { status = eventStatusType }

    if (!headerValue) { headerValue = key }
    if (isList && !headerValue) { headerValue = item }
    if (isList && headerValue) { headerValue = _.startCase(headerValue) }

    if (!headerValue && email) {
      headerValue = email
      item.key = email
    }

    const _diffItem = _.findKey(differences, { _itemKey: _itemKey })
    let isDiff = _diffItem ? true : false

    if (selectedItems) { checked = selectedItems[item.key] }

    let show = true

    if (showSelectedOnly && !checked) {
      show = false
    }

    if (show) {
      if (showImgs) {
        return imageListItem(item, index, headerValue, iconName, checked)
      } else {
        switch (dataModificationType) {
          case dataModificationTypes.modifyList:
            const _selectM = listElement(item, status)
            return listItem_status(index, headerValue, _selectM, null, alphaLetter, isDiff)

          case dataModificationTypes.status:
            const { itemStatusType } = item
            const _select = listElement(item, itemStatusType)
            const _icon = itemStatusType ? _statusTypes[itemStatusType] : _statusTypes.none
            return listItem_status(index, headerValue, _select, _icon, alphaLetter, isDiff)

          default:
            const _checker = <Checker item={item} checked={checked} handle_click={handleCheckClick} />
            const _gotoPageIcon = <List.Icon name={'external square alternate'} color={iconColor} onClick={e => handlePageClick(e, item)} />
            switch (listActionType) {
              case gEnums.listActionTypes.emailSignInLink:
                return listItem_emailLink(item, index, headerValue, emailValue, _checker)
              case gEnums.listActionTypes.delete:
                return listItem_delete(item, index, headerValue, _checker, checked)
              default:
                return listItem_checker(item, index, _gotoPageIcon, checked, _checker)
            }
        }
      }
    }
  }

  const listItems = (dataItems) => {
    // eslint-disable-next-line  
    let _currentLetter;
    return dataItems.map((item, index) => {
      const { name, lastName, firstName } = item
      const _ni = lastName ? lastName.substring(0, 1) : null
      let _alphaLetter;
      if (_ni !== _currentLetter) {
        _currentLetter = _ni
        _alphaLetter = _currentLetter
      }
      if (item.key !== 'id') {
        return listItem(item, index, _alphaLetter)
      }
    })
  }

  const viewList = (dataItems) => {
    let cn = 'data-list-list ' + dataModificationType
    if (_headerType) {
      cn += ' ' + _headerType
    }
    if (showImgs) { cn += 'imgz' }
    if (dataItems && dataItems.length) {
      return <List relaxed divided className={cn} inverted={_useDarkMode} verticalAlign='middle'>
        {listItems(dataItems)}
      </List>
    } else {
      return <Segment basic inverted={_useDarkMode}>
        {'No Items Found'}
      </Segment>
    }
  }

  const alphaMenu = () => {

    const grouping = {
      alphaDisplayType: gEnums.alphaDisplayTypes.vertical,
      useVerticalHidden: true,
      verticalOpacity: gEnums.opacityTypes.lightest,
      restrictToExisting: true,
    }

    return <AlphabetMenuWithProvider
      origin={'dataList'}
      isSolo={true}
      dataItems={viewableVld}
      handleMouseMove={handleMouseMove}
      alphaValue={77}
      restrictToExisting={true}
      sortProp={'lastName'}
      grouping={grouping}
    />
  }

  const visibleList = (dataItems) => {
    return <Ref innerRef={contextRef}>
      <Visibility offset={[10, 10]} onUpdate={handleScroll} style={{ width: '100%' }}>
        {alphaMenu()}
        {viewList(dataItems)}
      </Visibility>
    </Ref>
  }

  const content = () => {
    if (useScroll) {
      return visibleList(viewableVld)
    } else {
      return <div>
        {alphaMenu()}
        {viewList(viewableVld)}
      </div>
    }
  }

  if (viewableVld) {
    return <div className='data-list-container' >
      {content()}
    </div>
  } else {
    return PendingSeg(14)
  }
}

export default DataList