import { formatItem, formatTypes } from "../../common/dateFormatting"
import _ from 'lodash'

const rts = {
  handleInputClick: 'handleInputClick',
  handleDatePush: 'handleDatePush',
  handleSelectedDates: 'handleSelectedDates',
  handleDateItem: 'handleDateItem',
  handleMonthsVisible: 'handleMonthsVisible',
  handleShowCalList: 'handleShowCalList',
  handleDateSelect: 'handleDateSelect',
  handleYearSelect: 'handleYearSelect',
  handleItemClick: 'handleItemClick',
  handleMenuClick: 'handleMenuClick',
  handleAmmend_crossover: 'handleAmmend_crossover',
}

export const calendarReducer = (state, action) => {

  const { currentDate, calOpen, selectedDates, dateFormat } = state
  const { type } = action

  switch (type) {

    case rts.handleMenuClick:
      switch (action.key) {
        case 'back':
          currentDate.setMonth(currentDate.getMonth() - 1)
          return { ...state, currentDate }
        case 'forward':
          currentDate.setMonth(currentDate.getMonth() + 1)
          return { ...state, currentDate }
        case 'pick':
          return { ...state, monthsVisible: !state.monthsVisible }
        default:
          return { ...state, }
      }

    case rts.handleItemClick:
      currentDate.setMonth(action.monthIndex)
      return {
        ...state,
        monthsVisible: false,
        currentDate
      }

    case rts.handleYearSelect:
      let _monthsVisible;
      switch (action.key) {
        case 'back':
          currentDate.setFullYear(currentDate.getFullYear() - 1)
          return { ...state, currentDate }
        case 'forward':
          currentDate.setFullYear(currentDate.getFullYear() + 1)
          return { ...state, currentDate }
        case 'pick':
          _monthsVisible = !state.monthsVisible
          break;
        default:
          // nothing  
          _monthsVisible = false
      }
      return {
        ...state,
        currentDate,
        monthsVisible: _monthsVisible
      }

    case rts.handleDateSelect:

      const _selectedDate = formatItem(dateFormat ? dateFormat : formatTypes.date, action.date)
      let _calOpen;

      if (selectedDates && !state.singleSelect) {
        const _selectedDates = [...selectedDates]
        ammendSelectedDates(_selectedDates, _selectedDate)
        return {
          ...state,
          selectedDates: _selectedDates,
          calOpen: _calOpen
        }
      } else {
        return {
          ...state,
          selectedDate: _selectedDate,
          calOpen: false
        }
      }

    case rts.handleSelectedDates:
      const { selectedDates: selectedDates_action } = action
      const _selectedDatez = []
      if (selectedDates_action) {
        _.forEach(selectedDates_action, (sd) => {
          _selectedDatez.push({ date: sd })
        })
      }
      return {
        ...state,
        selectedDates: action.selectedDates,
      }

    case rts.handleDateItem:
      const { dateItem } = action ?? {}
      const { scheduleDates } = dateItem ?? {}
      return {
        ...state,
        dateItem,
        selectedDates: scheduleDates ? scheduleDates : [],
      }

    case rts.handleShowCalList:
      return {
        ...state,
        calListOn: !state.calListOn
      }

    case rts.handleInputClick:
      return {
        ...state,
        calOpen: !calOpen,
      }

    case rts.handleAmmend_crossover:
      return {
        ...state,
        crossoverOn: action.crossoverOn
      }

    default:
      return state
  }
}

export const calendarInitialState = (init_state) => {
  const { fip, singleSelect, calProp, initDate, currentDate, dateFormat, selectedDates, fixedDates, dateGroups, dateGroupProp, selectOnly, sidebar } = init_state
  let _selectedDates = selectedDates
  if (!_selectedDates && fip && fip.value) { _selectedDates = [fip.value] }
  return {
    calOpen: false,
    calProp: calProp ?? {},
    currentDate: currentDate,
    dateFormat,
    dateGroupProp,
    dateGroups,
    fip,
    fixedDates,
    initDate,
    selectedDate: fip ? fip.value : null,
    selectedDates: _selectedDates ? _selectedDates : [],
    selectOnly,
    sidebar,
    singleSelect,
  }
};

export const calendarHandlers = (dispatch, state) => {
  return {
    handleMenuClick: (key, date) => { dispatch({ type: rts.handleMenuClick, key, date }) },
    handleItemClick: (monthIndex) => { dispatch({ type: rts.handleItemClick, monthIndex }) },
    handleYearSelect: (key) => { dispatch({ type: rts.handleYearSelect, key }) },
    handleDateSelect: (date) => { dispatch({ type: rts.handleDateSelect, date }) },
    handleShowCalList: () => { dispatch({ type: rts.handleShowCalList }) },
    handleInputClick: () => { dispatch({ type: rts.handleInputClick }) },
    handleDatePush: (selectedDates) => { dispatch({ type: rts.handleDatePush, selectedDates }) },
    handleSelectedDates: (selectedDates) => { dispatch({ type: rts.handleSelectedDates, selectedDates }) },
    handleDateItem: (dateItem) => { dispatch({ type: rts.handleDateItem, dateItem }) },
    handleAmmend_crossover: (crossoverOn) => { dispatch({ type: rts.handleAmmend_crossover, crossoverOn }) },
  }
}

const ammendSelectedDates = (selectedDates, selectedDate) => {
  if (selectedDates && selectedDate) {
    if (selectedDates.includes(selectedDate) === false) {
      selectedDates.push(selectedDate)
    } else {
      const index = selectedDates.indexOf(selectedDate);
      selectedDates.splice(index, 1)
    }
  }
}