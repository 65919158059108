import React, { useEffect, useState } from 'react';
import { Button, Icon, Label } from 'semantic-ui-react';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import { uniqueKey } from '../../../common/keys';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';

export const numberPadTypes = {
  grid: 'num-grid',
  vertSlider: 'num-slider'
}

export const NumberPad = (props) => {

  const { onChange, value, itemKey, opts, icon, showPadDirect, labelSize, useButton, withDecimal, numberPadType } = props
  const { disabled, noSize, floatRight, forEdit, caption } = opts ?? {}

  const [showPad, setShowPad] = useState(showPadDirect)
  const [currentValue, setCurrentValue] = useState()
  const [currentFloatValue, setCurrentFloatValue] = useState()
  const [editValue, setEditValue] = useState()
  const [editFloatValue, setEditFloatValue] = useState()

  const _value = showPad ? editValue : currentValue

  useEffect(() => {
    setCurrentValue(value)
    setCurrentFloatValue(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [value]);

  useEffect(() => {
    setEditValue(0)
    setEditFloatValue('0.00')
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const getFixed = (v) => (parseFloat(parseInt(v)) / 100).toFixed(2)

  const handleShowPad = (e, b) => {
    e && e.stopPropagation()
    b && b.stopPropagation()
    setShowPad(!showPad)
    setEditValue(0)
    setEditFloatValue('0.00')
  }

  const handleSetValue = (e, v) => {
    e && e.stopPropagation()
    const _clickedValue = v
    let _editValue = editValue.toString()
    if (_editValue === '0' && !withDecimal) {
      _editValue = _clickedValue.toString()
    } else {
      _editValue += _clickedValue.toString()
    }
    setEditValue(_editValue)
    setEditFloatValue(getFixed(_editValue))
  }

  const handleRemoveValue = (e) => {
    e && e.stopPropagation()
    let _editValue = editValue.toString()
    _editValue = _editValue.substring(0, _editValue.length - 1)
    setEditValue(_editValue)
    setEditFloatValue(getFixed(_editValue))
  }

  const handleClearValue = (e) => {
    e && e.stopPropagation()
    setEditValue(0)
    setEditFloatValue(getFixed('000'))
  }

  const handleOk = (a, e) => {
    e && e.stopPropagation()
    handleShowPad()
    setEditValue(0)
    setEditFloatValue(getFixed('000'))
    if (withDecimal) {
      onChange(null, { value: editFloatValue, itemKey, opts })
    } else {
      onChange(null, { value: editValue, itemKey, opts })
    }
  }

  const numHeader = (value) => {
    const divs = []
    divs.push(<div >{value}</div>)
    return divs
  }

  const numPad = () => {
    const gridItems = []
    for (var i = 1; i <= 12; i++) {
      const v = i
      switch (i) {
        case 10:
          gridItems.push(<div key={uniqueKey('np', i)} onClick={(e) => { handleRemoveValue(e) }}>{<Icon size={'small'} name={'arrow left'} />}</div>)
          break;
        case 11:
          gridItems.push(<div key={uniqueKey('np', i)} onClick={(e) => { handleSetValue(e, 0) }}>{0}</div>)
          break;
        case 12:
          gridItems.push(<div key={uniqueKey('np', i)} onClick={(e) => { handleClearValue(e) }}>{<Icon size={'small'} name={'delete'} />}</div>)
          break;
        default:
          gridItems.push(<div key={uniqueKey('np', i)} onClick={(e) => { handleSetValue(e, v) }}><div>{i}</div></div>)
          break;
      }
    }
    return gridItems
  }

  const content = () => <div className={'num-pad ' + numberPadType}>
    <div key={uniqueKey('np', 'c')} className={'num-pad-current'}>{'Current Value:'}{numHeader(withDecimal ? currentFloatValue : currentValue)}</div>
    <div key={uniqueKey('np', 'v')} className={'num-pad-value'}> {numHeader(withDecimal ? editFloatValue : editValue)}</div>
    <div key={uniqueKey('np', 'p')} className={'num-pad-pad'}>{numPad()}</div>
  </div>

  const wrapper = () => <Wrapper
    content={content()}
    footer={<UiSaveButtons save={{ oc: handleOk, caption: 'OK' }} />}
    wrapperType={wrapperTypes.paddedFooter}
  />

  const lProps = { color: disabled ? 'grey' : 'black', size: noSize ? null : 'tiny' }
  if (floatRight) { lProps.style = { float: 'right' } }
  if (forEdit) {
    lProps.style = { width: '100%', margin: '.25em 0 1em 0', padding: '1em' }
    lProps.color = 'white'
  }

  const amountElement = () =>
    useButton
      ?
      <Button icon labelPosition='right' onClick={(e) => { handleShowPad(e) }}>
        <Icon name='edit' color='green' />
        {'$'}{_value}
      </Button>
      :
      <Label {...lProps} size={labelSize ? labelSize : null} onClick={(e) => { !disabled && handleShowPad(e) }}>{icon && icon}{_value}</Label>

  return showPad ? <FullPageWrapper
    content={wrapper()}
    handleCancel={handleShowPad}
    topperCaption={'Pick Number'}
    topperCaption2={caption ? caption : null}
  />
    :
    amountElement()
}