import React, { useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { ImageContext } from '../../cnr/contexts/ImageContext';
import { ItemStorageContext } from '../../cnr/contexts/ItemStorageContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { imageHandlers, imageInitialState, imageReducer } from '../../cnr/reducers/ImageReducer';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { _animateTypes } from '../../motions/AnimateComponent';
import UiUpload from '../../pageItem/modification/dataManagement/UiUpload';
import { useElementOnScreen } from '../sem/visibleElements';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { ElementContext } from '../../cnr/contexts/ElementContext';

const UiImage = (props) => {

  const { showFullImage } = props

  const uiItemContext = useContext(UiItemContext)
  const { item_state } = uiItemContext ?? {}
  const { viewItem, itemData, _imageOpts, _dataModificationOpts } = item_state ?? {}
  const { images } = _dataModificationOpts ?? {}
  const { allowAction } = images ?? {}

  const { images: images_viewItem, key: key_viewItem, dataSource } = viewItem ?? {}
  const { borderColor, imageSize } = _imageOpts ?? {}
  const { dataCollectionName } = dataSource ?? {}
  const { email, _itemKey } = itemData ?? {}

  const elementContext = useContext(ElementContext)
  const { element_state } = elementContext ?? {}
  const { itemData: itemData_element } = element_state ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state, page_state, eventInfo_state, storage_state, paps_state, transition_state } = states
  const { viewKey } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings } = pageSettings ?? {}
  const { projectOptions, storageOptions, appUserPermissions } = aps_global ?? {}
  const { useLazyImageLoading } = projectOptions ?? {}
  const { allowImageUpload } = appUserPermissions ?? {}
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { accessLevel, appUserSession, isAdminOrSuper } = appUserAccess ?? {}
  const { appUserSessionKey } = appUserSession ?? {}
  const pageIsAppUsers = viewKey === appUserSessionKey
  const _allowAction = (viewKey && (allowAction || allowImageUpload)) && (pageIsAppUsers || accessLevel >= gEnums.accessLevels.admin.value)

  const { transitions } = transition_state ?? {}
  const transition_image = transitions ? transitions[_animateTypes.image] : null

  const { useClientProfileImage } = aps_appUserSettings ?? {}

  const { appUserUrls } = eventInfo_state ?? {}
  const { galleries } = storage_state ?? {}

  // itemStorageContext
  const itemStorageContext = useContext(ItemStorageContext)
  const { itemStorage_state } = itemStorageContext ?? {}
  const { galleryItems } = itemStorage_state ?? {}

  // eslint-disable-next-line 
  const [containerRef, isVisible] = useElementOnScreen({ root: null, rootMargin: '0px', threshold: 1.0 })

  let cn = 'card-image basic'
  // cn += ' is-' + imageSize
  if (useClientProfileImage && isAdminOrSuper) { cn += ' cpi' }

  const init_state = {
    _itemKey,
    allowAction: _allowAction,
    aps_appUserSettings,
    appUserUrls,
    borderColor,
    cn,
    containerRef,
    dataCollectionName,
    galleries,
    images_viewItem,
    itemData,
    key_viewItem,
    showFullImage,
    storageOptions,
    transition_image,
    useLazyImageLoading,
    viewItem,
    ...props
  }

  // image_state
  const [image_state, image_dispatch] = useReducer(imageReducer, init_state, imageInitialState);
  const image_handlers = imageHandlers(image_dispatch, image_state)
  const { cardImageElement, showImageUpload, fullImageProps } = image_state ?? {}

  useEffect(() => {
    if (galleryItems) {
      image_handlers.handleGet_galleryImage(galleryItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [galleryItems, galleries, email, _itemKey, dataCollectionName]);

  useEffect(() => {
    if (fullImageProps && isVisible) {
      image_handlers.handleImageVisible(_imageOpts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [isVisible, fullImageProps]);

  const content = () => {
    if (showImageUpload) {
      return <UiUpload origin={'UiImage'} handleCancel={image_handlers.handleShow_imageUpload} />
    } else {
      return <div className={cn} key={uniqueKey('crdi', _itemKey)} ref={containerRef}>
        {cardImageElement && cardImageElement.element}
      </div>
    }
  }

  const imageProvider = () => <ImageContext.Provider value={{ image_state, image_handlers }}>
    {content()}
  </ImageContext.Provider>

  return imageProvider()

}

export default UiImage

export const addCardImage = (cardImage, image_viewItem, display) => {

  const { displayType, cardDisplayType } = display ?? {}
  const { imageLocation } = image_viewItem ?? {}

  if (cardImage && displayType === gEnums.displayTypes.card && cardDisplayType === gEnums.cardDisplayTypes.nameSectionOnly) {
    return cardImage
  } else {
    if (imageLocation && imageLocation.indexOf(imageLocation) >= 0) {
      return cardImage
    }
  }
}