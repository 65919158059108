import _ from 'lodash';

export const creatingHelpers = {
  createArray: (items, keyProp) => createArray(items, keyProp),
  createDatesArray: (startDate, numberOfMonths) => createDatesArray(startDate, numberOfMonths),
  createItemArray: (items, keyProp) => createItemArray(items, keyProp),
  createKeyedObjectFromKey: (items, removes, altKey) => createKeyedObjectFromKey(items, removes, altKey),
  createKeys: (items) => createKeys(items),
  createObject: (items, keyProp, removeIds, removeKeys) => createObject(items, keyProp, removeIds, removeKeys),
  createObjectBundle: (root, prop) => createObjectBundle(root, prop),
}

/**
 * 
 * @param {array} items 
 * @param {string} keyProp 
 * @param {boolean} removeIds 
 * @param {boolean} removeKeys 
 * @returns 
 */
const createObject = (items, keyProp, removeIds, removeKeys) => {
  const fullO = {}
  if (_.isArray(items)) {
    items.forEach(item => {
      const oKey = item[keyProp]
      fullO[oKey] = item
      if (fullO[oKey]) {
        if (removeIds) { delete fullO[oKey].id }
        if (removeKeys) { delete fullO[oKey].key }
      }
    })
  } else {
    if (items) {
      Object.keys(items).forEach(item => { fullO[items[item][keyProp]] = items[item] })
    }
  }
  return fullO
}

/**
 * 
 * @param {object} items 
 * @param {array} removes 
 * @param {*} altKey 
 * @returns a new object with the key of the item.key as the key 
 */
const createKeyedObjectFromKey = (items, removes, altKey) => {
  const fullO = {}
  const _trueKey = altKey ? altKey : 'key'
  Object.keys(items).forEach(key => {
    const item = items[key]
    fullO[item[_trueKey]] = item
    if (removes) {
      removes.forEach(r => {
        delete fullO[items[key][_trueKey]][r]
      })
    }
  })
  // delete fullO.undefined
  return fullO
}

/**
 * 
 * @param {object} items 
 * @param {string} keyProp 
 * @returns an `array` from `items`
 */
const createArray = (items, keyProp) => {
  const fullO = {}
  Object.keys(items).forEach(key => {
    fullO[key] = {
      ...items[key],
      id: key
    }
    if (keyProp) { fullO[key][keyProp] = key }
  })
  return _.filter(fullO)
}

const createItemArray = (items, keyProp) => {
  const fullO = {}
  Object.keys(items).forEach(key => {
    fullO[key] = {
      key,
      id: key,
      ...items[key],
    }
  })
  return _.filter(fullO)
}

/**
 * 
 * @param {*} root 
 * @param {*} prop 
 * @deprecated
 * @returns 
 */
const createObjectBundle = (root, prop) => {
  if (!root[prop]) { root[prop] = {} }
  return root[prop]
}

/**
 * 
 * @param {object} items 
 * @returns adds a key for each item
 */
const createKeys = (items) => {
  if (items && _.isObject(items)) {
    Object.keys(items).forEach(key => {
      if (items[key] && !items[key].key && (key !== 'key')) {
        try {
          items[key].key = key
        } catch (error) {
          console.error('createKeys', items, key, error)
        }
      }
    })
  }
  return items
}

const createDatesArray = (startDate, numberOfMonths) => {

  function formatDate(date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  function generateDates() {

    const datesArray = [];

    const currentDate = new Date(startDate);

    const _startDate = currentDate.getDate()
    const _startMonth = currentDate.getMonth() + 1

    for (let i = 0; i < numberOfMonths; i++) {

      const _currentMonth = currentDate.getMonth() + 1

      const startDay = _startMonth === _currentMonth ? _startDate : 1

      const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

      for (let day = startDay; day <= daysInMonth; day++) {
        const dateCopy = new Date(currentDate);
        dateCopy.setDate(day);
        datesArray.push(formatDate(dateCopy));
      }

      // Move to the next month
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return datesArray;
  }

  return generateDates()

}