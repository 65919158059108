import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Image, Segment } from 'semantic-ui-react'
import { ManifestContext } from '../../cnr/contexts/ManifestContext'
import { manifestSizes } from '../imaging/createManifestIcons'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { getAppUserAccess } from '../../auth/appUserAccessPermissions'
import { gEnums } from '../../enums/globalEnums'
import Uploader from '../uploading/Uploader'

/** Dipslays the manifest icons vertically with a header of the size */
const ManifestViewer = (props) => {

  const { manifestFiles: manifestFiles_preview, preview } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state } = states
  const { appUsers } = appUser_state ?? {}

  const appUserAccess = getAppUserAccess(appUsers)
  const { appUserAccessType } = appUserAccess ?? {}

  let storageLocationType;

  switch (appUserAccessType) {
    case gEnums.appUserAccessTypes.admin:
    case gEnums.appUserAccessTypes.superAdmin:
      storageLocationType = gEnums.storageLocationTypes.profiles
      break;
    default:
      storageLocationType = gEnums.storageLocationTypes.clientProfile
  }

  // storageContext
  const manifestContext = useContext(ManifestContext);
  const { manifest_state } = manifestContext ?? {}
  const { manifestIcons } = manifest_state ?? {}

  const [files, setFiles] = useState()

  useEffect(() => {
    if (preview) {
      setFiles(manifestFiles_preview)
    } else {
      if (manifestIcons && Object.keys(manifestIcons).length > 0) {
        const mfs = _.sortBy(manifestIcons, 'size')
        setFiles(mfs)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const segGroup = (caption, content) => <Segment.Group>
    <Segment inverted>{caption}</Segment>
    <Segment inverted>{content}</Segment>
  </Segment.Group>

  const manImage = (imageFile, key) => <Image key={key} src={imageFile} />

  const uploader = () => <Uploader
    origin={'manifestViewer'}
    storageLocationType={gEnums.storageLocationTypes.event}
    storageType={gEnums.storageTypes.manifest}
  />

  // eslint-disable-next-line 
  const manifestContent = () => files.map((file, index) => {
    if (preview) {
      return segGroup(manifestSizes[index], manImage(URL.createObjectURL(file), index))
    } else {
      const { fileUrl } = file
      if (fileUrl) {
        return segGroup(manifestSizes[index], manImage(fileUrl, index))
      }
    }
  })

  const content = () => <React.Fragment>
    {uploader()}
    {files && manifestContent()}
  </React.Fragment>

  return content()

}

export default ManifestViewer