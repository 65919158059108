import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { appUsersHandlers, appUsersReducer, appUsersReducerInitialState } from '../reducers/AppUsersReducer';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';

/** 
@provides appUser_state
@provides appUser_handlers 
*/
export const AppUsersContext = createContext();

const AppUsersProvider = (props) => {

  // parentContext not available  

  // papsContext
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext ?? {}
  const { pathViews } = paps_state ?? {}

  // papsContext
  const pageContext = useContext(PageContext)
  const { page_state } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings } = pageSettings ?? {}

  // local state
  const initState = appUsersReducerInitialState({ pathViews })
  const [appUsers_state, appUsers_dispatch] = useReducer(appUsersReducer, initState);
  const appUsers_handlers = appUsersHandlers(appUsers_dispatch, appUsers_state)

  // get the profile for the appUser
  // useEffect(() => {
  //   const { appUserCollections } = aps_appUserSettings ?? {}
  //   appUsers_handlers.handleInit_appUsers(appUserCollections)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const provider = () => {
    return <AppUsersContext.Provider value={{
      appUsers_state,
      appUsers_handlers,
    }} >
      {props.children}
    </AppUsersContext.Provider >
  }

  return provider()
}

export default AppUsersProvider