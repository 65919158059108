import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { elementHandlers, elementInitialState, elementReducer } from '../reducers/ElementReducer';
import { ParentContext } from './ParentContext';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';

export const ElementContext = createContext();

const ElementProvider = (props) => {

  const { cn, elemProps, itemData } = props ?? {}
  const { _itemKey } = itemData ?? {}

  const parentContext = useContext(ParentContext);
  const { states, settings } = parentContext ?? {}
  const { appUser_state, page_state, paps_state, storage_state } = states ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { clientOptions, eventOptions, display: display_global } = aps_global ?? {}
  const { autoLinkPdfs, showAutoLinkIcons } = display_global ?? {}
  const _appOptions = { ...clientOptions, ...eventOptions }
  let { weatherZip } = _appOptions ?? {}

  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { isAdminOrSuper } = appUserAccess ?? {}

  const { platform, viewKey } = paps_state ?? {}
  const { isMobileDevice } = platform ?? {}

  const { homeSettings } = settings
  const { global: global_home } = homeSettings ?? {}
  const { appUrls: homeAppUrls } = global_home ?? {}

  const { globalFiles, galleries } = storage_state ?? {}

  const { pdf: global_pdf } = globalFiles ?? {}
  const { pdf: gallery_pdf } = galleries ?? {}

  const opts = { isAdminOrSuper, viewKey, autoLinkPdfs, showAutoLinkIcons, global_pdf, gallery_pdf, isMobileDevice, homeAppUrls, weatherZip }

  // isMobileDevice, homeAppUrls, weatherZip
  const init_state = { cn, elemProps, itemData, opts }
  const [element_state, element_dispatch] = useReducer(elementReducer, elementInitialState(init_state));
  const element_handlers = elementHandlers(element_dispatch, element_state)

  useEffect(() => {
    if (itemData) {
      element_handlers.handleInit_element(cn, elemProps, itemData, opts)
    }
  }, [_itemKey, elemProps, itemData]);

  const elementProvider = () => <ElementContext.Provider value={{ element_state, element_handlers }}>
    {props.children}
  </ElementContext.Provider>

  return elementProvider()
};

export default ElementProvider 