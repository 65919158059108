import React, { useContext, useEffect, useReducer } from "react";
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { sportsDistrictHandlers, sportsDistrictInitialState, sportsDistrictReducer } from "../reducers/SportsDistrictReducer";
import { FirestoreContext } from "../../../../global/cnr/contexts/FirestoreContext";
import { _cacheNames, _cacheTypes } from "../../../../global/cnr/reducers/ServiceWorkerReducer";

export const SportsDistrictContext = React.createContext()

export default function SportsDistrictProvider(props) {

  const { directReturn } = props ?? {}

  const parentContext = useContext(ParentContext)
  const { states, handlers, fns } = parentContext ?? {}
  const { appUser_state, paps_state, page_state, serviceWorker_state, database_state } = states ?? {}
  const { appUser_handlers, serviceWorker_handlers } = handlers ?? {}
  const { database_fns } = fns ?? {}

  const { cache_info } = serviceWorker_state ?? {}
  const { cache_groups } = cache_info ?? {}
  const { cache_district } = cache_groups ?? {}

  // appUserContext
  const { appUser } = appUser_state ?? {}
  const { uid } = appUser ?? {}

  // papsContext 
  const { view, pathViews, viewKey } = paps_state ?? {}
  const { districts: districts_key } = pathViews ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_page, aps_global } = pageSettings ?? {}
  const { sportPermissions } = aps_global ?? {}

  const { viewItems: viewItems_page } = aps_page ?? {}

  let viewItem = viewItems_page && viewItems_page[view] ? viewItems_page[view] : {}
  const { dataSource } = viewItem ?? {}
  const { dataParents, useSeasonals } = dataSource ?? {}

  const _cacheKey = _cacheNames.cache_district + '-' + districts_key
  const _cacheData = cache_district && cache_district[districts_key]

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext)
  const { firestore_fns } = firestoreContext ?? {}

  let ownerPath;

  if (pathViews) {
    if (pathViews.schools) { ownerPath = 'schools' }
    if (pathViews.districts) { ownerPath = 'districts' }
    if (pathViews.organizations) { ownerPath = 'organizations' }
  }

  const init_state = {
    sportPermissions,
    firestore_fns,
    pathViews,
    viewItem,
    dataParents,
    useSeasonals,
    uid,
    ownerPath,
    inverted: false,
    cssOpts: { name: 'ssc-normal' },
    _cacheData,
    database_fns,
  }

  const [sportsDistrict_state, sportsDistrict_dispatch] = useReducer(sportsDistrictReducer, init_state, sportsDistrictInitialState)
  const sportsDistrict_handlers = sportsDistrictHandlers(sportsDistrict_dispatch, sportsDistrict_state)
  const { sports, district_data } = sportsDistrict_state ?? {}

  useEffect(() => {
    if (pathViews.districts) {
      sportsDistrict_handlers.handleInit_sportsDistrict(pathViews.districts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews.districts])

  useEffect(() => {
    if (sports) {
      appUser_handlers.handleAmmend_favs(sports)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sports])

  useEffect(() => {
    if (district_data && !_cacheData) {
      const settings = {
        cacheKey: _cacheKey,
        cacheName: _cacheTypes.district_data,
        cacheData: district_data,
      }
      serviceWorker_handlers.handleSend_messageToServiceWorker(settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [district_data])

  if (sportsDistrict_state) {
    if (directReturn) {
      return { sportsDistrict_handlers, sportsDistrict_state }
    } else {
      return <SportsDistrictContext.Provider
        value={{ sportsDistrict_state, sportsDistrict_handlers, appComponent: 'sportsDistrict' }}>
        {props.children}
      </SportsDistrictContext.Provider>
    }
  } else {
    return <SportsDistrictContext.Provider
      value={{}}>
      {props.children}
    </SportsDistrictContext.Provider>
  }
}