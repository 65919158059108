import React from 'react';
import { Grid } from 'semantic-ui-react';
import { dateTimeSplits, formatItem, formatTypes } from '../../common/dateFormatting';
import { gEnums } from '../../enums/globalEnums';
import SemCards from './SemCards';

const widths = {
  left: 3,
  right: 13,
  full: 16
}

const SemSchedule = (props) => {

  const { groupType, card_props, groupDataProps } = props
  const { groups } = groupDataProps ?? {}

  if (card_props) {
    card_props.scheduleType = 'list'
  }

  const dateDiv = (df) => {
    switch (groupType) {
      case gEnums.groupTypes.scheduleTop:
        return <div>{df}</div>
      default:
        return <div className={'dater'}>
          <div>{df.dow}{' '}{df.day}</div>
        </div>
    }
  }

  const gridColumn = (data, df, width) => <Grid.Column width={width}>
    {df && dateDiv(df)}
    <SemCards cardProps={card_props} viewListData={data} />
  </Grid.Column>

  let columns = 2

  switch (groupType) {
    case gEnums.groupTypes.scheduleTop:
      columns = 1
      break;
    default:
    // nothing
  }

  const gridRow = (group, index) => {
    const { key: rowDate, data } = group
    switch (groupType) {
      case gEnums.groupTypes.scheduleTop:
        const _date = new Date(rowDate)
        const _dateFormatted = formatItem(formatTypes.shortDate, _date)
        return <Grid.Row stretched>
          {gridColumn(data, _dateFormatted, widths.full)}
        </Grid.Row>
      default:
        let df = dateTimeSplits(rowDate)
        return <Grid.Row stretched>
          <Grid.Column width={widths.left}>
            {dateDiv(df)}
          </Grid.Column>
          {gridColumn(data, null, widths.right)}
        </Grid.Row>
    }
  }

  const gridRows = () => groups.map((group, index) => gridRow(group, index))
  const schedule = () => <Grid className={'schedule-grid'} columns={columns} divided>{gridRows()}</Grid>

  // return <StickyExample />

  return schedule()

}

export default SemSchedule 