import { callFunction } from "./fbAll";

const ticketingGenericTypes = {
  ticketingGeneric: 'ticketingGeneric',
}

const ticketingFunctionTypes = {
  updateTicket_assignments: 'updateTicket_assignments',
  updateTicket_me: 'updateTicket_me',
  updateTicketItem: 'updateTicketItem',
}

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(ticketingGenericTypes.ticketingGeneric, callData)
  return data
}

export const fsfn_updateWillCallTicket = async (state, action, appTicketing) => {

  const { useMeWillCallUpdateFunction } = appTicketing

  const { scannedInfo, pathViews } = state
  const { ticketUserKey } = scannedInfo ?? {}
  const { ticketKey, subTicketKey, registrationId, credit } = action

  const _registrationId = registrationId ? registrationId : ticketUserKey

  let fbf;

  if (useMeWillCallUpdateFunction) {
    fbf = ticketingFunctionTypes.updateTicket_me
  } else {
    fbf = ticketingFunctionTypes.updateTicketItem
  }

  const callData = { ticketingFunctionType: fbf, ticketKey, subTicketKey, registrationId: _registrationId, direction: credit ? 'exit' : 'enter', clientKey: null, eventKey: null }
  callData.clientKey = pathViews.clients ? pathViews.clients : null
  callData.eventKey = pathViews.events ? pathViews.events : null
  const response_data = await callFsFunction(callData) // OK
  return response_data
}

export const fsfn_updateTicketAssignments = async (pathViews, assignedTickets) => {

  const callData = {
    eventKey: pathViews.events,
    clientKey: pathViews.clients,
    assignedTickets,
    ticketingFunctionType: ticketingFunctionTypes.updateTicket_assignments,
  }
  const response_data = await callFsFunction(callData) // OK
  return response_data

}