import { getDownloadURL, getMetadata, listAll, ref } from 'firebase/storage';
import _ from 'lodash';
import { gEnums } from '../enums/globalEnums';
import { createRefPath, createRefPath_client, createRefPath_event } from '../firestoreData/appData/appRefPaths';
import { _storageSettings, getFirebaseStorage } from './storageHelpers';

/**
 * Gets the storage files from the galleryPath
 * @param {object} pathViews 
 * @param {object} action 
 * @param {function} callback 
 */
export const get_documentStorageItems = async (pathViews, action, callback) => {
  const galleryItems = {}
  const urlRef = createRefPath_event(pathViews, [action.galleryPath, action.key_viewItem])
  get_storageItems({ callback, slt: gEnums.storageLocationTypes.pageGallery, galleryPath: action.galleryPath, viewItemKey: action.key_viewItem, urlRef, galleryItems })
}

/**
 * Gets the storage files from the _gallery_page
 * @param {object} pathViews 
 * @param {object} action 
 * @param {function} callback 
 */
export const getPdfs = async (pathViews, sp, callback, slt) => {
  const { view: viewItemKey, viewKey } = sp
  const galleryPath = _storageSettings.galleryPaths.pagePdf
  const urlRef = createRefPath_event(pathViews, [galleryPath, viewItemKey, viewKey])
  const galleryItems = {}
  get_storageItems({ callback, slt, galleryPath, viewItemKey, viewKey, urlRef, galleryItems, isPdf: true })
}

/**
 * Gets the storage files from `_gallery_event`
 * @param {object} pathViews 
 * @param {function} callback 
 * @param {object} slt 
 */
export const getGallery_event = async (pathViews, callback, slt) => {
  const galleryPath = _storageSettings.galleryPaths.galleryEvent
  const urlRef = createRefPath_event(pathViews, [galleryPath])
  const galleryItems = {}
  get_storageItems({ callback, slt, galleryPath, urlRef, galleryItems })
}

/**
 * Gets the storage files from `_gallery_page`
 * @param {object} pathViews 
 * @param {object} action 
 * @param {function} callback 
 */
export const getGallery_page = async (pathViews, sp, callback, slt) => {
  const { view: viewItemKey, viewKey } = sp
  const galleryPath = _storageSettings.galleryPaths.galleryPage
  const urlRef = createRefPath_event(pathViews, [galleryPath, viewItemKey, viewKey])
  const galleryItems = {}
  get_storageItems({ callback, slt, galleryPath, viewItemKey, viewKey, urlRef, galleryItems })
}

/**
 * Gets the storage files from `profiles` in the client folder
 * if `email` is passed in, `profiles/email`
 * @param {object} pathViews 
 * @param {string} appUserCollection 
 * @param {string} email 
 * @param {function} callback 
 * @param {object} slt 
 */
export const getGallery_clientProfiles = async (pathViews, appUserCollection, email, callback, slt, emails) => {
  const galleryPath = _storageSettings.galleryPaths.profiles
  const urlRef = email ? createRefPath_client(pathViews, [galleryPath, email]) : createRefPath_client(pathViews, [galleryPath])
  const galleryItems = {}
  get_storageItems({ callback, slt, galleryPath, urlRef, galleryItems, appUserCollection, emails })
}

export const getGallery_clientFolder = async (pathViews, itemKey, callback, slt) => {
  const galleryPath = itemKey
  const urlRef = createRefPath_client(pathViews, [itemKey])
  const galleryItems = {}
  get_storageItems({ callback, slt, galleryPath, urlRef, galleryItems, galleryKey: itemKey })
}

/**
 * _gallery_profile_all
 * @param {string} email 
 * @param {function} callback 
 * @param {object} slt 
 */
export const getGallery_appUserGallery = async (email, callback, slt) => {
  const galleryPath = _storageSettings.galleryPaths.profile_all
  const urlRef = createRefPath([galleryPath, email])
  const galleryItems = {}
  get_storageItems({ callback, slt, galleryPath, urlRef, galleryItems, email })
}

async function getPrefixedStorageItems(prefixes, pr_props, emails) {
  const promises = prefixes.map(folderRef => get_storageItems({ ...pr_props, urlRef: folderRef.fullPath, parentLength: prefixes.length, emails }));
  const res = await Promise.all(promises);
  return res
}

const get_storageItems = async (props) => {

  const { callback, slt, galleryPath, viewItemKey, viewKey, email, urlRef, galleryItems, parentLength, appUserCollection, galleryKey, isPrefixed, isPdf, emails } = props
  const cbProps = { slt, galleryPath, viewItemKey, viewKey, email, appUserCollection, galleryKey }

  const storage = getFirebaseStorage();
  const listRef = ref(storage, urlRef);
  const { name } = listRef

  const rez = await listAll(listRef)
  const { prefixes, items } = rez

  const _prefixNames = []

  _.forEach(prefixes, (prefix) => {
    _prefixNames.push(prefix.name)
  })

  const _prefixes = prefixes && emails ? _.filter(prefixes, (prefix) => {
    return _.includes(emails, prefix.name);
  }) : prefixes;

  // if (_prefixNames.length > 0) {
  //   console.log('_prefixNames', _prefixNames)
  //   console.log('emails', emails)
  // }

  const _callback = async (name, result, items, noPrefix, _galleryKey) => {

    switch (slt) {
      case gEnums.storageLocationTypes.eventGallery:
        if (result && result.length > 0) {
          ammendGalleryItemsList(galleryItems, result, items)
          await getTheMetadata(slt, galleryItems, storage)
          callback({ ...cbProps, galleryItems })
        } else {
          callback({ ...cbProps, galleryItems: {} })
        }
        break;

      case gEnums.storageLocationTypes.pagePdf:
        if (result && result.length > 0) {
          ammendPdsGalleryItems(galleryItems, result, items)
          await getTheMetadata(slt, galleryItems, storage)
          callback({ ...cbProps, galleryItems })
        } else {
          callback({ ...cbProps, galleryItems: {} })
        }
        break;

      default:
        ammendGalleryItems(galleryItems, name, result, items)

        if (parentLength === Object.keys(galleryItems).length) {
          await getTheMetadata(slt, galleryItems, storage)
          callback({ ...cbProps, galleryItems })
        }

        if (noPrefix) {
          callback({ ...cbProps, galleryItems })
        }
    }
  }

  if (isPdf) {
    const rez1 = await getItemDownloadUrlPromises(storage, items)
    _callback(name, rez1, items, !isPrefixed, galleryKey)
  } else {
    const pr_props = {
      appUserCollection,
      callback,
      email,
      galleryItems,
      galleryPath,
      isPrefixed: true,
      slt,
      viewItemKey,
      viewKey,
    }
    // prefixes are the folders
    if (_prefixes && _prefixes.length > 0) {
      await getPrefixedStorageItems(_prefixes, pr_props, emails)
    } else if (items && items.length > 0) {
      // get a promise for the urls for each of the items
      const result = await getItemDownloadUrlPromises(storage, items) //.then(result => {
      _callback(name, result, items, !isPrefixed, galleryKey)
    } else {
      callback({ ...cbProps, galleryItems: {} })
    }
  }
}

/**
 * 
 * @param {object} storage 
 * @param {array} items 
 * @returns a promise containing the urls for each of the storage items
 */
const getItemDownloadUrlPromises = async (storage, items) => {
  const promises = []
  items.forEach((itemRef) => {
    const { fullPath } = itemRef
    const _ref = ref(storage, fullPath)
    promises.push(getDownloadURL(_ref))
  });
  return Promise.all(promises)
}

/**
 * Adds the urls(thumbnail, full) and path to the galleryItems[name]
 * @param {object} galleryItems 
 * @param {string} name 
 * @param {object} result 
 * @param {object} items 
 */
const ammendGalleryItems = (galleryItems, name, result, items) => {

  if (!galleryItems[name]) {
    galleryItems[name] = { path: null, urls: { full: null, thumbnail: null } }
  }

  result.forEach(url => {
    if (url.indexOf('-thumb') >= 0) {
      galleryItems[name].urls.thumbnail = url
    } else {
      galleryItems[name].urls.full = url
    }
  })

  items.forEach(item => {
    if (item.fullPath.indexOf('-thumb') < 0) {
      galleryItems[name].path = item.fullPath
    }
  })
}

const ammendGalleryItemsList = (galleryItems, result, items) => {

  // add a galleryItem for non-thumbs only
  items.forEach(item => {
    if (item.name.indexOf('-thumb') <= 0) {
      galleryItems[item.name] = {
        path: item.fullPath,
        urls: {
          full: null,
          thumbnail: null
        }
      }
    }

  })

  // now that we have a gallery item for each of the items, add the results
  Object.keys(galleryItems).forEach(giKey => {
    const gi = galleryItems[giKey]
    const r = getMatchingUrls(giKey, result)
    r.forEach(url => {
      if (url.indexOf('-thumb') >= 0) {
        gi.urls.thumbnail = url
      } else {
        gi.urls.full = url
      }
    })
  })
}

const getMatchingUrls = (giKey, result) => _.filter(result, i => i.indexOf(giKey) >= 0)

// ammends the pdfs
const ammendPdsGalleryItems = (galleryItems, result, items) => {

  items.forEach(item => {
    galleryItems[item.name] = {
      path: item.fullPath,
      urls: {
        full: null,
        thumbnail: null
      }
    }
  })

  // NBA%20Sales%20&%20Marketing%20Meeting%20Agenda%202024v8.pdf
  // NBA%20Sales%20%26%20Marketing%20Meeting%20Agenda%202024v8.pdf
  // "https://firebasestorage.googleapis.com/v0/b/me-mobile-4410b.appspot.com/o/clients%2FnplGL5xAI6N2uWX2TBsw%2Fevents%2FrLT40U6OTHiaCeYL0GCh%2F_page_pdf%2Fsessions%2FtmHTByeZvjDuspiIJQdE%2FNBA%20Sales%20%26%20Marketing%20Meeting%20Agenda%202024v8.pdf?alt=media&token=4daefe93-4e1d-4292-9af8-e926a9527cf4"

  Object.keys(galleryItems).forEach(giKey => {
    const gi = galleryItems[giKey]
    result.forEach(r => {
      const _giKey = giKey.replace(/\s/g, '%20')
      if (r.indexOf(_giKey) >= 0) {
        gi.urls.full = r
      }
    })
  })
}

async function getTheMetadata(slt, galleryItems, storage) {
  const promises = Object.keys(galleryItems).map(k => {
    const gi = galleryItems[k]
    const _ref = gi.path ? ref(storage, gi.path) : null
    return _ref ? getMetadata(_ref) : 'none'
  });
  const res = await Promise.all(promises);
  Object.keys(galleryItems).forEach((k, index) => {
    if (res && res[index]) {
      galleryItems[k].customMetadata = res[index].customMetadata ? res[index].customMetadata : 'none'
    }
  })
  return res
}

export const getGalleryMetadata_global = (gfs, storage, callback) => {

  const _callback = (gis) => {
    let missing = false
    Object.keys(gis).forEach(key => {
      const gi = gis[key]
      Object.keys(gi).forEach(kk => {
        if (!gi[kk].customMetadata && !gi[kk].noMetadata) {
          missing = true
        }
      })
    })
    if (!missing) {
      callback(gis)
    }
  }

  if (gfs) {
    Object.keys(gfs).forEach(key => {
      const galleryItems = gfs[key]
      if (galleryItems && Object.keys(galleryItems).length > 0) {
        Object.keys(galleryItems).forEach(k => {
          const gi = galleryItems[k]
          const { fullPaths } = gi ? gi : {}
          const { full } = fullPaths ? fullPaths : {}
          const _ref = full ? ref(storage, full) : null
          if (_ref) {
            getMetadata(_ref)
              .then((metadata) => {
                if (metadata && metadata.customMetadata) {
                  gi.customMetadata = metadata.customMetadata
                } else {
                  gi.noMetadata = true
                }
                _callback(gfs)
                // callback(galleryItems)
              })
              .catch((error) => {
                console.error(error);
                callback(gfs)
              });
          } else {
            callback(gfs)
          }
        })
      } else {
        _callback(gfs)
      }
    })
  }

}