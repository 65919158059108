import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Dropdown, Form, Icon, List } from 'semantic-ui-react'
import { uniqueKey } from '../common/keys'
import { removeArrayItem } from '../common/sorting'
import { FormItemContext } from '../components/forms/FormItem'
import { AppUserContext } from '../cnr/contexts/AppUserContext'

const ListAdd = (props) => {

  const { inverted, ddOpts, iconOpts, fip: fip_props, clickOpts } = props ?? {}
  const { name: ddName, options } = ddOpts ?? {}

  // appUserContext
  const appUserContext = useContext(AppUserContext)
  const { appUser_state } = appUserContext ?? {}
  const { accessClone } = appUser_state ?? {}
  const { name: cloneName } = accessClone ?? {}

  // formItemContext
  const formItemContext = useContext(FormItemContext)
  const { formItem_state } = formItemContext ?? {}
  const { fip } = formItem_state ?? {}
  const _fip = fip_props ? fip_props : fip
  const { label, onChange, value, parentItem } = _fip ?? {}

  const [list, setList] = useState()
  const [currentItem, setCurrentItem] = useState()

  useEffect(() => {
    setList(value ? value : [])
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [value]);

  const updateValue = (value) => {
    let _value = value
    if (_value && _value.length === 0) { _value = null }
    const x = {
      propname: _fip.propname,
      value: _value,
      parentItem
    }
    onChange(null, x)
  }

  const handleChange = (e, data) => {
    if (ddOpts) {
      setCurrentItem({ name: data.value, [ddName]: null })
    } else {
      setCurrentItem(data.value)
    }
  }

  const handleDdChange = (e, data) => {
    const { item } = data
    const _list = [...list]
    const _listKey = _.findKey(_list, { name: item.name })
    _list[_listKey][ddName] = data.value
    setList(_list)
    updateValue(_list)
  }

  const handleAddToList = () => {
    const _list = list ? [...list] : []
    if (ddOpts) {
      const _item = _.find(_list, { name: currentItem.name })
      if (!_item) {
        _list.push(currentItem)
        setList(_list)
        updateValue(_list)
      }
    } else {
      if (!_list.includes(currentItem)) {
        _list.push(currentItem)
        setList(_list)
        updateValue(_list)
      }
    }
    setCurrentItem()
  }

  const handleRemove = (item) => {
    const _list = list ? [...list] : []
    removeArrayItem(_list, item)
    setList(_list)
    updateValue(_list)
  }

  const inputValue = () => {
    if (ddOpts) {
      if (currentItem) {
        return currentItem.name
      } else {
        return ''
      }
    } else {
      return currentItem
    }
  }

  const input = () => <Form.Input
    fluid
    value={inputValue()}
    label={label}
    onChange={handleChange}
    action={{ disabled: !currentItem, color: 'blue', icon: 'check', onClick: () => { handleAddToList() } }}
  />

  const dropdown_options = (item) => {

    const value = item[ddName]

    const ddItems = []

    Object.keys(options).forEach(opt => {
      ddItems.push({
        key: opt,
        text: _.startCase(opt),
        value: opt,
      })
    })

    return <Dropdown
      key={uniqueKey('hdd', 'dd')}
      direction={'left'}
      options={ddItems}
      selection
      item={item}
      value={value}
      onChange={handleDdChange}
    />
  }

  const button_iconOption = (item) => <Button icon onClick={(e) => { iconOpts.oc(item) }}>
    <Icon name={iconOpts.name} color={cloneName === item.name ? 'blue' : 'grey'} />
  </Button>

  const button_remove = (item) => <Button icon onClick={(e) => { handleRemove(item) }}>
    <Icon name='delete' color={'red'} />
  </Button>

  const listItem = (item) => {
    if (ddOpts) {
      let cn = 'list-add-options'
      if (iconOpts) { cn += ' io' }
      return <div className={cn}>
        <div onClick={(e) => { clickOpts && clickOpts.oc && clickOpts.oc(item) }}>{item.name}</div>
        <div>
          {iconOpts && button_iconOption(item)}
          <div>{options && dropdown_options(item)}</div>
          <div>{button_remove(item)}</div>
        </div>
      </div>
    } else {
      return <div className='list-add'>
        <div>{item}</div>
        <div>{button_remove(item)}</div>
      </div>
    }
  }

  const listItems = () => list.map((item, index) => {
    return <List.Item key={uniqueKey('la', index)}   >
      <List.Content>
        {listItem(item)}
      </List.Content>
    </List.Item >
  })

  const content = () => <div >
    {input()}
    <List divided relaxed inverted={inverted} ordered>
      {list && _.isArray(list) && listItems()}
    </List></div>

  return content()

}

export default ListAdd