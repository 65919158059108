import _ from 'lodash';
import React, { useContext } from 'react';
import { Menu, Message } from 'semantic-ui-react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { SettingParentContext } from '../cnr/contexts/SettingParentContext';
import { ViewSettingsComponentContext } from '../cnr/contexts/ViewSettingsComponentContext';
import { ViewSettingsContext } from '../cnr/contexts/ViewSettingsContext';
import { ViewSettingsMenuContext } from '../cnr/contexts/ViewSettingsMenuContext';
import { creatingHelpers } from '../common/creating';
import { uniqueKey } from '../common/keys';
import { sortObject } from '../common/sorting';
import DragDropper, { dragDropperTypes } from '../dragNDrops/DragDropper';
import { gEnums } from '../enums/globalEnums';
import MenuItemSingle from './components/menus/MenuItemSingle';
import { allowShow, setUpdateProps, updateMenuItem } from './components/menus/menuItemHelpers';
import HeaderDropdown from './headers/HeaderDropdown';
import { _ignoreKeys } from '../common/dataFix';

/**
 * @calledFrom ViewSettingsMenuProvider ONLY
 * @returns a Menu (vertical) with menuItems
 * @description Contains all the viewSettings information.
 */
const ViewSettingsMenu = () => {

  // settingsParentContext
  const settingsParentContext = useContext(SettingParentContext)
  const { states, handlers, settings } = settingsParentContext ?? {}
  const { appUser_state, page_state, appSettings_state, framework_state } = states ?? {}
  const { appSettings_handlers } = handlers ?? {}
  const { homeSettings } = settings ?? {}
  const { global: global_home } = homeSettings ?? {}
  const { settingsOptions: settingsOptions_home } = global_home ?? {}
  const { helpOn, themeSelected } = appSettings_state ?? {}
  const { desktopMode } = framework_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_styles, aps_viewItems } = pageSettings ?? {}
  const { useDarkMode, showHelpLine, sortMenuItemsByPermission } = settingsOptions_home ?? {}
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  // viewSettingsSwipeContext - THIS has all the info
  const viewSettingsContext = useContext(ViewSettingsContext);
  const { viewSettings_state, viewSettings_handlers } = viewSettingsContext ?? {}
  const { settings_temp, isGv, showViewLinking } = viewSettings_state ?? {}
  const { display } = settings_temp ?? {}
  const { layoutType } = display ?? {}

  // viewSettingsComponentContext 
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext);
  const { vsc_state, vsc_handlers } = viewSettingsComponentContext ?? {}
  const { isGlobal, swipedItem, updateProps } = vsc_state ?? {}
  const { swipeToSelected } = vsc_handlers ?? {}

  // IMPORTANT: Settings - activeMenuItems are the menu item for this current menu    
  // viewSettingsMenuContext
  const viewSettingsMenuContext = useContext(ViewSettingsMenuContext);
  const { vsm_state } = viewSettingsMenuContext ? viewSettingsMenuContext : {}
  const { menuItemAllows, menuType, activeMenuItems, propsOnly, menuTypeKey, showSort, otherData, settingsMessage } = vsm_state ? vsm_state : {}

  const cn_menu = 'view-settings-menu msmi '
  const cn_portal = 'msmi four menu-groups menu'

  const clickTo = (item, allowClick) => {
    if (!allowClick) { return false }
    const { show, showDesktop } = item ?? {}
    const _show = desktopMode ? showDesktop : show
    if (allowShow(menuTypeKey)) { if (!_show) { return false } }
    const swipeProps = {
      swipedItem: item,
      menuType
    }
    // the sidebar is already open, add to it  
    swipeToSelected(swipeProps)
  }

  const themeSelectClick = (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    appSettings_handlers.handleThemeSelect(item.parentSettingKey)
  }

  /** handles the click of any of the list items in viewSettingsGrid */
  const clickCheck = (e, menuItem, allowClick, checkType, subProp) => {
    if (!allowClick) { return false }
    e.preventDefault()
    e.stopPropagation()
    switch (checkType) {
      case gEnums.menuItemOptionTypes.highlight:
        // nothing
        break;
      default:
        const { handleFormDataChange_settings } = viewSettings_handlers
        if (handleFormDataChange_settings) {
          const ups = setUpdateProps(updateProps, menuItem, menuType, subProp, checkType)
          handleFormDataChange_settings(null, ups)
        }
    }
  }

  /** handles the click of the option dd of the menuItem */
  const clickOption = (clickOptions, e) => {
    const { menuItem, allowClick, clickOptionType } = clickOptions
    if (!allowClick) { return false }
    e.preventDefault()
    // e.stopPropagation()  
    switch (clickOptionType) {
      default:
        const { handleFormDataChange_settings } = viewSettings_handlers
        if (handleFormDataChange_settings) {
          const ups = setUpdateProps(updateProps, menuItem, menuType, null, clickOptionType)
          // formData, updateProps
          handleFormDataChange_settings(null, ups)
        }
    }
  }

  // back from DragDropper 
  const handleSorted = (sortedGroups) => {
    if (sortedGroups) {
      const _sortedGroups = {}
      Object.keys(sortedGroups).forEach(key => {
        const dis = sortedGroups[key].dataItems
        _sortedGroups[key] = creatingHelpers.createObject(dis, 'key')
      })
      viewSettings_handlers.handleGroupSort(updateProps, _sortedGroups, menuType)
    }
  }

  let allowSort = false

  switch (menuType) {
    case gEnums.menuTypes.viewItem:
      allowSort = !isGlobal
      break;
    case gEnums.menuTypes.propItem:
    case gEnums.menuTypes.propSections:
      allowSort = true
      break;
    default:
    // nothing
  }

  const grProps = {
    aps_viewItems,
    clicks: { clickCheck, clickTo, clickOption },
    desktopMode,
    helpOn,
    isGlobal,
    isGv,
    menuType,
    showViewLinking,
    themeSelectClick,
    themeSelected,
  }

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.gridViewItemIcons] : {}
  const sac = { ...styleAndClass }

  const setAllowClick = (item) => {
    let allowClick = true
    if (!isGlobal) {
      switch (layoutType) {
        case gEnums.layoutTypes.icons:
          if (updateProps && updateProps.vit) {
            switch (item.key) {
              case 'auth':
              case 'display':
                break;
              default:
                allowClick = false
            }
          }
          break;
        default:
        // nothing
      }
    }
    return allowClick
  }

  // SORTING
  /**
   * 
   * @param {array} menuItems 
   * @returns the sorted list of menu items 
   */
  const getSortedMenuItems = (menuItems) => {
    switch (menuTypeKey) {
      case 'props':
      case 'propSections':
      case 'viewItems':
        return _.sortBy(menuItems, 'position')
      default:
        return _.sortBy(menuItems, 'caption')
    }
  }

  /**
   * 
   * @param {object} menuItem 
   * @param {string} key 
   * @returns a `MenuItemSingle`
   */
  const menuItemSingle = (menuItem, key) => {
    let allowClick = setAllowClick(menuItem)
    if (menuItem) { updateMenuItem(menuItem, menuType) }
    let { bc, c, restrict, settingsAuthLevel } = menuItem
    const saci = { className: sac.className }
    if (bc || c) { saci.style = {} }
    if (bc) {
      saci.className = saci.className.replace('bc-settings', '')
      saci.style.backgroundColor = bc
    }
    if (c) { saci.style.color = c }
    const x = { allowClick, menuItem, grProps, isGlobal, restrict, settingsAuthLevel, menuItemAllows, useDarkMode, showHelpLine }
    return <MenuItemSingle key={uniqueKey('vsm', 'mis', key)} {...x} />
  }

  /**
   * IMPORTANT - Settings: Menu Items
   * @param {array} menuItems 
   * @returns a List of all the view items
   */
  const menuMenuItems = (menuItems) => {
    const x = {}
    const menuItems_sorted = getSortedMenuItems(menuItems)
    // eslint-disable-next-line  
    const segs = Object.keys(menuItems_sorted).map((key, index) => {
      const menuItem = menuItems_sorted[key]
      const { key: key_mi, settingsAuthLevel, position } = menuItem ?? {}
      let allow = true
      if (settingsAuthLevel && (appUserAccess.accessLevel < settingsAuthLevel)) { allow = false }
      if (_ignoreKeys.includes(key_mi)) { allow = false }
      if (allow) {
        if (showSort) {
          x[key_mi] = {
            item: menuItem,
            position: position ? position : index,
            content: menuItemSingle(menuItem, key)
          }
        } else {
          return menuItemSingle(menuItem, key)
        }
      }
    })
    if (showSort) {
      return x
    } else {
      return segs
    }
  }

  const sectionProps = (otherData) => {
    // group the menuItems by propSection 
    const grps = _.groupBy(activeMenuItems, 'propSection')
    // sort the groups by position
    const od = sortObject(otherData, 'position')
    const xx = {}
    Object.keys(od).forEach(key => { xx[key] = grps[key] })
    return xx
  }

  /** DragDropper container will a header dropdown and dragable items */
  const groupContainerDd = (groups) => {

    const _dndGroups = {}

    Object.keys(groups).forEach(key => {
      const dataItems = groups[key] ? groups[key] : {}
      const elements = menuMenuItems(dataItems)
      const hdd = <HeaderDropdown key={uniqueKey('vsm', 'gc', 'hdd')} dataHeaderType={gEnums.dataHeaderTypes.sectionHeader} clickOption={clickOption} opts={{ groupKey: key, iconSize: 'small' }} />
      _dndGroups[key] = { elements, dataItems, hdd }
    })

    return <DragDropper
      ddType={'viewItems'}
      dndType={dragDropperTypes.viewSettingMenu}
      dndGroups={_dndGroups}
      handleSorted={handleSorted}
      groupChangeKey={'propSection'}
      otherData={otherData}
      allowSort={true}
      portalCn={cn_portal}
    />
  }

  const menuMenuItems_sort = (groupKey, menuItems) => {
    const dndGroups = {
      [groupKey]: {
        dataItems: menuItems,
        elements: menuMenuItems(menuItems),
      }
    }

    return <DragDropper
      dndType={dragDropperTypes.viewSettingMenu}
      dndGroups={dndGroups}
      handleSorted={handleSorted}
      allowSort={true}
      portalCn={cn_portal}
    />
  }

  /**
   * 
   * @param {array} menuItems (caption, icon, key, pit, etc.)
   * @returns a group of `menu items` based on certain parameters 
   */
  const menuItemElements = (menuItems) => {
    if (!propsOnly) {
      const groupArray = []
      const menuItems_propSection = _.groupBy(menuItems, 'propSection')
      if (menuItems_propSection) {
        if (otherData) {
          const groups = sectionProps(otherData)
          return groupContainerDd(groups)
        } else {
          return groupContainerDd(menuItems_propSection)
        }
      }
      return groupArray
    } else {
      const groupKey = swipedItem && swipedItem.settingKey ? swipedItem.settingKey : swipedItem ? swipedItem.key : 'none'
      if (allowSort) {
        return menuMenuItems_sort(groupKey, menuItems)
      } else {
        // for basic pageItems
        return menuMenuItems(menuItems)
      }
    }
  }

  const parentMessage = () => <Message
    warning
    size='tiny'
    header='Settings'
    content={settingsMessage}
  />

  /**
   * 
   * @param {object} groupedMenuItem 
   * @param {string} cnsmi 
   * @returns a `Menu`
   */
  const menu = (groupedMenuItem, cnsmi, key_settingsAuthLevel) => <Menu key={uniqueKey('vsm', 'grp', key_settingsAuthLevel)} borderless inverted={useDarkMode} vertical fluid className={cn_menu + cnsmi}>
    {groupedMenuItem && menuItemElements(groupedMenuItem)}
  </Menu>

  const content = () => {

    let cnsmi = (menuItemAllows.useAllow || menuItemAllows.color) ? 'msmi menu-groups four' : 'msmi menu-groups three'

    if (sortMenuItemsByPermission || 1 === 1) {
      cnsmi += ' padd0 mar0'

      const groupedMenuItems = _.groupBy(activeMenuItems, 'settingsAuthLevel')
      const menus = []

      // the `undefined` items do NOT have a `settingsAuthLevel`. This is NOT an error
      if (groupedMenuItems.undefined) { menus.push(menu(groupedMenuItems.undefined, cnsmi)) }

      Object.keys(groupedMenuItems).forEach(key_settingsAuthLevel => {
        switch (key_settingsAuthLevel) {
          case 'undefined':
            break;
          default:
            const groupedMenuItem = groupedMenuItems[key_settingsAuthLevel]
            menus.push(menu(groupedMenuItem, cnsmi, key_settingsAuthLevel))
            break;
        }
      })

      return <React.Fragment>
        {settingsMessage && parentMessage()}
        {menus}
      </React.Fragment>
    } else {
      return <React.Fragment>
        {settingsMessage && parentMessage()}
        <Menu borderless inverted={useDarkMode} vertical fluid className={cn_menu + cnsmi}>
          {activeMenuItems && menuItemElements(activeMenuItems)}
        </Menu>
      </React.Fragment>
    }

  }

  if (activeMenuItems) {
    return content(activeMenuItems)
  } else {
    return <div></div>
  }
}

export default ViewSettingsMenu