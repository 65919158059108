import React from 'react';
import { uniqueKey } from '../../common/keys';
import { addToClass, getSectionClass, getSectionThemeColors } from '../../styles/formatting';
import { gEnums } from '../../enums/globalEnums';
import { sectionMerge } from '../../viewSettings/helpers/sectionMerge';
import { Label, Segment, Icon } from 'semantic-ui-react';

/** Creates a section div for a single section  */

export const groupedSections = (groupedElements, semSecProps) => {
  const { nameElements, metaElements } = groupedElements
  const { snc } = getStylesAndProps('name', semSecProps)
  return <div key={uniqueKey('crd', 'cs')} {...snc} >
    {nameElements && <div className='header'>
      {nameElements}
    </div>}
    {metaElements && <div className='meta'>
      {metaElements}
    </div>}
  </div >
}

/**
 * 
 * @param {object} sections 
 * @param {object} semSecProps 
 * @param {object} groupedElements 
 * @returns a div with the section elements
 */
export const cardSections = (sections, semSecProps) => Object.keys(sections).map((sectionKey, index) => {

  switch (sectionKey) {
    case 'name':
      break;
    default:
      const { snc, isNameSection, isPlus } = getStylesAndProps(sectionKey, semSecProps)
      const section = sections[sectionKey]
      const { items, noContent } = section
      if (isNameSection && isPlus) {
        // nothing
        return null
      } else {
        if (noContent) {
          return items.map(item => (item))
        } else {
          // if (propOverrideOn) {
          //   return <Segment basic className='prop-override-section'>
          //     <Label attached='top right'><Icon name='check' />{'Show'}</Label>
          //     <div key={uniqueKey('crd', 'cs', index)} {...snc} >
          //       {items.map(item => { return item })}
          //     </div >
          //   </Segment>
          // } else {
          return <div key={uniqueKey('crd', 'cs', index)} {...snc} >
            {items.map(item => { return item })}
          </div >
          // }

        }
      }
  }
})

const getStylesAndProps = (sectionKey, semSecProps) => {

  const { aps_global, cardDisplayType, propSections, showQrCode } = semSecProps

  const propSection = propSections ? propSections[sectionKey] : {}
  const isNameSection = propSection && propSection.key === gEnums.sectionHeaderNames.name

  const { display: display_global } = aps_global ?? {}
  const { propsToSeparate: propsToSeparate_global } = display_global ?? {}

  const { sectionHeader, sectionDisplay } = sectionMerge(aps_global, propSection)
  const { sectionDisplayType } = sectionDisplay ?? {}

  let isPlus;

  switch (cardDisplayType) {
    case gEnums.cardDisplayTypes.profilePlus:
    case gEnums.cardDisplayTypes.schedulePlus:
      isPlus = true
      break;
    default:
    // nothing
  }

  let cn = 'sec-container'
  cn = addToClass(cn, isNameSection ? 'header' : 'sub-h ddd')
  cn = addToClass(cn, showQrCode ? 'qrc' : null)
  cn = addToClass(cn, sectionDisplayType ? sectionDisplayType : null)

  if (propsToSeparate_global) {
    if (propsToSeparate_global.includes(sectionKey)) {
      cn = addToClass(cn, 'separate')
    }
  }

  const snc = {
    style: isNameSection ? getSectionThemeColors(aps_global, sectionHeader) : {},
    className: isNameSection ? getSectionClass(cn, sectionHeader) : cn += ' ' + sectionHeader.headerDisplayType
  }

  return { snc, isNameSection, isPlus }

}