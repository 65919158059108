import { isPossiblePhoneNumber } from 'libphonenumber-js/min';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { List } from 'semantic-ui-react';
import { validateEmail } from '../../../global/validation/validation';
import { uniqueKey } from '../../../global/common/keys';
import NoData from '../../../global/components/alerts/NoData';
import { fsfn_auth } from '../../../global/functions/fbAuth';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper';
import { getTrueLevel } from '../../sports/helpers/league';
import { TicketingContext } from '../cnr/contexts/TicketingContext';

const limit = 100

export const ticketStatusTypes = {
  assignedTo: 'assignedTo',
  entered: 'entered'
}

export const FullTicketStatus = (props) => {

  const { ticketStatus, handleCancel } = props

  const ticketingContext = useContext(TicketingContext)
  const { ticketing_state } = ticketingContext ?? {}
  const { _staticAppUsers, appTicketing } = ticketing_state ?? {}
  const { enteredCaption, remainingCaption } = appTicketing ?? {}

  const captions = {
    entered: enteredCaption ? enteredCaption : 'Entered',
    remaining: remainingCaption ? remainingCaption : 'Remaining'
  }

  const { ticket, ticketType } = ticketStatus
  const { userTickets } = ticket ?? {}

  const [listType] = useState(ticketType)
  const [currentTicketStatus, setCurrentTicketStatus] = useState({ assignedTo: [], set: false })

  const [authsFound, setAuthsFound] = useState()

  useEffect(() => {
    if (userTickets) {
      const status = {
        assignedTo: [],
        assignedToGroup: null,
        entered: [],
        remaining: [],
        set: false
      }
      try {
        Object.keys(userTickets).forEach(key => {
          const userTicket = userTickets[key]
          const { subTickets } = userTicket ?? {}
          if (subTickets) {
            subTickets.forEach(st => {
              const { assignedTo, entered } = st
              if (_staticAppUsers && _staticAppUsers[key]) {
                st.name = _staticAppUsers[key].firstName + ' ' + _staticAppUsers[key].lastName
              }
              st.listName = st.assignedTo ? st.assignedTo : st.name
              if (assignedTo && !status.assignedTo.includes(assignedTo)) { status.assignedTo.push(st) }
              if (entered) { status.entered.push(st) }
              if (!entered) { status.remaining.push(st) }
            })
          }
        })
      } catch (error) {
        console.error(error)
      }
      status.set = getTrueLevel
      status.assignedToGroup = _.groupBy(status.assignedTo, 'listName')
      setCurrentTicketStatus(status)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [userTickets]);

  // run after we have currentTicketStatus
  useEffect(() => {
    if (currentTicketStatus && currentTicketStatus.set) {
      const { assignedToGroup } = currentTicketStatus
      try {
        if (assignedToGroup) {
          const _assignedTo = []
          try {
            Object.keys(assignedToGroup).forEach(key => {
              const item = {}
              if (validateEmail(key)) { item.email = key }
              if (isPossiblePhoneNumber(key)) { item.phoneNumber = key }
              _assignedTo.push(item)
            })
            const chunks = _.chunk(_assignedTo, limit);
            fsfn_auth.getAuthsByChunkEmail(chunks, null, false, 4, setAuthsFound)
          } catch (error) {
            console.error(error)
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentTicketStatus]);

  const listItem = (listName, ticketKey, subTicketIndex) => <List.Item>
    <List.Content>
      <List.Header>{listName}</List.Header>
      {ticketKey && subTicketIndex && <List.Description>{ticketKey}{'-'}{subTicketIndex}</List.Description>}
    </List.Content>
  </List.Item>

  const subTicketsList = (key, items) => {
    const _items = _.orderBy(items, ['listName'])
    const listItems = _items ? _items.map(item => {
      const { listName, ticketKey, subTicketIndex } = item
      return listItem(listName, ticketKey, subTicketIndex)
    }) : []

    return <List key={uniqueKey('fts', 'li', key)} divided relaxed ordered>
      {listItems}
    </List>
  }

  const subTicketsContainer = () => {
    const { entered, remaining } = currentTicketStatus
    return <React.Fragment>
      <div className={'sub-tickets-container'}>
        <div>{captions.remaining}</div>
        <div>{captions.entered}</div>
      </div>
      <div className={'sub-tickets-container'}>
        {subTicketsList('remaining', remaining)}
        {subTicketsList('entered', entered)}
      </div>
    </React.Fragment>
  }

  const assignedToList = () => {
    const { assignedToGroup } = currentTicketStatus
    const items = []
    if (assignedToGroup) {
      try {
        Object.keys(assignedToGroup).sort()
        Object.keys(assignedToGroup).forEach(atgKey => {
          const count = assignedToGroup[atgKey].length
          let _found;
          if (authsFound) {
            _found = _.find(authsFound, function (af) {
              if (af.email === atgKey || af.phoneNumber === atgKey) { return true; }
            });
          }
          items.push(<List.Item>
            <List.Icon name={atgKey ? 'arrow right' : 'user'} color={_found || !atgKey ? 'green' : 'red'} verticalAlign='middle' />
            <List.Content>
              <List.Header>{atgKey}{': '}{count}</List.Header>
            </List.Content>
          </List.Item>)
        })
      } catch (error) {
        console.error(error)
      }
      if (items && items.length > 0) {
        return <List ordered divided relaxed>
          {items}
        </List>
      } else {
        return <NoData altCaption={'Items'} />
      }
    }
  }

  const list = () => {
    switch (listType) {
      case ticketStatusTypes.entered:
        return subTicketsContainer()
      default:
        return assignedToList()
    }
  }

  const ticketStatusWrapper = () => <Wrapper
    content={list()}
    wrapperType={wrapperTypes.padded}
  />

  const stepWizardTicketStatus = () => <FullPageWrapper
    content={ticketStatusWrapper()}
    handleCancel={handleCancel}
    topperCaption={'Ticket Status'}
    topperCaption2={_.startCase(listType)}
    isBack={true}
  />

  return stepWizardTicketStatus()

}
