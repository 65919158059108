import React, { createContext, useContext, useReducer } from 'react';
import { _showStates } from '../../../App';
import { validationHandlers, validationInitialState, validationReducer } from '../reducers/ValidationReducer';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';

export const ValidationContext = createContext();
/** Top level provider the App
@componentContexts [StartContext]
@provides validation_state, validation_handlers 
@description Returns the Auth object for the App. Will return a `PageDimmer` until the appUser && appUser.profileValidated is set  
*/
const ValidationProvider = (props) => {

  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext ?? {}

  const pageContext = useContext(PageContext)
  const { page_state } = pageContext ?? {}

  const { pathViews, sharePath } = paps_state ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { appSignIn, signInEmail } = aps_global ?? {}

  // ValidationReducer
  const initState = validationInitialState({ validationValidated: false, pathViews, sharePath, appSignIn, signInEmail })
  const [validation_state, validation_dispatch] = useReducer(validationReducer, initState);
  const validation_handlers = validationHandlers(validation_dispatch, validation_state)

  if (_showStates.any && _showStates.validation) {
    console.log(Object.keys(validation_state))
    console.log(Object.keys(validation_handlers))
  }

  const provider = () => <ValidationContext.Provider
    value={{
      validation_handlers,
      validation_state,
    }} >
    {props.children}
  </ValidationContext.Provider >

  return provider()

}

export default ValidationProvider

