import { fsfn_updateDataCollection } from '../../../functions/fbUpdate';
import { dispatchProps } from '../../../cnr/reducers/reducerHelpers/dispatchProps';
import { createRefPath_event } from '../../appData/appRefPaths';
import { dataFix } from '../../../common/dataFix';

/**
 * Updates a collection with the updated data items only
 * @param {object} props (paps_state, viewItem, dataList, dataRestrictions, dispatch)
 * @returns the result
 * @description This is a firebase update function
 */
export const fsUpdate_updateExistingCollection = async (props) => {

  const { pathViews, collectionName, dataList, dataRestrictions, dispatch, restrict, updateOnly, clearCollection } = props ?? {}

  const _dataRef = createRefPath_event(pathViews, [collectionName])

  if (restrict || (dataRestrictions && (dataRestrictions.all || dataRestrictions.updateExisting))) {
    console.log('_dataRef', _dataRef)
    console.log('dataList', dataList)
    dispatch && dispatch({ type: dispatchProps.successAlt, dispatch })
    return true
  }

  try {

    if (dataList) {
      Object.keys(dataList).forEach(key => {
        const item = dataList[key]
        dataFix.deleteUTCs(item)
      })
    }

    const count = dataList ? Object.keys(dataList).length : 0

    if (count > 50) {
      const dataLists = getDataLists(dataList)
      updateDataLists(dataLists, pathViews, collectionName, updateOnly, clearCollection, dispatch)
    } else {
      await fsfn_updateDataCollection(pathViews, collectionName, dataList, updateOnly, clearCollection, dispatch)
      dispatch && dispatch({ type: dispatchProps.success, dispatch })
    }
  } catch (error) {
    dispatch && dispatch({ type: dispatchProps.error, error, dispatch })
  }

}

const getDataLists = (dataList) => {

  const _dataLists = []
  let _dataList = {}
  let count = 0

  Object.keys(dataList).forEach(key => {
    count++
    _dataList[key] = dataList[key]
    if (count === 50) {
      _dataLists.push(_dataList)
      _dataList = {}
      count = 0
    }
  })

  if (Object.keys(_dataList).length > 0) {
    _dataLists.push(_dataList)
  }

  return _dataLists

}

const updateDataLists = (dataLists, pathViews, collectionName, updateOnly, clearCollection, dispatch) => {
  let count = 0;
  dataLists.forEach(async (dataList, index) => {
    const _clearCollection = clearCollection && (index === 0)
    console.log('updateDataLists > dataList', dataList)
    await fsfn_updateDataCollection(pathViews, collectionName, dataList, updateOnly, _clearCollection)
    count++
    if (count === dataLists.length) {
      dispatch && dispatch({ type: dispatchProps.success, dispatch })
    }
  })
} 