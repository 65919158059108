import _ from 'lodash';
import React from 'react';
import { Dropdown } from "semantic-ui-react";

const GenericDdi = (props) => {
  const { gdiProps } = props ?? {}
  const { key, iconName, onClick, clickOptions, opts } = gdiProps ?? {}
  const { color, actionResult, actionName, imageUrl, disabled } = opts ?? {}
  let { title } = opts ?? {}

  const { actionType } = clickOptions ?? {}
  if (!title && actionType) { title = _.startCase(actionType) }
  if (title && title.startsWith('Handle')) { title = title.replace('Handle', '') }
  const showAvatar = false
  if (imageUrl && showAvatar) {
    return <Dropdown.Item
      key={key}
      image={{ avatar: true, src: imageUrl }}
      text={title}
      disabled={disabled}
      onClick={(e) => { onClick && onClick(clickOptions, e, actionResult, actionName) }}
    />
  } else {
    return <Dropdown.Item
      key={key}
      icon={{ name: iconName, color: color ? color : 'blue' }}
      text={title}
      disabled={disabled}
      onClick={(e) => { onClick && onClick(clickOptions, e, actionResult, actionName) }}
    />
  }
}

export default GenericDdi