import { createAccount } from '../../auth/signInActions/createAccount';
import { sendPasswordResetEmail } from '../../auth/signInActions/resetPasswordEmail';
import { signInAppUser } from '../../auth/signInActions/signIn';
import { appUserPasscodeValidation_update, firebaseSignIn } from '../../auth/signInActions/signInMethods';
import { gEnums } from '../../enums/globalEnums';
import { getAppUserProfile } from '../../firestoreData/profiles/getProfile';
import { fsfn_auth } from '../../functions/fbAuth';
import { createVerificationCode, validateEmail } from '../../validation/validation';
import { sendPushToAdmin } from './reducerHelpers/adminHelpers';
import { grts, responseHandlers, responseReducers } from './reducerHelpers/dispatchProps';
import { ammendSignInHelp, getSignInResponse, signInResponseData, updateSignInResponse } from "./reducerHelpers/signInProps";

// https://support.google.com/firebase/answer/7000714?authuser=1
// https://www.edwardbeazer.com/sending-email-with-firebase-functions-and-sendgrid/

export const sirts = {
  handleCancel_signIn: 'handleCancel_signIn',
  handleChange_signInMethodType: 'handleChange_signInMethodType',
  handleChange_signInFormType: 'handleChange_signInFormType',
  handleChange_signInType: 'handleChange_signInType',
  handleChanges_signInData: 'handleChanges_signInData',
  handleCloseConfirmation: 'handleCloseConfirmation',
  handleCloseNotification: 'handleCloseNotification',
  handleConfirm_passwordChange: 'handleConfirm_passwordChange',
  handleCreate_account: 'handleCreate_account',
  handleCreate_passcode: 'handleCreate_passcode',
  handleGet_dbProfile: 'handleGet_dbProfile',
  handleResponse_createPasscode: 'handleResponse_createPasscode',
  handleSend_googleLink: 'handleSend_googleLink',
  handleSend_passwordResetEmail: 'handleSend_passwordResetEmail',
  handleSend_phoneCode: 'handleSend_phoneCode',
  handleSet_dbProfile: 'handleSet_dbProfile',
  handleSet_signInHelp: 'handleSet_signInHelp',
  handleSet_signInMethods: 'handleSet_signInMethods',
  handleShow_forgotPassword: 'handleShow_forgotPassword',
  handleShow_linkConfirmation: 'handleShow_linkConfirmation',
  handleShow_resetPassword: 'handleShow_resetPassword',
  handleSignInResponse: 'handleSignInResponse',
  handleTrigger_signIn: 'handleTrigger_signIn',
  ...grts
}

export const signInMethodTypes = {
  emailAndPassword: 'emailAndPassword',
  emailPassword: 'emailPassword',
  emailLink: 'emailLink',
  facebook: 'facebook',
  google: 'google',
  passcode: 'passcode',
  passcodeLink: 'passcodeLink',
  phone: 'phone',
  twitter: 'twitter',
}

export const signInComponentTypes = {
  emailLink: 'emailLink',
  emailReset: 'emailReset',
  tooManyAttempts: 'tooManyAttempts',
  confirmPasswordChange: 'confirmPasswordChange',
  resetPasswordConfirmation: 'resetPasswordConfirmation',
  forgotPassword: 'forgotPassword',
}

export const signInTypes = {
  signIn: 'signIn',
  createAccount: 'createAccount'
}

export const linkConfirmationTypes = {
  email: 'email',
  phone: 'phone',
}

export const signInHelpTypes = {
  createAccountHelp: 'createAccountHelp',
  signInEmailHelp: 'signInEmailHelp',
  signInEmailPasswordHelp: 'signInEmailPasswordHelp',
  signInPasscodeHelp: 'signInPasscodeHelp',
  signInPhoneHelp: 'signInPhoneHelp',
  signInGoogleHelp: 'signInGoogleHelp',
  forgotPasswordHelp: 'forgotPasswordHelp',
  globalPasswordHelp: 'globalPasswordHelp',
}

const resetState = () => {
  return {
    confirmationResult: null,
    creds: null,
    hasAuth: false,
    hasProfile: false,
    authData: null,
    profileData_signIn: null,
    profileData: null,
    signInComponentType: null,
    signInFormType: gEnums.signInFormTypes.signInEmail,
    signInResponse: null,
  }
}

export const signInReducer = (state, action) => {

  const { database_fns, baseProps, signInMethodType, signInEmail, profileData_signIn, creds, hasAuth, hasProfile, signInFormType, passwordConfirmation, adminNotifications } = state ?? {}
  const { pathViews, appSignIn, aps_appUserSettings, logging } = baseProps ?? {}
  const { appUserCollection, appUserCollections } = aps_appUserSettings ?? {}
  const { forceAuthValidation } = appSignIn ?? {}

  const { dispatch, type } = action

  const { handleSignInResponse, handleSet_dbProfile, handleResponse_createPasscode } = signInHandlers(dispatch)

  switch (type) {

    case sirts.handleSet_signInHelp:
      return {
        ...state,
        signInHelp: ammendSignInHelp(action.signInHelp)
      }

    case sirts.handleChanges_signInData:
      // updates the signInData on change of the inputs
      if (action && action.signInData && action.signInData.email) {
        action.signInData.email = action.signInData.email.toLowerCase()
      }

      return {
        ...state,
        updating: false,
        creds: action.signInData
      }

    case sirts.handleCancel_signIn:
      if (state.cancelMethod) { state.cancelMethod() }
      return { ...state }

    case sirts.handleChange_signInFormType:
      return { ...state, signInFormType: action.signInFormType }

    case sirts.handleChange_signInType:
      return { ...state, signInType: state.signInType === signInTypes.signIn ? signInTypes.createAccount : signInTypes.signIn }

    // loads the signInMethodTypes to the ui
    case sirts.handleSet_signInMethods:
      const items = []
      const { allowEmail, allowFacebook, allowGoogle, allowPasscode, allowPasscodeLink, allowPhone, allowTwitter, allowEmailLink } = action.appSignIn ?? {}
      if (allowEmail) { items.push({ name: signInMethodTypes.emailAndPassword, icon: 'mail', color: 'blue' }) }
      if (allowEmailLink) { items.push({ name: signInMethodTypes.emailLink, icon: 'send', color: 'blue' }) }
      if (allowFacebook) { items.push({ name: signInMethodTypes.facebook, icon: 'facebook', color: 'blue' }) }
      if (allowGoogle) { items.push({ name: signInMethodTypes.google, icon: 'google', color: 'blue' }) }
      if (allowPasscode) { items.push({ name: signInMethodTypes.passcode, icon: 'code', color: 'blue' }) }
      if (allowPasscodeLink) { items.push({ name: signInMethodTypes.passcodeLink, icon: 'code', color: 'blue' }) }
      if (allowPhone) { items.push({ name: signInMethodTypes.phone, icon: 'phone', color: 'blue' }) }
      if (allowTwitter) { items.push({ name: signInMethodTypes.twitter, icon: 'twitter', color: 'blue' }) }
      let sim;
      switch (items.length) {
        case 0:
          sim = signInMethodTypes.emailAndPassword
          break;
        case 1:
          sim = items[0]
          break;
        default:
          // nothing
          break;
      }
      return {
        ...state,
        signInMethods: items,
        signInMethod: sim,
        cancelMethod: action.cancelMethod,
        ...resetState()
      }

    case sirts.handleChange_signInMethodType:
      return {
        ...state,
        signInMethodType: action.mt,
        ...resetState()
      }

    // case sirts.handleReset:
    //   return {
    //     ...state,
    //     ...resetState()
    //   }

    case sirts.handleGet_dbProfile:
      // get the profile and auth information from firebase 

      if (creds.email) { creds.email = creds.email.toLowerCase().trim() }

      // check email 
      const _validEmail = validateEmail(creds.email)

      if (!_validEmail) {
        // bad email 
        const _sirProps = { signInResponseType: signInResponseTypes.invalidEmail }
        const rd = signInResponseData(_sirProps)
        handleSignInResponse(rd)
        return { ...state }
      } else {
        getAppUserProfile(database_fns, creds, pathViews, aps_appUserSettings, forceAuthValidation, logging, handleSet_dbProfile)
        return {
          ...state,
          updating: true,
          fetchingCaption: 'Searching',
          passwordConfirmation: false,
          signInResponse: null,
          hasAuth: false
        }
      }

    // handles the sign in.
    case sirts.handleTrigger_signIn:
      const siProps = { callback: handleSignInResponse, hasAuth, hasProfile, creds, pathViews, allowSignUp: false, appUserCollection, appSignIn, signInData: profileData_signIn, passwordConfirmation }
      signInAppUser(siProps)
      return { ...state, updating: true, fetchingCaption: 'Signing in', signInResponse: null }

    case sirts.handleSet_dbProfile:
      updateSignInResponse(action.profileData, action.authData, action, handleSignInResponse)
      return { ...state, profileData: action.profileData, authData: action.authData }

    case sirts.handleCreate_account:
      const caProps = { handleSignInResponse, creds, appSignIn }
      createAccount(caProps, pathViews, handleSignInResponse)
      return { ...state }

    case sirts.handleShow_linkConfirmation:
      return {
        ...state,
        signInComponentType: state.signInComponentType === signInComponentTypes.emailLink ? null : signInComponentTypes.emailLink,
        confirmationType: action.confirmationType
      }

    case sirts.handleCreate_passcode:
      const validationCode = createVerificationCode()
      const callback_update = (res) => firebaseSignIn.passcodeInLink(res, validationCode, creds, appSignIn, signInEmail, handleResponse_createPasscode)
      const callback_token = (res) => appUserPasscodeValidation_update(creds, res, validationCode, callback_update)
      fsfn_auth.createCustomToken(creds, callback_token)
      // const sms_data = { to: creds.phoneNumber, text: uuidv4() }
      // fsfn_sendSms(sms_data, null, handleSignInResponse)
      return { ...state }

    case sirts.handleResponse_createPasscode:
      // functions_handlers.handleFunction_auth(authFunctionTypes.createCustomToken, { creds, action, appSignIn })
      // sendPasscodeInLink(creds, action, appSignIn)
      return { ...state, updating: false }

    case sirts.handleSend_phoneCode:
      firebaseSignIn.phoneNumber(action.phoneNumber, action.recaptcha, handleSignInResponse)
      creds.phoneNumber = action.phoneNumber
      return { ...state, creds }

    case sirts.handleSend_passwordResetEmail:
      const { email: email_action } = action ?? {}
      const { email: email_creds } = creds ?? {}
      const _email = email_action ? email_action : email_creds
      if (_email) { sendPasswordResetEmail(_email, handleSignInResponse) }
      return { ...state }

    case sirts.handleSend_googleLink:
      firebaseSignIn.googleLink()
      return { ...state }

    case sirts.handleShow_forgotPassword:
      return { ...state, signInComponentType: state.signInComponentType === signInComponentTypes.forgotPassword ? null : signInComponentTypes.forgotPassword }

    case sirts.handleShow_resetPassword:
      return { ...state, show_resetPassword: !state.show_resetPassword }

    case sirts.handleCloseNotification:
      return {
        ...state,
        signInNotification: null,
      }

    case sirts.handleConfirm_passwordChange:
      const data = { email: creds.email, password: creds.password }
      let respp = {
        ...state,
        success: true,
        signInResponseType: signInResponseTypes.signInPending
      }

      fsfn_auth.createOrUpdateAuthAndSignIn(data).then(async res => {
        const { success, signInResponse } = res
        if (success === true) {
          respp = { success: true, signInResponseType: signInResponseTypes.signInSuccess }
        } else {
          if (signInResponseTypes[signInResponse]) {
            respp = { success: false, signInResponseType: signInResponse }
          } else {
            respp = { success: false, signInResponseType: signInResponseTypes.unknownError }
          }
        }
        handleSignInResponse(respp)
        return respp
      })

      return { ...state }

    // handles the response from the sign in method
    case sirts.handleSignInResponse:

      const { responseData } = action
      const {
        authProviders,
        confirmationResult,
        creds: creds_rd,
        profileData_signIn: rd_profileData_signIn,
        signInResponseType,
      } = responseData ?? {}

      const pd = rd_profileData_signIn ? rd_profileData_signIn : profileData_signIn
      const hp = pd ? true : false

      const _creds = creds ? creds : creds_rd

      const resp = {
        ...state,
        updating: false,
        hasProfile: hp,
        hasAuth: hasAuth ? hasAuth : responseData.hasAuth,
        passwordConfirmation: responseData.passwordConfirmation,
        profileData_signIn: rd_profileData_signIn ? rd_profileData_signIn : profileData_signIn,
        signInResponseType: signInResponseType,
        authProviders: authProviders ? authProviders : state.authProviders,
        confirmationResult: confirmationResult,
        confirmation_email: creds && creds.email
      }

      console.log('signInResponseType', signInResponseType)

      switch (signInResponseType) {
        case signInResponseTypes.emailResetSent:
          break;
        case signInResponseTypes.emailLinkSent:
          resp.signInComponentType = signInComponentTypes.emailLink
          break;
        case signInResponseTypes.phoneCodeSent:
          switch (signInMethodType) {
            case signInMethodTypes.phone:
              resp.signInFormType = gEnums.signInFormTypes.confirmation
              break;
            default:
              resp.signInFormType = gEnums.signInFormTypes.confirmation
          }
          break;
        case signInResponseTypes.phoneCodeNotSent:
          resp.signInFormType = gEnums.signInFormTypes.confirmation
          break;
        case signInResponseTypes.profileFound:
          switch (signInMethodType) {
            case signInMethodTypes.phone:
            case signInMethodTypes.phone:
            case signInMethodTypes.passcode:
            case signInMethodTypes.passcodeLink:
              resp.signInFormType = gEnums.signInFormTypes.signInPhoneNumber
              break;
            default:
              resp.signInFormType = gEnums.signInFormTypes.signInPassword
          }
          break;
        case signInResponseTypes.handlePasswordAlert:
          resp.signInComponentType = signInComponentTypes.confirmPasswordChange
          break;
        case signInResponseTypes.tooManyAttempts:
          sendPushToAdmin(state.profileData, creds, adminNotifications, responseData.signInResponseType)
          resp.signInComponentType = signInComponentTypes.tooManyAttempts
          break;
        case signInResponseTypes.signInSuccess:
          sendPushToAdmin(state.profileData, creds, adminNotifications, responseData.signInResponseType)
          resp.signInFormType = signInFormType
          break;
        case signInResponseTypes.signInError:
          sendPushToAdmin(state.profileData, creds, adminNotifications, responseData.signInResponseType)
          resp.signInFormType = signInFormType
          break;

        default:
          resp.signInFormType = signInFormType
        // nothing
      }

      if (responseData.passwordConfirmation) {
        resp.signInConfirmation = getSignInResponse(responseData, state, pd, appSignIn, _creds)
      } else {
        resp.signInResponse = getSignInResponse(responseData, state, pd, appSignIn, _creds)
      }

      return resp

    case sirts.handleCloseConfirmation:
      return { ...state, passwordConfirmation: null }

    // case sirts.handleCloseConfirmation:
    case sirts.handleFunctionResponse:
    case sirts.handleStartUpdate:
    case sirts.updateError:
    case sirts.updateSuccess:
    case sirts.updateSuccessAlt:
      return responseReducers(state, action)

    default:
      return {
        ...state,
      }
  }
}

export const signInReducerInitialState = (init_state) => {

  const { database_fns, functions_handlers, paps_state, aps_global, aps_appUserSettings, signInMethodType, adminNotifications } = init_state

  let signInFormType = gEnums.signInFormTypes.signInEmail

  const { appSignIn, logging, signInEmail } = aps_global ?? {}
  const { appUserCollection } = aps_appUserSettings ?? {}
  const { pathViews, sharePath } = paps_state ?? {}

  const baseProps = { appSignIn, appUserCollection, pathViews, sharePath, logging }

  return {
    baseProps,
    creds: {},
    hasProfile: false,
    show_resetPassword: false,
    adminNotifications,
    signInMethodType,
    signInResponse: null,
    signInFormType: signInFormType,
    signInType: signInTypes.signIn,
    signInEmail,
    functions_handlers,
    database_fns
  }
};

export const signInHandlers = (dispatch) => {
  return {
    // handleBack: () => { dispatch({ type: sirts.signInGoBack }) },
    handleCancel_signIn: () => { dispatch({ type: sirts.handleCancel_signIn, dispatch }) },
    handleChange_signInMethodType: (mt) => { dispatch({ type: sirts.handleChange_signInMethodType, dispatch, mt }) },
    handleChange_signInFormType: (signInFormType) => { dispatch({ type: sirts.handleChange_signInFormType, signInFormType }) },
    handleChange_signInType: () => { dispatch({ type: sirts.handleChange_signInType }) },
    handleCloseConfirmation: () => { dispatch({ type: sirts.handleCloseConfirmation }) },
    handleCloseNotification: () => { dispatch({ type: sirts.handleCloseNotification }) },
    handleConfirm_passwordChange: () => { dispatch({ type: sirts.handleConfirm_passwordChange }) },
    handleCreate_account: () => { dispatch({ type: sirts.handleCreate_account, dispatch }) },
    handleCreate_passcode: () => { dispatch({ type: sirts.handleCreate_passcode, dispatch }) },
    handleResponse_createPasscode: (res) => { dispatch({ type: sirts.handleResponse_createPasscode, dispatch, res }) },
    handleFormDataChange_local: (signInData) => { dispatch({ type: sirts.handleChanges_signInData, signInData }) },
    handleGet_dbProfile: () => { dispatch({ type: sirts.handleGet_dbProfile, dispatch }) },
    handleSend_googleLink: () => { dispatch({ type: sirts.handleSend_googleLink }) },
    handleSend_passwordResetEmail: (email) => { dispatch({ type: sirts.handleSend_passwordResetEmail, dispatch, email }) },
    handleSend_phoneCode: (phoneNumber, recaptcha) => { dispatch({ type: sirts.handleSend_phoneCode, dispatch, phoneNumber, recaptcha }) },
    handleSet_dbProfile: (profileData, authData) => { dispatch({ type: sirts.handleSet_dbProfile, dispatch, profileData, authData }) },
    handleSet_signInHelp: (signInHelp) => { dispatch({ type: sirts.handleSet_signInHelp, dispatch, signInHelp }) },
    handleSet_signInMethods: (appSignIn, cancelMethod) => { dispatch({ type: sirts.handleSet_signInMethods, appSignIn, cancelMethod }) },
    handleShow_forgotPassword: () => { dispatch({ type: sirts.handleShow_forgotPassword }) },
    handleShow_linkConfirmation: (confirmationType) => { dispatch({ type: sirts.handleShow_linkConfirmation, dispatch, confirmationType }) },
    handleShow_resetPassword: () => { dispatch({ type: sirts.handleShow_resetPassword }) },
    handleSignInResponse: (responseData, creds) => { dispatch({ type: sirts.handleSignInResponse, dispatch, responseData, creds }) },
    handleTrigger_signIn: () => { dispatch({ type: sirts.handleTrigger_signIn, dispatch }) },
    ...responseHandlers(dispatch)
  }
}

export const signInResponseTypes = {
  authFound: 'authFound',
  authNotFound: 'authNotFound',
  confirmation: 'confirmation',
  createAuthError: 'createAuthError',
  createAuthSuccess: 'createAuthSuccess',
  emailLinkNotSent: 'emailLinkNotSent',
  emailLinkSent: 'emailLinkSent',
  emailResetNotSent: 'emailResetNotSent',
  emailResetSent: 'emailResetSent',
  handlePasswordAlert: 'handlePasswordAlert',
  handlePasswordAlertConfirmed: 'handlePasswordAlertConfirmed',
  incorrectPassword: 'incorrectPassword',
  invalidEmail: 'invalidEmail',
  invalidPassword: 'invalidPassword',
  phoneCodeNotSent: 'phoneCodeNotSent',
  phoneCodeSent: 'phoneCodeSent',
  profileError: 'profileError',
  profileFound: 'profileFound',
  profileNotFound: 'profileNotFound',
  signInError: 'signInError',
  signInPassword: 'signInPassword',
  signInPending: 'signInPending',
  signInSuccess: 'signInSuccess',
  signInWithEmailSuccess: 'signInWithEmailSuccess',
  signUpComfirmationError: 'signUpComfirmationError',
  signUpEmailSent: 'signUpEmailSent',
  signUpEmailSentError: 'signUpEmailSentError',
  signUpError: 'signUpError',
  signUpSuccessWithProfileCreated: 'signUpSuccessWithProfileCreated',
  testing: 'testing',
  tooManyAttempts: 'tooManyAttempts',
  unknownError: 'unknownError',
} 