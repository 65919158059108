import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer, sidebarSliderTypes } from '../../../../global/cnr/reducers/SidebarReducer';
import { ScheduleCalendar } from '../../../../global/components/calendar/Calendar';
import { gEnums } from '../../../../global/enums/globalEnums';
import MenuSidebars from '../../../../global/sidebars/MenuSidebars';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { createScheduleTypes } from '../../cnr/reducers/CreateSportsReducer';
import { CreatePlayoffContext } from '../CreatePlayoff';
import { CreateSportsContext } from '../CreateSports';

const sidebarType = 'createScheduleDates'

const CreateSectionDates = (props) => {

  const _asat = gEnums.availableSubActionTypes

  const { createScheduleType, teamCounts, menuDisplayType } = props ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_handlers } = sportsSeasonContext ?? {}

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_state, createSports_handlers } = createSportsContext ?? {}
  const { subActionType, season_info, sportsDetails, levels_info } = createSports_state ?? {}
  const { scheduleDatesInfo } = season_info ?? {}
  const { currentDates: currentDates_schedule } = scheduleDatesInfo ?? {}
  const { year: sportsYears } = sportsDetails ?? {}

  // createPlayoffContext
  const createPlayoffContext = useContext(CreatePlayoffContext);
  const { createPlayoff_state, createPlayoff_handlers } = createPlayoffContext ?? {}
  const { playoff_info, playoffGroupKey } = createPlayoff_state ?? {}
  const { playoffLevelTeams, playoffDates_current, playoffLevel } = playoff_info ?? {}
  const { currentDates: currentDates_playoffs } = playoffDates_current ?? {}

  const _currentDates = createScheduleType === createScheduleTypes.season ? currentDates_schedule : currentDates_playoffs

  // sidebar
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType, handlers: sportsSeason_handlers }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, currents, dimmed } = sidebar_state ?? {}
  const { setInit } = sidebar_handlers ?? {}

  const { ms_dataGroups } = sidebar_items ?? {}
  const { selected: selected_pg } = ms_dataGroups ?? {}
  const { item: item_pg } = selected_pg ?? {}

  const [levels, setLevels] = useState()

  useEffect(() => {
    let _sidebarSliderType = sidebarSliderTypes.labeled
    if (menuDisplayType && menuDisplayType !== gEnums.menuDisplayTypes.menuButtons) { _sidebarSliderType = sidebarSliderTypes.normal }
    switch (createScheduleType) {
      case createScheduleTypes.season:
        if (teamCounts) {
          setInit({
            smt: sidebarMenuTypes.one,
            items: teamCounts,
            currents,
            as: 'dataGroups',
            sidebarSliderType: _sidebarSliderType,
          })
        }
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [teamCounts]);

  // once
  useEffect(() => {
    if (playoffLevelTeams) {
      const _levels = Object.keys(playoffLevelTeams).sort().reverse()
      setLevels(_levels)
    }
    switch (createScheduleType) {
      case createScheduleTypes.season:
        const { existing } = levels_info ?? {}
        const _existing = _.filter(existing, { checked: true })
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    let _sidebarSliderType = sidebarSliderTypes.labeled
    if (menuDisplayType && menuDisplayType !== gEnums.menuDisplayTypes.menuButtons) { _sidebarSliderType = sidebarSliderTypes.normal }
    switch (createScheduleType) {
      case createScheduleTypes.playoff:
        if (playoffLevel) {
          setInit({
            smt: sidebarMenuTypes.one,
            items: [playoffLevel],
            currents,
            as: 'dataGroups',
            sidebarSliderType: _sidebarSliderType,
          })
        } else if (levels) {
          setInit({
            smt: sidebarMenuTypes.one,
            items: levels,
            currents,
            as: 'dataGroups',
            sidebarSliderType: _sidebarSliderType,
          })
        }
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [levels, playoffLevel]);

  // once
  useEffect(() => {
    if (item_pg) {
      switch (createScheduleType) {
        case createScheduleTypes.playoff:
          const _playoffGroup = playoffLevelTeams[item_pg]
          createPlayoff_handlers.handleSet_playoffGroup(item_pg, _playoffGroup)
          // createPlayoff_handlers.handleAmmend_playoffDateGroup({ levelKey: item_pg })
          break;
        case createScheduleTypes.season:
          createSports_handlers.handleAmmend_seasonSectionDateGroup(item_pg)
          break;
        default:
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [item_pg]);

  useEffect(() => {
    if (playoffGroupKey) {
      switch (createScheduleType) {
        case createScheduleTypes.playoff:
          createPlayoff_handlers.handleAmmend_playoffDateGroup()
          break;
        default:
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [playoffGroupKey]);

  const content = () => {
    switch (subActionType) {
      case _asat.createSeason:
      case _asat.createPlayoff:
        return <ScheduleCalendar subActionType={subActionType} createScheduleType={createScheduleType} currentDates={_currentDates} />
      default:
        return <div>No Create Type</div>
    }
  }

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={content()}
    dimmed={dimmed}
  />

  switch (createScheduleType) {
    case createScheduleTypes.playoff:
      if (playoffLevelTeams && levels && ms_dataGroups && ms_dataGroups.items) {
        return menuSidebars()
      } else {
        return <div>---</div>
      }
    case createScheduleTypes.season:
      if (ms_dataGroups && ms_dataGroups.items) {
        return menuSidebars()
      } else {
        return <div>---</div>
      }
    default:
      return <div>---</div>
  }


}

export default CreateSectionDates