import { gEnums } from '../../../enums/globalEnums';
import { currentHelpers } from '../../../redirection/current';
import { dispatchProps } from '../../../cnr/reducers/reducerHelpers/dispatchProps';
import { createRefPath } from '../../appData/appRefPaths';
import { fs_dbu } from '../../appData/fsAppDataUpdate';

export const fsUpdate_updateAccessRequest = (props) => {

  const { baseRef, ufProps, dispatch } = props

  const { refPath } = baseRef
  let { appUser, pathName, pathCaption, dataRestrictions } = ufProps ?? {}

  const lp = currentHelpers.getLastPathView(pathName)

  const itemData = {
    [lp.path + '_' + lp.key]: {
      appUserKey: appUser.uid,
      appUserName: appUser.displayName,
      appUserEmail: appUser.email,
      _pathName: lp.path,
      _pathKey: lp.key,
      _pathCaption: pathCaption,
      pathName,
      status: gEnums.accessStatusTypes.pending
    }
  }

  const _addRef = createRefPath(['_accessRequests', appUser.email], refPath)

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.updateAccessRequest)) {
    console.log(_addRef)
    console.log(itemData)
    dispatch({ type: dispatchProps.successAlt, dispatch })
    return false
  }

  fs_dbu.set_doc(_addRef, itemData, true, dispatch)
}