import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { Grid, Label, Segment } from 'semantic-ui-react'
import { uniqueKey } from '../../../../global/common/keys'
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext'
import MatchWrapper, { sportsMatchViewTypes } from './MatchWrapper'

export const playoffBracketTypesByCount = (count) => {
  switch (count) {
    case 16:
      return playoffBracketTypes.roundOf32
    case 8:
      return playoffBracketTypes.roundOf16
    case 4:
      return playoffBracketTypes.quarterFinals
    case 2:
      return playoffBracketTypes.semiFinals
    case 1:
      return playoffBracketTypes.finals
    default:
      return playoffBracketTypes.roundOf16
  }
}

export const playoffBracketTops = {
  finals: [],
  quarterFinals: [1, 4],
  roundOf16: [1, 3, 6, 8],
  roundOf32: [1, 3, 5, 7, 10, 12, 14, 16],
  semiFinals: [],
}

export const playoffBracketTypes = {
  finals: 'finals',
  quarterFinals: 'quarterFinals',
  roundOf16: 'roundOf16',
  roundOf32: 'roundOf32',
  semiFinals: 'semiFinals',
}

const bracketTypes = {
  startFull: 'startFull',
  startSolo: 'startSolo',
  solo: 'solo'
}

const useGrid = true

const PlayoffBracket = (props) => {

  const { pool } = props ?? {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state } = seasonMatchesContext ?? {}
  const { pools, tournamentType } = seasonMatches_state

  const [selectedBracket] = useState(bracketTypes.startSolo)

  const _bracketSeedSums = {}

  _.forEach(playoffBracketTypes, (b) => {
    const p = pools ? pools[b] : {}
    const { matches } = p ?? {}
    const teamCount = _.size(matches) * 2

    const totalSeeds = _.sumBy(_.values(matches), match => {
      const homeSeed = _.get(match.home, 'seed', 0);
      // If no away seed, calculate based on the home seed
      const awaySeed = _.get(match.away, 'seed', (teamCount + 1) - homeSeed);
      return homeSeed + awaySeed;
    });

    // const totalSeeds = _.sumBy(_.values(matches), match =>
    //   _.get(match.home, 'seed', 0) + _.get(match.away, 'seed', 0)
    // ); 
    const totalSum = (teamCount * (teamCount + 1)) / 2;
    _bracketSeedSums[b] = totalSeeds - totalSum
  })


  const getMatch = (match, keyOrIndex) => {

    const { key, id, poolKey, matchKey } = match
    let keyy = key ? key : id
    if (!keyy) { keyy = keyOrIndex }

    let matchCss = matchKey

    if (playoffBracketTops[poolKey].length > 0) {
      if (playoffBracketTops[poolKey].includes(match.matchNumber)) {
        matchCss += ' playoff-top'
      } else {
        matchCss += ' playoff-bottom'
      }
    }

    const m = <MatchWrapper
      key={uniqueKey('mls', keyy)}
      sportsMatchViewType={sportsMatchViewTypes.singlePool}
      match={match}
      tournamentType={tournamentType}
    />
    return useGrid ? <div key={uniqueKey('mwd', keyy)} className={matchCss}>{m}</div> : <div key={uniqueKey('mwd', keyy)} className={matchCss}>{m}</div>
  }

  /**
   * 
   * @param {string} playoffBracketType 
   * @param {object} seeds 
   * @returns a group of matches base on the pool
   */
  const bracket = (playoffBracketType, seeds, side) => {
    const pool = pools ? pools[playoffBracketType] : {}
    const { matches } = pool ?? {}

    // eslint-disable-next-line 
    const bs = seeds ? seeds.map((seed, index) => {
      const m = _.find(matches, function (m) { return ((m.home && m.home.seed === seed) || (m.pending && m.pending.home && m.pending.home.originalSeed === seed)); })
      if (m) {
        return getMatch(m, index)
      }
    }) : []
    return useGrid ? <Grid.Column key={uniqueKey('pb', playoffBracketType, side)}>{bs}</Grid.Column> : bs
  }

  // const horizonalBracket = () => (
  //   <Grid columns={poolCount} divided className={'playoff-grid'}>
  //     <Grid.Row stretched>
  //       {roundColumns()}
  //     </Grid.Row>
  //   </Grid>
  // )

  // const fullBracket = () => {
  //   return <Grid columns={7} divided className={'playoff-grid'}>
  //     <Grid.Row stretched>
  //       {bracket([1, 16, 9, 8, 5, 12, 13, 4])}
  //       {bracket([1, 8, 5, 4])}
  //       {bracket([1, 4])}
  //       {bracket([1, 'C', 2])}
  //       {bracket([2, 3])}
  //       {bracket([2, 7, 6, 3])}
  //       {bracket([2, 15, 10, 7, 6, 11, 14, 3])}
  //     </Grid.Row>
  //   </Grid>
  // }

  // const fullMatchBracket = () => {
  //   return <div className={'grid-overflow'}>
  //     <Grid columns={6} divided className={'playoff-grid'}>
  //       <Grid.Row stretched>
  //         {bracket([1, 8, 5, 4])}
  //         {bracket([1, 4])}
  //         {bracket([1])}
  //         {bracket([2])}
  //         {bracket([2, 3])}
  //         {bracket([2, 7, 6, 3])}
  //       </Grid.Row>
  //     </Grid>
  //   </div>
  // }

  const headerCol = (playoffBracketType) => <Grid.Column key={uniqueKey('pb', playoffBracketType)}><Segment key={uniqueKey('pb', 'seg', playoffBracketType)}>{_.startCase(playoffBracketType)}</Segment></Grid.Column>

  const col32 = () => headerCol(playoffBracketTypes.roundOf32)
  const col16 = () => headerCol(playoffBracketTypes.roundOf16)
  const colQf = () => headerCol(playoffBracketTypes.quarterFinals)
  const colSf = () => headerCol(playoffBracketTypes.semiFinals)
  const colF = () => headerCol(playoffBracketTypes.finals)

  const r32_left = () => bracket(playoffBracketTypes.roundOf32, [1, 16, 9, 8, 5, 12, 13, 4], 'left')
  const r32_right = () => bracket(playoffBracketTypes.roundOf32, [2, 15, 10, 7, 6, 11, 14, 3], 'right')

  const r16_left = () => bracket(playoffBracketTypes.roundOf16, [1, 8, 5, 4], 'left')
  const r16_right = () => bracket(playoffBracketTypes.roundOf16, [2, 7, 6, 3], 'right')

  const quarter_left = () => bracket(playoffBracketTypes.quarterFinals, [1, 4], 'left')
  const quarter_right = () => bracket(playoffBracketTypes.quarterFinals, [2, 3], 'right')

  const semi_left = () => bracket(playoffBracketTypes.semiFinals, [1], 'left')
  const semi_right = () => bracket(playoffBracketTypes.semiFinals, [2], 'right')

  const finals = () => bracket(playoffBracketTypes.finals, [1, 3], 'left')

  const headerCols_full = () => {
    const cols = []
    switch (pool.poolKey) {
      case playoffBracketTypes.roundOf32:
        cols.push(col32())
        cols.push(col32())
        break;

      case playoffBracketTypes.roundOf16:
        cols.push(col16())
        cols.push(col16())
        break;

      case playoffBracketTypes.quarterFinals:
        cols.push(col16())
        cols.push(colQf())
        cols.push(colQf())
        cols.push(col16())
        break;

      case playoffBracketTypes.semiFinals:
        cols.push(col16())
        cols.push(colQf())
        cols.push(colSf())
        cols.push(colSf())
        cols.push(colQf())
        cols.push(col16())
        break;

      case playoffBracketTypes.finals:
        cols.push(col16())
        cols.push(colQf())
        cols.push(colSf())
        cols.push(colF())
        cols.push(colSf())
        cols.push(colQf())
        cols.push(col16())
        break;

      default:
        break;
    }
    return cols
  }

  // const headerCols_solo = () => {
  //   const cols = []
  //   switch (pool.poolKey) {
  //     case playoffBracketTypes.roundOf32:
  //       cols.push(col32())
  //       cols.push(col32())
  //       break;

  //     case playoffBracketTypes.roundOf16:
  //       cols.push(col16())
  //       cols.push(col16())
  //       break;

  //     case playoffBracketTypes.quarterFinals:
  //       cols.push(colQf())
  //       cols.push(colQf())
  //       break;

  //     case playoffBracketTypes.semiFinals:
  //       cols.push(colSf())
  //       cols.push(colSf())
  //       break;

  //     case playoffBracketTypes.finals:
  //       cols.push(colF())
  //       break;

  //     default:
  //       break;
  //   }
  //   return cols
  // }

  const startBracket_full = () => {

    const cols = []
    let columns = 2

    switch (pool.poolKey) {
      case playoffBracketTypes.roundOf32:
        cols.push(r32_left())
        cols.push(r32_right())
        break;

      case playoffBracketTypes.roundOf16:
        cols.push(r16_left())
        cols.push(r16_right())
        break;

      case playoffBracketTypes.quarterFinals:
        cols.push(r16_left())
        cols.push(quarter_left())
        cols.push(quarter_right())
        cols.push(r16_right())
        columns = 4
        break;

      case playoffBracketTypes.semiFinals:
        cols.push(r16_left())
        cols.push(quarter_left())
        cols.push(semi_left())
        cols.push(semi_right())
        cols.push(quarter_right())
        cols.push(r16_right())
        columns = 6
        break;

      case playoffBracketTypes.finals:
        cols.push(r16_left())
        cols.push(quarter_left())
        cols.push(semi_left())
        cols.push(finals())
        cols.push(semi_right())
        cols.push(quarter_right())
        cols.push(r16_right())
        columns = 7
        break;

      default:
        break;
    }

    return <div className={'grid-overflow'}>
      <Grid columns={columns} className={'playoff-grid-header'} >
        {headerCols_full()}
      </Grid>
      <Grid columns={columns} className={'playoff-grid'}>
        <Grid.Row>
          {cols}
        </Grid.Row>
      </Grid>
    </div>
  }

  const bracket_grid = (columns, cols) => <div className={'grid-overflow-c'}>
    <div className={'grid-overflow'}>
      <Grid columns={columns} divided className={'playoff-grid'}>
        <Grid.Row>
          {cols}
        </Grid.Row>
      </Grid>
    </div>
  </div>

  const bracket_div = (cols, pk) => <div className={'p-bracket ' + pk}>{cols}</div>

  const startBracket_solo = () => {

    const cols = []
    let columns = 2

    if (pool) {
      switch (pool.poolKey) {
        case playoffBracketTypes.roundOf32:
          cols.push(r32_left())
          cols.push(r32_right())
          break;

        case playoffBracketTypes.roundOf16:
          cols.push(r16_left())
          cols.push(r16_right())
          break;

        case playoffBracketTypes.quarterFinals:
          cols.push(quarter_left())
          cols.push(quarter_right())
          columns = 2
          break;

        case playoffBracketTypes.semiFinals:
          cols.push(semi_left())
          cols.push(semi_right())
          columns = 1
          break;

        case playoffBracketTypes.finals:
          cols.push(finals())
          columns = 1
          break;

        default:
          break;
      }

    }



    return cols.length > 0 ? useGrid ? bracket_grid(columns, cols) : bracket_div(cols, pool.poolKey) :
      <div>---</div>
  }

  const content = () => {
    switch (selectedBracket) {
      case bracketTypes.startSolo:
        return <React.Fragment>
          <div className='pbsd'>
            <Label size='tiny' color='black' circular>{'Seed Difference: '}{_bracketSeedSums[pool.poolKey]}</Label>
          </div>
          {startBracket_solo()}
        </React.Fragment>
      case bracketTypes.startFull:
        return startBracket_full()
      default:
        break;
    }
  }

  // return <SwiperGroup content={'asdfasdfasdfadsf'} />

  return content()

}

export default PlayoffBracket