import { where } from "firebase/firestore"
import _ from 'lodash'
import { convertHelpers, getFirstObject } from "../../../../../global/common/convert"
import { formatItem, formatTypes } from "../../../../../global/common/dateFormatting"
import { getLastItem, getLastItemSortedByKey } from "../../../../../global/common/filtering"
import { createRefPath_event } from "../../../../../global/firestoreData/appData/appRefPaths"
import { fs_dbu } from "../../../../../global/firestoreData/appData/fsAppDataUpdate"
import { sortDB } from "../../../helpers/league"
import { matchCalcs } from "../../../helpers/match"
import { matchStatusTypes } from "../SportsDataReducer"
import { gameScoringTypes } from "../SportsSeasonReducer"
import { _sportCollections } from "../SportsSidebarReducer"
import { seasonalHelpers } from "./seasonalSportsData"
import { fs_db } from "../../../../../global/firestoreData/appData/fsAppData"
import { realtimeDocumentTypes } from "../SportsEditMatchReducer"
import { removeFromObjects } from "../../../../../global/common/sorting"

const _pendingPrefix = 'playoffMatches.'

export const sportsHelpers = {
  ammendMatchesFromRealtime: (rtms, matches_info, realtimeDocumentType, gameScoringType) => ammendMatchesFromRealtime(rtms, matches_info, realtimeDocumentType, gameScoringType),
  ammendMatchesInfo: (matches, playoffMatches) => ammendMatchesInfo(matches, playoffMatches),
  ammendScoresWithScores: (matches, scores) => ammendScoresWithScores(matches, scores),
  ammendTeams: (teams, matches, rankings, sportsModeSport, sectionPlayoffTeams) => ammendTeams(teams, matches, rankings, sportsModeSport, sectionPlayoffTeams),
  getLastestRankings: (rankings) => getLastestRankings(rankings),
  getMatch_flat: (ms, allowMatchStatusType) => getMatch_flat(ms, allowMatchStatusType),
  getMatchDateKeys: (matchDateGroups) => getMatchDateKeys(matchDateGroups),
  getMatches_flat: (matches_season) => getMatches_flat(matches_season),
  getMatchesScores: (matches, prefix) => getMatchesScores(matches, prefix),
  getPendingMatchesInfo: (matchDataUpdates, playoffMatches, teams) => getPendingMatchesInfo(matchDataUpdates, playoffMatches, teams),
  getPendingMatchInfo: (match, playoffMatches, pendings, teams) => getPendingMatchInfo(match, playoffMatches, pendings, teams),
  getPlayoffTeams: (teams) => getPlayoffTeams(teams),
  getPrePendingMatches: (match, playoffMatches) => getPrePendingMatches(match, playoffMatches),
  getScoreType: (sportsModeKey) => getScoreType(sportsModeKey),
  getTeamHistories: (teams, history) => getTeamHistories(teams, history),
  getWheres_sports: (pathViews, dataItemDirect, sportsKey) => getWheres_sports(pathViews, dataItemDirect, sportsKey),
  isSectionMatch: (m) => isSectionMatch(m),
  updateSeasonalMatchesFromMatches: (sportPermissions, matches, pathViews, key_matches_seasonal, forPlayoff, forceUpdate, forBackup) => updateSeasonalMatchesFromMatches(sportPermissions, matches, pathViews, key_matches_seasonal, forPlayoff, forceUpdate, forBackup),
  updateSeasonalScoresFromMatches: (sportPermissions, matches, pathViews, key_matches_seasonal, forPlayoff, forceUpdate, forBackup) => updateSeasonalScoresFromMatches(sportPermissions, matches, pathViews, key_matches_seasonal, forPlayoff, forceUpdate, forBackup),
}

const ammendMatchesFromRealtime = (rtms, matches_info, realtimeDocumentType, gameScoringType) => {

  let _statusProp;

  const { matches, playoffMatches } = matches_info ?? {}

  switch (realtimeDocumentType) {
    case realtimeDocumentTypes.matchDay:
      _statusProp = 'isMatchDayResult'
      break;
    case realtimeDocumentTypes.realtime:
      _statusProp = 'isRealtimeResult'
      break;
    default:
    // nothing
  }

  removeFromObjects(matches, ['_results_realtime'])
  removeFromObjects(playoffMatches, ['_results_realtime'])

  if (_statusProp) {

    const matchDayMatches = matches ? _.filter(matches, match => _.get(match, `_status.${_statusProp}`, false) === true) : [];
    const matchDayMatches_playoff = playoffMatches ? _.filter(playoffMatches, match => _.get(match, `_status.${_statusProp}`, false) === true) : [];

    _.forEach(matchDayMatches, (mdm) => {
      if (mdm._status) { mdm._status[_statusProp] = false }
      if (mdm._results) { delete mdm._results.score }
      matchCalcs.calcVballMatch(mdm, gameScoringType, realtimeDocumentType, mdm)
    })

    _.forEach(matchDayMatches_playoff, (mdm) => {
      if (mdm._status) { mdm._status[_statusProp] = false }
      if (mdm._results) { delete mdm._results.score }
      matchCalcs.calcVballMatch(mdm, gameScoringType, realtimeDocumentType, mdm)
    })
  }

  _.forEach(rtms, (mr, mk) => {
    if (matches && matches[mk]) {
      const match_actual = matches[mk]
      const rtm = getFirstObject(mr)
      const { item } = rtm ?? {}
      const { results: results_rt } = item ?? {}
      const match_rt = { results: results_rt }
      matchCalcs.calcVballMatch(match_actual, gameScoringType, realtimeDocumentType, match_rt)
      const { _results } = match_rt ?? {}
      switch (realtimeDocumentType) {
        case realtimeDocumentTypes.matchDay:
          match_actual._results_matchDay = _results
          break;
        case realtimeDocumentTypes.realtime:
          match_actual._results_realtime = _results
          break;
        default:
        // nothing
      }
    }

    if (playoffMatches && playoffMatches[mk]) {
      const match_actual = playoffMatches[mk]
      const { home, away } = match_actual ?? {}
      const rtm = getFirstObject(mr)
      const { item } = rtm ?? {}
      const { results: results_rt } = item ?? {}
      const match_rt = { home, away, results: results_rt }
      matchCalcs.calcVballMatch(match_rt, gameScoringType)
      const { _results } = match_rt ?? {}
      switch (realtimeDocumentType) {
        case realtimeDocumentTypes.matchDay:
          match_actual._results_matchDay = _results
          break;
        case realtimeDocumentTypes.realtime:
          match_actual._results_realtime = _results
          break;
        default:
        // nothing
      }
    }
  })

  matchCalcs.calcAllVballMatches_displays(matches)
  matchCalcs.calcAllVballMatches_displays(playoffMatches)
}



const getWheres_sports = (pathViews, dataItemDirect, sportsKey) => {

  const { parentKeys } = dataItemDirect ?? {}

  // get the sport
  const wheres = []
  const _wheres = []

  parentKeys.forEach(parentKey => {
    switch (parentKey) {
      case 'name':
        wheres.push(where("name", "==", _.startCase(pathViews.sports)))
        _wheres.push({ name: _.startCase(pathViews.sports) })
        break;
      default:
        if (pathViews[parentKey]) {
          switch (parentKey) {
            case 'sports':
              wheres.push(where('parentKeys.' + parentKey, '==', sportsKey))
              _wheres.push({ [parentKey]: sportsKey })
              break;
            default:
              wheres.push(where('parentKeys.' + parentKey, '==', pathViews[parentKey]))
              _wheres.push({ [parentKey]: pathViews[parentKey] })
          }
        }
    }
  })

  return { wheres, _wheres }

}

const getMatchDateKeys = (matchDateGroups) => {
  const today = new Date()
  const _today = formatItem(formatTypes.shortestDate, today)
  let _todaysMatchCount;
  const matchDateKeys = []
  const matchDateKeys_count = {}
  if (matchDateGroups) {
    matchDateGroups.forEach(group => {
      matchDateKeys.push(group.matchDate)
      matchDateKeys_count[group.matchDate] = Object.keys(group.matches).length
      if (group.matchDate === _today) {
        _todaysMatchCount = Object.keys(group.matches).length
      }
    })
  }
  return { matchDateKeys, matchDateKeys_count, _todaysMatchCount }
}

/**
 * 
 * @param {object} matches_season 
 * @returns the same list of matches, but converts the home, away and results object to props without the object
 */
const getMatches_flat = (matches_season) => {
  const _matchesFlat = {}
  _.forEach(matches_season, (ms, mk) => {
    const mf = getMatch_flat(ms)
    if (mf) { _matchesFlat[mk] = mf }
  })
  return _matchesFlat
}

const getMatch_flat = (ms, allowMatchStatusType) => {
  const {
    _matchCollectionKey,
    _matchStatusTypes,
    _results,
    _status,
    away,
    home,
    isPlayoff,
    levels,
    location,
    matchNumber,
    old,
    pending,
    poolKey,
    results,
    sections,
    startDate,
    startTime,
  } = ms
  const { isBye } = _status ?? {}
  const { score, scores } = _results ?? {}
  const { home: score_home, away: score_away } = score ?? {}
  if (!isBye) {

    const _ms = {
      home: home ? home.name : '?',
      away: away ? away.name : '?',
      section: sections,
      level: levels,
      startDate,
      _matchCollectionKey,
      isPlayoff: isPlayoff ? true : false,
    }

    if (_.isNumber(score_home)) { _ms.homeScore = parseInt(score_home) }
    if (_.isNumber(score_away)) { _ms.awayScore = parseInt(score_away) }
    if (results) { _ms._results = results }
    if (results) { _ms.results = results }
    if (old) { _ms.old = old }
    if (allowMatchStatusType) { _ms._matchStatusTypes = _matchStatusTypes }
    if (!_ms._matchStatusTypes) { _ms._matchStatusTypes = [matchStatusTypes.existing] }
    if (isPlayoff) {
      _ms.startTime = startTime
      _ms.poolKey = poolKey
      _ms.matchNumber = matchNumber
      if (away && away.seed) { _ms.awaySeed = away.seed }
      if (home && home.seed) { _ms.homeSeed = home.seed }
    }
    if (pending) {
      const { home: home_pending, away: away_pending } = pending
      if (!home) {
        _ms.homeSeed = home_pending.originalSeed
        _ms.home = home_pending.place + ' ' + home_pending.poolName + ' match #' + home_pending.matchNumber
      }
      if (!away) {
        _ms.awaySeed = away_pending.originalSeed
        _ms.away = away_pending.place + ' ' + away_pending.poolName + ' match #' + away_pending.matchNumber
      }
    }
    if (scores) {
      Object.keys(scores).forEach(sn => {
        const sc = scores[sn]
        _ms['away' + sn] = parseInt(sc.away)
        _ms['home' + sn] = parseInt(sc.home)
      })
    }
    if (location) {
      _ms.location = location.name
    }
    return _ms
  }
}

const getScoreType = (sportsModeKey) => {

  let _sportsModeKey = sportsModeKey.replace('womens', '')
  _sportsModeKey = _sportsModeKey.replace('mens', '')
  _sportsModeKey = _sportsModeKey.toLowerCase()

  const matchTypes = ['volleyball', 'tennis']
  const halfTypes = ['soccer', 'fieldHockey', 'lacrosse']
  const quarterTypes = ['basketball', 'football']
  const inningTypes = ['baseball', 'softball']

  let gameScoringType;
  let gameAllowTies = false;

  if (matchTypes.includes(_sportsModeKey)) {
    gameScoringType = gameScoringTypes.matchPoints
  } else if (halfTypes.includes(_sportsModeKey)) {
    gameScoringType = gameScoringTypes.half
  } else if (quarterTypes.includes(_sportsModeKey)) {
    gameScoringType = gameScoringTypes.quarter
  } else if (inningTypes.includes(_sportsModeKey)) {
    gameScoringType = gameScoringTypes.inning
  }

  return { gameScoringType, gameAllowTies }
}

const updateSeasonalMatchesFromMatches = async (sportPermissions, matches, pathViews, key_matches_seasonal, forPlayoff, forceUpdate, forBackup) => {

  // const collectionName = _sportCollections._scores_seasonal

  const collectionName = _sportCollections._matches_seasonal
  let docName = forPlayoff ? 'playoffMatches' : 'matches'
  if (forBackup) { docName += '_backup' }

  const _refPath = createRefPath_event(pathViews, [collectionName, key_matches_seasonal, docName])
  const scoresCollection = await fs_db.get_collectionExists({ refPath: _refPath })

  const allowUpdate = sportPermissions.any && sportPermissions.matchesList

  if (_.isEmpty(scoresCollection) || forceUpdate) {
    if (matches) {
      const mws_g = _.groupBy(matches, 'levels')
      if (mws_g) {
        Object.keys(mws_g).forEach(async levelKey => {
          const lms = mws_g[levelKey]
          const _matches = {}
          lms.forEach(lm => {
            _matches[lm._itemKey] = lm
            delete _matches[lm._itemKey]._itemKey
          })
          const _refPathM = createRefPath_event(pathViews, [collectionName, key_matches_seasonal, docName, levelKey])
          seasonalHelpers.cleanDataToUpdate(_matches)
          const _dataToUpdate = forPlayoff ? { playoffMatches: _matches } : { matches: _matches }
          if (allowUpdate) {
            await fs_dbu.update_doc(_refPathM, _dataToUpdate)
          } else {
            console.log('_refPathM', _refPathM)
            console.log('_dataToUpdate', _dataToUpdate)
          }
        })
      }
    }
  }
}

const updateSeasonalScoresFromMatches = async (sportPermissions, matches, pathViews, key_matches_seasonal, forPlayoff, forceUpdate, forBackup) => {

  // const collectionName = _sportCollections._scores_seasonal

  const collectionName = _sportCollections._matches_seasonal
  let docName = forPlayoff ? 'playoffScores' : 'scores'
  if (forBackup) { docName += '_backup' }

  const _refPath = createRefPath_event(pathViews, [collectionName, key_matches_seasonal, docName])
  const scoresCollection = await fs_db.get_collectionExists({ refPath: _refPath })

  const allowUpdate = sportPermissions.any && sportPermissions.matchesList

  if (_.isEmpty(scoresCollection) || forceUpdate) {
    if (matches) {
      const mws = getMatchesScores(matches)
      const mws_g = _.groupBy(mws, 'levels')
      if (mws_g) {
        Object.keys(mws_g).forEach(async levelKey => {
          const lms = mws_g[levelKey]
          const _scores = {}
          lms.forEach(lm => {
            _scores[lm._itemKey] = lm
            delete _scores[lm._itemKey]._itemKey
            if (lm.home && !lm.home.id) { delete lm.home.id }
            if (lm.away && !lm.away.id) { delete lm.away.id }
          })
          const _refPathM = createRefPath_event(pathViews, [collectionName, key_matches_seasonal, docName, levelKey])
          const _dataToUpdate = forPlayoff ? { playoffScores: _scores } : { scores: _scores }
          if (allowUpdate) {
            await fs_dbu.update_doc(_refPathM, _dataToUpdate)
          } else {
            console.log('_refPathM', _refPathM)
            console.log('_dataToUpdate', _dataToUpdate)
          }
        })
      }
    }
  }
}

const getMatchesScores = (matches, prefix) => {
  console.log('getMatchesScores')
  const mws = {}
  _.forEach(matches, (m, mk) => {
    const { levels, home, away, _itemKey } = m ?? {}
    const _results = m ? m._results : undefined;
    const results = m ? m._results : undefined;
    const { score, scores } = _results ?? {}
    if (home && away && (score || scores)) {
      const { id: id_away, name: name_away } = away
      const { id: id_home, name: name_home } = home
      const _mks = !_.isNumber ? mk.split('.') : []
      const _mk = _mks.length === 2 ? prefix + '.' + _mks[1] : mk
      mws[_mk] = {
        _itemKey,
        away: {
          id: id_away,
          name: name_away
        },
        home: {
          id: id_home,
          name: name_home
        },
        levels: levels,
      }
      if (score) { mws[_mk].score = score }
      if (scores) { mws[_mk].scores = scores }
      if (prefix) { delete mws[_mk]._itemKey }
    }
  })
  return mws
}

const getLastestRankings = (rankings) => {
  if (rankings) {
    const _rs = getLastItemSortedByKey(rankings)
    return _rs
  }
}

const isSectionMatch = (m) => {
  if (m && m.home && m.away && (m.home.sections === m.away.sections) && (m.home.levels === m.away.levels) && (!m.exhibition && m._matchCollectionKey !== 'exhibition')) {
    return true
  }
}

/**
 * 
 * @param {object} matches 
 * @param {function} callback 
 * @returns 
 */
const ammendMatchesInfo = (matches, playoffMatches) => {
  let totalWeeks = 1;
  let firstMatchDate;
  let lastMatchDate;
  const dm = _.groupBy(matches, 'startDate')
  const dms = Object.keys(dm)
  const dmsk = _.sortBy(dms, function (value) { return new Date(value); })
  firstMatchDate = dm ? dmsk[0] : null
  lastMatchDate = dm ? dmsk[dmsk.length - 1] : null
  totalWeeks = weeksBetween(dmsk[0], dmsk[dmsk.length - 1])
  const _matchesG = _.groupBy(matches, '_matchCollectionKey')
  const _playoffMatches = playoffMatches ? playoffMatches : _matchesG.playoffs
  return { matches, playoffMatches: _playoffMatches ? _playoffMatches : {}, totalWeeks, firstMatchDate, lastMatchDate }
}

/**
 * 
 * @param {object} teams 
 * @param {object} matches 
 * @returns teams, sportDistricts, sportLevels
 */
const ammendTeams = (teams, matches, rankings, sportsModeSport, sectionPlayoffTeams) => {
  let sportLevels = []
  const currentRankings = rankings ? getLastItem(rankings) : null
  ammendTeamData(teams, matches, currentRankings)
  const levels = _.groupBy(teams, 'levels')
  delete levels.Non
  ammendTeamSectionInfo(levels, sportsModeSport, sectionPlayoffTeams)
  const lks = Object.keys(levels).sort().reverse()
  sportLevels = lks
  return { teams, sportLevels }
}

const getPlayoffTeams = (teams) => {
  const _playoffTeams = []
  Object.keys(teams).forEach(k => {
    const team = teams[k]
    if (team && team.record && team.record.playoffTeam) {
      _playoffTeams.push(team)
    }
  })
  return _playoffTeams
}


const getTeamHistories = (teams, history) => {

  const teamHistory = {}

  const getYears = (th, items, hr, winner) => {
    items.forEach(item => {
      if (winner) { th.winners.push({ year: item._itemKey, level: hr }) }
      if (!winner) { th.runnerUps.push({ year: item._itemKey, level: hr }) }
    })
  }

  if (teams && history) {
    Object.keys(teams).forEach(tk => {
      const team = teams[tk]
      teamHistory[tk] = {
        winners: [],
        runnerUps: []
      }
      const { name } = team
      Object.keys(history).forEach(hk => {
        const history_level = history[hk]
        convertHelpers.createItemKeys(history_level)
        const winners = _.filter(history_level, { winner: name })
        const runnerUps = _.filter(history_level, { runnerUp: name })
        if (winners && winners.length > 0) { getYears(teamHistory[tk], winners, hk, true) }
        if (runnerUps && runnerUps.length > 0) { getYears(teamHistory[tk], runnerUps, hk) }
      })
    })
  }

  return teamHistory

}

const ammendTeamSectionInfo = (levels, sportsModeSport, sectionPlayoffTeams) => {
  if (levels) {
    Object.keys(levels).forEach(levelKey => {
      const level = levels[levelKey]
      const sections = _.groupBy(level, 'sections')
      if (sections) {
        Object.keys(sections).forEach(sectionKey => {
          const section = sections[sectionKey]
          sortDB.sortSectionStandings(section, null, sectionPlayoffTeams, sportsModeSport)
        })
      }
    })
  }
}

/**
 * 
 * @param {object} teams
 * @param {object} matches 
 */
const ammendTeamData = (teams, matches, currentRankings) => {
  // const allTeamScores = {}
  // loop the teams 
  if (teams) {
    _.forEach(teams, (team, teamKey) => {
      if (team) {
        // set the base record 
        try {
          team.record = { tm: 0, tsm: 0, mp: 0, w: 0, l: 0, t: 0, sw: 0, sl: 0, st: 0, nsmt: 0, nsmp: 0, nsw: 0, nsl: 0, nst: 0, nssw: 0, nssl: 0, nsst: 0 }
          getTeamRank(team, currentRankings)
          if (matches) {
            team.record = getTeamRecord(matches, teamKey)
          }
        } catch (error) {
          console.log('teams', teams)
        }

      }
    })
  }
}


/**
 * 
 * @param {object} matches 
 * @param {string} teamKey 
 * @returns the record (w, sw, l, sl) for the team
 */
const getTeamRecord = (matches, teamKey) => {

  const currentDate = new Date();
  const twoDaysAgo = new Date(currentDate);
  twoDaysAgo.setDate(currentDate.getDate() - 2);

  const record = {
    totalMatches: null,
    wins: null,
    sectionWins: null,
    losses: null,
    sectionLoses: null,
    ties: null,
    sectionTies: null
  }

  record.totalMatches = _.pickBy(matches, function (m) {
    if (m && m.away && m.home) { return m.away.id === teamKey || m.home.id === teamKey }
  })

  // const xx = _.pickBy(record.totalMatches, function (m) {
  //   if (m && m.away && m.home && m._results && !m._results.loser) { return m }
  // })

  record.totalSectionMatches = _.pickBy(record.totalMatches, function (m) {
    if (m) { return (m.away.sections === m.home.sections && m.away.levels === m.home.levels) }
  })

  record.totalMatchesMissing = _.pickBy(record.totalMatches, (match) => {
    const matchDate = parseDate(match.startDate);
    return matchDate < twoDaysAgo && !match._results;
  });

  record.wins = _.pickBy(record.totalMatches, function (m) {
    if (m && m._results && m._results.winner) { return m._results.winner && m._results.winner.id === teamKey }
  })

  if (record.wins) {
    record.sectionWins = _.pickBy(record.wins, function (m) {
      if (m && m._results && m._results.winner) {
        return m._results.winner && m._results.winner.id === teamKey && isSectionMatch(m)
      }
    })
  }

  record.losses = _.pickBy(record.totalMatches, function (m) {
    if (m && m._results && m._results.loser) { return m._results.loser && m._results.loser.id === teamKey }
  })

  if (record.losses) {
    record.sectionLoses = _.pickBy(record.losses, function (m) {
      if (m && m._results && m._results.loser) {
        return m._results.loser && m._results.loser.id === teamKey && isSectionMatch(m)
      }
    })
  }

  record.ties = _.pickBy(matches, function (m) {
    if (m && m._results && m._results.tied) { return m._results.tied.includes(teamKey) }
  })

  if (record.ties) {
    record.sectionTies = _.pickBy(record.ties, function (m) {
      if (m && m._results && m._results.tied) { return m._results.tied.includes(teamKey) && isSectionMatch(m) }
    })
  }

  getTeamPoints(record)
  // wins
  // sectionWins,
  // losses
  // sectionLoses
  // ties
  // sectionTies

  const teamRecord = {
    tm: Object.keys(record.totalMatches).length, // totalMatches
    tmm: Object.keys(record.totalMatchesMissing).length, // totalMatches
    tsm: Object.keys(record.totalSectionMatches).length, // totalSectionMatches
    w: Object.keys(record.wins).length, // wins
    sw: Object.keys(record.sectionWins).length, // sectionWins
    l: Object.keys(record.losses).length, // losses
    sl: Object.keys(record.sectionLoses).length, // sectionLoses
    t: Object.keys(record.ties).length, // ties
    st: Object.keys(record.sectionTies).length, // sectionTies
  }

  teamRecord.mp = teamRecord.w + teamRecord.l + teamRecord.t
  teamRecord.owp = teamRecord.mp > 0 ? (teamRecord.w / (teamRecord.mp)) : .500
  teamRecord.op = (teamRecord.w * 3) + teamRecord.t

  teamRecord.smp = teamRecord.sw + teamRecord.sl + teamRecord.st
  teamRecord.swp = teamRecord.smp > 0 ? (teamRecord.sw / teamRecord.smp) : .500
  teamRecord.sp = (teamRecord.sw * 3) + teamRecord.st

  teamRecord.mr = teamRecord.tm - teamRecord.mp

  const teamRecord2 = getTR(matches, teamKey, twoDaysAgo)

  teamRecord2.p = record.matchPoints
  teamRecord2.op = record.matchPointsOverall

  return teamRecord2

}

const getTeamPoints = (record) => {

  const matches = {
    winsIn5: _.filter(record.sectionWins, match => match.results && match.results.score && match.results.score.home + match.results.score.away === 5),
    winsNot5: _.filter(record.sectionWins, match => match.results && match.results.score && match.results.score.home + match.results.score.away < 5),
    lossesIn5: _.filter(record.sectionLoses, match => match.results && match.results.score && match.results.score.home + match.results.score.away === 5),
    lossesNot5: _.filter(record.sectionLoses, match => match.results && match.results.score && match.results.score.home + match.results.score.away < 5),
  }

  const overallMatches = {
    winsIn5: _.filter(record.wins, match => match.results && match.results.score && match.results.score.home + match.results.score.away === 5),
    winsNot5: _.filter(record.wins, match => match.results && match.results.score && match.results.score.home + match.results.score.away < 5),
    lossesIn5: _.filter(record.losses, match => match.results && match.results.score && match.results.score.home + match.results.score.away === 5),
    lossesNot5: _.filter(record.losses, match => match.results && match.results.score && match.results.score.home + match.results.score.away < 5),
  }

  const points = {
    winsIn5: matches.winsIn5.length,
    winsNot5: matches.winsNot5.length,
    lossesIn5: matches.lossesIn5.length,
    lossesNot5: matches.lossesNot5.length,
  }

  const overallPoints = {
    winsIn5: overallMatches.winsIn5.length,
    winsNot5: overallMatches.winsNot5.length,
    lossesIn5: overallMatches.lossesIn5.length,
    lossesNot5: overallMatches.lossesNot5.length,
  }

  points.total = (points.winsNot5 * 3) + (points.winsIn5 * 2) + (points.lossesIn5 * 1)
  points.totalOverall = (overallPoints.winsNot5 * 3) + (overallPoints.winsIn5 * 2) + (overallPoints.lossesIn5 * 1)

  record.matchPoints = points.total
  record.matchPointsOverall = points.totalOverall

}

const getTR = (matches, teamKey, twoDaysAgo) => {

  const record = {};

  record.totalMatches = _.pickBy(matches, m => (m?.away?.id === teamKey || m?.home?.id === teamKey));
  record.totalSectionMatches = _.pickBy(record.totalMatches, m => (m?.away?.sections === m?.home?.sections));

  record.totalMatchesMissing = _.pickBy(record?.totalMatches, match => {
    const matchDate = parseDate(match.startDate);
    return matchDate < twoDaysAgo && !match._results;
  });

  record.wins = _.pickBy(record.totalMatches, m => (m?._results?.winner?.id === teamKey));
  record.sectionWins = _.pickBy(record.wins, m => (isSectionMatch(m)));
  record.losses = _.pickBy(record.totalMatches, m => (m?._results?.loser?.id === teamKey));
  record.sectionLoses = _.pickBy(record.losses, m => (isSectionMatch(m)));
  record.ties = _.pickBy(matches, m => (m?._results?.tied?.includes(teamKey)));
  record.sectionTies = _.pickBy(record.ties, m => (isSectionMatch(m)));

  const teamRecord = {
    tm: _.size(record.totalMatches),
    tmm: _.size(record.totalMatchesMissing),
    tsm: _.size(record.totalSectionMatches),
    w: _.size(record.wins),
    sw: _.size(record.sectionWins),
    l: _.size(record.losses),
    sl: _.size(record.sectionLoses),
    t: _.size(record.ties),
    st: _.size(record.sectionTies),
  };

  teamRecord.mp = teamRecord.w + teamRecord.l + teamRecord.t
  teamRecord.owp = teamRecord.mp > 0 ? (teamRecord.w / (teamRecord.mp)) : .500
  teamRecord.op = (teamRecord.w * 3) + teamRecord.t

  teamRecord.smp = teamRecord.sw + teamRecord.sl + teamRecord.st
  teamRecord.swp = teamRecord.smp > 0 ? (teamRecord.sw / teamRecord.smp) : .500
  teamRecord.sp = (teamRecord.sw * 3) + teamRecord.st

  teamRecord.mr = teamRecord.tm - teamRecord.mp

  return teamRecord
}


function parseDate(dateString) {
  try {
    const [month, day, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day); // Note: months are 0-based
  } catch (error) {

  }

}

const getTeamRank = (team, currentRankings) => {
  const { name, levels } = team ?? {}
  if (currentRankings) {
    Object.keys(currentRankings).forEach(crKey => {
      if (crKey === levels) {
        const cr = currentRankings[crKey]
        if (cr && cr.includes(name)) {
          team.currentRank = cr.indexOf(name) + 1
        }
      }
    })
  }
}

function weeksBetween(d1, d2) {
  const weeks = Math.round((new Date(d2) - new Date(d1)) / (7 * 24 * 60 * 60 * 1000))
  const totalWeeks = weeks + 2
  return totalWeeks
}

const ammendScoresWithScores = (matches, scores) => {

  if (scores && matches) {
    _.forEach(scores, (scoreItem, scoreKey) => {
      if (matches[scoreKey]) {
        const match = matches[scoreKey]
        const { score, scores } = scoreItem ?? {}
        if (match && match.results && (score || scores)) {
          match._results_fromScores = {}
          if (score) { match._results_fromScores.score = score }
          if (scores) { match._results_fromScores.scores = scores }
        }
      }
    })
  }
}

const getPendingMatchesInfo = (matchDataUpdates, playoffMatches, teams) => {

  _.forEach(matchDataUpdates, (matchDataUpdate, changeKey) => {

    switch (changeKey) {

      case matchStatusTypes.scoreNew:
      case matchStatusTypes.scoreNew:
      case matchStatusTypes.scoreChanged:
      case matchStatusTypes.scoresChanged:

        _.forEach(matchDataUpdate, (levelMatches, levelKey) => {

          const pendings = {
            levelMatchUpdates: {},
            matchWinners: {},
            matchLosers: {}
          }

          _.forEach(levelMatches, (levelMatch) => {
            matchCalcs.calcVballMatch(levelMatch)
            getPendingMatchInfo(levelMatch, playoffMatches, pendings, teams)
          })

          const pending = pendings.levelMatchUpdates[levelKey]

          _.forEach(pending, (pendingMatch, key) => {
            matchDataUpdate[levelKey][key] = pendingMatch
          })


        })
    }
  })

}

const getPendingMatchInfo = (match, playoffMatches, pendings, teams) => {

  console.log('match', match)

  // this is the match that was updated 
  const { home, away, poolKey, levels, pending, results: results_fromMatch, _matchCollectionKey } = match ?? {}
  let results = match ? match._results : undefined;
  if (!results && results_fromMatch) { results = results_fromMatch }
  const { winner, loser, scoreDisplays } = results ?? {}
  const { full } = scoreDisplays ?? {}

  const { seed: seed_home } = home ?? {}
  const { seed: seed_away } = away ?? {}

  const { home: pending_home, away: pending_away } = pending ?? {}

  const { originalSeed: originalSeed_home } = pending_home ?? {}
  const { originalSeed: originalSeed_away } = pending_away ?? {}

  const _seed_home = originalSeed_home ? originalSeed_home : seed_home
  const _seed_away = originalSeed_away ? originalSeed_away : seed_away

  const match_winner = getWinnerMatch(playoffMatches, levels, poolKey, _seed_home, winner, pendings, full, teams)
  const match_loser = getWinnerMatch(playoffMatches, levels, poolKey, _seed_away, loser, pendings, full, teams)

  if (match_winner) {
    if (!pendings.matchWinners[match_winner._itemKey]) {
      pendings.matchWinners[match_winner._itemKey] = match_winner
    } else {
      pendings.matchWinners[match_winner._itemKey] = { ...pendings.matchWinners[match_winner._itemKey], ...match_winner }
    }
  }

  if (match_loser) {
    if (!pendings.matchLosers[match_loser._itemKey]) {
      pendings.matchLosers[match_loser._itemKey] = match_winner
    } else {
      pendings.matchLosers[match_loser._itemKey] = { ...pendings.matchWinners[match_loser._itemKey], ...match_loser }
    }
  }

  return { _matchCollectionKey }
}

const getWinnerMatch = (playoffMatches, levels, poolKey, seed, winner, pendings, results_full, teams) => {

  const _pendingPrefix = 'playoffMatches.'

  const _awayMatch = findPendingMatchNonResults(playoffMatches, levels, poolKey, 'away', seed)
  const awayMatch = _awayMatch ? _awayMatch[0] : null

  const _homeMatch = findPendingMatchNonResults(playoffMatches, levels, poolKey, 'home', seed)
  const homeMatch = _homeMatch ? _homeMatch[0] : null

  const _awayMatchNonPending = findMatchBySeed(playoffMatches, levels, poolKey, 'away', seed)
  const awayMatchNonPending = _awayMatchNonPending?.[0]?.away?.id ?? null;

  const _homeMatchNonPending = findMatchBySeed(playoffMatches, levels, poolKey, 'home', seed)
  const homeMatchNonPending = _homeMatchNonPending?.[0]?.home?.id ?? null;


  let _levelMatch;
  let existingTeams;
  let matchTeamsKey;

  if (awayMatch) {
    const { levels, teams } = awayMatch ?? {}
    awayMatch._awayAlreadyExists = awayMatch.away ? true : false
    if (awayMatch._awayAlreadyExists) { awayMatch._existingsName = awayMatch.away.name }
    awayMatch.away = winner
    _levelMatch = levels
    if (!pendings.levelMatchUpdates[levels]) {
      pendings.levelMatchUpdates[levels] = {}
    }
    existingTeams = teams
  }

  if (homeMatch) {
    const { levels, teams } = homeMatch ?? {}
    homeMatch._homeAlreadyExists = homeMatch.home ? true : false
    if (homeMatch._homeAlreadyExists) { homeMatch._existingsName = homeMatch.home.name }
    homeMatch.home = winner
    _levelMatch = levels
    if (!pendings.levelMatchUpdates[levels]) {
      pendings.levelMatchUpdates[levels] = {}
    }
    existingTeams = teams
  }

  let winnerMatch;

  if (winner && (homeMatch || awayMatch)) {
    if (!winner.id && winner.name && teams) {
      const _teamKey = _.findKey(teams, { name: winner.name })
      if (_teamKey) {
        winner.id = _teamKey
      }
    }

    if (homeMatch) {
      const homeKey = _pendingPrefix + homeMatch._itemKey + '.home'
      winnerMatch = homeMatch

      pendings.levelMatchUpdates[_levelMatch][homeKey] = winner
      pendings.levelMatchUpdates[_levelMatch][homeKey]._existingInfo = {
        full: results_full,
        exists: homeMatch._awayAlreadyExists ? homeMatch._existingsName : null
      }

      const teamsKey = _pendingPrefix + homeMatch._itemKey + '.teams'
      matchTeamsKey = teamsKey
      if (!pendings.levelMatchUpdates[_levelMatch][teamsKey]) { pendings.levelMatchUpdates[_levelMatch][teamsKey] = [] }
      pendings.levelMatchUpdates[_levelMatch][teamsKey].push(winner.id)
    }

    if (awayMatch) {
      const awayKey = _pendingPrefix + awayMatch._itemKey + '.away'
      winnerMatch = awayMatch

      pendings.levelMatchUpdates[_levelMatch][awayKey] = winner
      pendings.levelMatchUpdates[_levelMatch][awayKey]._existingInfo = {
        full: results_full,
        exists: awayMatch._awayAlreadyExists ? awayMatch._existingsName : null
      }

      const teamsKey = _pendingPrefix + awayMatch._itemKey + '.teams'
      matchTeamsKey = teamsKey
      if (!pendings.levelMatchUpdates[_levelMatch][teamsKey]) { pendings.levelMatchUpdates[_levelMatch][teamsKey] = [] }

      pendings.levelMatchUpdates[_levelMatch][teamsKey].push(winner.id)
    }
  }

  if (winnerMatch && winnerMatch.teams && winnerMatch.teams.length === 1) {
    if (awayMatchNonPending && homeMatchNonPending) {
      const _teams = pendings.levelMatchUpdates[_levelMatch][matchTeamsKey]
      if (!_.includes(_teams, awayMatchNonPending)) { _teams.push(awayMatchNonPending) }
      if (!_.includes(_teams, homeMatchNonPending)) { _teams.push(homeMatchNonPending) }
      pendings.levelMatchUpdates[_levelMatch][matchTeamsKey] = _teams
    }
  }

  const { home, away } = winnerMatch ?? {}
  const { id: id_home } = home ?? {}
  const { id: id_away } = away ?? {}

  if (id_home && id_away) {
    pendings.levelMatchUpdates[_levelMatch][matchTeamsKey] = [id_away, id_home]
  }

  return winnerMatch


}

const findMatchBySeed = (playoffMatches, levels, poolKey, awayOrHome, seed) => _.filter(playoffMatches, function (pm) {
  const { home, away, poolKey: pk, results } = pm
  return (levels === pm.levels) &&
    ((away && away.seed === parseInt(seed)) || (home && home.seed === parseInt(seed))) &&
    poolKey === pk
})

const findPendingMatchNonResults = (playoffMatches, levels, poolKey, awayOrHome, seed) => _.filter(playoffMatches, function (pm) {
  const { pending, results } = pm
  return !results &&
    (levels === pm.levels) &&
    pending &&
    pending[awayOrHome] &&
    (pending[awayOrHome].poolKey === poolKey) &&
    (parseInt(pending[awayOrHome].originalSeed) === parseInt(seed))
})

const getPrePendingMatches = (match, playoffMatches) => {

  const data_match = {}

  // this is the match that was updated
  const { levels, pending, _matchCollectionKey, _itemKey } = match

  const { home: pending_home, away: pending_away } = pending ?? {}

  const { matchNumber: matchNumber_home, poolKey: poolKey_home } = pending_home ?? {}
  const { matchNumber: matchNumber_away, poolKey: poolKey_away } = pending_away ?? {}

  // get the MATCH from playoffMatches for the pending HOME team
  const preMatch = {
    home: getPrePendingMatch(levels, playoffMatches, poolKey_home, matchNumber_home),
    away: getPrePendingMatch(levels, playoffMatches, poolKey_away, matchNumber_away)
  }

  if (preMatch.home && preMatch.home.length === 1 && preMatch.home[0].results && preMatch.home[0].results.winner) {
    data_match[_pendingPrefix + _itemKey + '.home'] = preMatch.home[0].results.winner
  }

  if (preMatch.away && preMatch.away.length === 1 && preMatch.away[0].results && preMatch.away[0].results.winner) {
    data_match[_pendingPrefix + _itemKey + '.away'] = preMatch.away[0].results.winner
  }

  return { preMatch, data_match, _matchCollectionKey }
}

/**
 * 
 * @param {object} levels 
 * @param {object} playoffMatches 
 * @param {string} homeOrAway 
 * @param {string} poolKey 
 * @param {number} originalSeed 
 * @returns the pending match
 * @description filters the playoff matches for a match on the `poolKey` and the `originalSeed`
 */
const getPrePendingMatch = (levels, playoffMatches, poolKey, matchNumber) => _.filter(playoffMatches, function (pm) {
  const results = pm ? pm._results : undefined;
  const { winner } = results ?? {}
  return levels === pm.levels && pm.poolKey === poolKey && pm.matchNumber === matchNumber && winner
})
