import React, { useEffect, useState, createContext, useContext } from 'react';
import { ParentContext } from './ParentContext';
import { getDatabase, onValue, ref, remove, set } from 'firebase/database';
import { convertHelpers } from '../../common/convert';
import { allowDistance } from '../../components/map/mapHelper';

export const LocationsContext = createContext();

/**
 * 
 * @param {object} props 
 * @returns - {preview_state, preview_handlers }
 */
const LocationsProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state, paps_state, page_state } = states
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { appLocationTracking } = aps_global ?? {}
  const { allowLocationTracking, locationCoordinates, locationRadius, useRadius } = appLocationTracking ?? {}

  const _locationCoordinates = locationCoordinates ? locationCoordinates.split(',') : null

  const { appUser, appUserLocation } = appUser_state ?? {}
  const { pathViews } = paps_state ?? {}
  const { uid, displayName } = appUser ?? {}

  const [locations, setLocations] = useState()
  const [appLocation, setAppLocation] = useState()

  useEffect(() => {
    if (allowLocationTracking && appUserLocation) {
      const _appLocation = {
        latitude: _locationCoordinates ? _locationCoordinates[0] : appUserLocation.latitude,
        longitude: _locationCoordinates ? _locationCoordinates[1] : appUserLocation.longitude,
      }
      if (allowDistance(_appLocation, appUserLocation, appLocationTracking)) {
        const callback_user = (result) => { }
        if (appUserLocation && pathViews.clients && pathViews.events) {
          saveAppUserLocation(uid, appUserLocation, displayName, pathViews, callback_user)
        }
      } else {
        const callback_delete = (result) => { }
        deleteAppUserLocation(uid, pathViews, callback_delete)
      }
      setAppLocation(_appLocation)

      if (allowLocationTracking) {
        const callback_locations = (result) => {
          if (result) {
            const _locations = {}
            Object.keys(result).forEach(rk => {
              const loc = result[rk]
              const { appUserLocation: locau } = loc ?? {}
              if (allowDistance(_appLocation, locau, appLocationTracking)) {
                _locations[rk] = loc
              }
            })
            setLocations(_locations)
          }
        }
        getLocations(pathViews, callback_locations)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [uid, appUserLocation, locationRadius, useRadius]);

  const providerContext = () => <LocationsContext.Provider value={{ locations_state: { appLocationTracking, appUserUid: uid, appUserLocation, appLocation, allowLocationTracking: allowLocationTracking, locations } }}>
    {props.children}
  </LocationsContext.Provider>

  return providerContext()

}

export default LocationsProvider

const saveAppUserLocation = (uid, appUserLocation, displayName, pathViews, callback) => {

  const db = getDatabase();
  const refString = 'clients/' + pathViews.clients + '/events/' + pathViews.events + '/locations/' + uid

  const updateData = { appUserLocation, displayName }

  try {
    set(ref(db, refString), updateData).then(() => {
      callback({ success: true })
    }).catch((error) => {
      callback({ success: false, error })
    });
  } catch (error) {
    callback({ success: false, error })

  }
}

const deleteAppUserLocation = (uid, pathViews, callback) => {

  const db = getDatabase();
  const refString = 'clients/' + pathViews.clients + '/events/' + pathViews.events + '/locations/' + uid

  try {
    remove(ref(db, refString)).then(() => {
      callback({ success: true })
    }).catch((error) => {
      callback({ success: false, error })
    });
  } catch (error) {
    callback({ success: false, error })

  }
}

const getLocations = (pathViews, callback) => {

  const { clients: clientId, events: eventId } = pathViews
  const refString = '/clients/' + clientId + '/events/' + eventId + '/locations'

  const db = getDatabase();
  const _ref = ref(db, refString);

  onValue(_ref, (snapshot) => {
    const r = convertHelpers.createKeyedObject(snapshot.val())
    callback(r);
  });
}