import _ from 'lodash';
import { gEnums } from '../../../enums/globalEnums';

export const getViewableList = (scrolled, viewListData, sortProp, sortReverse, sortByPositionAfter, dataCount, sortByIndic, isSuperAdminDeveloper) => {

  let vld = null

  const _viewListData = viewListData //isSuperAdminDeveloper ? viewListData : _.filter(viewListData, item => item.itemStatusType !== gEnums.statusTypes.beta && item.itemStatusType !== gEnums.statusTypes.hidden);

  if (sortProp && sortProp.endsWith('Date')) {
    if (sortByPositionAfter) {
      vld = _.orderBy(_viewListData, [(item) => (item[sortProp] ? new Date(item[sortProp]) : 0), 'position'], ['asc']);
    } else {
      vld = _.orderBy(_viewListData, [(item) => (item[sortProp] ? new Date(item[sortProp]) : 0)], ['desc']);
    }
  } else {
    try {
      if (sortByIndic) {
        vld = _.orderBy(_viewListData, [i => i[sortByIndic].toLowerCase(), i => i[sortProp].toLowerCase()]);
      } else {
        if (sortProp && sortProp.endsWith('UTC')) {
          if (sortByPositionAfter) {
            vld = _.orderBy(_viewListData, [i => new Date(i[sortProp]), 'position'], ['asc']);
          } else {
            vld = _.orderBy(_viewListData, [i => new Date(i[sortProp])]);
          }
        } else {
          if (sortByPositionAfter) {
            vld = _.orderBy(_viewListData, [i => i[sortProp].toLowerCase(), 'position']);
          } else {
            switch (sortProp) {
              case 'lastNamee':
                vld = _.orderBy(
                  _viewListData,
                  [
                    item => item[sortProp] ? item[sortProp].toLowerCase() : '',
                    item => sortProp === 'lastName' ? item['firstName'].toLowerCase() : ''
                  ],
                  ['asc', 'asc'] // Sorting order for each criterion
                );
                break;
              default:
                vld = _.orderBy(_viewListData, [i => i[sortProp].toLowerCase()]);
            }
          }
        }
      }
    } catch (error) {
      vld = _.sortBy(_viewListData, sortProp)
    }
  }

  if (scrolled) {
    const nvld = []
    vld.slice(0, dataCount).forEach((vld) => {
      nvld.push(vld);
    });
    return nvld
  } else {
    if (sortReverse) { vld = _.reverse(vld) }
    return vld
  }
}