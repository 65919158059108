import React, { useContext } from 'react';
import { default as ReactSidebar } from 'react-sidebar';
import { SettingParentContext } from '../cnr/contexts/SettingParentContext';
import { SettingsAreaContext } from '../cnr/contexts/SettingsAreaContext';
import { g_cns } from '../common/cns';
import { uniqueKey } from '../common/keys';
import { SettingsAreaMenuWithProvider } from './components/menus/SettingsAreaMenu';
import ViewSettings from './ViewSettings';

/** This is the desktop settings sidebar using react-sidebar */
const SettingsAreaSidebar = () => {

  // settingsParentContext 
  const settingsParentContext = useContext(SettingParentContext)
  const { states } = settingsParentContext ?? {}
  const { page_state } = states ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { sideMenu } = aps_global ?? {}
  const { allowSwipeOpen } = sideMenu ?? {}

  // settingsAreaContext
  const settingsAreaContext = useContext(SettingsAreaContext)
  const { settingsArea_state, settingsArea_handlers } = settingsAreaContext ?? {}
  const { isGlobal, selectedGroupItem } = settingsArea_state ?? {}
  const { handleCloseGroupItem } = settingsArea_handlers ?? {}

  const pullRight = isGlobal ? false : true

  let sidebarCn = g_cns.sbrc

  // the sidebar is NOT visible until there is a selectedGroupItem

  return (
    <ReactSidebar
      sidebar={selectedGroupItem ? <ViewSettings key={uniqueKey('vss', 'vs')} /> : <div></div>}
      open={selectedGroupItem ? true : false}
      sidebarClassName={sidebarCn}
      contentClassName='noOverflowY'
      pullRight={pullRight}
      touch={allowSwipeOpen ? true : false}
      transitions={true}
      onSetOpen={() => { handleCloseGroupItem() }}
      styles={{ sidebar: { background: "black" } }}
    >
      <SettingsAreaMenuWithProvider />
    </ReactSidebar >
  )
}

export default SettingsAreaSidebar