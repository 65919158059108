import React, { useContext } from 'react'
import { Divider, Grid, Header } from 'semantic-ui-react'
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { uniqueKey } from '../../common/keys'
import { gridIconElements } from './gridItems/SegIconGroup'
import { getAppUserAccess } from '../../auth/appUserAccessPermissions'

export const permissionGroupTypes = {
  page: 'page',
  appUser: 'appUser',
  appAdmin: 'appAdmin',
  ticketing: 'ticketing',
  admin: 'admin',
  pageHidden: 'pageHidden',
}

const IconGrid = (props) => {

  const { permissionGroupType } = props

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, appUser_state } = states ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_page } = pageSettings ?? {}
  const { layout: layout_page } = aps_page ?? {}
  const { iconsPerRow } = layout_page ?? {}
  const { gridIcons } = aps_global && aps_global.themedItems ? aps_global.themedItems : {}
  const { iconsPerRow: iconsPerRow_gi, textLimit } = gridIcons ?? {}

  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { displayName } = appUserAccess ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { permissionGroups } = framework_state ?? {}
  const { handleClick_contentItem } = framework_handlers ?? {}

  const _gridItemGroup = permissionGroups && permissionGroups[permissionGroupType]
  const gridItems = gridIconElements({ gridItemGroup: _gridItemGroup, handleIconClick: handleClick_contentItem, textLimit })

  const globalIconsPerRow = iconsPerRow_gi ? iconsPerRow_gi : 3
  let _iconsPerRow = iconsPerRow ? iconsPerRow : globalIconsPerRow

  let dividerText;

  switch (permissionGroupType) {
    case permissionGroupTypes.admin:
      dividerText = 'Admin Only'
      break;
    case permissionGroupTypes.appAdmin:
      dividerText = 'App Admin Only'
      break;
    case permissionGroupTypes.appUser:
      dividerText = displayName
      break;
    case permissionGroupTypes.pageHidden:
      dividerText = 'Admin Hidden'
      break;
    case permissionGroupTypes.ticketing:
      dividerText = 'Ticketing'
      break;
  }

  const grid = (gridRows) => <Grid key={uniqueKey('igr')} columns={_iconsPerRow}>
    {gridRows.map(gridRow => gridRow.map((gridItem, index) => <Grid.Column key={uniqueKey('irg', index)}>{gridItem.element}</Grid.Column>))}
  </Grid>

  const getGridRows = () => {
    let colCount = 0
    const gridRows = []
    let rowCols = []
    gridItems.forEach(col => {
      rowCols.push(col)
      colCount++
      if (colCount >= iconsPerRow) {
        gridRows.push(rowCols)
        colCount = 0
        rowCols = []
      }
    })
    if (rowCols.length > 0) { gridRows.push(rowCols) }
    return gridRows
  }

  const gridWithDivider = () => <React.Fragment>
    {dividerText && <Divider horizontal>
      <Header>
        {dividerText}
      </Header>
    </Divider>}
    {gridItems && grid(getGridRows())}
  </React.Fragment>

  return gridWithDivider()

}

export default IconGrid