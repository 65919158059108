import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Icon, Image, Label, Menu } from 'semantic-ui-react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { getAuthHeaderProps } from '../auth/authSettings';
import { HeaderContext } from '../cnr/contexts/HeaderContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { uniqueKey } from '../common/keys';
import AppUserPages from '../components/user/AppUserPages';
import AppUserSettings from '../components/user/AppUserSettings';
import { appFormTypes } from '../enums/appFormTypes';
import { appIconTypes } from '../enums/appIconTypes';
import { appUserDropdownItems } from './AppUserItems';
import SearchPage from './SearchPage';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';

/**
 * 
 * @returns a Menu.Item
 */
const TopMenuIcons = () => {

  const parentContext = useContext(ParentContext);
  const { states, handlers, fns } = parentContext ?? {}
  const { appUser_state, page_state, paps_state } = states
  const { appUser_handlers } = handlers ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings } = pageSettings ?? {}
  const { page_fns } = fns

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers } = frameworkContext ?? {}
  const { handleClick_contentItem } = framework_handlers ?? {}

  // appUser 
  let { appUser, appUsers, isClone, notificationRequestGranted } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  let { loggedIn, appUserSessionKey, isAdminOrSuper } = appUserAccess ?? {}

  // papsContext 
  const { view, landingView, pathViews, messagingSupported } = paps_state

  // pageContext
  const { pushSimple } = page_fns ?? {}
  const { topMenu } = aps_global ?? {}
  const { menuLocation } = topMenu ?? {}
  const { myProfileCaption, myProfileIcon, showProfileAvatar, appUserCollection, showHeaderDropdown } = aps_appUserSettings ?? {}

  // headerContext
  const headerContext = useContext(HeaderContext)
  const { header_state } = headerContext ?? {} // yes, this is true
  const { allowSiteSearch } = header_state ? header_state : {}

  const [authProps, setAuthProps] = useState()
  const [showTopMenuSearch, setShowTopMenuSearch] = useState()
  const [showAppUserSettings, setShowAppUserSettings] = useState()
  const [showSettings, setShowSettings] = useState();

  const upward = menuLocation === 'bottom' && view === landingView ? true : false

  // get the time form
  useEffect(() => {
    const _authProps = getAuthHeaderProps({ appUserAccess, aps_global, notificationRequestGranted, messagingSupported })
    setAuthProps(_authProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserAccess, notificationRequestGranted]);

  const handleShowSearch = () => setShowTopMenuSearch(!showTopMenuSearch)
  const handleMenuClick = (dropItem, itemKey) => pushSimple({ key: dropItem }, itemKey)
  const handleShowSettings = () => setShowSettings(!showSettings)
  const handleShowAppUserSettings = () => setShowAppUserSettings(!showAppUserSettings)

  const handleIconClick = () => {
    handleClick_contentItem({ key: appUserCollection, itemKey: appUserSessionKey }, appUserSessionKey)
  }

  const handleHomeClick = () => {
    if (pathViews.events) {
      pushSimple({ key: landingView })
    }
  }

  const handleResetAccessLevel = (e) => {
    e.stopPropagation()
    appUser_handlers.handleResetAccessLevel()
  }

  const iSearch = () => <Menu.Item
    onClick={() => { handleShowSearch() }}>
    <Icon name={appIconTypes.search} ></Icon>
  </Menu.Item>

  const icon_user = (iconName, value, cn) => <Icon
    name={iconName}
    className={cn ? cn : null}
  >
    {value ? value : null}
  </Icon>

  const icon_user_clone = (value, cn) => <Icon
    name={'clone outline'}
    className='blink_me_2'
    color={'black'}
    onClick={(e) => handleResetAccessLevel(e)}
  >
  </Icon>

  const userLabel = (iconName, value, cn) => {
    let cnn = 'lbl-user'
    return <Label color={authProps.color} className={cnn} onClick={() => { !isAdminOrSuper && handleIconClick() }}>
      {isClone && icon_user_clone()}
      {icon_user(iconName, value, cn)}
      <Icon name={authProps.iconBell} />
    </Label>
  }

  const menuIcon = (iconName, value, cn) => <Menu.Item>
    {userLabel(iconName, value, cn)}
  </Menu.Item>

  /** Creates an `Icon` that will trigger the `menu dropdown`.
* @returns Icon
*/
  const iTrigger = () => {
    if (authProps) {
      if (authProps.value) {
        if (showProfileAvatar && appUserAccess && appUser.imageUrls && appUser.imageUrls.thumbnail) {
          return <Image src={appUser.imageUrls.thumbnail} avatar></Image>
        } else {
          let cn = 'icon-text'
          return showHeaderDropdown ? icon_user(authProps.name, authProps.value, 'icon-text') : menuIcon(authProps.name, authProps.value, cn)
        }
      } else {
        return showHeaderDropdown ? icon_user(appIconTypes.user) : menuIcon(appIconTypes.user)
      }
    }
  }

  const menuRight = () => {
    const icon = !appUser && appIconTypes.ellipsisVertical
    const trigger = iTrigger()

    if (showHeaderDropdown) {
      return <React.Fragment>
        {allowSiteSearch && iSearch()}
        {trigger}
        <Dropdown item icon={icon} trigger={trigger} upward={upward} >
          <Dropdown.Menu className={'menu-drop-right'} size='tiny'  >
            <Dropdown.Item key={uniqueKey('tmi', 'di', 'home')} icon='home' text='Home' onClick={() => { handleHomeClick() }} />
            {loggedIn && appUserDropdownItems({ appUserAccess, myProfileCaption, myProfileIcon, loggedIn, pathViews, appUserCollection, handleMenuClick, handleShowSettings })}
          </Dropdown.Menu>
        </Dropdown>
      </React.Fragment>
    } else {
      return <React.Fragment>
        {allowSiteSearch && iSearch()}
        {trigger}
      </React.Fragment>
    }

  }

  if (showSettings) {
    return <AppUserPages
      appFormType={appFormTypes.appAuth}
      useFullPageWrapper={true}
      handleCancel={handleShowSettings}
    />
  } else if (showAppUserSettings) {
    return <AppUserSettings
      handleCancel={handleShowAppUserSettings}
    />
  } else {
    return showTopMenuSearch ? <SearchPage handleShowSearch={handleShowSearch} /> : menuRight()
  }
}

export default TopMenuIcons