import React, { useContext, useEffect, useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Image } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const slideStyles = {
  viewAll: {
    float: 'right'
  },
  root: {
    position: 'relative',
    height: '14vh'
  },
  carosel: {
    position: 'relative',
  },
  seg: {
    marginLeft: 4,
    marginRight: 8
  },
  segHeader: {
    paddingBottom: 0
  },
  segBody: {
    padding: 8
  },
  header: {
    margin: 0,
  },
  divider: {
    margin: 10
  },
  slide: {
    padding: 0,
    color: '#fff',
  },
};

const Banners = () => {

  const [index, setIndex] = useState(0);

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { page_state, storage_state } = states
  const { storage_fns } = fns

  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_styles } = pageSettings ?? {}

  const { banners: app_banners } = aps_global ?? {}

  const { globalFiles } = storage_state ?? {}
  const { image: images_global } = globalFiles ?? {}

  let { banners, showBanners, bannersHeight, bannersDuration, bannersSize, bannerUrlSize } = app_banners ?? {}

  const [_banners, set_banners] = useState()
  const [_bannerImages, set_bannerImages] = useState()

  const _bannersDuration = bannersDuration ? bannersDuration * 1000 : 6000

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.appBanners] : {}

  useEffect(() => {
    if (showBanners && banners && images_global) {
      storage_fns.getImageUrls(app_banners, bannerUrlSize, gEnums.storageTypes.image, set_banners)
    } else {
      set_banners()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [globalFiles]);

  useEffect(() => {
    if (_banners) { getBannerImages(_banners, bannersHeight, bannersSize, set_bannerImages) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_banners, _bannersDuration]);

  // const bannerStyle = apply && padding > 0 ? { padding: padding + 'em' } : {}

  const bannersContainer = useRef(null);

  const bannersCarosel = () => {
    return <div {...styleAndClass} id={'sem-carosel'} ref={bannersContainer} >
      <AutoPlaySwipeableViews index={index} onChangeIndex={setIndex} interval={_bannersDuration} >
        {_bannerImages}
      </AutoPlaySwipeableViews>
    </div>
  }

  return _banners && _banners.urls && _banners.urls.length > 0 && _bannerImages ? bannersCarosel() : <div></div>

}

export default Banners

const getBannerImages = (_banners, bannersHeight, bannersSize, callback) => {
  const itemsPerSlide = null
  const caroselItems = []
  let count = 1
  _banners.urls.forEach((url, index) => {
    const b = url.trim()
    if (itemsPerSlide) {
      if (count === itemsPerSlide) {
        caroselItems.push(<div style={Object.assign({}, slideStyles.slide, slideStyles.slide1)}>{caroselImage(b, bannersHeight, bannersSize, index)}</div>)
        count = 1
      } else {
        count++
      }
    } else {
      caroselItems.push(<div className={'banners-image'} key={uniqueKey('sbn', index)} style={Object.assign({}, slideStyles.slide, slideStyles.slide1)}>{caroselImage(b, bannersHeight, bannersSize, index)}</div>)
    }
  })
  callback(caroselItems)
}


const caroselImage = (b, bannersHeight, bannersSize, index) => {
  const sty = (!bannersHeight || bannersHeight === 0) ? { display: 'block' } : { display: 'block', height: bannersHeight }
  return <Image size={bannersSize ? bannersSize : 'large'} key={uniqueKey('sbn', index)} centered src={b} style={sty} alt={'image'} />
}