import _ from 'lodash';
import { fsfn_auth } from '../../functions/fbAuth';
import { gEnums } from '../../enums/globalEnums';
import { getRecentAuths } from '../../common/filtering';

const limit = 100

export const rts = {
  handleGetAppUserAuths: 'handleGetAppUserAuths',
  handleAppUserAuths: 'handleAppUserAuths',
}

export const appUserAuthReducerInitialState = (initState) => {
  return { ...initState }
};

export const appUserAuthReducer = (state, action) => {

  const { appUserCollection, pathViews } = state
  const { type, dispatch, appProfileData } = action

  const { handleAppUserAuths } = appUserAuthHandlers(dispatch)

  switch (type) {

    case rts.handleGetAppUserAuths:
      let _staticAppUsers;
      if (appUserCollection && appProfileData && appProfileData[appUserCollection]) {
        _staticAppUsers = appProfileData[appUserCollection]
        if (_staticAppUsers) {
          const _sau = _.sortBy(_staticAppUsers, 'lastName')
          const chunks = _.chunk(_sau, limit);
          fsfn_auth.getAuthsByChunkEmail(chunks, pathViews, true, handleAppUserAuths, _staticAppUsers)
        }
      }
      return { ...state }

    case rts.handleAppUserAuths:
      const { result } = action
      const { found, staticAppUsers } = result ?? {}
      if (found && staticAppUsers) {
        const recent = getRecentAuths(found, 1)
        Object.keys(staticAppUsers).forEach((key) => {
          const _staticAppUser = staticAppUsers[key]
          const { email } = _staticAppUser
          const _af = email ? _.find(found, { email: email.toLowerCase() }) : null
          const _ar = email ? _.find(recent, { email: email.toLowerCase() }) : null
          _staticAppUser.foundType = gEnums.authListTypes.notFound
          if (_af) { _staticAppUser.foundType = gEnums.authListTypes.found }
          if (_ar) { _staticAppUser.foundType = gEnums.authListTypes.recent }
          if (_af || _ar) {
            _staticAppUser.metadata = _af.metadata
          }
        })
        const appUserAuthList = _.groupBy(staticAppUsers, 'foundType')
        return { ...state, appUserAuthList }
      }
      return { ...state }


    default:
      return { ...state }
  }
}

export const appUserAuthHandlers = (dispatch) => {
  return {
    handleGetAppUserAuths: (appProfileData) => { dispatch({ type: rts.handleGetAppUserAuths, dispatch, appProfileData }) },
    handleAppUserAuths: (result) => { dispatch({ type: rts.handleAppUserAuths, dispatch, result }) },
  }
} 