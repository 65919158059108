import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Menu } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';

const SearchMenu = () => {

  // parentContext
  const parentContext = useContext(ParentContext)
  const { states } = parentContext ?? {}
  const { page_state, paps_state, eventInfo_state } = states
  const { staticViews } = eventInfo_state ?? {}

  // papsContext  
  const { view, viewKey } = paps_state ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_styles } = pageSettings ?? {}

  // dataContext 
  let storageItem = 'alpha-item-'
  storageItem += viewKey ? view + '-' + viewKey : view

  const _menuContainerId = 'alpha-menu' // + view

  // local state
  const [staticViewKeys, setStaticViewKeys] = useState()
  const [selectedItem, setSelectedItem] = useState({})
  const [currentItem, setCurrentItem] = useState({})
  const [currentItems, setCurrentItems] = useState({})
  const [showNext, setShowNext] = useState()
  const [snc, setSnc] = useState({})
  const [sidebar, setSidebar] = useState({})
  const [currentStates, setCurrentStates] = useState([])

  const _isVert = true

  if (snc && snc.className && snc.className.indexOf('alpha-float') < 0) {
    snc.className += ' alpha-float abs-side'
    snc.className += ' hidden'
    // if (verticalOpacity) {
    //   snc.className += ' vo-' + verticalOpacity
    // } 
  }

  const scrollableDivRef = useRef(null);

  useEffect(() => {
    const htm = (e) => {
      // Check if the touch event target is within the scrollable div 
      if (e.target.closest('.menu-alphabet') && e.target.closest('.menu-alphabet').id === 'alpha-menu') {
        e.preventDefault();
      }
    };

    document.addEventListener('touchmove', htm, { passive: false });

    return () => {
      document.removeEventListener('touchmove', htm);
    };
  }, [scrollableDivRef]);

  useEffect(() => {
    const data = {
      displayTypes: { ...gEnums.displayTypes }
    }
    const _sidebar = { _0: Object.keys(data) }
    const _currentSidebar = { _0: data }
    setSidebar(_sidebar)
    setCurrentStates(_currentSidebar)
    // setStaticViewKeys(staticViews ? Object.keys(staticViews) : [])
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [states]);

  useEffect(() => {
    setStaticViewKeys(staticViews ? Object.keys(staticViews) : [])
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [staticViews]);

  useEffect(() => {
    const _sidebar = { ...sidebar }
    const nextIndex = currentItem.sbIndex + 1
    const deleteIndex = currentItem.sbIndex + 2
    const _sbIndex = '_' + currentItem.sbIndex
    if (currentItem && currentStates[_sbIndex] && currentStates[_sbIndex][currentItem.itemValue]) {
      const item = currentStates[_sbIndex][currentItem.itemValue]
      let skis;
      if (_.isObject(item)) {
        skis = item ? Object.keys(item) : []
      } else if (_.isArray(item)) {
        skis = item
      } else if (_.isBoolean(item)) {
        skis = item.toString()
      } else {
        skis = item
      }
      _sidebar['_' + nextIndex] = skis
      currentStates['_' + nextIndex] = item

      for (let i = 10; i >= 0; i--) {
        if (i >= deleteIndex) {
          delete _sidebar['_' + i]
          delete currentStates['_' + i]
        }
      }

      setSidebar(_sidebar)
      setCurrentStates(currentStates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentItem]);

  const handleItemClick = (e, { name }) => {
    setSelectedItem(name)
  }

  const handleUpdateAlpha = (itemValue, sbIndex) => {
    if (showNext && (itemValue !== currentItem.itemValue)) {
      const _currentItems = { ...currentItems }
      const _sbIndex = '_' + sbIndex
      _currentItems[_sbIndex] = itemValue
      setCurrentItem({ itemValue, sbIndex });
      setCurrentItems(_currentItems);
    }
  }

  const handleTouchMove = (event, sbIndex) => {
    const touchedItem = document.elementFromPoint(
      event.touches[0].clientX,
      event.touches[0].clientY
    );
    if (touchedItem && touchedItem.attributes && touchedItem.attributes['itemval'] && touchedItem.attributes['itemval'].value) {
      const itemValue = touchedItem.attributes['itemval'].value
      if (itemValue !== currentItem.itemValue) {
        setCurrentItem({ sbIndex, sbIndex })
      }
    }
  }

  const handleSetAlphaItem = (sbIndex) => {
    setSelectedItem({ sbIndex, sbIndex })
    setShowNext(false)
  }

  const handleShowSecond = (e, sbIndex) => {
    if (e) { handleTouchMove(e, sbIndex) }
    setShowNext(true)
  }
  const handleKillLetter = () => setShowNext()

  const menuItem = (svk, index, sbIndex) => {
    // console.log('svk', svk, selectedItem)
    const _currentItem = currentItems['_' + sbIndex]
    const _selected = _currentItem === svk
    return <Menu.Item
      // className={currentStatic === svk ? 'sel' : null}
      id={'am_' + index}
      itemval={svk}
      touchtype={'mi'}
      name={svk}
      key={uniqueKey('abm', svk)}
      active={_selected}
      onClick={handleItemClick}

      onTouchStart={() => { _isVert && handleUpdateAlpha(svk, sbIndex) }}

      onMouseMove={() => { _isVert && handleUpdateAlpha(svk, sbIndex) }}
      onTouchMove={(e) => { _isVert && handleTouchMove(e, sbIndex) }}

      // end
      onMouseUp={(e) => { _isVert && handleSetAlphaItem(svk, sbIndex) }}
      onTouchEnd={() => { _isVert && handleSetAlphaItem(svk, sbIndex) }}
    >
      {svk}
    </Menu.Item>
  }

  const staticMis = (keys, sbIndex) => {
    if (_.isArray(keys)) {
      const mis = keys.map((svk, index) => {
        return menuItem(svk, index, sbIndex)
      })
      return mis
    } else {
      return menuItem(keys, 0, sbIndex)
    }
  }

  let cn = snc.className

  if (cn) {
    cn += showNext ? ' ' : ' dead'
  }

  const menuItems = () => {
    const itemList = Array.from({ length: 10 }, (_, index) => index);
    return itemList.map((item) => (
      menu(sidebar['_' + item], item)
    ))
  }

  const menu = (keys, _keyy) => <Menu
    key={'sb' + _keyy}
    ref={scrollableDivRef}
    id={_menuContainerId}
    onMouseDown={(e) => { _isVert && handleShowSecond() }}

    onTouchStart={(e) => { _isVert && handleShowSecond(e) }}
    onTouchMove={(e) => { _isVert && handleTouchMove(e) }}

    inverted
    borderless
    vertical={_isVert ? true : false}
    compact={_isVert ? true : null}
    style={snc.style}
  >
    {keys && staticMis(keys, _keyy)}
  </Menu >

  return <dim className={'search-float'}>
    {menuItems()}
  </dim>

}

export default SearchMenu
