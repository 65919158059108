import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { PageContext } from '../../../../global/cnr/contexts/PageContext'
import { getAlphaList } from '../../../../global/common/convert'
import { AlphabetMenuWithProvider } from '../../../../global/components/menus/AlphabetMenu'
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper'
import Wrapper from '../../../../global/wrappers/Wrapper'
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'
import { gEnums } from '../../../../global/enums/globalEnums'

const TeamList = (props) => {

  // pageContext
  const pageContext = useContext(PageContext)
  const { page_fns } = pageContext ?? {}
  const { pushSimple } = page_fns ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { teams_info } = sportsSeason_state ?? {}
  const { teams } = teams_info ?? {}

  const [selectedTeams, setSelectedTeams] = useState(teams)

  const handleCancel = () => {
    sportsSeason_handlers.handleShow_sportsElem()
    if (props.handleCancel) {
      props.handleCancel()
    }
  }

  const handleAlphaChange = (alphaItem) => {
    const _alphaChar = String.fromCharCode(alphaItem)
    const { alphaList } = getAlphaList(teams, _alphaChar, 'name')
    setSelectedTeams(alphaList)
  }

  const handleClick = (team) => {
    sportsSeason_handlers.handleShow_sportsElem()
    if (props.handleSelect) {
      props.handleSelect(team)
    } else {
      team && pushSimple && sportsSeason_handlers.handleGoTo_team(pushSimple, team)
    }
  }

  const teamsList = () => {
    if (selectedTeams) {
      const _teams = _.sortBy(selectedTeams, 'name')
      return _teams.map(team => {
        const { name, sections, levels } = team
        return <div onClick={() => { handleClick(team) }}>
          <div>{name}</div>
          <div>{levels}</div>
          <div>{_.startCase(sections)}</div>
        </div>
      })
    }
  }

  const content = () => <div className='teams-list vlo'>
    {teamsList()}
  </div>

  const alphaMenu = () => {

    const grouping = {
      alphaDisplayType: gEnums.alphaDisplayTypes.vertical,
      useVerticalHidden: true,
      verticalOpacity: gEnums.opacityTypes.medium,
      restrictToExisting: true,
    }

    return <AlphabetMenuWithProvider
      useVertical={true}
      origin={'teamList'}
      dataItems={teams}
      sortProp={'name'}
      handleAlphaChange={handleAlphaChange}
      grouping={grouping}
    />
  }

  const wrapper = () => <Wrapper
    header={alphaMenu()}
    content={content()}
  ></Wrapper>

  const fpw_teamList = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleCancel}
    topperCaption={'Team Lookup'}
  ></FullPageWrapper>

  return fpw_teamList()
}

export default TeamList