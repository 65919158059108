import _ from 'lodash';
import { convertHelpers } from '../../common/convert';
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { fs_db } from '../../firestoreData/appData/fsAppData';
import { fs_dbu } from '../../firestoreData/appData/fsAppDataUpdate';
import { grts, responseReducers } from './reducerHelpers/dispatchProps';

// https://web-push-book.gauntface.com/demos/payload-examples/

const rts = {
  handle_init: 'handle_init',
  handle_init_response: 'handle_init_response',
  handleAmmend_data: 'handleAmmend_data',
  handleAmmend_meetingData: 'handleAmmend_meetingData',
  handleAmmend_meetingRoomData: 'handleAmmend_meetingRoomData',
  handleSave_meetingRoom: 'handleSave_meetingRoom',
  handleSave_newMeeting: 'handleSave_newMeeting',
  handleSaveMeeting: 'handleSaveMeeting',
  handleSet_attendees: 'handleSet_attendees',
  handleSet_meetingDisplayType: 'handleSet_meetingDisplayType',
  // handleSet_meetingRooms: 'handleSet_meetingRooms', 
  handleSet_selectedAttendees: 'handleSet_selectedAttendees',
  handleSet_subMeetingType: 'handleSet_subMeetingType',
  handle_setStep: 'handle_setStep',
  handle_update: 'handle_update',
  ...grts
}

export const _meetingDisplayTypes = {
  scheduledMeetings: 'scheduledMeetings',
  rooms: 'rooms',
  roomBlocks: 'roomBlocks'
}

export const _meetingSubTypes = {
  addMeeting: 'addMeeting',
  addMeetingRoom: 'addMeetingRoom',
  addMeetingRoomDate: 'addMeetingRoomDate',
  attendees: 'attendees',
  editMeeting: 'editMeeting',
  editMeetingRoom: 'editMeetingRoom',
  scheduledMeeting: 'scheduledMeeting',
  meetingRoom: 'meetingRoom',
  meetingRoomBlock: 'meetingRoomBlock',
  timeSlot: 'timeSlot',
}

export const meetingsReducer = (state, action) => {

  const { currentMeetingData, staticView, steps, selectedAttendees, currentMeetingRoomData, selectedMeeting, selectedMeetingRoomBlock, selectedMeetingRoom, selectedTimeSlot, timeInterval_settings, pathViews, forProfileItem, appUserSessionKey } = state
  const { type, dispatch } = action

  const { handle_init_response, handle_setStep, handleSet_subMeetingType } = meetingsHandlers(dispatch)

  switch (type) {

    case rts.handle_init:
      const refPath_meetings = createRefPath_event(pathViews, ['_meetingRooms'])
      fs_db.get_data({ refPath: refPath_meetings, callback: handle_init_response })
      return { ...state }

    case rts.handle_init_response:
      const { data: _mrs } = action
      if (_mrs && Object.keys(_mrs).length > 0) {
        const { scheduledMeetings, attendeeMeetings } = getTimeSlots(_mrs, timeInterval_settings, forProfileItem, appUserSessionKey)
        const opts = []
        Object.keys(_mrs).forEach(key => {
          const dataItem = _mrs[key]
          opts.push({ key: key, value: key, text: dataItem.name })
        })
        Object.keys(_mrs).forEach(k => {
          const { location } = _mrs[k] ?? {}
          if (location) {
            _mrs[k].name = staticView && staticView[location] ? staticView[location].name : location
          }
        })
        const _mrsg = _.groupBy(_mrs, 'name')
        convertHelpers.createItemKeys(_mrs)
        return { ...state, scheduledMeetings, attendeeMeetings, meetingRoomBlocks: _mrsg, meetingRooms: _mrs, defaultOptions: { meetingRoom: opts } }
      }
      return { ...state }

    case rts.handle_setStep:
      return { ...state, step: action.step }

    case rts.handleSet_meetingDisplayType:
      return { ...state, meetingDisplayType: action.meetingDisplayType }

    case rts.handleSet_subMeetingType:
      let _otherData = {}
      switch (action.meetingSubType) {
        case _meetingSubTypes.addMeeting:
          const _currentMeetingData = {
            meetingRoom: selectedMeetingRoom._itemKey,
            startTime: selectedTimeSlot.formattedTime
          }
          return { ...state, meetingSubType: action.meetingSubType, currentMeetingData: _currentMeetingData }
        case _meetingSubTypes.scheduledMeeting:
          _otherData = { selectedMeeting: action.item ? action.item : selectedMeeting }
          break;
        case _meetingSubTypes.meetingRoom:
          _otherData = { selectedMeetingRoom: action.item ? action.item : selectedMeetingRoom }
          break;
        case _meetingSubTypes.meetingRoomBlock:
          _otherData = { selectedMeetingRoomBlock: action.item ? action.item : selectedMeetingRoomBlock }
          break;
        case _meetingSubTypes.timeSlot:
          _otherData = { selectedTimeSlot: action.item ? action.item : selectedTimeSlot }
          break;
        default:
          switch (state.meetingSubType) {
            case _meetingSubTypes.scheduledMeeting:
              _otherData = { selectedMeeting: null }
              break;
            case _meetingSubTypes.meetingRoom:
              _otherData = { selectedMeetingRoom: null }
              break;
            case _meetingSubTypes.meetingRoomBlock:
              _otherData = { selectedMeetingRoomBlock: null }
              break;
            case _meetingSubTypes.timeSlot:
              _otherData = { selectedTimeSlot: null }
              break;
            default:
            // nothing
          }
      }
      return { ...state, meetingSubType: action.meetingSubType, ..._otherData }

    case rts.handleSet_selectedAttendees:
      return { ...state, selectedAttendees: action.selectedAttendees }

    case rts.handleSet_attendees:
      return { ...state, attendees: action.attendees }

    case rts.handleAmmend_data:
      switch (state.meetingSubType) {
        case _meetingSubTypes.addMeeting:
          return { ...state, currentMeetingData: action.data }
        case _meetingSubTypes.addMeetingRoom:
          return { ...state, currentMeetingRoomData: action.data }
        case _meetingSubTypes.addMeetingRoomDate:
          return { ...state, currentMeetingRoomDateData: action.data }
        default:
          return { ...state }
      }

    case rts.handle_update:
      switch (state.meetingSubType) {
        case _meetingSubTypes.addMeetingRoom:
          const refPath_2 = createRefPath_event(pathViews, ['_meetingRooms'])
          delete currentMeetingRoomData._deleteProps
          if (currentMeetingRoomData._itemKey) {
            fs_dbu.update_doc(refPath_2, currentMeetingRoomData, handleSet_subMeetingType)
          } else {
            fs_dbu.add_doc(refPath_2, currentMeetingRoomData, handleSet_subMeetingType)
          }
          return { ...state }
        case _meetingSubTypes.addMeetingRoomDate:
          return { ...state, currentMeetingRoomDateData: action.data }
        default:
          return { ...state }
      }

    case rts.handleAmmend_meetingData:
      return { ...state, currentMeetingData: action.currentMeetingData }

    case rts.handleAmmend_meetingRoomData:
      return { ...state, currentMeetingRoomData: action.currentMeetingRoomData }

    // case rts.handleSet_meetingRooms:
    //   const _meetings = getTimeSlots(action.meetingRooms, timeInterval_settings)
    //   return { ...state, meetingRooms: action.meetingRooms, meetings: _meetings }

    // SAVE
    case rts.handleSaveMeeting:
      return { ...state }


    case rts.handleSave_newMeeting:
      const cb = (res) => {
        handleSet_subMeetingType(_meetingSubTypes.meetingRoom)
        handle_setStep({ index: 0, name: steps[0] })
      }

      const _dataToUpdate = {
        name: currentMeetingData.name,
        attendees: selectedAttendees
      }

      if (currentMeetingData.description) {
        _dataToUpdate.description = currentMeetingData.description
      }

      const dataToUpdate = {
        ['timeSlots.' + selectedTimeSlot.index]: { ..._dataToUpdate }
      }

      const refPath_1 = createRefPath_event(pathViews, ['_meetingRooms', selectedMeetingRoom._itemKey])
      fs_dbu.update_doc(refPath_1, dataToUpdate, cb)

      return { ...state }


    case rts.handleSave_meetingRoom:
      const cb_mr = () => {
        handleSet_subMeetingType()
      }
      const refPath_2 = createRefPath_event(pathViews, ['_meetingRooms'])
      if (currentMeetingRoomData._itemKey) {
        fs_dbu.update_doc(refPath_2, currentMeetingRoomData, cb_mr)
      } else {
        fs_dbu.add_doc(refPath_2, currentMeetingRoomData, cb_mr)
      }
      return { ...state }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { questionProps: null })

    default:
      return { ...state }
  }
}

export const meetingsInitialState = (initState) => {
  return { ...initState }
};

export const meetingsHandlers = (dispatch) => {
  return {
    handle_init_response: (data) => { dispatch({ type: rts.handle_init_response, dispatch, data }) },
    handle_init: (data) => { dispatch({ type: rts.handle_init, dispatch, data }) },
    handle_setStep: (step) => { dispatch({ type: rts.handle_setStep, dispatch, step }) },
    handle_update: () => { dispatch({ type: rts.handle_update, dispatch }) },
    handleAmmend_data: (data) => { dispatch({ type: rts.handleAmmend_data, dispatch, data }) },
    handleAmmend_meetingData: (currentMeetingData) => { dispatch({ type: rts.handleAmmend_meetingData, dispatch, currentMeetingData }) },
    handleAmmend_meetingRoomData: (currentMeetingRoomData) => { dispatch({ type: rts.handleAmmend_meetingRoomData, dispatch, currentMeetingRoomData }) },
    handleSave_meetingRoom: (startTimer) => { dispatch({ type: rts.handleSave_meetingRoom, dispatch, startTimer }) },
    handleSave_newMeeting: () => { dispatch({ type: rts.handleSave_newMeeting, dispatch }) },
    handleSaveMeeting: (appProfileData, unsubscribe) => { dispatch({ type: rts.handleSaveMeeting, dispatch, appProfileData, unsubscribe }) },
    handleSet_attendees: (attendees) => { dispatch({ type: rts.handleSet_attendees, dispatch, attendees }) },
    handleSet_meetingDisplayType: (meetingDisplayType) => { dispatch({ type: rts.handleSet_meetingDisplayType, dispatch, meetingDisplayType }) },
    // handleSet_meetingRooms: (meetingRooms) => { dispatch({ type: rts.handleSet_meetingRooms, dispatch, meetingRooms }) },
    handleSet_selectedAttendees: (selectedAttendees) => { dispatch({ type: rts.handleSet_selectedAttendees, dispatch, selectedAttendees }) },
    handleSet_subMeetingType: (meetingSubType, item) => { dispatch({ type: rts.handleSet_subMeetingType, dispatch, meetingSubType, item }) },
  }
}

const getTimeSlots = (meetingRooms, timeInterval_settings, forProfileItem, appUserSessionKey) => {

  let scheduledMeetings = []
  let attendeeMeetings = []

  // loop the meetingRooms
  Object.keys(meetingRooms).forEach(mrk => {

    const meetings_mr = []
    const attendeeMeetings_mr = []

    const meetingRoom = meetingRooms[mrk]

    const { location, startDate, startTime, endTime, timeSlots, _itemKey, timeInterval } = meetingRoom

    const dateTimeSlots = (fd) => {

      const time_interval = timeInterval ? timeInterval : timeInterval_settings

      // Create an array to store the time slots
      const _timeSlots = [];
      const _timeSlotsTaken = [];

      if (startTime && endTime) {
        const _start = {
          hour: startTime.split(':')[0],
          minute: startTime.split(':')[1],
        }

        const _end = {
          hour: endTime.split(':')[0],
          minute: endTime.split(':')[1],
        }

        // Start time and end time
        const _startTime = new Date();
        _startTime.setHours(_start.hour, _start.minute, 0); // 8:00 AM

        const _endTime = new Date();
        _endTime.setHours(_end.hour, _end.minute, 0); // 8:00 PM

        const currentTime = new Date(_startTime);
        let timeSlotIndex = 0

        while (currentTime <= _endTime) {

          let isAttendeeMeeting;

          const timeSlot = timeSlots && timeSlots[timeSlotIndex] ? timeSlots[timeSlotIndex] : {}
          const { name, attendees, description } = timeSlot ?? {}

          const attendeeCount = attendees ? attendees.length : 0

          if (attendees && forProfileItem && appUserSessionKey) {
            if (attendees.includes(appUserSessionKey)) {
              isAttendeeMeeting = true
            }
          }

          // Format time as "hh:mm AM/PM"
          const formattedTime = getFormattedTime(currentTime)

          timeSlotIndex++

          // Increment time by 30 minutes
          currentTime.setMinutes(currentTime.getMinutes() + time_interval);

          if (attendeeCount) {
            _timeSlotsTaken.push(formattedTime)
            const _meeting = {
              _itemKey: 'mr' + mrk,
              name,
              location,
              startDate,
              startTime: formattedTime,
              endTime: getFormattedTime(currentTime),
              meetingRoom: _itemKey,
              description,
              attendees
            }
            meetings_mr.push(_meeting)

            isAttendeeMeeting && attendeeMeetings_mr.push(_meeting)
            scheduledMeetings = [...scheduledMeetings, ...meetings_mr]
            attendeeMeetings = [...attendeeMeetings, ...attendeeMeetings_mr]
          } else {
            _timeSlots.push(formattedTime)
          }
        }
      }

      meetingRoom.availablity = { taken: _timeSlotsTaken.length, free: _timeSlots.length }

    }

    dateTimeSlots(meetingRoom)

  })

  return { scheduledMeetings, attendeeMeetings }

};

const getFormattedTime = (currentTime) => currentTime.toLocaleTimeString([], {
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
});
