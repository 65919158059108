import React, { createContext, useContext, useState } from 'react'
import { QrReader } from 'react-qr-reader'
import { UiItemContext } from '../../../cnr/contexts/UiItemContext'
import FullPageWrapper from '../../../wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper'

export const QrScanContext = createContext();

const QrScan = (props) => {

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers } = uiItemContext ?? {}

  const [scannedData, setScannedData] = useState()

  const handleCancel = () => {
    if (props.handleCancel) {
      props.handleCancel()
    } else if (item_handlers.handleShow_itemSidebar) {
      item_handlers.handleShow_itemSidebar()
    }
  }

  const handleError = err => console.error(err)

  const handleScan = (data) => {
    if (data) {
      try {
        const _scannedData = JSON.parse(data)
        _scannedData.qrKey = 1234
        setScannedData(_scannedData)
        props.handleScannedData && props.handleScannedData(data)
      } catch (error) {
        setScannedData({ qrKey: 5678 })
        props.handleScannedData && props.handleScannedData(data)
      }
    }
  }

  const qrReader = () => <QrReader
    delay={300}
    onError={handleError}
    onResult={handleScan}
    style={{ width: '100%' }}
    constraints={{ facingMode: { ideal: "environment" } }}
    className="test"
  />

  const content = () => {
    if (scannedData) {
      return <QrScanContext.Provider value={{ qrScan_state: { scannedData: scannedData } }} >
        <div>{JSON.stringify(scannedData)}</div>
        {/* <UiAddEdit dataModificationType={dataModificationTypes.add} ignoreItemStatus={true} /> */}
      </QrScanContext.Provider >
    } else {
      return qrReader(128)
    }
  }

  const wrapper = () => <Wrapper
    content={content()}
    wrapperType={wrapperTypes.padded}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleCancel}
    topperCaption={'QR Code'}
  />

  return fullPageWrapper()
}

export default QrScan