const thumbStatusTypes = {
  p: 'p'
}

const touchAreaTypes = {
  top: 'top',
  bottom: 'bottom'
}

export const thumbTouch = {
  parent: null,
  swipeData: null,
  statGroupStatItem: null,
  groupColor: null,
  resultGroupItemName: null,
  sectors: null,
  touch: {
    eopInterval: 50,
    isTouch: false,
    isTouching: false,
    isMouse: false,
    offsetTop: 0,
    offsetLeft: 0,
    touchStartX: 0,
    touchStartY: 0,
    touchNowX: 0,
    touchNowY: 0,
    swipedRight: function () {
      const tsx = this.touchStartX - (this.offsetLeft)
      const tnx = this.touchNowX - (this.offsetLeft)
      const srd = tnx - tsx
      console.log('srd', srd)
      return srd > 100
    },
    touchStartYAdjusted: function () {
      return this.touchStartY - (this.offsetTop)
    },
    touchStartXAdjusted: function () {
      return this.touchStartX - (this.offsetLeft)
    },
    touchNowYAdjusted: function () {
      return this.touchNowY - (this.offsetTop)
    },
    touchNowXAdjusted: function () {
      return this.touchNowX - (this.offsetLeft)
    },
    touchXDiff: function () {
      const tsx = this.touchStartX - (this.offsetLeft)
      const tnx = this.touchNowX - (this.offsetLeft)
      return (tsx > tnx) ? (tsx - tnx) : (tnx - tsx)
    },
    touchYDiff: function () {
      const tsy = this.touchStartY - (this.offsetTop)
      const tny = this.touchNowY - (this.offsetTop)
      return (tsy > tny) ? (tsy - tny) : (tny - tsy)
    },
    line: function () {
      const l = {
        sx: this.touchStartX - this.offsetLeft,
        ex: this.touchNowX - this.offsetLeft,
        sy: this.touchStartYAdjusted(),
        ey: this.touchNowYAdjusted()
      }
      return l
    },
    rect: function () {
      const startX = this.touchStartXAdjusted()
      const nowX = this.touchNowXAdjusted()
      const startY = this.touchStartYAdjusted()
      const nowY = this.touchNowYAdjusted()
      const r = {
        w: this.touchXDiff(),
        h: this.touchYDiff(),
        sx: nowX > startX ? startX : nowX,
        sy: nowY > startY ? startY : nowY,
      }
      return r
    },
    circle: function () {
      const startX = this.touchStartXAdjusted()
      const startY = this.touchStartYAdjusted()
      const c = {
        sx: startX,
        sy: startY,
        r: this.touchXDiff(),
      }
      return c
    },
    touchEnd: 0,
    moveDiffX: 0,
    moveDiffY: 0,
    scrollStartX: 0,
    scrollStartY: 0,
    direction: 'n',
    up: false,
    top: false,
  },
  courtData: (isStart, maxHeight) => {

    const { width, halfH } = thumbTouch.sectors.courtSpace

    let touchD = thumbTouch.touchData

    if (isStart) {

      // START 

      let xNow = thumbTouch.touch.touchStartXAdjusted()
      let yNow = thumbTouch.touch.touchStartYAdjusted()

      touchD.reset(xNow, yNow)

      touchD.start.touchArea = (yNow < halfH) ? touchAreaTypes.top : touchAreaTypes.bottom

      // get the area for the swipeArea from the GROUP, not the item
      // the statGroup may consist of multiple items, select  

      // top AND bottom
      thumbTouch.resultGroupItemName = thumbTouch.parent.swipeArea[touchD.start.touchArea] // get the correction statGroup

      if (thumbTouch.resultGroupItemName) {

        // attack, dig, etc  
        // get the item from within the statGroup.statItems
        // resultGroupItemName (top/bottom)
        thumbTouch.statGroupStatItem = thumbTouch.parent.statGroup.statItems[thumbTouch.resultGroupItemName]

        if (thumbTouch.statGroupStatItem) {
          thumbTouch.groupColor = thumbTouch.statGroupStatItem.color
          thumbTouch.swipeData = thumbTouch.statGroupStatItem.swipeData

          //  CHANGE THE REQUEST VIEW
          //  this.settings.handlers.resetView(this.resultGroupItemName)
        } else {
          thumbTouch.groupColor = thumbTouch.parent.statView.color
          thumbTouch.swipeData = thumbTouch.statGroupStatItem.swipeData
        }

        if (thumbTouch.swipeData) {
          const z = thumbTouch.sectors.zones.find(xNow, yNow, touchD)
          if (z) { touchD.start.zone = z.name }
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {

      // setting the resultItems
      const { resultItems, xtraResultItems } = thumbTouch.swipeData

      //  if current, calculate location of the cursor 
      let yNow = thumbTouch.touch.touchNowYAdjusted()
      let xNow = thumbTouch.touch.touchNowXAdjusted()

      // set the defaults
      touchD.above = false
      touchD.below = false
      touchD.lol = false
      touchD.ror = false
      touchD.csr.xtra = resultItems.p

      touchD.current.x = xNow
      touchD.current.y = yNow

      if (yNow < 0) {
        touchD.above = true
      } else if (yNow > maxHeight) {
        touchD.below = true
      } else if (xNow < 0) {
        touchD.lol = true
      } else if (xNow > width) {
        touchD.ror = true
      }

      touchD.current.touchArea = (yNow < halfH) ? touchAreaTypes.top : touchAreaTypes.bottom
      const z = thumbTouch.sectors.zones.find(xNow, yNow, touchD)
      const sameArea = touchD.areasSame()



      if (z && !sameArea) { touchD.current.zone = z.name }


      if (!sameArea) {
        touchD.crossedCourt = true  // we have crossed the court  
        touchD.crossedBack = false
      } else {
        if (touchD.crossedCourt) {
          touchD.crossedBack = true // we have crossed over and back 
        }
        touchD.crossedCourt = false
      }

      if (touchD.crossedCourt) {
        // touchD.csr.swipeResult = resultItems.crossedCourt
        touchD.csr.xtra = resultItems.p
      }

      if (touchD.crossedBack) {
        // touchD.csr.swipeResult = resultItems.crossedBack
        touchD.csr.xtra = resultItems.p
      }

      if (touchD.above) {
        // touchD.csr.swipeResult = resultItems.error
        touchD.csr.xtra = (xtraResultItems.above) ? (xtraResultItems.above) : thumbStatusTypes.p
      }

      if (touchD.lol) {
        if (sameArea) {
          touchD.resetSmall()
        } else {
          touchD.csr.swipeResult = resultItems.error
          touchD.csr.xtra = (xtraResultItems.lol) ? (xtraResultItems.lol) : thumbStatusTypes.p
        }
      }

      if (touchD.ror) {
        if (sameArea) {
          touchD.resetSmall()
        } else {
          touchD.csr.swipeResult = resultItems.error
          touchD.csr.xtra = (xtraResultItems.ror) ? (xtraResultItems.ror) : thumbStatusTypes.p
        }
      }

      if (touchD.below) {
        touchD.csr.swipeResult = resultItems.error
        touchD.csr.xtra = (xtraResultItems.below) ? (xtraResultItems.below) : thumbStatusTypes.p
      }

      if (sameArea) {
        touchD.actionLineColor = 'grey'
      } else (
        touchD.actionLineColor = 'black'
      )

      if (touchD.hasDipped()) {
        touchD.dipped = true
      }

      if (touchD.csr.swipeResult) {
        touchD.actionLineColor = thumbTouch.parent.settings.colors[touchD.csr.swipeResult]
      }

      thumbTouch.parent.setState({
        courtOuterColor: thumbTouch.groupColor,
        actionLineColor: touchD.actionLineColor,
        action: thumbTouch.resultGroupItemName,
      })
    }

    return true
  },
  touchData: {
    reset: function (xNow, yNow) {
      this.start.x = xNow
      this.start.y = yNow
      this.csr.swipeResult = thumbStatusTypes.p
      this.csr.xtra = thumbStatusTypes.p
      this.crossedCourt = false
      this.crossedBack = false
      this.actionLineColor = 'grey'
      this.dipped = null
    },
    resetSmall: function () {
      this.csr.swipeResult = thumbStatusTypes.p
      this.csr.xtra = thumbStatusTypes.p
      this.crossedCourt = false
      this.crossedBack = false
    },
    csr: {
      swipeResult: thumbStatusTypes.p,
      xtra: thumbStatusTypes.p,
    },
    below: false,
    above: false,
    lol: false,
    ror: false,
    dipped: null,
    areasSame: function (swipeProps) {
      if (swipeProps) {
        if (this.start.touchArea === this.current.touchArea) {
          switch (swipeProps.xtra) {
            case 'top':
            case 'bottom':
            case 'wide':
            case 'net':
            case 'out':
              return false
            default:
              return true
          }
        }
      } else {
        if (this.start.touchArea === this.current.touchArea) {
          return true
        }
      }

    },
    hasDipped: function () {
      const dipp = 75
      if (this.areasSame()) {
        if (this.start.touchArea === touchAreaTypes.top) {
          if ((this.start.y - this.current.y) > dipp) {
            return true
          }
        } else {
          if ((this.current.y - this.start.y) > dipp) {
            return true
          }
        }
      }
    },
    start: {
      touchArea: touchAreaTypes.top,
      y: 0,
      x: 0,
      zone: 0
    },
    current: {
      touchArea: touchAreaTypes.top,
      y: 0,
      x: 0,
      zone: 0
    },
    crossedCourt: false,
    crossedBack: false,
    actionLineColor: 'black',
    movement: function (cw, ch, touchD) {
      return {
        startX: this.start.x,
        startY: this.start.y,
        endX: this.current.x,
        endY: this.current.y,
        cw: cw,
        ch: ch,
        zones: {
          start: {
            area: this.start.touchArea,
            zone: this.start.zone
          },
          end: {
            area: this.current.touchArea,
            zone: this.current.zone
          }
        }
      }
    }
  },
}
