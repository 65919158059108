import { arrayRemove, arrayUnion } from "firebase/firestore";
import { dispatchProps } from '../../../cnr/reducers/reducerHelpers/dispatchProps';
import { gEnums } from '../../../enums/globalEnums';
import { fsfn_updateLinkItems } from "../../../functions/fbUpdate";
import { createRefPath } from '../../appData/appRefPaths';
import { fs_dbu } from "../../appData/fsAppDataUpdate";
import { getBaseRef } from '../../helpers/getBaseRef';
import { updateLinkProfile } from "./updateLinkProfile";

const _showLog = true
const _useFunctions = true
const _allowArrayUpdate = true

const _updateGroupLimit = 200

/** Update the arrays in the database */
export const arrayUpdateToDatabase = (props) => {

  // https://firebase.google.com/docs/firestore/manage-data/add-data#update_elements_in_an_array

  const {
    actionItem,
    dispatch,
    dataActionType,
    logging,
    page_fns,
    paps_state,
    preview,
    remove,
  } = props

  const { getGviDeps } = page_fns ?? {}
  const { view, pathViews } = paps_state
  const viDeps = getGviDeps(view)
  const baseRef = getBaseRef(viDeps, pathViews)

  switch (dataActionType) {

    case gEnums.itemLinkTypes.linkProfile:
      updateLinkProfile(dispatch, baseRef, actionItem, remove, logging).then(res => {
        console.log('res', res)
      }).catch(err => {
        console.log('err', err)
      })
      break;

    case gEnums.itemLinkTypes.linkItems:
      updateLinkItems(dispatch, baseRef, actionItem, remove, logging)
      break;

    case gEnums.itemLinkTypes.linkAllItems:
      if (_useFunctions) {
        fsfn_updateLinkItems(pathViews, preview, _updateGroupLimit, dispatch)
      } else {
        updateAllLinkItems(baseRef, preview, dispatch)
      }
      break;

    default:
    // nothing
  }
}

const getDocKey = (dataLinkType, itemKey) => {
  let docKey = itemKey;
  let docKeyData;
  switch (dataLinkType) {
    // case gEnums.dataLinkTypes.mapCollection:
    //   docKey = Object.keys(itemKey)[0]
    //   docKeyData = itemKey
    //   break;
    default:
      docKey = itemKey
  }
  return { docKey, docKeyData }
}

/** Updates two collection documents */
const updateLinkItems = (dispatch, baseRef, actionItem, remove, logging) => {

  const { actionProps } = actionItem
  const { vl, linkValue, linkData } = actionProps
  // const { vl, view, viewKey, viewItem, linkValue, linkData } = actionProps
  const { arrayName, soloLink } = vl ?? {}
  const { showArrayUpdates } = logging ?? {}

  const { _view, _viewItem } = linkData ?? {}
  const { name: name_view, key: key_view } = _view ?? {}
  const { name: name_viewItem } = _viewItem ?? {}

  let ar1Name = arrayName
  let ar2Name = arrayName

  if (!ar1Name) { ar1Name = name_view }
  if (!ar2Name) { ar2Name = name_viewItem }

  let valueToAdd;

  if (linkValue) { valueToAdd = { [linkValue]: key_view } }

  arrayPromise(actionProps, baseRef, ar1Name, ar2Name, remove, soloLink, valueToAdd).then(() => {
    if (showArrayUpdates || _showLog) { console.log('Array Updated') }
    dispatch({ type: dispatchProps.success });
  }).catch(err => {
    if (showArrayUpdates || _showLog) { console.log('Array Not Updated.' + err) }
    dispatch({ type: dispatchProps.error }, err);
  });
}

/**
 * handles the batch updates
 * @param {object} uc 
 */
const collectionPromise = async (uc) => {
  const promises = []
  try {
    const all = []
    uc.forEach(u => {
      const { refPath, collectionKey, key, dataToUpdate } = u
      const _ref = createRefPath([collectionKey, key], refPath)
      if (_allowArrayUpdate) {
        promises.push(fs_dbu.update_doc(_ref, dataToUpdate, null, true))
      } else {
        all.push({ _ref, dataToUpdate })
      }
    })
    // if (allowArrayUpdate) {
    //   await batch.commit();
    // } else {
    //   console.log('all', all)
    // }
  } catch (error) {
    console.error(error);
  }

  return Promise.all(promises)
}

/** Updates two collection documents */
const updateAllLinkItems = async (baseRef, preview, callback) => {
  const _updateCollections = getArrayCollections(baseRef, preview)
  console.log('_updateCollections', _updateCollections)
  // const res = await collectionPromises(_updateCollections)
  callback({ type: dispatchProps.success, callback })
}


/**
 * 
 * @param {string} baseRef 
 * @param {object} preview 
 * @returns 
 */
const getArrayCollections = (baseRef, preview) => {
  const { refPath } = baseRef
  const pks = Object.keys(preview)
  const cis = getCollectionItems(pks, preview)
  const updateCollection = getUpdateCollection(pks, cis, refPath)
  return updateCollection
}

/**
 * get the collection items
 * @param {array} pks 
 * @param {object} preview 
 * @returns 
 */
const getCollectionItems = (pks, preview) => {

  const cis = {}

  Object.keys(preview).forEach((collectionKey, index) => {

    if (!cis[collectionKey]) { cis[collectionKey] = {} }

    const otherCollectionKey = index === 0 ? pks[1] : pks[0]

    const p = preview[collectionKey]

    Object.keys(p).forEach(key => {
      const otherKeys = p[key][otherCollectionKey]
      Object.keys(otherKeys).forEach(otherKey => {
        const item = otherKeys[otherKey]
        const { selected } = item
        if (!cis[collectionKey]) { cis[collectionKey] = {} }
        if (!cis[collectionKey][key]) { cis[collectionKey][key] = {} }
        if (!cis[collectionKey][key][otherCollectionKey]) { cis[collectionKey][key][otherCollectionKey] = {} }
        cis[collectionKey][key][otherCollectionKey][otherKey] = { selected: selected }
      })
    })
  })

  return cis
}

/**
 * gets the update collection
 * @param {array} pks 
 * @param {object} cis 
 * @param {string} refPath 
 * @returns 
 */
const getUpdateCollection = (pks, cis, refPath) => {

  const updateCollection = []
  let items = []
  let counter = 0

  Object.keys(cis).forEach((collectionKey, index) => {
    const ci = cis[collectionKey]
    const otherCollectionKey = index === 0 ? pks[1] : pks[0]
    Object.keys(ci).forEach(key => {
      const si = ci[key]
      const otherItems = si[otherCollectionKey]
      const { _selected } = getSelected(otherItems)
      const dataToUpdate = { [otherCollectionKey]: _selected }
      const data = { refPath, collectionKey, key, dataToUpdate }
      counter++
      items.push(data)
      if (counter === _updateGroupLimit) {
        updateCollection.push(items)
        items = []
        counter = 0
      }
    })
  })

  if (items.length > 0) {
    updateCollection.push(items)
  }

  return updateCollection
}

/**
 * get the selected/unselected items
 * @param {object} collectionItem 
 * @returns 
 */
const getSelected = (collectionItem) => {
  const _selected = []
  const _unSelected = []
  Object.keys(collectionItem).forEach(ciKey => {
    const item = collectionItem[ciKey]
    const { selected } = item
    if (selected) {
      _selected.push(ciKey)
    } else {
      _unSelected.push(ciKey)
    }
  })
  return { _selected, _unSelected }
}

const arrayPromise = (actionProps, baseRef, ar1Name, ar2Name, remove, soloLink, valueToAdd) => {

  const { refPath } = baseRef

  const { allowMultiSelect, dataLinkType, linkData, viewItemKey, deleteKeys } = actionProps

  const { _view, _viewItem } = linkData ?? {}
  const { name: name_view, key: key_view } = _view ?? {}
  const { name: name_viewItem, keys: keys_viewItem } = _viewItem ?? {}

  const _viewRef = createRefPath([name_view, key_view], refPath)

  const promises = []

  if (allowMultiSelect) {
    // add all the viewItemKeys
    keys_viewItem.forEach(vik => {
      const { docKey, docKeyData } = getDocKey(dataLinkType, vik)
      const _viewItemRef = createRefPath([name_viewItem, docKey], refPath)
      addItemPromise(promises, dataLinkType, _viewItemRef, _viewRef, docKey, key_view, ar1Name, ar2Name, false, soloLink, valueToAdd, docKeyData)
    })
    deleteKeys.forEach(vik => {
      const { docKey, docKeyData } = getDocKey(dataLinkType, vik)
      const _viewItemRef = createRefPath([name_viewItem, docKey], refPath)
      addItemPromise(promises, dataLinkType, _viewItemRef, _viewRef, docKey, key_view, ar1Name, ar2Name, true, soloLink, valueToAdd, docKeyData)
    })
  } else {
    const _ref1 = createRefPath([name_viewItem, viewItemKey], refPath)
    addItemPromise(promises, dataLinkType, _ref1, _viewRef, viewItemKey, key_view, ar1Name, ar2Name, remove, soloLink, valueToAdd)
  }

  return Promise.all(promises)

}

const addItemPromise = (promises, dataLinkType, ref1, ref2, viewItemKey, viewKey, ar1Name, ar2Name, remove, soloLink, valueToAdd, viewItemKeyData) => {

  const allow = true
  let allowOther = false

  switch (dataLinkType) {
    case gEnums.dataLinkTypes.mapCollection:
      // nothing
      allowOther = false
      break;
    default:
      allowOther = true
  }

  if (valueToAdd) {
    if (remove) {
      const uda1 = { [ar1Name]: arrayRemove(viewKey) }
      const r1 = fs_dbu.doc_update(ref1, uda1)
      allow && allowOther && promises.push(r1)

      const uda2 = { [ar2Name]: arrayRemove(viewItemKey) }
      const r2 = fs_dbu.doc_update(ref2, uda2)
      allow && promises.push(r2)
    } else {
      const uda3 = { [ar1Name]: valueToAdd }
      const r3 = fs_dbu.doc_set(ref1, uda3, true)
      allow && allowOther && promises.push(r3)

      const uda4 = { [ar2Name]: arrayUnion(viewItemKey) }
      const r4 = fs_dbu.doc_update(ref2, uda4)
      allow && promises.push(r4)
    }
  } else if (soloLink) {
    if (remove) {
      const dataUpdate1 = { ar1Name: [viewKey] }
      const r1 = fs_dbu.doc_update(ref1, dataUpdate1)
      allow && promises.push(r1)

      const dataUpdate2 = { ar2Name: [viewItemKey] }
      const r2 = fs_dbu.doc_update(ref2, dataUpdate2)
      allow && promises.push(r2)
    } else {
      const dataUpdate3 = { ar1Name: [viewKey] }
      const r3 = fs_dbu.doc_update(ref1, dataUpdate3)
      allow && promises.push(r3)

      const dataUpdate4 = { ar2Name: [viewItemKey] }
      const r4 = fs_dbu.doc_update(ref2, dataUpdate4)
      allow && promises.push(r4)
    }
  } else {
    if (remove) {
      !allow && console.log('Remove 1', ref1.path, 'REMOVE >>', ar1Name, viewKey)
      const uda1 = { [ar1Name]: arrayRemove(viewKey) }
      const r1 = fs_dbu.doc_update(ref1, uda1)
      allow && allowOther && promises.push(r1)
      !allow && console.log('Remove 2', ref2.path, 'REMOVE >>', ar2Name, viewItemKey)
      const uda2 = { [ar2Name]: arrayRemove(viewItemKey) }
      const r2 = fs_dbu.doc_update(ref2, uda2)
      allow && promises.push(r2)
    } else {
      !allow && console.log('Adding 1', ref1.path, 'ADD >>', ar1Name, viewKey)
      const uda3 = { [ar1Name]: arrayUnion(viewKey) }
      const r3 = fs_dbu.doc_update(ref1, uda3)
      allow && allowOther && promises.push(r3)

      !allow && console.log('Adding 2', ref2.path, 'ADD >>', ar2Name, viewItemKey)
      const uda4 = { [ar2Name]: arrayUnion(viewItemKeyData ? viewItemKeyData : viewItemKey) }
      const r4 = fs_dbu.doc_update(ref2, uda4)
      allow && promises.push(r4)
    }
  }
}

