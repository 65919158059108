import { getAuth, EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, PhoneAuthProvider, TwitterAuthProvider } from "firebase/auth";
import * as firebaseui from "firebaseui";
import { signInMethodTypes } from "../../../cnr/reducers/SignInReducer";

export const firebaseAuth = (appSignIn, methods, ignoreEmailLine, linkOnly, callback) => {

  const { useRecaptcha, allowEmailLink, allowEmail, allowFacebook, allowGoogle, allowPhone, allowTwitter } = appSignIn ?? {}

  // const hmmm = () => {
  //   const pwd = document.getElementById('ui-sign-in-password-input')
  // }

  const addMethod = (m) => {
    switch (m) {
      case signInMethodTypes.emailAndPassword:
        return {
          provider: EmailAuthProvider.PROVIDER_ID,
          signInMethod: (allowEmailLink || linkOnly) && !ignoreEmailLine ? EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD : EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
          appName: 'meetingEvolution'
        }
      case signInMethodTypes.facebook:
        return {
          provider: FacebookAuthProvider.PROVIDER_ID,
        }
      case signInMethodTypes.google:
        return {
          provider: GoogleAuthProvider.PROVIDER_ID,
        }
      case signInMethodTypes.phone:
        return {
          provider: PhoneAuthProvider.PROVIDER_ID,
          recaptchaParameters: { size: !useRecaptcha ? 'invisible' : 'normal', badge: 'inline' }
        }
      case signInMethodTypes.twitter:
        return {
          provider: TwitterAuthProvider.PROVIDER_ID,
        }
      default:
        break;
    }
  }

  const getSignInOptions = () => {
    const items = []
    if (methods) {
      methods.forEach(m => {
        items.push(addMethod(m))
      })
    } else {
      if (allowEmail) { items.push(addMethod(signInMethodTypes.emailAndPassword)) }
      if (allowFacebook) { items.push(addMethod(signInMethodTypes.facebook)) }
      if (allowGoogle) { items.push(addMethod(signInMethodTypes.google)) }
      if (allowPhone) { items.push(addMethod(signInMethodTypes.phone)) }
      if (allowTwitter) { items.push(addMethod(signInMethodTypes.twitter)) }
    }
    return items
  }

  const uiConfig = {
    signInOptions: getSignInOptions(),
    callbacks: {
      signInSuccessWithAuthResult: function (authResult) {
        console.log('authResult', authResult)
      },
      uiShown: function () {
        // The widget is rendered.
        // Hide the loader. 
        // hmmm()
        // document.getElementById('loader').style.display = 'none';
      },
    },
  };
  const auth = getAuth()
  var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
  ui.start("#firebaseui-auth-container", uiConfig);
}
