import React, { useContext, useEffect, useReducer } from "react";
import { getAppUserAccess } from "../../../../global/auth/appUserAccessPermissions";
import { ParentContext } from "../../../../global/cnr/contexts/ParentContext";
import { getFirstObject } from "../../../../global/common/convert";
import { sportsMatchHandlers, sportsMatchInitialState, sportsMatchReducer } from "../reducers/SportsMatchReducer";
import { SeasonMatchesContext } from "./SeasonMatchesContext";
import { SportsSeasonContext } from "./SportsSeasonContext";

export const SportsMatchContext = React.createContext()

export default function SportsMatchProvider(props) {

  const { sportsMatchViewType, fromLevelDates, forOverview, forSummary } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext)
  const { states, fns } = parentContext ?? {}
  const { appUser_state, paps_state, page_state } = states ?? {}
  const { appUser_fns } = fns ?? {}
  const { appUsers, accessClone } = appUser_state ?? {}
  const { pathViews, pathName, viewKey } = paps_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { sportSettings } = aps_global ?? {}
  const { matchResultFullBackground } = sportSettings ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const {
    _sportData,
    gameScoringType,
    latestSeason,
    matches_info,
    mediaMatches,
    parentDataKeys,
    sportData,
    sportPermissions,
    sportsKey,
    tournamentType,
    useScoreBlock,
    viewItem,
    playoffDates,
  } = sportsSeason_state ?? {}

  const { _matchStatusGroups } = mediaMatches ?? {}
  const { allUpdates } = _matchStatusGroups ?? {}

  const { playoffMatches, matches_realtime, matches_matchDay } = matches_info ?? {}
  const { matches_seasonal: pk_matches_seasonal } = parentDataKeys ?? {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_fns } = seasonMatchesContext ?? {}
  const componentContexts = { sportsSeasonContext, seasonMatchesContext }

  const init_state = {
    _sportData,
    appUserAccess,
    forOverview,
    forSummary,
    fromLevelDates,
    gameScoringType,
    latestSeason,
    pathName,
    pathViews,
    pk_matches_seasonal,
    playoffMatches,
    sportData,
    sportPermissions,
    sportsKey,
    sportsMatchViewType,
    viewItem,
    viewKey,
  }
  const [sportsMatch_state, sportsMatch_dispatch] = useReducer(sportsMatchReducer, init_state, sportsMatchInitialState)
  const sportsMatch_handlers = sportsMatchHandlers(sportsMatch_dispatch, sportsMatch_state)
  const { match_true } = sportsMatch_state ?? {}
  const { _itemKey } = match_true ?? {}

  const sportsMatch_cn = seasonMatches_fns ? seasonMatches_fns.getMatchCn(match_true, useScoreBlock, tournamentType, playoffDates, allUpdates, matchResultFullBackground) : {}

  const getMatchEditAllowance = () => {
    const vaProps = { match_true, sportsKey, accessClone }
    const _matchAllows = appUser_fns.validateAccess_allowScoring(vaProps)
    sportsMatch_handlers.handleSet_matchAllowances(_matchAllows)
  }

  useEffect(() => {
    getMatchEditAllowance()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [match_true]);

  // useEffect(() => {
  //   if (sportsShowType && sportsShowOptions && sportsShowOptions.matchKey === _itemKey) {
  //     const xx = { match_rt: match_true, match_rt_scorer: null }
  //     sportsMatch_handlers.handleSet_realtimeMatchData(xx)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sportsShowType]);

  useEffect(() => {
    // these are all of the realtimeMatches 
    if (matches_realtime) {
      const rtm_currentMatch = matches_realtime ? matches_realtime[_itemKey] : {}
      if (rtm_currentMatch) {
        const rtm = getFirstObject(rtm_currentMatch)
        const { item } = rtm ?? {}
        const { displayName, results } = item ?? {}
        if (results) {
          sportsMatch_handlers.handleSet_realtimeMatchData({ match_rt: item, match_rt_scorer: displayName })
        } else {
          sportsMatch_handlers.handleSet_realtimeMatchData({ match_rt: null, match_rt_scorer: null })
        }
      } else {
        sportsMatch_handlers.handleSet_realtimeMatchData({ match_rt: null, match_rt_scorer: null })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [matches_realtime, _itemKey]);

  useEffect(() => {
    // these are all of the realtimeMatches 
    if (matches_matchDay) {
      const rtm_currentMatch = matches_matchDay ? matches_matchDay[_itemKey] : {}
      if (rtm_currentMatch) {
        const rtm = getFirstObject(rtm_currentMatch)
        const { item } = rtm ?? {}
        const { displayName, results } = item ?? {}
        if (results) {
          sportsMatch_handlers.handleSet_matchDayMatchData({ match_dy: results, match_rt_scorer: displayName })
        } else {
          sportsMatch_handlers.handleSet_matchDayMatchData({})
        }
      } else {
        sportsMatch_handlers.handleSet_matchDayMatchData({})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [matches_matchDay, _itemKey]);

  return <SportsMatchContext.Provider value={{ sportsMatch_state, sportsMatch_handlers, componentContexts, sportsMatch_cn }}>
    {props.children}
  </SportsMatchContext.Provider>

}