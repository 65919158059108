import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { uniqueKey } from '../common/keys'
import { getListStyle } from '../viewSettings/sorting/sortHelpers'
import DraggableElement from './DraggableElement'

const DroppableElement = (props) => {

  const {
    allowSort,
    columnKey,
    ddKey,
    group,
    groupKey,
    horizontal,
    index,
    portal,
    portalCn,
    wrapperCns,
  } = props

  const draggableElement = (index, element, _droppableId) => <DraggableElement
    element={element}
    portal={portal}
    portalCn={portalCn}
    index={index}
    draggableId={_droppableId + '_' + index}
  // groupKey={groupKey}
  // columnKey={columnKey}
  ></DraggableElement>

  const droppableElement = () => {

    const _uk = uniqueKey('dd', 'dr', groupKey, index)
    const { elements } = group
    let cn = 'drop-list active drag drag-dropper'

    if (wrapperCns) { cn += ' ' + wrapperCns }

    let _droppableId = columnKey + '_' + groupKey
    let _droppableKey = uniqueKey(_uk, ddKey)

    _droppableKey = _droppableKey.replace(' ', '-')

    return <Droppable
      key={_droppableKey}
      droppableId={_droppableId}
      direction={horizontal ? 'horizontal' : 'vertical'}
    >
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={cn}
          key={uniqueKey(_uk, 'dd', ddKey)}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {elements.map((element, index) => allowSort ? draggableElement(index, element, _droppableId) : <div key={uniqueKey(_uk, 'de', index)} className={'dd-menu'}>{element}</div>)}
        </div>
      )}
    </Droppable>
  }

  return droppableElement()

}

export default DroppableElement
