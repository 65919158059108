import { Icon, Image, Menu } from "semantic-ui-react";
import React, { useContext } from 'react'
import { FrameworkContext } from "../../cnr/contexts/FrameworkContent";
import { PapsContext } from "../../cnr/contexts/PapsContext";
import { PageContext } from "../../cnr/contexts/PageContext";
import { StorageContext } from "../../cnr/contexts/StorageContext";
import { uniqueKey } from "../../common/keys";

/**
 * 
 * @param {object} props (items, horz, alignRight, handleClick)
 * @returns 
 */
const MenuItems = (props) => {

  const { items, horz, alignRight, handleClick } = props

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { desktopMode } = framework_state ?? {}

  // storageContext
  const storageContext = useContext(StorageContext);
  const { storage_fns } = storageContext ?? {}

  // pageContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { styles, sideMenu } = aps_global ?? {}
  const { sideMenus } = styles ? styles : {}
  const { excludeCurrentPage } = sideMenu ?? {}

  const getItemStyle = () => {
    const miStyle = {
      paddingTop: sideMenus && sideMenus.apply && sideMenus.padding ? sideMenus.padding + 'em' : null,
      paddingBottom: sideMenus && sideMenus.apply && sideMenus.padding ? sideMenus.padding + 'em' : null,
      fontSize: sideMenus && sideMenus.fontSize && sideMenus.fontSize ? sideMenus.fontSize + 'em' : null,
    }
    return miStyle
  }

  const sideItems = () => {

    const mis = []

    Object.keys(items).forEach((key, index) => {
      const item = items[key]
      let mi;
      // don't show if on the same page
      if (item && ((item.key !== paps_state.view) || desktopMode)) {
        mi = miPush(item, index, horz, (desktopMode && item.key === paps_state.view) ? true : false)
      } else {
        if (!excludeCurrentPage) {
          mi = miPush(item, index, horz, (item.key === paps_state.view) ? true : false, true)
        }
      }
      if (mi) {
        if (alignRight) {
          mis.unshift(mi)
        } else {
          mis.push(mi)
        }
      }
    })
    return mis
  }

  const itemIcon = (item) => {
    const { useIconImage, iconImage, adm } = item
    let { icon, iconColor } = item
    const iconImageUrls = storage_fns.getIconUrl(useIconImage, iconImage)
    const { urls } = iconImageUrls ?? {}
    const { full, thumbnail } = urls ?? {}
    const src = thumbnail ? thumbnail : full
    if (adm) {
      icon = 'user secret'
      iconColor = 'yellow'
    }
    if (src) {
      return <Image style={getIconStyle()} name={icon} src={src} alt={'image'} />
    } else {
      if (icon) {
        return <Icon color={iconColor ? iconColor : 'grey'} style={getIconStyle()} name={icon} />
      }
    }
    return null
  }

  const miPush = (item, index, horz, selected, noClick) => {

    const menuIcon = itemIcon(item)

    let className = item.className ? item.className : null
    let caption = item.caption
    if (selected) { className += ' sel' }

    try {
      return <Menu.Item
        style={getItemStyle()}
        className={className}
        key={uniqueKey('mi', index)}
        onClick={() => handleClick(item, noClick)}>
        {menuIcon && menuIcon}
        {!horz && caption}
      </Menu.Item>
    } catch (error) {
      // nothing
    }
  }

  const getIconStyle = () => {
    const miStyle = {
      fontSize: sideMenus && sideMenus.iconSize && sideMenus.iconSize ? sideMenus.iconSize + 'em' : null,
    }
    return miStyle
  }

  return items ? sideItems() : []

}

export default MenuItems