import { where } from "firebase/firestore"
import { copyObj } from "../../../../../global/common_web/copy"
import { createRefPath_event } from "../../../../../global/firestoreData/appData/appRefPaths"
import { createFsDocKey, fs_db } from "../../../../../global/firestoreData/appData/fsAppData"
import { fs_dbu } from "../../../../../global/firestoreData/appData/fsAppDataUpdate"

export const updateSportsTeams = async (database_fns, teams, pathViews, parentKeys, sportsKey, parentDataKeys, callback) => {
  const result = await saveNewTeams(database_fns, teams, pathViews, parentKeys, sportsKey, parentDataKeys)
  console.log('result', result)
  callback(result)
}

/**
 * Adds a NEW team to the `teams` collection for each team in `teams`
 * @param {object} teams 
 * @param {object} pathViews 
 */
const saveNewTeams = async (database_fns, teams, pathViews, parentKeys, sportsKey) => {

  const refs = []
  const existingTeams = []
  const promises = []

  teams.forEach(async (newTeam) => {

    const _newTeam = copyObj(newTeam)

    const { _itemKey, name, _schoolKey } = _newTeam

    console.log(_newTeam)

    const currentRef = createRefPath_event(pathViews, ['teams'])
    const wheres = []
    wheres.push(where('name', '==', name))

    Object.keys(parentKeys).forEach(pk => {
      switch (pk) {
        case 'sports':
          wheres.push(where('parentKeys.' + pk, '==', sportsKey))
          break;
        default:
          wheres.push(where('parentKeys.' + pk, '==', parentKeys[pk]))
      }
      parentKeys[pk] = pathViews[pk]
    })

    const { item: existingTeam } = await database_fns.get_data({ refPath: currentRef, wheres, opts: { returnFirstObject: true, ignoreId: true, returnKey: true } })

    if (!existingTeam) {
      const teamKey = _itemKey ? _itemKey : createFsDocKey()
      _newTeam.parentKeys = copyObj(parentKeys)
      _newTeam.parentKeys.schools = _schoolKey

      delete _newTeam.checked
      delete _newTeam.disabled
      delete _newTeam.key
      delete _newTeam.id
      delete _newTeam._isNew
      delete _newTeam._teamKey
      delete _newTeam._itemKey
      delete _newTeam._schoolKey

      const _ref = createRefPath_event(pathViews, ['teams', teamKey])
      refs.push({
        ref: _ref,
        team: _newTeam
      })
      console.log('_ref', _ref, _newTeam)
      promises.push(fs_dbu.set_doc(_ref, _newTeam, true, null, true))
    } else {
      existingTeams.push(existingTeam)
    }
  })

  return Promise.all(promises)

}

// Aquinas Academy
// Mohawk

// Albert Gallatin
// Allderdice
// Ambridge
// Aquinas Academy
// Armstrong
// Avonworth

// Baldwin
// Beaver
// Beaver County Christian
// Belle Vernon
// Bentworth
// Bethel Park
// Bethlehem-Center
// Bishop Canevin
// Blackhawk
// Brownsville
// Burrell
// Brentwood
// Butler

// California
// Canon McMillian
// Carlynton
// Central Catholic
// Central Valley
// Charleroi
// Chartiers Valley
// Chartiers Houston
// Connellsville

// Deer Lakes
// Derry

// East Allegheny
// Eden Christian Academy
// Elizabeth Forward
// Ellwood City

// Fox Chapel
// Franklin Regional
// Freedom
// Freeport

// Gateway
// Geibel Catholic
// Greensburg C.C.
// Greensburg Salem

// Hampton
// Hempfield
// Highlands
// Hopewell

// Indiana

// Jeannette

// Keystone Oaks
// Kiski Area
// Knoch

// Latrobe
// Laurel Highlands
// Leechburg
// Ligonier Valley

// Mars
// McGuffey
// McKeesport
// Mohawk
// Montour
// Moon
// Mt. Pleasant
// Mt. Lebanon

// Neshannock
// North Allegheny
// North Catholic
// North Hills
// Norwin
// Obama Academy
// Our Lady of Sacred Heart
// Penn Hills
// Penn Trafford
// Peters Township
// Pine Richland
// Plum
// Quaker Valley
// Ringgold
// Riverside
// Riverview
// St, Joseph
// Seneca Valley
// Serra Catholic
// Seton LaSalle
// Sewickley Academy
// Shady Side Academy
// Shaler
// South Allegheny
// South Fayette
// South Park
// South Side
// Southmoreland
// Springdale
// Steel Valley
// Thomas Jefferson
// Trinity
// Trinity Christian
// Uniontown
// Upper St.Clair
// Washington
// Waynesburg Central
// West Allegheny
// West Mifflin
// Winchester Thurston
// Woodland Hills
// Yough
