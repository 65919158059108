import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import { getFirstObject } from '../../common/convert';
import { ActionContext } from '../../cnr/contexts/ActionContext';
import { DataModifyContext } from '../../cnr/contexts/DataModifyContext';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { ViewSettingsComponentContext } from '../../cnr/contexts/ViewSettingsComponentContext';
import { ViewSettingsContext } from '../../cnr/contexts/ViewSettingsContext';
import { gEnums } from '../../enums/globalEnums';
import { appIconTypes } from '../../enums/appIconTypes';
import { getMenuHeaderItems } from '../components/menus/menuItemHelpers';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import { getDataModifyItems } from './dropdownItemGroups/getDataModifyItems';
import { getItemHeaderItems } from './dropdownItemGroups/getItemHeaderItems';
import { getSectionHeaderItems } from './dropdownItemGroups/getSectionHeaderItems';
import { ddDivider, getHddItems } from '../helpers/hddItems';

/**
 * 
 * @param {object} props 
 * @returns catch all header dropdown handling multiple events for multiple components
 */
const HeaderDropdown = (props) => {

  const { dataHeaderType, buttonPermissions, viType, clicks, menuItem, menuItemAllows, headerProps, opts } = props
  const { clickOption } = clicks ?? {}

  let showAddAuth = true

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state, page_state, paps_state } = states
  const { pathViews } = paps_state
  const { pageSettings } = page_state
  const { aps_viewItems, aps_appUserSettings } = pageSettings ?? {}

  // authContext 
  const { appUser: authAppUser } = appUser_state ?? {}
  const { appUserAccess } = authAppUser ?? {}

  // pageContext  
  const { appUserCollection } = aps_appUserSettings ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { desktopMode } = framework_state ?? {}

  // viewSettingsContext
  const viewSettingsContext = useContext(ViewSettingsContext);
  const { viewSettings_handlers } = viewSettingsContext ?? {}

  //  viewSettingsComponentContext
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext);
  const { vsc_handlers, vsc_state } = viewSettingsComponentContext ?? {}
  const { swipedItem, updateProps } = vsc_state ?? {}
  const { swipeToSelected } = vsc_handlers ?? {}

  // dataModifyContext
  const dataModifyContext = useContext(DataModifyContext)
  const { dataModify_state, dataModify_handlers } = dataModifyContext ?? {}
  const { isList, modifyData, dataListKey, dataListData } = dataModify_state ?? {}

  const { item: firstData } = dataListData ? getFirstObject(dataListData) : {}
  const _modifyData = firstData ? firstData : modifyData

  // actionContext
  const actionContext = useContext(ActionContext);
  const { action_state, action_handlers } = actionContext ?? {}
  const { confirmation: actionConfirmation, fetching } = action_state ?? {}

  const gvisKeys = aps_viewItems ? Object.keys(aps_viewItems) : []
  const vit = null
  let showAccessPermissions = (vit === appUserCollection && _modifyData)

  // local state
  const [hddButtons, setHddButtons] = useState();
  const [confirmOn, setConfirmOn] = useState()
  const [ddItems, setDDItems] = useState()

  const getDDItems = () => {

    const ddis = []
    const ddas = []

    let addType;
    if (swipedItem) {
      switch (swipedItem.key) {
        case 'props':
          addType = 'addProp'
          break;
        case 'views':
          addType = 'addPage'
          break;
        case 'viewItems':
          addType = 'addPageItem'
          break;
        case 'rssFeeds':
          addType = 'addRssFeed'
          break;
        default:
        // nothing
      }
    }

    switch (dataHeaderType) {

      case gEnums.dataHeaderTypes.dataModify:
        ddis.push(getDataModifyItems({
          appUserAccess, gvisKeys, hddButtons, isList, showAccessPermissions, showAddAuth, vit,
        }))
        break;

      case gEnums.dataHeaderTypes.dataSubHeader:
        if (_modifyData && _modifyData.imageUrl) {
          ddis.push(...hddButtons.modifyImage)
        }
        break;

      case gEnums.dataHeaderTypes.itemHeader:
        ddis.push(...getItemHeaderItems({
          addType, buttonPermissions, hddButtons, viType
        }))
        break;

      case gEnums.dataHeaderTypes.menuHeader:
        ddis.push(...getMenuHeaderItems({
          menuItemAllows, desktopMode, menuItem, hddButtons,
        }))
        break;

      case gEnums.dataHeaderTypes.sectionHeader:
        ddis.push(...getSectionHeaderItems({
          addType, hddButtons, opts, swipedItem,
        }))
        break;

      default:
      // nothing
    }

    const allDdis = [...ddis, ...ddas]

    if (allDdis && allDdis.length > 0) {
      allDdis.unshift(ddDivider(0))
      allDdis.unshift(<Dropdown.Header key={uniqueKey('hdd', 'dh', dataHeaderType)} className={'hddh'} content='Options' />)
    }

    setDDItems(allDdis)
  }

  useEffect(() => {
    if (actionConfirmation) {
      setConfirmOn(true)
      const timer = setTimeout(() => setConfirmOn(false), actionConfirmation.success === true ? 2000 : 3000);
      return () => { clearTimeout(timer) };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionConfirmation]);

  useEffect(() => {
    const ddp = {
      _modifyData,
      action_handlers,
      clickOption,
      hdd_handlers,
      menuItem,
      opts,
      swipedItem,
      updateProps,
      vit,
      viType,
    }
    /** All the buttons available for the dropdown */
    const x = getHddItems(ddp)
    setHddButtons(x)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    hddButtons && getDDItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItem, hddButtons]);

  const componentContexts = { viewSettingsContext, dataModifyContext }

  switch (vit) {
    case 'appProfiles':
    case 'profiles':
    case 'attendees':
    case 'golfers':
      showAddAuth = true
      break;
    default:
    // nothing 
  }

  // const haq = (actionProps) => {
  //   const { actionType } = actionProps
  //   action_handlers.handleActionQuestion(actionType, componentContexts, _modifyData, headerProps)
  // }

  // Group of handlers for the header dropDown
  const hdd_handlers = {
    getAuthUser: () => dataModify_handlers.handleGetAuth(_modifyData),
    handleCopySettingsItem: (a) => viewSettings_handlers.handleCopySettingsItem(a),
    handlePasteSettingsItem: (a) => viewSettings_handlers.handlePasteSettingsItem(a),
    handleSettingsItem: (a) => viewSettings_handlers.handleCopySettingsItem(a),
    handleDelete: () => dataModify_handlers.handleDelete(),
    handleModifyAccess: () => dataModify_handlers.handleGoToAccess(_modifyData),
    handleModifyImage: () => dataModify_handlers.handleOpenImageUploader(),
    handleRestoreFromGlobal: (viewItemKey) => viewSettings_handlers.handleRestoreFromGlobal(viewItemKey),
    handleRestoreDefaults: (resetKey) => viewSettings_handlers.handleRestoreDefaults(resetKey),
    handleReorderByPosition: (resetKey) => viewSettings_handlers.handleReorderByPosition(resetKey),
    handleShowPermissions: (a) => viewSettings_handlers.handleShowPermissions(a),
    handleShowModifyPropData: (a) => viewSettings_handlers.handleShowModifyPropData(a),
    handleSortItems: (a) => viewSettings_handlers.handleSortItems(a),
    handleXXX: () => viewSettings_handlers.handleXXX(),
    openFirebaseDb: () => action_handlers.handleOpenFirebaseDb(pathViews, vit, dataListKey),
    handleUpdateViewPage: (viewItemKey) => viewSettings_handlers.handleUpdateViewPage(viewItemKey),
    handleSwipeTo: (item) => {
      const sp = { swipedItem: item }
      if (swipeToSelected) { swipeToSelected(sp) }
    },
    haq: (actionProps) => {
      const { actionType } = actionProps
      action_handlers.handleActionQuestion(actionType, componentContexts, _modifyData, headerProps)
    }
  }


  const dropdown = (onChange) => {

    let iconDefault = fetching ? appIconTypes.cog : appIconTypes.ellipsisVertical
    let loading = fetching
    let circular = true
    let ddiSize;

    switch (dataHeaderType) {
      case gEnums.dataHeaderTypes.menuHeader:
        iconDefault = 'arrow alternate circle down outline'
        circular = false
        break;
      case gEnums.dataHeaderTypes.sectionHeader:
        // ddiSize = 'small'
        break;
      default:
      // nothing
    }
    let iconColor = confirmOn ? 'green' : 'blue'
    if (loading) { iconColor = iconColorTypes.fetching }
    let iconName = confirmOn ? 'check' : iconDefault

    if (confirmOn && actionConfirmation && actionConfirmation.success === false) {
      iconColor = 'red'
      iconName = 'delete'
    }

    return <Dropdown
      key={uniqueKey('hdd', 'dd')}
      className={'icon hodd'}
      direction={'left'}
      icon={{ color: iconColor, circular: circular, name: iconName, inverted: true, size: ddiSize, loading }}
      options={ddItems}
      onChange={onChange}
    />
  }

  if (ddItems) {
    if (ddItems.length > 0) {
      return dropdown()
    } else {
      return null
    }
  }

  return null

}

export default HeaderDropdown