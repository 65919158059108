import React, { useContext } from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { DataModifyContext } from '../../cnr/contexts/DataModifyContext';
import { ViewSettingsComponentContext } from '../../cnr/contexts/ViewSettingsComponentContext';
import { uniqueKey } from '../../common/keys';

export const ParentBreadcrumbs = () => {

  // viewSettingsComponentContext 
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext);
  const { vsc_state, vsc_handlers } = viewSettingsComponentContext ?? {}
  let { swipedItems } = vsc_state ?? {}
  const { swipeToSelected } = vsc_handlers ?? {}

  let bcrs = null
  if (swipedItems && Object.keys(swipedItems).length > 0) {
    const limit = swipedItems.length - 1
    bcrs = []
    Object.keys(swipedItems).forEach((keyy, index) => {
      if (index < limit) {
        const si = swipedItems[keyy]
        const { key, breadCaption, caption: caption_si } = si
        if (key) {
          let _caption = caption_si ? caption_si : null
          if (!_caption) {
            console.log('NO Caption')
          }
          let caption = breadCaption ? breadCaption : _caption
          const swipeProps = { count: index }
          bcrs.push(<Breadcrumb.Section key={uniqueKey('pbc', 'bcs', index)} onClick={() => { swipeToSelected(swipeProps) }}>{caption}</Breadcrumb.Section>)
          bcrs.push(<Breadcrumb.Divider key={uniqueKey('pbc', 'bsd', index)} />)
        }
      }
    })
  }
  if (bcrs && bcrs.length > 0) {
    return <div className={'pbc'}>
      <Breadcrumb className={'settings-breadcrumb'}>
        {bcrs}
      </Breadcrumb>
    </div>
  } else {
    return null
  }
}

export const ParentSubBreadcrumbs = (props) => {

  const { sis, forData } = props

  // dataModifyContext
  const dataModifyContext = useContext(DataModifyContext)
  const { dataModify_handlers } = dataModifyContext ?? {}

  let breadClick = null

  if (forData) {
    breadClick = dataModify_handlers.handleHeaderBreadcrumbClick
  } else {
    // breadClick = viewDataSwiper_handlers.handleHeaderBreadcrumbClick
  }

  let bcrs = null
  if (sis) {
    const limit = sis.length - 1
    sis.forEach((si, index) => {
      // don't show the first or last item. 
      if (index > 0 && index < limit) {
        const { caption } = si
        if (caption) {
          if (!bcrs) { bcrs = [] }
          bcrs.push(<Breadcrumb.Section key={uniqueKey('pbcs', index)} onClick={() => { breadClick(index) }}>{caption}</Breadcrumb.Section>)
          index < (limit - 1) && bcrs.push(<Breadcrumb.Divider key={uniqueKey('pbcs', 'd', index)} />)
        }
      }
    })
  }
  if (bcrs) {
    return <Breadcrumb className={'d-breadcrumbs'}>
      {bcrs}
    </Breadcrumb>
  }
}