import _ from 'lodash';
import React from 'react';
import { Breadcrumb, Label } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';

export const parentBreadcrumbs = (viewItem, getParents, page_fns) => {

  const { getGviDeps } = page_fns ?? {}
  const { menu, key } = viewItem ?? {}
  const { showParentBreadcrumbs } = menu ?? {}

  let bcrs = null
  let labels = null

  if (showParentBreadcrumbs && key && getParents) {
    const deps = getGviDeps(key)
    const depItems = getParents(deps)
    if (depItems && Object.keys(depItems).length > 0) {
      bcrs = []
      labels = []
      Object.keys(depItems).forEach(key => {
        const di = depItems[key]
        const { name } = di
        bcrs.push({ key: name, content: name, link: false })
        labels.push(<Label fluid color='grey' key={uniqueKey('bc', 'l', key)} className='item-label'>{name}</Label>)
      })
    }
  }
  if (labels) {
    return labels
  }
  if (bcrs) {
    return <Breadcrumb className={'item-breadcrumb'} icon='right angle' sections={bcrs} />
  }
}

export const propBreadcrumbs = (drillDown, vld, props_viewItem, ddGroupIndex, goBackTo) => {
  let bcrs = []
  const d = vld[0]
  if (d) {
    for (var i = 1; i < ddGroupIndex; ++i) {
      const ii = i
      const ddg = drillDown['group' + ii]
      if (ddg) {
        ddg.forEach(item => {
          if (d[item]) {
            bcrs.push(<Breadcrumb.Section key={uniqueKey('bc', 'l', ii)} >{d[item]}</Breadcrumb.Section>)
          }
        })
      }
    }
  }
  if (bcrs && bcrs.length > 0) {
    return <div className='vi-bc'><Breadcrumb className={'item-breadcrumb'} icon='caret right' sections={bcrs} /></div>
  }
}

export const sectionBreadcrumbs = (drillDown, propSections, propSections_allowed, viewListData, ddGroupIndex, goBackTo) => {
  let bcrs = []
  for (var i = 1; i < ddGroupIndex; ++i) {
    const ii = i
    const ddg = drillDown['section' + ii]
    if (ddg) {
      ddg.forEach(item => {
        if (propSections[item]) {
          const propSection = propSections[item]
          let caption = propSection.sectionHeader && propSection.sectionHeader.caption ? propSection.sectionHeader.caption : _.startCase(item)
          if (viewListData && propSections_allowed && propSections_allowed[item] && propSections_allowed[item].props) {
            const sprs = propSections_allowed[item].props
            Object.keys(sprs).forEach(key => {
              if (sprs[key].tagged) {
                if (Object.values(viewListData)[0][key]) {
                  caption = Object.values(viewListData)[0][key]
                }
              }
            })
          }
          const count = ddGroupIndex - ii
          bcrs.push(<Breadcrumb.Section
            key={uniqueKey('bc', 'sec')}
            onClick={(e) => { goBackTo(count) }}
          >
            {caption}
          </Breadcrumb.Section>)
        }
      })
    }
  }
  if (bcrs && bcrs.length > 0) {
    return <div className='vi-bc'><Breadcrumb className={'item-breadcrumb'} icon='caret right' sections={bcrs} /></div>
  }
}