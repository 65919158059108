import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Icon, Label, Segment } from 'semantic-ui-react';
import { SwiperContext } from '../../../../global/cnr/contexts/SwiperContext';
import { formatItem, formatTypes, getFormattedDate } from '../../../../global/common/dateFormatting';
import { uniqueKey } from '../../../../global/common/keys';
import PendingWait from '../../../../global/components/alerts/pendings/PendingWait';
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { _matchLsTypes } from '../../cnr/reducers/SportsDataReducer';
import MatchCount from './MatchCount';
import MatchScheduleHeader from './MatchScheduleHeader';
import MatchWrapper, { sportsMatchViewTypes } from './MatchWrapper';
import ScheduleLegend from '../standings/ScheduleLegend';

const _colors = {
  section: 'black',
  matchCount: 'blue',
  matchZeroCount: 'black',
  matchCompleted: 'blue',
  matchEqual: 'green',
}

const MatchLevelDates = (props) => {

  const { showHeader } = props ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext);
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { matches_info, upcomingMatchDate } = sportsSeason_state ?? {}
  const { matchDateKeys, levelUpcomingMatches, _levelUpcomingMatches } = matches_info ?? {}

  // swiperContext
  const swiperContext = useContext(SwiperContext)
  const { swiper_handlers } = swiperContext ?? {}

  const _mld = localStorage.getItem('mld')

  const [levelMatches, setLevelMatches] = useState()
  const [selectedDate, setSelectedDate] = useState()
  // const [allowForward, setAllowForward] = useState(true)
  // const [allowBack, setAllowBack] = useState(true)

  const _dateCaption = formatItem(formatTypes.fullDate, selectedDate)

  useEffect(() => {
    setLevelMatches(levelUpcomingMatches)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [levelUpcomingMatches]);

  useEffect(() => {
    if (upcomingMatchDate) {
      let _selectedDate = _mld ? _mld : getFormattedDate(upcomingMatchDate)
      if (!_selectedDate) {
        _selectedDate = getFormattedDate(new Date());
      }
      setSelectedDate(_selectedDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [upcomingMatchDate]);

  useEffect(() => {
    if (_levelUpcomingMatches && selectedDate) {
      localStorage.setItem('mld', selectedDate)
      const _matchDateLevelGroup = _levelUpcomingMatches[selectedDate]
      setLevelMatches(_matchDateLevelGroup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_levelUpcomingMatches, selectedDate]);

  const clns = {
    ldm: 'level-date-matches schedule-section-teams',
    ldmh: 'level-date-matches-header',
    ldmmt: 'level-date-matches-mt'
  }

  const handleLevelClick = () => swiper_handlers && swiper_handlers.handleSet_tabIndexByKey('matches')

  const handleTodayClick = () => {
    const td = upcomingMatchDate ? getFormattedDate(upcomingMatchDate) : getFormattedDate(new Date())
    let seletedIndex = _.findIndex(matchDateKeys, item => item === td);
    const _selectedDate = matchDateKeys[seletedIndex]
    if (_selectedDate) {
      setSelectedDate(_selectedDate)
    }
  }

  const handleDateClick = (forward) => {
    let seletedIndex = _.findIndex(matchDateKeys, item => item === selectedDate);
    if (forward) {
      seletedIndex++
    } else {
      seletedIndex--
    }
    const _selectedDate = matchDateKeys[seletedIndex]
    if (_selectedDate) {
      setSelectedDate(_selectedDate)
    }
  }

  const lbl = (index, value, color, circular, size) => <Label key={uniqueKey('mld', 'lm', 'lbl', index)} size={size} color={color} circular={circular}>{value}</Label>

  const elemms_levelMatches = (lkey, lucm) => {

    if (lucm) {

      const levelCaption = _.startCase(lkey)

      const { subCaption, matches, hasMatches, matchCount } = lucm
      const endCaption = matchCount === 1 ? 'match' : 'matches'

      if (hasMatches) {

        const sortedMatchesX = _.orderBy(matches, ['_results.complete', 'home.name'], ['desc', 'asc']);
        const completedMatchesCount = _.countBy(matches, _.property('_results.complete')).true || 0;

        const matchCounts = { matchCount, endCaption, completedMatchesCount }

        // @ts-ignore
        const lms = sortedMatchesX.map(m => {

          const { isPlayoff, _status } = m
          const { isBye } = _status ?? {}

          if (!isBye) {
            if (isPlayoff) {
              return <MatchWrapper key={uniqueKey('mld', 'mw', lkey, m._itemKey)} match={m} sportsMatchViewType={sportsMatchViewTypes.single} fromLevelDates={true} />
            } else {
              return <MatchWrapper key={uniqueKey('mld', 'mw', lkey, m._itemKey)} match={m} sportsMatchViewType={sportsMatchViewTypes.single} fromLevelDates={true} />
            }
          }
        })

        return <div key={uniqueKey('mld', 'lm', lkey)} className={clns.ldm}>
          <div key={uniqueKey('mld', 'lm', 'lc', lkey)} className={clns.ldmh} onClick={() => { handleLevelClick(lkey) }}>
            <div>{lbl(1, levelCaption, _colors.section)}</div>
            <div><MatchCount matchCounts={matchCounts} /> </div>
            <div><Icon name={'arrow right'}></Icon></div>
          </div>
          <div className='schedule-section-teams mld'>
            {lms}
          </div>
        </div >
      } else {
        const matchCounts = { matchCount, endCaption, completedMatchesCount: 0, clns }
        return <div key={uniqueKey('mld', 'lm', lkey)} className={clns.ldm}>
          <div key={uniqueKey('mld', 'lm', 'lc', lkey)} className={clns.ldmh} onClick={() => { handleLevelClick(lkey) }}>
            <div>{lbl(1, levelCaption, _colors.section)}</div>
            <div><MatchCount matchCounts={matchCounts} /> </div>
            <div><Icon name={'arrow right'}></Icon></div>
          </div>
          <Segment basic>
            <div>{subCaption}</div>
          </Segment>
        </div>
      }
    }
  }

  const dateHeader = () => {
    return <div className='date-header'>
      <div><Icon name='arrow left' onClick={() => { handleDateClick(false) }} /></div>
      <div>{_dateCaption}</div>
      <div><Icon name='calendar outline' onClick={() => { handleTodayClick(true) }} /></div>
      <div><Icon name='arrow right' onClick={() => { handleDateClick(true) }} /></div>
    </div>
  }

  const matchGrids = () => {

    let dateDiv;
    const sectionDivs = []
    const nonSectionDivs = []


    if (levelMatches) {
      _.forEach(levelMatches, (lucm, lkey) => {
        const { matchCount, hide } = lucm ?? {}
        switch (lkey) {
          case _matchLsTypes.non:
          case _matchLsTypes.nonDistrict:
          case _matchLsTypes.tournament:
            if (!hide) {
              nonSectionDivs.unshift(elemms_levelMatches(lkey, lucm))
            }
            break;
          default:
            if (matchCount === 0) {
              sectionDivs.push(elemms_levelMatches(lkey, lucm))
            } else {
              sectionDivs.unshift(elemms_levelMatches(lkey, lucm))
            }
        }
      })
    }

    return showHeader ?
      <div>
        <MatchScheduleHeader todayOnly={true} />
        <div className={'levels-date-matches'}>
          {dateHeader()}
          {sectionDivs}
          {nonSectionDivs}
          <ScheduleLegend />
        </div>
      </div>
      :
      <div className={'levels-date-matches'}>
        {dateHeader()}
        {sectionDivs}
        {nonSectionDivs}
        <ScheduleLegend />
      </div>
  }

  return levelMatches ? matchGrids() : <PendingWait />

}

export default MatchLevelDates
