import React from 'react'
import { motion } from 'framer-motion'
import { uniqueKey } from '../common/keys'

// https://www.framer.com/motion/

// const modalVariants = {
//   initial: { opacity: 0 },
//   animate: { opacity: 1, transition: { duration: 5 } },
//   exit: { opacity: 0 },
// };

const MotionComponent = (props) => {

  const { dimmer, transition, cnRoot, oc } = props
  const { animateType, variants, cn, styleAndClass } = transition ?? {}
  const { dimmerDuration, dimmerType } = dimmer ?? {}

  const _styleAndClass = styleAndClass ?? {}

  const _motionType = dimmerType ? dimmerType : animateType

  let _className = 'motion-container ' + _motionType
  if (cn) { _className += ' ' + cn }
  if (cnRoot) { _className += ' ' + cnRoot }

  const transitionDiv = () => <motion.div
    key={uniqueKey('md', animateType)}
    variants={variants}
    initial={'initial'}
    animate={'animate'}
    exit={'exit'}
    className={_className}
    {..._styleAndClass}
    onClick={() => { oc && oc() }}
  >
    {props.children}
  </motion.div>

  const dimmerDiv = () => <motion.div
    className={_className}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{
      opacity: { duration: dimmerDuration }
    }}
    {..._styleAndClass}
  >
    {props.children}
  </motion.div>

  if (dimmer) {
    return dimmerDiv()
  } else if (transition) {
    return transitionDiv()
  }


}

export default MotionComponent 