import React, { useContext, useEffect } from 'react';
import { AppUserContext } from '../../../../global/cnr/contexts/AppUserContext';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import MatchScoreKeypad, { _keypadTypes } from './MatchScoreKeypad';
import MatchScoreTable from './MatchScoreTable';
import MatchSetScores from './MatchSetScores';

export const matchScoreTypes = {
  match: 'match',
  sets: 'sets',
  realtime: 'realtime',
}

const MatchRealtimeScoring = (props) => {

  const { viewKey, smatchScoreType, fullPage } = props

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ?? {}
  const { appUser } = appUser_state ?? {}
  const { loggedIn } = appUser ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { matches_info } = sportsSeason_state
  const { matches_realtime } = matches_info ?? {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ?? {}
  const { match_true, matchData_rt } = sportsMatch_state ?? {}
  const { match_rt: match_rt_match } = matchData_rt ?? {}

  const { _resultsStatus } = match_true ?? {}
  const { scoreType } = _resultsStatus ?? {}

  // sportsRtMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state, sportsRtMatch_handlers } = sportsRtMatchContext ?? {}
  const { match_rt } = sportsRtMatch_state ?? {}

  const matchScoring = () => {
    switch (smatchScoreType) {
      case matchScoreTypes.sets:
        return <MatchSetScores viewKey={viewKey} />
      case matchScoreTypes.realtime:
        return <MatchScoreKeypad keypadType={_keypadTypes.realtime} />
      default:
        break;
    }
  }

  const vbs = () => <div className={'match-scoring-container'}>
    {matchScoring()}
  </div>

  const content = () => <React.Fragment>
    <MatchScoreTable />
    {loggedIn && vbs(6)}
  </React.Fragment>

  const wrapper = () => <Wrapper
    header={'Realtime Scoring'}
    content={content()}
    wrapperType={(wrapperTypes.paddedHeader)}
    css={{ header: 'nob', container: 'match-set-scoring' }}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={sportsMatch_handlers.handleShowRealtime}
    topperCaption={'Scoring'}
  />

  if (fullPage) {
    return match_rt ? fullPageWrapper() : <div></div>
  } else {
    return match_rt ? wrapper() : <div></div>
  }

}

export default MatchRealtimeScoring