const rts = {
  handleDataConstraintPreview: 'handleDataConstraintPreview',
  handleSettings_preview: 'handleSettings_preview',
  handleSettings_previewCancel: 'handleSettings_previewCancel',
  handleSettings_googleSheets: 'handleSettings_googleSheets',
}

export const previewReducer = (state, action) => {

  const { settingsPreviewHasChanged, settingsPreviewStatus, previewStatus } = state
  const { type } = action

  switch (type) {

    case rts.handleDataConstraintPreview:
      const _previews = { ...state.previews }
      _previews[action.key] = action.currentData
      return { ...state, previews: _previews, previewStatus: previewStatus === 'a' ? 'b' : 'a' }

    case rts.handleSettings_previewCancel:
      return { ...state, global: null, page: null, settingsPreviewHasChanged: !settingsPreviewHasChanged }

    case rts.handleSettings_preview:
      if (action.settingsPreviewStatus === settingsPreviewStatus) {
        return { ...state, settings_preview: action.settings, settingsPreviewHasChanged: true, settingsPreviewStatus: settingsPreviewStatus === 'a' ? 'b' : 'a' }
      } else {
        return { ...state }
      }

    case rts.handleSettings_googleSheets:
      return { ...state, settings_googleSheets: action.settings }

    default:
      return { ...state }
  }
}

export const previewHandlers = (dispatch) => {
  return {
    handleDataConstraintPreview: (settings, key, currentData) => dispatch({ type: rts.handleDataConstraintPreview, settings, key, currentData }),
    handleSettings_googleSheets: (settings) => dispatch({ type: rts.handleSettings_googleSheets, settings }),
    handleSettings_preview: (settings, isGlobal, settingsPreviewStatus) => dispatch({ type: rts.handleSettings_preview, settings, isGlobal, settingsPreviewStatus }),
    handleSettings_previewCancel: (settings) => dispatch({ type: rts.handleSettings_previewCancel, settings }),
  }
}

export const previewInitialState = () => {
  return { previews: {} }
};