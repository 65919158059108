import React, { useContext } from 'react';
import IconGrids from '../components/layout/IconGrids';
import { clickOriginTypes, gEnums } from '../enums/globalEnums';
import PageItem from './PageItem';
import { PageAreaContext } from '../cnr/contexts/PageAreaContext';
import ListGrid from '../components/layout/ListGrid';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { uniqueKey } from '../common/keys';
import { Segment } from 'semantic-ui-react';
import FullPageContent from '../components/layout/FullPageContent';

/**
 * 
 * @returns a list of PageItems
 */
const PageContent = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state } = states
  const { pageSettings } = page_state ?? {}
  const { aps_viewItems, aps_page } = pageSettings ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { desktopMode, showFullPage, fullPageType } = framework_state ?? {}
  const { handleShow_fullPage } = framework_handlers ?? {}

  // pageContext   
  const { layout: layout_page, desktopLayout: desktopLayout_page } = aps_page ?? {}
  const _layout = desktopMode ? desktopLayout_page : layout_page
  const { layoutType } = _layout ?? {}

  // pageAreaContext
  const pageAreaContext = useContext(PageAreaContext)
  const { pageArea_state } = pageAreaContext ?? {}
  const { pageItemsShown, uiGroupedItems, uiSelectedItems, fixFirstViewItem, gridTemplateRows } = pageArea_state ?? {}

  const getVis = (uivi) => {
    const viewItem = pageItemsShown[uivi]
    const viewItem_app = aps_viewItems[uivi]
    const { display, desktopDisplay } = viewItem ?? {}
    const _display = desktopMode && desktopDisplay ? desktopDisplay : display
    const { caption } = _display ?? {}
    return { uivi, viewItem, viewItem_app, display: _display, caption }
  }

  const pageItemsContent = (isSinglePage) => {
    if (Object.keys(pageItemsShown).length === 0) {
      return <Segment basic>No Page Items are set to 'show'. Check the 'Page Items' settings.</Segment>
    } else {
      if (isSinglePage || desktopMode) {
        return Object.keys(pageItemsShown).map(uivi => {
          const vip = getVis(uivi)
          return <PageItem key={uniqueKey('pcpic', uivi)} uivi={uivi} caption={vip.caption} viewItem_app={vip.viewItem_app} />
        })
      } else {
        return Object.keys(pageItemsShown).map(uivi => (
          <PageItem key={uniqueKey('pcpic', uivi)} uivi={uivi} from={'PageContent'} />
        ))
      }
    }
  }

  // IMPORTANT: UI - layoutType - build the correct UI content based on the viewType
  const content = () => {

    if (showFullPage && fullPageType) {
      return <FullPageContent fullPageType={fullPageType} handleCancel={handleShow_fullPage} clickOriginType={clickOriginTypes.iconGrid} />
    } else if (pageItemsShown) {

      switch (layoutType) {

        case gEnums.layoutTypes.golfTournament:
          return <div>Golf Touranemnt</div>

        case gEnums.layoutTypes.pageList:
          const visl = getGroupVis(uiSelectedItems, aps_viewItems)
          return <ListGrid key={uniqueKey('pcpic', 'pl')} selectedItems={visl} />

        case gEnums.layoutTypes.pageIcons:
          const vis = getGroupVis(uiGroupedItems, aps_viewItems)
          return <IconGrids key={uniqueKey('pcpic', 'pi')} pageIcons={vis} />

        case gEnums.layoutTypes.icons:
          return <IconGrids key={uniqueKey('pcpic', 'icn')} />

        case gEnums.layoutTypes.horizontalSliders:
          return <div className={'horizontal-container'}>
            {pageItemsContent()}
          </div>

        case gEnums.layoutTypes.singlePage:
          let cn = 'single-page-container'
          if (fixFirstViewItem) { cn += ' fix-first' }
          const sty = gridTemplateRows ? { gridTemplateRows } : {}
          return <div className={cn} style={sty} id={cn} name={cn}>
            {pageItemsContent(true)}
          </div>

        case gEnums.layoutTypes.swipeable:
        case gEnums.layoutTypes.swipeableHidden:
          return pageItemsContent()

        default:
          return pageItemsContent()
      }
    } else {
      return <Pending />
    }
  }

  return content()

}

const Pending = () => (
  <div>Page Content Pending...</div>
)

const getGroupVis = (pageIcons, aps_viewItems) => {
  const groupVis = {}
  if (pageIcons) {
    pageIcons.forEach((gi, index) => {
      if (aps_viewItems && aps_viewItems[gi]) {
        groupVis[gi] = {
          position: index,
          show: true,
          key: gi,
          itemKey: gi,
          display: aps_viewItems[gi].display
        }
      }
    })
  }
  return groupVis
}

export default PageContent