/**
 * An object container the firebase configs for the apps
 */
export const fbAppConfigs = {
  meetingevolution: {
    dev: {
      apiKey: "AIzaSyDHbf1FjfK5gM89jIv6ZcP9_9QY-cX2ufc", // in console.cloud.google.com
      authDomain: "me-mobile-4410b.firebaseapp.com",
      databaseURL: "https://me-mobile-4410b.firebaseio.com",
      projectId: "me-mobile-4410b",
      storageBucket: "me-mobile-4410b.appspot.com",
      messagingSenderId: "79555447917",
      appId: "1:79555447917:web:174d2ae291d8661b450364",
      // measurementId: "G-2KVVSF3FQL",
      appName: "Meeting Evolution"
    },
    prod: {
      apiKey: "AIzaSyBAnPqX2PmWY_cxjm48cUyyYDBEMGmxvPQ",
      authDomain: "me-production-57ad6.firebaseapp.com",
      databaseURL: "https://me-production-57ad6.firebaseio.com",
      projectId: "me-production-57ad6",
      storageBucket: "me-production-57ad6.appspot.com",
      messagingSenderId: "53426719471",
      appId: "1:53426719471:web:3ceac8e41cf6522ea3691a",
      // measurementId: "G-3GDHV2FTKP",
      appName: "Meeting Evolution"
    }
  },
  pojo: {
    dev: {
      apiKey: "AIzaSyDSao7Pwb__uNn744yYJA2x5cL-934AVj0",
      authDomain: "pojo-1fdf3.firebaseapp.com",
      databaseURL: 'https://pojo-1fdf3-default-rtdb.firebaseio.com',
      projectId: "pojo-1fdf3",
      storageBucket: "pojo-1fdf3.appspot.com",
      messagingSenderId: "31787147406",
      appId: "1:31787147406:web:05e11319c4f130290529df",
      // measurementId: "G-5103NCPRJ3"
    },
    prod: {
      apiKey: "AIzaSyCvNY0mLVOnlupTVfHwc43DItcbHs8J_Ps",
      authDomain: "pojo-production.firebaseapp.com",
      projectId: "pojo-production",
      storageBucket: "pojo-production.appspot.com",
      messagingSenderId: "13594628038",
      appId: "1:13594628038:web:ca8d1d54b9f5e780c3acc7",
      // measurementId: "G-73G6Y1LC4Z"
    },
  },
  thumbstat: {
    dev: {
      apiKey: "AIzaSyDvmt1zleE-uFekqmd4YO2r8SDyK_PUkFk",
      authDomain: "thumbstat.firebaseapp.com",
      databaseURL: "https://thumbstat.firebaseio.com",
      projectId: "thumbstat",
      storageBucket: "thumbstat.appspot.com",
      // messagingSenderId: "357578850696",
      appId: "1:357578850696:web:e0b5f449d477e44bfdfa3d"
    },
    prod: {
      apiKey: "AIzaSyDvmt1zleE-uFekqmd4YO2r8SDyK_PUkFk",
      authDomain: "thumbstat.firebaseapp.com",
      databaseURL: "https://thumbstat.firebaseio.com",
      projectId: "thumbstat",
      storageBucket: "thumbstat.appspot.com",
      // messagingSenderId: "357578850696",
      appId: "1:357578850696:web:e0b5f449d477e44bfdfa3d"
    }
  },
  thumbstat2: {
    dev: {
      apiKey: "AIzaSyDHbf1FjfK5gM89jIv6ZcP9_9QY-cX2ufc", // in console.cloud.google.com
      authDomain: "me-mobile-4410b.firebaseapp.com",
      databaseURL: "https://me-mobile-4410b.firebaseio.com",
      projectId: "me-mobile-4410b",
      storageBucket: "me-mobile-4410b.appspot.com",
      messagingSenderId: "79555447917",
      appId: "1:79555447917:web:174d2ae291d8661b450364",
      appName: "Meeting Evolution"
    },
    prod: {
      apiKey: "AIzaSyBAnPqX2PmWY_cxjm48cUyyYDBEMGmxvPQ",
      authDomain: "me-production-57ad6.firebaseapp.com",
      databaseURL: "https://me-production-57ad6.firebaseio.com",
      projectId: "me-production-57ad6",
      storageBucket: "me-production-57ad6.appspot.com",
      messagingSenderId: "53426719471",
      appId: "1:53426719471:web:3ceac8e41cf6522ea3691a",
      // measurementId: "G-3GDHV2FTKP",
      appName: "Meeting Evolution"
    }
  },
  cardnudge: {
    dev: {
      apiKey: "AIzaSyDkzYzHqV-j2ALfHQtWefjU2HSPkKdMStc",
      authDomain: "card-nudge.firebaseapp.com",
      projectId: "card-nudge",
      storageBucket: "card-nudge.appspot.com",
      messagingSenderId: "686550023929",
      appId: "1:686550023929:web:bd0b450a5228dd2659ac28",
      // measurementId: "G-4Y7Y0QKN2B"
    },
    prod: {
      apiKey: "AIzaSyDkzYzHqV-j2ALfHQtWefjU2HSPkKdMStc",
      authDomain: "card-nudge.firebaseapp.com",
      projectId: "card-nudge",
      storageBucket: "card-nudge.appspot.com",
      messagingSenderId: "686550023929",
      appId: "1:686550023929:web:bd0b450a5228dd2659ac28",
      // measurementId: "G-4Y7Y0QKN2B"
    }
  },
  fcasd_ft: {
    dev: {
      apiKey: "AIzaSyCwPbNTZaL1PUS1Qlku5a1N2bdJxfrNplQ",
      authDomain: "fcasd-field-trip.firebaseapp.com",
      projectId: "fcasd-field-trip",
      storageBucket: "fcasd-field-trip.appspot.com",
      messagingSenderId: "1053629618408",
      appId: "1:1053629618408:web:03dbebdc1cacce832d515d",
      measurementId: "G-VKD0EGLLMC",
      appName: "FCASD"
    },
    prod: {
      apiKey: "AIzaSyCwPbNTZaL1PUS1Qlku5a1N2bdJxfrNplQ",
      authDomain: "fcasd-field-trip.firebaseapp.com",
      projectId: "fcasd-field-trip",
      storageBucket: "fcasd-field-trip.appspot.com",
      messagingSenderId: "1053629618408",
      appId: "1:1053629618408:web:03dbebdc1cacce832d515d",
      measurementId: "G-VKD0EGLLMC",
      appName: "FCASD"
    }
  },
  fcasd: {
    dev: {
      apiKey: "AIzaSyBBM58fcMNaAZg4l_BK_JJgYMzx9c6QOtQ",
      authDomain: "fcasd-2d233.firebaseapp.com",
      projectId: "fcasd-2d233",
      storageBucket: "fcasd-2d233.appspot.com",
      messagingSenderId: "1076998227402",
      appId: "1:1076998227402:web:510bdd0329758e93defb33",
      measurementId: "G-JTX78YWJWW",
      appName: "FCASD"
    },
    prod: {
      apiKey: "AIzaSyBBM58fcMNaAZg4l_BK_JJgYMzx9c6QOtQ",
      authDomain: "fcasd-2d233.firebaseapp.com",
      projectId: "fcasd-2d233",
      storageBucket: "fcasd-2d233.appspot.com",
      messagingSenderId: "1076998227402",
      appId: "1:1076998227402:web:510bdd0329758e93defb33",
      measurementId: "G-JTX78YWJWW",
      appName: "FCASD"
    }
  }
}

export const googleMapConfigs = {
  meetingevolution: {
    // 'AIzaSyBtBpk_tKRNMuV28EgMaQZhTB_5Rlv9fs8'
    // AIzaSyBiqalBwhund2GZNpGc4rG7w8Ob3GVkzqk
    dev: {
      apiKey: 'AIzaSyDQB9kGM8JwsBJZ7g5GNvm4CdiEEJxMSxQ'
    },
    prod: {
      apiKey: 'AIzaSyDQB9kGM8JwsBJZ7g5GNvm4CdiEEJxMSxQ'
    }
  },
  thumbstat: {
    dev: {
      apiKey: 'AIzaSyBtBpk_tKRNMuV28EgMaQZhTB_5Rlv9fs8'
    },
    prod: {
      apiKey: 'AIzaSyBtBpk_tKRNMuV28EgMaQZhTB_5Rlv9fs8'
    }
  },
  cardnudge: {
    dev: {
      apiKey: 'AIzaSyD6FZYK0K6JMOPq9-Pf72PAaCEquQkzRHw'
    },
    prod: {
      apiKey: 'AIzaSyD6FZYK0K6JMOPq9-Pf72PAaCEquQkzRHw'
    }
  },
  pojo: {
    dev: {
      apiKey: 'AIzaSyD6FZYK0K6JMOPq9-Pf72PAaCEquQkzRHw'
    },
    prod: {
      apiKey: 'AIzaSyD6FZYK0K6JMOPq9-Pf72PAaCEquQkzRHw'
    }
  }
}