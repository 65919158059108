import React, { useContext, useEffect, useReducer, useRef } from 'react'
import { AppSettingsContext } from '../cnr/contexts/AppSettingsContext'
import { swipeHandlers, swipeInitialState, swipeReducer } from '../cnr/reducers/SwipeReducer'

const TOUCH_INTERVAL = 1000

const TouchWrapper = (props) => {

  const { handleSwipedRight, handleTouchHold } = props ?? {}

  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_state } = appSettingsContext ?? {}
  const { currentDevice } = appSettings_state ?? {}
  const { msettings } = currentDevice ?? {}
  const { h, w } = msettings ?? {}

  let imageStyle = msettings ? { height: h, width: w } : { height: 360, width: 540 }

  const [swipe_state, swipe_dispatch] = useReducer(swipeReducer, swipeInitialState(imageStyle));
  const swipe_handlers = swipeHandlers(swipe_dispatch, swipe_state)
  const { swipedRight, isTouching } = swipe_state
  const { handleTouchStart, handleTouchMove, handleTouchEnd } = swipe_handlers

  const _swipeDiv = useRef(null);

  useEffect(() => {
    swipe_handlers.handleDiv(_swipeDiv)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (handleSwipedRight && swipedRight) {
      handleSwipedRight()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipedRight]);

  useEffect(() => {
    if (handleTouchHold && isTouching) {
      const timer = setTimeout(() => handleTouchHold(), TOUCH_INTERVAL);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [isTouching]);

  return <div
    ref={_swipeDiv}
    onTouchStart={handleTouchStart}
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd}>
    {props.children}
  </div>
}

export default TouchWrapper