import { appIconTypes } from '../enums/appIconTypes';
import { iconColorTypes } from '../enums/settingsIconTypes';

/**
 * 
 * @param {object} appUserAccess 
 * @param {object} aps_global 
 * @param {???} notificationRequestGranted 
 * @param {boolean} messagingSupported 
 * @returns {color, circular, bordered, value, name, appManagmentLeve}
 */
export const getAuthHeaderProps = (props) => {

  const { appUserAccess, aps_global, notificationRequestGranted, messagingSupported } = props
  const { initials, loggedIn } = appUserAccess ?? {}

  let { appUserColor } = appUserAccess ?? {}
  let { appUserViewTypeProp } = aps_global ?? {}
  if (!appUserViewTypeProp) { appUserViewTypeProp = 'registrationType' }

  let iconName = loggedIn ? null : appIconTypes.user
  let iconBell = notificationRequestGranted ? 'bell' : 'bell outline'
  if (!messagingSupported) { iconBell = 'bell slash outline' }

  return {
    color: loggedIn ? appUserColor : iconColorTypes.default,
    value: loggedIn ? initials : null,
    name: iconName,
    appManagementLevel: 0,
    iconBell,
  }
}