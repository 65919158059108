import _ from 'lodash';
import { appOptions_right } from "../../optionLists/appOptionsRight";
import { currentHelpers } from "../../redirection/current";

export const rts = {
  handle_triggerAction: 'handle_triggerAction',
  handleCloseCurrentMenuType: 'handleCloseCurrentMenuType',
  handleDatabaseOpen: 'handleDatabaseOpen',
  handleGoToClient: 'handleGoToClient',
  handleGoToHome: 'handleGoToHome',
  handleInit_rightMenu: 'handleInit_rightMenu',
  handleOpen_otherSite: 'handleOpen_otherSite',
  handleOpenGs: 'handleOpenGs',
  handleOpenSettings: 'handleOpenSettings',
  handleSet_currentMenuType: 'handleSet_currentMenuType',
}

export const rightMenuTypes = {
  propsOverride: 'propsOverride',
  subActions: 'subActions',
}

export const rightMenuTriggerTypes = {
  frameworkRight: 'frameworkRight',
  openDb: 'openDb',
  propsSave: 'propsSave',
  settings: 'settings',
  display: 'display',
  toggle: 'toggle'
}

export const rightMenuReducer = (state, action) => {

  const { type, dispatch } = action

  const _handlers = rightMenuHandlers(dispatch)

  switch (type) {

    case rts.handleInit_rightMenu:
      const _rightOptions = getRightOptions(action, state, _handlers)
      return { ...state, rightOptions: _rightOptions }

    case rts.handleSet_currentMenuType:
      return { ...state, currentMenuType: action.options && action.options.rmt ? action.options.rmt : null, triggerActionType: null }

    case rts.handle_triggerAction:
      const { options } = action
      const { rmt, frt, dmt } = options ?? {}
      switch (rmt) {
        case rightMenuTriggerTypes.propsSave:
          return { ...state, currentMenuType: null, triggerActionType: rmt, frt, dmt }
        default:
          return { ...state, triggerActionType: rmt, frt, dmt }
      }

    case rts.handleCloseCurrentMenuType:
      return { ...state, currentMenuType: null }

    case rts.handleDatabaseOpen:
      const item_hp = action.eventKey ? 'homePageSeen-' + action.eventKey : 'homePageSeen'
      currentHelpers.storageItem_set(item_hp, true);
      return { ...state }

    case rts.handleGoToHome:
      const item_w = action.eventKey ? 'waiverSeen-' + action.eventKey : 'waiverSeen'
      currentHelpers.storageItem_set(item_w, true);
      return { ...state }

    case rts.handleGoToClient:
      return { ...state, messagingSupported: action.messagingSupported }

    default:
      return { ...state }
  }
}

export const rightMenuHandlers = (dispatch) => {
  return {
    handle_triggerAction: (options) => { dispatch({ type: rts.handle_triggerAction, dispatch, options }) },
    handleCloseCurrentMenuType: () => { dispatch({ type: rts.handleCloseCurrentMenuType, dispatch }) },
    handleDatabaseOpen: (eventKey) => { dispatch({ type: rts.handleDatabaseOpen, eventKey }) },
    handleGoToClient: (messagingSupported) => { dispatch({ type: rts.handleGoToClient, messagingSupported }) },
    handleGoToHome: (eventKey) => { dispatch({ type: rts.handleGoToHome, eventKey }) },
    handleInit_rightMenu: (settings) => { dispatch({ type: rts.handleInit_rightMenu, dispatch, settings }) },
    handleSet_currentMenuType: (options) => { dispatch({ type: rts.handleSet_currentMenuType, dispatch, options }) },
  }
}

export const rightInitialState = (initState) => {
  const _rightOptions = {}
  _.forEach(appOptions_right, (alo, key) => {
    _rightOptions[key] = []
  })
  return { ...initState, appOptions: appOptions_right, rightOptions: _rightOptions }
};

const getRightOptions = (action, state, _handlers) => {

  const { settings } = action
  const { allowSideSettings, allowSideData, isLanding } = settings

  if (allowSideSettings) {
    const _rightOptions = {}
    _.forEach(appOptions_right, (alo, key) => {
      _rightOptions[key] = alo(_handlers, settings)
    })
    return _rightOptions
  } else if (allowSideData) {
    const _rightOptions = {
      page: appOptions_right.data(_handlers, settings),
    }
    return _rightOptions
  }
}