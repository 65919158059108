import { fs_dbu } from '../../firestoreData/appData/fsAppDataUpdate';
import { fsfn_auth } from '../../functions/fbAuth';

const collectionName = 'clients'
const profileName = 'profiles'

export const deleteAppAdminProfileAndAuth = async (dispatch, itemData, pathViews) => {
  const _refPath = [collectionName, pathViews.clients, profileName, itemData.email]
  fs_dbu.delete_doc(_refPath).then(res => {
    const { uid } = itemData
    fsfn_auth.deleteAuthByUid(uid, dispatch)
  })
}