import React, { createContext, useReducer } from 'react';
import { dragNDropHandlers, dragNDropInitialState, dragNDropReducer } from '../reducers/DragNDropReducer';

/** 
 * @state dataWrapper_state (`data`, `item_data_temp`)
 * @functions dataWrapper_fns (`setPageTempData`)
 * @contexts (`AppSettingsContext`, `ClientSettingsContext`, `HomeSettingsContext`,`PapsContext`, `StartContext`)
 */
export const DragNDropContext = createContext();

const DragNDropProvider = (props) => {

  const { allowSort, ddType, dndGroups, dndType, groupChangeKey, handleSorted, otherDate, portalCn } = props ?? {}

  const initState = { allowSort, ddType, dndGroups, dndType, groupChangeKey, handleSorted, otherDate, portalCn }
  const [dragNDrop_state, dragNDropDispatch] = useReducer(dragNDropReducer, dragNDropInitialState(initState));
  const dragNDrop_handlers = dragNDropHandlers(dragNDropDispatch, dragNDrop_state)

  // setPageData
  const providerContext = () => <DragNDropContext.Provider value={{ dragNDrop_state, dragNDrop_handlers }}>
    {props.children}
  </DragNDropContext.Provider>

  return providerContext()

}

export default DragNDropProvider
