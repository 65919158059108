import _ from 'lodash';
import React, { useState } from 'react';
import { Dropdown, Label } from 'semantic-ui-react';

export const _scheduleFilterTypes = {
  dropdown: 'dropdown',
  list: 'list',
  label: 'label',
}

const ScheduleFilter = (props) => {

  const { enabled, filterButtons, handleFilter, matchStatusGroups, scheduleFilterType } = props

  const [selectedButton, setSelectedButton] = useState()

  const handleClick_filter = (btn) => {
    const _sb = selectedButton === btn ? null : btn
    handleFilter(_sb)
    setSelectedButton(_sb)
  }

  const label = (key, selected, text, oc) => {
    const count = matchStatusGroups && matchStatusGroups[key] ? Object.keys(matchStatusGroups[key]).length : 0
    const nonSelectedColor = count > 0 ? 'blue' : 'grey'
    return <Label color={selected ? 'black' : nonSelectedColor} size='mini' onClick={(e) => { oc(key) }}>
      {text}
      <Label circular style={{ marginLeft: '.5em' }} size='mini' >{count} </Label>
    </Label>
  }

  const ddb = () => {
    const ddis = Object.keys(filterButtons).map(key => {
      const count = matchStatusGroups && matchStatusGroups[key] ? Object.keys(matchStatusGroups[key]).length : 0
      return <Dropdown.Item onClick={(e) => { handleClick_filter(key) }}><Label color={count > 0 ? 'green' : 'grey'}>{count}</Label>{_.startCase(key)}</Dropdown.Item>
    })

    let cn = 'icon mini'
    if (matchStatusGroups) { cn += ' blue' }
    return <Dropdown
      text='Filters'
      icon='filter'
      floating
      labeled
      button
      className={cn}
      disabled={!enabled}
    >
      <Dropdown.Menu>
        <Dropdown.Header content='Action' />
        {ddis}
      </Dropdown.Menu>
    </Dropdown>
  }

  const buttons = () => {
    switch (scheduleFilterType) {
      case _scheduleFilterTypes.dropdown:
        return <div>
          {ddb(filterButtons)}
        </div>
      case _scheduleFilterTypes.label:
        const labels = Object.keys(filterButtons).map(key => (label(key, (selectedButton === key ? true : false), _.startCase(key), handleClick_filter)))
        return <div>
          {labels}
        </div>
      case _scheduleFilterTypes.list:
        const btns = Object.keys(filterButtons).map(key => (label(key, (selectedButton === key ? true : false), _.startCase(key), handleClick_filter)))
        return btns

      default:
      // nothing
    }
  }

  const buttonHeader = () => <div className={'schedule-buttons ' + scheduleFilterType}>
    {buttons()}
  </div>

  return buttonHeader()
}


export default ScheduleFilter