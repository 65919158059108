import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { appMonitorHandlers, appMonitorInitialState, appMonitorReducer } from '../reducers/AppMonitorReducer';
import { AppUserContext } from './AppUserContext';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';

/** Global VideoConferences (Icon, Image, PDF) for the Event taken from the database */
export const AppMonitorContext = createContext();

/** This will get the qAndA, ratings and survey */
const AppMonitorProvider = (props) => {

  const appUserContext = useContext(AppUserContext)

  const { appUser_state } = appUserContext ?? {}
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  // appMonitor_state
  const init_state = {}
  const [appMonitor_state, appMonitorDispatch] = useReducer(appMonitorReducer, appMonitorInitialState(init_state));
  const appMonitor_handlers = appMonitorHandlers(appMonitorDispatch, appMonitor_state)

  useEffect(
    () => {
      // only get videoConferences in the events collection  
      appMonitor_handlers.handleInit_appMonitor(appUserAccess)
    },
    // eslint-disable-next-line
    [appUserAccess] // does not re-rednder unless uivi has changed
  )

  return <AppMonitorContext.Provider value={{ appMonitor_state, appMonitor_handlers }}>
    {props.children}
  </AppMonitorContext.Provider>

}

export default AppMonitorProvider