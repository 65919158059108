import React, { createContext, useContext } from 'react';
import { separateContexts } from '../../common/filtering';
import { SettingsFrameworkContext } from '../../viewSettings/AppSettingsSidebar';
import { AppAccessContext } from './AppAccessContext';
import { AppFormsContext } from './AppFormsContext';
import { AppSettingsContext } from './AppSettingsContext';
import { AppUserContext } from './AppUserContext';
import { BaseSettingsContext } from './BaseSettingsContext';
import { EventInfoContext } from './EventInfoContext';
import { FirestoreContext } from './FirestoreContext';
import { FrameworkContext } from './FrameworkContent';
import { GlobalContext } from './GlobalContext';
import { HelpContext } from './HelpContext';
import { ParentContext } from './ParentContext';
import { ClientSettingsContext, EventSettingsContext, HomeSettingsContext } from './SettingsDataContext';

/**
 * Provider providing the following Contexts
  @components actionContext, baseSettingsContext, appAccessContext, appFormsContext, appSettingsContext, appUserContext,
   clientSettingsContext, createContext, dataModifyContext, eventInfoContext, eventSettingsContext, firestoreContext,
   frameworkContext, frameworkSettingsContext, helpContext, globalContext, homeSettingsContext, parentContext, uiItemContext,  viewSettingsContext,
 */
export const SettingParentContext = createContext();

/**
 * 
 * @param {*} props 
 * @returns SettingParentContext with createContext
 */
const SettingsParentProvider = (props) => {

  const parentContext = useContext(ParentContext)

  // const actionContext = useContext(ActionContext)
  const appAccessContext = useContext(AppAccessContext)
  const appFormsContext = useContext(AppFormsContext)
  const appSettingsContext = useContext(AppSettingsContext);
  const appUserContext = useContext(AppUserContext)
  const baseSettingsContext = useContext(BaseSettingsContext)
  const clientSettingsContext = useContext(ClientSettingsContext);
  const eventInfoContext = useContext(EventInfoContext);
  const eventSettingsContext = useContext(EventSettingsContext)
  const firestoreContext = useContext(FirestoreContext)
  const frameworkContext = useContext(FrameworkContext)
  const globalContext = useContext(GlobalContext)
  const helpContext = useContext(HelpContext)
  const homeSettingsContext = useContext(HomeSettingsContext)
  const settingsFrameworkContext = useContext(SettingsFrameworkContext)

  const contexts = {
    appAccessContext,
    appFormsContext,
    appSettingsContext,
    appUserContext,
    baseSettingsContext,
    clientSettingsContext,
    eventInfoContext,
    eventSettingsContext,
    firestoreContext,
    frameworkContext,
    globalContext,
    helpContext,
    homeSettingsContext,
    parentContext,
    settingsFrameworkContext,
  }

  Object.keys(contexts).forEach(k => {
    if (!contexts[k]) {
      console.log('zz', k)
    }
  })

  const settingsParentContext = separateContexts(contexts)

  return <SettingParentContext.Provider value={{ ...settingsParentContext }}>
    {props.children}
  </SettingParentContext.Provider>
}

export default SettingsParentProvider