import React, { useContext } from 'react';
import { Menu } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { gEnums } from '../../enums/globalEnums';
import MenuItems from './MenuItems';

/** Menu of navigation items */
const NavMenu = (props) => {

  const { items, handleClick, navOnly, sac, signInButtons } = props
  const { sob, sib } = signInButtons ?? {}

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { page_state, } = states
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { appUser_fns } = fns

  // pageContext 
  const { desktop, sideMenu, appAccess } = aps_global ?? {}
  const { navigationAlignment } = desktop ? desktop : {}
  const { iconsLeft } = sideMenu ?? {}
  const { appAccessType } = appAccess ? appAccess : {}

  const alignRight = navigationAlignment === gEnums.captionAlignmentTypes.right
  const allowSidebarView = appUser_fns.validateAccess_sidebar(appAccessType)

  const menuItems = () => {
    const nmiProps = { items, alignRight, handleClick }
    return <MenuItems {...nmiProps} />
  }

  const navMenu = () => {
    const sty = sac ? sac.style : {}
    const cn = !sac ? 'inh' : ''
    const sAndC = { className: cn, style: sty }
    if (iconsLeft) { sAndC.className += ' icons-left' }

    return <Menu borderless vertical={!navOnly ? true : false} fluid {...sAndC} inverted>
      <Menu.Menu position='right'>
        {sob}
        {sib}
      </Menu.Menu>
      {(allowSidebarView === gEnums.viewPermissionTypes.allow) && items && menuItems()}
    </Menu >
  }

  return navMenu()
}

export default NavMenu