import { getDatabase, onValue, ref, set, update } from "firebase/database";
import { getSportsYear } from '../../../global/firestoreData/helpers/years';
import { realtimeDocumentTypes } from "../cnr/reducers/SportsEditMatchReducer";
import _ from 'lodash'
import { matchCalcs } from "../helpers/match";
import { updateCurrentRealtimeMatchScoreToRtDb } from "./updateMatch";
import { convertHelpers } from "../../../global/common/convert";
import { _projectAppNames, _useMeDb, _useMeRtDb } from "../../../project/appConfiguration";
import { fs_db } from "../../../global/firestoreData/appData/fsAppData";

const rtPath_results = (realtimeDocumentType, pathViews, sportsYear, realtimeMatchKey, uid) => {
  const rtDoc = realtimeDocumentType === realtimeDocumentTypes.matchDay ? 'matchDateResults' : 'realtimeResults'
  const path = 'clients/' + pathViews.clients + '/events/' + pathViews.events + '/' + rtDoc + '/' + sportsYear
  return realtimeMatchKey ? path + '/' + realtimeMatchKey + '/' + uid : path
}

const rtPath_results_noId = (realtimeDocumentType, pathViews, sportsYear, realtimeMatchKey) => {
  const rtDoc = realtimeDocumentType === realtimeDocumentTypes.matchDay ? 'matchDateResults' : 'realtimeResults'
  const path = 'clients/' + pathViews.clients + '/events/' + pathViews.events + '/' + rtDoc + '/' + sportsYear
  return path + '/' + realtimeMatchKey
}


export const realTimeScoring = {
  ammendRealtimeResults: (rtr, gameScoringType) => ammendRealtimeResults(rtr, gameScoringType),
  calcMatchAndUpdateRtDb: (sportPermissions, sportData, match, match_true, pathViews, appUserInfo, latestSeason) => calcMatchAndUpdateRtDb(sportPermissions, sportData, match, match_true, pathViews, appUserInfo, latestSeason),
  deleteRealtimeDatabaseScores: (realtimeDocumentType, sportPermissions, sportData, seasonalUpdateType, pathViews, matchKeys, appUserInfo, latestSeason, callback) => deleteRealtimeDatabaseScores(realtimeDocumentType, sportPermissions, sportData, seasonalUpdateType, pathViews, matchKeys, appUserInfo, latestSeason, callback),
  getRealtimeScores: (realtimeDocumentType, sportData, pathViews, latestSeason, callback) => getRealtimeScores(realtimeDocumentType, sportData, pathViews, latestSeason, callback),
  updateRealtimeScoreToDatabase: (realtimeDocumentType, sportPermissions, sportData, seasonalUpdateType, pathViews, match_key, results, appUserInfo, latestSeason, callback) => updateRealtimeScoreToDatabase(realtimeDocumentType, sportPermissions, sportData, seasonalUpdateType, pathViews, match_key, results, appUserInfo, latestSeason, callback),
}

const getDb = () => {
  const fs = fs_db.get_fs()
  const { _app } = fs
  try {
    const db = getDatabase(_app);
    return db
  } catch (error) {
    const db = getDatabase();
    return db
  }
}
/**
 * Updates the scores into the realtime database
 * @param {object} pathViews 
 * @param {object} realtimeMatch 
 * @param {object} results 
 * @param {object} appUserInfo 
 * @param {string} latestSeason 
 * @param {function} callback 
 */
const updateRealtimeScoreToDatabase = async (realtimeDocumentType, sportPermissions, sportData, seasonalUpdateType, pathViews, match_key, results, appUserInfo, latestSeason, callback) => {

  const allowUpdate = sportPermissions.any && sportPermissions[seasonalUpdateType]
  const { useRtMatchData } = sportData

  const { uid, displayName } = appUserInfo ?? {}
  const sportsYear = getSportsYear(latestSeason)
  const db = getDb();
  const refString = rtPath_results(realtimeDocumentType, pathViews, sportsYear, match_key, uid)

  const updateData = {
    displayName,
    results
  }

  // clients/b4C6wHP0A5AgQPTD78ue/events/pojoSports/realtimeResults/2025/8tcl4K1DKQDpvK8SbTYc/isOUmqKoJGOrd6ncgWMxjClA1Cd2

  if (allowUpdate && useRtMatchData) {
    set(ref(db, refString), updateData).then(() => {
      callback && callback({ success: true })
    }).catch((error) => {
      console.log('updateRealtimeScoreToDatabase Error', error)
      callback && callback({ success: false, error })
    });
  } else {
    console.log('updateRealtimeScoreToDatabase not permitted', sportData, refString, updateData)
  }

}

/**
 * Gets the scores from the realtime database
 * @param {object} pathViews 
 * @param {string} latestSeason 
 * @param {function} callback 
 */
const getRealtimeScores = async (realtimeDocumentType, sportData, pathViews, latestSeason, callback) => {

  const { useRtMatchData } = sportData ?? {}

  const sportsYear = getSportsYear(latestSeason)
  const refString = rtPath_results(realtimeDocumentType, pathViews, sportsYear)

  const db = getDb();
  const _ref = ref(db, refString);

  if (useRtMatchData) {
    onValue(_ref, (snapshot) => {
      callback(snapshot.val())
    });
  }

}

/**
 * Updates the scores into the realtime database
 * @param {object} pathViews 
 * @param {object} realtimeMatch 
 * @param {object} results 
 * @param {object} appUserInfo 
 * @param {string} latestSeason 
 * @param {function} callback 
 */
const deleteRealtimeDatabaseScores = async (realtimeDocumentType, sportPermissions, sportData, seasonalUpdateType, pathViews, _matchKeys, appUserInfo, latestSeason, callback) => {

  const allowUpdate = sportPermissions.any && sportPermissions[seasonalUpdateType]
  const { useRtMatchData } = sportData ?? {}

  const { uid } = appUserInfo ?? {}
  const sportsYear = getSportsYear(latestSeason)
  const db = getDb();

  const promises = _.map(_matchKeys, async (matchKey) => {
    const refString = uid ? rtPath_results(realtimeDocumentType, pathViews, sportsYear, matchKey, uid) : rtPath_results_noId(realtimeDocumentType, pathViews, sportsYear, matchKey);
    if (allowUpdate && useRtMatchData) {
      try {
        await set(ref(db, refString), null);
        return { success: true };
      } catch (error) {
        return { success: false, error };
      }
    } else {
      console.log('-----------')
      console.log('deleteRealtimeDatabaseScores')
      console.log('allowUpdate', allowUpdate, seasonalUpdateType)
      console.log('refString', refString);
      return { success: true };
    }
  });

  const results = await Promise.all(promises);

  const success = results.every(result => result.success);
  callback && callback({ success, results });
}

const ammendRealtimeResults = (rtr, gameScoringType) => {
  if (rtr) {
    _.forEach(rtr, (rtMatch) => {
      _.forEach(rtMatch, (rtUserMatch) => {
        const { results } = rtUserMatch ?? {}
        const { scores } = results ?? {}
        if (scores && _.isArray(scores)) {
          results.scores = convertHelpers.createObjectFromArray(scores)
        }
        const m = { results }
        // matchCalcs.calcVballMatch(m, gameScoringType)
      })
    })
  }
}

/**
 * 
 * @param {boolean} useRtMatchData 
 * @param {object} match 
 * @param {object} match_true 
 * @param {object} pathViews 
 * @param {object} appUserInfo 
 * @param {number} latestSeason 
 * @description calculates the match and updates the realtime database
 */
const calcMatchAndUpdateRtDb = (sportPermissions, sportData, match, match_true, pathViews, appUserInfo, latestSeason) => {
  matchCalcs.calcVballMatch(match)
  const { useRtMatchData } = sportData ?? {}
  const current_props = { match_rt: match.results, match_true }
  if (useRtMatchData) {
    updateCurrentRealtimeMatchScoreToRtDb(sportPermissions, sportData, pathViews, current_props, appUserInfo, latestSeason)
  } else {
    console.log('calcAndUpdate not permitted', useRtMatchData, current_props)
  }
}