import _ from 'lodash'
import { addAdditionalProps } from '../../optionLists/additionalProps'
import { gEnums } from '../../enums/globalEnums'
import { getPropSectionsGrouped } from '../../viewSettings/helpers/settingsHelpers'

const rts = {
  handleActiveItem: 'handleActiveItem',
  handleGet_viewProps: 'handleGet_viewProps',
  handleDataHasChanged: 'handleDataHasChanged',
  handleEditor: 'handleEditor',
  handleFormItemChange: 'handleFormItemChange',
  handleFormPaste: 'handleFormPaste',
  handleSet_formProps: 'handleSet_formProps',
  handleOptionsList: 'handleOptionsList',
  handlePropDataEdit: 'handlePropDataEdit',
  handlePropPush: 'handlePropPush',
  handleUpdateLocalFormData: 'handleUpdateLocalFormData',
}

export const formReducer = (state, action) => {

  const { formProps, showAll, modifyType, swipedItem, allowImage, data_localForm, desktopMode, dataOptions, isDataContext, isViewSettingsContext } = state
  const { type } = action

  // viewProps are the items that will be displayed
  const { viewItem, viewProps } = formProps ?? {}

  switch (type) {

    case rts.handleActiveItem:
      return { ...state, activeItem: action.activeItem }

    case rts.handleGet_viewProps:
      if (viewItem && viewItem.propSections) {
        const _normalProps = getNormalProps(viewItem, viewProps, formProps, dataOptions, swipedItem, allowImage, isDataContext, isViewSettingsContext)
        const _sectionedProps = getSectionedProps(viewItem, viewProps, modifyType, showAll, desktopMode)
        return { ...state, viewProps_normal: _normalProps, viewProps_sectioned: _sectionedProps }
      } else if (action.viewProps) {
        const _normalProps = getNormalProps(viewItem, action.viewProps, formProps, dataOptions, swipedItem, allowImage, isDataContext, isViewSettingsContext)
        return { ...state, viewProps_normal: _normalProps }
      }
      return { ...state }

    case rts.handleEditor:
      return { ...state, currentFip: action.currentFip }

    case rts.handleOptionsList:
      return { ...state, optionsList: action.optionsList }

    case rts.handlePropDataEdit:
      return { ...state, showPropDataEdit: !state.showPropDataEdit, itemPropEdit: action.itemProp }

    case rts.handlePropPush:
      const cd = { ...data_localForm }
      if (cd.key) {
        cd.text = _.startCase(cd.key)
        cd.value = cd.key
        return { ...state, data_localForm: cd }
      } else {
        return { ...state }
      }

    case rts.handleDataHasChanged:
      return { ...state, dataHasChanged: action.opts }

    case rts.handleFormItemChange:
      // triggers as soon as a value has changed.    
      const { fd, propItemData: pid } = getChange(state, action)
      return { ...state, dataHasChanged: true, propItemData: pid, data_localForm: fd }

    case rts.handleSet_formProps:
      return { ...state, formProps: action.formProps }

    case rts.handleUpdateLocalFormData:
      return { ...state, data_localForm: action.data_localForm }

    case rts.handleFormPaste:
      return { ...state, data_localForm: action.copiedData }

    default:
      return { ...state }
  }
}

export const formInitialState = (initState) => {
  return { ...initState, dataHasChanged: false } // , data_localForm: {}
};

export const formHandlers = (dispatch) => {
  return {
    handleGet_viewProps: (viewProps) => { dispatch({ type: rts.handleGet_viewProps, dispatch, viewProps }) },
    handleActiveItem: (activeItem) => { dispatch({ type: rts.handleActiveItem, dispatch, activeItem }) },
    handleDataHasChanged: (opts) => { dispatch({ type: rts.handleDataHasChanged, dispatch, opts }) },
    handleEditor: (currentFip) => { dispatch({ type: rts.handleEditor, dispatch, currentFip }) },
    handleFormItemChange: (e, data, items, arrayItems) => { dispatch({ type: rts.handleFormItemChange, dispatch, e, data, items, arrayItems }) },
    handleSet_formProps: (formProps) => { dispatch({ type: rts.handleSet_formProps, dispatch, formProps }) },
    handleOptionsList: (optionsList) => { dispatch({ type: rts.handleOptionsList, dispatch, optionsList }) },
    handlePropDataEdit: (itemProp) => { dispatch({ type: rts.handlePropDataEdit, dispatch, itemProp }) },
    handlePropPush: () => { dispatch({ type: rts.handlePropPush, dispatch }) },
    handleUpdateLocalFormData: (data_localForm) => { dispatch({ type: rts.handleUpdateLocalFormData, dispatch, data_localForm }) },
    handleFormPaste: (copiedData) => { dispatch({ type: rts.handleFormPaste, dispatch, copiedData }) },
  }
}

const getNormalProps = (viewItem, viewProps, formProps, dataOptions, swipedItem, allowImage, isDataContext, isViewSettingsContext) => {

  addAdditionalProps(swipedItem, viewProps, viewItem, dataOptions, allowImage)

  const { editableItem } = formProps ?? {}

  let props_normal;

  if (isViewSettingsContext) {
    props_normal = { ...viewProps }
  } else if (isDataContext && !editableItem) {
    const { props } = viewItem ?? {}
    props_normal = props ? { ...props } : { ...viewProps }
  } else {
    props_normal = { ...viewProps }
  }
  return props_normal
}

const getSectionedProps = (viewItem, viewProps, modifyType, showAll, desktopMode) => {
  const _showAll = showAll || (modifyType && (modifyType === 'add' || modifyType === gEnums.dataUpdateTypes.addData))
  const psgs = getPropSectionsGrouped(viewProps, viewItem.propSections, _showAll, desktopMode)
  Object.keys(psgs).forEach(key => {
    const psg = psgs[key]
    if (psg.props) {
      psg.props = _.sortBy(psg.props, 'position')
    }
  })
  return psgs
}

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @param {object} logging 
 * @param {boolean} logErrors 
 * @returns 
 */
const getChange = (state, action, logging, logErrors) => {

  const { data_localForm, formProps } = state
  const { data, items, arrayItems } = action
  const { dataListType, propname, value, options, formItemType } = data ?? {}
  const { camelCase } = formProps ?? {}

  const fd = data_localForm ? _.cloneDeep(data_localForm) : {}

  const deleteProps = fd._deleteProps ? fd._deleteProps : []

  try {
    if (items) {
      fd[propname] = items
    } else if (arrayItems) {
      fd[propname] = arrayItems
    } else {
      if (data) {
        if (dataListType) {
          fd['listItems'] = camelCase ? _.camelCase(value) : value
        } else {
          if (data.type === 'checkbox') {
            fd[propname] = data.checked
          } else {
            fd[propname] = camelCase ? _.camelCase(value) : value
          }
        }
      }
    }

    let _propItemData;

    if (value && options) {
      const option = _.find(options, { 'key': camelCase ? _.camelCase(value) : value })
      _propItemData = {
        propKey: propname,
        ...option
      }

      switch (formItemType) {
        case gEnums.formItemTypes.nameSelect:
          if (option && option.text) { fd[propname] = option.text }
          if (option && option.altvalue && option.altprop) { fd[option.altprop] = option.altvalue }
          break;
        default:
        // nothing
      }
    }

    if (logging && logging.allowLogging && logging.logFormDataChanges) { console.log('logging >', 'Form Data', fd) }

    switch (propname) {
      case 'allowAction':
        if (fd && _.isBoolean(fd[propname]) && fd[propname] === false) {
          if (!deleteProps.includes(propname)) {
            deleteProps.push(propname)
          }
        }
        break;
      default:
        if (fd && !_.isBoolean(fd[propname]) && !_.isNumber(fd[propname]) && (_.isNull(fd[propname]) || _.isEmpty(fd[propname]))) {
          if (!deleteProps.includes(propname)) {
            deleteProps.push(propname)
          }
        }
      // nothing
    }

    if (deleteProps.length > 0) {
      // console.log('deleteProps', deleteProps)
      fd._deleteProps = deleteProps
    }

    return { fd, propItemData: _propItemData }
  } catch (error) {
    logErrors && console.error(error)
    return { fd: {}, propItemData: null }
  }
}
