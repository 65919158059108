import { deleteObject, ref } from "firebase/storage";
import { callFunctionDirect } from "./fbAll";
import { getFirebaseStorage } from "../storage/storageHelpers";

const fbFunctionTypes = {
  deleteBucket: 'deleteBucket',
  deleteStorageBucketFiles: 'deleteStorageBucketFiles',
  deleteStorageFolder: 'deleteStorageFolder',
  getFirebaseFiles: 'getFirebaseFiles',
  getBlob: 'getBlob',
  getBlobb: 'getBlobb',
}

export const deleteStorageFolder = async (fullPath) => {
  try {
    const data = { storageRootPath: fullPath }
    const response = await callFunctionDirect(fbFunctionTypes.deleteStorageFolder, data);
    console.log('response', response);
    return response
  } catch (error) {
    console.error(error)
  }
}

export const getFirebaseFiles = async (folder) => {
  const data = { folder }
  try {
    const response = callFunctionDirect(fbFunctionTypes.getFirebaseFiles, data);
    return response
  } catch (error) {
    console.error(error)
  }
}

export const getBlob = async (url, fileName) => {
  try {
    const fileName_s = fileName ? fileName.split('.') : []

    let type = { type: 'image/png' }

    if (fileName_s && fileName_s[1]) {
      if (fileName_s[1].indexOf('gif') >= 0) { type = { type: 'image/gif' } }
      if (fileName_s[1].indexOf('jpg') >= 0) { type = { type: 'image/jpg' } }
    }

    const data = { url, fileName, type }
    const response = callFunctionDirect(fbFunctionTypes.getBlob, data);
    const { data: dr } = response ?? {}
    const { result } = dr ? dr : {}
    const { blob, file } = result ?? {}
    console.log('blob', blob)
    console.log('file', file)
    return response
  } catch (error) {
  }
}

export const deleteStorageBucket = async (storageRootPath, dispatch) => {
  const storage = getFirebaseStorage()
  const manifestRef = ref(storage, storageRootPath + '/manifest')
  deleteManifestFiles(manifestRef, 100, dispatch)
}

const deleteManifestFiles = async (manifestRef, count, dispatch) => {
  const promises = []
  getManifestFilesPromise(manifestRef, count).then(m => {
    deleteManifestFilesPromise(manifestRef, m).then(res => {
      deleteManifestFiles(manifestRef, count, dispatch)
    })
  }).catch((error) => {
    console.error(error)
  })
  return Promise.all(promises)
}

const getManifestFilesPromise = async (manifestRef, count) => {
  const promises = []
  promises.push(await manifestRef.list({ maxResults: count }))
  return Promise.all(promises)
}

const deleteManifestFilesPromise = async (manifestRef, files) => {
  const promises = []
  const dd = []
  Object.keys(files).forEach(key => {
    const file = files[key]
    const { items } = file ? file : {}
    if (items) {
      items.forEach(item => {
        const { fullPath, name } = item ?? {}
        if (fullPath) {
          if (!dd.includes(fullPath)) { dd.push(fullPath) }
          dd[name] = { deleted: true }
          promises.push(deleteObject(manifestRef + '/' + name))
        }
      })
    }
  })
  return Promise.all(promises)
}