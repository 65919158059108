
import React, { useContext, useEffect, useState } from 'react';
import { Label, Button, Icon, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { ViewSettingsContext } from '../../cnr/contexts/ViewSettingsContext';
import { copyObj } from '../../common_web/copy';
import { gEnums } from "../../enums/globalEnums";
import { _storageSettings } from '../../storage/storageHelpers';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import NoData from '../alerts/NoData';
import PendingWait from '../alerts/pendings/PendingWait';
import Uploader from '../uploading/Uploader';
import ImagesGrid from './ImagesGrid';
import { ElementContext } from '../../cnr/contexts/ElementContext';
import { clickToMethods } from '../../cnr/reducers/ElementReducer';
import { uniqueKey } from '../../common/keys';

const _labelColors = {
  data: 'green',
  time: 'green',

}

/**
 * Displays the files within storage. 
 * @param {object} props (allowMultiItemSelect, fileViewer_handlers, storageLocationType, storageType, viewerProps)
 *  @description Clicking on the image will get and display the full image
 * @description Allows upload using Uploader
 * @note If the list is for manifest icons, displays ManifestViewer
 *  */

const PdfGallery = (props) => {

  const {
    asLabel,
    itemPdf,
    showFile,
  } = props ?? {}

  const storageLocationType = gEnums.storageLocationTypes.pagePdf
  const storageType = gEnums.storageTypes.icon

  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { appUser_state, paps_state, page_state, storage_state } = states ?? {}
  const { storage_handlers } = handlers ?? {}

  // authContext 
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  // papsContext 
  const { view, viewKey } = paps_state
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_styles } = pageSettings ?? {}
  const { appGallery } = aps_global ?? {}
  const { allowAppUserUpload, allowMultiUpload, uploadAccessLevel } = appGallery ?? {}

  const elementContext = useContext(ElementContext)
  const { element_state } = elementContext ?? {}
  const { clickTo } = element_state ?? {}
  const { clickToMethod } = clickTo ?? {}

  // storageContext 
  const {
    confirmation,
    currentGalleryItems,
    galleries,
    globalFiles,
    selectedItems,
    showApprovalWizard,
    storagePermissions,
  } = storage_state ?? {}

  const { pdf: global_pdf } = globalFiles ?? {}
  const { pdf: gallery_pdf } = galleries ?? {}

  // viewSettingsContext
  const viewSettingsContext = useContext(ViewSettingsContext);
  const { viewSettings_handlers } = viewSettingsContext ?? {}

  const styleAndClass = aps_styles && aps_styles[gEnums.projectStyles.actionButton] ? copyObj(aps_styles[gEnums.projectStyles.actionButton]) : {}
  styleAndClass.className += ' solo'

  // local state  
  const [galleryInfo, setGalleryInfo] = useState({ has: false, count: 0, gallery: null })
  const [showUpload, setShowUpload] = useState()
  const [showPdfs, setShowPdfs] = useState()
  const [pdfsFetched, setPdfsFetched] = useState()
  const [galleryExists, setGalleryExists] = useState()
  const [itemPdfUrl, setItemPdfUrl] = useState()

  // clients/w4wsbHrmO5Uw5GL14M1t/events/65C136255E45E/_page_pdf/attendees/6669B42A438CB
  // clients/w4wsbHrmO5Uw5GL14M1t/events/65C136255E45E/_page_pdf/attendees/6669B42A438CB
  // clients/w4wsbHrmO5Uw5GL14M1t/events/65C136255E45E/_page_pdf/attendees/6669B42A438CB

  useEffect(
    () => {
      setPdfsFetched(false)
      let galleryType = _storageSettings.galleryTypes.pdf
      const _galleryExists = galleries && galleries[galleryType] && galleries[galleryType][view] && galleries[galleryType][view][viewKey]
      if (!_galleryExists) {
        const _storageProps = {
          allowAppUserUpload,
          appUserAccess,
          galleryType,
          storageLocationType: gEnums.storageLocationTypes.pagePdf,
          storageType,
          uploadAccessLevel,
          view,
          viewKey,
        }
        // get s the gallery for the page if does not aleady exist 
        setGalleryExists(false)
        storage_handlers.handleGet_galleryAny(_storageProps)
      } else {
        setGalleryExists(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gallery_pdf, view, viewKey, confirmation]
  )

  useEffect(
    () => {
      let galleryType = _storageSettings.galleryTypes.pdf
      if (viewKey && galleries && galleries[galleryType] && galleries[galleryType][view] && galleries[galleryType][view][viewKey]) {
        const _gallery = galleries[galleryType][view][viewKey]
        const _galleryInfo = {
          gallery: _gallery,
          has: _gallery && Object.keys(_gallery).length > 0,
          count: _gallery ? Object.keys(_gallery).length : 0
        }
        if (_galleryInfo.count === 1) {
          _galleryInfo.soloItem = Object.values(_gallery)[0]
        }
        setGalleryInfo(_galleryInfo)
        setPdfsFetched(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [galleryExists, pdfsFetched, currentGalleryItems]
  )

  useEffect(
    () => {
      if (global_pdf && global_pdf[itemPdf] && global_pdf[itemPdf].urls && global_pdf[itemPdf].urls.full) {
        setItemPdfUrl(global_pdf[itemPdf].urls.full)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [global_pdf, itemPdf]
  )

  useEffect(
    () => {
      if (confirmation && viewSettings_handlers) {
        viewSettings_handlers.handleStopUpdate()
        handleRefresh()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmation]
  )

  const handleRefresh = () => storage_handlers.handleGet_globalStorageFiles(storageLocationType, storageType)
  const handleShowUpload = () => setShowUpload(!showUpload)
  const openAppUrl = (appUrl) => { window.open(appUrl, '_blank') }

  const handlePdfIconClick = (e, fileName) => {
    e && e.stopPropagation()
    if (clickTo) {
      switch (clickToMethod) {
        case clickToMethods.openAppPdf:
          openAppUrl(clickTo.value)
          break;
        default:
          fileName && openAppUrl(fileName)
      }
    } else {
      fileName && openAppUrl(fileName)
    }
  }

  const imageGridProps = {
    handlePdfIconClick,
    storagePermissions,
    selectedItems,
    showApprovalWizard,
    documentKey: null,
    isFullGalleryItem: false,
    gallery: null,
  }

  /** Returns a Grid with images of a single document */
  const pdfGalleryGrid = (documentKey, isFullGalleryItem) => {
    imageGridProps.documentKey = documentKey
    imageGridProps.isFullGalleryItem = isFullGalleryItem
    imageGridProps.gallery = galleryInfo.gallery
    imageGridProps.isPdf = true
    imageGridProps.origin = 'pdfGallery'
    return <ImagesGrid igProps={imageGridProps} />
  }

  const fullPageUploader = () => <Uploader
    allowMultiUpload={allowMultiUpload}
    handleCancel={handleShowUpload}
    origin={'pdfGallery'}
    storageLocationType={storageLocationType}
    storageType={storageType}
    useFull={true}
  />

  const wrapperContent = () => {
    if (!pdfsFetched) {
      return <PendingWait />
    } else {
      if (showUpload) {
        return fullPageUploader()
      } else {
        return galleryInfo.has ? pdfGalleryGrid(storageLocationType) : <NoData altCaption={'PDFs'} />
      }
    }
  }

  const wrapper = () => <Wrapper
    content={wrapperContent()}
    wrapperType={wrapperTypes.padded}
    updating={false}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handlePdfIconClick}
    topperCaption={'PDF Gallery'}
  />

  const pdfButton = (showCount) => {
    const _count = itemPdf ? 1 : galleryInfo.count
    const { soloItem } = galleryInfo ?? {}
    const { customMetadata, urls } = soloItem ?? {}
    const { name } = customMetadata ?? {}
    const { full } = urls ?? {}
    if (_count === 1 && name && full) {
      return <Button className='btn-pdf' icon labelPosition='right' size='mini' color='blue' onClick={(e) => { handlePdfIconClick(e, full) }}>
        <Icon name='file pdf' />
        {name ? name : 'PDF'}
      </Button>
    } else {
      return <Button className='btn-pdf' icon labelPosition='right' size='mini' color='blue' onClick={(e) => { handlePdfIconClick(e) }}>
        <Icon name='file pdf' />
        PDF
      </Button>
    }
  }

  const icon = (iconName, color) => <Icon color={color} name={iconName} />

  const pdfLabel = () => {
    const dtProp = 'pdf'
    const _count = itemPdf ? 1 : galleryInfo.count
    const { soloItem } = galleryInfo ?? {}
    const { customMetadata, urls } = soloItem ?? {}
    const { name } = customMetadata ?? {}
    const { full } = urls ?? {}
    return <Label
      key={uniqueKey('dtl', 3, dtProp)}
      className={'pi-' + dtProp}
      onClick={(e) => { handlePdfIconClick(e, full) }}
    >
      {icon('file pdf', 'blue')}
      {name}
    </Label>
  }

  /**
   * 
   * @returns an indicator or file
   */
  const pdfContent = () => {
    if (asLabel) {
      return pdfLabel(true)
    } else if (showFile) {
      return <Segment.Group>
        <Segment>{itemPdf}</Segment>
        <Segment>{pdfButton()}</Segment>
      </Segment.Group>
    } else {
      return pdfButton(true)
    }
  }

  if (itemPdfUrl) {
    return pdfContent()
  } else {
    if (pdfsFetched && galleryInfo.has) {
      if (showPdfs) {
        return fullPageWrapper()
      } else {
        return pdfContent()
      }
    } else {
      return <div></div>
    }
  }
}

export default PdfGallery