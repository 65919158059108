import React, { useContext } from 'react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { appIconTypes } from '../../enums/appIconTypes';
import { gEnums } from '../../enums/globalEnums';
import GenericIcon from '../../genericControls/GenericIcon';
import { H1Topper, H2Topper, H3Topper } from '../../wrappers/TopperWrappers';
import Wrapper from '../../wrappers/Wrapper';
import WrapperSidebar from '../../wrappers/WrapperSidebar';
import { getArrayIndex } from '../../common/sorting';

const StepWizard = (props) => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state } = states ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_styles, aps_global } = pageSettings ?? {}

  const { projectOptions } = aps_global ?? {}
  let { useAppDarkMode } = projectOptions ?? {}

  const topSac = aps_styles ? aps_styles[gEnums.projectStyles.topMenu] : {}

  const {
    allowBack,
    allowForward,
    confirmation,
    content,
    containerCn,
    darkMode,
    fullMode,
    halfModal,
    handleCancel,
    handleHelp,
    help,
    isBack,
    noModal,
    notification,
    setStep,
    step,
    stepCaption,
    stepCaption2,
    steps,
    stepValidations,
    topperCaption,
    topperCaption2,
    headerCn,
    topperHeader,
    updating,
    usePlaceholder,
    useTopper,
    useVertical,
  } = props

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers } = uiItemContext ?? {}
  const { handleClose_openedItem } = item_handlers ?? {}

  let _handleCancel = handleCancel
  if (!_handleCancel && handleClose_openedItem) { _handleCancel = handleClose_openedItem }

  const stepCount = steps ? steps.length : 0

  const buttonProps = { color: 'black', inverted: true }
  const buttonProps_cancel = { color: isBack ? 'blue' : 'black', inverted: true }

  const handleStep = (co) => {
    const { count } = co
    let newStep = step.index + count
    if (newStep < 0) { newStep = 0 }
    if (newStep > (stepCount - 1)) { newStep = stepCount - 1 }
    const name = steps[newStep];
    setStep({ index: newStep, name })
  }

  const handleMenuClick = (item) => {
    const ni = getArrayIndex(steps, item)
    const name = steps[ni];
    setStep({ index: ni, name })
  }

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const backButton = () => gi('btn-back', appIconTypes.arrowLeft, handleStep, { count: -1 }, { ...buttonProps, nb: false, nf: true, disabled: !allowBack })
  const cancelButton = () => gi('btn-cancel', isBack ? appIconTypes.arrowLeft : appIconTypes.delete, _handleCancel, null, { ...buttonProps_cancel, nb: false, nf: true })
  const nextButton = () => gi('btn-forward', appIconTypes.arrowRight, handleStep, { count: 1 }, { ...buttonProps, nb: false, nf: true, disabled: !allowForward })
  const helpButton = () => gi('btn-help', appIconTypes.help, handleHelp, { count: 1 }, { ...buttonProps, nb: false, nf: true, color: 'red' })

  const toppers = () => <React.Fragment>
    <div>{topperCaption}</div>
    <div style={{ paddingTop: '.5em' }} >{topperCaption2}</div>
    {handleHelp && <div>{'xxxx'}</div>}
  </React.Fragment>

  const topperSimple = () => <H1Topper
    content={topperCaption2 ? toppers() : topperCaption}
    style={topSac.style}
  ></H1Topper>

  const topper = () => <H2Topper
    leftContent={cancelButton()}
    rightContent={topperCaption2 ? toppers() : topperCaption}
    style={topSac.style}
  ></H2Topper>

  const helpTopper = () => <H3Topper
    leftContent={cancelButton()}
    middleContent={topperCaption2 ? toppers() : topperCaption}
    rightContent={helpButton()}
    style={topSac.style}
  />

  const header = () => <H3Topper
    leftContent={backButton()}
    middleContent={stepCaption}
    rightContent={nextButton()}
    middleContent2={stepCaption2}
    style={topSac.style}
  />

  const topperContent = () => {
    if (handleHelp) {
      return _handleCancel ? helpTopper() : topperSimple()
    } else {
      return _handleCancel ? topper() : topperSimple()
    }
  }

  const th = () => topperHeader ? topperHeader : null

  let wcn = 'sw-wrapper'

  if (_handleCancel) { wcn += ' topper' }
  if (darkMode) { wcn += ' app-dark-mode ' }
  if (useAppDarkMode) { wcn += ' app-dark-mode ' }
  if (containerCn) { wcn += ' ' + containerCn }


  const wizard = () => <Wrapper
    confirmation={confirmation}
    content={content}
    css={{ container: wcn }}
    fullMode={fullMode}
    halfModal={halfModal}
    header={steps ? header() : th()}
    help={help}
    modal={noModal ? false : true}
    usePlaceholder={usePlaceholder}
    notification={notification}
    topper={useTopper && topperContent()}
    updating={updating}
  />

  if (useVertical) {
    return <WrapperSidebar
      menuItems={steps}
      stepValidations={stepValidations}
      handleMenuClick={handleMenuClick}
      content={content}
      topperCaption={topperCaption}
      topperCaption2={topperCaption2}
      containerCn={containerCn}
      headerCn={headerCn}
      useFull={true}
      handleCancel={_handleCancel}
    />
  } else {
    return wizard()
  }

}

export default StepWizard