import React, { useRef } from 'react';
// import { Button, Embed, Segment } from 'semantic-ui-react'
import { Segment } from 'semantic-ui-react';
import { ControlBar, Player } from 'video-react';
import "video-react/dist/video-react.css"; // import css
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';

export const Video = (props) => {

  const { url, handleCancel } = props ?? {}
  // https://www.youtube.com/watch?v=70qjkDRGAfk&feature=youtu.be
  // return (
  //   <Embed
  //     id='70qjkDRGAfk'
  //     placeholder='/images/image-16by9.png'
  //     source='youtube'
  //   />
  // )
  // return (
  //   <Embed
  //     id='125292332'
  //     placeholder='/images/vimeo-example.jpg'
  //     source='vimeo'
  //   />
  // )
  // return (
  //   <Embed
  //     icon='right circle arrow'
  //     placeholder='/images/image-16by9.png'
  //     url='https://d1i86v6eoquaxn.cloudfront.net/users/776b342f-0661-4085-8044-67c133db7bff/forever_files/a6ea49fb-3a1d-4b2d-a699-d8229e6ee1ea/transcoded.mp4'
  //   />
  // )

  // const aaa = 'https://d1i86v6eoquaxn.cloudfront.net/users/776b342f-0661-4085-8044-67c133db7bff/forever_files/a6ea49fb-3a1d-4b2d-a699-d8229e6ee1ea/transcoded.mp4'

  const player = useRef(null);

  // const [url, setUrl] = useState()

  // const handleUrl = () => setUrl(aaa)

  // useEffect(() => {
  //   handleUrl()
  // }, []);

  // const play = () => player.current.actions.play()
  // const pause = () => player.current.actions.pause()
  // const load = () => player.current.actions.load()

  // return <Embed
  //   id='g9LfyCZjeKI'
  //   placeholder='/images/image-16by9.png'
  //   source='youtube'
  // />

  const content = () => <Segment className={'seg-video'}>
    <Player
      ref={player}
      autoPlay
    >
      <source src={url} />
      <ControlBar autoHide={false} className="my-class" />
    </Player>
    {/* <div className="py-3">
      <Button className="mr-3" onClick={(e) => { play() }}>Play</Button>
      <Button className="mr-3" onClick={(e) => { pause() }}>Pause</Button>
      <Button className="mr-3" onClick={(e) => { load() }}>Load</Button>
    </div> */}
  </Segment>

  const fpw = () => <FullPageWrapper
    topperCaption={'Video'}
    content={content()}
    handleCancel={handleCancel}
  ></FullPageWrapper>

  return fpw()
}
