import _ from 'lodash'
import { getFirstObject } from '../../common/convert'
import { getAlphaMenuItems } from '../../components/menus/genericMenuItem'
import { gEnums } from '../../enums/globalEnums'
import { currentHelpers } from '../../redirection/current'

const rts = {
  handleAmmend_fromGroupInfo: 'handleAmmend_fromGroupInfo',
  handleInit_alpha: 'handleInit_alpha',
  handleSet_alphaGroups: 'handleSet_alphaGroups',
  handleSet_alphaItem: 'handleSet_alphaItem',
  handleSet_alphaItems: 'handleSet_alphaItems',
  handleSet_currentAlphaIndex: 'handleSet_currentAlphaIndex',
  handleSet_letterCount: 'handleSet_letterCount',
  handleSet_letterShowing: 'handleSet_letterShowing',
  handleSet_groupInfo: 'handleSet_groupInfo',
}

const _alphaLimit = 1

export const alphaReducer = (state, action) => {

  const { type } = action
  const { alphabetSnC, grouping: grouping_state, restrictToExisting: restrictToExisting_state, dataAlphaProp } = state

  switch (type) {

    case rts.handleInit_alpha:

      const { grouping, dataItems, viewListData, restrictToExisting, storageItem, alphaValue } = action

      const _isFresh = dataItems ? false : true

      const _grouping = grouping ? grouping : grouping_state
      const _restrictToExisting = restrictToExisting ? restrictToExisting : restrictToExisting_state

      const { alphaProp } = _grouping ?? {}

      let _alphaProp = alphaProp

      const _data = dataItems ? dataItems : viewListData
      const dataCount = _data ? _.size(_data) : 0
      const useAlpha = dataCount >= _alphaLimit

      const { item: dataItem } = getFirstObject(_data)
      if (!_alphaProp && dataItem) {
        if (dataItem.lastName) {
          _alphaProp = 'lastName'
        } else if (dataItem.name) {
          _alphaProp = 'name'
        }
      }

      const { alphaItems, firstAlpha: fa, letterCount } = getAlphaMenuItems(_data, _alphaProp, _restrictToExisting)
      let _alphaItem = currentHelpers.storageItem_get(storageItem) ? parseInt(currentHelpers.storageItem_get(storageItem)) : null
      if (!_alphaItem) { _alphaItem = fa }
      if (alphaValue) { _alphaItem = alphaValue }

      const { snc, isVert } = ammendSnC(alphabetSnC, alphaItems, _grouping)

      return {
        ...state,
        alphaItem: _isFresh ? _alphaItem : state.alphaItem,
        alphaItems,
        dataAlphaProp: _alphaProp, // action.dataAlphaProp,
        grouping: _grouping,
        isVert,
        letterCount,
        restrictToExisting: _restrictToExisting,
        snc,
        storageItem: storageItem,
        useAlpha,
      }

    case rts.handleSet_alphaItem:
      return { ...state, alphaItem: action.alphaItem }

    case rts.handleSet_alphaItems:
      return { ...state, alphaItems: action.alphaItems }

    case rts.handleSet_currentAlphaIndex:
      return { ...state, currentAlphaIndex: action.alphaIndex }

    case rts.handleSet_letterShowing:
      return { ...state, letterShowing: action.letterShowing }

    case rts.handleSet_letterCount:
      return { ...state, letterCount: action.letterCount }

    case rts.handleSet_alphaGroups:
      return { ...state, alphaGroups: action.alphaGroups }

    case rts.handleSet_groupInfo:
      const { selectedGroupItem, data } = action
      const xxx = getAlphaMenuItems(data, dataAlphaProp, restrictToExisting_state)
      const _groupedInfo = { ...state.groupedInfo }
      _groupedInfo[selectedGroupItem] = xxx
      return { ...state, groupedInfo: _groupedInfo }

    case rts.handleAmmend_fromGroupInfo:
      const { alphaInfo } = action ?? {}
      const { firstAlpha } = alphaInfo ?? {}
      return { ...state, ...alphaInfo, alphaItem: firstAlpha }

    default:
      return { ...state }
  }
}

export const alphaInitialState = (init_state) => {
  return { ...init_state, snc: {}, groupedInfo: {} }
};

export const alphaHandlers = (dispatch, state) => {
  return {
    handleAmmend_fromGroupInfo: (alphaInfo) => { dispatch({ type: rts.handleAmmend_fromGroupInfo, dispatch, alphaInfo }) },
    handleInit_alpha: (viewListData, dataItems, grouping, restrictToExisting, storageItem, alphaValue) => { dispatch({ type: rts.handleInit_alpha, dispatch, viewListData, dataItems, grouping, restrictToExisting, storageItem, alphaValue }) },
    handleSet_alphaGroups: (alphaGroups) => { dispatch({ type: rts.handleSet_alphaGroups, dispatch, alphaGroups }) },
    handleSet_alphaItem: (alphaItem) => { dispatch({ type: rts.handleSet_alphaItem, dispatch, alphaItem }) },
    handleSet_alphaItems: (alphaItems) => { dispatch({ type: rts.handleSet_alphaItems, dispatch, alphaItems }) },
    handleSet_currentAlphaIndex: (alphaIndex) => { dispatch({ type: rts.handleSet_currentAlphaIndex, dispatch, alphaIndex }) },
    handleSet_letterCount: (letterCount) => { dispatch({ type: rts.handleSet_letterCount, dispatch, letterCount }) },
    handleSet_letterShowing: (letterShowing) => { dispatch({ type: rts.handleSet_letterShowing, dispatch, letterShowing }) },
    handleSet_groupInfo: (selectedGroupItem, data) => { dispatch({ type: rts.handleSet_groupInfo, dispatch, selectedGroupItem, data }) },
  }
}

const ammendSnC = (alphabetSnC, alphaItems, grouping) => {

  let isVert;
  let snc;

  if (alphaItems.length === 0) {
    snc = { ...alphabetSnC, ...{ style: { display: 'none' } } }
  } else {
    snc = { ...alphabetSnC }
  }

  const {
    alphaDisplayType,
    useVerticalHidden,
    verticalOpacity,
  } = grouping ?? {}

  switch (alphaDisplayType) {
    case gEnums.alphaDisplayTypes.vertical:
    case gEnums.alphaDisplayTypes.verticalFull:
      isVert = true
      if (snc && snc.className && snc.className.indexOf('alpha-float') < 0) {
        snc.className += ' alpha-float abs-side'
        if (useVerticalHidden) {
          snc.className += ' hidden'
          if (verticalOpacity) {
            snc.className += ' vo-' + verticalOpacity
          }
        }
      }
      break;
    default:
    // nothing
  }

  return { snc, isVert }

}