import _ from 'lodash'

export const addIdsToDataItem = (firstData) => {
  if (firstData) {
    _.forEach(firstData, (data, key) => {
      if (_.isObject(data)) {
        data.id = key
        data._itemKey = key
      } else {
        // console.log('firstData', firstData)
      }
    })
  }
}

export const addParentKeysToDataItem = (parentKeys, firstData) => {
  if (firstData && parentKeys) {
    _.forEach(firstData, (data, key) => {
      _.forEach(parentKeys, (parentKey, pk) => {
        data[pk] = parentKeys[pk]
      })
    })
  }
}

export const updateStyleSettings = (styleAndClass, styleType, styleValue) => {
  const { style } = styleAndClass ?? {}
  const _style = { ...style, [styleType]: styleValue }
  return { ...styleAndClass, style: _style }
};