import React, { createContext, useEffect, useReducer, useState } from 'react';
import StartDimmer from '../../components/alerts/StartDimmer';
import { startHandlers, startInitialState, startReducer, startTypes } from '../reducers/StartReducer';
import useTimeMeasurement from '../reducers/reducerHelpers/validTimer';
import { _checkComponentTimes, _showStates } from '../../../App';

const useStart = true
const killInterval = 0.5

/** Displays a `PageDimmer` until the `handleAmmend_startDimmer` function in called */

/**
 * @state start_state (`dimmerOn`, `startType`, `startTime`, `killInterval`, `startCaption`, `startDocName`, `killDimmer`)
 * @handlers start_handlers (`handleKillDimmer`, `ha
 */
export const StartContext = createContext();

/** 
 * @param {object} props 
 * @state start_state (`dimmerOn`, `startType`, `startTime`, `killInterval`, `startCaption`, `startDocName`, `killDimmer`)
 * @handlers start_handlers (`handleKillDimmer`, `handleTimeLeft`, `handleAmmend_startDimmer`)
 * @description Starting point for the App/Page 
 * @description Displays a `PageDimmer` until the `handleAmmend_startDimmer` function in called
 */
const StartProvider = (props) => {

  const init_state = { startType: startTypes.app, startTime: Date.now(), killInterval }
  const [start_state, start_dispatch] = useReducer(startReducer, init_state, startInitialState)
  const start_handlers = startHandlers(start_dispatch, start_state)
  const { killDimmer, timeLeft, startTime } = start_state

  if (_showStates.any && _showStates.start) {
    console.log(Object.keys(start_state))
    console.log(Object.keys(start_handlers))
  }

  const [componentTimes, setComponentTimes] = useState({})

  const start_fns = {
    useTimeMeasurement: (typeOf, valid) => {
      const cb = (x) => {
        const _componentTimes = { ...componentTimes }
        _componentTimes[typeOf] = x
        setComponentTimes(_componentTimes)
      }
      useTimeMeasurement(typeOf, valid, cb)
    },
  }

  _checkComponentTimes && start_fns.useTimeMeasurement('StartProvider', killDimmer)

  useEffect(() => {
    if (_showStates.any && _showStates.start) {
      if (_checkComponentTimes && componentTimes) {
        console.log('componentTimes', componentTimes)
      }
    }
    // eslint-disable-next-line
  }, [componentTimes]);

  useEffect(() => {
    if (killDimmer) {
      if (!timeLeft) {
        const diff = (Date.now() - startTime) / 1000
        if (_showStates.any && _showStates.start) {
          console.log('diff', diff)
        }
        start_handlers.handleKillDimmer()
      } else {
        const intervalId = setInterval(() => {
          start_handlers.handleTimeLeft();
        }, 1000);
        return () => clearInterval(intervalId);
      }
    }
    // eslint-disable-next-line
  }, [timeLeft, killDimmer]);

  return <StartContext.Provider value={{ start_state, start_handlers, start_fns }}>
    {useStart && <StartDimmer />}
    {props.children}
  </StartContext.Provider>
}

export default StartProvider 