import React, { createContext, useReducer } from 'react';
import { fetchHandlers, fetchInitialState, fetchReducer } from '../reducers/FetchReducer';

/** AppContext */
export const FetchContext = createContext();

/** Global OtherDatas for the Event */
const FetchProvider = (props) => {

  const fetch_initState = {}
  const [fetch_state, fetch_dispatch] = useReducer(fetchReducer, fetchInitialState(fetch_initState));
  const fetch_handlers = fetchHandlers(fetch_dispatch, fetch_state)

  const providerContext = () => <FetchContext.Provider
    value={{ fetch_state, fetch_handlers }}>
    {props.children}
  </FetchContext.Provider>

  return providerContext()
}

export default FetchProvider