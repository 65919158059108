import _ from 'lodash'

export const _sectionNames = {
  section1: 'section1',
  section2: 'section2',
  section3: 'section3',
  section4: 'section4',
  section5: 'section5',
  section6: 'section6',
  section7: 'section7',
  section8: 'section8',
}

export const getSectionColor = (section) => {
  const _section = _.camelCase(section)
  switch (_section) {
    case 'section1':
      return 'blue'
    case 'section2':
      return 'green'
    case 'section3':
      return 'red'
    case 'section4':
      return 'yellow'
    case 'section5':
      return 'orange'
    case 'section6':
      return 'teal'
    case 'section7':
      return 'purple'
    case 'section8':
      return 'brown'
    default:
      return 'grey'
  }
}