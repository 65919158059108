import _ from 'lodash';
import React, { useContext, useEffect, useReducer } from "react";
import { getAppUserAccess } from "../../../../global/auth/appUserAccessPermissions";
import { ParentContext } from "../../../../global/cnr/contexts/ParentContext";
import { sportsRtMatchHandlers, sportsRtMatchInitialState, sportsRtMatchReducer } from "../reducers/SportsRtMatchReducer";
import { SeasonMatchesContext } from "./SeasonMatchesContext";
import { SportsMatchContext } from "./SportsMatchContext";
import { SportsSeasonContext } from "./SportsSeasonContext";

export const SportsRtMatchContext = React.createContext()

export default function SportsRtMatchProvider(props) {

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ?? {}
  const { appUser_state, paps_state } = states ?? {}
  const { appUsers } = appUser_state ?? {}
  const { pathViews } = paps_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const appUserInfo = appUserAccess ? { displayName: appUserAccess.displayName, uid: appUserAccess.uid } : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { matches_info, latestSeason, _sportData, sportPermissions, sportData } = sportsSeason_state ?? {}
  const { matches_realtime } = matches_info ?? {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)

  const componentContexts = { sportsSeasonContext, seasonMatchesContext }

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state } = sportsMatchContext ?? {}
  const { match_true, gameScoringType } = sportsMatch_state ?? {}

  const init_state = { sportData, sportPermissions, _sportData, latestSeason, appUserInfo, pathViews, gameScoringType }
  const [sportsRtMatch_state, sportsMatchRealtime_dispatch] = useReducer(sportsRtMatchReducer, init_state, sportsRtMatchInitialState)
  const sportsRtMatch_handlers = sportsRtMatchHandlers(sportsMatchRealtime_dispatch, sportsRtMatch_state)

  useEffect(() => {
    if (match_true) {
      const rtMatch = matches_realtime && matches_realtime[match_true._itemKey]
      if (rtMatch) {
        sportsRtMatch_handlers.handleSet_rtMatch(match_true, rtMatch)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [matches_realtime, match_true]);

  return <SportsRtMatchContext.Provider value={{ sportsRtMatch_state, sportsRtMatch_handlers, componentContexts }}>
    {props.children}
  </SportsRtMatchContext.Provider>
}