import React, { useContext } from 'react'
import AddToHomeScreen, { installAppTypes } from '../../AddToHomeScreen'
import { AllowNotifications } from '../../AllowNotifications'
import NewVersion from '../../NewVersion'
import { ParentContext } from '../cnr/contexts/ParentContext'
import { cn_s, cn_snp, g_cns } from '../common/cns'
import { gEnums } from '../enums/globalEnums'
import { AppContentContext } from '../page/AppContent'
import { currentHelpers } from '../redirection/current'

/** Wraps app-container */
const AppWrapper = (props) => {

  const {
    banner,
    banners,
    content,
    footer,
    menu,
    navigation,
  } = props

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appSettings_state, page_state, serviceWorker_state } = states
  const { showHomeScreenPrompt } = appSettings_state ?? {}
  const { newVersionAvailable } = serviceWorker_state ?? {}

  const appContentContext = useContext(AppContentContext)
  const { appContent_state } = appContentContext ? appContentContext : {}
  const {
    appInstallation,
    appNotifications,
    asSegment,
    bannersLocation,
    css,
    desktopMobileOn,
    eventKey,
    forceInstall,
    homePageResponded,
    inverted,
    showHomeScreenHelp,
    useAppDarkMode,
    wrapper,
  } = appContent_state

  const { pageSettings } = page_state ?? {}
  const { aps_styles } = pageSettings ?? {}

  const { container: css_container, header: css_banner, menu: css_menu, content: css_content, navigation: css_navigation, footer: css_footer } = css ? css : {}

  const { allowNotifications, allowPushNotifications } = appNotifications ?? {}
  const { allowAppInstallation, allowAutoPromptForAppIntallation } = appInstallation ?? {}

  const addToHomeScreenItem = eventKey ? 'appInstallation-' + eventKey : 'appInstallation'
  const homeScreenAsked = currentHelpers.storageItem_get(addToHomeScreenItem)

  let cssT = css_container ? ' ' + css_container : ''
  let cssB = css_banner ? ' ' + css_banner : ''
  let cssM = css_menu ? ' ' + css_menu : ''
  let cssC = css_content ? ' ' + css_content : ''
  let cssN = css_navigation ? ' ' + css_navigation : ''
  let cssF = css_footer ? ' ' + css_footer : ''

  let prefix = ' ';

  if (banner) { prefix += 'b' }
  if (banners) { prefix += 'n' }
  if (menu) { prefix += 'm' }
  if (content) { prefix += 'c' }
  if (navigation) { prefix += 'n' }
  if (footer) { prefix += 'f' }

  if (bannersLocation === gEnums.bannerLocationTypes.top) {
    prefix += ' blt'
  }

  if (cssM) { cssT += ' ' + cssM }

  const mainCn = 'app'

  const backgroundSaC = aps_styles ? aps_styles[gEnums.projectStyles.background] : {}
  const { style } = backgroundSaC ?? {}

  let cn = g_cns.app_container

  if (desktopMobileOn) { cn += ' dtm' }

  if (asSegment) {
    cssT += ' ui segments'
    cssM += cn_s(inverted)
    cssB += cn_s(inverted)
    cssC += cn_snp(inverted)
    cssN += cn_s(inverted)
    cssF += cn_s(inverted)
  }

  if (useAppDarkMode) {
    cn += ' app-dark-mode'
  } else {
    cn += ' app-light-mode'
  }

  const athsAuths = {
    showAuto: allowAppInstallation && allowAutoPromptForAppIntallation && !homePageResponded && !homeScreenAsked,
    show: ((allowAppInstallation && showHomeScreenPrompt) || forceInstall)
  }

  const aths = () => <React.Fragment>
    {athsAuths.showAuto && <AddToHomeScreen installAppType={installAppTypes.auto} />}
    {athsAuths.show && <AddToHomeScreen installAppType={installAppTypes.manual} forceInstall={forceInstall} />}
    {showHomeScreenHelp && <AddToHomeScreen installAppType={installAppTypes.help} />}
  </React.Fragment>

  const main = <React.Fragment>
    {banner && <div className={mainCn + '-banner' + cssB}>{banner}</div>}
    {banners && <div className={mainCn + '-banners' + cssB}>{banners}</div>}
    {menu && <div className={mainCn + '-menu' + cssM}>{menu}</div>}
    {content && <div className={mainCn + '-content h100_of' + cssC}>{content}</div>}
    {navigation && <div className={mainCn + '-navigation' + cssN}>{navigation}</div>}
    {footer && <div className={mainCn + '-footer' + cssF}>{footer}</div>}
    {aths()}
    {newVersionAvailable && <NewVersion />}
    {allowNotifications && allowPushNotifications && <AllowNotifications />}
    {/* {allowNewVersionUpdate && <ServiceWorkerWrapper />} */}
  </React.Fragment>

  if (wrapper) {
    return <div className={mainCn + 'container' + prefix + cssT}  >
      <div className={wrapper}>
        {main}
      </div>
      {main}
    </div>
  } else {
    return <div className={cn + prefix + cssT}  >
      {main}
    </div>
  }
}

export default AppWrapper