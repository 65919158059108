import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import { currentHelpers } from '../redirection/current';

const PageNotFound = () => {

  let { _pathName } = currentHelpers.getHashPath()
  const navigate = useNavigate();

  console.log('PageNotFound')

  useEffect(() => {
    console.log('_pathName', _pathName)
    navigate(_pathName)
    window.location.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Segment basic>Page Not Found</Segment>
}

export default PageNotFound
