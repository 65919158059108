import React, { useEffect, useState } from 'react'
import { getAlphaList } from '../../common/convert'
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper'
import { AlphabetMenuWithProvider } from '../menus/AlphabetMenu'
import TableViewer from './TableViewer'
import _ from 'lodash'

const AlphaTableViewer = (props) => {

  const { appData, handleRowSelect, tableRef, viewItem, showItemKey } = props

  const [alphaValue, setAlphaValue] = useState(65)
  const [currentDataList, setCurrentDataList] = useState()
  const [sortProp, setSortProp] = useState()

  useEffect(() => {
    const firstItemKey = _.keys(appData)[0]; // Get the first key in the `items` object
    const firstItem = appData[firstItemKey];
    const isPerson = firstItem && _.has(firstItem, 'firstName') && _.has(firstItem, 'lastName')
    const _sortProp = isPerson ? 'lastName' : 'name'
    setSortProp(_sortProp)
    setCurrentDataList(appData)
    handleAlphaChange(65, _sortProp)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appData]);

  const handleAlphaChange = (av, sp) => {
    const _sortProp = sp ? sp : sortProp
    const _alphaChar = String.fromCharCode(av)
    if (appData && av) {
      const { alphaList, sortProp: sp } = getAlphaList(appData, _alphaChar, sortProp)
      setAlphaValue(av)
      setCurrentDataList(alphaList)
      setSortProp(sp)
    }
  }

  const tableViewer = () => <TableViewer
    tableRef={tableRef}
    jsonData={currentDataList}
    handleRowSelect={handleRowSelect}
    isAppData={true}
    viewItem={viewItem}
    showItemKey={showItemKey}
  />

  const alphaWrapper = () => <Wrapper
    header={<AlphabetMenuWithProvider
      origin={'alphaTableViewer'}
      handleAlphaChange={handleAlphaChange}
      alphaValue={alphaValue}
      restrictToExisting={true}
      sortProp={sortProp}
    />}
    content={tableViewer()}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper>

  return alphaWrapper()

}


export default AlphaTableViewer