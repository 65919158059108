import React from 'react'
import { gEnums } from '../../enums/globalEnums'
import FieldTripProvider from '../../../projectSpecific/fieldTrip/cnr/contexts/FieldTripContext'
import FieldTrip from '../../../projectSpecific/fieldTrip/elements/FieldTrip'

const CardExtra = (props) => {

  const { extraDisplayType, _itemKey } = props

  switch (extraDisplayType) {
    case gEnums.displayTypes.fieldTrip:
      return <FieldTripProvider {...props} _itemKey={_itemKey} >
        <FieldTrip />
      </FieldTripProvider>

    case gEnums.displayTypes.fieldTripStatus:
      return <FieldTripProvider {...props} _itemKey={_itemKey}>
        <FieldTrip statusOnly={true} />
      </FieldTripProvider>
    default:
      return <duv></duv>
  }

}

export default CardExtra