import React from 'react';
import AppUserTicketingWithProvider from '../../../projectSpecific/ticketing/components/AppUserTicketing';
import AppNotificationsManager, { appNotificationTypes } from '../../appNotifications/AppNotificationsManager';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { swipePropNew } from '../../cnr/reducers/reducerHelpers/swipeHelpers';
import { appFormTypes } from '../../enums/appFormTypes';
import { gEnums } from '../../enums/globalEnums';
import { Suspense } from 'react';
import SuspenseDimmer from '../alerts/SuspenseDimmer';
import AppMeetingsManagement from '../../appMeetings/AppMeetingsManagement';

const QrCode = React.lazy(() => import('../../pageItem/modification/pageItemActions/QrCode'));
const AppUserPages = React.lazy(() => import('./AppUserPages'));
const UserAuth = React.lazy(() => import('./UserAuth'));
const UserConnections = React.lazy(() => import('./UserConnections'));
const UserConversations = React.lazy(() => import('./UserConversations'));
const UserEvents = React.lazy(() => import('./UserEvents'));
const UserFavorites = React.lazy(() => import('./UserFavorites'));
const UserNotes = React.lazy(() => import('./UserNotes'));

/**
 * 
 * @param {object} aps_global 
 * @param {object} appUser 
 * @returns a group of components associated with the `appUser`
 */
export const userProfileItems = (aps_global, aps_appUserSettings, appUser, appUsers) => {

  /** Simple props builder
 * @returns key
 * @returns captions
 * @returns content
 * @description Items using UiSwiper need to be passed in with these props.
 */

  const { appTicketing, appNotifications, appNotes } = aps_global ?? {}
  const { appUserPages, qrCodeProps } = aps_appUserSettings ?? {}
  const { allowNotifications } = appNotifications ?? {}
  const { allowNotes } = appNotes ?? {}
  const { ticketingCaption } = appTicketing ?? {}
  const _ticketCaption = ticketingCaption ? ticketingCaption : 'Tickets'

  const appUserAccess = getAppUserAccess(appUsers)

  const addItem = (pageType, items) => {

    switch (pageType) {

      case gEnums.appUserPersonalPageTypes.auth:
        if (appUserAccess && appUserAccess.isAdminOrSuper) items[pageType] = swipePropNew(pageType, <UserAuth itemData={appUser} inApp={true} />, true)
        break;

      case gEnums.appUserPersonalPageTypes.connections:
        items[pageType] = swipePropNew(pageType, <UserConnections />, false, true)
        break;

      case gEnums.appUserPersonalPageTypes.conversations:
        items[pageType] = swipePropNew(pageType, <UserConversations />, false, true)
        break;

      case gEnums.appUserPersonalPageTypes.events:
        if (appUserAccess && !appUserAccess.isAdminOrSuper) items[pageType] = swipePropNew(pageType, <UserEvents />, false, true)
        break;

      case gEnums.appUserPersonalPageTypes.favorites:
        items[pageType] = swipePropNew(pageType, <UserFavorites />, false, true)
        break;

      case gEnums.appUserPersonalPageTypes.meetings:
        items[pageType] = swipePropNew(pageType, <AppMeetingsManagement />, false, true)
        break;

      case gEnums.appUserPersonalPageTypes.notes:
        if (allowNotes) {
          items[pageType] = swipePropNew(pageType, <UserNotes />, false, true)
        }
        break;

      case gEnums.appUserPersonalPageTypes.notifications:
        if (allowNotifications) {
          items[pageType] = swipePropNew(pageType, <AppNotificationsManager appNotificationType={appNotificationTypes.userNotifications} />, false, true)
        }
        break;

      case gEnums.appUserPersonalPageTypes.qrCode:
        items[pageType] = swipePropNew(pageType, <QrCode qrCodeType={gEnums.qrCodeTypes.appUser} qrCodeProps={qrCodeProps} />, false, true)
        break;

      case gEnums.appUserPersonalPageTypes.settings:
        items[pageType] = swipePropNew(pageType, <AppUserPages appFormType={appFormTypes.appUserSettings} useFullPageWrapper={false} />, false, true)
        break;

      case gEnums.appUserPersonalPageTypes.ticketing:
        items[_ticketCaption] = swipePropNew(_ticketCaption, <AppUserTicketingWithProvider />, false, true)
        break;

      default:
      // nothing
    }
    // }
  }

  const items = {}

  if (appUserPages) {
    appUserPages.forEach(aup => {
      addItem(aup, items)
    })
  }

  return <Suspense fallback={<SuspenseDimmer origin={'userProfileItems'} />}>
    {items}
  </Suspense>

}
