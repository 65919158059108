import _ from 'lodash'
import React from 'react'
import { Menu } from 'semantic-ui-react'
import { uniqueKey } from '../common/keys'

export const GenericMenuItemWithHeader = (props) => {
  const { gmiProps } = props ?? {}
  const { item, left, center, right, clickTo, allowClick } = gmiProps
  return <Menu.Item className='smi gmi' onClick={() => { clickTo(item, allowClick) }} >
    <Menu.Header>
      {left}
      <div>{center}</div>
      {right}
    </Menu.Header>
  </Menu.Item>
}

const GenericPopupMenuItem = (props) => {
  const { item, oc } = props
  return <Menu.Item key={uniqueKey('cnt', 'gpni', item)} onClick={(e) => { oc(item) }} >{_.startCase(item)}</Menu.Item>
}

export default GenericPopupMenuItem