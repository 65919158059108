import React from 'react'
import _ from 'lodash'

const MatchScores = (props) => {

  const { results } = props
  const { scores, scoreDisplays, winner } = results ?? {}
  const { pointDifference } = scoreDisplays ?? {}
  const { points, team } = pointDifference ?? {}
  const { name } = winner ?? {}

  const winnerHasMostPoints = name === team

  const scrd = scores ? _.map(scores, (score, setNumber) => (
    <div className='abs'><span className='abw'>{score.away}</span><span>-</span><span className='abl'>{score.home}</span></div>
  )) : '-'

  if (scores) {
    scrd.unshift(<div ><span className={winnerHasMostPoints ? 'whmp' : 'lhmp'}>{points}</span></div>)
  }

  return scrd

}

export default MatchScores