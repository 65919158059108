import { gEnums } from "../../../enums/globalEnums"
import { filterHelpers, getItemsArrayNotInArray } from "../../../common/filtering"

export const postFilterData = (props) => {

  const { viewItem, groupingOpts, viewListData, filterValues, searchValue, alphaValue, selectedFilters, dataFilters, ignoreAlpha, logging, f } = props

  const { display, filter, search, dataSource, dataConstraints } = viewItem ?? {}
  const { sortProp } = display ?? {}
  const { filterProp } = filter ?? {}
  const { searchProps } = search ?? {}

  const { alphabetActive, alphaFilterAfter, alphaFilterType, fetchByAlphabet, alphaProp, alphaDisplayType } = groupingOpts ?? {}

  const _dataConstraints = dataConstraints ? dataConstraints : dataSource

  const _showFullAlphaList = alphaFilterType === gEnums.alphaDisplayTypes.verticalFull

  let fds;

  const {
    useDataFilter,
    filterName,
    appUserConstraintDisallowProps, // not in use??
    useDataConstraints,
    dataConstraintDisallowProps, // not in use??
  } = _dataConstraints ?? {}

  const showRefLog = logging && logging.allowLogging && logging.logRefProps

  // eslint-disable-next-line 
  let allowAlpha = false
  let isAlpha = false

  switch (alphaDisplayType) {
    case gEnums.alphaDisplayTypes.tab:
    case gEnums.alphaDisplayTypes.vertical:
      allowAlpha = true
      break;
    default:
    // nothing
  }

  if (!ignoreAlpha && alphabetActive && !_showFullAlphaList && !fetchByAlphabet && allowAlpha) {
    switch (alphaFilterType) {
      case gEnums.alphaFilterTypes.always:
        isAlpha = true
        break;
      case gEnums.alphaFilterTypes.afterLimit:
        const dataCount = viewListData ? Object.keys(viewListData).length : 0
        const limit = alphaFilterAfter ? alphaFilterAfter : 100
        if (dataCount > limit) { isAlpha = true }
        break;
      default:
      // nothing
    }
  }

  if (viewListData && useDataFilter && filterName && dataFilters && dataFilters[filterName]) {
    const df = dataFilters[filterName]
    fds = filterHelpers.filterGroupFilters(viewListData, df)
  } else {
    if (!searchValue) {
      const _alphaProp = alphaProp ? alphaProp : sortProp
      fds = filterHelpers.filterFilteredData(viewListData, filterProp, filterValues, f)
      if (selectedFilters) { fds = filterHelpers.filterGroupFilters(fds, selectedFilters) }
      if (isAlpha) { fds = filterHelpers.filterAlphaData(fds, alphaValue, _alphaProp, f) }
    } else {
      fds = filterHelpers.filterSearchValue(viewListData, searchProps, searchValue, f)
    }

    if (useDataConstraints && dataConstraintDisallowProps && appUserConstraintDisallowProps) {
      appUserConstraintDisallowProps.forEach(aucdp => {
        const _dcdp = []
        dataConstraintDisallowProps.forEach(dcdp => {
          _dcdp.push(dcdp)
        })
        fds = getItemsArrayNotInArray(fds, aucdp, _dcdp)
        showRefLog && console.log('dataConstraints - POST (getItemsArrayNotInArray)', aucdp, _dcdp)
      })
    }
  }

  return fds

}