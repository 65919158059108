import { getFirstObject } from '../../../../global/common/convert';
import { realTimeScoring } from '../../dbActions/realtimeScoring';
import { matchCalcs } from '../../helpers/match';
import { realtimeDocumentTypes } from './SportsEditMatchReducer';
import { ammendTeamName } from './SportsMatchReducer';
import { seasonalUpdateTypes } from './reducerHelpers/seasonalSportsData';

const rts = {
  handleSet_latestSeason: 'handleSet_latestSeason',
  handleShow_rtMatch: 'handleShow_rtMatch',
  // handleShowScore: 'handleShowScore',
  handleSet_rtMatch: 'handleSet_rtMatch',

  handleRtUpdate_score: 'handleRtUpdate_score',
  handleRtChange_score: 'handleRtChange_score',
  handleRtChange_set: 'handleRtChange_set',
  handleRtChange_setScores: 'handleRtChange_setScores',
  handleRtConfirm_match: 'handleRtConfirm_match',
  handleRtConfirm_set: 'handleRtConfirm_set',
  handleRtDelete_scores: 'handleRtDelete_scores',

  handleCallback_delete: 'handleCallback_delete'
}

export const sportsRtMatchReducer = (state, action) => {

  const {
    appUserInfo,
    latestSeason,
    match_rt,
    match_true,
    pathViews,
    _sportData,
    sportPermissions,
    sportData,
    gameScoringType,
  } = state

  const { type, dispatch } = action
  const { useRtMatchData } = _sportData ?? {}

  const _match_rt = { ...match_rt }

  const { handleRtChange_score, handleRtChange_set, handleCallback_delete } = sportsRtMatchHandlers(dispatch)

  switch (type) {

    case rts.handleSet_rtMatch:
      const { match_true: mt } = action
      const { home, away } = mt
      if (action.match_rt) {
        const { item } = getFirstObject(action.match_rt)
        const { results } = item ?? {}
        const rt_match = { home, away, results }
        matchCalcs.calcVballMatch(rt_match, gameScoringType)
        return { ...state, match_true: mt, match_key: mt._itemKey, match_rt: rt_match }
      } else {
        const rt_match = { home, away, results: { setNumber: 1, score: {}, scores: { 1: { home: 0, away: 0 } } } }
        matchCalcs.calcVballMatch(rt_match, gameScoringType)
        return { ...state, match_true: mt, match_key: mt._itemKey, match_rt: rt_match }
      }

    case rts.handleShow_rtMatch:
      if (action.match_rt) {
        return { ...state, showRealtimeScoringSelected: !state.showRealtimeScoringSelected, match_rt_selected: action.match_rt }
      } else {
        return { ...state, showRealtimeScoring: !state.showRealtimeScoring }
      }

    case rts.handleRtUpdate_score:
      handleRealTimeChange(action, handleRtChange_score, handleRtChange_set)
      return { ...state }

    case rts.handleRtChange_score:
      if (_match_rt) {
        const { results, _results } = _match_rt
        const { setNumber } = _results ?? {}
        if (setNumber) {
          results.scores[setNumber][action.team] = action.value
          matchCalcs.calcVballMatch(_match_rt, gameScoringType)
          realTimeScoring.calcMatchAndUpdateRtDb(sportPermissions, sportData, { results }, match_true, pathViews, appUserInfo, latestSeason)
        }
      }
      return { ...state, match_rt: _match_rt }

    case rts.handleRtChange_set:
      // changes the set and creates a blank new set
      if (_match_rt) {
        _match_rt.setNumber = action.setNumber
        if (!_match_rt.scores[_match_rt.setNumber]) {
          _match_rt.scores[_match_rt.setNumber] = createNewSet()
        }
      }
      return { ...state, match_rt: _match_rt }

    case rts.handleRtChange_setScores:
      const _rtm = action.match_rt
      const { results } = _rtm
      realTimeScoring.calcMatchAndUpdateRtDb(sportPermissions, sportData, { results }, match_true, pathViews, appUserInfo, latestSeason)
      return { ...state, match_rt: _rtm }

    case rts.handleRtConfirm_set:
      if (_match_rt) {
        matchCalcs.calcVballMatch(_match_rt, gameScoringType, null, null, true)
        const { _results } = _match_rt
        _results.setNumber++
        if (!_results.scores[_results.setNumber]) {
          _results.scores[_results.setNumber] = createNewSet()
        }
        realTimeScoring.calcMatchAndUpdateRtDb(sportPermissions, sportData, { results: _results }, match_true, pathViews, appUserInfo, latestSeason)
      }
      return { ...state, match_rt: _match_rt }

    case rts.handleRtConfirm_match:
      if (_match_rt) {
        console.log('_match_rt', _match_rt)
      }
      return { ...state }

    case rts.handleRtDelete_scores:
      realTimeScoring.deleteRealtimeDatabaseScores(realtimeDocumentTypes.realtime, sportPermissions, sportData, seasonalUpdateTypes.rtMatches, pathViews, [state.match_key], appUserInfo, latestSeason, handleCallback_delete)
      return { ...state, match_rt: null }

    case rts.handleCallback_delete:
      return { ...state, match_true: null, match_rt: null }

    case rts.handleSet_latestSeason:
      return { ...state, latestSeason: action.latestSeason }

    // case rts.handleShowScore:
    //   action.e && action.e.preventDefault()
    //   action.e && action.e.stopPropagation()
    //   return { ...state, showScore: !state.showScore }


    default:
      return { ...state }
  }
}

export const sportsRtMatchHandlers = (dispatch) => {
  return {
    handleCallback_delete: (e) => { dispatch({ type: rts.handleCallback_delete, e }) },
    handleRtChange_score: (value, team) => { dispatch({ type: rts.handleRtChange_score, dispatch, value, team }) },
    handleRtChange_set: (setNumber) => { dispatch({ type: rts.handleRtChange_set, dispatch, setNumber }) },
    handleRtChange_setScores: (match_rt) => { dispatch({ type: rts.handleRtChange_setScores, match_rt }) },
    handleRtConfirm_match: (value) => { dispatch({ type: rts.handleRtConfirm_match, dispatch, value }) },
    handleRtConfirm_set: (value) => { dispatch({ type: rts.handleRtConfirm_set, dispatch, value }) },
    handleRtDelete_scores: () => { dispatch({ type: rts.handleRtDelete_scores, dispatch }) },
    handleRtUpdate_score: (match_rt, rtType, add, currentSetNumber) => { dispatch({ dispatch, type: rts.handleRtUpdate_score, match_rt, rtType, add, currentSetNumber }) },
    handleSet_latestSeason: (latestSeason) => { dispatch({ type: rts.handleSet_latestSeason, latestSeason }) },
    handleSet_rtMatch: (match_true, match_rt) => { dispatch({ type: rts.handleSet_rtMatch, match_true, match_rt }) },
    handleShow_rtMatch: (match_rt) => { dispatch({ type: rts.handleShow_rtMatch, match_rt }) },
    // handleShowScore: (e) => { dispatch({ type: rts.handleShowScore, e }) },
  }
}

export const sportsRtMatchInitialState = (init_state) => {
  return { ...init_state }
};


export const matchNeedsUpdated = (matchResults, results) => {
  if (matchResults && matchResults.score && results.score)
    if (matchResults.score.home !== results.score.home || matchResults.score.away !== results.score.away) {
      return true
    }
}

export const fixTeamNames = (matches) => {
  Object.keys(matches).forEach(mk => {
    const match = matches[mk]
    const { home, away } = match ?? {}
    match.home = ammendTeamName(home)
    match.away = ammendTeamName(away)
  })
}

const createNewSet = () => {
  return {
    home: 0,
    away: 0
  }
}

const createTeam = (team) => {
  return {
    id: team._itemKey,
    levels: team.levels,
    sections: team.sections,
    name: team.name
  }
}

/**
 * 
 * @param {object} action 
 * @param {function} handleRtChange_score 
 * @param {function} handleRtChange_set 
 * @description ammends the realtime score, then triggers either handleRtChange_score or handleRtChange_set
 */
const handleRealTimeChange = (action, handleRtChange_score, handleRtChange_set) => {

  const { match_rt, rtType, add, currentSetNumber } = action
  let { results, _results } = match_rt ?? {}

  if (!results) { results = {} }
  if (!_results) { _results = {} }

  const { setNumber } = _results ?? _results
  let _setNumber = setNumber ? setNumber : 1
  if (currentSetNumber) { _setNumber = currentSetNumber }


  switch (rtType) {
    case 'away':
    case 'home':
      const sn = setNumber
      if (!results.scores) { results.scores = {} }
      if (!results.scores[sn]) {
        results.scores[sn] = {
          away: 0,
          home: 0
        }
      }
      let teamScore = results.scores[sn][rtType]
      if (add) {
        teamScore++
      } else {
        teamScore--
      }
      if (teamScore < 0) { teamScore = 0 }
      handleRtChange_score(teamScore, rtType)
      break;

    case 'set':
      if (add) {
        _setNumber++
      } else {
        _setNumber--
      }
      if (_setNumber < 1) { teamScore = 1 }
      if (_setNumber > 5) { teamScore = 5 }
      handleRtChange_set(_setNumber)
      break;

    default:
      break;
  }
}
