import _ from 'lodash';
import React from 'react';
import { uniqueKey } from '../../common/keys';
import { reduceObject } from '../../common/sorting';
import { getFormPropz } from '../../components/forms/props/formPropz';
import { gEnums } from '../../enums/globalEnums';
import { getTempSettingsState } from '../../firestoreData/settings/ammendSettings';
import ViewSettingsComponentWithProvider from '../../viewSettings/ViewSettingsComponent';

const rts = {
  addHelp: 'addHelp',
  onTransitionEnd: 'onTransitionEnd',
  removeItem: 'removeItem',
  setDataRoot: 'setDataRoot',
  setSelectedIndex: 'setSelectedIndex',
  swipeToItem: 'swipeToItem',
  updateSettingsTemp: 'updateSettingsTemp',
}

export const getSettingsUpdateProps = ({ swipedItem, swipedItems, vit }) => {

  const { key, parentSettingKey, parentKey, subKey, dataListType, attachToParent } = swipedItem ?? {}

  const updateProps = {
    attachToParent: attachToParent,
    dataListType: dataListType,
    diKey: null,
    dKey: null,
    formKey: key,
    parentKey,
    parentSettingKey,
    pit: null,
    psg: null,
    subKey,
    vit: null,
    consoleItemType: gEnums.consoleItemTypes.vw
  }

  // see if any of the parents have specific values
  const vitSwipeItem = _.find(swipedItems, 'vit')
  const pitSwipeItem = _.find(swipedItems, 'pit')
  const psgSwipeItem = _.find(swipedItems, 'psg')
  const ditSwipeItem = _.find(swipedItems, 'dKey')
  const ditiSwipeItem = _.find(swipedItems, 'diKey')

  if (vit) {
    updateProps.vit = vit
  } else {
    if (swipedItem.vit) {
      updateProps.vit = swipedItem.vit
    } else if (vitSwipeItem) {
      updateProps.vit = vitSwipeItem.vit
    }
  }

  if (swipedItem.pit) {
    updateProps.pit = swipedItem.pit
  } else if (pitSwipeItem) {
    updateProps.pit = pitSwipeItem.pit
  }

  if (swipedItem.psg) {
    updateProps.psg = swipedItem.psg
  } else if (psgSwipeItem) {
    updateProps.psg = psgSwipeItem.psg
  }

  if (swipedItem.dKey) {
    updateProps.dKey = swipedItem.dKey
  } else if (ditSwipeItem) {
    updateProps.dKey = ditSwipeItem.dKey
  }

  if (swipedItem.diKey) {
    updateProps.diKey = swipedItem.diKey
  } else if (ditiSwipeItem) {
    updateProps.diKey = ditiSwipeItem.diKey
  }

  if (swipedItem.pit) {
    updateProps.consoleItemType = gEnums.consoleItemTypes.pr
  } else if (swipedItem.vit) {
    updateProps.consoleItemType = gEnums.consoleItemTypes.vi
  }

  return updateProps

}

export const viewSettingsComponentReducer = (state, action) => {

  const { isGlobal, appArea, settings_temp, settings_temp_global, selectedSettingGroup, swipedElements, swipedItems, swipeToSelected, addHelp, logging } = state
  const { type, swipeProps } = action
  const { swipedItem, count, editProps } = swipeProps ?? {}
  const { key, subKey } = swipedItem ?? {}

  let keyy = 'vscp-' + key

  switch (type) {

    case rts.swipeToItem:

      if (swipedItem) {

        const updateProps = getSettingsUpdateProps({ swipedItem, swipedItems })

        const allSwipeItems = [...swipedItems]
        allSwipeItems.push(swipedItem)

        // get the current settings data from the settings_temp
        const data_current = getCurrentData(settings_temp, allSwipeItems, settings_temp_global, isGlobal)

        if (logging && logging.allowLogging && logging.logSwipeItems) { console.log('logging >', 'Swipe Info', allSwipeItems, data_current) }

        if (subKey) { keyy += '-' + subKey }
        if (editProps && editProps.dataUpdateType) { keyy += '-' + editProps.dataUpdateType }
        if (subKey) { keyy += '-' + subKey }
        if (updateProps.diKey) { keyy += '-' + updateProps.diKey }

        const dataRoot = swipedItem.isDataRoot ? swipedItem.viewListData : null

        const ncProps = {
          appArea,
          addHelp,
          data_current,
          dataRoot,
          editProps,
          isGlobal,
          keyy,
          selectedSettingGroup,
          swipedItem,
          swipedItems: allSwipeItems,
          swipeToSelected,
          updateProps,
        }

        // get the new component 
        const newElement = <ViewSettingsComponentWithProvider key={uniqueKey('vscr', keyy)} ncProps={ncProps} />

        // combine existing and new Elements
        const newElements = [...swipedElements, newElement]

        // combine existing and new Items
        const newSwipeItems = [...swipedItems, swipedItem]

        return {
          ...state,
          swipedElements: newElements,
          selectedIndex: newElements.length - 1,
          swipedItems: newSwipeItems,
        }

      } else {
        // removing the swipeItem(s)
        if (swipedElements.length > 1) {
          const newItemsX = [...swipedElements]
          const newSwipeItemsX = [...swipedItems]

          const startNumber = (count || count === 0) ? count + 1 : newItemsX.length - 1
          const startNumberNew = (count || count === 0) ? count + 1 : newSwipeItemsX.length - 1
          const deleteCount = (count || count === 0) ? newItemsX.length - (count + 1) : 1

          newItemsX.splice(startNumber, deleteCount)
          newSwipeItemsX.splice(startNumberNew, deleteCount)

          return {
            ...state,
            swipedElements: newItemsX,
            selectedIndex: newItemsX.length - 1,
            swipedItems: newSwipeItemsX,
          }
        } else {
          return {
            ...state
          }
        }
      }

    case rts.setSelectedIndex:

      if (swipedElements.length > 1) {
        const newItemsXX = [...swipedElements]
        const newSwipeItemsXX = [...swipedItems]

        newItemsXX.splice(newItemsXX.length - 1, 1)
        newSwipeItemsXX.splice(newSwipeItemsXX.length - 1, 1)

        return {
          ...state,
          swipedElements: newItemsXX,
          selectedIndex: newItemsXX.length - 1,
          swipedItems: newSwipeItemsXX,
        }
      } else {
        return {
          ...state,
        }
      }

    case rts.setDataRoot:
      return {
        ...state,
        dataRoot: action.dataRoot
      }

    case rts.addHelp:
      return {
        helpKey: action.viType,
        ...state
      }

    case rts.onTransitionEnd:
      // const x = [...swipedElements]
      // x.splice(x.length - 1, 1)
      return {
        ...state,
        // swipedElements: x, 
      }

    case rts.updateSettingsTemp:
      return { ...state, settings_temp: action.settings_temp }

    default:
      return { ...state }
  }
}

export const viewSettingsComponentHandlers = (dispatch) => {
  return {
    addHelp: (viType) => {
      const swipeProps = { swipedItem: { helpKey: viType, key: viType, caption: 'Help' } }
      dispatch({ type: rts.swipeToItem, swipeProps })
    },
    onTransitionEnd: (selectedIndex) => { dispatch({ type: rts.onTransitionEnd, selectedIndex: selectedIndex }) },
    setDataRoot: (dataRoot) => { dispatch({ type: rts.setDataRoot, dataRoot }) },
    setSelectedIndex: (selectedIndex) => { dispatch({ type: rts.setSelectedIndex, selectedIndex: selectedIndex }) },
    swipeToItem: (swipeProps) => { dispatch({ type: rts.swipeToItem, swipeProps }) },
    updateSettingsTemp: (settings_temp) => { dispatch({ type: rts.updateSettingsTemp, settings_temp }) },
    swipeToSelected: (swipeProps) => { dispatch({ type: rts.swipeToItem, swipeProps }) },
  }
}

export const viewSettingsComponentInitialState = (props) => {

  const { componentContexts, logging, addHelp, swipeToSelected, settings_globalPageItems, startSwipeItems } = props

  // componentContexts
  const { settingsAreaContext, viewSettingsContext } = componentContexts
  const { settingsArea_state } = settingsAreaContext ?? {}
  const { selectedSettingGroup, isGlobal } = settingsArea_state ?? {}
  const { viewSettings_state } = viewSettingsContext
  let { settings_temp, settings_temp_global, selectedGroupItem } = viewSettings_state

  // IMPORTANT: Settings - set the swipeItem
  const swipedItem = selectedGroupItem ? { key: selectedGroupItem.key, settingKey: selectedGroupItem.key, viType: selectedGroupItem.key, caption: selectedGroupItem.caption } : null
  const swipedItems = swipedItem ? [swipedItem] : []

  let data_current = getCurrentData(settings_temp, swipedItems, settings_temp_global, isGlobal)

  const ncProps = {
    addHelp,
    data_current,
    isGlobal,
    swipedItem,
    swipedItems,
    swipeToSelected,
  }

  const newElement = <ViewSettingsComponentWithProvider key={uniqueKey('vscr')} ncProps={ncProps} />

  const state = {
    addHelp,
    data_current,
    isGlobal,
    logging,
    selectedIndex: startSwipeItems ? 1 : 0,
    selectedSettingGroup,
    settings_globalPageItems,
    swipedItem: startSwipeItems ? startSwipeItems[0] : swipedItem,
    swipedItems: startSwipeItems ? startSwipeItems : swipedItems,
    swipeToSelected,
    settings_temp,
    settings_temp_global,
    swipedElements: [newElement]
  }

  return state

};

// current data
const trueKey = (key) => {
  switch (key) {
    case 'settings_globalPageItems':
    case 'views':
      return 'viewItems'
    default:
      return key
  }
}

/** Returns the curren data of the last swiped item */
const getCurrentData = (settings_temp, swipedItems, settings_globalPageItems, isGlobal) => {

  let cd;

  const aps = []

  // settings_page
  const { _global, _viewItems } = getTempSettingsState(settings_temp, isGlobal)
  const isGv = swipedItems && swipedItems[0] && swipedItems[0].key === 'settings_globalPageItems' ? true : false
  let gvv = null;

  // settings_globalPageItems need wrapped because it does not have a parent object.
  if (isGv) { gvv = settings_globalPageItems }

  if (swipedItems && swipedItems.length > 0) {
    Object.keys(swipedItems).forEach(key => {
      const swipeItem = swipedItems[trueKey(key)]
      const { key: swipeKey, subKey } = swipeItem
      const dk = subKey ? subKey : swipeKey
      aps.push(dk)
    })
  }

  // remove prop if under propSections
  if (aps.includes('props') && aps.includes('propSections')) {
    const propsIndex = aps.indexOf('props');
    aps.splice(propsIndex, 1);
  }

  if (aps.length > 0) {
    aps.forEach(dk => {
      const _tk = trueKey(dk)
      if (cd) {
        // existnging data
        cd = cd[_tk]
      } else {
        // initial data
        if (isGv && gvv) {
          cd = gvv[_tk]
        } else {
          cd = _global
          cd = cd ? cd[_tk] : null
          // LOOOK
          if (_.isArray(cd)) { cd = {} }
          if (!cd && _viewItems) {
            cd = _viewItems[_tk]
          }
        }
      }
    })
  }

  return cd ? cd : {}

}

/**
 *  
 * @param {object} data_current 
 * @param {handlers} viewSettings_handlers 
 * @param {object} swipedItem 
 * @param {object} updateProps 
 * @param {object} viewItems 
 * @returns the swipeProp settings (viewProps, formProps) for the swipedItem
 */
export const getSwipeProps = (data_current, viewSettings_handlers, swipedItem, updateProps, viewItems, settingsArea_fns, isGlobal, appArea) => {

  const swipeProps = {
    key: swipedItem ? swipedItem.key : null,
    viewProps: null,
    isMulti: false,
    swipedItem,
  }

  if (swipedItem) { getSelectedSettingsGroupItem(swipeProps, updateProps, viewItems, data_current, settingsArea_fns, isGlobal, appArea) }

  if (swipedItem) {
    const udp = updateProps ? updateProps : { formKey: swipedItem.key, parentSettingKey: swipedItem.parentSettingKey, subKey: swipedItem.subKey }

    const fpp = {
      form_handlers: viewSettings_handlers,
      updateProps: udp,
      viewProps: swipeProps.viewProps,
      viewPropsAllowed: swipeProps.viewPropsAllowed,
      data_current,
      formActions: swipeProps.formActions,
      formHandler: swipeProps.formHandler,
    }
    swipeProps.formProps = getFormPropz(fpp)
  }

  return swipeProps

}


// IMPORTANT: Settings - Get the groupItems for the settings
/** @returns the settings groupItems (viewProps and buttons) for the swipedItem */

/**
 * IMPORTANT: Settings - View Props get the viewProps from the selected item
 * Ammends the swipeProps
 * @param {object} swipeProps (swipedItem) swipedItem:(dataListKey, dKey, subKey, vit, pit, psg, key)
 * @param {object} updateProps 
 * @param {object} viewItems 
 * @param {object} data_current 
 * @returns the settings groupItems (viewProps and buttons) for the swipedItem
 */
const getSelectedSettingsGroupItem = (swipeProps, updateProps, viewItems, data_current, settingsArea_fns, isGlobal, appArea) => {

  const { getSelectedGroupItem, getSettingsBaseItem, ammendDataGroupItem } = settingsArea_fns ?? {}

  const { swipedItem } = swipeProps ?? {}
  const { dKey, subKey } = swipedItem ?? {}

  let selectedGroupItem;

  if (dKey && viewItems) {
    swipeProps.viewProps = viewItems
  } else {
    // determine what viewItems by the 'key' of the swipeProps.
    switch (swipeProps.key) {
      case gEnums.viewSettingTypes.globalPageItems:
      case gEnums.viewSettingTypes.propSections:
      case gEnums.viewSettingTypes.viewItems:
      case gEnums.viewSettingTypes.views:
        swipeProps.viewProps = viewItems
        break;

      default:
        if (swipedItem.vit || swipedItem.pit || swipedItem.psg) {
          selectedGroupItem = getSelectedGroupItem(swipedItem, isGlobal)
          swipeProps.isMulti = true
        } else {
          selectedGroupItem = ammendDataGroupItem(swipedItem, updateProps, swipeProps)
        }

        if (selectedGroupItem) {

          const {
            childItems, // the list of child item. Each child item will be renderd to the childMenuItem OR card, description, list, mete
            childItemsFromData,
            childMenuItem, // the menuItem from which the childItems will be rendered
            childMenuItems, // {card, description, list, mete}
            extraMenuItems,
            formActions,
            groupItems,
            isStatic,
            restrictedAccess,
            formHandler,
          } = selectedGroupItem

          if (isStatic) { swipeProps.isStatic = true }

          if (childItemsFromData && !subKey) {
            // get the list from data instead of a static list.
            const dks = Object.keys(data_current)
            swipeProps.viewProps = dks
            swipeProps.isMulti = true
          } else if (childItems && !subKey) {
            swipeProps.viewProps = childItems
            swipeProps.isMulti = true
          } else {
            // SET THE VIEW PROPS

            if (subKey) {
              swipeProps.viewProps = getSettingsBaseItem(subKey)
            } else if (!groupItems && childItems && childItems.length > 0 && subKey) {
              swipeProps.viewProps = getSettingsBaseItem(subKey)
            } else {
              swipeProps.viewProps = groupItems // ammendComponents(groupItems, isGlobal, appArea)
            }

            if (swipeProps.viewProps) {
              try {
                swipeProps.viewProps = reduceObject(swipeProps.viewProps, 'hideFromMenu')
              } catch (error) {
                console.error('swipeProps.viewProps: error', error)
              }
            }

            // only the viewProps that are not marked with hideFromMenu  
            if (childMenuItem || childMenuItems) {

              const cmi = childMenuItem ? childMenuItem : childMenuItems[swipedItem.subKey]

              const _childMenuItems = getSettingsBaseItem(cmi)
              const allowedItems = getSettingsBaseItem(cmi, 'allowedItems')

              if (_childMenuItems && allowedItems && allowedItems[subKey]) {
                const cmis = {}
                const allowed = allowedItems[subKey]
                allowed.forEach(ai => {
                  if (_childMenuItems[ai]) { cmis[ai] = _childMenuItems[ai] }
                })
                let capped = false
                if (cmis && Object.keys(cmis).length > 0) {
                  try {
                    Object.keys(cmis).forEach(k => {
                      if (!cmis[k] && !cmis[k].data && !cmis[k].data.breakBefore && !capped) {
                        cmis[k].data.breakBefore = 'Additional'
                        capped = true
                      }
                    })
                  } catch (error) {
                    console.log('error', error)
                  }

                }
                swipeProps.viewProps = cmis
              } else {
                swipeProps.viewProps = _childMenuItems
              }
            }

            if (extraMenuItems) {
              const emi = extraMenuItems[swipedItem.subKey]
              const extraItems = getSettingsBaseItem(emi)
              if (extraItems) {
                const existingCount = swipeProps.viewProps ? Object.keys(swipeProps.viewProps).length : 0
                Object.keys(extraItems).forEach(eiKey => {
                  extraItems[eiKey]._position = extraItems[eiKey]._position + (existingCount - 1)
                })
                const fei = _.find(extraItems, { _position: existingCount - 1 })
                if (fei && fei.key) { extraItems[fei.key].data.breakBefore = 'Additional' }
                const merged = { ...swipeProps.viewProps, ...extraItems }
                swipeProps.viewProps = merged
              }
            }

            if (restrictedAccess && swipedItem.subKey && restrictedAccess[swipedItem.subKey]) {
              swipeProps.restrictedAccess = restrictedAccess[swipedItem.subKey]
            }
          }

          if (!swipeProps.viewProps) { swipeProps.isStatic = true }

          if (formHandler) { swipeProps.formHandler = formHandler }
          if (formActions) { swipeProps.formActions = formActions }

        }
    }
  }
} 