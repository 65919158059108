import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions';
import { AppComponentContext } from '../../../../global/cnr/contexts/AppComponentContext';
import { FirestoreContext } from '../../../../global/cnr/contexts/FirestoreContext';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { fieldTripHandlers, fieldTripInitialState, fieldTripReducer } from '../reducers/FieldTripReducer';
import _ from 'lodash'

export const FieldTripContext = createContext();

/**
 * @provides tournamentTeams 
 * @provides golfCourse  
 * @provides holes 
 * @provides golfMatchData  
 * @provides roundsStatus 
 * @provides fieldTrip_handlers 
 * @provides golfTeam1
 * @provides golfTeam2
 * @provides golfTeam1Style
 * @provides golfTeam2Style
 */

const FieldTripProvider = (props) => {

  const { _itemKey } = props ?? {}

  const parentContext = useContext(ParentContext)
  const { states, fns } = parentContext ?? {}
  const { appUser_state, eventInfo_state, paps_state, page_state } = states
  const { page_fns } = fns
  const { appDataDocuments } = eventInfo_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { appDataSource } = aps_global ?? {}

  // papsContext
  const { appUser, appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { isSuperAdminDeveloper } = appUserAccess ?? {}

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext);
  const { firestore_handlers, firestore_state } = firestoreContext ?? {}

  // fieldTripsContext
  const fieldTripsContext = useContext(AppComponentContext)
  const { fieldTrips_state } = fieldTripsContext ?? {}
  const { fieldTrips, staffFieldTrips } = fieldTrips_state ?? {}

  // papsContext
  const { viewKey, pathViews, trueView, sharePathFull } = paps_state ?? {}
  const _trueViewKey = pathViews[trueView]

  const _fieldTripKey = _itemKey ? _itemKey : viewKey

  // pageContext  
  const { pushSimple } = page_fns ?? {}

  // fieldTripReducer
  const init_state = { pathViews, firestore_handlers, sharePathFull, pushSimple, ...props, fieldTrips_state, sharePathFull, appDataSource }
  const [fieldTrip_state, fieldTrip_dispatch] = useReducer(fieldTripReducer, init_state, fieldTripInitialState)
  const fieldTrip_handlers = fieldTripHandlers(fieldTrip_dispatch, fieldTrip_state)

  useEffect(() => {
    if (fieldTrips && _fieldTripKey && fieldTrips[_fieldTripKey]) {
      fieldTrip_handlers.handleInit_fieldTrip(fieldTrips[_fieldTripKey])
    }
    if (staffFieldTrips && _trueViewKey && staffFieldTrips[_trueViewKey]) {
      console.log('staffFieldTrips', _trueViewKey, staffFieldTrips[_trueViewKey])
    }
    // else {
    //   const fo = getFirstObject(viewListData)
    //   const { item } = fo ?? {}
    //   fieldTrip_handlers.handleInit_fieldTrip(item)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_fieldTripKey, fieldTrips, staffFieldTrips]);

  return <FieldTripContext.Provider value={{ fieldTrip_state, fieldTrip_handlers }}>
    {props.children}
  </FieldTripContext.Provider>
};

export default FieldTripProvider 