import _ from 'lodash';

/** Splits the itemProps into wizardGroupd using the `breakBefore` attribute */
export const createWizardGroups = (itemProps) => {

  const wizardGroups = {}

  if (itemProps) {
    let bb;
    Object.keys(itemProps).forEach(key => {
      const itemProp = itemProps[key]
      const { data } = itemProp ?? {}
      const { breakBefore } = data ?? {}
      if (breakBefore) {
        bb = _.camelCase(breakBefore)
        if (!wizardGroups[bb]) { wizardGroups[bb] = {} }
      }
      if (bb) {
        wizardGroups[bb][key] = itemProp
      }
    })
  }

  return wizardGroups

}