import React, { useContext, useEffect, useRef, useState } from 'react';
import { Image } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';

const Banner = () => {

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { page_state, transition_state, storage_state } = states
  const { storage_fns } = fns

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_styles } = pageSettings ?? {}
  const { themedItems, banner: app_banner } = aps_global ?? {}
  const { appBanner: appBannerTheme } = themedItems ?? {}
  const { apply, padding } = appBannerTheme ?? {}

  const { globalFiles } = storage_state ?? {}
  const { image: images_global } = globalFiles ?? {}

  // transitionContext  
  const { transitions } = transition_state ?? {}
  const transition = transitions ? transitions[_animateTypes.topBanner] : null
  const { showTransition } = transition ?? {}

  const { bannerType, banner, bannerDirectUrl, bannerSize, bannerHeight } = app_banner ?? {}

  const [topBanner, setTopBanner] = useState()

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.appBanner] : {}
  let _styleAndClass = styleAndClass

  useEffect(() => {
    switch (bannerType) {
      case gEnums.bannerTypes.fromFile:
        if (banner && images_global) {
          storage_fns.getImageUrl(banner, true, gEnums.storageTypes.image, setTopBanner)
        }
        break;
      case gEnums.bannerTypes.directUrl:
        if (bannerDirectUrl) { setTopBanner({ url: bannerDirectUrl }) }
        break;
      default:
        setTopBanner()
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [images_global, bannerDirectUrl, bannerType]);

  const bannerRef = useRef(null);

  const bannerImage = () => {
    const _bannerSize = bannerSize && bannerSize === 'normal' ? null : bannerSize
    if (topBanner.url) {
      return <Image key={uniqueKey('sbr')} size={_bannerSize ? _bannerSize : 'large'} className={'banner-image'} centered src={topBanner.url} alt={''} />
    } else {
      return <Image key={uniqueKey('sbr')} size={_bannerSize ? _bannerSize : 'large'} className={'banner-image gen'} centered src={'/genericImage.png'} style={{ display: 'block' }} alt={'image'} />
    }
  }

  const content = () => {
    if (showTransition) {
      return <MotionComponent transition={transition}>
        {bannerImage()}
      </MotionComponent>
    } else {
      return bannerImage()
    }
  }

  if (topBanner) {
    return <div {..._styleAndClass} ref={bannerRef}>{content()}</div>
  } else {
    return <div></div>
  }
}

export default Banner