import React, { createContext, useEffect, useState } from 'react';

/**
 * @state components_state (componentFilters)
 */
export const ComponentsContext = createContext();

/**
 * 
 * @param {object} props  
 */
const ComponentsProvider = (props) => {

  const [componentFilters, setComponentFilters] = useState({})

  const pf_golf = {
    state: ['golfEvent_state', 'golfTournament_state'],
    props: ['pageSettings', 'desktopMode', 'pathViews', 'desktopMode', 'appUser'],
    functions: ['page_fns', 'appUser_fns'],
    handlers: ['item_handers', 'header_handlers', 'pageDimmer_handlers', 'golfEvent_handlers', 'golfTournament_handlers']
  };

  const pf_swiper = {
    state: ['pageArea_state', 'page_state', 'uiFilter_state', 'paps_state', 'framework_state', 'settingsPreview_state'],
    props: ['pageSettings', 'desktopMobileOn', 'pageItemsShown', 'profileItems', 'groupDataProps', 'viewItem', 'view', 'viewKey', 'desktopMode', 'settingsPreviewHasChanged'],
    functions: [],
    handlers: []
  };

  useEffect(() => {
    const _cfs = {
      state: [...pf_golf.state, ...pf_swiper.state],
      functions: [...pf_golf.functions, ...pf_swiper.functions],
      handlers: [...pf_golf.handlers, ...pf_swiper.handlers],
    }
    setComponentFilters(_cfs)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  return <ComponentsContext.Provider value={{ components_state: { componentFilters } }}>
    {props.children}
  </ComponentsContext.Provider>
};

export default ComponentsProvider