import _ from 'lodash';
import React, { Suspense, createContext, useContext, useEffect, useReducer, useState } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { getAppName } from '../../../project/appConfiguration';
import { appVersionDiv } from '../../appNotifications/appVersion';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { signInComponentTypes, signInHandlers, signInMethodTypes, signInReducer, signInReducerInitialState, signInTypes } from '../../cnr/reducers/SignInReducer';
import { uniqueKey } from '../../common/keys';
import { appFormTypes } from '../../enums/appFormTypes';
import { gEnums } from '../../enums/globalEnums';
import AnimateComponent, { _animateTypes } from '../../motions/AnimateComponent';
import WelcomePage from '../../page/WelcomePage';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import FullPageAlert from '../alerts/FullPageAlert';
import SuspenseDimmer from '../alerts/SuspenseDimmer';

const UiConfirmChange = React.lazy(() => import('./signInComponents/UiConfirmChange'));
const UiEmailLinkAuth = React.lazy(() => import('./signInComponents/UiEmailLinkAuth'));
const UiEmailPasswordAuth = React.lazy(() => import('./signInComponents/UiEmailPasswordAuth'));
const UiForgotPassword = React.lazy(() => import('./signInComponents/UiForgotPassword'));
const UiGoogleAuth = React.lazy(() => import('./signInComponents/UiGoogleAuth'));
const UiPhoneAuth = React.lazy(() => import('./signInComponents/UiPhoneAuth'));
const UiResetPasswordConfirmation = React.lazy(() => import('./signInComponents/UiResetPasswordConfirmation'));
const UiSignInLinkConfirm = React.lazy(() => import('./signInComponents/UiSignInLinkConfirm'));
const UiTooMany = React.lazy(() => import('./signInComponents/UiTooMany'));

export const SignInContext = createContext();

const SignIn = (props) => {

  const { fromDirect, signInMethodType: _signInMethodType, itemData } = props ?? {}

  const _host = document.location.host

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, fns, settings, navigate } = parentContext ?? {}
  const { appSettings_state, appUser_state, page_state, paps_state } = states
  const { functions_handlers } = handlers
  const { database_fns } = fns ?? {}
  const { clientSettings, homeSettings } = settings ?? {}

  const { global } = homeSettings ?? {}
  const { signInHelp } = global ?? {}

  const { appLanding_global, appConfigSettings } = appSettings_state ?? {}
  const { useAppLanding, appLandingUrls } = appLanding_global ?? {}
  const { appNames } = appConfigSettings ?? {}
  const { appName } = appNames ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers } = frameworkContext ?? {}

  const { global: global_client } = clientSettings ?? {}
  const { appSignIn: appSignIn_client } = global_client ?? {}
  const { forceEventSignInAtClient: forceEventSignInAtClient_c, useClientSignInSettings: useClientSignIn_c } = appSignIn_client ?? {}

  const { pathViews, rootPaths } = paps_state ?? {}
  const { events: eventKey } = pathViews

  // authContext 
  const { appUser } = appUser_state ?? {}
  const { appUserAccess } = appUser ?? {}
  const { loggedIn } = appUserAccess ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings } = pageSettings ?? {}
  const { appSignIn, topMenu, adminNotifications, welcomePage } = aps_global ?? {}
  const { showWelcomePage } = welcomePage ?? {}
  const { disableSignIn, allowCreateAccount, signInToClient, allowPasswordForgot, useClientSignInSettings, forceClientProfileValidation } = appSignIn ?? {}
  const { mainFixedCaption } = topMenu ?? {}

  const _signInAtClient = forceEventSignInAtClient_c || signInToClient
  const _useClientSignIn = useClientSignIn_c || useClientSignInSettings

  const _appSignIn = _useClientSignIn ? appSignIn_client : appSignIn

  // reducer
  const initState = signInReducerInitialState({ database_fns, functions_handlers, appSignIn: _appSignIn, paps_state, aps_global, aps_appUserSettings, signInMethodType: _signInMethodType, adminNotifications })
  const [signIn_state, dispatch] = useReducer(signInReducer, initState);
  const signIn_handlers = signInHandlers(dispatch)

  const { handleCancel_signIn } = signIn_handlers ?? {}
  const { creds, email: email_state, authProviders, signInMethods, signInMethodType, signInComponentType, signInType, confirmationType } = signIn_state ?? {}
  const { emailLink } = authProviders ?? {}
  const { email } = creds ?? {}

  const _email = email_state ? email_state : email

  const allowForgot = allowPasswordForgot && (signInType === signInTypes.signIn)

  const [welcomePageSeen, setWelcomePageSeen] = useState()

  useEffect(() => {
    if (useAppLanding && appLandingUrls) {
      appLandingUrls.forEach(appLandingUrl => {
        const { host, hostUrl, baseUrl } = appLandingUrl
        if ((_host === host) && hostUrl) {
          navigate(hostUrl)
        }
      })
    }

    let cm;
    if (props.handleCancel) {
      cm = props.handleCancel
    } else if (fromDirect && framework_handlers) {
      cm = framework_handlers.handleShow_signIn
    } else if (signInMethodType) {
      cm = handleCancelType
    } else {
      cm = signIn_handlers.handleChange_signInMethodType
    }
    signIn_handlers.handleSet_signInMethods(_appSignIn, cm)
    signIn_handlers.handleSet_signInHelp(signInHelp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_signInAtClient && eventKey) {
      const _cp = rootPaths.clients.substring(0, rootPaths.clients.length - 1)
      navigate(_cp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelType = () => handleSignInMethodType()
  const handleSignInType = () => signIn_handlers.handleChange_signInType()
  const handleSignInMethodType = (mt) => signIn_handlers.handleChange_signInMethodType(mt)
  const handleWelcomePageSeen = () => setWelcomePageSeen(true)

  const icon = (icon, color, inverted) => <Icon name={icon} color={color ? color : null} inverted />

  const signInButtons = () => signInMethods.map(sim => (
    <Button labelPosition='left'
      icon
      key={uniqueKey('sib', sim.name)}
      color={signInType === signInTypes.createAccount ? 'green' : sim.color}
      onClick={() => { handleSignInMethodType(sim.name) }}>
      {icon(sim.icon, forceClientProfileValidation ? 'blue' : null, forceClientProfileValidation ? true : null)}
      {_.startCase(sim.name)}
    </Button>
  ))

  const signUpButton = () => <Button className={'sign-up'} onClick={() => { handleSignInType() }}>{signInType === signInTypes.createAccount ? 'Sign In' : 'Create Account'}</Button>
  const forgotPasswordDiv = () => <div onClick={() => { signIn_handlers.handleShow_forgotPassword() }}>Forgot Password</div>

  const signInContents = () => {

    if (showWelcomePage && !welcomePageSeen) { return <WelcomePage handleWelcomePageSeen={handleWelcomePageSeen} /> }

    if (disableSignIn) { return <div className={'cntr'}>{'Sign In Not Permitted'}</div> }

    if (!signInMethodType && signInMethods && signInMethods.length > 0) {
      return <Segment basic placeholder className={'sib'}>
        <Header as='h5' dividing textAlign='center'>
          {signInType === signInTypes.createAccount ? 'Create Account Options' : 'Sign In Options'}
        </Header>
        <div className='btns-sign-in'>
          {signInButtons()}
        </div>
        {appVersionDiv(appName)}
        {allowCreateAccount && signUpButton()}
        {/* {!loggedIn && !emailLink && allowForgot && forgotPasswordDiv()} */}
      </Segment>
    } else {
      // signInAlertTypes  
      if (confirmationType) {
        return <UiSignInLinkConfirm />
      } else {
        switch (signInComponentType) {
          case signInComponentTypes.emailReset:
            return <div>Confirmation</div>
          case signInComponentTypes.resetPasswordConfirmation:
            return <UiResetPasswordConfirmation email={_email} />
          case signInComponentTypes.confirmPasswordChange:
            return <UiConfirmChange />
          case signInComponentTypes.forgotPassword:
            return <UiForgotPassword appFormType={appFormTypes.signInEmail} mainFixedCaption={mainFixedCaption} />
          case signInComponentTypes.tooManyAttempts:
            return <UiTooMany />
          default:
            // signInMethodTypes
            switch (signInMethodType) {
              case signInMethodTypes.phone:
              case gEnums.signInProviderTypes.phoneNumber:
                return <UiPhoneAuth mainFixedCaption={mainFixedCaption} itemData={itemData} />
              case gEnums.signInProviderTypes.emailLink:
                return <UiEmailLinkAuth mainFixedCaption={mainFixedCaption} />
              case gEnums.signInProviderTypes.emailPassword:
                return <UiEmailPasswordAuth mainFixedCaption={mainFixedCaption} />
              case gEnums.signInProviderTypes.google:
                return <UiGoogleAuth />
              case gEnums.signInProviderTypes.passcode:
                return <UiEmailPasswordAuth />
              default:
                return <UiEmailPasswordAuth />
            }
        }
      }
    }
  }

  // do not use transitions
  const content = () => <AnimateComponent animateType={_animateTypes.component} >
    <Suspense fallback={<SuspenseDimmer origin={'Sign In'} />}>
      {signInContents()}
    </Suspense>
  </AnimateComponent>

  const fpw_signIn = () => <SignInContext.Provider value={{ signIn_state, signIn_handlers }}>
    <FullPageWrapper
      content={content()}
      topperCaption={signInType === signInTypes.createAccount ? 'Create Account' : 'Sign In'}
      topperCaption2={mainFixedCaption}
      handleCancel={handleCancel_signIn}
      isBack={signInMethodType ? true : null}
      allowRefresh={true}
    ></FullPageWrapper>
  </SignInContext.Provider>

  if (loggedIn) {
    return <FullPageAlert message={'You are already signed in'} navigate={navigate} handleCancel={handleCancel_signIn} />
  } else {
    return fpw_signIn()
  }
}

export default SignIn