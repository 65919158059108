import React, { useContext, useState } from 'react';
import TimeKeeper from 'react-timekeeper';
import { Form } from 'semantic-ui-react';
import { validateTime } from '../../../validation/validation';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import UiSaveButtons from '../../buttons/UiSaveButtons';
import { FrameworkContext } from '../../../cnr/contexts/FrameworkContent';

const TimePicker = (props) => {

  const { fip } = props ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { frameworkRightType } = framework_state ?? {}

  const [selectedTime, setSelectedTime] = useState(fip && fip.value ? fip.value : '12:00')
  const [showTime, setShowTime] = useState()

  const _selectedTime = selectedTime && selectedTime.length === 8 ? selectedTime.substring(selectedTime, 5) : selectedTime

  const validTime = validateTime(_selectedTime)

  const handleShowTime = () => setShowTime(!showTime)

  const handleDone = () => {
    const data = {
      propname: fip.propname,
      value: _selectedTime
    }
    fip.onChange(null, data)
    setShowTime(false)
  }

  const handleSelectedTime = (t) => {
    const _t = t && t.length === 4 ? '0' + t : t
    setSelectedTime(_t)
  }

  const handleClearTime = () => {
    const data = {
      propname: fip.propname,
      value: null
    }
    fip.onChange(null, data)
    setShowTime(false)
  }

  const timeKeeper = () => <div className={'time-keeper'}><TimeKeeper
    time={validTime ? _selectedTime : '12:00'}
    onChange={(data) => handleSelectedTime(data.formatted24)}
    onDoneClick={handleDone}
    switchToMinuteOnHourSelect
  /></div>

  const footer = () => {
    const btns = [
      { caption: 'Clear Time', icon: 'delete', oc: handleClearTime, color: 'red', fl: true },
    ]
    return <UiSaveButtons
      others={btns}
    />
  }

  const wrapper = () => <Wrapper
    content={timeKeeper()}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper>

  const stepTimer = () => <FullPageWrapper
    content={_selectedTime && wrapper()}
    handleCancel={handleShowTime}
    topperCaption={'Select Time'}
    topperCaption2={!validTime && 'The current selected time is invalid'}
  />

  const timeContent = () => {
    if (frameworkRightType) {
      return wrapper()
    } else {
      return stepTimer()
    }
  }

  return <React.Fragment>
    <Form.Input {...fip} icon={'clock'} fluid onClick={(e) => handleShowTime(fip)} />
    {showTime && timeContent()}
  </React.Fragment>

}

export default TimePicker

export const TimePickerDirect = (props) => {

  const { handleCancel, initialValue, handleDone } = props ?? {}

  const [selectedTime, setSelectedTime] = useState(initialValue ? initialValue : '12:00')

  const _selectedTime = selectedTime && selectedTime.length === 8 ? selectedTime.substring(selectedTime, 5) : selectedTime

  const validTime = validateTime(_selectedTime)

  const handleSelectedTime = (t) => {
    const _t = t && t.length === 4 ? '0' + t : t
    setSelectedTime(_t)
  }

  const handleClearTime = () => {
    setSelectedTime(null)
    handleDone()
  }

  const timeKeeper = () => <div className={'time-keeper'}><TimeKeeper
    time={validTime ? _selectedTime : '12:00'}
    onChange={(data) => handleSelectedTime(data.formatted24)}
    onDoneClick={handleDone}
    switchToMinuteOnHourSelect
  /></div>

  const footer = () => {
    const btns = [
      { caption: 'Clear Time', icon: 'delete', oc: handleClearTime, color: 'red', fl: true },
    ]
    return <UiSaveButtons
      others={btns}
    />
  }

  const wrapper = () => <Wrapper
    content={timeKeeper()}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper>

  const stepTimer = () => <FullPageWrapper
    content={_selectedTime && wrapper()}
    handleCancel={handleCancel}
    topperCaption={'Select Time'}
    topperCaption2={!validTime && 'The current selected time is invalid'}
  />

  return stepTimer()
}
