import _ from 'lodash';
import { formatItem, formatTypes, toDateTime } from '../../../common/dateFormatting';
import { sortObject } from '../../../common/sorting';
import { gEnums } from '../../../enums/globalEnums';
import { filterHelpers, getAllFilterTags } from '../../../common/filtering';
import { propHelpers } from '../../../common/tester';
import { currentHelpers } from '../../../redirection/current';
import { getAlphaMenuItems } from '../../../components/menus/genericMenuItem';

/**
 * 
 * @param {*} filter_init (paps_state, viewItem, viewListData, layoutType, staticViews, dateFormat)
 * @returns 
 */
export const getGroupDataInfo = (filter_init, vi, viewListData) => {

  const { paps_state, layoutType, staticViews, dateFormat, viewItem, groupingOptions, display } = filter_init ?? {}
  const { useGlobalGroupSettings, groupType: groupType_go } = groupingOptions ?? {}

  const _viewItem = vi ? vi : viewItem

  const { view, viewKey } = paps_state ?? {}
  const { filter, grouping } = _viewItem ?? {}

  const { groupActive } = grouping ?? {}
  let { groupType } = grouping ?? {}
  const { showFilter } = filter ?? {}

  if (!groupType) { groupType = gEnums.groupTypes.swipeableTab }

  // adjust for global
  if (useGlobalGroupSettings && groupType_go) { groupType = groupType_go }

  let filterAlpha = 'alpha-item-'
  filterAlpha += viewKey ? view + '-' + viewKey : view
  const _alphaValue = currentHelpers.storageItem_get(filterAlpha)

  let divideIntoGroups = false

  switch (groupType) {
    case gEnums.groupTypes.accordion:
    case gEnums.groupTypes.calendarSidebar:
    case gEnums.groupTypes.menuSidebar:
    case gEnums.groupTypes.segment:
    case gEnums.groupTypes.swipeableGroup:
    case gEnums.groupTypes.swipeableTab:
      divideIntoGroups = true
      break;
    default:
    // nothing
  }

  const dataFilterz = {
    allFilterTags: null,
    alphaValue: _alphaValue ? _alphaValue : 65,
    alphaSelected: false,
    dateFormat,
    filterValues: [],
    filterStatusType: gEnums.filterStatusTypes.none,
    groupDataProps: null,
    maskValue: null,
    preFilteredData: null,
    searchValue: null,
    selectedTab: null,
    showFilter,
    showTab: showTab(groupActive, groupType, layoutType),
    staticViews,
    divideIntoGroups,
    view,
    viewItem: _viewItem,
  }

  // viewListData is the initial data
  if (viewListData) {
    ammendGroupFilterData(view, _viewItem, viewListData, dataFilterz, layoutType, staticViews, dateFormat, groupingOptions, display)
    if (showFilter) { dataFilterz.allFilterTags = getAllFilterTags(_viewItem, viewListData) }
  }

  const defaultValue = formatItem(dateFormat ? dateFormat : formatTypes.date, new Date())
  const gdps = getGroupDataProps(viewListData, _viewItem, defaultValue, staticViews, groupingOptions, display)

  return { dataFilterz, gdps }
}


/** groupDataProps, showTab, useSwipeable, preFilteredData */
const ammendGroupFilterData = async (view, viewItem, viewListData, dataFilterz, layoutType, staticViews, dateFormat, groupingOptions) => {

  const { useGlobalGroupSettings, groupType: groupType_go } = groupingOptions ?? {}

  const allowGroup = (layoutType === gEnums.layoutTypes.horizontalSliders) ? false : true
  const { grouping } = viewItem ?? {}

  let { display } = viewItem ?? {}

  const { groupActive, groupByProp, defaultToToday } = grouping ?? {}
  let { groupType } = grouping ?? {}

  // adjust for global
  if (useGlobalGroupSettings && groupType_go) { groupType = groupType_go }

  let { sortProp } = display ?? {}

  let f = []

  if (allowGroup && groupActive) {
    switch (groupType) {
      default:
        let defaultValue = currentHelpers.storageItem_get(view + '-selectedTabValue')

        if (!defaultValue && defaultToToday && groupType === gEnums.groupTypes.swipeableTab) {
          defaultValue = formatItem(dateFormat ? dateFormat : formatTypes.shortDate, new Date())
        }

        dataFilterz.groupDataProps = getGroupDataProps(viewListData, viewItem, defaultValue, staticViews, groupingOptions)

        if (dataFilterz.groupDataProps && dataFilterz.showTab) {
          switch (groupType) {
            case gEnums.groupTypes.swipeableTab:
            case gEnums.groupTypes.swipeableGroup:
              dataFilterz.useSwipeable = true
              break;
            default:
            // nothing
          }
          filterHelpers.filterTabData(viewListData, dataFilterz.groupDataProps.defaultTab, groupByProp, sortProp, f)
        }
        break;
    }
  }
}

export const showTab = (groupActive, groupType, layoutType) => {
  if (layoutType === gEnums.layoutTypes.horizontalSliders) {
    return false
  } else {
    if (groupActive) {
      switch (groupType) {
        case gEnums.groupTypes.swipeableGroup:
        case gEnums.groupTypes.swipeableTab:
          return true
        default:
          return false
      }
    }
  }
  return false
}

// Returns the data in groups
const getGroupDataProps = (viewListData, viewItem, defaultValue, staticViews, groupingOptions, display_g) => {

  const { grouping, display } = viewItem ?? {}
  const { groupActive, groupByProp } = grouping ?? {}
  let { orderGroupsByAlpha } = grouping ?? {}
  const { useGlobalGroupSettings, orderGroupsByAlpha: orderGroupsByAlpha_go } = groupingOptions ?? {}

  // adjust for global
  if (useGlobalGroupSettings && orderGroupsByAlpha_go) { orderGroupsByAlpha = orderGroupsByAlpha_go }

  let { sortProp } = display ?? {}

  const { timeZone, groupDatesByTimeZone } = display_g ?? {}

  if (!sortProp) { sortProp = 'name' }

  if (groupActive) {

    const groupDataProps = {
      defaultTab: { value: defaultValue },
      groupByProp: groupByProp,
    }

    // group the viewListData by groupTypeProp
    let groupedDataGrouped = _.groupBy(viewListData, groupByProp)

    let groupKeys;

    const groups = []

    if (sortProp === 'startDate' || groupByProp === 'startDate') {
      groupedDataGrouped = sortObject(groupedDataGrouped, null, false)
      groupKeys = Object.keys(groupedDataGrouped)
      groupKeys = _.sortBy(groupKeys, function (value) {
        const dv = value && value.seconds ? toDateTime(value.seconds) : value
        const normalTime = new Date(dv)
        const timeZoneTime = new Date(dv).toLocaleDateString('en', { timeZone: timeZone ? timeZone : 'UTC' })
        const _timeZoneTime = groupDatesByTimeZone && timeZone ? timeZoneTime : normalTime
        return _timeZoneTime
      })
    } else {
      groupKeys = Object.keys(groupedDataGrouped)
    }

    groupKeys.forEach(gk => {
      delete groupedDataGrouped[gk]['key']
      // add to the group
      groups.push({
        key: gk,
        data: groupedDataGrouped[gk],
        alphaInfo: getAlphaMenuItems(groupedDataGrouped[gk], null, true),
        namedKey: staticViews && staticViews[groupByProp] && staticViews[groupByProp][gk] && staticViews[groupByProp][gk].name ? staticViews[groupByProp][gk].name : gk
      })
    })

    const _isDateProp = groupByProp ? propHelpers.isDateProp(groupByProp) : false
    let _groups;

    if (!_isDateProp) {
      _groups = orderGroupsByAlpha ? _.orderBy(groups, 'namedKey') : groups
    } else {
      _groups = groups
    }

    groupDataProps.groups = _groups

    if (groupKeys) {
      if (defaultValue) {
        if (_.includes(groupKeys, defaultValue)) {
          groupDataProps.defaultTab = { value: defaultValue }
        } else {
          groupDataProps.defaultTab = { value: groupKeys[0] }
        }
      } else {
        groupDataProps.defaultTab = { value: groupKeys[0] }
      }
    }

    return groupDataProps
  }
}