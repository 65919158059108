import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import TableViewer from '../../../../global/components/viewers/TableViewer'
import { SportsDistrictContext } from '../../cnr/contexts/SportsDistrictContext'
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'
import { SportsSidebarContext } from '../SportsDataSidebar'
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper'
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons'

export const SportsRosters = (props) => {

  const { currentItem, currentMenuKey } = props ?? {}
  const { name: teamName, _itemKey: teamKey } = currentItem ?? {}
  const _teamName = teamName ? _.camelCase(teamName) : null

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state } = sportsDistrictContext ?? {}
  const { gls_scores } = sportsDistrict_state ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { sportsKey, } = sportsSeason_state ?? {}

  const sportsDataSidebarContext = useContext(SportsSidebarContext)
  const { sportsSidebar_state, sportsSidebar_handlers } = sportsDataSidebarContext ?? {}
  const { rosters, sheetsData } = sportsSidebar_state ?? {}

  const [rosterData, setRosterData] = useState()
  const [sheetData, setSheetData] = useState()

  useEffect(() => {
    if (teamName) {
      if (teamName && rosters && currentMenuKey && rosters[currentMenuKey]) {
        setRosterData(rosters[currentMenuKey])
      } else {
        setRosterData(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sportsKey, currentMenuKey]);

  useEffect(() => {
    if (sheetsData && sheetsData[_teamName]) {
      setSheetData(sheetsData[_teamName])
    } else {
      setSheetData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sheetsData]);

  const handleGet_rosterTeamSheet = () => {
    const _glsScore = gls_scores ? gls_scores[sportsKey] : {}
    const { rosterGoogleSheetsId } = _glsScore ?? {}
    sportsSidebar_handlers.handleStartUpdate()
    sportsSidebar_handlers.handleGet_gsSheetData(rosterGoogleSheetsId, teamName, teamKey)
  }

  const content = () => {
    if (rosterData) {
      return <TableViewer
        jsonData={rosterData}
        allowEdit={false}
      />
    } else if (sheetData) {
      return <TableViewer
        jsonData={sheetData}
        allowEdit={false}
      />
    } else {
      return <div>SportsRosters</div>
    }
  }

  const footer = () => {
    const btns = []
    btns.push({ oc: handleGet_rosterTeamSheet, caption: 'Get Google Sheet Roster', icon: 'arrow right' })
    return <UiSaveButtons
      others={btns}
    />
  }

  const wrapper = () => <Wrapper
    header={teamName}
    content={content()}
    footer={footer()}
    wrapperType={wrapperTypes.padded}
  ></Wrapper>

  return content()

}
