import React, { useContext, useState } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { SettingsMenuContext } from '../../../cnr/contexts/SettingsMenuContext';
import { uniqueKey } from '../../../common/keys';
import _ from 'lodash'

/**
 * These are the BIG menu items. 
 * This is an ELEMENT
 * @param {*} props (groupKey, menuItemElements, onClick, groupIcon, iconColor, caption, inverse, isAlt, notInverted)
 * @returns a <Menu.Item><Menu.Menu>menuItems</Menu.Menu><Menu.Item>
 * @references - AppSidebarMenu, MenuItemsGroup
 * @description - They wiil open and close. They have a ICON, a NAME and a CARET ICON
 * Menu.Header
 * Menu.Menu
 * >> list of menuItems
 * the Menu.Item contains a Menu.Menu element with all the passed in `menuItems` will be located
 */

const SettingsAreaGroupMenuItem = (props) => {

  // the menuItems are the menuItemElements that will be displayed
  const { groupKey, menuItemElements, restrict, cornerIcon, groupIcon, iconColor, caption, inverse, isAlt, notInverted } = props
  const ci = inverse ? 'up' : 'down'

  // settingsMenuContext
  const settingsMenuContext = useContext(SettingsMenuContext);
  const { settingsMenu_state, settingsMenu_handlers } = settingsMenuContext ? settingsMenuContext : {}
  const { activeMenu, useFlatMenuIcons } = settingsMenu_state ? settingsMenu_state : {}

  const [_itemActive, setItemActive] = useState()

  let _inverted = !useFlatMenuIcons
  if (notInverted) { _inverted = false }

  const itemActive = activeMenu === groupKey
  const _itemIsActive = itemActive || _itemActive ? true : false

  /**
   * handles the click of a menuGroup item. This will expand the menuItems within the group
   * @param {object} item 
   */
  const handleGroupHeaderClick = (item) => {
    settingsMenu_handlers && settingsMenu_handlers.handleActiveMenuGroup(item)
    !settingsMenu_handlers && setItemActive(!_itemActive)
  }

  const caretIcon = _itemIsActive ? 'angle ' + ci : 'angle right'
  let cn = _itemIsActive ? '' : 'dis-none'
  let allowClick = true

  if (restrict) { allowClick = false }

  const icon = () => {
    return cornerIcon ?
      <Icon.Group>
        <Icon color={iconColor} name={groupIcon ? groupIcon : 'cog'} circular inverted={_inverted} />
        <Icon corner name={cornerIcon} color={'black'} />
      </Icon.Group>
      :
      <Icon color={iconColor} name={groupIcon ? groupIcon : 'cog'} circular inverted={_inverted} />
  }

  const menu_header = <Menu.Header onClick={() => allowClick && handleGroupHeaderClick(groupKey)}  >
    {icon()}
    <div>{caption ? caption : _.startCase(groupKey) + ' m*'}</div>
    <div><Icon name={caretIcon} /></div>
  </Menu.Header>

  // this is menu.menu wrapper, wrapping all the passed in menuItems  
  // const menu_menu = <Menu.Menu className={'mi-group-item sagmi ' + cn}>

  const menu_menu = <Menu.Menu className={cn}>
    {menuItemElements}
  </Menu.Menu>

  let cnn = ''

  if (isAlt) { cnn += ' alt' }
  if (restrict) {
    allowClick = false
    cnn += ' restrict'
  }

  if (inverse) {
    return <Menu.Item key={uniqueKey('smg', groupKey)} className={cnn + ' inv'}>{menu_menu}{menu_header}</Menu.Item>
  } else {
    return <Menu.Item key={uniqueKey('smg', groupKey)} className={cnn} >{menu_header}{menu_menu}</Menu.Item>
  }
}

export default SettingsAreaGroupMenuItem