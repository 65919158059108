import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { FormContext } from '../../cnr/contexts/FormContext';
import { uniqueKey } from '../../common/keys';
import { gEnums, listTypes } from '../../enums/globalEnums';
import { propDataOpts, spp } from '../../viewSettings/settingsProps/settingsAll';
import HtmlEditor from './elements/HtmlEditor';
import FormElements from './props/FormElements';
import { ComponentContext } from '../../cnr/contexts/ComponentContext';

/**
 * 
 * @param {object} props 
 * @returns the formItems
 */
const FormItems = () => {

  // formContext
  const formContext = useContext(FormContext)
  const { form_state, form_handlers, formParent_contexts } = formContext ?? {}
  const { states, settings } = formParent_contexts ?? {}
  const { page_state } = states ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { homeSettings } = settings ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}
  const { logging } = homeSettings_global ?? {}

  // componentContexts
  const componentContext = useContext(ComponentContext)
  const { component_state } = componentContext ?? {}
  const { componentContexts } = component_state ?? {}

  const {
    currentFip,
    data_localForm,
    formProps,
    viewProps_normal,
    vpsa,
  } = form_state

  let {
    data_current,
    viewProps,
  } = formProps ?? {}

  // data_current does NOT change with temp updates

  // componentContexts
  const { viewSettingsContext } = componentContexts ?? {}

  let { themeColors } = aps_global ?? {}

  if (logging && logging.allowLogging && logging.logFormInitData) { console.log('logging >', 'data_current', data_current) }

  // viewSettingsContext  
  const { viewSettings_state } = viewSettingsContext ?? {}

  const { settings_temp } = viewSettings_state ?? {}
  const { gvvi } = settings_temp ?? {}
  const { global: _global } = gvvi ?? {}

  if (!themeColors && _global && _global.themeColors) { themeColors = _global.themeColors }

  if (vpsa) { viewProps = vpsa }

  // see if viewProps has 'dataListType'
  if (viewProps_normal && viewProps_normal['dataListType']) {
    if (data_localForm && data_localForm['dataListType'])
      switch (data_localForm['dataListType']) {
        case gEnums.dataListTypes.view:
          viewProps_normal['view'] = spp('view', propDataOpts(false, gEnums.formItemTypes.select, listTypes.dataCollection))
          break;
        default:
        // nothing
      }
  }

  // IMPORTANT: Form Items - Update the form item data
  useEffect(() => {
    if (data_current && !data_localForm) {
      form_handlers.handleUpdateLocalFormData(data_current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [data_current]);

  useEffect(() => {
    form_handlers.handleGet_viewProps(viewProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [viewProps]);

  // IMPORTANT: Form Items - All Form Item Changes 
  /** Triggered when any of the element changes value */
  const handleFormItemChange = (e, data, items, arrayItems) => {
    form_handlers.handleFormItemChange(e, data, items, arrayItems)
  }

  if (currentFip) {
    return <HtmlEditor
      key={uniqueKey('htmle', currentFip.propname)}
      currentFip={currentFip}
      handleUpdate={handleFormItemChange}
      handleEditor={form_handlers.handleEditor}
    />
  }

  return viewProps_normal ? <FormElements /> : <div>---</div>

}

export default FormItems