import React, { createContext, useContext } from 'react';
import { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';
import { FilterContext } from '../contexts/FilterContext';
import { FrameworkContext } from '../contexts/FrameworkContent';
import { ParentContext } from '../contexts/ParentContext';
import { UiItemContext } from '../contexts/UiItemContext';

export const DisplayContext = createContext();

const DisplayProvider = (props) => {

  const { selectedGroupItem } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, paps_state, transition_state } = states
  const { pageSettings } = page_state
  const { aps_page } = pageSettings ?? {}

  // papsContext 
  const { ddGroupIndex, ddGroupKey, ddProps } = paps_state ?? {}

  const { transitions } = transition_state ?? {}
  const transition = transitions ? transitions[_animateTypes.pageItem] : null
  const { showTransition } = transition ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { desktopMode } = framework_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { props_viewItem } = item_state


  // filterContext
  const filterContext = useContext(FilterContext);
  const { uiFilter_state } = filterContext ?? {}
  const { groupDataProps } = uiFilter_state ?? {}

  const { layout, desktopLayout } = aps_page ?? {}
  const _layout = desktopMode ? desktopLayout : layout

  const { groups } = groupDataProps ?? {}

  const displayProps = {
    ddGroupIndex,
    ddGroupKey,
    ddProps,
    dragMode: false,
    groups,
    selectedGroupItem,
    props_viewItem,
    layout_page: _layout,
  }

  const display_state = { displayProps }

  if (showTransition) {
    return <MotionComponent transition={transition} >
      <DisplayContext.Provider value={{ ...props, display_state }} >
        {props.children}
      </DisplayContext.Provider >
    </MotionComponent>
  } else {
    return <DisplayContext.Provider value={{ ...props, display_state }} >
      {props.children}
    </DisplayContext.Provider >
  }
}

export default DisplayProvider