/**
 * 
 * @param {object} settings cacheKey, cacheName, cacheData, merge
 * @returns 
 */
export const sendMessageToServiceWorker = (settings) => {
  const { cacheKey, cacheName } = settings ?? {}
  if (navigator.serviceWorker && navigator.serviceWorker.controller) {
    // Sends data from the main thread (React app) to the service worker. In service-worker.js
    // console.log('------- >>> postMessage', cacheKey, cacheName)
    navigator.serviceWorker.controller.postMessage(settings);
    return true
  } else {
    console.log('-xxx postMessage', cacheKey, cacheName)
    return false
  }
}