import QRCode from 'qrcode.react';
import React, { useContext } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import { Icon, Label, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../global/common/keys';
import { formatItem, formatTypes } from '../../../global/common/dateFormatting';
import { ParentContext } from '../../../global/cnr/contexts/ParentContext';
import { appUserLevels } from '../../../global/enums/globalEnums';
import { TicketingContext } from '../cnr/contexts/TicketingContext';
import { ticketKeyTypes } from '../cnr/reducers/TicketingReducer';

const useJsonForQrCode = false

const SubTicket = (props) => {

  const {
    forSend,
    isWillCall,
    subTicket,
    subTicketKey,
    subTicketSelected,
    ticket,
    ticketType,
  } = props ?? {}


  const { _itemKey, directTicketKey } = ticket ?? {}
  const { entered, registrationId, _itemKey: itemKey_sub, subTicketKey: ticketNumber, timestamp, assignedTo, forwardedTo, subTicketIndex } = subTicket ?? {}

  const t = timestamp ? formatItem(formatTypes.time, timestamp) : null
  const d = timestamp ? formatItem(formatTypes.shortDate, timestamp) : null
  const dt = t && d ? d + ' @ ' + t : null

  const _subTicketKey = itemKey_sub ? itemKey_sub : directTicketKey

  const parentContext = useContext(ParentContext);
  const { fns } = parentContext ?? {}
  const { appUser_fns } = fns ?? {}

  // authContext  
  const allowMods = appUser_fns ? appUser_fns.validateAccess_item(appUserLevels.admin) : false

  // ticketingContext
  const ticketingContext = useContext(TicketingContext)
  const { ticketing_state, ticketing_handlers } = ticketingContext ?? {}
  const { subTicketsSelected, appTicketing, showPresent, willCallTicketPath, pathViews: pathViews_ticket, showLookup, showPrint, willCallUserKey } = ticketing_state ?? {}
  let { allowTicketForwarding } = appTicketing ?? {}

  let showQrCord = true
  let headerCn = 'sub-ticket-header'
  if (forwardedTo && willCallUserKey && (forwardedTo !== willCallUserKey)) {
    showQrCord = false
    headerCn += ' fwd'
  }

  const _subTicketSelected = subTicketsSelected && subTicketsSelected.includes(subTicketKey)
  const _pathViews = pathViews_ticket ? pathViews_ticket : null // _pathViews

  allowTicketForwarding = showPrint ? false : allowTicketForwarding

  const ticketUrl = (data) => {
    const { registrationId, ticketKey, subTicketKey } = data
    const direction = entered ? 'exit' : 'enter'
    let turl;
    if (_pathViews) {
      turl = registrationId + '~' + ticketKey + '~' + subTicketKey + '~XX'
    } else {
      turl = ticketKeyTypes.registrationId + '~' + registrationId
        + '~' + ticketKeyTypes.ticketKey + '~' + ticketKey
        + '~' + ticketKeyTypes.subTicketKey + '~' + subTicketKey
        + '~' + ticketKeyTypes.direction + '~' + direction
    }
    return turl
  }

  const handleShowReassign = () => ticketing_handlers.handleShowReassign(ticket, subTicketKey)
  const handleGotoWillCall = () => {
    let wcPath = willCallTicketPath + '/'
    wcPath += forwardedTo ? forwardedTo : assignedTo
    window.open(wcPath, '_blank')
  }

  const willCallProps = () => {
    let cn = 'card-ticket-sub'
    let size = 48

    if (entered && entered === true) { cn += ' entered' }
    if (dt) { cn += ' dt' }

    switch (ticketType) {
      default:
        if ((subTicketSelected === subTicketKey)) {
          cn += ' selected'
          size = 128
        }
        if (!forSend && !isWillCall && !showPresent && subTicketsSelected && subTicketsSelected.includes(subTicketKey)) {
          cn += ' selectedd'
        }
    }

    if (showLookup && 1 === 3) {
      cn += ' look-up'
      if (entered && entered === true) { cn += ' entered' }
    } else if (isWillCall || showPresent || showLookup) {
      size = 164
      cn += ' will-call'
      if (entered && entered === true) { cn += ' entered' }
    }

    if (allowTicketForwarding) { cn += ' exchange' }

    return { willCallCn: cn, qrSize: size }
  }

  const { willCallCn, qrSize } = willCallProps()

  const qrValue = (data) => {
    if (useJsonForQrCode) {
      return JSON.stringify(data, null, 3)
    } else {
      return ticketUrl(data)
    }
  }

  let allowSelect = true

  const selfIcon = () => <Icon name='user' color={'green'} size='big' />

  const assignedIcon = () => <Icon.Group size='big' onClick={(e) => { allowMods && handleGotoWillCall(e) }}>
    <Icon name='user' color={'blue'} />
    <Icon corner name='mobile alternate' />
  </Icon.Group>

  const usedIcon = () => <Icon.Group size='big'>
    <Icon name='ticket' color={'red'} />
    <Icon corner name='check' />
  </Icon.Group>


  const ticketIcon = () => {
    if (assignedTo) {
      if (entered) {
        return usedIcon()
      } else {
        return assignedIcon()
      }
    } else {
      if (entered) {
        return usedIcon()
      } else {
        return selfIcon()
      }
    }
  }

  const iconPhone = (value, icon, color) => <div className={'icon-phone'}>
    <Icon name={icon} color={color} />
    {value}
  </div>

  const assigned = (forWc) => {
    let line;
    if (assignedTo.indexOf('@') > 0) {
      line = iconPhone(assignedTo, 'mail', 'blue')
    } else {
      line = iconPhone(formatPhoneNumber(assignedTo), 'mobile alternate', 'blue')
    }
    return <React.Fragment>
      {line}
      {forwardedTo && iconPhone(formatPhoneNumber(forwardedTo), 'mail forward', 'red')}
      {allowTicketForwarding && isWillCall && !forwardedTo && !showPresent && !entered &&
        <div onClick={(e) => { handleShowReassign(e) }}><Icon name={'mail forward'} color={'blue'} />Forward</div>
      }
    </React.Fragment>
  }

  const div_ticketIndex = () => <div className={'sub-ticket-number'}><Label color={'black'} size={'large'}>{subTicketIndex}</Label></div>
  const div_ticketNumber = () => <div className={'sub-ticket-key'}>{_subTicketSelected ? 'Click to Un-select' : 'Click to Select'}</div>
  const div_ticketAssigned = () => <div className={'sub-ticket-assigned'}>{assignedTo ? assigned() : 'Not Assigned'}</div>
  const div_ticketIcon = () => <div className={'sub-ticket-icon'}>{ticketIcon()}</div>
  const div_ticketHeader = () => <div className={headerCn}><Label color={assignedTo ? 'blue' : 'grey'} size={'large'}> {assignedTo ? subTicketIndex + ' (' + assignedTo + ')' : subTicketIndex}</Label></div>
  const div_ticketForward = () => <div className={'sub-ticket-forward'} onClick={(e) => { handleShowReassign(e) }}>Forward</div>
  const div_ticketQr = () => <div className={'sub-ticket-qr'}>
    {!showQrCord && <div className={'sub-ticket-forwarded'}>{'You have forwarded this to '}{forwardedTo}</div>}
    {showQrCord && <QRCode size={qrSize} value={qrValue({ registrationId, ticketKey: _itemKey, subTicketKey: _subTicketKey })} />}
  </div>

  let stcn = 'sub-ticket-container'
  if (entered) { stcn += ' entered' }
  if (assignedTo) { stcn += ' assigned' }

  switch (ticketType) {
    default:
      if ((subTicketSelected === subTicketKey)) {
        stcn += ' selected'
      }
      if (!forSend && !isWillCall && !showPresent && subTicketsSelected && subTicketsSelected.includes(subTicketKey)) {
        stcn += ' selectedd'
      }
  }

  const displayNormal = () => {
    return <div key={uniqueKey('sbt', ticketNumber)} className={stcn}
      onClick={() => { allowSelect && !forSend && ticketing_handlers && ticketing_handlers.handleSelectSubTicket({ subTicketTicket: ticket, subTicket, subTicketKey: _subTicketKey }) }}>
      {div_ticketIndex()}
      {div_ticketNumber()}
      {div_ticketAssigned()}
      {div_ticketIcon()}
    </div>
  }

  const displayWillCall = () => <Segment key={uniqueKey('sbt', ticketNumber)} className={willCallCn}>
    {div_ticketHeader()}
    {div_ticketQr()}
    {allowTicketForwarding && !showPresent && !entered && div_ticketForward()}
  </Segment>

  if (showLookup) {
    // return displayLookup()
    return displayWillCall()
  } else if (isWillCall || showPresent) {
    return displayWillCall()
  } else {
    switch (ticketType) {
      default:
        return displayNormal()
    }
  }
}

export default SubTicket