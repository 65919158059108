import _ from 'lodash';
import { fsfn_sheets } from '../functions/fbSheetsFuntions';


export const createVerificationCode = () => Math.floor(100000 + Math.random() * 900000).toString();

export function validateEmail(email) {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateTime(time) {
  var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(time);
  return isValid;
}

export const isItemReady = (itemProps, itemData) => {
  let itemIsReady = true
  Object.keys(itemProps).forEach(key => {
    if (itemIsReady) {
      const itemProp = itemProps[key]
      if (itemProp.data && itemProp.data.required) {
        if (!itemData[key]) {
          itemIsReady = false
        }
      }
    }
  })
  return itemIsReady
}

export const validatePropValues = (viewProps, formData) => {
  if (viewProps) {
    Object.keys(viewProps).forEach(vpKey => {
      const vp = viewProps[vpKey]
      if (formData && formData[vpKey]) {
        const { data: vpd } = vp
        if (vpd && vpd.camelCase) {
          formData[vpKey] = _.camelCase(formData[vpKey])
        }
      }
    })
  }
}

export const keysEqual = (key1, key2) => parseInt(key1) === parseInt(key2)

export function areObjectsEqual(obj1, obj2) {
  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate through the keys and compare values
  for (const key of keys1) {
    // Check if the key exists in both objects
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    // Check if the values are equal
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  // If all keys and values match, the objects are equal
  return true;
}

export const validateCollectionArrays = async (collectionsData) => {

  const invalidKeys = []

  const collectionKeyValues = {}
  const collectionKeys = Object.keys(collectionsData)

  Object.keys(collectionsData).forEach(collectionKey => {
    const collection = collectionsData[collectionKey]
    Object.keys(collection).forEach(itemKey => {
      const collectionItem = collectionsData[collectionKey]
      collectionKeyValues[collectionKey] = Object.keys(collectionItem);
    })
  })

  Object.keys(collectionsData).forEach(collectionKey => {
    const collection = collectionsData[collectionKey]
    Object.keys(collection).forEach(itemKey => {
      const collectionItem = collection[itemKey]
      Object.keys(collectionItem).forEach(propKey => {
        if (collectionKeys[propKey]) {
          const propValue = collectionItem[propKey]
          if (_.isArray(propValue)) {
            if (!_.intersection(collectionKeyValues[propKey], propValue)) {
              invalidKeys.push({
                collectionKey,
                itemKey,
                propKey,
                propValue
              })
            }
          }
        }
        const collectionItem = collectionsData[collectionKey]
      })
    })
  })
}

export const validateStaticArrays = async (staticViews, collectionData, callback) => {

  const staticDataCheck = {}

  const staticKeyValues = {}

  if (staticViews) {
    Object.keys(staticViews).forEach(staticKey => {
      const staticView = staticViews[staticKey]
      staticKeyValues[staticKey] = Object.keys(staticView);
    })
  }

  if (collectionData) {
    Object.keys(collectionData).forEach(itemKey => {
      const collectionItem = collectionData[itemKey]
      Object.keys(collectionItem).forEach(propKey => {
        if (staticKeyValues[propKey]) {
          const propValue = collectionItem[propKey]
          if (_.isArray(propValue)) {
            if (!staticDataCheck[propKey]) { staticDataCheck[propKey] = [] }
            if (!_.intersection(staticKeyValues[propKey], propValue)) {
              staticDataCheck[propKey].push({
                itemKey,
                propValue
              })
            }
          }
        }
      })
    })
  }

  callback(staticDataCheck)

}


export const createGoogleSheetKeys = async (googleSheetsData, collectionsData) => {

  // console.log('collectionsData', collectionsData)

  const sheetName = 'Departments'
  const _sheetName = _.camelCase(sheetName)

  // Object.keys(googleSheetsData).forEach(collectionKey => {
  //   const googleData = googleSheetsData[collectionKey]
  //   const collectionData = collectionsData[collectionKey]
  //   Object.keys(googleData).forEach(googleDataKey => {
  //     const googleDataItem = googleData[googleDataKey]
  //     const collectionDataItem = collectionData[googleDataKey]
  //     if (googleDataItem && collectionDataItem) {
  //       googleDataItem._fsKey = googleDataKey
  //     }
  //   })
  // })

  const updateFetchResults = (gsd) => {
    if (collectionsData[_sheetName]) {
      const collectionDataItem = collectionsData[_sheetName]
      Object.keys(gsd).forEach(diKey => {
        const gsdItem = gsd[diKey]
        const { _gsid } = gsdItem ?? {}
        const cdiKey = _.findKey(collectionDataItem, { _gsid: _gsid })
        if (cdiKey) {
          gsdItem._fsKey = cdiKey
        }
      })
    }
    console.log('gsd', gsd)
  }

  const formData = { googleSheetsId: '1kzxTk8UIaf7o9AlKggmykOBvb3pIOp9dyjdU48hNr2g', sheetName: 'Departments' }
  fsfn_sheets.getGoogleSheetRange(formData, updateFetchResults)

  // console.log('googleSheetsData', googleSheetsData)
}
