import _ from 'lodash';
import React from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import GenericIconButton from '../../genericControls/GenericIconButton';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';

/** Returns a Segment (title, content, footer) */
/**
 * This is only called from ViewSettingsComponent
 * @param {*} title 
 * @param {*} content 
 * @param {*} buttons 
 * @param {*} opts 
 * @param {*} footer 
 * @param {*} dd 
 * @param {*} unGroupedButtons 
 * @param {*} trueButtons 
 * @param {*} ccn 
 * @returns a Segment (title, content, footer) */
const SegItemWrap = (title, content, buttons, opts, footer, dd, unGroupedButtons, trueButtons, ccn) => {

  const { backButton, noSave, dataProps, toggle } = opts ?? {}

  const icon = (button) => <Icon bordered name={button.icon} size={button.size} onClick={() => { button.onClick(dataProps) }} />

  const buttonsRight = (buttons) => {
    if (buttons) {
      const bs = buttons.map((button, index) => {
        if (button.icon) {
          return <GenericIconButton button={button} index={index} clickOptions={dataProps} />
        } else {
          return <Button
            className={button.className}
            primary={button.primary}
            key={uniqueKey('siw', 'br', index)}
            disabled={!button.ready}
            color={button.color}
            icon={button.icon}
            inverted={true}
            onClick={() => { button.onClick(dataProps) }}>{button.caption}
          </Button>
        }
      })
      if (unGroupedButtons) {
        return bs
      } else {
        return <Button.Group size='small'>
          {bs}
        </Button.Group>
      }
    }
  }

  const header = () => {
    const bb = (backButton) ? icon(backButton) : null
    let brs = (buttons && !trueButtons && _.isArray(buttons)) ? buttonsRight(buttons) : null
    if (trueButtons && buttons) { brs = buttons }

    const w = { t: 16, tog: 2, bb: 2, br: 6, }

    // bck button
    if (bb) { w.t = w.t - w.bb }

    if (brs) {
      w.t = w.t - w.br
      if (toggle) { w.t = w.t - w.tog }
    }

    const gcls = []

    bb && gcls.push(<Grid.Column key={uniqueKey('siw', 1)} width={w.bb} textAlign='right'>{bb}</Grid.Column>)
    gcls.push(<Grid.Column key={uniqueKey('siw', 2)} width={w.t}>{title}</Grid.Column>)
    toggle && gcls.push(<Grid.Column key={uniqueKey('siw', 3)} width={w.tog} textAlign='right'>{toggle}</Grid.Column>)
    !noSave && brs && gcls.push(<Grid.Column key={uniqueKey('siw', 4)} width={w.br} textAlign='right'> {brs}</Grid.Column>)

    return title

  }

  const wrapper = () => {
    const { flexNamePlus } = opts ?? {}
    let contentCn = (flexNamePlus) ? ' ' + flexNamePlus : ''
    return <Wrapper
      header={title && header()}
      content={content}
      footer={footer}
      wrapperType={footer ? wrapperTypes.paddedFooter : null}
      css={{ container: ccn, content: contentCn }}
    />
  }

  return wrapper()

}

export default SegItemWrap