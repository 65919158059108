// AnimatedWrapper.js
import React from 'react';
import { motion } from 'framer-motion';

const transitionWrapperTypes = {
  fade: 'fade',
  slide: 'slide',
  scale: 'scale',
  rotate: 'rotate',
  scaleAndFade: 'scaleAndFade',
  simpleFade: 'simpleFade',
  whiteToBlack: 'whiteToBlack',
  default: 'default', // Add more transition types as needed
}

const TransitionWrapper = ({ children, transitionWrapperType = transitionWrapperTypes.fade, useStyle = true }) => {

  // <div className='parent'>
  //   <div className='scroller'>
  //     <div className='scroller'>
  //       Scroll This
  //     </div>
  //     <div className='scroller'>
  //       Scroll This
  //     </div>
  //   </div>
  // </div>

  const _defaults = {
    duration: 0.2,
    scale: .9,
    opacity: .9,
    animate: '2%'
  }

  const getTransition = () => {
    switch (transitionWrapperType) {
      case transitionWrapperTypes.slide:
        return {
          initial: { x: '2%' },
          animate: { x: 0 },
          exit: { x: '2%' },
          transition: { duration: 0.5 } // Add a default duration
        };
      case transitionWrapperTypes.scale:
        return {
          initial: { scale: _defaults.scale },
          animate: { scale: 1 },
          exit: { scale: 0 },
          transition: { duration: 0.5 }
        };
      case transitionWrapperTypes.rotate:
        return {
          initial: { rotate: -180 },
          animate: { rotate: 0 },
          exit: { rotate: 180 },
          transition: { duration: 0.5 }
        };
      case transitionWrapperTypes.scaleAndFade:
        return {
          initial: { scale: _defaults.scale, opacity: 0 },
          animate: { scale: 1, opacity: 1 },
          exit: { scale: 0, opacity: 0 },
          transition: { duration: 0.5 }
        };
      case transitionWrapperTypes.whiteToBlack:
        return {
          initial: { backgroundColor: 'white' },
          animate: { backgroundColor: 'black' },
          exit: { backgroundColor: 'white' },
          transition: { duration: 1 } // Example duration
        };
      case transitionWrapperTypes.simpleFade:
        return {
          initial: { opacity: 0 },
          animate: { opacity: 1 },
          exit: { opacity: 0 }, // Add exit to ensure a smooth fade-out
          transition: { duration: .2 }
        };
      default:
        return {
          initial: { opacity: _defaults.opacity },
          animate: { opacity: 1 },
          exit: { opacity: 0 },
          transition: { duration: 0.5 }
        };
    }
  };

  const transition = getTransition();

  return (
    <motion.div
      initial={transition.initial} // Pass initial state
      animate={transition.animate} // Pass animate state
      exit={transition.exit} // Pass exit state (if necessary)
      transition={transition.transition} // Use the transition duration
      className={'transition-wrapper ova'}
    >
      {children}
    </motion.div >
  );
};

export { transitionWrapperTypes };
export default TransitionWrapper;
