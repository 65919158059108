import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { fsfn_auth } from '../../../../global/functions/fbAuth';
import { golfEventHandlers, golfEventInitialState, golfEventReducer } from '../reducers/GolfEventReducer';

const _diffRecentDates = 3

export const GolfEventContext = createContext();

/**
 * @provides tournamentTeams 
 * @provides golfCourse  
 * @provides holes 
 * @provides golfMatchData  
 * @provides roundsStatus 
 * @provides golfEvent_handlers 
 * @provides golfTeam1
 * @provides golfTeam2
 * @provides golfTeam1Style
 * @provides golfTeam2Style
 */

const GolfEventProvider = (props) => {

  const { directReturn } = props ?? {}

  const parentContext = useContext(ParentContext)
  const { states, fns } = parentContext ?? {}
  const { appUser_state, paps_state } = states
  const { page_fns } = fns

  // papsContext
  const { appUser } = appUser_state ?? {}
  const { appUserAccess } = appUser ?? {}
  const { isSuperAdminDeveloper } = appUserAccess ?? {}

  // papsContext
  const { viewKey, pathViews } = paps_state ?? {}

  // pageContext  
  const { pushSimple } = page_fns ?? {}

  // golfEventReducer
  const init_state = { paps_state, pushSimple, viewKey }
  const [golfEvent_state, golfEvent_dispatch] = useReducer(golfEventReducer, init_state, golfEventInitialState)
  const golfEvent_handlers = golfEventHandlers(golfEvent_dispatch, golfEvent_state)
  const { golfCourses, golfers } = golfEvent_state

  useEffect(() => {
    golfEvent_handlers.handleGet_clientSubData()
    golfEvent_handlers.handleGet_eventSubData()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const daysDiff = (timestamp) => {
    if (!timestamp) {
      return false; // No timestamp available
    }
    const currentDate = new Date();
    const updateDate = new Date(timestamp);
    const timeDifference = currentDate - updateDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    return daysDifference
  };

  useEffect(() => {
    if (isSuperAdminDeveloper && golfers) {
      const cb_auths = (golfersWithPhone) => {
        const { found: golfers_found } = golfersWithPhone ? golfersWithPhone : {}
        // golfEvent_handlers.handleSet_golferAuths(d)
        const _golfers = {}
        _.forEach(Object.values(golfers), golfer => {
          const matchingGolfer = _.find(golfers_found, { phoneNumber: golfer.phoneNumber });
          if (matchingGolfer && matchingGolfer.metadata) {
            const { lastSignInTime, creationTime, lastRefreshTime } = matchingGolfer.metadata
            const _lrt = daysDiff(lastRefreshTime)
            _golfers[golfer._itemKey] = {
              lastSignInTime: daysDiff(lastSignInTime),
              creationTime: daysDiff(creationTime),
              lastRefreshTime: _lrt,
              isRecent: _lrt <= _diffRecentDates ? true : false
            }
          }
        });
        golfEvent_handlers.handleAmmend_eventGolfers(_golfers)
      }
      const _golfersWithPhones = _.filter(golfers, golfer => !_.isEmpty(golfer.phoneNumber));
      const _sau = _.sortBy(_golfersWithPhones, 'lastName')
      const chunks = _.chunk(_sau, 100);
      fsfn_auth.getAuthsByChunkEmail(chunks, pathViews, true, cb_auths)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [golfers]);

  if (golfCourses && golfers) {
    if (directReturn) {
      return { golfEvent_handlers, golfEvent_state }
    } else {
      return <GolfEventContext.Provider value={{ golfEvent_handlers, golfEvent_state }}>
        {props.children}
      </GolfEventContext.Provider>
    }
  } else {
    if (directReturn) {
      return { golfEvent_handlers }
    } else {
      return <GolfEventContext.Provider value={{ golfEvent_handlers }}>
        {props.children}
      </GolfEventContext.Provider>
    }
  }
};

export default GolfEventProvider 