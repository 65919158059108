import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";

const rts = {
  updateGs_dataSheet: 'updateGs_dataSheet',
  ...grts,
}

export const googleScriptsInitialState = (initState) => {
  return { ...initState, googleUpdateStatus: {} }
};

export const googleScriptsReducer = (state, action) => {

  const { type, dispatch } = action

  switch (type) {

    case rts.updateGs_dataSheet:
      return { ...state }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action,
        {
          dispatch,
          dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation,
          questionProps: null,
          updatingCaption: 'Creating',
        })

    default:
      return state;
  }
}

export const googleScriptsHandlers = (dispatch) => {
  return {
    updateGs_dataSheet: (dcUpdates, done) => { dispatch({ type: rts.updateGs_dataSheet, dispatch, dcUpdates, done }) },
    ...responseHandlers(dispatch)
  }
}