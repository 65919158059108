import React, { useContext, useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';

const MatchFilter = () => {

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext
  const { gameAllowTies } = sportsSeason_state ?? {}
  const { handleFilter_sports } = sportsSeason_handlers ?? {}


  // createSportsContext
  const seasonMatchesContext = useContext(SeasonMatchesContext);
  const { seasonMatches_state, seasonMatches_handlers } = seasonMatchesContext ?? {}
  const { selectedOpponent: selectedOpponent_team } = seasonMatches_state ?? {}
  const { handleFilter_matches } = seasonMatches_handlers ?? {}

  const [selectedLocation, setSelectedLocation] = useState()
  const [selectedResult, setSelectedResult] = useState()
  const [selectedSection, setSelectedSection] = useState()

  const handleSelectedLocation = (location) => setSelectedLocation(selectedLocation === location ? null : location)
  const handleSelectedResult = (result) => setSelectedResult(selectedResult === result ? null : result)
  const handleSelectedSection = (section) => setSelectedSection(selectedSection === section ? null : section)
  const handleClear = () => handleFilter_matches({ selectedOpponent: null })
  const handleShowCalendar = () => seasonMatches_handlers.handleShow_matches('teamCalendar', true)

  useEffect(() => {
    handleFilter_matches && handleFilter_matches({ selectedLocation, selectedResult, selectedSection })
    // handleFilter_sports && handleFilter_sports({ selectedLocation, selectedResult, selectedSection })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedLocation, selectedResult, selectedSection]);

  const button = (key, selected, text, oc) => <Button compact className={selected ? 'btn-sel' : null} color={selected ? 'blue' : 'black'} onClick={(e) => { oc(key) }}>{text}</Button>
  const icon = () => <Icon color={'blue'} name={'calendar alternate outline'} bordered title={'Show Calendar'} onClick={() => { handleShowCalendar() }} />

  const buttons_location = () => <div><Button.Group size={'mini'}>
    {button('away', (selectedLocation === 'away' ? true : false), 'Away', handleSelectedLocation)}
    {button('home', (selectedLocation === 'home' ? true : false), 'Home', handleSelectedLocation)}
  </Button.Group></div>

  const buttons_result = () => <div><Button.Group size={'mini'}>
    {button('win', (selectedResult === 'win' ? true : false), 'Wins', handleSelectedResult)}
    {button('loss', (selectedResult === 'loss' ? true : false), 'Losses', handleSelectedResult)}
    {gameAllowTies && button('tie', (selectedResult === 'tie' ? true : false), 'Ties', handleSelectedResult)}
  </Button.Group></div>

  const buttons_section = () => <div><Button.Group size={'mini'}>
    {button('section', (selectedSection === 'section' ? true : false), 'Section', handleSelectedSection)}
    {button('non', (selectedSection === 'non' ? true : false), 'Non', handleSelectedSection)}
  </Button.Group></div>

  const buttons_clear = () => <div><Button.Group size={'mini'}>
    {button('clear', true, 'Clear Opponent', handleClear)}
  </Button.Group></div>

  const buttonHeader = () => <div className={'match-buttons'}>
    {buttons_location()}
    {buttons_result()}
    {buttons_section()}
    {icon()}
  </div>

  const buttonHeader_clear = () => <div className={'match-buttons clear'}>
    {buttons_clear()}
  </div>

  return selectedOpponent_team ? buttonHeader_clear() : buttonHeader()
}

export default MatchFilter