import React from 'react';
import { Dimmer, Header, Icon, Loader, Segment } from 'semantic-ui-react';
import { touchHandlers } from '../../touch/touch';

const Pending = (viewItem, data, height, ti, altCaption, splashOn, displayProps) => {

  const { touch } = displayProps ?? {}
  const { display_viewItem } = viewItem ?? {}
  let { caption, defaultIcon } = display_viewItem ?? {}
  if (!caption) { caption = 'Item' }

  defaultIcon = defaultIcon ? defaultIcon : 'question circle outline'
  caption = caption ? caption : altCaption
  if (!caption) { caption = '---' }

  if (!data) {
    return <Segment className={'pending'}>
      <Dimmer active inverted >
        <Loader color='blue' size='massive' />
      </Dimmer>
      {/* {placeholders()} */}
    </Segment>
  } else {
    return <div className='seg-pending'>
      {touch && <Segment className='seg-zero pending-small' placeholder {...touchHandlers(null, touch)}>
        <Header icon>
          <Icon name={defaultIcon} />
          {'No ' + caption + ' found'}
        </Header>
      </Segment>}
      {!touch && <Segment className='seg-zero pending-small' placeholder >
        <Header icon>
          <Icon name={defaultIcon} />
          {'No ' + caption + ' found'}
        </Header>
      </Segment>}
      {ti}
    </div>
  }
}

export default Pending