import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";
import _ from "lodash";
import { isMeProd } from '../../../project/appConfiguration';
import { convertSnapshot } from '../../cnr/contexts/contextHelpers';
import { gEnums } from "../../enums/globalEnums";
import { createRefPath } from "../../firestoreData/appData/appRefPaths";
import { ref_get } from "../../firestoreData/appData/fsRefs";
import { defaultSettings } from '../defaults/defaultSettings';
import { fs_db } from "../../firestoreData/appData/fsAppData";

export const rootSettings_collection_sandbox = 'settings_sandbox'
export const rootSettings_collection_backup = 'settings_backup'
export const settings_global_pageItems = 'settings_global_pageItems'
export const _settingsFs = {
  root: 'settings',
  collection: 'settings',
  googleCollection: 'settings_gss'
}

const _allowListen = true

/**
 * Gets all the settings documents from the `settings` documents for each of the `settingsDocKeys`.
 * @param {object} apsaProps 
 */
export const getHCEAppSettings_simple = async (database_fns, settingsDocKeys, callback_init, callback_listen) => {

  try {
    fetchAndListenToSettings(database_fns, settingsDocKeys, callback_init, callback_listen)
    // const res2 = await getAppSettingsPromise(database_fns, settingsDocKeys)
    // console.log('initialSettings', initialSettings)
    // callback_init(initialSettings)
    // if (res) {
    //   const settings = {}
    //   _.forEach(res, (r) => {
    //     if (!r.empty) {
    //       settings[r.id] = convertSnapshot(r, true, { returnFirstObject: true })
    //     }
    //   })
    //   callback(settings)
    // } else {
    //   callback({})
    // }
  } catch (error) {
    console.error("Error fetching settings:", error);
    callback_init({})
  }

}

const fetchAndListenToSettings = async (database_fns, settingsDocKeys, callback_listen) => {
  try {
    const db = database_fns.get_fs();
    // Create promises array to fetch all initial settings
    _.map(settingsDocKeys, async (docKey, key) => {
      const docRef = doc(db, 'settings', docKey);
      onSnapshot(docRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const settings = convertSnapshot(docSnapshot, true, { returnFirstObject: true });
          callback_listen({ key: docSnapshot.id, data: settings })
        }
      });
    });
  } catch (error) {
    console.error('Error fetching settings:', error);
    // dispatch({ type: types.ERROR, payload: error });
  }
};

/**
 * 
 * @param {object} settingsDocKeys 
 * @returns a promise from the fetching of the setting of each of the settingsDocKeys
 */
export const getAppSettingsPromise = (database_fns, settingsDocKeys) => {
  const fs = fs_db.get_fs()
  const promises = []
  _.forEach(settingsDocKeys, (settingsDocKey) => {
    // const sdn = settingsDocKeys[sdnKey]
    const dr = ref_get(fs, [_settingsFs.root, settingsDocKey])
    promises.push(getDoc(dr))
  })
  return Promise.all(promises)
}

/**
 * Gets the settings data from the settings collection 
 * @param {object} apsProps (allowSettingsListen, backupOnly, settingsDocName, userSettingsOnly)
 */
export const getAppSettingsFromDb = (database_fns, apsProps) => {

  const {
    allowSettingsListen,
    backupOnly,
    settingsDocName,
    userSettingsOnly,
    status,
  } = apsProps

  let settingsDocRef;
  let _refPath;

  const _ammendProps = { docData: {}, apsProps, status }

  if (backupOnly) {
    _refPath = createRefPath([rootSettings_collection_backup, settingsDocName])
  } else if (userSettingsOnly) {
    _refPath = createRefPath([rootSettings_collection_sandbox, settingsDocName])
  } else {
    _refPath = createRefPath([_settingsFs.root, settingsDocName])
  }

  const _isMeProd = isMeProd()

  /**
   * This is the callback for the getSettingsGroup call
   * @param {object} data 
   * @param {string} settingsDocName 
   */
  const callback_new = (cbd, sdn, fromCache) => {
    const { data, error } = cbd
    if (!data) {
      console.log('callback_new NO DATA')
    }
    if (data && !data.global) {
      _ammendProps.docData = defaultSettings
      _ammendProps.error = error
      ammendAppSettings(_ammendProps)
    } else {
      if (data && status) { data.status = status }
      _ammendProps.docData = data
      _ammendProps.error = error
      ammendAppSettings(_ammendProps)
    }
  }

  const callback_alt = (cbd) => {
    const { settings, status, error } = cbd
    if (!settings.global) {
      _ammendProps.docData = defaultSettings ?? {}
      _ammendProps.error = error
      ammendAppSettings(_ammendProps)
    } else {
      if (status) { settings.status = status }
      _ammendProps.docData = settings ?? {}
      _ammendProps.error = error
      ammendAppSettings(_ammendProps)
    }
  }

  if (_isMeProd && !allowSettingsListen && 1 === 3) {
    settingsDocRef.get().then(res => {
      if (!res.empty) {
        const cc = convertSnapshot(res, true, { returnFirstObject: true })
        _ammendProps.docData = cc
        ammendAppSettings(_ammendProps)
      } else {
        ammendAppSettings(_ammendProps)
      }
    })
  } else {
    getSettingsAndStatus(database_fns, settingsDocName, false, _allowListen ? callback_new : callback_alt)
  }
}

/**
 * Gets the settings from the database for the `settingsDocName`
 * @param {string} settingsDocName 
 * @param {function} callback 
 */
const getSettingsAndStatus = async (database_fns, settingsDocName, listen, callback) => {

  const rsc = 'settings'

  const dr_settings = createRefPath([rsc, settingsDocName, 'settings'])
  const opts_settings = { listen, ignoreId: true, cbo: true }

  if (_allowListen) {
    database_fns.get_data({ refPath: dr_settings, opts: opts_settings, callback })
  } else {
    try {
      const settings = await database_fns.get_data({ refPath: dr_settings, opts: opts_settings })
      const s = { settings }
      callback(s, settingsDocName)
    } catch (error) {
      console.log('error', error)
    }
  }
}

/**
* 
* @param {object} docData 
* @param {object} apsProps 
* @param {string} defaultView 
* @param {array} defaultViews 
* @param {array} defaultViewItems 
* @returns The ammended appSettings
*/
export const ammendAppSettings = (ammendProps) => {

  const {
    docData,
    apsProps,
    defaultView,
    defaultViews,
    defaultViewItems,
    settingsSourceType,
    settingsOriginalSourceType,
    settingsInSync,
    error,
    status,
  } = ammendProps ?? {}

  const {
    manifestIcons,
    pathViews,
    pathname,
    previewOnly,
    settingsDocName,
    settingsDocKey,
    settingsDocKeys,
    callback,
    userSettingsOnly,
    ignoreAppUpdate,
  } = apsProps

  const { global, views } = docData ?? {}
  let { viewItems } = docData ?? {}

  const useDefault = false

  createAppComponents(global)

  const x = {
    global: global ? setFullViewSettings(global, defaultView) : {},
    viewItems: viewItems ?? {},
    views: views ? setFullViewsSettings(views, true) : {},
    missingSettings: global ? false : true,
    status,
    error
  }

  if (useDefault) {
    x.viewItems = defaultViewItems
    x.views = defaultViews
  }

  if (previewOnly) {
    const preview = {
      global: x.global,
      viewItems: x.viewItems,
      views: x.views
    }
    const previewData = {
      projectSettings: {
        global: preview.global,
        viewItems: preview.viewItems,
        views: preview.views
      }
    }
    !ignoreAppUpdate && callback({ type: 'showPreview', previewInfo: previewData });
    return previewData
  } else {
    x.isGlobalStatic = global ? false : true
    x.settingsDocName = settingsDocName
    x.settingsDocKey = settingsDocKey
    x.manifestIcons = manifestIcons
    x.showSplash = true
    x.time_stamp = Date.now()
    x.isUserSettings = userSettingsOnly
    x.settingsSourceType = settingsSourceType
    x.settingsOriginalSourceType = settingsOriginalSourceType
    x.settingsInSync = settingsInSync
    // settings, pathViews, settingsDocKeys, pathname 
    !ignoreAppUpdate && callback(x, pathViews, null, pathname)
    return x
  }
}

// IMPORTANT: Settings - setFullViewSettings
const setFullViewSettings = (settings) => settings ?? {}

const setFullViewsSettings = (views, ignoreThisView) => {

  const avs = {}

  if (views) {
    Object.keys(views).forEach(key => {
      const viewSetting = views[key]
      let view = null
      if (ignoreThisView) {
        view = viewSetting ?? {}
      } else {
        view = viewSetting ?? {}
      }
      if (view.viewItems) {
        Object.keys(view.viewItems).forEach(key => {
          const vi = view.viewItems[key]
          createDataSource(vi)
        })
      }
      avs[key] = view
    })
  } else {
    const thisStaticView = {}
    avs[thisStaticView.viewType] = thisStaticView
    avs[thisStaticView.viewType].isViewStatic = true
  }
  return avs
}

const createAppComponents = (global) => {
  if (global && global.appSettings && global.appSettings.useComponentContent && !global.appComponents) {
    global.appComponents = {
      useComponentContent: global.appSettings.useComponentContent,
      componentContent: global.appSettings.componentContent
    }
    global.appComponents.fetchCollectionList = null
  }

  if (global && global.appComponents) {
    delete global.appComponents.fetchCollectionList
  }
}

const createDataSource = (viewItem) => {

  const { key: key_viewItem, pageItemData, dataConstraints } = viewItem ?? {}

  if (viewItem && _.isObject(viewItem)) {

    if (!viewItem.dataSource) {

      viewItem.dataSource = {}

      if (!viewItem.dataSource.dataSourceType) { viewItem.dataSource.dataSourceType = gEnums.dataSourceTypes.firebase }
      viewItem.dataSource.dataCollectionName = key_viewItem

      if (pageItemData) {

        const {
          addParentKeysToData,
          dataCollectionName,
          dataLinkType,
          dataParents,
          docViewItemProp,
          ignorePathViewNulls,
          pageDataCollection,
          subDataCollection,
          subDataGroup,
          subDataName,
          useSeasonals,
          useSubDataCollection,
        } = pageItemData

        viewItem.dataSource.pageConstraintType = gEnums.pageConstraintTypes.none

        switch (dataLinkType) {
          case 'component':
            viewItem.dataSource.dataSourceType = gEnums.dataSourceTypes.component
            break;
          case 'docGroup':
            viewItem.dataSource.pageConstraintType = gEnums.pageConstraintTypes.arrayContains
            break;
          case 'docLink':
            viewItem.dataSource.pageConstraintType = gEnums.pageConstraintTypes.equalTo
            break;
          case 'docPropLink':
            viewItem.dataSource.pageConstraintType = gEnums.pageConstraintTypes.propEqualTo
            viewItem.dataSource.pageConstraintTypeProp = docViewItemProp
            break;
          default:
        }

        if (subDataGroup) { console.log('subDataGroup', subDataGroup) }

        if (dataCollectionName) { viewItem.dataSource.altDataCollectionName = dataCollectionName }

        if (dataParents) {
          viewItem.dataSource.dataParents = dataParents
          if (useSubDataCollection) {
            viewItem.dataSource.pageConstraintType = gEnums.pageConstraintTypes.dataParentKeys
          }
        }

        if (useSubDataCollection) {
          viewItem.dataSource.useSubDataCollection = useSubDataCollection
          viewItem.dataSource.subDataCollectionName = subDataCollection
        }

        if (addParentKeysToData) { viewItem.dataSource.addParentKeysToData = addParentKeysToData }
        if (ignorePathViewNulls) { viewItem.dataSource.ignorePathViewNulls = ignorePathViewNulls }
        if (useSeasonals) { viewItem.dataSource.useSeasonals = useSeasonals }
        if (subDataName) { viewItem.dataSource.subDataFieldName = subDataName }
        if (subDataGroup) { viewItem.dataSource.subDataGroup = subDataGroup }

        if (pageDataCollection) {
          viewItem.dataSource.pageDataCollection = pageDataCollection
          viewItem.dataSource.dataSourceType = gEnums.dataSourceTypes.pageDataCollection
        }

      }

      if (dataConstraints) {
        const {
          ignoreConstraintsAdminLevel,
          dataConstraintItems,
          appUserIgnoreProp,
          dataIgnoreValues,
          ignoreConstraintsAppUserList,
          ignoreConstraintsList,
        } = dataConstraints

        if (dataConstraintItems) {
          viewItem.dataSource.useDataConstraints = dataConstraintItems ? true : false
          viewItem.dataSource.dataConstraintItems = dataConstraintItems
        }

        if (ignoreConstraintsAdminLevel) { viewItem.dataSource.ignoreConstraintsAdminLevel = ignoreConstraintsAdminLevel }
        if (appUserIgnoreProp) { viewItem.dataSource.appUserIgnoreProp = appUserIgnoreProp }
        if (dataIgnoreValues) { viewItem.dataSource.dataIgnoreValues = dataIgnoreValues }
        if (ignoreConstraintsAppUserList) { viewItem.dataSource.ignoreConstraintsAppUserList = ignoreConstraintsAppUserList }
        if (ignoreConstraintsList) { viewItem.dataSource.ignoreConstraintsList = ignoreConstraintsList }

        if (ignoreConstraintsAdminLevel || appUserIgnoreProp || dataIgnoreValues || ignoreConstraintsAppUserList || ignoreConstraintsList) {
          viewItem.dataSource.allowConstraintOverrides = true
        }
      }

      delete viewItem.pageItemData
      delete viewItem.dataConstraints
    }
  }

}