import _ from 'lodash';
import { dispatchProps } from "../../../cnr/reducers/reducerHelpers/dispatchProps";
import { convertHelpers } from "../../../common/convert";
import { createRefPath } from "../../appData/appRefPaths";
import { fs_dbu } from "../../appData/fsAppDataUpdate";
import { updateDocumentHelpers } from './fsUpdate_document';

// IMPORTANT: Delete document
/** Deletes a document or documents from a collection */
/**
 * Amends a single field (amendType) to each item the selectedItems
 * @param {object} props 
 * @returns 
 */
export const fsUpdate_updateCollection = (props, singleProp) => {

  const {
    baseRef,
    dispatch,
    pathViews,
    ufProps,
  } = props

  const { refPath } = baseRef
  let { vit, selectedItems, ammendType, dataRestrictions, appDataSource } = ufProps ?? {}
  const { useAppDataDocuments, updateAppDataDocuments } = appDataSource ?? {}

  let _updateAppDataDocuments = updateAppDataDocuments || useAppDataDocuments

  let _selectedItems;

  if (_.isArray(selectedItems)) {
    _selectedItems = convertHelpers.createObjectFromArray(selectedItems)
  } else {
    _selectedItems = selectedItems
  }

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.ammendData)) {
    // Object.keys(vld).forEach(itemKey => {
    //   const _d = { [ammendType]: selectedItems && selectedItems[itemKey] ? true : false }
    // })
    dispatch({ type: dispatchProps.successAlt, dispatch })
    return false
  }

  if (_selectedItems) {
    if (_updateAppDataDocuments) {
      updateDocumentHelpers.update_itemsInDocument(pathViews, vit, _selectedItems)
    }
    ammendPromise(singleProp, refPath, vit, _selectedItems, ammendType).then(() => {
      dispatch({ type: dispatchProps.success, dispatch })
    }).catch(err => {
      dispatch({ type: dispatchProps.error, dispatch })
    });
  }
}

/**
 * Updates each of the dataItems
 * @param {*} singleProp 
 * @param {*} refPath 
 * @param {*} vit 
 * @param {*} data 
 * @param {*} ammendType 
 * @returns 
 */
const ammendPromise = async (singleProp, refPath, vit, data, ammendType) => {
  const promises = []
  Object.keys(data).forEach(itemKey => {
    const dataItem = data[itemKey]
    const _d = singleProp ? { [ammendType]: true } : dataItem
    const _updateRef = createRefPath([vit, itemKey], refPath)
    const r = fs_dbu.doc_update(_updateRef, _d)
    promises.push(r)
  })
  return Promise.all(promises)
}