import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Label, Segment } from 'semantic-ui-react';
import AppUserList from '../appUsers/AppUserList';

const TargetConfirmation = (props) => {

  const {
    topicGroup,
    appProfileData,
    topicName,
    selectedTopicGroups,
    selectedAppUsers,
    notificationRecipients,
  } = props ?? {}

  // local state 
  const [targetGroups, setTargetGroups] = useState([])

  useEffect(() => {
    if (topicGroup && selectedAppUsers && selectedAppUsers.length > 0) {
      const targetGroups = []
      const targets = []
      selectedAppUsers.forEach(sau => {
        if (topicGroup[sau]) {
          targets[sau] = topicGroup[sau]
        }
      })
      targetGroups.push({ groupKey: topicName, targets })
      setTargetGroups(targetGroups)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (selectedTopicGroups && Object.keys(selectedTopicGroups).length > 0) {
      const targetGroups = []
      Object.keys(selectedTopicGroups).forEach(key => {
        let targets = [];
        const selectedTopicGroup = selectedTopicGroups[key]
        const { groupKey, itemKey } = selectedTopicGroup
        if (groupKey) {
          if (appProfileData && appProfileData[groupKey]) {
            if (itemKey) {
              if (appProfileData[groupKey][itemKey] && appProfileData[groupKey][itemKey].targets) {
                targets = appProfileData[groupKey][itemKey] && appProfileData[groupKey][itemKey].targets
              }
            } else {
              targets = appProfileData[groupKey]
            }
          }
        }
        targetGroups.push({ groupKey, itemKey, targets })
      })
      setTargetGroups(targetGroups)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const getTokenedItems = (targetGroup) => {
    const { targets } = targetGroup
    const found = []
    const notFound = []
    if (targets && Object.keys(targets).length > 0) {
      Object.keys(targets).forEach(key => {
        const target = targets[key]
        const { fcmTokens } = target ?? {}
        if (fcmTokens) {
          found.push(target)
        } else {
          notFound.push(target)
        }
      })
    }
    return { found, notFound }
  }

  const segments = () => {
    const segs = []
    targetGroups.forEach(targetGroup => {
      const { found, notFound } = getTokenedItems(targetGroup)
      const { groupKey, itemKey } = targetGroup
      segs.push(<Segment.Group>
        <Segment>
          <Label color={'green'} size={'tiny'}>{_.startCase(groupKey)}</Label>
          {itemKey && <Label color={'blue'} size={'tiny'}>{_.startCase(itemKey)}</Label>}
        </Segment>
        <AppUserList dataItems={found} showFound={true} found={true} />
        <AppUserList dataItems={notFound} showFound={true} found={false} />
        <AppUserList dataItems={notificationRecipients} />
      </Segment.Group>)
    })
    return segs
  }

  const content = () => {
    if (targetGroups && targetGroups.length > 0) {
      return segments()
    } else if (notificationRecipients && Object.keys(notificationRecipients).length > 0) {
      return <AppUserList items={notificationRecipients} listOnly={true} />
    } else {
      return <div></div>
    }
  }

  return content()

}

export default TargetConfirmation