import _ from 'lodash';

export const ammendRankingsFromGs = (rankings_gs) => {

  const column_week = 'week'
  const _rankings = {}

  if (rankings_gs) {

    const _rankings_levels = _.groupBy(rankings_gs, 'level')
    if (_rankings_levels) {
      Object.keys(_rankings_levels).forEach(level => {
        const _rankings_level = _rankings_levels[level]
        if (_rankings_level) {
          for (var weekNumber = 1; weekNumber < 12; weekNumber++) {
            const _weekNumber = column_week + weekNumber
            const _weekKey = 'Week ' + weekNumber
            const _teams_week = []

            _rankings_level.forEach(rl => {
              if (rl[_weekNumber]) {
                _teams_week.push(rl[_weekNumber])
              }
            })

            if (_teams_week.length > 0) {
              if (!_rankings[_weekKey]) { _rankings[_weekKey] = {} }
              if (!_rankings[_weekKey][level]) { _rankings[_weekKey][level] = {} }
              _rankings[_weekKey][level] = _teams_week
            }
          }
        }
      })
    }
  }

  return _rankings
}