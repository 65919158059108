import React, { useContext, useEffect } from 'react';
import { AppComponentContext } from '../../../../global/cnr/contexts/AppComponentContext';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import JsonViewer from '../../../../global/components/viewers/JsonViewer';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsDistrictContext } from '../../cnr/contexts/SportsDistrictContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';

const NonSeasonalData = (props) => {

  const { fromDashboard, handleCancel, sportsKey, currentItem, currentItemKey } = props
  const { subCollection } = currentItem ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { pathViews } = paps_state ?? {}

  // sportsContext
  const sportsContext = useContext(AppComponentContext)
  const { sports_state } = sportsContext ?? {}
  const { sports: sports_all } = sports_state ?? {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state, sportsDistrict_handlers } = sportsDistrictContext ?? {}
  const { sportSeasonData, sportSeasonMatches, updating, nonSeasonalDatas, seasonalDataType } = sportsDistrict_state ?? {}
  const { sport, year } = sportSeasonData ?? {}
  const _nonSeasonalData = nonSeasonalDatas ? nonSeasonalDatas[seasonalDataType] : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { parentDataKeys } = sportsSeason_state ?? {}

  const _sportAndYear = sport + ' ' + year
  const _isSport = pathViews && pathViews.sports ? true : false

  // get the _matches_seasonal data
  useEffect(() => {
    if (currentItemKey) {
      let itemKey;
      if (_isSport) {
        const _currentItemKey = currentItemKey.substring(1)
        if (parentDataKeys && parentDataKeys[_currentItemKey]) {
          itemKey = parentDataKeys[_currentItemKey]
        }
      }
      if (nonSeasonalDatas && nonSeasonalDatas[currentItemKey]) {
        sportsDistrict_handlers.handleSet_districtSeasonalDataExisting(currentItemKey)
      } else {
        sportsDistrict_handlers.handleSet_sportsProps(sports_all, sportsKey, subCollection)
        sportsDistrict_handlers.handleGet_districtSeasonalData(currentItemKey, itemKey)
      }
    }
    // sportsDistrict_handlers.handleGet_districtSeasonalMatches()
  }, [currentItemKey]);

  const content_json = () => <Wrapper
    content={<JsonViewer json={_nonSeasonalData} name={seasonalDataType} />}
    wrapperType={wrapperTypes.paddedContent}
    updating={updating}
  />

  const wrapper = () => <Wrapper
    content={_nonSeasonalData ? content_json() : <div></div>}
    wrapperType={wrapperTypes.paddedFooter}
    css={{ header: 'bcg', content: 'sports-seasons' }}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    topperCaption={'Sports Season'}
    handleCancel={handleCancel}
  />

  return fromDashboard ? wrapper() : fullPageWrapper()

}

export default NonSeasonalData