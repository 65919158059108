import React, { useContext, useState } from 'react';
import FullPageWrapper from './global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from './global/wrappers/Wrapper';
import UiSaveButtons from './global/components/buttons/UiSaveButtons';
import { FrameworkContext } from './global/cnr/contexts/FrameworkContent';

const NotificationSettings = () => {

  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers } = frameworkContext ?? {}

  const [notificationEnabled, setNotificationEnabled] = useState(Notification.permission === 'granted');

  const handleToggleNotifications = () => {
    if (notificationEnabled) {
      // Disable notifications
      setNotificationEnabled(false);
    } else {
      // Enable notifications
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          setNotificationEnabled(true);
        }
      });
    }
  };

  const wrapper = () => <Wrapper
    content={<p>
      Notification permission status: {Notification.permission}
    </p>}
    footer={<UiSaveButtons
      save={{
        oc: handleToggleNotifications,
        caption: notificationEnabled ? 'Disable Notifications' : 'Enable Notifications'
      }}
    />}
    wrapperType={wrapperTypes.padded}
  ></Wrapper>

  return <FullPageWrapper
    topperCaption={'Notification Settings'}
    content={wrapper()}
    handleCancel={framework_handlers.handleShow_appSidebarContent}
  ></FullPageWrapper>
}

export default NotificationSettings;
