import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { gEnums } from '../../enums/globalEnums';
import { _storageSettings } from '../../storage/storageHelpers';
import { itemStorageHandlers, itemStorageInitialState, itemStorageReducer } from '../reducers/ItemStorageReducer';
import { ParentContext } from './ParentContext';
import { UiItemContext } from './UiItemContext';

/** Global Files (Icon, Image, PDF) for the Event taken from the storage */
export const ItemStorageContext = createContext();

/**
 * 
 * @param {*} props (children)
 * @returns Global Files (Icon, Image, PDF) for the Event taken from storage  
 */
const ItemStorageProvider = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { page_state, paps_state, storage_state, eventInfo_state } = states ?? {}
  const { staticViews } = eventInfo_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings, aps_global } = pageSettings ?? {}
  const { appUserCollection, useClientProfileImage, getImageFromProfileProp, profileImageProp } = aps_appUserSettings ?? {}
  const { galleries } = storage_state ?? {}
  const { direct: gallery_direct, profile: gallery_profile } = galleries ?? {}
  const { storageOptions } = aps_global ?? {}
  const { clientStorageImageCollections } = storageOptions ?? {}

  const { storage_handlers } = handlers ?? {}
  const { pathViews } = paps_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { viewItem, _imageOpts } = item_state ?? {}
  const { key: key_viewItem, pdfs } = viewItem ?? {}
  const { showItemImage } = _imageOpts ?? {}
  const { showPdfs } = pdfs ?? {}

  const _key_viewItem = props && props.viewItemKey ? props.viewItemKey : key_viewItem

  const init_state = { pathViews, useClientProfileImage, appUserCollection, clientStorageImageCollections, getImageFromProfileProp, profileImageProp, staticViews }
  const [itemStorage_state, itemStorageDispatch] = useReducer(itemStorageReducer, itemStorageInitialState(init_state));
  const itemStorage_handlers = itemStorageHandlers(itemStorageDispatch, itemStorage_state)
  const { galleryResponse, updateStoragePath } = itemStorage_state ?? {}

  useEffect(
    () => {
      if (showItemImage || (props && props.showItemImage)) {
        if (gallery_profile && clientStorageImageCollections && clientStorageImageCollections.includes(_key_viewItem)) {
          itemStorage_handlers.handleSet_existingGalleryItems(gallery_profile, _key_viewItem, _storageSettings.galleryPaths.profiles)
        } else {
          const _galleryItems_d = gallery_direct && gallery_direct[_key_viewItem] ? gallery_direct[_key_viewItem] : {}
          if (_galleryItems_d && Object.keys(_galleryItems_d).length > 0) {
            itemStorage_handlers.handleSet_existingGalleryItems(_galleryItems_d)
          } else {
            itemStorage_handlers.handleGet_itemGalleryItems(_storageSettings.galleryPaths.galleryDirect, _key_viewItem)
          }
        }
      }
    },
    // eslint-disable-next-line
    [showItemImage, pathViews, _key_viewItem, gallery_direct]
  )

  useEffect(
    () => {
      if (showPdfs || (props && props.showPdfs)) {
        itemStorage_handlers.handleGet_itemGalleryItems(_storageSettings.galleryPaths.pagePdf, _key_viewItem)
      }
    },
    // eslint-disable-next-line
    [showPdfs, pathViews, _key_viewItem]
  )

  useEffect(
    () => {
      if (updateStoragePath && galleryResponse) {
        // update the STORAGE REDUCER so we don't have to recall this !!!
        galleryResponse.slt = gEnums.storageLocationTypes.pageDirect
        storage_handlers.handleResponse_getAny(galleryResponse)
      }
    },
    // eslint-disable-next-line
    [updateStoragePath, galleryResponse]
  )

  const providerContext = () => <ItemStorageContext.Provider value={{ itemStorage_state, itemStorage_handlers }}>
    {props.children}
  </ItemStorageContext.Provider>
  return providerContext()

}

export default ItemStorageProvider