import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Icon, Label } from 'semantic-ui-react';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { formatItem, formatTypes, timeConvertor } from '../../../common/dateFormatting';
import { uniqueKey } from '../../../common/keys';
import { propHelpers } from '../../../common/tester';
import { ElementContext } from '../../../cnr/contexts/ElementContext';
import PdfGallery from '../../imaging/PdfGallery';

const _dtProps = {
  startDate: 'startDate',
  endDate: 'endDate',
  startTime: 'startTime',
  endTime: 'endTime',
  location: 'location',
  locations: 'locations',
  time: 'time',
  date: 'date',
}

const _timeTypes = {
  start: 'start',
  end: 'end',
  normal: 'normal'
}
/**
 * 
 * @param {object} props (itemData, _keyy)
 * @param {object} itemData (startDate, startTime, endTime)
 * @returns 
 */
const DateTimeLabels = (props) => {

  const { itemData, _keyy } = props
  const { _dateInfo } = itemData ?? {}
  const { actual: actual_dti, local: local_dti, item: item_dti, tzsDifferent } = _dateInfo ?? {}

  const _itemData = { ...itemData }

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, eventInfo_state } = states
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { display: display_global } = aps_global ?? {}
  const { staticViews, appGlobalData } = eventInfo_state ?? {}
  const { locations: locations_global } = appGlobalData ?? {}
  const {
    groupStartTimes,
    showTimezoneDifferences: showTimezoneDifferences_g,
    showTimezoneIndicator: showTimezoneIndicator_g,
    showTmz: showTmz_g,
    show24HourTime: show24HourTime_g,
    ignoreTimezoneFormat,
  } = display_global ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext)
  const { item_state, item_handlers } = uiItemContext ?? {}
  const { propGroups, viewItem, _display } = item_state ?? {}
  const { props: props_viewItem, display } = viewItem ?? {}
  const { showTimezoneDifferences, showTimezoneIndicator, showTmz, show24HourTime } = display ?? {}
  const { schedule: props_schedule } = propGroups ?? {}
  const { propArray: propArray_schedule } = props_schedule ?? {}
  const { startTimeProp, endTimeProp } = _display ?? {}

  const elementContext = useContext(ElementContext)
  const { element_state } = elementContext ?? {}
  const { elemProps } = element_state ?? {}
  const { showPdfs } = elemProps ?? {}

  const _showTimezoneDifferences = (showTimezoneDifferences_g || showTimezoneDifferences) && tzsDifferent
  const _showTimezoneIndicator = showTimezoneIndicator_g || showTimezoneIndicator
  const _showTmz = showTmz_g || showTmz

  const [propsToShow, setPropsToShow] = useState()
  const [otherPropsToShow, setOtherPropsToShow] = useState()

  useEffect(() => {

    const _propsToShow = {}
    const _otherPropsToShow = {}

    if (props_viewItem && _itemData) {
      if (propArray_schedule && propArray_schedule.length > 0) {
        propArray_schedule.forEach(pas => {
          if (props_viewItem[pas] && props_viewItem[pas].show) {
            if (_dtProps[pas]) {
              _propsToShow[pas] = true
            } else {
              _otherPropsToShow[pas] = true
            }
          }
        })
      } else {
        Object.keys(_dtProps).forEach(key => {
          if (props_viewItem[key] && props_viewItem[key].show) {
            if (propArray_schedule.includes(key)) {
              _propsToShow[key] = true
            }
          }
        })
      }
      // change this

    }

    if (Object.keys(_propsToShow).length > 0) { setPropsToShow(_propsToShow) }
    if (Object.keys(_otherPropsToShow).length > 0) { setOtherPropsToShow(_otherPropsToShow) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [itemData, props_viewItem]);

  const handleShowMap = (isMapped, dtProp) => {
    if (isMapped) {
      item_handlers.handleShow_modifyMapping(dtProp)
    }
  }

  let cn = 'extra'
  if (groupStartTimes) { cn += ' grp' }
  if (_showTimezoneDifferences) { cn += ' tz' }

  const icon = (iconName, color) => <Icon color={color} name={iconName} />

  const timeLabel = (timeType, dtProp, color) => {

    const { time24: time24_actual, timeAmPm: time_actual, tzs: tzs_actual } = actual_dti && actual_dti[timeType] && actual_dti[timeType].formatted ? actual_dti[timeType].formatted : {}
    const { time24: time24_local, timeAmPm: time_local, tzs: tzs_local } = local_dti && local_dti[timeType] && local_dti[timeType].formatted ? local_dti[timeType].formatted : {}

    const _time_actual = show24HourTime_g || show24HourTime ? time24_actual : time_actual
    const _time_local = show24HourTime_g || show24HourTime ? time24_local : time_local

    if (ignoreTimezoneFormat) {
      return <Label key={uniqueKey('dtl', 4, _keyy, dtProp)} className={'pi-' + dtProp}>
        <div>{icon('time', color)} </div>
        <div>{(item_dti[dtProp])}</div>
      </Label>
    } else {
      if (_showTimezoneDifferences && _time_actual && _time_local) {
        const t1 = _showTimezoneIndicator ? _time_actual + ' ' + tzs_actual : _time_actual
        const t2 = _showTimezoneIndicator ? _time_local + ' ' + tzs_local : _time_local
        return [
          <Label key={uniqueKey('dtl', 4, _keyy, dtProp)} className={'pi-' + dtProp}>
            <div>{icon('time', color)}</div>
            <div>{t1}</div>
            <div>{icon('user outline', color)}</div>
            <div>{t2}</div>
          </Label>,
        ]
      } else {
        return <Label key={uniqueKey('dtl', 4, _keyy, dtProp)} className={'pi-' + dtProp}>
          <div>{icon('time', color)} </div>
          <div>{(_showTmz ? _time_actual + ' ' + tzs_actual : _time_actual)}</div>
        </Label>
      }
    }

  }

  const timeLabel_simple = (text, value, dtProp, key) => <Label key={uniqueKey('dtl', 4, key, dtProp)} className={'pi-' + dtProp}>
    <div>{icon('time', 'green')} </div>
    <div>{text}{': '}{value}</div>
  </Label>

  const labelItemValue = (pts, dtProp, value, k) => {

    if (pts[dtProp] || value) {

      let dtValue1 = value ? value : _itemData[dtProp]

      switch (dtProp) {
        case _dtProps.startDate:
          dtValue1 = actual_dti && actual_dti.start && actual_dti.start.formatted.date
          return dtValue1 && <Label key={uniqueKey('dtl', 1, _keyy, dtProp)} className={'pi-' + dtProp}>{icon('calendar check outline', 'green')}{dtValue1}</Label>

        case _dtProps.endDate:
          dtValue1 = actual_dti && actual_dti.end && actual_dti.end.formatted.date
          return dtValue1 && <Label key={uniqueKey('dtl', 2, _keyy, dtProp)} className={'pi-' + dtProp}>{icon('calendar check outline', 'red')} {dtValue1}</Label>

        case _dtProps.location:
          return dtValue1 && <Label key={uniqueKey('dtl', 3, _keyy, dtProp)} className={'pi-' + dtProp}>{icon('map marker alternate', 'blue')} {dtValue1}</Label>

        case _dtProps.locations:
          const staticViews_locations = staticViews ? staticViews['locations'] : {}
          const svl = staticViews_locations && staticViews_locations[dtValue1] ? staticViews_locations[dtValue1].name : dtValue1
          const loc = locations_global && locations_global[svl] ? locations_global[svl] : {}
          const { zone } = loc ? loc : {}
          const isMapped = zone ? true : false

          return dtValue1 && <Label key={uniqueKey('dtl', 3, _keyy, dtProp)} className={'pi-' + dtProp}
            onClick={(e) => { handleShowMap(isMapped, dtProp) }}
          >{icon('map marker alternate', isMapped ? 'blue' : 'grey')} {svl}</Label>

        case _dtProps.startTime:
          return dtValue1 && timeLabel(_timeTypes.start, dtProp, 'green')

        case _dtProps.endTime:
          return dtValue1 && timeLabel(_timeTypes.end, dtProp, 'red')

        case _dtProps.date:
          const value_date = formatItem(formatTypes.shortDate, dtValue1)
          return dtValue1 && <Label key={uniqueKey('dtl', 1, _keyy, dtProp)} className={'pi-' + dtProp}>{icon('calendar check outline', 'green')}{value_date}</Label>

        case _dtProps.time:
          const { hour, min } = timeConvertor(dtValue1)
          let value_time = dtValue1
          if (hour && min) {
            const dt = new Date()
            dt.setHours(hour)
            dt.setMinutes(min)
            value_time = formatItem(formatTypes.timeAmPm, dt)
          }
          return dtValue1 && timeLabel_simple(k, value_time, dtProp)

        default:
        // nothing
      }
    }
  }

  const otherProps = () => {
    cn += ' other'
    const _others = []
    Object.keys(otherPropsToShow).forEach(k => {
      if (propHelpers.isDateProp(k)) {
        _others.push(labelItemValue(otherPropsToShow, _dtProps.date, _itemData[k], _.startCase(k)))
      }
      if (propHelpers.isTimeProp(k)) {
        _others.push(labelItemValue(otherPropsToShow, _dtProps.time, _itemData[k], _.startCase(k)))
      }
    })
    return <Card.Content key={uniqueKey('dtl', 'c', _keyy)} className={cn}>
      {_others}
    </Card.Content>
  }

  const pdf = () => <PdfGallery asLabel={true} />


  if (otherPropsToShow) {
    return otherProps()
  } else if (propsToShow) {
    return <Card.Content key={uniqueKey('dtl', 'c', _keyy)} className={cn}>
      {labelItemValue(propsToShow, _dtProps.startDate)}
      {labelItemValue(propsToShow, _dtProps.startTime)}
      {labelItemValue(propsToShow, _dtProps.endTime)}
      {labelItemValue(propsToShow, _dtProps.location)}
      {labelItemValue(propsToShow, _dtProps.locations)}
      {otherPropsToShow && otherProps()}
      {showPdfs && pdf()}
    </Card.Content>
  }
}

export default DateTimeLabels