import { formatItem } from './dateFormatting';

export const createTeamInits = (teamName) => {
  const cleanedName = teamName.replace(/\./g, '');

  // Split the team name by spaces
  const words = cleanedName.split(' ');

  // Get the first word (at least 2 letters) and the second word (at least 2 letters)
  const firstWordAbbreviation = words[0].slice(0, 2).toUpperCase();
  const secondWordAbbreviation = words.length > 1 ? words[1].slice(0, 2).toUpperCase() : '';

  return `${firstWordAbbreviation}${secondWordAbbreviation}`;
}
/**
 * 
 * @param {string} name 
 * @param {number} max 
 * @returns initials for the name
 */
export const createInitials = function (name, max, includeCount) {
  if (name) {
    let _max = max ? max : 2
    let parts = name.split(' ')
    let initials = ''
    if (parts.length > 1) {
      for (var i = 0; i < parts.length; i++) {
        if (parts[i].length > 1 && parts[i] !== '') {
          const p = parts[i].trim()
          if (includeCount) {
            initials += p[0].toUpperCase()
            for (var ic = 1; ic < includeCount; ic++) {
              initials += p[ic]
            }
            if (i === 0) {
              initials += ' '
            }
          } else {
            initials += p[0].toUpperCase()
          }
        }
      }
      return initials
    } else {
      initials += parts[0].substring(0, _max).toUpperCase()
    }
    return initials
  }
  return name
}

/**
 * 
 * @param {object} data 
 * @param {string} dateFormat 
 * @description formats any prop ending in `date` using the `formatItem` function
 */
export const convertDates = function (data, dateFormat) {
  Object.keys(data).forEach(key => {
    const dataItem = data[key]
    Object.keys(dataItem).forEach(propKey => {
      if (propKey.toLowerCase().indexOf('date') >= 0) {
        dataItem[propKey] = formatItem(dateFormat, dataItem[propKey])
      }
    })
  })
}

/**
 * 
 * @param {object} data  
 * @description converts any prop ending in `endingIn` to lowerCase
 */
export const convertToLowerCase = function (data, endingIn) {
  Object.keys(data).forEach(key => {
    const dataItem = data[key]
    Object.keys(dataItem).forEach(propKey => {
      if (propKey.toLowerCase().indexOf(endingIn) >= 0) {
        dataItem[propKey] = dataItem[propKey].toLowerCase()
      }
    })
  })
}

/**
 * 
 * @param {object} data 
 * @param {string} defaultProp 
 * @param {string} defaultValue 
 * @description add a `defaultValue` to all the items in `data` with the `defaultValue`
 */
export const addDefaultProps = function (data, defaultProp, defaultValue) {
  Object.keys(data).forEach(key => {
    const dataItem = data[key]
    dataItem[defaultProp] = defaultValue
  })
}

