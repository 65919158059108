import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Form, Icon, Label, Menu, Message } from 'semantic-ui-react'
import { uniqueKey } from '../../../common/keys'
import { _sdm } from '../../../../projectSpecific/sports/elements/dashboard/SportsDashboard'

const UiSideMenuWrapper = (props) => {

  const { isContent, showItemKey, sportsDataMode } = props

  const [menuItems, setMenuItems] = useState()
  const [menuObjects, setMenuObjects] = useState()
  const [contentElements, setContentElements] = useState()
  const [currentItemKey, setCurrentItemKey] = useState()

  useEffect(() => {
    setMenuItems(props.menuItems)
    setMenuObjects(props.menuObjects)
    setContentElements(props.contentElements)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [props.allItems, props.contentElements]);

  const handleItemSelect = (key) => {
    props.setCurrentMenuKey(key)
    setCurrentItemKey(key)
  }

  const menu = () => {
    const mis = []
    const _items = _.sortBy(menuItems, 'name')
    _items.forEach(item => {
      const { _itemKey } = item
      let lbl;
      if (menuObjects && menuObjects[_itemKey]) {
        const l = menuObjects[_itemKey].length
        lbl = l ? <Label color='blue'>{l}</Label> : <Icon name='check' color='blue' />
      }
      mis.push(<Menu.Item
        className={item._itemKey === currentItemKey ? 'sel' : null}
        key={uniqueKey('dv', 'mi', _itemKey)}
        onClick={() => { handleItemSelect(item._itemKey) }}>
        {_.startCase(item.name)}
        {showItemKey && <div style={{ fontSize: '.7rem', color: 'grey' }}>{_itemKey}</div>}
        {lbl}
      </Menu.Item>)
    })
    return <Menu vertical inverted className={'av-menu smw'}>{mis}</Menu>
  }

  const getDescription = () => {
    let description;
    switch (sportsDataMode) {
      case _sdm.district.glsScores:
        description = 'These are the settings for Scores Access to the App';
        break;
      case _sdm.sports.glsTeam:
        description = 'These are the settings for Team Score Access to the App';
        break;
      case _sdm.sports.accessTeams:
        description = 'These are the settings for Team Access to the App';
        break;
      default:
        description = 'These are the settings for District Access to the App';
    }
    return <Message content={description} />
  }

  const form = () => <Form>
    {getDescription()}
    {contentElements}
  </Form>

  const contentContent = () => {
    if (isContent) {
      return <div style={{ height: '100%' }}>{contentElements}</div>
    } else {
      return form()
    }
  }

  const formWithHeader = () => <div>
    {currentItemKey && contentContent()}
  </div>

  const content = () => <div className={'dashboard-menu'}>
    {menuItems && menu()}
    {formWithHeader()}
  </div>

  return content()
}

export default UiSideMenuWrapper