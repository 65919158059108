import { createRefPath } from "../appData/appRefPaths"
import { fs_dbu } from "../appData/fsAppDataUpdate"

export const mergePromise = (refPath, vit, directCollection, sortedData) => {
  const promises = []
  Object.keys(sortedData).forEach(itemKey => {
    const item = sortedData[itemKey]
    const coll = directCollection ? directCollection : vit
    const _refPath = createRefPath([coll, itemKey], refPath)
    const r = fs_dbu.doc_update(_refPath, item)
    promises.push(r)
  })
  return Promise.all(promises)
}