import { deleteField } from "firebase/firestore";
import _ from 'lodash';

export const _deleteItemProps = ['_deleteProps', '_itemKey', 'id', 'key', '_gs', '_sv', '_ts', '_dateInfo', 'published', '_dateInfo', '_allowProp', 'pageDataConstraintType', '_new', '_viewPermissionType']
export const _deleteViProps = ['bodyHeight', 'clickPath', 'css', 'path', 'viewPermissionType', 'viewProps']
export const _ignoreKeys = ['_svs', '_sv', '_dateInfo', '_itemKey', '_new', '_deleteProps', 'startTimeUTC', 'endTimeUTC']
export const _dateKeys = ['_dateProps', '_updateDate', '_authData', 'auth_uid']

const delete_field = () => deleteField()

const showLog = false
const clgg = (m, a, b) => { console.log(m, a, b) }

export const dataFix = {
  ammendDeleteFields: (updateData) => ammendDeleteFields(updateData),
  ammendDeleteProps: (obj) => ammendDeleteProps(obj),
  ammendOmits: (items, keysToRemove) => ammendOmits(items, keysToRemove),
  cleanObject: (obj) => cleanObject(obj),
  cleanUpData: (dataItems, fixBooleans, createDeleteFields, ammendDeletes) => cleanUpData(dataItems, fixBooleans, createDeleteFields, ammendDeletes),
  createDeleteFields: (updateData, removeOnly) => createDeleteFields(updateData, removeOnly),
  deepClean: (obj) => deepClean(obj),
  deleteEmptyObjectValues: (viewItem, createDeleteFields) => deleteEmptyObjectValues(viewItem, createDeleteFields),
  deleteObjectItems: (item, removes) => deleteObjectItems(item, removes),
  deleteUTCs: (item) => deleteUTCs(item),
  removeAllEmpties: (obj, createDeleteFields) => removeAllEmpties(obj, createDeleteFields),
  removeDeleteFields: (obj, createDeleteFields) => removeDeleteFields(obj, createDeleteFields),
  removeEmpties: (obj) => removeEmpties(obj),
  removeEmptiez: (obj) => removeEmptiez(obj),
  removeEmpty: (obj) => removeEmpty(obj),
  removeEmptyValues: (obj) => removeEmptyValues(obj),
  removeAllKeys: (obj, keysToRemove) => removeAllKeys(obj, keysToRemove),
  removeKeys: (obj, keysToRemove) => removeKeys(obj, keysToRemove),
  removeNulls: (obj) => removeNulls(obj),
  removeNullsInObject: (obj) => removeNullsInObject(obj),
  removeUnderscoreKeys: (obj) => removeUnderscoreKeys(obj),
  removeNumericKeys: (obj) => removeNumericKeys(obj)
}

const removeNumericKeys = (obj) => {
  return _.omitBy(obj, (value, key) => _.isFinite(_.toNumber(key)));
}

const cleanObject = (obj) => {
  _.forOwn(obj, (value, key) => {
    if (key.startsWith('_') || (_.isObject(value) && !_.isArray(value) && _.isEmpty(value))) {
      delete obj[key];
    } else if (_.isObject(value) && !_.isArray(value)) {
      cleanObject(value);
      if (_.isEmpty(value)) {
        delete obj[key];
      }
    }
  });
  return obj;
};

const removeNulls = (obj) => {
  if (_.isObject(obj) && !_.isArray(obj)) {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (!_.isNull(value)) {
        newObj[key] = removeUnderscoreKeys(value);
      }
    }
    return newObj;
  } else if (_.isArray(obj)) {
    return obj.map(removeUnderscoreKeys);
  } else {
    return obj;
  }
}

const removeAllKeys = (obj, keysToRemove) => {
  return _.mapValues(obj, (objItem) => removeKeys(objItem, keysToRemove));

}
// Recursive function to remove specified keys
const removeKeys = (obj, keysToRemove) => {
  if (_.isObject(obj) && !_.isArray(obj)) {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (!keysToRemove.includes(key)) {
        newObj[key] = removeUnderscoreKeys(value);
      }
    }
    return newObj;
  } else if (_.isArray(obj)) {
    return obj.map(removeUnderscoreKeys);
  } else {
    return obj;
  }
};

const removeUnderscoreKeys = (obj) => {
  const ignores = [] // ['_viewPermissionType']
  if (_.isObject(obj) && !_.isArray(obj)) {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (!key.startsWith('_') && !ignores.includes(key)) {
        newObj[key] = removeUnderscoreKeys(value);
      }
    }
    return newObj;
  } else if (_.isArray(obj)) {
    return obj.map(removeUnderscoreKeys);
  } else {
    return obj;
  }
};

const ammendDeleteProps = (data) => {
  const { _deleteProps } = data ?? {}
  if (data) {
    Object.keys(data).forEach(dk => {
      switch (dk) {
        case '_itemKey':
        case '_deleteProps':
          break;
        default:
          if (_deleteProps) {
            _deleteProps.forEach(dp => {
              if (data[dp]) {
                _.remove(_deleteProps, item => item === dp);
              }
            })
          }
      }
    })
  }
}

const ammendOmits = (items, keysToRemove) => {
  const deepOmit = (obj, keys) => {
    return _.mapValues(obj, (value) => {
      if (_.isObject(value) && !_.isArray(value)) {
        return deepOmit(value, keys);
      } else {
        return value;
      }
    });
  };

  const filteredViewItems = deepOmit(items, keysToRemove);
  return filteredViewItems

}

const removeEmptiez = (obj) => {
  Object.keys(obj).forEach(k => {
    removeEmpties(obj[k])
  })
};

const removeEmpties = (obj) => {
  if (_.isObject(obj)) {
    const filteredObj = _.pickBy(obj, (value) => removeEmpties(value));
    return _.omitBy(filteredObj, (value) => _.isEmpty(value));
  }
  return obj;
};

function isNullish(value) {
  return _.isNull(value) || (_.isArray(value) && _.isEmpty(value));
}

/**
 * 
 * @param {object} updateData 
 * @returns a new object with `deleteField` for empty data fields
 */
const createDeleteFields = (updateData, removeOnly) => {
  const _updateData = { ...updateData }
  if (_updateData) {
    const nullishFields = _.pickBy(_updateData, isNullish);
    if (nullishFields) {
      _.forEach(nullishFields, (_, prop) => {
        if (removeOnly) {
          delete _updateData[prop]
        } else {
          _updateData[prop] = delete_field()
        }
      });
    }
    _.forEach(_updateData, (_, prop) => {
      if (_deleteItemProps.includes(prop)) {
        if (removeOnly) {
          delete _updateData[prop]
        } else {
          _updateData[prop] = delete_field()
        }
      }
    });
  }
  return _updateData
}

const ammendDeleteFields = (dataItem) => {
  _deleteItemProps.forEach(df => {
    if (dataItem[df]) {
      dataItem[df] = delete_field()
    }
  })

  try {
    if (dataItem._deleteProps) {
      dataItem._deleteProps.forEach(dp => {
        dataItem[dp] = delete_field()
      })
      delete dataItem._deleteProps
    }
  } catch (error) {
    console.log('e', error)
  }

}

function removeEmpty(obj) {
  // return null, undefined, false objects as is
  if (obj === null || obj === undefined || obj === false) {
    return obj;
  }

  // handle arrays recursively
  if (Array.isArray(obj)) {
    return obj.filter(item => {
      return removeEmpty(item) !== null && removeEmpty(item) !== '' && removeEmpty(item) !== undefined;
    });
  }

  // handle objects recursively
  if (typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const value = removeEmpty(obj[key]);
      if (value !== null && value !== '' && value !== false && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  // return all other values as is
  return obj;
}

/** 
 * @param {object} dataItems 
 * @param {boolean} fixBooleans 
 * @description Loops the viewitems and removes/cleans up data
 * This modifies the object. 
 * No object is returned.
 */
const cleanUpData = (dataItems, fixBooleans, createDeleteFields, ammendDeletes) => {
  Object.keys(dataItems).forEach(key => {
    const dataItem = dataItems[key]
    if (dataItem && _.isObject(dataItem)) {

      removeKeys(dataItem, _deleteViProps)
      deleteEmptyObjectValues(dataItem, createDeleteFields)
      removeAllEmpties(dataItem, createDeleteFields)

      ammendDeletes && ammendDeleteFields(dataItem)

      if (fixBooleans) { fixBooleanItems(dataItem) }

      try {
        if (dataItem._deleteProps) {
          dataItem._deleteProps.forEach(() => {
            // dataItem[dp] = delete_field()
            // dataItem[db] = _deleteProp
          })
          delete dataItem._deleteProps
        }
      } catch (error) {
        console.log('e', error)
      }

      if (dataItem.props) {
        ammendDeleteFields(dataItem.props)
        Object.keys(dataItem.props).forEach(pKey => {
          const pr = dataItem.props[pKey]
          if (pr._deleteMe) {
            dataItem.props[pKey] = delete_field()
          }
          delete pr.propAuthLevel
        })
      }
    }
  })
}

/**
 * 
 * @param {object} viewItem 
 * @description Converts any string boolean values to the true or false
 */
const fixBooleanItems = (viewItem) => {
  Object.keys(viewItem).forEach(propKey => {
    const propValue = viewItem[propKey]
    if (propValue === 'TRUE' || propValue === 'true' || propValue === 'True') {
      viewItem[propKey] = true
    }
    if (propValue === 'FALSE' || propValue === 'false' || propValue === 'False') {
      viewItem[propKey] = false
    }
  })
}

const removeDeleteFields = (data) => {
  if (data) {
    Object.keys(data).forEach(propName => {
      if (propName.startsWith('_') || (data[propName] && (data[propName]._deleteMe))) {
        delete data[propName]
      }
    })
  }
}


/**  
/**
 * 
 * @param {object} vi 
 * @description deletes (bodyHeight, clickPath, css, pah, _viewPermissionType and viewProps) 
 */
export const removeViStuff = (vi) => {
  _deleteViProps.forEach(dp => {
    delete vi[dp]
  })
}

/**
 * 
 * @param {object} viewItem 
 * @description deletes all non boolean item values that do not have a value
 */
const deleteEmptyObjectValues = (viewItem, createDeleteFields) => {
  Object.keys(viewItem).forEach(key => {
    switch (key) {
      case 'position':
        break;
      default:
        if (!_.isBoolean(viewItem[key])) {
          if (!viewItem[key]) {
            delete viewItem[key]
          } else {
            const vip = viewItem[key]
            Object.keys(vip).forEach(key2 => {
              if (!_.isBoolean(vip[key2])) {
                if (!vip[key2]) {
                  if (createDeleteFields) {
                    vip[key2] = delete_field()
                  } else {
                    delete vip[key2]
                  }
                }
              }
            })
          }
        }
    }
  })
}

/**
 * 
 * @param {object} obj 
 * @description Removes empty, null, '', etc. values from all the objects in `obj`
 */
const removeAllEmpties = (obj, createDeleteFields) => {
  Object.keys(obj).forEach(key => {
    if (key === 'undefined') {
      delete obj[key];
    } else {
      if (!_.isBoolean(obj[key])) {
        if (obj[key] && _.isObject(obj[key])) {
          if (Object.keys(obj[key]).length === 0) {
            showLog && clgg('remove key', key, obj[key])
            if (createDeleteFields) {
              obj[key] = delete_field()
            } else {
              delete obj[key];
            }
          } else {
            removeAllEmpties(obj[key], createDeleteFields);
          }
        } else if (obj[key] && _.isArray(obj[key])) {
          if (obj[key].length === 0 || obj[key] === undefined) {
            showLog && clgg('remove key', key, obj[key])
            if (createDeleteFields) {
              obj[key] = delete_field()
            } else {
              delete obj[key];
            }
          }
        } else if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
          showLog && clgg('remove key', key, obj[key])
          if (createDeleteFields) {
            obj[key] = delete_field()
          } else {
            delete obj[key];
          }
        }
      }
    }

  })
  return obj;
};

const removeNullsInObject = (obj) => {
  if (typeof obj === 'string' || obj === "") {
    return;
  }
  _.forEach(obj, function (key, value) {
    if (value === "" || value === null) {
      delete obj[key];
    } else if (_.isArray(value)) {
      if (value.length === 0) {
        delete obj[key];
        return;
      }
      _.forEach(value, function (k, v) {
        removeNullsInObject(v);
      });
      if (value.length === 0) {
        delete obj[key];
      }
    } else if (typeof value === 'object') {
      if (Object.keys(value).length === 0) {
        delete obj[key];
        return;
      }
      removeNullsInObject(value);
      if (Object.keys(value).length === 0) {
        delete obj[key];
      }
    }
  });
  return obj
}

/**
 * 
 * @param {object} item 
 * @param {array} removes 
 * @description Removes the `removes` array from the `item`
 */
const deleteObjectItems = (item, removes) => {
  removes.forEach(remove => {
    delete item[remove]
  })
}

/**
 * 
 * @param {object} item  
 * @description Removes any value in the `item` object ending in `UTC`
 */
const deleteUTCs = (item) => {
  Object.keys(item).forEach(key => {
    if (key.endsWith('UTC')) {
      delete item[key]
    }
  })
}

function deepClean(obj) {
  return _.transform(obj, (result, value, key) => {
    if (_.isObject(value)) {
      const cleanedValue = deepClean(value);
      if (!_.isEmpty(cleanedValue)) {
        result[key] = cleanedValue;
      }
    } else if (!_.isNil(value) && !_.isEmpty(value.toString().trim())) {
      result[key] = value;
    }
  });
}

const removeEmptyValues = (obj) => {
  return _.pickBy(obj, (value) => {
    if (_.isObject(value)) {
      // Recursively remove empty values in nested objects or arrays
      return !_.isEmpty(removeEmptyValues(value));
    } else {
      // Remove empty strings and values that are not objects or arrays
      return !(_.isString(value) && value === '' || _.isEmpty(value));
    }
  });
}; 