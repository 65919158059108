import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { SettingsViewerContext } from '../../components/viewers/FullSettingsViewer';
import { uiFilterHandlers, uiFilterInitialState, uiFilterReducer } from '..//reducers/UiFilterReducer';
import { DataContext } from './DataContext';
import { PageAreaContext } from './PageAreaContext';
import { ParentContext } from './ParentContext';

export const FilterContext = createContext();

/**
 * A wrapper component that handles any data filtering and display options associated with any filter
 * @param {*} props 
 * @returns 
 */
const FilterProvider = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { eventInfo_state, page_state, paps_state } = states

  const { view } = paps_state

  // pageContext
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_page, aps_viewItems } = pageSettings ?? {}
  const { dataOptions, groupingOptions, display } = aps_global ?? {}
  const { dateFormat } = dataOptions ?? {}
  let { ui: vui } = aps_page ?? {}
  const { layoutType } = vui ?? {}
  const viewItem_g = aps_viewItems ? aps_viewItems[view] : null

  // eventInfoContext 
  const { staticViews } = eventInfo_state ?? {}

  // pageAreaContext
  const pageAreaContext = useContext(PageAreaContext)
  const { pageArea_state } = pageAreaContext ?? {}
  const { pageItemsShown } = pageArea_state ?? {}

  // settingsViewerContext
  const settingsViewerContext = useContext(SettingsViewerContext)
  const { settingsViewer_state } = settingsViewerContext ?? {}
  const { viewItem_preview } = settingsViewer_state ?? {}

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state } = dataContext ?? {}
  const { uivi, viewListData } = data_state ?? {}
  let viewItem = pageItemsShown ? pageItemsShown[uivi] : {}

  const _viewItem = viewItem_preview ? viewItem_preview : viewItem

  // reducer 
  const initState = { paps_state, page_state, viewItem: _viewItem, viewItem_g, layoutType, staticViews, dateFormat, groupingOptions, display }
  const [uiFilter_state, uiFilterDispatch] = useReducer(uiFilterReducer, uiFilterInitialState(initState));
  const uiFilter_handlers = uiFilterHandlers(uiFilterDispatch, uiFilter_state)
  const { ready } = uiFilter_state ?? {}

  useEffect(() => {
    uiFilter_handlers.handleInit_filter(_viewItem, viewListData)
    // eslint-disable-next-line
  }, [_viewItem, viewListData]);

  const filterProviderContext = () => <FilterContext.Provider value={{ ...props, uiFilter_handlers, uiFilter_state }}>
    {props.children}
  </FilterContext.Provider>

  if (viewListData && ready) {
    return filterProviderContext()
  } else {
    return <div></div>
  }
};

export default FilterProvider