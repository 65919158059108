import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { gEnums } from '../../../../global/enums/globalEnums';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { PageContext } from '../../../../global/cnr/contexts/PageContext';
import { PapsContext } from '../../../../global/cnr/contexts/PapsContext';
import { pasteHandlers, pasteInitialState, pasteReducer } from '../../../../global/cnr/reducers/PasteReducer';
import { uniqueKey } from '../../../../global/common/keys';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import TableViewer from '../../../../global/components/viewers/TableViewer';
import StepWizard from '../../../../global/components/wizard/StepWizard';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { createFsDocKey } from '../../../../global/firestoreData/appData/fsAppData';
import { getNonExistingItems } from '../../../../global/firestoreData/helpers/compareData';
import { CreateSportsContext } from '../CreateSports';

const stepTypes = {
  paste: 'paste',
  pastedItems: 'pastedItems',
  existingItems: 'existingItems',
  updateItems: 'updateItems',
}

const SportsDataPaste = (props) => {

  const _asat = gEnums.availableSubActionTypes

  const { tableRows, subActionType, fullMode } = props ?? {}
  let { caption } = props

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { _sportsMode, _sportsModeKey, teams_info, staff: staff_team_full, athletes: athletes_team_full } = sportsSeason_state ?? {}
  const { rosters, staff } = teams_info ?? {}

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext)
  const { createSports_handlers } = createSportsContext ?? {}

  const _steps = Object.keys(stepTypes)

  let alertCaption;
  let columnHeaders;

  switch (subActionType) {
    case _asat.createAthletes:
      alertCaption = 'Column headers must include: '
      columnHeaders = ['firstName', 'lastName', 'graduationYear']
      columnHeaders.forEach((ch, index) => {
        alertCaption += ' ' + _.startCase(ch)
        if (index < (columnHeaders.length - 1)) { alertCaption += ',' }
      })
      break;
    case _asat.createRoster:
      alertCaption = 'Column headers must include: '
      columnHeaders = ['firstName', 'lastName', 'graduationYear', 'position', 'uniformNumber']
      columnHeaders.forEach((ch, index) => {
        alertCaption += ' ' + _.startCase(ch)
        if (index < (columnHeaders.length - 1)) { alertCaption += ',' }
      })
      break;
    case _asat.createStaff:
      alertCaption = 'Column headers must include: '
      columnHeaders = ['firstName', 'lastName', 'title', 'email']
      columnHeaders.forEach((ch, index) => {
        alertCaption += ' ' + _.startCase(ch)
        if (index < (columnHeaders.length - 1)) { alertCaption += ',' }
      })
      break;
    default:
    // nothing
  }

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext ?? {}

  const pageContext = useContext(PageContext);

  // pasteReducer  
  const [paste_state, paste_dispatch] = useReducer(pasteReducer, pasteInitialState(paps_state, props.viewItem, props.viewListData, pageContext));
  const paste_handlers = pasteHandlers(paste_dispatch)
  const { pasteValue, itemData, tableItems, uniqueItems } = paste_state ?? {}
  const { propsCc } = tableItems ?? {}

  const [step, setStep] = useState({ index: 0, name: stepTypes.paste })
  const [existingItems_season, setExistingItems_season] = useState({})
  const [existingItems_full, setExistingItems_full] = useState({})
  const [nonExistingItems, setNonExistingItems] = useState()
  const [propsOk, setPropsOk] = useState()
  const stepCount = _steps.length

  const taRows = tableRows ? tableRows : 20

  const _combinedExistingItems = { ...existingItems_season, ...existingItems_full }

  if (!caption) { caption = 'Paste' }
  caption = _.startCase(caption)

  useEffect(() => {
    switch (_sportsMode) {
      case 'teams':
        switch (subActionType) {
          case _asat.createAthletes:
            sportsSeason_handlers.handleGet_parentKeyCollectionItems('athletes')
            const _teamAthletes = rosters ? rosters[_sportsModeKey] : {}
            setExistingItems_season(_teamAthletes ?? {})
            break;

          case _asat.createRoster:
            const _teamRoster = rosters ? rosters[_sportsModeKey] : {}
            setExistingItems_season(_teamRoster ?? {})
            break;

          case _asat.createStaff:
            sportsSeason_handlers.handleGet_parentKeyCollectionItems('staff')
            const _teamStaff = staff ? staff[_sportsModeKey] : {}
            setExistingItems_season(_teamStaff ?? {})
            break;

          default:
          // nothing
        }
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (_sportsMode) {
      case 'teams':
        switch (subActionType) {
          case _asat.createAthletes:
            setExistingItems_full(athletes_team_full ?? {})
            break;
          case _asat.createRoster:
            setExistingItems_full(athletes_team_full ?? {})
            break;
          case _asat.createStaff:
            setExistingItems_full(staff_team_full ?? {})
            break;
          default:
          // nothing
        }
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [athletes_team_full, staff_team_full]);

  useEffect(() => {
    const { nonExistingItems: _nonExistingItems } = getNonExistingItems(_combinedExistingItems, itemData, ['firstName', 'lastName'])
    setNonExistingItems(_nonExistingItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemData]);

  useEffect(() => {
    if (propsCc && columnHeaders) {
      let _propsOk = true
      columnHeaders.forEach(ch => {
        if (!propsCc.includes(ch)) {
          _propsOk = false
        }
      })
      setPropsOk(_propsOk)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableItems, propsCc]);

  const handlePaste = (e, { value }) => { paste_handlers.handlePaste(value) }

  const handleViewItemPasteCommit = () => {
    const newItems = {}
    Object.keys(nonExistingItems).forEach(k => {
      const nk = createFsDocKey('sportsData')
      newItems[nk] = uniqueItems[k]
    })
    if (createSports_handlers.handleCreate_staff) { createSports_handlers.handleCreate_staff(newItems) }
  }

  const validateComponent = () => {
    switch (step.name) {
      case stepTypes.existingItems:
        return true
      case stepTypes.paste:
        return itemData ? true : false
      case stepTypes.pastedItems:
        return true
      case stepTypes.updateItems:
        return uniqueItems ? true : false
      default:
        break;
    }
  }

  const allowBack = step.index > 0
  const allowForward = (step.index < (stepCount - 1) && validateComponent())

  const pasteForm = () => <Wrapper
    header={alertCaption}
    content={<Form>
      <Form.TextArea value={pasteValue} className={'text-area-paste'} key={uniqueKey('vip', 'ta')} onChange={handlePaste} rows={taRows} placeholder='paste here...' />
    </Form>}
    wrapperType={wrapperTypes.padded}
  >
  </Wrapper>

  const tableViewer_pasted = () => <TableViewer tableKey={'pasted'} jsonData={itemData} ignoreItemKeys={true} columnHeaders={columnHeaders} />
  const tableViewer_existing = () => <TableViewer tableKey={'existing'} jsonData={_combinedExistingItems} ignoreItemKeys={true} columnHeaders={columnHeaders} />
  const tableViewer_nonExisting = () => <TableViewer tableKey={'new'} jsonData={nonExistingItems} ignoreItemKeys={true} columnHeaders={columnHeaders} />

  const tableWrapper_pasted = () => <Wrapper content={tableViewer_pasted()} wrapperType={wrapperTypes.padded} />
  const tableWrapper_existing = () => <Wrapper content={tableViewer_existing()} wrapperType={wrapperTypes.padded} />

  const tableWrapper_nonExisting = () => <Wrapper
    content={tableViewer_nonExisting()}
    footer={<UiSaveButtons save={{ caption: 'Add Items', oc: handleViewItemPasteCommit, disabled: !propsOk }} />}
    wrapperType={wrapperTypes.padded}
  />

  const content = () => {
    switch (step.name) {
      case stepTypes.paste:
        return pasteForm()
      case stepTypes.pastedItems:
        return tableWrapper_pasted()
      case stepTypes.existingItems:
        return tableWrapper_existing()
      case stepTypes.updateItems:
        return tableWrapper_nonExisting()
      default:
        break;
    }
  }

  const stepWizard = () => <StepWizard
    allowBack={allowBack}
    allowForward={allowForward}
    content={content()}
    fullMode={fullMode}
    noModal={true}
    setStep={setStep}
    step={step}
    stepCaption={_.startCase(step.name)}
    steps={_steps}
    topperCaption={_.startCase(subActionType)}
  ></StepWizard>

  const fpw = () => <FullPageWrapper
    content={stepWizard()}
    topperCaption={_.startCase(subActionType)}
  ></FullPageWrapper>

  if (subActionType) {
    return fpw()
  } else {
    return stepWizard()
  }

}

export default SportsDataPaste