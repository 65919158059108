import { allPageTypes } from '../../../enums/allPageTypes';
import { settingsIconTypes } from '../../../enums/settingsIconTypes';
import { gridItemIconGroup } from './SegIconGroup';

/**
 * 
 * @param {object} props 
 * @returns appProfilesManager, appUsersManager, appUserRequests, topicsManager
 */
export const allowedItemsAdmin = (props) => {

  const { parentContext, pageIcons, gcProps } = props

  const { states } = parentContext ?? {}
  const { page_state, paps_state, appUser_state } = states
  const { appUser } = appUser_state ?? {}
  const { appUserAccess } = appUser ?? {}
  const { isSuperAdminDeveloper } = appUserAccess ?? {}

  // pageContext 
  const { pathViews } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { appNotifications, appUserPermissions } = aps_global ?? {}
  const { allowRequestAccess } = appUserPermissions ?? {}

  const { allowNotificationTopics, showTopicsManagerOnHomeScreen } = appNotifications ?? {}

  const allowedItem = (viewItem, segProps, gcProps, hidden) => {
    const { key } = viewItem ?? {}
    return { viewItem, itemKey: key, segProps, gcProps, hidden }
  }

  let showProfiles;

  if (pathViews.clients) {
    showProfiles = isSuperAdminDeveloper
  } else {
    showProfiles = true
  }

  const items = []
  if (!pageIcons) {
    showProfiles && items.push(allowedItem({ key: allPageTypes.appProfilesManager }, { caption: 'App Profiles', icon: settingsIconTypes.appProfiles, cn: 'ao' }, gcProps))
    pathViews.clients && items.push(allowedItem({ key: allPageTypes.appUsersManager }, { caption: 'App Users', icon: settingsIconTypes.appUsers, cn: 'ao' }, gcProps))
    allowRequestAccess && items.push(allowedItem({ key: allPageTypes.appUserRequests }, { caption: 'App Requests', icon: settingsIconTypes.appAccessRequests, cn: 'ao' }, gcProps))
    allowNotificationTopics && showTopicsManagerOnHomeScreen && items.push(allowedItem({ key: allPageTypes.topicsManager }, { caption: 'Topics', icon: 'chat', cn: 'ao' }, gcProps))
  }
  return items
}