import React, { useContext, useEffect, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { PageContext } from '../cnr/contexts/PageContext';
import { PapsContext } from '../cnr/contexts/PapsContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import { ValidationContext } from '../cnr/contexts/ValidationContext';
import { uniqueKey } from '../common/keys';
import { sortObject } from '../common/sorting';
import Checker from '../components/forms/elements/Checker';
import { gEnums } from '../enums/globalEnums';
import { fs_db } from '../firestoreData/appData/fsAppData';
import ConfirmationButton from '../viewSettings/actions/buttons/ConfirmationButton';
import FullPageWrapper from '../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import DataList from './DataList';

const AppUserView = (props) => {

  const { ignoreInverted } = props ?? {}

  // papsContext
  const validationContext = useContext(ValidationContext);
  const { validation_handlers } = validationContext ?? {}
  const { handleSend_signInEmails } = validation_handlers ?? {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext ?? {}
  const { pathViews, sharePath } = paps_state ?? {}

  // pageContext
  const pageContext = useContext(PageContext)
  const { page_state } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings } = pageSettings ?? {}
  const { appUserCollection: collectionName } = aps_appUserSettings ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { vld } = item_state ?? {}

  let _inverted = !uiItemContext
  if (ignoreInverted) { _inverted = false }

  // local state 
  const [profileList, setProfileList] = useState()
  const [selectedItems, setSelectedItems] = useState({})

  const callback = (response) => {
    console.log('response', response)
  }

  const handleProfileList = (list) => {
    const _list = sortObject(list, 'lastName')
    setProfileList(_list)
  }

  useEffect(() => {
    if (props.selectedItems) {
      setProfileList(props.selectedItems)
    } else {
      if (vld) {
        setProfileList(vld)
      } else {
        fs_db.get_data_any({ pathViews, collectionName, callback: handleProfileList })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSendEmails = () => handleSend_signInEmails(selectedItems, sharePath, callback)

  const handleSelectAll = (e, item, checked) => {
    if (profileList) {
      const _selectedItems = { ...selectedItems }
      if (!checked) {
        Object.keys(profileList).forEach(key => {
          _selectedItems[key] = profileList[key]
        })
        setSelectedItems(_selectedItems)
      } else {
        setSelectedItems({})
      }
    }
  }

  const handleSelected = (item, checked) => {
    const _selectedItems = { ...selectedItems }
    const { id, key } = item
    const _key = key ? key : id
    if (_selectedItems[_key]) {
      delete _selectedItems[_key]
    } else {
      _selectedItems[_key] = item
    }
    setSelectedItems(_selectedItems)
  }

  const header = () => <Segment basic inverted={_inverted} style={{ padding: 0 }}><Checker label={'Select All'} handle_click={handleSelectAll} item={{ key: 'selectAll' }} /></Segment>
  const footer = () => <ConfirmationButton buttonClicked={'Send Selected Sign-in Emails'} handleConfirm={handleSendEmails} disabled={Object.keys(selectedItems).length === 0} />

  const wrapper = () => <Wrapper
    header={header()}
    content={<DataList key={uniqueKey('auv', 'ta', 'dl')} ignoreInverted={ignoreInverted} inApp={uiItemContext ? true : false} selectedItems={selectedItems} handleSelected={handleSelected} listActionType={gEnums.listActionTypes.emailSignInLink} dataListerType={gEnums.dataListerTypes.collectionData} listData={profileList ?? {}} />}
    footer={footer()}
    wrapperType={wrapperTypes.paddedHeader}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    topperCaption={'Send Email Links'}
  />

  return uiItemContext ? fullPageWrapper() : wrapper()

}

export default AppUserView