import React, { useContext, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import { FrameworkContext } from '../../../cnr/contexts/FrameworkContent';

const HtmlEditor = (props) => {

  const { currentFip, handleEditor, handleUpdate } = props
  const { propname, label, value } = currentFip ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext)
  const { framework_state } = frameworkContext ?? {}
  const { frameworkLeftType, frameworkRightType } = framework_state ?? {}

  const [htmlValue, setHtmlValue] = useState(value ? value : '')

  const handleEditorChange = (html) => setHtmlValue(html)

  const handleOk = () => {
    const data = { propname, value: htmlValue }
    handleUpdate(null, data)
    handleEditor && handleEditor()
  }

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean']
    ],
  }

  // const formats = [
  //   'header',
  //   'bold', 'italic', 'underline', 'strike', 'blockquote',
  //   'list', 'bullet', 'indent',
  //   'link', 'image'
  // ]

  const editor = () => {
    return <div className={'html-editor-container'}>
      <ReactQuill
        defaultValue={htmlValue}
        onChange={handleEditorChange}
        placeholder={''}
        modules={modules}
      />
    </div>
  }

  const footer = () => <UiSaveButtons
    save={{ oc: handleOk, caption: 'OK', icon: 'check', color: 'blue' }}
  ></UiSaveButtons>

  const wrapper = () => <Wrapper
    content={editor()}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper>

  const fpw = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleEditor}
    topperCaption={'HTML Editor'}
    topperCaption2={label}
    isBack={true}
  ></FullPageWrapper>

  return frameworkRightType || frameworkLeftType ? wrapper() : fpw()
}

export default HtmlEditor