import React, { useContext } from 'react';
import { DataManagementContext } from '../../components/viewers/DataManagementViewer';
import { clickOriginTypes, gEnums } from '../../enums/globalEnums';
import { dataSidebarActionTypes } from '../../viewSettings/enums/itemActionTypes';
import FilterProvider from '../../cnr/contexts/FilterContext';
import UiDataFilters, { fullFilterTypes } from '../UiDataFilters';
import UiAppDataLinks from './dataManagement/UiAppDataLinks';

const AppDashboard = React.lazy(() => import('../../components/viewers/AppDashboard'));
const AppStorageTransferWithProvider = React.lazy(() => import('./dataModifications/AppStorageTransfer'));
const CreateNotificationWizard = React.lazy(() => import('../../appNotifications/CreateNotificationWizard'));
const UiBackupData = React.lazy(() => import('./dataManagement/UiBackupData'));
const UiConvertToDocument = React.lazy(() => import('./dataManagement/UiConvertToDocument'));
const UiCopyPaste = React.lazy(() => import('./dataManagement/UiCopyPaste'));
const UiCreateGlobalData = React.lazy(() => import('./dataManagement/UiCreateGlobalData'));
const UiFixPropNames = React.lazy(() => import('./dataManagement/UiFixPropNames'));
const UiMoveData = React.lazy(() => import('./dataManagement/UiMoveData'));
const UiParentKeys = React.lazy(() => import('./dataManagement/UiParentKeys'));
const UiSecretData = React.lazy(() => import('./dataManagement/UiSecretData'));

/**
 * 
 * @param {object} props (dataManagementType, inViewer)
 * @returns a DataManagementContent component based on the `dataManagementType`
 */
const DataSidebarContent = (props) => {

  const { handleItemClose } = props ?? {}

  const dataManagementContext = useContext(DataManagementContext);
  const { dataManagement_state } = dataManagementContext ?? {}
  const { dataManagementType, viewDataMode } = dataManagement_state ?? {}

  const _dataManagementType = viewDataMode ? viewDataMode : dataManagementType

  switch (_dataManagementType) {

    case dataSidebarActionTypes.ammendPropData:
    case dataSidebarActionTypes.fixPropNames:
      return <UiFixPropNames />

    case dataSidebarActionTypes.backupItemData:
      return <UiBackupData />

    case dataSidebarActionTypes.createSingleDocument:
      return <UiConvertToDocument />

    case dataSidebarActionTypes.createAppDataLinks:
      return <UiAppDataLinks />

    case dataSidebarActionTypes.copyData:
      return <UiCopyPaste />

    case dataSidebarActionTypes.createFilter:
      return <FilterProvider>
        <UiDataFilters fullFilterType={fullFilterTypes.create} />
      </FilterProvider>

    case dataSidebarActionTypes.createGlobalData:
    case dataSidebarActionTypes.updateStaticViews:
    case dataSidebarActionTypes.createCollectionData: // look
      return <UiCreateGlobalData />

    case dataSidebarActionTypes.createNotification:
      return <CreateNotificationWizard handleClose={handleItemClose} notificationOriginType={gEnums.notificationOriginTypes.uiItem} />

    case dataSidebarActionTypes.moveData:
    case dataSidebarActionTypes.moveDataTo:
      return <UiMoveData />

    case dataSidebarActionTypes.secretData:
      return <UiSecretData />

    case dataSidebarActionTypes.transferStorageFolder:
      return <AppStorageTransferWithProvider clickOriginType={clickOriginTypes.dataViewer} handleCancel={handleItemClose} />

    case dataSidebarActionTypes.updateParentKeys:
      return <UiParentKeys />

    case dataSidebarActionTypes.manageData:
      return <AppDashboard />

    default:
      return <div>{'DataSidebarContent: No component for ' + dataManagementType}</div>
  }
}

export default DataSidebarContent