import _ from 'lodash'
import { creatingHelpers } from '../../common/creating'
import { gEnums } from "../../enums/globalEnums"
import { dataModificationTypes } from "../../viewSettings/enums/itemActionTypes"
import { viewDataModes } from './DataManagmentReducer'
import { firestoreReducer, fsrts } from "./FirestoreReducer"
import { getModifyProps } from './reducerHelpers/dataModifyProps'
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps"
import { fsfn_sheets } from '../../functions/fbSheetsFuntions'

const rts = {
  handle_fsUpdateRequestAccess: 'handle_fsUpdateRequestAccess',
  handle_test: 'handle_test',
  handleAdd_dataItem: 'handleAdd_dataItem',
  handleAddProp: 'handleAddProp',
  handleAmmend_currentFormData: 'handleAmmend_currentFormData',
  handleAmmend_dataItems: 'handleAmmend_dataItems',
  handleAmmend: 'handleAmmend',
  handleCreateListName: 'handleCreateListName',
  handleDataModificationItems: 'handleDataModificationItems',
  handleDataModificationSelect: 'handleDataModificationSelect',
  handleDelete_dataItems: 'handleDelete_dataItems',
  handleGet_modifyProps: 'handleGet_modifyProps',
  handleInit_dataModification: 'handleInit_dataModification',
  handleSaveCreateList: 'handleSaveCreateList',
  handleSaveList: 'handleSaveList',
  handleSetLinkItem: 'handleSetLinkItem',
  handleShowAdditional: 'handleShowAdditional',
  handleSorted: 'handleSorted',
  handleSubAction: 'handleSubAction',
  handleUpdate_dataItem: 'handleUpdate_dataItem',
  handleUpdate_dataItems: 'handleUpdate_dataItems',
  handleUpdate_sortedList: 'handleUpdate_sortedList',
  handleUpload: 'handleUpload',
  // handleUpdate_linkItems: 'handleUpdate_linkItems',
  ...grts
}

export const dataModificationsReducer = (state, action) => {

  const {
    appData,
    appDataSource,
    appNotifications,
    dataModificationType,
    dataPermissions,
    handleItemClose,
    isDirectModify,
    modifyProps,
    paps_state,
    staticViewKeys,
    googleSheetsKey,
  } = state

  const { type, dispatch, data_current, dataModifyReady } = action
  const { useTimestamps, restrictArrayEdits } = dataPermissions ?? {}
  const { autoUpdateCollectionRelationships } = appDataSource ?? {}

  switch (type) {

    case rts.handleInit_dataModification:
      const { initData } = action
      const { item_state, frInfo, viewItem_preview: viewItem_preview_init, frameworkRightType } = initData
      const { viewItem: viewItem_item, viewListData: viewListData_item, singleDataItem, navigationOptions, subActionType, tabIndex } = item_state ?? {}
      const { pageNavOptions } = navigationOptions ?? {}
      const { dataModification } = pageNavOptions ?? {}
      const { viewItem: viewItem_fr, viewListData: viewListData_fr } = frInfo ?? {}
      let _viewItem = viewItem_preview_init ? viewItem_preview_init : viewItem_item
      _viewItem = _viewItem ? _viewItem : viewItem_fr
      const _viewListData = viewListData_item ? viewListData_item : viewListData_fr
      return { ...state, dataModification, frameworkRightType, frInfo, singleDataItem, viewItem: _viewItem, appData: _viewListData, subActionType, tabIndex }

    case rts.handle_test:
      return { ...state }

    case rts.handleGet_modifyProps:
      // , editableItem, props_viewItem, subEditProps, dataModificationType, dataModificationOpts 
      const { mprs } = action
      const {
        dataModificationOpts,
        editableItem,
        subEditProps,
        viewItem_g,
        viewItem_preview,
        viewItem,
      } = mprs

      const { props: props_viewItem, propSections } = viewItem ?? {}
      const { props: props_viewItem_g } = viewItem_g ?? {}
      const { props: prop_viewItem_p } = viewItem_preview ?? {}

      let _propSections = propSections //_.filter(propSections, { show: true })
      _propSections = _.sortBy(_propSections, 'position')

      const _props = props_viewItem_g ? props_viewItem_g : prop_viewItem_p
      const p = { appData, dataModificationType, editableItem, props_viewItem, props_viewItem_g, subEditProps, restrictArrayEdits, dataModificationOpts }
      const mps = getModifyProps(p)
      return { ...state, modifyProps: mps }

    case rts.handleShowAdditional:
      return { ...state, showAdditional: !state.showAdditional }

    case rts.handleAddProp:
      const { name } = action.fd
      const ips = addPropToProps(modifyProps, name)
      return { ...state, modifyProps: ips, showAdditional: false }

    case rts.handleDataModificationItems:
      return { ...state, dataModificationItems: action.dataModificationItems }

    case rts.handleDataModificationSelect:
      if (isDirectModify && !action.dataModificationType && handleItemClose) {
        handleItemClose()
        return { ...state }
      } else {
        return { ...state, dataModificationType: action.dataModificationType, subModificationType: action.subModificationType }
      }

    case rts.handleAdd_dataItem:
    case rts.handleAmmend_dataItems:
    case rts.handleDelete_dataItems:
    case rts.handleUpdate_dataItem:
    case rts.handleUpdate_dataItems:
    case rts.handleUpdate_sortedList:

      const ufProps = getRequestProps(type, state, action)

      ufProps.staticViewKeys = staticViewKeys
      ufProps.autoUpdateCollectionRelationships = autoUpdateCollectionRelationships
      ufProps.useTimestamps = useTimestamps

      if (action.createFunction) {
        action.createFunction(paps_state, ufProps)
      } else {
        const { allowDataUpdateNotifications, dataUpdateNotificationItems } = appNotifications ?? {}
        const { vit } = ufProps ?? {}
        if (allowDataUpdateNotifications && dataUpdateNotificationItems && dataUpdateNotificationItems.includes(vit)) {
          ufProps.itemData._notifyOnChange = true
        }
        // parentDispatch, paps_state, ufProps, parentDispatchProps  
        // fsfn_sheets.updateGoogleSheetRowById(googleSheetsKey, vit, ufProps.itemData)
        return firestoreReducer(state, action, { type: fsrts.handleUpdate_firestoreData, dispatch, paps_state, ufProps, settingsProps: action.settingsProps })
      }

      switch (type) {
        case rts.handle_fsUpdateDbFromGoogleSheet:
          return { ...state, modifySidebarOpen: false, modifyActionType: null, updating: false, settingsSidebarOpen: false }
        default:
        // nothing
      }

      return { ...state }

    case rts.handleAmmend_currentFormData:
      return { ...state, data_current, dataModifyReady }

    case rts.handleSorted:
      const { sortedItems } = action.dndGroups ?? {}
      const { dataItems } = sortedItems ?? {}
      return { ...state, vldSorted: dataItems, sortedReady: true }

    case rts.handleCreateListName:
      return { ...state, createListName: action.createListName }

    case rts.handleSetLinkItem:
      return { ...state, linkItem: action.linkItem, actionItem: action.actionItem }

    case rts.handleCloseConfirmation:
      if (state.item_handlers) {
        // LOOK TO MOVE
        state.item_handlers.handleShow_itemEdit()
      } else if (handleItemClose) {
        handleItemClose()
        return { ...state }
      }
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation, questionProps: null })

    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation, questionProps: null })

    default:
      return { ...state }
  }
}

export const dataModificationsInitialState = (initState) => {
  let { modifyActionType, viewDataMode } = initState
  if (viewDataMode === viewDataModes.dataEdit) { modifyActionType = dataModificationTypes.edit }
  return { ...initState, dataModificationType: modifyActionType ? modifyActionType : null, isDirectModify: modifyActionType ? true : false }
};

export const dataModificationsHandlers = (dispatch) => {
  return {
    handle_test: (d) => { dispatch({ type: rts.handle_test, dispatch, d }) },
    handleAdd_dataItem: (createFunction) => { dispatch({ type: rts.handleAdd_dataItem, dispatch, createFunction }) },
    handleAddProp: (fd) => { dispatch({ type: rts.handleAddProp, dispatch, fd }) },
    handleAmmend_currentFormData: (data_current, dataModifyReady) => { dispatch({ type: rts.handleAmmend_currentFormData, dispatch, data_current, dataModifyReady }) },
    handleAmmend_dataItems: (ammendType, vld, selectedItems) => { dispatch({ type: rts.handleAmmend_dataItems, dispatch, ammendType, vld, selectedItems }) },
    handleCreateListName: (createListName) => { dispatch({ type: rts.handleCreateListName, dispatch, createListName }) },
    handleDataModificationItems: (dataModificationItems) => { dispatch({ type: rts.handleDataModificationItems, dispatch, dataModificationItems }) },
    handleDataModificationSelect: (dataModificationType, subModificationType) => { dispatch({ type: rts.handleDataModificationSelect, dispatch, dataModificationType, subModificationType }) },
    handleDelete_dataItems: (selectedItems) => { dispatch({ type: rts.handleDelete_dataItems, dispatch, selectedItems }) },
    handleGet_modifyProps: (mprs) => { dispatch({ type: rts.handleGet_modifyProps, dispatch, mprs }) },
    handleInit_dataModification: (initData) => { dispatch({ type: rts.handleInit_dataModification, dispatch, initData }) },
    handleSaveCreateList: (createListProps) => { dispatch({ type: rts.handleSaveCreateList, dispatch, createListProps }) },
    handleSetLinkItem: (linkItem, actionItem) => { dispatch({ type: rts.handleSetLinkItem, dispatch, linkItem, actionItem }) },
    handleShowAdditional: (fd) => { dispatch({ type: rts.handleShowAdditional, dispatch, fd }) },
    handleSorted: (dndGroups) => { dispatch({ type: rts.handleSorted, dispatch, dndGroups }) },
    handleUpdate_dataItem: (settingsProps) => { dispatch({ type: rts.handleUpdate_dataItem, dispatch, settingsProps }) },
    handleUpdate_dataItems: (selectedItems, deletedItems) => { dispatch({ type: rts.handleUpdate_dataItems, dispatch, selectedItems, deletedItems }) },
    handleUpdate_sortedList: () => { dispatch({ type: rts.handleUpdate_sortedList, dispatch }) },
    ...responseHandlers(dispatch)
  }
}

/**
 * returns and object containing information about the update
 * @param {string} type 
 * @param {object} state 
 * @param {object} action 
 * @returns 
 */
const getRequestProps = (type, state, action) => {

  const { vldSorted, viewItem, data_current, appData, paps_state, modifyProps, appDataSource } = state
  const { dataSource, dataConstraints } = viewItem ?? {}
  const { dataCollectionName, altDataCollectionName } = dataSource ?? {}
  const _dataConstraints = dataConstraints ? dataConstraints : dataSource

  const vld = appData

  let _vit = viewItem.key
  if (dataCollectionName) { _vit = dataCollectionName }
  if (altDataCollectionName) { _vit = altDataCollectionName }

  switch (type) {

    case rts.handleAmmend_dataItems:
      const ammendItem = vld[Object.keys(vld)[0]]
      return {
        ammendType: action.ammendType,
        dataUpdateType: gEnums.dataUpdateTypes.updateCollection_prop,
        id: ammendItem.id,
        itemData: ammendItem,
        selectedItems: action.selectedItems,
        deletedItems: action.deletedItems,
        vit: viewItem.key,
        vld: action.vld,
        appDataSource,
      }

    case rts.handleUpdate_dataItems:
      return {
        dataUpdateType: gEnums.dataUpdateTypes.updateCollection,
        id: null,
        selectedItems: action.selectedItems,
        deletedItems: action.deletedItems,
        vit: _vit,
        appDataSource,
      }

    case rts.handleDelete_dataItems:
      const deleteItem = vld[Object.keys(vld)[0]]
      return {
        dataUpdateType: gEnums.dataUpdateTypes.deleteCollectionItems,
        id: deleteItem.id,
        itemData: deleteItem,
        selectedItems: action.selectedItems,
        vit: viewItem.key,
        appDataSource,
      }

    case rts.handleAdd_dataItem:
      let _itemData = { ...data_current };
      if (_dataConstraints) {
        const { view: pageName, viewKey: pageKey } = paps_state ?? {}
        if (pageName && pageKey) {
          switch (_dataConstraints.pageConstraintType) {
            case gEnums.pageConstraintTypes.arrayContains:
              _itemData[pageName] = [pageKey]
              break;
            case gEnums.pageConstraintTypes.equalTo:
              _itemData[pageName] = pageKey
              break;
            default:
            // nothing
          }
        }
      }

      if (modifyProps && _itemData) {
        Object.keys(_itemData).forEach(pk => {
          if (_itemData[pk] && modifyProps[pk]) {
            const mp = modifyProps[pk]
            const { data } = mp ?? {}
            const { isParentKey } = data ?? {}
            if (isParentKey && _itemData[pk]) {
              if (!_itemData.parentKeys) { _itemData.parentKeys = {} }
              _itemData.parentKeys[pk] = _itemData[pk]
              delete _itemData[pk]
            }
          }
        })
      }

      return {
        dataUpdateType: gEnums.dataUpdateTypes.addData,
        id: null,
        itemData: _itemData,
        vit: _vit,
        appDataSource,
      }

    case rts.handleUpdate_dataItem:
      return {
        dataUpdateType: gEnums.dataUpdateTypes.updateDoc,
        id: null,
        itemData: data_current,
        vit: _vit,
        appDataSource,
      }

    case rts.handleSaveCreateList:
      return {
        createListProps: action.createListProps,
        dataUpdateType: gEnums.dataUpdateTypes.addListDoc,
        vit: action.createListProps.createListAs,
        appDataSource,
      }

    case rts.handleUpdate_sortedList:
      const _sortedData = creatingHelpers.createObject(vldSorted, 'id', false, true)
      return {
        dataUpdateType: gEnums.dataUpdateTypes.updateSortedCollection,
        id: null,
        sortedData: _sortedData,
        vit: _vit,
        appDataSource,
      }

    case rts.handle_fsUpdateRequestAccess:
      return {
        appUser: action.appUser,
        dataUpdateType: gEnums.dataUpdateTypes.updateAccessRequest,
        modifyType: gEnums.dataUpdateTypes.updateAccessRequest,
        pathCaption: action.currentPageDataCaption,
        pathName: action.pathName,
        appDataSource,
      }
    default:
      break;
  }
}

const addPropToProps = (itemProps, name) => {
  const _itemProps = { ...itemProps }
  const pd = _.filter(_itemProps, { propSection: 'details' })
  const pdCount = pd ? pd.length : 0
  _itemProps[_.camelCase(name)] = addProp(name, pdCount + 1)
  return _itemProps
}

const addProp = (key, existingCount) => {
  return {
    position: existingCount ? existingCount : 2,
    show: true,
    display: {
      caption: _.startCase(key),
      elemPropType: gEnums.elemPropTypes.normal
    },
    data: {
      formItemType: gEnums.formItemTypes.text,
    },
    propSection: 'details'
  }
}