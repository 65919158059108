import React, { Suspense } from 'react';
import { appNotificationTypes } from '../../appNotifications/AppNotificationsManager';
import { allPageTypes } from '../../enums/allPageTypes';
import { gEnums } from '../../enums/globalEnums';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import NoData from '../alerts/NoData';
import SuspenseDimmer from '../alerts/SuspenseDimmer';
import ErrorBoundary from '../errorHandling/ErrorBoundery';
import { votingStatusTypes } from '../../cnr/reducers/VotingReducer';

// const AddressForm = React.lazy(() => import('./AddressForm'));
const AppAccessRequests = React.lazy(() => import('../access/AppAccessRequests'));
const AppMeetingsManagement = React.lazy(() => import('../../appMeetings/AppMeetingsManagement'));
const AppNotifications = React.lazy(() => import('../../appNotifications/AppNotificationsManager'));
const AppTicketingScannerWithProvider = React.lazy(() => import('../../../projectSpecific/ticketing/components/AppTicketingScanner'));
const AppTicketingWithProvider = React.lazy(() => import('../../../projectSpecific/ticketing/components/AppTicketing'));
const AppUserManagement = React.lazy(() => import('../../appUsers/AppUserManagement'));
const CreateTopicWizard = React.lazy(() => import('../../appNotifications/CreateTopicWizard'));
const ImageGallery = React.lazy(() => import('../imaging/ImageGallery'));
const ImageMap = React.lazy(() => import('../swipe/ImageMapping'));
const PageMedia = React.lazy(() => import('../../page/PageMedia'));
const QuestionsAndAnswers = React.lazy(() => import('../questionsAndAnswers/QuestionsAndAnswers'));
const SurveyManagement = React.lazy(() => import('../../appSurvey/SurveyManagement'));
const UiCredentialing = React.lazy(() => import('../../pageItem/modification/dataModifications/UiCredentialing'));
const UserConversations = React.lazy(() => import('../user/UserConversations'));
const UserFavorites = React.lazy(() => import('../../components/user/UserFavorites'));
const UserNotes = React.lazy(() => import('../user/UserNotes'));
const VotingManagement = React.lazy(() => import('../../appVoting/VotingManagement'));

const FullPageContent = (props) => {

  const { fullPageType, handleCancel, fromSidebar, clickOriginType } = props

  const wizardContent = () => {

    switch (fullPageType) {

      case allPageTypes.appProfilesManager:
      case allPageTypes.appUsersManager:
        return <AppUserManagement fullPageType={fullPageType} clickOriginType={clickOriginType} fromFullPage={fromSidebar ? false : true} handleCancel={handleCancel} />

      case allPageTypes.appUserRequests:
        return <AppAccessRequests handleCancel={handleCancel} />

      case allPageTypes.chat:
      case allPageTypes.conversations:
        return <UserConversations handleCancel={handleCancel} />

      case allPageTypes.credentialScan:
        return <UiCredentialing handleCancel={handleCancel} />

      case allPageTypes.favorites:
        return <UserFavorites handleCancel={handleCancel} fullPage={true} />

      case allPageTypes.imageMapping:
        return <ImageMap handleCancel={handleCancel} imageMapType={gEnums.imageMapTypes.zoneMapping} />

      case allPageTypes.notes:
        return <UserNotes handleCancel={handleCancel} fullPage={true} />

      case allPageTypes.notifications:
        return <AppNotifications handleCancel={handleCancel} appNotificationType={appNotificationTypes.notifications} />

      case allPageTypes.notificationsManager:
        return <AppNotifications handleCancel={handleCancel} appNotificationType={appNotificationTypes.notificationsManager} />

      case allPageTypes.media:
        return <PageMedia handleCancel={handleCancel} />

      case allPageTypes.meetingsManager:
        return <AppMeetingsManagement handleCancel={handleCancel} />

      case allPageTypes.qAndA:
        return <QuestionsAndAnswers fullPageType={fullPageType} fullMode={true} handleCancel={handleCancel} />

      case allPageTypes.qAndAManager:
        return <QuestionsAndAnswers fullPageType={fullPageType} fullMode={true} handleCancel={handleCancel} />

      case allPageTypes.surveyManager:
        return <SurveyManagement handleCancel={handleCancel} />

      case allPageTypes.ticketingManager:
        return <AppTicketingWithProvider handleCancel={handleCancel} />

      case allPageTypes.ticketingScan:
        return <AppTicketingScannerWithProvider handleCancel={handleCancel} />

      case allPageTypes.topicsManager:
        return <CreateTopicWizard handleCancel={handleCancel} topicOriginType={gEnums.topicOriginTypes.manager} />

      case allPageTypes.voting:
        return <VotingManagement handleCancel={handleCancel} votingStatusType={votingStatusTypes.voting} />

      case allPageTypes.votingManager:
        return <VotingManagement handleCancel={handleCancel} votingStatusType={votingStatusTypes.viewAll} />

      case allPageTypes.gallery:
        return <ImageGallery handleCancel={handleCancel} storageLocationType={gEnums.storageLocationTypes.eventGallery} storageType={gEnums.storageTypes.image} />

      default:
        return <FullPageWrapper
          content={<NoData fullCaption="The 'FullPageContent' does not have a return value" />}
          topperCaption={'Missing Prop'}
          handleCancel={handleCancel}
        ></FullPageWrapper>
    }
  }

  return <ErrorBoundary origin={'fullPageContent'} handleCancel={handleCancel}>
    <Suspense fallback={<SuspenseDimmer origin={'Sign In'} />}>
      {wizardContent()}
    </Suspense>
  </ErrorBoundary>

}

export default FullPageContent