// import { dataUpload } from '../../firestoreData/dataUpload';

export const rts = {
  handleAmmend_pageDimmer: 'handleAmmend_pageDimmer',
  handleKill_pageDimmer: 'handleKill_pageDimmer',
  handleTimeLeft: 'handleTimeLeft',
}

export const pageDimmerTexts = {
  app: 'Loading',
  authorizing: 'Authorizing',
  settings: 'Loading',
  createEvent: 'Creating Event',
}

export const pageDimmerTypes = {
  start: 'start',
  finish: 'finish',
}

export const pageDimmerReducer = (state, action) => {

  const { killInterval } = state
  const { type } = action

  switch (type) {

    case rts.handleAmmend_pageDimmer:
      switch (action.pageDimmerType) {
        case pageDimmerTypes.finish:
          return { ...state, pageDimmerOn: false, pageDimmerType: action.pageDimmerType, killPageDimmer: true }
        default:
          return { ...state, pageDimmerOn: true, pageDimmerType: action.pageDimmerType, killPageDimmer: false }
      }

    case rts.handleTimeLeft:
      return { ...state, timeLeft: killInterval - 1 }

    case rts.handleKill_pageDimmer:
      return { ...state, pageDimmerOn: false, pageDimmerType: null, killPageDimmer: true }

    default:
      return { ...state }
  }
}

export const pageDimmerInitialState = (initState) => {
  return { ...initState, pageDimmerOn: true, startTime: Date.now() }
};

export const pageDimmerHandlers = (dispatch) => {
  return {
    handleKill_pageDimmer: () => { dispatch({ type: rts.handleKill_pageDimmer }) },
    handleTimeLeft: () => { dispatch({ type: rts.handleTimeLeft }) },
    handleAmmend_pageDimmer: (pageDimmerType) => { dispatch({ type: rts.handleAmmend_pageDimmer, pageDimmerType }) },
  }
}