import { arrayRemove, arrayUnion } from "firebase/firestore";
import { dispatchProps } from '../../../cnr/reducers/reducerHelpers/dispatchProps';
import { createRefPath } from '../../appData/appRefPaths';
import { fs_dbu } from "../../appData/fsAppDataUpdate";

const _showLog = false

export const updateLinkProfile = async (dispatch, baseRef, actionItem, remove, logging) => {

  const promises = []

  const { actionProps } = actionItem
  const { linkData, vl, appUserCollection: name_view, appUserAccess } = actionProps
  const { appUserSessionKey: key_view } = appUserAccess

  const { refPath } = baseRef

  const { viewItem: name_viewItem } = vl

  const { _viewItem } = linkData ? linkData : {}
  const { key: key_viewItem } = _viewItem ?? {}

  _showLog && console.log('In the `' + name_viewItem + '` collection (' + key_viewItem + '), add the value (' + key_view + ') to the ' + name_view + ' array')
  _showLog && console.log('In the `' + name_view + '` collection (' + key_view + '), add the value (' + key_viewItem + ') to the ' + name_viewItem + ' array')

  if (name_viewItem && key_viewItem && name_view && key_view) {
    // eslint-disable-next-line 

    const ref1 = createRefPath([name_viewItem, key_viewItem], refPath)
    const ref2 = createRefPath([name_view, key_view], refPath)

    if (remove) {
      const uda1 = { [name_view]: arrayRemove(key_view) }
      const r1 = fs_dbu.doc_update(ref1, uda1)
      promises.push(r1)
      const uda2 = { [name_viewItem]: arrayRemove(key_viewItem) }
      const r2 = fs_dbu.doc_update(ref2, uda2)
      promises.push(r2)
    } else {
      const uda3 = { [name_view]: arrayUnion(key_view) }
      const r3 = fs_dbu.doc_update(ref1, uda3)
      promises.push(r3)
      const uda4 = { [name_viewItem]: arrayUnion(key_viewItem) }
      const r4 = fs_dbu.doc_update(ref2, uda4)
      promises.push(r4)
    }
  } else {
    dispatch({ type: dispatchProps.error });
  }

  return Promise.all(promises)

}