import _ from 'lodash';
import React from "react";
import USAMap from "react-usa-map";
import statesJson from "../../../states.json";

export default function UsaMap() {
  const states = statesJson.data;

  const mapHandler = (e) => {
    const _abr = e.target.dataset.name
    const _state = _.find(states, function (s) { return (s.attributes.abbreviation === _abr) })
    console.log('_state', _state)
  };

  return (
    <div>
      <USAMap defaultFill={'#2185d0'} onClick={mapHandler} width={'100%'} />
    </div>
  );
}
