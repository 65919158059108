import { svgElementProps } from './svgElements'

export const svgCategoryProps = (category, sectors, courtProps, swipeArea, parent) => {

  const { maxCourtWidth, courtSpace } = sectors

  const ce = {
    lines: [],
    rects: [],
    texts: [],
    circles: [],
    movementLines: []
  }

  const large = courtSpace.width / 2
  const middle = large * (2 / 3)
  const small = large * (1 / 3)
  const tiny = 10

  switch (category) {
    case 'volleyball':
      // halfCourt + 3 meter lines
      ce.lines.push(svgElementProps.lineFullHorz(maxCourtWidth, courtSpace.halfH, 2, courtProps.lineColor))
      ce.lines.push(svgElementProps.lineFullHorz(maxCourtWidth, courtSpace.thirdH, 1, courtProps.lineColor))
      ce.lines.push(svgElementProps.lineFullHorz(maxCourtWidth, (courtSpace.thirdH * 2), 1, courtProps.lineColor))

      // shadow half of the court
      svgElementProps.halfRect(ce, courtSpace, swipeArea)
      break;

    case 'axeThrowing':

      ce.circles.push(svgElementProps.circle(25, 25, tiny, 'green', 7))
      ce.circles.push(svgElementProps.circle(courtSpace.width - 25, 25, tiny, 'green', 7))

      ce.circles.push(svgElementProps.circle(courtSpace.halfW, courtSpace.halfH, large, 'blue', 1))
      ce.circles.push(svgElementProps.circle(courtSpace.halfW, courtSpace.halfH, middle, 'red', 3))
      ce.circles.push(svgElementProps.circle(courtSpace.halfW, courtSpace.halfH, small, 'black', 5))
      break;
    default:
    // nothing
  }

  return ce
}

export const getCourtSpace = (maxCourtHeight, w) => {

  const h = maxCourtHeight// from STATE

  return {
    width: w,
    halfW: (w / 2),
    thirdW: (w / 3),
    sixthW: (w / 6),

    height: h,
    halfH: (h / 2),
    quarterH: (h / 4),
    thirdH: (h / 3),
    sixthH: (h / 6),

    ratio: {
      w: 1,
      h: 1
    }
  }
}