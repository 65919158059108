import React from 'react'
import { Icon, Label, Popup, Segment } from 'semantic-ui-react'
import _ from 'lodash'

const _usePopup = false
const _size = 'small'

const StandingsLegend = (props) => {

  const { playoffTeamsPerSection, sectionPlayoffTeams } = props ?? {}

  const groupedByPlayoffTeams = _.groupBy(Object.entries(sectionPlayoffTeams), ([, value]) => value);

  const playoffCounts = {}

  _.forEach(sectionPlayoffTeams, (teamCount, key) => {
    if (!playoffCounts[teamCount]) { playoffCounts[teamCount] = [] }
    playoffCounts[teamCount].push(key)
  })

  // If you want it in a more readable format, you can map the result back to an object or any other format:
  const result = Object.entries(groupedByPlayoffTeams).reduce((acc, [key, teams]) => {
    acc[key] = teams.map(([numTeams]) => numTeams);
    return acc;
  }, {});

  let resultString = ''

  // Create the string
  _.map(result, (r, key) => {
    if (_.isEmpty(resultString)) {
      resultString += key
    } else {
      resultString += ', ' + key
    }
  })

  const resultStrings = {}

  // Create the string
  _.forEach(playoffCounts, (pc, pcKey) => {
    let resultString2 = ''
    _.forEach(pc, (pcc, k) => {
      resultString2 += pcc + ', '
    })
    resultString2 = resultString2.substring(resultString2, resultString2.length - 2)
    resultStrings[pcKey] = resultString2
  })


  const ppp = () => {
    const xxx = _.map(resultStrings, (rs, rsk) => (
      <Label size={_size}>{'Teams per Section (' + rs + ') '}{' Playoff Spots: ' + rsk}</Label>
    ))
    return xxx
  }

  const content = () => <Segment basic className='standings-legend' inverted>
    <Label size={_size}>{'Playoff Locked'}</Label>
    <Label size={_size}>{'Playoff Position'}{' '}{'(Top ' + resultString + ')'}</Label>
    <Label size={_size}>Non Playoff Position</Label>
    <Label size={_size}>Non Playoff</Label>
    {ppp()}
    <Label size={_size}>W - Wins</Label>
    <Label size={_size}>L - Losses</Label>
    <Label size={_size}>{'P - Points'}{' '}{'(*not is use for official standings)'}</Label>
    <Label size={_size}>Win 3-0 or 3-1: 3-Points</Label>
    <Label size={_size}>Win 3-2: 2-Points</Label>
    <Label size={_size}>Loss 3-2: 1-Point</Label>
    <Label size={_size}>Loss 3-0 or 3-1: 0-Points</Label>
  </Segment>

  return _usePopup ? <Popup
    className='standings-legend-pop'
    content={content()}
    on='click'
    position='left center'
    trigger={<Icon className='standings-legend-icn' name='info' inverted circular color={'blue'} />}
  /> : content()
}

export default StandingsLegend
