import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { uniqueKey } from '../../../global/common/keys';
import GenericSidebar from '../../../global/genericControls/GenericSidebar';
import { FieldTripContext } from '../cnr/contexts/FieldTripContext';
import { fieldTripListTypes } from '../cnr/reducers/FieldTripReducer';
import FieldTripApprovals from './FieldTripApprovals';

const FieldTrip = (props) => {

  const { statusOnly } = props ?? {}

  // fieldTripContext
  const fieldTripContext = useContext(FieldTripContext)
  const { fieldTrip_state, fieldTrip_handlers } = fieldTripContext ?? {}
  const { fieldTrip } = fieldTrip_state ?? {}
  const { status, _statusItems, _itemKey } = fieldTrip ?? {}

  console.log('fieldTrip', fieldTrip)

  useEffect(() => {
    if (fieldTrip && status) {
      fieldTrip_handlers.handleStausChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [status]);

  const content_status = () => {
    const lbls = []
    let fieldTripApproved = true
    if (_statusItems) {
      _.forEach(_statusItems, (_statusItem, key) => {
        const { sent, approved } = _statusItem
        let icon = sent ? 'send' : 'circle outline'
        let color = sent ? 'blue' : 'grey'
        if (approved) {
          color = 'green'
          icon = 'check circle'
        } else {
          fieldTripApproved = false
        }
        lbls.push(<Label key={uniqueKey('ft', _itemKey, key)}><Icon name={icon} color={color}></Icon>{_.startCase(key)}</Label>)
      })
    }
    lbls.unshift(<Label key={uniqueKey('ft', _itemKey, 'all')}><Icon name={fieldTripApproved ? 'check' : 'ban'} color={fieldTripApproved ? 'green' : 'red'}></Icon>{_.startCase('fieldTripApproved')}</Label>)
    return <div className='lbls-status'>{lbls}</div>
  }

  const content = () => {
    if (statusOnly) {
      { content_status() }
    } else {
      return <div>
        <FieldTripApprovals fieldTripListType={fieldTripListTypes.approvals} />
        <FieldTripApprovals fieldTripListType={fieldTripListTypes.validations} />
      </div>
    }
  }

  const content_sidebar = () => <GenericSidebar
    content={content()}
    sidebarContent={<div>TEST</div>}
    visible={true}
  />

  return fieldTrip ? content() : <div></div>
};

export default FieldTrip; 