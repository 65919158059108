import _ from 'lodash';
import { gEnums } from '../../../enums/globalEnums';
import { copyObj } from '../../../common_web/copy';
import { createPropsFromData } from '../../../viewSettings/helpers/settingsHelpers';
import { getDataModifications } from '../../../auth/actionAccessPermissions';

export const ammendUiItem = (props) => {
  const {
    appUserAccess,
    dataModifications_global,
    dataModifications,
    grouping,
    groupingOptions,
    imageOptions,
    images,
    props_viewItem,
    view,
    viewItemStatus,
    viewKey,
    viewListDataCount,
    viKey,
  } = props ?? {}

  const dmProps = {
    appUserAccess,
    dataModifications_global,
    dataModifications_pageItem: dataModifications,
    trimBySingle: true,
    view,
    viewItemKey: viKey,
    viewItemStatus,
  }

  return {
    propGroups: getPropGroups(props_viewItem),
    _dataModificationOpts: getDataModifications(dmProps),
    _groupingOpts: getGroupOptions(viKey, viewKey, grouping, groupingOptions, viewItemStatus, viewListDataCount),
    _imageOpts: getImageOptions(viKey, viewKey, images, imageOptions),
  }
}

export const getProps_viewItem = (viewItem, viewListData, props_vig) => {

  let { props: props_vi } = viewItem ?? {}

  // if the props for the viewItem do not exist, get them from the global viewItem
  if (!props_vi || Object.keys(props_vi).length === 0) {
    if (props_vig) {
      props_vi = props_vig
    }
  }

  // if the props for the viewItem do not exist, get them from the viewListData
  if (viewListData && (!props_vi)) {
    props_vi = createPropsFromData(viewListData)
  }

  if (_.isArray(props_vi)) {
    props_vi.forEach(sp => {
      sp.show = true
    })
  }
  return props_vi
}

export const ammendIcons = (props_vig, props_viewItem, aps_viewItems, defaultIconColor, showDefaultIconsInDisplay, showDefaultIconsInDisplayIfNull) => {

  const setIcon = (pvi, icon) => {
    let allow = false
    if (icon && icon !== 'file alternative') {
      if (showDefaultIconsInDisplay) {
        allow = true
      } else if (showDefaultIconsInDisplayIfNull) {
        allow = pvi.display.icon ? false : true
      }
      if (allow) {
        pvi.display.icon = icon
        pvi.display.defaultIconColor = defaultIconColor
      }
    }
  }

  if (props_viewItem && (showDefaultIconsInDisplay || showDefaultIconsInDisplayIfNull)) {
    Object.keys(props_viewItem).forEach(propKey => {
      const pvi = props_viewItem[propKey]
      if (pvi && pvi.tagged && pvi.display) {
        const prop_vig = props_vig ? props_vig[propKey] : {}
        const { display: display_prop } = prop_vig ?? {}
        const { icon: icon_prop } = display_prop ?? {}

        const vi_g = aps_viewItems ? aps_viewItems[propKey] : {}
        const { display: display_vi_g } = vi_g ?? {}
        const { defaultIcon } = display_vi_g ?? {}

        const _icon = icon_prop ? icon_prop : defaultIcon

        setIcon(pvi, _icon)

      }
    })
  }
}

const getPropGroups = (props_viewItem) => {
  const getProper = (option, altOption) => {
    const propArray = []
    const props = altOption ? _.filter(props_viewItem, i => ((i[option] === true) && (i[altOption] !== true))) : _.filter(props_viewItem, { [option]: true })
    if (props) { props.forEach(i => propArray.push(i.key)) }
    return {
      props,
      propArray
    }
  }

  const getProperNamed = () => {
    const propArray = []
    const props = _.filter(props_viewItem, i => i.propSection === 'name' && i.tagged === true);
    if (props) { props.forEach(i => propArray.push(i.key)) }
    return {
      props,
      propArray
    }
  }

  const getProperTagged = () => {
    const propArray = []
    const props = _.filter(props_viewItem, i => i.propSection !== 'name' && i.tagged === true);
    if (props) { props.forEach(i => propArray.push(i.key)) }
    return {
      props,
      propArray
    }
  }

  const getProperSchedule = () => {
    const propArray = []
    const props = _.filter(props_viewItem, i => ((i.key === 'startDate') || (i.key === 'endDate') || (i.key === 'startTime') || (i.key === 'endTime')));
    if (props) { props.forEach(i => propArray.push(i.key)) }
    return {
      props,
      propArray
    }
  }

  const getAllArrays = () => {
    let _allArrays = []
    Object.keys(propGroups).forEach(key => {
      switch (key) {
        case 'header':
        case 'meta':
        case 'pin':
        case 'schedule':
        case 'tagged':
          if (propGroups[key] && propGroups[key].propArray) {
            _allArrays = [..._allArrays, ...propGroups[key].propArray]
          }
          break;
        default:
        // nothing
      }
    })
    return _allArrays
  }

  const propGroups = {
    admin: getProper(gEnums.menuItemOptionTypes.admin),
    schedule: getProper(gEnums.menuItemOptionTypes.schedule),
    header: getProper(gEnums.menuItemOptionTypes.header),
    meta: getProper(gEnums.menuItemOptionTypes.meta),
    pin: getProper(gEnums.menuItemOptionTypes.pin),
    show: getProper(gEnums.menuItemOptionTypes.show),
    tagged: getProperTagged(gEnums.menuItemOptionTypes.tagged),
    address: getProperTagged(gEnums.menuItemOptionTypes.address),
    contact: getProperTagged(gEnums.menuItemOptionTypes.contact),
    propArrays: []
  }

  if (propGroups && propGroups.header && propGroups.header.propArray.length === 0) { propGroups.header = getProperNamed() }
  if (propGroups && propGroups.schedule && propGroups.schedule.propArray.length === 0) { propGroups.schedule = getProperSchedule() }

  propGroups.propArrays = getAllArrays()

  return propGroups
}

const getGroupOptions = (viKey, viewKey, grouping, groupingOptions, viewItemStatus, viewListDataCount) => {

  const _groupingProps = ['groupType', 'orderGroupsByAlpha', 'useStartCaseHeaders']

  const { overrideAlphaSettings, overrideGroupSettings } = grouping ?? {}
  const { useGlobalAlphabetSettings, useGlobalGroupSettings, applyToCollections, alphaFilterType, alphaFilterAfter } = groupingOptions ?? {}

  let _grouping = copyObj(grouping)

  if (useGlobalAlphabetSettings && !overrideAlphaSettings && applyToCollections && applyToCollections.includes(viKey) && !viewKey) {
    if (!_grouping) { _grouping = {} }
    switch (alphaFilterType) {
      case gEnums.alphaFilterTypes.afterLimit:
        _grouping.alphabetActive = viewListDataCount > alphaFilterAfter
        break;
      default:
        _grouping.alphabetActive = true
    }
  }

  if (_grouping && groupingOptions) {
    if (useGlobalGroupSettings && !overrideGroupSettings) {
      Object.keys(_grouping).forEach(gKey => {
        if (_groupingProps.includes(gKey)) {
          _grouping[gKey] = groupingOptions[gKey] ? groupingOptions[gKey] : _grouping[gKey]
        }
      })
    }

    if (useGlobalAlphabetSettings && !overrideAlphaSettings) {
      Object.keys(groupingOptions).forEach(gKey => {
        if (!_groupingProps.includes(gKey)) {
          _grouping[gKey] = groupingOptions[gKey] ? groupingOptions[gKey] : _grouping[gKey]
        }
      })
    }
  }

  const { isViewItemSolo } = viewItemStatus ?? {}

  const _groupingOpts = { ..._grouping }

  return isViewItemSolo ? {} : _groupingOpts
}

const getImageOptions = (viKey, viewKey, images_viewItem, imageOptions) => {

  let _images_vi = copyObj(images_viewItem)

  const { overrideImageSettings } = _images_vi ?? {}
  const { useGlobalImageSettings, collectionImageCollections } = imageOptions ?? {}

  let _imageOptions = {}

  if (useGlobalImageSettings && collectionImageCollections && collectionImageCollections.includes(viKey) && !overrideImageSettings) {
    _imageOptions = overrideImageSettings ? _images_vi : imageOptions
    _imageOptions.showItemImage = true
  } else {
    _imageOptions = _images_vi
  }

  return _imageOptions
}
