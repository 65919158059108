import React, { useContext } from 'react'
import { Sticky } from 'semantic-ui-react'
import { cn_s, cn_snp, g_cns } from '../common/cns'
import { AppContentContext } from '../page/AppContent'

/**
 * 
 * @param {*} props (innerRef, wrapper, banner, menu, content, navigation, footer, css, asSegment, inverted)
 * @returns a div `wrapper` that will include some additional classNames to help control the css settings.
 */
const DesktopWrapper = (props) => {

  const { innerRef, banner, menu, content, navigation, footer } = props

  const appContentContext = useContext(AppContentContext)
  const { appContent_state } = appContentContext ? appContentContext : {}
  const {
    asSegment,
    css,
    inverted,
    wrapper,
  } = appContent_state

  const { container: css_container, header: css_banner, menu: css_menu, content: css_content, navigation: css_navigation, footer: css_footer } = css ? css : {}

  let cssT = css_container ? ' ' + css_container : ''
  let cssB = css_banner ? ' ' + css_banner : ''
  let cssM = css_menu ? ' ' + css_menu : ''
  let cssC = css_content ? ' ' + css_content : ''
  let cssN = css_navigation ? ' ' + css_navigation : ''
  let cssF = css_footer ? ' ' + css_footer : ''

  let prefix = ' ';

  if (banner) { prefix += 'b' }
  if (menu) { prefix += 'm' }
  if (content) { prefix += 'c' }
  if (navigation) { prefix += 'n' }
  if (footer) { prefix += 'f' }

  if (cssM) { cssT += ' ' + cssM }

  const mainCn = 'app'
  let cn = g_cns.app_container

  if (asSegment) {
    cssT += ' ui segments'
    cssM += cn_s(inverted)
    cssB += cn_s(inverted)
    cssC += cn_snp(inverted)
    cssN += cn_s(inverted)
    cssF += cn_s(inverted)
  }

  const header = () => <Sticky className={mainCn + '-sticky'} context={innerRef}>
    <div className={mainCn + '-header' + cssB}>
      {banner && <div className={mainCn + '-banner' + cssB}>{banner}</div>}
      {menu && <div className={mainCn + '-menu' + cssM}>{menu}</div>}
      {navigation && <div className={mainCn + '-navigation' + cssN}>{navigation}</div>}
    </div>
  </Sticky>

  const main = <React.Fragment>
    {header()}
    {content && <div className={mainCn + '-content' + cssC}>{content}</div>}
    {footer && <div className={mainCn + '-footer' + cssF}>{footer}</div>}
  </React.Fragment>

  if (wrapper) {
    return <div className={mainCn + 'container' + prefix + cssT}>
      <div className={wrapper}>
        {main}
      </div>
      {main}
    </div>
  } else {
    return <div className={cn + prefix + cssT}>
      {main}
    </div>
  }
}

export default DesktopWrapper