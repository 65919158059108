import _ from 'lodash';
import QRCode from 'qrcode.react';
import React, { useContext, useEffect, useState } from 'react';
import { Icon, Image, Label, List, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../global/common/keys';
import { formatItem, formatTimeAmPm, formatTypes } from '../../../global/common/dateFormatting';
import { adjustCase } from "../../../global/styles/formatting";
import { ParentContext } from '../../../global/cnr/contexts/ParentContext';
import { UiItemContext } from '../../../global/cnr/contexts/UiItemContext';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import { _storageSettings } from '../../../global/storage/storageHelpers';
import { TicketingContext } from '../cnr/contexts/TicketingContext';
import AssignTicket, { assignTypes } from './AssignTIcket';
import SubTickets from './SubTickets';
import TicketStatus from './TicketStatus';

export const ticketTypes = {
  display: 'display',
  full: 'full',
  infoOnly: 'infoOnly',
  lookup: 'lookup',
  qrOnly: 'qrOnly',
  single: 'single',
  willCall: 'willCall',
}

const SingleTicket = (props) => {

  const { ticket, ticketType, isWillCall } = props ?? {}
  const { name, subTickets, startTime, startDate, endTime, _itemKey, location } = ticket ?? {}
  let { startDate2, startTime2, endTime2 } = ticket ?? {}

  const parentContext = useContext(ParentContext);
  const { handlers } = parentContext ?? {}
  const { storage_handlers } = handlers ?? {}

  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { viewItem } = item_state ?? {}
  const { propSections, props: props_viewItem } = viewItem ?? {}

  const ticketingContext = useContext(TicketingContext)
  const { ticketing_handlers, ticketing_state } = ticketingContext ?? {}
  const { subTicketInfo, ticketImage, showPrint, appTicketing, ticketColors, showPresent, showTicket } = ticketing_state ?? {}
  const { useTicketImageForAll, caseType, showLocationOnTicket } = appTicketing ?? {}
  const { subTicketKey: willCallTicketKey } = subTicketInfo ?? {}

  const ticketStyle = ticketColors ? {
    backgroundColor: ticketColors.background,
    color: ticketColors.text,
    borderColor: ticketColors.border
  } : {}

  const { galleryTypes } = _storageSettings

  const [subTicket, setSubTicket] = useState()
  const [subTicketSelected, setSubTicketSelected] = useState()
  const [currentImage, setCurrentImage] = useState()
  const [showInfo, setShowInfo] = useState()
  const [infoProps, setInfoProps] = useState([])

  const handleShowInfo = (e) => {
    e && e.stopPropagation()
    setShowInfo(!showInfo)
  }

  const { urls: urls_current } = currentImage ?? {}
  const { thumbnail: current_url_thumbnail } = urls_current ?? {}

  let _ticketUrl = ticketImage ? ticketImage.url : null
  if (!useTicketImageForAll && current_url_thumbnail && !useTicketImageForAll) { _ticketUrl = current_url_thumbnail }

  useEffect(() => {
    if (propSections && props_viewItem) {
      let _props = []
      let prs = _.filter(propSections, { show: true })
      prs = _.sortBy(prs, 'position')
      prs.forEach(ps => {
        const { key: sectionKey } = ps
        let prps = _.filter(props_viewItem, { propSection: sectionKey })
        prps = _.filter(prps, { show: true })
        prps = _.sortBy(prps, 'position')
        _props = [..._props, ...prps]
      })
      setInfoProps(_props)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [props_viewItem]);

  useEffect(() => {
    if (storage_handlers) {
      storage_handlers.handleGet_galleryImages(galleryTypes.direct, 'tickets', _itemKey, setCurrentImage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleSubTicketSelect = (tk) => {
    if (subTickets) {
      const _subTicket = subTickets[tk]
      _subTicket._itemKey = tk
      setSubTicketSelected(tk)
      setSubTicket(_subTicket)
    }
  }

  const dateTimeLabels = () => {
    const sd = startDate ? formatItem(formatTypes.shortestDate, startDate) : 'No Date'
    const st = startTime ? formatTimeAmPm(startTime) : 'No Time'
    const et = endTime ? formatTimeAmPm(endTime) : null
    const sd2 = startDate2 ? formatItem(formatTypes.shortestDate, startDate2) : null
    const st2 = startTime2 ? formatTimeAmPm(startTime2) : null
    const et2 = endTime2 ? formatTimeAmPm(endTime2) : null
    let cn = 'ticket-date-container'
    if (st2 && et2) { cn += ' multiple' }
    return <div className={cn}>
      {sd && <Label className={'ticket-date'}><Icon color={'grey'} name='calendar' />{sd}</Label>}
      {st && <Label className={'ticket-start-time'}><Icon color={'green'} name='time' />{st}</Label>}
      {et && <Label className={'ticket-end-time'}><Icon color={'red'} name='time' />{et}</Label>}

      {sd2 && <Label className={'ticket-date-2'}><Icon color={'grey'} name='calendar' />{sd2}</Label>}
      {st2 && <Label className={'ticket-start-time-2'}><Icon color={'green'} name='time' />{st2}</Label>}
      {et2 && <Label className={'ticket-end-time-2'}><Icon color={'red'} name='time' />{et2}</Label>}
    </div>
  }

  const ticketInfoOnly = () => {
    return <div className={'ticket-info'}>
      {!showPresent && <div className={'ticket-name'}>{dateTimeLabels()}</div>}
      <TicketStatus ticketKey={_itemKey} ticket={ticket} subTickets={subTickets} isWillCall={isWillCall} ticketType={ticketType} />
    </div>
  }

  const ticketLogo = () => <div className={'ticket-logo'}><Image src={_ticketUrl ? _ticketUrl : null} /></div>

  const ticketInfo = () => {
    return <div className={'ticket-info'} onClick={() => { isWillCall && ticketing_handlers.handleShowTicket() }}>
      <div className={'ticket-name'}>{adjustCase(caseType, name)}</div>
      {(showLocationOnTicket) && <div className={'ticket-location'}>{location}</div>}
      {!isWillCall && ticketLogo()}
      {!showPresent && dateTimeLabels()}
      <TicketStatus ticketKey={_itemKey} ticket={ticket} subTickets={subTickets} isWillCall={isWillCall} ticketType={ticketType} showFull={true} />
      {!showTicket && <div className={'ticket-click'}>{'click to view'}</div>}
    </div>
  }

  const qrValue = (data) => JSON.stringify(data, null, 3)

  const qrSize = 164

  const qrWrapper = () => <FullPageWrapper
    content={<QRCode size={qrSize} value={qrValue({ ticketKey: _itemKey, subTicketKey: willCallTicketKey })} />}
    topperCaption={"test"}
    handleCancel={ticketing_handlers.handleCloseSubTicket}
    noModal={true}
  />

  const singleTicketItem = () => {

    let cn = 'card-ticket'

    switch (ticketType) {
      case ticketTypes.willCall:
        cn += ' willCall'
        break;
      case ticketTypes.full:
        cn += ' full'
        break;
      default:
        cn += ' single'
        break;
    }

    // const sty = itemImageUrl ? { backgroundImage: `url(${itemImageUrl})` } : {}
    const sty = ticketStyle

    switch (ticketType) {
      case ticketTypes.single:
      case ticketTypes.lookup:
        return <div className={cn} style={sty} onClick={() => { ticketing_handlers.handleShowTicket(_itemKey) }}>
          <Icon color={'blue'} name='info' inverted circular onClick={(e) => { handleShowInfo(e) }} className={'item-info'} />
          {ticketInfo()}
        </div>

      case ticketTypes.full:
        return <div className={cn} style={sty}>
          {ticketInfo()}
          <div key={uniqueKey('stk', 'full')} className={'ticket-subs'}>{<SubTickets ticket={ticket} ticketType={ticketType} subTicketSelected={subTicketSelected} handleSubTicketSelect={handleSubTicketSelect} isWillCall={isWillCall} />}</div>
        </div>

      case ticketTypes.willCall:
        if (showPrint) {
          return <div className={cn} style={sty}  >
            {ticketInfo()}
            <div key={uniqueKey('stk', 'wc')} className={'ticket-subs'}>{<SubTickets ticket={ticket} ticketType={ticketType} subTicketSelected={subTicketSelected} handleSubTicketSelect={handleSubTicketSelect} isWillCall={isWillCall} />}</div>
          </div>
        } else {
          return <div className={cn} style={sty}
            onClick={() => { ticketing_handlers.handleShowTicket(_itemKey) }}>
            {ticketInfo(true)}
          </div>
        }

      case ticketTypes.qrOnly:
        return <div className={cn} style={sty}>
          {ticketInfo(true)}
          {qrWrapper()}
        </div>

      case ticketTypes.infoOnly:
        return <div className={cn} style={sty}>
          {ticketInfoOnly(true)}
        </div>

      default:
        return <div>{ticketType}</div>
    }
  }

  const itemInfo = () => {
    const listItems = []
    if (infoProps) {
      infoProps.forEach(infoProp => {
        const { key, display } = infoProp
        const { caption } = display ?? {}
        let value = ticket && ticket[key] ? ticket[key] : null
        if (value) {
          if (key.toLowerCase().indexOf('date') >= 0) { value = formatItem(formatTypes.shortDate, value) }
          if (key.toLowerCase().indexOf('time') >= 0) { value = formatTimeAmPm(value) }
          listItems.push(<List.Item>
            <List.Content>
              <List.Header >{caption ? caption : _.startCase(key)}</List.Header>
              <List.Description >{value}</List.Description>
            </List.Content>
          </List.Item>)
        }
      })
    }
    return <Segment basic><List divided relaxed>
      {listItems}
    </List></Segment>
  }

  const ticketInformation = () => <FullPageWrapper
    content={itemInfo()}
    topperCaption={"Info"}
    handleCancel={handleShowInfo}
  />

  const assignTicket = () => <AssignTicket
    ticket={ticket}
    handleCancel={setSubTicketSelected}
    userTicket={subTicket}
    subTicketSelected={subTicketSelected}
    assignType={assignTypes.assign}
  />

  if (showInfo) {
    return ticketInformation()
  } else {
    return subTicketSelected ? assignTicket() : singleTicketItem()
  }
}

export default SingleTicket