import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { PageDimmerContext } from '../../cnr/contexts/PageDimmerContext';
import CircleLoader from '../../motions/CircleLoader';

const _loaderTypes = {
  dot: 'dot',
  circle: 'circle',
  dots: 'dots',
}

const PageDimmer = () => {

  const loaderType = _loaderTypes.circle

  // startContext
  const pageDimmerContext = useContext(PageDimmerContext)
  const { pageDimmer_state } = pageDimmerContext ?? {}
  const { pageDimmerOn, dimmer_page } = pageDimmer_state ?? {}
  const { dimmerDuration } = dimmer_page ?? {}

  const loader = () => {
    switch (loaderType) {
      case _loaderTypes.circle:
        return <CircleLoader startType={'page-dimmer'} />
      default:
      // nothing
    }
  }

  const dimmer = () => <motion.div
    className={'page-dimmer'}
    initial={{ opacity: 1 }}
    animate={{ opacity: pageDimmerOn ? 0 : 0 }}
    exit={{ opacity: 0, display: 'none' }}
    transition={{
      opacity: { duration: dimmerDuration }
    }}
  >
    {loader()}
  </motion.div>

  return dimmer()

}

export default PageDimmer