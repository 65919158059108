import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { List } from 'semantic-ui-react';
import { filterArrayStartsWith } from '../common/filtering';
import UiSearchFilter from '../pageItem/UiSearchFilter';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';

const WAIT_INTERVAL = 100;

const SettingsSearch = (props) => {

  const { settingsGroupsByGroupType } = props

  const [initSearchKeys, setInitSearchKeys] = useState()
  const [searchKeys, setSearchKeys] = useState()
  const [searchingValue, setSearchingValue] = useState()
  const [searchValue, setSearchValue] = useState()
  const [selectedGroupKey, setSelectedGroupKey] = useState()
  const [selectedGroupItems, setSelectedGroupItems] = useState()

  const handleSearchChange = (value) => setSearchValue(value)
  const triggerChange = (searchValue) => setSearchingValue(searchValue)

  const handleItemClick = (itemKey) => {
    if (itemKey === selectedGroupKey) {
      setSelectedGroupKey()
      setSelectedGroupItems()
    } else {
      let _selectedGroupItems = []
      Object.keys(settingsGroupsByGroupType).forEach(groupKey => {
        const sg = settingsGroupsByGroupType[groupKey]
        const { groupItems } = sg ?? {}
        if (groupItems) {
          Object.keys(groupItems).forEach(groupItemKey => {
            const gii = groupItems[groupItemKey]
            const { groupItems: gis } = gii
            if (gis) {
              Object.keys(gis).forEach(groupItemSubKey => {
                if (groupItemSubKey === itemKey) {
                  _selectedGroupItems[itemKey] = {
                    groupKey,
                    groupItemKey,
                  }
                }
              })
            }
          })
        }
      })
      setSelectedGroupKey(itemKey)
      setSelectedGroupItems(_selectedGroupItems)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => triggerChange(searchValue), WAIT_INTERVAL);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchingValue) {
      const x = filterArrayStartsWith(searchKeys, searchingValue)
      setSearchKeys(x)
    } else {
      setSearchKeys(initSearchKeys)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [searchingValue]);

  useEffect(
    () => {
      if (settingsGroupsByGroupType) {
        let _sg = []
        Object.keys(settingsGroupsByGroupType).forEach(key => {
          const sg = settingsGroupsByGroupType[key]
          const { groupItems } = sg ?? {}
          if (groupItems) {
            Object.keys(groupItems).forEach(groupItemKey => {
              const gii = groupItems[groupItemKey]
              const { groupItems: gis } = gii
              if (gis) {
                _sg = [..._sg, ...Object.keys(gis)]
              }
            })
          }
        })
        const sks = _.uniq(_sg.sort())
        setInitSearchKeys(sks)
        setSearchKeys(sks)
      }
    },
    // update if appUser or settingsDocName changes
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [settingsGroupsByGroupType]
  )

  const searchHeader = () => <UiSearchFilter handleSearchChange={handleSearchChange} />

  const listItems = () => {
    const lis = []
    searchKeys.forEach(key => {
      if (selectedGroupItems && selectedGroupItems[key]) {
        const { groupKey, groupItemKey } = selectedGroupItems[key]
        lis.push(<List.Item onClick={(e) => { handleItemClick(key) }}>
          {key}
          <List.List as='ul'>
            <List.Item as='li'>{groupKey}</List.Item>
            <List.Item as='li'>{groupItemKey}</List.Item>
          </List.List>
        </List.Item>)
      } else {
        lis.push(<List.Item onClick={(e) => { handleItemClick(key) }}>{key}</List.Item>)
      }
    })
    return lis
  }

  const searchContent = () => <List divided relaxed style={{ color: 'white' }}>
    {listItems()}
  </List>

  const searchWrapper = () => <Wrapper
    header={searchHeader()}
    content={searchKeys && searchContent()}
    wrapperType={wrapperTypes.padded}
  />

  return searchWrapper()
}

export default SettingsSearch