import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import { ParentContext } from '../cnr/contexts/ParentContext'
import { animationVariants } from './animationVariants'

export const _animateTypes = {
  component: 'component', // SignIn, ListGrid
  data: 'data', // DataContext 
  fullPageWrapper: 'fullPageWrapper', // FullPageWrapper
  image: 'image', // UiImage
  landing: 'landing', // IconGrids
  map: 'map', // GoogleListMap, NoData
  page: 'page', // PageAreaContent
  pageItem: 'pageItem', // DisplayContent
  pageItemElement: 'pageItemElement', // CardNormal, CardProfile, CardSchedule,
  route: 'route', // AppPage
  splash: 'splash', // Splash
  start: 'start', // StartDimmmer
  title: 'title', // UiItem
  topBanner: 'topBanner',
}

export const _dimmerTypes = {
  page: 'page', // PageAreaContent 
  splash: 'splash', // Splash
  start: 'start', // StartDimmmer 
}

const AnimateComponent = (props) => {

  const { animateType, cn, sty, styleAndClass } = props ?? {}

  const _styleAndClass = styleAndClass ? styleAndClass : { style: null, className: null }

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { transition_state } = states ?? {}
  const { transitions: transitionItems } = transition_state ?? {}

  const { variants } = animationVariants(animateType, transitionItems, sty)

  let _className;

  const motionWrapper = () => {

    switch (animateType) {

      case _animateTypes.route:
        return <motion.div
          variants={variants}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.1 } }}
          exit={{ opacity: 0 }}
          {..._styleAndClass}
        >
          {props.children}
        </motion.div>

      case _animateTypes.splash:
      case _animateTypes.start:
        return <motion.div
          variants={variants}
          initial={'initial'}
          animate={'animate'}
          exit={'exit'}
          {..._styleAndClass}
        >
          {props.children}
        </motion.div>

      case _animateTypes.page:
        _className = 'motion-container ' + animateType
        if (cn) { _className += ' ' + cn }
        return <motion.div
          variants={variants}
          initial={'initial'}
          animate={'animate'}
          exit={'exit'}
          className={_className}
        >
          {props.children}
        </motion.div>

      case _animateTypes.pageItem:
        _className = 'motion-container ' + animateType
        const { style } = _styleAndClass ? _styleAndClass : {}
        if (cn) { _className += ' ' + cn }
        return <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.1 } }}
          className={_className}
          style={style}
        >
          {props.children}
        </motion.div>

      case _animateTypes.pageItemElement:
        _className = 'motion-container ' + animateType
        if (cn) { _className += ' ' + cn }
        return <motion.p
          variants={variants}
          initial={'initial'}
          animate={'animate'}
          exit={'exit'}
          className={_className}
        >
          {props.children}
        </motion.p>

      default:
        _className = 'motion-div-container ' + animateType
        if (cn) { _className += ' ' + cn }
        return <motion.div
          variants={variants}
          initial={'initial'}
          animate={'animate'}
          className={_className}
        >
          {props.children}
        </motion.div>
    }
  }

  return motionWrapper()

}

export default AnimateComponent