import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Icon } from 'semantic-ui-react';
import { ServiceWorkerContext } from './global/cnr/contexts/ServiceWorkerContext';
import { currentHelpers } from './global/redirection/current';

const WAIT_INTERVAL = 5000

export const installAppTypes = {
  auto: 'auto',
  help: 'help',
  manual: 'manual',
}

const NewVersion = () => {

  const serviceWorkerContext = useContext(ServiceWorkerContext)
  const { serviceWorker_state } = serviceWorkerContext ?? {}
  const { newVersionNumber } = serviceWorker_state ?? {}

  const [allow, setAllow] = useState()

  useEffect(() => {
    const timer = setTimeout(() => setAllow(true), WAIT_INTERVAL);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateVersion = async () => {
    window.location.reload(true);
  };

  const handleClickNo = () => {
    currentHelpers.storageItem_set('hasSeenUpdatePromptRecently', new Date().getTime().toString())
  };

  let cn = 'app-homepage'

  const content = () => <div className={cn}>
    <Card fluid as='div'>
      <Card.Content>
        <Icon style={{ float: 'right' }} float='right' name='refresh' />
        <Card.Header>{'New Version Available'}</Card.Header>
        <Card.Description>{'A new vesion (' + newVersionNumber + ') is Available. Please update.'}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
          <Button basic color='green' onClick={(e) => { handleUpdateVersion(e) }}>
            Update
          </Button>
          <Button basic color='red' onClick={(e) => { handleClickNo(e) }}>
            Cancel
          </Button>
        </div>
      </Card.Content>
    </Card>
  </div>

  return allow ? content() : <div></div>
};

export default NewVersion;
