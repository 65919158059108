import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Item, Segment } from 'semantic-ui-react';
import ComponentProvider from '../../cnr/contexts/ComponentContext';
import { DisplayContext } from '../../cnr/contexts/DisplayContext';
import { FilterContext } from '../../cnr/contexts/FilterContext';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { formatItem, formatTypes } from '../../common/dateFormatting';
import { filterHelpers } from '../../common/filtering';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';
import MenuSidebar from '../../sidebars/MenuSidebar';
import NoData from '../alerts/NoData';
import ErrorBoundary from '../errorHandling/ErrorBoundery';
import UsaMap from '../map/UsaMap';
import TableView from '../tables/TableView';
import SemCards from './SemCards';
import SemSchedule from './SemSchedule';

const CalendarSidebar = React.lazy(() => import('../../sidebars/CalendarSidebar'));
const SemanticItems = React.lazy(() => import('./SemItems'));
const SemElemsAlt = React.lazy(() => import('./SemElemsAlt'));
const SemList = React.lazy(() => import('./SemList'));
const SimpleCarosel = React.lazy(() => import('./SemCarosels'));

/**
 * 
 * @returns Creates and returns semanticUI element for the ui, called mainly from SemUI.js
 */
const SemElems = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { preview_state, transition_state } = states
  const { appUser_fns } = fns ?? {}
  const { previewStatus } = preview_state ?? {}

  const isSuperAdminDeveloper = appUser_fns.validateAccess_developer()

  const { transitions } = transition_state ?? {}
  const transition_pageItem = transitions ? transitions[_animateTypes.pageItem] : null
  const { showTransition: showTransition_pageItem } = transition_pageItem ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { desktopMode } = framework_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { _cnc: cnc, viewItem, vld: viewListData, _groupingOpts } = item_state ?? {}

  const { display, desktopDisplay, maps, dataSource } = viewItem ?? {}

  const { dataSourceType } = dataSource ?? {}
  const _display = desktopMode && desktopDisplay ? desktopDisplay : display
  const { groupActive, groupType } = _groupingOpts ?? {}

  let ignoreGroups;

  let { displayType, cardsPerRow, imageProp } = _display ?? {}

  // filterContext
  const filterContext = useContext(FilterContext);
  const { uiFilter_state } = filterContext ?? {}
  const { groupDataProps } = uiFilter_state ?? {}

  // displayContext
  const displayContext = useContext(DisplayContext);
  const { display_state } = displayContext ?? {}
  const { displayProps } = display_state ?? {}
  const { groups, selectedGroupItem } = displayProps ?? {}

  const viewItemType = viewItem.key
  const vit = viewItemType

  const [initData, setInitData] = useState({ vld: null, dataFetched: false })

  const { vld, dataFetched } = initData

  const viewListCount = vld ? Object.keys(vld).length : 0

  useEffect(() => {

    let _vld = Object.assign({}, viewListData);
    let _isGrouped = false;

    // get the data to be displayed 
    if (groupActive && groups && selectedGroupItem) {

      _isGrouped = true
      const gd = filterHelpers.find(groups, 'key', selectedGroupItem)
      if (gd && gd.data) {
        _vld = Object.assign({}, gd.data);
      } else {
        _vld = Object.assign({}, viewListData);
      }
    }

    const vld_allowed = !isSuperAdminDeveloper ? _.filter(_vld, function (i) { return i.name !== 'Test'; }) : _vld
    setInitData({ vld: vld_allowed, dataFetched: true })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewListData, previewStatus]);

  if (displayType === gEnums.displayTypes.item) { displayType = gEnums.displayTypes.card }

  // if the item has NO data, return NoData, with some exceptions 
  const content = (contentData) => {

    switch (displayType) {
      case gEnums.displayTypes.questionsAndAnswers:
        // let it go
        break;
      default:
        // return NoData if no data 
        switch (dataSourceType) {
          case gEnums.dataSourceTypes.component:
          case gEnums.dataSourceTypes.pageDataCollection:
            ignoreGroups = true
            // let it go
            break;
          default:
            if (viewListCount === 0) {
              return <NoData viewItem={viewItem} noCaption={true} />
            }
            break;
        }
    }

    if (viewItem.semWrapper && displayType === gEnums.displayTypes.card) {
      return <SimpleCarosel />
    } else {

      switch (displayType) {

        case gEnums.displayTypes.usaMap:
          return <UsaMap />

        case gEnums.displayTypes.list:
          return <SemList viewListData={contentData} />

        case gEnums.displayTypes.card:

          let itemsPerRow = viewListCount < 3 ? viewListCount : 3

          if (!desktopMode) {
            itemsPerRow = 1
          } else {
            if (cardsPerRow) { itemsPerRow = cardsPerRow }
          }

          const card_props = { vit, cnc, itemsPerRow }
          return <SemCards cardProps={card_props} viewListData={contentData} />

        case gEnums.displayTypes.item:

          const cn = viewListCount === 1 ? 'seg-list' : 'seg-item-group'
          const listOnly = false

          if (listOnly) {
            return <Segment basic style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}>
              <div>LIST</div>
            </Segment>
          } else {
            const itemProps = {}
            return <Segment basic style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}>
              <Item.Group {...itemProps} vit={vit} unstackable className={cn} >
                <SemanticItems viewListData={contentData} />
              </Item.Group>
            </Segment >
          }

        case gEnums.displayTypes.table:
        case gEnums.displayTypes.taggedTable:
          return <TableView
            viewItem={viewItem}
            viewListData={contentData}
            allowClickTo={true}
          />

        default:
          return <SemElemsAlt
            cnc={cnc}
            contentData={contentData}
            displayType={displayType}
            _display={_display}
            maps={maps}
            item_state={item_state}
            imageProp={imageProp}
            viewItem={viewItem}
            vit={vit}
          />
      }
    }
  }

  const contentWrapper = (vld) => <ComponentProvider>
    {content(vld)}
  </ComponentProvider>

  if (groupActive && groupDataProps && groupType && !ignoreGroups) {

    switch (groupType) {

      case gEnums.groupTypes.segment:
        const { groups: segGroups } = groupDataProps ?? {}
        if (segGroups) {
          segGroups.forEach(() => {

          })
        }
        return <Segment>TEST</Segment>

      case gEnums.groupTypes.calendarSidebar:
      case gEnums.groupTypes.menuSidebar:
        const contents = []
        const menuKeys = []
        const { groups: msGroups, groupByProp } = groupDataProps ?? {}
        if (msGroups) {
          msGroups.forEach(msGroup => {
            const { key: groupKey, data } = msGroup ?? {}
            if (data && groupKey) {
              menuKeys.push(groupByProp.toLowerCase().indexOf('date') >= 0 ? formatItem(formatTypes.fullDate, groupKey) : groupKey)
              contents.push(contentWrapper(data))
            }
          })
        }

        switch (groupType) {
          case gEnums.groupTypes.calendarSidebar:
            return <CalendarSidebar prefix={viewItemType} menuKeys={menuKeys} segs={contents} />

          case gEnums.groupTypes.menuSidebar:
            return <MenuSidebar prefix={viewItemType} menuKeys={menuKeys} segs={contents} />

          default:
          // nothing
        }
        break;

      case gEnums.groupTypes.schedule:
      case gEnums.groupTypes.scheduleTop:
        const card_props = { vit }
        return <SemSchedule groupType={groupType} groupDataProps={groupDataProps} card_props={card_props} />

      case gEnums.groupTypes.swipeableTab:
        return contentWrapper(vld)

      case gEnums.groupTypes.swipeableGroup:
        if (groups) {
          const gis = []
          groups.forEach(group => {
            const { data, key: group_key } = group
            const _content = contentWrapper(data)
            gis.push(<div key={uniqueKey('sw', 'si', group_key)} className={'swipeable-group-container'}>
              <div>{group_key}</div>
              {_content}
            </div>)
          })
          return gis
        } else {
          return contentWrapper(vld)
        }

      default:
        return <NoData altCaption={'Group Type'} />
    }
  }

  if (showTransition_pageItem) {
    return <MotionComponent transition={transition_pageItem}>
      <ErrorBoundary origin={'SemElems'}>
        {dataFetched ? contentWrapper(vld) : <div>---</div>}
      </ErrorBoundary>
    </MotionComponent>
  } else {
    return dataFetched ? contentWrapper(vld) : <div></div>
  }


}

export default SemElems