import _ from 'lodash';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from '../../../../global/cnr/reducers/reducerHelpers/dispatchProps';
import { ammendCurrentPlayoffInfo, ammendPlayoffSeeding, getFullPlayoffInfo, getSelectedPlayoffInfo } from './reducerHelpers/createSportsData';
import { createTournamentInfo, sortPlayoffLevelTeams } from '../../helpers/tournamentData';
import { seasonalHelpers, seasonalUpdateTypes } from './reducerHelpers/seasonalSportsData';
import { getCalStartDates } from '../../../../global/common/filtering';
import { convertHelpers } from '../../../../global/common/convert';
import { _matchSeasonalCollection } from './SportsSeasonReducer';

const rts = {
  handleAmmend_level: 'handleAmmend_level',
  handleAmmend_playoffDateGroup: 'handleAmmend_playoffDateGroup',
  handleCreate_playoff: 'handleCreate_playoff',
  handleGet_playoffInfo: 'handleGet_playoffInfo',
  handleInit_playoffTeams: 'handleInit_playoffTeams',
  handleSeasonal_savePlayoffTournament: 'handleSeasonal_savePlayoffTournament',
  handleSeasonal_updatePlayoffTournament: 'handleSeasonal_updatePlayoffTournament',
  handleSelect_playoffDates: 'handleSelect_playoffDates',
  handleSelect_playoffTeam: 'handleSelect_playoffTeam',
  handleClear_playoffTeams: 'handleClear_playoffTeams',
  handleSelect_playoffTeamRecord: 'handleSelect_playoffTeamRecord',
  handleSelectedTeams: 'handleSelectedTeams',
  handleSet_playoffGroup: 'handleSet_playoffGroup',
  handleSet_playoffInfo: 'handleSet_playoffInfo',
  handleSet_playoffLevelCount: 'handleSet_playoffLevelCount',
  handleSet_playoffLevelTeams: 'handleSet_playoffLevelTeams',
  handleShow_playoffMatchLocation: 'handleShow_playoffMatchLocation',
  handleShow_playoffMatchTime: 'handleShow_playoffMatchTime',
  handleUpdate_matchLocation: 'handleUpdate_matchLocation',
  handleUpdate_matchTime: 'handleUpdate_matchTime',
  handleUpdate_playoffInfo: 'handleUpdate_playoffInfo',
  handleUpdate_playoffSeededTeams: 'handleUpdate_playoffSeededTeams',
  handleUpdate_seededTeams: 'handleUpdate_seededTeams',
  ...grts,
}

export const sectionCountTypes = {
  checked: 'checked',
  qualifyCount: 'qualifyCount',
  sectionCount: 'sectionCount',
}

export const createScheduleTypes = {
  season: 'season',
  playoff: 'playoff',
}

export const seasonalUpdateSteps = {
  qualifyCount: 'qualifyCount',
  seasonAlignment: 'seasonAlignment',
  seasonDetails: 'seasonDetails',
  seasonSchedule: 'seasonSchedule',
  seasonSectionCount: 'seasonSectionCount',
  seasonSectionDates: 'seasonSectionDates',
  seasonSectionTeams: 'seasonSectionTeams',
  seasonTeams: 'seasonTeams',
  teams: 'teams',
  year: 'year',
}


/** 
 * @param {object} state 
 * @param {object} action 
 * @returns 
 */
export const createPlayoffReducer = (state, action) => {

  const {
    details_info,
    latestSeason,
    pathViews,
    playoff_info,
    sports_data,
    sportsKey,
    sportPermissions,
    database_fns
  } = state

  const { type, dispatch } = action

  const _playoffInfo = { ...playoff_info }
  const { playoffTeams, playoffTeamCompares } = _playoffInfo ?? {}
  const { teams } = sports_data ?? {}

  const { handleUpdate_playoffInfo, handleSet_playoffInfo, handleStopUpdate } = createPlayoffHandlers(dispatch)

  switch (type) {


    case rts.handleAmmend_level:
      _playoffInfo.playoffLevel = action.playoffLevel
      return { ...state, playoff_info: _playoffInfo }

    case rts.handleGet_playoffInfo:
      seasonalHelpers.getSeasonalData(database_fns, pathViews, sportsKey, latestSeason, handleSet_playoffInfo, null, { altSeasonalName: '_playoffs' })
      return { ...state }

    case rts.handleSet_playoffInfo:

      const d = new Date()

      const msds = [
        new Date(d.getFullYear(), d.getMonth() - 1, 1),
        new Date(d.getFullYear(), d.getMonth(), 1),
        new Date(d.getFullYear(), d.getMonth() + 1, 1)
      ]

      if (action.seasonalData) {
        const { seasonalData: _seasonalData, fk } = action
        const { playoffInfo: playoffInto_seasonal } = _seasonalData ?? {}

        // there may be some linging data issues here.
        const { playoffInfo: playoffInfo_sub } = playoffInto_seasonal ?? {}
        const _playoff_info = playoffInfo_sub ? playoffInfo_sub : playoffInto_seasonal
        const { playoffLevelTeams, playoffDates } = _playoff_info ?? {}

        if (playoffLevelTeams) {
          const _playoffTeams = []
          Object.keys(playoffLevelTeams).forEach(plk => {
            const _playoffLevelTeams = playoffLevelTeams[plk]
            _playoffLevelTeams.forEach(plt => {
              _playoffTeams.push(plt)
            })
          })
          _playoffInfo.playoffTeams = _playoffTeams
        }

        let _monthStartDates;
        if (details_info && details_info.details && details_info.details.seasonStartDate) {
          const { monthStartDates } = details_info.details.seasonStartDate ? getCalStartDates([details_info.details.seasonStartDate], 3) : {}
          if (monthStartDates) {
            _monthStartDates = monthStartDates
          }
        }

        _playoffInfo.found = true
        if (playoffLevelTeams) {
          ammendPlayoffSeeding(playoffLevelTeams)
          const pdts = ammendPlayoffDatesNeeded(playoffLevelTeams)
          _playoffInfo.playoffLevelTeams = playoffLevelTeams
          _playoffInfo.playoffDates_needed = pdts.playoffDates_needed
          _playoffInfo.playoffDates_ready = pdts.playoffDates_ready
          _playoffInfo.playoffDates_selected = pdts.playoffDates_selected
        }
        if (playoffDates) { _playoffInfo.playoffDates_selected = playoffDates }
        if (fk) { _playoffInfo.parentDataKey = fk }

        const _playoffInfoo = ammendCurrentPlayoffInfo(_playoffInfo)
        _playoffInfoo.monthStartDates = msds
        return { ...state, playoff_info: _playoffInfoo }
      } else {
        _playoffInfo.found = false
        _playoffInfo.monthStartDates = msds
        return { ...state, playoff_info: _playoffInfo }
      }

    case rts.handleAmmend_playoffDateGroup:
      const cpi1 = ammendCurrentPlayoffInfo(state.playoff_info, state.playoffGroupKey, action)
      return { ...state, playoff_info: cpi1 }

    case rts.handleSelect_playoffDates:
      const cpi2 = ammendCurrentPlayoffInfo(state.playoff_info, state.playoffGroupKey, action)
      return { ...state, playoff_info: cpi2 }

    case rts.handleCreate_playoff:
      createTournamentInfo(true, state, handleUpdate_playoffInfo)
      return { ...state }

    case rts.handleInit_playoffTeams:

      // if playoffTeams do not exist, create them
      if (!state.playoff_info.playoffTeams) {
        // the playoffTeams are from the sportsSeasonContext, not playoffInfo
        const _playoffTeams = []
        if (action.playoffTeams) {  // 25 of them
          action.playoffTeams.forEach(pt => {
            _playoffTeams.push(pt)
          })
        }
        _playoffInfo.playoffTeams = _playoffTeams
        const plt = sortPlayoffLevelTeams(_playoffInfo)
        _playoffInfo.playoffLevelTeams = plt
      }

      return { ...state, playoff_info: _playoffInfo }

    case rts.handleSeasonal_savePlayoffTournament:
      const cb4 = () => { handleStopUpdate() }
      const playoffMatches = getFullPlayoffInfo(_playoffInfo, action.playoffLevel)
      const _pks = seasonalHelpers.getPks(pathViews, action.latestSeason, sportsKey)
      const seasonalProps = {
        seasonalUpdateType: seasonalUpdateTypes.playoffBrackets,
        pathViews,
        pks: _pks,
        dataToUpdate: playoffMatches,
        collectionName: _matchSeasonalCollection,
        replaceData: true,
        levelKey: action.playoffLevel,
        callback: cb4,
        forPlayoffs: true,
        sportPermissions,
        database_fns,
      }
      seasonalHelpers.updateSeasonalMatches(seasonalProps)
      return { ...state }

    case rts.handleSeasonal_updatePlayoffTournament:
      const cb5 = (a) => {
        handleStopUpdate()
        console.log('Added')
      }
      const _playoffInfos = getSelectedPlayoffInfo(_playoffInfo, action.playoffInfoType, pathViews, action.latestSeason)
      // updates the `_playoffs` collection with the document matching the parentKeys
      // _playoffs/playoffInfo  
      const _pkss = seasonalHelpers.getPks(pathViews, action.latestSeason, sportsKey)
      const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, _pkss, cb5, 'playoffs', null, null, null, _playoffInfos)
      seasonalHelpers.updateSeasonalOrGlobal(action.playoffInfoType, _updateProps)
      return { ...state }

    case rts.handleClear_playoffTeams:
      _playoffInfo.playoffTeamCompares = []
      return { ...state, playoff_info: _playoffInfo }

    case rts.handleSelect_playoffTeam:
      if (action.compareOnly) {
        if (_.includes(playoffTeamCompares, action.team)) {
          _.remove(playoffTeamCompares, function (n) {
            return n.id === action.team.id;
          });
        } else {
          playoffTeamCompares.push(action.team)
        }
        _playoffInfo.playoffTeamCompares = playoffTeamCompares
        return { ...state, playoff_info: _playoffInfo }
      } else {
        if (_.includes(playoffTeams, action.team)) {
          _.remove(playoffTeams, function (n) {
            return n.id === action.team.id;
          });
        } else {
          playoffTeams.push(action.team)
        }
        _playoffInfo.playoffTeams = playoffTeams
        _playoffInfo.playoffLevelTeams = sortPlayoffLevelTeams(_playoffInfo)
        return { ...state, playoff_info: _playoffInfo }
      }

    case rts.handleSelect_playoffTeamRecord:
      _playoffInfo.playoffTeamSelected = action.team
      return { ...state, playoff_info: _playoffInfo }

    case rts.handleSelectedTeams:
      const st = []
      if (teams && action.selected) {
        action.selected.forEach(si => {
          if (teams[si]) {
            st.push(teams[si])
          }
        })
      }
      const _tt = {
        selected: st, //_.sortBy(st, 'name'),
        // selectedIndexes: action.selected
      }
      return { ...state, teams: _tt }

    case rts.handleSet_playoffGroup:
      return { ...state, playoffGroupKey: action.playoffGroupKey, playoffGroup: action.playoffGroup }

    case rts.handleSet_playoffLevelCount:
      if (!_playoffInfo.playoffLevelCounts) { _playoffInfo.playoffLevelCounts = {} }
      _playoffInfo.playoffLevelCounts[action.levelKey] = action.levelCount
      return { ...state, playoff_info: _playoffInfo }

    case rts.handleSet_playoffLevelTeams:
      _playoffInfo.playoffLevelTeams = action.playoffLevelTeams
      return { ...state, playoff_info: _playoffInfo }

    case rts.handleUpdate_playoffSeededTeams:
      ammendPlayoffSeeding(action.playoffLevelTeams)
      _playoffInfo.playoffLevelTeams = action.playoffLevelTeams
      _playoffInfo.playoffTeamsSorted[state.playoffGroupKey] = true
      return { ...state, playoff_info: _playoffInfo }

    case rts.handleUpdate_seededTeams:
      return { ...state, seededTeams: action.seededTeams }

    case rts.handleUpdate_playoffInfo:
      _playoffInfo.tournaments = action.tournaments
      _playoffInfo.matches = action.playoffMatches
      _playoffInfo.tournamentMatches = action.tournamentMatches
      return { ...state, playoff_info: _playoffInfo }

    case rts.handleShow_playoffMatchLocation:
      return { ...state, showPlayoffMatchLocation: action.match }

    case rts.handleShow_playoffMatchTime:
      return { ...state, showPlayoffMatchTime: state.showPlayoffMatchTime ? null : action.match }

    case rts.handleUpdate_matchLocation:
      if (_playoffInfo && _playoffInfo.matches) {
        convertHelpers.createItemKeys(_playoffInfo.matches)
        const _playoffMatch = _.find(_playoffInfo.matches, { _itemKey: state.showPlayoffMatchLocation._itemKey })
        if (_playoffMatch) {
          _playoffInfo.matches[_playoffMatch._itemKey].location = {
            id: action.team.id,
            name: action.team.name
          }
        }
      }
      return { ...state, playoff_info: _playoffInfo, showPlayoffMatchLocation: null }

    case rts.handleUpdate_matchTime:
      if (_playoffInfo && _playoffInfo.matches) {
        convertHelpers.createItemKeys(_playoffInfo.matches)
        const _playoffMatch = _.find(_playoffInfo.matches, { _itemKey: state.showPlayoffMatchTime._itemKey })
        if (_playoffMatch) {
          _playoffInfo.matches[_playoffMatch._itemKey].startTime = action.startTime
        }
      }
      return { ...state, playoff_info: _playoffInfo, showPlayoffMatchTime: null }

    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const createPlayoffHandlers = (dispatch) => {
  return {
    handleAmmend_level: (playoffLevel) => { dispatch({ type: rts.handleAmmend_level, dispatch, playoffLevel }) },
    handleAmmend_playoffDateGroup: () => { dispatch({ type: rts.handleAmmend_playoffDateGroup, dispatch }) },
    handleCreate_playoff: () => { dispatch({ type: rts.handleCreate_playoff, dispatch }) },
    handleGet_playoffInfo: () => { dispatch({ type: rts.handleGet_playoffInfo, dispatch }) },
    handleInit_playoffTeams: (playoffTeams) => { dispatch({ type: rts.handleInit_playoffTeams, dispatch, playoffTeams }) },
    handleSelect_playoffDates: (playoffDateProps) => { dispatch({ type: rts.handleSelect_playoffDates, dispatch, playoffDateProps }) },
    handleSeasonal_savePlayoffTournament: (playoffLevel, latestSeason) => { dispatch({ type: rts.handleSeasonal_savePlayoffTournament, dispatch, playoffLevel, latestSeason }) },
    handleSeasonal_updatePlayoffTournament: (playoffInfoType, latestSeason) => { dispatch({ type: rts.handleSeasonal_updatePlayoffTournament, dispatch, playoffInfoType, latestSeason }) },
    handleSelect_playoffTeamRecord: (team) => { dispatch({ type: rts.handleSelect_playoffTeamRecord, dispatch, team }) },
    handleClear_playoffTeams: () => { dispatch({ type: rts.handleClear_playoffTeams, dispatch }) },
    handleSelect_playoffTeam: (team, compareOnly) => { dispatch({ type: rts.handleSelect_playoffTeam, dispatch, team, compareOnly }) },
    handleSelectedTeams: (selected) => { dispatch({ type: rts.handleSelectedTeams, dispatch, selected }) },
    handleSet_playoffGroup: (playoffGroupKey, playoffGroup) => { dispatch({ type: rts.handleSet_playoffGroup, dispatch, playoffGroupKey, playoffGroup }) },
    handleSet_playoffInfo: (seasonalData, fk) => { dispatch({ type: rts.handleSet_playoffInfo, dispatch, seasonalData, fk }) },
    handleSet_playoffLevelTeams: (playoffLevelTeams) => { dispatch({ type: rts.handleSet_playoffLevelTeams, dispatch, playoffLevelTeams }) },
    handleSet_playoffLevelCount: (levelKey, levelCount) => { dispatch({ type: rts.handleSet_playoffLevelCount, dispatch, levelKey, levelCount }) },
    handleShow_playoffMatchLocation: (match) => { dispatch({ type: rts.handleShow_playoffMatchLocation, dispatch, match }) },
    handleShow_playoffMatchTime: (match) => { dispatch({ type: rts.handleShow_playoffMatchTime, dispatch, match }) },
    handleUpdate_matchLocation: (team) => { dispatch({ type: rts.handleUpdate_matchLocation, dispatch, team }) },
    handleUpdate_matchTime: (startTime) => { dispatch({ type: rts.handleUpdate_matchTime, dispatch, startTime }) },
    handleUpdate_playoffInfo: (tournaments, playoffMatches, tournamentMatches) => { dispatch({ type: rts.handleUpdate_playoffInfo, dispatch, tournaments, playoffMatches, tournamentMatches }) },
    handleUpdate_playoffSeededTeams: (playoffLevelTeams) => { dispatch({ type: rts.handleUpdate_playoffSeededTeams, dispatch, playoffLevelTeams }) },
    handleUpdate_seededTeams: (seededTeams) => { dispatch({ type: rts.handleUpdate_seededTeams, dispatch, seededTeams }) },
    ...responseHandlers(dispatch),
  }
}

export const createPlayoffInitialState = (init_state) => {

  const { teams, pathViews } = init_state
  const { sports } = pathViews

  const _playoffTeams = getPlayoffTeamsInit(teams)
  const _playoffLevelTeams = getPlayoffLevelTeamsInit(teams)

  const pdts = ammendPlayoffDatesNeeded(_playoffLevelTeams)

  return {
    ...init_state,
    currentSport: sports,
    playoff_info: {
      playoffTeams: _playoffTeams,
      playoffLevelTeams: _playoffLevelTeams,
      playoffTeamsSorted: {},
      playoffTeamCompares: [],
      ...pdts
    },
    parentDataKeys: {}
  };
}

const ammendPlayoffDatesNeeded = (playoffLevelTeams) => {

  const playoffDates_selected = {}
  const playoffDates_ready = {}
  const playoffDates_needed = {}

  if (playoffLevelTeams) {
    Object.keys(playoffLevelTeams).forEach(key => {
      const lt = playoffLevelTeams[key]
      playoffDates_selected[key] = []
      playoffDates_ready[key] = false
      playoffDates_needed[key] = datesNeeded(lt.length)
    })
  }

  return { playoffDates_selected, playoffDates_ready, playoffDates_needed }

}

const datesNeeded = (teamCount) => {
  switch (true) {
    case teamCount > 16 && teamCount <= 32:
      return 5
    case teamCount > 8 && teamCount <= 16:
      return 4
    case teamCount > 4 && teamCount <= 8:
      return 3
    case teamCount > 3 && teamCount <= 4:
      return 2
    default:
    // nothing
  }
}

const getPlayoffTeamsInit = (teams) => {
  const _teams = _.filter(teams, (t) => t.record && t.record.playoffTeam)
  return _teams
}

const getPlayoffLevelTeamsInit = (teams) => {
  const _teams = _.filter(teams, (t) => t.record && t.record.playoffTeam)
  const teams_levels = _teams ? _.groupBy(_teams, 'levels') : null
  return teams_levels
}
