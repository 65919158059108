import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Segment, Sidebar } from 'semantic-ui-react';
import { DataManagementContext } from '../../../components/viewers/DataManagementViewer';
import { appIconTypes } from '../../../enums/appIconTypes';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import SidebarConfirm from '../../../components/swipers/SidebarConfirm';
import { dataSidebarActionTypes } from '../../../viewSettings/enums/itemActionTypes';
import { updateLinkHelpers, getAppLinkedData } from '../../../firestoreData/updates/subUpdates/fsUpdate_dataLInks';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { convertHelpers } from '../../../common/convert';

/** Component for creating a single document containing collection of documents 
 * @description updates using (dataManagement_handlers.handleUpdate_dataToDocument)
 */
const UiAppDataLinks = () => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state, eventInfo_state } = states
  const { pathViews } = paps_state
  const { staticViews } = eventInfo_state

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state, dataManagement_handlers } = dataManagementContext ?? {}
  const { dataManagementType, appData, viewItem, updating, confirmation, showCommitDataManagment } = dataManagement_state ?? {}
  const { key: viewItemKey } = viewItem ?? {}
  const inViewer = dataManagement_state ? true : false

  const appDataCount = appData ? Object.keys(appData).length : 0

  const [appDataLinks, setAppDataLinks] = useState()
  const [appDataLinkData, setAppDataLinkData] = useState()

  useEffect(() => {
    try {
      const _appDataLinkData = {}
      const _appDataLinks = updateLinkHelpers.get_appLinkedData(pathViews, viewItemKey, appData, staticViews)
      const _appDataLink = _appDataLinks[viewItemKey]
      const _staticView = staticViews[viewItemKey]
      _.forEach(_appDataLink, (appDataLinkItem, key) => {
        const _staticViewItem = _staticView[key]
        const name = convertHelpers.getItemName(_staticViewItem)
        _appDataLinkData[key] = {
          _itemKey: key,
          name,
          collections: []
        }
        // if (appDataLinkItem && _.isArray(appDataLinkItem) && _.size(appDataLinkItem) > 0) {
        _.forEach(appDataLinkItem, (linkItems, collectionKey) => {
          const _staticCollection = staticViews[collectionKey]
          _appDataLinkData[key].collections.push(collectionKey)
          if (!_appDataLinkData[key][collectionKey]) { _appDataLinkData[key][collectionKey] = {} }
          _.forEach(linkItems, (linkItem) => {
            if (!_appDataLinkData[key][collectionKey][linkItem]) { _appDataLinkData[key][collectionKey][linkItem] = {} }
            const _staticCollectionItem = _staticCollection[linkItem]
            const collectionName = convertHelpers.getItemName(_staticCollectionItem)
            _appDataLinkData[key][collectionKey][linkItem] = {
              _itemKey: linkItem,
              name: collectionName
            }
          })
        })
      })
      console.log('_appDataLinks', _appDataLinks)
      setAppDataLinks(_appDataLinks)
      setAppDataLinkData(_appDataLinkData)
    } catch (error) {
      console.error('UiAppDataLinks', error)
    }
  }, []);


  const handleCommit = () => {
    dataManagement_handlers.handleStartUpdate()
    dataManagement_handlers.handleUpdate_appDataLinks(appDataLinks)
  }

  const handleShowCommit = () => dataManagement_handlers.handleShowCommit_dataManagment(dataManagementType)
  const handleKillCommit = () => dataManagement_handlers.handleShowCommit_dataManagment()

  const collectionsContent = (d) => {
    const segs = []
    const { collections } = d
    _.forEach(collections, (collectionKey) => {
      segs.push(<Segment color='blue'>{_.startCase(collectionKey)}</Segment>)
      const cd = d[collectionKey]
      if (cd) {
        _.forEach(cd, (collectionItem, collectionItemKey) => {
          const { name, collections } = collectionItem
          segs.push(
            <Segment.Group>
              <Segment>{name}</Segment>
              <Segment>{collectionItemKey}</Segment>
            </Segment.Group>
          )
        })
      }
    })
    return segs
  }

  const documentsContent = () => {
    let segs = []
    if (appDataLinkData) {
      segs = _.map(appDataLinkData, (d, key) => {
        const { name } = d
        return <Segment.Group>
          <Segment>{name}</Segment>
          <Segment>{key}</Segment>
          {collectionsContent(d)}
        </Segment.Group>
      })
    }
    return <div className={'document-container'}>{segs}</div>
  }

  const footer = () => {
    return <UiSaveButtons
      save={{ oc: handleShowCommit, color: 'blue', caption: _.startCase(dataSidebarActionTypes.createAppDataLinks), icon: appIconTypes.arrowRight, disabled: appDataCount === 0 }}
    />
  }

  const sidebar_confirm = (visible) => <SidebarConfirm
    visible={visible}
    onHide={handleKillCommit}
    oc={handleCommit}
    header={_.startCase('confirmation')}
    content={'Are you sure you want update the app data links for this collection (' + _.startCase(viewItemKey) + ')?'}
    saveCaption={_.startCase(dataSidebarActionTypes.createAppDataLinks)}
    useCancel={true}
    updating={updating}
  />

  const container = () => <Wrapper
    content={appDataLinkData ? documentsContent() : <div></div>}
    footer={footer()}
    wrapperType={wrapperTypes.padded}
    updating={updating}
    confirmation={confirmation}
  />

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_confirm(showCommitDataManagment)}
    <Sidebar.Pusher dimmed={showCommitDataManagment} className={'h100'}>
      {container()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    topperCaption={'App Data Links'}
    topperCaption2={viewItemKey}
  />


  return inViewer ? sidebar() : fullPageWrapper()

}

export default UiAppDataLinks