import _ from 'lodash';
import React, { Suspense, createContext, useContext } from 'react';
import { DataContext } from '../../cnr/contexts/DataContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { signInMethodTypes } from '../../cnr/reducers/SignInReducer';
import SuspenseDimmer from '../../components/alerts/SuspenseDimmer';
import { gEnums } from '../../enums/globalEnums';
import { itemActionTypes } from '../../viewSettings/enums/itemActionTypes';
import { dataModificationDisplayTypes } from './UiDataModifications';

const AppUserTicketingWithProvider = React.lazy(() => import('../../../projectSpecific/ticketing/components/AppUserTicketing'));
const AppAccessRequests = React.lazy(() => import('../../components/access/AppAccessRequests'));
const AppUserProfileViewer = React.lazy(() => import('../../components/auth/AppUserProfileViewer'));
const AppUserView = React.lazy(() => import('../../appUsers/AppUserView'));
const CreateNotificationWizard = React.lazy(() => import('../../appNotifications/CreateNotificationWizard'));
const GeoLocationListWithProvider = React.lazy(() => import('../../components/map/GeoLocationList'));
const PageItemAccess = React.lazy(() => import('../../components/user/PageItemAccess'));
const QandAManager = React.lazy(() => import('../../components/questionsAndAnswers/QandAItem'));
const RatingsManager = React.lazy(() => import('./pageItemActions/RatingsManager'));
const QrCode = React.lazy(() => import('./pageItemActions/QrCode'));
const SearchPage = React.lazy(() => import('../../page/SearchPage'));
const SignIn = React.lazy(() => import('../../components/auth/SignIn'));
const SignInDirect = React.lazy(() => import('../../components/auth/SignInDirect'));
const UiAppUserAction = React.lazy(() => import('./userActions/UiAppUserAction'));
const UiCustomAdd = React.lazy(() => import('./dataModifications/UiCustomAdd'));
const UiDataManagement = React.lazy(() => import('./UiDataManagement'));
const UiDataModifications = React.lazy(() => import('./UiDataModifications'));
const UiEmailLinkAuth = React.lazy(() => import('../../components/auth/signInComponents/UiEmailLinkAuth'));
const UiPhoneAuth = React.lazy(() => import('../../components/auth/signInComponents/UiPhoneAuth'));
const UiRating = React.lazy(() => import('./userActions/UiRating'));
const UiRequestAccess = React.lazy(() => import('./userActions/UiRequestAccess'));
const UiViewAuth = React.lazy(() => import('./UiViewAuth'));
const UserConnections = React.lazy(() => import('../../components/user/UserConnections'));
const UserNotifications = React.lazy(() => import('../../components/user/UserNotifications'));
const VideoConference = React.lazy(() => import('../../../globalAlt/components/videoConference/VideoConference'));

export const ItemActionContext = createContext();

/** Returns the compnent for all of the actions triggered by the options button for the page item */
const UiItemAction = () => {

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state } = dataContext ?? {}
  const { viewListData: vld } = data_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state, item_handlers } = uiItemContext ?? {}
  const { modifyActionType, customAddType, appFormType, currentGroupKey } = item_state ?? {}
  const itemData = vld ? vld[Object.keys(vld)[0]] : {}

  const actionUi = () => {

    // active
    // add
    // clipboard
    // delete
    // edit
    // images
    // sorting
    // subAction 

    switch (modifyActionType) {

      case itemActionTypes.connectToAppUser:
        return <UserConnections modifyActionType={modifyActionType} handleCancel={item_handlers.handleShow_itemSidebar} />

      // catch all for dataActions
      case itemActionTypes.dataActions:
      case itemActionTypes.dataManagement:
        return <UiDataManagement />

      // catch all for dataModifications
      case itemActionTypes.dataModifications:
        return <UiDataModifications dataModificationDisplayType={dataModificationDisplayTypes.direct} />

      case itemActionTypes.geoMapList:
        return <GeoLocationListWithProvider fullScreen={true} />

      case itemActionTypes.ratingManager:
        return <RatingsManager />

      // pageItemActions
      case itemActionTypes.qAndAManager:
        return <QandAManager />

      case itemActionTypes.qrCode:
        return <QrCode itemData={itemData} qrCodeType={gEnums.qrCodeTypes.itemData} />

      // pageItemPermissions
      case itemActionTypes.itemLinking:
        return <UiAppUserAction />

      case itemActionTypes.rating:
        return <UiRating />

      case itemActionTypes.requestAccess:
        return <UiRequestAccess />

      case itemActionTypes.userEmailSignInLinks:
        return <AppUserView />

      case itemActionTypes.videoConferencing: // LOOK
        return <VideoConference />

      case itemActionTypes.notifications: // LOOK
        return <CreateNotificationWizard handleClose={item_handlers.handleShow_itemSidebar} currentGroupKey={currentGroupKey} notificationOriginType={gEnums.notificationOriginTypes.uiItem} />

      // not is use

      case itemActionTypes.appUserInfo:
        return <AppUserProfileViewer json={vld} name={_.startCase(modifyActionType)} fullScreen={true} />

      case itemActionTypes.assignPageItemAccess:
        return <PageItemAccess />

      case itemActionTypes.emailAuth:
        return <UiEmailLinkAuth />

      case itemActionTypes.phoneAuth:
        return <UiPhoneAuth directSignIn={true} item_state={item_state} handleCancel={item_handlers.handleShow_itemSidebar} />

      case itemActionTypes.search:
      case itemActionTypes.voiceSearch:
        return <SearchPage item_state={item_state} data_state={data_state} handleShowSearch={item_handlers.handleShow_itemSidebar} />

      case itemActionTypes.signInDirectEmail:
        return <SignInDirect />

      case itemActionTypes.signInDirectPhone:
        return <SignIn origin={'UiItemAction'} signInMethodType={signInMethodTypes.phone} handleCancel={item_handlers.handleShow_itemSidebar} itemData={itemData} />

      case itemActionTypes.ticketing:
        return <AppUserTicketingWithProvider />

      case itemActionTypes.userNotification:
        return <UserNotifications />

      case itemActionTypes.viewAccessRequests:
        return <AppAccessRequests viewRequest={true} />

      case itemActionTypes.viewAppUserAuthorizations:
        return <UiViewAuth />

      default:
        if (customAddType || appFormType) {
          return <UiCustomAdd />
        } else {
          return <UiDataModifications modifyActionType={modifyActionType} />
        }
    }
  }

  return <Suspense fallback={<SuspenseDimmer origin={'UiItemAction'} />}>
    <ItemActionContext.Provider value={{ uiItemContext }}>
      {actionUi()}
    </ItemActionContext.Provider>
  </Suspense>
}

export default UiItemAction