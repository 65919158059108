import { arrayUnion } from "firebase/firestore";
import { createInitials } from '../../common/dataAdjust';
import { gEnums } from '../../enums/globalEnums';
import { createRefPath } from "../../firestoreData/appData/appRefPaths";
import { fs_dbu } from "../../firestoreData/appData/fsAppDataUpdate";

export const createAppUser = (dispatch, authUid, email, userData, papsState) => {

  const { pathViews } = papsState ?? {}

  const ai = { ...userData }

  delete ai.id
  delete ai.key
  delete ai.lastName
  delete ai.firstName
  delete ai.initials

  ai.pathViews = pathViews

  const user = {
    email: email ? email : userData.email,
    displayName: userData.firstName + ' ' + userData.lastName,
    initials: createInitials(userData.firstName.trim() + ' ' + userData.lastName.trim()),
    accessLevel: gEnums.accessLevels.user.value,
    additionalInfo: arrayUnion(ai)
  }

  fs_dbu.set_doc(createRefPath(['users', authUid]), user, false, dispatch)

}