
import { initializeApp } from 'firebase/app';
import { fbAppConfigs } from '../../../project/appConfigs';
import { _projectAppNames, _useMeDb, getAppName } from '../../../project/appConfiguration';
import { grts } from './reducerHelpers/dispatchProps';
import { fs_db } from '../../firestoreData/appData/fsAppData';


const rts = {
  handleInit_database: 'handleInit_database',
  handleChange_database: 'handleChange_database',
  handleAmmend_refPaths: 'handleAmmend_refPaths',
  ...grts
}

export const databaseReducer = (state, action) => {

  let { type, projectAppName } = action ?? {}

  switch (type) {

    case rts.handleInit_database:

      const appName = _useMeDb ? _projectAppNames.meetingevolution : _projectAppNames.pojo
      const _appName = projectAppName ? projectAppName : appName

      let _environment;

      switch (_appName) {
        case _projectAppNames.meetingevolution:
        case _projectAppNames.thumbstat:
        case _projectAppNames.cardnudge:
        case _projectAppNames.pojo:
          _environment = 'dev'
          break;
        default:
      }

      const _config = fbAppConfigs[_appName][_environment]
      const fsInit = initializeApp(_config, _appName)
      const fsdb = fs_db.get_fs(fsInit)

      return { ...state, fsdb, fsdbName: _appName }

    case rts.handleChange_database:

      let _environment_change;

      switch (projectAppName) {
        case _projectAppNames.meetingevolution:
        case _projectAppNames.thumbstat:
        case _projectAppNames.cardnudge:
        case _projectAppNames.pojo:
          _environment_change = 'dev'
          break;
        default:
      }

      const _config_change = fbAppConfigs[projectAppName][_environment_change]
      const fsInit_change = initializeApp(_config_change, projectAppName)
      const fsdb_change = fs_db.get_fs(fsInit_change)

      return { ...state, fsdb: fsdb_change, fsdbName: projectAppName }

    case rts.handleAmmend_refPaths:
      const _refPaths = [...state.refPaths]
      _refPaths.push(action.refPath)
      return { ...state, refPaths: _refPaths }

    default:
      return { ...state }
  }
}

export const databaseHandlers = (dispatch) => {
  return {
    handleChange_database: (projectAppName) => { dispatch({ type: rts.handleChange_database, dispatch, projectAppName }) },
    handleInit_database: (projectAppName) => { dispatch({ type: rts.handleInit_database, dispatch, projectAppName }) },
    handleAmmend_refPaths: (refPath) => { dispatch({ type: rts.handleAmmend_refPaths, dispatch, refPath }) },
  }
}

export const databaseInitialState = (initState) => {
  return { ...initState, refPaths: [] }
};
