import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext'
import { gEnums } from '../../../../global/enums/globalEnums'
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext'
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext'
import { matchScheduleTypes, SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'
import MatchScoreKeypad, { _keypadTypes } from './MatchScoreKeypad'
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext'
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions'
import { _matchResultTypes } from '../../cnr/reducers/SportsMatchReducer'

const WAIT_INTERVAL = 1000

const _matchScoreModes = {
  normal: 'normal',
  showMatchAmmend: 'showMatchAmmend',
  showConfirmRemove: 'showConfirmRemove',
  showConfirmMatch: 'showConfirmMatch',
  showConfirmSet: 'showConfirmSet',
  showKeyPad: 'showKeyPad'
}

const MatchScoreButtons = () => {

  const parentContext = useContext(ParentContext)
  const { states, fns } = parentContext ?? {}
  const { appUser_state } = states ?? {}
  const { appUsers } = appUser_state ?? {}
  const _appUserAccess = getAppUserAccess(appUsers)
  const { displayName } = _appUserAccess
  const { appUser_fns } = fns
  const accessLevel = appUser_fns.get_accessLevel()
  const allowMatchEdit = accessLevel >= gEnums.accessLevels.superAdmin.value

  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext
  const { sportData } = sportsSeason_state ?? {}
  const { useRtGameDayData } = sportData ?? {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state, seasonMatches_handlers } = seasonMatchesContext ?? {}
  const { matchScheduleType } = seasonMatches_state ?? {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_handlers, sportsMatch_state } = sportsMatchContext ?? {}
  const { match_true, sportPermissions } = sportsMatch_state ?? {}

  // sportsRtMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state, sportsRtMatch_handlers } = sportsRtMatchContext ?? {}
  const { match_rt, match_key } = sportsRtMatch_state ?? {}
  const { _results } = match_rt ?? {}
  const { setNumber, set_complete_possible, match_complete_possible } = _results ?? {}

  const [restrictAccess, setRestrictAccess] = useState()

  const [matchScoreMode, setMatchScoreMode] = useState(_matchScoreModes.normal)

  const _allowRemoveRts = sportPermissions && sportPermissions.any && sportPermissions.rtMatches ? true : false

  useEffect(() => {
    if (restrictAccess) {
      const timer = setTimeout(() => setRestrictAccess(false), WAIT_INTERVAL);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictAccess]);

  useEffect(() => {
    if (!match_rt && sportsRtMatch_handlers) {
      sportsRtMatch_handlers.handleSet_rtMatch(match_true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStopProp = (e) => {
    e.stopPropagation()
  }

  const handleScoreUpdate = (e, rtType, add) => {
    e.stopPropagation()
    setRestrictAccess(true)
    !restrictAccess && sportsRtMatch_handlers.handleRtUpdate_score(match_rt, rtType, add)
  }

  const handleMatchScoreMode = (e, sms) => {
    e.stopPropagation()
    setMatchScoreMode(sms ? sms : _matchScoreModes.normal)
  }

  const handleRemoveRtMatch = (e) => {
    e.stopPropagation()
    setRestrictAccess(true)
    setMatchScoreMode(_matchScoreModes.normal)
    sportsRtMatch_handlers.handleRtDelete_scores()
    seasonMatches_handlers.handleSelect_currentMatch()
  }

  const handle_updateMatchResults = (e) => {
    e.stopPropagation()
    const cb = () => {
      setMatchScoreMode(_matchScoreModes.normal)
      sportsSeason_handlers.handleUpdate_sportsSeasonTs()
      sportsRtMatch_handlers.handleRtDelete_scores()
      seasonMatches_handlers.handleSelect_currentMatch()
    }
    console.log('match_rt', match_rt)
    console.log('sportsMatch_handlers', sportsMatch_handlers)
    console.log('seasonMatches_handlers', seasonMatches_handlers)
    sportsMatch_handlers.handleUpdate_matchResults(match_rt, _matchResultTypes.realtime, cb)
  }

  const handleScoreClose = (e) => {
    e.stopPropagation()
    seasonMatches_handlers.handleSelect_currentMatch()
  }

  const rtScores = {
    home: 0,
    away: 0,
    setNumber: 0
  }

  if (match_rt) {

    const { results } = match_rt ?? {}
    const { scores } = results ?? {}

    if (scores) {
      delete scores[0]
      _.forEach(scores, (_score, setNumber) => {
        if (_score.home || _.isNumber(_score.home)) {
          rtScores.home = {
            score: _score.home,
            color: _score.home > _score.away ? 'green' : _score.home < _score.away ? 'red' : 'blue',
            cn: _score.home > _score.away ? 'up' : _score.home < _score.away ? 'down' : 'tie'
          }
          rtScores.setNumber = setNumber
        }
        if (_score.away || _.isNumber(_score.away)) {
          rtScores.away = {
            score: _score.away,
            color: _score.away > _score.home ? 'green' : _score.away < _score.home ? 'red' : 'blue',
            cn: _score.away > _score.home ? 'up' : _score.away < _score.home ? 'down' : 'tie'
          }
          rtScores.setNumber = setNumber
        }
      })
    }
  }

  const content_remove = (e) => <div className='match-score-btns rmv'>
    <Button.Group size='mini' fluid >
      <Button icon='delete' color={'grey'} onClick={(e) => { handleMatchScoreMode(e, _matchScoreModes.showMatchAmmend) }} />
      <Button color={_allowRemoveRts ? 'red' : 'grey'} fluid onClick={(e) => { handleRemoveRtMatch(e) }} >{'Confirm Remove RTS'}</Button>
    </Button.Group>
  </div>

  const content_confirm = (e) => <div className='match-score-btns rmv'>
    <Button.Group size='mini' fluid >
      <Button icon='delete' color={'grey'} onClick={(e) => { handleMatchScoreMode(e, _matchScoreModes.normal) }} />
      <Button color={'green'} fluid onClick={(e) => { handle_updateMatchResults(e) }} >{useRtGameDayData ? 'Confirm Match Result RT' : 'Confirm Match Result'}</Button>
    </Button.Group>
  </div>

  /**
   * 
   * @param {*} e 
   * @returns a group of buttons to modify the result
   */
  const content_ammend = (e) => <div className='match-score-btns amd' onClick={(e) => { handleMatchScoreMode(e, _matchScoreModes.normal) }}>
    <Button.Group size='mini' fluid >
      <Button icon='arrow left' color={'black'} onClick={(e) => { handleMatchScoreMode(e, _matchScoreModes.showMatchAmmend) }} />
      <Button icon='delete' color={'grey'} onClick={(e) => { handleScoreClose(e) }} />
      <Button color={'blue'} onClick={(e) => { handleMatchScoreMode(e, _matchScoreModes.showConfirmRemove) }} >{'Remove RTS'}</Button>
      {set_complete_possible && !match_complete_possible && <Button color={'blue'} disabled={!set_complete_possible} onClick={(e) => { handleMatchScoreMode(e, _matchScoreModes.showConfirmSet) }} >{'Confirm Set ' + setNumber}</Button>}
      {match_complete_possible && <Button color={'blue'} disabled={!match_complete_possible} onClick={(e) => { handleMatchScoreMode(e, _matchScoreModes.showConfirmMatch) }} >{'Confirm Match'}</Button>}
      <Button icon='keyboard outline' color={'blue'} onClick={(e) => { handleMatchScoreMode(e, _matchScoreModes.showKeyPad) }} />
    </Button.Group>
  </div>

  const teamSetScoreButtons = (score, team, t) => {
    let cn = matchScheduleType === matchScheduleTypes.latest ? 'team-score-' + t : t
    return <Button.Group size='mini' >
      <Button icon='arrow left' color={!restrictAccess ? 'red' : 'grey'} onClick={(e) => { handleScoreUpdate(e, team, false) }} />
      <Button content={score.score} className={cn} color='black' />
      <Button icon='arrow right' color={!restrictAccess ? 'green' : 'grey'} onClick={(e) => { handleScoreUpdate(e, team, true) }} />
    </Button.Group>
  }

  /**
   * 
   * @returns a group of buttons to update the score
   */
  const content_scoring = () => <div className='match-score-btns' onClick={(e) => { handleStopProp(e) }}>
    {teamSetScoreButtons(rtScores.away, 'away', 'away ' + rtScores.away.cn)}
    <div>
      {allowMatchEdit && !set_complete_possible && !match_complete_possible && <Icon bordered name='tasks' color='blue' onClick={(e) => { handleMatchScoreMode(e, _matchScoreModes.showMatchAmmend) }} />}
      {allowMatchEdit && set_complete_possible && !match_complete_possible && <Button size={'mini'} color={'blue'} disabled={!set_complete_possible} onClick={(e) => { handleMatchScoreMode(e, _matchScoreModes.showConfirmSet) }} >{'Confirm Set ' + setNumber}</Button>}
      {allowMatchEdit && match_complete_possible && <Button size={'mini'} color={'blue'} disabled={!set_complete_possible} onClick={(e) => { handleMatchScoreMode(e, _matchScoreModes.showConfirmMatch) }} >{'Confirm Match'}</Button>}
    </div >
    {teamSetScoreButtons(rtScores.home, 'home', 'home ' + rtScores.home.cn)}
  </div >

  const matchKeypad = () => <div className='match-score-btns kpd' >
    <MatchScoreKeypad keypadType={_keypadTypes.realtime} handleClose={handleMatchScoreMode} />
  </div>

  switch (matchScoreMode) {
    case _matchScoreModes.showConfirmMatch:
      return content_confirm()
    case _matchScoreModes.showConfirmRemove:
      return content_remove()
    case _matchScoreModes.showKeyPad:
      return matchKeypad()
    case _matchScoreModes.showMatchAmmend:
      return content_ammend()
    default:
      return content_scoring()
  }

}

export default MatchScoreButtons