import _ from 'lodash';
import React from 'react';
import { Menu } from 'semantic-ui-react';
import { sidebarSliderTypes } from '../../cnr/reducers/SidebarReducer';
import { formatItem, formatTypes } from '../../common/dateFormatting';
import { uniqueKey } from '../../common/keys';
import MenuItemCenter from './MenuItemCenter';
import MenuItemLR from './MenuItemLR';
import { menuWidths } from './menuSlider';

const MenuBaseSlider = (props) => {

  const { mbsProps } = props
  const { sidebarItem, sidebar_handlers, type, opts } = mbsProps ?? {}

  if (!sidebarItem) return

  const { selected, keyAs, useStartCase, sidebarSliderType } = sidebarItem ?? {}
  const { item: currentItem } = selected ?? {}
  const { handleGenericMenuClick: handleMenuClick } = sidebar_handlers

  const { count, size, inverted, sidebarType, label, useLabels } = opts ?? {}

  const clickType = keyAs ? keyAs : type

  let disabled;
  if (count && count <= 1) { disabled = true }

  const menuItemEmpty = (index, width, keyAs) => <Menu.Item key={uniqueKey('mi', index)} style={{ width: width }} >
    {'No ' + keyAs}
  </Menu.Item>

  const menuItemLR = (direction, handleMenuClick, width, type, disabled) => <MenuItemLR key={uniqueKey('milr', direction)} menuProps={{ direction, handleMenuClick, width, type, disabled }} />
  const menuItemCenter = (index, value, handleMenuClick, width, type, disabled, formattedItem) => <MenuItemCenter key={uniqueKey('milr', 'c')} menuProps={{ index, value, handleMenuClick, width, type, disabled, formattedItem, label: useLabels ? _.startCase(keyAs) : label }} />

  const menuItemRow = () => {
    const mis = []
    if (currentItem) {
      let formattedItem;
      if (sidebarSliderType === sidebarSliderTypes.calendar) { formattedItem = formatItem(formatTypes.fullDate, currentItem) }
      if (sidebarSliderType !== sidebarSliderTypes.calendar && useStartCase) { formattedItem = _.startCase(currentItem) }
      sidebarSliderType !== sidebarSliderTypes.normalNoLr && mis.push(menuItemLR('back', handleMenuClick, menuWidths['3'].lr, clickType, disabled))
      mis.push(menuItemCenter(0, currentItem, handleMenuClick, menuWidths['3'].mid, clickType, disabled, formattedItem))
      sidebarSliderType !== sidebarSliderTypes.normalNoLr && mis.push(menuItemLR('forward', handleMenuClick, menuWidths['3'].lr, clickType, disabled))
    } else {
      mis.push(menuItemEmpty(0, menuWidths['1'].full, keyAs))
    }
    return mis
  }

  const keyy = sidebarType ? clickType + '-' + sidebarType : clickType

  return <Menu key={uniqueKey('mbs', keyy)} widths={6} className='menu-slider bs' size={size ? size : null} inverted={inverted}>
    {menuItemRow()}
  </Menu>
  //  :
  //   <React.Fragment>
  //     <div key={uniqueKey(keyy, 1)} className={'push-caption'}><label>{label}</label></div>
  //     <div key={uniqueKey(keyy, 2)}><Menu key={uniqueKey(keyy, 3)} widths={6} className='menu-slider bs' size={size ? size : null} inverted={inverted}>
  //       {menuItemRow()}
  //     </Menu>
  //     </div>
  //   </React.Fragment>

}

export default MenuBaseSlider