import _ from 'lodash'
import { _globalCollectionName, _linkedDataCollectionName } from "../../../projectSpecific/sports/dbActions/globals"
import { createStaticViewFromDataCollections } from '../../common/convert'
import { createInitials } from '../../common/dataAdjust'
import { dataFix } from "../../common/dataFix"
import { ammendAllDates } from '../../common/dateFormatting'
import { copyObj } from "../../common_web/copy"
import { createRefPath_client, createRefPath_event } from "../../firestoreData/appData/appRefPaths"
import { fs_dbu } from '../../firestoreData/appData/fsAppDataUpdate'
import { getAppProfileData } from "../../firestoreData/helpers/appProfileData"
import { getStaticViews } from "../../firestoreData/staticViews/updateStaticViews"
import { updateLinkHelpers } from '../../firestoreData/updates/subUpdates/fsUpdate_dataLInks'
import { updateDocumentHelpers } from '../../firestoreData/updates/subUpdates/fsUpdate_document'
import { _appUserPresenceCollection } from "./AppUserReducer"
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps"
import { timeStampsHelpers } from '../../firestoreData/updates/subUpdates/fsTimeStamps'

const rts = {
  handleClear_appProfileData: 'handleClear_appProfileData',
  handleCreate_appDataDocuments: 'handleCreate_appDataDocuments',
  handleCreate_appDataLinks: 'handleCreate_appDataLinks',
  handleCreate_appProfiles: 'handleCreate_appProfiles',
  handleCreate_staticViews: 'handleCreate_staticViews',
  handleGet_appProfileData: 'handleGet_appProfileData',
  handleGet_appUserPresence: 'handleGet_appUserPresence',
  handleGet_eventStaticViews: 'handleGet_eventStaticViews',
  handleGet_startDataCollections: 'handleGet_startDataCollections',
  handleInit_event: 'handleInit_event',
  handleSet_appDataDocuments: 'handleSet_appDataDocuments',
  handleSet_appDataLinks: 'handleSet_appDataLinks',
  handleSet_appGlobalData: 'handleSet_appGlobalData',
  handleSet_appProfileData: 'handleSet_appProfileData',
  handleSet_appUserPresence: 'handleSet_appUserPresence',
  handleSet_appUserUrls: 'handleSet_appUserUrls',
  handleSet_eventCacheData: 'handleSet_eventCacheData',
  handleSet_eventInitData: 'handleSet_eventInitData',
  handleSet_eventStaticViews: 'handleSet_eventStaticViews',
  handleSet_googleSheetsStaticViews: 'handleSet_googleSheetsStaticViews',
  handleUpdate_eventStatus: 'handleUpdate_eventStatus',
  ...grts
}

const eventCreateTypes = {
  appDataDocuments: 'appDataDocuments',
  appDataLinks: 'appDataLinks',
  staticViews: 'staticViews',
}

export const eventInfoReducer = (state, action) => {

  const { eventCollectionsData, appDataDocuments, cache_eventInfo, database_fns } = state
  const { type, dispatch, pathViews } = action

  const eventInfo_handlers = eventInfoHandlers(dispatch)
  const { handleSet_appDataDocuments, handleSet_appDataLinks, handleSet_eventInitData, handleSet_appGlobalData, handleSet_eventStaticViews, handleSet_appProfileData, handleSet_appUserPresence } = eventInfo_handlers

  const _eventCollectionsData = eventCollectionsData ? copyObj(eventCollectionsData) : {}

  // metldev@2024! 

  switch (type) {

    case rts.handleSet_eventCacheData:
      const { cache_eventInfo } = action
      const { appGlobalData } = cache_eventInfo ?? {}
      if (!appGlobalData) {
        let _refPathG = createRefPath_event(pathViews, [_globalCollectionName])
        database_fns.get_data({ refPath: _refPathG, callback: handleSet_appGlobalData, opts: { ignoreId: true } })
      }
      return { ...state, cache_eventInfo, appGlobalData, appGlobalDataFroFvCache: appGlobalData ? true : false }

    case rts.handleSet_appGlobalData:
      return { ...state, appGlobalData: action.d, appGlobalDataFromCache: action.settingsSourceType }

    case rts.handleInit_event:
      const { init } = action
      const { appDataSource } = init ?? {}
      const { appDataFetchType, useAppDataDocuments, useAppDataLinks } = appDataSource ?? {}
      if (useAppDataDocuments) { getAllSingleDocuments(database_fns, init, handleSet_appDataDocuments) }
      if (useAppDataLinks) { getAppDataLinks(database_fns, init, handleSet_appDataLinks) }
      return { ...state, ...init }

    case rts.handleSet_appDataDocuments:
      const { appDataDocuments: appDd } = action
      ammendAllDates(appDd)
      return { ...state, appDataDocuments: appDd }

    case rts.handleSet_appDataLinks:
      return { ...state, appDataLinks: action.appDataLinks }

    case rts.handleGet_startDataCollections:

      const cb = (data, cbProps) => {
        handleSet_eventInitData(data, cbProps)
      }
      const { getAllDataCollections, startDataCollections, collectionItems } = action

      if (getAllDataCollections) {
        if (collectionItems && collectionItems.length > 0) {
          collectionItems.forEach(ic => {
            const _refPath = createRefPath_event(pathViews, [ic])
            database_fns.get_data({ refPath: _refPath, callback: cb, opts: { listen: true, cbProps: { collectionName: ic } } })
          })
        }
      } else if (startDataCollections && startDataCollections.length > 0) {
        startDataCollections.forEach(ic => {
          const _refPath = createRefPath_event(pathViews, [ic])
          database_fns.get_data({ refPath: _refPath, callback: cb, opts: { listen: true, cbProps: { collectionName: ic } } })
        })
      }
      return { ...state }

    case rts.handleSet_eventInitData:

      const { data, cbProps } = action
      const { collectionName } = cbProps
      _eventCollectionsData[collectionName] = data
      const collectionKeys = Object.keys(_eventCollectionsData)

      const _linkedCollections = {}

      Object.keys(_eventCollectionsData).forEach(collectionItemKey => {
        _linkedCollections[collectionItemKey] = {}
        const cd = _eventCollectionsData[collectionItemKey]
        Object.keys(cd).forEach(collectionDataKey => {
          const cdi = cd[collectionDataKey]
          Object.keys(cdi).forEach(collectionDataItemKey => {
            if (collectionKeys.includes(collectionDataItemKey) && !_linkedCollections[collectionItemKey][collectionDataItemKey]) {
              _linkedCollections[collectionItemKey][collectionDataItemKey] = cdi[collectionDataItemKey]
            }
          })
        })
      })

      return {
        ...state,
        eventCollectionsData: _eventCollectionsData
      }

    case rts.handleGet_eventStaticViews:
      getStaticViews(database_fns, action.pathViews, handleSet_eventStaticViews)
      return { ...state }


    case rts.handleGet_appUserPresence:
      let _refPathP = createRefPath_event(action.pathViews, [_appUserPresenceCollection])
      database_fns.get_data({ refPath: _refPathP, callback: handleSet_appUserPresence, opts: { ignoreId: true, listen: true } })
      return { ...state }

    case rts.handleSet_appUserPresence:
      let _appUsersPresense = { online: null, offline: null }
      if (action.results && action.results.appUsers) {
        const _appUsers = action.results.appUsers
        _appUsersPresense = _.groupBy(_appUsers, 'status')
      }
      return { ...state, appUsersPresense: _appUsersPresense }

    case rts.handleClear_appProfileData:
      return { ...state, appProfileData: null }

    case rts.handleGet_appProfileData:
      getAppProfileData({ appDataDocuments: state.appDataDocuments, appUserCollections: state.appUserCollections, aps_viewItems: state.aps_viewItems, pathViews: state.pathViews }, handleSet_appProfileData)
      return { ...state }

    case rts.handleSet_appProfileData:
      return { ...state, appProfileData: action.d }

    case rts.handleSet_eventStaticViews:
      let { staticViews, pathViews: pathViews_results } = action.results ?? {}
      if (_.isEmpty(staticViews) && appDataDocuments) {
        staticViews = createStaticViewFromDataCollections(appDataDocuments, state.aps_viewItems)
        console.log('staticViews', staticViews)
      }
      return { ...state, staticEventKey: pathViews_results.events, staticViews, staticViewKeys: staticViews ? Object.keys(staticViews) : [] }

    case rts.handleSet_appUserUrls:
      return { ...state, appUserUrls: action.profileUrls }

    case rts.handleSet_googleSheetsStaticViews:
      return { ...state, staticViews_gs: action.staticViews_gs }

    case rts.handleUpdate_eventStatus:
      const { events: eventKey } = pathViews
      const _eventData = copyObj(action.eventData)
      dataFix.ammendDeleteFields(_eventData)
      const _refPath = createRefPath_client(pathViews, ['events', eventKey])
      // fs_dbu.set_doc(_refPath, _eventData, true)
      return { ...state }

    case rts.handleCreate_appDataDocuments:
      createEventData(database_fns, state, eventCreateTypes.appDataDocuments)
      return { ...state }

    case rts.handleCreate_appDataLinks:
      createEventData(database_fns, state, eventCreateTypes.appDataLinks)
      return { ...state }

    case rts.handleCreate_staticViews:
      createEventData(database_fns, state, eventCreateTypes.staticViews)
      return { ...state }

    case rts.handleCreate_appProfiles:
      createAppProfiles(state)
      return { ...state }

    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
    case rts.handleCloseConfirmation:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation, questionProps: null })

    default:
      return { ...state }
  }
}

export const eventInfoInitialState = (initState) => {
  return { ...initState, appData: {}, eventCollectionsData: {}, missingSvDatas: {} }
};

export const eventInfoHandlers = (dispatch) => {
  return {
    handleClear_appProfileData: () => { dispatch({ type: rts.handleClear_appProfileData, dispatch }) },
    handleCreate_appDataDocuments: () => { dispatch({ type: rts.handleCreate_appDataDocuments, dispatch }) },
    handleCreate_appDataLinks: () => { dispatch({ type: rts.handleCreate_appDataLinks, dispatch }) },
    handleCreate_appProfiles: () => { dispatch({ type: rts.handleCreate_appProfiles, dispatch }) },
    handleCreate_staticViews: () => { dispatch({ type: rts.handleCreate_staticViews, dispatch }) },
    handleGet_appProfileData: () => { dispatch({ type: rts.handleGet_appProfileData, dispatch }) },
    handleGet_appUserPresence: (pathViews) => { dispatch({ type: rts.handleGet_appUserPresence, dispatch, pathViews }) },
    handleGet_eventStaticViews: (pathViews) => { dispatch({ type: rts.handleGet_eventStaticViews, dispatch, pathViews }) },
    handleGet_startDataCollections: (startDataCollections, getAllDataCollections, collectionItems, pathViews) => { dispatch({ type: rts.handleGet_startDataCollections, dispatch, startDataCollections, getAllDataCollections, collectionItems, pathViews }) },
    handleInit_event: (init) => { dispatch({ type: rts.handleInit_event, dispatch, init }) },
    handleSet_appDataDocuments: (appDataDocuments, linkedData) => { dispatch({ type: rts.handleSet_appDataDocuments, dispatch, appDataDocuments, linkedData }) },
    handleSet_appDataLinks: (appDataLinks) => { dispatch({ type: rts.handleSet_appDataLinks, dispatch, appDataLinks }) },
    handleSet_appGlobalData: (d, fromCache) => { dispatch({ type: rts.handleSet_appGlobalData, dispatch, d, fromCache }) },
    handleSet_appProfileData: (d) => { dispatch({ type: rts.handleSet_appProfileData, dispatch, d }) },
    handleSet_appUserPresence: (results) => { dispatch({ type: rts.handleSet_appUserPresence, dispatch, results }) },
    handleSet_appUserUrls: (profileUrls) => { dispatch({ type: rts.handleSet_appUserUrls, dispatch, profileUrls }) },
    handleSet_eventCacheData: (pathViews, cache_eventInfo) => { dispatch({ type: rts.handleSet_eventCacheData, dispatch, pathViews, cache_eventInfo }) },
    handleSet_eventInitData: (data, cbProps) => { dispatch({ type: rts.handleSet_eventInitData, dispatch, data, cbProps }) },
    handleSet_eventStaticViews: (results) => { dispatch({ type: rts.handleSet_eventStaticViews, dispatch, results }) },
    handleSet_googleSheetsStaticViews: (staticViews_gs) => { dispatch({ type: rts.handleSet_googleSheetsStaticViews, dispatch, staticViews_gs }) },
    handleUpdate_eventStatus: (eventData) => { dispatch({ type: rts.handleUpdate_eventStatus, dispatch, eventData }) },
    ...responseHandlers(dispatch)
  }
}

function hasProp(items, key) {
  return _.some(items, (item) => _.has(item, key));
}

/**
 * Gets the data from _documents collection
 * @param {object} init 
 * @param {function} callback 
 */
const getAllSingleDocuments = (database_fns, init, callback) => {

  const { pathViews } = init ?? {}

  const cb = (data) => {
    const linkedData = getSingleDocumentLinks(pathViews, data)
    callback(data, linkedData)
  }

  updateDocumentHelpers.get_allDocuments(database_fns, pathViews, cb)
}

const getAppDataLinks = (database_fns, init, callback) => {

  const { pathViews } = init ?? {}

  const cb = (appDataLinks) => {
    // const linkedData = getSingleDocumentLinks(pathViews, appDataLinks)
    callback(appDataLinks)
  }

  const _refPath = createRefPath_event(pathViews, [_linkedDataCollectionName])

  database_fns.get_data({ refPath: _refPath, callback: cb, opts: { ignoreId: true } })

}

const getSingleDocumentLinks = (pathViews, appDataDocuments, allowUpdate) => {
  const _linkedData = {}
  if (appDataDocuments) {
    const _sdks = _.keys(appDataDocuments)
    _.forEach(appDataDocuments, (appDataDocument, docKey) => { // docKey = attendees, sessions, etc.
      _.forEach(appDataDocument, (appDataDocumentItem, docItemKey) => { // docItemKey = attendeeKey, sessionKey, etc.
        _.forEach(appDataDocumentItem, (docPropValue, docProp) => { // docProp = name, attendees, sessions, etc.
          // if the docPropValue is an array AND the docProp is a key in the appDataDocuments
          if (_sdks.includes(docProp) && _.isArray(docPropValue)) {
            // create the OBEJCT if needed
            if (!_linkedData[docKey]) { _linkedData[docKey] = {} }
            if (!_linkedData[docKey][docItemKey]) { _linkedData[docKey][docItemKey] = {} }
            _linkedData[docKey][docItemKey][docProp] = docPropValue
          }
        })
      })
    })
  }

  if (allowUpdate) {
    _.forEach(_linkedData, (data, key) => {
      const _refPathL = createRefPath_event(pathViews, [_linkedDataCollectionName, key])
      fs_dbu.set_doc(_refPathL, data, true).then(res => {
      }).catch(error => {
        console.log('error', error)
      })
    })
  }

  return _linkedData

}

const createEventData = async (database_fns, state, eventCreateType) => {

  const { pathViews, aps_viewItems: viewItems, staticViews } = state

  const xxx = await getAllEventDataPromise(database_fns, pathViews, viewItems)

  let count = 0

  const documentsData = {}
  _.forEach(viewItems, (viewItem, viewItemKey) => {
    if (!_.isEmpty(xxx[count])) {
      documentsData[viewItemKey] = xxx[count]
    }
    count++
  })

  switch (eventCreateType) {
    case eventCreateTypes.appDataDocuments:
      const updateResults = await updateDocumentHelpers.update_allDocuments(pathViews, documentsData)
      const timeStampResults = await timeStampsHelpers.updateEventTimeStamps_db(pathViews, documentsData)
      return updateResults
    case eventCreateTypes.appDataLinks:
      const yyy = await createAppLinks(pathViews, staticViews, documentsData)
      return yyy
  }

}

const getAllEventDataPromise = (database_fns, pathViews, viewItems) => {
  const promises = []
  _.forEach(viewItems, (viewItem, viewItemKey) => {
    const _refPath = createRefPath_event(pathViews, [viewItemKey])
    promises.push(database_fns.get_data({ refPath: _refPath, opts: { cbProps: { collectionName: viewItemKey } } }))
  })
  return Promise.all(promises)
}

const findSessionsByAttendeeKey = (appDataDocuments) => {
  const { sessions } = appDataDocuments ?? {}
  const attendeeKey = '664C327D032AA'
  return _.filter(sessions, session => _.includes(session.attendees, attendeeKey));
};

const createAppLinks = async (pathViews, staticViews, documentsData) => {
  const cb = (data) => {
    console.log('data', data)
  }
  const appDataLinks = {}
  _.forEach(documentsData, (appData, viewItemKey) => {
    const linkData = updateLinkHelpers.get_appLinkedData(pathViews, viewItemKey, appData, staticViews)
    if (!_.isEmpty(linkData)) {
      appDataLinks[viewItemKey] = linkData[viewItemKey]
    }
  })
  // updateLinkHelpers.update_appLinkedData(pathViews, appDataLinks, cb)
  console.log('links', appDataLinks)
}

const createAppProfiles = async (state) => {
  const { appUserCollections, appDataDocuments, pathViews, mainFixedCaption } = state ?? {}
  const { events: eventKey } = pathViews ?? {}
  if (appUserCollections && appDataDocuments) {
    const profiles = {}
    _.forEach(appUserCollections, (appUserCollection) => {
      const appDataDocument = appDataDocuments[appUserCollection]
      _.forEach(appDataDocument, (appDataDoc, _itemKey) => {
        const { email, firstName, lastName } = appDataDoc ?? {}
        profiles[email] = {
          email,
          displayName: firstName + ' ' + lastName,
          initials: createInitials(firstName + ' ' + lastName),
          events: {
            [eventKey]: {
              appUserKey: _itemKey,
              name: mainFixedCaption
            }
          }
        }
      })
    })
    await createProfiles(profiles, pathViews)
  }
}

const createProfiles = async (profiles, pathViews) => {
  const promises = []
  _.forEach(profiles, (profile, key) => {
    const refPath = createRefPath_client(pathViews, ['profiles', key])
    promises.push(fs_dbu.set_doc(refPath, profile))
  })
  return Promise.all(promises)
}