import _ from 'lodash'
import { creatingHelpers } from "../../common/creating"
import { gEnums } from "../../enums/globalEnums"
import { updateNotifications } from "../../firestoreData/notifications/notificationsData"
import { fsfn_notifications } from "../../functions/fbNotifications"
import { getSendNotificationProps, sendTheScheduledNotification } from "../../functions/fbScheduledNotifications"

export const stepTypes = {
  appUserList: 'appUserList',
  appUserCollectionList: 'appUserCollectionList',
  appProfileData: 'appProfileData',
  confirmation: 'confirmation',
  deliveryType: 'deliveryType',
  filters: 'filters',
  notification: 'notification',
  notificationTarget: 'notificationTarget',
  notificationOptions: 'notificationOptions',
  scheduleType: 'scheduleType',
}

// clients_w4wsbHrmO5Uw5GL14M1t_events_6384B90ECDF2B
// clients_w4wsbHrmO5Uw5GL14M1t_events_6384B90ECDF2B"

export const nrts = {
  handleCancel: 'handleCancel',
  handleError: 'handleError',
  handleNotificationData: 'handleNotificationData',
  handleNotificationFormData: 'handleNotificationFormData',
  handleNotificationSave: 'handleNotificationSave',
  handleNotificationSend: 'handleNotificationSend',
  handlePageItem: 'handlePageItem',
  handleFormDataChange_local: 'handleFormDataChange_local',
  handleSelectedFilters: 'handleSelectedFilters',
  handleSelectedPageItem: 'handleSelectedPageItem',
  handleSelectedTargets: 'handleSelectedTargets',
  handleSelectedAppUsers: 'handleSelectedAppUsers',
  handleSelectFilter: 'handleSelectFilter',
  handleSendToSelf: 'handleSendToSelf',
  handleSetTopicGroups: 'handleSetTopicGroups',
  handleStep: 'handleStep',
  handleSuccess: 'handleSuccess',
  handletNotificationData: 'handletNotificationData',
  handleTopicOrigin: 'handleTopicOrigin',
  handletSelectedFilter: 'handleSelectedFilter',
}

export const notificationItemReducer = (state, action) => {

  const {
    activeNote,
    appProfileData,
    appUserAccess,
    appUserCollection,
    appUserViewTypeProp,
    currentGroupKey,
    handleClose,
    notificationData,
    page_fns,
    pathViews,
    selectedTopicGroups,
    step,
    stepCount,
    allowNotificationTopics,
  } = state

  const { type, dispatch } = action

  const _notification = { ...notificationData, currentGroupKey, appUserViewTypeProp, appUserCollection }

  const { handleSuccess } = notificationItemHandlers(dispatch)

  const _notificationData = { ...notificationData }
  console.log('notificationItemReducer', action)

  switch (type) {

    // SAVE the notification to the database
    case nrts.handleNotificationSave:
      Object.keys(_notification).forEach(key => {
        if (!_notification[key]) { delete _notification[key] }
      })
      updateNotifications(pathViews, page_fns, _notification, activeNote, handleSuccess)
      return { ...state, saving: true }

    case nrts.handleNotificationSend:
      const np = getSendNotificationProps(activeNote, appUserAccess, handleSuccess, _notification, page_fns, pathViews, appProfileData, allowNotificationTopics)
      sendTheScheduledNotification(np)
      return { ...state, sending: true }

    case nrts.handleSendToSelf:
      fsfn_notifications.sendNotificationToSelf({ notificationData: _notification, appUserAccess, pathViews, callback: handleSuccess })
      return { ...state, sending: true }

    case nrts.handleStep:
      return { ...state, step: action.step, ...getAllows(action.step, stepCount, state) }

    case nrts.handleFormDataChange_local:
      // data_form is the data from the individual form and needs to be combined with the existing data
      const { data_form } = action
      const cn = { ..._notificationData, ...data_form }
      const ndd = getNds(state, cn)
      return { ...state, notificationData: cn, ...getAllows(step, stepCount, ndd) }

    case nrts.handleTopicOrigin:

      const { groupKey, itemKey } = action && action.topicOrigin ? action.topicOrigin : {}
      const _selectedTopicGroups = { ...selectedTopicGroups }
      const stgKey = itemKey ? groupKey + '_' + itemKey : groupKey

      if (_selectedTopicGroups[stgKey]) {
        delete _selectedTopicGroups[stgKey]
      } else {
        _selectedTopicGroups[stgKey] = action.topicOrigin
      }
      const ndo = { ..._notificationData, selectedTopicGroups: _selectedTopicGroups }
      return { ...state, selectedTopicGroups: _selectedTopicGroups, ...getAllows(step, stepCount, ndo), notificationData: ndo }

    case nrts.handleCancel:
      handleClose()
      return { ...state }

    case nrts.handleNotificationFormData:
      return { ...state }

    case nrts.handlePageItem:
      return { ...state, key: action.pageItemKey, selectedPageItem: action.pageItem }

    case nrts.handleSelectedFilters:
      return { ...state, selectedFilters: action.sf }

    case nrts.handleSelectedPageItem:
      return { ...state }

    case nrts.handleSelectedAppUsers:
      return { ...state, selectedAppUsers: action.selectedAppUsers }

    case nrts.handleSelectedTargets:
      return { ...state }

    case nrts.handleSelectFilter:
      return { ...state, selectedFilter: action.sf }

    case nrts.handleSetTopicGroups:
      return { ...state, appProfileData: action.appProfileData }

    case nrts.handletNotificationData:
      return { ...state }

    case nrts.handletSelectedFilter:
      return { ...state }

    case nrts.handleNotificationData:
      return { ...state, notificationData: action.xxx }

    case nrts.handleSuccess:
      console.log('handleSuccess', action)
      return { ...state, success: action.success, sending: false, saving: false }

    case nrts.handleError:
      return { ...state, error: action.error }

    default:
      return { ...state }
  }
}

const getAllows = (step, stepCount, state) => {
  const allowBack = step.index > 0
  const allowForward = (step.index < (stepCount - 1) && validateComponent(step, state))
  return {
    allowBack,
    allowForward
  }
}

export const notificationItemInitialState = (initState) => {

  const {
    activeNote,
    appProfileData,
    appUserCollection,
    appUserViewTypeProp,
    currentGroupKey,
    handleClose,
    item_state,
    notificationScheduleType,
    staticView,
    staticView_appUser,
    paps_state,
    page_fns,
  } = initState

  const { view, viewKey, pathViews, rootPaths } = paps_state ?? {}
  const { viewItem_key, viewListData } = item_state ?? {}

  let targetOptions;
  let _viewListData;
  let _activeNote;

  if (activeNote) {
    _activeNote = { ...activeNote }
  } else {
    if (view === viewItem_key) { // same page and view
      if (currentGroupKey) {
        const xxx = viewListData && currentGroupKey && appUserViewTypeProp ? _.filter(viewListData, { [appUserViewTypeProp]: currentGroupKey }) : {}
        _viewListData = creatingHelpers.createObject(xxx, '_itemKey')
        targetOptions = {
          pageItem: view,
          pageItemKey: viewKey,
          recipientItem: viewItem_key ? viewItem_key : appUserCollection,
          subItem: appUserViewTypeProp,
          subKey: currentGroupKey,
          subType: staticView && currentGroupKey && staticView[currentGroupKey] ? staticView[currentGroupKey].name : null,
          appUserViewTypeProp,
        }
      } else {
        _viewListData = viewListData
        targetOptions = {
          pageItem: view,
          pageItemKey: viewKey,
          recipientItem: viewItem_key ? viewItem_key : appUserCollection,
          appUserViewTypeProp,
        }
      }
    } else {
      _viewListData = viewListData
      if (view === 'landing') {
        targetOptions = {
          recipientItem: appUserCollection,
        }
      } else {
        targetOptions = {
          pageItem: view,
          pageItemKey: viewKey,
          recipientItem: viewItem_key ? viewItem_key : appUserCollection,
          appUserViewTypeProp,
        }
      }
      // if (view !== 'landing' && viewKey) {
      //   // const xxx = view && viewKey ? _.filter(viewListData, function (i) {
      //   //   return (i[view] && i[view].includes(viewKey))
      //   // }) : {};
      //   // _viewListData = creatingHelpers.createObject(xxx, '_itemKey')
      //   // targetOptions.pageItemKey = viewKey
      // }
    }
    _activeNote = { ...activeNote, targetOptions }
  }

  const { sendDate, sendTime, selectedTopicGroups, sendDateAndTimeUtc, appUserTypes } = _activeNote ?? {}

  if (_activeNote) {
    _activeNote.notificationRecipients = getRecipients(targetOptions, _viewListData, appProfileData, appUserCollection, staticView_appUser)
  } else {
    _activeNote.notificationRecipients = getRecipients(targetOptions, _viewListData, appProfileData, appUserCollection, staticView_appUser)
  }

  _activeNote.targetOptions.notificationRecipientKeys = _activeNote.notificationRecipients ? Object.keys(_activeNote.notificationRecipients) : null

  if (!sendDateAndTimeUtc && sendDate && sendTime) {
    _activeNote._sendDateAndTimeUtc = new Date(sendDate + ' ' + sendTime)
  } else {
    _activeNote._sendDateAndTimeUtc = sendDateAndTimeUtc
  }

  let steps = []

  if (item_state) {
    steps = [
      // stepTypes.appUserCollectionList,
      stepTypes.notification,
      stepTypes.notificationOptions,
      // stepTypes.scheduleType,
      stepTypes.confirmation
    ]
  } else {
    steps = notificationScheduleType === gEnums.notificationScheduleTypes.direct
      ? [
        stepTypes.notification,
        stepTypes.notificationOptions,
        // stepTypes.scheduleType,
        stepTypes.confirmation
      ]
      : [
        stepTypes.notification,
        stepTypes.notificationOptions,
        // stepTypes.appUserList,
        // stepTypes.scheduleType,
        stepTypes.confirmation
      ]
  }

  const stepCount = steps.length
  const step = { index: 0, name: steps[0] }

  const s = {
    activeNote,
    appUserCollection,
    appUserViewTypeProp,
    allowBack: step.index > 0,
    allowForward: false,
    currentGroupKey,
    handleClose,
    notificationData: _activeNote ?? {},
    page_fns,
    pathViews,
    rootPaths,
    selectedTopicGroups: selectedTopicGroups ?? {},
    step: { index: 0, name: steps[0] },
    stepCount,
    steps,
  }

  const appUserTypes_s = appUserTypes ? appUserTypes.split(',') : []
  if (appUserTypes_s && appUserTypes_s.length > 0) {
    appUserTypes_s.forEach(aut => {
      s.selectedTopicGroups[appUserViewTypeProp + '_' + aut.trim()] = {
        groupKey: appUserViewTypeProp,
        itemKey: aut.trim()
      }
    })
  }

  if (s.notificationData.active === "FALSE") { s.notificationData.active = false }
  if (s.notificationData.sent === "FALSE") { s.notificationData.sent = false }

  s.allowForward = (step.index < (stepCount - 1) && validateComponent(step, s))

  return s
};

export const notificationItemHandlers = (dispatch) => {
  return {
    handleCancel: () => { dispatch({ type: nrts.handleCancel }) },
    handleNotificationFormData: () => { dispatch({ type: nrts.handleNotificationFormData }) },
    handlePageItem: (pageItemKey, pageItem) => { dispatch({ type: nrts.handlePageItem, dispatch, pageItemKey, pageItem }) },
    handleFormDataChange_local: (data_form) => { dispatch({ type: nrts.handleFormDataChange_local, dispatch, data_form }) },
    handleSelectedFilters: (sf) => { dispatch({ type: nrts.handleSelectedFilters, dispatch, sf }) },
    handleSelectedPageItem: () => { dispatch({ type: nrts.handleSelectedPageItem }) },
    handleSelectedTargets: () => { dispatch({ type: nrts.handleSelectedTargets }) },
    handleSelectedAppUsers: (selectedAppUsers) => { dispatch({ type: nrts.handleSelectedAppUsers, selectedAppUsers }) },
    handleSelectFilter: (sf) => { dispatch({ type: nrts.handleSelectFilter, dispatch, sf }) },
    handleSetTopicGroups: (appProfileData) => { dispatch({ type: nrts.handleSetTopicGroups, dispatch, appProfileData }) },
    handletNotificationData: () => { dispatch({ type: nrts.handletNotificationData }) },
    handleTopicOrigin: (topicOrigin) => { dispatch({ type: nrts.handleTopicOrigin, dispatch, topicOrigin }) },
    handletSelectedFilter: () => { dispatch({ type: nrts.handletSelectedFilter }) },
    handleNotificationData: (xxx) => { dispatch({ type: nrts.handleNotificationData, dispatch, xxx }) },
    handleStep: (step) => { dispatch({ type: nrts.handleStep, dispatch, step }) },

    handleNotificationSave: () => { dispatch({ type: nrts.handleNotificationSave, dispatch }) },
    handleNotificationSend: () => { dispatch({ type: nrts.handleNotificationSend, dispatch }) },
    handleSendToSelf: () => { dispatch({ type: nrts.handleSendToSelf, dispatch }) },

    handleSuccess: (success) => { dispatch({ type: nrts.handleSuccess, dispatch, success }) },
    handleError: (error) => { dispatch({ type: nrts.handleError, dispatch, error }) },
  }
}

const getNds = (state, notificationData) => {
  return { ...state, notificationData }
}

const validateComponent = (step, state) => {

  const { notificationData, selectedPageItem, selectedFilters, selectedTopicGroups, selectedAppUsers, viewListData } = state

  const { title, body, notificationDeliveryType, notificationScheduleType, sendDate, sendTime } = notificationData ?? {}

  switch (step.name) {
    case stepTypes.notification:
      return title && body ? true : false

    case stepTypes.notificationOptions:
      if (notificationDeliveryType) {
        if (notificationScheduleType === gEnums.notificationScheduleTypes.direct) {
          return true
        } else {
          return sendDate && sendTime ? true : false
        }
      } else {
        return false
      }

    case stepTypes.deliveryType:
      return notificationDeliveryType ? true : false

    case stepTypes.scheduleType:
      if (notificationScheduleType === gEnums.notificationScheduleTypes.direct) {
        return true
      } else {
        return sendDate && sendTime ? true : false
      }
    case stepTypes.appProfileData:
      return selectedTopicGroups && Object.keys(selectedTopicGroups).length > 0
    case stepTypes.notificationTarget:
      return selectedPageItem ? true : false
    case stepTypes.filters:
      return selectedFilters ? true : true
    case stepTypes.appUserList:
      return selectedAppUsers ? true : true
    case stepTypes.appUserCollectionList:
      return viewListData ? true : true
    default:
      break;
  }
}

const getRecipients = (targetOptions, currentItemData, appProfileData, appUserCollection, staticView_appUser, view, viewKey) => {

  const { pageItemKey } = targetOptions ?? {}

  const profileData = appProfileData ? appProfileData[appUserCollection] : null

  let combinedItems = {}

  if (pageItemKey) {
    combinedItems = getSelectedRecipients(currentItemData)
  } else {
    combinedItems = getSelectedRecipients(profileData)
  }

  return combinedItems

}

const getSelectedRecipients = (recipientDataList) => {

  const selectedItems = {}
  const appUserItems = {}

  if (recipientDataList) {

    Object.keys(recipientDataList).forEach(itemKey => {

      const itemData = recipientDataList[itemKey]
      const { firstName, lastName, email, phoneNumber } = itemData

      const newItem = { firstName, lastName, email, phoneNumber, _itemKey: itemKey }

      Object.keys(newItem).forEach(k => {
        if (newItem[k]) {
          if (!selectedItems[itemKey]) { selectedItems[itemKey] = {} }
          selectedItems[itemKey][k] = newItem[k]
        }
      })
    })
  }

  const combinedItems = { ...appUserItems, ...selectedItems }

  return combinedItems

}

// const ammendAppUserCollectionData_fromItemDataArray = (appUserItems, profileData, itemData, appUserCollection) => {
//   if (itemData[appUserCollection]) {
//     const itemData_appUser = itemData[appUserCollection]
//     if (itemData_appUser && _.isArray(itemData_appUser)) {
//       itemData_appUser.forEach(ai => {
//         if (profileData && profileData[ai]) {
//           appUserItems[ai] = profileData[ai]
//         }
//       })
//     }
//   }
// }

// const ammendAppUserCollectionData_fromProp = (appUserItems, profileData, itemData, appUserCollection) => {
//   if (itemData[appUserCollection]) {
//     const itemData_appUser = itemData[appUserCollection]
//     if (itemData_appUser && _.isArray(itemData_appUser)) {
//       itemData_appUser.forEach(ai => {
//         if (profileData && profileData[ai]) {
//           appUserItems[ai] = profileData[ai]
//         }
//       })
//     }
//   }
// }