import { gEnums } from "../../enums/globalEnums";

export const ammendViewSettingsSortPositions = (menuType, viewItems, vit, sortedGroups) => {

  // when items are sorted.
  switch (menuType) {

    case gEnums.menuTypes.viewItem:
      if (sortedGroups['viewItems']) {
        const items = sortedGroups['viewItems']
        Object.keys(items).forEach(key => {
          viewItems[key].position = items[key].position
        })
      }
      break;

    case gEnums.menuTypes.propItem:
      if (vit) {
        const prs = viewItems[vit].props
        Object.keys(sortedGroups).forEach(key => {
          const sg = sortedGroups[key]
          Object.keys(sg).forEach(key2 => {
            const pr = sg[key2]
            prs[pr.key].position = pr.position
            prs[pr.key].propSection = pr.propSection
          })
        })
      }
      break;

    case gEnums.menuTypes.propSections:
      if (vit && sortedGroups['propSections']) {
        const ps = sortedGroups['propSections']
        Object.keys(ps).forEach(key => {
          viewItems[vit].propSections[key].position = ps[key].position
        })
      }
      break;

    default:
    // nothing
  }
}

export const sortViewSettingItems = (sortedItems, sortedPropItems, sortedPropSections, viewItems, propSections, sortVit) => {
  if (sortedItems) {
    Object.keys(sortedItems).forEach(key => {
      viewItems[key].position = sortedItems[key].position
    })
  } else if (sortedPropItems) {
    const prs = viewItems[sortVit].props
    Object.keys(sortedPropItems).forEach(key => {
      prs[key].position = sortedPropItems[key].position
      if (sortedPropItems[key].propSection) { prs[key].propSection = sortedPropItems[key].propSection }
    })
    if (propSections) { viewItems[sortVit].propSections = propSections }
  } else if (sortedPropSections) {
    const pss = viewItems[sortVit].propSections
    Object.keys(sortedPropSections).forEach(key => {
      pss[key].position = sortedPropSections[key].position
    })
  }
}