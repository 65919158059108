import _ from 'lodash'

/**
 * Copies and object using JSON.parse/JSON.stringify
 * @param {object} obj 
 * @returns a copied object
 */
export const copyObj = (obj) => obj ? JSON.parse(JSON.stringify(obj)) : obj;

/**
 * 
 * @param {*} textAreaRef 
 * @description copies the `textAreaRef` to the `clipboard` using `document.execCommand`
 */
export const copyCodeToClipboard = (textAreaRef, index) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    try {
      const text = textAreaRef.current.innerHTML
      const jsonString = textAreaRef.current.value
      const originalObject = JSON.parse(jsonString);
      const x = flattenObject(originalObject)
      const { global } = x
      console.log('global ', global)
      console.log('global ', JSON.stringify(global))
      return navigator.clipboard.writeText(text);
    } catch (error) {
      console.error(error)
    }

  }
}

const flattenObject = (originalObject) => {

  const { adjustedPermissions } = originalObject

  return _.mapValues(adjustedPermissions, (value) => {
    if (_.isArray(value)) {
      return _.head(value);
    } else if (_.isObject(value)) {
      return _.mapValues(value, (innerValue) => {
        if (_.isArray(innerValue)) {
          return _.head(innerValue);
        }
        return _.mapValues(innerValue, (innerValueValue) => {
          if (_.isArray(innerValueValue)) {
            return _.head(innerValueValue);
          }
          // if (!innerValueValue.startsWith('_')) {
          return {}
          // }
        });
      });
    }
    return value; // Keep non-object, non-array values as they are
  });
};

/**
 * 
 * @param {*} textAreaRef 
 * @description copies the `textAreaRef` to the `clipboard` using `document.execCommand`
 */
export const copyTableToClipboard = (tableRef) => {
  const code = tableRef.current.innerText;
  navigator.clipboard.writeText(code).then(res => {
    console.log('Copied')
  });
}

export function copyDirect(text, propsToCopy) {

  let textToCopy = '';

  if (propsToCopy) {
    propsToCopy.forEach((p, index) => {
      textToCopy += `${text[p]}`;
      if (index < propsToCopy.length - 1) {
        textToCopy += ', '
      }
    })
  } else {
    textToCopy = text;
  }

  navigator.clipboard.writeText(textToCopy)
    .then(() => {
      console.log('Coordinates copied to clipboard:', textToCopy);
      // You can show a success message or perform any other desired actions here
    })
    .catch((error) => {
      console.error('Failed to copy coordinates to clipboard:', error);
      // You can handle the error or show an error message to the user
    });
}