import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { videoConferenceHandlers, videoConferenceInitialState, videoConferenceReducer } from '../reducers/VideoConferenceReducer';
import { ParentContext } from './ParentContext';

/** Global VideoConferences (Icon, Image, PDF) for the Event taken from the database */
export const VideoConferenceContext = createContext();

/** This will get the homeSettings and clientSettings */
const VideoConferenceProvider = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { page_state, paps_state } = states
  const { page_fns, database_fns } = fns

  // papsContext 
  const { settingsDocName, pathViews } = paps_state ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { projectModules, appVideoConferencing } = aps_global ?? {}
  const { allowVideoConferencing: allowVideoConferencing_g } = projectModules ?? {}
  const { allowVideoConferencing } = appVideoConferencing ?? {}

  const _allowVideoConferencing = allowVideoConferencing_g && allowVideoConferencing

  // local state
  const init_state = { settingsDocName, pathViews, page_fns, database_fns }
  const [videoConference_state, videoConferenceDispatch] = useReducer(videoConferenceReducer, videoConferenceInitialState(init_state));
  const videoConference_handlers = videoConferenceHandlers(videoConferenceDispatch, videoConference_state)

  useEffect(
    () => {
      // only get videoConferences in the events collection  
      if (_allowVideoConferencing) {
        videoConference_handlers.handleGetVideoConferences()
      }
    },
    // eslint-disable-next-line
    [settingsDocName] // does not re-rednder unless uivi has changed
  )

  const providerContext = () => {
    return <VideoConferenceContext.Provider value={{ videoConference_state, videoConference_handlers }}>
      {props.children}
    </VideoConferenceContext.Provider>
  }

  return providerContext()

}

export default VideoConferenceProvider