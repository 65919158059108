import React, { useContext } from 'react';
import { AppUserManagementContext } from '../cnr/contexts/AppUserManagementContext';
import { showAppManagementTypes } from '../cnr/reducers/AppUserManagementReducer';
import UserAuths from '../components/user/UserAuths';
import JsonViewer from '../components/viewers/JsonViewer';
import FullPageWrapper from '../wrappers/FullPageWrapper';
import AppUserView from "./AppUserView";
import UiSaveButtons from '../components/buttons/UiSaveButtons';
import { fsfn_notifications } from '../functions/fbNotifications';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import { clickOriginTypes } from '../enums/globalEnums';

const AppUserSubManager = (props) => {

  const { clickOriginType } = props

  let _fromDataViewer;

  switch (clickOriginType) {
    case clickOriginTypes.dataViewer:
      _fromDataViewer = true
    default:
    //nothing
  }

  // appUserManagementContext
  const appUserManagementContext = useContext(AppUserManagementContext)
  const { appUserManagement_state, appUserManagement_handlers } = appUserManagementContext ?? {}
  const { appManagementType, confirmation, currentUserCounts, filteredItems, selectedItems, updating } = appUserManagement_state ?? {}
  const { handleChange_appManagementType: hc_amt } = appUserManagement_handlers ?? {}

  const _selectedItems = filteredItems ? filteredItems : selectedItems

  const handleUpdate_clientProfiles = () => {
    appUserManagement_handlers.handleStartUpdate()
    appUserManagement_handlers.handleUpdate_clientProfiles()
  }

  const handleUpdate_clientProfilesMerge = () => {
    appUserManagement_handlers.handleStartUpdate()
    appUserManagement_handlers.handleUpdate_clientProfiles(true)
  }

  const handleUpdate_clientProfilesRemove = () => {
    appUserManagement_handlers.handleStartUpdate()
    appUserManagement_handlers.handleUpdate_clientProfiles(false, true)
  }

  const handleSendGsc = () => {
    // appUserManagement_handlers.handleStartUpdate()
    fsfn_notifications.sendScheduledNotifications_gcs_direct()
  }

  const wrapper_clientProfile = () => <Wrapper
    content={'Are you sure you want to update the client profile collection?'}
    footer={<UiSaveButtons
      save={{ caption: 'Update', oc: handleUpdate_clientProfiles }}
      preview={{ caption: 'Merge', oc: handleUpdate_clientProfilesMerge }}
      delete={{ caption: 'Remove Invalid', oc: handleUpdate_clientProfilesRemove }}
    />}
    wrapperType={wrapperTypes.padded}
    updating={updating}
    confirmation={confirmation}
  />

  const gcNotifications_wrapper = () => <Wrapper
    content={'Are you sure you want to send Gcs'}
    footer={<UiSaveButtons save={{ caption: 'Send', oc: handleSendGsc }} />}
    wrapperType={wrapperTypes.padded}
    updating={updating}
    confirmation={confirmation}
  />

  const fpw_appUserCount = () => <FullPageWrapper
    content={<JsonViewer json={currentUserCounts} />}
    handleCancel={hc_amt}
    topperCaption={'App User Counts'}
    isBack={true}
    fullMode={_fromDataViewer ? true : false}
  />

  const fpw_authorization = () => <FullPageWrapper
    content={<UserAuths />}
    handleCancel={hc_amt}
    topperCaption={'Authorization Check'}
    isBack={true}
    fullMode={_fromDataViewer ? true : false}
  />

  const fpw_add = () => <FullPageWrapper
    content={'Add Content'}
    handleCancel={hc_amt}
    topperCaption={'Add'}
    isBack={true}
    fullMode={_fromDataViewer ? true : false}
  />

  const fpw_email = () => <FullPageWrapper
    content={<AppUserView ignoreInverted={true} selectedItems={_selectedItems} />}
    handleCancel={hc_amt}
    topperCaption={'Send Emails'}
    isBack={true}
    fullMode={_fromDataViewer ? true : false}
  />

  const fpw_gcNotifications = () => <FullPageWrapper
    content={gcNotifications_wrapper()}
    handleCancel={hc_amt}
    topperCaption={'Send Gcs'}
    fullMode={_fromDataViewer ? true : false}
  />

  const fpw_clientProfiles = () => <FullPageWrapper
    content={wrapper_clientProfile()}
    handleCancel={hc_amt}
    topperCaption={'Update Client Profiles'}
    isBack={true}
    fullMode={_fromDataViewer ? true : false}
  />

  const content = () => {
    switch (appManagementType) {
      case showAppManagementTypes.appUserCount:
        return fpw_appUserCount()
      case showAppManagementTypes.auth:
        return fpw_authorization()
      case showAppManagementTypes.add:
        return fpw_add()
      case showAppManagementTypes.email:
        return fpw_email()
      case showAppManagementTypes.gcNotifications:
        return fpw_gcNotifications()
      case showAppManagementTypes.updateAppUserProfiles:
      case showAppManagementTypes.updateClientProfiles:
        return fpw_clientProfiles()
      default:
        return <div>{appManagementType}</div>
    }
  }

  return content()

}

export default AppUserSubManager