import _ from 'lodash';
import React, { createContext, Suspense, useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, Label, Menu } from 'semantic-ui-react';
import { getAuthIcon } from '../../auth/authPermissions';
import { FirestoreContext } from '../../cnr/contexts/FirestoreContext';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { dataModificationsHandlers, dataModificationsInitialState, dataModificationsReducer } from '../../cnr/reducers/DataModificationsReducer';
import SuspenseDimmer from '../../components/alerts/SuspenseDimmer';
import { DataManagementContext } from '../../components/viewers/DataManagementViewer';
import { appIconTypes } from '../../enums/appIconTypes';
import { clickOriginTypes, gEnums } from '../../enums/globalEnums';
import { dataModificationTypes } from '../../viewSettings/enums/itemActionTypes';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import UiDataFilters, { fullFilterTypes } from '../UiDataFilters';
import QrScan from './pageItemActions/QrScan';
import { ItemActionContext } from './UiItemAction';
import Uploader from '../../components/uploading/Uploader';
import { AppMonitorContext } from '../../cnr/contexts/AppMonitor';
import CreateSports from '../../../projectSpecific/sports/create/CreateSports';

const DataLinkingSidebar = React.lazy(() => import('../../viewSettings/data/DataLinkingSidebar'));
const ImageMapping = React.lazy(() => import('../../components/swipe/ImageMapping'));
const UiAddEdit = React.lazy(() => import('./dataModifications/UiAddEdit'));
const UiAmmend = React.lazy(() => import('./dataManagement/UiAmmend'));
const UiClipboard = React.lazy(() => import('./dataModifications/UiClipboard'));
const UiCredentialing = React.lazy(() => import('./dataModifications/UiCredentialing'));
const UiDataLinking = React.lazy(() => import('./dataModifications/UiDataLinking'));
const UiDownloads = React.lazy(() => import('./dataModifications/UiDownloads'));
const UiSorting = React.lazy(() => import('./dataModifications/UiSorting'));
const UiStatus = React.lazy(() => import('./dataManagement/UiStatus'));
const UiSubAction = React.lazy(() => import('./dataModifications/UiSubAction'));
const UiUpload = React.lazy(() => import('./dataManagement/UiUpload'));
const GolfParentProvider = React.lazy(() => import('../../../projectSpecific/golf/cnr/contexts/GolfParentContext'));
const CreateGolf = React.lazy(() => import('../../../projectSpecific/golf/create/CreateGolf'));

export const DataModificationsContext = createContext();

export const dataModificationDisplayTypes = {
  full: 'full',
  direct: 'direct',
  menu: 'menu',
  menuHeader: 'menuHeader'
}

/**
 * This 
 * @param {object} props (direct) 
 * @returns a Menu with the dataModification menuItems
 * @contexts DataModificationsContext, uiItemContext, dataContext
 * @description 
 */
const UiDataModifications = (props) => {

  const navigate = useNavigate()

  const {
    dataModificationDisplayType,
    clickOriginType,
    handleCancel: handleCancel_props,
    modifyActionType,
    singleItemOnly,
    useIcons,
    iconLabels,
  } = props ?? {}

  let _fromDataViewer;

  switch (clickOriginType) {
    case clickOriginTypes.dataViewer:
      _fromDataViewer = true
    default:
    //nothing
  }

  let trimBySingle;
  let fullPage;

  switch (dataModificationDisplayType) {
    case dataModificationDisplayTypes.direct:
      trimBySingle = true
      fullPage = true
      break;

    case dataModificationDisplayTypes.menuHeader:
      trimBySingle = true
      break;
    default:
      trimBySingle = false
  }

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns, settings } = parentContext ?? {}
  const { appUser_state, page_state, paps_state, eventInfo_state } = states
  const { pathName, _isDataModify } = paps_state ?? {}
  const { page_fns } = fns
  const { pageSettings, pageNav } = page_state ?? {}
  const { navUiItemContext } = pageNav ?? {}
  const { aps_global } = pageSettings ?? {}
  const { dataOptions, dataPermissions, dataRestrictions, appNotifications, appDataSource, googleSheets } = aps_global ?? {}
  const { appUser } = appUser_state ?? {}
  const { staticViewKeys } = eventInfo_state ?? {}
  const { useModifyRoutes } = dataOptions ?? {}
  const { autoUpdateCollectionRelationships } = appDataSource ?? {}
  const { homeSettings } = settings ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}
  const { logging } = homeSettings_global ?? {}
  const { googleSheetsId: googleSheetsKey } = googleSheets ?? {}

  // firestoreContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { frInfo, frameworkRightType } = framework_state ?? {}
  const { handleKill_pageNav } = framework_handlers ?? {}

  // itemActionContext
  const appMonitorContext = useContext(AppMonitorContext);
  const { appMonitor_handlers } = appMonitorContext ?? {}

  // itemActionContext
  const itemActionContext = useContext(ItemActionContext);
  const { uiItemContext: uiItemContext_alt } = itemActionContext ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);

  // LOOK
  let _uiItemContext = navUiItemContext ? navUiItemContext : uiItemContext
  _uiItemContext = uiItemContext_alt ? uiItemContext_alt : _uiItemContext

  const { item_handlers, item_state } = _uiItemContext ?? {}
  const { handleClose_openedItem } = item_handlers ?? {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state } = dataManagementContext ?? {}
  const { viewItem_preview, viewDataMode } = dataManagement_state ?? {}

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext);
  const { firestore_handlers } = firestoreContext ?? {}

  const _initState = {
    appDataSource,
    appMonitor_handlers,
    appNotifications,
    appUser,
    dataPermissions,
    dataRestrictions,
    firestore_handlers,
    framework_handlers,
    googleSheetsKey,
    handleClose_openedItem,
    item_handlers,
    logging,
    modifyActionType,
    page_fns,
    paps_state,
    staticViewKeys,
    trimBySingle,
    viewDataMode,
  }

  const [dataModifications_state, dataModificationsDispatch] = useReducer(dataModificationsReducer, dataModificationsInitialState(_initState));
  const dataModifications_handlers = dataModificationsHandlers(dataModificationsDispatch, dataModifications_state)
  const { viewItem, singleDataItem, dataModification, dataModificationType, subModificationType } = dataModifications_state ?? {}
  const { handleInit_dataModification, handleDataModificationSelect } = dataModifications_handlers ?? {}

  const { key: key_viewItem } = viewItem ?? {}

  useEffect(() => {
    handleInit_dataModification({ item_state, frInfo, frameworkRightType, viewItem_preview })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_isDataModify && dataModificationType && useModifyRoutes) {
      const navPath = pathName + '/' + dataModificationType
      navigate(navPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataModificationType]);

  const [activeItem, setActiveItem] = useState()

  const handleOpenMenuItem = () => setActiveItem(activeItem ? false : true)

  const handleCancel = () => {
    handleClose_openedItem && handleClose_openedItem()
    handleDataModificationSelect && handleDataModificationSelect()
    handleCancel_props && handleCancel_props()
    handleKill_pageNav && handleKill_pageNav()
  }

  const menuItem = (item, dmiKey, subKey) => {
    const { accessLevel } = item ?? {}
    const icon = getAuthIcon(accessLevel, true)
    const cc = subKey ? _.startCase(subKey) : _.startCase(dmiKey)
    const cn = icon ? 'iconed' : null
    return <Menu.Item
      key={subKey ? subKey : dmiKey}
      className={cn}
      onClick={() => { handleDataModificationSelect(dmiKey, subKey) }}
    >
      {icon && <Icon name={icon.name} color={icon.color} ></Icon>}
      {cc}
      <Icon name={appIconTypes.arrowRight} color={'blue'}></Icon>
    </Menu.Item>
  }

  const iconItem = (item, dmiKey, subKey) => {
    const { accessLevel } = item ?? {}
    const icon = getAuthIcon(accessLevel, true)
    const cc = subKey ? _.startCase(subKey) : _.startCase(dmiKey)
    const cn = icon ? 'iconed' : null
    return <Label
      key={subKey ? subKey : dmiKey}
      className={cn}
      onClick={() => { handleDataModificationSelect(dmiKey, subKey) }}>
      <Icon name={icon.name} color={icon.color} />  {cc}
    </Label>
  }

  const menuElements = () => {
    const elements = []
    if (dataModification) {
      _.forEach(dataModification, (item, dmiKey) => {
        switch (dmiKey) {
          case 'subAction':
            const { allowAction, allow, subActionTypes } = item ?? {}
            if ((allowAction || allow) && subActionTypes) {
              subActionTypes.sort()
              subActionTypes.forEach(sat => {
                elements.push(useIcons ? iconItem(item, dmiKey, sat) : menuItem(item, dmiKey, sat))
              })
            }
            break;
          default:
            elements.push(useIcons ? iconItem(item, dmiKey) : menuItem(item, dmiKey))
        }
      })
    }
    return elements
  }

  const menu = () => <Menu vertical fluid className={'menu-slim'}>
    {menuElements()}
  </Menu>

  const navDiv = () => <div className='menu-nav'>{iconLabels}</div>

  const menuHeader = () => <Menu.Item className='piom'>
    <Menu.Header onClick={() => handleOpenMenuItem()}>
      <div><Icon name={'cog'}></Icon></div>
      <div>{'Data Modifications'}</div>
      <div><Icon name={'angle right'} color={'blue'}></Icon></div>
    </Menu.Header>
    <Menu.Menu className={activeItem ? '' : 'dis-none'}>
      {menuElements()}
    </Menu.Menu>
  </Menu.Item>

  /**
   * 
   * @returns A Wrapper
   */
  const wrapper_menu = () => <Wrapper
    content={dataModification ? menu() : <div></div>}
    wrapperType={wrapperTypes.padded}
  />

  /**
   * 
   * @returns a FullPageWrapper
   */
  const fpw_menu = () => <FullPageWrapper
    content={wrapper_menu()}
    handleCancel={handleCancel}
    topperCaption={'Data Modifications'}
    topperCaption2={_.startCase(key_viewItem)}
  />

  const content = () => {
    if (dataModificationType) {
      return <DataModificationContent
        dataModification={dataModification}
        dataModificationType={dataModificationType}
        handleCancel={handleCancel}
        key_viewItem={key_viewItem}
        singleDataItem={singleDataItem}
        subModificationType={subModificationType}
        frameworkRightType={frameworkRightType}
      />
    } else {
      switch (dataModificationDisplayType) {
        case dataModificationDisplayTypes.menuHeader:
          if (dataModification) {
            if (useIcons && iconLabels) {
              return navDiv()
            } else {
              if (singleItemOnly) {
                return menuElements()
              } else {
                return menuHeader()
              }
            }
          } else {
            return <div></div>
          }
        default:
          if (fullPage || _fromDataViewer) {
            return fpw_menu()
          } else {
            return wrapper_menu()
          }
      }
    }
  }

  const contentWithContext = () => <DataModificationsContext.Provider value={{ dataModifications_state, dataModifications_handlers }}>
    <Suspense fallback={<SuspenseDimmer origin={'Ui Data Modification'} />}>
      {content()}
    </Suspense>
  </DataModificationsContext.Provider>

  return contentWithContext()

}

const DataModificationContent = (props) => {

  const _asat = gEnums.availableSubActionTypes
  const { viewItem, key_viewItem, dataModificationType, subModificationType, singleDataItem, dataModification, frameworkRightType } = props ?? {}

  switch (dataModificationType) {

    case dataModificationTypes.delete:
    case dataModificationTypes.modifyList:
      return <UiAmmend singleDataItem={singleDataItem} />

    case dataModificationTypes.status:
      switch (key_viewItem) {
        case 'events':
          return <UiStatus singleDataItem={singleDataItem} />
        default:
          return <UiAmmend singleDataItem={singleDataItem} />
      }

    case dataModificationTypes.add:
      const { add } = dataModification ?? {}
      const { allowScan } = add ?? {}
      if (allowScan) {
        return <QrScan qrAdd={add} />
      } else {
        return <UiAddEdit />
      }

    case dataModificationTypes.edit:
    case 'updateDoc':
      return <UiAddEdit dataModificationType={dataModificationType} />

    case dataModificationTypes.images:
      return <Uploader
        itemData={{}}
        origin={'FullImage'}
        storageLocationType={gEnums.storageLocationTypes.pageDirect}
        useFull={true}
      />
    // return <ImageGallery
    //   handleCancel={handleCancel}
    //   storageLocationType={gEnums.storageLocationTypes.pageDirect}
    //   storageType={gEnums.storageTypes.image}
    // />

    case dataModificationTypes.storageImages:
    case dataModificationTypes.bulkImageUpload:
    case dataModificationTypes.pdfs:
      return <UiUpload origin={'UiDataModifications'} showUpload={true} dataModificationType={dataModificationType} />

    case dataModificationTypes.imageMapping:
      return <ImageMapping imageMapType={gEnums.imageMapTypes.itemMapping} inDashboard={true} />

    case dataModificationTypes.clipboard:
      return <UiClipboard />

    case dataModificationTypes.connections:
    case dataModificationTypes.dataLinking:
      return <DataLinkingSidebar />

    case dataModificationTypes.downloads:
      return <UiDownloads />

    case dataModificationTypes.credentialing:
      return <UiCredentialing />

    case dataModificationTypes.filters:
      return <UiDataFilters fullFilterType={fullFilterTypes.create} />

    case dataModificationTypes.sorting:
      return <UiSorting frameworkRightType={frameworkRightType} />

    case dataModificationTypes.subAction:
      return <UiSubAction subActionType={subModificationType} />

    case _asat.archiveGolfTournament:
    case _asat.importGolfTournament:
    case _asat.createGolfTournament:
    case _asat.createRoundMatches:
    case _asat.createGolfLineups:
      console.log('dataModificationType', dataModificationType)
      return <GolfParentProvider>
        <CreateGolf
          subActionType={dataModificationType}
          viewItem={viewItem}
        />
      </GolfParentProvider>

    case _asat.createSeason:
      return <CreateSports
        subActionType={dataModificationType}
        viewItem={viewItem}
      />

    default:
      return <div>{'DataModificationContent: No component for ' + dataModificationType}</div>
  }
}

export default UiDataModifications