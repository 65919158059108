import { gEnums } from "../../enums/globalEnums"

export const sectionMerge = (aps_global, propSection) => {

  const isNameSection = propSection.key === gEnums.sectionHeaderNames.name

  // globalSections 
  const { uiSections: uiSections_global } = aps_global ?? {}
  const { header: header_g, sectionHeader: sectionHeader_g, sectionBody: sectionBody_g } = uiSections_global ? uiSections_global : {}

  const _header_g = Object.assign({}, header_g)
  const _sectionHeader_g = Object.assign({}, sectionHeader_g)
  const _sectionBody_g = Object.assign({}, sectionBody_g)

  // this should come from sectionHeader

  // @ts-ignore
  let { sectionDisplay, sectionHeader, sectionBody } = propSection ? propSection : {}

  if (!sectionDisplay) { sectionDisplay = {} }
  if (!sectionHeader) { sectionHeader = {} }
  if (!sectionBody) { sectionBody = {} }

  if (!isNameSection) {
    if (_sectionHeader_g && _sectionHeader_g.apply) {
      if (!sectionHeader.overrideAppSettings || sectionHeader.overrideAppSettings === false) {
        sectionHeader = { ..._sectionHeader_g, caption: sectionHeader.caption, icon: sectionHeader.icon }
      }
    }
    if (_sectionBody_g && _sectionBody_g.apply) {
      if (!sectionBody.overrideAppSettings || sectionBody.overrideAppSettings === false) {
        sectionBody = _sectionBody_g
      }
    }
  } else {
    if (_header_g && _header_g.apply) {
      if (!sectionHeader.overrideAppSettings || sectionHeader.overrideAppSettings === false) {
        sectionHeader = _header_g
      }
    }
    sectionBody.bodyDisplayType = gEnums.sectionBodyTypes.header
  }
  return { sectionDisplay, sectionHeader, sectionBody }
}