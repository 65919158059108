import React, { useContext, useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { SportsEditMatchContext } from '../../cnr/contexts/SportsEditMatchContext';

const MatchSetFilter = (props) => {

  const { setSetFilter } = props

  // sportsEditMatchContext
  const sportsEditMatchContext = useContext(SportsEditMatchContext)
  const { sportsEditMatch_state, sportsEditMatch_handlers } = sportsEditMatchContext ?? {}
  const { match_edit } = sportsEditMatch_state ?? {}

  const { _results } = match_edit ?? {}
  const { setNumber, setsCompleted } = _results ?? {}

  const [selectedSet, setSelectedSet] = useState(setNumber)

  const handleSelectedSet = (sn) => setSelectedSet(selectedSet === sn ? null : sn)

  useEffect(() => {
    sportsEditMatch_handlers.handleChange_set(selectedSet)
    setSetFilter({ selectedSet })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedSet]);

  const button = (sn, basic, text, oc) => {
    if ((sn <= setsCompleted + 1) || setNumber === sn) {
      const color = sn <= setsCompleted ? 'green' : 'blue'
      return <Button compact basic={basic} color={color} onClick={(e) => { oc(sn) }}>{sn <= setsCompleted && <Icon name='check' />}{text}</Button>
    } else {
      return <Button compact basic={basic} color={'grey'}>{text}</Button>
    }
  }

  const buttons = (min, max) => {
    const btns = []
    for (var i = min; i <= max; ++i) {
      btns.push(button(i, (selectedSet === i ? false : true), 'Set ' + i, handleSelectedSet))
    }
    return btns
  }
  const buttons_set = () => {
    return <div><Button.Group size={'mini'}>
      {buttons(1, 5)}
    </Button.Group></div>
  }

  const buttonHeader = () => {
    return <div className={'set-buttons'}>
      {buttons_set()}
    </div>
  }

  return buttonHeader()
}

export default MatchSetFilter