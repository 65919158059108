import _ from 'lodash';
import { useHash } from '../../App';
import { key_pages } from '../enums/allPageTypes';
import { gEnums } from '../enums/globalEnums';
import { getViewSettingsBaseName, getViewSettingsKeys } from '../viewSettings/helpers/settingsHelpers';
import { dataModificationTypes } from '../viewSettings/enums/itemActionTypes';
import { _cacheNames } from '../cnr/reducers/ServiceWorkerReducer';

const isOdd = (num) => {
  return num % 2 === 1 ? true : false
}

// https://mobiledev.events.mywebsite.net/#/

export const currentHelpers = {
  convertPathToKeys: (pathName) => convertPathToKeys(pathName),
  getCurrent: (url, pathname) => getCurrent(url, pathname),
  getCurrentItem: (defaultItem, array) => getCurrentItem(defaultItem, array),
  getDevice: () => getDevice(),
  getHashPath: () => getHashPath(),
  getLastPathView: (pathName) => getLastPathView(pathName),
  getPathViews: (pathName) => getPathViews(pathName),
  getQueryParamsObject: () => getQueryParamsObject(),
  getQueryValues: (pathName) => getQueryValues(pathName),
  getSearchPath: (newPath, ddProps, ddn) => getSearchPath(newPath, ddProps, ddn),
  getSplits: () => getSplits(),
  getStorageItem: (itemKey, array, pathKey) => getStorageItem(itemKey, array, pathKey),
  pushBack: (navigate) => pushBack(navigate),
  removeParam: (param) => removeParam(param),
  storageItem_get: (storageKey) => storageItem_get(storageKey),
  storageItem_set: (storageKey, value) => storageItem_set(storageKey, value),
}

const getHashPath = () => {

  const { hash, origin, pathname, href } = window.location

  const qvs = href ? getQueryValues(href) : {}
  const svs = getQueryParamsObject()

  const queryValues = { ...qvs, ...svs }

  if (hash === '') {
    return { pathname, href, queryValues, _hash: origin + '/#' + pathname, _redirect: true, _pathName: '/#' + pathname, _pathNameOriginal: pathname.substring(1) }
  } else {
    return { pathname, href, queryValues, _hash: hash, }
  }
}

const getSearchPath = (newPath, ddProps, ddn) => {
  let path = newPath + '?'
  if (ddProps) {
    Object.keys(ddProps).forEach(key => {
      path += key + '=' + ddProps[key]
    })
    path += '&' + ddn.key + '=' + ddn.value
  } else {
    path += ddn.key + '=' + ddn.value
  }
  return path
}

const getQueryParamsObject = () => {
  const hashParams = new URLSearchParams(window.location.hash.slice(1));
  return Object.fromEntries(hashParams.entries());
};

const getTrueView = (pathViews, _view, trueView, landingView, _viewKey) => {
  if (trueView === landingView) {
    if (pathViews.events) {
      trueView = 'events'
    } else if (pathViews.clients) {
      trueView = 'clients'
    }
  }
  return trueView
}

// eslint-disable-next-line 
const x = {
  event: {
    key: 'eventKey',
    attendees: {
      one: {
        test: 'test',
        aaaa: 'aaaa'
      },
      two: {
        test: 'test',
        aaaa: 'aaaa'
      }
    }
  }
}

const getLastPathView = (pathName) => {
  const pathViews = getPathViews(pathName)
  const lastPath = Object.keys(pathViews)[Object.keys(pathViews).length - 1]
  const pvs = pathName.split('/')
  return {
    path: lastPath,
    key: pathViews[lastPath],
    pathView: { [lastPath]: pathViews[lastPath] },
    lastKey: pvs[pvs.length - 1]
  }
}

const getSplits = () => {
  let splits = []
  try {
    splits = window.location.hash.split('/')
  } catch (error) {

  }
  return splits
}

export const getPathViews = (pathName) => {

  let counter = 1
  let start = 0
  let view = ''
  let hasDD = false
  let hasDDN = false

  const pathViews = {}

  const splitter = pathName.split('/')

  splitter.forEach((item, index) => {

    if (item === 'ddg') { hasDD = true }
    if (item === 'ddn') { hasDDN = true }

    if (index > start) {
      if (isOdd(counter)) {
        if (key_pages.includes(item)) {
        } else {
          if (!hasDD && !hasDDN) {
            view = item
          }
        }
      } else {
        if (!key_pages.includes(item) && !hasDD && !hasDDN) {
          pathViews[view] = item
        }
      }
      counter++
    }
  })

  return pathViews
}

const getQueryValues = (pathName) => {
  const queryValues = {};

  // Check if the URL contains a query string
  const queryString = pathName.split('?')[1];

  if (queryString) {
    // Split the query string into individual key-value pairs
    const keyValuePairs = queryString.split('&');

    keyValuePairs.forEach(pair => {
      const [key, value] = pair.split('=');

      if (key && value !== undefined) {
        // Decode URI components to handle special characters
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value);

        // Convert 'true' and 'false' strings to boolean values
        if (decodedValue === 'true') {
          queryValues[decodedKey] = true;
        } else if (decodedValue === 'false') {
          queryValues[decodedKey] = false;
        } else {
          queryValues[decodedKey] = decodedValue;
        }
      }
    });
  }

  return queryValues;
};

const getQueryValuesXXX = (pathName) => {
  const queryValues = {}
  const hashFragments = pathName.split('?')[1];
  if (hashFragments) {
    const _hashFragments = hashFragments.split('&')
    _hashFragments.forEach(hf => {
      const values = hf.split('=')
      if (values.length === 2) {
        if (values[1] === 'true') {
          queryValues[values[0]] = true
        } else if (values[1] === 'false') {
          queryValues[values[0]] = false
        } else {
          queryValues[values[0]] = values[1]
        }
      }
    })
  }
  return queryValues
}

const removeParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete(param);
  // Update the URL without the refresh query parameter
  const newUrlWithoutRefresh = window.location.pathname + '?' + urlParams.toString();
  return newUrlWithoutRefresh
};

const convertPathToKeys = (pathName) => {

  let counter = 1
  let start = 0
  let view = ''
  let viewKey = ''
  const pathViews = {}

  pathName.forEach((item, index) => {
    if (index > start) {
      if (isOdd(counter)) {
        view = item
        viewKey = null
      } else {
        viewKey = item
        pathViews[view] = viewKey
      }
      counter++
    }
  })

  return pathViews
}

/**
 * An object containing information about the current path.
 * @param props
 */
const getCurrent = (url, pathname) => {

  const hp = getHashPath()
  const { queryValues } = hp ?? {}
  const { appViewMode } = queryValues ?? {}

  let pathName = pathname ? pathname : url

  // trim pathName if last char is '/'
  if (pathName.substring(pathName.length - 1) === '/') {
    pathName = pathName.substring(0, pathName.length - 2)
  }

  const splitter = pathName.split('/')
  const pathViews = {}

  let hostName;

  if (useHash) {
    hostName = window && window.location ? window.location.hostname : ''
  } else {
    hostName = window && window.location ? window.location.hostname : ''
  }

  let counter = 1
  let dataMasked = false
  let lastPathName = splitter[splitter.length - 1]
  let lastView = ''
  let lastViewKey = ''
  let otherView = null
  let dataModificationView = null
  let start = 0
  let view = ''
  let viewKey = ''
  let ddGroupIndex = ''
  let ddGroupKey = ''

  const useLanding = true
  const landingView = 'landing'
  const landingViews = ['home', 'landing']
  const mainViews = ['home', 'clients', 'events']

  let hasDD = false
  let hasDDN = false

  splitter.forEach((item, index) => {

    if (item === 'mask') { dataMasked = true }
    if (item === 'ddg') { hasDD = true }
    if (item === 'ddn') { hasDDN = true }

    if (index > start) {
      if (isOdd(counter)) {
        if (dataModificationTypes[item]) {
          dataModificationView = item
        }
        if (key_pages.includes(item)) {
          otherView = item
        } else {
          if (!hasDD && !hasDDN) {
            view = item
            lastView = item
            viewKey = null
          }
        }
      } else {
        if (dataModificationTypes[item]) {
          dataModificationView = item
        }
        if (key_pages.includes(item)) {
          otherView = item
        } else {
          if (hasDD) {
            ddGroupIndex = parseInt(item)
          } else if (hasDDN) {
            ddGroupKey = item
          } else {
            viewKey = item
            lastViewKey = item
            pathViews[view] = viewKey ? viewKey.replace(/%20/g, ' ') : viewKey
          }
        }
      }
      counter++
    }
  })

  let storageRootPath = ''
  let rootPath = ''
  let pageKey = ''
  let rootPaths = {}
  let storagePaths = {}
  let lastRootPath = null

  Object.keys(pathViews).forEach(key => {
    const v = pathViews[key]
    storageRootPath = storageRootPath ? storageRootPath + '/' + key + '/' + v : key + '/' + v
    rootPath = rootPath + '/' + key + '/' + v
    pageKey = pageKey ? pageKey + '-' + v : v
    rootPaths[key] = rootPath + '/'
    const spz = rootPath
    storagePaths[key] = spz.substring(1)
    lastRootPath = rootPath
    if (!viewKey) { lastView = key }
  })

  if (dataMasked) {
    viewKey = null
    view = lastView
  }

  if (!view) { view = 'home' }

  let nonLandingView = view

  if (view === landingView && pathViews && Object.keys(pathViews).length > 0) {
    nonLandingView = Object.keys(pathViews)[Object.keys(pathViews).length - 1]
    viewKey = pathViews[nonLandingView]
  }

  if (useLanding) {
    if (landingViews.includes(viewKey)) {
      viewKey = landingView
    }
  }

  let trueView = view ? view : 'home'

  var userAgent = navigator.userAgent.toLowerCase();

  const _browser = {
    chrome: /Chrome/i.test(userAgent) && !/Edge/i.test(userAgent),
    firefox: /Firefox/i.test(userAgent),
    safari: /^((?!chrome|android).)*Safari/i.test(userAgent),
    edge: /Edge/i.test(userAgent),
    opera: /OPR/i.test(userAgent),
  }

  const _device = {
    android: /Android|android/i.test(userAgent),
    iphone: /iPhone|iphone/i.test(userAgent) || isSafari(),
    ipad: /iPad|ipad/i.test(userAgent) || isSafari(),
    ipod: /iPod|ipod/i.test(userAgent) || isSafari(),
    pixel: /Pixel|pixel/i.test(userAgent),
  }

  const platform = {
    isMobileDevice: isSmartphone() || appViewMode === gEnums.appViewModes.googleSheets,
    isStandAlone: window.matchMedia('(display-mode: standalone)').matches || navigator.standalone === true,
  }

  Object.keys(_browser).forEach(key => {
    if (_browser[key]) {
      platform.browser = key
    }
  })

  Object.keys(_device).forEach(key => {
    if (_device[key]) {
      platform.device = key
    }
  })

  if (!platform.device) { platform.device = 'desktop' }

  if (!platform.isMobileDevice) {
    platform.name = platform.browser
    switch (platform.browser) {
      case 'chrome':
        platform.icon = 'chrome'
        break;
      case 'firefox':
        platform.icon = 'firefox'
        break;
      case 'edge':
        platform.icon = 'edge'
        break;
      case 'opera':
        platform.icon = 'opera'
        break;
      default:
        platform.icon = 'desktop'
    }
    platform.caption = _.startCase(gEnums.deviceNames.desktop) + ' (' + _.startCase(platform.browser) + ')'
  } else {
    if (platform.browser === 'safari') {
      platform.name = gEnums.deviceNames.iphone
      platform.isSafari = true
      platform.icon = 'apple'
      platform.caption = _.startCase(gEnums.deviceNames.iphone)
    } else {
      platform.name = gEnums.deviceNames.android
      platform.isSafari = false
      platform.icon = 'android'
      platform.caption = _.startCase(gEnums.deviceNames.android)
    }
  }

  let appArea = gEnums.appAreaTypes.home

  if (view === 'clients') { appArea = gEnums.appAreaTypes.clients }
  if (rootPaths.clients) { appArea = gEnums.appAreaTypes.client }
  if (rootPaths.events) { appArea = gEnums.appAreaTypes.event }

  const { origin } = window.location
  let rp;

  switch (appArea) {
    case 'client':
      rp = rootPaths && rootPaths['clients'] ? rootPaths['clients'] : ''
      break;
    case 'event':
      rp = rootPaths && rootPaths['events'] ? rootPaths['events'] : ''
      break;
    default:
      break;
  }
  const sharePath = rp ? origin + '/#' + rp + 'landing' : origin + '/#/'
  const sharePathFull = origin + '/#' + pathName

  let ddProps = {}

  const pathModes = {
    attendees: pathViews.attendees ? true : false,
    district: pathViews && pathViews.districts && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    organization: pathViews && pathViews.organizations && !pathViews.districts ? true : false,
    event: pathViews.events ? true : false,
    match: pathViews && pathViews.matches ? true : false,
    sessions: pathViews.sessions ? true : false,
    school: pathViews.schools ? true : false,
    sport: pathViews && pathViews.sports ? true : false,
    team: pathViews.teams ? true : false,
  }

  const current = {
    appArea,
    appViewMode,
    appHome: 'home',
    dataMasked,
    dataModificationView,
    ddGroupIndex,
    ddGroupKey,
    eventPageKey: storageRootPath ? storageRootPath.replace(/\//g, '_') : null,
    fullSettingsKey: pathName.replace(/\//g, '_'),
    getTrueView: getTrueView,
    hostName,
    isHomePage: appArea === 'home',
    isLandingPage: landingView === view,
    _isDataModify: dataModificationTypes[dataModificationView],
    _dataModifyType: dataModificationTypes[dataModificationView] ? dataModificationView : null,
    landingView,
    lastPathName,
    lastRootPath,
    lastView,
    lastViewKey,
    mainViews,
    nonLandingView,
    otherView,
    pathKey: storageRootPath.replace(/\//g, '_'),
    pathname,
    pathName,
    pathViews,
    pathModes,
    pageKey,
    platform,
    rootPath,
    rootPaths,
    storageItemPage: viewKey ? view + '_' + viewKey : view,
    storagePaths,
    sharePath,
    sharePathFull,
    ddProps: ddProps,
    screen: window.screen,
    settingsDocName: getViewSettingsBaseName(view, pathViews),
    settingsDocKeys: getViewSettingsKeys(view, pathViews),
    splitter,
    storageRootPath,
    storageManifestPath: storageRootPath + '/manifest',
    trueView,
    url,
    useLanding,
    view,
    viewAs: view,
    viewKey,
    viewPathKey: viewKey ? view + '-' + viewKey : view,
  }

  current.eventPath = rootPaths.events ? rootPath.replace(rootPaths.events, '') : ''
  current.eventPath = current.eventPath ? current.eventPath.replace('/', '_') : ''

  switch (view) {
    case 'home':
    case 'clients':
    case 'events':
      current.settingsDocDirect = true
      break;
    default:
      current.settingsDocDirect = false
      break;
  }


  let _cacheKey = '';

  switch (appArea) {
    case 'home':
      _cacheKey = _cacheNames.cache_home + '-' + current.settingsDocName
      break;
    case 'client':
      _cacheKey = _cacheNames.cache_client + '-' + current.settingsDocName
      break;
    case 'event':
      _cacheKey = _cacheNames.cache_event + '-' + current.settingsDocName
      break;
    default:
    // nothing
  }

  current.cacheKey = _cacheKey

  return current
}

const pushBack = (navigate) => {
  const { location } = navigate
  if (location && location.search) {
    navigate(-2)
  } else {
    storageItem_set('isBack', true)
    navigate(-1)
  }
}

const getDevice = () => {
  return {
    android: /Android/i.test(navigator.userAgent),
    iphone: /iPhone|iphone/i.test(navigator.userAgent),
    ipad: /iPad|ipad/i.test(navigator.userAgent),
    ipod: /iPod|ipod/i.test(navigator.userAgent),
    userAgent: navigator.userAgent,
    name: gEnums.deviceNames.desktop
  }
}

const storageItem_set = (storageKey, value) => {
  window.localStorage.setItem(storageKey, value)
}

const storageItem_get = (storageKey) => {
  return window.localStorage.getItem(storageKey)
}

const getStorageItem = (itemKey, array, pathKey) => {
  const storageValue = window.localStorage.getItem(pathKey ? pathKey + '_' + itemKey : itemKey)
  if (storageValue) {
    return getCurrentItem(storageValue, array)
  } else {
    return getCurrentItem(array[0], array)
  }
}

const getCurrentItem = (defaultItem, array) => {
  const _array = array ? array : []
  const _defaultIndex = _array.indexOf(defaultItem)
  const _defaultValue = _defaultIndex && _defaultIndex > 0 ? _array[_defaultIndex] : _array[0]
  return { index: _defaultIndex, item: _defaultValue ? _defaultValue : {}, visible: false }
}


function isSmartphone() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isSp = /iPhone|iPad|iPod|iphone|ipad|ipod|android|webos|blackberry|windows phone/.test(userAgent);
  const _isSafari = isSafari()
  if (isSp || _isSafari) {
    return true;
  } else {
    return false;
  }
}

function isSafari() {
  return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
}