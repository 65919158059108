import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Form, Label, Segment } from 'semantic-ui-react';
import { DataContext } from '../cnr/contexts/DataContext';
import { FilterContext } from '../cnr/contexts/FilterContext';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer } from '../cnr/reducers/SidebarReducer';
import { convertHelpers } from '../common/convert';
import { filterHelpers, getUniqueData } from '../common/filtering';
import NoData from '../components/alerts/NoData';
import { DataManagementContext } from '../components/viewers/DataManagementViewer';
import { gEnums } from '../enums/globalEnums';
import { fs_db } from '../firestoreData/appData/fsAppData';
import { listList } from '../lists/lists';
import MenuSidebars from '../sidebars/MenuSidebars';
import { dataManagementTypes } from '../viewSettings/enums/itemActionTypes';
import FullPageWrapper from '../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import { DataModificationsContext } from './modification/UiDataModifications';

export const fullFilterTypes = {
  create: 'create',
  item: 'item',
  notification: 'notification',
  settingsView: 'settingsView',
  table: 'table',
  view: 'view',
}

export const _filterStatusTypes = {
  selected: 'selected',
  notSelected: 'notSelected',
  none: 'none'
}

const sidebarType = 'filters'

const UiDataFilters = (props) => {

  const { fullFilterType, selectedPageItem, handleSelectFilter, viewItem_current } = props ?? {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, paps_state, eventInfo_state } = states
  const { pageSettings } = page_state ?? {}
  const { aps_viewItems } = pageSettings ?? {}
  const { staticViews } = eventInfo_state ?? {}

  // firestoreContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { frInfo, frameworkRightType } = framework_state ?? {}
  const { viewListData: vld_fr, viewItem: viewItem_fr } = frInfo ?? {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state, dataManagement_handlers } = dataManagementContext ?? {}
  const { updating, confirmation, dataManagementType, viewItem: viewItem_dm, viewListData: viewListData_dm } = dataManagement_state ?? {}
  const inViewer = dataManagement_state ? true : false
  const isFilter = dataManagementType === dataManagementTypes.createFilter ? true : false

  const dataModificationsContext = useContext(DataModificationsContext)
  const { dataModifications_handlers } = dataModificationsContext ?? {}

  // papsContext 
  const { pathViews } = paps_state ?? {}

  // filterContext
  const filterContext = useContext(FilterContext);
  const { uiFilter_handlers, uiFilter_state } = filterContext ?? {}
  const { selectedFilters } = uiFilter_state ?? {}
  const filtersCount = selectedFilters && Object.keys(selectedFilters).length
  const hasFilters = filtersCount > 0 ? true : false

  // dataContext
  const dataContext = useContext(DataContext)
  const { data_state } = dataContext ?? {}
  const { viewListData } = data_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { viewItem } = item_state ?? {}

  let _viewItem = selectedPageItem ? selectedPageItem.pageItem : viewItem
  if (!_viewItem) { _viewItem = viewItem_dm }
  if (!_viewItem && viewItem_fr) { _viewItem = viewItem_fr }

  let _viewItem_global = aps_viewItems && _viewItem && _viewItem.key ? aps_viewItems[_viewItem.key] : null
  if (!_viewItem_global && viewItem_current && aps_viewItems[viewItem_current.key]) { _viewItem_global = aps_viewItems[viewItem_current.key] }

  const { dataFilters, props: props_vig } = _viewItem_global ?? {}

  const { key: viewItemKey, dataConstraints } = _viewItem ?? {}
  const { usePageConstraint: usePageConstraint_dc, dataConstraintItems } = dataConstraints ?? {}

  const { dataFilters: dataFilters_current } = viewItem_current ?? {}
  const _dataFilters = dataFilters_current ? dataFilters_current : dataFilters

  // sidebar
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType, handlers: null }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, currents, dimmed } = sidebar_state ?? {}
  const { setInit } = sidebar_handlers ?? {}
  const { ms_filters } = sidebar_items ?? {}
  const { selected: filter_selected } = ms_filters ?? {}
  const { item: filter_name } = filter_selected ?? {}

  const [vld, setVld] = useState()
  const [dataItems, setDataItems] = useState([])
  const [counts, setCounts] = useState({ full: 0, filtered: 0 })
  const [filterName, setFilterName] = useState()
  const [filterData, setFilterData] = useState()
  const [uniqueInfo, setUniqueInfo] = useState()
  const [selectedUniques, setSelectedUniques] = useState([])
  const [currentFilters, setCurrentFilters] = useState({})
  const [currentConstraints, setCurrentConstraints] = useState([])
  const [usePageConstraint, setUsePageConstraint] = useState(usePageConstraint_dc)

  const { options: filterOptions } = filterData ?? {}

  let buttonDisabled;

  switch (fullFilterType) {
    case fullFilterTypes.create:
      buttonDisabled = filterName && hasFilters ? false : true
      break;
    default:
      buttonDisabled = hasFilters ? false : true
  }

  const handleNameChange = (e, data) => setFilterName(data.value)
  const handlePageChange = (e, data) => setUsePageConstraint(data.checked)

  let _viewListData = vld ? vld : viewListData
  if (!_viewListData) { _viewListData = viewListData_dm }
  if (!_viewListData && vld_fr) { _viewListData = vld_fr }

  const buttonCaption = fullFilterType === fullFilterTypes.create ? 'Create (' + counts.filtered + '/' + counts.full + ')' : 'View (' + counts.filtered + '/' + counts.full + ')'

  useEffect(() => {
    if (_viewListData) {
      const uniques = getUniqueData(_viewListData)
      switch (fullFilterType) {
        case fullFilterTypes.create:
          uniques.uniqueKeys = Object.keys(props_vig)
          break;
        default:
        // nothing
      }
      setFilterData({ keys: Object.keys(uniques.uniqueKeys), options: listList(uniques.uniqueKeys.sort()) })
      setUniqueInfo(uniques)
      setCounts({ full: Object.keys(_viewListData).length, filtered: Object.keys(_viewListData).length })
      const { _currentFilters, _selectedUniques } = getFiltersFromConstraints()
      setCurrentFilters(_currentFilters)
      setSelectedUniques(_selectedUniques)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_viewListData]);

  // set the selected filters
  useEffect(() => {
    switch (fullFilterType) {
      case fullFilterTypes.create:
      case fullFilterTypes.settingsView:
        break;
      default:
        const _sfs = selectedFilters ? selectedFilters : props.selectedFilters
        uiFilter_handlers.handleCurrentSelectedFilters(_sfs ?? {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (_dataFilters && filter_name && _dataFilters[filter_name] && _dataFilters[filter_name].filters) {
      switch (fullFilterType) {
        case fullFilterTypes.settingsView:
          const _sfs = selectedFilters ? selectedFilters : props.selectedFilters
          uiFilter_handlers.handleCurrentSelectedFilters(_sfs ?? {})
          handleSelectFilter && handleSelectFilter(filter_name)
          break;
        default:
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [filter_name]);

  useEffect(() => {
    if (_dataFilters) {
      const pidf = Object.keys(_dataFilters)
      setInit({ smt: sidebarMenuTypes.one, items: pidf, currents, as: 'filters' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_dataFilters]);

  useEffect(() => {
    if (!_viewListData && selectedPageItem) {
      fs_db.get_data_any({ pathViews, collectionName: selectedPageItem.key, callback: setVld })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleCancel = () => {
    if (dataModifications_handlers) {
      dataModifications_handlers && dataModifications_handlers.handleDataModificationSelect()
    } else {
      uiFilter_handlers.handleFilterStatus(gEnums.filterStatusTypes.none)
    }
  }

  const handleButtonClick = () => {
    switch (fullFilterType) {
      case fullFilterTypes.create:
        dataManagement_handlers.handleStartUpdate()
        dataManagement_handlers.handleUpdate_pageItemDataFilter(isFilter, viewItemKey, filterName, selectedFilters)
        break;
      default:
        uiFilter_handlers && uiFilter_handlers.handleGroupFilter(selectedFilters)
    }
  }

  const handleOptionChange = (e, data) => setSelectedUniques(data.value)

  const ammendFilters = (groupKey, item) => {

    const staticView = staticViews[groupKey]
    const staticView_item = staticView ? staticView[item] : {}
    const staticView_name = staticView_item ? staticView_item.name : null

    const _currentFilters = currentFilters ? { ...currentFilters } : {}
    if (!_currentFilters[groupKey]) { _currentFilters[groupKey] = {} }

    if (!_currentFilters[groupKey][item]) {
      const _item = {
        _itemKey: item,
        value: item,
        name: staticView_name,
        status: _filterStatusTypes.selected,
        isArray: staticView_name ? true : false
      }
      _currentFilters[groupKey][item] = _item
    } else {
      const _item = _currentFilters[groupKey][item]
      const { status } = _item
      switch (status) {
        case _filterStatusTypes.selected:
          _item.status = _filterStatusTypes.notSelected
          break;
        case _filterStatusTypes.notSelected:
          _item.status = _filterStatusTypes.none
          break;
        case _filterStatusTypes.none:
          _item.status = _filterStatusTypes.selected
          break;
        default:
        // nothing
      }
    }

    return _currentFilters

  }

  const getStatus = (queryType) => {
    switch (queryType) {
      case gEnums.queryTypes.arrayContains:
      case gEnums.queryTypes.arrayContainsAny:
        return _filterStatusTypes.selected
      case gEnums.queryTypes.notIn:
        return _filterStatusTypes.notSelected
      default:
        return _filterStatusTypes.none
    }
  }

  const getGroupItemName = (groupKey, pv) => {
    const staticView = staticViews[groupKey]
    const staticView_item = staticView ? staticView[pv] : {}
    const staticView_name = staticView_item ? staticView_item.name : null
    return staticView_name
  }

  const getFiltersFromConstraints = () => {

    const _currentFilters = {}
    const _selectedUniques = []

    const _grouped = _.groupBy(dataConstraintItems, 'dataPropName')

    if (_grouped) {

      _.forEach(_grouped, (group, groupKey) => {

        _currentFilters[groupKey] = {}

        console.log('groupKey', groupKey, group)

        switch (groupKey) {
          case 'itemStatusType':
            // nothing
            break;
          default:
            _.forEach(group, (groupItem) => {
              const { queryType, propValues } = groupItem
              if (propValues) {
                _.forEach(propValues, (pv) => {
                  const staticView_name = getGroupItemName(groupKey, pv)
                  _currentFilters[groupKey][pv] = {
                    _itemKey: pv,
                    value: pv,
                    name: staticView_name,
                    status: getStatus(queryType),
                    isArray: staticView_name ? true : false
                  }
                })
              }
            })
            _selectedUniques.push(groupKey)
        }
      })
    }
    return { _currentFilters, _selectedUniques }
  }

  const getConstraintsFromFilters = (_currentFilters) => {

    let _currentConstraints = []

    if (_currentFilters) {
      _.forEach(_currentFilters, (filter, filterKey) => {

        const _grouped = _.groupBy(filter, 'status')

        _.forEach(_grouped, (group, status) => {
          const items = _.map(group, item => item._itemKey)
          const count = items.length
          console.log('items', items)
          switch (status) {
            case _filterStatusTypes.selected:
              _currentConstraints.push({
                dataPropName: filterKey,
                propValues: items,
                queryType: count > 1 ? gEnums.queryTypes.arrayContainsAny : gEnums.queryTypes.arrayContains,
              })
              break;
            case _filterStatusTypes.notSelected:
              _currentConstraints.push({
                dataPropName: filterKey,
                propValues: items,
                queryType: gEnums.queryTypes.notIn,
              })
              break;
            default:
            // nothing
          }
        })
      })
    }
    return _currentConstraints
  }

  const handleSelected_label = (groupKey, item) => {
    const _currentFilters = ammendFilters(groupKey, item)
    const _currentConstraints = getConstraintsFromFilters(_currentFilters)

    try {
      // uiFilter_handlers.handleCurrentSelectedFilters(_sfs)
      // handleSelectedFilters && handleSelectedFilters(_sfs)

      const items = filterHelpers.filterGroupFilters(_viewListData, _currentConstraints)
      console.log('_currentConstraints', _currentConstraints)
      console.log('items', items)
      setCurrentFilters(_currentFilters)
      setDataItems(items)
      setCounts({ full: Object.keys(_viewListData).length, filtered: Object.keys(items).length })
    } catch (error) {
      console.log('error', error)
    }

  }


  const segValues = (sui) => {
    const lbls = []

    const currentFilter = currentFilters ? currentFilters[sui] : []

    if (uniqueInfo && uniqueInfo.uniqueValues) {

      switch (sui) {
        case 'itemStatusType':
          break;
        default:
          const staticView = staticViews && staticViews[sui] ? staticViews[sui] : null
          convertHelpers.createItemKeys(staticView)
          const _staticView = _.sortBy(staticView, 'name')
          _.forEach(_staticView, (svi) => {
            const { name, _itemKey } = svi ?? {}
            let color = 'grey'
            const _filter = _.find(currentFilter, { value: _itemKey })
            const { status } = _filter ?? {}
            switch (status) {
              case _filterStatusTypes.selected:
                color = 'green'
                break;
              case _filterStatusTypes.notSelected:
                color = 'red'
                break;
              default:
              // nothing
            }
            lbls.push(<Label title={_itemKey} color={color} onClick={() => { handleSelected_label(sui, _itemKey) }}>{name}</Label>)
          })
      }
    }
    return lbls
  }

  const selectedItemsLabels = () => {
    if (!selectedUniques) {
      return <Label as='a'>
        {'No Filters Selected'}
      </Label>
    } else {
      const segments = []
      if (selectedUniques) {
        selectedUniques.forEach(sui => {
          segments.push(<Segment.Group>
            <Segment>{_.startCase(sui)}</Segment>
            <Segment>{segValues(sui)}</Segment>
          </Segment.Group>)
        })
      }
      return segments
    }
  }

  const selectedLabelGroup = () => <Label.Group size='tiny' >{selectedItemsLabels()}</Label.Group>

  const input = () => <Form.Input
    label={isFilter ? 'Filter Name' : 'Filter Name'}
    size='small'
    fluid
    value={filterName}
    onChange={handleNameChange} />


  const checkbox = () => <Form.Checkbox
    label={'Page Constraint'}
    size='small'
    fluid
    checked={usePageConstraint}
    onChange={handlePageChange} />

  const dropdown = () => <Form.Dropdown
    label={'Data Constraints'}
    search
    scrolling
    selection
    multiple
    options={filterOptions}
    value={selectedUniques}
    onChange={handleOptionChange} />

  const itemFilter = () => {
    return <div className={'filter-container'}>
      {fullFilterType === fullFilterTypes.create && <div className={'filter-header'}>
        <Form>
          {input()}
          {checkbox()}
          {dropdown()}
        </Form>

      </div>}
      <div className={'filter-selected'}>
        <div><Label.Group size='tiny'  >{selectedItemsLabels()}</Label.Group></div>
      </div>
    </div>
  }

  let allowFooter = () => {
    switch (fullFilterType) {
      case fullFilterTypes.notification:
      case fullFilterTypes.create:
      case fullFilterTypes.item:
        return true
      case fullFilterTypes.settingsView:
      case fullFilterTypes.view:
        return false
      default:
        return true
    }
  }

  const footer = () => <Button
    fluid
    disabled={buttonDisabled}
    color={(!buttonDisabled ? 'green' : 'grey')}
    onClick={handleButtonClick}>
    {buttonCaption}
  </Button>

  const itemFilterWrapper = () => <Wrapper
    content={itemFilter()}
    footer={allowFooter() && footer()}
    wrapperType={wrapperTypes.padded}
    updating={updating}
    confirmation={confirmation}
    css={{ container: 'app-dark-mode' }}
  />

  const notificationWrapper = () => <Wrapper
    header={selectedLabelGroup()}
    wrapperType={wrapperTypes.padded}
    css={{ container: 'app-dark-mode' }}
  />

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={itemFilterWrapper()}
    inverted={fullFilterType === fullFilterTypes.settingsView ? true : false}
    dimmed={dimmed}
  />

  const viewWizard = () => <FullPageWrapper
    content={menuSidebars()}
    topperCaption={'Filter'}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={itemFilterWrapper()}
    topperCaption={'Filter'}
    handleCancel={handleCancel}
  />

  switch (fullFilterType) {
    case fullFilterTypes.notification:
    case fullFilterTypes.table:
      return notificationWrapper()
    case fullFilterTypes.settingsView:
      return sidebar_items ? menuSidebars() : <NoData altCaption={dataManagementType} inverted={true} />
    case fullFilterTypes.view:
      return sidebar_items ? viewWizard() : <div></div>
    default:
      return inViewer || frameworkRightType ? itemFilterWrapper() : fullPageWrapper()
  }
}

export default UiDataFilters