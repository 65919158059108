import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Icon, Label } from 'semantic-ui-react';
import { ElementContext } from '../../../cnr/contexts/ElementContext';
import { PageAreaParentContext } from '../../../cnr/contexts/PageAreaParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { uniqueKey } from '../../../common/keys';
import { appIconTypes } from '../../../enums/appIconTypes';
import { gEnums } from '../../../enums/globalEnums';
import { _animateTypes } from '../../../motions/AnimateComponent';
import MotionComponent from '../../../motions/MotionComponent';
import UiCredential from '../../../pageItem/modification/dataModifications/UiCredential';
import QrCode from '../../../pageItem/modification/pageItemActions/QrCode';
import { addToClass } from '../../../styles/formatting';
import { getPropSectionsGrouped, groupTaggedPropSectionElements } from '../../../viewSettings/helpers/settingsHelpers';
import UiImage from '../../imaging/UiImage';
import { propsPicker } from '../../props/propsPicker';
import StarRating from '../../ratings/StarRating';
import CardAuthIcon from './CardAuthIcon';
import CardIndicator from './CardIndicator';
import PdfGallery from '../../imaging/PdfGallery';

const PropSorter = React.lazy(() => import('../../../dragNDrops/PropSorter'));

const CardProfile = () => {

  const _cds = gEnums.cardDisplayTypes

  // parentContext
  const parentContext = useContext(PageAreaParentContext)
  const { states, handlers, fns } = parentContext ?? {}
  const { appSettings_state, eventInfo_state, page_state, framework_state, rating_state, transition_state } = states
  const { settingsViewer_handlers } = handlers
  const { appUser_fns } = fns ?? {}
  const { pagePropsSettingsOn } = appSettings_state ?? {}
  const { staticViews, staticViews_gs } = eventInfo_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { display: display_global, appCredentialing, appRating } = aps_global ?? {}
  const { showEnteredInApp } = appCredentialing ?? {}
  const { authOn } = appSettings_state ?? {}
  const { ratings } = rating_state ?? {}

  const _staticViews = staticViews_gs ? staticViews_gs : staticViews

  const { propMode } = framework_state ?? {}

  // transitionContext  
  const { transitions } = transition_state ?? {}
  const transition = transitions ? transitions[_animateTypes.pageItemElement] : null
  const { showTransition } = transition ?? {}

  // uiItemContext 
  const uiItemContext = useContext(UiItemContext)
  const { item_state } = uiItemContext ?? {}
  const { _display, viewItem, propSections_allowed, propGroups, _imageOpts } = item_state ?? {}

  // elementContext
  const elementContext = useContext(ElementContext)
  const { element_state } = elementContext ?? {}
  const { itemData, elemProps, clickTo } = element_state ?? {}
  const { viewItem: viewItem_direct, handleClick, showPdfs, forAppManagement, imageOptions } = elemProps ?? {}
  const { email, fcmTokens, phoneNumber, _itemKey } = itemData ?? {}

  const currentRating = ratings && ratings[_itemKey]

  // _viewItem depending whether viewItem_direct exists
  const _viewItem = viewItem_direct ? viewItem_direct : viewItem
  const { display: display_direct } = viewItem_direct ?? {}
  const display_final = display_direct ? display_direct : _display

  const { showCaptionInitial, sortProp } = display_final ?? {}
  let { cardDisplayType } = display_final ?? {}
  const { qrCodes, props: props_viewItem, key: key_viewItem, propSections: propSections_viewItem } = _viewItem ?? {}
  const { showItemImage, imageLocation, imageSize } = _imageOpts ?? {}
  const { showQrCode } = qrCodes ?? {}

  const rating_access = appUser_fns.validateAccess_rating(currentRating, appRating, key_viewItem)
  const { allowAppUserView } = rating_access ?? {}

  const _cardKeyy = uniqueKey('cpr', _itemKey)

  let imageInContent = true

  switch (imageLocation) {
    case gEnums.imageLocations.top:
      imageInContent = false
      break;
    default:
    // nothing
  }

  const [propSections, setPropSections] = useState()
  const [nameElements, setNameElements] = useState()
  const [metaElements, setMetaElements] = useState()
  const [propIndicators, setPropIndicators] = useState()

  useEffect(() => {
    const _semPropsSections =
      forAppManagement ?
        getPropSectionsGrouped(props_viewItem, propSections_viewItem, false, null, display_global, propGroups, null, null, itemData)
        :
        propSections_allowed

    const sectionGroupedElement = groupTaggedPropSectionElements({
      _keyy: _cardKeyy,
      aps_global,
      display: _display,
      forAppManagement,
      itemData,
      propGroups,
      propsSections: _semPropsSections,
      staticViews: _staticViews,
      viewItemKey: key_viewItem,
    })

    const { nameElements: _nameElements, metaElements: _metaElements, propSections: _propSections } = sectionGroupedElement

    if (showCaptionInitial && sortProp && itemData[sortProp]) {
      _nameElements.unshift(<Label key={uniqueKey('prcr', 'l', 'ci')} circular color='blue'>{itemData[sortProp].substring(0, 1)}</Label>)
    }

    if (authOn) { _metaElements.push(<CardAuthIcon email={email} />) }

    const _propIndicators = []
    const _indicators = _.filter(props_viewItem, i => i.indicator && i.indicator.showIndicator);

    _indicators.forEach(ind => {
      if (itemData && ind.indicator && itemData[ind.key]) {
        _propIndicators.push({ ...ind.indicator, indicatorProp: ind.key })
      }
    })

    setPropIndicators(_propIndicators)

    if (settingsViewer_handlers && propMode) {
      setNameElements(propsPicker({ items: _nameElements, settingsViewer_handlers }))
      setMetaElements(propsPicker({ items: _metaElements, settingsViewer_handlers }))
      setPropSections(propsPicker({ items: _propSections, settingsViewer_handlers }))
    } else {
      setNameElements(_nameElements)
      setMetaElements(_metaElements)
      setPropSections(_propSections)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [itemData, props_viewItem, propMode]);

  const handleItemClick = () => {
    handleClick && handleClick(_viewItem, _itemKey, null, { itemData: itemData, clickTo })
  }

  let cn = 'card-profile'
  let showFullImage = false

  if (showQrCode) { cn += ' card-qr' }

  if ((propIndicators && propIndicators.length > 0) || showEnteredInApp) { cn += ' indic' }
  if (showPdfs) { cn += ' pdf' }

  switch (cardDisplayType) {
    case _cds.profilePics:
      cn += ' pci'
      break;

    case _cds.profilePlus:
    case _cds.schedulePlus:
      cn += ' plus'
      showFullImage = true
      break;
    default:
    // nothing
  }

  const cardImage_normal = () => <UiImage
    viewItem={_viewItem}
    itemData={itemData}
    showFullImage={showFullImage}
    imageSize={imageSize}
    imageOptions={imageOptions}
    cardDisplayType={cardDisplayType}
  />

  const cardQr = () => <QrCode
    key={uniqueKey('cp', 'qr', _itemKey)}
    itemData={_viewItem}
    viewItem={_viewItem}
    qrCodeType={gEnums.qrCodeTypes.itemData}
  />

  const cardIcons = () => <div key={uniqueKey('cp', 'd', _cardKeyy)} >
    <Icon key={uniqueKey('cp', 'i', _cardKeyy, 1)} bordered name={appIconTypes.mail} color={email ? 'blue' : 'grey'} />
    <Icon key={uniqueKey('cp', 'i', _cardKeyy, 2)} bordered name={appIconTypes.notifications} color={fcmTokens ? 'blue' : 'grey'} />
    <Icon key={uniqueKey('cp', 'i', _cardKeyy, 3)} bordered name={appIconTypes.phone} color={phoneNumber ? 'blue' : 'grey'} />
  </div>

  const cardIndicator = (ind) => <CardIndicator indicator={ind} cardKey={_cardKeyy} itemData={itemData} aps_global={aps_global} staticViews={_staticViews} />

  const cardIndicators = () => <div className='card-indics' key={uniqueKey('cp', 'ci', _cardKeyy)}>
    {propIndicators.map(ind => (cardIndicator(ind)))}
  </div>

  const cardPdfs = () => <div className='card-pdf' key={uniqueKey('cp', 'ci', _cardKeyy)}>
    <PdfGallery />
  </div>

  const cardContent = () => <React.Fragment key={uniqueKey('cc', 'rf')}>
    {!imageInContent && cardImage_normal()}
    <Card.Content key={uniqueKey('cp', 'crd', _cardKeyy)} className='card-content'>
      <Card.Header key={uniqueKey('cp', 'crd', 'h', _cardKeyy)}>
        {showItemImage && imageInContent && (imageLocation === gEnums.imageLocations.inline) && cardImage_normal()}
        {nameElements}
      </Card.Header>
      <Card.Meta key={uniqueKey('cp', 'mta', _cardKeyy)}>{metaElements}</Card.Meta>
      {allowAppUserView &&
        <Card.Meta key={uniqueKey('cs', 'sm', _cardKeyy)}>
          <StarRating key={uniqueKey('ss', 'sr', _cardKeyy)} allowRating={true} itemData={itemData} key_viewItem={key_viewItem} />
        </Card.Meta>
      }
    </Card.Content>
    {showItemImage && (imageInContent && imageLocation !== gEnums.imageLocations.inline) && <Card.Content key={uniqueKey('cp', 'crd', _cardKeyy, 1)} className={'card-image prof'}>{cardImage_normal()}</Card.Content>}
    {showQrCode && <Card.Content key={uniqueKey('cp', 'crd', _cardKeyy, 2)} className={'card-qr'}>{cardQr()}</Card.Content>}
    {forAppManagement && <Card.Content key={uniqueKey('cp', 'crd', _cardKeyy, 3)} extra className={'card-icons'}>{cardIcons()}</Card.Content>}
    {propIndicators && propIndicators.length > 0 && cardIndicators()}
    {showPdfs && cardPdfs()}
    {showEnteredInApp && <UiCredential cardKey={_cardKeyy} itemData={itemData} />}
  </React.Fragment>

  const profilePic = () => <div key={uniqueKey('cp', 'img', _cardKeyy)} className='pic-profile' onClick={() => { handleItemClick() }}>
    {cardImage_normal()}
    <div>{nameElements}</div>
  </div>

  const cardProfile = (contentOnly) => {
    switch (cardDisplayType) {
      case _cds.profilePics:
        return profilePic()
      default:
        if (contentOnly) {
          return cardContent()
        } else {

          // className={cn}
          return <Card fluid className={cn} key={uniqueKey('cp', _cardKeyy)} as='div' onClick={() => { handleItemClick() }}>
            {cardContent()}
          </Card>
        }
    }
  }

  const _showTransition = showTransition && cardDisplayType.indexOf('Plus') < 0

  const normalContent = () => {
    if (_showTransition) {
      return <MotionComponent
        transition={transition}
        oc={handleItemClick}
      >
        {cardProfile()}
      </MotionComponent>
    } else {
      return cardProfile()
    }
  }

  return pagePropsSettingsOn
    ?
    <PropSorter propSections={propSections} viewItem={_viewItem} />
    :
    normalContent()
}

export default CardProfile