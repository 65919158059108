
import React from "react";
import { motion } from "framer-motion";

function CircleLoader(props) {

  const { startType } = props ?? {}
  const cn_div = 'cntr start-circle-container ' + startType
  const cn_span = 'start-circle ' + startType

  return (
    <div className={cn_div}>
      <motion.span
        className={cn_span}
        animate={{ rotate: 360 }}
        transition={{ ease: "linear", duration: 2, repeat: Infinity }}
      />
    </div>
  );
}

export default CircleLoader