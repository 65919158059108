import { deleteField } from 'firebase/firestore';
import _ from 'lodash';
import { creatingHelpers } from './creating';

export const sortingHelpers = {
  removeArrayItem: (array, item) => removeArrayItem(array, item),
  updateArrayInclusion: (array, item) => updateArrayInclusion(array, item),
}

/**
 * 
 * @param {object} swipedItems 
 * @param {string} rootKey 
 * @returns 
 */
export const getComplexObjectKeys = (swipedItems, rootKey) => {
  const sks = []
  Object.keys(swipedItems).forEach(key => {
    const si = swipedItems[key]
    const { [rootKey]: sk } = si ? si : {}
    if (sk) { sks.push(sk) }
  })
  return sks
}

export const ammendComplexObject = (obj, items, data, isList) => {

  const count = items.length

  let a = items[0] ? items[0] : null;
  let b = items[1] ? items[1] : null;
  let c = items[2] ? items[2] : null
  let d = items[3] ? items[3] : null
  let e = items[4] ? items[4] : null

  if (a) { if (!obj[a]) { obj[a] = {} } }
  if (b) { if (!obj[a][b]) { obj[a][b] = {} } }
  if (c) { if (!obj[a][b][c]) { obj[a][b][c] = {} } }
  if (d) { if (!obj[a][b][c][d]) { obj[a][b][c][d] = {} } }
  if (e) { if (!obj[a][b][c][d][e]) { obj[a][b][c][d][e] = {} } }

  if (data) {
    switch (count) {
      case 1:
        obj[a] = !isList ? data : modifyObjectData(data, obj[a])
        break;
      case 2:
        obj[a][b] = !isList ? data : modifyObjectData(data, obj[a][b])
        break;
      case 3:
        obj[a][b][c] = !isList ? data : modifyObjectData(data, obj[a][b][c])
        break;
      case 4:
        obj[a][b][c][d] = !isList ? data : modifyObjectData(data, obj[a][b][c][d])
        break;
      case 5:
        obj[a][b][c][d][e] = !isList ? data : modifyObjectData(data, obj[a][b][c][d][e])
        break;
      default:
      //nothing
    }
  }
}

export const isObjectFull = (obj) => {
  let full = true;
  if (obj) {
    Object.keys(obj).forEach(key => {
      if (!obj[key]) { full = false }
    })
  } else {
    full = false
  }
  return full
}

/**
 * 
 * @param {array} arr 
 * @param {*} value 
 * @returns the `index` of an `array` that matches the `value`
 */
export const getArrayIndex = (arr, value) => {
  return arr.map(function (e) {
    return e;
  }).indexOf(value)
}

/**
 * 
 * @param {object} data 
 * @param {*} rootData 
 * @deprecated
 */
export const getNextKey = (data, rootData) => {
  const dataS = data.split('/')
  const i = getArrayIndex(dataS, rootData)
  if (i) {
    const nextI = i + 1
    const nextV = dataS[nextI]
    return nextV
  }
  return null
}

/**
 * 
 * @param {array} array 
 * @param {object} item 
 * @descriiption Removes an item from the array
 */
export const removeArrayItem = (array, item) => {
  var index = array ? array.indexOf(item) : null;
  if (array && index !== -1) {
    array.splice(index, 1);
  }
}

export const updateArrayInclusion = (array, item) => {
  var index = array ? array.indexOf(item) : null;
  if (array && index !== -1) {
    array.splice(index, 1);
  } else {
    array.push(item)
  }
}

export const updateObjectInclusion = (objects, item) => {
  const { _itemKey } = item
  if (objects[_itemKey]) {
    delete objects[_itemKey]
  } else {
    objects[_itemKey] = item
  }
}

export const updateSimpleArray = (list_obj, selectedList_arr, itemKey, fixedCount) => {
  const _item = list_obj[itemKey]
  const selectedKey = _item.key
  const _selectedList = [...selectedList_arr]
  if (_.includes(_selectedList, selectedKey)) {
    _.remove(_selectedList, function (n) {
      return n === selectedKey;
    });
  } else {
    if (fixedCount) {
      if (_selectedList.length < fixedCount) {
        _selectedList.push(selectedKey)
      }
    } else {
      _selectedList.push(selectedKey)
    }
  }
  return _selectedList
}

export const orderObject = (items, sortBy, desc) => {
  if (items) {
    try {
      Object.keys(items).forEach(key => { items[key].key = key })
    } catch (error) {
      // nothing
    }
    let sortedItems = _.orderBy(items, 'timestamp.seconds', desc ? 'desc' : 'asc')
    const sorted = {}
    sortedItems.forEach(vi => { sorted[vi.key] = { ...vi } })
    return sorted
  }
  return items
}

/**
 * 
 * @param {object} obj 
 * @returns the sorted object
 */
export function sortObj(obj, reverse) {
  if (reverse) {
    return Object.keys(obj).reverse().reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
  } else {
    return Object.keys(obj).sort().reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
  }

}

/**
 * 
 * @param {object} items 
 * @param {string} sortBy 
 * @param {boolean} keepKey 
 * @returns the sorted object
 */
export const sortObject = (items, sortBy, keepKey, reverse) => {
  if (items) {
    // const x = sortObj(items)  
    try {
      if (reverse) {
        Object.keys(items).reverse().forEach(key => { items[key].key = key })
      } else {
        Object.keys(items).forEach(key => { items[key].key = key })
      }
    } catch (error) {
      // nothing
    }

    let sortedItems = _.sortBy(items, sortBy)
    const sorted = {}
    sortedItems.forEach(vi => {
      // if (!keepKey) { delete vi.key }
      sorted[vi.key] = { ...vi }
      if (keepKey) { sorted[vi.key].key = vi.key }
    })
    return sorted
  }
  return items
}

export const sortObjectRoot = (items) => {
  if (items) {
    Object.keys(items).forEach(key => { items[key].key = key })
    let sortedItems = _.sortBy(items, 'key')
    const sorted = {}
    sortedItems.forEach(vi => {
      sorted[vi.key] = vi
      delete sorted[vi.key].key
    })
    return sorted
  }
  return items
}

export const reduceObject = (items, filterBy) => {
  if (items && filterBy) {
    Object.keys(items).forEach(key => { items[key].key = key })
    const filteredItems = _.filter(items, function (o) { return !o[filterBy]; });
    const filtered = {}
    filteredItems.forEach(vi => { filtered[vi.key] = { ...vi } })
    return filtered
  }
  return items
}

export const removeIdsFromObject = (items) => {
  if (items) {
    Object.keys(items).forEach(key => {
      delete items[key].id
      delete items[key].uid
    })
  }
}

// _.pickBy(teams, function (t) {
//   if (t) { return t.levels === level }
// })

export const removeFromObjects = (items, removes, exceptions) => {
  _.forEach(items, (item) => {
    if (removes) {
      removes.forEach(r => {
        if (exceptions) {
          if (exceptions && !exceptions.includes(r)) {
            delete item[r]
          }
        } else {
          delete item[r]
        }
      })
    }
  })
}

export const removeFromObject = (item, removes) => {
  if (item && removes) {
    removes.forEach(r => {
      delete item[r]
    })
  }
}


export const deleteFromObject = (item, removes) => {
  if (removes) {
    removes.forEach(r => {
      item[r] = deleteField()
    })
  }
}

export const addPropToObject = (items, prop) => {
  Object.keys(items).forEach(key => { items[key][prop] = key })
}

export function chunkArrayInGroups(arr, size) {
  var result = [];
  var pos = 0;
  while (pos < size) {
    result.push(arr.slice(pos, pos + size));
    pos += size;
  }
  return result;
}


/**
 * @returns listItems
 * @returns groups
 * @param {*} items(obj)
 * @param {*} staticList 
 * @param {*} sortGroupMax 
 * @param {*} sortGroupName 
 */
export const getGroupListData = (items, staticList, sortGroupMax, sortGroupName) => {

  const gn = 'group'

  // update name and position if needed
  if (staticList) {
    Object.keys(items).forEach((key, index) => {
      const { name, lastName, firstName } = items[key]
      if (!name && !lastName && !firstName) {
        // assign item with staticList
        items[key] = getStaticListItem(staticList, key)
        // assign position
        if (!items[key].position) { items[key].position = index }
      }
    })
  }
  // sort the items by position
  const items_sorted = _.sortBy(items, 'position')

  let total = items_sorted.length

  if (total > 0) {

    assignGroupName(items_sorted, sortGroupMax, gn)

    // create a list of objects based on the key of each item  
    const listItems = creatingHelpers.createKeyedObjectFromKey(items_sorted)

    // create a grouped list of items 
    let groups = _.groupBy(listItems, 'groupNumber')
    let newGroups = {}

    if (groups) {
      Object.keys(groups).forEach((groupKey, gp) => {
        const group = groups[groupKey]
        const items = _.sortBy(group, 'position')
        newGroups['group' + (gp + 1)] = {
          items: items,
          groupPosition: gp
        }
      })
    }

    return { listItems, groups: newGroups }
  }

  return { listItems: items, groups: {} }

}

/**
 * 
 * @param {object} staticList 
 * @param {string} key 
 * @returns The `name` and 'key` of a staticList item
 */
const getStaticListItem = (staticList, key) => {
  let caption = key
  if (staticList && staticList[key]) {
    const { name, lastName, firstName } = staticList[key]
    if (name) {
      caption = name
    } else if (lastName && firstName) {
      caption = firstName + ' ' + lastName
    }
  }
  return { name: caption, key: key }
}

/**
 * 
 * @param {object} items 
 * @param {string} prop 
 * @returns The sum of the `prop` values of the items
 */
export const sumObjectProp = (items, prop) => {
  let sum = 0
  Object.keys(items).forEach(key => {
    const item = items[key]
    if (item[prop]) {
      sum += parseInt(item[prop])
    }
  })
  return sum
}

export const sorts = {
  sortBy(ar, prop) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return _ar.sort((a, b) => (
      b[prop] - a[prop]
    ))
  },
  sortByDesc(ar, prop) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    const x = _ar.sort((a, b) => (
      b[prop] - a[prop]
    ))
    return x.reverse()
  },
  sortBy2(ar, prop1, prop2) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return _ar.sort((a, b) => (
      b[prop1] - a[prop1] || b[prop2] - a[prop2]
    ))
  },
  sortByNumber(ar, prop) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return _ar.sort((a, b) => {
      const propA = parseInt(a[prop], 10)
      const propB = parseInt(b[prop], 10)
      if (propA > propB) { return -1 }
      if (propA < propB) { return 1 }
      return 0
    })
  },
}

export const sortDateArray = (dateArray) => {
  return _.sortBy(dateArray, date => {
    const [month, day, year] = date.split('/').map(Number);
    // Creating a Date object with year, month (0-indexed), day
    return new Date(year, month - 1, day);
  })
}

const modifyObjectData = (data, originalData) => {
  const od = { ...originalData }
  if (data && od) {
    Object.keys(od).forEach(key => {
      const oid = od[key]
      if (oid.key && data[oid.key]) {
        oid.text = data[oid.key]
      }
    })
  }
  return od
}

const assignGroupName = (listItems, groupMax, groupName) => {
  let total = listItems.length
  let done = false
  let count = 0
  let grpn = 0
  while (!done) {
    // group the items into groups by the sortGroupMax
    const groups = _.slice(listItems, count, count + groupMax)
    // eslint-disable-next-line 
    groups.forEach(r => {
      if (!r.groupNumber) { r.groupNumber = grpn }
    })
    grpn++
    count = count + groupMax
    if (count >= total) { done = true }
  }
}

export const reorderDateStrings = (dateArray) => {

  // Sort timestamp array 

  // Convert sorted timestamp array back to date strings
  const sortedDateStrings = _.sortBy(dateArray, dateStr => {
    const [month, day, year] = dateStr.split('/');
    return new Date(`${year}-${month}-${day}`).getTime();
  });

  return sortedDateStrings
}