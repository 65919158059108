import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Label, List, Segment } from 'semantic-ui-react';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { TopicContext } from '../cnr/contexts/TopicContext';
import { convertHelpers, getAlphaFromList, getAlphaList } from '../common/convert';
import { uniqueKey } from '../common/keys';
import { AlphabetMenuWithProvider } from '../components/menus/AlphabetMenu';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import AppUserCard from './AppUserCard';
import { gEnums } from '../enums/globalEnums';
import { AppUserManagementContext } from '../cnr/contexts/AppUserManagementContext';


const _defaultDays = 7
const _dayBackMax = 60

/**
 * 
 * @param {object} props (items, handleClick, showFound, found)
 * @returns A list of appUsers
 */
const AppUserList = (props) => {

  const { dataItems, handleCancel, handleClick, showFound, showCount, found, viewOnly, listOnly, alphaProp } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { paps_state, page_state } = states ?? {}
  const { appUser_handlers } = handlers ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings, aps_viewItems } = pageSettings ?? {}
  const { appUserCollection, appUserViewTypeProp } = aps_appUserSettings ?? {}

  // appUserManagementContext
  const appUserManagementContext = useContext(AppUserManagementContext)
  const { appUserManagement_state, appUserManagement_handlers } = appUserManagementContext ?? {}
  const { authsList } = appUserManagement_state ?? {}

  // topicContext
  const topicContext = useContext(TopicContext);
  const { topic_state } = topicContext ?? {}

  const [alphaValue, setAlphaValue] = useState(65)
  const [currentDataItems, setCurrentDataItems] = useState()
  const [currentDataList, setCurrentDataList] = useState()
  const [dayCount, setDayCount] = useState(_defaultDays)
  const [fcmCount, setFcmCount] = useState()
  const [itemCount, setItemCount] = useState()
  const [selectedListType, setSelectedListType] = useState()
  const [showFcmOnly, setShowFcmOnly] = useState()
  const [sortProp, setSortProp] = useState('name')
  const [uniqueProps, setUniqueProps] = useState('name')

  useEffect(() => {
    setCurrentDataItems(dataItems)
    if (dataItems) {
      setItemCount(Object.keys(dataItems).length)
    }
    if (aps_viewItems && appUserCollection && aps_viewItems[appUserCollection]) {
      const viau = aps_viewItems[appUserCollection]
      const { dataConnection } = viau ?? {}
      const { uniqueProps } = dataConnection ?? {}
      setUniqueProps(uniqueProps)
    }

    // setShowFcmOnly(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dataItems]);

  useEffect(() => {
    appUserManagement_handlers.handleAmmend_auths(dayCount)
    // setShowFcmOnly(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dayCount]);

  useEffect(() => {
    if (dataItems && authsList) {
      const { recent, found, notFound } = authsList
      Object.keys(dataItems).forEach(dik => {
        const r = recent ? _.find(recent, function (i) { return (i._itemKey === dik) || (i.email === dik) }) : null
        const f = found ? _.find(found, function (i) { return (i._itemKey === dik) || (i.email === dik) }) : null
        const nf = notFound ? _.find(notFound, function (i) { return (i._itemKey === dik) || (i.email === dik) }) : null
        if (r && r.metadata) { dataItems[dik].metadata = { recent: true, ...r.metadata } }
        if (f && f.metadata) { dataItems[dik].metadata = { found: true, ...f.metadata } }
        if (nf) { dataItems[dik].metadata = { notFound: true } }
      })
      setCurrentDataItems(dataItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [authsList]);

  useEffect(() => {
    switch (selectedListType) {
      case gEnums.authListTypes.recent:
        const authsRecent = authsList && authsList[gEnums.authListTypes.recent] ? authsList[gEnums.authListTypes.recent] : []
        setCurrentDataItems(authsRecent)
        break;
      case gEnums.authListTypes.found:
        const authsFound = authsList && authsList[gEnums.authListTypes.found] ? authsList[gEnums.authListTypes.found] : []
        setCurrentDataItems(authsFound)
        break;
      case gEnums.authListTypes.notFound:
        const authsNotFound = authsList && authsList[gEnums.authListTypes.notFound] ? authsList[gEnums.authListTypes.notFound] : []
        setCurrentDataItems(authsNotFound)
        break;
      default:
        setCurrentDataItems(dataItems)
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedListType]);

  useEffect(() => {
    const { firstAsc } = getAlphaFromList(currentDataItems, alphaProp ? alphaProp : 'lastName')
    handleAlphaChange(firstAsc === 0 ? 65 : firstAsc)
    const _fcmItems = currentDataItems ? _.filter(currentDataItems, 'fcmTokens') : []
    setFcmCount(Object.keys(_fcmItems).length)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentDataItems]);

  const handleShowFcmOnly = () => setShowFcmOnly(!showFcmOnly)

  const handleAlphaChange = (av) => {
    const _alphaChar = String.fromCharCode(av)
    const sortProp = 'lastName'
    if (currentDataItems && av) {
      let _currentDataItems = currentDataItems
      const { alphaList, sortProp: sp } = getAlphaList(_currentDataItems, _alphaChar, sortProp)
      setAlphaValue(av)
      setCurrentDataList(alphaList)
      setSortProp(sp)
    }
  }

  const handleDayChange = (e, d) => setDayCount(d.value)
  const handleSelectedListType = (sl) => setSelectedListType(sl)
  const handleGoToEvent = (e) => e.stopPropagation()

  const handleSignInAs = (e, listItem, _itemKey) => {
    e.stopPropagation()
    const actionItem = { dataActionType: 'clone', cloneId: _itemKey, cloneVit: appUserCollection, appUserViewTypeProp: appUserViewTypeProp }
    appUser_handlers.handleClone(actionItem, listItem, paps_state, appUserCollection)
    handleCancel && handleCancel()
  }

  const appUserCard = (listItem, lblColor, index) => <AppUserCard
    key={uniqueKey('aul', index)}
    appUser={listItem}
    index={index}
    lblColor={lblColor}
    handleClick={handleClick}
    handleGoToEvent={handleGoToEvent}
    handleSignInAs={handleSignInAs}
    viewOnly={viewOnly}
    uniqueProps={uniqueProps}
  />

  const appUserCards = () => {
    let list = _.orderBy(currentDataList, ['lastName'])
    const cardItems = []
    if (list) {
      list.forEach((listItem, index) => {
        let lblColor;
        if (showFound) { lblColor = found ? 'green' : 'red' }
        cardItems.push(appUserCard(listItem, lblColor, index))
      })
    }
    return <Segment basic>
      {cardItems}
    </Segment>
  }

  const options = () => {
    const opts = []
    for (let i = 1; i <= _dayBackMax; i++) {
      opts.push({ text: 'Last ' + i + ' Days', value: i, key: i })
    }
    return opts
  }

  const dropdown = () => <Dropdown style={{ float: 'right' }} text={'Last ' + dayCount + ' Days'} direction='left' className={'ua-search'} placeholder='days' size={'mini'} search selection options={options()} onChange={handleDayChange} />

  const button = (key, basic, text, color, oc, title) => <Button key={uniqueKey('au', 'b', key)} title={title} compact basic={basic} color={color} onClick={(e) => { oc(key) }}>{text}</Button>

  const buttons_auth = () => {
    const rc = authsList && authsList[gEnums.authListTypes.recent] ? authsList[gEnums.authListTypes.recent].length : 0
    const fc = authsList && authsList[gEnums.authListTypes.found] ? authsList[gEnums.authListTypes.found].length : 0
    const nfc = authsList && authsList[gEnums.authListTypes.notFound] ? authsList[gEnums.authListTypes.notFound].length : 0
    return <Button.Group size={'tiny'} style={{ float: 'right' }}>
      {button(gEnums.authListTypes.recent, (selectedListType === gEnums.authListTypes.recent ? false : true), rc, 'green', handleSelectedListType, _.startCase(gEnums.authListTypes.recent))}
      {button(gEnums.authListTypes.found, (selectedListType === gEnums.authListTypes.found ? false : true), fc, 'blue', handleSelectedListType, _.startCase(gEnums.authListTypes.found))}
      {button(gEnums.authListTypes.notFound, (selectedListType === gEnums.authListTypes.notFound ? false : true), nfc, 'red', handleSelectedListType, _.startCase(gEnums.authListTypes.notFound))}
    </Button.Group>
  }

  const header = () => <React.Fragment>
    <Label color={'green'} onClick={() => handleSelectedListType()}> <Icon name='users' />{itemCount}</Label>
    <Label color={showFcmOnly ? 'orange' : 'grey'} onClick={() => handleShowFcmOnly()}><Icon name='fire' />{fcmCount}</Label>
    {authsList && buttons_auth()}
    {authsList && dropdown()}
  </React.Fragment>

  const wrapper = () => <Wrapper
    header={showCount && header()}
    content={appUserCards()}
    wrapperType={wrapperTypes.paddedHeader}
  />

  const alphaMenu = () => {

    const grouping = {
      alphaDisplayType: gEnums.alphaDisplayTypes.vertical,
      useVerticalHidden: true,
      verticalOpacity: gEnums.opacityTypes.medium,
      restrictToExisting: true,
    }

    return <AlphabetMenuWithProvider
      origin={'appUserList'}
      dataItems={currentDataItems}
      handleAlphaChange={handleAlphaChange}
      alphaValue={alphaValue}
      restrictToExisting={true}
      sortProp={sortProp}
      grouping={grouping}
    />
  }

  const alphaWrapper = () => <Wrapper
    header={alphaMenu()}
    content={wrapper()}
    wrapperType={wrapperTypes.paddedFooter}
    css={{ container: 'app-user-list' }}
  ></Wrapper>

  const listItem = (item) => {
    const { email, metadata } = item
    const { lastSignInTime, creationTime, lastRefreshTime } = metadata ?? {}
    const name = convertHelpers.getItemName(item)
    return email ? <List.Item>
      <List.Icon name='user' verticalAlign='middle' />
      <List.Content>
        <List.Header >{name}</List.Header>
        <List.Description >{email}</List.Description>
      </List.Content>
      {metadata && <List.Content>
        <List.Header >{lastRefreshTime}</List.Header>
        <List.Description>{lastSignInTime}</List.Description>
        <List.Description>{creationTime}</List.Description>
      </List.Content>}
    </List.Item> : null
  }

  const list = () => {
    const _items = _.sortBy(currentDataItems, 'lastName')
    if (_items) {
      const lis = _items.map(item => {
        return listItem(item)
      })
      return <List relaxed divided>{lis}</List>
    }
  }

  const content = () => {
    if (listOnly) {
      return list()
    } else {
      return alphaWrapper()
    }
  }

  return currentDataList ? content() : <div></div>

}

export default AppUserList
