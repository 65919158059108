import { formatDistance } from 'date-fns'
import _ from 'lodash'
import React, { useContext } from 'react'
import { Icon } from 'semantic-ui-react'
import { PageAreaParentContext } from '../../../cnr/contexts/PageAreaParentContext'

const CardAuthIcon = (props) => {

  const { email } = props ?? {}

  const parentContext = useContext(PageAreaParentContext)
  const { states } = parentContext ?? {}
  const { appUserAuth_state } = states
  const { appUserAuthList } = appUserAuth_state ?? {}
  const { found, recent } = appUserAuthList ?? {}

  const f = found ? _.find(found, { 'email': email }) : null
  const r = recent ? _.find(recent, { 'email': email }) : null

  let authIndicator;

  if (f) {
    authIndicator = f
  }
  if (r) {
    authIndicator = r
  }

  const { metadata } = authIndicator ?? {}
  const { lastRefreshTime } = metadata ?? {}
  const _lastRefreshTime = lastRefreshTime ? formatDistance(new Date(lastRefreshTime), new Date(), { addSuffix: true }) : null

  const color = _lastRefreshTime ? 'green' : 'grey'
  const icon = _lastRefreshTime ? 'check circle outline' : 'circle outline'

  return <div><Icon color={color} name={icon} />{_lastRefreshTime ? _lastRefreshTime : '---'}</div>

}

export default CardAuthIcon