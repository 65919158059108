import React, { useContext } from 'react';
import ImageMapping from '../../components/swipe/ImageMapping';
import { clickOriginTypes, gEnums } from '../../enums/globalEnums';
import { dataManagementTypes, dataSidebarActionTypes } from '../../viewSettings/enums/itemActionTypes';
import { DataManagementContext } from '../../components/viewers/DataManagementViewer';
import UiAppDataLinks from './dataManagement/UiAppDataLinks';

const AppDashboard = React.lazy(() => import('../../components/viewers/AppDashboard'));
const AppStorageTransferWithProvider = React.lazy(() => import('./dataModifications/AppStorageTransfer'));
const CreateNotificationWizard = React.lazy(() => import('../../appNotifications/CreateNotificationWizard'));
const CreateTopicWizard = React.lazy(() => import('../../appNotifications/CreateTopicWizard'));
const UiAmmendData = React.lazy(() => import('./dataManagement/UiAmmendData'));
const UiBackupData = React.lazy(() => import('./dataManagement/UiBackupData'));
const UiConvertToDocument = React.lazy(() => import('./dataManagement/UiConvertToDocument'));
const UiCopyPaste = React.lazy(() => import('./dataManagement/UiCopyPaste'));
const UiCreateGlobalData = React.lazy(() => import('./dataManagement/UiCreateGlobalData'));
const UiDataLinking = React.lazy(() => import('./dataModifications/UiDataLinking'));
const UiFixData = React.lazy(() => import('./dataManagement/UIFixData'));
const UiFixPropData = React.lazy(() => import('./dataManagement/UiFixPropData'));
const UiFixPropNames = React.lazy(() => import('./dataManagement/UiFixPropNames'));
const UiGoogleLinks = React.lazy(() => import('./dataManagement/UiGoogleLinks'));
const UiGoogleSheets = React.lazy(() => import('./dataManagement/UiGoogleSheets'));
const UiLinks = React.lazy(() => import('./dataManagement/UiLinks'));
const UiMoveData = React.lazy(() => import('./dataManagement/UiMoveData'));
const UiParentKeys = React.lazy(() => import('./dataManagement/UiParentKeys'));
const UiProductionDataWithProvider = React.lazy(() => import('./dataManagement/UiProductionData'));
const UiSecretData = React.lazy(() => import('./dataManagement/UiSecretData'));
const UiUpload = React.lazy(() => import('./dataManagement/UiUpload'));

/**
 * 
 * @param {object} props (dataManagementType, inViewer)
 * @returns a DataManagementContent component based on the `dataManagementType`
 */
const DataManagementContent = (props) => {

  const { handleItemClose } = props ?? {}

  const dataManagementContext = useContext(DataManagementContext);
  const { dataManagement_state } = dataManagementContext ?? {}
  const { dataManagementType, viewDataMode } = dataManagement_state ?? {}

  const _dataManagementType = viewDataMode ? viewDataMode : dataManagementType

  switch (_dataManagementType) {

    case dataSidebarActionTypes.backupItemData:
      return <UiBackupData />

    case dataManagementTypes.bulkImageUpload:
    case dataManagementTypes.storageImages:
      return <UiUpload origin={'UiDataManagement'} showUpload={true} />

    case dataManagementTypes.convertToParentKeys:
      return <UiParentKeys />

    case dataManagementTypes.createCollectionData:
    case dataSidebarActionTypes.createGlobalData:
    case dataSidebarActionTypes.updateStaticViews:
      return <UiCreateGlobalData />

    case dataSidebarActionTypes.createSingleDocument:
      return <UiConvertToDocument />

    case dataSidebarActionTypes.createAppDataLinks:
      return <UiAppDataLinks />

    case dataSidebarActionTypes.fixPropNames:
    case dataSidebarActionTypes.ammendPropData:
      return <UiFixPropNames />

    case dataSidebarActionTypes.moveData:
    case dataSidebarActionTypes.moveDataTo:
      return <UiMoveData />

    // this is catch all
    case dataSidebarActionTypes.fixData:
      return <UiFixData />

    case dataManagementTypes.copyData:
      return <UiCopyPaste />

    case dataManagementTypes.createNotification:
      return <CreateNotificationWizard handleClose={handleItemClose} notificationOriginType={gEnums.notificationOriginTypes.uiItem} />

    case dataManagementTypes.createTopic:
      return <CreateTopicWizard topicOriginType={gEnums.topicOriginTypes.page} activeTopic={{ name: 'test' }} />

    case dataManagementTypes.googleSheetsData:
    case dataManagementTypes.registrationData:
    case dataManagementTypes.productionData:
      return <UiProductionDataWithProvider />

    case dataManagementTypes.imageMapping:
      return <ImageMapping imageMapType={gEnums.imageMapTypes.itemLocation} handleCancel={handleItemClose} inDashboard={true} />

    // this is catch all
    case dataManagementTypes.fixPropData:
      return <UiFixPropData />

    case dataManagementTypes.googleSheetLink:
      return <UiGoogleLinks />

    case dataManagementTypes.googleDataUpdate:
      return <UiGoogleSheets />

    case dataManagementTypes.googleSheetUpdate:
      return <UiGoogleSheets />

    case dataManagementTypes.imageLinks:
      return <UiLinks />

    case dataManagementTypes.linkData:
      return <UiDataLinking />

    case dataManagementTypes.manageData:
      return <AppDashboard />

    case dataSidebarActionTypes.secretData:
      return <UiSecretData />

    case dataSidebarActionTypes.transferStorageFolder:
      return <AppStorageTransferWithProvider clickOriginType={clickOriginTypes.dataViewer} handleCancel={handleItemClose} />

    case dataSidebarActionTypes.updateParentKeys:
      return <UiParentKeys />

    case dataManagementTypes.updateDataRelationships:
    case dataManagementTypes.modifyList:
      return <UiAmmendData />

    default:
      return <div>{'DataManagementContent: No component for ' + dataManagementType}</div>
  }
}

export default DataManagementContent