import { listAll, ref } from 'firebase/storage';
import { createRefPath_event } from '../firestoreData/appData/appRefPaths';
import { getFirebaseStorage } from './storageHelpers';

/**
 * Gets the storage files from the galleryPath
 * @param {object} pathViews 
 * @param {object} action 
 * @param {function} callback 
 */
export const get_storageForders = async (pathViews, action, callback) => {

  const folderItems = {}
  const urlRef = createRefPath_event(pathViews)
  get_storageFolders({ callback, galleryPath: action.galleryPath, urlRef, folderItems })
}

export const get_storageFolders = async ({ callback, galleryPath, urlRef, folderItems }) => {

  const storage = getFirebaseStorage();

  const _callback = (fullPath, name, parentLength) => {
    folderItems[name] = { fullPath }
    if (parentLength === Object.keys(folderItems).length) {
      callback({ galleryPath, folderItems })
    }
  }

  // Create a reference under which you want to list
  const listRef = ref(storage, urlRef);

  // Find all the prefixes and items.
  listAll(listRef).then((res) => {
    const { prefixes } = res
    if (prefixes && prefixes.length > 0) {
      prefixes.forEach((folderRef) => {
        const { fullPath, name } = folderRef
        _callback(fullPath, name, prefixes.length)
      });
    }
  }).catch((error) => {
    // Uh-oh, an error occurred!
  });
}