// import { dataUpload } from '../../firestoreData/dataUpload';

import { deleteField } from "firebase/firestore"
import _ from 'lodash'
import { removeFcmTokenFromTokens, updateAppUserProfile } from "../../firestoreData/profiles/updateProfiles"
import { fsfn_notifications } from "../../functions/fbNotifications"

export const _topicCollection = '_topics'

export const rts = {
  handleSet_dbTopics: 'handleSet_dbTopics',
  handleEmails: 'handleEmails',
  handleProfiles: 'handleProfiles',
  handleSend_fcm: 'handleSend_fcm',
  handleSubscribe: 'handleSubscribe',
  handleSubscribeToTopic: 'handleSubscribeToTopic',
  handleTopicFcms: 'handleTopicFcms',
  handleResponse_fcmSend: 'handleResponse_fcmSend',
  handleResponse_fcmClear: 'handleResponse_fcmClear',
  handleResponse_fcmSubscribe: 'handleResponse_fcmSubscribe',
  handleClear_fcm: 'handleClear_fcm',
  handlePending_start: 'handlePending_start',
  handlePending_clear: 'handlePending_clear',
}

export const topicReducer = (state, action) => {

  const { profiles, pathViews } = state
  const { type, dispatch } = action

  const _pending = state.pending ? { ...state.pending } : {}

  const { handleResponse_fcmSubscribe, handleResponse_fcmSend, handleResponse_fcmClear } = topicHandlers(dispatch)

  switch (type) {

    case rts.handleSubscribeToTopic:

      const cb_subscribe = (r) => {
        const { success } = r ?? {}
        console.log('r', r)
        let _confirmation = getFcmComfirmation(success)
        handleResponse_fcmSend(_confirmation)
      }

      handleSubscribeToTopic(action, cb_subscribe)
      return { ...state }

    case rts.handleSubscribe:
      if (action.fcmTokens) {
        const _tokens = []
        Object.keys(action.fcmTokens).forEach(k => {
          _tokens.push(action.fcmTokens[k].tokenKey)
        })
        handleUpdateTopics(_tokens, action.topic, pathViews, action.unsubscribe, handleResponse_fcmSubscribe)
      } else {
        handleUpdateProfilesTopics(profiles, action.topic, pathViews, action.unsubscribe, handleResponse_fcmSubscribe)
      }
      return { ...state }

    case rts.handleSend_fcm:

      const cb_send = (r) => {
        let _confirmation;
        const { result, success } = r ? r : {}
        if (action.topic) {
          _confirmation = getFcmComfirmation(success)
        } else if (action.fcmTokens) {
          const { successCount } = result ?? {}
          _confirmation = getFcmComfirmation(successCount === 1)
        } else if (action.fcmToken) {
          _confirmation = getFcmComfirmation(success)
        }
        handleResponse_fcmSend(_confirmation)
      }

      if (action.topic) {
        fsfn_notifications.sendNotificationByData(action.notificationMethodType, action.notificationData, {}, null, null, action.topic, null, null, cb_send)
      } else if (action.fcmTokens) {
        fsfn_notifications.sendNotificationByData(action.notificationMethodType, action.notificationData, {}, action.fcmTokens, null, null, null, null, cb_send)
      } else if (action.fcmToken) {
        fsfn_notifications.sendNotificationByData(action.notificationMethodType, action.notificationData, {}, null, action.fcmToken, null, null, null, cb_send)
      }

      return { ...state }

    case rts.handleResponse_fcmSend:
      const { confirmation } = action
      _pending.result = confirmation.success ? 'SUCCESS' : 'FAIL'
      return { ...state, confirmation: action.confirmation, pending: _pending }

    case rts.handleResponse_fcmSubscribe:
      const { result } = action
      _pending.result = result.success ? 'SUCCESS' : 'FAIL'
      return { ...state, pending: _pending }

    case rts.handleResponse_fcmClear:
      return { ...state, updating: null }

    case rts.handlePending_start:
      return { ...state, updating: true, pending: action.pending }

    case rts.handlePending_clear:
      return { ...state, updating: false, pending: null }

    case rts.handleProfiles:
      return { ...state, profiles: action.profiles }

    case rts.handleEmails:
      return { ...state, emails: action.emails }

    case rts.handleSet_dbTopics:
      return { ...state, dbTopics: action.dbTopics }

    case rts.handleTopicFcms:
      return { ...state, topicFcms: action.topicFcms }

    case rts.handleClear_fcm:

      const cb_clear = (res1) => {
        removeFcmTokenFromTokens(state.dbTopics, action.tokenKey, pathViews, _topicCollection).then(res2 => {
          handleResponse_fcmClear(res1, res2)
        })
      }

      const dataToUpdate = { fcmTokens: action.fcmTokens }
      if (_.isEmpty(dataToUpdate.fcmTokens)) { dataToUpdate.fcmTokens = deleteField() }

      updateAppUserProfile(action.appUserKey, dataToUpdate, cb_clear)
      // cHU1Pw2ElBIc2eBNRuei7Y:APA91bE8ZPKFgCwSXNlbaDHdvPajuO0V7l-XOxCwFqA0X8BdDsn_0GNHzri5S32JQoyNtVy1b6l6OZjh_vT3yuTAFAtKfnruBpqCZIGc5M25514yWJYf1KXU2QttiaQ2u0kzs3abttok
      // cHU1Pw2ElBIc2eBNRuei7Y:APA91bFSdwHHD9cyd_83GhyEoeAkVbmg7Bac6yzlQJVRxULjpRiZ-4PtIRyWdLbysIA-J8rpcywUheqwVyp2dGa383nv06pyNgRSP-ouLUleiyLRe_g9Tq9Po5xDnbret9GLWDU5TPDk
      return { ...state }

    default:
      return { ...state }
  }
}

export const topicInitialState = (initState) => {
  return { ...initState }
};

export const topicHandlers = (dispatch, state) => {
  return {
    handleClear_fcm: (appUserKey, fcmTokens, tokenType, tokenKey) => { dispatch({ type: rts.handleClear_fcm, dispatch, appUserKey, fcmTokens, tokenType, tokenKey }) },
    handleEmails: (emails) => { dispatch({ type: rts.handleEmails, dispatch, emails }) },
    handlePending_clear: () => { dispatch({ type: rts.handlePending_clear, dispatch }) },
    handlePending_start: (pending) => { dispatch({ type: rts.handlePending_start, dispatch, pending }) },
    handleProfiles: (profiles) => { dispatch({ type: rts.handleProfiles, dispatch, profiles }) },
    handleResponse_fcmClear: (res1, res2) => { dispatch({ type: rts.handleResponse_fcmClear, dispatch, res1, res2 }) },
    handleResponse_fcmSend: (confirmation) => { dispatch({ type: rts.handleResponse_fcmSend, dispatch, confirmation }) },
    handleResponse_fcmSubscribe: (result) => { dispatch({ type: rts.handleResponse_fcmSubscribe, dispatch, result }) },
    handleSend_fcm: (notificationMethodType, notificationData, fcmTokens, topic, fcmToken) => { dispatch({ type: rts.handleSend_fcm, dispatch, notificationMethodType, notificationData, fcmTokens, topic, fcmToken }) },
    handleSet_dbTopics: (dbTopics) => { dispatch({ type: rts.handleSet_dbTopics, dispatch, dbTopics }) },
    handleSubscribe: (topic, fcmTokens, unsubscribe) => { dispatch({ type: rts.handleSubscribe, dispatch, topic, fcmTokens, unsubscribe }) },
    handleSubscribeToTopic: (token, topicKey, unsubscribe, itemKey, callback) => { dispatch({ type: rts.handleSubscribeToTopic, dispatch, token, topicKey, unsubscribe, itemKey, callback }) },
    handleTopicFcms: (topicFcms) => { dispatch({ type: rts.handleTopicFcms, dispatch, topicFcms }) },
  }
}

const handleSubscribeToTopic = (action, callback) => {
  const { token, topicKey, unsubscribe, itemKey } = action
  fsfn_notifications.subscribeToTopic(token, topicKey, unsubscribe, itemKey).then(res => {
    console.log('res', res)
    callback && callback(res)
  })
}

const handleUpdateTopics = (_tokens, selectedTopic, pathViews, unsubscribe, callback) => {
  fsfn_notifications.addTopicToFcmTokens(_tokens, selectedTopic, unsubscribe, pathViews, true).then(res => {
    console.log('res', res)
    callback(res)
  })
}

const handleUpdateProfilesTopics = (profiles, selectedTopic, pathViews, unsubscribe, callback) => {
  const _fcmProfiles = profiles ? _.filter(profiles, 'fcmTokens') : []
  const _fcmTokens = []
  if (_fcmProfiles) {
    _fcmProfiles.forEach(fcmProfile => {
      const { fcmTokens } = fcmProfile ?? {}
      if (fcmTokens) {
        Object.keys(fcmTokens).forEach(fk => {
          const fcmToken = fcmTokens[fk]
          if (fcmToken.tokenKey) {
            _fcmTokens.push(fcmToken.tokenKey)
          }
        })
      }
    })
  }
  fsfn_notifications.addTopicToFcmTokens(_fcmTokens, selectedTopic, unsubscribe, pathViews, true).then(res => {
    console.log('res', res)
    callback()
  })
}

const getFcmComfirmation = (success) => {
  const _confirmation = {
    title: success ? 'Sent' : 'Not Sent',
    success: success ? true : false,
    content: success ? 'The Push Notification has been sent' : 'The Push Notification has NOT been sent',
  }
  return _confirmation
}