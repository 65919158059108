import React from 'react';
import { Icon } from "semantic-ui-react";
import { uniqueKey } from '../common/keys';
import { copyObj } from '../common_web/copy';
import { g_cns } from '../common/cns';

const GenericIcon = (props) => {

  const { giProps } = props ?? {}
  const { key, iconName, onClick, clickOptions, opts, corner } = giProps ?? {}

  const { fl, nf, nb, color, title, actionResult, actionName, circular, inverted, cn, validateAction, loading, sac } = opts ?? {}

  let { size, disabled } = opts ? copyObj(opts) : {}
  let { style, className } = sac ? copyObj(sac) : {}
  if (validateAction) { disabled = onClick(clickOptions, null, actionResult, actionName, true) }
  let sty = style ?? {};
  if (!nf && sty) { sty.float = fl ? 'left' : 'right' }
  const bordered = nb ? false : true
  let iconColor = style ? null : color
  const defaultIconColor = style ? null : 'blue'
  if (!nb) { sty.bordered = true }
  let _className = cn
  if (className) {
    const _size = className.replace('fs-', '')
    size = _size
  }
  const icn = <Icon
    key={uniqueKey('cnt', 'gi', key)}
    bordered={bordered}
    circular={circular ? circular : null}
    className={_className ? _className : null}
    color={iconColor ? iconColor : defaultIconColor}
    corner={corner}
    disabled={disabled}
    inverted={inverted && !sac ? true : null}
    loading={loading ? loading : null}
    name={iconName}
    size={size ? size : null}
    style={sty}
    title={title ? title : null}
    onClick={(e) => { onClick && onClick(clickOptions, e, actionResult, actionName) }}
  />

  return icn

}

export const PopupIcon = (props) => {
  const { piProps } = props ?? {}
  const { onClick, iconName, size, styleAndClass, altCn, color } = piProps ?? {}
  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />
  return <span className={altCn ? altCn : g_cns.item_popup_single_left}>
    {gi('item-icon-pop-' + iconName, iconName, onClick, null, { circular: true, size, color: color ? color : 'blue', inverted: true, sac: styleAndClass })}
  </span>

}

export default GenericIcon