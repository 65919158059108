import React, { useContext, useEffect, useState } from 'react'
import { Card, Icon, Label } from 'semantic-ui-react'
import { ElementContext } from '../../../cnr/contexts/ElementContext'
import { PageAreaParentContext } from '../../../cnr/contexts/PageAreaParentContext'
import { UiItemContext } from '../../../cnr/contexts/UiItemContext'
import { clickToMethods } from '../../../cnr/reducers/ElementReducer'
import { uniqueKey } from '../../../common/keys'
import { gEnums } from '../../../enums/globalEnums'
import { _animateTypes } from '../../../motions/AnimateComponent'
import MotionComponent from '../../../motions/MotionComponent'
import { groupTaggedPropSectionElements } from '../../../viewSettings/helpers/settingsHelpers'
import { addCardImage } from '../../imaging/UiImage'
import { parentBreadcrumbs } from '../breadcrumbs'
import { cardSections, groupedSections } from '../cardSections'
import CardIndicator from './CardIndicator'

const CardNormal = (props) => {

  const { isSubCard } = props

  // parentContext
  const parentContext = useContext(PageAreaParentContext)
  const { states } = parentContext ?? {}
  const { eventInfo_state, transition_state } = states
  const { staticViews } = eventInfo_state ?? {}

  // uiItemContext 
  const uiItemContext = useContext(UiItemContext)
  const { item_state, item_handlers } = uiItemContext ?? {}
  const { handleShow_modifyMapping } = item_handlers ?? {}
  const { propSections_allowed, propGroups } = item_state ?? {}

  // elementContext
  const elementContext = useContext(ElementContext)
  const { element_state } = elementContext ?? {}
  const { itemData, elemProps, clickTo } = element_state ?? {}

  const {
    _display,
    _itemKey,
    _sections,
    aps_global,
    appUserAccess,
    cardImage,
    cardPrps,
    cardQr,
    divRef,
    getParents,
    handleClick,
    imageInContent,
    image_viewItem,
    index,
    noClick,
    page_fns,
    semSecProps,
    showItemIds,
    showItemImage,
    showQrCode,
    style,
    viewItem,
  } = elemProps ?? {}

  const { isSuperAdmin } = appUserAccess ?? {}
  const { cardDisplayType } = _display ?? {}

  // transitionContext  
  const { transitions } = transition_state ?? {}
  const transition = transitions ? transitions[_animateTypes.pageItemElement] : null
  const { showTransition } = transition ?? {}

  const { indicator } = viewItem ?? {}
  const { showIndicator, indicatorProp, indicatorLocation } = indicator ? indicator : {}
  const _indicatorLocation = indicatorLocation ? indicatorLocation : gEnums.bannerLocationTypes.bottom

  const _shows_card = {
    indicatorTop: showIndicator && indicatorProp && _indicatorLocation === gEnums.bannerLocationTypes.top,
    imageAbove: showItemImage && !imageInContent && !isSubCard,
    imageIn: showItemImage && imageInContent && !isSubCard,
    qrCode: showQrCode,
    indicatorBottom: showIndicator && indicatorProp && _indicatorLocation === gEnums.bannerLocationTypes.bottom
  }

  const _cardKeyy = uniqueKey('cpr', _itemKey)

  const [groupedElements, setGroupedElements] = useState()

  useEffect(() => {
    const _semPropsSections = propSections_allowed
    const sectionGroupedElement = groupTaggedPropSectionElements({
      _keyy: _cardKeyy,
      aps_global,
      display: _display,
      itemData,
      propGroups,
      propsSections: _semPropsSections,
      staticViews,
      viewItemKey: _itemKey,
      isSubCard,
      handleShow_modifyMapping,
    })
    setGroupedElements(sectionGroupedElement)

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [itemData]);

  const cardContent = () => {

    const cc = []
    let hasPdf;

    showItemIds && isSuperAdmin && cc.push(
      <div key={uniqueKey('sccid', index)} className='card-id'>
        <Label key={uniqueKey('cs', 'l', index)} attached='top right' size={'mini'} color={'grey'}>{_itemKey}</Label>
      </div>)

    if (clickTo && clickTo.showIcons) {
      switch (clickTo.clickToMethod) {
        case clickToMethods.openAppPdf:
          cc.push(<Icon name='file pdf outline' size='large' color='blue' style={{ float: 'right' }} />)
          hasPdf = true
          break;
        case clickToMethods.openAppUrl:
          cc.push(<Icon name='external alternate' size='large' color='blue' style={{ float: 'right' }} />)
          hasPdf = true
          break;
        default:
        // nothing
      }
    }

    // add breadcrumbs
    cc.push(parentBreadcrumbs(viewItem, getParents, page_fns))

    // add the sections 
    groupedElements && cc.push(groupedSections(groupedElements, semSecProps))
    cc.push(cardSections(_sections, semSecProps))

    return { cc, hasPdf }
  }

  const cardIndicator = () => <CardIndicator key={uniqueKey('sc', 'cn', 'ind', _itemKey)} indicator={indicator} cardKey={_cardKeyy} itemData={itemData} aps_global={aps_global} staticViews={staticViews} />

  const card = () => {

    const { cc, hasPdf } = cardContent()

    let cn = 'card-content'
    if (hasPdf) { cn += ' pdf' }

    return <Card
      ref={divRef}
      key={uniqueKey('sccrd', _itemKey)}
      as='div'
      style={style}
      {...cardPrps}
      className={'card-normal'}
      fluid
      // onMouseMove={e => e.stopPropagation()}
      // onMouseOver={(e) => { handleHover() }}
      onClick={() => handleClick(viewItem, _itemKey, null, { itemData: itemData, noClick, clickTo })}
    >
      {_shows_card.indicatorTop && cardIndicator()}
      {_shows_card.imageAbove && <Card.Content key={uniqueKey('sc', 'cn', 'img-t', _itemKey)} className={'card-image norm'}>{addCardImage(cardImage, image_viewItem, _display)}</Card.Content>}

      <Card.Content key={uniqueKey('sc', 'cn', 'mc', _itemKey)} className={cn}>{cc}</Card.Content>

      {_shows_card.imageIn && <Card.Content key={uniqueKey('sc', 'cn', 'img-i', _itemKey)} className={'card-image norm'}>{addCardImage(cardImage, image_viewItem, _display)}</Card.Content>}
      {_shows_card.qrCode && <Card.Content key={uniqueKey('sc', 'cn', 'qr', _itemKey)} className={'card-qr'}>{cardQr}</Card.Content>}
      {_shows_card.indicatorBottom && cardIndicator()}
    </Card>
  }

  const _showTransition = showTransition && cardDisplayType.indexOf('Plus') < 0

  if (_showTransition) {
    return <MotionComponent
      transition={transition}
    >
      {card()}
    </MotionComponent>
  } else {
    return card()
  }

}

export default CardNormal 