import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Uploader from '../../../components/uploading/Uploader';
import { DataManagementContext } from '../../../components/viewers/DataManagementViewer';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { gEnums } from '../../../enums/globalEnums';
import ImageGallery from '../../../components/imaging/ImageGallery';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import { DataModificationsContext } from '../UiDataModifications';
import { dataModificationTypes } from '../../../viewSettings/enums/itemActionTypes';
import { FrameworkContext } from '../../../cnr/contexts/FrameworkContent';

/** Component for Uploading Images 
  @description updates using (storage_handlers.handleUpdate_gallery)
 */
const UiUpload = (props) => {

  const { handleCancel: _handleCancel } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, storage_state } = states ?? {}
  const { currentGalleryItems, selectedItems } = storage_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings } = pageSettings ?? {}
  const { useClientProfileImage, appUserCollection } = aps_appUserSettings ?? {}

  // dataManagementContext
  const frameworkContext = useContext(FrameworkContext)
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { selectedDataMode, selectedCollectionKey } = framework_state ?? {}
  const { handleShow_appDashboard } = framework_handlers ?? {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state, dataManagement_handlers } = dataManagementContext ?? {}
  const { viewItem, dataManagementType } = dataManagement_state ?? {}
  const { key: viewItemKey } = viewItem ?? {}

  // dataManagementContext
  const dataModificationsContext = useContext(DataModificationsContext)
  const { dataModifications_state, dataModifications_handlers } = dataModificationsContext ?? {}
  const { dataModificationType } = dataModifications_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { viewItem_key, dataName } = item_state ?? {}

  let _viewItemKey = viewItemKey ? viewItemKey : viewItem_key
  if (!_viewItemKey && selectedCollectionKey) { _viewItemKey = selectedCollectionKey }

  let topperCaption;
  let storageLocationType = gEnums.storageLocationTypes.pageDirect
  let storageType = gEnums.storageTypes.image
  let useFull = true

  // local state
  const [selectedImage, setSelectedImage] = useState()
  const [showUpload, setShowUpload] = useState(props.showUpload)

  switch (dataManagementType) {
    case dataModificationTypes.bulkImageUpload:
    case dataModificationTypes.storageImages:
      if (appUserCollection === _viewItemKey) {
        storageLocationType = gEnums.storageLocationTypes.clientProfiles
      } else {
        storageLocationType = gEnums.storageLocationTypes.pageDirect
      }
      topperCaption = showUpload ? 'Bulk Upload' : 'Bulk Images'
      break;
    default:
      topperCaption = showUpload ? 'Upload' : 'Images'
      if (selectedDataMode) {
        if (appUserCollection === selectedCollectionKey) {
          storageLocationType = gEnums.storageLocationTypes.clientProfiles
        } else {
          storageLocationType = gEnums.storageLocationTypes.pageDirect
        }
        useFull = true
      } else {
        useFull = false
      }
      // nothing
      break;
  }

  switch (dataModificationType) {
    case dataModificationTypes.pdfs:
      topperCaption = showUpload ? 'Upload' : 'PDFs'
      storageLocationType = gEnums.storageLocationTypes.pagePdf
      storageType = gEnums.storageTypes.pdf
      break;
    default:
      // nothing
      break;
  }

  useEffect(() => {
    let _selected;
    if (currentGalleryItems && selectedItems) {
      Object.keys(selectedItems).forEach(key => {
        if (currentGalleryItems[key]) {
          _selected = { key, image: currentGalleryItems[key] }
        }
      })
    }
    setSelectedImage(_selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGalleryItems, selectedItems]);

  const handleCancel = () => {
    _handleCancel && _handleCancel()
    dataManagement_handlers && dataManagement_handlers.handleSelect_dataActionOption()
    dataModifications_handlers && dataModifications_handlers.handleDataModificationSelect()
    handleShow_appDashboard && handleShow_appDashboard()
  }

  // const handleSelect = () => storage_handlers.handleUpdate_gallery(selectedImage, _viewItemKey)

  const getTopperCaption2 = () => {
    const _caption = dataName ? dataName : _.startCase(_viewItemKey)
    if (useClientProfileImage) {
      return _caption + ' (to client)'
    } else {
      return _caption
    }
  }

  const wrapper_upload = () => <Wrapper
    content={<Uploader
      handleCancel={handleCancel}
      origin={'uiUpload'}
      storageLocationType={storageLocationType}
      storageType={storageType}
      viewItemKey={_viewItemKey}
    />}
    css={{ footer: 'modify' }}
    wrapperType={wrapperTypes.upload}
  />

  const imageGallery = () => <ImageGallery
    storageLocationType={storageLocationType}
    storageType={storageType}
  />

  // current image gallery
  const fpw_images = () => <FullPageWrapper
    content={imageGallery()}
    topperCaption={topperCaption}
    topperCaption2={getTopperCaption2()}
    handleCancel={handleCancel}
    isBack={props && props.handleCancel ? true : false}
    fullMode={useFull}
    css={{ container: 'upload' }}
  />

  const fpw_upload = () => <FullPageWrapper
    content={wrapper_upload()}
    topperCaption={topperCaption}
    topperCaption2={getTopperCaption2()}
    handleCancel={handleCancel}
    isBack={true}
    fullMode={useFull}
    css={{ container: 'upload' }}
  />

  return showUpload ? fpw_upload() : fpw_images()
}

export default UiUpload