import React, { useContext, useEffect, useState } from 'react';
import { keysEqual } from '../../../global/validation/validation';
import { ParentContext } from '../../../global/cnr/contexts/ParentContext';
import { UiItemContext } from '../../../global/cnr/contexts/UiItemContext';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import UiSaveButtons from '../../../global/components/buttons/UiSaveButtons';
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper';
import { _storageSettings } from '../../../global/storage/storageHelpers';
import { TicketingContext } from '../cnr/contexts/TicketingContext';
import AssignTicket, { assignTypes } from './AssignTIcket';
import SingleTicket, { ticketTypes } from './SingleTicket';
import TicketHelpWizard from './TicketHelpWizard';

/** Returns a single Ticket, with Image if provided */
const TicketInfo = (props) => {

  const { itemData, ticket, ticketType } = props
  let isWillCall = (ticketType === ticketTypes.willCall) ? true : false

  const { galleryTypes } = _storageSettings

  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { storage_state, appUser_state } = states ?? {}
  const { storage_handlers } = handlers ?? {}

  // authContext 
  const { appUser } = appUser_state ?? {}
  const { uid } = appUser ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { viewItem } = item_state ?? {}
  const { display } = viewItem ?? {}
  const { showTicketImage } = display ?? {}

  const ticketingContext = useContext(TicketingContext)
  const { ticketing_state, ticketing_handlers } = ticketingContext ?? {}
  const { subTicketsSelected, presentedTicketsSelected, showTicket, showSend, showAssign, showPresent, appTicketing } = ticketing_state ?? {}
  const { allowAssign } = appTicketing ?? {}

  const _presentCaption = presentedTicketsSelected ? 'Present (' + presentedTicketsSelected.length + ')' : 'Present'
  let _allowPresent = presentedTicketsSelected && presentedTicketsSelected.length > 0 ? true : false

  const _assignCaption = subTicketsSelected ? 'Assign (' + subTicketsSelected.length + ')' : 'Assign'
  const _allowAssign = subTicketsSelected && subTicketsSelected.length > 0 ? true : false

  // storageContext  
  const { collectionImages } = storage_state ?? {}

  const _ticket = ticket ? ticket : itemData
  const { _itemKey: ticketKey, name } = _ticket ?? {}

  const _collectionImages = collectionImages && collectionImages[ticketKey]

  const [showHelp, setShowHelp] = useState()

  const handleShowHelp = () => setShowHelp(!showHelp)

  useEffect(() => {
    if (showTicketImage) {
      storage_handlers.handleGet_galleryImages(galleryTypes.direct, viewItem.key, ticketKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (showTicketImage && _collectionImages) {
      // const itemImageProps = getFirstObject(_collectionImages)
      // const { thumbnailUrl } = itemImageProps ?? {}
      // if (thumbnailUrl) { setItemImageUrl(thumbnailUrl) }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_collectionImages]);

  const handleShowTicket = () => ticketing_handlers.handleShowTicket()
  const handleShowSend = () => ticketing_handlers.handleShowSend(ticketKey)
  const handleShowAssign = () => ticketing_handlers.handleShowAssign(ticketKey)
  const handleShowPresent = () => ticketing_handlers.handleShowPresent(ticketKey)

  const singleTicketItem = (full) => {
    let tt = full ? ticketTypes.full : ticketType
    if (full && ticketType === ticketTypes.lookup) { isWillCall = true }
    return <SingleTicket
      ticket={_ticket}
      ticketType={tt}
      uid={uid}
      isWillCall={isWillCall}
    />
  }

  const footer = () => {
    if (showPresent) {
      return <UiSaveButtons
        preview={{ oc: handleShowPresent, caption: _presentCaption, icon: 'delete', color: 'green' }}
      />
    } else if (showAssign) {
      return <UiSaveButtons
        save={{ oc: handleShowAssign, caption: _assignCaption, icon: 'delete' }}
      />
    } else {
      if (allowAssign) {
        return <UiSaveButtons
          preview={{ oc: handleShowPresent, caption: _presentCaption, disabled: !_allowPresent, icon: 'ticket', color: 'green' }}
          save={{ oc: handleShowAssign, caption: _assignCaption, disabled: !_allowAssign, icon: 'mobile alternate' }}
        />
      } else {
        return <UiSaveButtons
          save={{ oc: handleShowPresent, caption: _presentCaption, disabled: !_allowPresent, icon: 'ticket', color: 'green' }}
        />
      }
    }
  }

  const ticketWrapper = () => <Wrapper
    content={singleTicketItem(true)}
    footer={!isWillCall && footer()}
    wrapperType={wrapperTypes.paddedFooter}
  />

  const assignTicket = (forAssign) => <AssignTicket
    ticket={ticket}
    handleCancel={forAssign ? handleShowAssign : handleShowSend}
    assignType={forAssign ? assignTypes.assign : assignTypes.forward}
  />

  /** Displays a full screen ticket */
  const ticketWizard = () => <FullPageWrapper
    content={ticketWrapper()}
    handleCancel={handleShowTicket}
    topperCaption={name}
    handleHelp={!isWillCall ? handleShowHelp : null}
    noModal={isWillCall}
  />

  if (showHelp) {
    return <TicketHelpWizard handleShowHelp={handleShowHelp} />
  } else {
    if (showAssign && keysEqual(showAssign, ticketKey)) {
      return assignTicket(true)
    } else if (showSend && keysEqual(showSend, ticketKey)) {
      return assignTicket()
    } else if (showTicket && keysEqual(showTicket, ticketKey)) {
      return isWillCall ? ticketWrapper() : ticketWizard()
    } else {
      return singleTicketItem()
    }
  }
}

export default TicketInfo