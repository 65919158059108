import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { StartContext } from '../../cnr/contexts/StartContext';
import { startTexts } from '../../cnr/reducers/StartReducer';
import CircleLoader from '../../motions/CircleLoader';
import DotLoader from '../../motions/DotLoader';
import StartDots from '../../motions/StartDots';

const _loaderTypes = {
  dot: 'dot',
  circle: 'circle',
  dots: 'dots',
}

const StartDimmer = () => {

  const loaderType = _loaderTypes.circle

  // startContext
  const startContext = useContext(StartContext)
  const { start_state } = startContext ?? {}
  const { startCaption, startDimmerOn, startType } = start_state ?? {}

  const _startCaption = startCaption ? startCaption : startTexts.app

  const loader = () => {
    switch (loaderType) {
      case _loaderTypes.dot:
        return <DotLoader startType={startType} />
      case _loaderTypes.circle:
        return <CircleLoader startType={startType} />
      case _loaderTypes.dots:
        return <StartDots startType={startType} />
      default:
      // nothing
    }
  }

  const dimmer = () => <motion.div
    className={'start-dimmer'}
    initial={{ opacity: 1 }}
    animate={{ opacity: startDimmerOn ? 1 : 0 }}
    exit={{ opacity: 0, display: 'none' }}
    transition={{ duration: .2 }}
  >
    <motion.h4
      style={{ color: 'white' }}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, delay: 0.3 }}
    >
      {_startCaption}
    </motion.h4>
    {loader()}
  </motion.div>

  return dimmer()
}

export default StartDimmer