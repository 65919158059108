import React, { useContext } from 'react';
import { Segment } from 'semantic-ui-react';
import { HeaderContext } from '../../../cnr/contexts/HeaderContext';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { appIconTypes } from '../../../enums/appIconTypes';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';

const UiAppUserAction = (props) => {

  const { handleCancel } = props ?? {}

  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { paps_state, page_state } = states ?? {}
  const { appUser_handlers } = handlers ?? {}

  // headerContext
  const headerContext = useContext(HeaderContext)
  const { header_state } = headerContext ?? {}
  const { itemLinked } = header_state ?? {}

  // papsContext 
  const { view, viewKey } = paps_state ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_page } = pageSettings ?? {}
  const { viewItems: viewItems_page } = aps_page ?? {}
  let viewItem_page = viewItems_page && viewItems_page[view] ? viewItems_page[view] : {}
  const { appUserPagePermissions } = viewItem_page ?? {}
  const { itemLinking } = appUserPagePermissions ?? {}
  const { actionCaption, actionDescription } = itemLinking ?? {}

  const uiItemContext = useContext(UiItemContext);
  const { item_handlers } = uiItemContext ?? {}
  const { handleShow_itemSidebar } = item_handlers ?? {}

  const callback = () => handleCancel ? handleCancel() : handleShow_itemSidebar()

  const handleAction = () => appUser_handlers.handleLink_appUserData(view, viewKey, callback)

  const container = () => <Wrapper
    content={<Segment basic>
      {actionDescription ? actionDescription : actionCaption + '?'}
    </Segment>}
    footer={appUser_handlers && <UiSaveButtons save={{ oc: handleAction, caption: actionCaption, icon: itemLinked ? appIconTypes.delete : appIconTypes.check, color: itemLinked ? 'red' : 'green' }} />}
    wrapperType={wrapperTypes.padded}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={container()}
    handleCancel={handleCancel ? handleCancel : handleShow_itemSidebar}
    topperCaption={actionCaption}
  />

  return fullPageWrapper()

}

export default UiAppUserAction