import { throttle } from 'lodash'
import React, { useContext } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { DragNDropContext } from '../cnr/contexts/DragNDropContext'

/**
 * 
 * @param {object} props (content, dcKey, groupKey, index)
 * @returns the content wrapped in DragDropContext (react-beautiful-dnd)
 * handles onDragEnd
 */
const DragContext = (props) => {

  const { content, groupKey } = props

  const dragNDropContext = useContext(DragNDropContext)
  const { dragNDrop_handlers } = dragNDropContext ?? {}
  const { handleDrag_end } = dragNDrop_handlers ?? {}

  const handleDragEnd = throttle((res) => {
    // Your onDragEnd logic here
    handleDrag_end(res, groupKey)
  }, 500);


  const ddContext = () => <DragDropContext onDragEnd={handleDragEnd}  >
    {content}
  </DragDropContext>

  return ddContext()

}

export default DragContext
