import React, { createContext, useContext, useEffect, useState } from 'react';
import { _animateTypes, _dimmerTypes } from '../../motions/AnimateComponent';
import { animationVariants } from '../../motions/animationVariants';
import { HomeSettingsContext } from './SettingsDataContext';

export const TransitionContext = createContext();

const TransitionProvider = (props) => {

  const homeSettingsContext = useContext(HomeSettingsContext)

  const { homeSettings_state } = homeSettingsContext ?? {}
  const { homeSettings } = homeSettings_state ?? {}
  const { global: global_h } = homeSettings ?? {}
  const { transitionItems, dimmerItems } = global_h ?? {}

  const [transitions, setTransitions] = useState()
  const [dimmers, setDimmers] = useState()
  const [isNewRoute, setIsNewRoute] = useState()

  useEffect(() => {
    const _transitions = {}
    if (transitionItems) {
      Object.keys(_animateTypes).forEach(at => {
        const { variants, useTransitions } = animationVariants(at, transitionItems)
        _transitions[at] = { animateType: at, variants, showTransition: useTransitions }
      })
    }
    setTransitions(_transitions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitionItems]);

  useEffect(() => {
    const _dimmers = {}
    if (dimmerItems) {
      Object.keys(_dimmerTypes).forEach(dt => {
        const di = dimmerItems[dt]
        _dimmers[dt] = di
        _dimmers[dt].dimmerType = dt
      })
    }
    setDimmers(_dimmers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimmerItems]);

  const transition_handlers = {
    handleIsNewRoute: (isNewRoute) => {
      setIsNewRoute(isNewRoute)
    }
  }

  return transitions ? <TransitionContext.Provider value={{ transition_state: { transitions, isNewRoute, dimmers }, transition_handlers }} >
    {props.children}
  </TransitionContext.Provider> : <div></div>

};

export default TransitionProvider 