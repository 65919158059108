import _ from 'lodash';
import React, { } from 'react';
import { Icon } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';
import { gEnums } from '../../../enums/globalEnums';
import { iconColorTypes, settingsIconTypes } from '../../../enums/settingsIconTypes';
import { menuItemItems } from './menuItemHelpers';

/** Div containing Icons associated with show, tagged, css, drillDown and secret */
const MenuItemIcons = (props) => {

  const { menuItem, handleIconClick, menuItemAllows, itemKey, useDarkMode } = props

  const { iconProps } = menuItem

  const getIconProps = (type) => {
    return {
      size: 'small',
      inverted: useDarkMode,
      title: _.startCase(type)
    }
  }

  const genIcon = (mii) => {
    const value = iconProps[mii]
    const color = iconColorTypes[mii]
    return <Icon key={uniqueKey('mii', 'i', mii, itemKey)}
      color={value ? color : iconColorTypes.default}
      name={settingsIconTypes[mii]}  {...getIconProps(mii)}
      onClick={(e) => { handleIconClick(e, gEnums.menuItemOptionTypes[mii]) }}
    />
  }

  const miis = []

  Object.keys(menuItemItems).forEach(mii => {
    if (menuItemAllows[mii]) {
      miis.push(genIcon(mii))
    }
  })

  const tagDiv = () => <div className={'smii tt'}>
    {miis}
  </div>

  return tagDiv()
}

export default MenuItemIcons