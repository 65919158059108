import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { Sidebar } from 'semantic-ui-react';
import { ParentContext } from '../../../global/cnr/contexts/ParentContext';
import { convertHelpers } from '../../../global/common/convert';
import { copyObj } from '../../../global/common_web/copy';
import { DimmerPending } from '../../../global/components/alerts/DimmerWrap';
import UiSaveButtons from '../../../global/components/buttons/UiSaveButtons';
import DropdownOptionsMenu from '../../../global/components/forms/elements/DropdownOptionsMenu';
import DataSidebar from '../../../global/components/swipers/DataSidebar';
import JsonViewer from '../../../global/components/viewers/JsonViewer';
import { appIconTypes } from '../../../global/enums/appIconTypes';
import { iconColorTypes } from '../../../global/enums/settingsIconTypes';
import { fsfn_auth } from '../../../global/functions/fbAuth';
import UiSideMenuWrapper from '../../../global/pageItem/modification/dataModifications/UiSideMenuWrapper';
import { openExternal } from '../../../global/viewSettings/helpers/settingsLinks';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper';
import { SportsDistrictContext } from '../cnr/contexts/SportsDistrictContext';
import { SportsSeasonContext } from '../cnr/contexts/SportsSeasonContext';
import { sportsSidebarHandlers, sportsSidebarInitialState, sportsSidebarReducer } from '../cnr/reducers/SportsSidebarReducer';
import { _sdm } from '../elements/dashboard/SportsDashboard';
import MatchDataUpdate from '../elements/matches/MatchDataUpdate';
import SportFormProps from './SportFormProps';
import SportsData from './subs/SportsData';
import { SportsRosters } from './subs/SportsRosters';

export const sportAccessTypes = {
  none: 'none',
  accessLink: 'accessLink',
  googleLink: 'googleLink',
  news: 'news',
  school: 'school',
  scoreLink: 'scoreLink',
  sport: 'sport',
  team: 'team',
  tournamentResults: 'tournamentResults',
}

export const SportsSidebarContext = createContext();

const SportsDataSidebar = (props) => {

  const { forSportsManagement, sportsDataMode } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, fns } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { database_fns } = fns ?? {}
  const { appUser_handlers } = handlers ?? {}
  const { pathViews } = paps_state ?? {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state, sportsDistrict_handlers } = sportsDistrictContext ?? {}
  const { schools: schools_district, sports: sportsGlobal, sportsAccess_district, sportsAccess_schools, gls_access, gls_scores, sportPermissions } = sportsDistrict_state ?? {}
  const { schools: access_schools, sports: access_sports } = sportsAccess_district ?? {}
  const { schools: access_schools_schools } = sportsAccess_schools ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { selectedSeason, sportsKey, _sportsModeKey, teams_info, tournaments_info, sportsAccess_sports, gls_teams } = sportsSeason_state ?? {}
  const { teams: access_teams } = sportsAccess_sports ?? {}
  const { teams, rosters } = teams_info ?? {}
  const { tournaments } = tournaments_info ?? {}

  const _initState = {
    sportPermissions,
    parent: {
      access: {
        access_schools_schools,
        access_schools,
        access_sports,
        access_teams,
      },
      gls: {
        gls_scores,
        gls_teams,
      },
    },
    pathViews,
    rosters,
    schools_district,
    selectedSeason,
    sportsKey,
    sportsModeKey: _sportsModeKey,
    teams,
    tournaments,
    database_fns,
  }

  const [sportsSidebar_state, sportsSidebar_dispatch] = useReducer(sportsSidebarReducer, sportsSidebarInitialState(_initState));
  const sportsSidebar_handlers = sportsSidebarHandlers(sportsSidebar_dispatch)

  const {
    current_accessOrGls,
    accessOptions,
    collectionName,
    currentItem,
    currentMenuKey,
    formData,
    glsScores,
    glsTeams,
    isContent,
    menuItems,
    menuObjects,
    roster,
    saveCaption,
    sheetsData,
    sheetType,
    showItemKey,
    staff,
    updating,
  } = sportsSidebar_state ?? {}

  const {
    schoolsAccess,
    sportsAccess,
    teamsAccess,
    schoolsContacts,
    districtContacts
  } = sheetsData ?? {}

  const _sportsGlobal = Object.keys(_.groupBy(sportsGlobal, 'name'))

  const [caption, setCaption] = useState()
  const [mode_right, setMode_right] = useState()

  const _sports_current = {}

  let _sports_active;
  let _sports_global;

  if (sportsGlobal) {
    Object.keys(sportsGlobal).forEach(key => {
      _sports_current[key] = copyObj(sportsGlobal[key])
      _sports_current[key]._itemKey = key
      if (_sports_active && _sports_active[key] && _sports_active[key].active) {
        _sports_current[key].active = true
      }
    })

    _sports_global = sportsGlobal ? copyObj(sportsGlobal) : null
    convertHelpers.createItemKeys(_sports_global)
  }

  // this will handle the initial data
  useEffect(() => {
    sportsSidebar_handlers.handleInit_sportsSidebar(sportsDataMode, _sports_current, access_teams, access_sports, access_schools)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sportsDataMode, sportsAccess_sports, access_teams, access_sports, access_schools]);

  useEffect(() => {
    if (roster) { handleMode_right(roster) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [roster]);

  useEffect(() => {
    if (staff) { handleMode_right(staff) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [staff]);

  useEffect(() => {
    if (schoolsContacts) { handleMode_right(schoolsContacts) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [schoolsContacts]);

  useEffect(() => {
    if (districtContacts) { handleMode_right(districtContacts) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [districtContacts]);

  useEffect(() => {
    if (sportsAccess && sportsGlobal && access_sports) {
      const _access_sports = {}
      _.forEach(sportsAccess, (sportAccess, key) => {
        const { sport, name: email, accessLevels } = sportAccess
        const _sportKey = _.findKey(sportsGlobal, { name: sport })
        if (_sportKey && access_sports[_sportKey]) {
          _access_sports[_sportKey] = [{ name: email, accessLevel: _.camelCase(accessLevels) }]
        } else {
          _access_sports[_sportKey] = [{ name: email, accessLevel: _.camelCase(accessLevels) }]
        }
      })
      sportsDistrict_handlers.handleAmmend_sportsAccess(_access_sports)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sportsAccess]);

  useEffect(() => {
    if (schoolsAccess && schools_district && access_schools) {
      const _access_schools = {}
      _.forEach(schoolsAccess, (schoolAccess, key) => {
        const { schools, name: email, accessLevels } = schoolAccess
        const _schoolKey = _.findKey(schools_district, { name: schools })
        if (_schoolKey && access_schools[_schoolKey]) {
          _access_schools[_schoolKey] = [{ name: email, accessLevel: _.camelCase(accessLevels) }]
        } else {
          _access_schools[_schoolKey] = [{ name: email, accessLevel: _.camelCase(accessLevels) }]
        }
      })
      sportsDistrict_handlers.handleAmmend_schoolsAccess(_access_schools)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [schoolsAccess]);

  useEffect(() => {
    if (teamsAccess && teams && access_teams) {
      const _access_teams = {}
      _.forEach(teamsAccess, (teamAccess, key) => {
        const { teams: team, name: email, accessLevels } = teamAccess
        const _teamKey = _.findKey(teams, { name: team })
        if (_teamKey && access_teams[_teamKey]) {
          _access_teams[_teamKey] = [{ name: email, accessLevel: _.camelCase(accessLevels) }]
        } else {
          _access_teams[_teamKey] = [{ name: email, accessLevel: _.camelCase(accessLevels) }]
        }
      })
      sportsSeason_handlers.handleAmmend_teamsAccess(_access_teams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [teamsAccess]);

  const handleAuthClick = (x) => {
    const cb = (r) => {
      console.log('r', r)
    }
    const obj = {}
    x.email = x.name
    obj[x.name] = x
    fsfn_auth.getAuthsByEmail(obj, cb)
  }

  const handleAccessClone = (x) => appUser_handlers.handleAccessClone(x)
  const handleShowCreateNew = () => sportsSidebar_handlers.handleShow_createNew()
  const handleCurrentMenuKey = (cmk) => sportsSidebar_handlers.handleSet_currentMenuKey(cmk)
  const handleDatabaseOpen = () => openExternal.db(pathViews)

  const getSheetsData = (tabName) => {
    sportsSidebar_handlers.handleStartUpdate()
    sportsSidebar_handlers.handleGet_gsSheetData(gls_access.googleSheetsId, tabName)
  }

  const handleGetSheetData_schoolsAccess = () => getSheetsData('Schools Access')
  const handleGetSheetData_sportsAccess = () => getSheetsData('Sports Access')
  const handleGetSheetData_teamAccess = () => getSheetsData('Teams Access')
  const handleGetSheetData_schoolsContact = () => getSheetsData('Schools Contacts')
  const handleGetSheetData_districtContact = () => getSheetsData('District Contacts')

  const handleMode_right = (mr) => setMode_right(mr)

  const handlePreview_accessInfo = () => handleMode_right(current_accessOrGls)

  const handleSave_accessInfoToDatabase = () => {
    sportsSidebar_handlers.handleStartUpdate()
    sportsSidebar_handlers.handleSave_accessInfoToDatabase()
  }

  const handleSave_glsToDatabase = () => {
    sportsSidebar_handlers.handleStartUpdate()
    sportsSidebar_handlers.handleSave_glsToDatabase(mode_right)
  }

  const handleSave_teamSettings = () => {
    sportsSidebar_handlers.handleStartUpdate()
    sportsSidebar_handlers.handleSave_teamSettings(sheetType, mode_right, currentMenuKey)
  }

  const handleOpen_googleSheets = () => {

    switch (sportsDataMode) {
      case _sdm.district.accessSchools:
      case _sdm.district.accessSport:
      case _sdm.sports.accessTeams:
        openExternal.googleSheets(gls_access.googleSheetsId)
        break;

      case _sdm.sports.glsTeam:
        if (glsTeams && glsTeams[currentMenuKey] && glsTeams[currentMenuKey].googleSheetsId) {
          openExternal.googleSheets(glsTeams[currentMenuKey].googleSheetsId)
        }
        break;

      case _sdm.district.glsScores:
        if (glsScores && glsScores[currentMenuKey] && glsScores[currentMenuKey].googleSheetsId) {
          openExternal.googleSheets(glsScores[currentMenuKey].googleSheetsId)
        }
        break;
      default:
    }
  }

  const handleCreate_gsTeamSheetsWithProtection = async () => {
    if (access_teams && teams) {
      sportsSidebar_handlers.handleStartUpdate()
      sportsSidebar_handlers.handleCreate_gsTeamSheetsWithProtection(access_teams, teams)
      sportsSidebar_handlers.handleStopUpdate()
    }
  }

  const handleGetGs = (st) => {
    switch (sportsDataMode) {
      case _sdm.sports.glsTeam:
        sportsSidebar_handlers.handleStartUpdate()
        if (glsTeams && glsTeams[currentMenuKey] && glsTeams[currentMenuKey].googleSheetsId) {
          sportsSidebar_handlers.handleGet_gsTabValues(glsTeams[currentMenuKey], st)
        }
        break;
      case _sdm.district.glsScores:
        sportsSidebar_handlers.handleStartUpdate()
        if (glsScores && glsScores[currentMenuKey] && glsScores[currentMenuKey].googleSheetsId) {
          sportsSidebar_handlers.handleGet_gsTabValues(glsScores[currentMenuKey], st)
        }
        break;
      default:
      // nothing
    }
  }

  const handleSaveToGoogleSheets = async () => {

    // const updateGsAccess_district = async (globalItems, globalItem, sport) => sportsSidebar_handlers.handleUpdate_gsDistrictAccess(sportsAccess, globalItems, globalItem, sport)

    // if (sportsAccess) {
    //   switch (sportsDataMode) {
    //     case _sdm.district.accessSchools:
    //       updateGsAccess_district(schools_district, 'school')
    //       break;

    //     case _sdm.district.accessSport:
    //       updateGsAccess_district(sportsGlobal, 'sport')
    //       break;

    //     case _sdm.sports.accessTeams:
    //       updateGsAccess_district(teams, 'team', _sportsModeKey)
    //       break;
    //     default:
    //   }
    // }
  }

  const handleFormChange = (e, data) => {
    const _formData = { ...formData }
    const { value, propname, checked } = data
    if (data.type === 'checkbox') {
      _formData[propname] = checked
    } else {
      _formData[propname] = value
    }
    sportsSidebar_handlers.handleSet_formData(_formData)
  }

  const content_right = () => {

    switch (sportsDataMode) {

      case _sdm.district.accessSchools:
      case _sdm.district.accessSport:
      case _sdm.district.contactsDistrict:
      case _sdm.district.contactsSchools:
      case _sdm.district.glsScores:
      case _sdm.sports.accessTeams:
      case _sdm.sports.news:
      case _sdm.sports.teamRosters:
      case _sdm.sports.tournamentResults:
        return <Wrapper
          header={_.startCase(sportsDataMode)}
          content={<JsonViewer json={mode_right} name={'Data to Update: (' + collectionName + ')'} />}
          footer={footer_right()}
          wrapperType={wrapperTypes.paddedHeaderAndFooter}
        ></Wrapper>

      case _sdm.sports.glsTeam:
        return <Wrapper
          header={_.startCase(sheetType)}
          content={<JsonViewer json={mode_right} name={'Data to Update: (' + collectionName + ')'} />}
          footer={
            <UiSaveButtons
              preview={{ oc: handleOpen_googleSheets, caption: 'Open Google Sheet', icon: appIconTypes.externalAlternate, color: iconColorTypes.external }}
              save={{ oc: handleSave_teamSettings, caption: 'Update', color: sportPermissions.any ? 'green' : 'olive' }}
            />
          }
          wrapperType={wrapperTypes.paddedHeaderAndFooter}
        ></Wrapper>
      default:
        return <div>JSON</div>
    }
  }

  const sidebar_right = (visible) => <DataSidebar visible={visible} content={visible && content_right()} onHide={handleMode_right} direction={'right'} />

  const getContentElements = () => {

    switch (sportsDataMode) {

      case _sdm.district.updateDistrictSeasonScores:
        return <MatchDataUpdate forSportsManagement={true} sportsDataMode={sportsDataMode} currentMenuKey={currentMenuKey} />

      case _sdm.district.sportsData:
        return <SportsData sportsDataMode={sportsDataMode} currentItemKey={currentMenuKey} currentItem={currentItem} />

      case _sdm.sports.teamRosters:
        return <SportsRosters currentItem={currentItem} currentMenuKey={currentMenuKey} />

      default:
        const inputs = []
        if (formData) {
          return <SportFormProps
            accessOptions={accessOptions}
            currentItem={currentItem}
            formData={formData}
            handleAccessClone={handleAccessClone}
            // handleAuthClick={handleAuthClick}
            handleFormChange={handleFormChange}
            handleGetGs={handleGetGs}
            sportsDataMode={sportsDataMode}
          />
        }
        return inputs
    }
  }

  const wrapperContent = () => {
    const contentElems = getContentElements()
    return <UiSideMenuWrapper
      menuItems={menuItems}
      menuObjects={menuObjects}
      contentElements={contentElems}
      isContent={isContent}
      setCurrentMenuKey={handleCurrentMenuKey}
      showItemKey={showItemKey}
      sportsDataMode={sportsDataMode}
    />
  }

  const footer_right = () => {

    const btns = []
    const btns_dd = []

    switch (sportsDataMode) {
      case _sdm.sports.accessTeams:
        btns.push({ oc: handleSave_accessInfoToDatabase, caption: 'Update', icon: appIconTypes.save, color: sportPermissions.any ? 'green' : 'olive' })
        btns_dd.push({ oc: handleOpen_googleSheets, caption: 'Open Google Sheet', icon: appIconTypes.externalAlternate, color: iconColorTypes.external })
        btns_dd.push({ oc: handleCreate_gsTeamSheetsWithProtection, caption: 'Create Team Sheets', icon: appIconTypes.externalAlternate, color: iconColorTypes.external })
        btns_dd.push({ oc: handleSaveToGoogleSheets, caption: 'Download to Google Sheets', icon: 'database', color: iconColorTypes.external })
        break;

      case _sdm.district.glsScores:
        btns.push({ oc: handleSave_glsToDatabase, caption: 'Update ' + _.startCase(sportsDataMode), icon: appIconTypes.save, color: sportPermissions.any ? 'green' : 'olive' })
        break;

      case _sdm.district.contactsDistrict:
      case _sdm.district.contactsSchools:
        btns.push({ oc: handleSave_accessInfoToDatabase, caption: 'Update' + _.startCase(sportsDataMode), icon: appIconTypes.save, color: sportPermissions.any ? 'green' : 'olive' })
        break;

      case _sdm.sports.tournamentResults:
        btns.push({ oc: handleSave_accessInfoToDatabase, caption: 'Update' + _.startCase(sportsDataMode), icon: appIconTypes.save, color: sportPermissions.any ? 'green' : 'olive' })
        break;

      case _sdm.sports.teamRosters:
        btns.push({ oc: handleSave_accessInfoToDatabase, caption: 'Update' + _.startCase(sportsDataMode), icon: appIconTypes.save, color: sportPermissions.any ? 'green' : 'olive' })
        break;

      default:
      // nothing
    }

    if (btns_dd.length > 0) {
      return <div className={'header-flex'}>
        <div>
          <DropdownOptionsMenu menuButtons={btns_dd} caption={'Options'} icon={'unordered list'} color={'blue'} upward={true} />
        </div>
        <div>
          <UiSaveButtons
            others={btns}
            color={'blue'}
          />
        </div>
      </div>
    } else {
      return <UiSaveButtons
        others={btns}
        color={'blue'}
      />
    }
  }

  const footer_main = () => {

    const btns = []
    btns.push({ caption: saveCaption, oc: handlePreview_accessInfo, icon: 'arrow right', color: 'blue', title: collectionName })

    const btns_dd = [{ caption: 'Open Database', oc: handleDatabaseOpen, icon: 'database', color: iconColorTypes.external }]

    switch (sportsDataMode) {
      case _sdm.district.accessSport:
        btns_dd.push({ caption: 'Get Sport Access Sheet Data', oc: handleGetSheetData_sportsAccess, icon: 'google', color: iconColorTypes.external })
        break;

      case _sdm.district.accessSchools:
        btns_dd.push({ caption: 'Get Schools Access Sheet Data', oc: handleGetSheetData_schoolsAccess, icon: 'google', color: iconColorTypes.external })
        break;

      case _sdm.sports.accessTeams:
        btns_dd.push({ caption: 'Get Teams Access Sheet Data', oc: handleGetSheetData_teamAccess, icon: 'google', color: iconColorTypes.external })
        break;

      case _sdm.district.contactsSchools:
        btns_dd.push({ caption: 'Get Schools Contacts Sheet Data', oc: handleGetSheetData_schoolsContact, icon: 'google', color: iconColorTypes.external })
        break;

      case _sdm.district.contactsDistrict:
        btns_dd.push({ caption: 'Get District Contacts Sheet Data', oc: handleGetSheetData_districtContact, icon: 'google', color: iconColorTypes.external })
        break;

      case _sdm.sports.tournamentResults:
      case _sdm.sports.news:
        btns_dd.push({ caption: 'Add ' + _.startCase(sportsDataMode), oc: handleShowCreateNew, icon: 'google', color: iconColorTypes.external })
        break;

      default:
      // nothing
    }

    return <div className={'header-flex'}>
      <div>
        <DropdownOptionsMenu menuButtons={btns_dd} caption={'Actions'} icon={'unordered list'} color={'blue'} upward={true} />
      </div>
      <div>
        <UiSaveButtons
          others={btns}
          color={'blue'}
        />
      </div>
    </div>
  }

  const wrapper = () => <Wrapper
    content={wrapperContent()}
    footer={!isContent && footer_main()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    updating={updating}
  />

  const wrapper_sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_right(mode_right ? true : false)}
    <Sidebar.Pusher dimmed={mode_right ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const sidebar_full = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_right(mode_right ? true : false)}
    <Sidebar.Pusher dimmed={mode_right ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar_full()}
    topperCaption={caption}
  />

  const content = () => forSportsManagement ? wrapper_sidebar() : fullPageWrapper()

  const content_provider = () => <SportsSidebarContext.Provider value={{ sportsSidebar_state, sportsSidebar_handlers }}>
    {content()}
  </SportsSidebarContext.Provider>

  return _sportsGlobal ? content_provider() : <DimmerPending />

}

export default SportsDataSidebar