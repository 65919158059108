import _ from 'lodash';
import { gEnums } from '../enums/globalEnums';
import { currentHelpers } from '../redirection/current';
import { appUserPermissionTypes, dataDataActionTypes, dataModificationTypes, dataSidebarActionTypes, itemActionTypes, pageItemActionTypes } from '../viewSettings/enums/itemActionTypes';

/**
 * 
 * @param {object} dataManagementSettings 
 * @param {object} appDataManagement 
 * @param {object} appUserAccess 
 * @returns a list of objects that 
 */
export const getDataManagementOptions = (dataManagementSettings, appDataManagement, appUserAccess) => {

  const { accessLevel } = appUserAccess ?? {}

  let _dmo = {}

  // loop the dataDataActionTypes (ammendPropData, backupItemData, bulkImageUpload, etc.)
  Object.keys(dataDataActionTypes).forEach(dat => {
    const dms = dataManagementSettings && dataManagementSettings[dat]
    const adm = appDataManagement && appDataManagement[dat]

    let _accessLevel = 0

    // if global has a settings, use that
    if (adm && adm.allowAction && adm.accessLevel) {
      _accessLevel = adm.accessLevel
    } else {
      if (dms) {
        if (dms && dms.settingsAuthLevel) {
          _accessLevel = dms.settingsAuthLevel
        }
      }
    }
    _dmo[dat] = { allowAction: accessLevel >= _accessLevel }
  })

  return _dmo
}

export const getDataSidebarOptions = (dataManagementSettings, appDataManagement, appUserAccess) => {

  const { accessLevel } = appUserAccess ?? {}

  let _dmo = {}

  // loop the dataDataActionTypes (ammendPropData, backupItemData, bulkImageUpload, etc.)
  Object.keys(dataSidebarActionTypes).forEach(dat => {
    const dms = dataManagementSettings && dataManagementSettings[dat]
    const adm = appDataManagement && appDataManagement[dat]

    let _accessLevel = 0

    // if global has a settings, use that
    if (adm && adm.allowAction && adm.accessLevel) {
      _accessLevel = adm.accessLevel
    } else {
      if (dms) {
        if (dms && dms.settingsAuthLevel) {
          _accessLevel = dms.settingsAuthLevel
        }
      }
    }
    _dmo[dat] = { allowAction: accessLevel >= _accessLevel }
  })

  return _dmo
}


/**
 * 
 * @param {object} dataModifications_pageItem 
 * @param {object} dataModifications_global 
 * @returns  the merged `dataModifications` from the page and global
 */
export const getDataModifications = (dmProps) => {

  const {
    appUserAccess,
    dataModifications_global,
    dataModifications_pageItem,
    trimBySingle,
    view,
    viewItemKey,
    viewItemStatus,
  } = dmProps

  const { appUserSession, appUserAccessType, accessLevelTypes } = appUserAccess ?? {}
  const { appUserEvent } = appUserSession ?? {}

  let _allowCombo

  switch (appUserAccessType) {
    case gEnums.appUserAccessTypes.appDataAdminLimited:
      if (accessLevelTypes && accessLevelTypes.includes(viewItemKey)) {
        _allowCombo = appUserAccess && appUserAccess.accessLevel >= gEnums.authLevels.appDataAdmin.value
      }
      break;
    default:
      _allowCombo = appUserAccess && appUserAccess.accessLevel >= gEnums.authLevels.appDataAdmin.value
  }

  const { isViewItemList, isViewItemSubList, isViewItemSolo } = viewItemStatus ?? {}

  // adjust for dataModifications at the page level where allowAction = false
  _.forEach(dataModifications_pageItem, (dm, key) => {
    if (!dm.allowAction) {
      delete dataModifications_pageItem[key]
    }
  })

  let _dm = {}

  if (_allowCombo) {
    if (dataModifications_global && dataModifications_pageItem) {
      _dm = { ...dataModifications_global, ...dataModifications_pageItem }
    } else if (dataModifications_global) {
      _dm = { ...dataModifications_global }
    }
  } else {
    _dm = dataModifications_pageItem ? { ...dataModifications_pageItem } : {}
  }

  if (trimBySingle) {
    if (isViewItemSolo) {
      // single
      delete _dm.add
      delete _dm.addWithImage
      delete _dm.sorting
      delete _dm.activate
    } else {
      // multi
      delete _dm.edit
      delete _dm.dataLinking
      delete _dm.images
      delete _dm.pdfs
    }
  }

  if (!isViewItemList && !isViewItemSubList && view !== 'landing') {
    delete _dm.add
  }

  delete _dm.bulkImageUpload
  delete _dm.imageMapping
  delete _dm.filters
  delete _dm.modifyList

  return _dm

}

/**
 * returns 
 * @param {object} props (accessRequests, authAppUser, itemData, pageSettings, viewItemStatus, view, viewItem, viewKey)
 * @returns whether the `action popup` will be displayed
 */
export const validateUiActionAccess = (props) => {

  const {
    accessRequests,
    appUserAccess,
    appUserDataModifications,
    itemData,
    pageSettings,
    view,
    viewItem,
    viewItemStatus,
    viewKey,
  } = props


  const { isViewItemSolo } = viewItemStatus ?? {}

  const { accessLevel: accessLevel_appUser, appUserSession, loggedIn } = appUserAccess ?? {}
  const { appUserEvent } = appUserSession ?? {}

  const { pageItemActions, dataModifications: dataModifications_pageItem, appUserPagePermissions, search, maps } = viewItem ?? {}
  const { aps_global, aps_viewItems, aps_appUserSettings } = pageSettings ?? {}

  const _viewItemKey = !_.isEmpty(viewItem) ? viewItem.key : view

  const {
    appNotifications,
    appSignIn,
    appUserPermissions,
    dataModifications: dataModifications_global,
    dataPermissions,
    imageMapping,
    tools,
  } = aps_global ?? {}

  const { allowNotifications, allowDirectToNotificationTargets, notificationTargets, notificationsAuthLevel } = appNotifications ?? {}
  const { allowRating, ratingCollections, allowRequestAccess, requestAccessCollections } = appUserPermissions ?? {}
  const { appUserCollection, appUserCollections, allowCloning, appUserViewTypeProp } = aps_appUserSettings ?? {}
  const { allowSignInDirect, directSignInType } = appSignIn ?? {}
  const { showSearch, searchOnPage } = search ?? {}
  const { showGeoMapList } = maps ?? {}
  const { showItemCount } = tools ?? {}


  // LOOK
  // dataModifications_pageItem && dataFix.removeEmpties(dataModifications_pageItem)

  const { appUserDataPermissions, appUserDataPermission_allowed } = getAppUserAccessPermissions(appUserDataModifications, appUserEvent, appUserViewTypeProp, _viewItemKey)

  // const _dataManagement = {}
  const dmProps = {
    appUserAccess,
    dataModifications_global,
    dataModifications_pageItem,
    trimBySingle: true,
    view,
    viewItemKey: _viewItemKey,
    viewItemStatus,
    appUserDataPermission_allowed
  }

  const _dataModifications = getDataModifications(dmProps)

  const { allowItemDataModification, accessLevel: accessLevel_gdo } = dataPermissions ?? {}

  const appSettings_viewItem = aps_viewItems && viewItem && aps_viewItems[viewItem.key] ? aps_viewItems[viewItem.key] : null
  const { dataModifications: dataModifications_app } = appSettings_viewItem ?? {}

  const { allowCollectionMapping, collectionNames, locationProps } = imageMapping ?? {}
  const _allowMapping = allowCollectionMapping && collectionNames && collectionNames.includes(viewItem.key)

  const dataModifications_c = { ...dataModifications_app, ..._dataModifications }

  const actionItems = {
    appUserDirect: {},
    dataManagement: {},
    dataModification: {},
  }

  // IMPORTANT: PageAction - add to the dataManagement (dataManagementTypes)
  // checkListType_modification(actionItems.dataManagement, dataManagementTypes, _dataManagement,   viewItemStatus, appUserAccess)

  // IMPORTANT: PageAction - add to the dataModification (dataManagementTypes) 
  checkListType_modification(actionItems.dataModification, dataModificationTypes, dataModifications_c, viewItemStatus, appUserAccess, viewItem, appUserDataPermission_allowed)

  // adds a menuItem if `allowMapping` and 'mapFieldName` exist  
  // if (_allowMapping && locationProps && accessLevel_appUser >= gEnums.accessLevels.admin.value) { actionItems.dataManagement[dataManagementTypes.imageMapping] = true }

  // IMPORTANT: PageAction - add to the appUserDirect (pageItemActions)
  checkListType_modification(actionItems.appUserDirect, pageItemActionTypes, pageItemActions, viewItemStatus, appUserAccess)
  checkListType_global(actionItems.appUserDirect, aps_global, viewItemStatus, appUserAccess, view, viewKey)

  // IMPORTANT: PageAction - add to the appUserDirect (appUserPagePermissions)
  // questionsAndAnswers, qrCode, ticketing, videoConferencing
  checkListType_modification(actionItems.appUserDirect, appUserPermissionTypes, appUserPagePermissions, viewItemStatus, appUserAccess)

  if (allowNotifications && allowDirectToNotificationTargets && notificationTargets && viewItem && notificationTargets.includes(viewItem.key)) {
    let allowNotif = false;
    switch (viewItem.key) {
      case [appUserCollection]:
        allowNotif = notificationTargets.includes(viewItem.key)
        break;
      default:
        allowNotif = notificationTargets.includes(viewItem.key)
        break;
    }
    if (allowNotif) {
      if (notificationsAuthLevel) {
        allowNotif = accessLevel_appUser >= notificationsAuthLevel
      } else {
        allowNotif = appUserAccess.isAdminOrSuper
      }
      if (allowNotif) {
        actionItems.appUserDirect[itemActionTypes.notifications] = { allow: true }
      }
    }
  }

  let _isAppUserCollection;

  _isAppUserCollection = viewItem && ((viewItem.key === appUserCollection) || (appUserCollections && appUserCollections.includes(viewItem.key)))

  // adds a menuItem if `signInAs` and the other criterea are met
  if (isViewItemSolo && allowCloning && _isAppUserCollection && appUserAccess && appUserAccess.isAdminOrSuper) {
    actionItems.appUserDirect[itemActionTypes.signInAs] = { allow: true }
  }

  addMenuItem_signInDirect(actionItems.appUserDirect, allowSignInDirect, directSignInType, appUserCollection, view, viewKey, viewItem, loggedIn)

  if (allowItemDataModification) {
    if (accessLevel_appUser >= accessLevel_gdo) {
      if (!isViewItemSolo && viewItem.key === view) { actionItems.appUserDirect[itemActionTypes.add] = { allow: true } }
      if (isViewItemSolo && viewItem.key === view) { actionItems.appUserDirect[itemActionTypes.edit] = { allow: true } }
      if (isViewItemSolo && _isAppUserCollection) { actionItems.appUserDirect[itemActionTypes.appUserInfo] = { allow: true } }
    }
  }

  if (showSearch && !searchOnPage && !isViewItemSolo) { actionItems.appUserDirect[itemActionTypes.search] = { allow: true } }
  if (showGeoMapList) { actionItems.appUserDirect[itemActionTypes.geoMapList] = { allow: true } }
  if (showItemCount) { actionItems.appUserDirect[itemActionTypes.showItemCount] = { allow: true } }

  if (allowRating && ratingCollections && viewKey && ratingCollections.includes(view)) {
    actionItems.appUserDirect[appUserPermissionTypes.rating] = { allow: true }
  }

  if (allowRequestAccess && requestAccessCollections && viewKey && requestAccessCollections.includes(view)) {
    actionItems.appUserDirect[appUserPermissionTypes.requestAccess] = { allow: true }
  }

  addMenuItem_accessRequests(actionItems.appUserDirect, accessRequests, viewItemStatus, itemData, viewItem)

  return actionItems

}

/**
 * adds a menuItem if `directSignInType` exists
 * @param {array} dms 
 * @param {boolean} allowSignInDirect 
 * @param {string} directSignInType 
 * @param {object} appUserCollection 
 * @param {string} view 
 * @param {string} viewKey 
 * @param {object} viewItem 
 * @param {boolean} loggedIn 
 */
const addMenuItem_signInDirect = (dms, allowSignInDirect, directSignInType, appUserCollection, view, viewKey, viewItem, loggedIn) => {
  if (allowSignInDirect && view !== 'landing' && viewKey && viewItem && viewItem.key && (viewItem.key.toLowerCase().indexOf(appUserCollection.toLowerCase()) >= 0)) {
    if (!loggedIn) {
      switch (directSignInType) {
        case gEnums.directSignInTypes.email:
          dms[itemActionTypes.signInDirectEmail] = { allow: true }
          break;
        case gEnums.directSignInTypes.phoneNumber:
          dms[itemActionTypes.signInDirectPhone] = { allow: true }
          break;
        default:
        // nothing
      }
    }
  }
}

/**
 * adds a menuItem if `accessRequest` exists
 * @param {array} dms 
 * @param {object} accessRequests 
 * @param {array} viewItemStatus
 * @param {boolean} itemData 
 * @param {object} viewItem 
 */
const addMenuItem_accessRequests = (dms, accessRequests, viewItemStatus, itemData, viewItem) => {
  const { isViewItemSolo } = viewItemStatus ?? {}
  if (accessRequests && isViewItemSolo && itemData) {
    Object.keys(accessRequests).forEach(key => {
      const accessRequest = accessRequests[key]
      delete accessRequest.id
      Object.keys(accessRequest).forEach(keyy => {
        const ar = accessRequest[keyy]
        if (ar.pathName) {
          const alp = currentHelpers.getLastPathView(ar.pathName)
          const { path, key } = alp ?? {}
          if (viewItem.key === path && itemData.id === key) {
            dms[itemActionTypes.viewAccessRequests] = { allow: true }
          }
        }
      })
    })
  }
}

/**
 * 
 * @param {object} actions 
 * @param {string} modType 
 * @param {boolean} viewItemStatus 
 * @param {object} appUserAccess 
 * @returns the action object if it is allowed
 */
const getPermissions = (actions, modType, viewItemStatus, appUserAccess) => {

  const action = actions ? actions[modType] : {}
  const { allowAction } = action ?? {}
  let allow = allowAction

  const { isViewItemSolo, isViewItemList } = viewItemStatus ?? {}

  if (!action) {
    allow = false
  } else {
    if (isViewItemSolo) {
      switch (modType) {
        case itemActionTypes.bulkImageUpload:
        case itemActionTypes.filters:
        case itemActionTypes.modifyList:
        case itemActionTypes.sorting:
        case itemActionTypes.status:
          allow = false
          break;
        case itemActionTypes.edit:
          allow = true
          break;
        default:
        // nothing
      }
    } else {
      // this is a list
      switch (modType) {
        case itemActionTypes.add:
          if (isViewItemList && isViewItemSolo) {
            allow = false
          }
          break;
        case itemActionTypes.delete:
          allow = false
          break;
        case itemActionTypes.clipboard:
        case itemActionTypes.backupItemData:
        case itemActionTypes.imageUpload:
        case itemActionTypes.rating:
        case itemActionTypes.requestAccess:
          allow = false
          break;

        case itemActionTypes.itemLinking:
        case itemActionTypes.edit:
          allow = true
          break;

        case itemActionTypes.status:
          allow = isViewItemList
          break;
        default:
        // nothing
      }
    }
  }

  switch (modType) {
    case itemActionTypes.edit:
      if (appUserAccess >= gEnums.accessLevels.admin.value) {
        return action
      }
      break;
    default:
    // nothing
  }

  return allow ? action : {}
}

/**
 * 
 * @param {string} modType 
 * @param {object} appUserAccess 
 * @param {object} permissionProps 
 * @returns true if the action is allowed by the appUser
 */
const validateDataModification_appUser = (modType, appUserAccess, permissionProps, appUserDataPermission_allowed) => {

  const { allowAction, accessLevel: accessLevel_item, actionCaption, subActionTypes } = permissionProps ?? {}
  const { accessLevel: accessLevel_appUser } = appUserAccess ?? {}

  const allows = {
    allow: false,
    actionCaption
  }

  // if the appUser is a admin, allow the action
  if (accessLevel_appUser && (accessLevel_appUser >= gEnums.accessLevels.admin.value)) {
    allows.allow = allowAction
  } else {
    if (accessLevel_item) {
      switch (accessLevel_item) {
        // allow if accessLevel is public
        case gEnums.accessLevels.public.value:
          allows.allow = true
          break;
        default:
          if (allowAction && accessLevel_appUser >= accessLevel_item) {
            allows.allow = true
          }
      }
    } else {
      switch (modType) {
        case itemActionTypes.emailAuth:
          if (allowAction && !appUserAccess.userData) {
            allows.allow = true
            // return { allow: true, actionCaption }
          }
          break;
        default:
        // nothing
      }
    }
  }

  if (subActionTypes) {
    allows.subActionTypes = subActionTypes
  }

  // if (appUserDataPermission_allowed) {
  //   switch (modType) {
  //     case itemActionTypes.add:
  //     case itemActionTypes.edit:
  //       allows.allow = true
  //       break;
  //     default:
  //     // nothing
  //   }
  // }

  return { ...allows }
}

/**
 * sets where the `listType` within the `listTypes` is `allowed` by checking the 
 * permissions using `validateDataModification` and `getPermissions`
 * @param {enum} listTypes 
 * @param {string} listType 
 * @param {object} actions 
 * @param {boolean} viewItemStatus 
 * @param {object} appUserAccess 
 */
const checkListType_modification = (listTypes, listType, actions, viewItemStatus, appUserAccess, viewItem, appUserDataPermission_allowed) => {
  Object.keys(listType).forEach(type => {
    const { allow, actionCaption, subActionTypes } = validateDataModification_appUser(type, appUserAccess, getPermissions(actions, type, viewItemStatus, appUserAccess), appUserDataPermission_allowed)
    if (allow) {
      listTypes[type] = { allow: true, actionCaption, subActionTypes }
    }
  })
}

const checkListType_global = (listTypes, aps_global, viewItemStatus, appUserAccess, view, viewKey) => {

  const { accessLevel } = appUserAccess ?? {}

  const {
    appQuestionsAndAnswers,
    appRating,
    appVideoConferencing,
  } = aps_global ?? {}

  const { allowQuestionsAndAnswers, qAndAAuthLevel, questionsAndAnswersCollections } = appQuestionsAndAnswers ?? {}
  const { allowRating, ratingAuthLevel, ratingCollections } = appRating ?? {}
  const { allowVideoConferencing, videoConferencingAuthLevel, videoConferencingCollections } = appVideoConferencing ?? {}

  if (allowQuestionsAndAnswers &&
    accessLevel >= qAndAAuthLevel &&
    questionsAndAnswersCollections &&
    questionsAndAnswersCollections.includes(view) &&
    viewKey) {
    listTypes['qAndAManager'] = { allow: true }
  }

  if (allowRating &&
    accessLevel >= ratingAuthLevel &&
    ratingCollections &&
    ratingCollections.includes(view) &&
    viewKey) {
    listTypes['ratingManager'] = { allow: true }
  }

  if (allowVideoConferencing &&
    accessLevel >= videoConferencingAuthLevel &&
    videoConferencingCollections &&
    videoConferencingCollections.includes(view) &&
    viewKey
  ) {
    listTypes['videoConferencing'] = { allow: true }
  }

}

const getAppUserAccessPermissions = (appUserDataModifications, appUserEvent, appUserViewTypeProp, _viewItemKey) => {

  const appUserDataPermissions = []
  let appUserDataPermission_allowed;

  if (appUserDataModifications && appUserEvent && appUserViewTypeProp && appUserEvent[appUserViewTypeProp]) {
    const _appUserEventTypes = appUserEvent[appUserViewTypeProp]
    if (_appUserEventTypes) {
      _.forEach(_appUserEventTypes, (auEventType) => {
        if (appUserDataModifications[auEventType]) {
          const permissions = appUserDataModifications[auEventType]
          _.forEach(permissions, (permission) => {
            appUserDataPermissions.push(permission)
          })
        }
      })
    }
  }

  if (appUserDataPermissions && appUserDataPermissions.length > 0) {
    if (appUserDataPermissions.includes(_viewItemKey)) {
      appUserDataPermission_allowed = true
    }
  }

  return { appUserDataPermissions, appUserDataPermission_allowed }
}