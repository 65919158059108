import _ from 'lodash';
import { manifestSizes } from '../components/imaging/createManifestIcons';
import { gEnums, listTypes } from '../enums/globalEnums';
import { listList } from '../lists/lists';

export const eventOptionsList = (props) => {

  const { listType, viewProps } = props ?? {}

  const ol = {}

  ol[listTypes.manifestSizes] = listList(manifestSizes)
  ol[listTypes.settingGroupTypes] = listList(gEnums.settingsMenuGroupTypes, true)
  ol[listTypes.roundTypes] = listList(gEnums.roundTypes, true)

  if (viewProps) {
    // loop the viewProps
    Object.keys(viewProps).forEach(key => {
      const viewProp = viewProps[key]
      const { data: data_prop } = viewProp ?? {}
      // look for the listType
      const { listType, labelProps } = data_prop ?? {}
      // if the listType exists in gEnums, add it to the options
      if (listType && gEnums[listType]) {
        ol[listType] = createItemList(gEnums[listType])
      }

      // add the labelProps lists
      if (labelProps) {
        labelProps.forEach(lpr => {
          const { data: label_data } = lpr
          const { listType: label_listType } = label_data
          if (label_listType && gEnums[label_listType]) {
            ol[label_listType] = createItemList(gEnums[label_listType])
          }
        })
      }
    })
    return ol
  } else {
    Object.keys(gEnums).forEach(gkey => {
      ol[gkey] = createItemList(gEnums[gkey])
    })
  }

  if (listType) {
    return ol[listType]
  } else {
    return ol
  }
}


const createItemList = (list) => {

  const items = []

  if (_.isArray(list)) {
    list.forEach(key => {
      items.push({
        text: _.startCase(key),
        value: key,
        key: key,
      })
    })
  } else {
    Object.keys(list).forEach(key => {
      if (list[key].caption) {
        items.push({
          text: list[key].caption,
          value: list[key].value,
          key: list[key].key,
        })
      } else {
        items.push({
          text: _.startCase(key),
          value: key,
          key: key,
        })
      }
    })
  }
  return items
}

