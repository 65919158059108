import React, { createContext, useContext, useEffect, useState } from 'react';
import { createRefPath } from '../../firestoreData/appData/appRefPaths';
import { DatabaseContext } from './DatabaseContext';

export const GlobalContext = createContext();

/**
 * 
 * @param {object} props 
 * @returns GlobalContext global_state: { settings_globalPageItems }
 */
const GlobalProvider = (props) => {

  // databaseContext
  const databaseContext = useContext(DatabaseContext)
  const { database_fns } = databaseContext ?? {}

  const [settings_globalPageItems, setSettingsGlobal] = useState()

  // eslint-disable-next-line
  const [error, setError] = useState()

  useEffect(
    () => {
      if (!settings_globalPageItems) {
        database_fns.get_data({ refPath: createRefPath(['settings_global_pageItems', 'all']) }).then(settings_gpi => {
          if (settings_gpi.all && settings_gpi.all.viewItems) {
            setSettingsGlobal(settings_gpi.all.viewItems)
          } else {
            setSettingsGlobal({})
          }
        })
      }
    },
    // eslint-disable-next-line
    [] // does not re-rednder unless uivi has changed
  )

  return (
    <React.Fragment>
      {(settings_globalPageItems) &&
        <GlobalContext.Provider value={{ global_state: { settings_globalPageItems } }}>
          {props.children}
        </GlobalContext.Provider>
      }
      {(error) &&
        <GlobalContext.Provider value={{ global_state: { settings_globalPageItems: {} } }}>
          {props.children}
        </GlobalContext.Provider>
      }
    </React.Fragment>
  )
}

export default GlobalProvider