import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { ImageContext } from '../../cnr/contexts/ImageContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { getLastSplit } from '../../common/filtering';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import { appIconTypes } from '../../enums/appIconTypes';
import { gEnums } from '../../enums/globalEnums';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import UiConfirmation from '../../pageItem/modification/UiConfirmation';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import NoData from '../alerts/NoData';
import Uploader from '../uploading/Uploader';

const FullImage = (props) => {

  const { fullImageProps, src, handleClose, useFpw, viewOnly } = props
  const { urls, url, documentKey, customMetadata, path } = fullImageProps ?? {}
  const { full: url_full } = urls ?? {}
  const { uploadedBy } = customMetadata ?? {}
  const _fileName = path ? getLastSplit(path, '/') : null

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { appUser_state, storage_state } = states ?? {}
  const { updating, confirmation } = storage_state ?? {}
  const { storage_handlers } = handlers ?? {}

  // imageContext
  const imageContext = useContext(ImageContext)
  const { image_state } = imageContext ?? {}
  const { itemData } = image_state ?? {}
  const { email: email_image } = itemData ?? {}

  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { accessLevel, email } = appUserAccess ?? {}

  let allowMod = appUserAccess && (accessLevel > gEnums.accessLevels.admin.value)
  if (email_image && (email_image === email)) { allowMod = true }
  if (uploadedBy && (uploadedBy === email)) { allowMod = true }
  if (viewOnly) { allowMod = false }

  // local state
  const [currentImageSrc, setCurrentImageSrc] = useState()
  const [showDelete, setShowDelete] = useState()
  const [showUpload, setShowUpload] = useState()
  const [isLoaded, setIsLoaded] = useState(false);

  const _uploadCaption = currentImageSrc ? 'Edit Image' : 'Add Image'

  const handleImageLoad = () => setIsLoaded(true);

  useEffect(() => {
    let _src = url_full ? url_full : src
    if (url) { _src = url }
    setCurrentImageSrc(_src)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowDelete = () => setShowDelete(!showDelete)
  const handleShowUpload = () => setShowUpload(!showUpload)

  const handleDeleteStorageItem = () => {
    setShowDelete()
    storage_handlers.handleStartUpdate()
    storage_handlers.handleDelete_storageItem(fullImageProps)
  }

  /**
   * This will only appear if allowMods i true
   * @returns saveButtons
   */
  const footer = () => <UiSaveButtons
    save={{ oc: handleShowUpload, caption: _uploadCaption, icon: appIconTypes.arrowRight, color: 'blue' }}
    delete={currentImageSrc && { oc: handleShowDelete, color: iconColorTypes.confirmation, icon: appIconTypes.delete, caption: 'Delete Image' }}
  />

  const content = () => <Wrapper
    content={currentImageSrc ? <Image className={'img-nrm cntr'} style={{ display: isLoaded ? 'block' : 'none' }} onLoad={handleImageLoad} src={currentImageSrc} size='medium' /> : <NoData fullCaption={'No Current Image'} />}
    footer={allowMod && footer()}
    wrapperType={wrapperTypes.paddedHeader}
    updating={updating}
    confirmation={confirmation}
    css={{ container: 'full-image' }}
  />

  const fpw = () => <FullPageWrapper
    content={content()}
    handleCancel={handleClose}
    topperCaption={documentKey ? _.startCase(documentKey) : 'Image'}
    topperCaption2={_fileName}
  />

  if (showDelete) {
    return <UiConfirmation
      caption={'Delete Item?'}
      text={'Are you sure you want to delete this item. This action cannot be undone.'}
      updateCaption={'Delete'}
      updateColor={'red'}
      updateIcon={'delete'}
      handleUpdate={handleDeleteStorageItem}
      handleCancel={handleShowDelete}
    />
  } else if (showUpload) {
    return <Uploader
      handleCancel={handleShowUpload}
      itemData={{}}
      origin={'FullImage'}
      storageLocationType={gEnums.storageLocationTypes.pageDirect}
      useFull={true}
    />
  } else {
    return handleClose || useFpw ? fpw() : content()
  }

}

export default FullImage