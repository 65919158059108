import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { FirestoreContext } from '../../cnr/contexts/FirestoreContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { getUfProps } from '../../cnr/reducers/FirestoreReducer';
import { getGroupListData } from '../../common/sorting';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import { gEnums } from '../../enums/globalEnums';
import GenericButton from '../../genericControls/GenericButton';
import GenericIcon from '../../genericControls/GenericIcon';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import { labels } from './labels';
import LabelSort from './LabelSort';
import { useNavigate } from 'react-router-dom';
import { ComponentContext } from '../../cnr/contexts/ComponentContext';

export const ArrayLabels = (props) => {

  const navigate = useNavigate()

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { appUser_state, page_state, paps_state, eventInfo_state } = states
  const { page_fns } = fns
  const { rootPaths } = paps_state ?? {}

  // componentContexts
  const componentContext = useContext(ComponentContext)
  const { component_state } = componentContext ?? {}
  const { componentContexts } = component_state ?? {}

  const { arrayProps } = props

  // componentContexts
  const { uiItemContext } = componentContexts ?? {}

  const { prop_value: initialArray, propItem, viewKey } = arrayProps
  const { viewItem, itemData } = arrayProps
  const { dataModification: prop_dataModification, display: display_propItem, drilled } = propItem ?? {}
  const { caption, ignoreStatic } = display_propItem ?? {}
  const { allowSorting, allowAppUserSorting } = prop_dataModification ?? {}
  let { sortGroupName, sortGroupMin, sortGroupMax } = prop_dataModification ?? {}

  if (!sortGroupName) { sortGroupName = 'group' }
  if (!sortGroupMin) { sortGroupMin = 1 }
  if (!sortGroupMax) { sortGroupMax = 4 }

  // authContext 
  const { appUser: authAppUser } = appUser_state ?? {}
  const { appUserAccess } = authAppUser ?? {}
  const { accessLevel } = appUserAccess ?? {}

  let allowSort = (allowSorting || allowAppUserSorting) ? true : false

  // papsContext   
  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings } = pageSettings ?? {}
  const { appUserCollection } = aps_appUserSettings ?? {}

  //eventInfoContext  
  const { staticViews, staticViews_gs } = eventInfo_state ?? {}
  const _staticViews = staticViews_gs ? staticViews_gs : staticViews

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext);
  const { firestore_handlers, firestore_state } = firestoreContext ?? {}
  const { handleKill_confirmation } = firestore_handlers ?? {}
  const { confirmation } = firestore_state ?? {}

  let initCount = initialArray ? Object.keys(initialArray).length : 1
  let existingGroups = initialArray ? _.groupBy(initialArray, 'groupNumber') : null

  const initGroupCount = existingGroups ? Object.keys(existingGroups).length : 1
  const itemsPerGroup = initCount && initGroupCount ? initCount / initGroupCount : 1

  if (appUserCollection && itemData[appUserCollection] && authAppUser && authAppUser.userData) {
    const authFullValue = authAppUser[appUserCollection]
    const { isCaptain } = authFullValue
    allowSort = isCaptain || accessLevel >= gEnums.accessLevels.admin.value
  }

  // local state
  const [sortData, setSortData] = useState()
  const [updatedGroups, setUpdatedGroups] = useState()
  const [showSorted] = useState(itemsPerGroup && itemsPerGroup > 0 ? true : false)
  const [sortOn, setSortOn] = useState()
  const [groupCount, setGroupCount] = useState(itemsPerGroup)

  const handleDrill = (e, item) => {
    e.stopPropagation()
    const { key: propItemKey, propSection } = propItem ?? {}
    const { propSections } = viewItem ?? {}
    const itemDataItem = itemData ? itemData[propItemKey] : {}
    const itemDataItemItem = itemDataItem ? itemDataItem[item.key] : {}
    const drillSection = propSections[propSection]
    const nextSection = _.find(propSections, { position: drillSection.position + 1 })
    const { item_handlers } = uiItemContext ?? {}
    item_handlers.handleDrill({ drillSection: nextSection.key, drillItem: itemDataItemItem })
  }

  const handleSortOn = () => setSortOn(true)
  const handleSortOff = () => setSortOn()

  const reset = () => {
    handleKill_confirmation()
    setSortOn(false)
    setUpdatedGroups()
  }

  useEffect(() => {
    if (confirmation) {
      const timer = setTimeout(() => reset(), confirmation.success === true ? 2000 : 3000);
      return () => { clearTimeout(timer) };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation]);

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />
  const gb = (key, caption, icon, onClick, clickOptions, opts, noE) => <GenericButton gbProps={{ key, caption, icon, onClick, clickOptions, opts, noE }} />

  const confirmationIcon = () => {
    if (confirmation.success) {
      return gi('ib-ok', 'check circle outline', null, null, { color: 'green', size: 'large' })
    } else {
      return gb('ib-ok', 'Error', 'ban', null, null, { title: 'Save', color: 'red', size: 'large' })
    }
  }

  const saveArray = () => {
    const { key: propKey } = propItem
    const { listItems } = updatedGroups
    const updateData = { ...itemData }
    updateData[propKey] = listItems
    const ufProps = getUfProps(viewItem.key, itemData.id, updateData, gEnums.dataUpdateTypes.updateDoc)
    firestore_handlers.handleUpdate_firestoreDataDirect(paps_state, ufProps)
  }

  // called from LabelSort
  const updateArray = (dndGroups) => {
    const dataItems = {}
    if (dndGroups) {
      let pos = 0
      Object.keys(dndGroups).forEach(key => {
        const groupDataItems = dndGroups[key].dataItems
        if (groupDataItems) {
          const gdis = _.sortBy(groupDataItems, 'position')
          gdis.forEach(gdi => {
            dataItems[gdi.key] = {
              key: gdi.key,
              name: gdi.lastName ? gdi.firstName + ' ' + gdi.lastName : gdi.name,
              position: pos,
            }
            pos++
          })
        }
      })
    }
    const sortedDataItems = _.sortBy(dataItems, 'position')
    const new_sort_data = getGroupListData(sortedDataItems, staticList, groupCount, sortGroupName)
    setSortData(new_sort_data)
    setUpdatedGroups(new_sort_data)
  }

  const { listItems } = sortData ?? {}

  const staticList = _staticViews && propItem && propItem.key && _staticViews[propItem.key] ? _staticViews[propItem.key] : null

  /** triggers on start and when the count has changed */
  const getGroupedSortData = () => {
    let init_array = { ...initialArray }
    let sort_data;
    const startLength = groupCount ? groupCount : 1
    if (_.isArray(init_array)) {
      const pvv = []
      init_array.forEach(item => { pvv[item] = { key: item } })
      sort_data = getGroupListData(pvv, staticList, startLength, sortGroupName)
    } else {
      sort_data = getGroupListData(init_array, staticList, startLength, sortGroupName)
    }
    setSortData(sort_data)
  }

  useEffect(() => {
    if (sortGroupName && allowSort) {
      // get the sort data
      getGroupedSortData()
    } else {
      const lis = {}
      if (initialArray && _.isArray(initialArray)) {
        initialArray.forEach(iai => {
          lis[iai] = { key: iai, name: iai }
        })
      } else {
        Object.keys(initialArray).forEach(ak => {
          lis[ak] = { key: ak, name: ak }
        })
      }
      setSortData({ listItems: lis })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [groupCount]);

  const handleCountClick = (index) => setGroupCount(index)

  const numberOptions = () => {
    const opts = []
    const min = sortGroupMin ? sortGroupMin : 1
    const max = sortGroupMax ? sortGroupMax : 8
    let c = 0;
    for (var i = min; i <= max; ++i) {
      c++
      // eslint-disable-next-line 
      opts.push(<Dropdown.Item onClick={() => handleCountClick(c)}>{c}</Dropdown.Item>)
    }
    return opts
  }

  const numberDD = () => <Dropdown
    text={groupCount.toString()}
    icon='users'
    floating
    labeled
    button
    className='icon'
  >
    <Dropdown.Menu>
      {numberOptions()}
    </Dropdown.Menu>
  </Dropdown>

  const sortHeader = () => {
    switch (sortOn) {
      case true:
        return <React.Fragment>
          {numberDD()}
          {confirmation && confirmationIcon()}
        </React.Fragment>
      default:
      // nothing
    }
  }

  const labelContent = () => {
    if (sortData && allowSort) {
      return <LabelSort sortData={sortData} viewKey={viewKey} display_propItem={display_propItem} updateArray={updateArray} allowSort={sortOn} />
    } else {
      const lbProps = { page_fns, rootPaths, navigate, items: listItems, viewKey, sortGroupName, drilled: drilled ? drilled : handleDrill, ignoreStatic, staticList, itemKey: propItem.key }
      return labels(lbProps)
    }
  }

  const sortOnButton = (allow) => allow && gb('btn-sort', 'Sort', 'sort', handleSortOn, null, { color: 'grey' })

  const labelHeaderContent = (allow) => {
    return <div className={'label-container arr srt'}>
      {allowSort && <div className={'label-header'}>{sortHeader()}{sortOnButton(allow)}</div>}
      <div className={'label-content'}>{labelContent(allow)}</div>
    </div>
  }

  const container = () => <Wrapper
    header={numberDD()}
    content={labelContent(true)}
    footer={<UiSaveButtons save={{ oc: saveArray, disabled: !updatedGroups }} />}
    wrapperType={wrapperTypes.padded}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={container()}
    handleCancel={handleSortOff}
    topperCaption={caption ? 'Sort ' + caption : 'Sort Items'}
  />

  const content = () => {
    if (allowSort) {
      if (sortOn) {
        return fullPageWrapper() // modal()
      } else {
        return labelHeaderContent(true)
      }
    } else {
      if (showSorted) {
        return labelHeaderContent(false)
      } else {
        return <div className={'label-container arr'}>{labelContent()}</div>
      }
    }
  }

  return content()

}