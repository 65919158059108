import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { postFilterData } from '../../cnr/contexts/contextHelpers/postFilterData'
import { DataContext } from '../../cnr/contexts/DataContext'
import { getHeaders } from '../headers/getHeaders'
import TableViewer from './TableViewer'

/**
 * This is an independent dataTable 
 * @param {object} props (fixedData, viewItem, handleCount, tableRef, allowEdit, handleRowSelect, viewItemHeaders)
 * @returns 
 */
const DataListTable = (props) => {

  const { fixedData, viewItem, tableRef, allowEdit, handleRowSelect } = props

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state } = dataContext ?? {}
  const { viewListData, fsr } = data_state ?? {}

  const _viewListData = fixedData ? fixedData : viewListData

  const [filteredData, setFilteredData] = useState();
  const [headers, setHeaders] = useState();

  useEffect(() => {
    const p = { viewItem, viewListData: _viewListData, ignoreAlpha: true, fsr }
    const fd = postFilterData(p)
    const data = _.isArray(fd) ? fd : _.sortBy(fd)
    const { headers } = getHeaders(data)
    setHeaders(headers)
    setFilteredData(fd)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewItem, _viewListData]);

  return filteredData ? <React.Fragment>
    {/* <Segment basic><Label color={'blue'}>{Object.keys(filteredData).length}</Label></Segment> */}
    {headers && <TableViewer
      handleRowSelect={allowEdit && handleRowSelect}
      jsonData={filteredData ? filteredData : _viewListData}
      tableRef={tableRef}
      viewItem={viewItem}
      headers={headers}
    />}
  </React.Fragment> : <div>---</div>
}

export default DataListTable