import React, { useContext } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { FrameworkContext } from '../../../cnr/contexts/FrameworkContent';
import { uniqueKey } from '../../../common/keys';

const MenuSettingsClose = (props) => {

  const { useDarkMode } = props

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers } = frameworkContext ?? {}
  const { handleShow_appSidebar } = framework_handlers ?? {}

  return (
    <Menu borderless fluid inverted={useDarkMode} widths={1}>
      <Menu.Item key={uniqueKey('mss', 'cl')} onClick={() => handleShow_appSidebar()} >
        <Icon color={'blue'} name={'delete'} />Close</Menu.Item>
    </Menu>
  )
}

export default MenuSettingsClose