import { filterHelpers } from '../common/filtering';
import { gEnums } from '../enums/globalEnums';

export const getAppUserUserProfileData = (appUser) => {
  const { appUser_cloned } = appUser ?? {}
  const _appUser = appUser_cloned ? appUser_cloned : appUser
  const { profileData } = _appUser ?? {}
  return profileData
}

export const getAuthColor = (accessLevel) => {
  const x = filterHelpers.find(gEnums.accessLevels, 'key', accessLevel)
  if (x) { return x.color }
  return 'black'
}

export const getAuthIcon = (accessLevel, adminIf) => {
  const x = filterHelpers.find(gEnums.accessLevels, 'key', accessLevel)
  if (x) {
    return {
      color: x.color,
      name: x.icon,
    }
  } else {
    return adminIf ?
      getAuthIcon(gEnums.accessLevels.admin.value)
      : {
        color: 'black',
        name: 'user',
      }
  }
} 