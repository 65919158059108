import { _animateTypes } from "./AnimateComponent";

/**
 * 
 * @param {string} animateType 
 * @param {object} transitionItems 
 * @param {object} sty 
 * @returns 
 */
export const animationVariants = (animateType, transitionItems, sty) => {

  const _transitionItems = transitionItems ? transitionItems : getDefaultTransitionItems()

  // set the initial values
  const values = {
    scale: { end: 1, start: 1, duration: 0.1 },
    opacity: { end: 1, start: 1, duration: 0.1 },
  }

  // set the initial useValues
  const useValues = {
    opacity: false,
    scale: false,
    exit: false,
  }

  const animationItem = ammendAnimationItem(animateType, _transitionItems, useValues)
  const { useTransitions } = animationItem ? animationItem : {}

  ammendValue(animationItem, useValues, values)

  const variants = {
    initial: {},
    animate: {
      transition: {}
    },
  }

  if (useValues.scale) {
    variants.initial.scale = values.scale.start
    variants.animate.scale = values.scale.end
    variants.animate.transition.scale = values.scale.duration
  }

  if (useValues.opacity) {
    variants.initial.opacity = values.opacity.start
    variants.animate.opacity = values.opacity.end
  }

  if (useValues.scale || useValues.opacity) {
    variants.animate.transition = {}
    if (useValues.scale) {
      variants.animate.transition.scale = { duration: values.scale.duration }
    }
    if (useValues.opacity) {
      variants.animate.transition = { duration: values.opacity.duration }
      // variants.animate.transition.opacity = { duration: values.opacity.duration }
    }
  }

  return { variants, useTransitions }

}

/**
 * 
 * @param {string} animateType 
 * @param {object} transitionItems 
 * @param {boolean} useValues 
 * @returns 
 */
const ammendAnimationItem = (animateType, transitionItems, useValues) => {

  let animationItem;

  switch (animateType) {
    case _animateTypes.map:
      animationItem = transitionItems.mapp
      break;
    default:
      animationItem = transitionItems[animateType]
  }

  return animationItem

}

const ammendValue = (item, useValues, values) => {

  const {
    useOpacity,
    opacityValue,
    opacityDuration,
    useScale,
    scaleValue,
    scaleDuration,
  } = item ?? {}

  useValues.opacity = useOpacity
  useValues.scale = useScale

  if (useValues.opacity) {
    values.opacity.start = opacityValue ? Math.abs((opacityValue * .1).toFixed(2)) : .9
    values.opacity.duration = opacityDuration ? opacityDuration : .1
  }

  if (useValues.scale) {
    values.scale.start = scaleValue ? Math.abs((scaleValue * .1).toFixed(2)) : .98
    values.scale.duration = scaleDuration ? scaleDuration : .1
  }
}

/**
 * 
 * @returns the default transition items
 */
const getDefaultTransitionItems = () => {
  return {
    page: {
      useTransitions: true,
      useOpacity: true,
      opacityDuration: .2,
      opacityValue: 8.0,

      useScale: true,
      scaleValue: 9.6,
    },

    component: {
      opacityDuration: .4,
      opacityValue: 8,
      scaleValue: 9.8,
      useOpacity: true,
      useTransitions: true,
    },
    data: {
      opacityDuration: .2,
      opacityValue: 8,
      scaleValue: 9.8,
      useOpacity: true,
      useScale: false,
      useTransitions: false,
    },
    fullPageWrapper: {
      opacityDuration: .2,
      opacityValue: 9,
      useOpacity: true,
      useTransitions: true,
    },
    image: {
      opacityDuration: .6,
      opacityValue: 8,
      scaleValue: 9.8,
      useOpacity: true,
      useScale: true,
      useTransitions: true,
    },
    landing: {
      opacityDuration: .2,
      opacityValue: 8,
      scaleValue: 9.8,
      useOpacity: true,
      useScale: false,
      useTransitions: false,
    },
    map: {
      opacityDuration: .6,
      opacityValue: 8,
      scaleValue: 9.8,
      useOpacity: true,
      useScale: true,
      useTransitions: true,
    },

    pageItem: {
      opacityDuration: .5,
      opacityValue: 8.0,
      scaleValue: 9.6,
      useOpacity: true,
      useScale: true,
      useTransitions: true,
    },
    pageItemElement: {
      opacityDuration: .2,
      opacityValue: 8,
      scaleValue: 9.8,
      useOpacity: true,
      useScale: false,
      useTransitions: false,
    },
    topBanner: {
      opacityDuration: .8,
      opacityValue: 8,
      scaleValue: 9.1,
      useOpacity: true,
      useTransitions: true,
    }
  }
}