import React, { useContext, useEffect, useState } from 'react';
import InputNumber from 'rc-input-number';
import { FormItemContext } from '../FormItem';

const NumberPicker = (props) => {

  // formItemContext
  const formItemContext = useContext(FormItemContext)
  const { formItem_state } = formItemContext ?? {}
  const { fip, itemProp } = formItem_state ?? {}
  const { propname } = fip ?? {}

  let { value, onChange } = fip
  let { data } = itemProp
  const { start, end, step: data_step, initialValue: data_initial } = data ?? {}

  const [min, setMin] = useState()
  const [max, setMax] = useState()
  const [step, setStep] = useState()
  const [numberValue, setNumberValue] = useState()

  useEffect(() => {
    setMin(start ? start : 0)
    setMax(end ? end : 2000)
    setStep(data_step ? data_step : 1)
    setNumberValue(value ? value : data_initial)
    // eslint-disable-next-line
  }, []);

  const handleChange = (value) => {
    if (value || value === 0) { setNumberValue(value) }
    const data = { propname, value: value ? value : 0 }
    onChange(null, data)
  };

  if (step) {
    return <React.Fragment>
      <div className='field'>
        <label >{fip.label}</label>
      </div>
      <InputNumber
        required={true}
        step={step}
        max={max}
        min={min}
        value={numberValue}
        onChange={handleChange} />
    </React.Fragment>
  }

  return <div></div>

}

export default NumberPicker

export const NumberPickerDirect = (props) => {

  const { start, end, step: data_step, initialValue, onChange } = props ?? {}

  const [min, setMin] = useState()
  const [max, setMax] = useState()
  const [step, setStep] = useState()
  const [numberValue, setNumberValue] = useState()

  useEffect(() => {
    setMin(start ? start : 0)
    setMax(end ? end : 2000)
    setStep(data_step ? data_step : 1)
    setNumberValue(initialValue)
    // eslint-disable-next-line
  }, []);

  const handleChange = (value) => {
    if (value || value === 0) { setNumberValue(value) }
    onChange(value)
  };

  if (step) {
    return <React.Fragment>
      <InputNumber
        required={true}
        step={step}
        max={max}
        min={min}
        value={numberValue}
        formatter={numberValue => numberValue < 10 ? `0${numberValue}` : numberValue}
        onChange={handleChange} />
    </React.Fragment>
  }

  return <div></div>

}

export const AmPmPicker = (props) => {

  const { initialValue, onChange } = props ?? {}

  const [numberValue, setNumberValue] = useState()

  useEffect(() => {
    setNumberValue(initialValue)
    // eslint-disable-next-line
  }, []);

  const handleChange = (value) => {
    setNumberValue(value)
    onChange(value)
  };

  return <React.Fragment>
    <InputNumber
      required={true}
      step={1}
      max={2}
      min={1}
      value={numberValue}
      formatter={numberValue => (numberValue === '1') ? 'AM' : 'PM'}
      onChange={handleChange} />
  </React.Fragment>


}