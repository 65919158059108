import React, { useContext, useEffect, useState } from 'react';
import { ParentContext } from '../cnr/contexts/ParentContext';
import UiSaveButtons from '../components/buttons/UiSaveButtons';
import { renderHtml } from '../components/html/renderHtml';
import { getThemeColorDirect } from '../styles/formatting';
import FullPageWrapper from '../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import { StartContext } from '../cnr/contexts/StartContext';
import { startTypes } from '../cnr/reducers/StartReducer';
import { pageDimmerTypes } from '../cnr/reducers/PageDimmerReducer';

const WelcomePage = (props) => {

  const { handleWelcomePageSeen } = props ?? {}


  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { page_state, paps_state } = states ?? {}
  const { pathViews } = paps_state ?? {}
  const { events: eventKey } = pathViews ?? {}
  const { paps_handlers, pageDimmer_handlers } = handlers ?? {}

  // startContext
  const startContext = useContext(StartContext);
  const { start_handlers } = startContext ?? {}
  const { handleAmmend_startDimmer } = start_handlers ?? {}


  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { welcomePage } = aps_global ?? {}
  const { welcomePageText, welcomePageTitle, backgroundColor, color } = welcomePage ?? {}
  console.log('welcomePage', welcomePage)

  const [welcomePageColors, setWelcomePageColors] = useState({ backgroundColor: 'black', color: 'black' })

  const welcomePageStyle = welcomePageColors ? {
    backgroundColor: welcomePageColors.backgroundColor,
    color: welcomePageColors.color,
  } : {}

  useEffect(() => {
    const hpcs = {
      backgroundColor: getThemeColorDirect(aps_global, backgroundColor),
      color: getThemeColorDirect(aps_global, color),
    }
    setWelcomePageColors(hpcs)
    handleAmmend_startDimmer && handleAmmend_startDimmer(startTypes.finish)
    pageDimmer_handlers && pageDimmer_handlers.handleAmmend_pageDimmer(pageDimmerTypes.finish)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleSeen = () => handleWelcomePageSeen(eventKey)

  const content = () => <Wrapper
    content={renderHtml(welcomePageText)}
    footer={<UiSaveButtons save={{ oc: handleSeen, caption: 'Sign In', icon: 'arrow right' }} />}
    wrapperType={wrapperTypes.padded}
    style={welcomePageStyle}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={content()}
    topperCaption={welcomePageTitle}
  ></FullPageWrapper >

  return content()
}

export default WelcomePage