import React, { useEffect, useState } from 'react'
import { Label } from 'semantic-ui-react'
import { uniqueKey } from '../../../../global/common/keys'

const MatchResultsRt = (props) => {

  const { match } = props
  const { _results_realtime: results } = match
  const { scores, setNumber } = results ?? {}

  const [awayScore, setAwayScore] = useState(0)
  const [homeScore, setHomeScore] = useState(0)
  const [hasMounted, setHasMounted] = useState(false)
  const [flashAway, setFlashAway] = useState(false);
  const [flashHome, setFlashHome] = useState(false);

  useEffect(() => {
    if (scores) {

      const _away = scores && scores[setNumber] && scores[setNumber].away ? scores[setNumber].away : 0
      const _home = scores && scores[setNumber] && scores[setNumber].home ? scores[setNumber].home : 0

      setAwayScore(_away)
      setHomeScore(_home)

      if (hasMounted) {
        if (_away !== awayScore) { setFlashAway(true); }
        if (_home !== homeScore) { setFlashHome(true); }
        const timeout = setTimeout(() => {
          setFlashAway(false);
          setFlashHome();
        }, 1000);

        return () => clearTimeout(timeout);
      } else {
        setHasMounted(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scores]);

  const match_results_rt = () => {

    let cn = 'match-rtc'
    if (flashAway) { cn += ' flash-away' }
    if (flashHome) { cn += ' flash-home' }

    if (homeScore > awayScore) { cn += ' hl' }
    if (awayScore > homeScore) { cn += ' al' }
    if (awayScore === homeScore) { cn += ' as' }

    return <div className={cn}>
      <Label key={uniqueKey('mrrt', 1)}>{awayScore}</Label>
      <Label key={uniqueKey('mrrt', 2)}>{setNumber}</Label>
      <Label key={uniqueKey('mrrt', 3)} >{homeScore}</Label>
    </div>
  }

  return match_results_rt()
}

export default MatchResultsRt