import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { regDataHandlers, regDataInitialState, regDataReducer } from '../reducers/RegDataReducer';
import { ParentContext } from './ParentContext';

/** AppContext */
export const RegDataContext = createContext();

/** Global OtherDatas for the Event */
const RegDataProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states, settings } = parentContext ?? {}
  const { paps_state, page_state } = states ?? {}
  const { pathViews } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { dataOptions } = aps_global ?? {}

  // papsContext 
  const { clientSettings } = settings ?? {}
  const { global: global_client } = clientSettings ?? {}
  const { appSettings: appSettings_client } = global_client ?? {}
  const { clientRegistrationUrl, useClientRegistrationUrl } = appSettings_client ?? {}
  const regUrl = useClientRegistrationUrl && clientRegistrationUrl ? clientRegistrationUrl : null

  const regData_initState = { pathViews, regUrl, dataOptions }
  const [regData_state, regData_dispatch] = useReducer(regDataReducer, regDataInitialState(regData_initState));
  const regData_handlers = regDataHandlers(regData_dispatch, regData_state)

  useEffect(() => {
    regData_handlers.handleGetRegData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const providerContext = () => <RegDataContext.Provider
    value={{ regData_state, regData_handlers }}>
    {props.children}
  </RegDataContext.Provider>

  return providerContext()
}

export default RegDataProvider