import React, { useContext } from 'react';
import { Header, Icon, Label, Menu } from 'semantic-ui-react';
import { AppSettingsContext } from '../../../cnr/contexts/AppSettingsContext';
import { FrameworkContext } from '../../../cnr/contexts/FrameworkContent';
import { _settingSourceTypes, ServiceWorkerContext } from '../../../cnr/contexts/ServiceWorkerContext';
import { iconColorTypes } from '../../../enums/settingsIconTypes';
import { _settingsFs, rootSettings_collection_sandbox } from '../../actions/getSettings';

const backIcon = {
  color: 'red', // 'grey'
  icon: 'delete', // 'arrow left'
}

/**
 * 
 * @param {object} props (sideMenu, iconLeft, iconLeftColor, title, rightMenuItemProps)
 * @returns The `Menu` for the header of the `SettingsArea`
 */
const SettingsAreaMenuHeader = (props) => {

  const { isGlobal, sideMenu, iconLeft, iconLeftColor, title, subCaption, subTimeStamp, subDisplayName, rightMenuItemProps, settingsSourceType, settingsOriginalSourceType } = props

  // serviceWorkerContext
  const serviceWorkerContext = useContext(ServiceWorkerContext)
  const { serviceWorker_state } = serviceWorkerContext ?? {}
  const { useSwCache } = serviceWorker_state ?? {}

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_state, appSettings_handlers } = appSettingsContext ?? {}
  const { sandboxOn } = appSettings_state ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers } = frameworkContext ?? {}
  const { handleShow_appSidebar } = framework_handlers ?? {}

  const { icon: iconRight, handleUpdateAll, handleAllowGroupMenuSort, show: showRight, allowGroupMenuSort } = rightMenuItemProps ?? {}
  const { fullMobileWidth } = sideMenu ?? {}

  const rsc = sandboxOn ? rootSettings_collection_sandbox : _settingsFs.root

  const handleSettingsOn = () => {
    if (isGlobal) {
      appSettings_handlers.handleToggle_settingsLeftOn()
    } else {
      appSettings_handlers.handleToggle_settingsRightOn()
    }
  }

  let ico = {
    name: null,
    color: 'grey'
  }

  switch (settingsSourceType) {
    case _settingSourceTypes.app:
      ico.name = 'setting'
      ico.color = 'blue'
      break;
    case _settingSourceTypes.database:
      ico.name = 'database'
      ico.color = 'blue'
      break;
    case _settingSourceTypes.cache:
      ico.name = 'check circle'
      ico.color = 'green'
      break;
  }

  const subHeader = () => <Header.Subheader>
    <Label size={'mini'} color={iconColorTypes.settingsUser} style={{ margin: 0, marginTop: 6 }} className={sandboxOn ? 'blink_me_2' : null}>
      {useSwCache && <Icon name="database" color='black' />}
      {rsc + ': ' + subCaption}
    </Label>
    {subTimeStamp && <Label size={'mini'} color={ico.color} style={{ marginLeft: 6, marginTop: 6 }}>
      <Icon name="clock outline" color='black' />
      {subTimeStamp}
    </Label>}
    {subDisplayName && <Label size={'mini'} color={'blue'} style={{ marginLeft: 6, marginTop: 6 }}>
      <Icon name="user outline" color='black' />
      {subDisplayName}
    </Label>}
  </Header.Subheader>

  const menuItem_right = () => <Menu.Menu position='right'>
    {fullMobileWidth && <Menu.Item onClick={handleShow_appSidebar}>
      <Icon name={'delete'} />
    </Menu.Item>}
    {showRight && <Menu.Item onClick={handleUpdateAll}>
      <Icon name={iconRight} />
    </Menu.Item>}
  </Menu.Menu>

  const menuItem_edit = () => <Menu.Menu className={'samhe'} position='right'>
    <Menu.Item onClick={handleAllowGroupMenuSort}>
      <Icon name={'sort'} color={allowGroupMenuSort ? 'blue' : null} />
    </Menu.Item>
  </Menu.Menu>

  return <Menu borderless inverted className={'samh'}>
    <Menu.Item>
      <Icon.Group onClick={handleSettingsOn}>
        <Icon name={iconLeft} size={'large'} color={iconLeftColor} />
        <Icon corner='bottom left' name={backIcon.icon} size='tiny' color={backIcon.color} />
      </Icon.Group>
    </Menu.Item>
    <Menu.Item>
      <Header inverted>
        <Header.Content>
          {title}
          {subHeader()}
        </Header.Content>
      </Header>
    </Menu.Item>
    {(showRight || fullMobileWidth) && menuItem_right()}
    {allowGroupMenuSort && menuItem_edit()}
    {/* <Menu.Item onClick={handleShowQuickSearch}>
      <Icon name={'caret down'} size={'large'} color={iconLeftColor} />
    </Menu.Item> */}
  </Menu>
}

export default SettingsAreaMenuHeader