import { gEnums } from '../enums/globalEnums';
import { projectStyles } from './projectStyles';

export const addToClass = (cn, value, prefix) => {
  if (value && value !== 'none') {
    if (prefix) {
      if (cn) {
        cn += ' ' + prefix + '-' + value
      } else {
        cn = prefix + '-' + value
      }
    } else {
      if (cn) {
        cn += ' ' + value
      } else {
        cn = value
      }
    }
  }
  return cn
}

export const setCssStyle = (style, itemStyle) => {
  if (style.apply) {
    if (style.fontSize) { itemStyle.fontSize = style.fontSize + 'em' }
    if (style.fontWeight) { itemStyle.fontWeight = style.fontWeight }
    if (style.margin) { itemStyle.marginLeft = style.margin + 'em' }
    if (style.opacity) { itemStyle.opacity = style.opacity }
    if (style.padding) { itemStyle.paddingLeft = style.padding + 'em' }
  }
}

const removeClass = (themeProps) => {
  if (themeProps.className) {
    themeProps.className = themeProps.className.replace('bc-main', '')
    themeProps.className = themeProps.className.replace('bc-bk', '')
    themeProps.className = themeProps.className.replace('bc-sec', '')
    themeProps.className = themeProps.className.replace('bc-settings', '')
  }
}

export const getElementStyle = (styles, keys) => {
  const sty = {}
  keys.forEach(item => {
    sty[item] = styles && styles[item] && styles[item] ? styles[item] + 'em' : null
  })
  return sty
}

export const subSnC = (snc, opts, getColors) => {

  const s = { style: {} }
  const styO = snc.style
  const sty = s.style

  if (opts) {
    opts.forEach(opt => {
      if (styO[opt]) {
        switch (opt) {
          case 'fontSize':
          case 'margin':
          case 'padding':
            sty[opt] = styO[opt]
            break;
          case 'iconSize':
            sty['fontSize'] = styO[opt]
            break;
          case 'height':
            sty[opt] = styO[opt]
            break;
          default:
            sty[opt] = styO[opt]
            break;
        }
      }
    })
  } else {
    Object.keys(styO).forEach(key => {
      switch (key) {
        case 'backgroundColor':
        case 'color':
          // ignore
          break;
        default:
          sty[key] = styO[key]
      }
    })
  }

  if (getColors) {
    Object.keys(styO).forEach(key => {
      switch (key) {
        case 'backgroundColor':
        case 'color':
          sty[key] = styO[key]
          break;
        default:
        // nothing
      }
    })
  }

  return s
}

/**
 * 
 * @param {string} cn 
 * @param {object} sectionObject 
 * @returns a className base on the sectionObject settings
 */
export const getSectionClass = (cn, sectionObject) => {
  let { clear, clearBackground, clearBorder, borderRadius, borderWidth, displayType, bodyDisplayType, headerDisplayType, captionAlignment, captionWidth, celled, divided, dividerSize, vertical, fontSize, fontWeight, gap, headerAlignment, iconSize, margin, opacity, padding, thickness, caseType } = sectionObject ? sectionObject : {}
  if (captionWidth === 3) { captionWidth = gEnums.captionWidths.normal }
  cn = addToClass(cn, borderRadius, 'br')
  cn = addToClass(cn, borderWidth, 'bw')
  cn = addToClass(cn, captionAlignment)
  cn = addToClass(cn, captionWidth, 'w')
  cn = addToClass(cn, caseType)
  cn = addToClass(cn, displayType)
  cn = addToClass(cn, dividerSize, 'ds')
  cn = addToClass(cn, fontSize, 'fs')
  cn = addToClass(cn, fontWeight, 'fw')
  cn = addToClass(cn, gap, 'g')
  cn = addToClass(cn, headerAlignment, 'ha')
  cn = addToClass(cn, iconSize, 'i')
  cn = addToClass(cn, margin, 'm')
  cn = addToClass(cn, opacity, 'op')
  cn = addToClass(cn, padding, 'p')
  cn = addToClass(cn, thickness, 't')
  cn = addToClass(cn, clear, 'clr')
  cn = addToClass(cn, clearBackground, 'clr-bkg')
  cn = addToClass(cn, clearBorder, 'clr-brd')
  if (celled) { cn = addToClass(cn, 'celled') }
  if (divided) { cn = addToClass(cn, 'divided') }
  if (vertical) { cn = addToClass(cn, 'vertical') }
  if (bodyDisplayType) { cn = addToClass(cn, bodyDisplayType) }
  if (headerDisplayType) { cn = addToClass(cn, headerDisplayType) }
  return cn
}

export const getSectionThemeColors = (_asg, sectionObject) => {
  const { colors } = sectionObject ? sectionObject : {}
  return setThemeColors(_asg, colors)
}

export const setThemeColors = (_asg, colors) => {
  const { themeColors } = _asg ? _asg : {}
  const sty = {}
  if (themeColors && colors && colors.apply) {
    if (themeColors[colors.backgroundColor]) { sty.backgroundColor = themeColors[colors.backgroundColor] }
    if (themeColors[colors.color]) { sty.color = themeColors[colors.color] }
  }
  return sty
}

export const getThemeColorDirect = (_asg, colorSetting) => {
  if (colorSetting && colorSetting.color && colorSetting.apply) {
    const { themeColors } = _asg ? _asg : {}
    if (themeColors && themeColors[colorSetting.color]) {
      return themeColors[colorSetting.color]
    }
  }
}

export const getThemeColor = (_asg, color) => {
  const { themeColors } = _asg ? _asg : {}
  if (themeColors && themeColors[color]) {
    return themeColors[color]
  }
}

export const setSectionStyle = (cn) => {
  let style = {}
  const cns = { className: cn.trim(), style }
  if (!cns.className || cns.className === '') { delete cns.className }
  if (!cns.style || Object.keys(cns.style).length === 0) { delete cns.style }
  return cns
}

export const getTheme = (_asg, item) => {
  const { themedItems } = _asg ? _asg : {}
  const ti = themedItems ? themedItems[item] : {}
  return ti
}

export const getThemeItem = (_asg, item) => {
  const { themedItems, themeColors } = _asg ? _asg : {}
  const { defaultTheme } = themedItems ?? {}
  const ti = themedItems ? themedItems[item] : {}
  let bc;
  let c;

  // colors
  if (ti && ti.apply && ti.backgroundColor) {
    if (themeColors[ti.backgroundColor]) { bc = themeColors[ti.backgroundColor] }
    if (themeColors[ti.color]) { c = themeColors[ti.color] }
  }

  if (!ti && defaultTheme && defaultTheme.apply) {
    switch (item) {
      case 'active':
      case 'background':
      case 'headers':
        break;
      default:
        if (defaultTheme.backgroundColor && themeColors[defaultTheme.backgroundColor]) { bc = themeColors[defaultTheme.backgroundColor] }
        if (defaultTheme.color && themeColors[defaultTheme.color]) { c = themeColors[defaultTheme.color] }
    }
  }
  return { bc, c }
}

export const setItemStyleAndClass = (_asg, sac, colors) => {

  const { themeColors } = _asg ? _asg : {}

  if (themeColors && colors && colors.apply) {
    const { apply, backgroundColor, color } = colors ? colors : {}
    if (apply) {
      sac.style = {}
      if (backgroundColor) {
        if (themeColors[backgroundColor]) {
          sac.style.backgroundColor = themeColors[backgroundColor]
        }
      }
      if (color) {
        if (themeColors[color]) {
          sac.style.color = themeColors[color]
        }
      }
    }
  }
}

export const setStyleAndClass = (_asg, item, opts, ignoreColors, forActive, forLine) => {

  const { themedItems, themeColors } = _asg ? _asg : {}
  const { defaultTheme } = themedItems ?? {}

  let defaultCnS = opts ? { style: {} } : projectStyles()[item]

  if (!defaultCnS) { defaultCnS = { style: {} } }
  if (!defaultCnS.style) { defaultCnS.style = {} }

  if (themedItems && item && themedItems[item]) {

    const themeItem = themedItems[item]

    defaultCnS.className = getSectionClass(defaultCnS.className, themeItem)

    if (themeItem && !ignoreColors && themeColors) {

      const { borderColor, colors, activeColors, lineColor, backgroundImage } = themeItem

      if (borderColor && borderColor.color && borderColor.color && themeColors[borderColor.color]) {
        defaultCnS.style.borderColor = themeColors[borderColor.color]
      }

      if (backgroundImage) {
        defaultCnS.style.bgi = backgroundImage
      }

      if (forActive) {
        if (activeColors && activeColors.apply) {
          const { apply, backgroundColor, color: foreColor } = activeColors ? activeColors : {}
          if (apply) {
            if (backgroundColor) {
              if (themeColors[backgroundColor]) {
                defaultCnS.style.backgroundColor = themeColors[backgroundColor]
              }
            }
            switch (item) {
              // case 'background':
              //   break;
              default:
                if (foreColor) {
                  if (themeColors[foreColor]) {
                    defaultCnS.style.color = themeColors[foreColor]
                  }
                }
            }
            removeClass(defaultCnS)
          }
        }
      } else if (forLine) {
        if (lineColor && lineColor.apply) {
          const { apply, color: foreColor } = lineColor ? lineColor : {}
          if (apply) {
            defaultCnS.style.borderColor = themeColors[foreColor]
            removeClass(defaultCnS)
          }
        } else {
          setDefaultStyle(item, defaultTheme, themeColors, defaultCnS)
        }
      } else if (colors && colors.apply) {
        const { apply, backgroundColor, color: foreColor } = colors ? colors : {}
        if (apply) {
          if (backgroundColor) {
            if (themeColors[backgroundColor]) {
              defaultCnS.style.backgroundColor = themeColors[backgroundColor]
            }
          }
          switch (item) {
            // case 'background':
            //   break;
            default:
              if (foreColor) {
                if (themeColors[foreColor]) {
                  defaultCnS.style.color = themeColors[foreColor]
                }
              }
          }
          removeClass(defaultCnS)
        }
      } else {
        setDefaultStyle(item, defaultTheme, themeColors, defaultCnS)
      }
    }
  }

  return defaultCnS
}

const setDefaultStyle = (item, defaultTheme, themeColors, defaultCnS) => {
  const { colors } = defaultTheme ? defaultTheme : {}
  const { apply, backgroundColor, color } = colors ? colors : {}
  if (apply) {
    switch (item) {
      case 'active':
      case 'background':
      case 'content':
      case 'headers':
        break;
      default:
        if (colors) {
          if (backgroundColor && themeColors[backgroundColor]) { defaultCnS.style.backgroundColor = themeColors[backgroundColor] }
          if (color && themeColors[color]) { defaultCnS.style.color = themeColors[color] }
          removeClass(defaultCnS)
        }
    }
  }
}

export const adjustCase = (caseType, pv) => {
  try {
    switch (caseType) {
      case gEnums.caseTypes.upperCase:
        return pv.toUpperCase()
      case gEnums.caseTypes.lowerCase:
        return pv.toLowerCase()
      default:
        return pv
    }
  } catch (error) {
    return pv
  }
}

export const formatTelephone = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[2] + ') ' + match[3] + '-' + match[4];
  }
  return phoneNumberString;
}


