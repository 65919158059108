import React, { useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { AppUserManagerContext } from '../cnr/contexts/AppUserManagerContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { appUserManagerHandlers, appUserManagerInitialState, appUserManagerReducer, showAppManagerTypes, userMenuItems } from '../cnr/reducers/AppUserManagerReducer';
import { uniqueKey } from '../common/keys';
import ConfirmationQuestion from '../components/alerts/ConfirmationQuestion';
import Popuper from '../components/popups/Popuper';
import UserAuth from '../components/user/UserAuth';
import UserProfile from '../components/user/UserProfile';
import JsonViewer from '../components/viewers/JsonViewer';
import { clickOriginTypes, gEnums } from '../enums/globalEnums';
import GenericPopupMenuItem from '../genericControls/GenericMenuItem';
import DirectMessage from '../messages/DirectMessage';
import { openExternal } from '../viewSettings/helpers/settingsLinks';
import FullPageWrapper from '../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import AppUserCard from './AppUserCard';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { Icon } from 'semantic-ui-react';

const AppUserManager = (props) => {

  const { clickOriginType, selectedAppUser, selectedAppUserKey, handleCancel, item_cpd } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, settings } = parentContext ?? {}
  const { appUser_state, paps_state, page_state } = states
  const { validation_handlers } = handlers ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { appSignIn, signInEmail, dataModifications: dataModifications_global } = aps_global ?? {}
  const { modification } = dataModifications_global ?? {}
  const { homeSettings } = settings ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}
  const { logging } = homeSettings_global ?? {}
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { accessLevel } = appUserAccess ?? {}
  const { displayName } = selectedAppUser ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { frameworkLeftType } = framework_state ?? {}

  let _fromDataViewer;

  switch (clickOriginType) {
    case clickOriginTypes.dataViewer:
    case clickOriginTypes.framework:
      _fromDataViewer = true
    default:
      if (frameworkLeftType) {
        _fromDataViewer = true
      }
    //nothing
  }

  const allowSA = appUserAccess && (accessLevel > gEnums.accessLevels.admin.value)

  // appUserManager
  const initState = appUserManagerInitialState({ paps_state, selectedAppUser, selectedAppUserKey, appSignIn, signInEmail, logging })
  const [appUserManager_state, dispatch] = useReducer(appUserManagerReducer, initState);
  const appUserManager_handlers = appUserManagerHandlers(dispatch)
  const { authRetrieved, selectedAppUserAuth, showAppManagerType } = appUserManager_state ?? {}
  const { email, _itemKey } = selectedAppUserAuth ?? {}

  useEffect(() => {
    appUserManager_handlers.handleGet_appUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);


  const handleOpenAuth = () => openExternal.firebaseAuth()
  const handleOpenProfile = () => openExternal.firebaseProfile(paps_state.pathViews, paps_state.pathViews.clients ? email : _itemKey)
  const handleMenuItemClick = (item) => appUserManager_handlers.handleSet_selectedAppUser(item)
  const cancelSendToEmail = () => appUserManager_handlers.handleSet_selectedAppUser()
  const sendPasswordReset = () => appUserManager_handlers.handleSend_passwordResetEmail(email)
  const handleResetDisclaimerResponses = () => appUserManager_handlers.handleReset_disclaimerResponses(email)

  const sendSignInLink = () => validation_handlers.handleSend_signInLinkToUserEmail(email)

  const gpmi = (item, oc) => <GenericPopupMenuItem key={uniqueKey('aum', 'gmpi', item)} item={item} oc={oc} />

  /** a list of menu items  */
  const sidebarMenuItems = () => {
    const mis = []
    if (selectedAppUserAuth.email) {
      mis.push(gpmi(userMenuItems.resetDisclaimerResponses, handleResetDisclaimerResponses))
      mis.push(gpmi(userMenuItems.sendEmail, handleMenuItemClick))
      mis.push(gpmi(userMenuItems.sendPasswordResetEmail, handleMenuItemClick))
    }

    selectedAppUserAuth.fcmTokens && mis.push(gpmi(userMenuItems.sendPushNotification, handleMenuItemClick))
    selectedAppUserAuth.phoneNumber && mis.push(gpmi(userMenuItems.sendSms, handleMenuItemClick))
    allowSA && mis.push(gpmi(userMenuItems.openAuthorization, handleOpenAuth))
    allowSA && mis.push(gpmi(userMenuItems.openDatabaseProfile, handleOpenProfile))
    mis.unshift(gpmi(userMenuItems.editProfile, handleMenuItemClick))
    allowSA && mis.unshift(gpmi(userMenuItems.editAuthorization, handleMenuItemClick))
    // selectedAppUserAuth.email && mis.push(gpmi(userMenuItems.sendSignInLinkEmail, handleMenuItemClick))
    return mis
  }

  const jsonViewer = () => <JsonViewer json={selectedAppUserAuth} noFullHeight={true} name={'App User Profile'} inApp={true} />

  const appUserCardEdit = () => <React.Fragment>
    <AppUserCard appUser={selectedAppUserAuth} />
    {jsonViewer()}
  </React.Fragment>

  const appUserWrapper = () => <Wrapper
    header={_fromDataViewer && <div><Icon style={{ marginRight: '1em' }} name='arrow left' color={'blue'} onClick={(e) => { handleCancel(e) }} />{displayName}</div>}
    content={selectedAppUserAuth && appUserCardEdit()}
    wrapperType={wrapperTypes.padded}
    updating={!authRetrieved}
    updatingCaption={'fetching'}
    css={{ container: frameworkLeftType ? 'app-dark-mode' : null }}
  />

  const popuper = () => <Popuper
    menuItems={selectedAppUserAuth && sidebarMenuItems()}
    content={appUserWrapper()}
    showPopupIcon={true}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={popuper()}
    handleCancel={handleCancel}
    topperCaption={'App User'}
    topperCaption2={email}
    isBack={true}
  />

  const confirmation_sendSignInLink = () => <ConfirmationQuestion
    header={'Please Confirm'}
    question={'Send sign in link email to ' + email + '?'}
    handleNo={cancelSendToEmail}
    handleYes={sendSignInLink}
  />

  const confirmation_sendPasswordReset = () => <ConfirmationQuestion
    header={'Please Confirm'}
    question={'Send password reset email ' + email + '?'}
    buttonCaption={'Send'}
    buttonIcon={'send'}
    handleNo={cancelSendToEmail}
    handleYes={sendPasswordReset}
  />

  const content = () => {
    switch (showAppManagerType) {
      case showAppManagerTypes.emailSignInLink:
        return confirmation_sendSignInLink()
      case showAppManagerTypes.emailPasswordReset:
        return confirmation_sendPasswordReset()
      case showAppManagerTypes.email:
      case showAppManagerTypes.notification:
      case showAppManagerTypes.sms:
        return <DirectMessage />
      case showAppManagerTypes.auth:
        return <UserAuth inApp={true} item_cpd={item_cpd} />
      case showAppManagerTypes.profile:
        return <UserProfile clickOriginType={clickOriginType} item_cpd={item_cpd} />
      default:
        return _fromDataViewer ? popuper() : fullPageWrapper()
    }
  }

  return <AppUserManagerContext.Provider value={{ appUserManager_state, appUserManager_handlers }} >
    {content()}
  </AppUserManagerContext.Provider >

}

export default AppUserManager