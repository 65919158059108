import React, { createContext, useContext } from 'react';
import { DataContext } from './DataContext';
import { DisplayContext } from './DisplayContext';
import { FrameworkContext } from './FrameworkContent';
import { PageDataContext } from './PageDataContext';
import { SettingsViewerContext } from '../../components/viewers/FullSettingsViewer';
import { UiItemContext } from './UiItemContext';
import { PageAreaContext } from './PageAreaContext';

/**
 * @state components_state (componentContexts)
 */
export const ComponentContext = createContext();

/**
 * 
 * @param {object} props  
 */
const ComponentProvider = (props) => {

  const dataContext = useContext(DataContext);
  const displayContext = useContext(DisplayContext);
  const frameworkContext = useContext(FrameworkContext);
  const pageAreaContext = useContext(PageAreaContext);
  const pageDataContext = useContext(PageDataContext);
  const settingsViewerContext = useContext(SettingsViewerContext);
  const uiItemContext = useContext(UiItemContext);

  const componentContexts = {
    dataContext,
    displayContext,
    frameworkContext,
    pageAreaContext,
    pageDataContext,
    settingsViewerContext,
    uiItemContext,
  }

  return <ComponentContext.Provider value={{ component_state: { componentContexts } }}>
    {props.children}
  </ComponentContext.Provider>
};

export default ComponentProvider