import { where } from "firebase/firestore"
import _ from "lodash"
import { dispatchProps } from "../../../cnr/reducers/reducerHelpers/dispatchProps"
import { fs_db } from "../../appData/fsAppData"
import { createRefPath } from "../../appData/appRefPaths"
import { fs_dbu } from "../../appData/fsAppDataUpdate"
import { mergePromise } from "../../promises/mergePromise"

export const fsUpdate_mergeData = (props) => {

  const {
    baseRef,
    dispatch,
    ufProps,
  } = props

  const { refPath, directCollection } = baseRef
  let { vit, allInfo, itemData } = ufProps ?? {}

  if (allInfo) {
    const coll = directCollection ? directCollection : vit
    const _refPath = createRefPath([coll], refPath)
    const wheres = [where('name', '==', allInfo.name)]
    fs_db.get_data({ refPath: _refPath, wheres }).then(item => {
      if (!_.isEmpty(item)) {
        const _updateRef = createRefPath([coll, item.id], refPath)
        fs_dbu.update_doc({ refPath: _updateRef }, allInfo, dispatch)
      } else {
        const _setRef = createRefPath([coll, item.id], refPath)
        fs_dbu.update_doc({ refPath: _setRef }, allInfo, dispatch)
      }
    })
  } else {
    mergePromise(refPath, vit, directCollection, itemData).then(pr => {
      dispatch({ type: dispatchProps.success, dispatch })
    }).catch(error => {
      dispatch({ type: dispatchProps.error, error, dispatch })
    })
  }
}