import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Icon, Label, List, Message, Segment, Sidebar } from 'semantic-ui-react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { getGoogleSheetDataValues } from '../../../../global/common/convert';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import DropdownOptionsMenu from '../../../../global/components/forms/elements/DropdownOptionsMenu';
import { getHeaders } from '../../../../global/components/headers/getHeaders';
import JsonViewer from '../../../../global/components/viewers/JsonViewer';
import { fsfn_transfer } from '../../../../global/functions/fbTransfer';
import { openExternal } from '../../../../global/viewSettings/helpers/settingsLinks';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { fbAppConfigs } from '../../../../project/appConfigs';
import { _projectAppNames, allowAppSportsDataExport, getAppName, getFbConfig } from '../../../../project/appConfiguration';
import { SportsDistrictContext } from '../../cnr/contexts/SportsDistrictContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { _districtCollections } from '../../cnr/reducers/SportsDistrictReducer';
import NonSeasonalData from './NonSeasonalData';
import SeasonalData from './SeasonalData';

const _rightModes = {
  backup: 'backup',
  commitGs: 'commitGs',
  exportTsSports: 'exportTsSports',
  importTsSports: 'importTsSports',
  exportMeSports: 'exportMeSports',
  importMeSports: 'importMeSports',
}

const _projectPaths = {
  meSports: 'clients/b4C6wHP0A5AgQPTD78ue/events/pojoSports/',
  tsSports: 'clients/thumbstat/events/thumbstatSports/',
}

const SportsData = (props) => {

  const { sportsDataMode, currentItem, currentItemKey } = props
  const { subCollection } = currentItem ?? {}

  const trueConfig = getFbConfig()
  const { appName } = getAppName()
  const { projectId } = trueConfig ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { pathViews } = paps_state ?? {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state, sportsDistrict_handlers } = sportsDistrictContext ?? {}
  const { seasonalDatas, nonSeasonalDatas, seasonalDataType } = sportsDistrict_state ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { parentDataKeys } = sportsSeason_state ?? {}

  const _sportsDataExport = allowAppSportsDataExport()

  const [showDatasDuel, setShowDatasDuel] = useState()
  const [currentSportsData, setCurrentSportsData] = useState()

  const [isSeasonalData, setIsSeasonalData] = useState()
  const [_seasonalData, setSeasonalData] = useState()
  const [_nonSeasonalData, setNonSeasonalDatas] = useState()
  const [currentTsData, setCurrentTsData] = useState()
  const [mode_right, setMode_right] = useState()
  const [updating, setUpdating] = useState()
  const [selectedSport, setSelectedSport] = useState()

  useEffect(() => {
    if (currentItemKey) {
      let _isSeasonalData;
      switch (currentItemKey) {
        case _districtCollections._matches_seasonal:
        case _districtCollections._rankings:
        case _districtCollections._rosters:
        case _districtCollections._scores_seasonal:
        case _districtCollections._seasonals:
        case _districtCollections._teams:
        case _districtCollections._tournaments:
          _isSeasonalData = true
          break;
        // const _seasonalDatas = seasonalDatas ? seasonalDatas[seasonalDataType] : null
        // const _seasonData = _seasonalDatas ? _seasonalDatas : _currentData
        // setSeasonalData(_seasonData)
        default:
          _isSeasonalData = false
        // let _currentItemKey;
        // let _currentData;
        // if (currentItemKey.startsWith('_')) {
        //   _currentItemKey = currentItemKey.substring(1)
        //   _currentData = sportsDistrict_state[_currentItemKey]
        // } else {
        //   _currentItemKey = _currentItemKey
        //   _currentData = sportsDistrict_state[_currentItemKey]
        // }
        // setNonSeasonalDatas(_currentData)
      }
      setIsSeasonalData(_isSeasonalData)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentItemKey]);

  const getExportConfigs = () => {

    let _isExport;

    switch (mode_right) {
      case _rightModes.exportTsSports:
      case _rightModes.exportMeSports:
        _isExport = true
        break;
      default:
      // nothing
    }

    const configs = {
      me: fbAppConfigs['meetingevolution']['dev'],
      ts: fbAppConfigs['pojo']['dev'],
    }

    const configz = {
      me: configs.me,
      ts: configs.ts
    }

    const projectIds = _isExport ? {
      source: configz.me.projectId,
      destination: configz.ts.projectId
    } : {
      destination: configz.me.projectId,
      source: configz.ts.projectId
    }

    const paths = _isExport ? {
      sourcePath: _projectPaths.meSports + currentItemKey,
      destinationPath: _projectPaths.tsSports + currentItemKey
    } : {
      destinationPath: _projectPaths.meSports + currentItemKey,
      sourcePath: _projectPaths.tsSports + currentItemKey
    }

    return { projectIds, paths }

  }

  const exportToSports = async () => {

    setUpdating(true)

    const { projectIds, paths } = getExportConfigs()

    console.log('projectIds', projectIds, paths, currentItem)
    // await fsfn_transfer.transferDirect_ts(projectIds, true, paths, currentItem)
    setUpdating(false)
  }


  const handleShowData_duel = () => setShowDatasDuel(!showDatasDuel)

  const handleSelectedSport = (ss) => setSelectedSport(ss)
  const handleCommit_backup = () => sportsDistrict_handlers.handleBackup(currentItemKey)
  const handleModeRight = (opts) => handleMode_right(opts.rightMode)
  const handle_openDb = () => openExternal.firebaseDb(pathViews, currentItemKey)
  const handleCommit_exportSports = () => exportToSports()

  const handleCommit_gs = () => {
    const { headers } = getHeaders(_seasonalData)
    const _currentData = _.sortBy(_seasonalData, 'name')
    const { values } = getGoogleSheetDataValues(_currentData, headers, 'Results')
    console.log('values', values)
  }

  const handleMode_right = (mr) => setMode_right(mr)

  const ddb = (menuButtons, value) => <DropdownOptionsMenu
    menuButtons={menuButtons}
    value={value}
    caption={'Action'}
    icon={'list'}
    color={'blue'}
    direction={'left'}
  />

  const saveButtons = () => {
    const btns = []
    btns.push({ oc: handle_openDb, caption: 'Open Database' })
    // btns.push({ oc: handleShowData, caption: 'View Data' })
    btns.push({ oc: handleShowData_duel, caption: 'View Duel Data' })

    _seasonalData && btns.push({ oc: handleModeRight, caption: 'Backup Data (' + _.startCase(currentItemKey) + ')', opts: { rightMode: _rightModes.backup } })
    _seasonalData && btns.push({ oc: handleModeRight, caption: 'Download to Google Sheets (' + _.startCase(currentItemKey) + ')', opts: { rightMode: _rightModes.commitGs } })

    switch (appName) {
      case _projectAppNames.meetingevolution:
        _sportsDataExport && btns.push({ oc: handleModeRight, caption: 'Export to Thumbstat Sports (' + _.startCase(currentItemKey) + ')', opts: { rightMode: _rightModes.exportTsSports } })
        _seasonalData && _sportsDataExport && btns.push({ oc: handleModeRight, caption: 'Import from Thumbstat Sports (' + _.startCase(currentItemKey) + ')', opts: { rightMode: _rightModes.importTsSports } })
        break;
      case _projectAppNames.pojo:
        _seasonalData && _sportsDataExport && btns.push({ oc: handleModeRight, caption: 'Export to ME Pojo Sports (' + _.startCase(currentItemKey) + ')', opts: { rightMode: _rightModes.exportMeSports } })
        _seasonalData && _sportsDataExport && btns.push({ oc: handleModeRight, caption: 'Import from ME Pojo Sports (' + _.startCase(currentItemKey) + ')', opts: { rightMode: _rightModes.importMeSports } })
        break;
      default:
      // nothing
    }
    return btns
  }

  const footer = () => {
    const btns = saveButtons()
    return btns.length > 0 ? ddb(saveButtons()) : null
  }

  const footer_confirm_export = () => {
    let _caption;
    switch (mode_right) {
      case _rightModes.exportTsSports:
        _caption = 'Export to Thumbstat Sports ('
        break;
      case _rightModes.exportMeSports:
        _caption = 'Export to ME Sports ('
        break;
      default:
      // nothing
    }
    return <UiSaveButtons
      save={{ oc: handleCommit_exportSports, caption: _caption + _.startCase(currentItemKey) + ')', icon: 'save', color: 'green' }}
      cancel={{ oc: handleMode_right, }}
    />
  }

  const footer_confirm_import = () => {
    let _caption;
    switch (mode_right) {
      case _rightModes.importTsSports:
        _caption = 'Import to Thumbstat Sports ('
        break;
      case _rightModes.importMeSports:
        _caption = 'Import to ME Sports ('
        break;
      default:
      // nothing
    }
    return <UiSaveButtons
      save={{ oc: handleCommit_exportSports, caption: _caption + _.startCase(currentItemKey) + ')', icon: 'save', color: 'green' }}
      cancel={{ oc: handleMode_right, }}
    />
  }

  const footer_confirm_gs = () => <UiSaveButtons
    save={{ oc: handleCommit_gs, caption: 'Download to Google Sheets (' + _.startCase(currentItemKey) + ')', icon: 'save', color: 'green' }}
    cancel={{ oc: handleMode_right }}
  />

  const footer_confirm_backup = () => <UiSaveButtons
    save={{ oc: handleCommit_backup, caption: 'Backup (' + _.startCase(currentItemKey) + ')', icon: 'save', color: 'green' }}
    cancel={{ oc: handleMode_right }}
  />

  const jsonViewer = (data, name) => <JsonViewer json={data} name={name} />

  const content_json = () => _seasonalData ? jsonViewer(_seasonalData, currentItemKey + ' (me)') : <div className='cntr fw-b'>{currentItemKey}</div>

  const content_json_ts = () => currentTsData ? jsonViewer(currentTsData, currentItemKey + ' (ts)') : <div className='cntr fw-b'>{currentItemKey}</div>

  const content_sport = () => {
    const lis = []
    let _jv = [];
    if (currentSportsData) {
      Object.keys(currentSportsData).forEach(sKey => {
        lis.push(<List.Item onClick={(e) => { handleSelectedSport(sKey) }}>{sKey}</List.Item>)
      })
    }
    if (selectedSport) {
      const csd = currentSportsData[selectedSport]
      const { sportsKey, items } = csd ?? {}
      _jv = jsonViewer(items, selectedSport + ' (me)')
    }
    return <div className='table-split-container'>
      <List divided relaxed>
        {lis}
      </List>
      {_jv}
    </div>
  }

  const content_split = () => <div className='table-split-container'>
    {content_json()}
    {content_json_ts()}
  </div>


  const message = (key, direction) => <Message warning>
    <Message.Header>{'Are you sure you want to ' + key + ' (' + _.startCase(currentItemKey) + ')?'}</Message.Header>
  </Message>

  const content_export = () => {
    const { projectIds, paths } = getExportConfigs()
    const _json = { projectIds, paths, _seasonalData }
    return <JsonViewer json={_json} name={'Export Paths'} />
  }

  const lblIcon = (caption, iconName, color) => <Label size='mini'>
    <Icon name={iconName} color={color} />{caption}
  </Label>

  const content_main = () => {
    if (currentItemKey) {
      if (isSeasonalData) {
        return <SeasonalData fromDashboard={true} currentItem={currentItem} currentItemKey={currentItemKey} showDatasDuel={showDatasDuel} />
      } else {
        return <NonSeasonalData fromDashboard={true} currentItem={currentItem} currentItemKey={currentItemKey} showDatasDuel={showDatasDuel} />
      }
    }
  }

  const wrapper_content = () => <Wrapper
    header={lblIcon(appName + '/' + projectId, 'database')}
    content={content_main()}
    footer={footer()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    css={{ header: 'bcg', container: 'wrp-sub' }}
  />

  const wrapper_confirm_export = () => <Wrapper
    header={message('Export')}
    content={content_export()}
    footer={footer_confirm_export()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    updating={updating}
    css={{ header: 'bcg', container: 'wrp-sub' }}
  />

  const wrapper_confirm_import = () => <Wrapper
    header={message('Import')}
    content={content_export()}
    footer={footer_confirm_import()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    updating={updating}
    css={{ header: 'bcg', container: 'wrp-sub' }}
  />

  const wrapper_confirm_gs = () => <Wrapper
    content={message('Google Sheets')}
    footer={footer_confirm_gs()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    updating={updating}
    css={{ header: 'bcg', container: 'wrp-sub' }}
  />

  const wrapper_confirm_backup = () => <Wrapper
    content={message('Backup')}
    footer={footer_confirm_backup()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    updating={updating}
    css={{ header: 'bcg', container: 'wrp-sub' }}
  />

  const content_right = () => {
    switch (mode_right) {
      case _rightModes.backup:
        return wrapper_confirm_backup()
      case _rightModes.commitGs:
        return wrapper_confirm_gs()
      case _rightModes.exportTsSports:
      case _rightModes.exportMeSports:
        return wrapper_confirm_export()
      case _rightModes.importTsSports:
      case _rightModes.importMeSports:
        return wrapper_confirm_import()
      default:
      // nothing
    }
  }

  const sidebar_right = (visible) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'right'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='vsr-container sports'
      onHide={() => handleMode_right()}
    >
      {visible && content_right()}
    </Sidebar>
  )

  const sidebar_full = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_right(mode_right ? true : false)}
    <Sidebar.Pusher dimmed={mode_right ? true : false} className={'h100'}>
      {wrapper_content()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  return sidebar_full()

}

export default SportsData