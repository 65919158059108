import _ from 'lodash'
import React from 'react'
import { uniqueKey } from '../../../global/common/keys'
import { appIconTypes } from '../../../global/enums/appIconTypes'
import { openExternal } from '../../../global/viewSettings/helpers/settingsLinks'
import { _sportsFormProps } from '../cnr/contexts/SportsSeasonContext'
import { Form, Icon } from 'semantic-ui-react'
import { _sdm } from '../elements/dashboard/SportsDashboard'
import ListAdd from '../../../global/lists/ListAdd'
import CheckboxInput from '../../../global/components/forms/elements/CheckboxInput'

const _sheetsKeys = ['googleSheetsId', 'rankingsGoogleSheetsId', 'rosterGoogleSheetsId']

const SportFormProps = (props) => {

  const { handleGetGs, handleFormChange, formData, accessOptions, sportsDataMode, currentItem, handleAuthClick, handleAccessClone } = props

  const formLabel = (fdKey, value) => {
    if (_sheetsKeys.includes(fdKey)) {
      return <label>{_.startCase(fdKey)}<Icon style={{ float: 'right' }} name={appIconTypes.externalAlternate} onClick={(e) => openExternal.googleSheets(value)} /></label>
    } else {
      return _.startCase(fdKey)
    }
  }

  const listAdd = (fip) => <ListAdd fip={fip} ddOpts={{ name: 'accessLevel', options: accessOptions }} iconOpts={{ name: 'users', oc: handleAccessClone }} clickOpts={{ oc: handleAccessClone }} />

  const formInputElements = () => {
    const inputs = []
    _.forEach(formData, (value, keyOrIndex) => {
      const fip = { label: formLabel(keyOrIndex, value), value, onChange: handleFormChange, propname: keyOrIndex }
      switch (keyOrIndex) {
        case _sdm.district.accessSchools:
        case _sdm.district.accessSport:
        case _sdm.sports.accessTeams:
        case 'validAdmins':
          inputs.push(<ListAdd fip={fip} ddOpts={{ name: 'accessLevel', options: accessOptions }} />)
          break;

        case 'allowScoreUpdateFromGs':
        case 'allowScheduledScoreUpdateFromGs':
        case 'sortStandingsByPoints':
          inputs.push(<CheckboxInput fip={fip} data_localForm={formData} />)
          break;

        case 'scoreEmails':
          inputs.push(<ListAdd fip={fip} ddOpts={{ name: 'accessLevel', options: accessOptions }} />)
          break;

        case _sportsFormProps.glsTeam.rosterSheet:
        case _sportsFormProps.glsTeam.staffSheet:
        case _sportsFormProps.glsDistrict:
          inputs.push(<div />)
          inputs.push(<Form.Input
            key={uniqueKey('gsl', 'fi', keyOrIndex)}
            placeholder={keyOrIndex}
            icon={<Icon name='search' inverted circular link onClick={() => handleGetGs && handleGetGs(keyOrIndex)} />}
            {...fip}
          />)
          break;

        case _sportsFormProps.tournament.results:
        case _sportsFormProps.news.body:
          inputs.push(<Form.TextArea
            key={uniqueKey('gsl', 'fi', keyOrIndex)}
            placeholder={keyOrIndex}
            {...fip}
          />)
          break;

        default:
          inputs.push(<Form.Input
            key={uniqueKey('gsl', 'fi', keyOrIndex)}
            placeholder={keyOrIndex}
            {...fip}
          />)
      }
    })
    return inputs
  }

  switch (sportsDataMode) {
    case _sdm.district.accessSchools:
    case _sdm.district.accessSport:
    case _sdm.sports.accessTeams:
      let _formData = formData
      if (!_.isArray(_formData)) { _formData = [] }
      const fip = { label: _.startCase('teamAccess'), value: _formData, onChange: handleFormChange, propname: 'teamAccess' }
      fip.parentItem = currentItem
      return listAdd(fip)
    default:
      return formInputElements()
  }


}

export default SportFormProps