import React, { createContext, useReducer } from 'react';
import GlobalProvider from '../cnr/contexts/GlobalContext';
import SettingsParentProvider from '../cnr/contexts/SettingParentContext';
import SettingsAreaProvider from '../cnr/contexts/SettingsAreaContext';
import { settingsFrameworkHandlers, settingsFrameworkInitialState, settingsFrameworkReducer } from '../cnr/reducers/SettingFrameworkReducer';
import { uniqueKey } from '../common/keys';
import SettingsAreaSidebar from './SettingsAreaSidebar';
import '../../cssMain/AppSections.css'

/**
 * @state settingsFramework_state (leftSidebarGroupKey, leftSidebarGroupKey)
 * @handlers settingsFramework_handlers: (handleGroupKey_left, handleGroupKey_right)
 */
export const SettingsFrameworkContext = createContext();

export const settingsMenuTypes = {
  fullSettingsView: 'fullSettingsView',
  fullDataView: 'fullDataView'
}

/**
 * This is the launch point for all the settings
 * This will contain the main settings menu items for either the global OR page, depending on the input paramenters
 * This is the left and right columns of the `PageFramework's` grid columns
 * @param {object} vssProps (settingsMenuType)
 * @returns The settings column 
 */
const AppSettingsSidebarWithProvider = (vssProps) => {

  const { settingsMenuType } = vssProps ? vssProps : {}

  const initState = settingsFrameworkInitialState(settingsMenuType)
  const [settingsFramework_state, dispatch] = useReducer(settingsFrameworkReducer, initState);
  const settingsFramework_handlers = settingsFrameworkHandlers(dispatch)

  return <SettingsFrameworkContext.Provider value={{ settingsFramework_state, settingsFramework_handlers }}>
    <GlobalProvider>
      <SettingsParentProvider>
        <SettingsAreaProvider vssProps={vssProps}>
          <SettingsAreaSidebar key={uniqueKey('vss', 'ss')} />
        </SettingsAreaProvider>
      </SettingsParentProvider>
    </GlobalProvider>
  </SettingsFrameworkContext.Provider>
}

export default AppSettingsSidebarWithProvider