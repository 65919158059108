import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { uniqueKey } from '../../../common/keys'

const DropdownOptionsMenu = (props) => {

  const { menuButtons, subButtons, value, caption, icon, direction, color, disabled, upward, float, ignoreCaption } = props ?? {}

  const _caption = value ? value : caption

  const ddItems = (btns) => {
    const x = btns.map((mb, index) => {
      const { header, caption, icon, oc, opts, da, db } = mb
      const hdrs = []
      if (header) {
        hdrs.push(<Dropdown.Header icon={icon} content={header} key={uniqueKey('ddom', 'h', index)} />)
      } else {
        hdrs.push(<Dropdown.Item icon={icon} text={caption} key={uniqueKey('ddom', index)} onClick={(e) => { oc && oc(opts) }} />)
      }
      if (db) { hdrs.unshift(<Dropdown.Divider key={uniqueKey('ddb', 'menu', 'd1')} />) }
      if (da) { hdrs.push(<Dropdown.Divider key={uniqueKey('ddb', 'menu', 'd2')} />) }
      return hdrs
    })
    return x
  }

  const ddbs = () => {
    const dds = []
    subButtons.forEach((subButton, index) => {
      const { caption, btns } = subButton
      const ddis = ddItems(btns)
      dds.push(<Dropdown key={uniqueKey('ddb', 'dd', index)} text={caption} pointing='left' className='link item'>
        <Dropdown.Menu>
          {ddis}
        </Dropdown.Menu>
      </Dropdown>)
    })
    return dds
  }

  const ddb = () => {

    const ddis = menuButtons ? ddItems(menuButtons) : []

    return <Dropdown
      text={_caption ? _caption : 'Action'}
      icon={icon ? icon : 'setting'}
      floating
      labeled
      button
      direction={direction ? direction : 'right'}
      upward={upward}
      disabled={disabled}
      className={color ? 'icon mini ' + color : 'icon mini'}
      style={{ float: float ? float : 'right' }}
    >
      <Dropdown.Menu className='ddom'>
        {!ignoreCaption && <Dropdown.Header content={caption ? caption : 'Action'} />}
        {ddis}
        {subButtons && ddbs()}
      </Dropdown.Menu>
    </Dropdown>
  }

  return ddb()
}

export default DropdownOptionsMenu
