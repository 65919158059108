import { callFunctionDirect } from "./fbAll";

const fbFunctionTypes = {
  updateRegistrationDirect: 'updateRegistrationDirect',
}

export const fsfn_updateRegistration = (pathViews) => {

  const data = {
    clientKey: pathViews.clients,
    eventKey: pathViews.events,
    registrationKey: 'attendees',
    registrationData: {
      registrationId: '12345',
      firstName: 'Test',
      lastName: 'Tester',
      email: 'test@test.com',
      title: 'Tester',
      company: 'The Testing Company',
      tickets: [
        {
          ticketKey: '158',
          allotment: 2
        },
        {
          ticketKey: '159',
          allotment: 9
        },
      ]
    }
  }

  callFunctionDirect(fbFunctionTypes.updateRegistrationDirect, data);
} 