
import _ from 'lodash';
import { gEnums } from '../../enums/globalEnums';
import { fs_db } from '../../firestoreData/appData/fsAppData';
import { fs_dbu } from '../../firestoreData/appData/fsAppDataUpdate';
import { updateSettings } from '../../firestoreData/settings/updateSettings';
import { updateFirestoreData } from '../../firestoreData/updates/dataUpdates';
import { copyFirestore, pushFirestore } from '../../firestoreData/updates/subUpdates/copyFirestore';
import { updateDocumentHelpers } from '../../firestoreData/updates/subUpdates/fsUpdate_document';
import { fsfn_settings } from '../../functions/fbSettingsFunctions';
import { globalPageItems_update } from '../../viewSettings/actions/globalData';
import { grts, responseReducers } from './reducerHelpers/dispatchProps';

export const fsrts = {
  handleCopy_firestoreData: 'handleCopy_firestoreData',
  handlePush_firestoreData: 'handlePush_firestoreData',
  handleUpdate_firestoreData: 'handleUpdate_firestoreData',
  handleUpdate_firestoreDataDirect: 'handleUpdate_firestoreDataDirect',
  handleUpdate_firestoreSettings_globalAndViewsDb: 'handleUpdate_firestoreSettings_globalAndViewsDb',
  handleUpdate_firestoreSettings_db: 'handleUpdate_firestoreSettings_db',
  handleUpdate_firestoreSettings_globalViews: 'handleUpdate_firestoreSettings_globalViews',
  handleKill_confirmation: 'handleKill_confirmation',

  handleUpdate_allDocuments: 'handleUpdate_allDocuments',
  handleUpdate_dataToDocument: 'handleUpdate_dataToDocument',
  handleUpdate_dataToDocuments: 'handleUpdate_dataToDocuments',
  handleUpdate_itemInDocuments: 'handleUpdate_itemInDocuments',
  handleUpdate_itemsInDocument: 'handleUpdate_itemsInDocument',

  handle_addDoc: 'handle_addDoc',
  handle_deleteDoc: 'handle_deleteDoc',
  handle_setDoc: 'handle_setDoc',
  handle_updateDoc: 'handle_updateDoc',
  handle_getData: 'handle_getData',
  handle_fsCall: 'handle_fsCall',
  ...grts
}

export const firestoreReducer = (state, action, opts) => {

  const { paps_state: ps, page_fns, appUser, dataRestrictions, appDataSource, database_fns } = state
  let { paps_state, type, dispatch, parentDispatch, ufProps, settings_temp, fullDbSettings, isGlobal, settings_temp_global, sandboxOn, callback } = action ?? {}

  if (opts) {
    if (opts.type) { type = opts.type }
    if (opts.paps_state) { paps_state = opts.paps_state }
    if (opts.ufProps) { ufProps = opts.ufProps }
    if (opts.dispatch) { parentDispatch = opts.dispatch }
  }

  const { pathViews } = ps ?? {}

  const { useAppDataDocuments } = appDataSource ?? {}

  switch (type) {

    case fsrts.handle_fsCall:
      const { fsCall } = action
      let _refPaths;
      const { refPath, opts: opts_fs } = fsCall
      const { cbProps } = opts_fs ?? {}
      const { wherez } = cbProps ?? {}
      let lastItem;
      if (refPath) {
        if (_.isArray(refPath)) {
          _refPaths = refPath
        } else {
          _refPaths = refPath.split('/')
        }
        lastItem = _.takeRight(_refPaths, 1);
        // console.log('lastTwoItems', lastTwoItems)
      }

      const _fsCalls = [...state.fsCalls]
      _fsCalls.push({ [lastItem]: { refPath, wherez } })
      return { ...state, fsCalls: _fsCalls }

    case fsrts.handleUpdate_firestoreData:
      updateFirestoreData(parentDispatch, paps_state, ufProps, page_fns, appUser, dataRestrictions, opts, database_fns)
      return { ...state }

    // updates using dispatchProps as the callback.
    // updateSuccess or updateError will get updated to the caller
    case fsrts.handleUpdate_firestoreDataDirect:
      updateFirestoreData(dispatch, paps_state, ufProps, page_fns, appUser, dataRestrictions, null, database_fns)
      return { ...state }

    case fsrts.handleCopy_firestoreData:
      copyFirestore(dispatch, paps_state, ufProps, page_fns)
      return { ...state }

    case fsrts.handlePush_firestoreData:
      pushFirestore(dispatch, paps_state, ufProps, page_fns)
      return { ...state }

    // from the Big Settings Check
    case fsrts.handleUpdate_firestoreSettings_db:
      // this is currently being executed internally
      fsfn_settings.updateSettingsDb(dispatch, settings_temp, paps_state, isGlobal, sandboxOn, appUser).then(data_result => {
        return data_result
        // parentDispatch(res);
      })
      return { ...state }

    case fsrts.handleUpdate_firestoreSettings_globalViews:
      globalPageItems_update(settings_temp_global, parentDispatch)
      return { ...state }

    case fsrts.handleUpdate_firestoreSettings_globalAndViewsDb:
      updateSettings.updateGlobalAndViewsDBSettings(fullDbSettings, paps_state, sandboxOn, appUser, parentDispatch)
      return { ...state }

    case fsrts.handle_getData:
      // paths, wheres, callback, cbProps, opts
      fs_db.get_data(action.props)
      const newState = { ...state };
      return newState;

    case fsrts.handle_addDoc:
      fs_dbu.add_doc(action.paths, action.data, action.callback)
      return { ...state }

    case fsrts.handle_deleteDoc:
      fs_dbu.delete_doc(action.paths, action.data, action.callback)
      return { ...state }

    case fsrts.handle_setDoc:
      fs_dbu.set_doc(action.paths, action.data, action.merge, action.callback)
      return { ...state }

    case fsrts.handle_updateDoc:
      fs_dbu.update_doc(action.paths, action.data, action.callback)
      return { ...state }

    case fsrts.handleKill_confirmation:
      return { ...state, confirmation: null }

    case fsrts.updateSuccess:
    case fsrts.updateSuccessAlt:
    case fsrts.updateError:
      return responseReducers(state, action, {})

    case fsrts.handleUpdate_allDocuments:
      updateDocumentHelpers.update_allDocuments(pathViews, action.documentsData)
      return { ...state }

    case fsrts.handleUpdate_dataToDocument:
      updateDocumentHelpers.update_dataToDocument(pathViews, action.collection_pk, action.data, action.dataRestrictions, dispatch)
      return { ...state }

    case fsrts.handleUpdate_dataToDocuments:
      updateDocumentHelpers.update_dataToDocuments(pathViews, action.dataCollections)
      return { ...state }

    case fsrts.handleUpdate_itemInDocuments:
      updateDocumentHelpers.update_itemInDocuments(pathViews, action.documentName, action.documentKey, action.itemData, callback ? callback : dispatch)
      return { ...state }

    case fsrts.handleUpdate_itemsInDocument:
      updateDocumentHelpers.update_itemsInDocument(pathViews, action.documentName, action.data)
      return { ...state }

    default:
      return { ...state }
  }
}

export const firestoreFunctions = (firestore_handlers) => {

  const { handle_fsCall } = firestore_handlers

  return {
    handle_getDoc: (refPath, callback) => {
      const cb = (d) => {
        handle_fsCall(refPath)
        callback(d)
      }
      fs_db.get_doc({ refPath, callback: cb })
    },
    handle_getData: (props) => {
      const { callback } = props
      const cb = (d) => {
        handle_fsCall(props)
        callback(d)
      }
      const _props = { ...props }
      _props.callback = cb
      fs_db.get_data(_props)
    },
    ...fs_db
  }
}

export const firestoreHandlers = (dispatch) => {
  return {
    handle_fsCall: (fsCall) => { dispatch({ type: fsrts.handle_fsCall, dispatch, fsCall }) },
    handleCopy_firestoreData: (paps_state, ufProps) => { dispatch({ type: fsrts.handleCopy_firestoreData, dispatch, paps_state, ufProps }) },
    handlePush_firestoreData: (paps_state, ufProps) => { dispatch({ type: fsrts.handlePush_firestoreData, dispatch, paps_state, ufProps }) },
    handleUpdate_firestoreData: (parentDispatch, paps_state, ufProps, parentDispatchProps) => { dispatch({ type: fsrts.handleUpdate_firestoreData, dispatch, parentDispatch, paps_state, ufProps, parentDispatchProps }) },
    handleUpdate_firestoreDataDirect: (paps_state, ufProps) => { dispatch({ type: fsrts.handleUpdate_firestoreDataDirect, dispatch, paps_state, ufProps }) },

    handleUpdate_firestoreSettings_globalAndViewsDb: (parentDispatch, paps_state, isGlobal, fullDbSettings) => { dispatch({ type: fsrts.handleUpdate_firestoreSettings_globalAndViewsDb, dispatch, parentDispatch, paps_state, isGlobal, fullDbSettings }) },
    handleUpdate_firestoreSettings_db: (parentDispatch, paps_state, isGlobal, settings_temp, sandboxOn, isSolo) => { dispatch({ type: fsrts.handleUpdate_firestoreSettings_db, dispatch, parentDispatch, paps_state, isGlobal, settings_temp, sandboxOn, isSolo }) },
    handleUpdate_firestoreSettings_globalViews: (parentDispatch, paps_state, settings_temp_global) => { dispatch({ type: fsrts.handleUpdate_firestoreSettings_globalViews, dispatch, parentDispatch, paps_state, settings_temp_global }) },

    updateSuccess: () => { dispatch({ type: fsrts.updateSuccess }) },
    updateError: (error) => { dispatch({ type: fsrts.updateError, error }) },
    handleKill_confirmation: () => { dispatch({ type: fsrts.handleKill_confirmation }) },

    handle_addDoc: (paths, data, callback) => { dispatch({ type: fsrts.handle_addDoc, dispatch, paths, data, callback }) },
    handle_deleteDoc: (paths, callback) => { dispatch({ type: fsrts.handle_deleteDoc, dispatch, paths, callback }) },
    handle_setDoc: (paths, data, merge, callback) => { dispatch({ type: fsrts.handle_setDoc, dispatch, paths, data, merge, callback }) },
    handle_updateDoc: (paths, data, callback) => { dispatch({ type: fsrts.handle_updateDoc, dispatch, paths, data, callback }) },
    handle_getData: (props) => { dispatch({ type: fsrts.handle_getData, dispatch, props }) },

    handleUpdate_allDocuments: (documentsData) => { dispatch({ type: fsrts.handleUpdate_allDocuments, dispatch, documentsData }) },
    handleUpdate_dataToDocument: (collection_pk, data, dataRestrictions) => { dispatch({ type: fsrts.handleUpdate_dataToDocument, dispatch, collection_pk, data, dataRestrictions }) },
    handleUpdate_dataToDocuments: (dataCollections) => { dispatch({ type: fsrts.handleUpdate_dataToDocuments, dispatch, dataCollections }) },
    handleUpdate_itemInDocuments: (documentName, documentKey, itemData, callback) => { dispatch({ type: fsrts.handleUpdate_itemInDocuments, dispatch, documentName, documentKey, itemData, callback }) },
    handleUpdate_itemsInDocument: (documentName, data) => { dispatch({ type: fsrts.handleUpdate_itemsInDocument, dispatch, documentName, data }) },
    ...fs_db
  }
}

export const firestoreInitialState = (initState) => {
  return { ...initState, fsCalls: [] }
};

// export const firestoreInitialState = (paps_state, staticViews, page_fns, authAppUser, appSettings_state, dataRestrictions, appDataSource) => {
//   return { paps_state, staticViews, page_fns, authAppUser, appSettings_state, dataRestrictions, fsCalls: [], appDataSource }
// };

export const getUfProps = (vit, itemId, itemData, modifyType, selectedDataItems, swipedItems) => {
  return {
    id: itemId,
    itemData,
    modifyType,
    dataUpdateType: modifyType,
    selectedDataItems,
    swipedItem: swipedItems,
    vit: vit,
  }
}

export const getUfCopyProps = (copyFrom, copyTo) => {
  return {
    copyFrom,
    copyTo,
    dataUpdateType: gEnums.dataUpdateTypes.copy,
  }
}

export const getUfPushProps = (pushFrom, pushTo) => {
  return {
    pushFrom,
    pushTo,
    dataUpdateType: gEnums.dataUpdateTypes.push,
  }
} 