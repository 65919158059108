import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { appUserDataHandlers, appUserDataInitialState, appUserDataReducer } from '../reducers/AppUserDataReducer';
import { ParentContext } from './ParentContext';

/** Global Conversations for the Event */
export const AppUserDataContext = createContext();

/** Global Conversations for the Event */
const AppUserDataProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { appUser_state, page_state, paps_state } = states
  const { page_fns } = fns ?? {}

  const { appUser, appUsers } = appUser_state ?? {}
  const { pathViews, showStatus } = paps_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { uid } = appUserAccess ?? {}

  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings } = pageSettings ?? {}

  // local state
  const initState = appUserDataInitialState(appUser, appUsers, paps_state, page_state, page_fns, aps_appUserSettings)
  const [appUserData_state, appUserData_dispatch] = useReducer(appUserDataReducer, initState);
  const appUserData_handlers = appUserDataHandlers(appUserData_dispatch)
  const { conversations, notes } = appUserData_state

  if (showStatus && (conversations || notes)) { console.log('conversation_state ready', appUserData_state) }

  useEffect(
    () => {
      if (appUser.loggedIn && pathViews && pathViews.events) {
        appUserData_handlers.handleGet_conversations(paps_state)
        appUserData_handlers.handleGetNotes(paps_state)
      } else {
        appUserData_handlers.handleLoadConversations({})
        appUserData_handlers.handleLoadNotes({})
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathViews.events, uid] // does not re-rednder unless uivi has changed
  )

  const providerContext = () => <AppUserDataContext.Provider value={{ appUserData_state, appUserData_handlers, appUser }}>
    {props.children}
  </AppUserDataContext.Provider>

  return (
    <React.Fragment>
      {(conversations && notes) && providerContext(conversations)}
    </React.Fragment>
  )
}

export default AppUserDataProvider