import React, { createContext } from 'react';

export const AppUserManagementContext = createContext();

const AppUserManagementProvider = (props) => {

  return <AppUserManagementContext.Provider value={{ ...props }}>
    {props.children}
  </AppUserManagementContext.Provider>
};

export default AppUserManagementProvider