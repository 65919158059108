import { deleteField, where } from 'firebase/firestore'
import _ from 'lodash'
import { createFsDocKey, fs_db } from '../../firestoreData/appData/fsAppData'
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths'
import { fs_dbu } from '../../firestoreData/appData/fsAppDataUpdate'
import { dataFix } from '../../common/dataFix'
import { grts, responseHandlers, responseReducers } from './reducerHelpers/dispatchProps'

const rts = {
  handleAmmend_currentVotes: 'handleAmmend_currentVotes',
  handleAmmend_currentVotingItems: 'handleAmmend_currentVotingItems',
  handleAmmend_votingDocs: 'handleAmmend_votingDocs',
  handleAmmend_votingStatusType: 'handleAmmend_votingStatusType',
  handleClear_votes: 'handleClear_votes',
  handleClear_appUserVotes: 'handleClear_appUserVotes',
  handleDelete_voteItem: 'handleDelete_voteItem',
  handleDelete_voting: 'handleDelete_voting',
  handleInit_voting: 'handleInit_voting',
  handleSave_active: 'handleSave_active',
  handleSave_voteItemDoc: 'handleSave_voteItemDoc',
  handleSave_votes: 'handleSave_votes',
  handleSave_voting: 'handleSave_voting',
  handleVoteItemVote: 'handleVoteItemVote',
  handleVoting: 'handleVoting',
  ...grts
}

export const votingStatusTypes = {
  addVote: 'addVote',
  editVote: 'editVote',
  viewAll: 'viewAll',
  viewSelected: 'viewSelected',
  voting: 'voting',
}

export const voteItemResponses = {
  no: 'no',
  yes: 'yes',
}

const _votingCollection = '_voting'
const _votesCollection = '_votes'

const _votingsPaths = {
  votingCollection: '_voting',
  votingCollectionDoc: 'voting',
  votesCollectionDoc: 'votes',
}

const _voting = {
  voting: {
    voting: {

    },
    votes: {

    }
  }
}
// 2022NSC
export const votingReducer = (state, action) => {

  const { pathViews, currentVote, votingDocs, votingDoc, voteItemDocKey, appUserVotes } = state
  const { dispatch, type, votingStatusType } = action

  const { handleAmmend_votingDocs, handleAmmend_currentVotes, handleStopUpdate } = votingHandlers(dispatch)

  const _votingDocs = votingDocs ? { ...votingDocs } : {}
  let _votingDoc = _votingDocs ? _votingDocs[voteItemDocKey] : {}
  let { voteItems } = _votingDoc ?? {}
  const _voteItems = voteItems ?? {}

  const _appUserVotes = { ...appUserVotes }

  const _votingPath = createRefPath_event(pathViews, [_votingsPaths.votingCollection, _votingsPaths.votingCollectionDoc])
  const _votesPath = createRefPath_event(pathViews, [_votingsPaths.votingCollection, _votingsPaths.votesCollectionDoc])

  switch (type) {
    case rts.handleInit_voting:
      fs_db.get_data({ refPath: _votingPath, callback: handleAmmend_votingDocs, opts: { listen: true, returnFirstObject: true, ignoreId: true } })
      fs_db.get_data({ refPath: _votesPath, callback: handleAmmend_currentVotes, opts: { listen: true, returnFirstObject: true, ignoreId: true } })
      return { ...state, votingStatusType: action.votingStatusType }

    case rts.handleAmmend_votingDocs:
      return { ...state, votingDocs: action.results }

    case rts.handleAmmend_votingStatusType:

      if (action.e) { action.e.stopPropagation() }

      const _voteItemDocKey = !_.isObject(action.key) ? action.key : null

      switch (votingStatusType) {

        case votingStatusTypes.viewSelected:
          _votingDoc = _votingDocs ? _votingDocs[_voteItemDocKey] : {}
          break;

        case votingStatusTypes.editVote:
          _votingDoc = _votingDocs ? _votingDocs[_voteItemDocKey] : {}
          break;

        default:
        // nothing
      }
      return { ...state, votingStatusType, votingDoc: _votingDoc, voteItemDocKey: _voteItemDocKey }

    case rts.handleAmmend_currentVotingItems:
      return { ...state, currentVoteItems: action.results }

    case rts.handleAmmend_currentVotes:
      const { votes, _votingStatus, votingResults, appUserVotes } = getVotingStatus(action, state, votingDocs)
      return { ...state, votes, votingResults, votingStatus: _votingStatus, appUserVotes }

    case rts.handleSave_voting:
      const _dta = action.formData
      if (voteItemDocKey) {
        const { name } = action.formData
        _voteItems[name] = action.formData
        let dataToUpdate = action.formData
        let _updatePath;
        if (voteItemDocKey) {
          _updatePath = createRefPath_event(pathViews, [_votingCollection, voteItemDocKey, 'voteItems', voteItemDocKey])
          fs_dbu.update_doc(_updatePath, dataToUpdate, handleStopUpdate)
        } else {
          _updatePath = createRefPath_event(pathViews, [_votingCollection, voteItemDocKey, 'voteItems'])
          fs_dbu.add_doc(_updatePath, dataToUpdate, handleStopUpdate)
        }
      } else {
        const _docKey = createFsDocKey()
        const dta = { [_docKey]: _dta }
        fs_dbu.update_doc(_votingPath, dta, handleStopUpdate)
      }
      return { ...state }

    case rts.handleSave_voteItemDoc:
      const _dtu = action.formData
      dataFix.ammendDeleteFields(_dtu)
      if (voteItemDocKey) {
        const dta = { [voteItemDocKey]: _dtu }
        fs_dbu.update_doc(_votingPath, dta, handleStopUpdate)
      }
      return { ...state }

    case rts.handleSave_active:
      if (action.e) { action.e.stopPropagation() }
      const _key = action.key ? action.key : voteItemDocKey
      const vd = action.key ? votingDocs[action.key] : votingDoc
      const dtu = { active: !vd.active }
      const dta = { [_key]: dtu }
      fs_dbu.set_doc(_votingPath, dta, true, handleStopUpdate)
      return { ...state }

    case rts.handleDelete_voteItem:
      const _keyD = action.key ? action.key : voteItemDocKey
      if (_keyD) {
        const dta = { [_keyD]: deleteField() }
        fs_dbu.set_doc(_votingPath, dta, true, handleStopUpdate)
      }
      return { ...state }

    case rts.handleDelete_voting:
      if (voteItemDocKey) {
        const dta = { [voteItemDocKey]: deleteField() }
        fs_dbu.set_doc(_votingPath, dta, true, handleStopUpdate)
      }
      return { ...state }

    case rts.handleVoteItemVote:
      if (action.e) { action.e.stopPropagation() }
      switch (action.response) {
        case voteItemResponses.no:
          _appUserVotes[action.key] = false
          break;
        case voteItemResponses.yes:
          _appUserVotes[action.key] = true
          break;
      }
      return { ...state, appUserVotes: _appUserVotes }

    case rts.handleSave_votes:
      const _voteData = {
        [state.email_profile]: _appUserVotes
      }
      fs_dbu.set_doc(_votesPath, _voteData, true, action.callback)
      return { ...state }

    case rts.handleClear_votes:
      fs_dbu.delete_doc(_votesPath)
      return { ...state }

    case rts.handleClear_appUserVotes:
      return { ...state, appUserVotes: {} }

    case rts.handleStopUpdate:
      return responseReducers(state, action, { dispatch }, { votingStatusType: votingStatusTypes.viewAll })

    case rts.handleCloseConfirmation:
    case rts.handleStartUpdate:
      return responseReducers(state, action, { dispatch })

    default:
      return { ...state }
  }
}

export const votingInitialState = (initState) => {
  return { ...initState, appUserVotes: {} }
};

export const votingHandlers = (dispatch) => {
  return {
    handleAmmend_currentVotes: (results) => { dispatch({ type: rts.handleAmmend_currentVotes, dispatch, results }) },
    handleAmmend_currentVotingItems: (results) => { dispatch({ type: rts.handleAmmend_currentVotingItems, dispatch, results }) },
    handleAmmend_votingDocs: (results) => { dispatch({ type: rts.handleAmmend_votingDocs, dispatch, results }) },
    handleAmmend_votingStatusType: (votingStatusType, key, e) => { dispatch({ type: rts.handleAmmend_votingStatusType, dispatch, votingStatusType, key, e }) },
    handleClear_votes: () => { dispatch({ type: rts.handleClear_votes, dispatch }) },
    handleClear_appUserVotes: () => { dispatch({ type: rts.handleClear_appUserVotes, dispatch }) },
    handleDelete_voteItem: () => { dispatch({ type: rts.handleDelete_voteItem, dispatch }) },
    handleDelete_voting: () => { dispatch({ type: rts.handleDelete_voting, dispatch }) },
    handleInit_voting: (activeOnly, votingStatusType) => { dispatch({ type: rts.handleInit_voting, dispatch, activeOnly, votingStatusType }) },
    handleSave_active: (e, key) => { dispatch({ type: rts.handleSave_active, dispatch, e, key }) },
    handleSave_voteItemDoc: (formData) => { dispatch({ type: rts.handleSave_voteItemDoc, dispatch, formData }) },
    handleSave_votes: (callback) => { dispatch({ type: rts.handleSave_votes, dispatch, callback }) },
    handleSave_voting: (formData) => { dispatch({ type: rts.handleSave_voting, dispatch, formData }) },
    handleVoteItemVote: (e, key, response) => { dispatch({ type: rts.handleVoteItemVote, dispatch, e, key, response }) },
    ...responseHandlers(dispatch)
  }
}

const getVotingStatus = (action, state, votingDocs) => {

  const votingResults = {}
  let appUserVotes = {};
  let _votingStatus;

  const { results: votes } = action

  if (votes && votes[state.email_profile]) {
    _votingStatus = { allow: false }
    appUserVotes = votes[state.email_profile]
  } else {
    _votingStatus = { allow: true }
  }

  if (votes && votingDocs) {
    _.forEach(votingDocs, (votingDoc, votingDocKey) => {
      if (!votingResults[votingDocKey]) {
        votingResults[votingDocKey] = {
          yesCount: 0,
          noCount: 0
        }
      }

      _.forEach(votes, (vote, voteKey) => {
        if (_.isBoolean(vote[votingDocKey])) {
          if (vote[votingDocKey]) {
            votingResults[votingDocKey].yesCount++
          } else {
            votingResults[votingDocKey].noCount++
          }
        }
      })
    })

  }
  return { votes, _votingStatus, votingResults, appUserVotes }
}