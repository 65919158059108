import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Button, Icon, List, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../global/common/keys';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import UiSaveButtons from '../../../global/components/buttons/UiSaveButtons';
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper';
import { TicketingContext } from '../cnr/contexts/TicketingContext';

const ContactAssignment = (props) => {

  const { handleContact, handleCancel, handleAssignToContact } = props

  // ticketingContext
  const ticketingContext = useContext(TicketingContext)
  const { ticketing_handlers, ticketing_state } = ticketingContext ?? {}
  const { contacts } = ticketing_state ?? {}

  const [currentContact, setCurrentContact] = useState()
  const [phoneList, setPhoneList] = useState()
  const [emailList, setEmailList] = useState()
  const [selectedItem, setSelectedItem] = useState()

  const { item: item_selected, type: type_selected } = selectedItem ?? {}

  useEffect(() => {
    if (contacts && contacts[0]) {
      try {
        const contact = contacts[0]
        const { tel, email, name } = contact ?? {}
        if (tel && tel.length > 0) { setPhoneList(tel) }
        if (email && email.length > 0) { setEmailList(email) }
        setCurrentContact(name)
      } catch (error) {
        alert(error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [contacts]);

  const handleOk = () => {
    handleAssignToContact(selectedItem)
    handleContact({ name: currentContact, phoneNumber: type_selected === 'phone' ? item_selected : null, email: type_selected === 'email' ? item_selected : null })
    setPhoneList()
    setEmailList()
    ticketing_handlers.handleClearContacts()
  }

  const handleContactSelect = (type, item) => setSelectedItem({ type, item })

  const buttonSelect = () => <div className={'padd10'}>
    <Button fluid key={uniqueKey('ca', 'bs')} icon={'users'} content={_.startCase('contacts')} onClick={(e) => { ticketing_handlers.handleGetContact() }}></Button>
  </div>

  const contactItems = (type, list) => list.map((item, index) => {
    let listValue = item
    switch (type) {
      case 'phone':
        if (listValue.length === 10) { listValue = '+1' + listValue }
        listValue = formatPhoneNumber(listValue)
        break;
      default:
        break;
    }
    return <List.Item key={uniqueKey('ca', 'ci', index)} onClick={(e) => { handleContactSelect(type, item) }} >
      <Icon name={type === 'phone' ? 'phone' : 'mail'} color={item_selected === item ? 'blue' : 'grey'} />
      <List.Content>
        <List.Header>{listValue}</List.Header>
      </List.Content>
    </List.Item >
  })

  const contactList = () => <Segment basic><List divided relaxed size={'large'} >
    {phoneList && contactItems('phone', phoneList)}
    {emailList && contactItems('email', emailList)}
  </List></Segment>

  const wrapper = () => <Wrapper
    content={contactList()}
    footer={<UiSaveButtons save={{ oc: handleOk, caption: 'Select', disabled: !selectedItem }} />}
    wrapperType={wrapperTypes.paddedFooter}
  />

  const listWizard = () => <FullPageWrapper
    content={wrapper()}
    topperCaption={'Select Contact'}
    handleCancel={handleCancel}
  />

  if (contacts && (phoneList || emailList)) {
    return listWizard()
  } else {
    return buttonSelect()
  }
}

export default ContactAssignment