import _ from 'lodash';
import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { Card, Divider, Header, Icon, Message, Ref, Visibility } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { AlphaContext } from '../../cnr/contexts/AlphaContext';
import { ComponentContext } from '../../cnr/contexts/ComponentContext';
import ElementProvider from '../../cnr/contexts/ElementContext';
import { PageAreaParentContext } from '../../cnr/contexts/PageAreaParentContext';
import { convertHelpers, getAlphaList } from '../../common/convert';
import { filterHelpers } from '../../common/filtering';
import { uniqueKey } from '../../common/keys';
import { copyObj } from '../../common_web/copy';
import { gEnums } from '../../enums/globalEnums';
import { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';
import QrCode from '../../pageItem/modification/pageItemActions/QrCode';
import { addToClass, getThemeColorDirect, getThemeItem } from '../../styles/formatting';
import UiImage from '../imaging/UiImage';
import SwiperGroup from '../swipers/SwiperGroup';
import GetSectionElements from './SemSections';
import { propBreadcrumbs, sectionBreadcrumbs } from './breadcrumbs';
import CardNormal from './cards/CardNormal';
import CardProfile from './cards/CardProfile';
import CardSchedule from './cards/CardSchedule';
import { getCardColor, getCardStyle } from './semHelpers';
import { getViewableList } from './viewable/getViewableList';
import { useElementOnScreen } from './visibleElements';

const CardExtra = React.lazy(() => import('./CardExtra'));
const CardGeneric = React.lazy(() => import('./cards/CardGeneric'));
const ErrorPage = React.lazy(() => import('../alerts/ErrorPage'));
const PdfGallery = React.lazy(() => import('../imaging/PdfGallery'));

/**
 * 
 * @param {object} props (componentContexts, sliderCount, viewListData, cardProps)
 * @returns 
 */
const SemCards = (props) => {

  const _cds = gEnums.cardDisplayTypes

  // viewListData is passed into props because it can be modfied form the orignial list!!!
  const { sliderCount, viewListData, cardProps: card_props } = props

  // parentContext
  const parentContext = useContext(PageAreaParentContext);
  const { states, fns } = parentContext ?? {}
  const { appUser_state, paps_state, page_state, preview_state, transition_state } = states ?? {}
  const { previewStatus } = preview_state ?? {}
  const { page_fns } = fns ?? {}

  // authContext  
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { pageFavs, uid: uid_aua, isSuperAdminDeveloper } = appUserAccess ?? {}

  // componentContexts
  const componentContext = useContext(ComponentContext)
  const { component_state } = componentContext ?? {}
  const { componentContexts } = component_state ?? {}
  const { frameworkContext, displayContext, uiItemContext } = componentContexts ?? {}

  // frameworkContext 
  const { framework_state } = frameworkContext ?? {}
  const { desktopMode } = framework_state ?? {}

  const { view } = paps_state ?? {}

  // pageContext  
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings } = pageSettings ?? {}
  const { allowFavorites, favoriteCollections, showFavoritesFirst } = aps_appUserSettings ?? {}
  const { pushSimple, goBackTo, getGviDeps } = page_fns ?? {}
  const { dataOptions, tools, uiElements, propItems, themedItems, themeColors, clientOptions, eventOptions, desktop, projectOptions, groupingOptions, display: display_global } = aps_global ?? {}
  const { useLazyImageLoading } = projectOptions ?? {}
  const { minimizeBreaks } = display_global ?? {}
  const { useDefaultLayoutType, defaultDisplayType } = desktop ?? {}
  const { useScroll, scrollIncrement } = dataOptions ?? {}
  const _appOptions = { ...clientOptions, ...eventOptions }
  const { showEventStatus } = _appOptions ?? {}
  const { showItemIds } = tools ?? {}
  const { useGlobalAlphabetSettings, alphaDisplayType: alphaDisplayType_go, applyToCollections } = groupingOptions ?? {}
  const { card: cardStyle } = uiElements ?? {}
  const { content: contentTheme } = themedItems ?? {}
  const { propValueColors } = propItems ?? {}
  const cardProps = uiElements && uiElements.card ? { ...uiElements.card } : {}

  // transitionContext  
  const { transitions } = transition_state ?? {}
  const transition_image = transitions ? transitions[_animateTypes.image] : null
  const transition_pageItemElement = transitions ? transitions[_animateTypes.pageItemElement] : null
  const { showTransition } = transition_pageItemElement ?? {}

  const imageOptions = {
    transition_image,
    useLazyImageLoading
  }

  // alphaContext
  const alphaContext = useContext(AlphaContext);
  const { alpha_state, alpha_handlers } = alphaContext ?? {}
  const { useAlpha } = alpha_state ?? {}

  // displayContext 
  const { display_state } = displayContext ?? {}
  const { displayProps } = display_state ?? {}
  const { props_viewItem, ddGroupIndex } = displayProps ?? {}

  // uiItemContext 
  const { item_state, getParents } = uiItemContext ?? {}
  const { viewItem, propSections_allowed, viewItemStatus, _filterOpts, _groupingOpts, _imageOpts, _pageOpts, _viewItemOpts } = item_state ?? {}
  const { singleDataItem } = viewItemStatus ?? {}
  const { _display: _display_pageOpts } = _pageOpts ?? {}
  const { key: viewItem_key } = viewItem ?? {}
  const { alphaValue } = _filterOpts ?? {}
  const { showExtra, extraDisplayType, showExtraInternal } = _viewItemOpts ?? {}

  const _showFavs = allowFavorites && showFavoritesFirst && favoriteCollections && favoriteCollections.includes(viewItem_key) && (view !== viewItem_key)
  const _pageFavs = pageFavs && pageFavs[viewItem_key] && pageFavs[viewItem_key]

  // ref
  const contextRef = createRef()
  const headerColors = getThemeItem(aps_global, 'headers')

  let style = contentTheme && contentTheme.gap ? { marginBottom: contentTheme.gap + 'em' } : {}

  const { itemColor, images: image_viewItem, qrCodes, drillDown, propSections, display: display_vi, desktopDisplay, pdfs, indicator } = viewItem ?? {}
  const { alphaDisplayType, alphabetActive, alphaSortProp, groupActive, groupType } = _groupingOpts ?? {}

  const { showPdfs } = pdfs ?? {}
  const _display_true = _display_pageOpts ? _display_pageOpts : display_vi
  const _display = desktopMode && desktopDisplay ? desktopDisplay : _display_true
  const { indicatorProp, indicatorLocation } = indicator ?? {}

  const { showPdf, pdfFile } = _display ?? {}
  let { displayType, displayDirection, cardDisplayType, cssContainerName, sortProp, sortReverse, sortByPosition, sortByPositionAfter, useLazyLoading } = _display ?? {}

  // adjust for global
  const _alphaDisplayType = useGlobalAlphabetSettings && alphaDisplayType_go ? alphaDisplayType_go : alphaDisplayType

  if (desktopMode && useDefaultLayoutType && defaultDisplayType) { displayType = defaultDisplayType }
  if (!cardDisplayType) { cardDisplayType = _cds.normal }

  const _alphabetActive = alphabetActive || applyToCollections && applyToCollections.includes(viewItem_key) ? true : false

  const handleMouseMove = () => {

  }

  if (!sortProp && props_viewItem) {
    if (_.includes(Object.keys(props_viewItem), 'lastName')) {
      sortProp = 'lastName'
    } else {
      sortProp = 'name'
    }
  }

  const _inics = _.find(props_viewItem, (item) => _.get(item, 'indicator.showIndicator') === true);
  const sortByIndic = _inics ? _inics.key : null

  if (sortByPosition) { sortProp = 'position' }
  const _alphaSortProp = alphaSortProp ? alphaSortProp : sortProp

  if (_alphabetActive && _alphaSortProp) { sortProp = _alphaSortProp }

  const { useDrillDown, drillDownType } = drillDown ?? {}
  let { backgroundColor, backgroundImage, backgroundUrl, usePropColor, colorProp, colorDisplayType, showParentColor } = itemColor ?? {}
  const { showItemImage, imageLocation } = _imageOpts ?? {}

  let cardBdColor;
  if (backgroundColor) {
    cardBdColor = getThemeColorDirect(aps_global, backgroundColor)
  }

  const { showQrCode } = qrCodes ?? {}
  const { propColors } = {}
  const colorProps = {}
  let prcs = null

  const getCn = () => {
    const _indicatorLocation = indicatorLocation ? indicatorLocation : gEnums.bannerLocationTypes.bottom
    let _cn = ''
    if (showQrCode) { _cn += ' card-qr' }
    if (indicatorProp) {
      _cn += ' indic'
      if (_indicatorLocation === gEnums.bannerLocationTypes.top) { _cn += ' top' }
    }
    switch (cardDisplayType) {
      case _cds.profilePlus:
      case _cds.schedulePlus:
        _cn += ' plus'
        break;
      default:
      // nothing
    }

    let showFullImage = false

    if (imageLocation && showItemImage) {
      _cn = addToClass(_cn, 'card-img')
      _cn = addToClass(_cn, imageLocation, 'img')
    }

    if (showQrCode) { _cn += ' card-qr' }

    switch (cardDisplayType) {
      case _cds.profilePics:
        _cn += ' pci'
        break;

      case _cds.profilePlus:
      case _cds.schedulePlus:
        _cn += ' plus'
        showFullImage = true
        break;
      default:
      // nothing
    }

    return _cn
  }

  let imageInContent = true

  const cn = getCn()

  switch (imageLocation) {
    case gEnums.imageLocations.top:
      imageInContent = false
      break;
    default:
    // nothing
  }

  if (propValueColors) { prcs = _.groupBy(propValueColors, 'propItem') }


  let noClick;
  let bc;

  if (aps_global && aps_global.colorDisplayType) { colorDisplayType = aps_global.colorDisplayType }
  if (usePropColor && !colorDisplayType) { colorDisplayType = gEnums.colorDisplayTypes.tagLabel }

  // local state 
  const [fullList, setFullList] = useState()
  const [viewableVld, setViewableVld] = useState()
  const [alphaGroups, setAlphaGroups] = useState()
  const [useAlphabetMenu, setUseAlphabetMenu] = useState()
  const [dataCount, setDataCount] = useState(scrollIncrement ? scrollIncrement : 10)
  const [selectedKey] = useState()

  const alphaRefs = useRef([]);

  useEffect(() => {

    let _viewListData = copyObj(viewListData)
    let _useAlphabetMenu = false

    if (alphabetActive && useAlpha) {
      switch (_alphaDisplayType) {
        case gEnums.alphaDisplayTypes.tab:
        case gEnums.alphaDisplayTypes.vertical:
          if (_viewListData && alphabetActive) {
            const _alphaChar = String.fromCharCode(alphaValue)
            const { alphaList } = getAlphaList(_viewListData, _alphaChar, sortProp)
            _viewListData = alphaList
            _useAlphabetMenu = true
          }
          break;
        default:
        // nothing
      }
    }

    const fl = getViewableList(false, _viewListData, sortProp, sortReverse, sortByPositionAfter, dataCount, null, isSuperAdminDeveloper)
    let _viewableList = getViewableList(useScroll || useLazyLoading, _viewListData, sortProp, sortReverse, sortByPositionAfter, dataCount, sortByIndic, isSuperAdminDeveloper)

    if (alphabetActive) {
      switch (_alphaDisplayType) {
        case gEnums.alphaDisplayTypes.group:
          if (_viewableList) {
            const _alphaGroups = _.groupBy(_viewableList, (item) => _.head(_.first(item[sortProp]).toUpperCase()));
            setAlphaGroups(_alphaGroups)
            alpha_handlers.handleSet_alphaGroups(_alphaGroups)
          }
          break
        default:
        // nothing
      }
    }

    if (selectedKey) {
      const _data = filterHelpers.find(_viewableList, 'id', selectedKey) // _.find(vld, { id: selectedKey })
      setViewableVld([_data])
    } else {
      setViewableVld(copyObj(_viewableList))
    }
    setFullList(fl)

    setUseAlphabetMenu(_useAlphabetMenu)

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [viewListData, previewStatus]);

  useEffect(() => {
    if (alphaValue) {
      switch (alphaDisplayType) {
        case gEnums.alphaDisplayTypes.verticalFull:
          const _alphaChar = String.fromCharCode(alphaValue)
          if (alphaRefs && alphaRefs[_alphaChar]) {
            try {
              alphaRefs[_alphaChar].scrollIntoView({ behavior: 'instant', block: 'start' });
            } catch (error) {
              console.log('e', error)
            }
          }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [alphaValue]);

  const [divRef, isVisible] = useElementOnScreen({ root: null, rootMargin: '0px', threshold: 1.0 })

  const handleClick = (clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex) => {
    if (itemKey) pushSimple(clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex)
  }

  // handle the scroll
  const handleScroll = (e, value) => {
    if (useScroll || useLazyLoading) {
      if (value.calculations.bottomVisible) {
        if (dataCount <= fullList.length + 1) {
          let count = dataCount + scrollIncrement
          if (count > fullList.length + 1) { count = fullList.length + 1 }
          setDataCount(count)
        }
      }
    }
  }

  // drillDown
  if (useDrillDown && ddGroupIndex) {
    switch (drillDownType) {
      case gEnums.drillDownTypes.sections:
        if (!drillDown['section' + (ddGroupIndex + 1)]) { noClick = true }
        if (drillDown.showBreadcrumbs) { bc = sectionBreadcrumbs(drillDown, propSections, propSections_allowed, viewListData, ddGroupIndex, goBackTo) }
        break;
      default:
        if (!drillDown['group' + (ddGroupIndex + 1)]) { noClick = true }
        if (drillDown.showBreadcrumbs) { bc = propBreadcrumbs(drillDown, viewableVld, propSections_allowed, ddGroupIndex, goBackTo) }
    }
  }

  const card_top = (index, elemProps, itemData) => {

    switch (cardDisplayType) {
      case _cds.profilePlus:
        return <ElementProvider elemProps={elemProps} itemData={itemData} >
          <CardProfile key={uniqueKey('sc', 'cp', index)} topOnly={true} />
        </ElementProvider>

      case _cds.schedulePlus:
        return <ElementProvider elemProps={elemProps} itemData={itemData} >
          <CardSchedule key={uniqueKey('sc', 'cp', index)} topOnly={true} />
        </ElementProvider>

      default:
        return null
    }
  }

  const card_single = (index, _isBreak) => {

    if (_isBreak) {
      return <Divider horizontal className='break-div' >
        <Header size={'tiny'}>
          <Icon name='time' color="blue" />
          Break
        </Header>
      </Divider>
    } else {

      switch (cardDisplayType) {

        case _cds.profile:
        case _cds.profilePics:
          return <CardProfile key={uniqueKey('sc', 'cp', uid_aua)} />

        case _cds.profilePlus:
          return <CardNormal key={uniqueKey('sc', 'cp', 'cpp', uid_aua)} isSubCard={true} />

        case _cds.busSchedule:
        case _cds.client:
        case _cds.event:
        case _cds.schedule:
          const _idx = uniqueKey('sc', 'cp', 'sc', index)
          return <CardSchedule key={_idx} />

        case _cds.schedulePlus:
          return <CardNormal key={uniqueKey('sc', 'cp', 'cn', uid_aua)} isSubCard={true} />

        case _cds.taggedOnly:
          return <div>TEST</div>

        case _cds.description:
          return <div>Description ONLY</div>

        case _cds.venue:
          return <CardGeneric />

        default:
          return <CardNormal key={uniqueKey('sc', 'cp', 'cn', uid_aua)} />
      }
    }
  }

  const card_extra = (_itemKey) => <CardExtra extraDisplayType={extraDisplayType} viewListData={viewListData} _itemKey={_itemKey} />

  const card_alpha = (alpha) => <div className='card-alpha' ref={el => (alphaRefs[alpha] = el)} >
    {alpha}
  </div>

  const cards = (dataList) => {

    let _crdTop;
    const crds = []
    const crds_favs = []

    let currentAlpha;

    // eslint-disable-next-line 
    dataList.forEach((itemData, index) => {

      const { _itemKey, name } = itemData ?? {}
      let alpha;
      let isNewAlpha;

      if (alphabetActive) {
        switch (alphaDisplayType) {
          case gEnums.alphaDisplayTypes.verticalFull:
            const _name = convertHelpers.getItemName(itemData, true)
            alpha = _name.charAt(0).toUpperCase();
            isNewAlpha = alpha !== currentAlpha;
            currentAlpha = alpha
        }
      }

      let _isBreak = false

      if (minimizeBreaks && name && name.toLowerCase().startsWith('break')) {
        _isBreak = true
      }

      let addCard = true
      let showFullImage = singleDataItem

      if (sliderCount && (index >= sliderCount)) { addCard = false }

      if (!_.isObject(itemData)) {
        addCard = false
        showFullImage = true
      }

      if (addCard) {

        let { inactive } = itemData ?? {}
        let _showItemImage = showItemImage

        switch (cardDisplayType) {
          case _cds.profile:
          case _cds.profilePics:
          case _cds.profilePlus:
          case _cds.schedule:
          case _cds.schedulePlus:
            _showItemImage = false
            break;

          case _cds.page:
            ammendPropSections(cardDisplayType, propSections)
            break;
          default:
          // nothing
        }

        const cardImage = _showItemImage ? <UiImage
          key={uniqueKey('scci', index)}
          itemData={itemData}
          viewItem={viewItem}
          index={index}
          showFullImage={showFullImage}
          inView={isVisible}
          imageOptions={imageOptions}
        /> : null

        const cardQr = showQrCode ? <QrCode
          qrCodeType={gEnums.qrCodeTypes.itemData}
          key={uniqueKey('sccq', index)}
          itemData={itemData}
          viewItem={viewItem}
          index={index}
          showFullImage={showFullImage}
          inView={isVisible}
        /> : null


        // IMPORTANT: UI - Sections - Get the Sections
        const sprops = { parentContext, componentContexts, itemData, headerColors, cardImage, cardQr, showEventStatus, componentContexts }
        const _sections = GetSectionElements(sprops)

        if (!_sections) { return <Card fluid><Message>No Props selected. Check view settings.</Message></Card> }

        if (_sections && Object.keys(_sections).length === 0) {
          return <Card fluid><Message>No Sections are set to show. Check settings.</Message></Card>
        }

        const allowItem = true
        const inactiveClass = inactive ? ' item-inactive' : ''

        if (allowItem) {

          let color = getCardColor(getGviDeps, viewItem, itemData, itemColor, propColors, colorProps, prcs, getParents)

          const csp = getCardStyle(usePropColor, color, colorDisplayType, cardBdColor)
          const cardPrps = {}

          if (cardProps.raised) { cardPrps.raised = true }
          if (csp.cardStyle) { style = { ...style, ...csp.cardStyle } }

          if (backgroundUrl) {
            style.backgroundImage = `url(${backgroundUrl})`
          }

          // items passed into iCardItems
          const semSecProps = {
            aps_appUserSettings,
            aps_global,
            cardDisplayType,
            cardImage,
            cardStyle,
            color,
            colorProp,
            colorProps,
            csp,
            display_viewItem: _display,
            displayType,
            headerColors,
            images: image_viewItem,
            propSections,
            showParentColor,
            showQrCode,
            statusIcon: showEventStatus && viewItem && viewItem.key === 'events' ? 'check' : 'cog',
            themeColors,
            usePropColor,
          }

          let cardWrapperCn = csp.cardCn + inactiveClass

          if (cssContainerName) {
            if (cardWrapperCn) {
              cardWrapperCn = addToClass(cardWrapperCn, 'ccn-' + cssContainerName)
            } else {
              cardWrapperCn = 'ccn-' + cssContainerName
            }
          }

          if (cardProps && cardProps.shadowless) { cardWrapperCn = addToClass(cardWrapperCn, 'nbs') }

          const elemProps = {
            _display,
            _itemKey,
            _sections,
            aps_appUserSettings,
            aps_global,
            appUserAccess,
            cardImage,
            cardPrps,
            card_props,
            cardQr,
            divRef,
            getParents,
            handleClick,
            imageInContent,
            image_viewItem,
            index,
            noClick,
            page_fns,
            semSecProps,
            showItemIds,
            showItemImage,
            showPdfs,
            showQrCode,
            style,
            viewItem,
          }

          _crdTop = card_top(index, elemProps, itemData)

          let ac;

          switch (_alphaDisplayType) {
            case gEnums.alphaDisplayTypes.verticalFull:
              ac = isNewAlpha ? card_alpha(alpha) : null
              break;
            default:
            // nothing
          }

          const cardProvider = () => <ElementProvider key={uniqueKey('cp', index)} cn={cn} itemData={itemData} elemProps={elemProps}          >
            {ac}
            {card_single(index, _isBreak)}
            {showExtra && extraDisplayType && !showExtraInternal && card_extra(_itemKey)}
          </ElementProvider>

          if (_showFavs && _pageFavs && _pageFavs[_itemKey]) {
            crds_favs.push(cardProvider())
          } else {
            crds.push(cardProvider())
          }

        }
      }
    })

    let useSwiperGroup;

    if (groupActive) {
      switch (groupType) {
        case gEnums.groupTypes.swipeableGroup:
          useSwiperGroup = true
          break;
        default:
        // nothing 
      }
    }

    switch (displayDirection) {
      case gEnums.displayDirectionTypes.horizontal:
        useSwiperGroup = true
        break;
      default:
      // nothing 
    }

    // className={card_props.cnc}
    const cards_withTop = (crds, crdt) => <React.Fragment key={uniqueKey('sc', 'cp', 'ct')}>
      {crdt}
      <Card.Group vit={card_props.vit} itemsPerRow={card_props.itemsPerRow}  >
        {crds}
      </Card.Group>
    </React.Fragment>

    const cards_normal = (crds) => <Card.Group vit={card_props && card_props.vit} itemsPerRow={card_props && card_props.itemsPerRow}>
      {crds}
    </Card.Group>

    const cards_favs = (crds) => <Card.Group className={'card-group-favs'}>
      <div key={uniqueKey('sc', 'am', 'grp', 'fav')}>Following</div>
      {crds}
    </Card.Group>

    if (useSwiperGroup) {
      return <SwiperGroup content={crds} />
    } else {
      if (_crdTop) {
        if (showTransition) {
          return <MotionComponent
            transition={transition_pageItemElement}
          >
            {cards_withTop(crds, _crdTop)}
          </MotionComponent>
        } else {
          return cards_withTop(crds, _crdTop)
        }
      } else {
        if (useAlphabetMenu) {
          return <React.Fragment key={uniqueKey('sc', 'am')}>
            {cards_normal(crds)}
          </React.Fragment>
        } else {
          if (crds_favs.length > 0) {
            return <React.Fragment key={uniqueKey('sc', 'cf', 'cn')}>
              {cards_favs(crds_favs)}
              {cards_normal(crds)}
            </React.Fragment>
          } else {
            return cards_normal(crds)
          }
        }
      }
    }
  }

  const pdfLine = () => <PdfGallery itemPdf={pdfFile} showFile={true} />

  // IMPORTANT: UI - Create CARDS for EACH itemData
  // LOOP data 
  const allContent = () => {
    if (!viewableVld) { return <div key={uniqueKey('ac', 'nvd')}></div> }
    if (alphaGroups) {
      const _groups = []
      Object.keys(alphaGroups).forEach((agk, index) => {
        const alphaGroup = alphaGroups[agk]
        _groups.push(<div key={uniqueKey('sc', 'am', 'grp', index)}>
          <Divider horizontal>{agk}</Divider>
          {cards(alphaGroup)}
        </div>)
      })
      return _groups
    } else {
      return <React.Fragment key={uniqueKey('sc', 'am', 'psf')}  >
        {showPdf && pdfFile && pdfLine()}
        {cards(viewableVld)}
      </React.Fragment>
    }
  }

  const content = () => {
    const _allContent = allContent()
    if (useScroll || useLazyLoading) {
      return <Ref innerRef={contextRef}>
        <Visibility offset={[10, 10]} onUpdate={handleScroll} className={'scroll-container'}>
          {_allContent}
        </Visibility>
      </Ref>
    } else {
      return _allContent
    }
  }

  try {
    if (viewableVld || alphaGroups) {
      if (bc) {
        return <React.Fragment key={uniqueKey('sc', 'am', 'ag')}>
          {bc}
          {allContent()}
        </React.Fragment>
      } else {
        return content()
      }
    } else {
      return <div key={uniqueKey('sc', 'am', 'grp', 'empty')}></div>
    }
  } catch (error) {
    return <ErrorPage error={error} component={'Sem Cards'} />
  }
}

const ammendPropSections = (cardDisplayType, ps) => {
  if (ps) {
    Object.keys(ps).forEach(key => {
      switch (cardDisplayType) {
        case gEnums.cardDisplayTypes.page:
          const sps = ps[key]
          if (sps.sectionHeader) {
            sps.sectionHeader.overrideAppSettings = true
            sps.sectionHeader.headerDisplayType = gEnums.sectionHeaderTypes.none
          }
          if (sps.sectionBody) {
            sps.sectionBody.overrideAppSettings = true
            sps.sectionBody.bodyDisplayType = gEnums.sectionBodyTypes.noCaptions
          }
          break;
        default:
        // nothing
      }
    })
  }
}

export default SemCards