import _ from 'lodash'

const rts = {
  handleNoData: 'handleNoData',
  handleAmmend_swiperCaptions: 'handleAmmend_swiperCaptions',
  handleSet_levels: 'handleSet_levels',
  handleSet_selected_teams: 'handleSet_selected_teams',
  handleSet_selectedLevel: 'handleSet_selectedLevel',
  handleSet_selectedTeamLevel: 'handleSet_selectedTeamLevel',
  handleSet_selectedTeams: 'handleSet_selectedTeams',
  handleSet_swiper: 'handleSet_swiper',
  handleSet_teamRecords: 'handleSet_teamRecords',
  handleShow_common: 'handleShow_common',
  handleShow_selected: 'handleShow_selected',
  handleShow_playoffPicture: 'handleShow_playoffPicture',
}

export const sportsStandingsReducer = (state, action) => {
  const { type } = action
  switch (type) {
    case rts.handleNoData:
      return { ...state, noData: action.noData }

    case rts.handleAmmend_swiperCaptions:
      const _swiper = state.swiper
      const { swipeItems } = _swiper ?? {}
      if (swipeItems && action.captions) {
        Object.keys(action.captions).forEach(capKey => {
          const swipeItem = _.find(swipeItems, { key: capKey })
          if (swipeItem) {
            swipeItem.caption = action.captions[capKey]
          }
        })
      }
      return { ...state, swiper: _swiper }
    case rts.handleSet_levels:
      return { ...state, levels: action.levels }
    case rts.handleSet_selected_teams:
      const commonOpponents = getOppCommon(action.selected_teams, state.teamRecords)
      return { ...state, selected_teams: action.selected_teams, commonOpponents: commonOpponents }
    case rts.handleSet_selectedTeamLevel:
      return { ...state, selectedTeamLevel: action.selectedTeamLevel }
    case rts.handleSet_selectedTeams:
      return { ...state, selectedTeams: action.selectedTeams }
    case rts.handleSet_swiper:
      return { ...state, swiper: action.swiper }
    case rts.handleShow_selected:
      return { ...state, showSelected: !state.showSelected }
    case rts.handleShow_common:
      return { ...state, showCommon: !state.showCommon }
    case rts.handleSet_teamRecords:
      return { ...state, teamRecords: action.teamRecords }
    case rts.handleSet_selectedLevel:
      return { ...state, selectedLevelKey: action.selectedLevelKey }
    case rts.handleShow_playoffPicture:
      if (state.showPlayoffPicture) {
        return { ...state, showPlayoffPicture: false, selected_teams: [], showSelected: false, showCommon: false }
      } else {
        return { ...state, showPlayoffPicture: !state.showPlayoffPicture }
      }

    default:
      return { ...state }
    // nothing
  }
}

export const sportsStandingsHandlers = (dispatch) => {
  return {
    handleAmmend_swiperCaptions: (captions) => { dispatch({ type: rts.handleAmmend_swiperCaptions, dispatch, captions }) },
    handleNoData: (noData) => { dispatch({ type: rts.handleNoData, dispatch, noData }) },
    handleSet_levels: (levels) => { dispatch({ type: rts.handleSet_levels, dispatch, levels }) },
    handleSet_selected_teams: (selected_teams) => { dispatch({ type: rts.handleSet_selected_teams, dispatch, selected_teams }) },
    handleSet_selectedLevel: (selectedLevelKey) => { dispatch({ type: rts.handleSet_selectedLevel, dispatch, selectedLevelKey }) },
    handleSet_selectedTeamLevel: (selectedTeamLevel) => { dispatch({ type: rts.handleSet_selectedTeamLevel, dispatch, selectedTeamLevel }) },
    handleSet_selectedTeams: (selectedTeams) => { dispatch({ type: rts.handleSet_selectedTeams, dispatch, selectedTeams }) },
    handleSet_swiper: (swiper) => { dispatch({ type: rts.handleSet_swiper, dispatch, swiper }) },
    handleSet_teamRecords: (teamRecords) => { dispatch({ type: rts.handleSet_teamRecords, dispatch, teamRecords }) },
    handleShow_common: () => { dispatch({ type: rts.handleShow_common, dispatch }) },
    handleShow_playoffPicture: () => { dispatch({ type: rts.handleShow_playoffPicture, dispatch }) },
    handleShow_selected: () => { dispatch({ type: rts.handleShow_selected, dispatch }) },
  }
}

export const sportsStandingsInitialState = (init_state) => {
  return { ...init_state, selected_teams: [] }
};

const getOppCommon = (teamsSelected, teamRecords) => {
  const teams = []
  teamsSelected.forEach(st => {
    const _tr = _.find(teamRecords, { _itemKey: st })
    if (_tr) {
      teams.push(_tr)
    }
  })
  const opponentNamesByTeam = teams.map(team => [
    ...team.results.nonSection.wins.map(win => win.opponent.name),
    ...team.results.nonSection.losses.map(loss => loss.opponent.name),
    ...team.results.section.wins.map(win => win.opponent.name),
    ...team.results.section.losses.map(loss => loss.opponent.name)
  ]);
  const commonOpponents = _.intersection(...opponentNamesByTeam);
  return commonOpponents
}