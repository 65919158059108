import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { where } from "firebase/firestore";
import { signInResponseTypes, sirts } from '../../cnr/reducers/SignInReducer';
import { signInResponseData } from "../../cnr/reducers/reducerHelpers/signInProps";
import { gEnums } from '../../enums/globalEnums';
import { fs_db } from '../../firestoreData/appData/fsAppData';
import { fs_dbu } from '../../firestoreData/appData/fsAppDataUpdate';
import { fsfn_auth } from '../../functions/fbAuth';
import { validateEmail } from '../../validation/validation';
import { sendEmailVerificationToCurrentUser } from './sendEmailVerification';
import { firebaseSignIn } from "./signInMethods";


const dispatchResponse = (callback, response) => {
  const { signInResponseType, authData } = response ?? {}
  const _sirProps = { signInResponseType, authData }
  callback(signInResponseData(_sirProps))
}

/** Sign In using signInWithEmailAndPassword */
export const signInAppUser = async (siProps) => {

  const { hasProfile, creds, signInData } = siProps
  const { email } = signInData ?? {}
  const { email: email_creds, password } = creds ?? {}

  const _email = email ? email : email_creds

  const authCreds = {
    email: _email ? _email.toLowerCase().trim() : null,
    password: password ? password.trim() : null,
  }

  // validate the email
  if (!validateEmail(authCreds.email)) {
    return ({ success: false, signInResponseType: signInResponseTypes.invalidEmail, email: authCreds.email });
  }

  // a profile must have been found before continuing
  if (hasProfile) {
    signInWithPassword(authCreds, siProps)
  }
}

const signInWithPassword = async (authCreds, siProps) => {

  const { callback } = siProps

  console.log('authCreds', authCreds)

  const auth = getAuth()

  try {
    signInWithEmailAndPassword(auth, authCreds.email.toLowerCase(), authCreds.password)
      .then((userCredential) => {
        const resp = { success: true, signInResponseType: signInResponseTypes.signInSuccess, authData: userCredential }
        dispatchResponse(callback, resp);
      })
      .catch((error) => {
        emailPasswordFail(authCreds, siProps, error)
      });
  } catch (err) {
    emailPasswordFail(authCreds, siProps, err)
  }
}

// CPS2023
/**
 * Handles the failure of the sign in
 * @param {object} authCreds 
 * @param {object} siProps 
 * @param {object} err 
 * @returns 
 */
const emailPasswordFail = (authCreds, siProps, err) => {

  const { callback, appSignIn, allowSignUp, appUserCollection, signInData, directSignIn } = siProps
  const { appUserAccessType } = signInData ?? {}
  const { useAuthGlobalPassword, authGlobalPassword } = appSignIn ?? {}

  const allowNonAuth = appUserAccessType === gEnums.appUserAccessTypes.appProfile ? true : false

  let resp = {}

  //  sign in with the email/password FAILED
  const { code, message: errorMessage } = err ?? {};

  switch (code) {

    case 'auth/too-many-requests':
      resp = { signInResponseType: signInResponseTypes.tooManyAttempts, errorMessage, success: false };
      dispatchResponse(callback, resp);
      break;

    default:
      // if we allow authGlobalPassword, continue
      if (!directSignIn && appUserCollection) {
        if (allowNonAuth && useAuthGlobalPassword && (authGlobalPassword === authCreds.password)) {
          signInWithGlobalPw(authCreds, siProps)
        } else {
          dispatchResponse(callback, { success: false, signInResponseType: signInResponseTypes.incorrectPassword });
        }
      } else {
        if (allowSignUp) {
          createNewAuth(authCreds)
        } else {
          const err_2 = { message: 'Log-in Error' };
          dispatchResponse(callback, { signInResponseType: signInResponseTypes.incorrectPassword, err_2, success: false })
          // return ({ signInResponseType: signInResponseTypes.incorrectPassword, err_2, success: false });
        }
      }
  }
}

/**
 * Tries to sign in with a global password
 * @param {object} authCreds 
 * @param {object} siProps 
 */
const signInWithGlobalPw = async (authCreds, siProps) => {

  let resp = {}

  const { callback, hasAuth, appSignIn, signInData } = siProps
  const { firstName, lastName, displayName } = signInData ?? {}
  const { sendEmailVerification } = appSignIn ?? {}

  const data_global = { email: authCreds.email, password: authCreds.password, firstName, lastName, displayName };

  if (data_global.email && data_global.password) {
    if (hasAuth) {
      // if the user already has authentication, and has not already been alerted, alert the user that their password will be changed
      resp = { success: true, signInResponseType: signInResponseTypes.handlePasswordAlert };
    } else {
      const response = await fsfn_auth.createOrUpdateAuthAndSignIn(data_global)
      const { success } = response ?? {}
      if (success === true) {
        resp = await firebaseSignIn.emailAndPassword(authCreds)
        if (sendEmailVerification) { sendEmailVerificationToCurrentUser(); }
      } else {
        resp = { type: sirts.handleSignInResponse, signInResponseType: signInResponseTypes.signInError, success: false };
      }
    }
  }
  dispatchResponse(callback, resp);
}

/**
 * Creates a new user with an email and password
 * @param {object} authCreds 
 * @returns 
 */
const createNewAuth = async (authCreds) => {

  const auth = getAuth()

  try {
    await createUserWithEmailAndPassword(
      auth,
      authCreds.email.toLowerCase(),
      authCreds.password
    );

    var user = auth.currentUser;

    fs_db.get_data({ refPath: ['profiles'], wheres: [where('email', '==', user.email)] }).then(profile => {
      fs_dbu.add_doc(['profiles'], profile);
      fs_dbu.delete_doc(['profiles', user.email]);
    });

  } catch (err) {
    return ({ signInResponseType: signInResponseTypes.signUpError, err, success: false });
  }
}