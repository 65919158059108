import React, { useContext } from 'react';
import { SettingsAreaContext } from '../../../cnr/contexts/SettingsAreaContext';
// import { SettingsAreaContext } from '../../../cnr/contexts/SettingsAreaContext';
import { SettingsMenuContext } from '../../../cnr/contexts/SettingsMenuContext';
import { uniqueKey } from '../../../common/keys';
import { copyObj } from '../../../common_web/copy';
import { getSettingsAreaGroupMenuItemElements } from './getSettingsAreaGroupMenuItems';
import SettingsAreaGroupMenuItem from './SettingsAreaGroupMenuItem';

/**
   * Creates a single SettingsMenuGroup with a list of menuItems that are 
   * @param {*} group 
   * @param {*} grpSettings 
   * @param {*} inverse 
   * @returns 
   */
const SettingsAreaGroupMenu = (props) => {

  const { group, grpSettings, inverse, menuElementGroups } = props
  const { key: groupKey, groupItems, iconColor: groupIconColor, restrict } = group ?? {}
  const { caption: groupCaption, icon: groupIcon, isAlt, notInverted, componentAreas } = grpSettings ?? {}
  const { appAreaTypes } = componentAreas ?? {}

  const _groupItems = copyObj(groupItems)

  // settingsMenuContext
  const settingsMenuContext = useContext(SettingsMenuContext);
  const { settingsMenu_state } = settingsMenuContext ?? {}
  const { activeMenu, activeMenus, useCollapsibleMenus } = settingsMenu_state ?? {}

  const cornerApp = {
    home: appAreaTypes && appAreaTypes.length === 1 && appAreaTypes[0] === 'home',
    client: appAreaTypes && appAreaTypes.length === 1 && appAreaTypes[0] === 'client',
    clients: appAreaTypes && appAreaTypes.length === 1 && appAreaTypes[0] === 'clients',
    event: appAreaTypes && appAreaTypes.length === 1 && appAreaTypes[0] === 'event'
  }

  let cornerIcon;

  if (cornerApp.home) {
    cornerIcon = 'home'
  } else if (cornerApp.client) {
    cornerIcon = 'building outline'
  } else if (cornerApp.clients) {
    cornerIcon = 'list ul'
  } else if (cornerApp.event) {
    cornerIcon = 'calendar check outline'
  }

  const settingsAreaContext = useContext(SettingsAreaContext);

  let menuItemElements = menuElementGroups && menuElementGroups[groupKey] ? menuElementGroups[groupKey].menuItemElements : null

  if (!menuItemElements) {
    const { menuItemElements: menuItemElements_alt } = getSettingsAreaGroupMenuItemElements(groupKey, groupItems, settingsMenuContext, settingsAreaContext)
    menuItemElements = menuItemElements_alt
  }

  // get all the menuItems for the groupKey  
  if (menuItemElements.length > 0) {

    const itemActive = activeMenus[groupKey]
    const active = !useCollapsibleMenus ? (activeMenu === groupKey) : itemActive

    // IMPORTANT: Settings Menu - Set the caption
    const smgProps = {
      active,
      isAlt,
      caption: groupCaption,
      groupIcon,
      cornerIcon: cornerIcon ? cornerIcon : null,
      groupItems: _groupItems,
      groupKey,
      iconColor: groupIconColor ? groupIconColor : 'blue',
      inverse,
      menuItemElements,
      notInverted,
      restrict
    }

    return <SettingsAreaGroupMenuItem key={uniqueKey('smgm', groupKey)} {...smgProps} />

  }

  return menuItemElements

}

export default SettingsAreaGroupMenu