import _ from 'lodash';
import { toDateTime } from '../../common/dateFormatting';
import { sortObject } from '../../common/sorting';
import { gEnums } from '../../enums/globalEnums';
import { updateNotifications } from '../../firestoreData/notifications/notificationsData';
import { grts, responseReducers } from './reducerHelpers/dispatchProps';

export const viewModes = {
  addDirect: 'addDirect',
  addNormal: 'addNormal',
  addTopic: 'addTopic',
  edit: 'edit',
  list: 'list',
  normal: 'normal',
  preview: 'preview',
  remove: 'remove',
  user: 'user',
}


const rts = {
  handleAppUserNotifications: 'handleAppUserNotifications',
  handleInit_notifications: 'handleInit_notifications',
  handleNotificationActive: 'handleNotificationActive',
  handleNotificationEdit: 'handleNotificationEdit',
  handleNotificationSend: 'handleNotificationSend',
  handleSetActiveNotificationKey: 'handleSetActiveNotificationKey',
  handleSetAppUserNotifications: 'handleSetAppUserNotifications',
  handleSetViewMode: 'handleSetViewMode',
  ...grts
}

export const appNotificationsViewerReducer = (state, action) => {

  const { notifications, activeNotificationKey, pathViews, page_fns } = state
  const { type } = action

  switch (type) {

    case rts.handleInit_notifications:
      return { ...handleNotificationsInit(state, action.notifications) }

    case rts.handleSetViewMode:
      return { ...state, viewMode: action.viewMode }

    case rts.handleSetActiveNotificationKey:
      return { ...state, activeNotificationKey: action.activeNotificationKey, viewMode: viewModes.preview }

    case rts.handleSetAppUserNotifications:
      return { ...state, appUser_notifications: action.notes }

    case rts.handleNotificationActive:
      const _notificationA = notifications[activeNotificationKey]
      _notificationA.active = !_notificationA.active
      updateNotifications(pathViews, page_fns, _notificationA, _notificationA, handleSuccess)
      return { ...state }

    case rts.handleNotificationEdit:
      return { ...state, viewMode: viewModes.edit }

    case rts.handleNotificationSend:
      const _notification = notifications[activeNotificationKey]
      _notification.sent = !_notification.sent
      updateNotifications(pathViews, page_fns, _notification, _notification, handleSuccess)
      return { ...state }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { questionProps: null })

    default:
      return { ...state }
  }
}

export const appNotificationsViewerHandlers = (dispatch) => {
  return {
    handleInit_notifications: (notifications) => { dispatch({ type: rts.handleInit_notifications, dispatch, notifications }) },
    handleNotificationActive: () => { dispatch({ type: rts.handleNotificationActive, dispatch }) },
    handleNotificationEdit: (activeNotificationKey) => { dispatch({ type: rts.handleNotificationEdit, dispatch, activeNotificationKey }) },
    handleNotificationSend: () => { dispatch({ type: rts.handleNotificationSend, dispatch }) },
    handleSetActiveNotificationKey: (activeNotificationKey) => { dispatch({ type: rts.handleSetActiveNotificationKey, dispatch, activeNotificationKey }) },
    handleSetAppUserNotifications: (notes) => { dispatch({ type: rts.handleSetAppUserNotifications, dispatch, notes }) },
    handleSetViewMode: (viewMode) => { dispatch({ type: rts.handleSetViewMode, dispatch, viewMode }) },
  }
}

const handleSuccess = () => { console.log('SEND') }

export const appNotificationsViewerInitialState = (initState) => {
  return { ...initState }
};

const handleNotificationsInit = (state, notifications) => {

  const { pathViews, page_fns, staticViews, aps_appUserSettings } = state

  ammendNotifications(notifications, aps_appUserSettings, staticViews)

  let _notesDirect = {}
  let _notesTimed = {}
  let _noteGroups

  let ngk = []

  if (notifications) {
    Object.keys(notifications).forEach(key => {
      const notification = notifications[key]
      const { sendDate, sendTime } = notification
      if (sendDate && sendTime) {
        _notesTimed[key] = notification
      } else {
        _notesDirect[key] = notification
      }
    })
    _notesTimed = _.sortBy(_notesTimed, 'sendDateAndTimeUtc')
    _notesDirect = _.sortBy(_notesDirect, 'sent')
    _noteGroups = _.groupBy(_notesTimed, 'sendDate')
    ngk = getNotificationGroupsKeys(_noteGroups)
  }

  return {
    directNotifications: _notesDirect,
    notifications,
    notificationScheduleType: gEnums.notificationScheduleTypes.scheduled,
    timedNotificationGroups: _noteGroups,
    timedNotificationGroupsKeys: ngk,
    viewMode: viewModes.normal,
    pathViews,
    page_fns,
  }
}


const getNotificationGroupsKeys = (gdg) => {
  gdg = sortObject(gdg)
  let groupKeys = Object.keys(gdg)
  groupKeys = _.sortBy(groupKeys, function (value) {
    const dv = value && value.seconds ? toDateTime(value.seconds) : value
    return new Date(dv);
  })
  return groupKeys
}

const ammendNotifications = (notifications, aps_appUserSettings, staticViews) => {
  const { appUserViewTypeProp } = aps_appUserSettings ?? {}
  const staticView = staticViews && appUserViewTypeProp ? staticViews[appUserViewTypeProp] : null
  if (notifications && staticView) {
    Object.keys(notifications).forEach(key => {
      const notification = notifications[key]
      const { targetOptions } = notification ?? {}
      if (targetOptions) {
        const { subKey, subKeys } = targetOptions ?? {}
        if (subKey) {
          if (staticView[subKey]) {
            targetOptions.subType = staticView[subKey].name
          }
        }
        if (subKeys) {
          targetOptions.subTypes = []
          subKeys.forEach(sk => {
            if (staticView[sk]) {
              targetOptions.subTypes.push(staticView[sk].name)
            }
          })
        }
      }
    })
  }
}
