import React, { useContext } from 'react';
import { Divider, Icon } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import { getSectionClass, getSectionThemeColors } from '../../styles/formatting';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { gEnums } from '../../enums/globalEnums';
import { headerOverrides, propOverrides } from '../props/propsCreate';
import { ComponentContext } from '../../cnr/contexts/ComponentContext';

const SectionHeader = (props) => {

  const { shProps, sectionHeader: sectionHeader_p } = props
  const { propSection, key, itemData, propSectionKey, sectionHeader, secret } = shProps ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state } = states ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}

  // componentContexts
  const componentContext = useContext(ComponentContext)
  const { component_state } = componentContext ?? {}
  const { componentContexts } = component_state ?? {}

  // componentContexts
  const { dataContext, uiItemContext } = componentContexts ?? {}
  const { data_handlers } = dataContext ?? {}
  const { handleSecretData } = data_handlers ?? {}

  let { item_handlers, item_state } = uiItemContext ?? {}
  let { viewItem, vld, propOverrideOn } = item_state ?? {}

  const _sectionHeader = sectionHeader_p ? sectionHeader_p : sectionHeader

  let { headerDisplayType, caption, icon, allowHeaderCollapse: ahc } = _sectionHeader ?? {}

  let cn = 'sec-header'
  cn = getSectionClass(cn, _sectionHeader)
  if (propOverrideOn) { cn += ' sec-ovr' }
  const sectionHeaderStyle = getSectionThemeColors(aps_global, _sectionHeader)

  const snc = {
    style: sectionHeaderStyle,
    className: cn
  }

  const getHeader = () => {
    const secretColor = itemData && itemData[propSectionKey] ? 'blue' : 'grey'
    const subs = []
    const subsA = []
    if (icon) { subs.push(<Icon name={icon} />) }
    if (ahc) { subs.push(<Icon className={'hcd'} name={'caret down'} />) }
    if (secret) { subsA.push(<Icon className={'hcd'} name={'user secret'} color={secretColor} onClick={() => { handleSecretData({ propSectionKey, viewItem, vld, itemData }) }} />) }
    if (propOverrideOn) { subsA.push(<div className='disp-override'>{headerOverrides(item_handlers, propSection)}</div>) }
    return <div key={uniqueKey('crd', 'sh', key)}>{subs}{caption}{subsA}</div>
  }

  switch (headerDisplayType) {
    case gEnums.sectionHeaderTypes.clearing:
      return <div key={uniqueKey('crd', 'dsh', propSectionKey)}{...snc}>
        {getHeader()}
        <Divider clearing />
      </div>
    case gEnums.sectionHeaderTypes.divider:
      return <div key={uniqueKey('crd', 'dsh', propSectionKey)}{...snc}>
        <Divider horizontal>
          {getHeader()}
        </Divider>
      </div>
    case gEnums.sectionHeaderTypes.section:
      return <div key={uniqueKey('crd', 'dsh', propSectionKey)}{...snc}>
        {getHeader()}
        <Divider section />
      </div>
    case gEnums.sectionHeaderTypes.hidden:
    case gEnums.sectionHeaderTypes.blockAttached:
    case gEnums.sectionHeaderTypes.block:
      return <div key={uniqueKey('crd', 'dsh', propSectionKey)} {...snc}>
        {getHeader()}
      </div>
    default:
      return <div key={uniqueKey('crd', 'dsh', propSectionKey)} {...snc}>
        <Divider />
      </div>
  }
}

export default SectionHeader
