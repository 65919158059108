import _ from 'lodash';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { uniqueKey } from '../common/keys';

export const appUserDropdownItems = (props) => {

  const { appUserAccess, myProfileCaption, myProfileIcon, pathViews, appUserCollection, handleMenuClick } = props
  const { appUserSessionKey, events: events_appUser } = appUserAccess ?? {}

  const items = []
  const itemKey = appUserSessionKey

  const appUserDDi = {
    caption: myProfileCaption ? myProfileCaption : _.startCase('myProfile'),
    icon: myProfileIcon ? myProfileIcon : 'user',
  }

  // if the eventPath exists
  if (events_appUser) {
    if (pathViews.events && events_appUser[pathViews.events]) {
      items.push(<Dropdown.Item key={uniqueKey('tmi', 'di', 'evt')} icon={appUserDDi.icon} text={appUserDDi.caption} onClick={() => { handleMenuClick(appUserCollection, itemKey) }} />)
    } else if (pathViews.clients) {
      items.push(<Dropdown.Item key={uniqueKey('tmi', 'di', 'evt')} icon={appUserDDi.icon} text={appUserDDi.caption} onClick={() => { handleMenuClick(appUserCollection, itemKey) }} />)
    }
  }

  return items

};
