import _ from 'lodash';
import React, { useContext } from 'react';
import { Divider, Header, Icon } from 'semantic-ui-react';
import { FormContext } from '../../../cnr/contexts/FormContext';
import { uniqueKey } from '../../../common/keys';
import { gEnums } from '../../../enums/globalEnums';
import FormItem from '../FormItem';
import { creatingHelpers } from '../../../common/creating';

const logErrors = true
/**
 * 
 * @param {*} props (aips, itemProp, index, isTabbed)
 * @returns a single `FormItem`. It may contain a `Divider`
 */
export const FormItemProp = (props) => {

  const { itemProp, index, isTabbed } = props

  // formContext
  const formContext = useContext(FormContext)
  const { form_state, formParent_contexts } = formContext ?? {}

  const { states, settings } = formParent_contexts ?? {}
  const { page_state } = states ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_page } = pageSettings ?? {}
  const { appDataSource } = aps_global ?? {}
  const { viewItems: viewItems_page } = aps_page ?? {}
  const { autoUpdateCollectionRelationships } = appDataSource ?? {}

  const { homeSettings } = settings ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}
  const { logging } = homeSettings_global ?? {}

  const {
    activeItem,
    viewProps_normal,
    data_localForm,
    formProps,
    isWizard,
    viewOnly,
  } = form_state

  let {
    readOnlyForm,
    useWizard,
    viewItem,
    viewPropsAllowed,
  } = formProps ?? {}

  const viewItem_page = viewItem && viewItems_page && viewItems_page[viewItem.key] ? viewItems_page[viewItem.key] : {}
  const { dataSource, dataConstraints } = viewItem_page ?? {}
  const tabbed = _.filter(viewProps_normal, i => i.data ? i.data.tabbed : {});
  const tabbedO = creatingHelpers.createObject(tabbed, 'key')

  const propElements = []

  let { inPisa, pisa } = props

  const _dataConstraints = dataConstraints ? dataConstraints : dataSource
  const { dataParents } = dataSource ?? {}
  const { useDataParentConstraints } = _dataConstraints ?? {}
  const { key: propKey, prop: propProp } = itemProp

  const _propKey = propKey ? propKey : propProp

  // if (_propKey === 'locations') {
  //   console.log('itemProp', itemProp)
  // }

  if (!itemProp.data) { itemProp.data = {} }

  const { data: data_itemProp } = itemProp
  const { breakAfter, breakBefore, iconBefore, breakBeforeGroup, breakBeforeIcon, breakAfterGroup, isAlt } = data_itemProp

  // determine whether this prop should be shown
  let allow = isTabbed ? setPropFormAllow(data_localForm, _propKey, data_itemProp) : setPropFormAllow(data_localForm, _propKey, data_itemProp, tabbedO, viewPropsAllowed, autoUpdateCollectionRelationships)

  if (useDataParentConstraints && dataParents) {
    if (dataParents.includes(_propKey)) { allow = false }
  }

  // disallow certain props
  if ((_propKey && _propKey.startsWith('_gs')) || (_propKey && _propKey.startsWith('_gsid'))) { allow = false }
  if (_propKey.startsWith('_')) { allow = false }

  const _allow = allow

  if (itemProp && _allow) {
    if (breakBeforeGroup) {
      inPisa = true
      if (breakBeforeIcon) {
        propElements.push(<Header key={uniqueKey('fi', 'h', _propKey)} as='h3' dividing textAlign='center'>
          <Icon name={breakBeforeIcon} />
          <Header.Content>{breakBeforeGroup}</Header.Content>
        </Header>)
      } else {
        propElements.push(<Divider className={isAlt ? 'emph alt' : 'emph'} key={uniqueKey('fi', 'd', _propKey)} horizontal >{breakBeforeGroup}</Divider>)
      }
    }

    if (breakBefore && !useWizard && !isWizard) {
      if (iconBefore) {
        propElements.push(<Divider key={uniqueKey('fi', 'bb', _propKey)} horizontal className={isAlt ? 'alt' : null}><Icon name={iconBefore} />{breakBefore}</Divider>)
      } else {
        propElements.push(<Divider key={uniqueKey('fi', 'bb', _propKey)} horizontal className={isAlt ? 'alt' : null}>{breakBefore}</Divider>)
      }
    }

    let pise;

    switch (_propKey) {
      case 'address2':
      case 'country':
      case 'latitudeLongitude':
        break;
      case 'displayType':
        pise = <FormItem
          key={uniqueKey('fi', _propKey)}
          activeItem={activeItem}
          formData={data_localForm}
          index={index}
          itemProp={itemProp}
          propKey={_propKey}
          allow={allow}
        />
        break;
      default:
        if (data_itemProp && !data_itemProp.formItemType) {
          try {
            switch (_propKey) {
              case 'description':
                data_itemProp.formItemType = gEnums.formItemTypes.textArea
                break;
              default:
                data_itemProp.formItemType = gEnums.formItemTypes.text
                break;
            }
          } catch (error) {
            logErrors && console.error(error)
          }
        }

        if (data_itemProp.formItemType && _propKey && _propKey.indexOf('UTC') <= 0) {
          pise = <FormItem
            key={uniqueKey('fi', 'aaa', _propKey)}
            activeItem={activeItem}
            allow={allow}
            index={index}
            itemProp={itemProp}
            propKey={_propKey}
            readOnlyForm={readOnlyForm}
            viewOnly={viewOnly}
          // formData={data_localForm}
          />
        }
    }

    propElements.push(pise)

    if (inPisa && pisa) { pisa.push(pise) }
    if (breakAfterGroup) { pisa = [] }
  }

  if (breakAfter) { propElements.push(<Divider key={uniqueKey('fi', 'ba', _propKey)} horizontal>{breakAfter}</Divider>) }
  return propElements
}

/**
 * 
 * @param {object} formData 
 * @param {string} propKey 
 * @param {object} data_itemProp 
 * @param {object} tabbedO 
 * @param {object} viewPropsAllowed 
 * @returns whether the prop is allowed
 */
const setPropFormAllow = (formData, propKey, data_itemProp, tabbedO, viewPropsAllowed, autoUpdateCollectionRelationships) => {

  const { formItemType, dataListType, showIfProp, showIfProps, showIfPropValues, showIfPropsValues, showIfNotPropValues, showIfPropExists } = data_itemProp

  let allow = true


  let allowAny = formData && showIfPropValues && showIfPropValues.includes('ANY') && showIfProp && formData[showIfProp] && formData[showIfProp].length > 0

  // if (tabbedO && tabbedO[propKey]) { allow = false }
  if (viewPropsAllowed) { if (!viewPropsAllowed.includes(propKey)) { allow = false } }
  if (showIfProp && formData) { if (showIfPropValues && !allowAny) { if (!showIfPropValues.includes(formData[showIfProp])) { allow = false } } }
  if (showIfNotPropValues) { if (showIfNotPropValues.includes(formData[showIfProp])) { allow = false } }
  if (showIfPropExists) { if (!formData[showIfPropExists]) { allow = false } }
  // if (!autoUpdateCollectionRelationships && dataListType === gEnums.dataListTypes.staticViews) { allow = false }
  if (propKey && propKey.toLowerCase().indexOf('ampm') >= 0) { allow = false }

  switch (formItemType) {
    case gEnums.formItemTypes.colorSelect:
    case gEnums.formItemTypes.colorSelectSingle:
      // nothing
      break;
    default:
      // if (formData[propKey] && _.isObject(formData[propKey])) { allow = false }
      break;
  }

  switch (propKey && propKey.toLowerCase()) {
    case 'fsid':
    case 'key':
      allow = false
      break;
    default:
    // nothing
  }

  if (formData && showIfProps && showIfPropsValues && (showIfProps.length === Object.keys(showIfPropsValues).length)) {
    let _allow = true
    showIfProps.forEach((_showIfProp, index) => {
      try {
        const _formValue = formData[_showIfProp]
        const _valueArray = showIfPropsValues[index + 1]
        if (_formValue && _valueArray) {
          if (!_valueArray.includes(_formValue)) {
            _allow = false
          }
        } else {
          _allow = false
        }
      } catch (error) {
        console.error(error)
      }
    })
    allow = _allow
  }


  return allow
}
