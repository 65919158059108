import React from 'react'
import { Header, Icon, Message, Segment } from 'semantic-ui-react'

const _useSegment = false

const NotificationMessage = (props) => {

  const { notification } = props
  const { title, content, message, extra, auth } = notification ?? {}

  const notificationDiv = () => {
    if (title && content) {
      return <Message icon size='tiny'>
        <Icon name='info circle' color='blue' />
        <Message.Content>
          <Message.Header>{notification.title}</Message.Header>
          {content}
        </Message.Content>
      </Message>
    } else if (message) {
      return <Message icon size='tiny'>
        <Icon name='info circle' color='blue' />
        <Message.Content>
          <Message.Header>{message}</Message.Header>
        </Message.Content>
      </Message>
    } else {
      return <Message icon size='tiny'>
        <Icon name='info circle' color='blue' />
        <Message.Content>
          {/* <Message.Header>{notification}</Message.Header> */}
        </Message.Content>
      </Message>
    }
  }

  const segment = () => {
    if (title && content) {
      return <Segment.Group size='mini'>
        <Segment><Header>
          <Icon name='info circle' color='blue' />
          <Header.Content>{title}</Header.Content>
        </Header></Segment>
        <Segment>
          {content}
        </Segment>
        {extra && <Segment>
          <Icon name='bell outline' color='blue' />{extra}
        </Segment>}
        {auth && <Segment>
          <Icon name='user secret' color='red' />{'sending to: '}{auth}
        </Segment>}
      </Segment.Group>
    } else if (message) {
      return <Segment.Group>
        <Segment><Header>
          <Icon name='info circle' color='blue' />
          <Header.Content>{message}</Header.Content>
        </Header></Segment>
      </Segment.Group>
    } else {
      return <Segment.Group>
        <Segment> <Header>
          <Icon name='info circle' color='blue' />
          <Header.Content>{'Title'}</Header.Content>
        </Header></Segment>
      </Segment.Group>
    }
  }

  return _useSegment ? segment() : notificationDiv()

}

export default NotificationMessage