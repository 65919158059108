import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { rssHandlers, rssInitialState, rssReducer } from '../reducers/RssReducer';
import { PageContext } from './PageContext';
import { ParentContext } from './ParentContext';
import { PageDataContext } from './PageDataContext';
import { PageAreaContext } from './PageAreaContext';

/** Global Conversations for the Event */
export const RssContext = createContext();

/** Global Conversations for the Event */
const RssProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state } = states

  const pageContext = useContext(PageContext); // OK
  const { homeSettings_global } = pageContext ?? {}
  const { rssFeeds: rssFeeds_home } = homeSettings_global ?? {}

  const pageAreaContext = useContext(PageAreaContext)
  const { pageArea_state } = pageAreaContext ?? {}
  const { pageItemsShown } = pageArea_state ?? {}
  const { feeds } = pageItemsShown ?? {}
  const { componentProps } = feeds ?? {}
  const { rssFeeds, rssFeed, titleSearch, contentSearch } = componentProps ?? {}


  const pageDataContext = useContext(PageDataContext); // OK
  const { pageData_state } = pageDataContext ?? {}
  const { currentPageDataCaption } = pageData_state ?? {}

  // const ts = searchByPageCaption && currentPageDataCaption ? currentPageDataCaption.trim() : titleSearch
  // const cs = searchByPageCaption && currentPageDataCaption ? currentPageDataCaption.trim() : contextSearch

  const { pathViews } = paps_state ?? {}

  // local state
  const initState = rssInitialState({})
  const [rss_state, appUserData_dispatch] = useReducer(rssReducer, initState);
  const rss_handlers = rssHandlers(appUserData_dispatch)

  useEffect(() => {
    if (rssFeeds_home && rssFeeds) {
      rss_handlers.handleGet_rssFeeds(rssFeeds_home, rssFeeds, rssFeed, titleSearch, contentSearch)
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathViews.events, rssFeeds_home, rssFeeds, rssFeed] // does not re-rednder unless uivi has changed
  )

  return <RssContext.Provider value={{ rss_state, rss_handlers }}>
    {props.children}
  </RssContext.Provider>
}

export default RssProvider