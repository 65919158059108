import React from 'react';
import { Icon } from 'semantic-ui-react';

export const propsPicker = (props) => {

  const { items, settingsViewer_handlers } = props
  const { handleUpdateViewItem } = settingsViewer_handlers ? settingsViewer_handlers : {}

  const _itemsW = []

  if (items) {
    items.forEach(i => {
      const { props } = i
      const { propKey } = props ?? {}
      _itemsW.push(
        <div className='prop-picker'>
          <div><Icon name='delete' onClick={() => { handleUpdateViewItem(propKey) }}></Icon></div>
          {i}
        </div>
      )
    })
  }

  return _itemsW

}