import { _globalCollectionName } from "../../../projectSpecific/sports/dbActions/globals";
import { dispatchProps } from "../../cnr/reducers/reducerHelpers/dispatchProps";
import { fsfn_updateStaticViews } from "../../functions/fbDataFunctions";
import { createRefPath_any } from "../appData/appRefPaths";

const staticViewCollectionName = 'staticViews'

export const saveToStaticViews = (pathViews, viewItem, viewListData, staticName, fixedStaticView, dataRestrictions, dispatch) => {

  const { key: currentVit } = viewItem ?? {}

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.backupSeasonals)) {
    dispatch && dispatch({ type: dispatchProps.successAlt, dispatch })
    return true
  }

  if (currentVit) {
    fsfn_updateStaticViews(pathViews, viewItem, viewListData, staticName, fixedStaticView, null, dispatch)
  }
}

/**
 * 
 * @param {object} paps_state 
 * @param {string} currentVit 
 * @param {function} callback 
 * @param {object} pageContext 
 * @param {boolean} clientOnly 
 * @param {object} globals 
 */
export const getStaticViews = async (database_fns, pathViews, callback, globals) => {

  let refPath = createRefPath_any(pathViews)

  refPath += globals ? '/' + _globalCollectionName : '/' + staticViewCollectionName

  try {
    const resultData = await database_fns.get_data({ refPath: refPath, opts: { ignoreId: true } })
    const { globals: globals_r } = resultData ?? {}
    if (globals_r) {
      callback({ pathViews, hasData: true, globals: resultData })
    } else {
      callback({ pathViews, hasData: true, staticViews: resultData })
    }
  } catch (error) {
    if (globals) {
      callback({ pathViews, hasData: true, globals: {} })
    } else {
      callback({ pathViews, hasData: true, staticViews: {} })
    }
  }
}

export const createAppStaticViews = (app_state, viSubKeys, aps_viewItems) => {
  const asl = {}
  if (viSubKeys && app_state) {
    viSubKeys.forEach(sk => {
      if (app_state[sk]) {
        const skItems = app_state[sk]
        const vi = aps_viewItems ? aps_viewItems[sk] : null
        const { dataConnection } = vi ?? {}
        const { uniqueProps } = dataConnection ?? {}
        asl[sk] = {}

        Object.keys(skItems).forEach(skItemKey => {
          const skItem = skItems[skItemKey]
          if (skItem.name || (skItem.lastName && skItem.firstName)) {
            asl[sk][skItemKey] = {}
            if (skItem.lastName && skItem.firstName) {
              asl[sk][skItemKey].firstName = skItem.firstName
              asl[sk][skItemKey].lastName = skItem.lastName
            } else if (skItem.name) {
              asl[sk][skItemKey].name = skItem.name
            }
          }
          if (uniqueProps) {
            uniqueProps.forEach(up => {
              if (skItem[up] && !asl[sk]) {
                asl[sk][skItemKey][up] = skItem[up]
              }
            })
          }
        })
      }
    })
  }
  return asl
}